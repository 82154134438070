import { useEffect, useState } from "react";
import MicRecorder from 'mic-recorder-to-mp3';

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const Mp3Recorder = new MicRecorder({ bitRate: 128 });  
  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (isRecording) {
        requestRecorder().then(setRecorder, console.error);
      }
      return;
    }
    // Manage recorder state.
    if (isRecording) {
      recorder.start();
    } else {
      recorder.stop();
    }
    // Obtain the audio when ready.
 
    const handleData = (e) => {
       const blobUrl =URL.createObjectURL(e.data,{type:'audio/mpeg-3'})
        
      fetch(blobUrl)
      .then(response => response.blob())
      .then(blobData => {
        // Create a new File from the Blob data
        const audioFile = new File([blobData], 'audio.mp3', { type: blobData.type });
        setAudioURL([audioFile])
      })
      .catch(error => {
        console.error('Error fetching Blob data:', error);
      });
    };

  
    recorder.addEventListener("dataavailable", handleData);
    return () => recorder.removeEventListener("dataavailable", handleData);
  }, [recorder, isRecording]);
  const startRecording = () => {
    Mp3Recorder
      .start()
      .then(() => {
        setIsRecording(true);
    }).catch((e) => console.error(e));

  };
  const stopRecording = () => {
    Mp3Recorder
    .stop()
    .getMp3()
    .then(([buffer, blob]) => {
      setIsRecording(false);
    }).catch((e) => console.error(e));
  };
  return [audioURL, isRecording, startRecording, stopRecording];
};
async function requestRecorder() {
  const stream = (await navigator.mediaDevices.getUserMedia({ audio: true }))
  return new MediaRecorder(stream, { type: "audio/mpeg-3" });
}
export default useRecorder;