import React, { useCallback, useEffect } from "react"
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useState } from "react";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import EuiSwitch from "../../eui-components/switch";
import EuiTooltip from "../../eui-components/tooltip";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import ActivityHelper from "../../utils/activity-helper";
import ApiClient from "../../utils/api-client";
import CommonHelper from "../../utils/common-helper";
import constants from "../../utils/constants";
import DateUtils from "../../utils/date-utils";
import PageHelper from "../../utils/page-helper";

const ActiveEHSSessions = forwardRef((props, ref) => {
    const [activeEHSPingsColumns, setColumns] = useState([
        {
            title: "EHS Ping ID",
            key: "id",
            sort: "none",
            width: "10%",
        },
        {
            title: "Activity Name",
            key: "name",
            sort: "none",
            width: "10%"
        },
        {
            title: "Customer",
            key: "customerName",
            sort: "none",
            width: "15%"
        },
        {
            title: "Workplan",
            key: "workplanName",
            sort: "none",
            width: "15%"
        },
        {
            title: "ASP Company | (Team Leader | EHS Auditor)",
            key: "aspCompanyAndTeamLead",
            sort: "none",
            width: "15%"
        },
        {
            title: "RSC EHS Inspector",
            key: "rscPersonNameEHS",
            sort: "none",
            width: "15%"
        },
        {
            title: "Sent to RSC Date",
            key: "sentToRSC",
            onCreatedCell: (td, cellData) => CommonHelper.renderTableDate(td, cellData),
            sort: "desc",
            width: "15%"
        },
        {
            title: "Status",
            key: "acceptanceStatusId",
            onCreatedCell: (td, cellData) => {
                const status = PageHelper.renderActivityStatus(cellData);
                td.innerHTML = `${status}`;
            },
            sort: "none",
            width: "5%"
        },
    ]);

    const [activeEHSPings, setActiveEHSPings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showOnlyNotAssignedEHSPings, changeFilter] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 100);
    }, [props.selectedRscCenter]);

    useImperativeHandle(ref, () => ({
        getActiveEHSPings() {
            getActiveEHSPings();
        }
    }));

    const getActiveEHSPings = useCallback(() => {
        setIsLoading(true);
        ApiClient.post("Activity/GetActiveEHSPings").then(response => {
            const ehsPings = [...response.data];
            ActivityHelper.mapEHSSessions(ehsPings);
            setActiveEHSPings(ehsPings);
            setIsLoading(false);
        }).catch(error => {
            console.error(error);;
            CommonHelper.createNotification({ title: constants.errorMessage, icon: "cross" });
            setIsLoading(false);
        });
    }, []);

    const getActionButtons = () => {
        return <><button className={"btn primary " + (props.selectedSessions.length === 0 ? "disabled" : "")}
            style={{ marginRight: 10 }}
            onClick={() => props.toggleUnAssignDialog({ open: true, type: ActivityHelper.sessionUnAssignDialogContents.EHS })}>Un-Assign</button>
            <button className={"btn primary " + (props.selectedSessions.length === 0 ? "disabled" : "")}
                style={{ marginRight: 10 }}
                onClick={() => props.toggleAssignDialog({ open: true, type: ActivityHelper.sessionDialogContents.EHS })}>Re-Assign</button>
            <EuiTooltip title="Refresh" position="left">
                <i className="icon icon-reload icon-lg icon-frame m-1" onClick={() => getActiveEHSPings()}></i>
            </EuiTooltip>
        </>
    }

    const getActiveEHSPingsFiltered = useCallback(() => {
        return showOnlyNotAssignedEHSPings ? activeEHSPings.filter(a => a.rscCenter === props.selectedRscCenter && !a.rscPersonNameEHS)
            : activeEHSPings.filter(a => a.rscCenter === props.selectedRscCenter);
    }, [activeEHSPings, showOnlyNotAssignedEHSPings, props.selectedRscCenter, isLoading])

    return <EuiRow disabled={isLoading ? "disabled" : ""} key={isLoading}>
        <EuiLoading isLoading={isLoading} />
        <ObseleteEuiTable
            classes="tiny split-striped"
            columns={activeEHSPingsColumns}
            data={getActiveEHSPingsFiltered()}
            id="activeEHSListTableId"
            viewActionButton={true}
            actionButton={getActionButtons()}
            paginated={true}
            rowsPerPage={10}
            selectable="multi"
            selectRow={(e, type) => props.setSelectedSessions(e, type)}
            filter={true}
            hideSearch={true}
            viewActionIcon={true}
            actionIcon={
                <span className="ml-2">
                    <EuiSwitch
                        id="filterRscEHSPingsSwitch"
                        onChange={() => { setIsLoading(true); changeFilter(!showOnlyNotAssignedEHSPings); setTimeout(() => setIsLoading(false), 1000) }}
                        checked={showOnlyNotAssignedEHSPings}
                        dataEnabled="Not Assigned EHS Pings"
                        dataDisabled="All EHS Pings"
                        name="rscEHSPingsFiltered"
                    />
                </span>
            }
        />
    </EuiRow>
});

export default ActiveEHSSessions;