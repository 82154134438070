import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTile from "../../eui-components/tile";
import FormField from "../../eui-components/formfield";


class TicketSolution extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() { }

    render() {

        return (
            <EuiTile fullscreen>

                <FormField className="w-10">
                    <label htmlFor="TicketSolutionLabelId">Solution</label>
                    <textarea
                        style={{ height: 200 }}
                        className="fullwidth"
                        id="ticketSolutionId"
                        name="ticketSolution"
                        onChange={e => this.props.setSolution(e.target.value)}
                        defaultValue={this.props.ticketData.currentTicket.solution || ""}
                        onBlur={e => { }}
                        disabled={this.props.viewOnly}
                    />
                </FormField>

                <ToastContainer enableMultiContainer containerId={'WD'} autoClose={2500} />

            </EuiTile>);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ticketData: state.ticket });
const mapDispatchToProps = dispatch => {
    return {
        setTickets: (tickets) => dispatch({ type: "SET_TICKETS", payload: tickets }),
        setTicketPageMod: (mod) => dispatch({ type: "SET_TICKET_PAGE_MOD", payload: mod }),
        setCurrentTicket: (ticket) => dispatch({ type: "SET_CURRENT_TICKET", payload: ticket })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TicketSolution));