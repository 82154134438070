import React from "react";
import DateUtils from "../../utils/date-utils";
import DialogModal from "../dialog";
import { connect } from "react-redux";
import SasHelper from "../../utils/sas-helper";

class DocumentDialogModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
    }

    getLatitude = () => {
        return this.props.selectedDocument.latitude && this.props.selectedDocument.latitude.length > 8 ?
            this.props.selectedDocument.latitude.substr(0, 8) :
            this.props.selectedDocument.latitude;
    }

    getLongitude = () => {
        return this.props.selectedDocument.longitude && this.props.selectedDocument.longitude.length > 8 ?
            this.props.selectedDocument.longitude.substr(0, 8) :
            this.props.selectedDocument.longitude
    }

    getContent = () => {
        const documentPath = this.props.selectedDocument.documentPath;
        const documentPathSas = SasHelper.addSasKey(this.props.selectedDocument.documentPath, this.props.sasToken);

        if (this.props.selectedDocument.type === 1)
        {
            return <img className="responsive-dialog-document fit-image" src={documentPathSas} alt={this.props.selectedDocument.name}/>;
        }
        else if (this.props.selectedDocument.type === 2) {
            let type = documentPath.split(".")[documentPath.split(".").length - 1];

            if (type.includes("?sv=")) {
                type = type.split("?sv=")[0];
            }
            return <video controls className="responsive-dialog-document fit-image" name="media">
                <source src={documentPathSas} type={"video/" + type} />
             Your browser does not support HTML5 video.
            </video>
        } else if (this.props.selectedDocument.type === 4 || this.props.selectedDocument.type === 3) {
            return <iframe src={"https://docs.google.com/viewer?url=" + encodeURIComponent(documentPathSas) + "&embedded=true"}
                className="responsive-dialog-document fit-image" frameBorder="0"
            ></iframe>
        }
        return <div>File type can't resolved, please download document.</div>
    }

    render() {
        return this.props.openDialogPreview ?
            (
                <DialogModal
                    fullScreen={true}
                    content={<div>{this.props.content || this.getContent()}</div>}
                    customStyle={{ backgroundColor: "black", color: "#fff" }}
                    handleClose={() => this.props.handleClose && this.props.handleClose()}
                    id={this.props.id}
                    open={this.props.openDialogPreview}
                    buttonsVisible="false"
                    title={<div style={{ fontSize: 14 }} >
                        If you cannot preview the file, please <a href={SasHelper.addSasKey(this.props.selectedDocument.documentPath, this.props.sasToken)} target="_blank"> download</a>.
                        <br />
                        {this.props.showLocation ?
                            <div>
                                <b>Latitude:</b> {this.getLatitude()}
                                <b>Longitude:</b> {this.getLongitude()}
                            </div>
                            : null
                        }
                        {this.props.selectedDocument.uploadDate && <div><b>Upload Date: </b>{DateUtils.toDateString(this.props.selectedDocument.uploadDate, "YYYY-MM-DD HH:mm A")}</div>}
                    </div>
                    }
                >
                </DialogModal>
            )
            :
            null
    }
}


const mapStateToProps = state => ({ sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentDialogModal);