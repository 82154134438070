import React, { useEffect, useState } from "react";
import constants from "../../../utils/constants";
import AssignedSessions from "./assignedSessions";
import InspectorsList from "./inspectorsList";
import EuiMultiPanelTile from "../../../eui-components/multipaneltile";
import EuiRow from "../../../eui-components/row";

const QAInspectionData = () => {
    const leftPanel = {
        title: "RSC QA Inspectors", content: <InspectorsList mod={constants.userRoles.RSC_SITE_SUPERVISOR} />,
        style: { width: "400px" }
    };
    const midPanel = {
        title: <div className="flex-start-content "><div className="mr-2" style={{  color: "gray", fontWeight: 100 }}>
            Assigned QA Activities 
        </div> </div>,
        content: <AssignedSessions mod={constants.userRoles.RSC_SITE_SUPERVISOR} />,
    };
    return (
        <EuiRow >
            <EuiMultiPanelTile id="qaInspectorDashbordMultiPanel"
                midPanelButton={true}
                leftPanel={leftPanel}
                midPanel={midPanel}
                leftPanelStyle={{ width: "400px" }}
            />
        </EuiRow>
        )
}
export default QAInspectionData;