import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {
  AddOutlined,
  BlockOutlined,
  DeleteOutlined,
  EditOutlined,
  GetAppOutlined,
  PublishOutlined,
  TocOutlined,
  TurnedInNotOutlined,
} from '@material-ui/icons';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import DateUtils from '../../utils/date-utils';
import TableCard from '../../components/table/TableCard';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '16px 16px',
  },
  timeLineContent: {
    flex: 0.1,
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  timelineDot: {
    color: '#616161',
    backgroundColor: '#fff',
  },
}));

export default function OperationTimeline(props) {
  const classes = useStyles();
  const { data } = props;

  const [oldValues, setOldValues] = useState([]);
  const [newValues, setNewValues] = useState([]);
  const [oldObjectCreationDate, setOldObjectCreationDate] = useState('');

  useEffect(() => {
    switch (data[0]?.logAction) {
      // cases for one element
      case 'CREATED':
      case 'DELETED':
    
        Array.isArray(JSON.parse(data[0].logDetail).data)
          ? setNewValues(JSON.parse(data[0].logDetail).data)
          : setNewValues([JSON.parse(data[0].logDetail).data]);
        break;
      case 'UPDATED':
      case 'APPROVED':
      case 'REJECTED':
      case 'SUBMITTED':
      case 'RETIRED':

        
        setOldObjectCreationDate(JSON.parse(data[0].logDetail).oldDataDate);
        setOldValues([JSON.parse(data[0].logDetail).oldData]);
        setNewValues([JSON.parse(data[0].logDetail).newData]);
        break;
  
      // cases for array of elements
      case 'IMPORTED':
      case 'EXPORTED':
      // eslint-disable-next-line no-duplicate-case
      // case 'REJECTED':
      //   setNewValues(JSON.parse(data[0].logDetail).data);
        break;
      default:
        break;
    }
  }, [data[0].logAction]);

  const getIcon = (operation) => {
    switch (operation) {
      case 'CREATED':
        return <AddOutlined />;
        case 'UPDATED':
          case 'RETIRED':

            return <EditOutlined />;
            case 'APPROVED':
                return <ThumbUpAltOutlinedIcon />;
      case 'REJECTED':
        return <BlockOutlined />;
      case 'DELETED':
        return <DeleteOutlined />;
      case 'EXPORTED':
        return <GetAppOutlined />;
      case 'IMPORTED':
        return <PublishOutlined />;
      default:
        return <TurnedInNotOutlined />;
    }
  };

  return (
    <Timeline>
      {(() => {
        switch (data[0].logAction) {
            case 'UPDATED':
            case 'APPROVED':
            case 'REJECTED':
            case 'SUBMITTED':
              case 'RETIRED':

              
            return (
              <>
                <TimelineItem>
                  <TimelineOppositeContent className={classes.timeLineContent}>
                    <Typography variant="body2" color="textSecondary">
                    {  
                      DateUtils.toDateString(oldObjectCreationDate, "YYYY-MM-DD HH:mm")
                     }
                    
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot className={classes.timelineDot}>
                      <TocOutlined />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                      <Chip
                        color="primary"
                        label={
                          <Typography variant="body2" component="h1">
                            {"old Data"}
                          </Typography>
                        }
                      />
                      <Divider style={{ margin: '16px' }} />
                      <TableCard data={oldValues} />
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
                <TimelineItem>
                  <TimelineOppositeContent className={classes.timeLineContent}>
                    <Typography variant="body2" color="textSecondary">
                    {
                        DateUtils.toDateString(data[0].logDateTimeUTC, "YYYY-MM-DD HH:mm")

                    }
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot className={classes.timelineDot}>
                      <EditOutlined />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                      <Chip
                        color="primary"
                        label={
                          <Typography variant="body2" component="h1">
                            {"new Data"}
                          </Typography>
                        }
                      />
                      <Divider style={{ margin: '16px' }} />
                      <TableCard data={newValues} />
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              </>
            );
          case 'CREATED':
          case 'IMPORTED':
          case 'EXPORTED':
            return (
              <TimelineItem>
                <TimelineOppositeContent className={classes.timeLineContent}>
                  <Typography variant="body2" color="textSecondary">
                    {  DateUtils.toDateString(data[0].logDateTimeUTC, "YYYY-MM-DD HH:mm") }
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot className={classes.timelineDot}>
                    {getIcon(data[0].logAction)}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Chip
                      color="primary"
                      label={
                        <Typography variant="body2" component="h1">
                          {"Data"}
                        </Typography>
                      }
                    />
                    <Divider style={{ margin: '16px' }} />
                    <TableCard data={newValues} />
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            );
          default:
            return (
              <TimelineItem>
                <TimelineOppositeContent className={classes.timeLineContent}>
                  <Typography variant="body2" color="textSecondary">
               {     DateUtils.toDateString(data[0].logDateTimeUTC, "YYYY-MM-DD HH:mm")

                    }
                  </Typography>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot className={classes.timelineDot}>
                    {getIcon(data[0].logAction)}
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Paper elevation={3} className={classes.paper}>
                    <Chip
                      color="primary"
                      label={
                        <Typography variant="body2" component="h1">
                          {data[0].logDetail}
                        </Typography>
                      }
                    />
                    <Divider style={{ margin: '16px' }} />
                    <Typography variant="body2" component="h1">
                      { data[0].logDetail}
                      
                    </Typography>
                  </Paper>
                </TimelineContent>
              </TimelineItem>
            );
        }
      })()}
    </Timeline>
  );
}
