import React, { useState, useRef } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import EuiTextArea from "../../eui-components/textarea";
import PageHelper from "../../utils/page-helper";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import ResolutionReducer from "./resolutionReducer";
import constants from "../../utils/constants";
import EuiHeader from "../../eui-components/header";
import { useReducer } from "react";
import { useEffect } from "react";
import ApiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import EuiLoading from "../../eui-components/loading";


const EhsPingViolationResolution = (props) => {

    const userData = useSelector(state => state.user.userData);
    const ehsPing = useSelector(state => state.ehsPing.ehsPing);
    const [resolutionReducer, dispatch] = useReducer(ResolutionReducer, {
        violatedItems: ehsPing.ehsRatings.filter(r => r.violation),
        selectedRow: { id: 0 },
        comment: ehsPing.resolutionComments,
    });

    const [isLoading, setIsLoading] = useState(false);
    const [tableRefresh, toggleTableRefresh] = useState(false);
    const [ratingColumns, setRatingColumns] = useState([
        {
            title: "Violation ID",
            key: "ohsItemNo",
            width: "10%",
        },
        {
            title: "EHS Category",
            key: "subCategory",
            width: "10%",
        },
        {
            title: "Check Points",
            key: "checkPoints",
            width: "30%",
        },
        {
            title: "Severity Level",
            key: "isCritical",
            onCreatedCell: (td, cellData) => {
                const isCritical = cellData ? "Critical" : "Not Critical"
                const idLink = `<div>${isCritical}</div>`;
                td.innerHTML = idLink;
            },
            width: "10%",
        },
        {
            title: "Violation Reason",
            key: "additionalComments",
            width: "30%",
        },
        {
            title: "Resolution Info",
            key: "resolvedDateAndUser",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = `<span class="tooltip"><i class="icon icon-info"></i><span class="message right">${cellData.resolvedUserName ?? ""}-${DateUtils.toDateString(cellData.resolvedDate, "YYYY-MM-DD HH:mm")}</span></span>`
            },
            width: "8%",
        },
        {
            title: "",
            key: "resolutionId",
            width: "2%",
        },
    ]);

    const reduxDispatch = useDispatch();

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        handleChange();
    }, [resolutionReducer.selectedRow]);

    const renderRow = (tr, rowData) => {
        if (isViewOnly()) {
            tr.getElementsByTagName("td")[6].innerHTML = "";
            if (ehsPing.resolution) {
                tr.style.background = "#E6F1EB";
            } else {
                if (rowData.resolution) {
                    tr.style.background = "#E6F1EB";
                } else {
                    tr.style.background = "#F9E2E1";
                }
            }
            return;
        }
        if (rowData.resolution) {
            tr.style.background = "#E6F1EB";
            tr.getElementsByTagName("td")[6].innerHTML = `<span class="tooltip">
                                              <i id="unresolve_id_${rowData.id}" style={font-weight:500px;} class="icon icon-failed color-red icon-lg clickable"></i>
                                                  <span class="message top-end">Un-Resolve Item</span></span>`;;
            tr.getElementsByTagName("td")[6].querySelector("#unresolve_id_" + rowData.id).addEventListener('click',
                () => { dispatch({ type: "SET_SELECTED_ROW", payload: { ...rowData, resolution: false, resolvedDate: null, resolvedUserName: null, resolvedUserId: null } }); });
        } else {
            tr.style.background = "#F9E2E1";
            tr.getElementsByTagName("td")[6].innerHTML = `<span class="tooltip">
                                              <i id="resolve_id_${rowData.id}" style={font-weight:500px;} class="icon icon-success color-green icon-lg clickable"></i>
                                                  <span class="message top-end">Resolve Item</span></span>`;;
            tr.getElementsByTagName("td")[6].querySelector("#resolve_id_" + rowData.id).addEventListener('click',
                () => { dispatch({ type: "SET_SELECTED_ROW", payload: { ...rowData, resolution: true, resolvedDate: new Date(), resolvedUserName: userData.username, resolvedUserId: parseInt(userData.userId) } }); });
        }
    }

    const handleChange = () => {
        const index = resolutionReducer.violatedItems.findIndex(r => r.id === resolutionReducer.selectedRow.id);
        const items = [...resolutionReducer.violatedItems];
        
        if (index != -1) {
            items[index] = { ...resolutionReducer.selectedRow };
            
            dispatch({ type: "SET_VIOLATED_ITEMS", payload: items });
            toggleTableRefresh(!tableRefresh);
        }

    }

    const saveOrResolve = (resolution) => {
        const request = {
            ehsPingId: ehsPing.id,
            resolution,
            resolutionComments: resolutionReducer.comment,
            violatedRatings: resolutionReducer.violatedItems,
            userId: parseInt(userData.userId),
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude
        };
        
        setIsLoading(true);
        ApiClient.post("Workplan/ResolveEhsViolation", request).then(response => {
            if (response.data) {
                if (resolution) {
                    toast.success("EHS Ping Violations has been cleared successfully.", { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                } else {
                    toast.success(constants.processPozitiveMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                }
                const newEhsPing = { ...ehsPing, resolution, resolvedData: new Date(), resolvedUser: userData.username, resolvedUserId: userData.userId, resolutionComments: resolutionReducer.comment };
                newEhsPing.ehsRatings.map(v => {
                    const index = resolutionReducer.violatedItems.findIndex(i => i.id === v.id);
                    if (index > -1) {
                        v.resolution = resolutionReducer.violatedItems[index].resolution;
                        v.resolvedDate = resolutionReducer.violatedItems[index].resolvedDate;
                        v.resolvedUserName = resolutionReducer.violatedItems[index].resolvedUserName;
                        v.resolvedUserId = resolutionReducer.violatedItems[index].resolvedUserId;
                    }
                });
                
                reduxDispatch({ type: "SET_EHS_PING", payload: newEhsPing });
                toggleTableRefresh(!tableRefresh);
            } else {
                toast.error(constants.errorMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
            }
            setIsLoading(false);
        }).catch(error => {
            toast.error(constants.errorMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
            console.error(error);;
            setIsLoading(false);
        });
    }

    const isViewOnly = () => {
        return ehsPing.resolution || userData.jobRole != constants.userRoles.RSC_OHS_INSPECTOR;
    }

    const resolveAll = () => {
        const items = [...resolutionReducer.violatedItems];
        items.map(item => {
            item.resolution= true;
            item.resolvedDate= new Date();
            item.resolvedUserName= userData.username;
            item.resolvedUserId= parseInt(userData.userId);
        });
        
        dispatch({ type: "SET_VIOLATED_ITEMS", payload: items });
        toggleTableRefresh(!tableRefresh);
    }

    return <EuiRow key={tableRefresh} className={" p-1 " + (isLoading ? "disabled" : "")}>
        <EuiLoading isLoading={isLoading}></EuiLoading>
        <EuiHeader id="ehsViolationHeaderId"
            style={{ height: 110, alignItems: "unset" }}
            title="Resolution Comments: "
            titleStyle={{ fontSize: 14, marginLeft: 0 }}
            detail={<EuiTextArea value={resolutionReducer.comment}
                style={{ width: "100%", height: 100 }}
                divStyle={{ width: "100%" }}
                onChange={(e) => dispatch({ type: "SET_COMMENT", payload: e.target.value })}
                disabled={isViewOnly()}
            ></EuiTextArea>}
            rightActions={isViewOnly() ? <div>
                <EuiTextField id="resolvedUserViolation"
                    name="resolvedUser"
                    label="Resolved By User : "
                    value={ehsPing.resolvedUser || "---"}
                    readOnly
                    labelClass="left"
                    labelstyle={{ fontWeight: 500, }}
                ></EuiTextField>
                <EuiTextField id="resolvedDateViolation"
                    name="resolvedDate"
                    label="Resolved Date : "
                    value={DateUtils.toDateString(ehsPing.resolvedDate, "YYYY-MM-DD HH:mm")}
                    readOnly
                    labelClass="left"
                    labelstyle={{ fontWeight: 500, }}
                    style={{ marginRight: 25 }}
                ></EuiTextField>
            </div> :
                <div>
                    {resolutionReducer.violatedItems.filter(i => i.violation).length === 0 || resolutionReducer.violatedItems.some(i => !i.resolution)
                        ? <button className="btn primary p-1" onClick={() => saveOrResolve(false)}><i className="icon icon-save p-1"></i>Save</button>
                        : <button className="btn primary p-1" onClick={() => saveOrResolve(true)}><i className="icon icon-check p-1"></i>Resolve&Send Email</button>
                    }
                </div>}>
        </EuiHeader>
        <ObseleteEuiTable
            actionButton={<button className="btn primary" onClick={() => resolveAll()}>Resolve All</button>}
            classes="tiny"
            columns={ratingColumns}
            data={resolutionReducer.violatedItems}
            hideSearch={true}
            id={"ehsViolatedRatingsTable"}
            onCreatedRow={(tr, rowData) => renderRow(tr, rowData)}
            viewActionButton={!isViewOnly()}
        >
        </ObseleteEuiTable>
    </EuiRow>;

}

export default EhsPingViolationResolution;