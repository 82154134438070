import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import constants from "../../utils/constants";
import EuiTile from "../../eui-components/tile";
import EuiLoading from "../../eui-components/loading";
import EuiTooltip from "../../eui-components/tooltip";
import ApiClient from "../../utils/api-client";
import UserAdminHelper from "../../utils/user-admin-helper";
import EuiSwitch from "../../eui-components/switch";
import { toast } from "react-toastify";

const UserList = (props) => {

    const [userColumns, setColumns] = useState([
        {
            title: "ID",
            key: "id",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const idLink = `<a id="userAdminid_${cellData}">${cellData}</a>`;
                    td.innerHTML = idLink;
                    td.querySelector("#userAdminid_" + cellData).addEventListener('click', () => openUserDetail(cellData, constants.pageMods.VIEW_ONLY))
                }
            },
            width: "10%",
        },
        {
            title: "Name",
            key: "name",
            sort: "none",
            width: "20%"
        },
        {
            title: "Email",
            key: "email",
            sort: "none",
            width: "20%"
        },
        {
            title: "Signum",
            key: "signum",
            sort: "none",
            width: "10%"
        },
        {
            title: "Status",
            key: "active",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                const userStatus = UserAdminHelper.getUserStatus(cellData);
                td.innerHTML = userStatus;
            },
            width: "10%",
            notSearchable: true
        },
        {
            title: "Roles",
            key: "roles",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const roles = cellData.map((item, index) => {
                        const name = UserAdminHelper.getUserRoleName(item);
                        if (index === 0) {
                            return `<kbd class="tag big green" style="margin:5px;"><i class="icon icon-favorite"></i><span>${name}</span></kbd>`;
                        } else {
                            return `<kbd class="tag big " style="margin:5px;"></i><span>${name}</span></kbd>`;
                        }
                        return null;
                    });
                    td.innerHTML = `${roles.filter(r => r)}`;
                }
            },
            notSearchable: true
        },
        {
            title: "",
            key: "editId",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const action = `<i id="user_edit_${cellData}" class="icon icon-edit icon-frame clickable p-1 text-lg"></i>`
                    td.innerHTML = `${action}`;
                    td.querySelector("#user_edit_" + cellData).addEventListener('click', () => openUserDetail(cellData, constants.pageMods.EDIT))
                }
            },
            notSearchable: true,
            width: "5%"
        },
    ]);
    const [isLoading, changeIsLoading] = useState(false);
    const [showOnlyActiveUsers, changeFilter] = useState(false);

    const userList = useSelector(state => state.userAdmin.userList);
    const dispatch = useDispatch();

    useEffect(() => {
        if ((userList ?? []).length === 0) {
            props.getUserList();
        }
    }, []);

    useEffect(() => {
        changeIsLoading(false);
    }, [showOnlyActiveUsers])

    const openUserDetail = (userId, mod) => {
        changeIsLoading(true);
        ApiClient.post("UserAdmin/GetUserDetails", { id: userId })
            .then(response => {
                if (response.data) {
                    changeIsLoading(false);
                    const user = response.data;
                    UserAdminHelper.mapUser(user);
                    dispatch({ type: "SET_CURRENT_USER", payload: user });
                    dispatch({ type: "CHANGE_USER_ADMIN_MOD", payload: mod });
                }
            }).catch(error => {
                changeIsLoading(false);
                toast.error(constants.errorMessage, { containerId: "UA", position: toast.POSITION.TOP_CENTER });
                console.error(error);;
            });
    }

    const openAddUser = () => {
        const emptyUser = { id: 0, roles: [], active: true };
        UserAdminHelper.mapUser(emptyUser);
        dispatch({ type: "SET_CURRENT_USER", payload: emptyUser });
        dispatch({ type: "CHANGE_USER_ADMIN_MOD", payload: constants.pageMods.ADD });
    }

    const getTableActions = () => {
        return <>
            <EuiTooltip title="Add User">
                <i className="icon icon-plus icon-lg icon-frame m-1" onClick={openAddUser}></i>
            </EuiTooltip>
            <EuiTooltip title="Refresh">
                <i className="icon icon-reload icon-lg icon-frame m-1" onClick={() => props.getUserList(false)}></i>
            </EuiTooltip>
        </>;
    }

    const userData = showOnlyActiveUsers ? userList.filter(item => item.active) : userList;

    const excelData = userData?.map(u => ({
        ID: u.id,
        Name: u.name,
        Email: u.email,
        Signum: u.signum,
        Status: u.Status ? "Active" : "Not Active",
        Roles: u.roles?.map(r => UserAdminHelper.getUserRoleName(r))?.reduce((a, b) => a.concat(b + " , "), "")
    }));

    return (
        <EuiTile tileClassName={(props.isLoading || isLoading) ? "disabled" : ""} fullscreen key={showOnlyActiveUsers}>
            <EuiLoading id="userListLoading" isLoading={props.isLoading || isLoading} />
            <ObseleteEuiTable
                classes="tiny split-striped"
                columns={userColumns}
                data={userData}
                exportExcel={true}
                excelFileName={"User_List" + new Date().toISOString()}
                Reportdata={excelData}
                id="usersListTable"
                viewActionButton={true}
                actionButton={getTableActions()}
                sortable={true}
                paginated={true}
                rowsPerPage={10}
                search={true}
                placeholder="Search user..."
                searchStyle={{ width: 300 }}
                viewActionIcon={true}
                actionIcon={
                    <span className="ml-2">
                        <EuiSwitch
                            id="filterUsersSwitch"
                            onChange={() => { changeIsLoading(true); changeFilter(!showOnlyActiveUsers); }}
                            checked={showOnlyActiveUsers}
                            dataEnabled="Active Users"
                            dataDisabled="All Users"
                            name="usersFiltered"
                        />
                    </span>
                }
            >
            </ObseleteEuiTable>
        </EuiTile>
    );

}

export default UserList;