import React from "react";

export default class EuiKpi extends React.Component {

    render() {
        return (
            <div className="kpi" style={this.props.barLabelStyle}>
                <div className="color-gray">{this.props.label}</div>
                <div className="item">
                    <span className={"text-xl " + (this.props.total ? "" : "hidden")}>{this.props.itemNumber}</span>
                    {(this.props.total ? <span className="item text-lg" >/{this.props.total}</span> : null)} 
                    <span className="text-lg color-gray">{this.props.unit}</span>
                </div>
                <div className={"progress-bar " + this.props.color} style={this.props.style}>
                    <progress style={{width:"156px"}} value={this.props.value} min="0" max={this.props.max} onClick={this.props.onClick}></progress>
                    <span style={{ fontSize:"14px", width:"140px" }}>{this.props.value}{this.props.type}</span>
                </div>
            </div>
        );
    }
}
