import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTile from "../../eui-components/tile";
import DialogModal from "../../components/dialog";
import ApiClient from "../../utils/api-client";
import CommonHelper from "../../utils/common-helper";
import { isEmpty, remove } from "lodash";
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import ReportList from './ReportList.js';
import { useSelector, useDispatch } from "react-redux";






const MyReportsTable = (aa) => {
    const [isLoading, setIsLoading] = useState(false);
    const userData = useSelector(state => state.user);
    const [openDialog, setOpenDialog] = useState(false);
    const [reports, setReports] = useState([])


    const dispatch = useDispatch();
    useEffect(() => {
        GetReports()
       
    }, [])
    const midPanel = {
        content: <ReportList />
        };
    const GetReports = () => {
        setIsLoading(true)
        ApiClient.post("ReportBuilder/GetMyReports", { UserId: parseInt(userData.userData.id) }).then(response => {
            setIsLoading(false);
            setReports(response.data)
        }).catch(error => {
            console.error(error);
            setIsLoading(false);
            setReports([])
        });
    }
  
    return (
        <EuiRow disabled={isLoading ? "disabled" : ""} key={isLoading}>
            <ReportList id="ReportPanel" reports={reports} isLoading={isLoading} setReports={setReports} midPanel={midPanel} style={{ minHeight: 610 }} />
       </EuiRow >
    );
};
export default withRouter(MyReportsTable);
