import React, { useState, useCallback, useEffect, useRef } from "react";
import EuiRow from "../../eui-components/row";
import EuiHeader from "../../eui-components/header";
import constants from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";

import ApiClient from "../../utils/api-client";
import EuiLoading from "../../eui-components/loading";
import PageHelper from "../../utils/page-helper";
import CommonHelper from "../../utils/common-helper";
import { useHistory } from "react-router-dom";
import VehicleContent from "../asp-companies/vehicle-management/vehicleContent";
import VehicleApprovalDetailsContent from "./VehicleApprovalDetailsContent";
import DateUtils from "../../utils/date-utils";
import VehicleHelper from "../../utils/vehicle-helper";
import DialogModal from "../../components/dialog";
import { Logs } from "../../utils/Log";
import EuiTextField from "../../eui-components/textfield";

const statusComments = {
    5: { comment: "Inactivate Vehicle", buttonType: "warning" },
    2: { comment: "Send Vehicle to Ericsson Review", buttonType: "primary" },
    3: { comment: "Approve Vehicle", buttonType: "primary" },
    4: { comment: "Reject Vehicle", buttonType: "warning" },
    1: { comment: "Revise Vehicle", buttonType: "primary" }
}

// Approve, Reject, no save and finsih later
const VehicleApprovalDetails = (props) => {
const {getAllVehicleUnderReview}=props;
const [statusDialog, openStatusDialog] = useState({ id: 0, open: false, type: VehicleHelper.vehicleStatusLookup['Under Review'] });
const [modifiedUserComment, setModifiedUserComment] = useState("");

const [warningDialog, setWarningDialog] = useState(false);

    const [isLoading, setIsLoading] = useState(false) ;
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.userData);
    const currentVehicle = useSelector(state => state.vehicle.currentVehicle);

    const changeVehicleStatus = useCallback((comment,type) => {
       
        setIsLoading(true);
  
        const request = {
            id: currentVehicle.id,
            vehicleStatus: type,
            modifiedUserid: parseInt(user.id),// sme user id
            vehicleApprovedUserid:parseInt(user.id),
            approvedDatetimeUtc:DateUtils.toUtcDate(new Date()),
            modifiedUserComment:comment
      
         }
         
      
        ApiClient.post("Vehicle/ApproveRejectVehicle", request, {})
        .then(response => {
            if (response?.data) {
                createLog(request);
                openStatusDialog({ id: 0, open: false, type: VehicleHelper.vehicleStatusLookup['Under Review'] });
                CommonHelper.createNotification({ title: "Vehicle Status is updated successfully!", icon: "icon-check", timeout: 5000 });
                dispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.LIST });
                getAllVehicleUnderReview();
            } 
                         setIsLoading(false);
                         setWarningDialog(false);
                      

        }).catch(error => {
            console.error(error);;
            setIsLoading(false);
            CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
        })
    }, [currentVehicle]);
    const createLog= (request) =>{
              const newData={};
              const oldData={};
              const logInfo={};
              let visitor = CommonHelper.getVisitorData();

              logInfo.LogName="Vehicle";
              logInfo.userId=parseInt(user.userId);
              logInfo.LogObjectId=currentVehicle.id;// id of vehicle
              logInfo.LogAction=request.vehicleStatus === VehicleHelper.vehicleStatusLookup['Approved'] ? VehicleHelper.vehicleAction.APPROVED:VehicleHelper.vehicleAction.REJECTED;
              logInfo.LogTypeId=VehicleHelper.LogTypeId['VEHICLE'];
            logInfo.latitude = Number(visitor.latitude).toString();
            logInfo.longitude = Number(visitor.longitude).toString();
              // new data is
              if(request.vehicleStatus === VehicleHelper.vehicleStatusLookup['Approved']){
                newData.vehicleStatus=request.vehicleStatus;
                newData.approvedDatetimeUtc=DateUtils.toUtcDate(new Date());
              }
              else{
                newData.vehicleStatus=request.vehicleStatus;
                newData.rejectedDatetimeUtc=DateUtils.toUtcDate(new Date());
                newData.UserComment=request.modifiedUserComment;
              }
             
             
          // old data is
              oldData.vehicleStatus=currentVehicle.vehicleStatus;
              oldData.submitDatetimeUtc=currentVehicle.submitDatetimeUtc;
              Logs(logInfo,{
                oldData,
                oldDataDate:currentVehicle.createDatetimeUtc,
                newData,  
             })
     };
useEffect(() => {
 
}, [modifiedUserComment])

    const checkIsVehicleCompliant= () => {
        if( currentVehicle.fittedWithSeatBelt===1&&
            currentVehicle.fittedWithHeadRests===1&&
            currentVehicle.fittedWithApprTyres===1&&
            currentVehicle.emergencyEqptTriAngles===1&&
            currentVehicle.emergencyEqpt25Fe ===1&&
            currentVehicle.emergencyEqptFaKit===1&&
            currentVehicle.emergencyEqptHighReflectorVest===1&&
            currentVehicle.gpsInstalled===1&&
            currentVehicle.gpsLiveTracking ===1){
            currentVehicle.isVehicleCompliant="Compliant"
            setWarningDialog(false);
            changeVehicleStatus(modifiedUserComment,VehicleHelper.vehicleStatusLookup['Approved'])
            }else{
                setWarningDialog(true);
                currentVehicle.isVehicleCompliant="Non-Compliant"
           return false; 
            }
     }
    const getRightActions = () => {
        return (
            <div>
                <button className={"btn p-1"}
                    onClick={() => dispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.LIST}) }>
                    <i className="icon icon-arrow-left p-1"></i>
                    Back
                </button>
                <button className={"btn primary p-1"}  
                    onClick={checkIsVehicleCompliant}
                    disabled={isLoading}
                >
                    <i className="icon icon-success p-1"></i>Approve</button>
                <button className={"btn warning p-1"}      
                    disabled={isLoading}
                    onClick={() => openStatusDialog({ id: currentVehicle.id, open: true, type: VehicleHelper.vehicleStatusLookup['Rejected'] })}

                    >
                    <i className="icon icon-failed p-1"></i>Reject</button>
        </div>)
    }
 
    return (<><EuiRow key={isLoading} disabled={isLoading ? "disbaled" : ""}>
        <EuiLoading id="inspectionInboxLoading" isLoading={isLoading} />
        <EuiHeader id="inspectionHeaderId"
            title={currentVehicle.id + " - " + currentVehicle.chassisNumber}
            rightActions={getRightActions()}>
        </EuiHeader>
        {isLoading ?
            <></> :
            <VehicleApprovalDetailsContent  setWarningDialog={setWarningDialog}   />}

    </EuiRow>
    <DialogModal
            id="confirmSendToEhsSME"
            title="vehicle is non-compliant!"
            content="Confirm approve vehicle is non-compliant!"
            buttonName="Confirm"
            handleClose={() => setWarningDialog(false)}
            onClick={() =>{changeVehicleStatus(modifiedUserComment,VehicleHelper.vehicleStatusLookup['Approved'])}}
            mainButtonStyle={{ backgroundColor: "red", borderColor: "red" }}
            open={warningDialog} />

     <DialogModal
                buttonName="Confirm"
                title={statusComments[statusDialog.type]?.comment}
                content={<><p>{"Are you sure that you want to " + statusComments[statusDialog.type]?.comment + "?"}</p>
                    <EuiTextField className="p-1"
                        fullwidth
                        required
                        multiLine={true}
                        id="vehicleUserCommentDialogId"
                        name="modifiedUserComment"
                        label="Comment"
                        value={modifiedUserComment}
                        onChange={(e) => setModifiedUserComment(e.target.value)}
                    />
                </>}
                handleClose={() => openStatusDialog({ id: 0, open: false, type: VehicleHelper.vehicleStatusLookup['Rejected'] })}
                id="vehicleSDialogId"
                onClick={() => changeVehicleStatus(modifiedUserComment,VehicleHelper.vehicleStatusLookup['Rejected'])}
                open={statusDialog.open}
                type={statusComments[statusDialog.type]?.buttonType}
            />
            </>
    );

}
export default VehicleApprovalDetails;//  

        