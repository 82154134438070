import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTile from "../../eui-components/tile";
import DialogModal from "../../components/dialog";
import ApiClient from "../../utils/api-client";
import CommonHelper from "../../utils/common-helper";
import { isEmpty, remove } from "lodash";



const DeletionRequestsTable = (props) => {
    const { deleteRequestsData,setDeleteRequestsData } = props;
    const userData = useSelector(state => state.user);

    const Project = useSelector((state) => state.projects);
    const totalCount = Project.allProjects?.totalCount;

    const [isLoading, setIsLoading] = useState(false);

    const [requestId, setRequestId] = useState(0);

    const [warning, setOpenWarning] = useState(false);
    const [removedItem, setDeletedItem] = useState(null);
    const [confirm, setConfirm] = useState(false);

    const projectColumns = [
        {
            title: "Request Id",
            key: "id",
            sort: "asc",
            width: "3%",
        },
        {
            title: "Project Id",
            key: "projectId",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                if (cellData === 0) {
                    td.innerHTML = "workplan request"

                } else {
                    td.innerHTML = cellData

                }
            },
        },
        {
            title: "Project Name",
            key: "projectName",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                if (isEmpty(cellData)) {
                    td.innerHTML = ""

                } else {
                    td.innerHTML = cellData

                }
            },
        },

        {
            title: "WorkPlan Ids",
            key: "workPlanIds",
            sort: "none",
            width: "30%",
            onCreatedCell: (td, cellData) => {
                if (isEmpty(cellData)) {
                    td.innerHTML = "project request"
                } else {
                    td.innerHTML = cellData

                }
            },
        },
        {

            title: "WorkPlan Name",
            key: "workPlanNames",
            sort: "none",
            width: "30%",
            onCreatedCell: (td, cellData) => {
                if (isEmpty(cellData)) {
                    td.innerHTML = ""
                } else {
                    td.innerHTML = cellData

                }
            },
        },

        {
            title: "user id",
            key: "userId",
            sort: "none",
            width: "10%",
        },
        {
            title: "Email",
            key: "userEmail",
            sort: "none",
            width: "5%",
        },
        {
            title: "Request Date",
            key: "requestDate",
            sort: "none",
            width: "5%",
        },
        {
            title: "Action",
            key: "id",
            width: "2%",
            hideFilter: true,
            notSearchable: true,

            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var request = deleteRequestsData.find(p => p.id === cellData);
                  
                    td.innerHTML = `<span id="delete_${cellData}" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable ${request.pendingDelete && "disabled"}"></i><span class="message top-end ${request.pendingDelete && "disabled"}" ">Revoke Request</span></span>`;
                    td.querySelector(`#delete_${cellData}`).addEventListener("click", () => confirmDelete(cellData));

                }
            },
        }
    ];
    const confirmDelete = (requestId) => {
        setOpenWarning(true);

        // get tokenCode sent by email from api 
        setRequestId(requestId)
        setDeletedItem(requestId);
    };


    const RevokeDeleteAction = () => {
        if (confirm === true) {
            const request = {
                requestId: parseInt(requestId),
                userId: parseInt(userData.userData.id),

            }
            setIsLoading(true)
            ApiClient.post("Projects/RevokeDeleteAction", { ...request }).then(response => {
                if (response.data) {
                    setIsLoading(false)
                   const data = remove(deleteRequestsData, function(n) {
                        return n.id!==parseInt(requestId)
                    });
                    setDeleteRequestsData(data)
                    CommonHelper.createNotification({ title: "Success", description: "Request is Revoked successfully", icon: "check", timeout: 5000 });
                }
            
            }).catch(err => {
                CommonHelper.createNotification({ title: "Error", description: "An issue occurred while deleting the Request, please try again!", icon: "icon-triangle-warning", timeout: 5000 });
                setOpenWarning(false);
                setConfirm(false);
                setDeletedItem(null);
                setIsLoading(false)

            }
            );
        }
    };
    useEffect(() => {
        if (confirm === true) {
            RevokeDeleteAction();
            setConfirm(false);
            setOpenWarning(false);
        }
    }, [confirm]);


    return (
        <>
            <EuiTile fullscreen key={deleteRequestsData}>
                <EuiRow disabled={isLoading ? "disabled" : ""}>
                    <EuiLoading
                        id="projectsToDetailLoading"
                        isLoading={isLoading}
                    />
                    <ObseleteEuiTable
                        classes="tiny split-striped"
                        columns={projectColumns}
                        data={deleteRequestsData}
                        id="deletionRequestsTableId"
                        scroll={false}
                        sortable={true}
                        paginated={true}
                        totalCount={totalCount}
                        search={true}
                        rowsPerPage={10}
                        filter={true}
                        key={DeletionRequestsTable}
                        exportExcel={true}
                        viewActionButton={true}
                        excelFileName={"Projects_Excel" + new Date().toISOString()}
                        paginationItems={[15, 20, 30]}
                    />
                </EuiRow>

                <DialogModal
                    buttonName="Delete"
                    type="warning"
                    title={"Revoke request"}
                    content="Are you sure you want to revoke this request ?"
                    handleClose={() => setOpenWarning(false)}
                    mainButtonStyle={{ backgroundColor: "red" }}
                    id="alertDialog"
                    onClick={() => setConfirm(true)}
                    open={warning}
                    style={{ height: "50%" }}
                    contentStyle={{ width: "380px" }}
                    disableBackdropClick={true}
                />
            </EuiTile>
        </>
    );
};
export default withRouter(DeletionRequestsTable);
