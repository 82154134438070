import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import constants from '../../utils/constants';
import VehicleHelper from '../../utils/vehicle-helper';
import ObseleteEuiTable from '../../eui-components/obseleteTable';
import EuiRow from '../../eui-components/row';
import CreateEditInspection from './createNewInspection';
import CommonHelper from '../../utils/common-helper';
import ApiClient from '../../utils/api-client';
import EuiLoading from '../../eui-components/loading';
import DialogModal from '../../components/dialog';
import EuiTimeline from '../../eui-components/timeline';
import PageHelper from '../../utils/page-helper';
import moment from 'moment';
import { Logs } from '../../utils/Log';

const MonthlyInspectionList = (props) => {
    useEffect(() => {
        
        ApiClient.post("Vehicle/GetChecklistItems")
            .then(response => {
                dispatch({ type: "SET_INSPECTION_ITEMS_LIST", payload: response.data.checklistItems });
            })
            .catch(error => {
                console.error(error);;
                //changeIsLoading(false);
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
            });
    }, []);
    const dispatch = useDispatch();
    const vehicle =  useSelector(state => state.vehicle)
    const inspectionsList = useSelector(state => state.vehicle?.vehicleInspections) || [];

    const mod = useSelector(state => state.vehicle.inspectionMod);
    const users = useSelector(state => state.user.users);
    var inspection = useSelector(state => state.vehicleInspection);
    const user = useSelector(state => state.user);
    const [historyDialog, openHistoryDialog] = useState(false);
    const [inspectionHistoryState, setHistory] = useState({});
    const [id, setId] = useState();
    const [warningDialog, openWarningDialog] = useState(false);
    const columns = [
        {
            title: "Inspection ID",
            key: "id",
            sort: "desc",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const idLink = `<a id="inspectionid_${cellData}">${cellData}</a>`;
                    td.innerHTML = idLink;
                    td.querySelector("#inspectionid_" + cellData).addEventListener('click', () => openInspectionDetail(cellData, constants.pageMods.EDIT))
                }
            },
            width: "7%",
        },
        {
            title: "Name",
            key: "inspectionName",
            sort: "none",
            width: "10%"
        },
        {
            title: "Create User",
            key: "createUser",
            sort: "none",
            width: "10%"
        },
        {
            title: "Status",
            key: "statusId",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                
                const inspectionStatus = VehicleHelper.inspectionHTMLTableStatus[cellData];
                
                td.innerHTML = inspectionStatus;
            },
            width: "15%",
            notSearchable: true
        },
        {
            title: "Mandatory Items Left",
            key: "mandatoryCount",
            width: "15%",
            notSearchable: true
        },
        {
            title: "Valid Until",
            key: "id",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                console.log(cellData)
                var inspection = inspectionsList?.find(i => i.id === cellData);

                const inspectionStatus = VehicleHelper.inspectionHTMLTableValid(inspection);
                
                td.innerHTML = inspectionStatus;
            },
            width: "15%",
            notSearchable: true
        },
        {
            title: "",
            key: "sendToSme",
            notSearchable: true,
            sort: "none",
            width: "2%",
            onCreatedCell: (td, cellData) => {

                if (cellData) {
                   if (forbiddenStatuses(cellData) && VehicleHelper.approvedRoles.includes(user.userData.jobRole)) {
                        const action = `<span class="tooltip"><i id="inspection_send_${cellData.id}" class="icon icon-send icon-frame clickable p-1 text-lg"></i><span class="message top-end">Send Inspection To SME</span></span>`
                        td.innerHTML = `${action}`;
                        td.querySelector("#inspection_send_" + cellData.id).addEventListener('click', () => {
                            sendInspectionForApproval(cellData);
                        })
                    }
                   else
                        td.innerHTML = "";
                }
                else
                    td.innerHTML = "";
            },

        },
        /*{
            title: "",
            key: "deleteInspection",
            onCreatedCell: (td, cellData) => {
                    if (cellData && getInspectionStatus(cellData) == VehicleHelper.vehicleStatusLookup.Created) {
                        const action = `<span class="tooltip"><i id="inspection_delete_${cellData.id}" class="icon icon-trashcan icon-frame clickable p-1 text-lg"></i><span class="message top-end">Delete Inspection</span></span>`
                        td.innerHTML = `${action}`;
                    }

                else {
                    td.innerHTML = "";
                }
            },
            notSearchable: true,
            width: "2%"
        },*/
        // {
        //     title: "",
        //     key: "history",
        //     onCreatedCell: (td, cellData) => {
        //         if (cellData) {
                    
        //             const action = `<span class="tooltip"><i id="inspection_history_" class="icon icon-time icon-frame clickable p-1 text-lg"></i><span class="message top-end">History</span></span>`
        //             td.innerHTML = `${action}`;
        //             td.querySelector("#inspection_history_").addEventListener('click', () => openInspectionHistory(cellData))
        //         } else {
        //             td.innerHTML = null;
        //         }
        //     },
        //     notSearchable: true,
        //     width: "2%"
        // },

    ];

    const [isLoading, changeIsLoading] = useState(false);
    
    
    useEffect(() => {
        changeIsLoading(true);
        getVehiclesInspections();
    }, [mod]);

    //
     VehicleHelper.mapInspections(inspectionsList, users);

    const getInspectionStatus = (id) => {
        return inspectionsList.find(i => i.id === id)?.statusId;
    }
    const forbiddenStatuses = (status) => {
        var valid = true;
        var status = getInspectionStatus(status);
        if (status === VehicleHelper.vehicleStatusLookup['Under Review'] || status === VehicleHelper.vehicleStatusLookup["Approved"]) {
            valid = false;
        }
        return valid;
    }
 
    const checkEvidenceFiles = (id) => {
        return new Promise(resolve => {
            ApiClient.post("Vehicle/CheckIsIncludeEvidences",  { id } )
                .then(response => {
                 resolve(response.data);
                
                }).catch(error => {
                    CommonHelper.createNotification({ title: "An error occurred.", icon: "icon-cross", timeout: 5000 });
                    resolve(false)
                });
        })
    }


    const sendInspectionForApproval = async (id) => {
        var checkEvidences = await checkEvidenceFiles(id);
      
           if (!checkEvidences) {
                CommonHelper.createNotification({ title: "All Mandatory categories needs to be filled in order to submit the inspection!", icon: "icon-cross", timeout: 50000 });
                return;
            }
            if (warningDialog) {
                changeIsLoading(true);
                openWarningDialog(false);
                let visitor = CommonHelper.getVisitorData();
                let request = {
                    vehicleInspectionId: parseInt(id),
                    logInfo: {
                        userId: parseInt(user?.userData?.id),
                        logBrowser: visitor.vBrowser,
                        logIP: visitor.vIP,
                        logLocation: visitor.vCountry,
                        latitude: Number(visitor.latitude).toString(),
                        longitude: Number(visitor.longitude).toString(),
                        logDetail: "Submit Vehicle Inspection For Approval"
                    }
                }

              

                ApiClient.post("Vehicle/SendVehicleInspectionForApproval",request).then(response => {
                    changeVehicleInspectionsLog()
                    getVehiclesInspections();
                    CommonHelper.createNotification({ title: "Vehicle Inspection is sent for approval successfully!", icon: "icon-check", timeout: 5000 });
                    setId(null);
                }).catch(error => {
                    CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
                    setId(null);
                    changeIsLoading(false);
                })
            }
            else {
                openWarningDialog(true);
                setId(id);
        }
    }
   
    const openInspectionDetail = useCallback((inspectionId, mod) => {
        if (mod === constants.pageMods.EDIT) {
            const id = inspectionId;
            changeIsLoading(true);
            let currentInspection = { ...inspection.currentInspection };
            currentInspection.id = inspectionId;

            ApiClient.post("Vehicle/GetVehicleInspectionDetails", { id })
                .then(response => {
                    if (response.data) {
                        currentInspection.currentInspectionItems = response.data;
                        dispatch({ type: "SET_CURRENT_INSPECTION", payload: currentInspection });
                    } 
                    changeIsLoading(false);
                })
                .catch(error => {
                    changeIsLoading(false);
                    CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
                });
        }
        dispatch({ type: "CHANGE_INSPECTION_MOD", payload: mod });
        
    });
    const getVehiclesInspections = useCallback(() => {
        const id = vehicle?.currentVehicle?.id;
        ApiClient.post("Vehicle/GetListOfInspectionsPerVehicle", { id })
            .then(response => {
                dispatch({ type: "SET_VEHICLE_INSPECTIONS_LIST", payload: response.data });
                changeIsLoading(false);
            })
            .catch(error => {
                changeIsLoading(false);
                console.error(error);;
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
            });
    });
    const renderHistoryData = () => {
        
        return (<EuiRow className="flex-middle-content w-10">
            <EuiTimeline data={inspectionHistoryState} />
                 </EuiRow>
            )
    }
    const createVehicleInspectionsLog= (response) =>{

        const logInfo={};
        const data={};
        logInfo.LogName= "Inspection" 
        logInfo.userId=parseInt(user?.userData?.id);
        logInfo.LogObjectId=vehicle?.currentVehicle?.id;// id of vehicle
        logInfo.LogAction=VehicleHelper.vehicleAction.CREATED ;
        logInfo.LogTypeId=VehicleHelper.LogTypeId['VEHICLE'];
        data.Name='create Inspection'
        data.createDatetimeUtc=response.createDatetimeUtc;
          Logs(logInfo,{data})
};

const changeVehicleInspectionsLog= () =>{

 
    // logInfo.LogObjectId=vehicle?.currentVehicle?.id;// id of vehicle


      const newData={};
      const oldData={};
      const logInfo={};
      logInfo.LogName= "Inspection";
      logInfo.userId=parseInt(user?.userData?.id);
      logInfo.LogObjectId=vehicle?.currentVehicle?.id;// vehicle?.currentVehicle?.id
      logInfo.LogAction =VehicleHelper.vehicleAction.SUBMITTED//mod===1 ?  VehicleHelper.vehicleAction.SUBMITTED : VehicleHelper.vehicleAction.RETIRED ;
      logInfo.LogTypeId=VehicleHelper.LogTypeId['VEHICLE'];
      // old data
      oldData.StatusId=1;
      oldData.CreatedUserName=user.name;
      // new data
      newData.StatusId=mod;
      newData.submitedUserName=user.name;
      //  newData.Comment=modifiedUserComment;

      Logs(logInfo,{
          oldData,
          oldDataDate:new Date(),
          newData,  
       });


};
    const createNewInspection = () => {
        const request = {
            vehicleId: vehicle?.currentVehicle?.id,
            createUserId: parseInt(user?.userData?.id),
            inspectionName: vehicle?.currentVehicle?.chassisNumber+"_"+moment().format('YYYY-MM-DD-HH:m:s')  ,
            checklistItemIds: [],
      
        }    
         changeIsLoading(true);
        ApiClient.post("Vehicle/MonthlyInspectionWithChecklists", request)
            .then(response => {
                if (response.data.errorMessage) {
                    changeIsLoading(false);

                }
                else {
                    createVehicleInspectionsLog(response.data);
                    getVehiclesInspections();
                    dispatch({ type: "CHANGE_INSPECTION_MOD", payload: constants.pageMods.LIST });
                    changeIsLoading(false);
                }
            })
            .catch(error => {
                console.error(error);;
            });
    }
    const getTableActions = () => {
        return (
            <span className="w-10" style={{ marginRight: 'auto' }}>
                <i className="icon icon-reload icon-frame clickable ml-2 mr-0 fl-r text-lg"
                    style={{ padding: "7px" }}
                    id="refreshStatusData"
                    onClick={() => {
                        changeIsLoading(true);
                        getVehiclesInspections()
                    }
                    }> 
                </i>
                {/* <button hidden={!VehicleHelper.approvedRoles.includes(user.userData.jobRole)} className="primary btn" id="newInspectionButton" onClick={() => openInspectionDetail(null, constants.pageMods.ADD)}><i className="icon icon-plus" /> New Inspection</button> */}
                <button hidden={!VehicleHelper.approvedRoles.includes(user.userData.jobRole)} className="primary btn" id="newInspectionButton" onClick={createNewInspection}><i className="icon icon-plus" /> New Inspection</button>

            </span>);
    }
    const inspectionHistory = () => {
        return (
            <>
            <DialogModal
                    id="confirmSendToEhsSME"
                    title="Send Inspection For Approval"
                    content="Confirm submitting the inspection for approval!"
                    buttonName="Confirm"
                    handleClose={() => openWarningDialog(false)}
                    onClick={() => {
                        sendInspectionForApproval(id);
                    }
                    }
                    mainButtonStyle={{ backgroundColor: "red", borderColor: "red" }}
                    open={warningDialog}
                />
            <DialogModal
            id="inspectionHistoryDialog"
            style={{ height: "1000px" }}
            title="Inspection History"
            content={renderHistoryData()}
            buttonName="Ok"
            handleClose={() => openHistoryDialog(false)}
            onClick={() => openHistoryDialog(false)}
            contentStyle={{ width: "800px", height: "400px" }}
            open={historyDialog}
        /></>)

    }
    const renderMod = () => {
        if (mod === constants.pageMods.LIST) {
            return <EuiRow >
                <ObseleteEuiTable
                    key={inspectionsList}
                    classes="tiny split-striped"
                    columns={columns}
                    data={inspectionsList}
                    exportExcel={true}
                    excelFileName={"inspectione_List" + new Date().toISOString()}
                    Reportdata={inspectionsList}
                    id="inspectionListTable"
                    viewActionButton={true}
                    actionButton={getTableActions()}
                    sortable={true}
                    paginated={true}
                    rowsPerPage={10}
                    search={true}
                    placeholder="Search inspections..."
                    searchStyle={{ width: 300 }}
                    viewActionIcon={true}
                >
                </ObseleteEuiTable>
            </EuiRow>
                ;
        } else {
            return <CreateEditInspection />;// <CreateEditVehicle openStatusDialog={openStatusDialog} />;
        }
    }
    return (
        <EuiRow style={{ padding: 5, width: "100%" }} key={isLoading} disabled={isLoading ? "disabled" : ""}>
            <EuiLoading id="monthlyInspectionListLoading" isLoading={isLoading} />
            {renderMod()}
            {inspectionHistory()}
        </EuiRow>
    );
}

export default MonthlyInspectionList;