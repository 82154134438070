import React from 'react';
import { Table } from '@eds/vanilla/table/Table';
import { Notification } from '@eds/vanilla';
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import EuiSearchField from './searchfield';
import { connect } from 'react-redux';

class ObseleteEuiTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            prevData: this.props.data,
            input: "",
            update: true,
            searchValue: "",
        }
        // received array of items, loop into the values and create the paginated items 
    }

    componentDidMount() {
        const tableDOM = document.querySelector('#' + this.props.id);
        const Data = this.props.data;
        var columns = this.props.columns;
        this.setState({
            data: Data,
            prevData: Data,
        })

        //create the table
        const table = new Table(tableDOM, {
            data: Data,
            columns: columns,
            rowsPerPage: this.props.rowsPerPage,
            scroll: this.props.scroll,
            expandable: this.props.expandable,
            selectable: this.props.selectable,
            sortable: true,
            actions: this.props.hasActions,
            onCreatedHead: (th, headData) => {
                if (!this.props.onCreatedHead)
                    return;
                this.props.onCreatedHead(th, headData);
            },
            onCreatedRow: (tr, rowData) => {
                if (!this.props.onCreatedRow)
                    return;
                this.props.onCreatedRow(tr, rowData);
            },
            onCreatedBody: (tb, bodyData) => {
                if (!this.props.onCreatedBody)
                    return;
                this.props.onCreatedBody(tb, bodyData);
            },

            height: this.props.height,
            onCreatedActionsCell: (td) => {
                if (!this.props.actions) {
                    return;
                }
                this.props.actions.map(item => {
                    td.innerHTML = td.innerHTML + item.icon;
                    td.querySelector("#" + item.id).addEventListener('click', item.onClick)
                });
            },
            onEditedCell: () => this.props.editFunction && this.props.editFunction(),
        });

        table.init();

        if (this.props.exportExcel) {
            document.querySelector('#exportData').addEventListener('click', () => {
                const notification = new Notification({
                    title: 'Export data',
                    description: 'File will be downloaded in your browser',
                    timeout: 5000,
                    icon: 'icon-download-save'
                });
                notification.init();
                

            })
        }

        //filtering process
        if (this.props.filter) {
            columns.filter(c => !c.headerStyle?.includes("display:none")).forEach(column => {
                //enable the filter to work on change
                if (column.key == "status" && this.props.editStatus) {
                    //handle render and real data change when diffrent render
                    document.querySelector('#status' + "_" + this.props.id).addEventListener('input', (evt) => {
                        this.state.data.forEach(item => {
                            if (item.status === true || item.status === "Passed")
                                item.status = "Passed"
                            else
                                item.status = "Failed"
                        })

                        var flag = false;
                        columns.filter(c => !c.headerStyle?.includes("display:none")).forEach(c => {
                            const _in = document.querySelector('#' + c.key + "_" + this.props.id);

                            if (_in && _in.value) {
                                flag = true;
                                if (this.state.data.length != 0 && evt.target.value) {
                                    this.setState({
                                        data: this.state.data.filter(item => item[c.key].toString().toUpperCase().includes(_in.value.toUpperCase())),

                                    })
                                }
                                else {
                                    this.setState({
                                        data: this.props.data.filter(item => item[c.key].toString().toUpperCase().includes(_in.value.toUpperCase())),

                                    })
                                }

                            }
                        })
                        if (flag) table.update(this.state.data);
                        else {
                            table.update(this.props.data);
                            this.setState({ data: this.props.data })
                        }
                    })
                }
                else {
                    document.querySelector('#' + column.key + "_" + this.props.id).addEventListener('input', (evt) => {
                        
                        // if (evt.target.value != "") {
                        var flag = false;
                        var e = evt.target.value;
                        columns.filter(c => !c.headerStyle?.includes("display:none")).forEach(c => {
                            const _in = document.querySelector('#' + c.key + "_" + this.props.id);

                            if (_in && _in.value) {
                                flag = true;
                                if (this.state.data.length != 0 && evt.target.value && evt.target.value.length >= this.state.input.length) {
                                    var _data = this.state.data.filter(item => item[c.key] != null);
                                    this.setState({
                                        data: _data.filter(item => item[c.key].toString().toUpperCase().includes(_in.value.toUpperCase())),
                                    })
                                }
                                else {
                                    var _data = this.props.data.filter(item => item[c.key] != null);

                                    this.setState({
                                        data: _data.filter(item =>
                                            item[c.key].toString().toUpperCase().includes(_in.value.toUpperCase())
                                        )
                                        ,
                                        input: evt.target.value

                                    })
                                }

                            }
                        })
                        this.setState({
                            input: evt.target.value

                        })
                        if (flag) table.update(this.state.data);
                        else {
                            table.update(this.props.data);
                            this.setState({ data: this.props.data })
                        }

                    })
                }
            });
        }

        if (this.props.search) {
            const data = [...this.props.data];
            document.querySelector('#' + "search_" + this.props.id).addEventListener('input', (evt) => {
                const target = evt.target;
                const value = target.value;

                if (value) {
                    const searchData = data.filter((row) => {
                        return columns
                            .filter((columnDef) => {
                                return !columnDef.notSearchable
                            })
                            .some((columnDef) => {
                                if (columnDef.key) {
                                    const field = row[columnDef.key];
                                    if (field) {
                                        return field
                                            .toString()
                                            .toUpperCase()
                                            .includes(this.state.searchValue.trim().toUpperCase());
                                    }
                                }
                            });
                    });
                    table.update(searchData);
                } else {
                    table.update(data);
                }
            });
        }
        tableDOM.addEventListener('selectRow', (e) => { this.props.selectRow && this.props.selectRow(e, "add") });
        tableDOM.addEventListener('unselectRow', (e) => { this.props.selectRow && this.props.selectRow(e, "remove") });
    }

    // static getDerivedStateFromProps(props, state)
    // {
    //     const tableDOM = document.querySelector('#' + props.id);
    //     const table = new Table(tableDOM);
    //     table.update(props.data);
    //     return {
    //         ...state,
    //         data: props.data
    //     }
    // }


    /*ExportToExcel = (apiData, fileName) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";

        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }*/
    ExportToExcel = (apiData, fileName) => {
        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        if (!this.props.multiSheets) {
            const ws = XLSX.utils.json_to_sheet(apiData);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }
        else {
            const wb = XLSX.utils.book_new();

            apiData.forEach(s => {
                const ws = XLSX.utils.json_to_sheet(s.data);
                XLSX.utils.book_append_sheet(wb, ws, s.name);
            })
            const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        }

    }

    setPagination = (pagination) => {
        this.props.setPagination({ pagination: pagination, id: this.props.id })
    }

    retreiveTablePagination = (
        this.props.paginationItems ?
            this.props.paginationItems?.map(item => {
                // return the paginated items 
                return <div className="item" onClick={() => { this.setPagination(item) }}>{item}</div>
            })
            :
            (<div>
                <div className="item" onClick={() => { this.setPagination(15) }}>15</div>
                <div className="item" onClick={() => { this.setPagination(20) }}>20</div>
            </div>)
    );

    render() {
        const searchColumns = (this.props.columns.filter(c => !c.headerStyle?.includes("display:none")).map((column, i) => {
            return (<td key={"td_" + column.key + "_" + this.props.id}><input id={column.key + "_" + this.props.id} type="text"
                style={{ width: "95%", display: column.hideFilter ? "none" : null }}
                placeholder="Type to filter" /></td>)
        }));

        const paginationValue = (
            (this.props.tableReducer?.tableId === this.props.id) ?
                (this.props.tableReducer?.tablePagination > this.props.data?.length) ?
                    this.props.data?.length :
                    this.props.tableReducer.tablePagination ?? this.props.rowsPerPage
                :
                this.props.rowsPerPage);
        const classes = ["table "];
        return (<>
            <div className="table-top w-10">
                <div className="table-top-left">
                    {this.props.hideTableInfo ? null :
                        <>
                            <div className="table-info"></div>
                            {this.props.totalCount ? <div className="my-table-info">{"Total Count: " + this.props.totalCount}</div> : null}
                        </>
                    }
                    {(this.props.exportExcel ?
                        <span className="tooltip mb-1 ml-1">
                            <i style={{ fontSize: "20px", color: "gray" }}
                                id="exportData" className="icon icon-download-save text-lg clickable"
                                onClick={(e) => this.ExportToExcel(this.props.Reportdata, this.props.excelFileName)}></i>
                            <span className="message top-end">Export To Excel</span>
                        </span>
                        : null)}

                    {this.props.viewActionIcon ?
                        this.props.actionIcon : null}

                </div>
                {(this.props.hideSearch ?
                    <div className="table-top-right flex-end-content" style={{ display: (!this.props.viewActionButton ? "none" : null) }} >
                        {this.props.actionButton}
                    </div>
                    :
                    <div className="table-top-right flex-end-content" style={{ display: (!this.props.viewActionButton ? "none" : null) }} >
                        <EuiSearchField id={"search_" + this.props.id}
                            className={"m-1 " + (this.props.searchClassName ?? "")}
                            value={this.state.searchValue}
                            placeholder={this.props.placeholder || "Search..."}
                            onChange={(e) => this.setState({ searchValue: e.target.value })}
                            style={this.props.searchStyle}
                        />
                        {this.props.actionButton && <div className="vertical-line"></div>}
                        {this.props.actionButton}
                    </div>
                )}
            </div>

            <table id={this.props.id} className={["table "].join(' ') + this.props.classes} >
                {this.props.filter ? (<tbody className="filters">
                    <tr>
                        {this.props.selectable ? < td ></td> : <></>}
                        {searchColumns}
                    </tr>
                </tbody>) : <></>}
            </table>
            {
                (this.props.paginated ?
                    <div className="table-bottom">
                        <div id={this.props.id} className="pagination-group" >
                            <ul className="pagination" />
                            {this.props.paginationActions ??
                                <div className="actions">
                                   { this.props.showNext && <button className="btn current-next" onClick={this.props.onNextClick}>Next</button>}
                                    
                                    <label className="left">Go to</label>
                                    <input type="number" min="1" />
                                    <label className="left">Show</label>
                                    <div className="select" data-type="single">
                                        <button className="btn current-options">{this.props.rowsPerPage}</button>
                                        <div className="options-list">
                                            <div className="item active">{paginationValue}</div>
                                            {this.retreiveTablePagination}
                                            <div className="item" onClick={() => { this.setPagination(this.props.data?.length) }}>All</div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    : null)
            }

        </>);
    }
}
const mapStateToProps = state => ({ tableReducer: state.table });
const mapDispatchToProps = dispatch => {
    return {
        setPagination: (pagination) => dispatch({ type: "SET_TABLE_PAGINATION", payload: pagination }),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ObseleteEuiTable);