import React from "react";
import { connect } from "react-redux";
import EuiTable from "../../../eui-components/table";
import EuiCheckbox from "../../../eui-components/checkbox";
import PageHelper from "../../../utils/page-helper";

class DisplayItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displayColumns: [{ title: "", field: "name" },
            { title: "", field: "display" },
                //{ title: "Embed", field: "embed" }
            ]
        }
    }

    componentDidMount() {

    }

    displayGpsCoord = () => {
        PageHelper.getCoordinates();
        this.props.changeVideoLabel(3)
    }

    render() {
        const data = [{

            name: "Active user login",
            display: <EuiCheckbox name="activeUserLogin" id="activeUserLoginId" label=" " refValue={this.props.liveStreamData.videoLabelItems[0]} onChange={() => this.props.changeVideoLabel(0)} />,
            //embed: <EuiCheckbox name="activeUserLoginEmbed" id="activeUserLoginEmbedId" label=" " defaultChecked={true} />
        },
        {
            name: "Date & time",
            display: <EuiCheckbox name="dateAndTime" id="dateAndTimeId" label=" " refValue={this.props.liveStreamData.videoLabelItems[1]} onChange={() => this.props.changeVideoLabel(1)}  />,
            //embed: <EuiCheckbox name="dateAndTimeEmbed" id="dateAndTimeEmbedId" label=" " defaultChecked={false} />
        },
        {
            name: "Ericsson logo",
            display: <EuiCheckbox name="ericssonLogo" id="ericssonLogoId" label=" " refValue={this.props.liveStreamData.videoLabelItems[2]} onChange={() => this.props.changeVideoLabel(2)}  />,
            //embed: <EuiCheckbox name="ericssonLogoEmbed" id="ericssonLogoEmbedId" label=" " defaultChecked={true} />
        },
        {
            name: "GPS coordinates",
            display: <EuiCheckbox name="gpsCoordinates" id="gpsCoordinatesId" label=" " refValue={this.props.liveStreamData.videoLabelItems[3]} onChange={() => this.displayGpsCoord()}  />,
            //embed: <EuiCheckbox name="gpsCoordinatesEmbed" id="gpsCoordinatesEmbedId" label=" " defaultChecked={true} />
        },
        {
            name: "Site Number",
            display: <EuiCheckbox name="siteNumber" id="siteNumberId" label=" " refValue={this.props.liveStreamData.videoLabelItems[4]} onChange={() => this.props.changeVideoLabel(4)}  />,
            //embed: <EuiCheckbox name="siteNumberEmbed" id="siteNumberId" label=" " defaultChecked={true} />
        },
        {
            name: "Session Name",
            display: <EuiCheckbox name="sessionName" id="sessionNameId" label=" " refValue={this.props.liveStreamData.videoLabelItems[5]} onChange={() => this.props.changeVideoLabel(5)}  />,
            //embed: <EuiCheckbox name="sessionNameEmbed" id="sessionNameId" label=" " defaultChecked={true} />
        },
        {
            name: "Streaming ID",
            display: <EuiCheckbox name="streamingIdName" id="streamingID" label=" " refValue={this.props.liveStreamData.videoLabelItems[6]} onChange={() => this.props.changeVideoLabel(6)}  />,
        },
        {
            name: "Streaming User",
            display: <EuiCheckbox name="streamingUserName" id="streamingUser" label=" " refValue={this.props.liveStreamData.videoLabelItems[7]} onChange={() => this.props.changeVideoLabel(7)}  />,
        },
        ];



        return (
            <div className="container-fluid chat-component" style={{ paddingRight: 0, paddingLeft: 0 }}>
                <div className="row no-gutters">
                    <div className="col-12">
                        <div className="display" style={{ width: this.props.liveStreamData.width }}>
                            <EuiTable classes="striped table-borderless"
                                columns={this.state.displayColumns}
                                data={data}
                                styleHeader={{ fontSize: 20 }}
                                styleTd={{ fontSize: 17, opacity: "0.9", fontWeight: 500 }}
                            >
                            </EuiTable>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
        changeVideoLabel: (index) => dispatch({ type: "CHANGE_VIDEO_LABEL", payload: index }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplayItem);
