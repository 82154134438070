import React from "react";
import { Select } from "./Select";

class EuiMultiSelect extends React.Component {

    componentDidMount() {
        if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new Select(element);
                    component.init();
                });
            }
        }
        else {
            console.error("You should set an id to Select component");
        }
    }

    render() {
        const p = {
            "data-type": "input-multi",
            "selectedOptions": this.props.value 
        }

        return (
            <>
                <div
                    id={this.props.id}
                    className={"select" + (this.props.disabled ? " disabled" : "")}
                    {...p}
                    //   selectedOptions={this.props.value}
                    style={{ maxWidth: "100%", display: "block", ...this.props.styleDiv }}
                >
                    <div className="clickable current-options">
                        <input required={this.props.required} type="text" placeholder="Select option" />
                    </div>
                    <div className="options-list"
                        //onClick={this.props.onClick}
                        style={{ width: "auto", maxHeight: 170, ...this.props.optionListStyle }} >
                        {this.props.options &&
                            this.props.options.map(o => (
                                <div
                                    key={o.value}
                                    className={
                                        "item"
                                    }
                                    onClick={() =>
                                        this.props.onChange({ target: { value: o.value, name: this.props.name, text: o.text } })
                                    }
                                >
                                    {o.text}
                                </div>
                            ))}
                    </div>
                </div>
            </>
        );
    }
}

export default EuiMultiSelect;
