
export const initRoom = (mod) => dispatch => {
    dispatch({
        type: "INIT_ROOM",
    });
};
export const setRoomId = (room_id) => dispatch => {
    dispatch({
        type: "SET_ROOM_ID",
        payload: {
            room_id
        }
    });
};
export const setChat = (activityId, rscScope, ehsPingId) => dispatch => {
    dispatch({
        type: "SET_ACTIVITY_ID",
        payload: {
            activityId,
            rscScope,
            ehsPingId
        }
    });
};
export const setConnection = (connection) => dispatch => {
    dispatch({
        type: "SET_CONNECTION",
        payload: {
            connection
        }
    });
};