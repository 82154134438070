import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import EuiTile from "../../eui-components/tile";
import PageHelper from "../../utils/page-helper";
import VehicleApprovalInbox from "../VehicleApprovalInbox/VehicleApprovalInbox";

class vehicleInboxApproval extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        PageHelper.getCoordinates();
        this.props.changeVehicleMod();
    }

    renderMod = () => {
        return <VehicleApprovalInbox />
        // if (this.props.mod === constants.pageMods.EDIT) {
        //     return <VehicleApprovalDetails />
        // }
        // else {  //VehicleInboxMenu
        //     return <VehicleApprovalInbox />
        // }
    }
    render() {
        return (<EuiTile fullscreen={true} tileStyle={{ padding: 0 }}>{this.renderMod()}</EuiTile>);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, imInboxData: state.imInbox, mod: state.vehicleApproval.inspectionMod})
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        changeVehicleMod: (mod) => dispatch({ type: "CHANGE_VEHICLE_MOD", payload: mod }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(vehicleInboxApproval)));