export default class SiteHelper {

    static mapSites = (sites) => {
        if ((sites || []).length === 0) {
            return [];
        }

        sites.forEach(item => {
            item.customerName = item.customer?.name;
            item.coordinates = [item.latitude, item.longitude];
            item.ownerDetails = [item.ownerName, item.ownerEmail];
            (item.logs || []).forEach(log => {
                log.timestamp = log.dateTimeUTC;
                log.title = log.user?.text
                log.content = `<p style="white-space: pre-wrap;">${log.logDetail}</p>`;
            });
        });

        return sites;
    }

    static mapSite = (site) => {

    }

}