import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import EuiTile from "../../eui-components/tile";
import IMActivitySearch from "./imActivitySearch";
import PageHelper from "../../utils/page-helper";

class IMInbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        PageHelper.getCoordinates();
    }

    renderMod = () => {
        return <IMActivitySearch />
    }

    render() {
        return (<EuiTile fullscreen={true} tileStyle={{ padding: 0 }}>{this.renderMod()}</EuiTile>);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, imInboxData: state.imInbox });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(IMInbox)));