const initialState = {
    files: [],
    documents: [],
    projectDocuments: [],
    ASPDocuments: [],
    activityDocuments: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_CUSTOMER_DOCUMENTS":
            return {
                ...state,
                documents: action.payload
            }
        case "SET_FILES":
            return {
                ...state,
                files: action.payload
            }

        case "SET_PROJECT_DOCUMENTS":
            return {
                ...state,
                projectDocuments: action.payload
            };
            case "SET_CHAT_DOCUMENTS":
                return {
                    ...state,
                    chatDocuments: action.payload
                };
        case "SET_ASP_DOCUMENTS":
            return {
                ...state,
                ASPDocuments: action.payload
            };
        case "SET_ACTIVITY_DOCUMENTS":
            return {
                ...state,
                activityDocuments: action.payload
            };
        default:
            return state;
    }
};
