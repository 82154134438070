import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import { withLayout } from "../../layout";
import constants from "../../utils/constants";
import ChecklistItemsList from "./activityChecklistItemsList";
import ChecklistItemsSubList from "./activityChecklistItemsSubList";
import AddActivityTemplate from "./addActivityTemplate";

class ActivityChecklistsItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
    }

    renderMod = () => {
        const rightPanel = null;
        const midPanel = { title: "", content: <ChecklistItemsList />, hideHeader: true };
        const rightPanelItem = null
        const midPanelItem = { title: "", content: <ChecklistItemsSubList />, hideHeader: true, style: { width: "75%" } };
        if (this.props.activityTemplateData.mod === constants.pageMods.SUB_LIST) {
            return <EuiMultiPanelTile id="checklistItemsPanel" midPanel={midPanelItem} rightPanel={rightPanelItem} />
        }
        else if (this.props.activityTemplateData.mod === constants.pageMods.EDIT) {
            return <AddActivityTemplate />
        } else {
            return <EuiMultiPanelTile key={this.props.activityTemplateData.templates} id="checklistItemsPanel" midPanel={midPanel} rightPanel={rightPanel} />
        }
    }

    render() {
        return (this.renderMod())
    }
}

const mapStateToProps = state => ({ userData: state.user, aspResourcesData: state.asp, navbarData: state.navigation, activityTemplateData: state.activityChecklists });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        changeMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(ActivityChecklistsItems)));