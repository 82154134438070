import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Stack, Typography } from "@mui/material";

import { isEmpty, isNull, isUndefined } from "lodash";
import { HubConnectionBuilder, LogLevel, HubConnectionState } from "@microsoft/signalr";
import { AddDirectMessage, FetchCurrentMessages, FetchDirectConversations, FetchParticipantInvitedConversations, getChatTime, setConvInitialState } from "../../../store/slices/conversation";
import { useLocation } from "react-router-dom";
import ChatComponent from "./Conversation";
import Chats from "./Chats";
import { useDispatch, useSelector } from "react-redux";
import { SelectConversation, setChatInitialState, setIsSentMessage } from "../../../store/slices/chat";
import constants from "../../../utils/constants";
import EuiLoading from "../../../eui-components/loading";
import ApiClient from "../../../utils/api-client";
import DateUtils from "../../../utils/date-utils";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { initRoom, setChat, setConnection, setRoomId } from "../../../store/actions/roomAction";

function containsUrl(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return urlRegex.test(text);
}

const GeneralChat = (props) => {
  const { rscScope, activityId, ehsPingId } =
    props;

  const dispatch = useDispatch();
  const [filterConversation, setFilterConversation] = useState(ehsPingId ? constants.FilterConversation.EHS : constants.FilterConversation.All);
  const [isLoading, setIsLoading] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('Connecting..');
  const [retry, setRetry] = useState(0)

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const {  isSentMsg } = useSelector((state) => state.chat);
  const roomReducer = useSelector((state) => state.room);
  const connection = roomReducer.connection;
  const room_id = roomReducer.room_id;
  const { current_messages } = useSelector(
    (state) => state.conversation.direct_chat
  );
  const userData = useSelector((state) => state.user);
  const userId = parseInt(userData.userData.userId);
  const activityData = useSelector((state) => state.activity);
  
  useEffect(() => {
    setConnectionStatus(connection? connection?.connectionState: 'Connecting..')
    if(room_id && connection && connection?.connectionState != 'Connecting')
    {

      onConnecting();
    }
    
  }, []);

  useEffect(() => {
    localStorage.setItem("userId", JSON.stringify(userId));
    localStorage.setItem("activityId", JSON.stringify(activityId));
    localStorage.setItem("ehsPingId", JSON.stringify(ehsPingId));
  
    return () => {
      if (connection) {
        connection.stop();
      }
      localStorage.removeItem("activityId");
      localStorage.removeItem("ehsPingId");

      dispatch(setIsSentMessage({ isSentMsg: null }));
      dispatch(setChatInitialState());
      dispatch(setConvInitialState());
      dispatch(initRoom())
      dispatch(SelectConversation({room_id: null}));
      dispatch(AddDirectMessage({current_messages: []}));
      dispatch(FetchCurrentMessages({ messages:  [] }));

    }
  }, []);


  useEffect(() => {

    if (room_id){
      console.info('connection useEffect createHubConnection current_messages',current_messages, room_id, roomReducer)
      createHubConnection();
    }

  }, [room_id]);

  function refreshAndReconnect() {
    //createHubConnection();
    onConnecting();
    
    setRetry(retry+ 1);
    console.log("testing connection with rahmat from refreshAndReconnect",retry);

  }

  function createHubConnection() {
    let con = connection
    console.info('connection createHubConnection', con)

    if (con == null) {
      let token = localStorage.getItem("token");
      con = new HubConnectionBuilder()
        .withUrl(constants.ChatUrlMobil, {
          accessTokenFactory: async () => token,
          withCredentials: false
        })
        // .withAutomaticReconnect((previousRetryCount, elapsedMilliseconds, retryReason) =>{
        //   console.log(retryReason)
        //   return elapsedMilliseconds > 1000 *60 ? null : 100;
        // })
        .configureLogging(LogLevel.Debug)
        .build();
     //setConnection(con);
      dispatch(setConnection(con))
      console.info('connection createHubConnection if', con)
    }
    // onConnecting(con)
  }

  const onConnecting = (con) => {
    const user_id = parseInt(userData.userData.userId)
    console.info('connection onConnecting if', user_id)

    let c = connection || con;
    console.info('connection onConnecting if', c)
    // if (c && c.state === HubConnectionState.Disconnected) {
      
    if(room_id && c && c.connectionState != HubConnectionState.Connected && c.connectionState != HubConnectionState.Connecting)
    {
      c.stop().finally(r => {

      c
      .start()
      .catch(err => {
        // refreshAndReconnect();
        console.error("Connection Error ")
        setConnectionStatus('Disconnected')
      })
      .then(function () {
        console.info("Connection Success ")
        setConnectionStatus('Connected')

        c.on(room_id + '', (sender, message) => {

          const res = JSON.parse(message)[0];
          console.info('Message received',res);

          dispatch(AddDirectMessage({
            id: new Date(res.createdAt).getTime() + sender + res?.text,
            type: "msg",
            _rnId: res._rnId,
            subtype:
              !isNull(res?.audio) && !isUndefined(res?.audio) ? "audio" : !isNull(res?.video) && !isUndefined(res?.video) ? "video" : !isNull(res?.image) && !isUndefined(res?.image) ? "img" : !isNull(res?.file) && !isUndefined(res?.file) ? "doc" : containsUrl(res?.text) ? "Link" : "Text",
            ehsPingId: ehsPingId,
            message: res?.text || null,
            userName: res.user?.name,
            email: res.user?.email,
            profileAvatar: res.user?.avatar,
            preview: null,
            audio_path: !isNull(res?.audio) ? res?.audio : null,
            file_path: !isNull(res?.file) ? res?.file : null,
            video_path: !isNull(res?.video) ? res.video : null,
            image_path: !isNull(res?.image) ? res.image : null,
            online: true,
            time: getChatTime(res?.time),
            createdAt: res?.createdAt,
            incoming: parseInt(sender) !== parseInt(user_id),// to
            outgoing: parseInt(sender) === parseInt(user_id)// sender 

          }));

        })

        c.onclose((error) => {
          console.info('Connection Closed');
          setConnectionStatus('Disconnected')

          // setConnection(null)
        })
        c.onreconnected(() => {
          setConnectionStatus('Connected')

        })
        c.onreconnecting(() => {
          setConnectionStatus('Connecting...')

        })
      dispatch(setConnection(c));

      })
      .catch(err => {
        refreshAndReconnect();
        console.error("Connection Error ")
        setConnectionStatus('Disconnected')
      });
      });

    }
  }

  const handleLoad = (load) => {
    setIsLoading(load != null ? load : !isLoading);
  }

  const GetAllConversationsByActivityId = () => {
    var request = {
      activityId: roomReducer.activityId,// 6645757,activityId
      conversationId: room_id, //

    };
    handleLoad(true);
    if(request.activityId)
    ApiClient.post("Chat/GetAllConversationsByActivityId", request).then(
      (data) => {
        if (!data.data) {
          createNewConversationOrRoom().then(() => {

            GetAllConversationsByActivityId();
          });
        }
        else {

          // dispatch action
          dispatch(FetchDirectConversations({ conversations: data?.data }));
          let participants = data.data[0]?.participants;
          participants = (participants || [])?.map(function (ele) {
            return { ...ele, activityid: data.data[0].activityid };
          });
          SelectConversation({ room_id: data.data[0]?.id });
          dispatch(setRoomId(data.data[0]?.id))

          dispatch(
            FetchParticipantInvitedConversations({
              participantInvited: participants,
            })
          );
          handleLoad(false);
        }

      }
    );
  };

  const createNewConversationOrRoom = (id) => {
    const ele = {};
    const userName = userData.username;
    const selectedActivity = activityData.activity;
    ele.activityid =roomReducer.activityId; //
    ele.teamLeadId = parseInt(selectedActivity.resourceId); // team lead id
    ele.rscPersonId = parseInt(selectedActivity.rscPersonId); //inspector
    ele.teamLeadMail = parseInt(selectedActivity.teamLeadMail); //team lead Mail\
    ele.userId = parseInt(userId);

    ele.isOnline = true;
    ele.message = "new chat room created";
    ele.title = "Activity - " + parseInt(selectedActivity.id);
    ele.userName = userName;
    ele.time = DateUtils.toUtcDate(new Date());
    if (isSentMsg === null) {
      return ApiClient.post("Chat/CreateConversations", { ...ele }).then(
        (response) => {
          dispatch(setIsSentMessage({ isSentMsg: userId }));
           GetAllConversationsByActivityId()
        }
      );
    }
  };

  return (
    <ErrorBoundary>
      <Stack direction="row" sx={{ width: "100%" }}>
        <EuiLoading isLoading={isLoading} />
        {/* side Bar */}
        <Chats
          createNewConversationOrRoom={createNewConversationOrRoom}
          handleLoad={handleLoad}
          activityId={activityId}
          ehsPingId={ehsPingId}
          filterConversation={filterConversation}
          retry={retry}
          // refreshAndReconnect={refreshAndReconnect}
        />
        <Box
          sx={{
            height: "75vh",//35vw
            width: "calc(100% - 400px)",
            backgroundColor: "#FFF",
            borderBottom:
              searchParams.get("type") === "individual-chat" &&
                searchParams.get("id")
                ? "0px"
                : "6px solid #0162C4",
          }}
        >
          {/* {room_id !== null ? ( */}
            <ChatComponent
              connectionStatus={connectionStatus}
              connection={connection}
              refreshAndReconnect={refreshAndReconnect}
              createNewConversationOrRoom={createNewConversationOrRoom}
              activityId={activityId}
              ehsPingId={ehsPingId}
              rscScope={rscScope}
              filterConversation={filterConversation}
              setFilterConversation={setFilterConversation}
            />
          {/* ) : (
            <Stack
              spacing={2}
              sx={{ height: "100vh", width: "100%" }}
              alignItems="center"
              justifyContent={"center"}
            >
              <Typography variant="subtitle2">
                Select a conversation from Chat Group
              </Typography>
            </Stack>
          )} */}
        </Box>
      </Stack>
    </ErrorBoundary>
  );
};

export default GeneralChat;
