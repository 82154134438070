import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import EuiTile from "../../eui-components/tile";
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import constants from "../../utils/constants";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import ResourceInfo from "./resourceInfo";
import Certification from "./certification";
import JobRoles from "./jobRoles";
import MenuMultiPanelTile from "../../components/menuMultiPanel";
import Competences from "./competences";
import PPE from "./ppe";
import AssignedProjects from "./assignedProjects";
class ResourceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    editResource = () => {
        if (this.props.aspResourcesData.resource?.status == "Blacklisted" && this.props.userData.userData.jobRole != constants.userRoles.ASP_MANAGER) {
            this.props.setDisabledResource(true);
        }
        else {
            this.props.setDisabledResource(false);
        } this.props.createEditResourceForm(true);
        this.props.setCreateResource(false);
    }
    onAddCrt = () => {
        this.props.setCertificate({});
    }
    getMenuList = () => {
        const roleFlag = this.props.userData.userData.jobRole === constants.userRoles.ERICSSON_IM
            // ||( this.props.userData.userData.jobRole === constants.userRoles.ASP_PROJECT_MANAGER && (this.props.aspResourcesData.resource.status === 'Created' || this.props.aspResourcesData.resource.status === 'Not Active'))
            || this.props.userData.userData.jobRole === constants.userRoles.RSC_OHS_INSPECTOR
            || this.props.userData.userData.jobRole === constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR
            || this.props.userData.userData.jobRole === constants.userRoles.VALIDATION_OFFICER || this.props.userData.userData.jobRole === constants.userRoles.ASP_MANAGER
        const certflag =  roleFlag // || this.props.userData.userData.jobRole === constants.userRoles.ASP_PROJECT_MANAGER 
        const menu = [{
            id: 1, title: "Resource Information", content: <ResourceInfo />,
            midPanelButton: true, button: <button id="editProfile" hidden={!roleFlag} className="btn primary" onClick={this.editResource}>{<span > <i class="icon icon-edit"></i> Edit Profile</span>}</button>,
        },
        {
            id: 2, title: "Certificates", content: <Certification />,
            midPanelButton: true, button: <button id="openDialog" hidden={this.props.aspResourcesData.resource.status == "Blacklisted"}
                disabled={!certflag}
                onClick={this.onAddCrt} className="btn primary" > {< span > <i className="icon icon-plus" /> Add Certificate</span >}</button>,
        },
        {
            id: 3, title: "Job Roles", content: <JobRoles />,
        },
        {
            id: 4, title: "PPE", content: <PPE />
        },
        {
            id: 5, title: "Technical Competences", content: <Competences />
        },
        {
            id: 6, title: "Projects Assignments", content: <AssignedProjects />
        },
        ]
        return menu;
    }
    createComponent = () => {
        this.props.createEditResourceForm(true);
    }
    render() {
        
        return (<EuiTile key={this.props.aspResourcesData.key} fullscreen tileStyle={{ padding: 0 }}>
            <EuiBreadcrumb id="resourcesBreadcrumb"
                parentName="Home"
                childName={<div className="flex-start-content">{(this.props.aspResourcesData.resource?.validResource ?
                    <span className="tooltip" id="resourceStatus"><i class="icon icon-alarm-level3 color-green m-1 "></i>
                        <span className="message right">Mandatory certificates filled and employee is active</span>
                    </span> :
                    <span className="tooltip" id="resourceStatus"> <i class="icon icon-alarm-level3 color-red m-1 "></i>
                        <span className="message right">Mandatory certificates not filled, expired or resource is not active</span>
                    </span>
                )}{this.props.aspResourcesData.resource?.name}</div>}
                onClickParent={() => this.props.changeResourceMod(constants.pageMods.LIST)}
                className="m-2 mb-3"
            />
            <MenuMultiPanelTile id="ResourceDetailsMenuMultiPanel" menuList={this.getMenuList()} selectedItem={this.props.aspResourcesData.selectedItem} />
        </EuiTile>);
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp });
const mapDispatchToProps = dispatch => {
    return {
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        createEditResourceForm: (state) => dispatch({ type: "CREATE_EDIT_RESOURCE_FORM", payload: state }),
        setCertificate: (certificate) => dispatch({ type: "SET_CERTIFICATE", payload: certificate }),
        changeResourceMod: (mod) => dispatch({ type: "CHANGE_RESOURCE_MOD", payload: mod }),
        setCreateResource: (state) => dispatch({ type: "SET_CREATE_RESOURCE", payload: state }),
        setDisabledResource: (isDisabled) => dispatch({ type: "SET_DISABLE_RESOURCE", payload: isDisabled })
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ResourceDetails));