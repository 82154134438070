import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../../eui-components/row";
import EuiTextField from "../../../../eui-components/textfield";
import EuiTile from "../../../../eui-components/tile";
import PageHelper from "../../../../utils/page-helper";
import ObseleteEuiTable from "../../../../eui-components/obseleteTable";

class TeamContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            certificationColumns:
                [                    
                    {
                        title: "Course Number",
                        key: "certificateIdentity",
                        width: "20%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Course Name",
                        key: "certificateName",
                        width: "35%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Required Certificates",
                        key: "requiredCertificates",
                        width: "10%",
                        headerStyle: "padding:1px;text-align:center;",
                        cellStyle: "text-align:center;"
                    },
                    {
                        title: "Valid Certificates",
                        key: "validCertificates",
                        width: "10%",
                        headerStyle: "padding:1px;text-align:center;",
                        cellStyle: "text-align:center;"
                    },
                    {
                        title: "Status",
                        key: "validStatus",
                        onCreatedCell: (td, cellData) => {
                            const icon = PageHelper.renderTeamVerification(cellData);
                            td.innerHTML = `${icon}`;
                            td.style.fontSize = "13px";
                        },
                        width: "15%",
                        headerStyle: "padding:1px;"
                    },                  
                ],
        }
    }

    componentDidMount() {

    }
   
    render() {
        return (<EuiRow>
            <EuiTile styleContent={{ display: "flex" }}>
                <div className="flex-start-content w-10 pl-1">
                    <EuiTextField
                        id="teamNoId"
                        className="w-1"
                        fullwidth
                        name="teamNo"
                        label="Team No : "
                        value={this.props.liveStreamData.teamsLogInfo.teamInfo?.id || "---"}
                        readOnly
                        labelClass="left"
                        labelStyle={{ fontWeight: 600, fontSize: 15, textAlign: 'center', width: '100%'}}
                        style={{ textAlign: 'center'}}
                    />
                    <div className="vl"></div>
                    <EuiTextField
                        id="teamNameId"
                        className="p-1 w-4"
                        fullwidth
                        name="teamName"
                        label="Team Name : "
                        value={this.props.liveStreamData.teamsLogInfo.teamInfo?.name || "---"}
                        readOnly
                        labelClass="left"
                        style={{ textAlign: 'center'}}
                        labelStyle={{ fontWeight: 600, fontSize: 15, textAlign: 'center', width: '100%' }}
                    />
                    <div className="vl"></div>
                    <EuiTextField
                        id="verificationSessionId"
                        className="w-3"
                        name="verificationSession"
                        label="Verification Session Id: "
                        value={this.props.liveStreamData.teamsLogInfo.teamInfo?.verificationSessionId || "---"}
                        readOnly
                        labelClass="left"
                        style={{ textAlign: 'center'}}
                        labelStyle={{ fontWeight: 600, fontSize: 15, textAlign: 'center', width: '100%' }}
                        fullwidth
                    />
                    <div className="vl"></div>
                    <EuiTextField
                        id="teamCustomerName"
                        className="p-1 w-2"
                        fullwidth
                        name="teamCustomerName"
                        label="Customer Name : "
                        value={this.props.liveStreamData.teamsLogInfo.teamInfo?.customerName || "Global"}
                        readOnly
                        labelClass="left"
                        style={{ textAlign: 'center'}}
                        labelStyle={{ fontWeight: 600, fontSize: 15, textAlign: 'center', width: '100%' }}
                    />
                    <div className="vl"></div>
                    {PageHelper.renderVerificationSite(this.props.liveStreamData.teamsLogInfo.teamInfo?.isOffsiteVerification)}
                    <div className="vl"></div>
                </div>
                <div className="flex-end-content w-2">
                    {PageHelper.getVerificationIcon(this.props.liveStreamData.teamsLogInfo.teamInfo?.status, "text-lg")}
                    {PageHelper.getVerificationLabel(this.props.liveStreamData.teamsLogInfo.teamInfo?.status)}
                    {this.props.liveStreamData.teamsLogInfo.teamInfo?.customerId > 0
                        ? null
                        : <div className="flex-end-content mr-2 ml-2"><i className="icon icon-globe icon-lg m-1"></i></div>}

                </div>
            </EuiTile>

            <EuiTile>
                <ObseleteEuiTable                   
                    classes="very-tiny"
                    columns={this.state.certificationColumns}
                    data={this.props.liveStreamData.teamsLogInfo.teamVerificationLogs}
                    hideTableInfo={true}
                    id="certificateTable"
                    scroll={true}
                    sortable={true}
                    styleHeader={{ fontSize: 20 }}
                />
            </EuiTile>

        </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session, liveStreamData: state.liveStream, });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamContent);
