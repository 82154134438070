import React from "react";
import { GroupButton } from '@eds/vanilla';

class EuiGroupButton extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const btnGroupDOM = document.querySelector('#btnGroupH');

        if (btnGroupDOM){
          const buttonGroup = new GroupButton(btnGroupDOM);
          buttonGroup.init();
        }       
    }
    render() {
        return (
<div class="btn-group horizontal" id="btnGroupH">
{this.props.options &&  this.props.options.map(m=>
 <button  key = {m?.id} disabled = {this.props?.disabled || m?.disabled} id={m.id}  class="btn" onClick={()=>m?.onTap && m?.onTap()}>{m.name}</button>
)}
</div>
        );
    }
}

export default EuiGroupButton;
