import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import constants from "../../utils/constants";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EhsPingInfo from "./ehsPingInfo";
import EhsPingChecklists from "./ehsPingChecklists";
import RecordingModal from "../../components/recordingModal";
import DocumentDialogModal from "../../components/documentDialogModal";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import RateEhsPing from "../ehs-inbox/ehs-pings/rateEhsPing";
import EuiTile from "../../eui-components/tile";
import MenuMultiPanelTile from "../../components/menuMultiPanel";
import EhsPingViolation from "../ehs-inbox/ehs-pings/ehsPingViolation";
import DialogModal from "../../components/dialog";
import PageHelper from "../../utils/page-helper";
import DownloadFilterModal from "../../components/downloadFilterModal";
import WorkplanHelper from "../../utils/workplan-helper";
import { saveAs } from "file-saver";
import CommonHelper from "../../utils/common-helper";
import DocumentLoadingModal from "../../components/documentLoadingModal";
import ApiClient from "../../utils/api-client";
import DateUtils from "../../utils/date-utils";
import EuiTooltip from "../../eui-components/tooltip";
import TeamCertificationItem from "../live-stream/live-streaming/team-certification/teamCertificationItem";
import EuiTabs from "../../eui-components/tabs";
import EhsPingViolationResolution from "./ehsPingViolationResolution";
import EuiRow from "../../eui-components/row"; 
import VehiclesRecordsEHS from "../live-stream/vehicles/VehiclesRecordsEHS";
import Cookies from "js-cookie";
import { ChatCircleDots } from "phosphor-react";
import GeneralChat from "../chatTools/dashboard";
import { setChat } from "../../store/actions/roomAction";

class EhsPingDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialogPreview: false,
            selectedRecording: { id: 0 },
            percentageArr: [],
            openDialogFilter: false,
            openDialogDownloadBar: false,
            openDialogClosePing: false,
            allDocument: [],
            isLoading: false,
            downloadFilter: {
                emptyChecklist: true,
                images: true,
                videos: true,
                pdfs: true,
                otherDocuments: true,
                accepted: true,
                rejected: true,
                pending: true,
                recordings: true
            },
            compressPercentage: 0,
            filteredDocuments: [],
            allDocumentTypeSizes: { total: 0, images: 0, videos: 0, pdfs: 0, otherDocuments: 0, recordings: 0 },
            openViolationDetail: false,
        }
    }

    componentDidMount() {
        const ehsPing = this.props.ehsPingData.ehsPing;
        
        if (ehsPing &&
            ehsPing.checklists &&
            ehsPing.checklists.map(item => item.documents && item.documents.length > 0).length > 0) {
            const allDocument = [...(ehsPing.checklists.map(item => item.documents).reduce((a, b) => { return a.concat(b); }) ?? []), ...ehsPing.recordings];
            this.setState({ allDocument },
                () => {
                    PageHelper.getPropertiesOfBlobAzure(this.state.allDocument)
                        .then(response => {
                            const files = [...(response || []).filter(item => item)];
                            const allDocumentTypeSizes = PageHelper.getFilesSizeByType(files);
                            
                            this.setState({ allDocumentTypeSizes });
                        })
                        .catch(error => {
                            console.error(error);;
                        });
                });
        }
    }

    getMenuList = () => {
        const ehsPing = this.props.ehsPingData.ehsPing;
        this.props.setChat(ehsPing.activityId, ehsPing.activity.rscScope, ehsPing.id);

        const menu = [{
            id: 1,
            title: "EHS Ping Information",
            icon: <i className="icon icon-info m-1 text-lg"></i>,
            rightIcon: <> {this.getErisiteButton()}
                <EuiTooltip title="Download Session">
                    <i className="icon icon-download-save icon-lg icon-frame" onClick={() => this.setState({ openDialogFilter: true })}></i>
                </EuiTooltip>
            </>,

            content: <EhsPingInfo />,
        },
        {
            id: 2, title: "Images | Videos", icon: <i className="icon icon-image m-1 text-lg"></i>,
            content: <EhsPingChecklists />
        },
        {
            id: 3, title: "Checklist Items",
            icon: <i className={"icon icon-favorite m-1 text-lg " + ActivityInboxHelper.getColorClass(ehsPing.acceptanceStatusId)}></i>,
            content: <RateEhsPing ehsPing={ehsPing} height={300} detailPage={true} openViolationDetailDialog={() => this.setState({ openViolationDetail: true })} />,
            hidden: ehsPing.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING
        },
        {
            id: 4, title: "Violation Reporting",
            icon: <i className={"icon icon-triangle-warning m-1 text-lg " + ActivityInboxHelper.getColorClass(ehsPing.acceptanceStatusId)}></i>,
            content: <EuiTabs
                id="violationEhsPingTab"
                titles={[{ value: 1, name: "Recipients", selected: "selected" }, { value: 2, name: "Violation Details", selected: "" }, { value: 3, name: "Resolution", selected: "" }]}
                contents={[{ value: 1, selected: "selected", children: <EhsPingViolation ehsPing={ehsPing} viewOnly={true} /> },
                {
                    value: 2, selected: "", children: <EuiRow>
                        <p style={{ marginLeft: 10, marginBottom: 0, marginTop: 10, padding: 5, border: "1px solid #242424" }}>
                            <b>EHS Violation Date: </b>{DateUtils.toDateString(ehsPing.violationDate, "YYYY-MM-DD HH:mm") || "(---,--)"}
                        </p>
                        <div className="p-2" style={{ paddingTop: 2 }} dangerouslySetInnerHTML={{ __html: ehsPing.violationReport }} />
                    </EuiRow>
                },
                { value: 3, selected: "", children: <EhsPingViolationResolution ehsPing={ehsPing} /> },
                ]}
            />,
            hidden: !ehsPing.violationReport
        },
        {
            id: 6, title: "Live Recordings (" + (ehsPing.recordings?.length ?? 0) + ")", icon: <i className={"icon icon-camera-classic m-1 text-lg "}></i>,
            content: <RecordingModal data={ehsPing.recordings} openDialog={(rowData) => this.setState({ openDialogPreview: true, selectedRecording: rowData })} />
        },
        {
            id: 7, title: "Team Certifications", icon: <i className={"icon icon-medal m-1 text-lg " + PageHelper.getVerificationColor(this.props.liveStreamData.teamsLogInfo.teamInfo?.status)}></i>,
            content: <TeamCertificationItem /> 
        },
        {
            id: 8, title: "Vehicles",
            // +  PageHelper.getVerificationColor(Cookies.get('vehicleStatusIsGreen'))
             icon: <i className={"icon icon-vehicle-car m-1 text-lg " } style={{ fontSize: 20,marginLeft: 3 }}></i>,
            content: <VehiclesRecordsEHS /> 

        },
        {
            id: 9, title: "Chat", icon:<i className={"icon icon-message-double m-1 text-lg"} ></i>, 
            content: <GeneralChat activityId={ehsPing.activityId} rscScope={ehsPing.activity.rscScope} ehsPingId={ehsPing.id}   />
        }
        ]
        return menu;
    }
    
    currentItemUpdated = (id) =>
    {
        const ehsPing = this.props.ehsPingData.ehsPing;
        this.props.setChat(ehsPing.activityId, ehsPing.activity.rscScope, ehsPing.id);
    }

    downloadEhsSession = () => {
        if ((this.state.allDocument || []).length === 0) {
            toast.error("The session has no document to download", { containerId: "ED", position: toast.TOP_CENTER });
            return;
        }
        const filteredDocuments = WorkplanHelper.filterFiles(this.state.downloadFilter, this.state.allDocument);
        this.setState({ openDialogFilter: false, openDialogDownloadBar: true, percentageArr: PageHelper.initializePercentageArr(filteredDocuments), isLoading: true, filteredDocuments }, () => {
            const onProgress = (progressEvent, file) => {
                let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                let percentageArr = [...this.state.percentageArr];
                const index = percentageArr.indexOf(file);
                percentageArr[index].percentage = percentage;
                this.setState({ percentageArr });
            }
            PageHelper.downloadBlobAzure(filteredDocuments, onProgress).then(response => {
                
                const fileArr = (response || []).filter(r => r).map(item => ({ contentAsBlob: item.contentAsBlob, copySource: item.copySource }));
                this.downloadAsZip(fileArr);
            }).catch(error => {
                this.setState({ isLoading: false, openDialogDownloadBar: false });
                toast.error(constants.errorMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                console.error(error);;
            });
        });
    }

    downloadAsZip = (fileArr) => {
        const session = this.props.ehsPingData.ehsPing;
        

        let pathArr = [];
        let path = "";
        const ehsPingName = "EHS Ping - " + session.id;
        fileArr.map((item, index) => {
            const document = this.state.allDocument.find(d => (d.documentPath || d.recordPath) === item.copySource);
            if (!document) {
                return;
            }
            else if (document.documentPath) {
                const checklistName = session.checklists.find(s => s.id === document.ohsChecklistId)?.name?.replace(/[\/\\":*?<>|]/g, '');
                const acceptanceStatus = constants.acceptanceStatusLookup[document.acceptanceStatusId];
                path = ehsPingName + "\\" + checklistName + "\\" + acceptanceStatus + "\\" + document?.name?.name;
            } else {
                path = ehsPingName + "\\" + "Live Streaming Recordings" + "\\" + document.recordName + ".mp4";
            }
            pathArr = [...pathArr, { path, contentAsBlob: item.contentAsBlob }];
        });

        const zipArr = PageHelper.getZipArr(pathArr);

        
        Promise.all(zipArr.map(async (item, index) => {
            try {
                if (Object.keys(item.files)?.length === 0) {
                    return;
                }
                const content = await this.generateZip(item);
                
                saveAs(content, ehsPingName + "-" + (index + 1) + ".zip");
                this.setState({ isLoading: false, compressPercentage: 0 },
                    () => { toast.success((index + 1) + ". " + " session package downloaded successfully.", { containerId: "ED", position: toast.POSITION.TOP_CENTER }); }
                );
            } catch (error) {
                console.error(error);;
                this.setState({ isLoading: false, compressPercentage: 0 },
                    () => { toast.error("Some issues occured while zipping, Please try with less data.", { containerId: "ED", position: toast.POSITION.TOP_CENTER }); }
                );
            }
        })).then(response => {
            this.setState({ openDialogDownloadBar: false, compressPercentage: 0 });
        }).catch(error => {
            this.setState({ openDialogDownloadBar: false, compressPercentage: 0 });
        });

    }

    async generateZip(zip) {
        
        const updateCallback = (metadata) => {
            this.setState({ compressPercentage: metadata.percent.toFixed(0) });
        }
        return await zip.generateAsync({
            type: "blob",
            compression: "DEFLATE",
            compressionOptions: {
                level: 5
            }
        }, updateCallback);
    }

    handleDownloadFilter = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value || target.checked;
        
        const filter = { ...this.state.downloadFilter };
        filter[name] = value;

        this.setState({
            downloadFilter: filter
        });
    }

    getErisiteButton = () => {
        const ehsPing = this.props.ehsPingData.ehsPing;
        const activity = ehsPing.activity;
        if (activity
            && (activity.systemrecordid || activity.externalSystemRecordId)
            //&& activity.erisiteStatus != constants.erisiteStatusesLookup.NOT_APPLICABLE
            && (!ehsPing.integrationStatus || ehsPing.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED)
            && ehsPing.acceptanceStatusId != constants.acceptanceStatusTypes.PENDING
            && (ehsPing.ratingDate)
        ) {
            return <>
                {activity.rscScope === constants.rscScopeEhsName
                    ? <button className={"btn primary" + (this.state.isLoading ? " loading disabled " : "") + (this.state.disable ? " disabled " : "")} style={{ margin: 5 }} onClick={() => this.setState({ openDialogClosePing: true })} >Close EHS</button>
                    : null
                }
                <button className={"btn primary" + (this.state.isLoading ? " loading disabled " : "") + (this.state.disable ? " disabled " : "")} style={{ margin: 5 }} onClick={() => this.updateErisite()} >
                    {
                        activity.externalSystemRecordId ? 'Sitetracker Update' : 'Erisite Update'
                    }
                </button>
                <div className="vertical-line"></div>
            </>
        }
        return null;
    }

    closePing = () => {
        const ehsPing = this.props.ehsPingData.ehsPing;
        const activity = ehsPing.activity;
        const request = {
            activityId: activity.id,
            systemRecordId: activity.systemrecordid?.toString(),
            externalSystemRecordId: activity.externalSystemRecordId?.toString(),
            assignedWorkGroup: activity.assignedGroup,
            actualStartDate: ehsPing.assignedOn,
            actualEndDate: new Date(),
            activityAction: constants.erisiteActions[activity.erisiteStatus],
            updateUserId: parseInt(this.props.userData.userData.userId),
            integrationStatus: activity.integrationStatus,
            rscScope: activity.rscScope,
            ohsPingId: ehsPing.id,
            acceptanceStatusId: ehsPing.acceptanceStatusId,
            resourceId: activity.resourceId
        };
        
        this.setState({ isLoading: true, openDialogClosePing: false }, () => {
            const api = activity.externalSystemRecordId ? 'SiteTracker/ClosePingErisiteActualDatesUpdate' : 'Erisite/ClosePingErisiteActualDatesUpdate'
            ApiClient.post(api, request)
                .then(response => {
                    if (response.data.status) {
                        toast.success(response.data.errorMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                    } else {
                        toast.error(response.data.errorMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                    }
                    ehsPing.integrationStatus = response.data.status;
                    ehsPing.integrationDate = DateUtils.toUtcDate(new Date());
                    ehsPing.integrationOutput = response.data.errorMessage;
                    this.props.setEhsPing(ehsPing);
                    this.setState({ isLoading: false });
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    updateErisite = () => {
        const ehsPing = this.props.ehsPingData.ehsPing;
        const activity = ehsPing.activity;
        const request = {
            activityId: activity.id,
            systemRecordId: activity.systemrecordid?.toString(),
            externalSystemRecordId: activity.externalSystemRecordId?.toString(),
            assignedWorkGroup: activity.assignedGroup,
            actualStartDate: ehsPing.assignedOn,
            actualEndDate: ehsPing.ratingDate,
            activityAction: constants.erisiteActions[activity.erisiteStatus],
            updateUserId: parseInt(this.props.userData.userData.userId),
            rscScope: activity.rscScope,
            integrationStatus: activity.integrationStatus,
            ohsPingId: ehsPing.id,
            acceptanceStatusId: ehsPing.acceptanceStatusId,
            resourceId: activity.resourceId
        };
        
        this.setState({ isLoading: true }, () => {
            const api = activity.externalSystemRecordId ? 'SiteTracker/UpdateChecklistItemsAndActualDates ' : 'Erisite/UpdateChecklistItemsAndActualDates'
            ApiClient.post(api, request, {}, 1200000)
                .then(response => {
                    if (response.data.status) {
                        toast.success(response.data.errorMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                    } else {
                        toast.error(response.data.errorMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                    }
                    ehsPing.integrationStatus = response.data.status;
                    ehsPing.integrationDate = DateUtils.toUtcDate(new Date());
                    ehsPing.integrationOutput = response.data.errorMessage;
                    this.props.setEhsPing(ehsPing);
                    this.setState({ isLoading: false });
                }).catch(error => {
                    console.error(error);;
                    toast.error(constants.erisiteUpdateError, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                    this.setState({ isLoading: false });
                });
        });
    }

    markEHSPingViolated = () => {
        const ehsPing = this.props.ehsPingData.ehsPing;
        const ehsViolationSentUsers = ehsPing.violationSentUsers ?? [];
        if (ehsViolationSentUsers && ehsViolationSentUsers.length > 0) {
            ehsViolationSentUsers.map(user => {
                user.id = 0;
                user.ohsPingId = ehsPing.id;
                user.userId = user.value;
                user.userEmail = user.mail;
                user.userName = user.text;
                user.createDate = new Date();
                user.createUser = this.props.userData.userData.userId;
            });
        }
        ehsPing.ehsViolationSentUsers = [...ehsViolationSentUsers, ...(ehsPing.externalUsers ?? [])];
        
        this.setState({ openViolationDetail: false, isLoading: true }, () => {
            ApiClient.post("EhsInbox/MarkEHSPingViolated", {
                ehsPingId: ehsPing.id,
                violationComments: ehsPing.violationComments,
                ehsViolationSentUsers: ehsPing.ehsViolationSentUsers
            })
                .then(response => {
                    if (response.data) {
                        ehsPing.violationDate = new Date();
                        ehsPing.violation = true;
                        ehsPing.violationReport = response.data;
                        ehsPing.ehsRatings.map(r => { r.violation = r.score === constants.ehsRatingOptions.FAIL; });
                        this.setState({ isLoading: false }, () => {
                            this.props.setEhsPing(ehsPing);
                            toast.success("EHS Ping marked as violated successfully.", { containerId: "ED", position: toast.TOP_CENTER });
                        });
                    } else {
                        this.setState({ isLoading: false });
                        toast.error(constants.errorMessage, { containerId: "ED", position: toast.TOP_CENTER });
                    }
                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    toast.error(constants.errorMessage, { containerId: "ED", position: toast.TOP_CENTER });
                    console.error(error);;
                });
        })
    }

    render() {
        const completedSize = this.state.percentageArr.filter(d => d.percentage === 100).length;
        const totalSize = this.state.filteredDocuments.length;
        const ehsPing = this.props.ehsPingData.ehsPing;
        return (<EuiTile fullscreen tileStyle={{ padding: 0 }}>

            <EuiBreadcrumb id="ehsPingBreadcrumb"
                parentName="Home"
                childName={<div className="flex-start-content">
                    {this.props.ehsPingData.ehsPing.type === constants.activityListTypes.WORKPLAN_ACTIVITY ?
                        <span>{this.props.ehsPingData.ehsPing.workplanName}<i className="icon icon-chevron-right m-1"></i>
                            {this.props.ehsPingData.ehsPing.activityName}<i className="icon icon-chevron-right m-1"></i></span> :
                        this.props.ehsPingData.ehsPing.type === constants.activityListTypes.ACTIVITY ?
                            <span>{this.props.ehsPingData.ehsPing.activityName} <i className="icon icon-chevron-right m-1"></i></span> :
                            this.props.ehsPingData.ehsPing.type === constants.activityListTypes.WORKPLAN ?
                                <span>{this.props.ehsPingData.ehsPing.workplanName} <i className="icon icon-chevron-right m-1"></i></span> :
                                null
                    }
                    <span>{"EHS Ping: " + this.props.ehsPingData.ehsPing.id}</span>
                    <span class="tooltip pointer">
                        <i className="icon icon-undo m-1 icon-lg" onClick={() => this.props.history.goBack()}></i>
                        <span class="message right">Return to Previous Page</span>
                    </span>
                </div>}
                onClickParent={() => this.props.changeEhsPingMod(constants.pageMods.LIST)}
                className="p-2" />

            <MenuMultiPanelTile id="ehsDetailsMenuMultiPanel"
                currentItemUpdated={this.currentItemUpdated}
                menuList={this.getMenuList()}
                key={this.state.isLoading}
            />

            <DocumentDialogModal openDialogPreview={this.state.openDialogPreview}
                handleClose={() => this.setState({ openDialogPreview: false })}
                id="ehsPignRecordingsDocumentView"
                showLocation={false}
                selectedDocument={{ ...this.state.selectedRecording, documentPath: this.state.selectedRecording.recordPath, uploadDate: this.state.selectedRecording.endDatetime, type: 2 }}
            >
            </DocumentDialogModal>

            <DialogModal
                content={<DocumentLoadingModal compressPercentage={this.state.compressPercentage}
                    session={this.props.ehsPingData.ehsPing}
                    length={totalSize}
                    percentageArr={this.state.percentageArr} />}
                customStyle={{ overflow: "hidden" }}
                handleClose={() => this.setState({ openDialogDownloadBar: false })}
                id="ehsPingUploadBarDialog"
                open={this.state.openDialogDownloadBar}
                buttonsVisible="false"
                title={"Downloading Progress: " + CommonHelper.getPercentage(completedSize, totalSize)}
                closeButtonVisible="false"
                disableBackdropClick={true}
            >
            </DialogModal>

            <DialogModal
                buttonName="Ok"
                customStyle={{ width: 700 }}
                content={<DownloadFilterModal
                    downloadFilter={this.state.downloadFilter}
                    handleDownloadFilter={this.handleDownloadFilter}
                    allDocumentTypeSizes={this.state.allDocumentTypeSizes} />}
                handleClose={() => this.setState({ openDialogFilter: false })}
                id="ehsDownloadSessionFilter"
                onClick={this.downloadEhsSession}
                open={this.state.openDialogFilter}
                title={"Filter Download Session | " + (this.state.allDocumentTypeSizes.total === 0 && this.state.allDocument.length > 0 ? "Please wait total size calculating..."
                    : " Total Size: " + CommonHelper.bytesToSize(this.state.allDocumentTypeSizes.total))}
                type="primary"
            >
            </DialogModal>

            <DialogModal
                buttonName="Confirm"
                content={<p>After closing the ping, you can't update again {ehsPing.activity.externalSystemRecordId ? 'sitetracker' : 'erisite'} because, activity will be completed in erisite. Are you sure you want to proceed?</p>}
                handleClose={() => this.setState({ openDialogClosePing: false })}
                id="ehsPingCloseDialog"
                onClick={this.closePing}
                open={this.state.openDialogClosePing}
                title="Confirmation Request"
                type="warning"
            >
            </DialogModal>

            <DialogModal
                buttonName="Save & Send"
                content={<EhsPingViolation ehsPing={ehsPing} detailPage={true} />}
                handleClose={() => this.setState({ openViolationDetail: false })}
                id="ehsViolationDetailDialog"
                onClick={() => this.markEHSPingViolated()}
                open={this.state.openViolationDetail}
                title="Reporting EHS Violation"
            >
            </DialogModal>

            <ToastContainer enableMultiContainer containerId={'ED'} autoClose={2500} />
        </EuiTile >);
    }
}

const mapStateToProps = state => ({ 
    userData: state.user,
    navbarData: state.navigation,
    ehsPingData: state.ehsPing, 
    liveStreamData: state.liveStream , 
    vehicles:state.vehicle?.vehicleList,

  
 });
const mapDispatchToProps = dispatch => {
    return {
        changeEhsPingMod: (mod) => dispatch({ type: "CHANGE_EHS_PING_MOD", payload: mod }),
        setEhsPing: (ehsPing) => dispatch({ type: "SET_EHS_PING", payload: ehsPing }),
        setChat: (activityId, rscScope, ehsPingId) => dispatch(setChat(activityId, rscScope, ehsPingId))

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EhsPingDetails));