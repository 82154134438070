import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import FormField from "../../eui-components/formfield";
import ApiClient from "../../utils/api-client";
import PageHelper from "../../utils/page-helper";
import { toast } from 'react-toastify';
import DateUtils from "../../utils/date-utils";
import constants from "../../utils/constants";
import AspHelper from "../../utils/asp-helper";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import { groupBy } from 'lodash';
import ApiHelper from "../../utils/api-helper";
import EuiSwitch from "../../eui-components/switch";
import SasHelper from "../../utils/sas-helper";

class ResourcesFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            legalStatuses: [],
            statuses: [],
            aspCompanies: [],
            tempAspCompanies: [],
            isLoading: false,
            initialState: false,
            certificates: [],
            disableControls: false,
            hideArchived: true
        }
    }

    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        const userCompanies = this.props.userData.userDetail?.userAspCompanies?.map(item => item.aspCompanyId);
        // if user role is reporter set countries based on his customer countries 
        var customerCountryIds = this.props.userData?.userDetail?.customers?.map(c => c.customer?.countryId);
        this.setState({ isLoading: true }, () => {

            this.setState({ isLoading: true }, () => {
                ApiHelper.setCountriesWithDetails((data) => this.props.setAllCountries(data), (data) => this.props.setAllCustomers(data));
                this.setState({
                    countries: this.props.userData.countries?.sort(PageHelper.dynamicSort("text")),
                    isLoading: false
                })
                this.props.setResourceCountries(this.props.userData.countries?.sort(PageHelper.dynamicSort("text")));
                this.props.setResourceCustomers(this.props.userData?.customers);

            });

            ApiClient.post("Asp/GetAllCompanies", {})
                .then(responseC => {
                    ApiClient.post("Asp/GetResourceData", {})
                        .then(allResponse => {
                            var companies = []
                            if (this.props.userData?.userData?.jobRole == constants.userRoles.ASP_PROJECT_MANAGER) {
                                companies = responseC.data?.filter(item => userCompanies?.includes(item.value))
                            }
                            else if (this.props.userData?.userData?.jobRole == constants.userRoles.REPORTER) {
                                companies = responseC.data?.filter(i => customerCountryIds?.includes(i.countryId));
                            }
                            else {
                                companies = responseC.data
                            }
                            var companiesCountryIds = companies.map(item => item.countryId);                            
                            if (this.props.userData.userData?.jobRole == constants.userRoles.ASP_PROJECT_MANAGER) {
                                this.setState({
                                    countries: this.props.userData.countries?.filter(item => companiesCountryIds?.includes(item.value)).sort(PageHelper.dynamicSort("text")),
                                })
                            }
                            else if (this.props.userData.userData?.jobRole == constants.userRoles.REPORTER) {
                                this.setState({
                                    countries: this.props.userData?.countries?.filter(item => customerCountryIds?.includes(item.value)).sort(PageHelper.dynamicSort("text")),
                                });
                            } else {
                                this.setState({
                                    countries: this.props.userData?.countries?.sort(PageHelper.dynamicSort("text")),
                                });
                            }
                            var certs = allResponse.data.certificates;
                            var statuses = allResponse.data.resourceStatuses;
                            //only getting PPE skills
                            var skills = allResponse.data.skills;
                            var projects = allResponse.data.resourceProjects;
                            var groupedSkills = groupBy(allResponse.data.skills, "skillArea");
                            var skills = allResponse.data.skills;
                            this.props.setResourceCompanies(companies.sort(PageHelper.dynamicSort("text")));
                            this.props.setResourceProjects(projects);
                            this.props.setSkills(skills);
                            this.props.setGroupedSkills(groupedSkills);
                            this.props.setStatuses(statuses);
                            this.setState({
                                aspCompanies: companies.sort(PageHelper.dynamicSort("text")),
                                tempAspCompanies: allResponse.data,
                                isLoading: false,
                            })
                            this.props.setCertificates(certs);
                            this.setState({
                                certificates: allResponse.data.certificates
                            })
                            this.props.setJobs(allResponse.data.jobs);
                            this.props.setIDTypes(allResponse.data.idTypes);
                            if (this.props.userData.userData.jobRole == "3") {
                                const filter = {};
                                filter['email'] = this.props.userData.userMail;
                                this.props.setResourceFilter(filter);
                                this.setState({
                                    disableControls: true
                                });
                                if (this.props.aspResourcesData.filter.email)
                                    this.getResources();
                            }
                            else if (this.props.userData.userData.jobRole == "4") {
                                const filter = {};
                                this.props.setResourceFilter(filter);
                                this.setState({
                                    disableControls: true
                                });
                            }
                        }).catch(error => {
                            alert(error);
                            this.setState({
                                isLoading: false
                            });
                        });

                }).catch(error => {
                    alert(error);
                    this.setState({
                        isLoading: false
                    })
                })
        })

    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;

        const value = target.value;
        const text = target.text;
        const filter = { ...this.props.aspResourcesData.filter };
        if (name == "country") {
            filter["country"] = value;
            filter["countryName"] = text;
            var temp = [];
            var companysIds = [];
            this.props.aspResourcesData.companies.forEach(c => {
                if (c.countryId == e.target.value)
                    temp.push(c);
            })

            this.setState({
                aspCompanies: (text ? temp : this.props.aspResourcesData.companies)
            })

            temp.forEach(e => {
                companysIds.push(e.value);
            });
            filter["companies"] = companysIds;
        }
        else if (name == 'archived') {
            filter[name] = target.checked;
            this.setState({
                hideArchived: !this.state.hideArchived
            });

        }
        else if (name == "companySelect") {
            /*   var Ids = [];
               value.forEach(v => {
                   Ids.push(v.value);
               })*/
            filter["companyId"] = value;
            // filter["companyName"] = text;
        }
        else {
            filter[name] = value || "";
        }
        this.props.setResourceFilter(filter);
    }

    getResources = () => {
        const filter = this.props.aspResourcesData.filter;
        if (!filter || Object.values(filter).every(x => (!x || x === ''))) {
            toast.error("Please, fill any field to get related resources", { containerId: "WS", position: toast.POSITION.TOP_CENTER });
            return;
        }
        else {
            if (filter.idsList && typeof filter.idsList == "string") {
                var _IdsArr = filter['idsList'].split(',');
                var IdsArr = [];
                _IdsArr.forEach(e => {
                    e = parseInt(e);
                    IdsArr.push(e);
                })
                filter['idsList'] = IdsArr;
                filter.id = parseInt(IdsArr[0]);
            }
            else if (filter.idsList) {
                filter.id = parseInt(filter['idsList'][0]);
            }
            else {
                filter.idsList = [];
                filter.id = parseInt("0");
            }
            this.setState({ isLoading: true }, () => {
                if (this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER) {
                    filter['userId'] = parseInt(this.props.userData.userData.userId);
                    ApiClient.post("Asp/GetResourcesForAspPm", filter)
                        .then(response => {
                            ApiClient.post("Asp/GetResourceJobs", response.data.iDs)
                                .then(response2 => {
                                    var _data = [];
                                    var blobs = response.data.profileBlobs;
                                    var certificates = [];
                                    var resourceProjects = response.data.projects;
                                    var resourceJobs = response2.data;
                                    var skills = response.data.skills;
                                    var faces = response.data.faces;
                                    this.props.setResourceUsers(response.data.users);
                                    response.data.certifications.forEach(c => {
                                        var _c = this.props.aspResourcesData.certificates.filter(x => x.value == c.certificationId);
                                        if (_c && _c[0]) {
                                            var date = DateUtils.getUtcDate();
                                            var diff = DateUtils.dateDifference(new Date(c.validUntil), new Date(date));
                                            var element = {
                                                ...c,
                                                validSince: DateUtils.toDateString(c.validSince),
                                                validUntil: DateUtils.toDateString(c.validUntil),
                                                createdate: DateUtils.toDateString(c.createdate),
                                                lastModifiedDateTime: DateUtils.toDateString(c.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                                                createUserName: c.createUserName,
                                                modifiedUserName: c.modifiedUserName,
                                                name: _c[0].text,
                                                code: _c[0].code,
                                                path: c.path,
                                                status: ((new Date(c.validUntil).getTime() - date.getTime()) > 0 ? "Valid" : "Expired"),
                                                _delete: c.id
                                            }
                                            certificates.push(element);
                                        }
                                    })
                                    this.props.setFilteredResources([]);
                                    response.data.resource.forEach(e => {
                                        var projects = AspHelper.mapProjects(resourceProjects?.filter(item => item.resourceId == e.id), this.props.aspResourcesData.projects, this.props.userData.customers);
                                        var country = this.state.countries?.filter(c => c.value === e.countryId);
                                        var company = this.props.aspResourcesData.companies?.filter(c => c.value === e.aspCompanyId);
                                        var cert = certificates.filter(c => c.resourceId === e.id);
                                        var jobs = [];
                                        var resourceSkills = skills?.filter(s => s.resourceId == e.id);
                                        var faceId = faces?.find(f => f.resourceId == e.id)?.faceId;
                                        var fullResourceSkills = AspHelper.getSkillInfo(resourceSkills, this.props.aspResourcesData.skills);
                                        var _jobs = resourceJobs.filter(j => j.resourceId == e.id);
                                        var inCompletedCerts = [];
                                        var validResource = false;
                                        _jobs.forEach(j => jobs.push(AspHelper.mapResourcesJobRole(j.value, this.props.aspResourcesData.jobs)));
                                        var blob = blobs.filter(b => b.resourceId == e.id);
                                        inCompletedCerts = AspHelper.getUnTakenCerts(cert, this.props.aspResourcesData.jobs, certificates, jobs);
                                      /*  if (e.status == "Active" && jobs.length) {
                                            var test = true;

                                            if (inCompletedCerts) {
                                                inCompletedCerts.forEach(c => {
                                                    if (!c.valid)
                                                        test = false;
                                                });
                                            }

                                            if (test)
                                                validResource = true;
                                        }
                                         */
                                        var test = true;
                                       if (inCompletedCerts) {
                                        inCompletedCerts.forEach(c => {
                                            if (!c.valid)
                                                test = false;
                                        });
                                    }
                                    validResource = test

                                        var element = {
                                            ...e,
                                            projects: projects,
                                            status: e.status,
                                            country: (country && country[0] ? country[0].text : ""),
                                            aspCompany: (company && company[0] ? company[0].text : ""),
                                            companyCountry: (company && company[0] ? company[0].countryId : ""),
                                            profileImg: (blob && blob[0] ? SasHelper.addSasKey(constants.profileStorage + blob[0].key , this.props.sasToken) : ""),
                                            certs: cert,
                                            jobs: (jobs ? jobs : []),
                                            jobList: (_jobs ? _jobs : []),
                                            skills: fullResourceSkills,
                                            inCompletedCerts: inCompletedCerts,
                                            validResource: validResource,
                                            faceId: faceId,
                                            path: (faceId ? e.path : null),
                                            imagePath: (faceId ? e.imagePath : null)

                                        }
                                        _data.push(element);
                                    });
                                    const data = [..._data];
                                    this.props.setFilteredResources(data);
                                    this.setState({ isLoading: false }, () => {
                                        this.props.changeInitialState();
                                    });
                                })
                        })

                        .catch(error => {
                            console.error(error);;
                            alert(error);
                            this.setState({ isLoading: false });
                        });
                }
                else {
                    ApiClient.post("Asp/GetResources", filter)
                        .then(response => {
                            ApiClient.post("Asp/GetResourceJobs", response.data.iDs)
                                .then(response2 => {
                                    var _data = [];
                                    var blobs = response.data.profileBlobs;
                                    var certificates = [];
                                    var resourceProjects = response.data.projects;
                                    var resourceJobs = response2.data;
                                    var skills = response.data.skills;
                                    var faces = response.data.faces;
                                    this.props.setResourceUsers(response.data.users);
                                    response.data.certifications.forEach(c => {
                                        var _c = this.props.aspResourcesData.certificates.filter(x => x.value == c.certificationId);
                                        if (_c && _c[0]) {
                                            var date = DateUtils.getUtcDate();
                                            var diff = DateUtils.dateDifference(new Date(c.validUntil), new Date(date));
                                            var element = {
                                                ...c,
                                                validSince: DateUtils.toDateString(c.validSince),
                                                validUntil: DateUtils.toDateString(c.validUntil),
                                                createdate: DateUtils.toDateString(c.createdate),
                                                lastModifiedDateTime: DateUtils.toDateString(c.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                                                createUserName: c.createUserName,
                                                modifiedUserName: c.modifiedUserName,
                                                name: _c[0].text,
                                                code: _c[0].code,
                                                path: c.path,
                                                status: ((new Date(c.validUntil).getTime() - date.getTime()) > 0 ? "Valid" : "Expired"),
                                                _delete: c.id
                                            }
                                            certificates.push(element);
                                        }
                                    })
                                    this.props.setFilteredResources([]);
                                    response.data.resource.forEach(e => {
                                        var projects = AspHelper.mapProjects(resourceProjects?.filter(item => item.resourceId == e.id), this.props.aspResourcesData.projects, this.props.userData.customers);
                                        var country = this.state.countries?.filter(c => c.value === e.countryId);
                                        var company = this.props.aspResourcesData.companies?.filter(c => c.value === e.aspCompanyId);
                                        var cert = certificates.filter(c => c.resourceId === e.id);
                                        var jobs = [];
                                        var resourceSkills = skills?.filter(s => s.resourceId == e.id);
                                        var faceId = faces?.find(f => f.resourceId == e.id)?.faceId;
                                        var fullResourceSkills = AspHelper.getSkillInfo(resourceSkills, this.props.aspResourcesData.skills);
                                        var _jobs = resourceJobs.filter(j => j.resourceId == e.id);
                                        var inCompletedCerts = [];
                                        var validResource = false;
                                        _jobs.forEach(j => jobs.push(AspHelper.mapResourcesJobRole(j.value, this.props.aspResourcesData.jobs)));
                                        var blob = blobs.filter(b => b.resourceId == e.id);
                                        inCompletedCerts = AspHelper.getUnTakenCerts(cert, this.props.aspResourcesData.jobs, certificates, jobs);
                                       
                                       var test = true;
                                       if (inCompletedCerts) {
                                        inCompletedCerts.forEach(c => {
                                            if (!c.valid)
                                                test = false;
                                        });
                                    }
                                    validResource = test
                                       
                                       /* if (e.status == "Active" && jobs.length) {
                                            var test = true;

                                            if (inCompletedCerts) {
                                                inCompletedCerts.forEach(c => {
                                                    if (!c.valid)
                                                        test = false;
                                                });
                                            }

                                            if (test)
                                                validResource = true;
                                        }
                                            */

                                        var element = {
                                            ...e,
                                            projects: projects,
                                            status: e.status,
                                            country: (country && country[0] ? country[0].text : ""),
                                            aspCompany: (company && company[0] ? company[0].text : ""),
                                            companyCountry: (company && company[0] ? company[0].countryId : ""),
                                            profileImg: (blob && blob[0] ? SasHelper.addSasKey(constants.profileStorage + blob[0].key, this.props.sasToken) : ""),
                                            certs: cert,
                                            jobs: (jobs ? jobs : []),
                                            jobList: (_jobs ? _jobs : []),
                                            skills: fullResourceSkills,
                                            inCompletedCerts: inCompletedCerts,
                                            validResource: validResource,
                                            faceId: faceId,
                                            path: (faceId ? e.path : null),
                                            imagePath: (faceId ? e.imagePath : null)

                                        }
                                        _data.push(element);
                                    });
                                    const data = [..._data];
                                    this.props.setFilteredResources(data);
                                    this.setState({ isLoading: false }, () => {
                                        this.props.changeInitialState();
                                    });
                                })
                        })

                        .catch(error => {
                            console.error(error);;
                            alert(error);
                            this.setState({ isLoading: false });
                        });
                }

            });
        }


    }

    clear = (e) => {
        const target = e.target;
        const name = target.name;

        const filter = { ...this.props.aspResourcesData.filter };
        if (name == "country") {
            filter["country"] = "";
            filter["countryName"] = "";
        }
        if (name == "companySelect") {

            filter["companyId"] = "";
            filter["companyName"] = "";
        }
        this.props.setResourceFilter(filter);

    }

    clearFilters = () => {
        var filter = { ... this.props.aspResourcesData.filter };
        filter["companyName"] = "";
        filter["countryName"] = "";
        //filter = {};
        this.props.setResourceFilter({});
        this.setState({
            initialState: !this.state.initialState
        })

        //this.props.setFilter({});
    }

    render() {
        return (<EuiRow key={this.state.isLoading}>
            <FormField className="w-10">
                <EuiSwitch
                    labelStyle={{ width: "100%", color: "gray", fontSize: 14 }}
                    id="filterArchived"
                    onChange={(e) => this.handleChange(e)}
                    checked={this.state.hideArchived}
                    dataEnabled="Exclude Archived"
                    dataDisabled="All Resources"
                    name="archived"
                />
            </FormField>
            <FormField className="w-10">
                <EuiTextField className="w-10" fullwidth id="filterResourcesid" name="idsList" labelClass="color-gray" label="Resource ID (*)"
                    clear={true}
                    onClear={() => this.handleChange({ target: { value: "", name: "idsList" } })} value={(this.props.aspResourcesData.filter.idsList == 0 || !this.props.aspResourcesData.filter.idsList ? "" : this.props.aspResourcesData.filter.idsList)} onChange={(e) => this.handleChange(e)} />
                <label className="color-gray">Use comma for multiple search</label>
            </FormField>
            <FormField className="w-10">
                <EuiTextField
                    className="w-10" fullwidth id="filterResourcesName" name="name" label="Employee Name (*)" labelClass="color-gray"
                    value={(this.props.aspResourcesData.filter.name ? this.props.aspResourcesData.filter.name : "")} onChange={(e) => this.handleChange(e)}
                    clear={true}
                    onClear={() => this.handleChange({ target: { value: "", name: "name" } })} />
            </FormField>
            <FormField className="w-10">
                <EuiTextField className="w-10" fullwidth id="filterResourcesEmail" name="email" label="Employee Email (*)" labelClass="color-gray"
                    value={(this.props.aspResourcesData.filter.email ? this.props.aspResourcesData.filter.email : "")} onChange={(e) => this.handleChange(e)}
                    clear={true}
                    onClear={() => this.handleChange({ target: { value: "", name: "email" } })}
                />
            </FormField>

            <FormField className="w-10">
                <label className="color-gray" style={{ fontSize: "14px" }} htmlFor="companySelect">Select Country</label>

                <EuiAutocompleteSelect
                    className="w-10"
                    fullwidth
                    key={this.state.countries}
                    id="countryResourcesSelect"
                    name="country" label="Select Country"
                    options={this.state.countries}
                    labelClass="color-blue"
                    value={this.props.aspResourcesData.filter.country}
                    onChange={(e) => this.handleChange(e)}
                    optionListStyle={{ width: "100%" }}
                />
            </FormField>
            <FormField className="w-10">
                <label className="color-gray" style={{ fontSize: "14px" }} htmlFor="companySelect">Select Company</label>
                <EuiAutocompleteSelect
                    key={this.state.aspCompanies}
                    className="w-10"
                    label="Select Company"
                    id="companyResourcesSelect"
                    optionListStyle={{ width: "100%" }}
                    name="companySelect"
                    options={this.state.aspCompanies}
                    value={this.props.aspResourcesData.filter.companyId}
                    onChange={(e) => this.handleChange(e)}
                    fullwidth
                />
            </FormField>
            <span className="flex-start-content">
                <span className="w-10" style={{ width: "130px" }}>
                    <button className={"btn primary m-1 " + (this.state.isLoading ? "loading disabled" : (this.state.disableControls ? "disabled" : ""))} onClick={() => this.getResources()}>Search</button>
                </span>
                <span>
                    <button className="btn " onClick={this.clearFilters}> Clear </button>
                </span>
            </span>
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, userAdminData: state.userAdmin, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        setFilteredResources: (filteredResources) => dispatch({ type: "SET_FILTERED_RESOURCES", payload: filteredResources }),
        setResourceFilter: (filter) => dispatch({ type: "SET_RESOURCE_FILTER", payload: filter }),
        setResourceCompanies: (companies) => dispatch({ type: "SET_RESOURCE_COMPANIES", payload: companies }),
        setResourceCountries: (countries) => dispatch({ type: "SET_RESOURCE_COUNTRIES", payload: countries }),
        setCertificates: (certs) => dispatch({ type: "SET_CERTIFICATES", payload: certs }),
        setJobs: (jobs) => dispatch({ type: "SET_JOBS", payload: jobs }),
        setIDTypes: (types) => dispatch({ type: "SET_ID_TYPES", payload: types }),
        updateTable: () => dispatch({ type: "UPDATE_TABLE" }),
        setSkills: (skills) => dispatch({ type: "SET_SKILLS", payload: skills }),
        setGroupedSkills: (skills) => dispatch({ type: "SET_GROUPED_SKILLS", payload: skills }),
        setResourceUsers: (users) => dispatch({ type: "SET_USERS", payload: users }),
        setStatuses: (statuses) => dispatch({ type: "SET_RESOURCE_STATUSES", payload: statuses }),
        setResourceCustomers: (customers) => dispatch({ type: "SET_RESOURCE_CUSTOMERS", payload: customers }),
        setResourceProjects: (projects) => dispatch({ type: "SET_RESOURCE_PROJECTS", payload: projects }),
        setAllCountries: (countries) => dispatch({ type: "SET_ALL_COUNTRIES", payload: countries }),
        setAllCustomers: (customers) => dispatch({ type: "SET_ALL_CUSTOMERS", payload: customers }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourcesFilter);