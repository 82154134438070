const initialState = {
    mod: 1,
    filter: {},
    filteredSites: { data: [], totalCount: 0, initialState: 0 },
    site: { id: 0 }
};

export default (state = initialState, action) => {
    switch (action.type) {

        case "SET_SITE":
            return {
                ...state,
                site: action.payload
            };
        case "CHANGE_SITE_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_FILTERED_SITES":
            return {
                ...state,
                filteredSites: action.payload
            };
        case "SET_SITE_FILTER":
            return {
                ...state,
                filter: action.payload
            };

        default:
            return state;
    }

};
