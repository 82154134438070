import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import constants from "../../utils/constants";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import EuiDatepicker from "../../eui-components/datepicker";
import AutoCompleteSelect from "../../components/autocompleteselect";
import FormField from "../../eui-components/formfield";
import ApiClient from "../../utils/api-client";
import WorkplanHelper from "../../utils/workplan-helper";
import EuiSelect from "../../eui-components/select";
import { withRouter } from "react-router-dom";

class WorkplanInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sites: [],
            projects: []
        }
    }

    componentDidMount() {
        const workplan = this.props.workplanData.workplan;
        if (workplan.id > 0) {
            this.setState({
                projects: (this.props.userData.customersWithProjects ?? []).find(item => item.value === workplan.customerId)?.projects,
                sites: [workplan.siteModel]
            });
        }
        
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        var value = target.value;
        const text = target.text;
        
        const workplan = { ...this.props.workplanData.workplan };
        if (name === "customerId") {
            workplan.customerName = text;
            workplan.siteId = null;

            this.setState({
                projects: (this.props.userData.customersWithProjects ?? []).find(item => item.value === value)?.projects,
                sites: []
            });
        }
        else if (name === "wpType") {
            if (!value || value != constants.wpTypeFarend) {
                workplan.farendId = null;
            }
            value = WorkplanHelper.getWpTypeText(value);
        }
        else if (name === "farendSiteModel") {
            workplan['farendId'] = value.value;
            workplan['farendName'] = value.text;
            workplan['farendSiteModel'] = value;
        }
        workplan[name] = value;
        workplan['lastModifiedUserName'] = this.props.userData.userData.username;
        this.props.setWorkplan(workplan);
    }

    handleRemoteChange = (e, value) => {
        if (!e || !value) {
            return;
        }
        const target = e.target;
        
        
        const filter = { customerId: this.props.workplanData.workplan.customerId, name: value }
        
        if (value && value.length > 2) {
            this.setState({ isLoading: true }, () => {
                ApiClient.post("Workplan/GetSitesOfCustomer", filter)
                    .then(response => {
                        
                        this.setState({ sites: response.data, isLoading: false });
                    }).catch(error => {
                        console.error(error);;
                        this.setState({ sites: [], isLoading: false });
                    });
            })
        }
    }

    goSiteDetailPage = () => {
        this.props.setSiteFilter({ ...this.props.siteData.filter, id: this.props.workplanData.workplan.siteId });
        this.props.history.push("/sites");
    }

    render() {
        return (<EuiRow key={this.props.workplanData.workplan}>
            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                required
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="workplanName"
                name="name"
                label="Workplan Name"
                value={this.props.workplanData.workplan.name}
                onChange={(e) => this.handleChange(e)} />

            {this.props.mod === constants.actionMods.VIEW_ONLY ?
                <>
                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="customerNameWorkplan"
                        name="customerName"
                        label="Customer Name"
                        value={this.props.workplanData.workplan.customerName} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="projectName"
                        name="projectName"
                        label="Project Name"
                        value={this.props.workplanData.workplan.projectName} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="siteName"
                        name="siteName"
                        label="Site Name"
                        value={this.props.workplanData.workplan.siteName}
                        additionalInfo={<a onClick={this.goSiteDetailPage}>{this.props.workplanData.workplan.siteId}</a>}
                    />
                </>
                :
                <>
                    <EuiAutocompleteSelect
                        className="p-1 w-5 mb-1"
                        fullwidth
                        style={{ height: "26px" }}
                        required
                        label={{ title: "Type Site", className: "color-gray required" }}
                        id="typeSiteWorkplanSelect"
                        name="typeSiteId"
                        options={constants.typeSites}
                        value={this.props.workplanData.workplan.typeSiteId || -1}
                        onChange={(e) => this.handleChange(e)}
                    />
                    <EuiAutocompleteSelect
                        className="p-1 w-5"
                        fullwidth
                        required
                        label={{ title: "Customer", className: "color-gray required" }}
                        id="customerWorkplanSelect"
                        name="customerId"
                        options={this.props.userData.customersWithProjects || []}
                        value={this.props.workplanData.workplan.customerId || 0}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="---Select Customer---"
                    />
                    <EuiAutocompleteSelect
                        key={this.state.projects}
                        className="p-1 w-5"
                        disabled={!this.props.workplanData.workplan.customerId}
                        fullwidth
                        required
                        label={{ title: "Project", className: "color-gray required" }}
                        id="projectWorkplanSelect"
                        name="projectId"
                        options={this.state.projects}
                        value={this.props.workplanData.workplan.projectId || 0}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="---Select Project---"
                    />
                    <FormField className="w-10">
                        <label className="required" htmlFor="workplanSites" style={{ color: "gray", fontSize: 14 }}>Site Name</label>
                        <AutoCompleteSelect
                            disabled={!this.props.workplanData.workplan.customerId}
                            required={true}
                            id="workplanSites"
                            options={this.state.sites}
                            name="siteModel"
                            onChange={e => this.handleChange(e)}
                            onInputChange={(e, value) => this.handleRemoteChange(e, value)}
                            value={this.props.workplanData.workplan.siteModel}
                            placeholder="Start typing site name, a dropdown with options will appear"
                            isLoading={this.state.isLoading}
                        />
                    </FormField>
                </>
            }
            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="wpAssignmentIDWorkplan"
                name="wpAssignmentID"
                label="WP Assignment ID"
                value={this.props.workplanData.workplan.wpAssignmentID}
                onChange={(e) => this.handleChange(e)} />
            {this.props.mod === constants.actionMods.VIEW_ONLY ?
                <EuiTextField className="p-1 w-5"
                    fullwidth
                    required
                    readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                    style={{ padding: 0 }}
                    labelstyle={{ color: "gray" }}
                    id="wpTypeWorkplan"
                    name="wpType"
                    label="WP Type"
                    value={this.props.workplanData.workplan.wpType}
                    onChange={(e) => this.handleChange(e)} />
                :
                <div className="p-1 w-5" >
                    <label style={{ color: "gray" }} htmlFor="wpTypeWorkplan" className="required w-10">WP Type</label>
                    <EuiSelect className="w-10"
                        fullwidth
                        required
                        readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                        labelstyle={{ color: "gray" }}
                        id="wpTypeWorkplan"
                        name="wpType"
                        label="WP Type"
                        options={constants.WpTypes}
                        value={WorkplanHelper.getWpTypeId(this.props.workplanData.workplan.wpType)}
                        optionListStyle={{ width: "100%" }}
                        onChange={(e) => this.handleChange(e)} />
                </div>
            }

            {this.props.workplanData.workplan.wpType !== constants.wpTypeFarend ? null :
                this.props.mod === constants.actionMods.VIEW_ONLY ?
                    <EuiTextField className="p-1 w-5"
                        readOnly
                        fullwidth
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="farendNameWorkplan"
                        name="farendId"
                        label="Farend Site Name"
                        value={this.props.workplanData.workplan.farendName} />
                    :
                    <FormField className="w-10">
                        <label className="required" htmlFor="farendWorkplanSites" style={{ color: "gray", fontSize: 14 }}>Farend Site Name</label>
                        <AutoCompleteSelect
                            disabled={!this.props.workplanData.workplan.customerId}
                            required={true}
                            id="farendWorkplanSites"
                            options={this.state.sites}
                            name="farendSiteModel"
                            onChange={e => this.handleChange(e)}
                            onInputChange={(e, value) => this.handleRemoteChange(e, value)}
                            value={this.props.workplanData.workplan.farendSiteModel}
                            placeholder="Start typing farend site name, a dropdown with options will appear"
                            isLoading={this.state.isLoading}
                        />
                    </FormField>
            }
            {this.props.mod === constants.actionMods.VIEW_ONLY ?
                <>
                    <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }}
                        id="workplanCreateDate"
                        name="createDate"
                        label="Workplan Created date"
                        value={DateUtils.toDateString(this.props.workplanData.workplan.createDate) || "(-----,----)"} />
                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="lastModifiedUser"
                        name="lastModifiedUser"
                        label="Last Modified User"
                        value={this.props.workplanData.workplan.lastModifiedUserName} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="lastModifiedDate"
                        name="lastModifiedDate"
                        label="Last Modified Date Time"
                        value={DateUtils.toDateString(this.props.workplanData.workplan.lastModifiedDate, "YYYY-MM-DD HH:mm")} />

                </>
                : <>
                    <EuiDatepicker
                        className="p-1 w-5"
                        id="workplanPlannedStart"
                        label="Planned Start"
                        disabled={this.props.mod === constants.actionMods.VIEW_ONLY}
                        labelStyle={{ color: "gray" }}
                        placeholder="YYYY-MM-DD"
                        name="plannedStart"
                        value={this.props.workplanData.workplan.plannedStart}
                        onChange={e => this.handleChange(e)}
                    />

                    <EuiDatepicker
                        className="p-1 w-5"
                        id="workplanPlannedEnd"
                        label="Planned End"
                        disabled={this.props.mod === constants.actionMods.VIEW_ONLY}
                        labelStyle={{ color: "gray" }}
                        placeholder="YYYY-MM-DD"
                        name="plannedEnd"
                        value={this.props.workplanData.workplan.plannedEnd}
                        onChange={e => this.handleChange(e)}
                    />
                </>
            }

        </EuiRow>);
    }
}

const mapStateToProps = state => ({
    userData: state.user, navbarData: state.navigation,
    workplanData: state.workplan,
    siteData: state.site
});
const mapDispatchToProps = dispatch => {
    return {
        setWorkplan: (workplan) => dispatch({ type: "SET_WORKPLAN", payload: workplan }),
        setSiteFilter: (filter) => dispatch({ type: "SET_SITE_FILTER", payload: filter }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WorkplanInfo));