import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import EuiTable from "../../eui-components/table";
import EuiTabs from "../../eui-components/tabs";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import PageHelper from "../../utils/page-helper";

const AspPmActivityDetails = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [columns, setColumns] = useState(
        [{
            title: "Activity ID",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = `<a id="activity_${cellData}">${cellData}</a>`;
                td.querySelector("#activity_" + cellData).addEventListener('click', () => this.openActivityDetail(cellData))
            },
            key: "id",
            sort: "asc",
            width: "10%"
        },
        {
            title: "",
            key: "isTemplate",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<span class="tooltip"><i id="assignedicon" class="icon icon-view-list text-lg "></i><span class="message top-end">Activivty assigned to checklist</span></span>`;
                }
                else td.innerHTML = ""
            }
        },
        {
            title: "Activity Name",
            key: "name",
            sort: "none",
            width: "23%"
        },

        {
            title: "Workplan Name",
            key: "workplanName",
            sort: "none",

            width: "15%"
        },
        {
            title: "Site Name",
            key: "siteName",
            sort: "none",

            width: "10%"
        },
        {
            title: "ASP Company | (Team Leader | EHS Auditor)",
            key: "aspCompanyAndTeamLead",
            sort: "none",
            width: "25%"
        },

        {
            title: "Status",
            hideFilter: true,
            key: "acceptanceStatusId",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                const status = PageHelper.renderActivityStatus(cellData);
                td.innerHTML = `${status}`;
            },
            width: "10%"
        },

        ]
    );


    const activityData = useSelector(state => state.activity);
    const aspPmActivities = activityData.aspPmActivities || [];

    const [assignedActivities, setAssignedActivities] = useState(aspPmActivities?.filter(item => item.resourceId));
    const [closedActivities, setClosedActivities] = useState(aspPmActivities?.filter(item => item.actualEnd));
    const [unAssignedActivities, setUnAssignedActivities] = useState(aspPmActivities?.filter(item => !item.resourceId && !item.actualEnd));

    useEffect(() => {
        setAssignedActivities(aspPmActivities?.filter(item => item.resourceId && !item.actualEnd));
        setClosedActivities(aspPmActivities?.filter(item => item.actualEnd));
        setUnAssignedActivities(aspPmActivities?.filter(item => !item.resourceId && !item.actualEnd));
    }, [activityData.aspPmActivities]);


    const titles = [{ id: 1, value: 1, name: "Assigned", selected: "selected", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } },
    { id: 2, value: 2, name: "Un Assigned", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } },
    { id: 3, value: 3, name: "Closed", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } }
    ];

    const contents = [{
        value: 1, children: <ObseleteEuiTable id="assignedAspPmActivities"
            data={assignedActivities}
            sfilter={true}
            hideSearch={true}
            scroll={false}
            sortable={true}
            paginated={true}
            columns={columns}
        />, selected: "selected", contentStyle: { width: "100%" }
    },
    {
        value: 2, children: <ObseleteEuiTable id="unAssignedAspPmActivities"
            data={unAssignedActivities}
            filter={true}
            hideSearch={true}
            scroll={false}
            sortable={true}
            paginated={true}
            columns={columns}
        />, selected: "", contentStyle: { width: "100%" }
    },
    {
        value: 3, children: <ObseleteEuiTable id="closedAspPmActivities"
            data={closedActivities}
            filter={true}
            hideSearch={true}
            scroll={false}
            sortable={true}
            paginated={true}
            columns={columns}
        />, selected: "", contentStyle: { width: "100%" }
    }];
    return (
        <EuiRow className={isLoading ? "disabled" : ""}>
            <EuiLoading isLoading={isLoading} />
            <EuiTabs
                titlesColumnStyle={{ marginBottom: "10px" }}
                contentStyle={{ width: "100%" }}
                tabStyle={{ width: "100%" }}
                id="_aspPmActivitiesTabs"
                titles={titles}
                contents={contents}
            />
        </EuiRow>
    )
}

export default AspPmActivityDetails;