import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import { ToastContainer, toast } from 'react-toastify';
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import EuiSearchField from "../../eui-components/searchfield";
import ResourcesFilter from "./resourcesFilter";
import ResourcesList from "./resourcesList";
import constants from "../../utils/constants";


class ResourcesSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            filter: {},
            resources: null,
            initialState: 0

        }
    }

    componentDidMount() {
    }

    changeInitialState = () => {
        this.setState({ initialState: 1 });
    }

    createResource = () => {
        this.props.setAspResource({ id: 0, jobs: [], jobList: [] });
        this.props.createEditResourceForm(true);
        this.props.setCreateResource(true);
    }

    render() {
        const leftPanel = {
            title: "Filter By:",
            content: <ResourcesFilter changeInitialState={() => this.changeInitialState()} />,
            hidden: "hidden"
        };
        const resources = this.props.aspResourcesData.filteredResources;
        const midPanel = {
            title: "Filtered Resources",
            content: this.state.initialState === 0 && resources.length === 0 ? <div className="flex-middle-content m-3 w-10 text-lg">Please click on filter to get a list of resources</div>
                : <ResourcesList />               // rightIcon: <EuiSearchField placeholder="Type to search..." />
        };
 
        return (<EuiRow>
            <EuiMultiPanelTile id="aspResourcesnMultiPanel" midPanelButton={true}
                className="fullscreen"
                button={<button hidden={
                    (this.props.userData.userData.jobRole == constants.userRoles.ERICSSON_IM
                    //|| this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER
                    || this.props.userData.userData.jobRole == constants.userRoles.RSC_OHS_INSPECTOR
                    || this.props.userData.userData.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR
                    || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                    ? false : true)} className="btn primary" onClick={this.createResource}><span><i className="icon icon-plus" />Add Employee</span></button>}
                leftPanel={leftPanel}
                midPanel={midPanel}
                leftPanelStyle={{ width: "230px" }}
                style={{ paddingRight: 23 }} />
            <ToastContainer enableMultiContainer containerId={'WS'} autoClose={2500} />
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp });
const mapDispatchToProps = dispatch => {
    return {
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        createEditResourceForm: (state) => dispatch({ type: "CREATE_EDIT_RESOURCE_FORM", payload: state }),
        setCreateResource: (state) => dispatch({ type: "SET_CREATE_RESOURCE", payload: state }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ResourcesSearch)); 