import {
  Fab,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Notification } from "@eds/vanilla";

import {
  File,
  LinkSimple,
  Smiley,
} from "phosphor-react";
import { isEmpty } from "lodash";
import DialogModal from "../../dialog";
import UploadDocuments from "../../documentUploadModal/documentsUploader";
import PageHelper from "../../../utils/page-helper";
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../../utils/api-client";

import constants from "../../../utils/constants";
import EuiProgressBar from "../../../eui-components/progressbar";
import DateUtils from "../../../utils/date-utils";
import SasHelper from "../../../utils/sas-helper";

const StyledInput = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingTop: "12px !important",
    paddingBottom: "12px !important",
    width: "90%",
    height: "45px !important"
  },
}));

const Actions = [
  {
    color: "#0159b2",
    icon: <File size={24} />,
    y: 102,
    title: "Document",

    //   color: "#4da5fe",
    //   icon: <Image size={24} />,
    //   y: 102,
    //  title:  "Photo/Video",
    // onchange:{handletjf},

    // content: <RHFUploadAvatar  />

    //  content: <div>{<RHFUploadAvatar name="avatar"  maxSize={3145728} onDrop={handletjf('mmmm')} />}</div>
  },
  // {
  //   color: "#1b8cfe",
  //   icon: <Sticker size={24} />,
  //   y: 172,
  //   title: "Stickers",
  // },
  // {
  //   color: "#0172e4",
  //   icon: <Camera size={24} />,
  //   y: 242,
  //   title: "Image",
  // },
  // {
  //   color: "#0159b2",
  //   icon: <File size={24} />,
  //   y: 312,
  //   title: "Document",
  // },
  // {
  //   color: "#013f7f",
  //   icon: <User size={24} />,
  //   y: 382,
  //   title: "Contact",
  // },
];

function ChatInput(props) {
  const { setIsLoading, ehsPingId,connectionStatus,refreshAndReconnect } = props;
  const dispatch = useDispatch();

  const files = useSelector((state) => state.documents.chatDocuments);
  const userData = useSelector((state) => state.user);
  const user_id = parseInt(userData.userData.userId);
  const { room_id } = useSelector((state) => state.chat);

  const {
    openPicker,
    setOpenPicker,
    setValue,
    value,
    inputRef,
    sendTextMessage,
    connection,
    createNewConversationOrRoom
  } = props;
  const [openActions, setOpenActions] = React.useState(false);
  const [openDiaolgUploadBar, setOpenDialogUploadBar] = useState(false);

  const [sasToken, setSasToken] = useState({});

  const [openDialog, setOpenDialog] = React.useState(false);
  const [percentageArr, setPercentageArray] = useState([]);

  const { current_conversation, participantInvited } = useSelector(
    (state) => state.conversation.direct_chat
  );
  const roomId = useSelector(state => state.room?.room_id);

useEffect(() => {
 console.log("new room redux from ChatInput",roomId);
}, [])



  useEffect(() => {
    if (!sasToken) {
      SasHelper.getSasKey().then((response) => {
          setSasToken(response);
      });
    } else {
      setIsLoading(false);
    }
  }, []);
  let documentsIntialList = [];
  function isImageFile(str) {
    // Regex to check valid
    // Image File
    let regex = new RegExp(
      /[^\s]+(.*?).(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF|ico|ICO)$/
    );
    // if str
    // is empty return false
    if (str == null) {
      return false;
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  }

  function isVideoFile(str) {
    // Regex to check valid
    // Image File
    let regex = new RegExp(
      /[^\s]+(.*?).(mp4|avi|mov|mpv|mpe|mpeg|mp2|mpg)$/
    );
    // if str
    // is empty return false
    if (str == null) {
      return false;
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  }

  function isDocFile(str) {
    // Regex to check valid
    // Image File
    let regex = new RegExp(
      /[^\s]+(.*?).(pdf|tsv|txt|csv|PDF|doc|docx|xls|xlsx|ppt|pptx|pptm|json)$/
    );
    // if str
    // is empty return false
    if (str == null) {
      return false;
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  }
  const createPercentage = (progressEvent, file) => {
    let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
    let percentageArray = [...percentageArr];
    const index = percentageArray.indexOf(file);
    percentageArray[index].percentage = percentage;
    setPercentageArray(percentageArray);
  };

  const addDocuments = () => {
    if (!documentsIntialList || documentsIntialList.length === 0) {
      const notification = new Notification({
        title: "Upload Failed",
        description: "No selected files to be uploaded!",
        timeout: 5000,
        icon: "icon-check",
      });
      notification.init();
      return;
    }
    if(connection== null || connection.state!=="Connected"){
      const notification = new Notification({
        title: 'Not connected',
        description: 'Reconnecting.. Please wait!',
        timeout: 5000,
        icon: 'icon-triangle'
      });
      notification.init();
      refreshAndReconnect(); //

    }else{
      createNewConversationOrRoom();

      const docs = documentsIntialList.map((item) => {
        if (connection) {
          // saif hub
  
  
          const user = (participantInvited || [])?.find((elm) => parseInt(elm?.userId) === parseInt(user_id));
  
  
          const userItem = {};
          userItem.name = user?.userName;
          userItem.email = user?.email;
          userItem.avatar = user?.profileAvatar
          userItem._id = user_id
  
          const newMessage = {};
          const arrayMsg = [];
          newMessage.text = item.title;
          newMessage.image = isImageFile(item.title)
            ? decodeURIComponent(item.documentPath)
            : null;
          newMessage.file = !isImageFile(item.title)  && !isVideoFile(item.title) ? decodeURIComponent(item.documentPath) : null;
          newMessage.createdAt = DateUtils.toUtcDate(new Date());
          newMessage.time = DateUtils.toUtcDate(new Date());
          newMessage.video = isVideoFile(item.title) ? decodeURIComponent(item.documentPath) : null;
          newMessage.ehsPingId = ehsPingId;
          newMessage.user = userItem;
  
  
          newMessage.activityId = current_conversation?.activityid;
          arrayMsg.push(newMessage);
          connection.invoke("SendMessageAsync", room_id+ "", user_id + "", JSON.stringify(arrayMsg))
            .catch(function (err) {
              console.log("SendMessageAsync", err);
            });
  
          //#endregion
  
        }
      });
      
    }

  };
  useEffect(() => {
    if (openDiaolgUploadBar) {
      const onProgress = createPercentage;
      ///console.log("first progress" , onProgress);
      ///console.log("first progress from docs", files);

      PageHelper.uploadItems(files, onProgress, "chat-files")
        .then((uploadedFiles) => {
          const notification = new Notification({
            title: "Document is uploaded successfully!",
            timeout: 5000,
            icon: "icon-check",
          });
          notification.init();
          setOpenDialogUploadBar(false);
          documentsIntialList = uploadedFiles;
          addDocuments();
        })
        .catch((error) => {
          ///console.log("first progress error", error);
        });
    }
    // if (confirm == true) {
    //     softDeleteDocument();
    //     setConfirm(false);
    //     setOpenWarning(false);
    // }
  }, [openDiaolgUploadBar]);

  const uploadDocuments = () => {
    var flag = false;
    const fileSize = PageHelper.checkFilesSize(files);
    if (fileSize) {
      const notification = new Notification({
        title: "Upload Failed",
        description: fileSize,
        timeout: 5000,
        icon: "icon-triangle-warning",
      });
      notification.init();
      setIsLoading(false);

      return;
    } else {
      files.map((item) => {
        if (item.documentType == null) {
          flag = true;
        }
      });
      if (flag) {
        const notification = new Notification({
          title: "Upload Failed",
          description: "Please add all document types!",
          timeout: 5000,
          icon: "icon-triangle-warning",
        });
        notification.init();
        setIsLoading(false);

        return;
      } else {
        PageHelper.getCoordinates();
        setOpenDialog(false);
        setOpenDialogUploadBar(true);
        setPercentageArray(PageHelper.initializePercentageArr(files));
      }
    }
  };

  function handletjf(title) {
    ///console.log("hii ffff", title);
    setOpenDialog(true);
  }



  return openDialog ? (
    <>
      <DialogModal
        id="chatDocumentsDialog"
        style={{ height: "1000px" }}
        title="Add Documents"
        content={
          <UploadDocuments
            id="chatDocumentsDialog"
            dispatch="SET_CHAT_DOCUMENTS"
            columns={{ name: true, type: false }}
          />
        }
        buttonName="Send"
        handleClose={() => setOpenDialog(false)}
        onClick={() => uploadDocuments()}
        contentStyle={{ height: "400px", width: "auto" }}// width: "800px", 
        open={openDialog}
        disableBackdropClick={true}
      />
    </>
  ) : (
    <>
      <StyledInput
        multiline
        rows={2}
        style={{ width: "auto", }}
        inputRef={inputRef}
        value={value}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !isEmpty(value)) {

            // write your functionality here
          }
        }}
        onChange={(event) => {
          setValue(event.target.value);
          setOpenPicker(false);
          setOpenActions(false);
        }}
        fullWidth
        placeholder="Write a message..."
        variant="filled"
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <Stack sx={{ width: "max-content" }}>
              <Stack
                sx={{
                  position: "relative",
                  display: openActions ? "inline-block" : "none",
                }}
              >
                {Actions.map((el) => (
                  <Tooltip placement="right" title={el.title}>
                    <Fab
                      onClick={() => {
                        handletjf(el.title);

                        // ///console.log("setOpenActions",el)
                        setOpenActions(!openActions);
                      }}
                      sx={{
                        position: "absolute",
                        top: -el.y,
                        backgroundColor: el.color,
                      }}
                      aria-label="add"
                    >
                      {el.icon}
                    </Fab>
                  </Tooltip>
                ))}
              </Stack>

              <InputAdornment>
                <IconButton
                  onClick={() => {
                    ///console.log("sopenActions", openActions);
                    setOpenActions(!openActions);
                  }}
                >
                  <LinkSimple />
                </IconButton>
              </InputAdornment>
            </Stack>
          ),
          endAdornment: (
            <>
              <Stack sx={{ position: "relative" }}>
                <InputAdornment>
                  <IconButton
                    onClick={() => {
                      setOpenPicker(!openPicker);
                    }}
                  >
                    <Smiley />
                  </IconButton>
                </InputAdornment>
              </Stack>
              <Stack sx={{ position: "relative" }}></Stack>
            </>
          ),
        }}
      />
      <DialogModal
        content={percentageArr.map((file) => {
          return (
            <div key={file.name} style={{ width: 500 }}>
              <label>
                <strong>{file.title}</strong>
              </label>
              <EuiProgressBar value={file.percentage} />
            </div>
          );
        })}
        handleClose={() => setOpenDialogUploadBar(false)}
        id="uploadChatDocumentsDialog"
        titleId="titleuploadBarChatDocumentsUploadDialog"
        open={openDiaolgUploadBar}
        buttonsVisible="false"
        title="Progress Loading..."
        closeButtonVisible="false"
        key={percentageArr}
        disableBackdropClick={true}
      ></DialogModal>
    </>
  );
}

export default ChatInput;
