import React from 'react';
import { Button,  Badge } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function HoverableButton(props) {

  const {
    disabled,
    onClick,
    isMandatory,
    selected,
    selectedNumber,
    outlinedIcon,
    title,
    colorText,
    backgroundColor,
  } = props;


  return title ? (

      <span style={{ color:colorText  }}>
        {/* <Badge badgeContent={selectedNumber} color="primary" > */}
          <Button
            {...props}
       
            type="submit" //228 HEI 100
            style={{height: 80 ,width: 170,backgroundColor:selected? backgroundColor :"inherit"}}// 
            disabled={disabled}
            onClick={onClick}
            color={ selected ? colorText : 'inherit'
            }
            startIcon={outlinedIcon}
          >
          <p style={{ wordWrap: "All" }}>{title}</p>  
          </Button>
        {/* </Badge> */}
      </span>

  ) : (
 
      <span style={{ color: '#616161' }}>
        <Button
          disabled={disabled}
          onClick={onClick}
          color={isMandatory ? 'secondary' : selected ? 'secondary' : 'inherit'}
        >
          <Badge badgeContent={selectedNumber} color="primary">
            {outlinedIcon}
          </Badge>
        </Button>
      </span>
 
  );
}

HoverableButton.propTypes = {
  tooltipTitle: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isMandatory: PropTypes.bool,
  selected: PropTypes.bool,
  selectedNumber: PropTypes.number,
  outlinedIcon: PropTypes.node,
  title: PropTypes.string,
};

HoverableButton.defaultProps = {
  tooltipTitle: '',
  disabled: false,
  onClick: () => {},
  isMandatory: false,
  selected: false,
  selectedNumber: 0,
  outlinedIcon: <></>,
  title: '',
};
