import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiRow from "../../../eui-components/row";
import EuiNewMultiSelect from "../../../eui-components/select/newmultiselect";

const Role567Content = (props) => {

    const rscCenters = useSelector(state => state.user.rscCenters);
    const roleEditMod = useSelector(state => state.userAdmin.roleEditMod);
    const currentUser = useSelector(state => state.userAdmin.currentUser);
    const userId = useSelector(state => state.user.userData.userId);

    const dispatch = useDispatch();

    const handleChange = (e, selectedItems) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        if (selectedItems) {
            const mappedItems = selectedItems.map(c => {
                const rscPersonUser = rscCenters && { ...(rscCenters.find(item => item.text?.trim() === c.trim()) ?? {}) };
                const rscPerson = {
                    name: currentUser.name,
                    personType: props.selectedRole.role,
                    rscCenterId: rscPersonUser.value,
                    email: currentUser.email,
                    createDate: new Date(),
                    createUserId: parseInt(userId)
                };
                rscPersonUser.rscPerson = rscPerson;
                rscPersonUser.personType = props.selectedRole.role;
                rscPersonUser.id = 0;
                return rscPersonUser;
            });
            
            props.setSelectedRoleItems(mappedItems);
        }
    }

    return (
        <EuiRow id={props.id}>
            <EuiNewMultiSelect
                className="m-1 "
                style={{ maxWidth: "100%", width: "47%" }}
                disabled={!roleEditMod}
                id={"multiSelect_" + props.selectedRole.role}
                placeholder="---Select RSC Centers---"
                name={"assignedCustomer" + props.selectedRole.role}
                options={rscCenters}
                value={props.selectedRoleItems.map(i => i.text)}
                onChange={(e, options) => handleChange(e, options)}
            />

        </EuiRow>
    );

}

export default Role567Content;