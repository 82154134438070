import React, { useState, useCallback } from "react";
import EuiRow from "../../eui-components/row";
import EuiHeader from "../../eui-components/header";
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import constants from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import IMActivityChecklists from "./imActivityChecklists";
import IMActivityInfo from "./imActivityInfo";
import ApiClient from "../../utils/api-client";
import { toast, ToastContainer } from "react-toastify";
import EuiLoading from "../../eui-components/loading";
import PageHelper from "../../utils/page-helper";
import DialogModal from "../../components/dialog";
import RateActivity from "./rateActivity";

const ApproveIMActivity = (props) => {

    const currentImActivity = useSelector(state => state.imInbox.currentIMActivity);
    const mod = useSelector(state => state.imInbox.mod);
    const user = useSelector(state => state.user.userData);
    const reduxDispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [openDialogRating, setOpenDialogRating] = useState(false);

    const getRightActions = () => {
        if (mod != constants.pageMods.VIEW_ONLY) {
            return <div>
                <button className={"btn primary "} style={{ margin: 5 }} onClick={() => save(constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER)}><i className="icon icon-check p-1"></i>Save&Finish Later</button>
                {currentImActivity.readyForRate ?
                    <button className={"btn primary "} style={{ margin: 5 }} onClick={() => rate()}><i className="icon icon-favorite p-1"></i>Rate Activity</button>
                    : null
                }
            </div>
        }
    }

    const rate = () => {
        setOpenDialogRating(true)
    }

    const save = (type) => {
        
        setIsLoading(true);
        ApiClient.post("Activity/SaveIMActivityApproval", {
            id: currentImActivity.id,
            acceptanceStatusId: type,
            externalSystemRecordId: currentImActivity.externalSystemrecordid,
            documents: currentImActivity?.subactivities?.map(item => item.documents).reduce((a, b) => { return a?.concat(b); }),
            userId: parseInt(user.userId),
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude,
        }, {}, 120000).then(response => {
            if (response.data) {
                toast.success(constants.processPozitiveMessage, { containerId: 'AI', position: toast.POSITION.TOP_CENTER });
            } else {
                toast.error(constants.errorMessage, { containerId: 'AI', position: toast.POSITION.TOP_CENTER });
            }
            setIsLoading(false);
        }).catch(error => {
            toast.error(constants.errorMessage, { containerId: 'AI', position: toast.POSITION.TOP_CENTER });
            console.error(error);;
            setIsLoading(false);
        });
    }

    //const midPanel = {
    //    title: "Checklist Items", content: <IMActivityChecklists />,
    //    //trigger: "Activity Information",
    //    //triggerId: "imActivityInfoTrigger",
    //    //triggerStyle: { marginLeft: 10 },
    //    hideSeperator: true,
    //    style: { paddingRight: 0 },
    //    leftClassName: "pl-2"
    //}

    //const rightPanel = { title: "Activity Information", hidden: true, content: <IMActivityInfo /> }

    const getAllDocument = useCallback(() => {
        return currentImActivity?.subactivities?.map(item => item.documents).reduce((a, b) => { return a?.concat(b); });
    }, [])

    return (<EuiRow className={(isLoading ? "disabled" : "")}>
        <EuiLoading id="imInboxApproveLoading" isLoading={isLoading} />
        <EuiHeader id="approveImActivityHeaderId"
            title={currentImActivity.id + " - " + currentImActivity.name}
            rightActions={getRightActions()}>
        </EuiHeader>
        <IMActivityChecklists />
        {
            //<EuiMultiPanelTile id="imApproveActivityMultiPanel"
            //    midPanelButton={true}
            //    midPanel={midPanel}
            //    rightPanel={rightPanel}
            //    style={{ padding: 10, paddingLeft: 0 }}
            ///>
        }

        {openDialogRating ?
            <DialogModal
                customStyle={{ width: 950 }}
                content={<RateActivity activityId={currentImActivity.id} allDocument={getAllDocument()} setOpenDialogRating={setOpenDialogRating} />}
                id="ratingImActivityDialog"
                open={openDialogRating}
                title="Rate Activity"
                titleStyle={{ fontSize: 20 }}
                closeButtonVisible="false"
                buttonsVisible="false"
            >
            </DialogModal>
            : null
        }
        <ToastContainer enableMultiContainer containerId={'AI'} autoClose={2500} />

    </EuiRow>);

}

export default ApproveIMActivity;