import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import constants from "../../utils/constants";
import PageHelper from "../../utils/page-helper";
import DateUtils from "../../utils/date-utils";
import { withRouter } from "react-router-dom";
import ApiClient from "../../utils/api-client";
import WorkplanHelper from "../../utils/workplan-helper";
import EuiLoading from "../../eui-components/loading";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import VehicleHelper from "../../utils/vehicle-helper";


class EhsPingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ehsPingColumns: [{
                title: "ID",
                onCreatedCell: (td, cellData) => {
                    td.innerHTML = `<a id="ehsPingId_${cellData}">${cellData}</a>`;
                    td.querySelector("#ehsPingId_" + cellData).addEventListener('click', () => this.openEhsPingDetail(cellData))
                },
                key: "id",
                sort: "desc",
                width: "3%"
            },

            {
                title: "Activity Name",
                headerStyle: this.props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                cellStyle: this.props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                key: "activityName",
                sort: "none",
                width: "15%"
            },
            {
                title: "Workplan Name",
                headerStyle: this.props.type !== constants.activityListTypes.EHS_PING ? "display:none;" : null,
                cellStyle: this.props.type !== constants.activityListTypes.EHS_PING ? "display:none;" : null,
                key: "workplanName",
                sort: "none",
                width: "15%"
            },
            {
                title: "Site Name",
                headerStyle: this.props.type !== constants.activityListTypes.EHS_PING ? "display:none;" : null,
                cellStyle: this.props.type !== constants.activityListTypes.EHS_PING ? "display:none;" : null,
                key: "siteName",
                sort: "none",
                width: "10%"
            },
            {
                title: "ASP Team Leader",
                key: "aspResourceNameAndMail",
                sort: "none",
                width: "10%"
            },
            {
                title: "EHS Supervisor",
                key: "rscPersonName",
                sort: "none",
                width: "10%"
            },
            {
                title: "Review Date",
                onCreatedCell: (td, cellData) => {
                    const date = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm:ss");
                    td.innerHTML = `${date}`;
                },
                key: "ratingDate",
                width: "10%"
            },
            {
                title: "Status",
                key: "acceptanceStatusId",
                onCreatedCell: (td, cellData) => {
                    const status = PageHelper.renderActivityStatus(cellData);
                    td.innerHTML = `${status}`;
                },
                width: "8%"
            },
            ],
            isLoading: false,
        }
    }

    componentDidMount() {

    }
    // createNewConversationOrRoom=(selectedEhsPing) => {
    //     const ele={}
    //     const userName=this.props.userData.userData.username
      
    //         ele.activityid=parseInt(selectedEhsPing.activityId) //
    //         ele.teamLeadId=parseInt(selectedEhsPing.activity.resourceId)// team lead id
    //         ele.rscPersonId=parseInt(selectedEhsPing.rscPersonId)//inspector
    //         ele.teamLeadMail=parseInt(selectedEhsPing.activity.teamLeadMail)//team lead Mail\
    //         ele.userId=parseInt(this.props.userData.userData.userId)
    //         ele.isOnline=true
    //         ele.userName=userName
    //         ele.time=DateUtils.toUtcDate(new Date())
    //     ApiClient.post("Chat/CreateConversations",{...ele}).then(response => {
    //         ///console.log('CreateConversationsRequest respos',response)
    //     });
    // }
    openEhsPingDetail = (ehsPingId) => {
        var selectedEhsPing = this.props.ehsPings?.find(item => item.id === ehsPingId);
        if(this.props.ehsPingData.ehsPing.id == ehsPingId)
        {
            selectedEhsPing = this.props.ehsPingData.ehsPing;
        }
        this.getAllVehiclesByAspCompanyId(selectedEhsPing,ehsPingId)
        ///console.log("selectedEhsPingopenEhsPingDetail ",selectedEhsPing,this.props.userData.userData)
  // create chat if the activity not complited
  // acceptance status should be not accepted(2) to add new room or add participant to room

//   if(selectedEhsPing.acceptanceStatusId!==2&&selectedEhsPing.activity.completed===0){

//     this.createNewConversationOrRoom(selectedEhsPing);
//    }
        var request={
                ehsPingId:ehsPingId,
                customerId: selectedEhsPing.customer?.value
        }
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/GetEhsPingDetails", { ...request })
                .then(response => {
                  
                    this.setState({ isLoading: false }, () => {
                        const ehsPing = response.data;
                        const type = this.props.type;
                        // const vehicles=this.props.vehicles
                        WorkplanHelper.mapEhsPing(ehsPing, selectedEhsPing, type);
                        const teamVerificationLogs = ActivityInboxHelper.mapTeamLogs(response.data.verificationLogs?.teamVerificationLogs);
                        const resourceVerificationLogs = ActivityInboxHelper.mapResourceLogs(response.data.verificationLogs?.resourceVerificationLogs);
                        const teamInfo = response.data.verificationLogs?.teamInfo || {};
                        teamInfo.status = teamVerificationLogs.length > 0 ? !teamVerificationLogs.some(item => !item.validStatus) : null;
                        teamInfo.verificationSessionId = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].verificationSessionId : "";
                        teamInfo.isOffsiteVerification = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].isOffsiteVerification : null;
                        teamInfo.resourceStatus = resourceVerificationLogs.length > 0 ? !resourceVerificationLogs.some(item => !item.status) : null;
                        const teamsLogInfo = { teamInfo, teamVerificationLogs, resourceVerificationLogs };
                        this.props.setTeamsLogInfo(teamsLogInfo);
                        this.props.setEhsPing(ehsPing);
                        this.props.changeEhsPingMod(constants.pageMods.VIEW_ONLY);
                        
                        this.props.history.push("/ehs-pings");
                    });
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    
    }

    getAllVehiclesByAspCompanyId =(ehsPingSelected,ehsPingId)=>{
        const request={
            ActivityId: ehsPingSelected.activity.id  || 0,
            AspCompanyId: ehsPingSelected.activity.aspCompanyId  || 0,
            EhsPingId:ehsPingId || 0
        }
    ApiClient.post("Vehicle/GetAspCompanyVehiclesByActivity",  {...request})
        .then(response => {
            const vehicles = response.data;
            VehicleHelper.mapVehicles(vehicles);

            this.props.setVehicleList(vehicles)
        });
    }
 
    render() {
        const ehsPings = this.props.ehsPings;
        if (ehsPings.length === 0) {
            return <div className="flex-middle-content m-3 w-10 text-lg">No EHS ping have found.</div>
        } else {
            const excelData = ehsPings?.map(a => ({
                EHSPingId: a.id,
                ActivityName: a.activityName,
                WorkplanName: a.workplanName,
                SiteName: a.siteName,               
                AspTeamLeader: a.aspResourceName,
                EHSSupervisor: a.rscPersonName,
                ReviewData: a.ratingDate,
                Status: PageHelper.mapRscStatus(a.acceptanceStatusId)
            }));
            return (<EuiRow disabled={this.state.isLoading ? "disabled" : ""}>
                <EuiLoading id="ehsPingToDetailLoading" isLoading={this.state.isLoading} />
                <ObseleteEuiTable
                    classes="tiny split-striped"
                    columns={this.state.ehsPingColumns}
                    data={ehsPings}
                    exportExcel={true}
                    Reportdata={excelData}
                    excelFileName={"EHS_Ping_List" + new Date().toISOString()}
                    id={this.props.id}
                    filter={true}
                    scroll={false}
                    sortable={true}
                    paginated={true}
                    totalCount={this.props.totalCount}
                    rowsPerPage={10}
                >
                </ObseleteEuiTable>
            </EuiRow >
            );
        }
    }
}

const mapStateToProps = state => ({
     userData: state.user, 
     navbarData: state.navigation,
     ehsPingData: state.ehsPing, 
     activityData: state.activity
   
    });
const mapDispatchToProps = dispatch => {
    return {
        setEhsPing: (ehsPing) => dispatch({ type: "SET_EHS_PING", payload: ehsPing }),
        changeEhsPingMod: (mod) => dispatch({ type: "CHANGE_EHS_PING_MOD", payload: mod }),
        setTeamsLogInfo: (teamsLogInfo) => dispatch({ type: "SET_TEAMS_LOG_INFO", payload: teamsLogInfo }),
        setVehicleList: (vehicle) =>  dispatch({ type: "SET_VEHICLE_LIST", payload: vehicle }),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EhsPingList));