import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PolicyDetail from "./policyDetail";
import Sysbar from "../../layout/sysbar";

class Policy extends React.Component {
  
    renderMod = () => {
        return (
            <>
                <Sysbar policy="true" />
                <main>                   
                    <div className="app">                       
                        <div className="appbody">                          
                            <div className="appcontent">
                                <div className="container">
                                    <div className="appcontent">  
                                        <PolicyDetail />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>               
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderMod()}
            </>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user });
const mapDispatchToProps = dispatch => {
    return {                 
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Policy));
