import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import Table from "../../../components/table/index";
import PageHelper from "../../../utils/page-helper";
import SimpleExpansionPanel from "../../../components/expansionpanel";

class SecondStepContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activitiesColumns:
                [{ title: "Site Code", field: "siteCode" },
                { title: "Work Plan Name", field: "workPlanName" },
                { title: "Activity Id", field: "activityId" },
                { title: "Activity Name", field: "activityName" },
                { title: "ASP Company Name", field: "aspCompanyName" },
                { title: "ASP Team Lead", field: "aspTeamLead" },
                { title: "RSC Engineer", field: "rscEngineer" },
                { title: "RSC Status", field: "rscStatus", render: rowData => PageHelper.renderStatus(rowData.rscStatus), defaultSort: "desc" },
                ],

            remoteActivitiesColumns:
                [{ title: "Site Code", field: "siteCode" },
                { title: "Work Plan Name", field: "workPlanName" },
                { title: "Activity Id", field: "activityId" },
                { title: "Activity Name", field: "activityName" }
                ],
            selectedActivities: [],
            tableRef: React.createRef(),
        }
    }

    selectActivities = (rows) => {
        if (this.props.sessionData.sessionInfo.id === 0) {
            this.props.setSessionActivities(rows);
        } else {
            let oldActivities = [...this.props.sessionData.sessionActivities];
            if (rows.length > oldActivities.filter(item => item.sessionType != 6).length) {
                rows.map(item => {
                    //When removed existing item and re added to array
                    if (item.sessionType == 6) {
                        item.sessionType = 2;
                    }
                })
                this.props.setSessionActivities(rows);
            } else {
                let flag = false;
                oldActivities.map(item => {
                    if (rows.indexOf(item) === -1 && item.sessionId > 0) {
                        item.sessionType = 6;
                        flag = true;
                    }
                });
                this.props.setSessionActivities(flag ? oldActivities : rows);
            }
        }
        
    }

    selectRemoteAcceptance = (rows) => {
        let remoteActivities = [...this.props.sessionData.sessionRemoteActivities];

        remoteActivities.map(item => {
            if (rows.map(r => r.workPlanId).indexOf(item.workPlanId) > -1) {
                if (item.tableData.hasOwnProperty("checked")) {
                    item.tableData.checked = true;
                } else {
                    item.tableData["checked"] = true;
                }
            } else {
                if (item.tableData.hasOwnProperty("checked")) {
                    item.tableData.checked = false;
                } else {
                    item.tableData["checked"] = false;
                }
            }
        });

        this.props.setSessionRemoteActivities(remoteActivities);
    }

    renderRow = (rowData) => {
        var selected = rowData.tableData.hasOwnProperty("checked") && rowData.tableData.checked == true;
        return PageHelper.renderRow(selected);
    }

    render() {
        return (
            <>
                <EuiRow style={{ paddingRight: 15 }}>
                    <SimpleExpansionPanel
                        id="createSessionExpansionPanelRsc"
                        title="Select RSC Acceptance Activities"
                        style={{ borderTop: "1px solid #878787", borderBottom: "1px solid #878787", padding: 5 }}
                        content={<Table
                            columns={this.state.activitiesColumns}
                            data={this.props.sessionData.sessionAllActivities}
                            isLoading={this.props.isLoading}
                            options={{
                                minBodyHeight: 225, paging: false, selection: true, showTextRowsSelected: false, search: false,
                                toolbar: false,
                                showSelectAllCheckbox: !this.props.sessionData.sessionInfo.viewOnly,
                                selectionProps: rowData =>
                                    ({
                                        color: "default",
                                        disabled: this.props.sessionData.sessionInfo.viewOnly,
                                        style: {
                                            padding: 3,
                                            width: 17,
                                            marginLeft: 20
                                        },                                      
                                    }),
                                rowStyle: (rowData) => this.renderRow(rowData)
                            }}
                            onSelectionChange={rows => { this.selectActivities(rows); this.selectRemoteAcceptance(rows); }}
                            tableRef={this.state.tableRef}
                        >
                        </Table>
                        }
                    />

                    <SimpleExpansionPanel
                        id="createSessionExpansionPanel"
                        title="Remote Customer Acceptance"
                        style={{ borderTop: "1px solid #878787", borderBottom: "1px solid #878787", padding: 5 }}
                        content={<Table
                            columns={this.state.remoteActivitiesColumns}
                            data={this.props.sessionData.sessionRemoteActivities}
                            isLoading={this.props.isLoading}
                            options={{
                                minBodyHeight: 225, paging: false, selection: true, showTextRowsSelected: false, search: false,
                                toolbar: false,
                                showSelectAllCheckbox: !this.props.sessionData.sessionInfo.viewOnly,
                                selectionProps: rowData =>
                                    ({
                                        color: "default",
                                        disabled: this.props.sessionData.sessionInfo.viewOnly,
                                        style: {
                                            padding: 3,
                                            width: 17,
                                            marginLeft: 20
                                        }
                                    }),
                                rowStyle: (rowData) => this.renderRow(rowData)
                            }}
                        >
                        </Table>
                        }
                    />
                </EuiRow>
            </>
        );
    }
}

const mapStateToProps = state => ({ sessionData: state.session });
const mapDispatchToProps = dispatch => {
    return {
        setSessionActivities: (activities) => dispatch({ type: "SET_SESSION_ACTIVITIES", payload: activities }),
        setSessionRemoteActivities: (activities) => dispatch({ type: "SET_SESSION_REMOTE_ACTIVITIES", payload: activities }),
    };
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SecondStepContent);
