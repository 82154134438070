import React, { useEffect, useState } from "react";
import EuiVerticalBarChart from "../../../eui-components/verticalBarChart";
import EuiRow from "../../../eui-components/row";
import DateUtils from "../../../utils/date-utils";

const EhsPerformanceTrend = (props) => {
    var selected = props.selected;
    const [viewType, setViewType] = useState(selected ? 2 : null);
    var dates = [];
    if (props.data && props.data.length) {
        props.data.map(item => {
            if (dates.indexOf(item.ratingDate?.slice(5, 10)) === -1)
                dates.push(item.ratingDate.slice(5, 10));
        });
    }
    dates = dates.sort(function (a, b) { return a.localeCompare(b); });

    var dailyDates = dates;

    var ehsPassed = dates?.map(item => props.data.filter(site => site.ratingDate.slice(5, 10) === item && parseInt(site.acceptanceStatusId) == 2 && site.ratingDate.slice(5, 10) == item)?.length);
    var ftp = dates.map(item => props.data.filter(site => site.ratingDate.slice(5, 10) === item && site.ftp)?.length);
    var criticalViolationReporting = dates?.map(item => props.data.filter(site => site.ratingDate.slice(5, 10) === item && site.violationReport)?.length);
    var rscReviewLt = dates?.map(item => props.data.filter(site => parseInt(DateUtils.dateDifferenceInMinutes(site.ratingDate, site.sentOhsToRsc)) <= 15 && site.ratingDate.slice(5, 10) == item)?.length);
    var overallEhsLt = dates?.map(item => props.data.filter(site => parseInt(DateUtils.dateDifferenceInMinutes(site.ratingDate.slice, site.uploadDate)) <= 45 && site.ratingDate.slice(5, 10) == item)?.length);
    var allSites = dates?.map(item => props.data.filter(site => site.ratingDate.slice(5, 10) === item)?.length);

    var weeklyDates = [];
    var ehsPassedWeekly = [];
    var ftpWeekly = [];
    var criticalViolationReportingWeekly = [];
    var rscReviewLtWeekly = [];
    var overallEhsLtWeekly = [];
    var allSitesWeekly = [];
    const groupedDatesByWeek = DateUtils.groupByWeek(dates);
    Object.keys(groupedDatesByWeek).forEach(o => {
        weeklyDates.push(o);
        var dateRange = groupedDatesByWeek[o];
        ehsPassedWeekly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && parseInt(site.acceptanceStatusId) == 2)?.length);
        ftpWeekly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && site.ftp)?.length);
        criticalViolationReportingWeekly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && site.violationReport)?.length);
        rscReviewLtWeekly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && parseInt(DateUtils.dateDifferenceInMinutes(site.ratingDate, site.sentOhsToRsc)) <= 15)?.length);
        overallEhsLtWeekly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && parseInt(DateUtils.dateDifferenceInMinutes(site.ratingDate, site.uploadDate)) <= 45)?.length);
        allSitesWeekly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)))?.length);
    });

    var monthlyDates = [];
    var ehsPassedMonthly = [];
    var ftpMonthly = [];
    var criticalViolationReportingMonthly = [];
    var rscReviewLtMonthly = [];
    var overallEhsLtMonthly = [];
    var allSitesMonthly = [];
    const groupedDatesByMonth = DateUtils.groupByMonth(dates);
    Object.keys(groupedDatesByMonth).forEach(o => {
        monthlyDates.push(o);
        var dateRange = groupedDatesByMonth[o];
        ehsPassedMonthly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && parseInt(site.acceptanceStatusId) == 2)?.length);
        ftpMonthly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && site.ftp)?.length);
        criticalViolationReportingMonthly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && site.violationReport)?.length);
        rscReviewLtMonthly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && parseInt(DateUtils.dateDifferenceInMinutes(site.ratingDate, site.sentOhsToRsc)) <= 15)?.length);
        overallEhsLtMonthly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)) && parseInt(DateUtils.dateDifferenceInMinutes(site.ratingDate, site.uploadDate)) <= 45)?.length);
        allSitesMonthly.push(props.data?.filter(site => dateRange?.includes(site.ratingDate.slice(5, 10)))?.length);
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "daily": {
                setViewType(1);
            }
                break;
            case "weekly": {
                setViewType(2)
            } break;
            case "monthly": {
                setViewType(3)
            } break;
            default: {
                setViewType(2);
            }
                break;
        }
    }
    const ehsPassedData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [
            { "name": "Submitted", values: viewType == 1 ? allSites : viewType == 3 ? allSitesMonthly : allSitesWeekly },
            { "name": "EHS Passed", "values": viewType == 1 ? ehsPassed : viewType == 3 ? ehsPassedMonthly : ehsPassedWeekly }
        ]
    };
    const ftpData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [{ "name": "Submitted", values: viewType == 1 ? allSites : viewType == 3 ? allSitesMonthly : allSitesWeekly },

        { "name": "First Time Passed", "values": viewType == 1 ? ftp : viewType == 3 ? ftpMonthly : ftpWeekly }
        ]
    };
    const cvData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [
            { "name": "Submitted", values: viewType == 1 ? allSites : viewType == 3 ? allSitesMonthly : allSitesWeekly },
            { "name": "Critical Violation Reporting", "values": viewType == 1 ? criticalViolationReporting : viewType == 3 ? criticalViolationReportingMonthly : criticalViolationReportingWeekly }
        ]
    };
    const rscReviewLtData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [
            { "name": "Submitted", values: viewType == 1 ? allSites : viewType == 3 ? allSitesMonthly : allSitesWeekly },
            { "name": "RSC ReviewLt <=15 min", values: viewType == 1 ? rscReviewLt : viewType == 3 ? rscReviewLtMonthly : rscReviewLtWeekly }]
    };
    const overallLtData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [
            { "name": "Submitted", values: viewType == 1 ? allSites : viewType == 3 ? allSitesMonthly : allSitesWeekly },
            { "name": " Overall EHS Lt <= 45min", values: viewType == 1 ? overallEhsLt : viewType == 3 ? overallEhsLtMonthly : overallEhsLtWeekly }
        ]
    };

    return (<EuiRow className="w-10" key={props.data || viewType || selected}>
        <EuiRow className="mt-2 mb-2">
            <input id="monthlyEHS" name="monthly" type="radio" value={viewType === 3} checked={viewType === 3} onChange={(e) => handleChange(e)} />
            <label className="mr-2" style={{ color: "gray", fontSize: "14px" }} for="monthlyEHS">Monthly</label>
            <input id="weeklyEHS" name="weekly" type="radio" value={viewType === 2} checked={viewType === 2 || !viewType} onChange={(e) => handleChange(e)} />
            <label className="mr-2" style={{ color: "gray", fontSize: "14px" }} for="weeklyEHS">Weekly</label>
            <input id="dailyEHS" name="daily" type="radio" value={viewType === 1} checked={viewType === 1} onChange={(e) => handleChange(e)} />
            <label className="mr-2" style={{ color: "gray", fontSize: "14px" }} for="dailyEHS">Daily</label>
        </EuiRow>
        <EuiRow key={viewType || props.selected} style={{ overflowY: "scroll", maxHeight: "600px" }}>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id="ehsPassedPerformanceTrendBarChart" key={viewType} data={ehsPassedData} height={200} unit="EHS Ping" />
            </EuiRow>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id="ftpPerformanceTrendBarChart" key={viewType} data={ftpData} unit="EHS Ping" height={200} />
            </EuiRow>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id=" criticalViolationReportingPerformanceTrendBarChart" key={viewType} data={cvData} unit="Violations" height={200} />
            </EuiRow>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id="rscReviewLtformanceTrendBarChart" key={viewType} data={rscReviewLtData} unit="EHS Ping" height={200} />
            </EuiRow>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id=" overallEhsLtPerformanceTrendBarChart" key={viewType} data={overallLtData} unit="EHS Ping" height={200} />
            </EuiRow>
        </EuiRow>
    </EuiRow>);
}
export default EhsPerformanceTrend;