import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import FormField from "../../eui-components/formfield";
import EuiSelect from "../../eui-components/select";
import EuiDatepicker from "../../eui-components/datepicker";
import DateUtils from "../../utils/date-utils";
import SasHelper from "../../utils/sas-helper";

class AddCertificateDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            certificates: [],
            crtId: "",
            validSinse: "",
            validTill: "",
            disableAdd: false,
            initialState: "",
            dateError: false,
            certificateExist: "",
            toggleDialog: false,
            selectedCrtId: ""


        }
    }

    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        this.setState({
            initialState: this.props.initialState,
            toggleDialog: this.props.toggleDialog,
        })

    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        const crt = { ... this.props.aspResourcesData.certificate }
        crt[name] = value;
        crt["resourceId"] = parseInt(this.props.aspResourcesData.resource.id);
        if (name == "certificationId") {
            if (this.props.aspResourcesData.resource.certs.findIndex(e => e.certificationId == value) !== -1) {
                this.setState({
                    certificateExist: true
                })
                this.props.disableAddCertForName(true);
            }

            else {
                this.setState({
                    certificateExist: false
                })
                this.props.disableAddCertForName(false);
            }

            crt["code"] = this.props.aspResourcesData.certificates.filter(e => e.value === crt.certificationId)[0].code;
            crt["name"] = this.props.aspResourcesData.certificates.filter(e => e.value === crt.certificationId)[0].text;
            crt["createuser"] = parseInt(this.props.userData.userData.id);
            crt["lastModifiedUser"] = parseInt(this.props.userData.userData.id);
            crt["createUserName"] = this.props.userData.userData.username;
            crt["modifiedUserName"] = this.props.userData.userData.username;
            crt["createdate"] = DateUtils.toDateString(DateUtils.getUtcDate(), "", true);
            crt["lastModifiedDateTime"] = DateUtils.toDateString(DateUtils.getUtcDate(), "", true);
            ;
        }
        if (name == "validUntil") {
            var date = DateUtils.getUtcDate();
            var diff = DateUtils.dateDifference(new Date(value), new Date(date));
            if (diff <= 0) {
                this.setState({
                    dateError: true
                })
                this.props.disableAddCertForDate(true);

            }
            else {
                this.setState({
                    dateError: false
                })
                this.props.disableAddCertForDate(false);

            }
            crt["status"] = (new Date(value).getDate() - date.getDate() > 0 ? "Valid" : "Expired");

        }
        if (crt["certificationId"]
            && crt["validSince"]
            && crt["validUntil"]) {
            this.props.setAddCrtNotify(false);
        }
        this.props.setCertificate(crt);

    }

    fileChange = (e) => {
        const files = e.target.files;
        let reader = new FileReader();
        if (files.length !== 0) {
            reader.onload = () => {
                const data = reader.result;
                const crt = { ... this.props.aspResourcesData.certificate };
                crt["path"] = data;
                crt["fileName"] = files[0].name;
                this.props.setCertificate(crt);

            };
            reader.readAsDataURL(files[0]);
        }
    }

    render() {
        return (
            <EuiRow className="w-9" style={{ height: "70%" }} >
                <form>
                    <div>
                        <FormField className="w-10 mt-2 mb-2">
                            <label className="required" style={{ fontSize: "14px" }} htmlFor="ctrSelect">Select Certificate</label>
                            <EuiSelect required
                                className="m-1"// fullwidth
                                label="Select Certificate"
                                id={this.props.id + "Select"}
                                optionListStyle={{ width: "100%", height: "45%px" }}
                                name="certificationId"
                                options={this.props.aspResourcesData.certificates}
                                value={this.props.aspResourcesData.certificate.value}
                                onChange={(e) => this.handleChange(e)}
                                disabled={this.props.edit}
                            />
                        </FormField>
                        <EuiRow>
                            <div className="column xl-6 lg-6 m-6 sm-12">
                                <FormField className="w-10 mt-2 mb-2">
                                    <EuiDatepicker required
                                        style={{ width: "100%" }}
                                        id={this.props.id + "ValidSince"}
                                        label="Valid Since"
                                        dateStyle={{ marginRight: 6 }}
                                        name="validSince"
                                        value={(this.props.aspResourcesData.certificate.validSince ?
                                            this.props.aspResourcesData.certificate.validSince : null)}
                                        onChange={(e) => this.handleChange(e)}
                                        labelStyle={{ fontSize: "14px" }}
                                        labelClassName="required"
                                    />
                                </FormField>
                            </div>
                            <div className="column xl-6 lg-6 m-6 sm-12">
                                <FormField className="w-10 mt-2 mb-2">
                                    <EuiDatepicker
                                        required
                                        style={{ width: "100%" }}
                                        id={this.props.id + "validUntil"}
                                        label="Expiry Date"
                                        dateStyle={{ marginRight: 6 }}
                                        name="validUntil"
                                        value={(this.props.aspResourcesData.certificate.validUntil ?
                                            this.props.aspResourcesData.certificate.validUntil : null)}
                                        onChange={(e) => this.handleChange(e)}
                                        labelStyle={{ fontSize: "14px" }}
                                        labelClassName="required"
                                    />
                                </FormField>
                            </div>
                        </EuiRow>
                        <FormField className="w-10">
                            <label style={{ fontSize: "14px" }} htmlFor="ctName">Upload Certificate</label>
                            <input style={{ marginRight: "0px", width: "100%" }} id="ctName"
                                label="Upload Certificate"
                                onChange={(e) => this.fileChange(e)}
                                type="file" name="crt"
                                labelstyle={{ fontSize: "14px" }}
                                accept=".jpg,.jpeg,.png,.pdf"
                            >

                            </input>
                        </FormField>
                        <label className="color-gray">* Only images and PDF file types can be uploaded</label>
                        {(this.props.aspResourcesData.certificate.path ?
                            <FormField>
                                <img src={SasHelper.addSasKey(this.props.aspResourcesData.certificate.path, this.props.sasToken)}
                                    style={{ height: "200px", width: "200px" }}
                                />
                            </FormField>
                            : null)}
                    </div>
                    <FormField className={(this.props.aspResourcesData.disableAddCertForName ? "" : "hidden")} style={{ color: "red" }}>
                        *This certificate is already added to the user profile.
                    </FormField>
                    <FormField className={(this.props.aspResourcesData.disableAddCertForDate ? "" : "hidden")} style={{ color: "red" }}>
                        *Expiry date must be in the future.
                    </FormField>
                    <FormField className={(this.props.aspResourcesData.addCrtNotify ? "" : "hidden")} style={{ color: "red" }}>
                        *Mandatory data must be filled to save.
                    </FormField>
                </form>
            </EuiRow >

        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, sasToken: state.complaints.sasToken  });
const mapDispatchToProps = dispatch => {
    return {
        setCertificate: (certificate) => dispatch({ type: "SET_CERTIFICATE", payload: certificate }),
        disableAddCertForName: (state) => dispatch({ type: "DISABLE_ADD_CERT_FOR_NAME", payload: state }),
        disableAddCertForDate: (state) => dispatch({ type: "DISABLE_ADD_CERT_FOR_DATE", payload: state }),
        setAddCrtNotify: (state) => dispatch({ type: "SET_ADD_CRT_NOTIFY", payload: state }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCertificateDialog);

