import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import constants from "../../utils/constants";
import { withRouter } from "react-router-dom";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import EuiTextArea from "../../eui-components/textarea";
import { setTeam } from "../../store/actions/teamAction";
import DateUtils from "../../utils/date-utils";
import ApiClient from "../../utils/api-client";
import EuiSwitch from "../../eui-components/switch";

class TeamInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            global: !props.teamData.team.customerId,
            isActive: true,
            sites: [],
            projects: []
        }
    }

    componentDidMount() {
        const team = this.props.teamData.team;
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        var value = target.value;
        const team = { ...this.props.teamData.team };
        team[name] = value;
        team['lastModifiedUserName'] = this.props.userData.userData.username;
        if (name === 'customerId') {
            team.customerName = target.text;
        }
        if (name === 'name') {
            this.handleRemoteChange(name, value);
        }
        if(this.props.mod === constants.actionMods.CREATE){
            team.isActive=true;
        }
        this.props.setTeam(team);
    }

    handleRemoteChange = (name, value) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            const team = this.props.teamData.team;
            if (value.trim().length > 2) {
                this.setState({ isLoading: true }, () => {
                    ApiClient.post("Team/CheckTeamName", { id: team.id, name: value })
                        .then(response => {
                            if (!response.data) {
                                this.setState({ error: 'A team already exist with this name!' });
                                return;
                            }
                            else {
                                this.setState({ error: '' });
                            }
                        }).finally(() => {
                            this.setState({ isLoading: false });
                        })
                });
            }
            else {
                this.setState({ error: 'Fill at least 3 characters!' });
                return;
            }
        }, 500);
    }

    toggleSwitch = () => {
        if (!this.state.global) {
            const team = { ...this.props.teamData.team };
            team.customerId = null;
            team.customerName = '';
            this.props.setTeam(team);
        }
        this.setState({ global: !this.state.global })
    }
    toggleSwitchActive = () => {
        const team = { ...this.props.teamData.team };
        team.isActive=!this.state.isActive
        this.setState({ isActive: !this.state.isActive })
        this.props.setTeam(team);

    }

    render() {
        return (
            <EuiRow key={this.props.teamData.team}>
                <EuiSwitch
                    disabled={this.props.mod === constants.actionMods.VIEW_ONLY}
                    className="p-1 w-10 error-validation"
                    id="ViSwitchDrawer"
                    onChange={this.toggleSwitch}
                    checked={this.state.global}
                    dataEnabled="Global"
                    dataDisabled="Customer"
                />
                   <EuiSwitch
                    disabled={this.props.mod === constants.actionMods.VIEW_ONLY||
                        this.props.mod === constants.actionMods.CREATE}
                    className="p-1 w-10 error-validation"
                    id="ViSwitchDrawerActiveId"
                    onChange={this.toggleSwitchActive}
                    checked={(this.props.mod === constants.actionMods.CREATE)||this.props.teamData.team.isActive}
                    
                    // { this.props.mod === constants.actionMods.CREATE ?this.state.active :this.props.teamData.team.isActive}
                    dataEnabled="Active"
                    dataDisabled="Deactivate"
                />
                <EuiTextField
                    className="p-1 w-10 error-validation"
                    fullwidth
                    readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                    required
                    style={{ padding: 0 }}
                    labelstyle={{ color: "gray" }}
                    id="teamName"
                    name="name"
                    label="Team Name"
                    value={this.props.teamData.team.name}
                    onChange={(e) => this.handleChange(e)}
                    validationMessage={this.state.error}
                />
                {!this.state.global &&
                    (this.props.mod === constants.actionMods.VIEW_ONLY ?
                        <EuiTextField
                            className="p-1 w-10"
                            fullwidth
                            readOnly
                            labelstyle={{ color: "gray" }}
                            id="customerNameTeam"
                            name="customerName"
                            label="Customer Name"
                            value={this.props.teamData.team.customerName}
                        />
                        :
                        <EuiAutocompleteSelect
                            style={{ width: '100%', maxWidth: 'inherit' }}
                            className="p-1 w-10"
                            fullwidth
                            label={{ title: "Customer", className: "color-gray" }}
                            id="customerTeamSelect"
                            name="customerId"
                            options={this.props.userData.customersWithProjects || []}
                            value={this.props.teamData.team.customerId || 0}
                            onChange={(e) => this.handleChange(e)}
                            placeholder="---Select Customer---"
                        />
                    )
                }
                <EuiTextArea
                    className="p-1 w-10"
                    fullwidth
                    readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                    required
                    style={{
                        minHeight: 120,
                        border: '1px solid #878787',
                        padding: '5px'
                    }}
                    labelstyle={{ color: "gray" }}
                    id="teamComment"
                    name="comment"
                    label="Comment"
                    value={this.props.teamData.team.comment}
                    onChange={(e) => this.handleChange(e)}
                />
                <EuiTextField
                    className="p-1 w-5"
                    fullwidth
                    readOnly
                    required
                    style={{ padding: 0 }}
                    labelstyle={{ color: "gray" }}
                    id="lastModifiedDate"
                    name="lastModifiedDate"
                    label="Last Modified Date"
                    value={DateUtils.toDateString(this.props.teamData.team.lastModifiedDate, "YYYY-MM-DD HH:mm")}
                />
                <EuiTextField
                    className="p-1 w-5"
                    fullwidth
                    readOnly
                    required
                    style={{ padding: 0 }}
                    labelstyle={{ color: "gray" }}
                    id="lastModifiedUserName"
                    name="lastModifiedUserName"
                    label="Last Modified User Name"
                    value={this.props.teamData.team.lastModifiedUserName}
                />
            </EuiRow>);
    }
}

const mapStateToProps = state => ({
    userData: state.user,
    navbarData: state.navigation,
    teamData: state.team,
});
const mapDispatchToProps = dispatch => {
    return {
        setTeam: (team) => setTeam(team)(dispatch)
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TeamInfo));