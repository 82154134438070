import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import EuiRow from "../../eui-components/row";
import EuiLoading from "../../eui-components/loading";
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import { ToastContainer } from "react-toastify";
import IntegrationApiFilter from "./integrationApiFilter";
import IntegrationForm from "./integrationForm";
import DialogModal from "../../components/dialog";
import ApiClient from "../../utils/api-client";
import { Notification } from '@eds/vanilla';
import { isEqual } from "lodash";

class IntegrationApi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: {
                transactionId: 0
            },
            filter: null,
            selectedRow: [],
            selectedObject: null,
            openDialogCompleteSync: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
    }

    setData = (selectedObject, data, filter) =>{
        this.setState({selectedObject, data , filter, selectedRow: []});
    }

    sync = () =>
    {
        let { selectedObject, selectedRow, data } = { ...this.state };
        this.setState({ isLoading: true })
        ApiClient.post(selectedObject.syncApi, {records: selectedRow, userId : this.props.userData.userDetail.id}, {}, 1000000)
        .then(response => {
            let records = data.records.filter(record => 
                !selectedRow.find(item => 
                    item.systemRecordId == record.systemRecordId &&
                    item.vendorId == record.vendorId))

            this.setState({
                openDialogCompleteSync: false,
                data: {
                    records: records,
                    transactionId: data.transactionId + records.length
                },
                isLoading: false,
                selectedRow: []
            });
            const notification = new Notification({
                title: 'Sync Items',
                description: 'Items successfully synced',
                timeout: 5000,
                icon: 'icon-check'
            });
            notification.init();
        }).catch(error => {
            const notification = new Notification({
                title: 'Sync Items',
                description: 'Items synced Error: ' + error,
                //timeout: 5000,
                
                icon: 'icon-cross'
            });
            notification.init();
            this.setState({
                openDialogCompleteSync: false,
                isLoading: false
            })
        })
    }
    
    selectRow= (e, type) => {
        const data = e.detail.data;
        let selectedRow = [...this.state.selectedRow]

        if (type === "add") {
            selectedRow.push(data)
        } else if (type === "remove") {
            selectedRow = selectedRow.filter(item => item.id !== data.id) 
        }

        this.setState({ selectedRow });
    }

    render() {
        const leftPanel = {
            title: "Filter By:",
            content: <IntegrationApiFilter  setData={this.setData}/>,
        };

        const midPanel = {
            title:"Integration Form:",
            content: <IntegrationForm
                selectedRow={this.state.selectedRow}
                data={this.state.data}
                selectRow={this.selectRow}
            />
        };

        return (<EuiRow disabled={this.state.isLoading ? "disabled" : ""} >
            <EuiLoading id="integrationApiLoading" isLoading={this.state.isLoading} />
            <EuiMultiPanelTile id="integrationApiMultiPanel"
                midPanelButton={true}
                button={<button id="completeSync"
                    style={{ width: 123 }}
                    disabled={!this.state.selectedRow.length}
                    onClick={() => { this.setState({ openDialogCompleteSync: true }) }}
                    className="btn primary">Complete Sync</button>}
                className="fullscreen"
                leftPanel={leftPanel}
                leftPanelStyle={ { height: "4000px", width: "240px" } }
                midPanel={midPanel}
                style={{ paddingRight: 23 }}
            />
            <DialogModal
                id="SyncItems"
                disabled={this.state.isLoading ? "disabled" : ""}
                open={this.state.openDialogCompleteSync}
                title="Synchronize Selected Items"
                content={
                    <div>Are you sure you want to synchronize <b>({this.state.selectedRow.length})</b> selected items ?</div>}
                buttonName={this.state.isLoading ? "Please Wait..." : "Complete Sync"}
                type={this.state.isLoading ? "primary loading" : "primary"}
                handleClose={() => { this.setState({ openDialogCompleteSync: false }) }}
                onClick={this.sync}
            />
            <ToastContainer enableMultiContainer containerId={'AS'} autoClose={2500} />
        </EuiRow >);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(IntegrationApi)));