import { createSlice } from "@reduxjs/toolkit";
import ApiClient from "../../utils/api-client";
// ----------------------------------------------------------------------

const initialState = {
  user: {},
  sideBar: {
    open: false,
    type: "CONTACT", // can be CONTACT, STARRED, SHARED
  },
  refresh: {
    open: false,
  },
  isLoggedIn: true,
  tab: 0, // [0, 1, 2, 3]
  snackbar: {
    open: null,
    severity: null,
    message: null,
  },
  users: [], // all users of app who are not friends and not requested yet
  all_users: [],
  friends: [], // all friends
  friendRequests: [], // all friend requests
  chat_type: null,
  room_id: null,
  call_logs: [],
  isSentMsg: null
};

const slice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    // Toggle Sidebar
    toggleSideBar(state) {
      state.sideBar.open = !state.sideBar.open;
    },
    updateSideBarType(state, action) {
      state.sideBar.type = action.payload.type;
    },
    updateTab(state, action) {
      state.tab = action.payload.tab;
    },

    openSnackBar(state, action) {
      ///console.log(action.payload);
      state.snackbar.open = true;
      state.snackbar.severity = action.payload.severity;
      state.snackbar.message = action.payload.message;
    },
    closeSnackBar(state) {
      ///console.log("This is getting executed");
      state.snackbar.open = false;
      state.snackbar.message = null;
    },
    fetchUser(state, action) {
      state.user = action.payload.user;
    },

    updateUsers(state, action) {
      state.users = action.payload.users;
    },
    fetchUserById(state, action) {
      state.userById = action.payload.userById;
    },
    updateAllUsers(state, action) {
      state.all_users = action.payload.users;
    },
    updateFriends(state, action) {
      state.friends = action.payload.friends;
    },
    updateFriendRequests(state, action) {
      state.friendRequests = action.payload.requests;
    },
    selectConversation(state, action) {
      state.chat_type = "individual";
      state.room_id = action.payload.room_id;
    },
    setIsSentMessage(state, action) {
      state.isSentMsg = action.payload.isSentMsg;
    },


    refreshAPI(state) {
      state.refresh.open = !state.refresh.open;
    },
    initialState(state) {
      state = initialState;
      return initialState;
    }
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const closeSnackBar = () => async (dispatch, getState) => {
  dispatch(slice.actions.closeSnackBar());
};


export function RefreshAPI() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.refreshAPI());
  };
}
export const showSnackbar =
  ({ severity, message }) =>
    async (dispatch, getState) => {
      dispatch(
        slice.actions.openSnackBar({
          message,
          severity,
        })
      );

      setTimeout(() => {
        dispatch(slice.actions.closeSnackBar());
      }, 4000);
    };

export function ToggleSidebar() {
  return async (dispatch, getState) => {
    dispatch(slice.actions.toggleSideBar());
  };
}
export function UpdateSidebarType(type) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateSideBarType({ type }));
  };
}

export function UpdateTab(tab) {
  return async (dispatch, getState) => {
    dispatch(slice.actions.updateTab(tab));
  };
}

export function FetchUsers() {
  return async (dispatch, getState) => {
    await ApiClient.post("User/GetAllUsers").then(response => {
      // this is the old filter
      // const names = response.data.map(({ name }) => name);
      // const filtered = response.data.filter(({ name, mail }, index) => !names.includes(name, index + 1) && mail.split("@")[1] === "ericsson.com");
      // this is the new filter
      const filtered = response.data//.filter(({ name, mail }, index) =>mail.split("@")[1] === "ericsson.com");

      dispatch(slice.actions.updateUsers({ users: filtered}));
    }).catch(error => {
      console.error(error);;
    });

  };
}
export function FetchUserById(data) {

  return async (dispatch, getState) => {
    await ApiClient.post("User/GetUserById", { id: data }).then(response => {
      ///console.log("response FetchUsers",response,response.data)
      const ele = {}
      ele.email = response.data.email;
      ele.userName = response.data.name;
      ele.profileAvatar = response.data.profileAvatar;
      dispatch(slice.actions.fetchUserById({ userById: ele }));
    }).catch(error => {
      console.error(error);;
    });

  };
}
export function FetchAllUsers() {
  return async (dispatch, getState) => {
    await ApiClient.post("User/GetAllUsers").then(response => {
      dispatch(slice.actions.updateAllUsers({ users: response.data }));

    }).catch(error => {
      console.error(error);;
    });
  };
}
export function FetchFriends(data) {

  ///console.log("testing send data payload FetchFriends  chat",)

  return async (dispatch, getState) => {
    dispatch(slice.actions.updateFriends({ friends: data }));

  };
}
export function FetchFriendRequests(payload) {
  ///console.log("testing send data payload FetchFriendRequests  chat",payload)

  return async (dispatch, getState) => {
    await ApiClient.post("User/GetAllParticipantInvited", payload)
      .then((response) => {
        ///console.log("testing send data payload FetchFriends",response)

        dispatch(slice.actions.updateFriends({ friends: response.data }));
      })
      .catch((err) => {
        ///console.log(err);
      });
  };
}

export const SelectConversation = ({ room_id }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.selectConversation({ room_id }));
  };
};


export const setIsSentMessage = ({ isSentMsg }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.setIsSentMessage({ isSentMsg }));
  };
};


export const setChatInitialState= () => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.initialState());
  };
};
