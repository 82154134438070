import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageHelper from "../../utils/page-helper";
import { ToastContainer, toast } from 'react-toastify';
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTile from "../../eui-components/tile";
import ApiClient from "../../utils/api-client";
import TicketHelper from "../../utils/ticket-helper";
import DialogModal from "../../components/dialog";
import AssignTicketDialog from "./assignTicketDialog";
import Constants from "../../utils/constants";
import EuiLoading from "../../eui-components/loading";
import constants from "../../utils/constants";
import MenuMultiPanelTile from "../../components/menuMultiPanel";
import EuiTabs from "../../eui-components/tabs";
import TicketFilteredList from "./ticketFilteredList";
import EuiHeader from "../../eui-components/header";
import DateUtils from "../../utils/date-utils";

class TicketList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            openAssignDialog: false,
            openUnassignDialog: false,
            isRSCCoordinator: false,
            selectedTickets: []
        }
    }

    componentDidMount() {
        PageHelper.getCoordinates();

        // Prevent multiple call
        if (((this.props.ticketData.tickets.tickets || []).length == 0) && this.props.ticketData.ticketSelectedTab == constants.ticketTabs.DEFAULT) {
            this.props.setTicketSelectedTab(constants.ticketTabs.ASSIGNED);

            if (this.props.userData.userData.jobRole === constants.userRoles.RSC_COODINATOR) {
                this.getTickets(0, 4)
            } else {
                this.getTickets(this.props.userData.userData.userId, 4)
            }
        }

        if (!this.props.userData.users || this.props.userData.users.length === 0) {
            ApiClient.post("User/GetAllUsers").then(response => {
                this.props.setAllUsers(response.data);
            }).catch(error => {
                console.error(error);;
            });
        }
    }

    openTicketDetail = (ticketId) => {
        const ticket = this.props.ticketData.tickets.tickets.find(x => x.id === ticketId);
        this.props.setCurrentTicket(ticket);

        this.props.setTicketPageMod(constants.pageMods.EDIT);
    }

    getTickets = (userId = this.props.userData.userData.userId, ticketStatus = 4) => {
        

        this.setState({ isLoading: true });

        const payload = {
            AssignedUserId: userId ? parseInt(userId) : 0,
            Status: ticketStatus
        };

        

        ApiClient.post("Ticketing/GetActiveTickets", payload)
            .then(response => {
                this.setState({ isLoading: false });
                if (response.data) {
                    this.setState({ runOnce: false });

                    let data = response.data;

                    TicketHelper.mapTickets(data, this.props.ticketData.classificationList);
                    const tickets = {tickets: data, initialState: !this.props.ticketData.tickets.initialState}
                    this.props.setTickets(tickets);
                    
                } else {
                    this.setState({ isLoading: false });
                    toast.info("No tickets found", { containerId: 'TL', position: toast.POSITION.TOP_CENTER })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false });
                
                toast.error("An error occured, try again later!", { containerId: 'TL', position: toast.POSITION.TOP_CENTER });
            })
    }

    assignTicket = () => {
        if (!this.props.ticketData.assignTicketInfo.severity) {
            toast.error("Select severity", { containerId: 'TL', position: toast.POSITION.TOP_CENTER });
            return;
        }

        

        this.setState({ openAssignDialog: false, isLoading: true });

        let SLATime = 24;
        switch (this.props.ticketData.assignTicketInfo.severity) {
            case 1:
                SLATime = 24;
                break;
            case 2:
                SLATime = 16;
                break;
            case 3:
                SLATime = 8;
                break;
            case 4:
                SLATime = 4;
                break;
            default:
                SLATime = 24;
        }

        let plannedCompletion = DateUtils.getUtcDate();
        plannedCompletion.setHours(plannedCompletion.getHours() + SLATime);

        const selectedTicketIds = this.props.ticketData.selectedTickets.map((item) => {
            return item.id
        });

        const payload = {
            AssignedTicketList: selectedTicketIds,
            AssignedUserId: parseInt(this.props.ticketData.assignTicketInfo.assignedUserId),
            AssignedByUserId: parseInt(this.props.userData.userData.userId),
            Severity: parseInt(this.props.ticketData.assignTicketInfo.severity),
            PlannedCompletionDate: plannedCompletion            
        };

        // api call
        ApiClient.post("Ticketing/AssignTicketTo", payload)
            .then(response => {
                this.setState({ isLoading: false })

                if (!response.data.success) {
                    toast.info("An error occured, try again later!", { containerId: 'TL', position: toast.POSITION.TOP_CENTER })
                } else {
                    this.getTickets(0, 1);
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                
                toast.error("An error occured, try again later!", { containerId: 'TL', position: toast.POSITION.TOP_CENTER });
            })
    }

    unassignTickets = () => {
        this.setState({ isLoading: true, openUnassignDialog: false })

        const selectedTicketIds = this.props.ticketData.selectedTickets.map((item) => {
            return item.id
        });

        const payload = {
            AssignedTicketList: selectedTicketIds,
            AssignedByUserId: parseInt(this.props.userData.userData.userId)
        };

        // api call
        ApiClient.post("Ticketing/UnassignTickets", payload)
            .then(response => {
                this.setState({ isLoading: false })

                if (!response.data.success) {
                    toast.info("An error occured, try again later!", { containerId: 'TL', position: toast.POSITION.TOP_CENTER })
                } else {
                    if (this.props.userData.userData.jobRole === constants.userRoles.RSC_COODINATOR) {
                        this.getTickets(0, 4)
                    } else {
                        this.getTickets(this.props.userData.userData.userId, 4)
                    }
                }
            })
            .catch(error => {
                this.setState({ isLoading: false })
                
                toast.error("An error occured, try again later!", { containerId: 'TL', position: toast.POSITION.TOP_CENTER });
            })
    }

    openDialog = (ticketId) => {
        const assignTicketInfo = { ...this.props.ticketData.assignTicketInfo };
        assignTicketInfo["assignedUserId"] = this.props.userData.userData.userId;
        assignTicketInfo["severity"] = null; // reset
        this.props.setAssignTicketInfo(assignTicketInfo);

        if (this.props.userData.userData.jobRole === constants.userRoles.RSC_COODINATOR) {
            this.setState({ isRSCCoordinator: true });
        }

        this.setState({
            openAssignDialog: true
        })
    }

    getRightActions = () => {

        const accessUsers = [
            constants.userRoles.RSC_COODINATOR,
            constants.userRoles.RSC_SITE_SUPERVISOR,
            constants.userRoles.RSC_OHS_INSPECTOR
        ];

        if (!accessUsers.includes(this.props.userData.userData.jobRole)) {
            return <th></th>;
        }

        if (this.props.ticketData.ticketSelectedTab == constants.ticketTabs.ASSIGNED) {
            return <>
                <button className="btn warning " style={{ margin: 5 }} onClick={() => {
                    if ((this.props.ticketData.selectedTickets || []).length != 0) {
                        this.setState({ openUnassignDialog: true });
                    } else {
                        toast.error("No selected tickets found", { containerId: 'TL', position: toast.POSITION.TOP_CENTER });
                    }
                }}>
                    <i className="icon icon-cross p-1"></i>Unassign
                </button>
            </>
        } else if (this.props.ticketData.ticketSelectedTab == constants.ticketTabs.UNASSIGNED) {
            return <>
                <button className="btn primary " style={{ margin: 5 }} onClick={() => {
                    
                    if (this.props.ticketData.selectedTickets?.length != 0) {
                        this.openDialog()
                    } else {
                        toast.error("No selected tickets found", { containerId: 'TL', position: toast.POSITION.TOP_CENTER });
                    }
                }}>
                    <i className="icon icon-avatar p-1"></i>Assign
                </button>
            </>
        } else {
            return null
        }
    }

    getMenuList = () => {
        const menu = [
            {
                id: 1, title: "Open",
                icon: <i className="icon icon-avatar m-1 text-lg"></i>,
                content: <TicketFilteredList id="ticketTab1" enableFilter={true} enableMultiSelection={true} />,
                onClick: () => {
                    
                    this.props.setTicketSelectedTab(constants.ticketTabs.ASSIGNED);

                    if (this.props.userData.userData.jobRole === constants.userRoles.RSC_COODINATOR) {
                        this.getTickets(0, 4)
                    } else {
                        this.getTickets(this.props.userData.userData.userId, 4)
                    }
                },
            },
            {
                id: 2, title: "Unassigned", icon: <i className="icon icon-group m-1 text-lg"></i>,
                content: <TicketFilteredList id="ticketTab2" enableFilter={true} enableMultiSelection={true}  />,
                onClick: () => {

                    this.props.setTicketSelectedTab(constants.ticketTabs.UNASSIGNED);
                    this.getTickets(0, 1)
                },
            },
            {
                id: 3, title: "Closed", icon: <i className={"icon icon-check m-1 text-lg"}></i>,
                content: <TicketFilteredList id="ticketTab3" enableFilter={true} enableMultiSelection={false} />,
                hidden: false,
                onClick: () => {

                    this.props.setTicketSelectedTab(constants.ticketTabs.CLOSED);

                    if (this.props.userData.userData.jobRole === constants.userRoles.RSC_COODINATOR) {
                        this.getTickets(0, 2)
                    } else {
                        this.getTickets(this.props.userData.userData.userId, 2)
                    }

                }
            }
        ];
        return menu;
    }

    render() {

        return (<EuiRow disabled={this.state.isLoading ? "disabled" : ""}>
            <EuiTile tileStyle={{padding: 0}} fullscreen>

                <EuiHeader title={
                    <div className="flex-start-content"><i className="icon icon-home pr-1"></i>Home</div>
                } rightActions={this.getRightActions()} />

                <MenuMultiPanelTile id="ticketListMenuMultiPanel"
                    selectedItem={this.props.ticketData.ticketSelectedTab}
                    menuList={this.getMenuList()} />

                <DialogModal
                    buttonName="Confirm"
                    content={<AssignTicketDialog isRSCCoordinator={this.state.isRSCCoordinator} />}
                    handleClose={() => this.setState({ openAssignDialog: false })}
                    id="ticketAssignDialog"
                    onClick={this.assignTicket}
                    open={this.state.openAssignDialog}
                    title="Assign Ticket"
                >
                </DialogModal>

                <DialogModal
                    buttonName="Confirm"
                    content={<div>Are you sure you want to unassign selected tickets? </div>}
                    handleClose={() => this.setState({ openUnassignDialog: false })}
                    id="ticketUnassignDialog"
                    onClick={() => this.unassignTickets()}
                    open={this.state.openUnassignDialog}
                    title="Unassign Tickets"
                >
                </DialogModal>

            </EuiTile>

            <EuiLoading size="large" id="ticketListLoading" isLoading={this.state.isLoading} />
            <ToastContainer enableMultiContainer containerId={'TL'} autoClose={2500} />

        </EuiRow >)
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ticketData: state.ticket });
const mapDispatchToProps = dispatch => {
    return {
        setTickets: (tickets) => dispatch({ type: "SET_TICKETS", payload: tickets }),
        setTicketPageMod: (mod) => dispatch({ type: "SET_TICKET_PAGE_MOD", payload: mod }),
        setCurrentTicket: (ticket) => dispatch({ type: "SET_CURRENT_TICKET", payload: ticket }),
        setTicketSelectedTab: (selectedTab) => dispatch({ type: "SET_TICKET_SELECTED_TAB", payload: selectedTab }),
        setAllUsers: users => dispatch({ type: "SET_ALL_USERS", payload: users }),
        setAssignTicketInfo: ticketInfo => dispatch({ type: "SET_ASSIGN_TICKET_INFO", payload: ticketInfo })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TicketList));