import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../eui-components/row";
import EuiTextField from "../../../eui-components/textfield";
import { ToastContainer, toast } from 'react-toastify';
import FormField from "../../../eui-components/formfield";
import { IconButton, Tooltip } from "@material-ui/core";
import constants from "../../../utils/constants";
import AutoCompleteMultiSelect from "../../../components/autocompletemultiselect";
import ApiClient from "../../../utils/api-client";
import DialogModal from "../../../components/dialog";
import EuiLoading from "../../../eui-components/loading";
import CommonHelper from "../../../utils/common-helper";

class EhsPingViolation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            email: "",
            openDialog: false,
        }
    }

    componentDidMount() {
        if (!this.props.userData.users || this.props.userData.users.length === 0) {
            ApiClient.post("User/GetAllUsers").then(response => {
                this.props.setAllUsers(response.data);
            }).catch(error => {
                console.error(error);;
            });
        }
        const ehsPing = this.props.ehsPing || this.props.ehsInboxData.currentEhsPing;
        if (ehsPing.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING || this.props.detailPage) {
            this.setState({ isLoading: true }, () => {
                ApiClient.post("Projects/GetTeamMembersByProjectId", { id: ehsPing.projectId }).
                    then(response => {
                        
                        const ehs = this.props.detailPage ? { ...this.props.ehsPingData.ehsPing } : { ...this.props.ehsInboxData.currentEhsPing };
                        const newUsers = (response.data?.data ?? []).filter(u => !ehs.violationSentUsers?.map(v => v.value)?.includes(u.user?.id));
                        newUsers.map(v => {
                            v.value = v.user?.id;
                            v.text = v.user?.name;
                            v.mail = v.user?.email;
                        });
                        ehs.violationSentUsers = [...(ehs.violationSentUsers ?? []), ...newUsers];

                        if (this.props.detailPage) {
                            this.props.setEhsPing(ehs);
                        } else {
                            this.props.setCurrentEhsPing(ehs);
                        }
                        this.setState({ isLoading: false });
                    })
                    .catch(error => {
                        toast.error(constants.errorMessage, { container: "VE", position: toast.POSITION.TOP_CENTER });
                        console.error(error);;
                        this.setState({ isLoading: false });
                    });
            });
        }
    }

    handleChange = (e, type) => {
        const target = e.target;
        const value = target.value;
        const ehs = this.props.detailPage ? { ...this.props.ehsPingData.ehsPing } : { ...this.props.ehsInboxData.currentEhsPing };
        if (type === 1) {
            ehs.violationSentUsers = value;
        } else if (type === 2) {
            ehs.externalUsers = value;
        } else if (type === 3) {
            ehs.violationComments = value;
        }
        if (this.props.detailPage) {
            this.props.setEhsPing(ehs);
        } else {
            this.props.setCurrentEhsPing(ehs);
        }
    }

    addEmailList = () => {
        const ehs = this.props.detailPage ? { ...this.props.ehsPingData.ehsPing } : { ...this.props.ehsInboxData.currentEhsPing };
        if ((this.state.email || "") === "") {
            toast.error("Please enter email!", { containerId: 'VE', position: toast.POSITION.TOP_CENTER });
            return;
        } else if (this.props.userData.users.filter(item => !constants.customerRoles.includes(item.role)).map(m => m.mail).includes(this.state.email)) {
            toast.error("Your entered email is already in list, please select from above list!", { containerId: 'VE', position: toast.POSITION.TOP_CENTER });
            return;
        } else if ((ehs.externalUsers || []).length > 0 && ehs.externalUsers.map(item => item.mail).includes(this.state.email)) {
            toast.error("The mail is already in selected list!", { containerId: 'VE', position: toast.POSITION.TOP_CENTER });
            return;
        } else {
            const newEmail = {
                value: new Date().getTime(),
                mail: this.state.email,
                text: "-", role: 101,
                ohsPingId: ehs.id,
                userEmail: this.state.email,
                createDate: new Date(),
                createUser: this.props.userData.userData.userId
            };
            ehs.externalUsers = [...(ehs.externalUsers || []), newEmail];
            if (this.props.detailPage) {
                this.props.setEhsPing(ehs);
            } else {
                this.props.setCurrentEhsPing(ehs);
            }
        }
        this.setState({ email: "" });
    }

    render() {
        const ehsPing = this.props.ehsPing || this.props.ehsInboxData.currentEhsPing;
        return <EuiRow style={{ width: 600 }} className={this.state.isLoading ? " disabled " : ""}>
            <EuiLoading isLoading={this.state.isLoading} />
            <EuiRow>
                <FormField className="w-10">
                    <label htmlFor="ericssonParticipantViolation">Ericsson Users</label>
                    <AutoCompleteMultiSelect
                        id="ericssonParticipantViolation"
                        options={(this.props.userData.users ?? []).filter(item => !constants.customerRoles.includes(item.role))}
                        value={ehsPing.violationSentUsers || []}
                        onChange={e => this.handleChange(e, 1)}
                        disabled={this.props.viewOnly}
                    />
                </FormField>

                <FormField className="flex-start-content w-10">
                    <div style={{ width: "95%" }}>
                        <label htmlFor="externalParticipantViolation">External Users</label>
                        <AutoCompleteMultiSelect
                            id="externalParticipantViolation"
                            options={ehsPing.externalUsers || []}
                            value={ehsPing.externalUsers || []}
                            onChange={e => this.handleChange(e, 2)}
                            disabled={this.props.viewOnly}
                        />
                    </div>
                    {this.props.viewOnly ? null :
                        <Tooltip title="Add External User">
                            <IconButton style={{ marginTop: 15 }} onClick={() => this.setState({ openDialog: true })}>
                                <i className="icon icon-plus m-1"></i>
                            </IconButton>
                        </Tooltip>
                    }
                </FormField>

                <FormField className="w-10">
                    <label className="required" htmlFor="ehsViolationCommentId">Issues</label>
                    <pre style={{ color: "red" }} className="small-info">1000 characters allowed only</pre>
                    <textarea
                        required
                        style={{ height: 200 }}
                        maxLength="1000"
                        className="fullwidth"
                        id="ehsViolationCommentId"
                        name="ehsViolationComment"
                        defaultValue={ehsPing.violationComments || ""}
                        onBlur={e => this.handleChange(e, 3)}
                        disabled={this.props.viewOnly}
                    />
                </FormField>
            </EuiRow>

            <DialogModal
                buttonName="Ok"
                content={<FormField style={{ width: 330 }}>
                    <EuiTextField
                        className="w-10"
                        fullwidth
                        type="email"
                        label="User Email"
                        id="violationEmail"
                        name="email"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                </FormField>}
                handleClose={() => this.setState({ openDialog: false })}
                id="ehsExternalEmailDialog"
                onClick={() => this.setState({ openDialog: false }, () => this.addEmailList())}
                open={this.state.openDialog}
                title="Add External User"
            >
            </DialogModal>

            <ToastContainer enableMultiContainer containerId={'VE'} autoClose={2500} />
        </EuiRow>
    }

}

const mapStateToProps = state => ({
    userData: state.user,
    navbarData: state.navigation,
    ehsInboxData: state.ehsInbox,
    ehsPingData: state.ehsPing
});
const mapDispatchToProps = dispatch => {
    return {
        setCurrentEhsPing: (ehsPing) => dispatch({ type: "SET_CURRENT_EHS_PING", payload: ehsPing }),
        setEhsPageMod: (mod) => dispatch({ type: "SET_EHS_PAGE_MOD", payload: mod }),
        setAllUsers: users => dispatch({ type: "SET_ALL_USERS", payload: users }),
        setEhsPing: (ehsPing) => dispatch({ type: "SET_EHS_PING", payload: ehsPing }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EhsPingViolation);