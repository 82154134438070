import React, { Component } from 'react';
import OpenViduVideoComponent from './ovVideo';
//import './userVideo.css';

export default class UserVideoComponent extends Component {

    getNicknameTag() {
        // Gets the nickName of the user
        return JSON.parse(this.props.streamManager.stream.connection.data).clientData;
    }

    render() {
        return (
            <div>
                {this.props.streamManager !== undefined ? (
                    <div className="streamcomponent">
                        <OpenViduVideoComponent streamManager={this.props.streamManager} />
                        <div style={{ display: "flex", justifyContent:"flex-end" }}>
                            <p style={{ marginBottom: 2, backgroundColor: "transparent", display: "inline-block", color: "#fff", fontWeight: 100, textShadow: "1px 1px 4px #000" }}>
                            {this.getNicknameTag()}
                        </p>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}