import React from "react";

export default class EuiLoading extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div id={this.props.id} className={"generic-loading " + (this.props.isLoading ? "" : "hidden")} style={{ ...this.props.style }}>
                <div className={"loading " + (this.props.size ?? "medium")}
                    style={this.props.loadingStyle}>
                </div>
            </div>
        );
    }
}
