import React from "react";

export default class EuiTooltip extends React.Component {

    render() {
        return <span className={"tooltip " + (this.props.type ?? "pointer")}>
            {this.props.children}
            <span className={"message " + (this.props.position ?? "top-end")}>{this.props.title}</span>
        </span>
    }
}