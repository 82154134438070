import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTile from "../../eui-components/tile";
import EuiTextField from "../../eui-components/textfield";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import ApiClient from "../../utils/api-client";
import Constants from "../../utils/constants";
import DateUtils from "../../utils/date-utils";
import PageHelper from "../../utils/page-helper";

class AssignTicketDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            severityText: "",
            SLAHour: null
        }
    }

    componentDidMount() {
        if (!this.props.userData.users || this.props.userData.users.length === 0) {
            ApiClient.post("User/GetAllUsers").then(response => {
                this.props.setAllUsers(response.data);
            }).catch(error => {
                console.error(error);;
            });
        }
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        var value = target.value;
        const assignTicketInfo = { ...this.props.ticketData.assignTicketInfo };
        assignTicketInfo[name] = value;
        this.props.setAssignTicketInfo(assignTicketInfo);
    }

    handleSeverityChange = (e) => {
        const target = e.target;
        const name = target.name;
        var value = target.value;
        const assignTicketInfo = { ...this.props.ticketData.assignTicketInfo };
        assignTicketInfo[name] = value;
        this.props.setAssignTicketInfo(assignTicketInfo);
        this.getPlannedCompletionDate(value);
    }

    getPlannedCompletionDate = (value) => {
        let SLATime = 24;
        //
        switch (parseInt(value)) {
            case 1:
                SLATime = 24;
                break;
            case 2:
                SLATime = 16;
                break;
            case 3:
                SLATime = 8;
                break;
            case 4:
                SLATime = 4;
                break;
            default:
                SLATime = 24;
        }

        this.setState({ SLAHour: SLATime });

        let stateText = ""

        this.props.ticketData.selectedTickets.forEach((item) => {
            var ticketDate = new Date(item.createDate);
            var dateNow = new Date(DateUtils.getUtcDate());

            var diff = DateUtils.dateDifferenceInhours(dateNow, ticketDate);

            if (diff >= SLATime) {
                stateText += item.id + " - " + item.title + "\n";
            }
        })

        this.setState({ severityText: stateText})
    }

    getSelectedTickets = () => {
        let selectedKeys = ""
        this.props.ticketData.selectedTickets.forEach((item) => {
            selectedKeys += item.id + ", ";
        });
        selectedKeys = selectedKeys.substring(0, selectedKeys.length - 2);
        return selectedKeys;
    }

    render() {
        return (
            <EuiRow style={{width: 600, height: 300}}>
                <EuiTile tileStyle={{padding: 5}}>

                    <EuiTextField
                        className="p-1"
                        fullwidth
                        readOnly
                        id="selectedTicketIds"
                        name="selectedTicketIds"
                        label="Selected Tickets"
                        value={this.getSelectedTickets()} />

                    <EuiAutocompleteSelect
                        className="p-1"
                        fullwidth
                        disabled={!this.props.isRSCCoordinator}
                        required
                        label={{ title: "Assign To"}}
                        id="ticketUserSelect12"
                        name="assignedUserId"
                        options={PageHelper.getEricssonUsers(this.props.userData.users)}
                        value={this.props.userData.userData.userId || 0}
                        onChange={(e) => this.handleChange(e)}
                        placeholder="---Select User---"
                    />

                    <EuiAutocompleteSelect
                        className="p-1"
                        fullwidth
                        required
                        label={{ title: "Select Severity" }}
                        id="ticketSeveritySelect2"
                        name="severity"
                        options={Constants.ticketSeverity}
                        value={this.props.ticketData.assignTicketInfo.severity || 0}
                        onChange={(e) => this.handleSeverityChange(e)}
                        placeholder="---Select Severity---"
                    />

                    {this.state.SLAHour && <span style={{ marginLeft: 6, marignTop: 25, marginBottom: 25 }}>SLA time to close ticket is {this.state.SLAHour} Hours</span>}

                    {this.state.severityText && <EuiTextField
                        className="p-1"
                        multiLine
                        style={{color: "red", height: 100}}
                        fullwidth
                        readOnly
                        id="ticketUserNameDialog2"
                        name="ticketUserName"
                        label="Overdue Tickets Available"
                        value={this.state.severityText} />}
                </EuiTile>
            </EuiRow>
            );
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ticketData: state.ticket });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setCurrentTicket: (ticket) => dispatch({ type: "SET_CURRENT_TICKET", payload: ticket }),
        setAllUsers: users => dispatch({ type: "SET_ALL_USERS", payload: users }),
        setAssignTicketInfo: ticketInfo => dispatch({ type: "SET_ASSIGN_TICKET_INFO", payload: ticketInfo })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignTicketDialog);