import constants from "./constants";

export default class UserHelper {

    static getUser = (users, userId) => {
        if ((users || []).length === 0 || !userId) {
            return "";
        } else {
            return users.find(u => u.value === userId);
        }
    }

    static isRscInspector = (jobRole) => {
        return [/*constants.userRoles.RSC_SITE_SUPERVISOR,*/ constants.userRoles.RSC_OHS_INSPECTOR].includes(jobRole); // Only for EHS sessions
    }


}