import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiLoading from "../../eui-components/loading";
import EuiSelect from "../../eui-components/select";
import { Notification } from '@eds/vanilla';
import ApiClient from "../../utils/api-client";
import DateUtils from "../../utils/date-utils";
import AspHelper from "../../utils/asp-helper";
import constants from "../../utils/constants";
import FormField from "../../eui-components/formfield";
import EuiTextField from "../../eui-components/textfield";
import EuiDatepicker from "../../eui-components/datepicker";
import EuiTabs from "../../eui-components/tabs";
import DialogModal from "../../components/dialog";
import PageHelper from "../../utils/page-helper";
import SasHelper from "../../utils/sas-helper";

class Competences extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            competencesColumns: [
                {
                    title: "Description",
                    key: "name",
                    width: "10%"
                },
                {
                    title: "Status",
                    key: "skillId",
                    onCreatedCell: (td, cellData) => {
                        const skill = this.state.data?.find(item => item.skillId == cellData && item.active);
                        var value = "";
                        if (!skill?.skillValue)
                            value = "";
                        else
                            value = skill.skillValue;
                        if (skill && skill.skillValue && skill.skillValue.edit && skill.skillValue.editId == skill.skillId) {
                            var v = skill.skillValue.editValue;
                            if (skill.skillValue.type == "TEXT") {
                                td.innerHTML = ` <span width="450px"><textarea style="width:450px;" type="text" id="update_" >${v}</textarea>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else if (skill.skillValue.type == "DATE") {
                                td.innerHTML = ` <span><input  type="date" id="update_" value="${v}" ></input>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else if (skill.skillValue.type == "LIST") {
                                var optionsList = `<option disabled selected value=""></option>`;
                                var selectedItem = "";
                                if (skill.options && skill.options.length) {
                                    skill.options.forEach(e => {
                                        if (e.text === skill.skillValue.editValue)
                                            selectedItem = e.text;
                                        optionsList += `<option value="${e.text}" >${e.text}</option>`;
                                    })
                                }
                                td.innerHTML = ` <span><select id="update_" value="${selectedItem}" style="height:30px;"> ${optionsList}</select>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else if (skill.skillValue.type == "NUMBER") {
                                td.innerHTML = ` <span><input  type="number" name="number" id="update_" min="0" max="100" value="${v}" ></input>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else {
                                td.innerHTML = ` <span><input  type="text" id="update_" value="${v}" ></input>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            td.querySelector("#save_").addEventListener('click', () => this.saveSkillValue(skill.skillId));
                            td.querySelector("#cancel_").addEventListener('click', () => this.cancel(skill.skillId));
                            td.querySelector("#update_").addEventListener('change', (e) => this.update(e));
                        }
                        else {
                            td.innerHTML = `<text style="max-width:100px" readonly type="text" id="value"  >${value}</text>`;
                        }
                    },
                    width: "20%"
                },
                {
                    title: "Last Modified Date",
                    key: "lastModifiedDateTime",
                    width: "10%",
                },
                {
                    title: "Last Modified User",
                    key: "lastModifiedUserName",
                    width: "10%",
                },
                {
                    title: "",
                    key: "skillId",
                    width: "2%",
                    onCreatedCell: (td, cellData) => {
                        if (cellData && (this.props.userData.userData.jobRole == constants.userRoles.ERICSSON_IM || this.props.userData.userData.jobRole == constants.userRoles.RSC_OHS_INSPECTOR || this.props.userData.userData.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                        )) {
                            td.innerHTML = `<span id="editSkillCompetences_" class="tooltip"><i id="editSkillCompetences_" style={font-weight:500px;} class="icon icon-edit color-black text-lg clickable my-disable "></i><span class="message top-end">Edit Skill</span></span>`;
                            td.querySelector("#editSkillCompetences_").addEventListener('click', () => this.initiateEdit(cellData))
                        }
                        else
                            td.innerHTML = "";
                    }
                },
            ],
            trainigColumns: [
                {
                    title: "Description",
                    key: "name",
                    width: "13%"
                },
                {
                    title: "Status",
                    key: "skillId",
                    onCreatedCell: (td, cellData) => {
                        const skill = this.state.data?.find(item => item.skillId == cellData && item.active);
                        var value = "";
                        if (!skill?.skillValue)
                            value = "";
                        else
                            value = skill.skillValue;
                        if (skill && skill.skillValue && skill.skillValue.edit && skill.skillValue.editId == skill.skillId) {
                            var v = skill.skillValue.editValue;
                            if (skill.skillValue.type == "TEXT") {
                                td.innerHTML = ` <span><textarea type="text" id="update_"rows="4" cols="80" >${v}</textarea>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else if (skill.skillValue.type == "DATE") {
                                td.innerHTML = ` <span><input  type="date" id="update_" value="${v}" ></input>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else if (skill.skillValue.type == "LIST") {
                                var optionsList = "";
                                var selectedItem = "";
                                if (skill.options && skill.options.length) {
                                    skill.options.forEach(e => {
                                        if (e.text === skill.skillValue.editValue)
                                            selectedItem = e.text;
                                        optionsList += `<option value="${e.text}" selected=${`"${e.text}"` === selectedItem ? true : false}>${e.text}</option>`;
                                    })
                                }
                                td.innerHTML = ` <span><select id="update_" value="${selectedItem}" > ${optionsList}</select>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else if (skill.skillValue.type == "NUMBER") {
                                td.innerHTML = ` <span><input name="number" type="number" id="update_"  min="0" max="100" value="${v}" ></input>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else {
                                td.innerHTML = ` <span><input  type="text" id="update_" value="${v}" ></input>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            td.querySelector("#save_").addEventListener('click', () => this.saveSkillValue(skill.skillId));
                            td.querySelector("#cancel_").addEventListener('click', () => this.cancel(skill.skillId));
                            td.querySelector("#update_").addEventListener('change', (e) => this.update(e));
                        }
                        else {
                            td.innerHTML = `<text style="max-width:100px" readonly type="text" id="value"  >${value}</text>`;
                        }
                    },
                    width: "10%"
                },
                {
                    title: "Issue Date",
                    key: "assignDate",
                    width: "10%",
                    onCreatedCell: (td, cellData) => {
                        td.innerHTML = cellData?.slice(0, 10) ?? ""
                    }
                },
                {
                    title: "Last Modified Date",
                    key: "lastModifiedDateTime",
                    width: "10%",
                },
                {
                    title: "Last Modified User",
                    key: "lastModifiedUserName",
                    width: "10%",
                },
                {
                    title: "",
                    key: "skillId",
                    width: "2%",
                    onCreatedCell: (td, cellData) => {
                        if (cellData && (this.props.userData.userData.jobRole == "1" || this.props.userData.userData.jobRole == "7" || this.props.userData.userData.jobRole == "8" || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                        )) {
                            td.innerHTML = `<span id="editSkillCompetences_" class="tooltip"><i id="editSkillCompetences_" style={font-weight:500px;} class="icon icon-edit color-black text-lg clickable my-disable "></i><span class="message top-end">Edit Skill</span></span>`;
                            td.querySelector("#editSkillCompetences_").addEventListener('click', () => this.openEditDialog(cellData))
                        }
                        else
                            td.innerHTML = "";
                    }
                },
            ],
            certificationColumns: [
                {
                    title: "Description",
                    key: "name",
                    width: "13%"
                },
                {
                    title: "Status",
                    key: "skillId",
                    onCreatedCell: (td, cellData) => {
                        const skill = this.state.data?.find(item => item.skillId == cellData && item.active);
                        var value = "";
                        if (!skill?.skillValue)
                            value = "";
                        else
                            value = skill.skillValue;
                        if (skill && skill.skillValue && skill.skillValue.edit && skill.skillValue.editId == skill.skillId) {
                            var v = skill.skillValue.editValue;
                            if (skill.skillValue.type == "TEXT") {
                                td.innerHTML = ` <span><textarea style="font-family: Ericsson Hilda;" type="text" id="update_"rows="4" cols="80" >${v}</textarea>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else if (skill.skillValue.type == "DATE") {
                                td.innerHTML = ` <span><input style="font-family: Ericsson Hilda;" type="date" id="update_" value="${v}" ></input>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else if (skill.skillValue.type == "LIST") {
                                var optionsList = `<option value="${""}"selected=${`"${!skill.skillValue.editValue ? true : false}"`}>${""}</option>`;
                                var selectedItem = "";
                                if (skill.options && skill.options.length) {
                                    skill.options.forEach(e => {
                                        if (e.text === skill.skillValue.editValue)
                                            selectedItem = e.text;
                                        optionsList += `<option value="${e.text}" selected=${`"${e.text}"` === selectedItem ? true : false}>${e.text}</option>`;
                                    })
                                }
                                td.innerHTML = ` <span><select id="update_" value="${selectedItem} style="height: 25px;font-family: Ericsson Hilda;" > ${optionsList}</select>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else if (skill.skillValue.type == "NUMBER") {
                                td.innerHTML = ` <span><input style="font-family: Ericsson Hilda;" type="number" name="number" id="update_"  min="0" max="100" value="${v}" ></input>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            else {
                                td.innerHTML = ` <span><input style="font-family: Ericsson Hilda;"  type="text" id="update_" value="${v}" ></input>
                                         <span id="save_" class="tooltip"><i id="save_"  class="icon icon-check  text-lg clickable color-green "></i><span class="message top-end">Save</span></span>
                                         <span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>
                                         </span>`;
                            }
                            td.querySelector("#save_").addEventListener('click', () => this.saveSkillValue(skill.skillId));
                            td.querySelector("#cancel_").addEventListener('click', () => this.cancel(skill.skillId));
                            td.querySelector("#update_").addEventListener('change', (e) => this.update(e));
                        }
                        else {
                            td.innerHTML = `<text style="max-width:100px" readonly type="text" id="value"  >${value}</text>`;
                        }
                    },
                    width: "10%"
                },
                {
                    title: "Issue Date",
                    key: "assignDate",
                    width: "10%",
                    onCreatedCell: (td, cellData) => {
                        td.innerHTML = cellData?.slice(0, 10) ?? ""
                    }
                },
                {
                    title: "Certificate Link",
                    key: "crtPath",
                    width: "10%",
                    // cellStyle: 'padding:0px',
                    onCreatedCell: (td, cellData) => {
                        if (cellData) {
                            td.innerHTML = `<a id="certificate_"  ><span id="certificate_" href=${SasHelper.addSasKey(cellData, this.props.sasToken)} target="_blank"><i class="icon icon-download"></i>Certificate</span></a>`;
                            if (td.querySelector("#certificate_")) {
                                td.querySelector("#certificate_").addEventListener('click', () => this.openViewCrtDialog(SasHelper.addSasKey(cellData, this.props.sasToken)));
                            }
                        }
                        else
                            td.innerHTML = ""
                    },
                    sort: 'none'
                },
                {
                    title: "Last Modified Date",
                    key: "lastModifiedDateTime",
                    width: "10%",
                },
                {
                    title: "Last Modified User",
                    key: "lastModifiedUserName",
                    width: "10%",
                },
                {
                    title: "",
                    key: "skillId",
                    width: "2%",
                    onCreatedCell: (td, cellData) => {
                        if (cellData && (this.props.userData.userData.jobRole == constants.userRoles.ERICSSON_IM || this.props.userData.userData.jobRole == constants.userRoles.RSC_OHS_INSPECTOR || this.props.userData.userData.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                        )) {
                            td.innerHTML = `<span id="editSkillCompetences_" class="tooltip"><i id="editSkillCompetences_" style={font-weight:500px;} class="icon icon-edit color-black text-lg clickable my-disable "></i><span class="message top-end">Edit Skill</span></span>`;
                            td.querySelector("#editSkillCompetences_").addEventListener('click', () => this.openEditDialog(cellData))
                        }
                        else
                            td.innerHTML = "";
                    }
                },
            ],
            data: [],
            isLoading: true,
            skillValue: "",
            skillOptions: [],
            currentSkill: { options: [], selectedOption: "" },
            optionTypeText: "",
            selectedTab: "",
            titles: [],
            contents: [],
            edit: false,
            editId: "",
            openEditCertificates: false,
            openViewCrtDialog: false,
            certFile: "",
            type: "",
        }
    }
    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        var data = [];
        const _skills = [...this.props.aspResourcesData.skills.filter(item => item.active)];
        const resourceSkills = [...this.props.aspResourcesData.resource.skills];
        if (_skills) {
            _skills.forEach(skill => {
                var resourceSkill = resourceSkills?.find(item => item.skillId == skill.id);
                var options = [];
                ///skills option mapping
                if (skill.skillOptions) {
                    skill.skillOptions.forEach(o => {
                        var option = {
                            text: o.name,
                            value: o.id
                        }
                        options.push(option);
                    });
                }
                if (resourceSkill) {
                    var selectedOption = options?.find(item => item.text == resourceSkill.skillValue)?.id;
                    var fullSkill = {
                        ...skill,
                        ...resourceSkill,
                        options: options,
                        selectedOption: selectedOption,
                        id: resourceSkill.id,
                        skillId: skill.id
                    }
                    data.push(fullSkill);
                }
                else {
                    var fullSkill = {
                        ...skill,
                        options: options,
                        skillValue: "---",
                        selectedOption: "",
                        skillId: (skill.id ? skill.id : skill.skillId),
                        id: ""
                    }
                    data.push(fullSkill);
                }
            });
        }
        this.setState({
            data: data
        });
        this.renderContent(data);
    }
    updateData = () => {
        this.setState({
            isLoading: true
        })
        const filter = this.props.aspResourcesData.filter;
        if (!filter || Object.values(filter).every(x => (x === null || x === ''))) {
            filter.id = this.props.aspResourcesData.resource.id;
            filter.idsList = [];
            filter.idsList.push(this.props.aspResourcesData.resource.id);
        }
        filter.id = parseInt(filter.id ?? "0");
        ApiClient.post("Asp/GetResources", filter)
            .then(response => {
                ApiClient.post("Asp/GetResourceJobs", response.data.iDs)
                    .then(response2 => {
                        var _data = [];
                        var blobs = response.data.profileBlobs;
                        var resourceProjects = response.data.projects;
                        var certificates = [];
                        var resourceJobs = response2.data;
                        var skills = response.data.skills;
                        var faces = response.data.faces;
                        this.props.setResourceUsers(response.data.users);
                        response.data.certifications.forEach(c => {
                            var _c = this.props.aspResourcesData.certificates.filter(x => x.value == c.certificationId);
                            if (_c && _c[0]) {
                                var date = DateUtils.getUtcDate();
                                var diff = DateUtils.dateDifference(new Date(c.validUntil), new Date(date));
                                var element = {
                                    ...c,
                                    validSince: DateUtils.toDateString(c.validSince),
                                    validUntil: DateUtils.toDateString(c.validUntil),
                                    createdate: DateUtils.toDateString(c.createdate),
                                    lastModifiedDateTime: DateUtils.toDateString(c.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                                    createUserName: c.createUserName,
                                    modifiedUserName: c.modifiedUserName,
                                    name: _c[0].text,
                                    code: _c[0].code,
                                    path: c.path,
                                    status: (new Date(c.validUntil).getTime() - date.getTime() > 0 ? "Valid" : "Expired"),
                                    _delete: c.id
                                }
                                certificates.push(element);
                            }
                        })
                        this.props.setFilteredResources([]);
                        response.data.resource.forEach(e => {
                            var projects = AspHelper.mapProjects(resourceProjects?.filter(item => item.resourceId == e.id), this.props.aspResourcesData.projects, this.props.userData.customers);
                            var country = this.props.userData.countries?.filter(c => c.value === e.countryId);
                            var company = this.props.aspResourcesData.companies.filter(c => c.value === e.aspCompanyId);
                            var cert = certificates.filter(c => c.resourceId === e.id);
                            var jobs = [];
                            var resourceSkills = skills.filter(s => s.resourceId == e.id);
                            var faceId = faces.find(f => f.resourceId == e.id)?.faceId;
                            var fullResourceSkills = AspHelper.getSkillInfo(resourceSkills, this.props.aspResourcesData.skills);
                            var _jobs = resourceJobs.filter(j => j.resourceId == e.id);
                            var inCompletedCerts = [];
                            var validResource = false;
                            _jobs.forEach(j => jobs.push(AspHelper.mapResourcesJobRole(j.value, this.props.aspResourcesData.jobs)));
                            var blob = blobs.filter(b => b.resourceId == e.id);
                            inCompletedCerts = AspHelper.getUnTakenCerts(cert, this.props.aspResourcesData.jobs, certificates, jobs);
                           /* if (e.status == "Active" && jobs.length) {
                                var test = true;
                                if (inCompletedCerts) {
                                    inCompletedCerts.forEach(c => {
                                        if (!c.valid)
                                            test = false;
                                    });
                                }
                                if (test)
                                    validResource = true;
                            }
                                */
                            var test = true;
                            if (inCompletedCerts) {
                             inCompletedCerts.forEach(c => {
                                 if (!c.valid)
                                     test = false;
                             });
                         }
                         validResource = test
                            if (jobs)
                            var element = {
                                ...e,
                                projects: projects,
                                status: e.status,
                                country: (country && country[0] ? country[0].text : ""),
                                aspCompany: (company && company[0] ? company[0].text : ""),
                                companyCountry: (company && company[0] ? company[0].countryId : ""),
                                profileImg: (blob && blob[0] ? constants.profileStorage + blob[0].key : ""),
                                certs: cert,
                                jobs: (jobs ? jobs : []),
                                skills: fullResourceSkills,
                                jobList: (_jobs ? _jobs : []),
                                inCompletedCerts: inCompletedCerts,
                                validResource: validResource,
                                faceId: faceId,
                                path: (faceId ? e.path : null),
                                imagePath: (faceId ? e.imagePath : null)
                            }
                            _data.push(element);
                        });
                        const data = [..._data];
                        const selectedResource = data.find(item => item.id === this.props.aspResourcesData.resource.id);
                        this.props.setAspResource(selectedResource);
                        this.props.setFilteredResources(data);
                        this.props.setSelectedItem(5);
                        var d = Date.now();
                        this.props.updateTable(d);
                        this.setState({ isLoading: false })
                    })
            }).catch(error => {
                console.error(error);;
                alert(error);
            });
    }
    initiateEdit = (cellData) => {
        const skill = this.state.data.find(item => item.skillId == cellData);
        const _skills = [...this.state.data.filter(item => item.active)];
        skill['selectedOption'] = (skill.skillValue !== "---" ? skill.options.find(item => item.text === skill.skillValue)?.value : "");
        this.setState({
            edit: true,
            editId: cellData,
            currentSkill: skill,
            optionTypeText: skill.optionsType,
            data: []
        });
        var _skill = _skills.find(item => item.skillId == cellData);
        var value = _skill.skillValue || "";
        _skill.skillValue = { editId: cellData, edit: true, editValue: value, type: _skill.optionsType.toUpperCase() };
        this.setState({
            data: _skills
        });
        this.renderContent(_skills);
    }
    onCancel = () => {
        const currentSkill = { ...this.state.currentSkill };
        currentSkill['skillValue'] = "---";
        this.setState({
            currentSkill: currentSkill,
            openEditCertificates: false
        })
    }
    saveSkillValue = () => {
        const skilltoUpdate = { ...this.state.currentSkill };
        if (skilltoUpdate.optionsType == "Number" && (skilltoUpdate?.skillValue < 0 || skilltoUpdate?.skillValue > 100 || !skilltoUpdate?.skillValue)) {
            const notification = new Notification({
                title: 'Edit Skill',
                description: 'Value should be between 0 and 100.',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
        }
        else {
            this.setState({
                openEditCertificates: false,
                isLoading: true,
            })
            skilltoUpdate['lastModifiedUser'] = parseInt(this.props.userData.userData.userId)
            skilltoUpdate['resourceId'] = parseInt(this.props.aspResourcesData.resource.id);
            if (!skilltoUpdate.id)
                skilltoUpdate['id'] = parseInt(0);
            if (skilltoUpdate.lastModifiedDateTime)
                skilltoUpdate.lastModifiedDateTime = null;
            ApiClient.post("Asp/AddEditSkill", skilltoUpdate)
                .then(response => {
                    this.setState({
                        isLoading: false,
                    })
                    if (!response.data) {
                        const notification = new Notification({
                            title: 'Edit Skill',
                            description: 'Please select value to save',
                            timeout: 5000,
                            icon: 'icon-triangle-warning'
                        });
                        notification.init();
                    }
                    else {
                        this.updateData();
                    }
                }).
                catch(error => {
                    alert(error);
                    this.setState({
                        isLoading: false
                    })
                })
        }
    }
    cancel = (cellData) => {
        const skill = this.state.data.find(item => item.skillId == cellData);
        const _skills = [...this.state.data.filter(item => item.active)];
        this.setState({
            data: []
        });
        skill['selectedOption'] = (skill.skillValue !== "---" ? skill.options.find(item => item.text === skill.skillValue)?.value : "");
        this.setState({
            edit: false,
            editId: cellData,
            currentSkill: skill,
        });
        var _skill = _skills.find(item => item.skillId == cellData);
        var value = _skill.skillValue.editValue || "";
        _skill.skillValue = value;
        this.setState({
            data: _skills
        });
        this.renderContent(_skills);
    }
    update = (e) => {
        const currentSkill = { ...this.state.currentSkill };
        const name = e.target.name;
        if (name == "selectDialog")
            currentSkill['skillValue'] = e.target.text;
        else if (name == "assignDate")
            currentSkill['assignDate'] = e.target.value;
        else if (name == "number" && (e.target.value < 0 || e.target.value > 100)) {
            currentSkill['skillValue'] = -1;
        }
        else
            currentSkill['skillValue'] = e.target.value;
        this.setState({
            currentSkill: currentSkill
        })
    }
    handleSkillText = (e) => {
        const currentSkill = { ...this.state.currentSkill };
        currentSkill['skillValue'] = e.target.value;
        this.setState({
            currentSkill: currentSkill
        })
    }
    handleSkillDate = (e) => {
        const currentSkill = { ...this.state.currentSkill };
        currentSkill['skillValue'] = DateUtils.toDateString(e.target.value);
        this.setState({
            currentSkill: currentSkill
        })
    }
    renderContent = (_data) => {
        var index = 0;
        var titles = [];
        var contents = [];
        const skills = Object.entries(this.props.aspResourcesData.groupedSkills)
            .filter(item => item[0] !== "Suspension Reason" && item[0] != "PPE")
            .map(item => {
                const data = _data.filter(d => d.skillArea == item[0]);
                const id = `${item[0].replace('', '_')}_id`;
                index++;
                if (data.length) {
                    const skillItem = {
                        id: "skills_" + index,
                        title: item[0],
                        titleStyle: { fontWeight: 700 },
                        content:
                            (item[0] === "Technical Training" ?
                                <ObseleteEuiTable
                                    className="w-10"
                                    hideTableInfo={true}
                                    classes="tiny"
                                    columns={this.state.trainigColumns}
                                    data={data}
                                    id={"skillsTable_" + index}
                                    sortable={true}
                                    rowsPerPage={data.length}
                                    key={this.state.data}
                                >
                                </ObseleteEuiTable>
                                :
                                (item[0] === "Certification" ?
                                    <ObseleteEuiTable
                                        className="w-10"
                                        hideTableInfo={true}
                                        classes="tiny"
                                        columns={this.state.certificationColumns}
                                        data={data}
                                        id={"skillsTable_" + index}
                                        sortable={true}
                                        rowsPerPage={data.length}
                                        key={this.state.data}
                                    >
                                    </ObseleteEuiTable> :
                                    <ObseleteEuiTable
                                        className="w-10"
                                        hideTableInfo={true}
                                        classes="tiny"
                                        columns={this.state.competencesColumns}
                                        data={data}
                                        id={"skillsTable_" + index}
                                        sortable={true}
                                        rowsPerPage={data.length}
                                        key={this.state.data}
                                    >
                                    </ObseleteEuiTable>))
                    }
                    titles.push({
                        value: skillItem.id, name: skillItem.title, selected: (skillItem.title == this.props.aspResourcesData.selectedTab ? "selected" : ""), onClick: this.changeTab
                    });
                    contents.push({
                        value: skillItem.id, children: skillItem.content, selected: (skillItem.title == this.props.aspResourcesData.selectedTab ? "selected" : "")
                        , contentStyle: { width: "100%" }
                    });
                    return skillItem;
                }
            }
            );
        this.setState({
            titles: titles,
            contents: contents,
            isLoading: false,
            selectedTab: titles[0]?.name || ""
        });
    }
    renderDialogBody = () => {
        const skill = { ... this.state.currentSkill };
        return <EuiRow className="w-10">
            <label>Status</label>
            {(skill?.optionsType?.toUpperCase() == "LIST" ?
                <FormField className="w-10">
                    <EuiSelect id="editSkill"
                        value={(this.state.currentSkill.selectedOption ? this.state.currentSkill.selectedOption : "Select Value")}
                        options={this.state.currentSkill.options}
                        label="Select Value"
                        name="selectDialog"
                        optionListStyle={{ width: "100%" }}
                        onChange={(e) => this.update(e)}
                    />
                </FormField> :
                (skill.optionsType == "TEXT" ?
                    <FormField className="w-10">
                        <EuiTextField className="w-10" fullwidth id="skillOption" name="skillOption" multiLine={true}
                            value={(this.state.currentSkill.skillValue)} onChange={(e) => this.update(e)}
                            style={{ height: "150px" }} />
                    </FormField>
                    : (skill.optionsType == "DATE" ?
                        <FormField className="w-10">
                            <EuiDatepicker style={{ width: "80%" }} className="w-10" id="editSkillDate"
                                value={(this.state.currentSkill.skillValue == "---" || !this.state.currentSkill.skillValue ? "" : this.state.currentSkill.skillValue)}
                                onChange={(e) => this.update(e)}
                            />
                        </FormField>
                        :
                        <input type="number" className="w-10" fullwidth id="skillOption" name="skillOption" multiLine={true}
                            value={(this.state.currentSkill.skillValue == "---" || !this.state.currentSkill.skillValue ? 0 : this.state.currentSkill.skillValue)}
                            onChange={(e) => this.fileChange(e)}
                        />)))}
            <FormField
                className="w-10 mt-2 mb-2">
                <EuiDatepicker required
                    style={{ width: "100%" }}
                    id={this.props.id + "ValidSince"}
                    label="Issue Date"
                    dateStyle={{ marginRight: 6 }}
                    name="assignDate"
                    value={skill.assignDate ?? ""}
                    onChange={(e) => this.update(e)}
                    labelStyle={{ fontSize: "14px" }}
                    labelClassName="required"
                />
            </FormField>
            {(skill?.skillArea?.toUpperCase() == "CERTIFICATION" ?
                <FormField className="w-10">
                    <label style={{ fontSize: "14px" }} htmlFor="ctName">Upload Certificate</label>
                    <input style={{ marginRight: "0px", width: "100%" }} id="ctName"
                        label="Upload Certificate"
                        onChange={(e) => this.fileChange(e)} name="file"
                        type="file" id="myFile" name="crt"
                        labelstyle={{ fontSize: "14px" }}
                        accept=".jpg,.jpeg,.png,.pdf"
                    >
                    </input>
                </FormField>
                : null)}
        </EuiRow>;
    }
    changeTab = () => {
        var div = document.querySelector(".title.selected");
        this.setState({
            selectedTab: div?.innerText || ""
        });
        this.props.setSelectedTab(div?.innerText || "");
    }
    openViewCrtDialog = (cellData) => {
        var splittedString = cellData.split(".");
        var type = "";
        if (!splittedString) {
            const notification = new Notification({
                title: 'Link Error',
                description: 'Certificate link is corrupted.',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        var extention = splittedString[splittedString.length - 1];
        if (extention == "jpg" || extention == "jpeg" || extention == "png" || extention == "PNG") {
            type = "img";
            this.setState({
                certFile: cellData,
                type: type,
                openViewCrtDialog: true
            });
        }
        else if (splittedString[splittedString.length - 1] == "pdf") {
            type = "pdf";
            this.setState({
                certFile: cellData,
                type: type,
                openViewCrtDialog: true
            });
        }
        else {
            type = "";
            this.setState({
                certFile: cellData,
                type: type,
                openViewCrtDialog: true
            });
        };
    }
    openEditDialog = (cellData) => {
        const skill = this.state.data?.find(item => item.skillId == cellData);
        skill['selectedOption'] = (skill.skillValue !== "---" ? skill.options.find(item => item.text === skill.skillValue)?.value : "");
        this.setState({
            currentSkill: skill,
            optionTypeText: skill.optionsType,
            openEditCertificates: true
        });
    }
    fileChange = (e) => {
        const files = e.target.files;
        let reader = new FileReader();
        if (files.length !== 0) {
            reader.onload = () => {
                const data = reader.result;
                const currentSkill = { ...this.state.currentSkill };
                currentSkill["crtPath"] = data;
                currentSkill["crtName"] = files[0].name;
                this.setState({
                    currentSkill: currentSkill
                })
            };
            reader.readAsDataURL(files[0]);
        }
    }
    download = () => {
        const link = document.createElement('a');
        link.href = SasHelper.addSasKey(this.state.certFile, this.props.sasToken);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    render() {
        return (
            <EuiRow key={this.state.data} disabled={this.state.isLoading || this.props.aspResourcesData.resource.status == "Blacklisted"  ? "disabled" : ""} className=" w-10">
                <EuiLoading isLoading={this.state.isLoading} />
                <EuiTabs
                    titlesColumnStyle={{ marginBottom: "10px" }}
                    contentStyle={{ width: "100%" }}
                    onClick={this.changeTab}
                    id="competencesTabs"
                    titles={this.state.titles}
                    contents={this.state.contents}
                    tabStyle={{ width: "100%" }}
                // key={this.state.data}
                >
                </EuiTabs >
                <DialogModal
                    key={this.state.currentSkill}
                    id="editDialog"
                    open={this.state.openEditCertificates}
                    title={this.state.currentSkill.name}
                    content={this.renderDialogBody()}
                    buttonName="Save"
                    type="primary"
                    handleClose={this.onCancel}
                    onClick={this.saveSkillValue}
                    contentStyle={{ width: "350px", height: "400px" }}
                />
                <DialogModal
                    fullWidth={true}
                    id="viewCrtDialog"
                    open={this.state.openViewCrtDialog}
                    title="Certificate"
                    content={
                        <div className="document-container">{(this.state.type === "img" ? <img className="responsive-document fit-image" src={SasHelper.addSasKey(this.state.certFile, this.props.sasToken)} />
                            : <iframe className="responsive-document fit-image" id="iframepdf" src={"https://docs.google.com/viewer?url=" + encodeURIComponent(SasHelper.addSasKey(this.state.certFile, this.props.sasToken)) + "&embedded=true"} frameBorder="0" />)}</div>}
                    buttonName="Download"
                    type="primary"
                    handleClose={() => { this.setState({ openViewCrtDialog: false }) }}
                    onClick={this.download}
                />
            </EuiRow>
        )
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        changeAspResourcesMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setFilteredResources: (filteredResources) => dispatch({ type: "SET_FILTERED_RESOURCES", payload: filteredResources }),
        setResourceFilter: (filter) => dispatch({ type: "SET_RESOURCE_FILTER", payload: filter }),
        setResourceCompanies: (companies) => dispatch({ type: "SET_RESOURCE_COMPANIES", payload: companies }),
        setResourceCountries: (countries) => dispatch({ type: "SET_RESOURCE_COUNTRIES", payload: countries }),
        setCertificates: (certs) => dispatch({ type: "SET_CERTIFICATES", payload: certs }),
        setJobs: (jobs) => dispatch({ type: "SET_JOBS", payload: jobs }),
        setIDTypes: (types) => dispatch({ type: "SET_ID_TYPES", payload: types }),
        updateTable: (val) => dispatch({ type: "UPDATE_TABLE", payload: val }),
        setSkills: (skills) => dispatch({ type: "SET_SKILLS", payload: skills }),
        setSelectedItem: (selectedItem) => dispatch({ type: "UPDATE_SELECTED_ITEM", payload: selectedItem }),
        setResourceUsers: (users) => dispatch({ type: "SET_USERS", payload: users }),
        setSelectedTab: (tab) => dispatch({ type: "SET_SELECTED_TAB", payload: tab }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Competences);
