import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import constants from "../../utils/constants";


class ResourcesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            resourcesColumns: [{
                title: "Resource ID",
                onCreatedCell: (td, cellData) => {
                    td.innerHTML = `<a id="resource_${cellData}">${cellData}</a>`;
                    td.querySelector("#resource_" + cellData).addEventListener('click', () => this.openResourceDetail(cellData))
                },
                key: "id",
                width: "5%"
            },
            {
                title: "Employee Name",
                key: "name",
                width: "10%"
            },
            {
                title: "ASP Company",
                key: "aspCompany",
                width: "10%"
            },
            {
                title: "Email",
                key: "email",
                width: "10%"
            },
            {
                title: "iSignum",
                key: "isignum",
                width: "10%"
            },
            {
                title: "Certificate Validity",
                key: "validResource",
                width: "10%",
                onCreatedCell: (td, cellData) => {
                   
                    if (cellData == true)
                        td.innerHTML = `<div className="flex-start-content"><i class="icon icon-alarm-level3 color-green m-1 "></i>Valid</div>`
                    else
                        td.innerHTML = `<div className="flex-start-content"><i class="icon icon-alarm-level3 color-red m-1 "></i>Not Valid</div>`

                },
                sort: 'none',
                hideFilter: true
            },
            {
                title: "Status",
                key: "status",
                width: "10%",

            },
            {
                title: "",
                hideFilter: true,
                key: "teamLead",
                width: "2%",
                sort: 'none',
                onCreatedCell: (td, cellData) => {
                    if (cellData) {
                        td.innerHTML = `<span id="edit_" class="tooltip"><i id="edit_" style={font-weight:500px;} class="icon icon-avatar color-grey text-lg "></i><span class="message top-end">Team Leader</span></span>`;
                    }
                    else {
                        td.innerHTML = "";
                    }
                }
            }
            ],
        }
    }

    componentDidMount() {
    }

    openResourceDetail = (resourceId) => {
        const selectedResource = this.props.aspResourcesData.filteredResources.find(item => item.id === resourceId);
        if (!this.props.aspResourcesData.resource || this.props.aspResourcesData.resource.id !== selectedResource.id)
            this.props.setAspResource(selectedResource);
        this.props.setSelectedItem(1);

        this.props.changeResourceMod(constants.pageMods.VIEW_ONLY);
    }

    render() {
        const resources = this.props.aspResourcesData.filteredResources;
        const excelData = resources?.map(item => {
            var jobs = "";
            var index = 0;
            item.jobs.map(j => {
                index++;
                jobs += j + (index == item.jobs.length ? "" : ", ");
            });
            var element = {
                ResourceID: item.id,
                EmployeeName: item.name,
                EmailAddress: item.email,
                MobileNumber: item.phone,
                iSignum: item.isignum,
                NationalID: item.identificationNumber,
                IsTemLeader: item.teamLead,
                EricssonAspManagerEmail: item.lineManagerEmail,
                Status: item.status,
                LastModifiedDate: item.lastModifiedDate,
                LastModifiedUserName: item.lastModifiedUserName,
                Title: item.position,
                AspCompanyName: item.aspCompany,
                Nationality: item.country,
                FaceID: item.faceId,
                ValidResource: item.validResource,
                jobRoles: jobs
            };
            return element;
        });
        var DD = new Date();
        const _fileName_ = "AspResources-" + DD.toISOString();
        const fileName = _fileName_.split(' ').join('-');
        if (!resources) {
            return <div className="flex-middle-content m-3 w-10 text-lg">Please click on filter to get a list of Resources</div>
        } else if (resources.length === 0) {
            return <div className="flex-middle-content m-3 w-10 text-lg">No resources found.</div>
        } else {
            return (<EuiRow>
                <ObseleteEuiTable
                    key={resources}
                    classes="tiny striped"
                    columns={this.state.resourcesColumns}
                    data={resources}
                    Reportdata={excelData}
                    excelFileName={fileName}
                    id="resourcesFilteredTable"
                    scroll={false}
                    sortable={true}
                    filter={(this.props.userData.userData.jobRole == constants.userRoles.ASP_TEAM_LEADER ? false : true)}
                    paginated={(this.props.userData.userData.jobRole == constants.userRoles.ASP_TEAM_LEADER ? false : true)}
                    exportExcel={(this.props.userData.userData.jobRole == constants.userRoles.ASP_TEAM_LEADER ? false : true)}
                    rowsPerPage={13}
                >
                </ObseleteEuiTable>
            </EuiRow >
            );
        }
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp });
const mapDispatchToProps = dispatch => {
    return {
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        changeResourceMod: (mod) => dispatch({ type: "CHANGE_RESOURCE_MOD", payload: mod }),
        setSelectedItem: (selectedItem) => dispatch({ type: "UPDATE_SELECTED_ITEM", payload: selectedItem }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourcesList);