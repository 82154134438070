import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import PrivacyPolicyDetails from './PrivacyPolicyDetails'
import EuiTooltip from "../../eui-components/tooltip";

class PolicyDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {

    }
    render() {
        return (
            <EuiRow>
                <div style={{ margin: "15px 10px" }}>
                    <div className="privacy">
                        <br />
                        <br />
                        <p>
                            <h1 style={{ paddingLeft: "15px" }}>Privacy Notice and Consent - ERITOP ASP
                                <EuiTooltip title="Download Privacy Policy Notice and Consent of ERITOP ASP">
                                    <i className="icon icon-download-save pl-3 clickable icon-lg" onClick={() => window.open(require("../../assets/Privacy Notice and Consent_ERITOPASP.docx.pdf"))} target="_blank"></i>
                                </EuiTooltip>
                            </h1>
                        </p>
                        <p style={{ paddingLeft: "15px" }} >Updated: April 21, 2021<br /></p>
                        <br />
                        <PrivacyPolicyDetails />
                    </div>
                </div>
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PolicyDetail);
