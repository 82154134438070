import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import constants from "../../../utils/constants";
import { ToastContainer, toast } from 'react-toastify';
import UserDetails from "../../user-admin/userDetails";
import UserAdminHelper from "../../../utils/user-admin-helper";
import { withLayout } from "../../../layout";
import EuiRow from "../../../eui-components/row";
import EuiLoading from "../../../eui-components/loading";


class MyProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        const user = { ...this.props.userData.userDetail };
        UserAdminHelper.mapUser(user);
        this.props.setCurrentUser(user);
        this.props.setUserAdminMod(constants.pageMods.EDIT);
    }

    render() {
        return (<EuiRow className={this.state.isLoading ? " disabled " : ""}>
            <EuiLoading id="myProfileLoading" isLoading={this.state.isLoading} />
            <UserDetails isMyProfile={true} />
            <ToastContainer enableMultiContainer containerId={'MP'} autoClose={2500} />
        </EuiRow>);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, userAdminData: state.userAdmin, userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setUserLoginRoles: (loginRoles) => dispatch({ type: "SET_USER_LOGIN_ROLES", payload: loginRoles }),
        setCurrentUser: (user) => dispatch({ type: "SET_CURRENT_USER", payload: user }),
        setUserAdminMod: (mod) => dispatch({ type: "CHANGE_USER_ADMIN_MOD", payload: mod }),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(MyProfile)));