import React from 'react';
import EuiRow from '../../../../eui-components/row';
import DateUtils from "../../../../utils/date-utils";
import PageHelper from '../../../../utils/page-helper';
import UserAdminHelper from '../../../../utils/user-admin-helper';
import CommonHelper from '../../../../utils/common-helper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import SasHelper from "../../../../utils/sas-helper";

const UserInfo = ({ currentRow }) => {
    const dispatch = useDispatch();
    const sasToken = useSelector(state => state.complaints.sasToken);
    
    useEffect(() => {
        if (!sasToken) {
            SasHelper.getSasKey().then(response => {
                dispatch({ type: "SET_SAS_TOKEN", payload: response });
            })
        }
    });

    const profilePhoto = currentRow.user.profilePhoto ? SasHelper.addSasKey(currentRow.user.profilePhoto , sasToken) : require("../../../../assets/default-profile.png");
    const siteAccessPhoto = currentRow.user.siteAccessPhoto ? SasHelper.addSasKey(currentRow.user.siteAccessPhoto , sasToken) : require("../../../../assets/default-profile.png");

    return (<EuiRow className="flex-middle-content">
        <div className="w-2 center-content">
            <img className="avatar m-1 clickable" src={profilePhoto}
                onClick={() => window.open(profilePhoto, "_blank").focus()}
                style={{ width: 125, height: 125 }}></img>
            <p><a href={profilePhoto} target="_blank" rel="noopener noreferrer" >Profile Photo</a></p>
        </div>
        <div className="flex-start-content w-3">
            <ul style={{ listStyleType: "none", paddingInlineStart: 0 }}>
                <li style={{ paddingBottom: 4 }} key={"name_" + currentRow.user.text}>{currentRow.user.text}</li>
                <li style={{ paddingBottom: 4 }} key={"emailAdress_" + currentRow.user.mail}>{currentRow.user.mail}</li>
                <li style={{ paddingBottom: 4 }} key={"position_" + currentRow.user.role}>{UserAdminHelper.getUserRoleName(currentRow.user.role)}</li>
                <li style={{ paddingBottom: 4 }} key={"photoAuth_" + currentRow.user.isPhotoVerified}>
                    <div className="flex-start-content">Photo Authentication:{PageHelper.renderPhotoAuthentication(currentRow.user.isPhotoVerified)}</div>
                    <div className="text-sm mt-1">{"Photo Authentication Datetime: " + (DateUtils.toDateString(currentRow.user.verificationDate, "YYYY-MM-DD HH:mm:ss") || "---")}</div>
                </li>
            </ul>
        </div>
        <div className="flex-start-content w-3">
            <ul style={{ listStyleType: "none", paddingInlineStart: 0 }}>
                <li style={{ paddingBottom: 4 }} key={"manager_" + (currentRow.user.managerEmail || "")}>
                    {"Manager Email: " + (currentRow.user.managerEmail || "Mail not defined")}
                </li>
                <li style={{ paddingBottom: 4 }} key={"signum" + currentRow.user.signum}>{"Signum: " + (currentRow.user.signum || "Not defined")}</li>
                <li style={{ paddingBottom: 4 }} key={"isLockedOut" + currentRow.user.status}>
                    <div className="flex-start-content">{"User Status: " + CommonHelper.capitalizeFirstLetter(currentRow.user.status)}</div>
                </li>
            </ul>
        </div>
        <div className="w-2 center-content">
            <img className="avatar m-1 clickable" src={siteAccessPhoto}
                onClick={() => window.open(siteAccessPhoto, "_blank").focus()}
                style={{ width: 125, height: 125 }}></img>
            <p><a href={siteAccessPhoto} target="_blank" rel="noopener noreferrer" >Site Access Photo</a></p>
        </div>
    </EuiRow>)
}
export default UserInfo;