import * as moment from "moment";

export default class DateUtils {

    static toDateString = (date, format, noUtcMode = false) => date ? (noUtcMode ? moment(date).format(format || 'YYYY-MM-DD') : moment(date).format(format || 'YYYY-MM-DD') + (format ? " UTC " : "")) : '';

    static getUtcDate = () => {
        var date = new Date();
        var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
            date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());

        return new Date(now_utc);
    }

    static dateDifference = (firstDate, secondDate) => {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        return Math.round(((firstDate - secondDate) / oneDay));
    }

    static dateDifferenceInhours = (firstDate, secondDate) => {
        if (!firstDate || !secondDate) {
            return "---";
        }
        const hours = 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        return Math.round(((firstDate - secondDate) / hours));
    }

    static dateDifferenceInSeconds = (firstDate, secondDate) => {
        if (!firstDate || !secondDate) {
            return "---";
        }
        const seconds = 1000; //milliseconds
        return Math.round(((new Date(firstDate).getTime() - new Date(secondDate).getTime()) / seconds)) + " sec";
    }

    static dateDifferenceInMinutes = (firstDate, secondDate) => {
        if (!firstDate || !secondDate) {
            return "---";
        }
        const seconds = 60 * 1000; // minutes*seconds*milliseconds
        return Math.round(((new Date(firstDate).getTime() - new Date(secondDate).getTime()) / seconds)) + " min";
    }

    static toLocalDateString = (date, format) => {
        if (!date) {
            return '';
        }
        var date = new Date(this.toDateString(date, format || "YYYY-MM-DD HH:mm:ss"));
        return moment(date).format(format || "YYYY-MM-DD HH:mm:ss") + " Local ";
    }

    static toUtcDate = (date) => date ? moment(date).utc() : null;
    
    static formatDateTime = (date) => {
        return date ? moment.utc(date).local().format('DD/MM/YYYY HH:mm') : '';
      };
    static groupByWeek = (dates) => {
        const groups = dates.reduce((acc, date) => {

            // create a composed key: 'year-week' 
            const yearWeek = `w${moment(date).week()}`;

            // add this key as a property to the result object
            if (!acc[yearWeek]) {
                acc[yearWeek] = [];
            }

            // push the current date that belongs to the year-week calculated befor
            acc[yearWeek].push(date);

            return acc;

        }, {});
        return groups;
    }
    static groupByMonth = (dates) => {
        const groups = dates.reduce((acc, date) => {

            // create a composed key: 'year-month' 
            const yearMonth = `${moment(date).format('MMMM').slice(0, 3)}`;

            // add this key as a property to the result object
            if (!acc[yearMonth]) {
                acc[yearMonth] = [];
            }

            // push the current date that belongs to the year-month calculated befor
            acc[yearMonth].push(date);

            return acc;

        }, {});
        return groups;
    }
    static getMonthDifference(startDate, endDate) {
        let dates = [startDate, endDate];
        const groupedDatesByMonth = this.groupByMonth(dates);
        let result = Object.keys(groupedDatesByMonth)?.length;
        return result;
        /* return (
             endDate.getMonth() -
             startDate.getMonth() +
             12 * (endDate.getFullYear() - startDate.getFullYear())
         );*/
    }

    static getDateDiffByHoursAndMinute = (firstDate, secondDate) => {
        if (!firstDate || !secondDate) {
            return "---";
        }
        var hour = this.dateDifferenceInhours(firstDate, secondDate);
        if (!hour || hour === 0) {
            return this.dateDifferenceInMinutes();
        } else {
            return hour;
        }
    }
}