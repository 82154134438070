import React from "react";
import { connect } from "react-redux";

class Appbar extends React.Component {

    constructor(props) {
        super(props);
         const isEritopUser = this.props?.userData?.userDetail?.email 
      // const isEritopUser = true
        this.state = {
            ISEritopUser : isEritopUser
        };
    }
   
    renderMenu = () => {
   return (
            
    <nav className="appbar" >
        <div className="actions-left">
            <div className="item" onClick={this.props.toggleNavbar}>
                <i
                    className={
                        "navigation-toggle " +
                        (this.props.navData.navBarView ? "closed" : "")
                    }
                ></i>
            </div>
            <div
                className={
                    "menu-anchor " +
                    (this.props.navData.navBarView ? "open-menu" : "")
                }
            >
                Menu
            </div>
            <div
                className={
                    "title " + (this.props.navData.navBarView ? "open-menu" : "")
                }
            >
                <span className="title-name" style={{ fontSize: 20 }}>
                    {this.props.navData.currentPage && this.props.navData.currentPage.title}
                </span>
                <span className="subtitle">
                    {this.props.navData.currentPage && this.props.navData.currentPage.subTitle}
                </span>
            </div>
        </div>
        <div className="actions-right"></div>
    </nav>

);
    }
    render() {
        return (
            <>
                {this.state.ISEritopUser ? this.renderMenu() : null }
            </>
        );
        
       
         
    }
}

const mapStateToProps = state => ({ navData: state.navigation , userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);
