import React from "react";
import ApiClient from "./api-client";
import constants from "./constants";
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';



export default class WorkflowHelper {

    static modeler = null;
    static viewer = null;

    static getElements = (elementName) => {
        let elements = [];
        
        if (!this.modeler) {
            return [];
        }
        const elementRegistry = this.modeler.get('elementRegistry');
        
        if (elementRegistry) {
            elementRegistry.forEach(function (elem, gfx) {
                if (elem.businessObject.$instanceOf(elementName) && elem.type !== 'label') {
                    elements = [...elements, elem];
                }
            });
        }
        
        return elements;
    }   

    static getMailTaskNames = (tasks) => {
        const cloneTasks = [...tasks];
        if (cloneTasks.length > 1) {
            cloneTasks.pop();
            cloneTasks.pop();
            
            return cloneTasks.map(item => item.businessObject.name);
        }
        return [];
    }

    static checkTaskOutgoings = (tasks) => {
        let message = "";
        tasks.some(task => {
            if (!task.businessObject.outgoing || task.businessObject.outgoing.length != 1) {
                message = "Task must have only one global connect tool!";
                return;
            } else if (!task.businessObject.outgoing[0].targetRef.$instanceOf('bpmn:ExclusiveGateway')) {
                message = "Task must have only one Gateway connection";
                return;
            } else if (task.businessObject.outgoing[0].targetRef.$instanceOf('bpmn:ExclusiveGateway')) {
                const outgoing = task.businessObject.outgoing[0].targetRef.outgoing;
                
                if (!outgoing || outgoing.length < 1 || outgoing.length > 2) {
                    message = "Gateway must have at least one global connect tool";
                    return;
                } else if (!outgoing[0].targetRef.$instanceOf('bpmn:Task') &&
                    !outgoing[0].targetRef.$instanceOf('bpmn:EndEvent') &&
                    !outgoing[0].targetRef.$instanceOf('bpmn:IntermediateThrowEvent')) {
                    message = "Gateway must have only Task or EndEvent or Intermediate/Boundary Event connection!";
                    return;
                } else if (outgoing.length > 1 &&
                    !outgoing[1].targetRef.$instanceOf('bpmn:Task') &&
                    !outgoing[1].targetRef.$instanceOf('bpmn:EndEvent') &&
                    !outgoing[1].targetRef.$instanceOf('bpmn:IntermediateThrowEvent')) {
                    message = "Gateway must have only Task or EndEvent or Intermediate/Boundary Event connection!";
                    return;
                }
            }
        });
        return message;
    }

    static getActionValues = (step, task, allTaskIds) => {
        let actionValues = { goAction: -1, noGoAction: -1 };
        const actions = task.businessObject.outgoing[0].targetRef.outgoing;
        if (!actions || actions.length === 0) {
            return actionValues;
        }
        actions.map((action) => {
            if (action.targetRef.$instanceOf('bpmn:Task')) {
                const actionStep = (allTaskIds.find(item => item.id === action.targetRef.id) || { step: -1 }).step;
                if (step > actionStep) {
                    actionValues.noGoAction = actionStep;
                } else {
                    actionValues.goAction = actionStep;
                    if (actions.length === 1) {
                        actionValues.noGoAction = actionStep;
                    }
                }
            } else if (action.targetRef.$instanceOf('bpmn:IntermediateThrowEvent')) {
                actionValues.noGoAction = 0;
            } else if (action.targetRef.$instanceOf('bpmn:EndEvent')) {
                actionValues.goAction = -1;
            }
        });
        return actionValues;
    }

    static getWorkflowStepsFromTask = (tasks, createUser, workflowId) => {
        const cloneTasks = [...tasks];
        if (cloneTasks.length === 0) {
            return [];
        }

        const allTaskIds = cloneTasks.map((item, index) => { return { id: item.id, step: index + 1 } });
        let workflowSteps = [];
        cloneTasks.map((item, index) => {
            const step = index + 1;
            const actionValues = this.getActionValues(step, item, allTaskIds);
            workflowSteps = [...workflowSteps,
            {
                step,
                name: item.businessObject.name,
                goAction: actionValues.goAction,
                noGoAction: actionValues.noGoAction,
                workflowStepApproverUsers: [],
                sendMail: false,
                createDate: new Date(),
                createUser,
                workflowId
            }];
        });
        return workflowSteps;
    }

    static mapUsers = (users) => {
        return users.map(item => {
            return { value: item.value, text: item.text + " - " + item.mail, userId: item.value };
        })
    }

    static mapUserValues = (userValues, users) => {
        userValues.map(item => {
            item.userId = item.userId;
            item.value = item.userId;
            item.text = (users.find(u => u.value === item.userId) || { text: "" }).text;
        });
    }

    static renderWorkflowStatus = (status) => {
        switch (status) {
            case 1:
                return <span style={{ display: "flex" }}><FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#008CFF" }} /> {constants.workflowStatus[1]} </span>;
            case 2:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#288964" }} /> {constants.workflowStatus[2]} </span>;
            case 3:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#DCAF00" }} /> {constants.workflowStatus[3]} </span>;
            case 4:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#ED0E00" }} /> {constants.workflowStatus[4]} </span>;
            default:
                return "";
        }
    }

    static getTitle = (mod) => {
        switch (mod)
        {
            case 0:
                return "Create";
            case 1:
                return "Save";
            case 2:
                return "Review";
            default:
                return "";
        }
    }
}
