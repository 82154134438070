import React from 'react';
import { connect } from "react-redux";
import EuiTile from '../../eui-components/tile';
import EuiLoading from '../../eui-components/loading';
import EuiGenericTree from '../../eui-components/genericTree';
import constants from '../../utils/constants';
import PageHelper from '../../utils/page-helper';


class CustomersTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listState: this.props.customersData.countryCustomersList,
            originalList: this.props.customersData.countryCustomersList,
            filterVal: "",
            isLoading: false
        };
    }

    componentDidMount() {
        
        const countries = [];

        if (this.props.customersData) {
            const countriesInitialList = this.props.customersData.countryCustomersList;
            countriesInitialList.forEach(e => {
                let tempCustomers = [];
                let customers = e.subList;

                switch (this.props.userData.userData.jobRole) {
                    case constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR:
                    case constants.userRoles.ERICSSON_IM:
                    case constants.userRoles.REPORTER:
                        let cIds = this.props.userData.userDetail.customers?.map(item => item.customerId);
                        
                        tempCustomers = customers?.filter(a => cIds?.includes(a.value));
                        break;
                    case constants.userRoles.RSC_COODINATOR:
                    case constants.userRoles.RSC_OHS_INSPECTOR:
                    case constants.userRoles.RSC_SITE_SUPERVISOR:
                        
                        
                        let rscCustomers = [...this.props.customersData.rscCustomers];
                        let customersListIds = rscCustomers?.filter(item => item.rscCenterId === this.props.userData.rscPerson?.rscCenterId)?.map(item => item.customerId);
                        tempCustomers = customers?.filter(a => customersListIds?.includes(a.value));
                        break;
                    default:
                        tempCustomers = customers;
                        break;
                };
                const aspCompanies = tempCustomers;
                
                let properties = { "state": "closed", "element": e.element, "subList": aspCompanies, "id": e.id };
                if ((aspCompanies ?? []).length > 0) {
                    countries.push(properties);
                }

            });
            countries.sort(PageHelper.dynamicSort("element"));
            
            this.setState({
                listState: countries,
                originalList: countries,
                isLoading: false
            });
            

        }

    }

    onItemClicked = (e) => {
        
        
        const data = [...this.state.listState];
        const item = data.find(item => item.id == e);
        item['state'] = (item.state == "opened" ? "closed" : "opened");
        
        this.props.setCountryCustomersList(data);
        this.setState({
            listState: data
        });
        

    }

    toggleAccordion = (itemID) => {
        
        
        /*  if (!this.state.listState.length > 0) {
              this.setState({
                  listState: this.props.customersData.countryCustomersList
              });
          }*/
        
        let data = [...this.state.listState];
        data.map(e => {
            if (e.id == itemID) {
                if (e.state == "closed") {

                    e.state = "opened"

                }
                else {
                    e.state = "closed"

                }
            }
            else {
                e.state = "closed"

            }
        });
        
        this.setState({
            listState: data
        })
    }

    filterCountries = (e) => {
        
        if (e.target.value === "") {
            this.setState({
                listState: this.state.originalList
            })
        }
        else {
            var tempCountries = this.state.listState?.filter(b =>
                b.element.toUpperCase().includes(e.target.value.toUpperCase())
            )
            if (tempCountries.length == 0) {
                tempCountries = tempCountries?.filter(b =>
                    b.element.includes(e.target.value)
                )
            }
            this.setState({
                listState: tempCountries
            })
        }
    }

    customersFilter = (e) => {
        var val = e.target.value;
        var testProps = [...this.state.listState];
        if (val !== "") {
            if (val.length > this.state.filterVal.length) {
                var value = val;
                var testList = this.state.listState;
                var listTemp = [];
                testList.forEach(e => {
                    var element = {
                        ...e,
                        subList: e.subList.filter(x => x.text.toUpperCase().includes(value.toUpperCase())),
                        state: "opened"
                    }

                    if (element.subList.length > 0) {
                        listTemp.push(element);
                    }

                })

                this.setState({
                    listState: listTemp,
                    filterVal: value
                })
            }

            else {
                var value = e.target.value;
                var testList = [];
                testProps.forEach(e => testList.push(e));
                var listTemp = [];
                testList.forEach(e => {
                    var element = {
                        ...e,
                        subList: e.subList.filter(x => x.text.toUpperCase().includes(value.toUpperCase())),
                        state: "opened"
                    }

                    if (element.subList.length > 0) {
                        listTemp.push(element);
                    }

                })

                this.setState({
                    listState: listTemp,
                    filterVal: value
                })
            }
        }
        else {
            this.setState({
                listState: this.state.originalList,
                filterVal: ""
            })
            
        }
    }

    editCustomer = (id, element, event) => {
        
        event.preventDefault();
        event.stopPropagation();
        this.props.editSubDetails(id, element);
        const newList = [];
        this.state.listState.map(e => {
            if (e.element == element) {
                e.subList.map(s => {
                    if (s.value == id) {
                        s.state = "active";
                    }
                    else {
                        s.state = "";
                    }
                })
            }
            else {
                e.subList.forEach(x => {
                    x.state = "";
                })
            }
            newList.push(e);
        });
        this.setState({
            listState: newList,
        })

        
    }

    selectCustomer = (id, element) => {
        this.props.viewSubDetails(id, element);
        const newList = [];
        this.state.listState.map(e => {
            if (e.element == element) {
                e.subList.map(s => {
                    if (s.value == id) {
                        s.state = "active";
                    }
                    else {
                        s.state = "";
                    }
                })
            }
            else {
                e.subList.forEach(x => {
                    x.state = "";
                })
            }
            newList.push(e);
        });
        this.setState({
            listState: newList,
        })
        
    }
    /*
    _selectCustomer = (itemId, subItemId) => {
        const data = [...this.props.customersData.countryCustomersList];
        
        const item = data?.find(item => item.id == itemId);
        
        const subList = item['subList'];
        const selectedSubItem = subList.find(item => item.value == subItemId);

        selectedSubItem['state'] = "active";
        this.props.setCountryCustomersList(data);
        this.setState({
            listState: this.props.customersData.countryCustomersList
        });
        
    }
    */
    render() {
        
        return (
            <div key={this.props.customersData} className="column sm-12 lg-3 xl-3 md-3">
                <EuiGenericTree
                    id="customersTree"
                    data={this.state.listState}
                    onFilterChange={(e) => { this.customersFilter(e) }}
                    viewTree={this.props.viewTree}
                    onItemClicked={this.onItemClicked}
                    onSubItemSelect={this.selectCustomer}
                    filter={true}
                />
            </div>)
    }


}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, customersData: state.customers });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setRowData: (rowData) => dispatch({ type: "SET_ROW_DATA", payload: rowData }),
        setCountryCustomersList: (countryCustomersList) => dispatch({ type: "SET_COUNTRY_CUSTOMERS_LIST", payload: countryCustomersList }),
        viewCustomer: ({ view, selected_customer, selected_country }) => dispatch({ type: "VIEW_CUSTOMER", payload: { view, selected_customer, selected_country } }),
        CheckDataEdit: (editCheckData) => dispatch({ type: "EDIT_CHECK_DATA", payload: editCheckData }),
        toggleCustomersTree: (customersTreeState) => dispatch({ type: "Toggle_CUSTOMERS_TREE", payload: customersTreeState }),


    };

};
export default connect(
    mapStateToProps,
    mapDispatchToProps)(CustomersTree);
