import React, { useState } from "react";
import EuiRow from "../../eui-components/row";
import EuiLoading from "../../eui-components/loading";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import constants from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import PageHelper from "../../utils/page-helper";
import { useHistory } from "react-router-dom";
import DateUtils from "../../utils/date-utils";
import ApiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import DialogModal from "../../components/dialog";

const IMActivityList = (props) => {

    const [imActivitiesColumns, setColumns] = useState([
        {
            title: "ID",
            key: "id",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const idLink = `<a id="imActivityid_${cellData}">${cellData}</a>`;
                    td.innerHTML = idLink;
                    td.querySelector("#imActivityid_" + cellData).addEventListener('click', () => openImActivityDetail({ id: cellData }, constants.pageMods.VIEW_ONLY))
                }
            },
            width: "5%",
        },
        {
            title: "Activity Name",
            key: "name",
            sort: "none",
            width: "15%"
        },
        {
            title: "Customer",
            key: "customerName",
            sort: "none",
            width: "15%"
        },
        {
            title: "Workplan",
            key: "workplanName",
            sort: "none",
            width: "15%"
        },
        {
            title: "Customer Site ID",
            key: "customerSiteId",
            sort: "none",
            width: "10%",
        },
        {
            title: "ASP Company | (Team Leader | EHS Auditor)",
            key: "aspCompanyAndTeamLead",
            sort: "none",
            width: "20%"
        },
        {
            title: "Actual Start",
            key: "actualStart",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const date = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm:ss");
                    td.innerHTML = `${date}`;
                }
            },
            sort: "none",
            width: "15%"
        },
        {
            title: "Actions",
            key: "assignId",           
            notSearchable: true,
            width: "5%"
        },
    ]);
    const [isLoading, setIsLoading] = useState(false);

    const imActivities = useSelector(state => state.imInbox.imActivityList || []);
    const filterTypes = useSelector(state => state.imInbox.imFilterTypes);
    const userId = useSelector(state => state.user.userData.userId);
    const currentImActivity = useSelector(state => state.imInbox.currentIMActivity);
    const [openDialogUnassign, setOpenDialogUnassign] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState({ id: 0 });

    let history = useHistory();

    const dispatch = useDispatch();

    const openImActivityDetail = (rowData, mod) => {
        const newImActivities = [...imActivities];
        const activity = imActivities.find(item => item.id == rowData.id);
        const logInfo = {
            userId: parseInt(userId),
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude,
        }
        
        setIsLoading(true);
        if (!activity.assigned) {
            ApiClient.post("Activity/AssignActivity", { id: activity.id, updateUser: userId, logInfo })
                .then(response => {
                    if (!response.data) {
                        setIsLoading(false);
                        toast.error(constants.errorMessage, { containerId: "IM", position: toast.POSITION.TOP_CENTER });
                        return;
                    } else {
                        newImActivities[newImActivities.indexOf(activity)].assigned = true;
                        dispatch({ type: "SET_IM_ACTIVITY_LIST", payload: newImActivities });
                        getSubactivitesOfActivityAndJoin(activity, mod);
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    console.error(error);;
                })
        } else {
            getSubactivitesOfActivityAndJoin(activity, mod);
        }

    }

    const getSubactivitesOfActivityAndJoin = (activity, mod) => {
        ApiClient.post("ActivityInbox/GetDetailsOfActivity", {
            id: activity.id,
        }).then(response => {
            setIsLoading(false);
            if (!response.data) {
                toast.error(constants.errorMessage, { containerId: "IM", position: toast.POSITION.TOP_CENTER });
                return;
            }

            const data = ActivityInboxHelper.mapSubactivities(response.data.subactivities, activity.aspResource, activity.addInfo);
            const recordings = PageHelper.mapRecordings(response.data.recordings);
            const teamVerificationLogs = ActivityInboxHelper.mapTeamLogs(response.data.teamVerificationLogs);
            const resourceVerificationLogs = ActivityInboxHelper.mapResourceLogs(response.data.resourceVerificationLogs);
            const teamInfo = response.data.teamInfo || {};
            teamInfo.status = teamVerificationLogs.length > 0 ? !teamVerificationLogs.some(item => !item.validStatus) : null;
            teamInfo.verificationSessionId = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].verificationSessionId : "";
            teamInfo.isOffsiteVerification = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].isOffsiteVerification : null;
            teamInfo.resourceStatus = resourceVerificationLogs.length > 0 ? !resourceVerificationLogs.some(item => !item.status) : null;

            activity["subactivities"] = data;
            activity["ratings"] = response.data.ratings;
            activity["readyForRate"] = !data?.map(item => item.documents).reduce((a, b) => { return a?.concat(b); })?.some(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING);
            
            dispatch({ type: "SET_CURRENT_IM_ACTIVITY", payload: activity });
console.log("activity?.plainActivity?.rscScope 1234",activity.rscScope);
            const liveSessionInfo = { ...response.data.liveSessionInfo };
            liveSessionInfo.type = constants.systemTypes.VSS;
            liveSessionInfo.sessionName = activity?.name;
            liveSessionInfo.siteName = activity?.siteName;
            liveSessionInfo.rscScope =  activity?.rscScope;
            liveSessionInfo.activityId = activity?.id;
            dispatch({ type: "SET_LIVE_SESSION_INFO", payload: liveSessionInfo });

            const teamsLogInfo = { teamInfo, teamVerificationLogs, resourceVerificationLogs };
            dispatch({ type: "SET_TEAMS_LOG_INFO", payload: teamsLogInfo });
            dispatch({ type: "SET_RECORDINGS", payload: recordings });
            dispatch({ type: "SET_RATINGS", payload: response.data.ratings || [] });
            dispatch({ type: "CHANGE_IM_INBOX_MOD", payload: mod });
            dispatch({ type: "TOGGLE_LIVE_STREAM_MOD", payload: true });
            history.push("/live-streaming");

        }).catch(error => {
            setIsLoading(false);
            console.error(error);;
        });
    }

    const unAssignActivity = () => {
        setOpenDialogUnassign(false);
        setIsLoading(true);
        const newImActivities = [...imActivities];
        const activity = imActivities.find(item => item.id == selectedActivity.id);
        const logInfo = {
            userId: parseInt(userId),
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude,
        }
        if (activity.assigned) {
            ApiClient.post("Activity/UnassignActivity", { id: activity.id, updateUser: userId, logInfo })
                .then(response => {
                    if (!response.data) {
                        toast.error(constants.errorMessage, { containerId: "IM", position: toast.POSITION.TOP_CENTER });
                        return;
                    } else {                       
                        newImActivities[newImActivities.indexOf(activity)].assigned = false;
                        dispatch({ type: "SET_IM_ACTIVITY_LIST", payload: newImActivities });
                        
                        setIsLoading(false);
                    }
                })
                .catch(error => {
                    console.error(error);;
                });
        }
    }

    const renderRow = (tr, rowData) => {
        const action = getActions(rowData);
        if (rowData.completed) {
            tr.style.background = "#EBEBEB";
            tr.getElementsByTagName("td")[7].innerHTML = null;
        } else if (rowData.assigned) {
            tr.style.background = "#E6F1EB";
            tr.getElementsByTagName("td")[7].innerHTML = action;
            tr.getElementsByTagName("td")[7].querySelector("#im_activity_unassign_" + rowData.id).addEventListener('click', () => { setSelectedActivity(rowData); setOpenDialogUnassign(true); });
        } else if (!rowData.assigned) {
            tr.style.background = "#F9E2E1";
            tr.getElementsByTagName("td")[7].innerHTML = action;
        }

        if (!rowData.completed) {
            tr.getElementsByTagName("td")[7].querySelector("#im_activity_assign_" + rowData.id).addEventListener('click', () => openImActivityDetail(rowData, constants.pageMods.EDIT))
        }
    }

    const getActions = (rowData) => {
        return rowData.assigned ? `<div class="flex-start-content">                           
                              <button id="im_activity_assign_${rowData.id}" class="btn primary">Review</button>                                                           
                              <span class="tooltip">
                              <i id="im_activity_unassign_${rowData.id}" class="icon icon-cross clickable m-1 icon-frame color-red"></i>
                                 <span class="message left">Unassign Activity</span>
                            </span>
                            </div>`
            : ` <span class="tooltip">
                 <i id="im_activity_assign_${rowData.id}" class="icon icon-eye clickable m-1 icon-frame color-blue"></i>
                 <span class="message left">Assign & Review Activity</span>
             </span>`
    }

    return (<EuiRow className="p-2" key={isLoading + filterTypes.length} disabled={isLoading ? "disabled" : ""}>
        <EuiLoading id="imInboxLoading" isLoading={isLoading} />
        <ObseleteEuiTable
            classes="tiny split-striped"
            columns={imActivitiesColumns}
            data={props.filteredIMActivities}
            id="imActivitiesTable"
            viewActionButton={true}
            //actionButton={<button className="btn primary">Assign To Me</button>}
            sortable={true}
            paginated={true}
            //selectable="multi"
            search={true}
            searchStyle={{ width: 300 }}
            rowsPerPage={10}
            placeholder="Search activity..."
            viewActionIcon={true}            
            onCreatedRow={(tr, rowData) => renderRow(tr, rowData)}
        >
        </ObseleteEuiTable>

        <DialogModal
            buttonName="OK"
            content={<p>{"Are you sure you want to unassign me to Activity ID : " + selectedActivity.id + " ?"}</p>}
            handleClose={() => setOpenDialogUnassign(false)}
            id="unassignImActivityDialog"
            onClick={unAssignActivity}
            open={openDialogUnassign}
            title="Unassign Me"
            type="warning"
        >
        </DialogModal>

    </EuiRow>);

}
export default IMActivityList;