import React, { useState } from "react";
import EuiRow from "../../eui-components/row";
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import IMActivityFilter from "./imActivityFilter";
import IMActivityList from "./imActivityList";
import { useSelector } from "react-redux";
import EuiTag from "../../eui-components/tag";
import constants from "../../utils/constants";
import { ToastContainer } from "react-toastify";

const IMActivitySearch = (props) => {

    const imActivities = useSelector(state => state.imInbox.imActivityList || []);
    const filterTypes = useSelector(state => state.imInbox.imFilterTypes );


    const getFilterPills = () => {
        const pills = filterTypes;
        return pills.map(item => {
            const pill = constants.pills.find(p => p.value + "" === item);
            return <EuiTag type="small" style={{ backgroundColor: pill?.color, borderRadius: 16, alignItems: "center" }} name={pill?.text}  ></EuiTag>
        })
    }

    const filteredIMActivities = () => {
        if (filterTypes.length === 2) {
            return imActivities;
        } else if (filterTypes.length === 1) {
            if (filterTypes.includes(constants.imFilterTypes.ASSIGNED)) {
                return imActivities.filter(item => item.assigned);
            } else if (filterTypes.includes(constants.imFilterTypes.UN_ASSIGNED)) {
                return imActivities.filter(item => !item.assigned);
            } 
        } else {
            return [];
        }

    }

    const leftPanel = { title: "Filter By", content: <IMActivityFilter /> };
    const midPanel = {
        title: <div className="flex-start-content "><div className="mr-2" >Filtered Activities</div> {getFilterPills()}</div>,
        content: <IMActivityList key={filteredIMActivities()} filteredIMActivities={filteredIMActivities()} />
    };

    return (<EuiRow>
        <EuiMultiPanelTile id="imActivityMultiPanel"
            midPanelButton={true}
            leftPanel={leftPanel}
            midPanel={midPanel}
        />
        <ToastContainer enableMultiContainer containerId={'IM'} autoClose={2500} />
    </EuiRow>);

}

export default IMActivitySearch;