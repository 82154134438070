import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../../eui-components/row";
import EuiTabs from "../../../../eui-components/tabs";
import TeamContent from "./teamContent";
import ResourcesContent from "./resourcesContent";
import PageHelper from "../../../../utils/page-helper";

class TeamCertificationItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            titles: [{
                value: 1, name: <div className="flex-start-content">
                    {PageHelper.getVerificationIcon(this.props.liveStreamData.teamsLogInfo.teamInfo?.resourceStatus, "text-lg")} Resources
                        </div>,
                selected: "selected"
            },
            {
                value: 2,
                name: <div className="flex-start-content">
                    {PageHelper.getVerificationIcon(this.props.liveStreamData.teamsLogInfo.teamInfo?.status, "text-lg")} Team
                    </div>,
                selected: ""
            },
            ]
        }
    }

    componentDidMount() {

    }


    render() {
        const content = [{ value: 1, selected: "selected", children: <ResourcesContent /> },
        { value: 2, selected: "", children: <TeamContent /> }];

        return (<EuiRow>
            <EuiTabs id="teamCertificationTab"
                titles={this.state.titles}
                contents={content}
                tabStyle={{ width: "100%" }}
                titleStyle={{ fontSize: 16, fontWeight: 500 }}
            ></EuiTabs>
        </EuiRow>

        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamCertificationItem);
