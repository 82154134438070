import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import DateUtils from "../../../utils/date-utils";
import PageHelper from "../../../utils/page-helper";
import { ToastContainer, toast } from 'react-toastify';
import SimpleExpansionPanel from "../../../components/expansionpanel";
import AdvancedGridList from "../../../components/advancedgridlist";
import Table from "../../../components/table";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MainHeader from "./mainHeader";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import ApiClient from "../../../utils/api-client";
import EuiSwitch from "../../../eui-components/switch";

class SessionDocuments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sessionActivitiesColumns:
                [
                    {
                        title: "Name", field: "name", render: (rowData) => this.renderSessionDocument(rowData),
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "98%" },
                    },
                    {
                        title: "Actions", render: (rowData) => {
                            return <label htmlFor={"uploadDocumentSessionApprove" + rowData.id} >
                                <input
                                    style={{ display: "none" }}
                                    id={"uploadDocumentSessionApprove" + rowData.id}
                                    name="documentPath"
                                    type="file"
                                    multiple
                                    onChange={e => this.handleFilesSessionActivity(e, rowData)}
                                />
                                <Tooltip id="addFileApproveSession" title="Add Document">
                                    <AttachFileIcon style={{ fontSize: 20, paddingTop: 4, paddingLeft: 3 }} />
                                </Tooltip>
                            </label>
                        },
                        hidden: this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.viewOnly || this.props.sessionData.approveSession.status === 101,
                        cellStyle: { padding: 0, paddingTop: 3, paddingRight: 3, display: "flex", width: "2%" },
                    },
                ],
            sessionActivityDocumentColumns:
                [
                    {
                        title: "Name", field: "name", render: (rowData) => this.props.renderName(rowData),
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "30%" },
                        defaultSort: "asc",
                        editable: "never"
                    },
                    {
                        title: "Status", field: "acceptanceStatusId", render: (rowData) => this.props.renderStatus(rowData, "", 2),
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "10%" },
                        editable: "never"
                    },
                    {
                        title: "Severity", field: "severityLevel", render: (rowData) => PageHelper.renderSeverityLevel(rowData.severityLevel),
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "10%" },
                        editable: "never"
                    },
                    {
                        title: "Comments", field: "customerComments",
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "15%" },
                        editable: "never"
                    },
                    {
                        title: "Ericsson Comments", field: "ericssonComments",
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "15%" },
                    },
                    {
                        title: "Updated Date",
                        field: "updateDate",
                        render: (rowData) => DateUtils.toDateString(rowData.updateDate, "YYYY-MM-DD HH:mm"),
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "10%" },
                        editable: "never"
                    },
                    {
                        title: "Updated By",
                        field: "updateUser",
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "10%" },
                        editable: "never"
                    },
                    {
                        title: "Actions", render: (rowData) => {
                            return rowData.acceptanceStatusId == 3 ?
                                <label style={{ paddingLeft: 8 }} htmlFor={"uploadDocumentSession" + rowData.id}>
                                    <input
                                        style={{ display: "none" }}
                                        id={"uploadDocumentSession" + rowData.id}
                                        name="documentPath"
                                        type="file"
                                        multiple
                                        onChange={e => this.handleFilesSession(e, rowData)}
                                    />
                                    <Tooltip id="addFileSession" title="Add Document">
                                        <AttachFileIcon style={{ fontSize: 20, paddingTop: 4, paddingLeft: 3 }} />
                                    </Tooltip>
                                </label>
                                :
                                null
                        },
                        hidden: ![4, 101].includes(this.props.sessionData.approveSession.status) || this.props.sessionData.approveSession.viewOnly
                    },
                ],
            approveExcludeSessionSwitchRef: this.props.sessionData.approveSession.status === 2 ? false : true,
            viewModSession: 3,
            expandAllSessionDocument: false,
            isLoading: false
        }
    }

    componentDidMount() {
    }

    renderSessionDocument = (rowData) => {
        return <SimpleExpansionPanel
            key={rowData.id + "_sessionActivity"}
            id={"approveSessionDocumentsAccordion" + rowData.id}
            title={PageHelper.getDetailPanelTitle(rowData.name, rowData.opened)}
            opened={rowData.opened}
            onChange={() => this.togglePanelSession(rowData)}
            content={<EuiRow>
                {this.setViewSession(rowData)}
            </EuiRow>
            }
        />
    }

    setViewSession = (rowData) => {
        if (this.state.viewModSession == 1) {
            return <AdvancedGridList
                key={rowData.id + "_advance"}
                tileData={!this.state.approveExcludeSessionSwitchRef ?
                    rowData.documents :
                    rowData.documents.filter(item => item.acceptanceStatusId != 2)}
                selectedDocument={(tile) => this.props.setSelectedDocument(tile)}
                onClickApprove={() => this.props.handleDialog(2)}
                onClickReject={() => this.props.handleDialog(3)}
                onClickPending={() => this.props.handlePending(1, 2)}
                viewOnly={this.props.sessionData.approveSession.viewOnly}
                onClickImage={(tile) => this.props.selectAndOpenDocument(tile)}
                type={2}
            />
        } else {
            return <Table
                key={rowData.id}
                columns={this.state.sessionActivityDocumentColumns}
                container={{ style: { background: "inherit" } }}
                data={this.getDocumentsByMod(rowData.documents, 2)}
                isLoading={this.state.isLoading}
                editable={
                    this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.status === 101 ?
                        {
                            isEditHidden: rowDataDocument => ![4, 101].includes(rowDataDocument.acceptanceStatusId),
                            isDeleteHidden: rowDataDocument => ![4, 101].includes(rowDataDocument.acceptanceStatusId),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataUpdate = [...rowData.documents];
                                        const index = dataUpdate.findIndex(item => item.tableData && item.tableData.editing === "update" && item.tableData.id == oldData.tableData.id);
                                        dataUpdate[index] = newData;
                                        this.updateSessionDocumentList(rowData, dataUpdate);
                                        resolve();
                                    }, 100);
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataDelete = [...rowData.documents];
                                        const index = dataDelete.findIndex(item => item.tableData && item.tableData.editing === "delete" && item.tableData.id == oldData.tableData.id);
                                        dataDelete.splice(index, 1);
                                        this.updateSessionDocumentList(rowData, dataDelete, oldData);
                                        resolve();
                                    }, 100);
                                })
                        }
                        :
                        {}
                }
                onRowClick={(event, rowData) => this.props.setSelectedDocument(rowData)}
                options={{
                    actionsColumnIndex: -1,
                    paging: false,
                    search: false,
                    header: false,
                    rowStyle: (rowData) => this.props.renderRow(rowData),
                    showTextRowsSelected: false,
                    selection: true
                }}
                actions={[
                    {
                        tooltip: 'Download All Selected Documents',
                        icon: () => <i className="icon icon-download-save"></i>,
                        onClick: (evt, data) => PageHelper.downloadFiles(data, rowData)
                    },
                    {
                        tooltip: 'Delete All Selected Documents',
                        icon: () => <i className="icon icon-trashcan"></i>,
                        onClick: (evt, data) => this.deleteDocuments(data, rowData),
                        hidden: this.props.sessionData.approveSession.viewOnly
                    }
                ]}
                tableRef={rowData.tableRef}
            >
            </Table>
        }

    }

    togglePanelSession = (rowData) => {
        let sessionActivities = [...this.props.session.sessionActivities];
        if (sessionActivities.length > 0) {
            sessionActivities.map(item => {
                if (item.id == rowData.id) {
                    item.opened = item.opened == "opened" ? "" : "opened";
                } else {
                    item.opened = "";
                }
            });
        }
        this.props.setSessionActivities(sessionActivities);
    }

    getSessionActivities = () => {
        if (this.props.session && this.props.session.sessionActivities && this.props.session.sessionActivities.length > 0) {
            if (this.state.approveExcludeSessionSwitchRef) {
                if (this.state.viewModSession !== 2) {
                    return this.props.session.sessionActivities.filter(item => item.documents &&
                        item.documents.length > 0 &&
                        item.documents.filter(doc => doc.acceptanceStatusId !== 2).length > 0
                    );
                } else {
                    return this.props.session.sessionActivities.filter(item => item.documents &&
                        item.documents.length > 0 &&
                        item.documents.filter(doc => doc.acceptanceStatusId !== 2 && doc.type === 2).length > 0
                    );
                }
            } else {
                if (this.state.viewModSession !== 2) {
                    return this.props.session.sessionActivities.filter(item => item.documents &&
                        item.documents.length > 0
                    );
                } else {
                    return this.props.session.sessionActivities.filter(item => item.documents &&
                        item.documents.length > 0 &&
                        item.documents.filter(doc => doc.type === 2).length > 0
                    );
                }
            }
        } else {
            return [];
        }
    }

    changeAllStatusSession = (status) => {
        let sessionActivities = [...this.props.session.sessionActivities];
        if (sessionActivities.length > 0) {
            sessionActivities.map(item => {
                if (item.documents.length > 0) {
                    item.documents.map(document => {
                        document.acceptanceStatusId = status;
                        document.severityLevel = status == 2 ? 1 : 4;
                        document.updateDate = new Date();
                        document.updateUser = this.props.userData.userData.username;
                    });
                }
            });
        }

        let allSessionDocument = [...this.props.allSessionDocument];
        if (allSessionDocument.length > 0) {
            allSessionDocument.map(document => {
                document.acceptanceStatusId = status;
                document.severityLevel = status == 2 ? 1 : 4;
                document.updateDate = new Date();
                document.updateUser = this.props.userData.userData.username;
            })
        }

        this.props.setSessionActivities(sessionActivities);
        this.props.setAllSessionDocument(allSessionDocument);
    }

    handleFilesSession = (e, rowData) => {
        const files = [...e.target.files];

        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            toast.error(fileSize, { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            return;
        }

        PageHelper.getCoordinates();
        this.props.uploadAndInsertItems(rowData, files, this.props.addSessionDocument, PageHelper.mapSessionDocuments);
    }

    //For customer insert session activity documents
    handleFilesSessionActivity = (e, rowData) => {
        const files = [...e.target.files];

        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            toast.error(fileSize, { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            return;
        }

        PageHelper.getCoordinates();
        this.props.uploadAndInsertItems(rowData, files, this.props.addSessionActivityDocument, this.props.mapSessionActivityDocument);
    }

    getDocumentsByMod = (documents) => {
        const mod = this.state.viewModSession;
        const switchRef = this.state.approveExcludeSessionSwitchRef;

        if (mod !== 2) {
            if (!switchRef) {
                return documents;
            } else {
                return documents.filter(item => (item.acceptanceStatusId) !== 2);
            }
        } else {
            if (!switchRef) {
                return documents.filter(item => item.type === 2);
            } else {
                return documents.filter(item => (item.acceptanceStatusId) !== 2 && item.type === 2);
            }
        }
    }

    updateSessionDocumentList = (rowData, newList, oldData = null) => {

        let sessionActivities = [...this.props.session.sessionActivities];
        let activity = { ...rowData };
        const index = sessionActivities.findIndex(item => item.id == rowData.id);
        activity.documents = newList;
        sessionActivities[index] = activity;

        let deletedSessionDocuments = [...this.props.deletedSessionDocuments];
        if (oldData) {
            let deleteData = { ...oldData };
            deleteData.deleteDate = new Date();
            deleteData.deleteUser = this.props.userData.userData.userId;
            deletedSessionDocuments = [...deletedSessionDocuments, deleteData];
        }
        
        
        this.props.setSessionActivities(sessionActivities);
        this.props.setDeletedSessionDocuments(deletedSessionDocuments);
        
    }

    expandAllSessionDocument = () => {
        this.setState({ expandAllSessionDocument: !this.state.expandAllSessionDocument }, () => {
            let sessionActivities = [...this.props.session.sessionActivities];
            if (sessionActivities.length > 0) {
                sessionActivities.map(item => {
                    if (this.state.expandAllSessionDocument == true) {
                        item.opened = "opened";
                    } else {
                        item.opened = "";
                    }
                });
            }
            this.props.setSessionActivities(sessionActivities);
        });
    }

    deleteDocuments = (selectedFiles, rowData) => {
        const ids = selectedFiles.map(item => item.id).filter(id => id);
        
        if (!ids || ids.length === 0) {
            toast.error("Please use in row delete property to delete unsaved document!", { containerId: 'SD', position: toast.POSITION.TOP_CENTER });
        } else if (selectedFiles.filter(item => item.createUser !== this.props.userData.userData.userId).length > 0) {
            toast.error("Can only delete documents you have created!", { containerId: 'SD', position: toast.POSITION.TOP_CENTER });
        }
        else {
            this.setState({ isLoading: true }, () => {
                ApiClient.post("Session/DeleteSessionActivityDocuments", { ids, deleteUser: this.props.userData.userData.userId })
                    .then(response => {
                        if (response.data) {
                            let sessionActivities = [...this.props.session.sessionActivities];
                            let activity = { ...rowData };
                            activity.documents = activity.documents.filter(item => !ids.includes(item.id));
                            const index = sessionActivities.findIndex(item => item.id == activity.id);
                            sessionActivities[index] = activity;
                            this.setState(prevState => ({
                                isLoading: false
                            }), () => {
                                this.props.setSessionActivities(sessionActivities, "deleteUpdate");
                                toast.success("Selected files deleted succesfully!", { toastId: "deleteDocumentsSuccess", containerId: 'SD', position: toast.POSITION.TOP_CENTER })
                            });
                        } else {
                            this.setState({ isLoading: false }, () => {
                                toast.error("Some error occurs, please later try again!", { toastId: "deleteDocumentsControl", containerId: 'SD', position: toast.POSITION.TOP_CENTER });
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({ isLoading: false });
                        console.error(error);;
                    });
            });
        }

        if (rowData.tableRef && rowData.tableRef.current) {
            rowData.tableRef.current.onAllSelected(false);
        }
    }

    render() {
        return (
            <EuiRow>
                <Table
                    key="session-documents-table"
                    container={{ style: { background: "inherit" } }}
                    columns={this.state.sessionActivitiesColumns}
                    data={this.getSessionActivities()}
                    isLoading={this.props.isLoading}
                    options={{ minBodyHeight: 50, search: false, paging: false, header: false }}
                    toolbar={{
                        leftCustom:
                            <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-start" }}>
                                {this.props.session && this.props.session.sessionActivities && this.props.session.sessionActivities.length > 0 ?
                                    <Tooltip id="allExpandCollapseSession" title="Expand/Collapse All">
                                        <IconButton style={{ margin: 10, marginLeft: 5 }} aria-label="expandCollapseAll" id="allExpandCollapseSession" onClick={this.expandAllSessionDocument}>
                                            <i className={"icon " + (this.state.expandAllSessionDocument ? "icon-chevron-down" : "icon-chevron-up")}></i>
                                        </IconButton>
                                    </Tooltip>

                                    :
                                    null
                                }
                                <EuiSwitch checked={this.state.approveExcludeSessionSwitchRef}
                                    name="excludeAcceptedSwitch"
                                    onChange={(e) => this.setState({ approveExcludeSessionSwitchRef: e.target.checked })}
                                    dataEnabled="Excluding Accepted"
                                    dataDisabled="Including Accepted"
                                    className="p-1"
                                />
                                {
                                    //<Tooltip id="excludeSwitch" title="Exclude Accepted">
                                    //    <FormControlLabel
                                    //        style={{ marginTop: 10, marginLeft: 2, fontSize: 14 }}
                                    //        control={
                                    //            <Switch
                                    //                checked={this.state.approveExcludeSessionSwitchRef}
                                    //                onChange={(e) => this.setState({ approveExcludeSessionSwitchRef: e.target.checked })}
                                    //                name="excludeAcceptedSwitch"
                                    //                color="primary"
                                    //                size="small"
                                    //            />
                                    //        }
                                    //        label={<span style={{ fontSize: 12 }}>Exclude Accepted</span>}
                                    //    />
                                    //</Tooltip>
                                }
                            </div>,
                        custom:
                            <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
                                {this.props.sessionData.approveSession.viewOnly || this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.status === 101 ? null :
                                    (
                                        <div>
                                            <Tooltip id="allAcceptSession" title="Accept All">
                                                <IconButton style={{ marginRight: 10 }} aria-label="add" id="acceptAllSession" onClick={() => this.changeAllStatusSession(2)}>
                                                    <CheckIcon style={{ fontSize: 22 }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip id="allRejectSession" title="Reject All">
                                                <IconButton style={{ marginRight: 10 }} aria-label="reject" id="rejectAllSession" onClick={() => this.changeAllStatusSession(3)}>
                                                    <CloseIcon style={{ fontSize: 22 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )}
                                {this.props.sessionData.approveSession.status === 4 ? null :
                                    <Tooltip id="viewComfySession" title="Image View">
                                        <IconButton style={{ marginRight: 10, background: (this.state.viewModSession == 1 ? "silver" : "inherit") }} aria-label="comfyView" id="comfyView"
                                            onClick={() => { this.setState({ viewModSession: 1 }); }}>
                                            <i className="icon icon-view-tiles" style={{ fontSize: 22 }} ></i>
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip id="viewTileSession" title="Video View">
                                    <IconButton style={{ marginRight: 10, background: (this.state.viewModSession == 2 ? "silver" : "inherit") }} aria-label="tileView"
                                        id="tileViewSession" onClick={() => { this.setState({ viewModSession: 2 }); }}
                                    >
                                        <VideocamOutlinedIcon style={{ fontSize: 22 }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip id="viewListSession" title="All View">
                                    <IconButton style={{ marginRight: 10, background: (this.state.viewModSession == 3 ? "silver" : "inherit") }} aria-label="listView"
                                        id="listViewSession"
                                        onClick={() => this.setState({ viewModSession: 3 })}>
                                        <i className="icon icon-view-list" style={{ fontSize: 22 }} ></i>
                                    </IconButton>
                                </Tooltip>
                                {(this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.status === 101) && !this.props.sessionData.approveSession.viewOnly ?
                                    <Tooltip id="addSessionActivity" title="Add Session Activity">
                                        <IconButton style={{ marginRight: 10 }} aria-label="addSessionActivity"
                                            id="SessionAddActivity" onClick={() => this.props.setOpenCreateEditActivity()}>
                                            <i className="icon icon-plus"></i>
                                        </IconButton>
                                    </Tooltip>
                                    : null
                                }
                            </div>,
                        toolbarHeader: <MainHeader />
                    }}
                >
                </Table>
                <ToastContainer enableMultiContainer containerId={'SD'} autoClose={2500} />
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ sessionData: state.session, userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionDocuments);
