import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import FormField from "../../eui-components/formfield";
import ApiClient from "../../utils/api-client";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import DateUtils from "../../utils/date-utils";
import AspHelper from "../../utils/asp-helper";
import constants from "../../utils/constants";
import EuiDatepicker from "../../eui-components/datepicker";
import PageHelper from "../../utils/page-helper";

class AssignProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: [],
            legalStatuses: [],
            statuses: [],
            aspCompanies: [],
            tempAspCompanies: [],
            isLoading: false,
            initialState: false,
            certificates: [],
            disableControls: false,
            projects: []
        }
    }

    componentDidMount() {
     
        const resource = { ...this.props.aspResourcesData.resource };
        const resourceProjects = resource.projects.map(e => e.projectId);
        const project = { ...this.props.aspResourcesData.assignedProject };
        if (project && project.id) {
            const p = this.props.aspResourcesData.projects.find(item => item.value == project.projectId);
            project["customerId"] = p?.customerId;
            project["assignDate"] = new Date(project?.assignDate);
            this.props.setAssignedProject(project);
            this.setState({
                projects: [...this.props.aspResourcesData.projects.filter(item => item.customerId == p.customerId)]
            });
        }
        else
            this.setState({
                projects: [... this.props.aspResourcesData.projects?.filter(item => !resourceProjects.includes(item.value))]
            })
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const resource = { ...this.props.aspResourcesData.resource };
        const resourceProjects = resource.projects.map(e => e.projectId);

        const value = target.value;
        const text = target.text;
        const assignedProject = { ...this.props.aspResourcesData.assignedProject };
        if (name == "customerId") {
            this.setState({
                projects: [...this.props.aspResourcesData.projects.filter(item => item.customerId == value && !resourceProjects.includes(item.value))]
            });
        }
        assignedProject[name] = value;
        this.props.setAssignedProject(assignedProject);
    }

    updateData = () => {
        this.setState({
            isLoading: true
        })
        const filter = this.props.aspResourcesData.filter;
        if (!filter || Object.values(filter).every(x => (x === null || x === ''))) {
            filter.id = this.props.aspResourcesData.resource.id;
            filter.idsList = [];
            filter.idsList.push(this.props.aspResourcesData.resource.id);
        }
        filter.id = parseInt(filter.id ?? "0");
        ApiClient.post("Asp/GetResources", filter)
            .then(response => {
                ApiClient.post("Asp/GetResourceJobs", response.data.iDs)
                    .then(response2 => {
                        var _data = [];
                        var blobs = response.data.profileBlobs;
                        var certificates = [];
                        var resourceProjects = response.data.projects;
                        var resourceJobs = response2.data;
                        var skills = response.data.skills;
                        var faces = response.data.faces;
                        this.props.setResourceUsers(response.data.users);
                        response.data.certifications.forEach(c => {
                            var _c = this.props.aspResourcesData.certificates.filter(x => x.value == c.certificationId);
                            if (_c && _c[0]) {
                                var date = DateUtils.getUtcDate();
                                var diff = DateUtils.dateDifference(new Date(c.validUntil), new Date(date));
                                var element = {
                                    ...c,
                                    validSince: DateUtils.toDateString(c.validSince),
                                    validUntil: DateUtils.toDateString(c.validUntil),
                                    createdate: DateUtils.toDateString(c.createdate),
                                    lastModifiedDateTime: DateUtils.toDateString(c.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                                    createUserName: c.createUserName,
                                    modifiedUserName: c.modifiedUserName,
                                    name: _c[0].text,
                                    code: _c[0].code,
                                    path: c.path,
                                    status: (DateUtils.dateDifference(new Date(c.validUntil), new Date(date)) > 0 ? "Valid" : "Expired"),
                                    _delete: c.id
                                }
                                certificates.push(element);
                            }
                        })
                        this.props.setFilteredResources([]);
                        response.data.resource.forEach(e => {
                            var projects = AspHelper.mapProjects(resourceProjects?.filter(item => item.resourceId == e.id), this.props.aspResourcesData.projects, this.props.userData.customers);
                            var country = this.props.userData.countries.filter(c => c.value === e.countryId);
                            var company = this.props.aspResourcesData.companies.filter(c => c.value === e.aspCompanyId);
                            var cert = certificates.filter(c => c.resourceId === e.id);
                            var jobs = [];
                            var resourceSkills = skills.filter(s => s.resourceId == e.id);
                            var faceId = faces.find(f => f.resourceId == e.id)?.faceId;
                            var fullResourceSkills = AspHelper.getSkillInfo(resourceSkills, this.props.aspResourcesData.skills);
                            var _jobs = resourceJobs.filter(j => j.resourceId == e.id);
                            var inCompletedCerts = [];
                            var validResource = false;
                            _jobs.forEach(j => jobs.push(AspHelper.mapResourcesJobRole(j.value, this.props.aspResourcesData.jobs)));
                            var blob = blobs.filter(b => b.resourceId == e.id);
                            inCompletedCerts = AspHelper.getUnTakenCerts(cert, this.props.aspResourcesData.jobs, certificates, jobs);
                          /*  if (e.status == "Active") {
                                var test = true;
                                if (inCompletedCerts) {
                                    inCompletedCerts.forEach(c => {
                                        if (!c.valid)
                                            test = false;
                                    });
                                }
                                if (test)
                                    validResource = true;
                            }
                                */
                            var test = true;
                            if (inCompletedCerts) {
                             inCompletedCerts.forEach(c => {
                                 if (!c.valid)
                                     test = false;
                             });
                         }
                         validResource = test
                            if (jobs)
                            var element = {
                                ...e,
                                projects: projects,
                                status: e.status,
                                country: (country && country[0] ? country[0].text : ""),
                                aspCompany: (company && company[0] ? company[0].text : ""),
                                companyCountry: (company && company[0] ? company[0].countryId : ""),
                                profileImg: (blob && blob[0] ? constants.profileStorage + blob[0].key: ""),
                                certs: cert,
                                jobs: (jobs ? jobs : []),
                                skills: fullResourceSkills,
                                jobList: (_jobs ? _jobs : []),
                                inCompletedCerts: inCompletedCerts,
                                validResource: validResource,
                                faceId: faceId,
                                path: (faceId ? e.path : null),
                                imagePath: (faceId ? e.imagePath : null)
                            }
                            _data.push(element);
                        });
                        const data = [..._data];
                        const selectedResource = data.find(item => item.id === this.props.aspResourcesData.resource.id);
                        this.props.setAspResource(selectedResource);
                        this.props.setFilteredResources(data);
                        this.props.setSelectedItem(6);
                        var d = Date.now();
                        this.props.updateTable(d);
                        this.setState({ isLoading: false })




                    })
            }).catch(error => {
                console.error(error);;
                alert(error);
            });



    }

    render() {
        return (<EuiRow>
            <FormField className="w-10 ">
                <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="customerSelect">Select Customer</label>
                <EuiAutocompleteSelect
                    required
                    key={this.props.userData.customers}
                    className="w-10"
                    label="Select Customer"
                    id="customerSelect"
                    optionListStyle={{ width: "100%" }}
                    name="customerId"
                    options={this.props.userData.customers}
                    value={this.props.aspResourcesData.assignedProject?.customerId}
                    onChange={(e) => this.handleChange(e)}
                    fullwidth
                />
            </FormField>
            <FormField className="w-10 ">
                <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="customerSelect">Select Project</label>
                <EuiAutocompleteSelect
                    required
                    key={this.state.projects}
                    className="w-10"
                    label="Select Project"
                    id="projectSelect"
                    optionListStyle={{ width: "100%" }}
                    name="projectId"
                    options={this.state.projects}
                    value={this.props.aspResourcesData.assignedProject?.projectId}
                    onChange={(e) => this.handleChange(e)}
                    fullwidth
                />
            </FormField>
            <FormField className="w-10 ">
                <EuiDatepicker
                    required
                    style={{ width: "100%" }}
                    id="assignProjectValidUntilDate"
                    label="Valid Until"
                    dateStyle={{ marginRight: 6 }}
                    name="validUntil"
                    value={this.props.aspResourcesData.assignedProject?.validUntil ?? ""}
                    onChange={(e) => this.handleChange(e)}
                    labelStyle={{ fontSize: "14px" }}
                    labelClassName="color-gray required"
                />
            </FormField>
        </EuiRow >);
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp });
const mapDispatchToProps = dispatch => {
    return {
        setAssignedProject: (project) => dispatch({ type: "SET_ASSIGNED_PROJECT", payload: project }),
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        changeAspResourcesMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setFilteredResources: (filteredResources) => dispatch({ type: "SET_FILTERED_RESOURCES", payload: filteredResources }),
        setResourceFilter: (filter) => dispatch({ type: "SET_RESOURCE_FILTER", payload: filter }),
        setResourceCompanies: (companies) => dispatch({ type: "SET_RESOURCE_COMPANIES", payload: companies }),
        setResourceCountries: (countries) => dispatch({ type: "SET_RESOURCE_COUNTRIES", payload: countries }),
        setCertificates: (certs) => dispatch({ type: "SET_CERTIFICATES", payload: certs }),
        setJobs: (jobs) => dispatch({ type: "SET_JOBS", payload: jobs }),
        setIDTypes: (types) => dispatch({ type: "SET_ID_TYPES", payload: types }),
        updateTable: (val) => dispatch({ type: "UPDATE_TABLE", payload: val }),
        setSkills: (skills) => dispatch({ type: "SET_SKILLS", payload: skills }),
        setResourceUsers: (users) => dispatch({ type: "SET_USERS", payload: users }),
        setSelectedItem: (selectedItem) => dispatch({ type: "UPDATE_SELECTED_ITEM", payload: selectedItem }),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AssignProject));