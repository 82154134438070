import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import DateUtils from "../../../utils/date-utils";
import PageHelper from "../../../utils/page-helper";
import Table from "../../../components/table";
import ApiClient from "../../../utils/api-client";
import constants from "../../../utils/constants";

class SessionHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            sessionHistoryColumns:
                [
                    {
                        title: "Status", field: "status", render: (rowData) => PageHelper.renderSessionStatus(rowData.status),
                        width: "10%",
                        lookup: constants.statusLookup,
                    },
                    {
                        title: "Processing Date", field: "createDate", render: (rowData) => DateUtils.toDateString(rowData.createDate, "YYYY-MM-DD HH:mm"), defaultSort: "desc",
                        width: "10%",
                    },
                    { title: "User Name", field: "createUser", cellStyle: { whiteSpace: "pre-wrap", width: "5%" }, },
                    {
                        title: "Comments", field: "comments", filtering: false,
                        //render: (rowData) => JSON.stringify(rowData.comments, null, "\t")
                    },
                    {
                        title: "Request Content", field: "requestJson", filtering: false,
                        //render: (rowData) => JSON.stringify(rowData.requestJson, null, "\t")
                    },
                    {
                        title: "Session Type", field: "sessionType", render: (rowData) => !rowData.sessionType ? "ERITOP" : rowData.sessionType,
                        width: "5%",
                    },
                ],
            sessionHistory: []
        }
    }

    componentDidMount() {
        if (this.props.sessionId) {
            this.setState({ isLoading: true }, () => {
                ApiClient.post("Session/GetSessionHistory", { id: this.props.sessionId })
                    .then(response => {
                        this.setState({ isLoading: false, sessionHistory: response.data });
                    }).catch(error => {
                        console.error(error);
                        this.setState({ isLoading: false });
                    });
            });
        }
    }

    render() {
        return (
            <EuiRow>
                <Table
                    key="session-history-table"
                    columns={this.state.sessionHistoryColumns}
                    data={this.state.sessionHistory}
                    isLoading={this.state.isLoading}
                    options={{
                        paging: false,
                        search: false,
                        minBodyHeight: 200
                    }}
                >
                </Table>
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ sessionData: state.session, userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionHistory);
