import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import EuiRow from "../../eui-components/row";
import EuiTile from "../../eui-components/tile";
import constants from "../../utils/constants";
import EuiCard from "../../eui-components/card";
import CommonHelper from "../../utils/common-helper";


class Inbox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inboxItems: [
                {
                    id: 1, title: "EHS Inbox", visible: [2],
                    onClick: () => {
                        this.props.userData.rscPerson?.checkin ? this.props.history.push("/ehs-inbox") : CommonHelper.createNotification({
                            title: "Check-In Required",
                            description: "Please check-in to access EHS Inbox and approve/reject sessions",
                            icon: "cross"
                        })
                    },
                    detail: "Remote EHS Inspection"
                },
                {
                    id: 2, title: "Quality/Supervision Inbox", visible: [2],
                    onClick: () => {
                        this.props.history.push("/activity-inbox")
                        /*this.props.userData.rscPerson?.checkin ?

                            : CommonHelper.createNotification({
                            title: "Check-In Required",
                            description: "Please check-in to access Quality/Supervision Inbox and approve/reject sessions",
                            icon: "cross"
                        })*/

                    },
                    detail: "Remote Quality Audits / Site Supervision"
                },
                {
                    id: 3, title: "Implementation Inbox", visible: [2], visibleJobRole: [constants.userRoles.ERICSSON_IM],
                    onClick: () => this.props.history.push("/im-inbox"),
                    detail: "VSS/VCOP"
                },
                {
                    id: 4, title: "Tickets", visible: [2, 3],
                    onClick: () => this.props.history.push("/ticket-inbox"),
                    detail: "RSC Ticketing System"
                },
                {
                    id: 5, title: "Customer Sessions Inbox", visible: [1, 2, 3],
                    onClick: () => this.props.history.push("/my-customer-sessions"),
                    detail: "Remote Customer Acceptance"
                },
                {
                    id: 6, title: "Live Streaming Sessions", visible: [1, 2, 3],
                    onClick: () => this.props.history.push("/live-streaming"),
                    detail: "Live Streaming Sessions"
                },
                {
                    id: 7, title: "Vehicle Inspection Inbox", visible: [2], visibleJobRole: [constants.userRoles.EHS_SME,constants.userRoles.VALIDATION_OFFICER] ,
                    onClick: () => this.props.history.push("/vehicle-inbox"),
                    detail: "Vehicle Inspection Inbox"
                },
                // {
                //     id: 8, title: "Sudden EHS Inbox", visible: [1, 2, 3],
                //     onClick: () => this.props.history.push("/live-streaming"),
                //     detail: "Sudden EHS Inbox"
                // }
                // {
                //     id: 8, title: "Vehicle approval", visible: [2],  visibleJobRole:  [constants.userRoles.EHS_SME,constants.userRoles.VALIDATION_OFFICER],
                //     onClick: () => this.props.history.push("/vehicleApproval"),
                //     detail: "Vehicle approval process Inbox"
                // },
       

            ]
        };
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
    }

    renderCard = () => {
        return this.state.inboxItems.map(item => {
            
            

            if ((item.visibleJobRole && !item.visibleJobRole.includes(this.props.userData.userData.jobRole))
                || !item.visible.includes(this.props.userData.loginRole)) {
                return null;
            }
            return <div className="column sm-12 md-6 lg-3 xl-3 filled">
                <EuiCard key={item.id} id={"inbox_card_" + item.id}
                    className={item.disabled ? " disabled " : ""}
                    title={item.title}
                    titleStyle={{ fontSize: 16 }}
                    content={<div style={{ color: "gray" }}>
                        <p style={{ height: 50 }}>
                            {item.detail}
                        </p>
                        <p>
                            <button className="btn primary " onClick={() => item.onClick && item.onClick()}><i class="icon icon-launch" style={{ marginRight: 10 }}></i>Go</button>
                        </p>
                    </div>}
                    style={{ height: 150 }}
                />
            </div>
        })
    }

    renderMod = () => {
        return (
            <>
                <EuiTile fullscreen contentClassName="mt-4" title="Overview" headerStyle={{ paddingBottom: 12 }} titleClassName="ml-1">
                    <p className="ml-1">This page provides a channel to link all kinds of sessions.</p>
                    <EuiRow>
                        {this.renderCard()}
                    </EuiRow>
                </EuiTile>
            </>
        );
    }

    render() {
        return (
            <>
                {this.renderMod()}
            </>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(Inbox)));
