import React, { useEffect, useState } from "react";
import { withLayout } from "../../layout";
import EuiRow from "../../eui-components/row";
import EuiTile from "../../eui-components/tile";
import ApiHelper from "../../utils/api-helper";
import withClearCache from "../../ClearCache";
import { connect, useDispatch, useSelector } from "react-redux";
import EuiLoading from "../../eui-components/loading";
import DialogModal from "../../components/dialog";
import UserHelper from "../../utils/user-helper";
import ApiClient from "../../utils/api-client";
import CommonHelper from "../../utils/common-helper";
import AutoAssignmentHelper from "../../utils/auto-assignment-helper";
import constants from "../../utils/constants";
import UserAdminHelper from "../../utils/user-admin-helper";


const Home = (props) => {

    const navBarView = useSelector(state => state.navigation.navBarView);
    const jobRole = useSelector(state => state.user.userData.jobRole);
    const checkin = useSelector(state => state.user.rscPerson?.checkin);
    const rscPerson = useSelector(state => state.user.rscPerson);
    const userRefresfred = useSelector(state => state.user.userRefreshed);
    const [isLoading, setIsLoading] = useState(false);
    const [openCheckinDialog, setOpenCheckinDialog] = useState(UserHelper.isRscInspector(jobRole) && !checkin);
    const [ISEritopUser, setISEritopUser] = useState(true)
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();


    useEffect(() => {
        if (!navBarView) {
            dispatch({ type: "TOGGLE_NAVBAR" });
        }

        async function fetchCountriesAndUsers() {
            await ApiHelper.setCountriesWithDetails((data) => dispatch({ type: "SET_ALL_COUNTRIES", payload: data }), (data) => dispatch({ type: "SET_ALL_CUSTOMERS", payload: data }));
            await ApiHelper.setAllUsers((data) => dispatch({ type: "SET_ALL_USERS", payload: data }));
        }

        setIsLoading(true);
        fetchCountriesAndUsers();
        setIsLoading(false);

        setISEritopUser(!user?.userDetail?.loginComment)
    }, []);

    useEffect(() => {
        // if (!userRefresfred)  // refresh user data atleast one time in backgroud i.e if someone updated db , this is to avoid force cache clean
        setTimeout(() => {
            refreshAllUserDataInBackgroudAsync()
        }, 0);

    }, [userRefresfred])


    const refreshAllUserDataInBackgroudAsync = async () => {
        return ApiHelper.refreshAllUserDataInBackgroud(props.setUserLoginRoles, props.setRscCenters, props.login, props.setWebToken, props.setUserRefreshed,props.user.userData.jobRole);
    }


    // const refreshAllUserDataInBackgroud = () => {
    //     ApiClient.post("UserAdmin/GetUserLoginRoles")
    //         .then(response => {
    //             props.setUserLoginRoles(response.data);
    //             ApiClient.post("UserAdmin/GetRscCenters")
    //                 .then(response => {
    //                     const rscCenters = response.data;
    //                     if (rscCenters) {
    //                         rscCenters.map(r => {
    //                             r.value = r.id;
    //                             r.text = r.name;
    //                         });
    //                     }
    //                     props.setRscCenters(rscCenters);
    //                     fetchUSerDetail()
    //                 }).catch(error => {
    //                     console.error(error);

    //                 });
    //         }).catch(error => {
    //             console.error(error);

    //         });
    // }

    const fetchUSerDetail = () => {


        const onSuccess = (response) => {

            props.setWebToken(response.data.user?.webToken);
            const loginUser = UserAdminHelper.mapLoginUser(response.data, props.userAdminData.loginRoles, props?.user?.rscCenters);
            loginUser.rememberMe = true;

            props.login(loginUser);
            props.setUserRefreshed(true)

            // setTimeout(() => this.props.history.push("/"), 1000);

        };
        const onError = (error) => {
            console.error(error);

        };
        ApiClient.post("User/GetUserConfig", { includeEveryThing: true }).then(onSuccess).catch(onError);
    };
    const checkinCheckout = () => {

        var request = {
            rscPersonId: rscPerson.id,
            checkin: true,
        };
        setIsLoading(true);
        ApiClient.post("AutoAssignment/CheckinCheckout", request)
            .then(response => {
                if (response.data) {
                    ApiClient.checked(request);
                    AutoAssignmentHelper.mapRscPerson(response.data);
                    dispatch({ type: "SET_RSC_PERSON", payload: { ...rscPerson, ...response.data } });
                    setOpenCheckinDialog(false);
                    setIsLoading(false);
                } else {
                    CommonHelper.createNotification({ title: response.data.errorDetail, icon: "cross" });
                }
            })
            .catch(error => {
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "cross" });
                console.error(error);;
            });
    }

    const renderModNotEritopUser = () => {
        return (
            <>
                <EuiRow style={{ fontSize: 14 }} className="disabled" >
                    <EuiLoading id="checkinCheckOutLoading" isLoading={isLoading} />

                    <EuiTile fullscreen>
                        Ericsson Eritop ASP

                    </EuiTile>
                    <div style={{ width: '100%', top: '10%', position: 'absolute' }}>
                        
                        <h2 style={{ textAlign: 'left', color: 'red' , left : 10 , right : 10 , maxLines : 10 , whiteSpace: 'pre-wrap'}}>{user?.userDetail?.loginComment}</h2>
                    </div>

                </EuiRow>
            </>
        );
    }
    const renderMod = () => {
        return (
            <>
                <EuiRow style={{ fontSize: 14 }} className={isLoading ? "disabled" : ""}>
                    <EuiLoading id="checkinCheckOutLoading" isLoading={isLoading} />

                    <EuiTile fullscreen>
                        Ericsson Eritop ASP

                    </EuiTile>
                    <DialogModal
                        buttonName="Confirm"
                        content={<div>{"Please confirm your check-in for Activities/EHS pings Auto-Assignment"} </div>}
                        handleClose={() => setOpenCheckinDialog(false)}
                        id="checkinDialogId"
                        onClick={() => checkinCheckout()}
                        open={openCheckinDialog}
                        title="Check-In to Auto-Assignment"
                    >
                    </DialogModal>
                </EuiRow>
            </>
        );
    }
    return (
        <>
            {ISEritopUser ? renderMod() : renderModNotEritopUser()}
        </>
    );
}

const mapStateToProps = state => ({ user: state.user, userAdminData: state.userAdmin });
const mapDispatchToProps = dispatch => {
    return {
        passwordReset: () => dispatch({ type: "RESET_PASSWORD" }),
        login: (loginUser) => dispatch({ type: "LOGIN", payload: loginUser }),
        setUserLoginRoles: (loginRoles) => dispatch({ type: "SET_USER_LOGIN_ROLES", payload: loginRoles }),
        setRscCenters: (rscCnters) => dispatch({ type: "SET_RSC_CENTERS", payload: rscCnters }),
        setWebToken: (webToken) => dispatch({ type: "SET_WEB_TOKEN", payload: webToken }),
        setUserRefreshed: (userRefreshed) => dispatch({ type: "SET_USER_REFRESHED", payload: userRefreshed }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLayout(withClearCache(Home)));


