import React, { useState } from "react";
import {
  Stack,
  Box,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { DownloadSimple, Image } from "phosphor-react";
import Embed from "react-embed";
import constants from "../../../utils/constants";
import DocumentDialogModal from "../../../components/documentDialogModal";
import SasHelper from "../../../utils/sas-helper";

const TextMsg = ({ el, activityId }) => {
  const theme = useTheme();


  return (
    <>
      <Box
        display="flex"
        sx={{
          position: "relative",
          left: el.incoming ? "0" : "40%",
          maxWidth: '60%',
          width: '60%',
          backgroundColor: el.incoming ? "#FFF"
            : "lightgray",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack direction="column" width={'100%'} >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              borderBottom: '1px solid darkgrey',
              flexGrow: 1
            }}
          >
            <Stack p={1} justifyContent={'flex-start'} flex={1}>

              {/* <Grid item xs={6} md={1}>
                <Tooltip title={el.userName}>
                  <div>
                    <Avatar
                      alt={el.userName}
                      src={el.profileAvatar}
                      sx={{
                        height: "33px",
                        width: "33px",
                        marginTop: "24px",
                        marginRight: "-40px",
                        marginLeft: "-24px",
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid> */}
              <label>{el?.userName}</label>
              <label>{el?.email}</label>
            </Stack>
            <Stack p={1} justifyContent={'flex-end'}>
              <label>{el.time}</label>
              <label>{el.ehsPingId ? 'EHS ID: ' + el.ehsPingId : 'Activity ID: ' + activityId}</label>
            </Stack>
          </Stack>
          <Stack direction="row" p={1}>
            <Typography
              variant="body2"
              style={{ whiteSpace: "pre-wrap", textWrap: 'wrap', overflowWrap: 'anywhere' }}
              color={el.incoming ? theme.palette.text : "#fff"}
            >
              {el.message}

            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
const ImageMsg = ({ el, sasToken, activityId }) => {
  const theme = useTheme();

  const [openDialogPreview, setOpenDialogPreview] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  return (
    <>
      <Box
        display="flex"
        sx={{
          position: "relative",
          left: el.incoming ? "0" : "40%",
          maxWidth: '60%',
          width: '60%',
          backgroundColor: el.incoming ? "#FFF"
            : "lightgray",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack direction="column" width={'100%'} >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              borderBottom: '1px solid darkgrey',
              flexGrow: 1
            }}
          >
            <Stack p={1} justifyContent={'flex-start'} flex={1}>
              <label>{el?.userName}</label>
              <label>{el?.email}</label>
            </Stack>
            <Stack p={1} justifyContent={'flex-end'}>
              <label>{el.time}</label>
              <label>{el.ehsPingId ? 'EHS ID: ' + el.ehsPingId : 'Activity ID: ' + activityId}</label>
            </Stack>
          </Stack>
          <Stack direction="column" p={1}>
            <img
              src={SasHelper.addSasKey(el?.image_path , sasToken)} //
              style={{
                maxHeight: 210,
                borderRadius: "10px",
                objectFit: "contain",
              }}
              alt={el.message}
              onClick={() => {
                setOpenDialogPreview(true);
                setSelectedDocument({
                  documentPath: SasHelper.addSasKey(el?.image_path , sasToken),
                  type: constants.documentTypes.IMAGE,
                });
              }}
            />
            <Typography
              variant="body2"
              alignSelf="center"
              color={el.incoming ? theme.palette.text : "#fff"}
            >
              {el.message}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <DocumentDialogModal
        openDialogPreview={openDialogPreview}
        handleClose={() => setOpenDialogPreview(false)}
        id="ChatDocumentView"
        showLocation={false}
        selectedDocument={selectedDocument} // 1 image, 2 video, 3 pdf
      ></DocumentDialogModal>
    </>
  );
};
const VideoMsg = ({ el, sasToken, activityId }) => {
  const theme = useTheme();

  const [openDialogPreview, setOpenDialogPreview] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState({});
  return (
    <>
      <Box
        display="flex"
        sx={{
          position: "relative",
          left: el.incoming ? "0" : "40%",
          maxWidth: '60%',
          width: '60%',
          backgroundColor: el.incoming ? "#FFF"
            : "lightgray",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack direction="column" width={'100%'} >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              borderBottom: '1px solid darkgrey',
              flexGrow: 1
            }}
          >
            <Stack p={1} justifyContent={'flex-start'} flex={1}>
              <label>{el?.userName}</label>
              <label>{el?.email}</label>
            </Stack>
            <Stack p={1} justifyContent={'flex-end'}>
              <label>{el.time}</label>
              <label>{el.ehsPingId ? 'EHS ID: ' + el.ehsPingId : 'Activity ID: ' + activityId}</label>
            </Stack>
          </Stack>
          <Stack direction="column" p={1}>
            <video
              controls
              // autoPlay={true}
              style={{
                maxHeight: 210,
                borderRadius: "10px",
                objectFit: "contain",
              }}
              alt={el.message}
              onClick={() => {
                setOpenDialogPreview(true);
                setSelectedDocument({
                  documentPath: SasHelper.addSasKey(el?.video_path , sasToken),
                  type: constants.documentTypes.VIDEO,
                });
              }}
            >
              <source
                src={SasHelper.addSasKey(el?.video_path , sasToken)}
                type="video/mp4"
              />
            </video>
            <Typography
              variant="body2"
              alignSelf="center"
              color={el.incoming ? theme.palette.text : "#fff"}
            >
              {el.message}
            </Typography>
          </Stack>
        </Stack>
      </Box>
      <DocumentDialogModal
        openDialogPreview={openDialogPreview}
        handleClose={() => setOpenDialogPreview(false)}
        id="ChatDocumentView"
        showLocation={false}
        selectedDocument={selectedDocument} // 1 image, 2 video, 3 pdf
      ></DocumentDialogModal>
    </>
  );
};
const AudioMsg = ({ el, sasToken, activityId }) => {
  return (
    <>
      <Box
        display="flex"
        sx={{
          position: "relative",
          left: el.incoming ? "0" : "40%",
          maxWidth: '60%',
          width: '60%',
          backgroundColor: el.incoming ? "#FFF"
            : "lightgray",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack direction="column" width={'100%'} >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              borderBottom: '1px solid darkgrey',
              flexGrow: 1
            }}
          >
            <Stack p={1} justifyContent={'flex-start'} flex={1}>
              <label>{el?.userName}</label>
              <label>{el?.email}</label>
            </Stack>
            <Stack p={1} justifyContent={'flex-end'}>
              <label>{el.time}</label>
              <label>{el.ehsPingId ? 'EHS ID: ' + el.ehsPingId : 'Activity ID: ' + activityId}</label>
            </Stack>
          </Stack>
          <Stack direction="row" p={1}>
            <div
              style={{
                marginTop: "-4px",
                marginLeft: "-2px",
                width: "100%",
                height: 70,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
                flexDirection: "row"
              }}
            >
              <audio
                src={`${el.audio_path}?${sasToken}`}
                type="audio/mpeg"
                controls
              ></audio>
            </div>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
const DocMsg = ({ el, sasToken, activityId }) => {
  const theme = useTheme();

  const handleDownload = (link) => {
    window.location.replace(SasHelper.addSasKey(link , sasToken));
  };

  return (
    <>
      <Box
        display="flex"
        sx={{
          position: "relative",
          left: el.incoming ? "0" : "40%",
          maxWidth: '60%',
          width: '60%',
          backgroundColor: el.incoming ? "#FFF"
            : "lightgray",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack direction="column" width={'100%'} >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              borderBottom: '1px solid darkgrey',
              flexGrow: 1
            }}
          >
            <Stack p={1} justifyContent={'flex-start'} flex={1}>
              <label>{el?.userName}</label>
              <label>{el?.email}</label>
            </Stack>
            <Stack p={1} justifyContent={'flex-end'}>
              <label>{el.time}</label>
              <label>{el.ehsPingId ? 'EHS ID: ' + el.ehsPingId : 'Activity ID: ' + activityId}</label>
            </Stack>
          </Stack>
          <Stack direction="row" p={1}>

            <Stack
              p={2}
              direction="row"
              spacing={3}
              alignItems="center"
              sx={{
                backgroundColor: theme.palette.background.paper,
                borderRadius: 1,
              }}
            >
              <Image size={48} />
              <Typography variant="caption">
                {el.msg}
              </Typography>
              <IconButton
                onClick={() => {
                  ///console.log("report onclikc", el);
                  handleDownload(el.file_path);
                }}
              >
                <DownloadSimple />
              </IconButton>
            </Stack>
            <Typography
              variant="body2"
              color={el.incoming ? theme.palette.text : "#fff"}
            >
              {el.message}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
const LinkMsg = ({ el, activityId }) => {
  const theme = useTheme();
  return (
    <>
      <Box
        display="flex"
        sx={{
          position: "relative",
          left: el.incoming ? "0" : "40%",
          maxWidth: '60%',
          width: '60%',
          backgroundColor: el.incoming ? "#FFF"
            : "lightgray",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Stack direction="column" width={'100%'} >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              borderBottom: '1px solid darkgrey',
              flexGrow: 1
            }}
          >
            <Stack p={1} justifyContent={'flex-start'} flex={1}>
              <label>{el?.userName}</label>
              <label>{el?.email}</label>
            </Stack>
            <Stack p={1} justifyContent={'flex-end'}>
              <label>{el.time}</label>
              <label>{el.ehsPingId ? 'EHS ID: ' + el.ehsPingId : 'Activity ID: ' + activityId}</label>
            </Stack>
          </Stack>
          <Stack direction="row" p={1}>

            <Stack direction={"column"} spacing={2}>
              <Embed
                width="300px"
                isDark
                url={el.message}
              />
            </Stack>
            <Typography
              variant="body2"
              color={el.incoming ? theme.palette.text : "#fff"}
            >
              <div dangerouslySetInnerHTML={{ __html: el.message }}></div>
            </Typography>
          </Stack>
        </Stack>
      </Box >
    </>
  );
};
const ReplyMsg = ({ el }) => {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent={el.incoming ? "start" : "end"}>
      <Box
        px={1.5}
        py={1.5}
        sx={{
          backgroundColor: el.incoming
            ? alpha(theme.palette.background.paper, 1)
            : theme.palette.primary.main,
          borderRadius: 1.5,
          width: "max-content",
        }}
      >
        <Stack spacing={2}>
          <Stack
            p={2}
            direction="column"
            spacing={3}
            alignItems="center"
            sx={{
              backgroundColor: alpha(theme.palette.background.paper, 1),

              borderRadius: 1,
            }}
          >
            <Typography variant="body2" color={theme.palette.text}>
              {el.message}
            </Typography>
          </Stack>
          <Typography
            variant="body2"
            color={el.incoming ? theme.palette.text : "#fff"}
          >
            {el.reply}
          </Typography>
        </Stack>
      </Box>
      {/* {menu && <MessageOption />} */}
    </Stack>
  );
};
const Timeline = ({ el }) => {
  const theme = useTheme();

  return (
    <Stack direction="row" alignItems={"center"} justifyContent="space-between">
      <Divider width="46%" />
      <Typography
        variant="caption"
        sx={{ color: theme.palette.text, width: "46%" }}
      >
        {el.time}
      </Typography>
      <Divider width="46%" />
    </Stack>
  );
};

export {
  Timeline,
  ImageMsg,
  VideoMsg,
  LinkMsg,
  DocMsg,
  TextMsg,
  ReplyMsg,
  AudioMsg,
};
