import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Sysbar from "./sysbar";
import Appbar from "./appbar";
import Appnav from "./appnav";
import Syspanel from "./syspanel";
import Notification from "../pages/notifications/notificationHandler";
export function withLayout(Page) {
    return class extends React.Component {
        render() {
            return (
                <>
                    <Sysbar />
                    <main>
                        <Syspanel />
                        <div className="app">
                            <Appbar />
                            <div className="appbody">
                                <Appnav key={this.props.userData?.rscPerson?.id} />
                                <div className="appcontent">
                                    <div className="container">
                                        <div className="appcontent">
                                            <Notification />
                                            <Page {...this.props} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            );
        }
    };
}

const mapStateToProps = state => ({ userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch({ type: "LOGOUT" })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout));
