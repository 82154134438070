export default class ProjectsHelper {

    static mapProjects = (projects, countries) => {
        if ((projects || []).length === 0) {
            return [];
        }
        projects.forEach(item => {
            item.customerName = item.customer?.name;
            item.countryId = item.customer?.countryId;
            item.customerId = item.customer?.id;
            item.viewActive = item.active ? "Active" : "Not Active";
            item.countryName = (item.customer?.countryId) ? countries?.find(x => x.value === item.customer?.countryId).text : "";
        });
        return projects;
    }

    static mapEditedProject = (Project, countries) => {
        if (!Project) {
            return;
        }
        Project.customerName = Project.customer.name;
        Project.countryName = Project.countryId ? countries?.find(x => x.value === Project.customer?.countryId).text : "";
        return Project;
    }

}