import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import EuiLoading from "../../eui-components/loading";

const VIImagePreview = (props) => {
    const { VIResponse, fileOnlinePath } = props;

    const [isLoading, setIsLoading] = useState(true);
    const canvasRef = useRef(null);

    useEffect(() => {
        
        
        getCanvasImage();
    }, [])

    const getCanvasImage = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');

        const image = new Image();
        image.src = fileOnlinePath;
       
        image.addEventListener('load', () => {
            let hRatio = canvas.width / image.width;
            let vRatio = canvas.height / image.height;
            let ratio = Math.min(hRatio, vRatio);

            context.drawImage(image, 0, 0, image.width, image.height, 0, 0, image.width * ratio, image.height * ratio);

            context.beginPath();

            
            

            _.forEach(VIResponse?.annotations, (annotation) => {
                context.beginPath();
                context.rect(annotation?.box?.box[0] * ratio, annotation?.box?.box[1] * ratio, annotation?.box?.box[2] * ratio, annotation?.box?.box[3] * ratio);
                context.fillStyle = 'transparent';
                context.fill();
                context.lineWidth = 2;

                if (annotation?.isDefect) {
                    context.strokeStyle = '#EE2D28';
                } else {
                    context.strokeStyle = '#09FC08';
                }
                context.stroke();

                context.beginPath();
                if (annotation?.isDefect) {
                    context.fillStyle = '#EE2D28';
                } else {
                    context.fillStyle = '#09FC08' ;
                }
                context.font = "20px Arial";
                context.fillText(annotation?.label + ": " + annotation?.box?.score, annotation?.box?.box[0] * ratio, annotation?.box?.box[1] * ratio - 5);
            })

            setIsLoading(false);
        });
    }

    return (
        <div>
            <EuiLoading isLoading={isLoading} />
            <canvas ref={canvasRef} height={window.innerHeight} width={window.innerWidth/2} style={{ backgroundColor: "transparent", height: "100%" }} ></canvas>
        </div>
    )
}

export default VIImagePreview;
