import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React from 'react'

const StyledChatBox = styled(Box)(({ theme }) => ({
  "&:hover": {
    cursor: "pointer",
  },
}));

export default StyledChatBox