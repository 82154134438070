import React from "react";
import { connect } from "react-redux";
import "../../../css/livestreamStyle.css";

class SlidePopup extends React.Component {

    render() {
        return (
            <div id="slide-popup" className={"slide-popup " + (this.props.liveStreamData.show ? "show" : "")} data-scrollbar="true" tabIndex="1"
                style={{ overflow: "hidden", outline: "none", width: this.props.liveStreamData.content.width }}>
                <div className="scroll-content">
                    <div>
                    {/*  height: 984, 670 803 */}
                        <div style={{ height: 624, overflow: "scroll", width: "100%" }}> 
                            <h2 draggable="true" className="slide-header" style={{ marginLeft: -10 }}>{this.props.liveStreamData.content.title}</h2>
                            {this.props.liveStreamData.content.htmlContent}
                        </div>
                    </div>
                </div>
                <div className="scrollbar-track scrollbar-track-x" style={{ display: "none" }}>
                    <div className="scrollbar-thumb scrollbar-thumb-x" style={{ width: this.props.liveStreamData.width, transform: "translate3d(0px, 0px, 0px)" }}>
                    </div>
                </div>
                <div className="scrollbar-track scrollbar-track-y" style={{ display: "none" }}>
                    <div className="scrollbar-thumb scrollbar-thumb-y" style={{ height: 754, transform: "translate3d(0px, 0px, 0px)" }}>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SlidePopup);
