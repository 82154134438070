// import React from 'react';
import ApiClient from './api-client';
import CommonHelper from "./common-helper";

/**
 * createLogs Component
 * @param logInfo
 * @param details
 * @constructor
 */
function Logs(logInfo, details) {

  if(!CommonHelper.getVisitorData()){
      // Saving visitor
      CommonHelper.saveVisit();
  }

  // check if there's a user
  if (logInfo.userId) {
      let visitor = CommonHelper.getVisitorData();
      const logsRequest = {
      userId: logInfo.userId,
      logName: logInfo.LogName,
      logAction: logInfo.LogAction,
      logObjectId: logInfo.LogObjectId,
      logTypeId: logInfo.LogTypeId,
      logBrowser: visitor.vBrowser,
      logIP: visitor.vIP,
      logLocation: visitor.vCountry,
      latitude: Number(visitor.latitude || logInfo.latitude).toString(),
       longitude: Number(visitor.longitude || logInfo.longitude).toString(),
      logDetail:
        typeof details === 'string'
          ? details
          : JSON.stringify(details),
      // when logout wait till log post is done the reload the page
    }
    return ApiClient.post("Log/createLog", logsRequest) // .then(() => payload.logObjectId === 0));
  }
}

export { Logs }


