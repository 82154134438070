const initialState = {
    tablePagination: null,
    tableId: null
}
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_TABLE_PAGINATION":
            return {
                ...state,
                tablePagination: action.payload.pagination,
                tableId: action.payload.id
            };
        default:
            return state;
    }
}