import React from "react";
import { useEffect } from "react";
import { Select } from '@eds/vanilla';

const EuiNewMultiSelect = (props) => {

    useEffect(() => {
        if (props.id) {
            const selectDOM = document.querySelector('#' + props.id);
            if (selectDOM) {
                const select = new Select(selectDOM);
                select.init();
                select.value = props.value || [];
                selectDOM.addEventListener("change", (e) => handleChange(e, select));
                if (selectDOM.querySelector("div.options_container")) {
                    selectDOM.querySelector("div.options_container").addEventListener("click", (e) => handleChange(e, select));
                }
            }
        } else {
            console.error("You should set an id to Select component");
        }
    }, []);

    const handleChange = (e, select) => {
        props.onChange && props.onChange(e, select.selectedOptions);
    }

    const p = {
        "data-type": "input-multi",
        "selectedOptions": props.value
    }

    return (<div id={props.id}
        className={"select " + (props.className ?? "") + " " + (props.disabled && "disabled")}
        style={props.style}
        {...p}>       
        <div className="clickable current-options">
            <input id={"input_" + props.id} className={(props.inputClassName ?? "")} type="text" placeholder={props.placeholder ?? "Select option"} name={props.name} />
        </div>
        <div className="options-list">{
            props.options?.map(o => {
                return <div key={o.value}
                    className={"item " + (o.hidden ? " hidden " : "") + (!o.hidden && props.value?.includes(o.text) ? "active" : "")}>{o.text}
                </div>
            })
        }
        </div>
    </div>);
}

export default EuiNewMultiSelect;