import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import FormField from "../../eui-components/formfield";
import { withRouter } from "react-router-dom";
import EuiTextField from "../../eui-components/textfield";
import EuiTextArea from "../../eui-components/textarea";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import ApiClient from "../../utils/api-client";
import EuiCheckbox from "../../eui-components/checkbox";

class AddActivityTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templateType: [{ value: 0, text: "Customer" }, { value: 1, text: "Project" }],
            isProject: (this.props.edit ? (this.props.activityTemplateData.selectedTemplate?.templatableType === 'Project' ? true : false) : false),
            isCustomer: (this.props.edit ? (this.props.activityTemplateData.selectedTemplate?.templatableType === 'Customer' ? true : false) : true),
            templateClassifications: []
        }
    }

    componentDidMount() {
        if (!this.props.activityTemplateData.projects.length || !this.props.activityTemplateData.customers.length) {
            this.setState({
                isLoading: true
            }, () => {
                ApiClient.post("ActivityTemplate/GetData", {})
                    .then(response => {
                        if (response.data) {
                            this.props.setData({ projects: response.data.projects, customers: response.data.customers });
                            this.setState({
                                isLoading: false
                            })
                        }
                    }).
                    catch(error => {
                        alert(error);
                        this.setState({
                            isLoading: false
                        })
                    })
            })

        }
    }

    handleChange = (e) => {
        const selectedTemplate = { ...this.props.activityTemplateData.selectedTemplate };
        const target = e.target;
        const name = target.name;
        const value = target.value ?? target.checked;
        if (name == "templatableType") {
            selectedTemplate[name] = value;
            if (value === "Customer") {
                this.setState({
                    isCustomer: true,
                    isProject: false
                });
            }
            else {
                this.setState({
                    isCustomer: false,
                    isProject: true
                });
            }
            selectedTemplate['templatableId'] = null;
        }
        else {
            selectedTemplate[name] = value;
        }
        this.props.setSelectedTemplate(selectedTemplate);
    }

    render() {
        return (
            <EuiRow key={this.state.isProject} className="w-9"  >
                <FormField className="w-10 mt-2 mb-2">
                    <label className="color-gray mb-2 required" style={{ fontSize: "14px" }} htmlFor="templateType">Template Type</label>
                    <span>
                        <span>
                            <input id="rbtn1" name="templatableType" type="radio"
                                checked={(this.props.activityTemplateData.selectedTemplate?.templatableType === 'Customer' || this.props.activityTemplateData.selectedTemplate?.templatableType === 'Project' ?
                                    this.props.activityTemplateData.selectedTemplate?.templatableType === 'Customer' : true)} value="Customer" onChange={this.handleChange} />
                            <label for="rbtn1">Customer</label>
                        </span>
                        <span>

                            <input id="rbtn2" name="templatableType" type="radio" checked={this.props.activityTemplateData.selectedTemplate?.templatableType === 'Project'} value="Project" onChange={this.handleChange} />
                            <label for="rbtn2">Project</label>
                        </span>

                    </span>
                </FormField>
                <FormField className={this.state.isCustomer ? "hidden" : "w-10 mt-2 mb-2"}>
                    <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="projectSelect">Select Project</label>
                    <EuiAutocompleteSelect
                        required
                        key={this.props.activityTemplateData.projects}
                        className="w-10"
                        label="Select Project"
                        id="projectSelect"
                        optionListStyle={{ width: "100%" }}
                        name="templatableId"
                        options={this.props.activityTemplateData.projects}
                        value={(this.props.activityTemplateData.selectedTemplate?.templatableType === 'Project' ? this.props.activityTemplateData.selectedTemplate?.templatableId : 0)}
                        onChange={(e) => this.handleChange(e)}
                        fullwidth
                    />
                </FormField>
                <FormField className={this.state.isProject ? "hidden" : "w-10 mt-2 mb-2"}>
                    <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="customerSelect">Select Customer</label>
                    <EuiAutocompleteSelect
                        required
                        key={this.props.activityTemplateData.customers}
                        className="w-10"
                        label="Select Customer"
                        id="customerSelect"
                        optionListStyle={{ width: "100%" }}
                        name="templatableId"
                        options={this.props.activityTemplateData.customers}
                        value={(this.props.activityTemplateData.selectedTemplate?.templatableType === 'Customer' ? this.props.activityTemplateData.selectedTemplate?.templatableId : 0)}
                        onChange={(e) => this.handleChange(e)}
                        fullwidth
                    />
                </FormField>
                <FormField className="w-10 mt-2 mb-2">
                    <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="classificationSelect">Template Classification</label>
                    <EuiAutocompleteSelect
                        required
                        key={this.props.activityTemplateData.categories}
                        className="w-10"
                        label="Select Classification"
                        id="classificationSelect"
                        optionListStyle={{ width: "100%" }}
                        name="templateClassification"
                        options={this.props.activityTemplateData.categories}
                        value={this.props.activityTemplateData.selectedTemplate?.templateClassification}
                        onChange={(e) => this.handleChange(e)}
                        fullwidth
                    />
                </FormField>

                <FormField className="w-10 mt-2 mb-2">
                    <EuiTextField required className="w-10" fullwidth id="templateName" name="name" label="Template Name" labelClass="color-gray required" labelStyle={{ fontSize: "14px", color: "gray" }}
                        value={this.props.activityTemplateData.selectedTemplate?.name} onChange={(e) => this.handleChange(e)} />
                </FormField>
                <FormField className="w-10 mt-2 mb-2">
                    <EuiTextArea className="w-10" fullwidth id="templateDescription" name="description" label="Description" labelClass="color-gray" labelStyle={{ fontSize: "14px", color: "gray" }}
                        value={this.props.activityTemplateData.selectedTemplate?.description} onChange={(e) => this.handleChange(e)} />
                </FormField>
                <FormField className="w-10 mt-2 mb-2">
                    <EuiCheckbox
                        label="Public Template"
                        className="flex-start-content pl-0 pr-1"
                        id="publicTemplateChecklist"
                        refValue={!this.props.activityTemplateData.selectedTemplate?.publicTemplate ? false : true}
                        onChange={(e) => this.handleChange(e)}                        
                        name="publicTemplate" />
                    <label style={{ color: 'red' }}>*Public template can be modified or deleted by any user</label >
                </FormField>
            </EuiRow >

        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, activityTemplateData: state.activityChecklists });
const mapDispatchToProps = dispatch => {
    return {
        setSelectedTemplate: (selectedTemplate) => dispatch({ type: "SET_SELECTED_TEMPLATE", payload: selectedTemplate }),
        setTemplates: (temps) => dispatch({ type: "SET_TEMPLATES", payload: temps }),
        setData: (data) => dispatch({ type: "SET_DATA", payload: data })
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddActivityTemplate));

