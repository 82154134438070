import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiDialog from "../../eui-components/dialog";
import EuiLoading from "../../eui-components/loading";
import EuiSelect from "../../eui-components/select";
import { Notification } from '@eds/vanilla';
import ApiClient from "../../utils/api-client";
import DateUtils from "../../utils/date-utils";
import AspHelper from "../../utils/asp-helper";
import constants from "../../utils/constants";
import SasHelper from "../../utils/sas-helper";

class PPE extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            competencesColumns: [
                {
                    title: "Description",
                    key: "name",
                    width: "13%"
                },
                {
                    title: "Status",
                    key: "skillValue",
                    width: "2%"
                },
                {
                    title: "",
                    key: "skillId",
                    width: "2%",
                    onCreatedCell: (td, cellData) => {
                        if (cellData && (this.props.userData.userData.jobRole == constants.userRoles.ERICSSON_IM || this.props.userData.userData.jobRole == constants.userRoles.RSC_OHS_INSPECTOR || this.props.userData.userData.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                        )) {
                            td.innerHTML = `<span id="editSkill_" class="tooltip"><i id="editSkill_" style={font-weight:500px;} class="icon icon-edit color-black text-lg clickable my-disable "></i><span class="message top-end">Edit Skill</span></span>`;
                            td.querySelector("#editSkill_").addEventListener('click', () => this.openEditDialog(cellData))
                        }
                        else
                            td.innerHTML = "";
                    }
                },
                {
                    title: "Last Modified Date",
                    key: "lastModifiedDateTime",
                    width: "10%",

                },
                {
                    title: "Last Modified User",
                    key: "lastModifiedUserName",
                    width: "10%",

                }
            ],
            data: [],
            isLoading: true,
            skillValue: "",
            skillOptions: [],
            dialogOpened: false,
            currentSkill: { options: [], selectedOption: "" },
            dialogEditDone: false,
        }
    }

    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        var data = [];
        const _skills = [...this.props.aspResourcesData.skills.filter(item => item.skillArea === "PPE" && item.active)];
        const resourceSkills = [...this.props.aspResourcesData.resource.skills];
        if (_skills) {
            _skills.forEach(skill => {
                var resourceSkill = resourceSkills.find(item => item.skillId == skill.id);
                var options = [];
                ///skills option mapping
                if (skill.skillOptions) {
                    skill.skillOptions.forEach(o => {
                        var option = {
                            text: o.name,
                            value: o.id
                        }
                        options.push(option);
                    });
                }
                if (resourceSkill) {
                    var selectedOption = options.find(item => item.text == resourceSkill.skillValue)?.id;
                    var fullSkill = {
                        ...skill,
                        ...resourceSkill,
                        options: options,
                        selectedOption: selectedOption,
                        id: resourceSkill.id,
                        skillId: skill.id
                    }
                    data.push(fullSkill);
                }
                else {
                    var fullSkill = {
                        ...skill,
                        options: options,
                        skillValue: "---",
                        selectedOption: "",
                        skillId: (skill.id ? skill.id : skill.skillId),
                        id: ""
                    }

                    data.push(fullSkill);
                }

            });
        }
        this.setState({
            data: data,
            isLoading: false
        })
    }

    updateData = () => {
        this.setState({
            isLoading: true
        })
        const filter = this.props.aspResourcesData.filter;
        if (!filter || Object.values(filter).every(x => (x === null || x === ''))) {
            filter.id = this.props.aspResourcesData.resource.id;
            filter.idsList = [];
            filter.idsList.push(this.props.aspResourcesData.resource.id);
        }
        filter.id = parseInt(filter.id ?? "0");
        ApiClient.post("Asp/GetResources", filter)
            .then(response => {
                ApiClient.post("Asp/GetResourceJobs", response.data.iDs)
                    .then(response2 => {
                        var _data = [];
                        var blobs = response.data.profileBlobs;
                        var resourceProjects = response.data.projects;
                        var certificates = [];
                        var resourceJobs = response2.data;
                        var skills = response.data.skills;
                        var faces = response.data.faces;
                        this.props.setResourceUsers(response.data.users);
                        response.data.certifications.forEach(c => {
                            var _c = this.props.aspResourcesData.certificates.filter(x => x.value == c.certificationId);
                            if (_c && _c[0]) {
                                var date = DateUtils.getUtcDate();
                                var diff = DateUtils.dateDifference(new Date(c.validUntil), new Date(date));
                                var element = {
                                    ...c,
                                    validSince: DateUtils.toDateString(c.validSince),
                                    validUntil: DateUtils.toDateString(c.validUntil),
                                    createdate: DateUtils.toDateString(c.createdate),
                                    lastModifiedDateTime: DateUtils.toDateString(c.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                                    createUserName: c.createUserName,
                                    modifiedUserName: c.modifiedUserName,
                                    name: _c[0].text,
                                    code: _c[0].code,
                                    path: c.path,
                                    status: (new Date(c.validUntil).getTime() - date.getTime() > 0 ? "Valid" : "Expired"),
                                    _delete: c.id
                                }
                                certificates.push(element);
                            }
                        })
                        this.props.setFilteredResources([]);
                        response.data.resource.forEach(e => {
                            var projects = AspHelper.mapProjects(resourceProjects?.filter(item => item.resourceId == e.id), this.props.aspResourcesData.projects, this.props.userData.customers);
                            var country = this.props.userData.countries?.filter(c => c.value === e.countryId);
                            var company = this.props.aspResourcesData.companies.filter(c => c.value === e.aspCompanyId);
                            var cert = certificates.filter(c => c.resourceId === e.id);
                            var jobs = [];
                            var resourceSkills = skills.filter(s => s.resourceId == e.id);
                            var faceId = faces.find(f => f.resourceId == e.id)?.faceId;
                            var fullResourceSkills = AspHelper.getSkillInfo(resourceSkills, this.props.aspResourcesData.skills);
                            var _jobs = resourceJobs.filter(j => j.resourceId == e.id);
                            var inCompletedCerts = [];
                            var validResource = false;
                            _jobs.forEach(j => jobs.push(AspHelper.mapResourcesJobRole(j.value, this.props.aspResourcesData.jobs)));
                            var blob = blobs.filter(b => b.resourceId == e.id);
                            inCompletedCerts = AspHelper.getUnTakenCerts(cert, this.props.aspResourcesData.jobs, certificates, jobs);
                           /* if (e.status == "Active" && jobs.length) {
                                var test = true;
                                if (inCompletedCerts) {
                                    inCompletedCerts.forEach(c => {
                                        if (!c.valid)
                                            test = false;
                                    });
                                }
                                if (test)
                                    validResource = true;
                            }
                                */
                            var test = true;
                            if (inCompletedCerts) {
                             inCompletedCerts.forEach(c => {
                                 if (!c.valid)
                                     test = false;
                             });
                         }
                         validResource = test
                            if (jobs)
                            var element = {
                                ...e,
                                projects: projects,
                                status: e.status,
                                country: (country && country[0] ? country[0].text : ""),
                                aspCompany: (company && company[0] ? company[0].text : ""),
                                companyCountry: (company && company[0] ? company[0].countryId : ""),
                                profileImg: (blob && blob[0] ? SasHelper.addSasKey(constants.profileStorage + blob[0].key, this.props.sasToken)  : ""),
                                certs: cert,
                                jobs: (jobs ? jobs : []),
                                skills: fullResourceSkills,
                                jobList: (_jobs ? _jobs : []),
                                inCompletedCerts: inCompletedCerts,
                                validResource: validResource,
                                faceId: faceId,
                                path: (faceId ? e.path : null),
                                imagePath: (faceId ? e.imagePath : null)
                            }
                            _data.push(element);
                        });
                        const data = [..._data];
                        const selectedResource = data.find(item => item.id === this.props.aspResourcesData.resource.id);
                        this.props.setAspResource(selectedResource);
                        this.props.setFilteredResources(data);
                        this.props.setSelectedItem(4);
                        var d = Date.now();
                        this.props.updateTable(d);
                        this.setState({ isLoading: false })


                    })
            }).catch(error => {
                console.error(error);;
                alert(error);
            });

    }

    openEditDialog = (cellData) => {
        if (!this.state.dialogOpened) {
            const skill = this.state.data.find(item => item.skillId == cellData);
            skill['selectedOption'] = (skill.skillValue !== "---" ? skill.options.find(item => item.text === skill.skillValue)?.value : "");
            this.setState({
                currentSkill: skill,
                dialogOpened: true
            });
            document.querySelector("#editSkill_").click();
        }
    }

    onCancel = () => {
        this.setState({
            dialogOpened: false,
        })
    }

    saveSkillValue = () => {
        this.setState({
            isLoading: true,
            dialogOpened: false
        })
        const skilltoUpdate = { ...this.state.currentSkill };
        skilltoUpdate['lastModifiedUser'] = parseInt(this.props.userData.userData.userId)
        skilltoUpdate['resourceId'] = parseInt(this.props.aspResourcesData.resource.id);
        if (!skilltoUpdate.id)
            skilltoUpdate['id'] = parseInt(0);
        if (skilltoUpdate.lastModifiedDateTime)
            skilltoUpdate.lastModifiedDateTime = null;
        ApiClient.post("Asp/AddEditSkill", skilltoUpdate)
            .then(response => {
                this.setState({
                    isLoading: false
                })
                if (!response.data) {
                    const notification = new Notification({
                        title: 'Edit Skill',
                        description: 'Please select value to save',
                        timeout: 5000,
                        icon: 'icon-triangle-warning'
                    });
                    notification.init();
                }
                else {
                    this.setState({
                        dialogEditDone: true
                    })
                    this.updateData();
                }
            }).
            catch(error => {
                alert(error);
                this.setState({
                    isLoading: false
                })
            })
    }

    handleChange = (e) => {
        const currentSkill = { ...this.state.currentSkill };
        currentSkill['skillValue'] = e.target.text;
        this.setState({
            currentSkill: currentSkill
        })
    }

    render() {
        return (
            <EuiRow key={this.state.isLoading} disabled={this.state.isLoading || this.props.aspResourcesData.resource.status == "Blacklisted" ? "disabled" : ""} className=" w-10">
                <EuiLoading isLoading={this.state.isLoading} />

                <ObseleteEuiTable
                    classes="tiny striped"
                    columns={this.state.competencesColumns}
                    data={this.state.data}
                    id="competencesTable"
                    sortable={true}
                    rowsPerPage={this.state.data.length}
                >
                </ObseleteEuiTable>
                {(document.querySelector("#editSkill_") ?
                    <EuiDialog
                        id="editDialog"
                        dataTrigger="#editSkill_"
                        title={this.state.currentSkill.name}
                        body={<EuiSelect id="editSkill"
                            value={(this.state.currentSkill.selectedOption ? this.state.currentSkill.selectedOption : "Select Value")}
                            options={this.state.currentSkill.options}
                            label="Select Value"
                            name="editSkill"
                            optionListStyle={{ width: "100%" }}
                            onChange={(e) => this.handleChange(e)}
                        />}
                        buttonName="Save"
                        type="primary"
                        onCancel={this.onCancel}
                        onClick={this.saveSkillValue}
                        contentStyle={{ width: "250px" }}
                        bodyStyle={{ height: "200px" }}
                        done={this.state.dialogEditDone}

                    />
                    : null)}
            </EuiRow>

        )
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        changeAspResourcesMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setFilteredResources: (filteredResources) => dispatch({ type: "SET_FILTERED_RESOURCES", payload: filteredResources }),
        setResourceFilter: (filter) => dispatch({ type: "SET_RESOURCE_FILTER", payload: filter }),
        setResourceCompanies: (companies) => dispatch({ type: "SET_RESOURCE_COMPANIES", payload: companies }),
        setResourceCountries: (countries) => dispatch({ type: "SET_RESOURCE_COUNTRIES", payload: countries }),
        setCertificates: (certs) => dispatch({ type: "SET_CERTIFICATES", payload: certs }),
        setJobs: (jobs) => dispatch({ type: "SET_JOBS", payload: jobs }),
        setIDTypes: (types) => dispatch({ type: "SET_ID_TYPES", payload: types }),
        updateTable: (val) => dispatch({ type: "UPDATE_TABLE", payload: val }),
        setSkills: (skills) => dispatch({ type: "SET_SKILLS", payload: skills }),
        setSelectedItem: (selectedItem) => dispatch({ type: "UPDATE_SELECTED_ITEM", payload: selectedItem }),
        setResourceUsers: (users) => dispatch({ type: "SET_USERS", payload: users }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PPE);


