import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DialogModal from '../../components/dialog';
import EuiRow from '../../eui-components/row';
import EuiRowWithToolbar from '../../eui-components/rowwithtoolbar';
import EuiTile from '../../eui-components/tile';
import ObseleteEuiTable from '../../eui-components/obseleteTable';
import { withLayout } from '../../layout';
import ApiClient from '../../utils/api-client';
import PageHelper from "../../utils/page-helper";
import EuiLoading from "../../eui-components/loading";
import { Notification } from '@eds/vanilla';
import DateUtils from "../../utils/date-utils";
import constants from '../../utils/constants';
import SasHelper from "../../utils/sas-helper";

const ComplaintsList = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const complaint = useSelector(state => state.complaints.addedComplaint);
    const isLoading = useSelector(state => state.complaints.isLoading);
    const complaintsList = useSelector(state => state.complaints.complaintsList)
    const sasToken = useSelector(state => state.complaints.sasToken);
    const [warningDialog, openWarningDialog] = useState(false);
    const [cancel, setCancel] = useState(false);
    const [close, setClose] = useState(false);

    const complaintsColumns = [
        {
            title: "ID", key: "id", width: "3%", sort: "desc",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = `<a id=${"complaintId" + cellData}>${cellData}</a>`;
                td.querySelector("#complaintId" + cellData).addEventListener('click', () => openComplaintDetails(cellData))
            }
        },
        { title: "Type", key: "complaintTypeName", width: "8%" },
        { title: "ASP Company", key: "aspCompanyName", width: "8%" },
        {
            title: "Details", key: "complaintContentId", width: "15%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<a id=${"complaintDetails" + cellData[0]}>${cellData[1].substring(0, 80) + "..."}</a>`;
                    td.querySelector("#complaintDetails" + cellData[0]).addEventListener('click', () => openComplaintDetails(cellData[0]))
                }
            }
        },
        { title: "ASP Team", key: "complainedAboutUserName", width: "8%" },
        { title: "Created User", key: "createdUserName", width: "8%" },
        {
            title: "Status", key: "complaintStatusName", width: "5%", onCreatedCell: (td, cellData) => {
                var color = PageHelper.mapComplaintStatus(cellData)
                td.innerHTML = `<span><i class="icon icon-alarm-level3" style="color: ${color}"></i>${cellData}</span>`
            }
        },
        user.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER ?
            {
                title: "",
                key: "ackId",
                width: "1%",
                hideFilter: true,
                onCreatedCell: (td, cellData) => {
                    var complaintStatus = complaintsList.find(i => i.id == cellData);
                    if (complaintStatus.statusId == constants.complaintStatus.Cancelled || complaintStatus.statusId == constants.complaintStatus.Closed) {
                        td.innerHTML = ""
                    }
                    else {
                        complaintStatus.resolvedDateTime == null ?
                            (td.innerHTML = `<span style="display: none" class="tooltip clickable" id="editResolveBtn"><i style = "font-size: medium; font-weight: bold;" class="icon icon-edit"></i><span class="message top-end">Edit Corrective Actions</span></span>`)
                            : (td.innerHTML = `<span class="tooltip clickable" id="editResolveBtn" ><i style = "font-size: medium;" class="icon icon-edit clickable"></i><span class="message top-end">Edit Corrective Actions</span></span>`);
                        td.querySelector("#editResolveBtn").addEventListener('click', () => {
                            dispatch({ type: "SET_ADDED_COMPLAINT", payload: complaintsList.find(i => i.id == cellData) });
                            dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.RESOLVE })
                        })
                    }
                }
            } :
            {
                title: "",
                key: "ackId",
                width: "0%",
                hideFilter: true,
                onCreatedCell: (td, cellData) => {
                    td.innerHTML = "";
                }
            },
        {
            title: "",
            key: "resolveId",
            hideFilter: true,
            width: "1%",
            onCreatedCell: (td, cellData) => {
                var complaintCell = complaintsList.find(i => i.id == cellData);
                if (complaintCell.statusId == constants.complaintStatus.Cancelled || complaintCell.statusId == constants.complaintStatus.Closed) {
                    td.innerHTML = ""
                }
                else {
                    if (complaintCell.createUserId == user.userData.userId) {
                        td.innerHTML = `<span class="tooltip clickable" id="editTooltipid"><i style = "font-size: medium;" class = "icon icon-edit clickable"></i><span class="message top-end">Edit</span></span>`;
                        td.querySelector("#editTooltipid").addEventListener('click', () => {
                            dispatch({ type: "SET_ADDED_COMPLAINT", payload: complaintsList.find(i => i.id == cellData) });
                            dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.ADD });
                        });
                    }
                    else {
                        td.innerHTML = "";
                    }
                }
            }
        },

        {
            title: "",
            key: "resolveId",
            width: "1%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                var complaintCell = complaintsList.find(i => i.id == cellData);
                if (complaintCell.statusId == constants.complaintStatus.Cancelled || complaintCell.statusId == constants.complaintStatus.Closed) {
                    td.innerHTML = ""
                }
                else {
                    if (complaintCell.createUserId == user.userData.userId && (complaintCell.resolvedDateTime !== null)) {
                        td.innerHTML = `<div style="text-align: center;" class="tooltip clickable" id="closeBtn"><i style = "font-size: medium; font-weight: bold; color: #329864" class="icon icon-check clickable"></i><span class="message top-end">Close</span></div>`;
                        td.querySelector("#closeBtn").addEventListener('click', () => {
                            setClose(true);
                            openWarningDialog(true);
                            dispatch({ type: "SET_ADDED_COMPLAINT", payload: complaintsList.find(i => i.id == cellData) });
                        });
                    }
                    else {
                        td.innerHTML = "";
                    }
                }
            }
        },
        user.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER ?
            {
                title: "",
                key: "resolveId",
                width: "1%",
                hideFilter: true,
                onCreatedCell: (td, cellData) => {
                    var complaintCell = complaintsList.find(i => i.id == cellData);
                    if (complaintCell.statusId == constants.complaintStatus.Cancelled || complaintCell.statusId == constants.complaintStatus.Closed) {
                        td.innerHTML = ""
                    }
                    else {
                        (complaintCell.acknowledgedDateTime === null) ? td.innerHTML = `<span style="display: none" class="tooltip clickable" id = "resolveBtn" ><i class = "icon icon-arrow-right clickable"></i><span class="message top-end">Resolve</span></span>` : (complaintCell.resolvedDateTime === null) ? td.innerHTML = `<span class="tooltip clickable" id = "resolveBtn" ><i class="icon icon-arrow-right clickable" style = "font-size: medium;"></i><span class="message top-end">Resolve</span></span >` : td.innerHTML = `<span style="display: none" class="tooltip clickable" id = "resolveBtn" ><i class = "icon icon-arrow-right clickable"></i><span class="message top-end">Resolve</span></span >`;
                        td.querySelector("#resolveBtn").addEventListener('click', () => {
                            dispatch({ type: "SET_ADDED_COMPLAINT", payload: complaintsList.find(i => i.id == cellData) });
                            dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.RESOLVE})
                        });
                    }
                }
            } :
            {
                title: "",
                key: "resolveId",
                width: "0%",
                hideFilter: true,
                onCreatedCell: (td, cellData) => {
                    td.innerHTML = "";
                }
            },
        {
            title: "",
            key: "resolveId",
            width: "1%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                var complaintCell = complaintsList.find(i => i.id == cellData);
                if (complaintCell.statusId == constants.complaintStatus.Cancelled || complaintCell.statusId == constants.complaintStatus.Closed) {
                    td.innerHTML = ""
                }
                else {
                    if (complaintCell.createUserId == user.userData.userId) {
                        td.innerHTML = `<div style="text-align: center;" class="tooltip clickable" id="cancelBtn"><i style = "font-size: medium;" class="icon icon-trashcan clickable"></i><span class="message top-end">Cancel</span></div>`;
                        td.querySelector("#cancelBtn").addEventListener('click', () => {
                            setCancel(true);
                            openWarningDialog(true);
                            dispatch({ type: "SET_ADDED_COMPLAINT", payload: complaintsList.find(i => i.id == cellData) });
                        });
                    }
                    else {
                        td.innerHTML = "";
                    }
                }
            }
        },
        {
            title: "", key: "logInfo", width: "1%", hideFilter: true,
            onCreatedCell: (td, cellData) => { td.innerHTML = `<div id="complaintslogtooltip" style="text-align: center;" class="tooltip"><i id="complaintsloginfo_" style= "font-weight: bold;" class="icon icon-info"></i><span style= "text-align: left" class="message top-end" id="complaintslogInfoMessage">${cellData}</span></div>` }
        },
    ]

    useEffect(() => {
        if ((!complaintsList || complaintsList.length == 0) && user.userData.jobRole !== constants.userRoles.ASP_PROJECT_MANAGER) {
            dispatch({ type: "SET_IS_LOADING", payload: true });
            ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                dispatch({ type: "SET_IS_LOADING", payload: false });
            }).catch(error => {
                dispatch({ type: "SET_IS_LOADING", payload: false });
            })
        }
        if ((!complaintsList || complaintsList.length == 0) && user.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER) {
            dispatch({ type: "SET_IS_LOADING", payload: true });
            var userAspCompaniesList = user.userDetail?.userAspCompanies.map(i => parseInt(i.aspCompanyId));
            if (userAspCompaniesList !== null || userAspCompaniesList.length !== 0) {
                ApiClient.post("UserAdmin/GetComplaintsByASPCompanyId", { userAspCompanies: userAspCompaniesList, createUserId: parseInt(user.userData.userId) }).then(response => {
                    dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                }).catch(error => {
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                })
            }
            else {
                ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                    dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                }).catch(error => {
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                })
            }
        }
        if (!sasToken) {
            SasHelper.getSasKey().then(response => {
                dispatch({ type: "SET_SAS_TOKEN", payload: response });
            })
        }
    }, []);

    const openComplaintDetails = (id) => {
        dispatch({ type: "SET_ADDED_COMPLAINT", payload: complaintsList.find(e => e.id == id) });
        dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.VIEW_ONLY });
    }

    const confirmCancelClose = () => {
        dispatch({ type: "SET_IS_LOADING", payload: true });
        if (cancel == true) {
            ApiClient.post("UserAdmin/ChangeComplaintStatus", { statusId: parseInt(constants.complaintStatus.Cancelled), createUserId: parseInt(user.userData.userId), complaintId: parseInt(complaint.id) }).then(response => {
                const notification = new Notification({
                    title: 'Complaint is cancelled successfully!',
                    timeout: 5000,
                    icon: 'icon-check'
                });
                notification.init();
                openWarningDialog(false);
                if (user.userData.jobRole !== constants.userRoles.ASP_PROJECT_MANAGER) {
                    ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                        dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                        dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                        dispatch({ type: "SET_IS_LOADING", payload: false });
                        setCancel(false);
                    }).catch(error => {
                        dispatch({ type: "SET_IS_LOADING", payload: false });
                    })
                }
                else {
                    var userAspCompaniesList = user.userDetail?.userAspCompanies.map(i => parseInt(i.aspCompanyId));
                    if (userAspCompaniesList != null || userAspCompaniesList.length != 0) {
                        ApiClient.post("UserAdmin/GetComplaintsByASPCompanyId", { userAspCompanies: userAspCompaniesList, createUserId: parseInt(user.userData.userId) }).then(response => {
                            dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                            dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                            dispatch({ type: "SET_IS_LOADING", payload: false });
                            setCancel(false);
                        }).catch(error => {
                            dispatch({ type: "SET_IS_LOADING", payload: false });
                        })
                    }
                    else {
                        ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                            dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                            dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                            dispatch({ type: "SET_IS_LOADING", payload: false });
                            setCancel(false);
                        }).catch(error => {
                            dispatch({ type: "SET_IS_LOADING", payload: false });
                        })
                    }
                }
            }).catch(error => {
                const notification = new Notification({
                    title: 'An error occurred while sending the acknowledgment!',
                    timeout: 5000,
                    icon: 'icon-circle-warning'
                });
                notification.init();
                dispatch({ type: "SET_IS_LOADING", payload: false });
                openWarningDialog(false);
                setCancel(false);
                dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
            });
        }
        else if (close == true) {
            ApiClient.post("UserAdmin/ChangeComplaintStatus", { statusId: parseInt(constants.complaintStatus.Closed), createUserId: parseInt(user.userData.userId), complaintId: parseInt(complaint.id) }).then(response => {
                const notification = new Notification({
                    title: 'Complaint is closed successfully!',
                    timeout: 5000,
                    icon: 'icon-check'
                });
                notification.init();
                openWarningDialog(false);
                if (user.userData.jobRole != constants.userRoles.ASP_PROJECT_MANAGER) {
                    ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                        dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                        dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                        dispatch({ type: "SET_IS_LOADING", payload: false });
                        setClose(false);
                    }).catch(error => {
                        dispatch({ type: "SET_IS_LOADING", payload: false });
                    })
                }
                else {
                    var userAspCompaniesList = user.userDetail?.userAspCompanies.map(i => parseInt(i.aspCompanyId));
                    if (userAspCompaniesList != null || userAspCompaniesList.length != 0) {
                        ApiClient.post("UserAdmin/GetComplaintsByASPCompanyId", { userAspCompanies: userAspCompaniesList, createUserId: parseInt(user.userData.userId) }).then(response => {
                            dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                            dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                            dispatch({ type: "SET_IS_LOADING", payload: false });
                            setClose(false);
                        }).catch(error => {
                            dispatch({ type: "SET_IS_LOADING", payload: false });
                        })
                    }
                    else {
                        ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                            dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                            dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                            dispatch({ type: "SET_IS_LOADING", payload: false });
                            setClose(false);
                        }).catch(error => {
                            dispatch({ type: "SET_IS_LOADING", payload: false });
                        })
                    }
                }
            }).catch(error => {
                const notification = new Notification({
                    title: 'An error occurred while sending the acknowledgment!',
                    timeout: 5000,
                    icon: 'icon-circle-warning'
                });
                notification.init();
                openWarningDialog(false);
                dispatch({ type: "SET_IS_LOADING", payload: false });
                dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                setClose(false);
            });
        }
    }

    return (<EuiRow key={isLoading} disabled={isLoading ? "disabled" : ""}>
        <EuiTile>
            <EuiRowWithToolbar
                toolBarStyle={{ backgroundColor: "#FAFAFA" }}
                leftIcons={<button hidden={user.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER || user.userData.jobRole == constants.userRoles.REPORTER}
                    onClick={() => {
                        dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.ADD });
                    }}
                    className="btn primary">
                    Raise Complaint
                </button>} />
            <ObseleteEuiTable
                id="ComplaintsListId"
                classes="tiny"
                columns={complaintsColumns}
                key={complaintsList}
                data={complaintsList}
                paginated={true}
                rowsPerPage={10}
                sortable={true}
                filter={true}
                exportExcel={true}
                Reportdata={complaintsList.map(i => {
                    return {
                        ID: i.id,
                        ComplaintScope: i.complaintScopeName,
                        ComplaintType: i.complaintTypeName,
                        AspCompany: i.aspCompanyName,
                        AspResource: i.complainedAboutUserName,
                        Details: i.complaintContent,
                        ComplaintStatus: i.complaintStatusName,
                        CreatedUser: i.createdUserName,
                        CreateTime: i.createDateTime,
                        ModifiedUser: i.modifiedUserName,
                        ModifiedTime: i.modifiedDateTime,
                        AcknowledgedUser: i.acknowledgedUserName,
                        AcknowledgmentTime: i.acknowledgedDateTime,
                        ResolvedUser: i.resolvedUserName,
                        ResolutionTime: i.resolvedDateTime,
                        complaintRecipients: i.complaintRelatedPeopleView?.map(i => i.text)?.join(", ")
                    }
                })}
                excelFileName={"ASP Complaints"}
            />
        </EuiTile>
        {/*Here we put basic HTML*/}
        { /*Layout must be row -> tile -> compononent*/}
        <EuiLoading id="complaintsLoading" isLoading={isLoading} />
        <DialogModal
            id="CancelCloseComplaintId"
            title={close == true ? "Close Complaint" : "Cancel Complaint"}
            content={<div>
                <p >
                    Are you sure you want to {close == true ? "close " : "cancel "} this complaint?
                </p></div>}
            key={warningDialog}
            open={warningDialog}
            buttonName="Confirm"
            handleClose={() => {
                openWarningDialog(false);
                dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                setCancel(false);
                setClose(false);
            }}
            onClick={() => {
                confirmCancelClose();
            }
            }
            disabled={isLoading}
            mainButtonStyle={{ backgroundColor: "red", borderColor: "red" }}
            contentStyle={{ width: "400px", height: "80px" }}
            closeButtonVisible="false"

        />
    </EuiRow>)
}
export default withLayout(ComplaintsList);