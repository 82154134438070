import React from "react";
import EuiMultiPanelTile from "../../eui-components/multipaneltile";



class MenuMultiPanelTile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentItem: { id: this.props.selectedItem || 1 },
        }
    }

    componentDidMount() {
        if (this.props.selectedItem)
            this.setState({ currentItem: this.props.menuList[this.props.selectedItem - 1] });
        else
            this.setState({ currentItem: this.props.menuList[0] });
    }

    componentDidUpdate(prevProps, prevState)
    {
        if(prevState.currentItem?.id != this.state.currentItem?.id && this.props.currentItemUpdated)
        {
            this.props.currentItemUpdated(this.state.currentItem.id);
        }
    }

    getLeftPanel = () => {
        const content = (this.props.menuList ?? []).map(item => {
            if (item.hidden) {
                return null;
            }
            return <div key={item.id} onClick={() => {
                this.setState({ currentItem: { ...item } }, () => {
                    item.onClick && item.onClick()
                })
            }
            } className={this.getClassName(item)} >
                <div class="menu-item" style={{ display: "flex", alignItems: "center" }} tabindex="0">{item.icon}{item.title}</div>
            </div>
        });
        const leftPanel = {
            title: "", content: <div>{content}</div>
        }
        return leftPanel;
    }

    getMidPanel = () => {
        const currentItem = this.state.currentItem;
        const midPanel = {
            title: currentItem.title,
            subtitle: currentItem.subtitle,
            content: currentItem.content,
            icon: "icon-nav",
            trigger: currentItem.trigger,
            rightIcon: currentItem.rightIcon
        };
        return midPanel;
    }

    getRightPanel = () => {
        const currentItem = this.state.currentItem;
        if (!currentItem.rightPanel) {
            return null;
        }
        return { title: currentItem.rightPanel.title, content: currentItem.rightPanel.content };
    }

    getClassName = (item) => {
        let className = this.state.currentItem.id === item.id ? "selected-item" : "";
        className += item.disabled ? " disabled" : "";
        return className;
    }

    getMidPanelButtonState = () => {
        const currentItem = this.state.currentItem;
        if (!currentItem.midPanelButton) {
            return null;
        }
        return currentItem.midPanelButton;
    }

    getButtonName = () => {
        const currentItem = this.state.currentItem;
        if (!currentItem.midPanelButton) {
            return null;
        }
        return currentItem.button;
    }

    render() {
        return (<EuiMultiPanelTile id={this.props.id}
            leftPanel={this.getLeftPanel()}
            midPanel={this.getMidPanel()}
            rightPanel={this.getRightPanel()}
            midPanelButton={this.getMidPanelButtonState()}
            button={this.getButtonName()}
            style={{ paddingRight: 18 }}
        />)
    }
}

export default MenuMultiPanelTile;