import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../../eui-components/row";
import EuiTile from "../../../../eui-components/tile";
import PageHelper from "../../../../utils/page-helper";
import EuiAccordion from "../../../../eui-components/accordion";
import UserInfo from "./userInfo";
import ObseleteEuiTable from "../../../../eui-components/obseleteTable";
import DateUtils from "../../../../utils/date-utils";
import DocumentDialogModal from "../../../../components/documentDialogModal";

class ResourcesContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            certificationColumns:
                [
                    {
                        title: "Certificate Type",
                        key: "isEricssonCertificate",
                        onCreatedCell: (td, cellData) => {
                            const icon = PageHelper.renderCertificateIcon(cellData);                          
                            td.innerHTML = `${icon}`;
                            td.style.fontSize = "20px";
                        },
                        width: "5%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Course Number",
                        key: "certificateIdentity",
                        width: "15%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Course Name",
                        key: "certificateName",
                        width: "20%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Date Taken",
                        key: "certificateDateTaken",
                        onCreatedCell: (td, cellData) => {
                            const date = cellData ? DateUtils.toDateString(cellData) : ""
                            td.innerHTML = `${date}`;
                        },
                        width: "10%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Expiry Date",
                        key: "certificateExpiryDate",
                        onCreatedCell: (td, cellData) => {
                            const date = cellData ? DateUtils.toDateString(cellData) : ""
                            td.innerHTML = `${date}`;
                        },
                        width: "10%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Certificate Category",
                        key: "isMandatory",
                        onCreatedCell: (td, cellData) => {
                            const icon = PageHelper.renderMandatory(cellData);
                            td.innerHTML = `${icon}`;
                            td.style.fontSize = "13px";
                        },
                        width: "10%",
                        headerStyle: "padding:1px;",
                        sort: "desc"
                    },
                    {
                        title: "Status",
                        key: "certificateStatus",
                        onCreatedCell: (td, cellData) => {
                            const icon = PageHelper.renderVerification(cellData);
                            td.innerHTML = `${icon}`;
                            td.style.fontSize = "13px";
                        },
                        width: "10%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Actions",
                        key: "certificateLink",
                        onCreatedCell: (td, cellData) => {
                            
                            if (cellData?.documentPath) {
                                const icon = `<a id="showUsersId" className="m-1">Show Certificate</a>`;
                                td.innerHTML = `${icon}`;
                                td.querySelector("#showUsersId").addEventListener('click',
                                    (e) => this.setState({ certificateLink: cellData }, () => { this.setState({ openDialog: true });}));
                            } else {
                                td.innerHTML = null;
                            }
                        },
                        width: "10%",
                        headerStyle: "padding:1px;"
                    },
                ],
            openDialog: false,
            certificateLink: {}
        }
    }

    componentDidMount() {
    }

    renderResources = () => {
        if (!this.props.liveStreamData.teamsLogInfo.resourceVerificationLogs || this.props.liveStreamData.teamsLogInfo.resourceVerificationLogs.length === 0) {
            return null;
        }
        const resources = this.props.liveStreamData.teamsLogInfo.resourceVerificationLogs.map(item => {
            const roles = item.roles ? item.roles.map(roleItem => {
                const role = {
                    title: roleItem.job.name,
                    titleIcon: PageHelper.getVerificationIcon(roleItem.status, "text-sm"),
                    titleStyle: { fontWeight: 500 },
                    content: <ObseleteEuiTable
                        classes="very-tiny"
                        columns={this.state.certificationColumns}
                        data={roleItem.certificates}
                        hideTableInfo={true}
                        id={"certificateTable_" + item.user.value + roleItem.job.id}
                        scroll={true}
                        sortable={true}
                        styleHeader={{ fontSize: 20 }}
                    />
                };
                return role;
            }) : []
            const res = {
                title: item.user.text,
                titleIcon: PageHelper.getVerificationIcon(item.status, "text-lg"),
                titleStyle: { paddingLeft: 0, fontWeight: 500, fontSize: 16 },
                content: <EuiAccordion id={"resourcesAccordion_" + item.user.value} style={{ width: "100%" }} items={[
                    {
                        title: "User Details",
                        titleIcon: <i className="icon icon-info m-1 text-sm icon-normal-weight"></i>,
                        titleStyle: { fontWeight: 500 },
                        content: <UserInfo currentRow={item} />
                    },
                    ...(roles)
                ]}>
                </EuiAccordion>
            };
            return res;
        });

        return <EuiAccordion id={"resourcesAccordion"} style={{ width: "100%" }} items={resources}></EuiAccordion>
    }

    render() {
        return (<EuiRow>
            <EuiTile>
                {this.renderResources()}
            </EuiTile>
            {
                //<DialogModal
                //    content={<div className="document-container">
                //        <img className="responsive-document fit-image"
                //            alt={this.state.certificateLink && this.state.certificateLink.split('/').pop()}
                //            src={this.state.certificateLink}
                //            onClick={() => window.open(this.state.certificateLink, "_blank")}
                //        ></img>                   
                //    </div>}
                //    handleClose={() => this.setState({ openDialog: false })}
                //    id="certificateDialog"
                //    open={this.state.openDialog}
                //    buttonsVisible="false"
                //    fullWidth={true}
                //>
                //</DialogModal>
            }

            <DocumentDialogModal openDialogPreview={this.state.openDialog}
                handleClose={() => this.setState({ openDialog: false })}
                id="certificateDialog"
                showLocation={false}
                selectedDocument={this.state.certificateLink}
            >
            </DocumentDialogModal>

        </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourcesContent);
