import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";

class MainHeader extends React.Component {
   

    render() {
        return (
            <EuiRow style={{display:"flex", marginLeft:10}}>
                <div className="sm-6 md-3 lg-3 xl-3" style={{ fontWeight: 700, padding: 10, fontSize: "1em", marginRight:70}}>Document Name</div>
                <div className="sm-6 md-1 lg-1 xl-1" style={{ fontWeight: 700, padding: 10, fontSize: "1em" }}>Status</div>
                <div className="sm-6 md-1 lg-1 xl-1" style={{ fontWeight: 700, padding: 10, fontSize: "1em"}}>Severity Level</div>
                <div className="sm-6 md-2 lg-2 xl-2" style={{ fontWeight: 700, padding: 10, fontSize: "1em" }}>Customer Comments</div>
                <div className="sm-6 md-2 lg-2 xl-2" style={{ fontWeight: 700, padding: 10, fontSize: "1em" }}>Ericsson Comments</div>
                <div className="sm-6 md-1 lg-1 xl-1" style={{ fontWeight: 700, padding: 10, fontSize: "1em" }}>Updated Date</div>
                <div className="sm-6 md-1 lg-1 xl-1" style={{ fontWeight: 700, padding: 10, fontSize: "1em" }}>Updated User</div>
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainHeader);
