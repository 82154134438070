import React, { useState, useEffect } from "react";
import ApiClient from "../../utils/api-client";
import { useSelector } from "react-redux";
import PageHelper from "../../utils/page-helper";
import EuiLoading from "../../eui-components/loading";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiRow from "../../eui-components/row";
import _ from "lodash";
import DateUtils from "../../utils/date-utils";
import SasHelper from "../../utils/sas-helper";

const SiteDocuments = () => {
    const site = useSelector(state => state.site.site);
    const [siteDocuments, setSiteDocuments] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [SASToken, setSASToken] = useState("");
    useEffect(() => {
        if (siteDocuments.length == 0) {
            setIsLoading(true);
            ApiClient.post("Workplan/GetSiteDocuments", { id: parseInt(site.id) }).then(response => {
                setIsLoading(false)
                setSiteDocuments(response?.data?.data);
                setSASToken(response?.data?.sasToken);
            }).catch(error => console.error(error.response?.data))
        }
    }
        , [site]);
    const sortByKey = (array, key) => {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];

            if (typeof x == "string") {
                x = ("" + x).toLowerCase();
            }
            if (typeof y == "string") {
                y = ("" + y).toLowerCase();
            }

            return ((x < y) ? 1 : ((x > y) ? -1 : 0));
        });
    }
    const viewSiteDocuments = () => {
        let siteDocumentsData = []
        var siteDocumentsColumns = [{
            title: "Document Name",
            key: "name",
            width: "15%",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                const cellDataName = cellData[0];
                const cellDataLink = cellData[1];
                td.innerHTML = `<a id="link_"> ${cellDataName}</a>`;
                td.querySelector("#link_").addEventListener('click', () => window.open(SasHelper.addSasKey(cellDataLink , SASToken), "_blank"));
            },
        },
            {
                title: "Document Type",
                key: "documentType",
                sort: "none",
                width: "10%",
            },{
            title: "Project Name",
            key: "projectName",
            width: "15%",
            sort: "none"
        }, {
                title: "Workplan Name",
                key: "workplanName",
                width: "15%",
                sort: "none"
            },
            {
                title: "Activity Name",
                key: "activityName",
                width: "10%",
                sort: "none"
            },

            {
                title: "Document location",
                key: "location",
                width: "10%",
                sort: "none",
                onCreatedCell: (td, cellData) => {
                    if (cellData == "Workplan") {
                        td.innerHTML = `<span style = "background-color: rgb(255 229 229); color: black; " class="pill toggle">${cellData}</span>`
                    }
                    else {
                        td.innerHTML = `<span style = "background-color: rgb(223 236 251); color: black; " class="pill toggle">${cellData}</span>`
                    }
                }
            },
            {
                title: "Modified Date",
                key: "lastModifiedDate",
                width: "10%",
                sort: 'none',
                onCreatedCell: (td, cellData) => {
                    td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm");
                }
            },
            {
                title: "Modified User",
                key: "lastModifiedUser",
                width: "7%",
                onCreatedCell: (td, cellData) => {
                    td.innerHTML =`<span id="user_" style= "margin: 35px; text-align: center;" class="tooltip"><i style = "font-size: 20px" id="user_" class="icon icon-info text-lg clickable color-grey "></i><span class="message top-end">${cellData}</span></span>`;
                }
            }];
        siteDocuments.map((workplan) => {
            workplan.workplanDocuments.map(workplanDocument => {
                siteDocumentsData.push({
                    workplanName: workplan.workplanName,
                    id: workplanDocument.id,
                    documentType: PageHelper.mapDocumentTypes(workplanDocument.documentType),
                    name: [workplanDocument.title, workplanDocument.documentPath],
                    lastModifiedDate: workplanDocument.updatedAt,
                    lastModifiedUser: workplanDocument.lastModifiedUserName,
                    projectName: workplan.projectName,
                    location: "Workplan"

                })
            })
            workplan.workplanActivityDocuments.map(activityDocument => {
                activityDocument.activityDocuments.map(document => {
                    siteDocumentsData.push({
                        workplanName: workplan.workplanName,
                        id: document.id,
                        documentType: PageHelper.mapDocumentTypes(document.documentType),
                        name: [document.title, document.documentPath],
                        lastModifiedDate: document.updatedAt,
                        lastModifiedUser: document.lastModifiedUserName,
                        activityName: activityDocument.activityName,
                        projectName: workplan.projectName,
                        location: "Activity"
                    })
                })
            });
        });
        let sortedSiteDocuments = sortByKey(siteDocumentsData, 'lastModifiedDate');
        return (siteDocumentsData.length > 0 ? <ObseleteEuiTable
            classes="tiny"
            style={{ overflow: 'hidden' }}
            columns={siteDocumentsColumns}
            data={sortedSiteDocuments}
            id={"siteDocumentsTable"}
            key={siteDocuments}
            styleHeader={{ width: "100%" }}
            styleTd={{ width: "100%" }}
            scroll={false}
            sortable={true}
            paginated={true}
            rowsPerPage={10}
            filter={true}
        /> : <EuiRow className="flex-middle-content w-10">No documents attached to this site...</EuiRow>);

    }
    return (<EuiRow disabled={ isLoading? "disabled" : ""} key={siteDocuments}>
        <EuiLoading id="sitesDocumentsLoading" isLoading={isLoading} />
        {(siteDocuments.length > 0 && SASToken.length > 0 ) ?
            viewSiteDocuments() : null}</EuiRow>)
}
export default SiteDocuments;