import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import constants from "../../utils/constants";
import TicketList from "./ticketList";
import TicketDetails from "./ticketDetails";
import EuiRow from "../../eui-components/row";
import EuiLoading from "../../eui-components/loading";
import ApiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import "../../css/ticketingStyle.css"
import SasHelper from "../../utils/sas-helper";

class Tickets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }

        if ((this.props.ticketData.classficationList || []).length == 0) {
            this.getClassificationList();
        }
        if (!this.props.ticketData.sasToken) {
            this.generateSasToken();
        }
    }

    getClassificationList = () => {
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Ticketing/GetTicketClassification", {})
                .then(response => {
                    this.props.setTicketClassificationList(response.data);
                    this.setState({ isLoading: false });

                    
                    
                })
                .catch(error => {
                    
                    this.setState({ isLoading: false });
                    toast.error("An error occured, try again later!", { containerId: 'TL', position: toast.POSITION.TOP_CENTER });
                })
        });
    }

    generateSasToken = () => {
        this.setState({ isLoading: true }, () => {
            
            SasHelper.getSasKey().then((response) => {
                this.props.setSasToken(response);
                this.setState({ isLoading: false });
            })
            .catch(error => {
                
                this.setState({ isLoading: false });
                toast.error("An error occured, try again later!", { containerId: 'TL', position: toast.POSITION.TOP_CENTER });
            });
        });
    }

    renderMod = () => {

        if (this.props.ticketData.mod === constants.pageMods.EDIT) {
            return <TicketDetails />
        } else {
            return <TicketList key={this.props.ticketData.tickets.initialState} />;
        }
    }

    render() {
        return (
            <EuiRow disabled={this.state.isLoading ? "disabled" : ""}>
                {
                    this.renderMod()
                }

                <EuiLoading size="large" id="ticketIndexLoading" isLoading={this.state.isLoading} />
            </EuiRow>

        );
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ticketData: state.ticket });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setTicketClassificationList: (classificationList) => dispatch({ type: "SET_CLASSIFICATION_LIST", payload: classificationList }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(Tickets)));