import React from "react";
import EuiRow from "../../../eui-components/row";
import EuiMultiPanelTile from "../../../eui-components/multipaneltile";
import { useSelector } from "react-redux";
import EhsDashboardData from "./ehsDashboard";
import EhsDashboardFilter from "./ehsDashboardFilter";

const EhsDashboardPanels = (props) => {
    const ehsDashboardData = useSelector(state => state.ehsDashboard);
    const incentives = ehsDashboardData.kpis || [];
    const leftPanel = {
        title: "Filter By", content: <EhsDashboardFilter />,
        style: { width: "400px" }
    };
    const midPanel = {
        title: <div className="flex-start-content "><div className="mr-2" style={{ color: "gray", fontWeight: 100 }}>
            {(!ehsDashboardData.dateSelected?.startDate || !ehsDashboardData?.dateSelected?.endDate ? "" : new Date(ehsDashboardData?.dateSelected?.startDate).toDateString() + "-" + new Date(ehsDashboardData?.dateSelected?.endDate).toDateString())}
        </div> </div>,
        content: <EhsDashboardData data={incentives} />,
    };
    return (<EuiRow style={{ zoom: "0.9" }}>
        <EuiMultiPanelTile id="ehsDashbordMultiPanel"
            midPanelButton={true}
            leftPanel={leftPanel}
            midPanel={midPanel}
            leftPanelStyle={{ width: "400px" }}
        />
    </EuiRow>);
}
export default EhsDashboardPanels;