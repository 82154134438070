import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import EuiTextField from "../../../eui-components/textfield";
import Table from "../../../components/table/index";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { ToastContainer, toast } from 'react-toastify';
import PageHelper from "../../../utils/page-helper";


class CreateEditSubActivity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            subActivity: {
                name: "",
                activityId: 0,
                activityName: "",
                createDate: null,
                activityDocuments: [],
                sessionAcceptance: 1,
                latitude: "",
                longitude: ""
            },
            documentPath: {},
            documentColumns: [
                { title: 'Document Name', field: 'name' },
            ],
            selectedFileList: [],
            tableRef: React.createRef()
        }
    }
    componentDidMount() {
        if (this.props.subActivity) {
            this.setState({ subActivity: Object.assign(this.state.subActivity, this.props.subActivity) });
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState(prevState => ({
            subActivity: {
                ...prevState.subActivity,
                [name]: value
            }
        }), () => this.props.getNewSubActivity(this.state.subActivity));
    }

    deleteSelectedDocuments = () => {
        if (this.state.selectedFileList.length == 0) {
            toast.error("Please, select a document to delete!", { position: toast.POSITION.TOP_CENTER });
            return;
        }

        const selectedDocumentNames = this.state.selectedFileList.map(item => item.name);
        const oldDocuments = [...this.state.subActivity.activityDocuments.filter(item => !selectedDocumentNames.includes(item.name))];

        this.setState(prevState => ({
            subActivity: {
                ...prevState.subActivity,
                activityDocuments: oldDocuments
            }
        }), () => this.props.getNewSubActivity(this.state.subActivity));
    }

    handleFiles = (e) => {
        const files = [...e.target.files];

        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            toast.error(fileSize, { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            return;
        }

        this.setState(prevState => ({
            subActivity: {
                ...prevState.subActivity,
                activityDocuments: files
            }
        }), () => this.props.getNewSubActivity(this.state.subActivity));
    };

    render() {
        return (
            <EuiRow>
                <EuiTextField style={{ marginBottom: 10, marginLeft: 10 , width: "80%"}}
                    label="Item Name"
                    labelClass="left"
                    name="name"
                    className="w-10"
                    required
                    value={this.state.subActivity.name || ""}
                    onChange={e => this.handleChange(e)}
                />

                <Table
                    title="Documents"
                    columns={this.state.documentColumns}
                    data={this.state.subActivity.activityDocuments}
                    isLoading={this.state.isLoading}
                    options={{
                        search: false,
                        selection: true,
                        showTextRowsSelected: false,
                        minBodyHeight: 300,
                        paging: false,
                        selectionProps: rowData =>
                            ({
                                color: "primary",
                                style: {
                                    padding: 3,
                                    marginLeft: 17
                                }
                            })
                    }}
                    onSelectionChange={(rows) => this.setState({ selectedFileList: rows })}
                    tableRef={this.state.tableRef}
                    toolbar={{
                        custom:
                            <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
                                <Tooltip id="addFile" title="Add Document">
                                    <label htmlFor="uploadDocument">
                                        <input
                                            style={{ display: "none" }}
                                            id="uploadDocument"
                                            name="documentPath"
                                            type="file"
                                            multiple
                                            onChange={e => this.handleFiles(e)}
                                        />
                                        <AddIcon />
                                    </label>
                                </Tooltip>
                                <Tooltip id="deleteFile" title="Delete Document">
                                    <IconButton aria-label="delete" id="subActivityFileDeleteButton" onClick={this.deleteSelectedDocuments}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                    }}
                >
                </Table>

                <ToastContainer enableMultiContainer containerId={'C'} />
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEditSubActivity);
