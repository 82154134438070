import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default class DialogModal extends React.Component {
    render() {
        return (
            <div>
                <Dialog id={this.props.id}
                    open={this.props.open}
                    onClose={() => this.props.handleClose()}
                    aria-labelledby="form-dialog-title"
                    fullWidth={this.props.fullWidth}
                    fullScreen={this.props.fullScreen}
                    disableBackdropClick={true}
                >
                    <div style={{ backgroundColor: document.body.className === "light" ? '#fafafa' : '#242424', ...this.props.customStyle }}>
                        <div style={{ display: "flex", alignItems: "baseline" }}>
                            <div style={{ width: "80%", justifyContent: "flex-start", }}>
                                <DialogTitle>{<div style={this.props.titleStyle}>{this.props.title}</div>}</DialogTitle>
                            </div>
                            <div style={{ width: "20%", display: "flex", justifyContent: "flex-end", paddingRight: 20 }}>
                                {this.props.closeButtonVisible && this.props.closeButtonVisible == "false" ?
                                    null
                                    :
                                    <IconButton edge="end" color="inherit" onClick={() => this.props.handleClose()} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                }
                            </div>
                        </div>
                        <DialogContent style={this.props.contentStyle}>
                            {this.props.content}
                        </DialogContent>
                        <DialogActions style={this.props.actionStyle}>
                            {this.props.buttonsVisible === "false" ? null :
                                !this.props.buttonsSeperate ?
                                    <div className="m-1">
                                        <button type="button" className={"btn ghost m-1 "} style={this.props.buttonsStyle} onClick={() => this.props.handleClose()}>{this.props.cancelButton ?? "Cancel"}</button>
                                        <button type="button" disabled={this.props.disabled}  className={"btn m-1 " + (this.props.type ?? " primary ")} style={(this.props.mainButtonStyle ? this.props.mainButtonStyle : this.props.buttonsStyle)} onClick={() => this.props.onClick && this.props.onClick()} >{this.props.buttonName}</button>
                                    </div>

                                    :
                                    <div className="flex-start-content m-2 mr-3 ml-3 w-10">
                                        <button type="button" className="btn ghost m-1" style={this.props.buttonsStyle} onClick={() => this.props.handleClose()}>{this.props.cancelButton ?? "Cancel"}</button>
                                        <div className="flex-end-content w-10 pr-1">
                                            <button type="button"  
                                            disabled={this.props.disabled} 
                                            className={"btn m-1 " + (this.props.type ?? "primary")} style={(this.props.mainButtonStyle ? this.props.mainButtonStyle : this.props.buttonsStyle)} onClick={() => this.props.onClick && this.props.onClick()} >{this.props.buttonName}</button>
                                        </div>
                                    </div>
                            }
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        );
    }
}

