const VehicleReducer = (state, action) => {
    switch (action.type) {
        case "HANDLE_VEHICLE_CHANGE":
            return {
                ...state,
                [action.field]: action.payload,
            }
        case "SET_VEHICLE":
            return {
                ...action.payload,
            }
        default:
            return state;
    }
};

export default VehicleReducer;