import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../../layout";
import EuiRow from "../../../eui-components/row";
import CreateEditWorkflow from "./createEditWorkflow";
import Table from "../../../components/table";
import ApiClient from "../../../utils/api-client";
import DialogModal from "../../../components/dialog";
import { ToastContainer, toast } from "react-toastify";
import constants from "../../../utils/constants";
import WorkflowHelper from "../../../utils/workflow-helper";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import LoopIcon from '@material-ui/icons/Loop';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import WorkflowStepsMailDialog from "./workflowStepsMailDialog";
import EuiTile from "../../../eui-components/tile";

class MyWorkflows extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            mod: 0,
            workflowColumns: [
                {
                    title: "Workflow ID",
                    field: "id",
                    width: "5%"
                },
                {
                    title: "Workflow Name",
                    field: "name",
                    width: "15%"
                },
                {
                    title: "Description",
                    field: "description",
                    width: "30%"
                },
                {
                    title: "Country",
                    field: "countryId",
                    render: (rowData) => rowData.countryId.text
                },
                {
                    title: "Customer",
                    field: "customerId",
                    render: (rowData) => rowData.customerId.text
                },
                {
                    title: "Default",
                    field: "default",
                    render: (rowData) => rowData.default ? "Yes" : "No",
                    width: "5%"
                },
                {
                    title: "Actions",
                    render: (rowData) => this.renderWorkflowActions(rowData),
                    width: "10%"
                },
                {
                    title: "Status",
                    field: "status",
                    render: (rowData) => WorkflowHelper.renderWorkflowStatus(rowData.status),
                    lookup: constants.workflowStatus
                },

            ],
            workflows: [],
            currentWorkflow: { id: 0 },
            editMod: 0,
            openDialogDelete: false
        };
    }
    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        this.setState({ isLoading: true }, () => {
            this.getWorkflows();
        });
    }

    getWorkflows = () => {
        ApiClient.post("Workflow/GetWorkflowsOfUser", { id: parseInt(this.props.userData.userData.id) })
            .then(response => {
                if (response.data && response.data.length > 0) {
                    const workflows = [...response.data];
                    
                    workflows.map(item => {
                        item.customerId = { value: item.customer.id, text: item.customer.name };
                        item.countryId = { value: item.country.id, text: item.country.name };
                    });
                    this.setState({ workflows }, () => this.setState({ isLoading: false }));
                } else {
                    this.setState({ isLoading: false });
                }
            })
            .catch(error => {
                this.setState({ isLoading: false });
                console.error(error);;
            })
    }

    openWorkflow = (mod, rowData) => {
        this.setState({ editMod: mod, mod: 1, currentWorkflow: (rowData ?? { id: 0 }) });
    }

    deleteWorkflow = () => {
        this.setState({ isLoading: true, openDialogDelete: false }, () => {
            ApiClient.post("Workflow/DeleteWorkflow", { id: this.state.currentWorkflow.id, updateUser: this.props.userData.userData.id })
                .then(response => {
                    if (response.data) {
                        this.setState({ isLoading: false },
                            () => {
                                const workflows = [...this.state.workflows];
                                workflows.splice(workflows.indexOf(this.state.currentWorkflow), 1);
                                this.setState({ workflows });
                                toast.success("Selected Workflow deleted succesfully", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
                            });
                    } else {
                        this.setState({ isLoading: false },
                            () => toast.error("Some problems occred! Please try later", { containerId: 'WL', position: toast.POSITION.TOP_CENTER }));
                    }

                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                })
        });
    }

    setIsLoading = () => {
        this.setState({ isLoading: !this.state.isLoading });
    }

    changeStatus = (status, rowData) => {
        const workflow = { ...rowData };
        workflow.status = status;
        workflow.updateUser = this.props.userData.userData.userId;
        workflow.updateDate = new Date();
        workflow.countryId = workflow.countryId.value;
        workflow.customerId = workflow.customerId.value;

        

        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workflow/UpdateWorkflow", workflow)
                .then(response => {
                    if (response.data) {
                        this.setState({ isLoading: false },
                            () => {
                                this.getWorkflows();
                                toast.success("Selected Workflow status changed succesfully", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
                            });
                    } else {
                        this.setState({ isLoading: false },
                            () => toast.error("Some problems occred! Please try later", { containerId: 'WL', position: toast.POSITION.TOP_CENTER }));
                    }

                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                })
        });
    }

    renderWorkflowActions = (rowData) => {
        switch (rowData.status) {
            case 1:
                return <span>
                    <Tooltip title="Edit Workflow">
                        <IconButton aria-label="edit" onClick={() => this.openWorkflow(1, rowData)}>
                            <i className="icon icon-edit" style={{ fontSize: 20 }}></i>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Workflow">
                        <IconButton aria-label="delete" onClick={() => this.setState({ currentWorkflow: rowData }, () => { this.setState({ openDialogDelete: true }) })}>
                            <i className="icon icon-trashcan" style={{ fontSize: 20 }}></i>
                        </IconButton>
                    </Tooltip>
                </span>;
            case 2:
                return <span>
                    <Tooltip title="In Progress Workflow">
                        <IconButton aria-label="inProgress" onClick={() => this.changeStatus(3, rowData)}>
                            <LoopIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel Workflow">
                        <IconButton aria-label="cancel" onClick={() => this.changeStatus(4, rowData)}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Review Workflow">
                        <IconButton aria-label="review" onClick={() => this.openWorkflow(2, rowData)}>
                            <i className="icon icon-eye" style={{ fontSize: 20 }}></i>
                        </IconButton>
                    </Tooltip>
                </span>;
            case 3:
                return <span>
                    <Tooltip title="Release Workflow">
                        <IconButton aria-label="release" onClick={() => this.changeStatus(2, rowData)}>
                            <CheckIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Workflow">
                        <IconButton aria-label="edit" onClick={() => this.openWorkflow(1, rowData)}>
                            <i className="icon icon-edit" style={{ fontSize: 20 }}></i>
                        </IconButton>
                    </Tooltip>

                </span>;
            case 4:
                return <span>
                    <Tooltip title="Start Workflow">
                        <IconButton aria-label="start" onClick={() => this.changeStatus(2, rowData)}>
                            <i className="icon icon-video-play" style={{ fontSize: 20 }}></i>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Review Workflow">
                        <IconButton aria-label="review" onClick={() => this.openWorkflow(2, rowData)}>
                            <i className="icon icon-eye" style={{ fontSize: 20 }}></i>
                        </IconButton>
                    </Tooltip>
                </span>;
            default:
                return "";
        }
    }

    renderMod = () => {
        if (this.state.mod === 0) {
            return (
                <EuiRow>
                    <Table
                        actions={[
                            {
                                icon: "add",
                                tooltip: "Create Workflow",
                                onClick: (event, rowData) => this.openWorkflow(0, null),
                                isFreeAction: true
                            }
                        ]}
                        columns={this.state.workflowColumns}
                        data={this.state.workflows}
                        isLoading={this.state.isLoading}
                        options={{
                            pageSize: 10,
                            pageSizeOptions: [10],
                            cellStyle: { fontSize: "0.875rem", padding: 0, paddingLeft: 10 },
                            search: false,
                        }}
                    >
                    </Table>
                </EuiRow>
            );
        } else if (this.state.mod === 1) {
            return (<CreateEditWorkflow
                workflow={this.state.currentWorkflow}
                changeMod={() => this.setState({ mod: 0 })}
                editMod={this.state.editMod}
                setIsLoading={this.setIsLoading}
                getWorkflows={this.getWorkflows}
            />);
        }
    }

    render() {
        return (
            <>
                <EuiTile fullscreen tileStyle={{ padding: 0, paddingLeft: 5, paddingRight: 5 }}>{this.renderMod()}</EuiTile>

                <DialogModal
                    buttonName="Delete"
                    content={<p>Are you sure you want to delete <b>{this.state.currentWorkflow.name}</b> ?</p>}
                    handleClose={() => this.setState({ openDialogDelete: false })}
                    id="deleteWorkflowDialog"
                    onClick={this.deleteWorkflow}
                    open={this.state.openDialogDelete}
                    title="Delete"
                    type="warning"
                    titleId="deleteWorkflowTitle"
                >
                </DialogModal>

                <ToastContainer enableMultiContainer containerId={'WL'} autoClose={2500} />
            </>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(MyWorkflows)));
