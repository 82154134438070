import React, { forwardRef, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiTextField from "../../eui-components/textfield";
import constants from "../../utils/constants";
import EuiRow from "../../eui-components/row";
import EuiTile from "../../eui-components/tile";
import VehicleHelper from "../../utils/vehicle-helper";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import { useReducer } from "react";
import EuiNumberField from "../../eui-components/numberfield";
import EuiDatepicker from "../../eui-components/datepicker";
import CommonHelper from "../../utils/common-helper";
import ApiClient from "../../utils/api-client";
import { useImperativeHandle } from "react";
import EuiLoading from "../../eui-components/loading";
import UserHelper from "../../utils/user-helper";
import DateUtils from "../../utils/date-utils";
import EuiCheckbox from "../../eui-components/checkbox";
import VehicleReducer from "../asp-companies/vehicle-management/vehicleReducer";

const VehicleApprovalDetailsContent = forwardRef((props, ref) => {
const {setWarningDialog}=props;
    const [isLoading, changeIsLoading] = useState(false);
    const users = useSelector(state => state.user.users);
    const currentVehicle = useSelector(state => state.vehicle.currentVehicle);
    const mod = useSelector(state => state.vehicle.mod);
    const [vehicleReducer, dispatch] = useReducer(VehicleReducer, { ...currentVehicle });

    useEffect(() => {
        dispatch({ type: "SET_VEHICLE", payload: currentVehicle });
    }, [currentVehicle]);
  
    const getVehicleStatus = useCallback(() => {
        if (mod === constants.pageMods.VIEW_ONLY) {
            return VehicleHelper.vehicleHTMLStatus[vehicleReducer.vehicleStatus];
        }
    }, [currentVehicle.vehicleStatus]);
   

   
    const getUserModificationsSection = useCallback(() => {
        if (mod === constants.pageMods.VIEW_ONLY) {
            const createUser = UserHelper.getUser(users, vehicleReducer.createUserid);
            const submitUser = UserHelper.getUser(users, vehicleReducer.submitUserid);
            const approveUser = UserHelper.getUser(users, vehicleReducer.vehicleApprovedUserid);
      
            return <>
                <div className="tile-title" style={{ marginTop: 0 }}>User Modifications</div>
                <div className="content tile-bordered mt-2" style={{ paddingLeft: 2 }}>
                    <EuiRow>
                        <div className="column sm-12 md-6 lg-2 xl-2 filled">
                            <EuiTextField className="p-1"
                                fullwidth
                                readOnly={true}
                                id="vehicleCreateUserid"
                                name="vehicleCreateUser"
                                label="Created By"
                                value={createUser?.name}
                                additionalInfo={createUser?.mail}
                            />
                        </div>
                        <div className="column sm-12 md-6 lg-2 xl-2 filled">
                            <EuiTextField className="p-1"
                                fullwidth
                                readOnly={true}
                                id="vehicleCreateDatetimeUtcId"
                                name="createDatetimeUtc"
                                label="Created Datetime"
                                value={DateUtils.toDateString(vehicleReducer.createDatetimeUtc, "YYYY-MM-DD hh:mm")}
                            />
                        </div>
                        {submitUser &&
                            <>
                                <div className="column sm-12 md-6 lg-2 xl-2 filled">
                                    <EuiTextField className="p-1"
                                        fullwidth
                                        readOnly={true}
                                        id="vehicleSubmitUserid"
                                        name="vehicleSubmitUser"
                                        label="Submitted By"
                                        value={submitUser?.name}
                                        additionalInfo={submitUser?.mail}
                                    />
                                </div>
                                <div className="column sm-12 md-6 lg-2 xl-2 filled">
                                    <EuiTextField className="p-1"
                                        fullwidth
                                        readOnly={true}
                                        id="vehicleSubmitDatetimeUtcId"
                                        name="submitDatetimeUtc"
                                        label="Submitted Datetime"
                                        value={DateUtils.toDateString(vehicleReducer.submitDatetimeUtc, "YYYY-MM-DD hh:mm")}
                                    />
                                </div>
                            </>
                        }
                        {approveUser &&
                            <>
                                <div className="column sm-12 md-6 lg-2 xl-2 filled">
                                    <EuiTextField className="p-1"
                                        fullwidth
                                        readOnly={true}
                                        id="vehicleApprovedUserid"
                                        name="vehicleApprovedUser"
                                        label="Approved By"
                                        value={approveUser?.name}
                                        additionalInfo={approveUser?.mail}
                                    />
                                </div>
                                <div className="column sm-12 md-6 lg-2 xl-2 filled">
                                    <EuiTextField className="p-1"
                                        fullwidth
                                        readOnly={true}
                                        id="vehicleApprovedDatetimeUtcId"
                                        name="approvedDatetimeUtc"
                                        label="Approved Datetime"
                                        value={DateUtils.toDateString(vehicleReducer.approvedDatetimeUtc, "YYYY-MM-DD hh:mm")}
                                    />
                                </div>
                            </>
                        }
                        <div className="column sm-12 md-12 lg-12 xl-12 filled">
                            <EuiTextField className="p-1"
                                fullwidth
                                multiLine={true}
                                readOnly={true}
                                id="vehicleModifiedUserCommentId"
                                name="modifiedUserComment"
                                label="Modified User Comment"
                                value={vehicleReducer.modifiedUserComment}
                            />
                        </div>
                    </EuiRow>
                </div>
            </>
        }
    }, [mod]);

   
    return (
        <>
            <EuiTile title="Vehicle Make" contentClassName="tile-bordered" className={isLoading ? " disabled " : ""}>
                <EuiLoading id="vehicleDetailLoading" isLoading={isLoading} />
                <EuiRow>

                    <div className="column sm-12 md-4 lg-4 xl-4 filled">
                        {
                            getVehicleStatus()
                        }
                        <EuiAutocompleteSelect id="vehicleTypeSelect"
                            className="pl-1"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            name="vehicleType"
                            label={{ title: "Vehicle Type" }}
                            placeholder="---Select Vehicle Type---"
                            options={VehicleHelper.vehicleTypesOptions}
                            value={VehicleHelper.vehicleTypes[vehicleReducer.vehicleType] || vehicleReducer.vehicleType}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value, field: e.target.name })}
                            optionListStyle={{ width: "100%" }}
                            required
                        />
                        <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            style={{ padding: 3 }}
                            id="vehicleChassisNumberId"
                            inputClassName={"validation " + (VehicleHelper.isValidChassisNumber() ? " valid " : "")}
                            name="chassisNumber"
                            label="Chassis Number"
                            pattern="[A-Za-z0-9]+"
                            validationMessage={VehicleHelper.isValidChassisNumber() ? "Valid Chassis Number" : "Only numbers and letters can be used and have to be unique."}
                            value={vehicleReducer.chassisNumber || ""}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value?.replaceAll(/\s/g, '')?.toUpperCase(), field: e.target.name })}
                        />
                        <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            style={{ padding: 3 }}
                            id="registrationPlateNoId"
                            name="registrationPlateNo"
                            label="Registration Plate No"
                            value={vehicleReducer.registrationPlateNo || ""}
                            onChange={(e) => { dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value?.replaceAll(/\s/g, '')?.toUpperCase(), field: e.target.name }); }}
                        />
                        <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            style={{ padding: 3 }}
                            id="vehicleManufacturerNameId"
                            name="vehicleManufacturer"
                            label="Manufacturer"
                            value={vehicleReducer.vehicleManufacturer || ""}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value, field: e.target.name })}
                        />
                       <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly
                            style={{ padding: 3 ,color: vehicleReducer.isVehicleCompliant==="Compliant" ? "green" : "red"}}
                            id="IsVehicleCompliant"
                            name="IsVehicleCompliant"
                            label="Is Vehicle Compliant"
                            value={vehicleReducer.isVehicleCompliant || ""}
                        />
                        <EuiTextField className="p-1"
                            fullwidth
                            required
                            readOnly={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            style={{ padding: 3 }}
                            id="vehicleModelNameId"
                            name="vehicleModelName"
                            label="Model Name"
                            value={vehicleReducer.vehicleModelName || ""}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value, field: e.target.name })}
                        />
                        <EuiRow>
                            <div className="column sm-4 md-4 lg-4 xl-4 filled">
                                <EuiNumberField
                                    id="vehicleModelYearId"
                                    className="ml-1"
                                    disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                    name="vehicleModelYear"
                                    min={1950}
                                    max={new Date().getFullYear() + 1}
                                    label="Model Year"
                                    style={{ width: "70%" }}
                                    value={vehicleReducer.vehicleModelYear}
                                    onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: parseInt(e.target.value), field: e.target.name })}
                                />
                            </div>
                            <div className="column sm-8 md-8 lg-8 xl-8 filled">
                                <EuiNumberField
                                    id="kmReadingId"
                                    className="mr-1"
                                    disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                    name="kmReading"
                                    label="Current Vehicle Mileage"
                                    style={{ width: "100%", }}
                                    value={vehicleReducer.kmReading}
                                    onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: parseInt(e.target.value), field: e.target.name })}
                                />
                            </div>
                        </EuiRow>
                        <EuiRow>
                            <div className="column sm-6 md-6 lg-6 xl-6 filled">
                                <EuiNumberField name="lastServiceKmReading"
                                    id="lastServiceKmReadingId"
                                    className="ml-1 mt-1"
                                    disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                    label="KM Reading of Last Service"
                                    value={vehicleReducer.lastServiceKmReading}
                                    style={{ width: "100%" }}
                                    onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: parseInt(e.target.value), field: e.target.name })}
                                />
                            </div>
                            <div className="column sm-3 md-3 lg-3 xl-3 filled">
                                <EuiDatepicker
                                    id="lastServiceDatetimeId"
                                    className="mt-1 ml-1 mr-1"
                                    disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                    label="Last Service DateTime"
                                    placeholder="YYYY-MM-DD"
                                    name="lastServiceDatetime"
                                    value={vehicleReducer.lastServiceDatetime}
                                    onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: e.target.value, field: e.target.name })}
                                />
                            </div>
                            <div className="column sm-3 md-3 lg-3 xl-3 filled">
                                <EuiNumberField name="mileage"
                                    id="mileageId"
                                    className="ml-1 mt-1"
                                    disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                    label="mileage"
                                    value={vehicleReducer.mileage}
                                    style={{ width: "100%" }}
                                    onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: parseInt(e.target.value), field: e.target.name })}
                                />
                            </div> 
                        </EuiRow>
                    </div>
                    <div className="column sm-12 md-8 lg-8 xl-8 filled">
                        <EuiCheckbox name="fittedWithAbs"
                            id="fittedWithAbsId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with ABS braking system"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer.fittedWithAbs === 1 ? true : false}
                            onChange={(e) => { dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name }); }}
                        />
                        <EuiCheckbox name="fittedWithSeatBelt"
                            id="fittedWithSeatBeltId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with Seat belts and headrests applicable to all seats"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer.fittedWithSeatBelt === 1 ? true : false}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                        />
                          <EuiCheckbox name="fittedWithHeadRests"
                            id="fittedWithHeadRestsId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with headrests "
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer.fittedWithHeadRests === 1 ? true : false}
                            onChange={(e) => { dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name }); }}
                        />
                        <EuiCheckbox name="fittedWithRolloverProtection"
                            id="fittedWithRolloverProtectionId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with roll-over protection / Bars (pickups or similar)"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer.fittedWithRolloverProtection === 1 ? true : false}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                        />
                        <EuiCheckbox name="fittedWithApprTyres"
                            id="fittedWithApprTyresId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with Appropriate Tyres / Replaced considering milage usage and life time"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer.fittedWithApprTyres === 1 ? true : false}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                        />
                        <EuiCheckbox name="fittedWithHeadsFreeKit"
                            id="fittedWithHeadsFreeKitId"
                            className="checkbox-padding"
                            disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                            label="Fitted with Hands-free Kits (where safe and legally to do so)"
                            labelStyle={{ marginLeft: 2 }}
                            refValue={vehicleReducer.fittedWithHeadsFreeKit === 1 ? true : false}
                            onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                        />
                        <div className="tile-title">Emergency Requirements</div>
                        <div className="content tile-bordered mt-2" style={{ paddingLeft: 2 }}>
                            <EuiCheckbox name="emergencyEqptTriAngles"
                                id="emergencyEqptTriAnglesId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Minimum emergency equipment 2x emergency tri-angles"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer.emergencyEqptTriAngles === 1 ? true : false}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="emergencyEqpt25Fe"
                                id="emergencyEqpt25FeId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Minimum emergency equipment  1x 2.5 Kg FE"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer.emergencyEqpt25Fe === 1 ? true : false}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="emergencyEqptFaKit"
                                id="emergencyEqptFaKitId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Minimum emergency equipment  1 x FA Kit"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer.emergencyEqptFaKit === 1 ? true : false}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="emergencyEqptHighReflectorVest"
                                id="emergencyEqptHighReflectorVestId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Minimum emergency equipment  High-visible reflector vest"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer.emergencyEqptHighReflectorVest === 1 ? true : false}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                        </div>
                        <div className="tile-title">GPS/Telematics Requirements</div>
                        <div className="content tile-bordered mt-2" style={{ paddingLeft: 2 }}>
                            <EuiCheckbox name="gpsInstalled"
                                id="gpsInstalledId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Installed in vehicle"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer.gpsInstalled === 1 ? true : false}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="gpsSystemAccessGranted"
                                id="gpsSystemAccessGrantedId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="System Access Granted"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer.gpsSystemAccessGranted === 1 ? true : false}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="gpsLiveTracking"
                                id="gpsLiveTrackingId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Active/live tracking Reports"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer.gpsLiveTracking === 1 ? true : false}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                            <EuiCheckbox name="driverBehaviourMonitor"
                                id="driverBehaviourMonitoredId"
                                className="checkbox-padding"
                                disabled={mod === constants.pageMods.VIEW_ONLY || mod === constants.pageMods.APPROVE}
                                label="Driver Behaviour Monitor"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={vehicleReducer.driverBehaviourMonitor === 1 ? true : false}
                                onChange={(e) => dispatch({ type: "HANDLE_VEHICLE_CHANGE", payload: (e.target.value ? 1 : 0), field: e.target.name })}
                            />
                       
                        </div>
                   
                    </div>
                    <div className="column sm-12 md-12 lg-12 xl-12 filled pl-1">
                        {getUserModificationsSection()}
                    </div>

                </EuiRow>
            </EuiTile>
          
        </>
    );

});

export default VehicleApprovalDetailsContent;