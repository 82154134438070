import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import { ToastContainer, toast } from 'react-toastify';
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import EuiSearchField from "../../eui-components/searchfield";
import EhsPingFilter from "./ehsPingFilter";
import EhsPingList from "./ehsPingList";
import constants from "../../utils/constants";


class EhsPingSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            initialState: 0,
        }
    }

    componentDidMount() {
    }

    changeInitialState = () => {
        this.setState({ initialState: 1 });
    }

    render() {
        const leftPanel = {
            title: "Filter By:",
            content: <EhsPingFilter changeInitialState={() => this.changeInitialState()} />,
            hidden: "hidden"
        };
        const ehsPings = this.props.ehsPingData.filteredEhsPings.data;
        const totalCount = this.props.ehsPingData.filteredEhsPings.totalCount;
        const midPanel = {
            title: "Filtered EHS Pings",
            content: this.state.initialState === 0 && ehsPings.length === 0 ? <div className="flex-start-content m-3 w-10">Please click on filter to get a list of EHS Pings</div>
                : <EhsPingList id="filteredEhsPings" ehsPings={ehsPings} totalCount={totalCount} type={constants.activityListTypes.EHS_PING} height="530px" />,
        };

        return (<EuiRow>
            <EuiMultiPanelTile id="ehsPingMultiPanel"
                className="fullscreen"
                leftPanel={leftPanel}
                midPanel={midPanel}
                style={{ paddingRight: 23 }} />
            <ToastContainer enableMultiContainer containerId={'ES'} autoClose={2500} />
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ehsPingData: state.ehsPing });
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EhsPingSearch)); 