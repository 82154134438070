const initialState = {   
    mod: 0,  
    activities: [],
    currentActivity: { id: 0 },
    ratings: []
};

export default (state = initialState, action) => {
    switch (action.type) {
       
        case "SET_ACTIVITY_PAGE_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_ACTIVITIES":
            return {
                ...state,
                activities: action.payload
            };
        case "SET_CURRENT_ACTIVITY":
            return {
                ...state,
                currentActivity: action.payload
            };
        case "SET_RATINGS":
            return {
                ...state,
                ratings: action.payload
            };
       
        default:
            return state;
    }

};
