import axios from 'axios';
import constants from './constants';
import jwt from 'jwt-decode'
import { getAuthToken , getTokenAndRenew  } from './adalConfig';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';

axios.defaults.headers.common['wafkey'] = '049aefe8-975b-11ee-b9d1-0242ac120002' // for all requests
axios.defaults.headers.common['E-Top'] = 'qAhENO3QuvN8AIGGKaCVKdSX3XP2BWEe' // for all requests

export const _axios = axios.create({
    baseURL: constants.baseUrl,
    timeout: constants.timeout,
});

export default class ApiClient {

    static setToken(token) {
      //  localStorage.setItem("token", token);

       // _axios.defaults.headers.authorization = "Bearer " + token;
        
         getTokenAndRenew().then((token)=>{
            _axios.defaults.headers.authorization = "Bearer " + token;
         }).catch(error => {
            _axios.defaults.headers.authorization = "Bearer " + getAuthToken(); 
         });
       
    }

    static checked(rscPerson) {
        ///console.log('checked')
        localStorage.setItem("UserCheckout", JSON.stringify(rscPerson));
    }

    static checkSessionExpiredWithAzureAd(url) {
        
        //Note : if needed . use iframe to generate new token 
        //MARK : TODO 
      //  test case
       /* if (url?.includes("GetUserTemplates")) {
            authContext.login()  
        }*/
      /*  _axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            console.error(error);
            const status = error.response?.status;
            if (401 === status) {

             authContext.login() // login redirect

            }
        });*/
    }
    static checkSessionExpired() {
        _axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            console.error(error);
            const status = error.response?.status;
            if (401 === status) {
                let conf = {...error.response?.config }
                let token = localStorage.getItem("token");
                const user = jwt(token);
                if (user.exp  * 1000 > Date.now())
                {
                    conf.headers.authorization= 'Bearer ' + token;
                    return _axios.request(conf);
                }
                else
                {
                    return ApiClient.get("User/RefreshUserToken/", {userId: user.id}).then(response =>{
                        ApiClient.setToken(response.data);
                        conf.headers.authorization= 'Bearer ' + response.data;
                        _axios.defaults.headers.authorization = "Bearer " + token;
                        return _axios.request(conf);
                    }).catch(()=>{
                        alert("Your session has expired, Please login again!");
                        const rscPerson = localStorage.getItem("rscPerson");
                        if (rscPerson?.id && rscPerson?.checkin) {
                            const request = {
                                rscPersonId: rscPerson.id,
                                checkin: false,
                            };
                            async function checkout() {
                                await this.post("AutoAssignment/CheckinCheckout", request)
                                        .then(r => ApiClient.checked(request));
                            }
                            checkout();
                        }
        
                        localStorage.clear();
                        window.location = '/signin';
                    });
                }
            } else {
                return Promise.reject(error);
            }
        });
    }

    static async post(url, parameters, headers = {}, timeout, onUploadProgress, apiType = 1) {
     //   this.checkSessionExpired();
     this.checkSessionExpiredWithAzureAd(url)
     
        if (apiType === 2) {
            _axios.defaults.baseURL = constants.mobilUrl;
        } else {
            _axios.defaults.baseURL = constants.baseUrl;
            
            //let token = localStorage.getItem("token");
          let  token =  await getTokenAndRenew()

            if(token)
                headers.authorization = "Bearer " + token;
        }

        return await _axios.post(url, parameters, {
            headers,
            timeout,
            onUploadProgress
        });
    }

    static async get(url, parameters, headers, timeout, onUploadProgress) {
        // Check with interceptor 403 responses and logout and redirect to signin
        //this.checkSessionExpired();
        this.checkSessionExpiredWithAzureAd()
        let  token =  await getTokenAndRenew()

            if(token)
            _axios.defaults.headers.authorization = "Bearer " + token;
        

        return await _axios.get(url, {
            params:{...parameters},
            headers,
            timeout,
            onUploadProgress
        });
    }
    static async sendRequest(url,method, body, apiType = 1) {
        // this.checkSessionExpired();
        this.checkSessionExpiredWithAzureAd()
        if (apiType === 2) {
            _axios.defaults.baseURL  = constants.mobilUrl;  
        } else {
            _axios.defaults.baseURL = constants.baseUrl;
        }
        
        let  token =  await getTokenAndRenew()

            if(token)
            _axios.defaults.headers.authorization = "Bearer " + token;

        const options = {
            method: method,
            headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization':_axios.defaults.headers.authorization 

            },
        };
        options.body = JSON.stringify(body);
        return fetch(_axios.defaults.baseURL +url, options);
    }
}

export const connectionHubs = async ()=> {
    //let token = localStorage.getItem("token");
    let token = await getTokenAndRenew()
    

    const connect = new HubConnectionBuilder()
      .withUrl(constants.ChatUrlMobil, {
        accessTokenFactory: () => token,
        // accessTokenFactory: async () => {
        //     return webToken;
        // },
        // skipNegotiation: true,
        // transport: HttpTransportType.WebSockets,
        withCredentials: false
    }).configureLogging(LogLevel.Debug)
       .withAutomaticReconnect()
      .build();
    connect.start().then(result => {
         ///console.log("connected from api_client, webToken",result)
        });
        localStorage.setItem("connection", JSON.stringify(connect));
        return connect
}
