import React from "react";

export default class EuiNumberField extends React.Component {
    constructor(props)
    {
        super(props);
        this.state = {
            min : props.min ?? 0,
            max : props.max ?? 100000000000000000000000000000000000000000000
        }
    }

    handleOnBlur = (e) => {
        ///console.log(e)
        var value = e.target.value;

        if(value >= this.state.min && value <= this.state.max)
        {
            e.target.value = value
        }
        else
        {
            if(value < this.state.min)
            {
                e.target.value =  this.state.min
            }
            else    
            {
                e.target.value =  this.state.max
            }
        }
        this.props.onChange(e)
    }

    render() {
        const classes = [];
        this.props.fullwidth && classes.push("fullwidth");
        this.props.disabled && classes.push("disabled");
        this.props.required && classes.push("required");

        return (
            <div className={this.props.className ?? ""}>
                {this.props.label && <label className={this.props.labelClass} style={{ fontSize: 14, fontWeight: 500, color: "gray", ...this.props.labelstyle }} htmlFor={this.props.name}>{this.props.label}</label>}
                <input type="number"
                    step={this.props.step ?? 1}
                    min={this.state.min}
                    max={this.state.max}
                    onBlur={(e) => this.handleOnBlur(e)}
                    {...this.props}
                    className={classes.join(' ')}
                    style={this.props.style}
                />
            </div>
        );
    }
}
