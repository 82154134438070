import React from "react";
import MaterialTable, { MTableAction, MTableToolbar, MTableGroupbar, MTableGroupRow } from 'material-table';
import { Paper, TablePagination, withStyles } from '@material-ui/core';
import GroupRow from "../GroupRow";
import { connect } from "react-redux";
import TableBodyRow from "./tableBodyRow";
import TableHeaderRow from "./tableHeaderRow";
import TableFilterRow from "./tableFilterRow";

class Table extends React.Component {
    renderAction = props => {
        if (props.action.isFreeAction && props.action.buttonType === "text") {
            return <button className="btn" onClick={props.action.onClick} disabled={props.action.disabled}>{props.action.tooltip}</button>;
        }
        else {
            return <MTableAction {...props} />;
        }
    }

    renderToolbar = props => {
        if (this.props.toolbar && this.props.toolbar.custom) {
            return <div>
                <div style={{ display: "flex", flex: 1 , ...this.props.style } }>
                    <div style={{ width: "100%", height: 50 }}>
                        {this.props.toolbar.leftCustom ? this.props.toolbar.leftCustom : <MTableToolbar {...props} />}
                    </div>
                    {this.props.toolbar.custom}
                </div>
                {this.props.toolbar.toolbarHeader}
            </div>;
        }
        else {
            return <div style={{ height: 55 }}>
                <MTableToolbar{...props} classes={{ root: this.props.navbarData.theme === "dark" ? this.props.classes.paginationRoot : "" }} />
            </div>;
        }
    }

    renderContainer = props => {
        return <Paper {...props} style={{
            display: "grid",
            width: "100%",
            backgroundColor: "inherit",
            boxShadow: "0px 0px 0px transparent",
            borderRadius: 0,
            ...(this.props.container || {}).style
        }} />
    }

    renderGroupBar = props => {
        if (this.props.groupbar && this.props.groupbar.custom) {
            return this.props.groupbar.custom;
        } else {
            return <MTableGroupbar {...props} />
        }
    }

    renderGroupRow = props => {
        if (this.props.groupRow && this.props.groupRow.custom) {
            return <GroupRow {...props} />;
        }
        return <MTableGroupRow {...props} />
    }

    renderPagination = props => {
        return (
            <TablePagination {...props}
                classes={{
                    root: this.props.navbarData.theme === "dark" ? this.props.classes.paginationRoot : ""
                }}
                labelRowsPerPage={<div style={{ color: "inherit" }}>{props.labelRowsPerPage}</div>}
                SelectProps={{
                    style: {
                        color: "inherit"
                    },
                    MenuProps: {
                        classes: { paper: { color: "inherit" } }
                    }
                }} />
        )
    }

    renderRow = props => {
        return <TableBodyRow {...props} />
    }

    renderHeader = props => {
        return <TableHeaderRow {...props} />
    }

    renderFilter = props => {
        return <TableFilterRow {...props} />
    }

    //renderBody = props => {
    //    if (this.props.body && this.props.body.custom) {
    //        return <MTableBody {...props} onToggleDetailPanel={(path, render) => {   
    //            
    //            
    //            
    //            props.onRowClick(event, props.data, props.onToggleDetailPanel);

    //            //props.onToggleDetailPanel(path, render);
    //           }}
    //        />
    //    }
    //    return <MTableBody {...props} />;
    //}

    render() {
        return (
            <MaterialTable
                {...this.props}
                title={
                    <div style={{
                        fontSize: "18px",
                        display: "inlineBlock",
                        textAlign: "left",
                        fontWeight: 500,
                        color: "inherit",
                        paddingLeft: 0,
                        ...this.props.titleStyle
                    }}>{this.props.title}</div>
                }
                options={{
                    padding: 'dense',
                    rowStyle: {
                        ...((this.props.options || {}).rowStyle),
                    },
                    headerStyle: {
                        background: 'transparent',
                        color: "inherit",
                        fontSize: "15px",
                        whiteSpace: "nowrap",
                        fontWeight: 600,
                        padding: 0,
                        paddingLeft: 10,                     
                        ...((this.props.options || {}).headerStyle)
                    },
                    cellStyle: {
                        padding: 0,
                        paddingLeft: 10,
                        fontSize: "0.800rem",
                        ...((this.props.options || {}).cellStyle)
                    },
                    filterCellStyle: {
                        padding: 10,                    
                    },
                    selectionProps: {
                        background: "#fff",
                        ...((this.props.options || {}).selectionProps)
                    },
                    ...this.props.options

                }}
                style={{
                    boxShadow: 'none',
                    background: 'transparent',
                    ...this.props.style
                }}
                components={{
                    Action: this.renderAction,
                    //Body: this.renderBody,
                    Container: this.renderContainer,
                    Toolbar: this.renderToolbar,
                    Groupbar: this.renderGroupBar,
                    GroupRow: this.renderGroupRow,
                    Pagination: this.renderPagination,
                    Header: this.renderHeader,
                    Row: this.renderRow,
                    FilterRow: this.renderFilter
                }}
                icons={{
                    Search: React.forwardRef((props, ref) => <span ref={ref}></span>),
                    ResetSearch: React.forwardRef((props, ref) => <span ref={ref}></span>),
                    Filter: React.forwardRef((props, ref) => <span ref={ref}></span>),
                    ...this.props.icons
                }}
            />
        );
    }
}

const styles = () => ({
    paginationRoot: {
        '& button': {
            color: '#fff'
        },
        '& button:disabled': {
            color: '#fff',
            opacity: '0.5'
        },
    },
})
const mapStateToProps = state => ({ navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(mapStateToProps,
    mapDispatchToProps)
    (withStyles(styles, { withTheme: true })(Table));

