﻿import React, { useEffect, useState } from 'react'
import ApiClient from '../../utils/api-client';
import WorkplanHelper from '../../utils/workplan-helper';
import constants from '../../utils/constants';
import EuiRow from '../../eui-components/row';
import EuiLoading from '../../eui-components/loading';
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiSwitch from '../../eui-components/switch';
import { connect, useDispatch } from "react-redux";
import CommonHelper from '../../utils/common-helper';
import DialogModal from '../../components/dialog';
import { Typography } from '@material-ui/core';
import { isEmpty, remove } from 'lodash';
import { red } from '@material-ui/core/colors';
import ContentDeleteWorkplanDialog from './ContentDeleteWorkplanDialog';
import PageHelper from '../../utils/page-helper';

 function WorkplanList(props) {
    const dispatch = useDispatch();

    const { isDeletedbuttonClicked, setIsDeletedbuttonClicked } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [workplanFiltered, setWorkplanFiltered] = useState(false);
    const [errorTokenCode, setErrorTokenCode] = useState(false);
    const [errorMessageTokenCode, setErrorMessageTokenCode] = useState("");


    const [tokenCode, setTokenCode] = useState("");
    const [selectedProjectId, setSelectedProjectId] = useState(0);
    const [requestId, setRequestId] = useState(0);

    const [warning, setOpenWarning] = useState(false);
    const [removedItem, setDeletedItem] = useState(null);
    const [confirm, setConfirm] = useState(false);
    let filteredWorkplans = (workplanFiltered ? [...props.workplanData.filteredWorkplans.data?.filter(item => !constants.hiddenWorkplans.includes(item.erisiteStatus?.toUpperCase()))]
    : [...props.workplanData.filteredWorkplans.data]);

    useEffect(() => {
        PageHelper.getCoordinates();
        dispatch({ type: "SET_SELECTED_WORKPLANS", payload: [] });


    }, []);

    const workplanColumns = [
        {
            title: "Workplan ID",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = `<a id="workplan_${cellData}">${cellData}</a>`;
                td.querySelector("#workplan_" + cellData).addEventListener('click', () => openWorkplanDetail(cellData))
            },
            key: "id",
            width: "10%",
            sort: "desc",
            cellStyle: "white-space:nowrap; font-size: 13px"
        },
        {
            title: "WP Assignment ID",
            key: "wpAssignmentID",
            width: "10%",
            sort: "none",
            cellStyle: "white-space:nowrap; font-size: 13px"
        },
        {
            title: "Workplan Name",
            key: "name",
            width: "20%",
            sort: "none",
            cellStyle: "white-space:nowrap; font-size: 13px"
        },
        {
            title: "Erisite Workplan ID",
            key: "erisiteWorkplanId",
            width: "10%",
            sort: "none",
            cellStyle: "white-space:nowrap; font-size: 13px"
        },
        {
            title: "Customer Site ID",
            key: "customerSiteId",
            width: "10%",
            sort: "none",
            cellStyle: "white-space:nowrap; font-size: 13px"
        },
        {
            title: "Project Name",
            key: "projectName",
            width: "10%",
            sort: "none",
            cellStyle: "white-space:nowrap; font-size: 13px"
        },
        {
            title: "Site Name",
            key: "siteName",
            width: "10%",
            sort: "none",
            cellStyle: "white-space:nowrap; font-size: 13px"
        },
        {
            title: "Customer Name",
            key: "customerName",
            width: "10%",
            sort: "none",
            cellStyle: "white-space:nowrap; font-size: 13px"
        },
        {
            title: "WP Type",
            key: "wpType",
            width: "10%",
            sort: "none",
            cellStyle: "white-space:nowrap; font-size: 13px"
        },
    ];
    const confirmDelete = (workplans) => {
        // get tokenCode sent by email from api workPlanIds

        const lst = [];
        setSelectedProjectId(workplans)
        workplans.forEach(element => {
            const ele = {};
            ele.workPlanId = element.id;
            // ele.projectId= element.projectId
            lst.push(element.id);
        });
        CreateRequest(lst)
        setDeletedItem(workplans);
    };

    const CreateRequest = (workplanIds) => {
        // opend confirmation delete project
        const request = {
            workPlanIds: JSON.stringify([...workplanIds]),
            userId: parseInt(props.userData.userData.id),

        }
        setIsLoading(true);

      ApiClient.post("Projects/CreateRequest", {...request}).then(response => {
            setOpenWarning(true);
            setIsLoading(false);

            setRequestId(response.data)
          }).catch(err => {
            CommonHelper.createNotification({ title: "Error", description: "An issue occurred while deleting the Project, please try again!", icon: "icon-triangle-warning" ,timeout: 5000});
              setOpenWarning(false);
              setConfirm(false);
              setDeletedItem(null);
              setIsLoading(false);

          }
          );
        
      };
      const VerifyCodeConfiramtion = () => {
        setIsLoading(true);

        if (confirm === true) {      
          const request={
            requestId:parseInt(requestId),
            userId:parseInt(props.userData.userData.id) ,
            tokenCode:tokenCode
    }
          ApiClient.post("Projects/VerifyCode", {...request}).then(response => {
            setIsLoading(false);

            if (response.data) {
             const data= remove(filteredWorkplans, function(n) {
                return !selectedProjectId.some(d => d.id === n.id)
            });
              WorkplanHelper.mapWorkplans(data);
              props.setFilteredWorkplans({ data, totalCount: response.data.totalCount });
              CommonHelper.createNotification({ title: "Success", description: "WorkPlan Deletion Request Created successfully", icon: "check", timeout: 5000 });
          } else {
              CommonHelper.createNotification({ title: "Error", description: "the token sent by Email its doesn`t match!", icon: "cross",timeout: 5000 });
              setOpenWarning(true);
    
            }   
           
          }).catch(err => {
            CommonHelper.createNotification({ title: "Error", description: "An issue occurred while deleting the project, please try again!", icon: "icon-triangle-warning" ,timeout: 5000});
            setIsLoading(false);
            setOpenWarning(false);
              setConfirm(false);
              setDeletedItem(null);
          }
          );
        }
    };
    useEffect(() => {
        if (confirm) {
            VerifyCodeConfiramtion();
            setConfirm(false);
            setOpenWarning(false);
        }
    }, [confirm]);
    useEffect(() => {
        if (isDeletedbuttonClicked) {
            confirmDelete(props.workplanData.selectedWorkplans)
            setIsDeletedbuttonClicked(false);

        }
    }, [isDeletedbuttonClicked]);




    const openWorkplanDetail = (workplanId) => {
        const selectedWorkplans = props.workplanData.filteredWorkplans.data.find(item => item.id === workplanId);

        setIsLoading(true);
        ApiClient.post("Workplan/GetWorkplanDetails", { id: workplanId })
            .then(response => {
                setIsLoading(false);
                const workplan = { ...response.data }
                workplan["customer"] = { ...selectedWorkplans.customer };
                WorkplanHelper.mapWorkplan(workplan);
                props.setWorkplan(workplan);

                props.changeMod(constants.pageMods.VIEW_ONLY);

            })
            .catch(error => {
                console.error(error);;
                setIsLoading(false);
            });

    }


    const changeFilterWorkplans = (e) => {
        setWorkplanFiltered(e.target.checked)
        setIsLoading(false);

    }
    const onChangeTokenCode = (e) => {
        setTokenCode(e.target.value);
  };
  
const selectedWorkplansFun = (e, type) => {
    const detail = e.detail;
    const data = detail.data;

    if (type === "add") {
        props.setSelectedWorkplans([...props.workplanData.selectedWorkplans, data])
    }
    else if (type === "remove") {
         props.setSelectedWorkplans(props.workplanData.selectedWorkplans.filter(item => item.id !== data.id))
             
    }
}

  return (
    
    <EuiRow key={isLoading} disabled={isLoading ? "disabled" : ""}>
    <EuiLoading id="workplanToDetailsLoading" isLoading={isLoading} />
    <ObseleteEuiTable     
        classes="tiny striped"
        columns={workplanColumns}
        data={filteredWorkplans}
        id="workplanFilteredTable"
        scroll={true}
        sortable={true}
        paginated={true}
        viewActionIcon={true}
        totalCount={props.workplanData.workplansFitered?.data.length}
        hideTableInfo={false}
        selectable='multi'
        selectRow={(e, type) => selectedWorkplansFun(e, type)}
        actionIcon={
            <EuiRow className="ml-1 w-10">
                <span >
                    <EuiSwitch
                        id="filterWorkplanCheckbox"
                        onChange={(e) => changeFilterWorkplans(e)}
                        checked={workplanFiltered}
                        dataEnabled="Cancelled workplans excluded "
                        dataDisabled="All workplans"
                        name="workplanssFiltered"
                    />
                </span>
            </EuiRow>}
        rowsPerPage={10}
    >
    </ObseleteEuiTable>
    <DialogModal
          buttonName="Delete"
          disabled={isEmpty(tokenCode)}
          type="warning"
          title={
            <div className="flex-start-content" style={{ marginLeft: 34 }}>
              <Typography
                style={{
                  color: red[200],
                  fontSize: 16,
                  margin: 5,
                  lineHeight: "50px",
                }}
              >
                Delete Workplans reqaust
              </Typography>
            </div>
          }
          
          content={<ContentDeleteWorkplanDialog 
          errorTokenCode={errorTokenCode}
          tokenCode={tokenCode}
          onChangeTokenCode={onChangeTokenCode}
          errorMessageTokenCode={errorMessageTokenCode}/>
          }
          handleClose={() => setOpenWarning(false)}
          mainButtonStyle={{ backgroundColor: "red" }}
          id="alertDialog"
          onClick={() => setConfirm(true)}
          open={warning}
          style={{ height: "50%" }}
        //   contentStyle={{ width: "380px" }}
          disableBackdropClick={true}
        />
</EuiRow >
  )
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, workplanData: state.workplan });
const mapDispatchToProps = dispatch => {
    return {
        setWorkplan: (workplan) => dispatch({ type: "SET_WORKPLAN", payload: workplan }),
        changeMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setFilteredWorkplans: (filteredWorkplans) => dispatch({ type: "SET_FILTERED_WORKPLANS", payload: filteredWorkplans }),
        setSelectedWorkplans: (selectedWorkplans) => dispatch({ type: "SET_SELECTED_WORKPLANS", payload: selectedWorkplans }),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkplanList);
