import React from "react";
import constants from "./constants";
import DateUtils from "./date-utils";
import ActivityInboxHelper from "./activity-inbox-helper";
import PageHelper from "./page-helper";


export default class WorkplanHelper {

    static mapWorkplans = (workplans) => {
        if (!workplans || workplans.length === 0) {
            return;
        }
        workplans.map(item => {
            item.siteName = item.site?.name;
            item.projectName = item.project?.name;
            item.customerName = item.customer?.text;
            item.customerSiteId = item.site?.customerSiteId;
        });
    }

    static mapWorkplan = (workplan) => {
        if (!workplan) {
            return;
        }
        
        workplan.siteName = workplan.site?.name;
        workplan.siteModel = { value: workplan.site?.id || 0, text: workplan.site?.name || "" };
        workplan.farendSiteModel = { value: workplan?.farendId || 0, text: workplan.farendName || "" }
        workplan.projectName = workplan.project?.name;
        workplan.siteCode = workplan.site?.code;
        workplan.customerName = workplan.customer?.text;
        workplan.customerId = workplan.customer?.value;

        if (workplan.activities && workplan.activities.length > 0) {
            workplan.activities.map(item => {
                item.workplanName = workplan.name;
                item.projectName = workplan.projectName;
                item.siteName = workplan.siteName;
                item.siteId = workplan.siteId;
                item.customId = 'ID: ' + item.id + ' <br>E.Id:'+ item.erisitePlanningObjectId;

                item.plannedDates = [DateUtils.toDateString(item.plannedStart, "YYYY-MM-DD HH:mm:ss") || "(-----,----)", DateUtils.toDateString(item.plannedEnd, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"];
                item.actualDates = [DateUtils.toDateString(item.actualStart, "YYYY-MM-DD HH:mm:ss") || "(-----,----)", DateUtils.toDateString(item.actualEnd, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"];
                item.aspCompanyName = item.aspCompany?.name ?? "---";
                item.rscPersonName = item.rscPerson?.name ?? "---";
                item.customer = workplan.customer;
                item.teamLeadName = item.resource?.name ?? "---";
                item.teamLeadMail = item.resource?.email ?? "---";
                item.aspCompanyAndTeamLead = [item.aspCompanyName, item.teamLeadName];
                item.aspCompanyIconId = item.downloadSessionIconId = item.id;
            });
        }
        if (workplan.ohsPings && workplan.ohsPings.length > 0) {
            workplan.ohsPings.map(item => {
                item.activityName = item.activity?.name;
                item.workplanName = workplan.name;
                item.projectName = workplan.projectName;
                item.projectId = workplan.project?.id;
                item.siteName = workplan.siteName;
                item.aspResourceName = item.user?.name;
                item.aspResourceNameAndMail = [item.user?.name, item.user?.email];
                item.rscPersonName = item.rscPerson?.name;
                item.customer = workplan.customer;
                item.ehsDownloadSessionIconId = item.id;
            });
        }
        if (workplan.workplanDocs && workplan.workplanDocs.length > 0) {
            workplan.workplanDocs.map(item => {
                item.document = {
                    id: item.id, title: item.title, documentPath: item.documentPath, updatedAt: item.updatedAt,
                };
            });
        }
    }

    static mapActivity = (activity, selectedActivity, type) => {
        if (!activity) {
            return;
        }
        
        activity.type = type;
        activity.plannedStartTime = activity.plannedStart?.split('T', 4)[1] || "00:00";
        activity.plannedEndTime = activity.plannedEnd?.split('T', 4)[1] || "00:00";
        activity.customId = 'ID: ' + activity.id + ' <br>E.Id:'+ activity.erisitePlanningObjectId;
        activity.plannedDates = [DateUtils.toDateString(activity.plannedStart) || "(-----,----)", DateUtils.toDateString(activity.plannedEnd) || "(-----,----)"];
        activity.actualDates = [DateUtils.toDateString(activity.actualStart) || "(-----,----)", DateUtils.toDateString(activity.actualEnd) || "(-----,----)"];
        activity.ehsPings = activity.ohsPings || [];
        if (activity.ehsPings.length > 0) {
            activity.ehsPings.map(item => {
                item.activityName = selectedActivity.name;
                item.workplanName = selectedActivity.workplanName;
                item.projectName = selectedActivity.projectName;
                item.siteName = selectedActivity.siteName;
                item.aspResourceName = item.user?.name;
                item.aspResourceNameAndMail = [item.user?.name, item.user?.email];
                item.rscPersonName = item.rscPerson?.name;
                item.customer = selectedActivity.customer;
                item.ehsDownloadSessionIconId = item.id;
            });
        }
        activity.recordings = activity.recordings || [];
        activity.subactivities = activity.subactivities || [];
        activity.ratings = activity.activityRatings || [];
        activity.rscPersonName = activity.rscPerson?.name;
        activity.rscPersonMail = activity.rscPerson?.email;
        activity.aspCompanyName = selectedActivity.aspCompany?.name;
        activity.teamLead = selectedActivity.user?.name;
        activity.workplanName = selectedActivity.workplanName;
        activity.siteName = selectedActivity.siteName;
        activity.siteId = selectedActivity.siteId;
        activity.projectName = selectedActivity.projectName;
        activity.customerName = selectedActivity.customer?.text || selectedActivity.customerName;
        activity.customer = selectedActivity.customer;
        activity.teamLeadName = selectedActivity.resource?.name ?? "";
        activity.teamLeadMail = selectedActivity.resource?.email ?? "";
        activity.aspCompanyAndTeamLead = [activity.aspCompanyName, activity.teamLeadName];
        activity.forecastConfirmationUserName = activity.forecastConfirmationUser?.text ?? "";
        activity.integrationUserName = activity.integrationUser?.text ?? "";

        if ((activity.logs || []).length > 0) {
            activity.logs.map(item => {
                item.dateTimeUtc = DateUtils.toDateString(item.dateTimeUTC, "YYYY-MM-DD HH:mm:ss");
                item.userName = item.user?.text;
                item.user = [item.user?.text, item.user?.mail];
            });
        }

        if (activity.subactivities.length > 0) {
            activity.subactivities.map(item => {
                const viCode = item.viCode;
                item.documents = item.activityDocuments || [];
                item.documents.map(document => {
                    document.rscPersonName = document.rscPerson?.name;
                    const name = document.name ?? document.documentPath.split("/").pop();
                    const viInfo = document.viapiResultNavigation?.viResultModel?.conclusions?.map((c, index) =>
                        <li className="info-list-item" key={document.id + c.category + index}><span className="info-label">{c.category}</span><div className="pt-1"> {c.result}</div></li>);
                    document.isVIImage = viInfo ? true : false;
                    document.isVIDrawing = document.viapiResultNavigation?.viResultModel?.annotations?.length > 0 ? true : false;
                    document.title = <div className="info-list-item" style={{ color: "#fff" }}><div className="info-label">Checklist </div><div className="pt-1">{item.name}</div></div>
                    document.uploadDetails =
                        <ul className="infoList" style={{ paddingInlineStart: 0, color: "#fff" }} >
                            <li className="info-list-item" key={document.id + "aspName"}><span className="info-label">Team Lead </span><div className="pt-1">{activity.resource?.name || "---"}</div></li>
                            <li className="info-list-item" key={document.id + "aspPhone"}><div className="pt-1">{activity.resource?.phone || "---"}</div></li>
                            <li className="info-list-item" key={document.id + "aspMail"}><div className="pt-1">{activity.resource?.email || "---"}</div></li>
                        </ul>
                    document.additionalInfo = <span>
                        <ul className="infoList" style={{ paddingInlineStart: 0, color: "#fff" }} >
                            <li className="info-list-item" key={document.id + "project"}><span className="info-label">Project </span><div className="pt-1">{activity.projectName}</div></li>
                            <li className="info-list-item" key={document.id + "workplan"}><span className="info-label">Workplan </span> <div className="pt-1">{activity.workplanName}</div></li>
                            <li className="info-list-item" key={document.id + "site"}><span className="info-label">Site </span><div className="pt-1"> {activity.siteName}</div></li>
                            <li className="info-list-item" key={document.id + "latitude"}><span className="info-label">Latitude </span><div className="pt-1"> {ActivityInboxHelper.getLatitude(document.latitude)}</div></li>
                            <li className="info-list-item" key={document.id + "longitude"}><span className="info-label">Longitude </span> <div className="pt-1">{ActivityInboxHelper.getLongitude(document.longitude)}</div></li>
                            <li className="info-list-item" key={document.id + "uploadTime"}>
                                <span className="info-label">Upload Time: </span> {!document.uploadDate || !document.uploadStartDate ? "---"
                                    : DateUtils.dateDifferenceInSeconds(document.uploadDate, document.uploadStartDate)}
                            </li>
                            <li className="info-list-item" key={document.id + "rscReviewDate"}><span className="info-label">Actual Review DateTime </span>
                                <div className="pt-1">{DateUtils.toDateString(document.reviewDate, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"}</div>
                            </li>
                            <li className="info-list-item" key={document.id + "rscEngineer"}><span className="info-label">RSC Engineer </span><div className="pt-1"> {document.rscPersonName || "---"}</div></li>
                            {viInfo ?
                                <>
                                    <div className="info-list-item" style={{ color: "#fff" }}>
                                        <div className="info-label pb-1 pt-1" style={{ color: "yellow" }}>VI Inspection Result</div>
                                        <li className="info-list-item" key={document.id + "viCode"}><div className="pt-1 pb-1">{viCode}</div></li>
                                        {viInfo}
                                        <li className="info-list-item" key={document.id + "viReason"}><div className="pt-1">{document.viapiResultNavigation?.viResultModel?.reason}</div></li>
                                        <li className="info-list-item" key={document.id + "viResult"}>
                                            <div className="pt-1" style={{ color: PageHelper.getVIColor(document.viapiResultNavigation?.viResultModel?.result) }}>{document.viapiResultNavigation?.viResultModel?.result}</div>
                                        </li>
                                    </div>
                                </>
                                : null
                            }
                        </ul>
                    </span>
                    document.name = { id: document.id, type: document.type, name };
                    if (document.type === constants.documentTypes.IMAGE) {
                        document.posterPath = document.documentPath;
                    } else {
                        document.posterPath = ActivityInboxHelper.getPosterPath(document.type) ?? document.documentPath;
                    }

                })
            });
        }
    }

    static mapEhsPing = (ehsPing, selectedEhsPing, type) => {
        if (!ehsPing) {
            return;
        }
        ehsPing.type = type;
        ehsPing.recordings = ehsPing.recordings || [];
        ehsPing.checklists = ehsPing.checklists || [];
        ehsPing.ratings = ehsPing.ohsPingRatings || [];
        ehsPing.rscPersonName = selectedEhsPing.rscPerson?.name;
        ehsPing.rscPersonMail = selectedEhsPing.rscPerson?.email;
        ehsPing.teamLead = selectedEhsPing.user?.name;
        ehsPing.teamLeadMail = selectedEhsPing.user?.email;
        ehsPing.aspCompanyName = ehsPing?.activity?.aspCompany?.name;
        ehsPing.workplanName = selectedEhsPing.workplanName;
        ehsPing.activityName = ehsPing.activity?.name;
        ehsPing.rscScope = ehsPing.activity?.rscScope;
        ehsPing.HighRiskIntegrationOutput = ehsPing.activity?.highRiskItemConfig
        ehsPing.siteName = selectedEhsPing.siteName;
        ehsPing.projectName = selectedEhsPing.projectName;
        ehsPing.projectId = selectedEhsPing.projectId;
        ehsPing.customerName = selectedEhsPing.customer?.text;
        ehsPing.customer = selectedEhsPing.customer;
        ehsPing.viewOnly = true;
        ehsPing.status = ehsPing.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED;
        ehsPing.ratingSum = ehsPing.scoreTotal;
        if ((ehsPing.ehsViolationSentUsers || []).length > 0) {
            ehsPing.violationSentUsers = ehsPing.ehsViolationSentUsers.filter(v => v.userId)
                .map(s => {
                    const newUser = { ...s };
                    newUser.value = newUser.user?.id;
                    newUser.text = newUser.user?.name;
                    newUser.mail = newUser.user?.email;
                    return newUser;
                });
            ehsPing.externalUsers = ehsPing.ehsViolationSentUsers.filter(v => !v.userId).map(s => {
                const newUser = { ...s };
                newUser.value = newUser.id;
                newUser.text = "-"
                newUser.mail = newUser.userEmail;
                return newUser;
            });
        }

        if (ehsPing.checklists.length > 0) {
            ehsPing.checklists.map(item => {
                item.opened = "opened";
                if ((item.documents || []).length !== 0) {
                    item.documents.map(document => {
                        document.documentPath = document.imagePath;
                        document.rscPersonName = document.rscPerson?.name;
                        const name = document.name ?? document.documentPath.split("/").pop();
                        document.title = <div className="info-list-item" style={{ color: "#fff" }}><div className="info-label">Checklist </div><div className="pt-1">{item.name}</div></div>
                        
                        const viInfo = document.viapiResultNavigation?.viResultModel?.conclusions?.map((c, index) =>
                        <li className="info-list-item" key={document.id + c.category + index}><span className="info-label">{c.category}</span><div className="pt-1"> {c.result}</div></li>);
                        document.isVIImage = viInfo ? true : false;
                        document.isVIDrawing = document.viapiResultNavigation?.viResultModel?.annotations?.length > 0 ? true : false;
                        document.uploadDetails =
                            <ul className="infoList mb-2" style={{ paddingInlineStart: 0, color: "#fff" }} >
                                <li className="info-list-item" key={document.id + "aspName"}><span className="info-label">Team Lead </span><div className="pt-1">{selectedEhsPing.user?.name || "---"}</div></li>
                                <li className="info-list-item" key={document.id + "aspPhone"}>{selectedEhsPing.user?.phone || "---"}</li>
                                <li className="info-list-item" key={document.id + "aspMail"}>{selectedEhsPing.user?.email || "---"}</li>
                            </ul>
                        document.additionalInfo = <span>
                            <ul className="infoList" style={{ paddingInlineStart: 0, color: "#fff" }} >
                                <li className="info-list-item" key={document.id + "project"}><span className="info-label">Project </span><div className="pt-1">{ehsPing.projectName}</div></li>
                                <li className="info-list-item" key={document.id + "workplan"}><span className="info-label">Workplan </span> <div className="pt-1">{ehsPing.workplanName}</div></li>
                                <li className="info-list-item" key={document.id + "site"}><span className="info-label">Site </span><div className="pt-1"> {ehsPing.siteName}</div></li>
                                <li className="info-list-item" key={document.id + "latitude"}><span className="info-label">Latitude </span> <div className="pt-1">{ActivityInboxHelper.getLatitude(document.latitude)}</div></li>
                                <li className="info-list-item" key={document.id + "longitude"}><span className="info-label">Longitude </span> <div className="pt-1">{ActivityInboxHelper.getLongitude(document.longitude)}</div></li>
                                <li className="info-list-item" key={document.id + "uploadTime"}>
                                    <span className="info-label">Upload Time: </span> {!document.uploadDate || !document.uploadStartDate ? "---"
                                        : DateUtils.dateDifferenceInSeconds(document.uploadDate, document.uploadStartDate)}
                                </li>
                                <li className="info-list-item" key={document.id + "rscReviewDate"}><span className="info-label">Actual Review DateTime </span>
                                    <div className="pt-1">{DateUtils.toDateString(document.acceptanceDate, "YYYY-MM-DD HH:mm:ss")}</div>
                                </li>
                                <li className="info-list-item" key={document.id + "rscEngineer"}><span className="info-label">RSC Engineer </span><div className="pt-1"> {document.rscPersonName}</div></li>
                                {viInfo ?
                                    <>
                                        <div className="info-list-item" style={{ color: "#fff" }}>
                                            <div className="info-label pb-1 pt-1" style={{ color: "yellow" }}>VI Inspection Result</div>
                                            <li className="info-list-item" key={document.id + "viCode"}><div className="pt-1 pb-1">{document.viDocTag}</div></li>
                                            {viInfo}
                                            <li className="info-list-item" key={document.id + "viReason"}><div className="pt-1">{document.viapiResultNavigation?.viResultModel?.reason}</div></li>
                                            <li className="info-list-item" key={document.id + "viResult"}>
                                                <div className="pt-1" style={{ color: PageHelper.getVIColor(document.viapiResultNavigation?.viResultModel?.result) }}>{document.viapiResultNavigation?.viResultModel?.result}</div>
                                            </li>
                                        </div>
                                    </>
                                    : null
                                }                           
                            </ul>
                        </span>
                        document.name = { id: document.id, type: document.type, name };
                        if (document.type === constants.documentTypes.IMAGE) {
                            document.posterPath = document.documentPath;
                        } else {
                            document.posterPath = ActivityInboxHelper.getPosterPath(document.type) ?? document.documentPath;
                        }

                    });
                }
                if ((item.templateItems || []).length !== 0) {
                    item.templateItems.map(item => {
                        const ehsRating = ehsPing.ehsRatings.find(r => r.checkPoints === item.checkPoints);
                        item.ratingOptions = [{ value: 0, text: "NA" }, { value: 2, text: "FAIL" }, { value: 4, text: "PASS" }];
                        item.score = ehsRating?.score ?? 4;
                        item.additionalComments = ehsRating?.additionalComments;
                        item.isObservation = ehsRating?.isObservation;
                        item.integrationStatus = ehsRating?.integrationStatus;
                        item.integrationOutput = ehsRating?.integrationOutput;
                    });
                }
            });
        }
        if ((ehsPing.ehsRatings || []).length > 0) {
            ehsPing.ehsRatings.map(r => {
                r.resolvedDateAndUser = { resolvedUserName: r.resolvedUser?.text, resolvedDate: r.resolvedDate };
                r.resolutionId = r.id;
            });
        }
        ehsPing.resolvedUser = ehsPing.user?.name;
    }

    static mapActivities = (activities) => {
        if (activities && activities.length > 0) {
            activities.map(item => {
                item.workplanName = item.workplan?.name;
                item.projectName = item.workplan?.project?.name;
                item.siteName = item.workplan?.site?.name;
                item.siteId = item.workplan?.site?.id;
                item.customer = item.workplan?.customer;
                item.customerName = item.customer?.text;
                item.customId = 'ID: ' + item.id + ' <br>E.Id:'+ item.erisitePlanningObjectId;
                item.plannedDates = [DateUtils.toDateString(item.plannedStart, "YYYY-MM-DD HH:mm:ss") || "(-----,----)", DateUtils.toDateString(item.plannedEnd, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"];
                item.actualDates = [DateUtils.toDateString(item.actualStart, "YYYY-MM-DD HH:mm:ss") || "(-----,----)", DateUtils.toDateString(item.actualEnd, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"];
                item.aspCompanyName = item.aspCompany?.name ?? "---";
                item.rscPersonName = item.rscPerson?.name ?? "---";
                item.teamLead = item.user?.name;
                item.teamLeadName = item.resource?.name ?? "---";
                item.teamLeadMail = item.resource?.email ?? "---";
                item.aspCompanyAndTeamLead = [item.aspCompanyName, item.teamLeadName];
                item.aspCompanyIconId = item.downloadSessionIconId = item.id;
                item.editId = item.id + "_" + item.actualEnd;
                item.resetId = item.id + "_" + item.actualEnd;
                item.assignActivityId = { acceptanceStatusId: item.acceptanceStatusId, id: item.id };
            });
        }
    }

    static mapEhsPings = (ehsPings) => {
        if (ehsPings && ehsPings.length > 0) {
            ehsPings.map(item => {
                item.activityName = item.activity?.name
                item.workplanName = item.workplan.name;
                item.projectName = item.workplan?.project?.name;
                item.projectId = item.workplan?.project?.id;
                item.siteName = item.workplan?.site?.name;
                item.aspResourceName = item.user?.name;
                item.aspResourceNameAndMail = [item.user?.name, item.user?.email];
                item.rscPersonName = item.rscPerson?.name;
                item.customer = item.workplan?.customer;
                item.customerName = item.customer?.text;
                item.ehsDownloadSessionIconId = item.id;
            });
        }
    }

    static filterFiles = (filter, documents) => {
        const acceptanceStatus = [];
        acceptanceStatus.push(!filter.accepted ? constants.acceptanceStatusTypes.ACCEPTED : 0);
        acceptanceStatus.push(!filter.rejected ? constants.acceptanceStatusTypes.REJECTED : 0);
        acceptanceStatus.push(!filter.pending ? constants.acceptanceStatusTypes.PENDING : 0);
        const types = [];
        types.push(!filter.images ? constants.documentTypes.IMAGE : 0);
        types.push(!filter.videos ? constants.documentTypes.VIDEO : 0);
        types.push(!filter.pdfs ? constants.documentTypes.PDF : 0);
        types.push(!filter.otherDocuments ? constants.documentTypes.OTHER_DOCUMENTS : 0);

        let files = documents.filter(item => item
            && !acceptanceStatus.includes(item.acceptanceStatusId)
            && !types.includes(item.type));

        if (!filter.recordings) {
            files = files.filter(item => item.documentPath);
        }
        
        
        return files;
    }

    static getAssignedIndividual = (assigned) => {
        if (!assigned || (!assigned.assigneeFirstName && !assigned.assigneeLastName)) {
            return "(Not Defined)";
        } else {
            return (assigned.assigneeFirstName ?? "") + " " + (assigned.assigneeLastName ?? "");
        }
    }

    static getWpTypeId = (WpValue) => {
        if (WpValue == "TX Link")
            return 1;
        if (WpValue == "Radio Site")
            return 2;
    }

    static getWpTypeText = (value) => {
        if (value) {
            if (value === 1)
                return "TX Link";
            if (value === 2)
                return "Radio Site";
        }
        else return null;
    }
}
