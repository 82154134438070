import React from "react";
import { useEffect } from "react";
import { PillGroup } from '@eds/vanilla';

const EuiPillGroup = (props) => {

    useEffect(() => {
        if (props.id) {
            const pillGroupDOM = document.querySelector('#' + props.id);
            if (pillGroupDOM) {
                const pillGroup = new PillGroup({ element: pillGroupDOM, action: (e) => handleChange(e) });
                pillGroup.init();
            }
        } else {
            console.error("You should set an id to PillGroup component");
        }
    }, []);

    const handleChange = (pills) => {
        const filteredPills = [...pills.filter(p => p.isSelected()).map(pi => pi.data.key)];
        // add props to indicate that this is a special case
        if (props.pillsContrast) {
            document.querySelectorAll("span.pill.toggle").forEach(node => {
                node.style.color = "#FFFFFF";
                node.getAttribute('class') == 'pill toggle' ? node.style.background = "#329864" : node.style.background = "#878787";
            })
        }
        props.onChange && props.onChange(filteredPills);
    }

    return (<div id={props.id} className={props.className}>
        {props.pills && props.pills.map(item => {
            return <span key={item.value} class={"pill toggle " + (props.pillClassName ?? "")} style={{ backgroundColor: item.color, ...(item.style ?? {}) }} data-key={item.value}>{item.text}</span>
        })}
        <a class="toggle-all">Select all</a>
    </div>);
}

export default EuiPillGroup;