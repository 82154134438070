import React, { useEffect, useCallback, useState, useRef } from "react";
import VehicleHelper from '../../utils/vehicle-helper';
import { useDispatch, useSelector } from "react-redux";
import constants from '../../utils/constants';
import ObseleteEuiTable from '../../eui-components/obseleteTable';
import ApiClient from '../../utils/api-client';
import CommonHelper from '../../utils/common-helper';
import DateUtils from "../../utils/date-utils";

import EuiTooltip from "../../eui-components/tooltip";
import VehicleApprovalDetails from "./VehicleApprovalDetails";



const VehicleApprovalTable = (props) => {
    const mod = useSelector(state => state.vehicle.mod);

    const { loading ,vehicleUnderReviewData,id} = props
    const [isLoading, changeIsLoading] = useState(loading);
    const dispatch = useDispatch();
   
    const columns = [
        {
            title: "Vehicle ID",
            key: "id",
            sort: "desc",
            width: "4%",
        },
        {
            title: "Chassis Number",
            key: "chassisNumber",
            sort: "none",
            width: "8%"
        },
         {
            title: "Reqistration Plate No",
            key: "registrationPlateNo",
            width: "5%"
        },
        {
            title: "Manufacturer",
            key: "vehicleManufacturer",
            width: "6%"
        },
        {
            title: "Model Name",
            key: "vehicleModelName",
            sort: "none",
            width: "6%"
        },
        {
            title: "Submit Datetime",
            key: "submitDatetimeUtc",
            onCreatedCell: (td, cellData) => {
        
                td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm");
            },
            sort: "none",
            width: "4%"
        },
        {
            title: "Submit User",
            key: "submitUserName",
            sort: "none",
            width: "4%"
        },
        
        {
            title: "Country",
            key: "countryName",
            sort: "none",
            width: "4%",
            notSearchable: true
        },
        {
            title: "Asp Company Name",
            key: "aspCompanyName",
            width: "15%",
        },
        {
            title: "Is Complaint",
            key: "isVehicleCompliant",
            width: "6%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML =cellData==="Compliant" ? `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>${cellData}</div>`:
                    `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${cellData}</div>`;
            
                }
            },
        },
        {
            title: "",
            key: "id",
            hideFilter: true,
            notSearchable: true,
            width: "2%",
            onCreatedCell: (td, cellData) => {
          
                if (cellData) {
                    const action = `<span class="tooltip"><i id="vehicleid_${cellData?.id}" style="font-weight: bold" class="icon icon-eye color-blue clickable p-1 text-lg"></i><span class="message top-end">Approve/ Reject Vehicle </span></span>`
                    td.innerHTML =`${action}`;
                    td.querySelector("#vehicleid_" + cellData?.id).addEventListener('click', () => openVehicleDetail(cellData, constants.pageMods.VIEW_ONLY))
                }
                else
                    td.innerHTML = "";
            }
        }
    ];
  

    const openVehicleDetail = useCallback((model, mod) => {
        if (mod !== constants.pageMods.ADD) {
            const id = model?.id || model;
            changeIsLoading(true);
            
            ApiClient.post("Vehicle/GetVehicle", { id })
                .then(response => {
                    if (response.data) {
                        dispatch({ type: "SET_CURRENT_VEHICLE", payload: response.data });
                        dispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.VIEW_ONLY });
                    } 
                    changeIsLoading(false);
                })
                .catch(error => {
                    console.error(error);;
                    changeIsLoading(false);
                    CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
                });
   
        } else {
            dispatch({ type: "SET_CURRENT_VEHICLE", payload: { id: 0 } });
            dispatch({ type: "CHANGE_VEHICLE_MOD", payload: mod });
        }
        // dispatch({ type: "CHANGE_VEHICLE_APPROVAL_MOD", payload: mod });
        dispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.EDIT });


    });
    const getTableActions = useCallback(() => {
        return (<>
            <EuiTooltip title="Refresh">
                <i   className="icon icon-reload icon-lg icon-frame m-1" onClick={() => props.getAllVehicleUnderReview()}></i>
            </EuiTooltip>
        </>);
    });

    useEffect(() => {
        // === constants.pageMods.VIEW_ONLY
            
    }, [mod])
    
    return (
       <>
     {/*  eslint-disable-next-line react/jsx-pascal-case    */}

        {
            mod===constants.pageMods.VIEW_ONLY ?
            <VehicleApprovalDetails  getAllVehicleUnderReview={props.getAllVehicleUnderReview} /> :
            // eslint-disable-next-line react/jsx-pascal-case
            <ObseleteEuiTable
            key={vehicleUnderReviewData}
            classes="tiny split-striped"
            columns={columns}
            data={vehicleUnderReviewData}
            exportExcel={true}
            excelFileName={"Vehicle_List" + new Date().toISOString()}
            Reportdata={vehicleUnderReviewData}
            actionButton={getTableActions()}
            id={id}
            sortable={true}
            paginated={true}
            filter={true}
            rowsPerPage={10}
            searchStyle={{ width: 300 }}
            viewActionButton={true}
            search={true}
            placeholder="Search Vehicle..."
            viewActionIcon={true}>
        </ObseleteEuiTable>
        }
     
       
    
  
       </>
      
        )
}
export default VehicleApprovalTable;