import React from "react";
import { connect } from "react-redux";

import EuiRow from "../../eui-components/row";
import EuiSwitch from "../../eui-components/switch";
import constants from "../../utils/constants";
import EuiTile from "../../eui-components/tile";
import ApiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import PageHelper from "../../utils/page-helper";
import EuiAccordion from "../../eui-components/accordion";
import EuiTextArea from "../../eui-components/textarea";
import EuiCheckbox from "../../eui-components/checkbox";
import CommonHelper from "../../utils/common-helper";


class ActivityFeatures extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editEnabled: false,
            isEnableOnsiteGalleryPhotoVerification: false,
            isLoading: false,
            logColumns: [{
                title: "Log Date Time",
                key: "dateTimeUtc",
                width: "25%"
            },
            {
                title: "Log User",
                key: "userName",
                width: "10%"
            },
            {
                title: "Log Detail",
                key: "logDetail",
                width: "30%"
            },
            ],
            userAllowedDropCamera: [],
            activity: props.activityData.activity,
            isAllowed: false
        }
    }
    getAllUsersAllewod = () => {

        ApiClient.get("Activity/GetUsersAllowedDropCameraByUserId", { id: parseInt(this.props.userData.userData.userId) })
            .then(response => {
                this.setState({ userAllowedDropCamera: { ...response.data } })

            })
            .catch(error => {
                console.error(error);;
            });

    }

    componentDidMount() {
        PageHelper.getCoordinates();
        this.getAllUsersAllewod();

    }

    handleChange = (e) => {
        const target = e.target;
        let value = target.checked;
        const name = target.name;
        const activity = { ...this.state.activity };
        if (name === 'exemptionReason' || name === 'isHighRisk' || name === 'isExempted') {
            value = target.value;
        }
        activity[name] = value;
        this.setState({ activity });
    }

    saveChanges = () => {
        const activity = this.state.activity;
        this.props.setActivity(activity);
        let visitor = CommonHelper.getVisitorData();
        const request = {
            activityId: activity.id,
            disableOnsiteGps: activity.disableOnsiteGps,
            disablePhotoAuth: activity.disablePhotoAuth,
            disableTeamVerification: activity.disableTeamVerification,
            disableGpsRecordingInDb: activity.disableGpsRecordingInDb,
            autoViApproval: activity.autoViApproval,
            isHighRisk: activity.isHighRisk,
            enableOnsiteGalleryPhotoVerification: activity.enableOnsiteGalleryPhotoVerification,

            userId: parseInt(this.props.userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: "disableOnsiteGps: " + activity.disableOnsiteGps +
                " - disablePhotoAuth: " + activity.disablePhotoAuth +
                " - disableTeamVerification: " + activity.disableTeamVerification +
                " - disableGpsRecordingInDb: " + activity.disableGpsRecordingInDb +
                " - autoViApproval: " + activity.autoViApproval +
                " - enableOnsiteGalleryPhotoVerification: " + activity.enableOnsiteGalleryPhotoVerification +
                " - isHighRisk: " + activity.isHighRisk,
        };
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/ChangeActivityFeatures", request)
                .then(response => {
                    if (!response.data) {
                        toast.error(constants.errorMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER });
                        this.setState({ isLoading: false });
                    } else {
                        toast.success(constants.processPozitiveMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER });
                        this.setState({ editEnabled: false, isLoading: false });
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    isEditable = () => {
        return (this.props.userData.userData.jobRole === constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR ||
            this.props.userData.userData.jobRole === constants.userRoles.ERICSSON_IM ||
            this.props.userData.userData.jobRole === constants.userRoles.ADMIN) &&
            this.props.activityData.activity.acceptanceStatusId != constants.acceptanceStatusTypes.ACCEPTED;
    }

    onCancel = (flag) => {
        this.setState({ activity: this.props.activityData.activity });
        if (flag) {
            this.setState({ editEnabled: false })
        }
        else {
            this.setState({ editEnabled: false, isAllowed: false })
        }
    }

    saveExemption = () => {
        const activity = this.state.activity;
        this.props.setActivity(activity);
        const request = {
            activityId: activity.id,
            isExempted: activity.isExempted ? 1 : 0,
            exemptionReason: activity.exemptionReason
        };
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/ChangeExemption", request)
                .then(response => {
                    if (!response.data) {
                        toast.error(constants.errorMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER });
                        this.setState({ isLoading: false });
                    } else {
                        toast.success(constants.processPozitiveMessage, { containerId: "AD", position: toast.POSITION.TOP_CENTER });
                        this.setState({ editEnabled: false, isLoading: false });
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });

    }

    renderContent = () => {
        const activityFeatures = [{
            id: "activityFeaturesItem",
            title: "Feature Activation",
            content: <EuiTile tileStyle={{ padding: 5 }}>
                {this.isEditable() ?
                    <EuiRow className="flex-end-content p-1">
                        <button className="btn primary" onClick={() => this.setState({ editEnabled: true, isAllowed: this.state.userAllowedDropCamera.isAllowed })}>Edit</button>
                    </EuiRow>
                    : null
                }
                <EuiSwitch
                    checked={this.state.activity.disableOnsiteGps === true}
                    name="disableOnsiteGps"
                    onChange={(e) => this.handleChange(e)}
                    dataEnabled="On-Site Team Validation Disabled"
                    dataDisabled="On-Site Team Validation Enabled"
                    className="p-2"
                    disabled={!this.state.editEnabled}
                />
                <EuiSwitch
                    checked={this.state.activity.disableTeamVerification === true}
                    name="disableTeamVerification"
                    onChange={(e) => this.handleChange(e)}
                    dataEnabled="Team Verification Disabled"
                    dataDisabled="Team Verification Enabled"
                    className="p-2"
                    disabled={!this.state.editEnabled}
                />
                <EuiSwitch
                    checked={this.state.activity.disablePhotoAuth === true}
                    name="disablePhotoAuth"
                    onChange={(e) => this.handleChange(e)}
                    dataEnabled="Photo Authentication Disabled"
                    dataDisabled="Photo Authentication Enabled"
                    className="p-2"
                    disabled={true}
                />
                <EuiSwitch
                    checked={this.state.activity.disableGpsRecordingInDb === true}
                    name="disableGpsRecordingInDb"
                    onChange={(e) => this.handleChange(e)}
                    dataEnabled="GPS Recording in Database Disabled"
                    dataDisabled="GPS Recording in Database Enabled"
                    className="p-2"
                    disabled={true}
                />
                <EuiSwitch
                    checked={this.state.activity.enableOnsiteGalleryPhotoVerification === true}
                    name="enableOnsiteGalleryPhotoVerification"
                    onChange={(e) => this.handleChange(e)}
                    dataEnabled="Enable Onsite Gallery Photo Verification"
                    dataDisabled="Enable Onsite Gallery Photo Verification"
                    className="p-2"
                    disabled={!this.state.isAllowed && !this.state.editEnabled}

                />
                <EuiSwitch
                    checked={this.state.activity.autoViApproval === true}
                    name="autoViApproval"
                    onChange={(e) => this.handleChange(e)}
                    dataEnabled="Enable Auto VI Approval"
                    dataDisabled="Enable Auto VI Approval"
                    className="p-2"
                    disabled={!this.state.editEnabled}

                />
                <EuiCheckbox
                    name="isHighRisk"
                    className="m-2 flex-start-content pl-1 pr-1"
                    id="isHighRiskId"
                    label="High Risk Activity"
                    refValue={this.state.activity.isHighRisk}
                    disabled={!this.state.editEnabled}
                    onChange={(e) => this.handleChange(e)}
                />
                {this.state.editEnabled ?
                    <EuiRow className="flex-end-content p-1">
                        <button className={"btn secondary " + (this.state.isLoading ? "disabled" : "")} onClick={this.onCancel}>Cancel</button>
                        <button className={"btn primary " + (this.state.isLoading ? "loading disabled" : "")} onClick={() => this.saveChanges()}>Save</button>
                    </EuiRow>
                    : null
                }
            </EuiTile>
        },
        {
            id: "activityExemptionsItem",
            title: "Exemption",
            content: <EuiTile tileStyle={{ padding: 5 }}>
                {this.isEditable() ?
                    <EuiRow className="flex-end-content p-1">
                        <button className="btn primary" onClick={() => this.setState({ editEnabled: true })}>Edit</button>
                    </EuiRow>
                    : null
                }
                <EuiCheckbox
                    name="isExempted"
                    disabled={!this.state.editEnabled}
                    className="m-1"
                    id="isExemptedActivity"
                    label="Exempted"
                    refValue={this.state.activity.isExempted}
                    onChange={(e) => this.handleChange(e)}
                />
                <EuiTextArea
                    className="p-1 w-10"
                    fullwidth
                    readOnly={!this.state.editEnabled && !this.state.activity.isExempted}
                    style={{ minHeight: 150 }}
                    id="exemptionReasonActivity"
                    name="exemptionReason"
                    label="Exemption Reason"
                    labelstyle={{ fontWeight: 500 }}
                    onChange={(e) => this.state.editEnabled && this.state.activity.isExempted && this.handleChange(e)}
                    value={this.state.activity.isExempted ? this.state.activity.exemptionReason : "---"} />
                {this.state.editEnabled ?
                    <EuiRow className="flex-end-content p-1">
                        <button className={"btn secondary " + (this.state.isLoading ? "disabled" : "")} onClick={() => this.onCancel(true)}>Cancel</button>
                        <button className={"btn primary " + (this.state.isLoading ? "loading disabled" : "")} onClick={() => this.saveExemption()}>Save</button>
                    </EuiRow>
                    : null
                }
            </EuiTile>
        },
        ]
        return <EuiAccordion id="activityFeaturesAccordion"
            style={{ width: "100%", overflow: "auto", height: 475 }}
            items={activityFeatures}
        />;
    }

    render() {
        return (this.renderContent())
    }
}

const mapStateToProps = state => ({
    userData: state.user,
    navbarData: state.navigation,
    activityData: state.activity,
});
const mapDispatchToProps = dispatch => {
    return {
        setActivity: (activity) => dispatch({ type: "SET_ACTIVITY", payload: activity }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityFeatures);