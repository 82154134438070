import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { IconButton, Tooltip } from "@material-ui/core";
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PageHelper from '../utils/page-helper';
import constants from '../utils/constants';
import EuiSwitch from '../eui-components/switch';
import SasHelper from "../utils/sas-helper";


const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        background: "#2B2B2B"
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(15) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(20) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(1, 0),
    },
    list: {
        fontSize: 25
    },
    info: {
        listStyleType: "none",
        paddingInlineStart: 0,
        color: "#bbb !important",
        paddingTop: 8,
        lineHeight: "1em",
        fontSize: 12,
        whiteSpace: "normal",
        marginTop: 5
    },
    listItemIcon: {
        color: "#fff",
        fontSize: 25,
        minWidth: 44
    },
    listItemText: {
        color: "#bbb",
        fontSize: 18,
        fontWeight: 700,
        paddingBottom: 0,
        paddingTop: 0,
    },
    comment: {
        display: "flex",
        whiteSpace: "normal",
        color: "#fff",
        fontSize: 12,
        lineHeight: "1em",
        marginTop: 5
    }
}));

const MiniDrawer = ({
    currentSlide,
    onClose,
    onEdit,
    setZoom,
    zoom,
    setRotation,
    rotation,
    getOpen,
    comment,
    severityLevel,
    commentTitle,
    siteComment,
    status,
    acceptanceStatusId,
    showVI,
    toggleVI,
    sasToken
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(!open);
        getOpen(!open);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open
                    }),
                }}
                anchor="right"
            >
                <div className={classes.toolbar}>
                    <div style={{ width: "50%" }}>
                        <IconButton onClick={handleDrawerOpen}>
                            {!open ? <ChevronLeftIcon style={{ color: "#fff", fontSize: 25 }} /> : <ChevronRightIcon style={{ color: "#fff", fontSize: 25 }} />}
                        </IconButton>
                    </div>
                    <div style={{ width: "50%", justifyContent: "flex-end", display: "flex" }}>
                        <Tooltip id="exit" title="Close Image">
                            <IconButton onClick={onClose} >
                                <i style={{ color: "#fff" }} className={"icon " + "icon-cross-small"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <Divider />
                <List className={classes.list}>
                    <ListItem button key="zoom" style={{ display: (open ? "flex" : "grid"), justifyContent: "center", paddingTop: 0, paddingBottom: 3 }}>
                        <Tooltip id="downnload" title="Download Document">
                            <ListItemIcon className={classes.listItemIcon} onClick={() => window.open(SasHelper.addSasKey(currentSlide.documentPath, sasToken), "_blank")} style={{ padding: 8 }}>
                                <i className="icon icon-download-save" style={{ fontSize: 25 }}></i>
                            </ListItemIcon>
                        </Tooltip>
                        {currentSlide && currentSlide.type === constants.documentTypes.IMAGE ?
                            <>
                                <Tooltip id="zoom" title="Zoom In Image">
                                    <ListItemIcon className={classes.listItemIcon} onClick={() => setZoom(zoom + 0.2)} style={{ padding: 8 }}>
                                        <i className={"icon  icon-zoom-in"} />
                                    </ListItemIcon>
                                </Tooltip>
                                <Tooltip id="zoom" title="Zoom Out Image">
                                    <ListItemIcon className={classes.listItemIcon} onClick={() => setZoom((zoom !== 1 ? zoom - 0.2 : 1))} style={{ padding: 8 }}>
                                        <i className={"icon icon-zoom-out"} />
                                    </ListItemIcon>
                                </Tooltip>
                                <Tooltip id="rotate" title="Rotate Image">
                                    <ListItemIcon className={classes.listItemIcon} onClick={() => setRotation(rotation + 90 <= 270 ? rotation + 90 : 0)} style={{ padding: 8 }}>
                                        <Rotate90DegreesCcwIcon style={{ fontSize: 25 }} />
                                    </ListItemIcon>
                                </Tooltip>
                            </>
                            :
                            null
                        }
                    </ListItem>

                    {currentSlide && currentSlide.type === constants.documentTypes.IMAGE && currentSlide.isVIImage ?
                        <ListItem button key="viSwithListItem" style={{ paddingTop: 0, paddingBottom: 0, height: 25 }}>
                            <Tooltip id="viSwitch" title="Show VI Inspection Result">
                                <EuiSwitch
                                    id="ViSwitchDrawer"
                                    onChange={() => { toggleVI(!showVI); }}
                                    checked={showVI}
                                    dataEnabled="VI Inspection Result is ON"
                                    dataDisabled="VI Inspection Result is OFF"
                                    dataStyle={{ color: "yellow" }}
                                    style={{ paddingLeft: 42 }}
                                    name="ViSwitchDrawer"
                                    disabled={!currentSlide.isVIDrawing}
                                />
                            </Tooltip>
                        </ListItem>
                        : null
                    }

                    <ListItem button key="info">
                        <Tooltip id="info" title={currentSlide && currentSlide.title}>
                            <ListItemIcon className={classes.listItemIcon}  > <InfoOutlinedIcon style={{ fontSize: 25 }} /></ListItemIcon>
                        </Tooltip>
                        {open ?
                            <ListItemText
                                className={classes.listItemText}
                                primary={severityLevel ? "Information" : ""}
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.info}>
                                            {currentSlide && currentSlide.title}
                                        </span>
                                        <span className={classes.info}>
                                            {currentSlide && currentSlide.uploadDetails}
                                        </span>
                                        <span className={classes.info}>
                                            {currentSlide && currentSlide.additionalInfo}
                                        </span>
                                    </React.Fragment>
                                }
                            />
                            :
                            null
                        }
                    </ListItem>
                    {severityLevel ?
                        <ListItem button key="severityLevel" >
                            <Tooltip id="severity" title="Severity Level">
                                <ListItemIcon className={classes.listItemIcon} >{PageHelper.getSeverityIcon(severityLevel)}</ListItemIcon>
                            </Tooltip>
                            <ListItemText className={classes.listItemText} primary={severityLevel} />
                        </ListItem>
                        :
                        null
                    }
                    {siteComment ?
                        <ListItem button key="siteAspComments" >
                            <Tooltip id="siteComment" title={siteComment ?? "Site ASP Comments"}>
                                <ListItemIcon className={classes.listItemIcon}> <i className={"icon " + "icon-message-single"} /></ListItemIcon>
                            </Tooltip>
                            {open ?
                                <ListItemText
                                    className={classes.listItemText}
                                    primary={"Site ASP Comments"}
                                    secondary={
                                        <React.Fragment>
                                            <span className={classes.comment}>
                                                {siteComment}
                                            </span>
                                        </React.Fragment>
                                    }
                                />
                                :
                                null
                            }
                        </ListItem>
                        : null
                    }
                    <ListItem button key="status">
                        {open ? status
                            : <Tooltip id="statusIcon" title={PageHelper.mapRscStatus(acceptanceStatusId)}>
                                <ListItemIcon className={classes.listItemIcon}>
                                    <i className={"icon icon-alarm-level4 color-" + PageHelper.renderActivityStatusColor(acceptanceStatusId)} />
                                </ListItemIcon>
                            </Tooltip>}
                    </ListItem>
                    <ListItem button key="customerComment" >
                        <Tooltip id="comment" title={comment || "Comments"}>
                            <ListItemIcon className={classes.listItemIcon}> <i className={"icon " + "icon-message-single"} /></ListItemIcon>
                        </Tooltip>
                        {open ?
                            <ListItemText
                                className={classes.listItemText}
                                primary={commentTitle ?? "Comments"}
                                secondary={
                                    <React.Fragment>
                                        <span className={classes.comment}>
                                            {comment}
                                        </span>
                                    </React.Fragment>
                                }
                            />
                            :
                            null
                        }
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}
export default MiniDrawer;
