import { GridList, GridListTile } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SasHelper from '../../../utils/sas-helper';

export default function ImagesPreviewEHS(props) {

const {tileData, onClickImage}=props;
const [sasToken, setSasToken] = useState({});

  useEffect(() => {
    SasHelper.getSasKey().then((response) => {
      setSasToken(response);
    });

  }, []);
  return (  <GridList cellHeight={300} spacing={2} cols={2} >
      {tileData.map((tile, index) => (
          <GridListTile key={tile.id} cols={1} rows={1} 
            style={{ width: "auto", margin: "0px 5px" }}>
              <LazyLoadImage
                id={tile.id} 
                src={SasHelper.addSasKey(tile.posterPath, sasToken)} 
                alt={tile.name}
                height="300px" 
                onClick={() => onClickImage && onClickImage(tile)} />
          </GridListTile>
      ))}
  </GridList>)
}
