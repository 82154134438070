import React, {  useEffect, useState } from "react";
import { connect } from "react-redux";
import EuiAccordion from "../../../eui-components/accordion";
import EuiRow from "../../../eui-components/row";
import EuiTile from "../../../eui-components/tile";

import VehicleInformationEHS from "./VehicleInformationEHS";
import VehicleMonthlyInspectionsEHS from "./VehicleMonthlyInspectionsEHS";
import VehicleDailyInspectionsEHS from "./VehicleDailyInspectionsEHS";
import Cookies from "js-cookie";
import EuiTabs from "../../../eui-components/tabs";


 function VehiclesRecordsEHS(props) {
    const [isPassedDailyInspection, setIsPassedDailyInspection] = useState(false)
    const [isPassedMonthlyInspections, setIsPassedMonthlyInspections] = useState(false)

    const updateTabsContent = (item,index) => {
        const ehsPingId=props.ehsPingData.ehsPing.id||props.liveStreamData?.liveSessionInfo?.sessionId
console.log("itemitem",item)
        const monthlyInspection=item.vehicleInspection
        const dailyInspection=item.vehicleInspection
        ///console.log("item.vehicleInspection",item.vehicleInspection,dailyInspection)
             const isGreen= item.vehicle?.isVehicleCompliant === 'Compliant' && item.vehicle.vehicleStatus===3 && isPassedDailyInspection && isPassedMonthlyInspections
     
        const viewContent = [{
            value: 1,
            children: <VehicleInformationEHS vehicleReducer={item?.vehicle} vehicleAudit={dailyInspection[0].vehicle} id="vehicleInfoTab"  />, // openStatusDialog={openStatusDialog}
            selected: "selected",
            contentStyle: { width: "100%", maxHeight: 650 }
        },
        {
            value: 2,
            children: <VehicleDailyInspectionsEHS 
                    dailyInspection={dailyInspection}
                    setIsPassedDailyInspection={setIsPassedDailyInspection} vehicleId={item?.vehicle.id} EHSPingId={ehsPingId} />,
            selected: "",
            contentStyle: { width: "100%", maxHeight: 650 }
        },
        {
            value: 3,
            children: <VehicleMonthlyInspectionsEHS 
                     monthlyInspection={monthlyInspection}
                     key={index} vehicleItem={item?.vehicle} setIsPassedMonthlyInspections={setIsPassedMonthlyInspections} vehicleId={item.vehicle.id}  EHSPingId={ehsPingId} />,
            selected: "",
            contentStyle: { width: "100%", maxHeight: 650 }
        }
        ]

        return viewContent;
    }
    
    const getVerificationIcon=(isGreen) => { 
      return  true? <i className="icon icon-alarm-level3 text-sm color-green m-1  icon-normal-weight"></i>:
                       <i className="icon icon-alarm-level3 text-sm color-red m-1    icon-normal-weight"></i>

    
                    }
//       function getVerificationStatusVehicle(vehicleStatus){
//     // eslint-disable-next-line default-case
//     switch (vehicleStatus) {
//         case 1:
//             return <i className="icon icon-alarm-level3 text-sm color-yellow m-1 icon-normal-weight"></i>
//         case 2:
//             return <i className="icon icon-alarm-level3 text-sm color-blue m-1 icon-normal-weight"></i>
//         case 3:
//             return <i className="icon icon-alarm-level3 text-sm color-green m-1 icon-normal-weight"></i>
//         case 4:
//             return <i className="icon icon-alarm-level3 text-sm color-red m-1 icon-normal-weight"></i>                 
//         case 5:
//             return <i className="icon icon-alarm-level3 text-sm color-gray m-1 icon-normal-weight"></i>                          
//     }
// }





const renderResources=() => {
    // const ehsPingId=props.ehsPingData.ehsPing.id||props.liveStreamData?.liveSessionInfo?.sessionId
if(props.vehicles.length===0){
    Cookies.remove('vehicleStatusIsGreen')

}
    const resources = props.vehicles.map((item ,index)=> { 
        const isGreen= item.vehicle?.isVehicleCompliant === 'Compliant' && item.vehicle.vehicleStatus===3 && isPassedDailyInspection && isPassedMonthlyInspections
        Cookies.set('vehicleStatusIsGreen', isGreen)

        const icon=getVerificationIcon(isGreen)
        const res = {
            title: "Chassis Number :"+ item.vehicle.chassisNumber + " | " + "Registration Plate No : "+item.vehicle.registrationPlateNo ,
            titleIcon:icon,
            titleStyle: { paddingLeft: 0, fontWeight: 500, fontSize: 16 },
            content: 
            <EuiTabs
            titleColumnStyle={{ marginBottom: "10px" }}
            contentStyle={{ width: "100%" }}
            id="VehicleDetailsTabs"
            titles=
            {[
          { id: 1, value: 1, name: "Vehicle information", selected: "selected", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } },
            {
            id: 2, value: 2, name: "Daily pre-trip Audit", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" }
            },
            {
            id: 3, value: 3, name: "Vehicle Inspection", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" }
            },
            ]}
            contents={updateTabsContent(item,index)}
        />

            // <EuiAccordion 
            // id={"vehiclesAccordion_" + item.vehicle.id} 
            // style={{ width: "100%" }} 
            // items={[
            //     {
            //         title: "Vehicle information",
            //         titleIcon:getVerificationStatusVehicle(item.vehicle.vehicleStatus),
            //         titleStyle: { fontWeight: 500 },
            //         content: <VehicleInformationEHS vehicleReducer={item.vehicle} /> 
            
            //     },
            //     {
            //         title: "Daily pre-trip check",
            //         titleIcon:getVerificationIcon(isPassedDailyInspection),
            //         titleStyle: { fontWeight: 500 },
            //         content: <VehicleDailyInspectionsEHS 
            //         dailyInspection={dailyInspection}
            //     },
            //     {
            //          title: "Monthly Inspections",
            //          titleIcon:getVerificationIcon(isPassedMonthlyInspections),
            //          titleStyle: { fontWeight: 500 },
            //          content: <VehicleMonthlyInspectionsEHS 
            //          monthlyInspection={monthlyInspection}
            //     },
            // ]}
            // >
            // </EuiAccordion>
        };
        return res;
    });
    return props.vehicles.length > 0 ? <EuiAccordion id={"resourcesAccordion"} style={{ width: "100%" }} items={resources}></EuiAccordion>
: <div className="flex-middle-content m-3 w-10 text-lg">No Vehicles found for this Ehs ping.</div>
}
    
    return (<EuiRow>
        <EuiTile>
            {renderResources()}
        </EuiTile>
   
    

    </EuiRow>
    );
}

const mapStateToProps = state => ({ 
    userData: state.user, 
    sessionData: state.session,
    liveStreamData: state.liveStream,
    vehicles:state.vehicle?.vehicleList,
    ehsPingData: state.ehsPing, 

});

export default connect(
    mapStateToProps
)(VehiclesRecordsEHS);

