import React from 'react';
import { connect } from 'react-redux';
import { withLayout } from "../../layout";
import EuiRow from "../../eui-components/row";
import { withRouter } from 'react-router-dom';
import EuiTextField from "../../eui-components/textfield";
import EuiSwitch from "../../eui-components/switch";
import EuiDatepicker from "../../eui-components/datepicker";
import { toast } from 'react-toastify';
import EuiTile from '../../eui-components/tile';
import SasHelper from '../../utils/sas-helper';


class CreateCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            selectedCountry: "",
            logo: "",
            logoName: "",
            logoFile: {},
            countries: [],
            countryId: "",
            customerName: "",
            ericssonEritopAspAdoptionDate: "",
            customerEritopAspAdoptionDate: "",
            hideGPS: "",
            disableOnsiteGPS: "",
            disableTeamVerification: "",
            disablePhotoAuth: "",
            loading: true,
            id: "",
            edited: "",
        }
    }

    componentDidMount() {
        
        
        this.setState({
            loading: false,
            customerName: this.props.customersData.selectedCustomer.name,
            ericssonEritopAspAdoptionDate: this.props.customersData.selectedCustomer.ericssonEritopAspAdoptionDate,
            customerEritopAspAdoptionDate: this.props.customersData.selectedCustomer.customerEritopAspAdoptionDate,
            disableOnsiteGPS: this.props.customersData.selectedCustomer.disableOnsiteGPS,
            disableTeamVerification: this.props.customersData.selectedCustomer.disableTeamVerification,
            disableGpsRecordingInDb: this.props.customersData.selectedCustomer.disableGpsRecordingInDb,
            disablePhotoAuth: this.props.customersData.selectedCustomer.disablePhotoAuth,
            hideGPS: this.props.customersData.selectedCustomer.hideGPS,
            logo: this.props.customersData.selectedCustomer.logo,
            eriCustomerId: this.props.customersData.selectedCustomer.eriCustomerId,
            systemrecordid: this.props.customersData.selectedCustomer.systemrecordid,
            countryId: this.props.customersData.selectedCustomer.CountryId,
            id: this.props.customersData.selectedCustomer.id

        });
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
    }





    handleLogo = (e) => {
        const files = e.target.files;
        //var name = files[0].name;
        let reader = new FileReader();
        // var data = {};
        reader.onload = () => {
            const data = reader.result;

            this.setState({
                logoFile: data,
                logoName: files[0].name,
                logo: "",
            });
        };
        reader.readAsDataURL(files[0]);
    }

    save = (id, customerName,
        disableOnsiteGPS, disablePhotoAuth, disableTeamVerification, disableGpsRecordingInDb,
        hideGPS, customerEritopAspAdoptionDate, ericssonEritopAspAdoptionDate,
        systemrecordid, eriCustomerId, selectedCountry, logoFile, countryId, logoName) => {

        const customer = {
            id: id,
            customerName: customerName,
            ericssonEritopAspAdoptionDate: ericssonEritopAspAdoptionDate,
            customerEritopAspAdoptionDate: customerEritopAspAdoptionDate,
            disableOnsiteGPS: disableOnsiteGPS,
            disableTeamVerification: disableTeamVerification,
            disableGpsRecordingInDb: disableGpsRecordingInDb,
            disablePhotoAuth: disablePhotoAuth,
            hideGPS: hideGPS,
            logoFile: logoFile,
            logoName: logoName,
            eriCustomerId: eriCustomerId,
            systemrecordid: systemrecordid,
            countryId: countryId,
            selectedCountry: selectedCountry

        }
        this.setState({
            edited: true,
        })
        var logDetail = "";
        Object.keys(customer).forEach(e => {

            
            
            if (e == "customerName") {
                
                if (customer[e] !== this.props.customersData.selectedCustomer["name"]) {
                    logDetail += "old " + e + " = " + this.props.customersData.selectedCustomer["name"] + ", new " + e + " = " + customer[e] + "; ";
                    
                }
            }
            else if (e == "disableOnsiteGPS" || e == "disablePhotoAuth" || e == "disableTeamVerification" || e == "disableGpsRecordingInDb" || e == "hideGPS") {
                //  
                if (customer[e] !== this.props.customersData.selectedCustomer[e]) {
                    logDetail += "old " + e + " = " + this.props.customersData.selectedCustomer[e] + ", new " + e + " = " + customer[e] + "; ";
                    
                }
            }
            else if (e == "logoFile" || e == "logoName") {
                if (e === "logoName" && customer["logoName"])
                    logDetail += "old " + e + " = " + this.props.customersData.selectedCustomer["logo"] + ", new " + e + " = " + customer["logoName"] + "; ";

            }

            else {
                if (e !== "selectedCountry" && e !== "systemrecordid") {
                    
                    if (customer[e] !== this.props.customersData.selectedCustomer[e]) {
                        logDetail += "old " + e + " = " + this.props.customersData.selectedCustomer[e] + ", new " + e + " = " + customer[e] + "; ";
                        
                    }
                }
            }
        })


        this.props.saveChanges(id, customerName,
            disableOnsiteGPS, disablePhotoAuth, disableTeamVerification, disableGpsRecordingInDb,
            hideGPS, customerEritopAspAdoptionDate, ericssonEritopAspAdoptionDate,
            systemrecordid, eriCustomerId, selectedCountry, logoFile, countryId, logoName, logDetail);
    }

    cancel = (id, selectedCountry, event) => {
        this.props.cancel(id, selectedCountry);
        event.preventDefault();
        event.stopPropagation();


    }
    checkData = () => {
        if (this.props.customersData.mode === "edit") {
            if (this.props.customersData.editCheckData || this.props.customersData.selectedCustomer.id !== this.state.id || this.state.edited || (!this.state.ericssonEritopAspAdoptionDate && !this.state.customerEritopAspAdoptionDate && !this.state.customerName &&
                (this.props.customersData.selectedCustomer.ericssonEritopAspAdoptionDate || this.props.customersData.selectedCCustomer.customerEritopAspAdoptionDate))) {
                this.setState({
                    customerName: this.props.customersData.selectedCustomer.name,
                    ericssonEritopAspAdoptionDate: this.props.customersData.selectedCustomer.ericssonEritopAspAdoptionDate,
                    customerEritopAspAdoptionDate: this.props.customersData.selectedCustomer.customerEritopAspAdoptionDate,
                    disableOnsiteGPS: this.props.customersData.selectedCustomer.disableOnsiteGPS,
                    disableTeamVerification: this.props.customersData.selectedCustomer.disableTeamVerification,
                    disableGpsRecordingInDb: this.props.customersData.selectedCustomer.disableGpsRecordingInDb,
                    disablePhotoAuth: this.props.customersData.selectedCustomer.disablePhotoAuth,
                    hideGPS: this.props.customersData.selectedCustomer.hideGPS,
                    logo: this.props.customersData.selectedCustomer.logo,
                    eriCustomerId: this.props.customersData.selectedCustomer.eriCustomerId,
                    systemrecordid: this.props.customersData.selectedCustomer.systemrecordid,
                    countryId: this.props.customersData.selectedCustomer.CountryId,
                    id: this.props.customersData.selectedCustomer.id


                })
                this.props.CheckDataEdit(false);
            }


        }
    }

    render() {
        this.checkData();
        return (
            <EuiTile fullscreen>
                <EuiRow>
                    {(this.state.loading ? <div className="welcome">
                        <div className="loading large" style={{
                            position: 'absolute', left: '50%', top: '50%',

                            alignContent: "center", display: "flex", justifyContent: "flex-center"
                        }}></div></div> :

                        <div className="tile sm-12 lg-12 xl-12" id="data" >
                            {(this.props.customersData.mode == "initial" ?


                                < div className="flex-middle-content m-2 w-10" style={{ fontSize: "18px", color: "gray" }}>Select a customer to preview information..</div>

                                :
                                <EuiRow>
                                    <div className="column sm-12 md-12 lg-6">
                                        <FormField><EuiTextField style={{ marginRight: "0px", width: "90%" }} id="countryName"
                                            label="Country Name"
                                            name="name"
                                            // required
                                            value={this.props.customersData.selectedCountry}
                                            disabled
                                            labelstyle={{ fontSize: "15px" }}
                                        >

                                        </EuiTextField>
                                        </FormField>

                                        <FormField><EuiTextField style={{ marginRight: "0px", width: "90%" }}
                                            id="customerName"
                                            label="Customer Name"
                                            name="name"
                                            onChange={(e) => this.setState({ customerName: e.target.value })}
                                            value={(this.props.customersData.mode == "edit" ? this.state.customerName
                                                : this.props.customersData.selectedCustomer.name)}
                                            disabled={this.props.customersData.mode == "edit" ? false : true}
                                            labelstyle={{ fontSize: "15px" }}


                                        >

                                        </EuiTextField>
                                        </FormField>

                                        <FormField> <EuiDatepicker
                                            style={{ width: "60%" }}
                                            id="ericssonASPAdoptionDate"
                                            label="Eritop ASP Adoption Date"
                                            //disabled={this.state.session.isLiveStream === 0}
                                            dateStyle={{ marginRight: 6 }}
                                            // placeholder="YYYY-MM-DD"
                                            name="ericssonASPAdoptionDate"
                                            value={(this.props.customersData.mode == "edit" ? this.state.ericssonEritopAspAdoptionDate
                                                : (this.props.customersData.selectedCustomer.ericssonEritopAspAdoptionDate
                                                    ? this.props.customersData.selectedCustomer.ericssonEritopAspAdoptionDate : ""))}
                                            onChange={(e) => this.setState({ ericssonEritopAspAdoptionDate: e.target.value })}
                                            disabled={this.props.customersData.mode == "edit" ? false : true}
                                            labelStyle={{ fontSize: "15px" }}


                                        />
                                        </FormField>

                                        <FormField>
                                            <EuiDatepicker
                                                style={{ width: "60%" }}
                                                id="customerASPAdoptionDate"
                                                label="Eritop ASP Adoption Date For Remote Customer Acceptance"
                                                //disabled={this.state.session.isLiveStream === 0}
                                                dateStyle={{ marginRight: 6 }}
                                                placeholder="YYYY-MM-DD"
                                                name="customerASPAdoptionDate"
                                                value={(this.props.customersData.mode == "edit" ? this.state.customerEritopAspAdoptionDate
                                                    : (this.props.customersData.selectedCustomer.customerEritopAspAdoptionDate ?
                                                        this.props.customersData.selectedCustomer.customerEritopAspAdoptionDate : ""))}

                                                onChange={(e) => this.setState({ customerEritopAspAdoptionDate: e.target.value })}
                                                disabled={this.props.customersData.mode == "edit" ? false : true}
                                                labelStyle={{ fontSize: "15px", width: "100%" }}
                                            />
                                        </FormField>

                                        <FormField>
                                            <EuiDatepicker
                                                style={{ width: "60%" }}
                                                id="customerECAAdoptionDate"
                                                label="ECA Adoption Date For Remote Customer Acceptance"
                                                dateStyle={{ marginRight: 6 }}
                                                placeholder="YYYY-MM-DD"
                                                name="customerECAAdoptionDate"
                                                value={(this.props.customersData.mode == "edit" ? this.state.customerECAAdoptionDate
                                                    : (this.props.customersData.selectedCustomer.customerECAAdoptionDate ?
                                                        this.props.customersData.selectedCustomer.customerECAAdoptionDate : ""))}

                                                onChange={(e) => this.setState({ customerECAAdoptionDate: e.target.value })}
                                                disabled={this.props.customersData.mode == "edit" ? false : true}
                                                labelStyle={{ fontSize: "15px", width: "100%" }}
                                            />
                                        </FormField>


                                        <div className="flext-start-content">
                                            <FormFieldCheckBox>
                                                <EuiSwitch
                                                    dataEnabled="Hide GPS on photos and video recordings"
                                                    dataDisabled="Hide GPS on photos and video recordings"
                                                    name="GpsHide"
                                                    id="Hide GPS"
                                                    label="Hide GPS"
                                                    labelStyle={{ marginLeft: 2, width: "100%" }}
                                                    checked={(this.props.customersData.mode == "edit" ?
                                                        (this.state.hideGPS ? true : false)
                                                        : (this.props.customersData.selectedCustomer.hideGPS ?
                                                            true : false))}
                                                    onChange={(e) => this.setState({ hideGPS: e.target.checked })}
                                                    disabled={this.props.customersData.mode == "edit" ? false : true}

                                                />
                                            </FormFieldCheckBox>
                                            <FormFieldCheckBox>
                                                <EuiSwitch
                                                    dataEnabled="Disable GPS on-site team verification"
                                                    dataDisabled="Disable GPS on-site team verification"
                                                    name="onsiteGPS"
                                                    id="Onsite GPS enabled"
                                                    label="Disable Onsite GPS"
                                                    labelStyle={{ marginLeft: 2, width: "100%" }}
                                                    checked={(this.props.customersData.mode == "edit" ?
                                                        (this.state.disableOnsiteGPS ? true : false)
                                                        : (this.props.customersData.selectedCustomer.disableOnsiteGPS ?
                                                            true : false))}

                                                    onChange={(e) => this.setState({ disableOnsiteGPS: e.target.checked })}
                                                    disabled={this.props.customersData.mode == "edit" ? false : true}

                                                />
                                            </FormFieldCheckBox>
                                            <FormFieldCheckBox>
                                                <EuiSwitch
                                                    dataEnabled="Disable photo authentication"
                                                    dataDisabled="Disable photo authentication"
                                                    name="photoAuth"
                                                    id="photoAuth"
                                                    checked={(this.props.customersData.mode == "edit" ?
                                                        (this.state.disablePhotoAuth ? true : false)
                                                        : (this.props.customersData.selectedCustomer.disablePhotoAuth ?
                                                            true : false))}
                                                    onChange={(e) => this.setState({ disablePhotoAuth: e.target.checked })}
                                                    disabled={this.props.customersData.mode == "edit" ? false : true}
                                                />
                                            </FormFieldCheckBox>
                                            <FormFieldCheckBox>
                                                <EuiSwitch className="flext-start-content"
                                                    dataEnabled="Disable team verification"
                                                    dataDisabled="Disable team verification"
                                                    name="teamVerification"
                                                    id="teamVerification"
                                                    label="Enable team verification"
                                                    labelStyle={{ marginLeft: 2, width: "100%" }}
                                                    checked={(this.props.customersData.mode == "edit" ?
                                                        (this.state.disableTeamVerification ? true : false)
                                                        : (this.props.customersData.selectedCustomer.disableTeamVerification ?
                                                            true : false))}
                                                    onChange={(e) => this.setState({ disableTeamVerification: e.target.checked })}
                                                    disabled={this.props.customersData.mode == "edit" ? false : true}

                                                />
                                            </FormFieldCheckBox>
                                        </div>
                                        <FormFieldCheckBox>
                                            <EuiSwitch className="flext-start-content"
                                                dataEnabled="Disable GPS recording in DB"
                                                dataDisabled="Disable GPS recording in DB"
                                                name="gpsRecording"
                                                id="gpsRecording"
                                                label="Disabled GPS Recording"
                                                labelStyle={{ marginLeft: 2, width: "100%" }}
                                                checked={(this.props.customersData.mode == "edit" ?
                                                    (this.state.disableGpsRecordingInDb ? true : false)
                                                    : (this.props.customersData.selectedCustomer.disableGpsRecordingInDb ?
                                                        true : false))}
                                                onChange={(e) => this.setState({ disableGpsRecordingInDb: e.target.checked })}
                                                disabled={this.props.customersData.mode == "edit" ? false : true}

                                            />
                                        </FormFieldCheckBox>
                                    </div>
                                    <div className="column sm-12 md-12 lg-6">
                                        <FormField><EuiTextField style={{ marginRight: "0px", width: "90%" }} id="sessionName"
                                            label="System Record ID"
                                            name="systemrecordid"

                                            value={(this.props.customersData.mode == "edit" ? this.state.systemrecordid
                                                : (this.props.customersData.selectedCustomer.systemrecordid ?
                                                    this.props.customersData.selectedCustomer.systemrecordid : ""))}

                                            onChange={(e) => this.setState({ systemrecordid: e.target.value })}
                                            disabled
                                            labelstyle={{ fontSize: "15px" }}

                                        >
                                        </EuiTextField>
                                        </FormField>
                                        <FormField><EuiTextField style={{ marginRight: "0px", width: "90%" }} id="sessionName"
                                            label="Erisite Customer ID"
                                            name="name"
                                            value={(this.props.customersData.mode == "edit" ? this.state.eriCustomerId
                                                : (this.props.customersData.selectedCustomer.eriCustomerId ?
                                                    this.props.customersData.selectedCustomer.eriCustomerId : ""))}

                                            onChange={(e) => this.setState({ eriCustomerId: e.target.value })}
                                            disabled
                                            labelstyle={{ fontSize: "15px" }}

                                        >

                                        </EuiTextField>
                                        </FormField>

                                        <FormField>
                                            <label htmlFor="logo" style={{ fontSize: "15px" }}>
                                                Logo

                                            </label>

                                            <input type="file" name="file" onChange={(e) => this.handleLogo(e)} style={{ width: "90%" }}
                                                disabled={this.props.customersData.mode == "edit" ? false : true}
                                            />
                                            <img className="mt-2"
                                                style={{ width: "75%", height: "75%" }}
                                                src={(this.props.customersData.mode == "edit" ? this.state.logo : SasHelper.addSasKey(this.props.customersData.selectedCustomer.logo))}
                                                alt={(this.props.customersData.mode == "edit" ? this.state.customerName + " Logo" : this.props.customersData.selectedCustomer.name + " Logo")}
                                            />
                                        </FormField>


                                    </div>
                                    <EuiRow hidden={(this.props.customersData.mode !== "edit" ? true : false)}>
                                        <div className="column sm-12 md-12 lg-12 xl-12 filled" hidden={(this.props.customersData.mode !== "edit" ? true : false)}>

                                            <div className="wizard-footer" hidden={(this.props.customersData.mode !== "edit" ? true : false)}>
                                                <div className="item">
                                                    <button className="btn" onClick={(e) => { this.cancel(this.props.customersData.selectedCustomer.id, this.props.customersData.selectedCountry, e) }}>Cancel</button>
                                                </div>
                                                <div className="item" style={{ marginRight: "20px" }}>
                                                    <button className="btn primary" id="wizard-previous"
                                                        onClick={() => this.save(this.props.customersData.selectedCustomer.id, this.state.customerName,
                                                            this.state.disableOnsiteGPS, this.state.disablePhotoAuth, this.state.disableTeamVerification, this.state.disableGpsRecordingInDb,
                                                            this.state.hideGPS, this.state.customerEritopAspAdoptionDate, this.state.ericssonEritopAspAdoptionDate,
                                                            this.state.systemrecordid, this.state.eriCustomerId, this.props.customersData.selectedCountry, this.state.logoFile, this.props.customersData.selectedCustomer.countryId, this.state.logoName)}
                                                        style={{ width: "80px" }}>Save</button>

                                                </div >
                                            </div>
                                        </div>
                                    </EuiRow>

                                </EuiRow >)}
                        </div>)
                    }
                </EuiRow>
            </EuiTile>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, customersData: state.customers, sasToken: state.complaints.sasToken   });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setRowData: (rowData) => dispatch({ type: "SET_ROW_DATA", payload: rowData }),
        setCountryCustomersList: (countryCustomersList) => dispatch({ type: "SET_COUNTRY_CUSTOMERS_LIST", payload: countryCustomersList }),
        viewCustomer: ({ view, selected_customer, selected_country }) => dispatch({ type: "VIEW_CUSTOMER", payload: { view, selected_customer, selected_country } }),
        CheckDataEdit: (editCheckData) => dispatch({ type: "EDIT_CHECK_DATA", payload: editCheckData }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })

    };

};
const FormFieldCheckBox = (props) => (
    <div style={{ margin: 20, ...props.style }} className="field validate flex-start-content">
        {props.children}
    </div>
);
const FormField = (props) => (
    <div style={{ margin: 20, ...props.style }} className="field validate">
        {props.children}
    </div>
);
export default connect(
    mapStateToProps,
    mapDispatchToProps)(withRouter((CreateCustomer)));
