import CommonHelper from "../../utils/common-helper";
import TokenHelper from "../../utils/token-helper";

var userState = localStorage.getItem("userState");
var rscCenters = localStorage.getItem("rscCenters");
var rscPerson = localStorage.getItem("rscPerson");
var ecaConfig = localStorage.getItem("ecaConfig");
var webToken = localStorage.getItem("webToken");
let initialState = {
    userDetail: { id: 0 },
    userData: {
        username: "",
        jobRole: "",
        userId: "",
    },
    // login roles to be changed to populated array after user login
    token: "",
    loggedIn: false,
    rememberMe: false,
    loginRole: 0,
    resetPassword: false,
    userMail: "",
    rscPerson: { id: 0, checkin: false, lastCheckinDate: new Date().setHours(2), lastCheckoutDate: new Date() },
    loginRoles: [],
    users: [],
    customers: [],
    customersWithProjects: [],
    countries: [],
    rscCenters: [],
    ecaConfig: false,
    webToken: ""
};


if (userState) {
    initialState = JSON.parse(userState);
    TokenHelper.setToken(initialState.token);
}
if (rscCenters) {
    initialState.rscCenters = JSON.parse(rscCenters);
}
if (rscPerson) {
    initialState.rscPerson = JSON.parse(rscPerson);
}
if (webToken) {
    initialState.webToken = webToken;
}
if (ecaConfig) {
    initialState.ecaConfig = ecaConfig;
}

export default (state = initialState, action) => {
   

    switch (action.type) {
        
        case "LOGIN": {
                            
            const userState = action.payload;

            // Check if remember me is true
            if (action.payload.rememberMe) {
                const tempUserState = { ...state, ...userState };
                tempUserState.users = [];
                localStorage.setItem("userState", JSON.stringify(tempUserState));     
                // Saving visitor
                CommonHelper.saveVisit();
            }

            return {
                ...state,
                ...userState
            };
        }
        case "PASS_ID": {
            return {
                ...state,
                ID: action.payload.ID
            }
        }
        case "LOGOUT": {
            localStorage.clear();
            initialState = {
                userDetail: { id: 0 },
                userData: {
                    username: "",
                    jobRole: "",
                    userId: "",
                },
                token: "",
                loggedIn: false,
                rememberMe: false,
                loginRole: 0,
                userMail: "",
                password: "",
                rscPerson: { id: 0 },
                loginRoles: []
            };
            return initialState;
        }
        case "RESET_PASSWORD": {
            return {
                ...state,
                resetPassword: true
            }
        }
        case "SET_ALL_USERS": {
            return {
                ...state,
                users: action.payload
            }
        }
        case "SET_ALL_COUNTRIES": {
            return {
                ...state,
                countries: action.payload
            }
        }
        case "SET_ALL_CUSTOMERS": {
            return {
                ...state,
                customers: action.payload
            }
        }
        case "SET_ALL_CUSTOMERS_WITH_PROJECTS": {
            return {
                ...state,
                customersWithProjects: action.payload
            }
        }
        case "SET_RSC_CENTERS": {
            localStorage.setItem("rscCenters", JSON.stringify(action.payload));
            return {
                ...state,
                rscCenters: action.payload
            }
        }
        case "SET_RSC_PERSON": {
            localStorage.setItem("rscPerson", JSON.stringify(action.payload));
            return {
                ...state,
                rscPerson: action.payload
            }
        }
        case "SET_ECA_CONFIG": {
            localStorage.setItem("ecaConfig", action.payload);
            return {
                ...state,
                ecaConfig: action.payload
            }
        }
        case "SET_WEB_TOKEN": {
            localStorage.setItem("webToken", action.payload);
            return {
                ...state,
                webToken: action.payload
            }
        }
        case "SET_USER_REFRESHED": {
            return {
                ...state,
                userRefreshed: action.payload
            }
        }
        default: {
            return state;
        }
    }
};
