import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import { toast } from "react-toastify";
import ApiClient from "../../utils/api-client";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import SiteHelper from "../../utils/site-helper";


class SiteFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,                 
        }
    }

    componentDidMount() {        
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        
        const filter = { ...this.props.siteData.filter };
        filter[name] = value || "";
        this.props.setSiteFilter(filter);
    }

    getSites = (page, pageSize) => {
        const filter = this.props.siteData.filter;

        delete filter["page"];
        delete filter["pageSize"];

        if (!filter || Object.values(filter).every(x => (!x || x === ''))) {
            toast.error("Please, fill any field to get related sites", { containerId: "SS", position: toast.POSITION.TOP_CENTER });
            return;
        }

        const initialState = new Date();           
        filter.page = page;
        filter.pageSize = pageSize;
        filter.id = parseInt(filter.id ?? "0");
        filter.customerId = parseInt(filter.customerId ?? "0");
        
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/GetFilteredSites", filter)
                .then(response => {
                    const data = [...response.data.data];                   
                    SiteHelper.mapSites(data);
                    
                    this.setState({ isLoading: false }, () => { this.props.setFilteredSites({ data, totalCount: response.data.totalCount, initialState: initialState.getTime() }); });
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    render() {
        return (<EuiRow>
            <EuiTextField className="m-1 w-10" fullwidth id="siteIdFilter" name="id" label="System ID (=)" labelClass="color-gray"
                value={this.props.siteData.filter.id || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "id" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="siteCodeFilter" name="code" label="Site Code (=)" labelClass="color-gray"
                value={this.props.siteData.filter.code || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "code" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="siteNameFilter" name="name" label="Site Name (*)" labelClass="color-gray"
                value={this.props.siteData.filter.name || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "name" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="customerSiteIdFilter" name="customerSiteId" label="Customer Site ID (*)" labelClass="color-gray"
                value={this.props.siteData.filter.customerSiteId || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "customerSiteId" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="customerSiteNameFilter" name="customerSiteName" label="Customer Site Name (*)" labelClass="color-gray"
                value={this.props.siteData.filter.customerSiteName || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "customerSiteName" } })}
            />

            <EuiAutocompleteSelect
                className="m-1 w-10"
                fullwidth
                label={{ title: "Select Customer", className: "color-gray" }}
                id="customerFilterSelect"
                name="customerId"
                options={this.props.userData.customers || []}
                value={this.props.siteData.filter.customerId || 0}
                onChange={(e) => this.handleChange(e)}
                placeholder="---Select Customer---"
            />
            <button className={"btn primary m-1 " + (this.state.isLoading ? "loading disabled" : "")} onClick={() => this.getSites(1, 30)}>Search</button>
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, siteData: state.site });
const mapDispatchToProps = dispatch => {
    return {
        setFilteredSites: (filteredSites) => dispatch({ type: "SET_FILTERED_SITES", payload: filteredSites }),
        setSiteFilter: (filter) => dispatch({ type: "SET_SITE_FILTER", payload: filter }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteFilter); 