import React, { useEffect, useState } from 'react'
import EuiRow from '../../eui-components/row'
import EuiTimeline from '../../eui-components/timeline'
import DialogModal from '../../components/dialog'
import EuiLoading from '../../eui-components/loading'
import ApiClient from '../../utils/api-client'
import ObseleteEuiTable from '../../eui-components/obseleteTable';
import OperationTimeline from './OperationTimeline'
import VehicleHelper from '../../utils/vehicle-helper'
import  { filter } from 'lodash'
import DateUtils from '../../utils/date-utils'

import CommonHelper from "../../utils/common-helper";

export default function LogVehicle(props) {
const {currentVehicle}=props;
const [logsData, setLogsData] = useState([]);

const [historyDialog, openHistoryDialog] = useState(false);
const [logDetail, setLogDetail] = useState({});
const [isLoading, changeIsLoading] = useState(false);


useEffect(() => {
    if(logsData.length===0){
        changeIsLoading(true);
        const request={
              logTypeId : VehicleHelper.LogTypeId['VEHICLE'],
             logObjectId: currentVehicle.id
        }
        
         ApiClient.get("Log/GetAllLogsByCriteria",request).then(response => {
                       setLogsData(response.data)
                       changeIsLoading(false);
             })  
    }
  
      }, [])

    
      
const columns = [
    {
        title: "User Name",
        key: "logUserName",
        sort: "none",
        width: "17%"
    },
    {
        title: "User Email",
        key: "logUserEmail",
        sort: "none",
        width: "13%"
    },
    {
            title: "Log Name",
            key: "logName",
            sort: "none",
            width: "9%",
        filter: true,

        },
        {
            title: "Action",
            key: "logAction",
            sort: "none",
            width: "3%"
        },
        {
           
            title: "Location",
            width: "6%",
             key: "logLocation",
            defaultSort: "desc",
            onCreatedCell: (td, cellData) => {
                td.innerHTML =`<img
                style = "height: 24px; "
                src=${`https://purecatamphetamine.github.io/country-flag-icons/3x2/${cellData}.svg`}
              />`
            }
        },
         {
             title: "Browser",
                width: "6%",
                 key: "logBrowser",
                defaultSort: "desc",
                onCreatedCell: (td, cellData) => {
                    td.innerHTML =`<img
                    style = "height:28px; "
                    src=${CommonHelper.getBrowserLogo(cellData)}
                  />`
                }
            },
                {
           
                    title: "IP address",
                    width: "8%",
                    key: "logIP",
                    defaultSort: "desc",
                  },
            {
           
                title: "Datetime",
                width: "15%",
                key: "logDateTimeUTC",
                defaultSort: "desc",
                onCreatedCell: (td, cellData) => {
                    td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm");
    
                },

        },
        {
            title: "",
            key: "logId",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const action = `<span class="tooltip"><i id="vehicle_history_${cellData.id}" class="icon icon-time icon-frame clickable p-1 text-lg"></i><span class="message top-end">History</span></span>`
                    td.innerHTML = `${action}`;
                    td.querySelector("#vehicle_history_" + cellData.id).addEventListener('click', () => openVehicleDetailsLog(cellData))

                } else {
                    td.innerHTML = null;
                }
            },
            notSearchable: true,
            width: "2%"
        },
 ];
const openVehicleDetailsLog = (cellData) => {
    let logsDetails =filter(logsData, function(o) { return o.logId===cellData; });
        setLogDetail(logsDetails);
        openHistoryDialog(true);
    }
const renderHistoryData = () => {
    return (<EuiRow className="flex-middle-content w-10">
                <OperationTimeline data={logDetail}  />
             </EuiRow>
        )
}
const inspectionHistory = () => {
    return (
        <>
   
        <DialogModal
        id="inspectionHistoryDialog"
        style={{ height: "1000px" }}
        title="Vehicle History"
        content={renderHistoryData()}
        buttonName="Ok"
        handleClose={() => openHistoryDialog(false)}
        onClick={() => openHistoryDialog(false)}
        contentStyle={{ width: "999px", height: "500px" }}
        open={historyDialog}
    /></>)

}
const renderMod = () => {
        return <EuiRow>
             {/* eslint-disable-next-line react/jsx-pascal-case */}
            <ObseleteEuiTable
                key={logsData}
                classes="tiny split-striped"
                columns={columns}
                data={logsData}
                // exportExcel={true}
                excelFileName={"logs_List" + new Date().toISOString()}
                Reportdata={logsData}
                id="logsDataTable"
                viewActionButton={true}
                // actionButton={getTableActions()}
                sortable={true}
                paginated={true}
                rowsPerPage={10}
                search={true}
                placeholder="Search logs..."
                searchStyle={{ width: 300 }}
                viewActionIcon={true}
            >
            </ObseleteEuiTable>
        </EuiRow>
            ;
    } 
  
    return ( <EuiRow style={{ padding: 5, width: "100%" }} key={isLoading} disabled={isLoading ? "disabled" : ""}>
    <EuiLoading id="monthlyInspectionListLoading" isLoading={isLoading} />
    {renderMod()}
    {inspectionHistory()}
</EuiRow>)
}

