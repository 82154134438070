import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import { ToastContainer, toast } from 'react-toastify';
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import constants from "../../utils/constants";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTile from "../../eui-components/tile";
import MenuMultiPanelTile from "../../components/menuMultiPanel";
import SiteInfo from "./siteInfo";
import SiteErisiteInfo from "./siteErisiteInfo";
import EuiTimeline from "../../eui-components/timeline";
import EuiLoading from "../../eui-components/loading";
import SiteDocuments from './siteDocuments'
import CommonHelper from "../../utils/common-helper";
// IT Support 
class SiteDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            editMod: constants.actionMods.VIEW_ONLY
        }
    }

    componentDidMount() {

    }

    getMenuList = () => {
        const site = this.props.siteData.site;
        const user = this.props.userData.userData;
        const menu = [{
            id: 1,
            title: "Site Information",
            icon: <i className="icon icon-info m-1 text-lg"></i>,
            rightIcon: user.jobRole === constants.userRoles.ADMIN || user.jobRole === constants.userRoles.ERICSSON_IM ?
                this.getRightButton()
                : null,
            content: <SiteInfo mod={this.state.editMod} />,
        },
        {
            id: 2, title: "Erisite Information", icon: <i className="icon icon-info m-1 text-lg"></i>,
            content: <SiteErisiteInfo />
        },
        {
            id: 3, title: "Site History", icon: <i className={"icon icon-tasks m-1 text-lg"}></i>,
            content: <EuiRow className="flex-middle-content w-10">{(site.logs || []).length > 0 ? <EuiTimeline data={site.logs} /> : <div>No History about this site...</div>}</EuiRow>
            },
            {
                id: 4, title: "Site Documents", icon: <i className={"icon icon-document m-1 text-lg"}></i>,
                content: <SiteDocuments />
            },
        ]
        return menu;
    }

    getRightButton = () => {
        if (this.state.editMod === constants.actionMods.VIEW_ONLY) {
            return <button className={"btn primary m-1 " + (this.props.isLoading ? "loading  disabled" : "")} onClick={() => this.setState({ editMod: constants.actionMods.EDIT })}><i className="icon icon-edit p-1" />Edit Site</button>
        } else if (this.state.editMod === constants.actionMods.EDIT) {
            return <><button className={"btn primary m-1 "} onClick={() => this.saveSite()}><i className="icon icon-check p-1" />Save</button>
                <button className={"btn primary m-1 "} onClick={this.cancel}><i className="icon icon-cross p-1" />Cancel</button>
            </>
        }
    }

    saveSite = () => {
        const site = this.props.siteData.site;
        if (!site.name || !site.code || !site.customerId) {
            toast.error("Please, fill the mandatory fields!", { containerId: "SD", position: toast.POSITION.TOP_CENTER });
            return;
        } else if (!CommonHelper.isValidCoordinate(site.latitude) || !CommonHelper.isValidCoordinate(site.longitude)) {
            toast.error("Coordinates can only contain numbers and dot", { containerId: "SD", position: toast.POSITION.TOP_CENTER });
            return;
        }

        this.setState({ editMod: constants.actionMods.VIEW_ONLY }, () => {
            this.props.createEditSite();
        });
    }

    cancel = () => {
        const site = this.props.siteData.filteredSites.data.find(site => site.id === this.props.siteData.site?.id);
        this.props.setSite(site);
        this.setState({ editMod: constants.actionMods.VIEW_ONLY });

    }

    render() {
        const site = this.props.siteData.site;
        return (<EuiTile fullscreen tileStyle={{ padding: 0 }} className={this.state.isLoading ? " disabled " : ""}>
            <EuiLoading id="sitesSearchLoading" isLoading={this.state.isLoading} />
            <EuiBreadcrumb id="siteBreadcrumb"
                parentName="Home"
                childName={site.name}
                onClickParent={() => this.props.changeSiteMod(constants.pageMods.LIST)}
                className="p-2" />

            <MenuMultiPanelTile id="siteDetailsMenuMultiPanel" menuList={this.getMenuList()} key={this.state.editMod + this.props.isLoading} />

            <ToastContainer enableMultiContainer containerId={'SD'} autoClose={2500} />
        </EuiTile >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, siteData: state.site });
const mapDispatchToProps = dispatch => {
    return {
        changeSiteMod: (mod) => dispatch({ type: "CHANGE_SITE_MOD", payload: mod }),
        setSite: (site) => dispatch({ type: "SET_SITE", payload: site }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SiteDetails)); 