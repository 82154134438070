import React, { useEffect, useRef } from "react";
import { Datepicker } from "@eds/vanilla/date-field/Datepicker";
import DateUtils from "../utils/date-utils";

export default class EuiDatepicker extends React.Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
        this.state = {
            selectedDay: "",
            inputRef: "",
        }
    }

    componentDidMount() {
        if (this.inputRef.current)
            this.inputRef.current.addEventListener('change', (evt) => { this.onChange(evt) });

        const datepickers = document.querySelectorAll('#' + this.props.id);

        if (datepickers) {
            Array.from(datepickers).forEach((datepickerDOM) => {
                const datepicker = new Datepicker(datepickerDOM);
                datepicker.init();
            });
        }
    }

    onChange = (e) => {
        this.props.onChange && this.props.onChange(e);
    }


    render() {
        var dateValue = DateUtils.toDateString(this.props.value);
        return (
            <div className={"datepicker " + (this.props.className ?? "")} id={this.props.id} style={this.props.style}>
                {this.props.label && <label className={this.props.labelClassName ?? ""} htmlFor={this.props.name} style={{ fontSize: 14, ...this.props.labelStyle }}>{this.props.label}</label>}
                <input
                    type="text"
                    placeholder={this.props.value ? dateValue : "YYYY-MM-DD"}
                    disabled={this.props.disabled}
                    name={this.props.name}
                    maxLength="10"
                    className={"with-icon " + this.props.fullwidth ? "fullwidth" : ""}
                    style={this.props.style}
                    onChange={this.props.onChange}
                    ref={this.inputRef}
                    required={this.props.required}
                />
                <div className={"suffix icon-inside clickable "}>
                    <i className="icon icon-calendar"></i>
                </div>

                <div className="calendar closed">
                    <div className="head">
                        <i className="icon icon-chevron-left"></i>
                        <span className="month"></span>
                        <i className="icon icon-chevron-right"></i>

                        <i className="icon icon-chevron-left"></i>
                        <span className="year"></span>
                        <i className="icon icon-chevron-right"></i>
                    </div>
                    <table className="body">
                    </table>
                </div>

                <input type="hidden" name="selectedDay" value="" />
            </div >);
    }
}
