import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { toast } from 'react-toastify';
import EuiSignIn from "./eui-components/signin";

import CreateSession from "./pages/sessions/create-session";
import MySession from "./pages/sessions/my-sessions";
import ApproveSession from "./pages/sessions/approve-session";

import MyProfile from "./pages/admin/my-profile";
import CreateCustomerProfile from "./pages/admin/create-customer-profile";
import PasswordReset from "./pages/admin/reset-password";
import RecoverPassword from "./pages/admin/recover-password";
import TwoFactorAuth from './pages/admin/two-factor-auth';

import MyWorkflows from "./pages/workflow/my-workflows";
import CustomerSessionReport from "./pages/customer-session-report"

import Dashboard from "./pages/dashboard/quality-dashboard";
import EhsDashboard from "./pages/dashboard/ehs-dashboard";
import ProductivityDashboard from "./pages/dashboard/productivity-utilization";

import Policy from "./pages/policy";

import LiveStreaming from "./pages/live-stream/live-streaming";

import ActivityInbox from "./pages/activity-inbox/activities";
import ActivitySettings from "./pages/activity-inbox/settings";

import EhsInbox from "./pages/ehs-inbox/ehs-pings";
import Sites from "./pages/sites";
import Projects from "./pages/projects"
import Teams from "./pages/teams";
import Workplans from "./pages/workplans";
import Activities from "./pages/activities";
import EhsPings from "./pages/ehs-pings";
import Customers from "./pages/customers";
import ASPResources from "./pages/asp-resources";
import ActivityChecklistsItems from "./pages/activity-checklist-items";
import Home from "./pages/home";
import Tickets from "./pages/tickets";
import UserAdmin from "./pages/user-admin";
import UserManuals from "./pages/user-manuals";
import Inbox from "./pages/inbox";
import VehicleInbox from "./pages/vehicle-inbox"
import vehicleInboxApproval from "./pages/VehicleApprovalInbox"
import CommonHelper from "./utils/common-helper";

import AspPmActivities from "./pages/asp-pm-activities";
import Complaints from './pages/complaints';
import AspCompanies from './pages/asp-companies';
import IMInbox from './pages/im-inbox';
import withClearCache from "./ClearCache";

import RscAssignment from "./pages/rsc-assignment";
import RscDashboard from "./pages/dashboard/rsc-inspectors-dashboard";
import ReportDownload from "./pages/report-download";
import deletionRequests from "./pages/deletion-Requests";
import { getAuthToken, getTokenAndRenew, isLoggedIn } from "./utils/adalConfig";
import GeneralChat from "./pages/chatTools/dashboard";
import IntegrationApi from "./pages/integrationApi";
import ReportBuilder from "./pages/report-builder";
import MyReports from "./pages/my-reports"

const AppContext = React.createContext({});
class App extends React.Component {

    // save visitor
    componentDidMount() {
        // Saving visitor
        CommonHelper.saveVisit();
    }

    componentDidUpdate()
    {
        CommonHelper.handleOnUserCheckout();
    }

    render() {
        toast.configure();
        return (
            <Router>
                <Switch>
                    <ProtectedRoute path="/" component={Home} exact />
                    <ProtectedRoute path="/my-customer-sessions" component={MySession} exact />
                    <ProtectedRoute path="/create-session" component={CreateSession} exact />
                    <ProtectedRoute path="/approve-session" component={ApproveSession} exact />

                    <ProtectedRoute path="/my-profile" component={MyProfile} exact />
                    <ProtectedRoute path="/create-customer-profile" component={CreateCustomerProfile} exact />

                    <Route path="/reset-password" component={PasswordReset} exact />
                    <Route path="/recover-password" component={RecoverPassword} exact />
                    <Route path="/two-factor-auth" component={TwoFactorAuth} exact />

                    <ProtectedRoute path="/workflows" component={MyWorkflows} exact />
                    <ProtectedRoute path="/customer-session-report" component={CustomerSessionReport} exact />

                    <ProtectedRoute path="/quality-dashboard" component={Dashboard} exact />
                    <ProtectedRoute path="/ehs-dashboard" component={EhsDashboard} exact />
                    <ProtectedRoute path="/productivity-utilization" component={ProductivityDashboard} exact />

                    <Route path="/policy" component={Policy} exact />
                    <ProtectedRoute path="/complaints" component={Complaints} exact />

                    <ProtectedRoute path="/user-manuals" component={UserManuals} exact />

                    <ProtectedRoute path="/live-streaming" component={LiveStreaming} exact />

                    <ProtectedRoute path="/activity-inbox" component={ActivityInbox} exact />
                    <ProtectedRoute path="/activity-inbox-settings" component={ActivitySettings} exact />

                    <ProtectedRoute path="/ehs-inbox" component={EhsInbox} exact />

                    <ProtectedRoute path="/sites" component={Sites} exact />
                    <ProtectedRoute path="/projects" component={Projects} exact />
                    <ProtectedRoute path="/workplans" component={Workplans} exact />
                    <ProtectedRoute path="/teams" component={Teams} exact />
                    <ProtectedRoute path="/activities" component={Activities} exact />
                    <ProtectedRoute path="/deletion-Requests" component={deletionRequests} exact />

                    <ProtectedRoute path="/ehs-pings" component={EhsPings} exact />

                    <ProtectedRoute path="/customers" component={Customers} exact />
                    <ProtectedRoute path="/asp-resources" component={ASPResources} exact />


                    <ProtectedRoute path="/activity-checklist-items" component={ActivityChecklistsItems} exact />

                    <ProtectedRoute path="/ticket-inbox" component={Tickets} exact />
                    <ProtectedRoute path="/users" component={UserAdmin} exact />
                    <ProtectedRoute path="/inbox" component={Inbox} exact />
                    <ProtectedRoute path="/vehicle-inbox" component={VehicleInbox} exact />
                    <ProtectedRoute path="/vehicleApproval" component={vehicleInboxApproval} exact />
                    <ProtectedRoute path="/asp-companies" component={AspCompanies} exact />
                    <ProtectedRoute path="/asp-resources" component={ASPResources} exact />
                    <ProtectedRoute path="/asp-pm-activities" component={AspPmActivities} />
                    <ProtectedRoute path="/im-inbox" component={IMInbox} exact />
                    <ProtectedRoute path="/rsc-dashboard" component={RscDashboard} exact />
                    <ProtectedRoute path="/rsc-assignment" component={RscAssignment} exact />

                    <ProtectedRoute path="/report-download/:link" component={ReportDownload} exact />
                    <ProtectedRoute path='/app' component={GeneralChat } /> 
                    <ProtectedRoute path='/integration_api' component={IntegrationApi} /> 
                    <ProtectedRoute path='/report-builder' component={ReportBuilder} />
                    <ProtectedRoute path='/my-reports' component={MyReports}  />



                   <Route path="/signin" component={EuiSignIn} exact />
         
                    <Redirect to="/" />
                </Switch>
            </Router>
        );
    }
}


const mapStateToProps = state => ({ userData: state.user });

const ProtectedRoute = connect(mapStateToProps)(props => {

    const userData = props.userData
   // if(isLoggedIn && !props.userData.loggedIn ) {
    if(isLoggedIn && !props.userData.loggedIn ) {
       return <Redirect to={{
        pathname: '/signin',
        state: { redirect: window.location.pathname }
        
    }}
    />
    }
    else {
      //  ApiHelper.refreshAllUserDataInBackgroud(props.setUserLoginRoles , props.setRscCenters , props.login , props.setWebToken,props.setUserRefreshed) ; 
        console.log("OTHER ROUTE Called" + window.location.pathname)
        return <Route {...props} />;  
    }
 /*   if ((props.userData && props.userData.loggedIn)) {
        return <Route {...props} />;
    }
    else if (window.location.pathname === "/reset-password") {
        return <Redirect to="/reset-password" />;
    }
    else if (window.location.pathname === "/policy") {
        return <Redirect to="/policy" />;
    }
    else {
        return <Redirect to={{
            pathname: '/signin',
            state: { redirect: window.location.pathname }
        }}
        />;
    }
    */
});


export default withClearCache(App);