

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTile from "../../eui-components/tile";
import DialogModal from "../../components/dialog";
import ApiClient from "../../utils/api-client";
import { toast } from 'react-toastify';
import { remove } from "lodash";
import PageHelper from '../../utils/page-helper';
import SasHelper from "../../utils/sas-helper";

const ReportList = (props) => {
    const dispatch = useDispatch();
    const { reports, setReports } = props;
    const userData = useSelector(state => state.user);

    const ReportsList = useSelector((state) => state.reports);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);


    const [isLoading, setIsLoading] = useState(false);

    const [selectedReportId, setSelectedReportId] = useState(null);
    const [requestId, setRequestId] = useState(0);

  const [warning, setOpenWarning] = useState(false);
  const [removedItem, setDeletedItem] = useState(null);

  const reportColumns = [
    {
      title: "ID",
      onCreatedCell: (td, cellData) => {
        td.innerHTML = `<a id="report_${cellData}">${cellData}</a>`;
      
      },
      key: "id",
      sort: "desc",
      width: "3%",

    },
    {
      title: "Template Name",
      key: "templateName",
      sort: "none",
      width: "10%",
    },
    {
      title: "Report Date",
      key: "reportDate",
      sort: "none",
      width: "10%",
      }, 
      {
          title: "Status",

          onCreatedCell: (td, cellData) => {
              if (cellData  === 'Pending') {
                  td.innerHTML = `<div class="flex-start-content"><i class="icon icon-severity-cleared color-gray m-1 text-sm"></i>Pending</div>`
              } else {
                  td.innerHTML = `<div class="flex-start-content"><i class="icon icon-severity-cleared color-green m-1 text-sm"></i>Ready</div>`;
              }

          },
          key: "status",
          sort: "none",
          width: "10%",
      }, 
    {
      title: "File Name",
      key: "fileName",
      sort: "none",
      width: "15%",
    },
    {
      title: "",
      key: "id",
      width: "2%",
      hideFilter: true,
      notSearchable: true,
        onCreatedCell: (td, cellData) => {

            var reportRow = reports.find(p => p.id === cellData);
            if (reportRow.status === 'Pending') {
                td.innerHTML = ""
            } else {
                td.innerHTML = `<span id="delete_${cellData}" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable"></i><span class="message top-end " ">Delete Report</span></span>`;
                td.querySelector(`#delete_${cellData}`).addEventListener("click", () => OpenDeleteDialog(cellData));
            }
        }
  },
  {
    title: "",
    key: "id",
    width: "2%",
    hideFilter: true,
    notSearchable: true,
      onCreatedCell: (td, cellData) => {
          var reportRow = reports.find(p => p.id === cellData);
          if (reportRow.status === 'Pending') {
              td.innerHTML = ""
          } else {
              td.innerHTML = `<span id="download_${cellData}" class="tooltip"><i id="download__" style={font-weight:500px;} class="icon icon-download-save  color-black  text-lg clickable"></i><span class="message top-end " ">Download Report</span></span>`;
              td.querySelector(`#download_${cellData}`).addEventListener("click", () => DownloadReport(cellData));
          }

      }

      },
      {
          title: "",
          key: "id",
          width: "2%",
          hideFilter: true,
          notSearchable: true,
          onCreatedCell: (td, cellData) => {
              var reportRow = reports.find(p => p.id === cellData);
             
                  td.innerHTML = `<span id="refresh_${cellData}" class="tooltip"><i id="refresh__" style={font-weight:500px;} class="icon icon-reload  color-black  text-lg clickable"></i><span class="message top-end " ">Refresh Report</span></span>`;
                  td.querySelector(`#refresh_${cellData}`).addEventListener("click", () => Refresh());
              

          }

      },
   
  ];
  const columns = React.useMemo(() => reportColumns, [reportColumns]);

  const  OpenDeleteDialog = (reportid) => {
        setSelectedReportId(reportid);
        setOpenDeleteDialog(true);
  };

    const Refresh = () => {
        window.location.reload();
    }
    const DownloadReport = (id) => {
        setIsLoading(true);

        setSelectedReportId(id);
        ApiClient.post("ReportBuilder/GetReportLink", { ReportId: parseInt(id) }).then(response => {
            setIsLoading(false);

            PageHelper.download(SasHelper.addSasKey(response.data.link , response.data.sasToken))
        }).catch(error => {
            console.error(error);
            setIsLoading(false);

        });
    };
  const DeleteReport = () => {
      setIsLoading(true)
      ApiClient.post("ReportBuilder/DeleteReport", { ReportId: parseInt(selectedReportId) }).then(response => {
          setIsLoading(false);
          setOpenDeleteDialog(false);
          const data = remove(reports, function (n) {
              return n.id !== parseInt(selectedReportId)
          });
          setReports(data);
          toast.success("Report Deleted Successfully", { containerId: 'MS', position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
      }).catch(error => {
          console.error(error);
          setIsLoading(false);

      });
  };
  useEffect(() => {

      setOpenDeleteDialog(false);
  }, []);

    const reportsListData = reports.map((report) => {
    return {
      ID: report.id,
      Template_Name: report.template_name,
      Report_Date: report.report_date,
      GUID: report.report_guid,
        File_Name: report.filename,
        Status: report.status
    }
    }).sort((a, b) => b.ID - a.ID);
  return (
    <>
          <EuiTile fullscreen key={reports}>
        <EuiRow disabled={isLoading ? "disabled" : ""}>
          <EuiLoading
            id="ReportsLoading"
            isLoading={isLoading}
          /> 
   
          <ObseleteEuiTable
            classes="tiny split-striped"
            columns={reportColumns}
            data={reports}
            id="reportListTable"
            scroll={false}
            sortable={true}
            paginated={true}
            search={true}
            rowsPerPage={10}
            filter={true}
            key={columns}
            exportExcel={true}
            viewActionButton={true}
            Reportdata={reportsListData}
            excelFileName={"Reports_Excel" + new Date().toISOString()}
            paginationItems={[15, 20, 30]}
          />
        </EuiRow>

              <DialogModal
                  id="deleteReport"
                  open={openDeleteDialog}
                  title="Delete Report"
                  content={
                      <div>Are you sure you want to Delete this Report?</div>}
                  buttonName="Delete"
                  type="primary"
                  handleClose={() => {  setOpenDeleteDialog(false)}}
                  onClick={DeleteReport}
                  mainButtonStyle={{ backgroundColor: 'red' }}
              />
            </EuiTile>
        </>
    );
};
export default withRouter(ReportList);
