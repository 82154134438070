import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiTextField from "../../eui-components/textfield";
import EuiRow from "../../eui-components/row";
import EuiLoading from "../../eui-components/loading";
import EuiTooltip from "../../eui-components/tooltip";
import constants from "../../utils/constants";
import CommonHelper from "../../utils/common-helper";
import UserAdminHelper from "../../utils/user-admin-helper";
import DateUtils from "../../utils/date-utils";
import ApiClient from "../../utils/api-client";

const UserProfile = (props) => {

    const [isLoading, changeIsLoading] = useState(false);

    const users = useSelector(state => state.userAdmin.userList);
    const currentUser = useSelector(state => state.userAdmin.currentUser);
    const mod = useSelector(state => state.userAdmin.mod);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value ?? target.checked;

        const user = { ...currentUser };
        user[name] = value;

        dispatch({ type: "SET_CURRENT_USER", payload: user });
    }

    const handleFileChange = (e) => {
        const files = e.target.files;
        
        let reader = new FileReader();
        reader.onload = e => {
            const data = e.target.result;
            const name = files[0].name;
            changeIsLoading(true);
            ApiClient.post("User/AddSignature",
                {
                    userId: currentUser.id,
                    custSignaturePath: data,
                    signatureName: name,
                }).then(response => {
                    
                    const user = { ...currentUser };
                    user.signature = response.data;
                    user.signatureName = name;
                    dispatch({ type: "SET_CURRENT_USER", payload: user });
                    CommonHelper.createNotification({ title: "Signature has been added successfully.", icon: "check", timeout: 3000 });
                    changeIsLoading(false);
                }).catch(error => {
                    console.error(error);;
                    CommonHelper.createNotification({ title: constants.errorMessage, icon: "cross", timeout: 3000 });
                    changeIsLoading(false);
                });
        };
        reader.readAsDataURL(files[0]);
    }

    const onDelete = (e) => {
        changeIsLoading(true);
        ApiClient.post("User/DeleteSignature",
            {
                userId: currentUser.id,
                custSignaturePath: currentUser.signature,
                signatureName: currentUser.signatureName,
            }).then(response => {
                if (response.data) {
                    const user = { ...currentUser };
                    user.signature = null;
                    user.signatureName = null;
                    dispatch({ type: "SET_CURRENT_USER", payload: user });
                    CommonHelper.createNotification({ title: "Signature deleted successfully.", icon: "check", timeout: 3000 });
                    changeIsLoading(false);
                }
            }).catch((error) => {
                console.error(error);;
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "cross", timeout: 3000 });
                changeIsLoading(false);
            });
    }

    return (
        <div className={isLoading ? "disabled" : ""}>
            <EuiLoading isLoading={isLoading}></EuiLoading>
            <EuiTextField className="p-1"
                fullwidth
                required
                readOnly={mod === constants.pageMods.VIEW_ONLY}
                style={{ padding: 3 }}
                labelstyle={{ fontWeight: 600 }}
                id="userAdminName"
                name="name"
                label="Name"
                value={currentUser.name || ""}
                onChange={(e) => handleChange(e)}
            />
            <EuiTextField className="p-1"
                fullwidth
                required
                readOnly={mod != constants.pageMods.ADD}
                style={{ padding: 3 }}
                labelstyle={{ fontWeight: 600 }}
                id="userAdminEmail"
                inputClassName={"validation " + (UserAdminHelper.isValidEmail(currentUser.email, users) === UserAdminHelper.validEmailMessage ? " valid " : "")}
                name="email"
                label="Email Address"
                placeholder="name@domain.com"
                type="email"
                value={currentUser.email || ""}
                validationMessage={UserAdminHelper.isValidEmail(currentUser.email, users)}
                onChange={(e) => handleChange(e)}
            />
            <EuiTextField className="p-1"
                fullwidth
                readOnly={mod === constants.pageMods.VIEW_ONLY}
                required
                style={{ padding: 3 }}
                labelstyle={{ fontWeight: 600 }}
                id="userAdminSignum"
                inputClassName={"validation " + (UserAdminHelper.isValidSignum(currentUser.signum, users) === UserAdminHelper.validSignumMessage ? " valid " : "")}
                name="signum"
                label="Signum"
                pattern="[A-Za-z]+"
                validationMessage={UserAdminHelper.isValidSignum(currentUser.signum, users)}
                maxLength="7"
                size="7"
                value={currentUser.signum || ""}
                onChange={(e) => handleChange(e)}
            />
            <EuiTextField className="p-1"
                fullwidth
                readOnly={mod === constants.pageMods.VIEW_ONLY}
                style={{ padding: 3 }}
                labelstyle={{ fontWeight: 600 }}
                id="userAdminManagerEmailAdress"
                name="managerEmailAddress"
                type="email"
                label="Manager Email Address"
                value={currentUser.managerEmailAddress || ""}
                onChange={(e) => handleChange(e)}
            />
            <EuiTextField className="p-1"
                autoComplete="off"
                fullwidth
                readOnly={mod === constants.pageMods.VIEW_ONLY}
                style={{ padding: 3 }}
                labelstyle={{ fontWeight: 600 }}
                id="userAdminJobTitle"
                name="jobTitle"
                label="Job Title"
                value={currentUser.jobTitle || ""}
                onChange={(e) => handleChange(e)}
            />
            <EuiTextField className="p-1"
                fullwidth
                readOnly={true}
                style={{ padding: 3 }}
                labelstyle={{ fontWeight: 600 }}
                id="userAdminLastLoginDate"
                name="lastLoginDate"
                label="Last Login Date"
                value={DateUtils.toDateString(currentUser.lastLoginDate, "YYYY-MM-DD HH:mm")}
            />
            <EuiTextField className="p-1"
                fullwidth
                multiLine={true}
                readOnly={mod === constants.pageMods.VIEW_ONLY}
                style={{ padding: 3 }}
                labelstyle={{ fontWeight: 600 }}
                id="userAdminUserComments"
                name="userComments"
                label="User Comments"
                value={currentUser.userComments || ""}
                onChange={(e) => handleChange(e)}
            />
            {/* {currentUser.role?.toString() === constants.userRoles.TEAM_MEMBER ||
                currentUser.role?.toString() === constants.userRoles.ASP_TEAM_LEADER ||
                mod === constants.pageMods.ADD ? null :
                <EuiCheckbox name="twoFactorAuthStatus"
                    className="m-1"
                    id="userAdminTwoFactorAuthStatus"
                    disabled={mod === constants.pageMods.VIEW_ONLY}
                    label="Enable two factor authentication via Email Adress"
                    refValue={currentUser.twoFactorAuthStatus || false}
                    onChange={(e) => handleChange(e)}
                />
            } */}
            {currentUser.role?.toString() === constants.userRoles.CUSTOMER && props.isMyProfile ?
                <EuiRow className="pl-1">
                    <div className="column sm-12 md-12 lg-6 xl-6">
                        <label className="custom-label">Upload Electronic Signature</label>
                        <input type="file" name="file" onChange={(e) => handleFileChange(e)} style={{ width: "60%" }} />
                        <input type="checkbox" name="agreement" id="signatureAgreement" tabIndex="0" />
                        <label htmlFor="signatureAgreement">I agree to uploading my electronic signature</label>
                    </div>

                    <div className="column sm-12 md-12 lg-6 xl-6 mt-4" hidden={!currentUser.signature}>
                        <span style={{ display: "flex" }}>
                            <img
                                style={{ width: "200px", height: "150px", paddingLeft: "20px" }}
                                src={currentUser.signature}
                                alt="Electronic Signature"
                            />
                            <EuiTooltip title="Delete Signature">
                                <i className="icon icon-trashcan icon-frame m-1" onClick={onDelete}></i>
                            </EuiTooltip>
                        </span>
                    </div>
                </EuiRow>
                : null
            }
        </div>
    );

}

export default UserProfile;