import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiDialog from "../../eui-components/dialog";
import AddCertificateDialog from "./addCertificateDialog"
import ApiClient from "../../utils/api-client";
import DateUtils from "../../utils/date-utils";
import PageHelper from "../../utils/page-helper";
import constants from "../../utils/constants";
import AspHelper from "../../utils/asp-helper";
import DialogModal from "../../components/dialog";
import EuiLoading from "../../eui-components/loading";
import CommonHelper from "../../utils/common-helper";
import SasHelper from "../../utils/sas-helper";

class Certificates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            certificatesColumns: [
                {
                    title: "Course Code",
                    key: "code",
                    width: "12%",
                    sort: 'none'
                },
                {
                    title: "Course Name",
                    key: "name",
                    width: "20%",
                    sort: 'none'
                },
                {
                    title: "Valid Since",
                    key: "validSince",
                    width: "12%",
                    sort: 'none'
                },
                {
                    title: "Expiry Date",
                    key: "validUntil",
                    width: "12%",
                    sort: 'none'
                },
                {
                    title: "Status",
                    key: "status",
                    width: "10%",
                    onCreatedCell: (td, cellData) => {
                        if (cellData) {
                            td.innerHTML = PageHelper.renderCrtStatus(cellData);
                        }
                        else
                            td.innerHTML = ""
                    },
                    sort: 'none'
                },
                {
                    title: "Certificate Link",
                    key: "path",
                    width: "10%",
                    // cellStyle: 'padding:0px',
                    onCreatedCell: (td, cellData) => {
                        if (cellData) {
                            // var name = this.props.aspResourcesData.resource.certs.filter(c => c.path == cellData)[0].name + "_file";
                            // td.innerHTML = `<a id="certificate_${cellData}" href=${cellData} target="_blank" ><span><i class="icon icon-attach"></i>Certificate link</span></a>`;
                            var r = Math.floor(Math.random() * 1000) + 1;
                            td.innerHTML = `<a id="certificate_"  ><span id="certificate_" href=${SasHelper.addSasKey(cellData, this.props.sasToken)} target="_blank"><i class="icon icon-download"></i>Certificate</span></a>`;
                            if (td.querySelector("#certificate_")) {
                                td.querySelector("#certificate_").addEventListener('click', () => this.openViewCrtDialog(r, SasHelper.addSasKey(cellData, this.props.sasToken)));
                            }
                        }
                        else
                            td.innerHTML = ""
                    },
                    sort: 'none'
                },
                {
                    title: "Last Modified",
                    key: "lastModifiedDateTime",
                    width: "12%",
                    sort: 'desc'
                },
                {
                    title: "Modified By",
                    key: "modifiedUserName",
                    width: "10%",
                    sort: 'none'
                },
                {
                    title: "",
                    onCreatedCell: (td, cellData) => {
                        if (cellData && (this.props.userData.userData.jobRole == constants.userRoles.ERICSSON_IM || this.props.userData.userData.jobRole == constants.userRoles.RSC_OHS_INSPECTOR || this.props.userData.userData.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                        )) {
                            td.innerHTML = `<span id="edit_" class="tooltip"><i id="edit_" style={font-weight:500px;} class="icon icon-edit color-black text-lg clickable  "></i><span class="message top-end">Edit certificate</span></span>`;
                            td.querySelector("#edit_").addEventListener('click', () => this.openEditDialog(cellData))
                        }
                        else
                            td.innerHTML = "";
                    },
                    key: "id",
                    width: "2%",
                },
                {
                    title: "",
                    onCreatedCell: (td, cellData) => {
                        if (cellData && (this.props.userData.userData.jobRole == constants.userRoles.ERICSSON_IM || this.props.userData.userData.jobRole == constants.userRoles.RSC_OHS_INSPECTOR || this.props.userData.userData.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                        )) {
                            td.innerHTML = `<span id="delete_" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable "></i><span class="message top-end">Delete certificate</span></span>`;
                            td.querySelector("#delete_").addEventListener('click', () => this.openDeleteDialog(cellData))
                        }
                        else
                            td.innerHTML = "";
                    },
                    key: "id",
                    width: "2%",
                },
            ],
            initialState: true,
            testinitialState: true,
            certs: [],
            toggleDialog: false,
            dialogOpened: false,
            Done: false,
            editIcon: "",
            ViewCertDialogOpened: false,
            certFile: "",
            type: "",
            openViewCrtDialog: false
        }
    }
    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        this.setState({
            isLoading: true
        }, () => {
            if (this.props.aspResourcesData) {
                this.setState({
                    certs: this.props.aspResourcesData.resource.certs,
                    isLoading: false
                })
            }
        })
    }
    updateData = () => {
        this.setState({
            isLoading: true
        })
        const filter = this.props.aspResourcesData.filter;
        if (!filter || Object.values(filter).every(x => (x === null || x === ''))) {
            filter.id = this.props.aspResourcesData.resource.id;
            filter.idsList = [];
            filter.idsList.push(this.props.aspResourcesData.resource.id);
        }
        filter.id = parseInt(filter.id ?? "0");
        ApiClient.post("Asp/GetResources", filter)
            .then(response => {
                ApiClient.post("Asp/GetResourceJobs", response.data.iDs)
                    .then(response2 => {
                        var _data = [];
                        var blobs = response.data.profileBlobs;
                        var resourceProjects = response.data.projects;
                        var certificates = [];
                        var resourceJobs = response2.data;
                        var skills = response.data.skills;
                        var faces = response.data.faces;
                        this.props.setResourceUsers(response.data.users);
                        response.data.certifications.forEach(c => {
                            var _c = this.props.aspResourcesData.certificates.filter(x => x.value == c.certificationId);
                            if (_c && _c[0]) {
                                var date = DateUtils.getUtcDate();
                                var diff = DateUtils.dateDifference(new Date(c.validUntil), new Date(date));
                                var element = {
                                    ...c,
                                    validSince: DateUtils.toDateString(c.validSince),
                                    validUntil: DateUtils.toDateString(c.validUntil),
                                    createdate: DateUtils.toDateString(c.createdate),
                                    lastModifiedDateTime: DateUtils.toDateString(c.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                                    createUserName: c.createUserName,
                                    modifiedUserName: c.modifiedUserName,
                                    name: _c[0].text,
                                    code: _c[0].code,
                                    path: c.path,
                                    status: (new Date(c.validUntil).getTime() - date.getTime() > 0 ? "Valid" : "Expired"),
                                    _delete: c.id
                                }
                                certificates.push(element);
                            }
                        })
                        this.props.setFilteredResources([]);
                        response.data.resource.forEach(e => {
                            var projects = AspHelper.mapProjects(resourceProjects?.filter(item => item.resourceId == e.id), this.props.aspResourcesData.projects, this.props.userData.customers);
                            var country = this.props.userData.countries?.filter(c => c.value === e.countryId);
                            var company = this.props.aspResourcesData.companies.filter(c => c.value === e.aspCompanyId);
                            var cert = certificates.filter(c => c.resourceId === e.id);
                            var jobs = [];
                            var resourceSkills = skills.filter(s => s.resourceId == e.id);
                            var faceId = faces.find(f => f.resourceId == e.id)?.faceId;
                            var fullResourceSkills = AspHelper.getSkillInfo(resourceSkills, this.props.aspResourcesData.skills);
                            var _jobs = resourceJobs.filter(j => j.resourceId == e.id);
                            var inCompletedCerts = [];
                            var validResource = false;
                            _jobs.forEach(j => jobs.push(AspHelper.mapResourcesJobRole(j.value, this.props.aspResourcesData.jobs)));
                            var blob = blobs.filter(b => b.resourceId == e.id);
                          /*  inCompletedCerts = AspHelper.getUnTakenCerts(cert, this.props.aspResourcesData.jobs, certificates, jobs);
                            if (e.status == "Active" && jobs.length) {
                                var test = true;
                                if (inCompletedCerts) {
                                    inCompletedCerts.forEach(c => {
                                        if (!c.valid)
                                            test = false;
                                    });
                                }
                                if (test)
                                    validResource = true;
                            }
                                */
                            var test = true;
                                       if (inCompletedCerts) {
                                        inCompletedCerts.forEach(c => {
                                            if (!c.valid)
                                                test = false;
                                        });
                                    }
                                    validResource = test
                            if (jobs)
                            var element = {
                                ...e,
                                projects: projects,
                                status: e.status,
                                country: (country && country[0] ? country[0].text : ""),
                                aspCompany: (company && company[0] ? company[0].text : ""),
                                companyCountry: (company && company[0] ? company[0].countryId : ""),
                                profileImg: (blob && blob[0] ? constants.profileStorage + blob[0].key : ""),
                                certs: cert,
                                jobs: (jobs ? jobs : []),
                                skills: fullResourceSkills,
                                jobList: (_jobs ? _jobs : []),
                                inCompletedCerts: inCompletedCerts,
                                validResource: validResource,
                                faceId: faceId,
                                path: (faceId ? e.path : null),
                                imagePath: (faceId ? e.imagePath : null)
                            }
                            _data.push(element);
                        });
                        const data = [..._data];
                        const selectedResource = data.find(item => item.id === this.props.aspResourcesData.resource.id);
                        this.props.setAspResource(selectedResource);
                        this.props.setFilteredResources(data);
                        this.props.setSelectedItem(2);
                        var d = Date.now();
                        this.props.updateTable(d);
                        this.setState({ isLoading: false })
                    })
            }).catch(error => {
                console.error(error);;
                alert(error);
            });
    }
    openEditDialog = (cellData) => {
        if (!this.state.dialogOpened) {
            const selectedCertificate = { ...this.props.aspResourcesData.resource.certs.filter(c => c.id == cellData)[0] };
            var certificate = this.props.aspResourcesData.certificates.filter(e => e.value ===
                selectedCertificate.certificationId)[0];
            certificate["validSince"] = selectedCertificate.validSince;
            certificate["validUntil"] = selectedCertificate.validUntil;
            certificate["resourceId"] = selectedCertificate.resourceId;
            if (selectedCertificate.path) {
                certificate["path"] = selectedCertificate.path;
            }
            certificate["id"] = selectedCertificate.id;
            certificate["certificationId"] = selectedCertificate.certificationId;
            this.setState({
                editIcon: "edit_" + cellData,
                dialogOpened: true
            })
            this.props.setCertificate(certificate);
            document.querySelector("#edit_").click();
        }
    }
    openDeleteDialog = (cellData) => {
        if (!this.state.dialogOpened) {
            const selectedCertificate = { ...this.props.aspResourcesData.resource.certs.filter(c => c.id == cellData)[0] };
            var certificate = this.props.aspResourcesData.certificates.filter(e => e.value ===
                selectedCertificate.certificationId)[0];
            certificate["validSince"] = selectedCertificate.validSince;
            certificate["validUntil"] = selectedCertificate.validUntil;
            certificate["resourceId"] = selectedCertificate.resourceId;
            if (selectedCertificate.path) {
                certificate["path"] = selectedCertificate.path;
            }
            certificate["id"] = selectedCertificate.id;
            this.setState({
                editIcon: "edit_" + cellData,
                dialogOpened: true
            })
            this.props.setCertificate(certificate);
            document.querySelector("#delete_").click();
        }
    }
    edit = () => {
        var currentCertificate = { ...this.props.aspResourcesData.certificate };
        const resource = { ...this.props.aspResourcesData.resource }
        this.props.setCertificate(currentCertificate);
        var _c = resource["certs"];
        resource["certs"] = [];
        const resourceId = parseInt(this.props.aspResourcesData.resource.id);
        if (Object.keys(currentCertificate).length === 0) {
            this.props.setAddCrtNotify(true);
            this.setState({
                testinitialState: !this.state.testinitialState
            })
        }
        else if (!currentCertificate.value
            || !currentCertificate.validSince
            || !currentCertificate.validUntil) {
            this.props.setAddCrtNotify(true);
            this.setState({
                testinitialState: !this.state.testinitialState
            })
        }
        else {
            if (Object.keys(currentCertificate).length) {
                if (_c) {
                    _c.filter(c => c.id == currentCertificate.id)[0] = currentCertificate;
                }
            }
            this.props.setAddCrtNotify(false);
            const certificate = { ...this.props.aspResourcesData.certificate }
            certificate['validSince'] = certificate['validSince'].slice(0, 10);
            certificate['validUntil'] = certificate['validUntil'].slice(0, 10);
            certificate["lastModifiedUser"] = parseInt(this.props.userData.userData.id);
            this.setState({
                isLoading: true,
                Done: true
            }, () => {
                let visitor = CommonHelper.getVisitorData();
                resource["certs"] = [];
                this.props.setAspResource(resource);
                const oldCertificate = this.props.aspResourcesData.filteredResources.find(item => item.id == resource.id)
                    .certs.find(item => item.id === certificate.id);
                var logInfo = {
                    userId: parseInt(this.props.userData.userData.userId),
                    logBrowser: visitor.vBrowser,
                    logIP: visitor.vIP,
                    logLocation: visitor.vCountry,
                    latitude: visitor.latitude,
                    longitude: visitor.longitude,
                    logDetail: Object.entries(oldCertificate).reduce((a, b) => {
                        return a + b.join(" : ") + " , ";
                    }, ""),
                };
                ApiClient.post("Asp/EditCertificate",
                    {
                        Certificate: certificate, ResourceID: resourceId, LogInfo: logInfo
                    })
                    .then(response => {
                        currentCertificate["path"] = response.data.path;
                        var resourceCrt = response.data.certificates;
                        var certificates = [];
                        var date = DateUtils.getUtcDate();
                        if (resourceCrt.length) {
                            resourceCrt.forEach(_c => {
                                if (_c.certificationId) {
                                    var c = this.props.aspResourcesData.certificates.filter(x => x.value == _c.certificationId);
                                    var element = {
                                        ..._c,
                                        validSince: DateUtils.toDateString(_c.validSince, "", true),
                                        validUntil: DateUtils.toDateString(_c.validUntil, "", true),
                                        createdate: DateUtils.toDateString(_c.createdate, "", true),
                                        createUserName: c[0].createUserName,
                                        name: c[0].text,
                                        code: c[0].code,
                                        path: _c.path,
                                        status: (DateUtils.dateDifference(new Date(_c.validUntil), new Date(date)) > 0 ? "Valid" : "Expired")
                                    }
                                    certificates.push(element);
                                }
                            })
                            resource["certs"] = certificates;
                            this.props.setAspResource(resource);
                            this.setState({
                                certs: certificates,
                                isLoading: false,
                                dialogOpened: false,
                                initialState: !this.state.initialState,
                            });
                        }
                        else {
                            this.setState({
                                isLoading: false,
                                dialogOpened: false,
                                initialState: !this.state.initialState,
                            });
                        }
                        this.updateData();
                    })
                    .catch(error => {
                        alert(error);
                        this.setState({
                            isLoading: false,
                            dialogOpened: false,
                        });
                    });
            });
        }
    }
    delete = () => {
        let visitor = CommonHelper.getVisitorData();
        const resource = { ...this.props.aspResourcesData.resource };
        const currentCrt = this.props.aspResourcesData.certificate;
        resource["certs"] = [];
        this.props.setAspResource(resource);
        const oldCertificate = this.props.aspResourcesData.filteredResources.find(item => item.id == resource.id)
            .certs.find(item => item.id === currentCrt.id);
        var logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: Object.entries(oldCertificate).reduce((a, b) => {
                return a + b.join(" : ") + " , ";
            }, ""),
        };
        this.setState({ isLoading: true, Done: true }, () => {
            ApiClient.post("Asp/DeleteCertificate", { CertificateID: currentCrt.id, ResourceID: resource.id, LogInfo: logInfo })
                .then(response => {
                    var certificates = [];
                    resource["certs"] = [];
                    this.props.setAspResource(resource);
                    var date = DateUtils.getUtcDate();
                    if (response.data.length) {
                        response.data.forEach(_c => {
                            if (_c.certificationId) {
                                var c = this.props.aspResourcesData.certificates.filter(x => x.value == _c.certificationId);
                                var element = {
                                    ..._c,
                                    validSince: DateUtils.toDateString(_c.validSince, "", true),
                                    validUntil: DateUtils.toDateString(_c.validUntil, "", true),
                                    createdate: DateUtils.toDateString(_c.createdate, "", true),
                                    createUserName: _c.createUserName,
                                    name: c[0].text,
                                    code: c[0].code,
                                    path: _c.path,
                                    status: (DateUtils.dateDifference(new Date(_c.validUntil), new Date(date)) > 0 ? "Valid" : "Expired")
                                }
                                certificates.push(element);
                            }
                        })
                        resource["certs"] = [];
                        this.props.setAspResource({});
                        resource["certs"] = certificates;
                        this.props.setAspResource(resource);
                        this.setState({
                            isLoading: false,
                            initialState: !this.state.initialState,
                            certs: certificates
                        });
                    }
                    else {
                        resource["certs"] = [];
                        this.props.setAspResource({});
                        this.props.setAspResource(resource);
                        this.setState({
                            isLoading: false,
                            initialState: !this.state.initialState,
                            certs: certificates,
                            openDeleteDialog: false
                        });
                    }
                    this.updateData();
                })
                .catch(error => {
                    alert(error);
                    this.setState({ isLoading: false });
                });
        })
    }
    add = () => {
        var currentCertificate = { ...this.props.aspResourcesData.certificate };
        const resource = { ...this.props.aspResourcesData.resource }
        this.props.setCertificate(currentCertificate);
        var _c = resource["certs"];
        resource["certs"] = [];
        if (Object.keys(currentCertificate).length === 0) {
            this.props.setAddCrtNotify(true);
            this.setState({
                testinitialState: !this.state.testinitialState
            })
        }
        else if (!currentCertificate.certificationId
            || !currentCertificate.validSince
            || !currentCertificate.validUntil) {
            this.props.setAddCrtNotify(true);
            this.setState({
                testinitialState: !this.state.testinitialState
            })
        }
        else {
            if (Object.keys(currentCertificate).length) {
                let visitor = CommonHelper.getVisitorData();
                this.props.setAddCrtNotify(false);
                const certificate = this.props.aspResourcesData.certificate;
                certificate.logInfo = {
                    userId: parseInt(this.props.userData.userData.userId),
                    logBrowser: visitor.vBrowser,
                    logIP: visitor.vIP,
                    logLocation: visitor.vCountry,
                    latitude: visitor.latitude,
                    longitude: visitor.longitude,
                    logDetail: Object.entries(certificate).reduce((a, b) => {
                        return a + b.join(" : ") + " , ";
                    }, ""),
                };
                this.setState({ isLoading: true, Done: true }, () => {
                    ApiClient.post("Asp/AddCertificate", certificate)
                        .then(response => {
                            currentCertificate["id"] = response.data;
                            if (_c)
                                _c.push(currentCertificate);
                            else
                                _c = currentCertificate;
                            resource["certs"] = _c;
                            this.props.setAspResource(resource);
                            this.setState({
                                initialState: !this.state.initialState,
                                certs: _c
                            })
                            this.updateData();
                        })
                        .catch(error => {
                            alert(error);
                            this.setState({ isLoading: false });
                        });
                });
            }
        }
    }
    onCancel = () => {
        var crt = this.props.aspResourcesData.certificate;
        crt = {};
        this.props.setCertificate(crt);
        this.props.disableAddCertForName(false);
        this.props.disableAddCertForDate(false);
        this.setState({
            toggleDialog: !this.state.togglaDialog,
            dialogOpened: false
        })
    }
    openViewCrtDialog = (r, cellData) => {
        var splittedString = cellData.split(".");
        var type = "";
        if (!splittedString) {
            const notification = new Notification({
                title: 'Link Error',
                description: 'Certificate link is corrupted.',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        var extention = splittedString[splittedString.length - 1];
        if (extention == "jpg" || extention == "jpeg" || extention == "png" || extention == "PNG") {
            type = "img";
            this.setState({
                certFile: cellData,
                type: type,
                openViewCrtDialog: true
            });
        }
        else if (splittedString[splittedString.length - 1] == "pdf") {
            type = "pdf";
            this.setState({
                certFile: cellData,
                type: type,
                openViewCrtDialog: true
            });
        }
        else {
            type = "";
            this.setState({
                certFile: cellData,
                type: type,
                openViewCrtDialog: true
            });
        };
        // document.querySelector("#certificate_").click();
    }
    cancelViewCert = () => {
        this.setState({
            openViewCrtDialog: false
        });
    }
    download = () => {
        const link = document.createElement('a');
        link.href = SasHelper.addSasKey(this.state.certFile, this.props.sasToken);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    render() {
        return (
            <EuiRow key={this.state.isLoading} disabled={this.state.isLoading || this.props.aspResourcesData.resource.status == "Blacklisted" ? "disabled" : ""} >
                <EuiLoading isLoading={this.state.isLoading} />
                <ObseleteEuiTable
                    classes="tiny"
                    columns={this.state.certificatesColumns}
                    data={this.state.certs}
                    id="resourcesFilteredTable"
                    sortable={true}
                    paginated={true}
                    rowsPerPage={this.state.certs.length}
                >
                </ObseleteEuiTable>
                <EuiDialog
                    id="crtDialog"
                    dataTrigger="#openDialog"
                    title="Add Certificate"
                    body={<AddCertificateDialog id="addcrt" toggle={this.state.toggleDialog} initialState={this.state.testinitialState} />}
                    buttonName="Add"
                    type={(this.props.aspResourcesData.disableAddCertForName ||
                        this.props.aspResourcesData.disableAddCertForDate ? "primary disabled" : "primary")}
                    onCancel={this.onCancel}
                    onClick={this.add}
                    contentStyle={{ width: "500px" }}
                    bodyStyle={{ height: "500px" }}
                />
                {((document.querySelector("#edit_") && !this.state.isLoading) ?
                    <EuiDialog
                        id="editDialog"
                        dataTrigger="#edit_"
                        title="Edit Certificate"
                        body={<AddCertificateDialog id="editcrt" toggle={this.state.toggleDialog} edit={true} initialState={this.state.testinitialState} />}
                        buttonName="Save"
                        type={(this.props.aspResourcesData.disableAddCertForName ||
                            this.props.aspResourcesData.disableAddCertForDate ? "primary disabled" : "primary")}
                        onCancel={this.onCancel}
                        onClick={this.edit}
                        contentStyle={{ width: "500px" }}
                        bodyStyle={{ height: "500px" }}
                        done={this.state.Done}
                    /> : null)}
                {((document.querySelector("#delete_") && !this.state.isLoading) ?
                    <EuiDialog
                        id="deleteDialog"
                        dataTrigger="#delete_"
                        title="Delete Certificate"
                        body={<div>are you sure you want to delete this certificate? </div>}
                        buttonName="Yes"
                        type={(this.props.aspResourcesData.disableAddCertForName ||
                            this.props.aspResourcesData.disableAddCertForDate ? "primary disabled" : "primary")}
                        onCancel={this.onCancel}
                        onClick={this.delete}
                        done={this.state.Done}
                    /> : null)}
                <DialogModal
                    fullWidth={true}
                    id="viewCrtDialog"
                    open={this.state.openViewCrtDialog}
                    title="Certificate"
                    content={
                        <div className="document-container">{(this.state.type === "img" ? <img className="responsive-document fit-image" src={(SasHelper.addSasKey(this.state.certFile, this.props.sasToken))} />
                            : <iframe className="responsive-document fit-image" id="iframepdf" src={"https://docs.google.com/viewer?url=" + encodeURIComponent(SasHelper.addSasKey(this.state.certFile, this.props.sasToken)) + "&embedded=true"} frameBorder="0" />)}</div>}
                    buttonName="Download"
                    type="primary"
                    handleClose={this.cancelViewCert}
                    onClick={this.download}
                />
            </EuiRow>
        )
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        setCertificate: (certificate) => dispatch({ type: "SET_CERTIFICATE", payload: certificate }),
        disableAddCertForName: (state) => dispatch({ type: "DISABLE_ADD_CERT_FOR_NAME", payload: state }),
        disableAddCertForDate: (state) => dispatch({ type: "DISABLE_ADD_CERT_FOR_DATE", payload: state }),
        setAddCrtNotify: (state) => dispatch({ type: "SET_ADD_CRT_NOTIFY", payload: state }),
        setFilteredResources: (filteredResources) => dispatch({ type: "SET_FILTERED_RESOURCES", payload: filteredResources }),
        updateTable: (val) => dispatch({ type: "UPDATE_TABLE", payload: val }),
        setSelectedItem: (selectedItem) => dispatch({ type: "UPDATE_SELECTED_ITEM", payload: selectedItem }),
        setSkills: (skills) => dispatch({ type: "SET_SKILLS", payload: skills }),
        setResourceUsers: (users) => dispatch({ type: "SET_USERS", payload: users }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Certificates);
