import { combineReducers } from "redux";
import userReducer from "./userReducer";
import navigationReducer from "./navigationReducer";
import sessionReducer from "./sessionReducer";
import liveStreamReducer from "./liveStreamReducer";
import dashboardReducer from "./dashboardReducer";
import activityInboxReducer from "./activityInboxReducer";
import EHSInboxReducer from "./ehsInboxReducer"
import workplanReducer from "./workplanReducer";
import projectsReducer from './projectsReducer'
import activityReducer from "./activityReducer";
import ehsPingReducer from "./ehsPingReducer";
import customersReducer from "./customersReducer";
import aspReducer from "./aspReducer";
import siteReducer from "./siteReducer";
import activityChecklistsReducer from "./activityChecklistsReducer";
import ticketReducer from "./ticketReducer";
import userAdmin from "./userAdminReducer";
import userManualsReducer from "./userManualsReducer";
import documentsReducer from './documentsReducer'
import imInboxReducer from "./imInboxReducer";
import complaintsReducer from './complaintsReducer';
import ehsDashboardReducer from './ehsDashboardReducer';
import vehicleReducer from "./vehicleReducer";
import rscReducer from "./rscReducer";
import tableReducer from "./tableReducer"
import productivityDashboardReducer from "./productivityDashboardReducer";
import vehicleInspectionReducer from "./vehicleInspectionReducer"
import vehicleApprovalReducer from "./vehicleApprovalReducer"
import teamReducer from "./teamReducer"
import chatReducer from "../slices/chat"
import conversationReducer from "../slices/conversation"
import userChatReducer from "../slices/user"
import roomReducer from "./roomReducer";


export default combineReducers({

    chat: chatReducer,
    conversation: conversationReducer,
    userChat: userChatReducer,
    user: userReducer,
    room: roomReducer,
    navigation: navigationReducer,
    session: sessionReducer,
    liveStream: liveStreamReducer,
    dashboard: dashboardReducer,
    ehsDashboard: ehsDashboardReducer,
    productivityDashboard: productivityDashboardReducer,
    activityInbox: activityInboxReducer,
    ehsInbox: EHSInboxReducer,
    projects: projectsReducer,
    workplan: workplanReducer,
    activity: activityReducer,
    ehsPing: ehsPingReducer,
    customers: customersReducer,
    asp: aspReducer,
    site: siteReducer,
    activityChecklists: activityChecklistsReducer,
    ticket: ticketReducer,
    userAdmin: userAdmin,
    userManuals: userManualsReducer,
    documents: documentsReducer,
    imInbox: imInboxReducer,
    complaints: complaintsReducer,
    vehicle: vehicleReducer,
    rsc: rscReducer,
    table: tableReducer,
    vehicleInspection: vehicleInspectionReducer,
    vehicleApproval: vehicleApprovalReducer,
    team: teamReducer
});
