import React, { useState, useEffect } from "react";
import ProjectInfo from './ProjectInfo.js'
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import ProjectsList from './ProjectsList.js'
import { ToastContainer, toast } from 'react-toastify';
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import DialogModal from "../../components/dialog";
import constants from "../../utils/constants";
import ApiClient from "../../utils/api-client";
import ProjectsHelper from "../../utils/project-helper.js";
import PageHelper from "../../utils/page-helper";
import CommonHelper from "../../utils/common-helper.js";

const ProjectSearch = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const userData = useSelector(state => state.user);
    const Project = useSelector(state => state.projects);
    const countries = useSelector(state => state.user.countries)
    const dispatch = useDispatch();
    const project = Project.project;
    useEffect(
        () => {
            if (!countries) {
                setIsLoading(true)
            }
            else if ((!Project.allProjects || Project.allProjects.data.length == 0) && countries) {
                ApiClient.post("Projects/GetAllProjects").then(response => {
                    setIsLoading(false);
                    let projects = response.data.data;
                    ProjectsHelper.mapProjects(projects, countries)
                    dispatch({ type: "SET_ALL_PROJECTS", payload: { data: projects, totalCount: response.data.totalCount } });
                }).catch(error => {
                    console.error(error);
                });
            }
            if (openDialog) {
                dispatch({ type: "SET_PROJECT", payload: { id: 0 } });
            }
        }, [countries, openDialog])
    const midPanel = {
        content: <ProjectsList />,
        rightIcon: <button
            hidden={userData.userData.jobRole === "1" || userData.userData.jobRole === "0" ? false : true}
            className={"btn primary m-1 " + (props.isLoading ? "loading  disabled" : "")}
            onClick={() => setOpenDialog(true)}>
            <i className="icon icon-plus p-1" />Add Project</button>,
        icon: <></>,
        iconTooltip: "",
        hideSeperator: true
    };

    const createNewProject = () => {
        if (!project.name || project.name == "" || project.customerId == null || project.countryId == null) {
            toast.error("Please, fill the mandatory fields.", { containerId: "PS", position: toast.POSITION.TOP_CENTER });
            return;
        }
        setOpenDialog(false)
        createEditProject();
        setIsLoading(true)
    }
    const createEditProject = () => {
        let ModifiedProject = Project.project
        
        ModifiedProject = {
            name: ModifiedProject.name,
            customerId: ModifiedProject.customerId,
            countryId: ModifiedProject.countryId ?? 0,
            country: ModifiedProject.countryId ? userData.countries?.find(x => x.value === ModifiedProject.countryId).text : "",
            contactPerson: ModifiedProject.contactPerson ? ModifiedProject.contactPerson : ""
        }
        let visitor = CommonHelper.getVisitorData();
        ModifiedProject.logInfo = {
            userId: parseInt(userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: Object.entries(ModifiedProject).reduce((a, b) => {
                return a + b.join(" : ") + "    |    ";
            }, ""),
            userLogin: {
                nameOrMail: userData.userMail,
                jobRole: userData.loginRole,
                password: userData.password
            }
        }
        
        ApiClient.post("Projects/CreateEditProject", ModifiedProject).then(response => {
            if (response) {
                const initialState = new Date();
                ApiClient.post("Projects/GetAllProjects").then(response => {
                    setIsLoading(false);
                    toast.success(constants.processPozitiveMessage, { containerId: "PS", position: toast.POSITION.TOP_CENTER });
                    let projects = response.data.data;
                    ProjectsHelper.mapProjects(projects, userData.countries)
                    dispatch({ type: "SET_ALL_PROJECTS", payload: { data: projects, totalCount: response.data.totalCount } });
                    dispatch({ type: "SET_PROJECT", payload: Project });
                })
            }
        })

    }
    return (<EuiRow disabled={isLoading ? "disabled" : ""} key={isLoading}>
        <EuiLoading id="projectSearchLoading" isLoading={isLoading} />
        <EuiMultiPanelTile id="projectMultiPanel" key={isLoading} midPanel={midPanel} style={{ minHeight: 610 }} />
        <DialogModal
            buttonName="Save"
            content={<ProjectInfo mod={constants.actionMods.CREATE} />}
            handleClose={() => setOpenDialog(false)}
            id="projectCreateDialog"
            onClick={() => createNewProject()}
            open={openDialog}
            title="Create a new project"
            contentStyle={{ height: "450px" }}
            disableBackdropClick={true}
        >
        </DialogModal>
        <ToastContainer enableMultiContainer containerId={'PS'} autoClose={2500} />
    </EuiRow >);
}
export default withRouter(ProjectSearch);