import React, { useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogModal from "../../components/dialog";
import EuiLoading from "../../eui-components/loading";
import EuiPills from "../../eui-components/pill";
import EuiRow from "../../eui-components/row";
import EuiTabs from "../../eui-components/tabs";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import { withLayout } from "../../layout";
import ActivityHelper from "../../utils/activity-helper";
import ApiClient from "../../utils/api-client";
import ApiHelper from "../../utils/api-helper";
import CommonHelper from "../../utils/common-helper";
import constants from "../../utils/constants";
import ActiveActivitySessions from "./activeActivitySessions";
import ActiveEHSSessions from "./activeEHSSessions";

const RscAssignment = (props) => {

    const activeActivityContentRef = useRef();
    const activeEHSContentRef = useRef();

    const rscCenters = useSelector(state => state.user.rscCenters);
    const userId = useSelector(state => state.user.userData.userId);

    const reduxDispatch = useDispatch();

    const [openAssignDialog, toggleAssignDialog] = useState({ open: false, type: ActivityHelper.sessionDialogContents.ACTIVITY });
    const [openUnAssignDialog, toggleUnAssignDialog] = useState({ open: false, type: ActivityHelper.sessionUnAssignDialogContents.ACTIVITY });
    const [selectedSessions, setSelectedSessions] = useState([]);
    const [selectedRscCenter, setSelectedRscCenter] = useState(rscCenters[0].value);
    const [selectedRscUser, setSelectedRscUser] = useState({ id: 0, value: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [userColumns, setUserColumns] = useState([{ key: "id", title: "ID" }, { key: "name", title: "Name" }, { key: "email", title: "Email" }]);

    useEffect(() => {
        async function fetchRscUsers() {
            await ApiHelper.setAllRscUsers((data) => reduxDispatch({ type: "SET_ALL_RSC_USERS", payload: data }));
        }
        setIsLoading(true);
        fetchRscUsers();
        setIsLoading(false);
    }, []);

    const allRscUsers = useSelector(state => state.rsc.allRscUsers);

    const assignSessions = () => {
        
        

        setIsLoading(true);
        ApiClient.post("Activity/AssignRSCSessions", { sessions: selectedSessions.map(s => s.id), modifiedBy: parseInt(userId), rscPersonId: selectedRscUser.id, sessionType: openAssignDialog.type?.type })
            .then(response => {
                if (response.data) {
                    CommonHelper.createNotification({ title: response.data.responseDetail, icon: "check", timeout: 5000 });
                    toggleAssignDialog({ open: false, type: ActivityHelper.sessionUnAssignDialogContents.ACTIVITY });
                    setSelectedSessions([]);
                    if (openAssignDialog.type === ActivityHelper.sessionDialogContents.ACTIVITY) {
                        activeActivityContentRef.current.getActiveActivities();
                    } else if (openAssignDialog.type === ActivityHelper.sessionDialogContents.EHS) {
                        activeEHSContentRef.current.getActiveEHSPings();
                    }
                } else {
                    CommonHelper.createNotification({ title: response.data.responseDetail, icon: "cross" });
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);;
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "cross" });
            });

    }

    const unAssignSessions = () => {
        setIsLoading(true);
        ApiClient.post("Activity/UnassignRSCSessions", { sessions: selectedSessions.map(s => s.id), modifiedBy: parseInt(userId), sessionType: openUnAssignDialog.type?.type })
            .then(response => {
                if (response.data) {
                    CommonHelper.createNotification({ title: response.data.responseDetail, icon: "check", timeout: 5000 });
                    toggleUnAssignDialog({ open: false, type: ActivityHelper.sessionUnAssignDialogContents.ACTIVITY });
                    setSelectedSessions([]);
                    if (openUnAssignDialog.type === ActivityHelper.sessionUnAssignDialogContents.ACTIVITY) {
                        activeActivityContentRef.current.getActiveActivities();
                    } else if (openUnAssignDialog.type === ActivityHelper.sessionUnAssignDialogContents.EHS) {
                        activeEHSContentRef.current.getActiveEHSPings();
                    }
                } else {
                    CommonHelper.createNotification({ title: response.data.responseDetail, icon: "cross" });
                }
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);;
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "cross" });
            });
    }

    const setSessions = (e, type) => {
        const detail = e.detail;
        const data = detail.data;

        if (type === "add") {
            setSelectedSessions([...selectedSessions, data]);
        } else if (type === "remove") {
            setSelectedSessions(selectedSessions.filter(item => item.id !== data.id));
        }
    }

    const getRscUsers = useCallback(() => {
        return allRscUsers.filter(r => r.rscCenterId === selectedRscCenter && (r.personType + "") === openAssignDialog.type?.role);
    }, [allRscUsers, selectedRscCenter, openAssignDialog.type]);

    return (
        <EuiRow disabled={isLoading ? "disabled" : ""}>
            <EuiLoading isLoading={isLoading} />

            <EuiPills pills={rscCenters} onClick={(selectedValue) => { setSelectedRscCenter(selectedValue); setSelectedSessions([]); }} selectedPill={selectedRscCenter} ></EuiPills>

            <EuiTabs
                id="activeSessionsListId"
                titles=
                {[
                    { id: 1, value: 1, name: "Activities", selected: "selected", onClick: () => { setSelectedSessions([]); activeActivityContentRef.current.getActiveActivities(); } },
                    { id: 2, value: 2, name: "EHS Pings", onClick: () => { setSelectedSessions([]); activeEHSContentRef.current.getActiveEHSPings(); } },
                ]}
                contents={[{
                    value: 1, children: <ActiveActivitySessions toggleAssignDialog={toggleAssignDialog}
                        setSelectedSessions={setSessions}
                        selectedSessions={selectedSessions}
                        toggleUnAssignDialog={toggleUnAssignDialog}
                        selectedRscCenter={selectedRscCenter}
                        ref={activeActivityContentRef}
                    />, selected: "selected"
                },
                {
                    value: 2, children: <ActiveEHSSessions toggleAssignDialog={toggleAssignDialog}
                        setSelectedSessions={setSessions}
                        selectedSessions={selectedSessions}
                        toggleUnAssignDialog={toggleUnAssignDialog}
                        selectedRscCenter={selectedRscCenter}
                        ref={activeEHSContentRef}
                    />, selected: ""
                }
                ]} />

            <DialogModal
                buttonName="Confirm"
                content={<EuiRow style={{ width: 600 }}>
                    <ObseleteEuiTable
                        hideTableInfo={true}
                        classes="tiny split-striped"
                        columns={userColumns}
                        data={getRscUsers()}
                        id="reAssignActivityRscTable"
                        selectable='single'
                        selectRow={(e, type) => setSelectedRscUser(type === "add" ? e.detail.data : { id: 0, value: 0 })}
                        rowsPerPage={10}
                        search={true}
                        viewActionButton={true}
                        height="464px"
                    />
                </EuiRow>}
                handleClose={() => toggleAssignDialog(false)}
                id="activeSessionsDialogId"
                onClick={() => assignSessions()}
                open={openAssignDialog.open}
                title={openAssignDialog.type?.title}
            >
            </DialogModal>

            <DialogModal
                buttonName="Confirm"
                content={<div>{"Are you sure you would like to un-assign selected " + openUnAssignDialog.type?.name + "?"} </div>}
                handleClose={() => toggleUnAssignDialog(false)}
                id="activeSessionsUnAssignDialogId"
                onClick={() => unAssignSessions()}
                open={openUnAssignDialog.open}
                title={openUnAssignDialog.type?.title}
            >
            </DialogModal>

        </EuiRow>
    );
}

export default withLayout(RscAssignment);