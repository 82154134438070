import * as mime from "mime-types";
import Constants from "../utils/constants";
import SasHelper from "./sas-helper";


export const getFileMimeType = (filePath) => {
    return mime.lookup(filePath);
}

export const getFileType = (filePath) => {
    let fileType = Constants.documentTypes.OTHER_DOCUMENTS;
    let fileMimeType = getFileMimeType(filePath); // Get mime type
    if (fileMimeType.toLowerCase().includes("video")) {
        fileType = Constants.documentTypes.VIDEO;
    } else if (fileMimeType.toLowerCase().includes("image")) {
        fileType = Constants.documentTypes.IMAGE;
    } else if (fileMimeType.toLowerCase().includes("pdf")) {
        fileType = Constants.documentTypes.PDF;
    } else if (fileMimeType.toLowerCase().includes("word")) {
        fileType = Constants.documentTypes.WORD;
    } else if (fileMimeType.toLowerCase().includes("excel")) {
        fileType = Constants.documentTypes.EXCEL;
    } else if (fileMimeType.toLowerCase().includes("presentation")) {
        fileType = Constants.documentTypes.PRESENTATION;
    }

    return fileType;
}

export const getFileExtension = (filename) => {
    const ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
}

export const ExistNoExtension = (files) => {

    if ((files || []).length === 0) {
        return false;
    }

    for (let i = 0; i < files.length; i++)
    {
        if (getFileExtension(files[i]) === "") {
            return true;
        }
    }
    return false;
}