import React, { useEffect } from "react";
import { Badge, Stack, Avatar, Typography } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { SelectConversation } from "../../store/slices/chat";
import StyledChatBox from "./StyledChatBox";
import EuiTextField from "../../eui-components/textfield";
import { SimpleBarStyle } from "./Scrollbar";

// const truncateText = (string, n) => {
//   return string?.length > n ? `${string?.slice(0, n)}...` : string;
// };


const ListParticipants = ({ el }) => {
  const dispatch = useDispatch();

  const { room_id } = useSelector((state) => state.chat);

  useEffect(() => {
    //dispatch(SelectConversation({ room_id: room_id }));
  }, [room_id])


  const theme = useTheme();

  return (
    <SimpleBarStyle spacing={1} timeout={500} clickOnTrack={false}>

      <StyledChatBox
        //   onClick={() => updateSelectedConversation()}
        sx={{
          overflow: "hidden",
          overflowY: "scroll",
          borderRadius: 1,
          paddingLeft: '8px',
          paddingTop: '4px',
          paddingBottom: '4px',
          marginRight: '16px',
          border: 1,
          backgroundColor: theme.palette.background.paper// "#fff"
        }}
      >
        <Stack
          sx={{ flexGrow: 1, overflow: "scroll" }}
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Stack direction="row">
            <Stack>
              <div style={{ fontWeight: "bold", marginTop: "5%" ,padding: 0,}}>{el?.userName}</div>
              <label >{el?.email}</label>
            </Stack>
          </Stack>

        </Stack>
      </StyledChatBox>
    </SimpleBarStyle>

  );
};

export default ListParticipants;
