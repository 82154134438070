import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux"
import DialogModal from '../../components/dialog';
import { requestForToken, onMessageListener } from './firebaseHelper';
import ApiClient from '../../utils/api-client';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { AddDirectMessage } from '../../store/slices/conversation';


const Notification = () => {
    let history = useHistory();

    // localStorage.removeItem("activityId");
    const [notification, setNotification] = useState({ title: '', body: [] });
    const [openNotification, setOpenNotification] = useState(false);

    const webToken = useSelector(state => state.user.webToken);
    const userData = useSelector(state => state.user.userData);
    const dispatch = useDispatch();
    const notify = () => toast(<ToastDisplay />, {
        theme: "dark",
        closeButton:true
    });


    useEffect(() => {
        requestForToken().then(currentToken => {

            if (webToken == currentToken) {
                return;
            }
            if (currentToken == null) {
                return;
            }
            else {
                ApiClient.post("User/AddUserWebToken", { userId: parseInt(userData.id), token: currentToken })
                    .then(response => {
                    }).catch(err => console.error(err));


            }
            dispatch({ type: "SET_WEB_TOKEN", payload: currentToken });
        })
    }, []);

    useEffect(() => {
        console.log("notification?.title",notification)
        if (!isEmpty(notification?.title)) {
            notify()
        }
    }, [notification])

    function ToastDisplay() {
        return (

            <div>
                <div key={"NotifcationHandlerDialogChat"} style={{ width: 280, height: "35%" }}>
                    <h4>  {"Message received! "}</h4>
                    <label>{"Name: " + notification?.body[0]?.user?.name}</label>
                    <label>{"Message: " + notification?.body[0]?.text}</label>
                    <label>{"ActivityId: " + notification?.body[0]?.activityId}</label>
                    {/* <label  >{"EhsPingId: "+ notification?.body[0]?.ehsPingId}</label> */}


                    <div className="pt-1">
                        <button
                            type="button"
                            className={"btn ghost pt-1 "}
                            style={{ color: "green" }}
                            onClick={() => window.open(`/activities?id=${notification?.body[0]?.activityId}`, "_blank")}>
                                {"check messages"}
                        </button>
                    </div>

                </div>
            </div>
        );
    };

    onMessageListener().then((payload) => {

        var localStgactivityId = localStorage.getItem("activityId");
        const activityID = JSON.parse(localStgactivityId)
        const body = JSON.parse(payload.data.message)
        if (payload?.notification?.title?.includes("ChatMessage")){

            if (activityID !== body[0]?.activityId) {
                setNotification({ title: payload?.notification?.title, body: body });
            }
            else{
                let message = body[0]
                const user_id = parseInt(userData.userId)
                var sender = message.user?._id || message.user?.id 
                
                dispatch(AddDirectMessage({
                    id: new Date(message.createdAt).getTime() + sender + message?.text,
                    _rnId: message._rnId,
                    type: "msg",
                    subtype:
                            !isNull(message?.audio) && !isUndefined(message?.audio) ? "audio" : !isNull(message?.video) && !isUndefined(message?.video) ? "video" : !isNull(message?.image) && !isUndefined(message?.image) ? "img" : !isNull(message?.file) && !isUndefined(message?.file) ? "doc" : "Text",
                    ehsPingId: message.ehsPingId,
                    message: message?.text || null,
                    userName: message.user?.name,
                    email: message.user?.email,
                    // profileAvatar: res.user?.avatar,
                    // preview: null,
                    audio_path: !isNull(message?.audio) ? message?.audio : null,
                    file_path: !isNull(message?.file) ? message?.file : null,
                    video_path: !isNull(message?.video) ? message.video : null,
                    image_path: !isNull(message?.image) ? message.image : null,
                    createdAt: message.createdAt,
                    incoming: parseInt(sender) !== parseInt(user_id),// to
                    outgoing: parseInt(sender) === parseInt(user_id)// sender 
            
                }));
            }
            setOpenNotification(false);
        } else {
            setNotification({ title: payload?.notification?.title, body: payload?.notification });
            alert(payload?.notification?.title);
            setOpenNotification(true);

        }

    })
        .catch((err) => console.error('failed: ', err));


    return (<>
        <ToastContainer
            pauseOnFocusLoss={false}
            icon={false}
            autoClose={false}
            position="bottom-right"
            reverseOrder={false}
          
        >
        </ToastContainer>

        <DialogModal
            title={<b><h3>{notification?.title}</h3></b>}
            content={<div><h3 style={{ color: "red" }}>{notification?.body}</h3></div>}
            open={openNotification}
            id="NotifcationHandlerDialog"
            handleClose={() => setOpenNotification(false)}
            buttonName="I confirm"
            onClick={() =>
                setOpenNotification(false)}
            contentStyle={{ width: "500px", height: "100px" }} />
    </>

    )
}

export default Notification