import React from "react";
import { Card } from "@eds/vanilla/card/Card";

class EuiCard extends React.Component {
    componentDidMount() {
         if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new Card(element);
                    component.init();                    
                });
            }
        }
        else {
            console.error("You should set an id to Card component");
        }        
    }

    render() {
        return (
            <>
                <div id={this.props.id} className={"card " + (this.props.className ?? "")} style={{ padding: 10, ...this.props.style }}
                    onClick={() => this.props.onClick && this.props.onClick()}> 
                    <div className={"header " + (this.props.headerClass ?? "")} >
                        <div className="left">
                            <div className="title" style={{fontSize:14, ...this.props.titleStyle}}>{this.props.title}</div> 
                            <div className="subtitle">{this.props.subTitle}</div>                           
                        </div>
                        <div className="right">
                            <div className="action">
                                {this.props.actionIcon}
                            </div>
                        </div>
                    </div>
                    <div className={"content " + this.props.contentClassName} style={{paddingTop:0, ...this.props.contentStyle}}>
                        {this.props.content}
                   </div>
                </div>
            </>
        );
    }
}

export default EuiCard;
