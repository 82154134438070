import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import FormField from "../../eui-components/formfield";
import EuiDatepicker from "../../eui-components/datepicker";

const TeamMembersInfo = (props) => {
    const userData = useSelector(state => state.user);
    const dispatch = useDispatch();
    const member = useSelector(state => state.projects.member)
    const teamMember = {...member}
    const handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        teamMember[name] = value
        dispatch({ type: "SET_TEAM_MEMBER", payload: teamMember });
    }
    return (
        <FormField className="w-10">
                <div style={{ paddingTop: "20px" }}>
                    <EuiAutocompleteSelect
                    required
                    className="w-5"
                    labelClass="required"
                    id="representativesSelect"
                    name="userId"
                    label={{
                        title: "Team member *"
                    }}
                    options={userData.users.filter(i => (i.role != 3 && i.role != 4 && i.role != 100)).map(x => { return { text: x.text + " - " + (x.mail == null ? "" : x.mail )+ " - " + (x.jobTitle == null ? "" : x.jobTitle), value: x.value } }  ) || []}
                        style={{ marginRight: "180px", width: "400px" }}
                        labelStyle={{ paddingTop: "20px" }}
                        value={ teamMember.userId || -1}
                        optionListStyle={{ width: "400px" }}
                        onChange={(e) => handleChange(e)}
                        placeholder="---Select team member---"
                    />
                </div>
                <EuiDatepicker
                    type="text"
                    placeholder="YYYY-MM-DD"
                    disabled={false}
                    id="elgibility"
                    name="validUntil"
                    style={{ width: "400px" }}
                    maxLength="10"
                    labelStyle={{ paddingTop: "20px" }}
                    label="Valid Until *"
                    value={teamMember.valid_until || ""}
                    className="with-icon"
                    onChange={(e) => handleChange(e)}
                    required={true}
                />
        </FormField>
    )
}
export default TeamMembersInfo;
