
import { runWithAdal } from 'react-adal';
import { authContext } from './utils/adalConfig';
import { Tune } from '@material-ui/icons';

const DO_NOT_LOGIN = false;

runWithAdal(
    authContext,
    () => { require('./indexApp'); },
    DO_NOT_LOGIN
);




