import { Notification } from '@eds/vanilla';
import DateUtils from './date-utils';
import { isNull } from 'lodash';

export default class CommonHelper {

    static chunkArray = (myArray, chunk_size) => {
        let index = 0;
        const arrayLength = myArray.length;
        let tempArray = [];

        for (index = 0; index < arrayLength; index += chunk_size) {
            const myChunk = myArray.slice(index, index + chunk_size);
            tempArray.push(myChunk);
        }
        return tempArray;
    }

    static bytesToSize = (bytes) => {
        if ((bytes ?? -1) === -1) {
            return "Broken Link";
        }
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    static bytesToSizeMB = (bytes) => {
        if ((bytes || 0) === 0) return 0;
        return Math.round(bytes / (1024 * 1024));
    }

    static getPercentage = (completedSize, totalSize) => {
        if ((totalSize || 0) === 0) {
            return "0%";
        }

        return Math.floor(((completedSize || 0) / totalSize) * 100) + "%";
    }

    static validateEmail(email) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
            return true
        else {
            return false;

        }
    }

    static onlyUnique = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    static isValidEmail = (email) => {
        if (!email) {
            return false;
        }
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static isValidPassword = (str) => {
        if (!str) {
            return false;
        }

        var re = /^(?=.*\d)(?=.*[!@#$%^&*.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(str);
    }

    static capitalizeFirstLetter = (string) => {
        if (!string) {
            return "";
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static generateGuid = () => {
        let result, i, j;

        result = '';
        for (j = 0; j < 32; j++) {
            if (j == 8 || j == 12 || j == 16 || j == 20)
                result = result + '';
            i = Math.floor(Math.random() * 16).toString(16).toLowerCase();
            result = result + i;
        }

        return result;
    }

    static isValidCoordinate = (coordinate) => {
        if (!coordinate) {
            return false;
        }
        const re = /^-?[0-9]{1,3}[.][0-9]+$/;
        // /^-?[0-9]\d+(\.\d+)*$/;
        return re.test(coordinate);
    }

    static createNotification = (props) => {
        const notification = new Notification({
            title: props.title,
            description: props.description,
            icon: props.icon,
            timeout: props.timeout, // 5 seconds
            action: props.action
        });

        notification.init();
    }

    static renderTableDate = (td, cellData) => {
        if (cellData) {
            const date = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm:ss");
            td.innerHTML = `${date}`;
        }
    }


    // returns the browser name, type and version, and sets body classes
// detects major browsers: IE, Edge, Firefox, Chrome, Safari, Opera, Android
static getBrowser = () => {
    const ua = navigator.userAgent;
    let browser;
  
    // helper functions to deal with common regex
    function getFirstMatch(regex) {
      const match = ua.match(regex);
      return (match && match.length > 1 && match[1]) || '';
    }
  
    function getSecondMatch(regex) {
      const match = ua.match(regex);
      return (match && match.length > 1 && match[2]) || '';
    }
  
    
    
  
    // start detecting
    if (ua.indexOf('Edg') > -1 || /chrome.+? edge/i.test(ua)) {
      browser = {
        name: 'edge',
        type: 'msedge',
        version: getFirstMatch(/edge\/(\d+(\.\d+)?)/i),
      };
    } else if (/opera|opr/i.test(ua)) {
      browser = {
        name: 'opera',
        type: 'opera',
        version:
          getFirstMatch(/version\/(\d+(\.\d+)?)/i) ||
          getFirstMatch(/(?:opera|opr)[\s/](\d+(\.\d+)?)/i),
      };
    } else if (/msie|trident/i.test(ua)) {
      browser = {
        name: 'internet-explorer_9-11',
        type: 'msie',
        version: getFirstMatch(/(?:msie |rv:)(\d+(\.\d+)?)/i),
      };
    } else if (/chrome|crios|crmo/i.test(ua)) {
      browser = {
        name: 'chrome',
        type: 'chrome',
        version: getFirstMatch(/(?:chrome|crios|crmo)\/(\d+(\.\d+)?)/i),
      };
    } else if (/firefox/i.test(ua)) {
      browser = {
        name: 'firefox',
        type: 'firefox',
        version: getFirstMatch(/(?:firefox)[ /](\d+(\.\d+)?)/i),
      };
    } else if (!/like android/i.test(ua) && /android/i.test(ua)) {
      browser = {
        name: 'samsung-internet',
        type: 'android',
        version: getFirstMatch(/version\/(\d+(\.\d+)?)/i),
      };
    } else if (/safari/i.test(ua)) {
      browser = {
        name: 'safari-ios',
        type: 'safari',
        version: getFirstMatch(/version\/(\d+(\.\d+)?)/i),
      };
    } else {
      browser = {
        name: getFirstMatch(/^(.*)\/(.*) /),
        version: getSecondMatch(/^(.*)\/(.*) /),
      };
      browser.type = browser.name.toLowerCase().replace(/\s/g, '');
    }
    return browser;
  };
    static getBrowserLogo = (browserId) => {
        return !isNull(browserId) &&browserId.startsWith('internet_')
          ? `https://cdnjs.cloudflare.com/ajax/libs/browser-logos/70.1.0/archive/${browserId}/${browserId}.svg`
          : `https://cdnjs.cloudflare.com/ajax/libs/browser-logos/70.1.0/${browserId}/${browserId}.svg`;
      };
      
      /**
 * get visitor data from local storage
 * @returns {any}
 */
 static getVisitorData = () => {
    return JSON.parse(window.localStorage.getItem('visitor')) || {};
  };

  /** CommonHelper.getVisitorData()
 * save visitor in local storage
 * @param vCountry
 * @param vIP
 * @param latitude
 * @param longitude
 * @param vBrowser
 */
  static saveVisitorInLocalStorage = (vCountry, vIP, latitude, longitude, vBrowser) => {
    const visitor = this.getVisitorData();
    const data = {
      vCountry : vCountry || visitor.vCountry,
      vIP: vIP || visitor.vIP,
      vBrowser: vBrowser || visitor.vBrowser,
      latitude: (latitude || visitor.latitude) + '',
      longitude: (longitude || visitor.longitude) + '',
    };
    window.localStorage.setItem('visitor', JSON.stringify(data));
  };

  static  saveVisit = (savelog = true) => {
    let visitorCountry = '';
    let visitorIP = '';
    let latitude = '';
    let longitude = '';
    let success = false;
    let location = false;
    const visitor = this.getVisitorData();

    const save = ({visitorCountry, visitorIP, latitude, longitude,  visitorBrowser}) =>
    {
      this.saveVisitorInLocalStorage(visitorCountry, visitorIP, latitude, longitude,  visitorBrowser);
    }
    
    // Get Browser
    const visitorBrowser = this.getBrowser().name;

    // Check if geolocation is supported by the browser
    if ("geolocation" in navigator) {
      // Prompt user for permission to access their location
      navigator.geolocation.getCurrentPosition(
        // Success callback function
        (position) => {
          // Get the user's latitude and longitude coordinates
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          location = true;
          latitude = lat;
          longitude = lng;
          save({visitorCountry, visitorIP, latitude, longitude,  visitorBrowser})
        },
        // Error callback function
        (error) => {
          // Handle errors, e.g. user denied location sharing permissions
          console.error("Error getting user location:", error);
          location = false;

        }
      );
    } else {
      // Geolocation is not supported by the browser
        location = false;
        console.error("Geolocation is not supported by this browser.");
    }
    
    if(!visitor.ip)
    {
      fetch(
        'https://api.ipify.org/?format=json'
      )
        .then((response) => response.json())
        .then(data => {
          visitorIP = data.ip;
          fetch(
            'https://json.geoiplookup.io/'+data.ip
          )
          .then((response) => response.json())
          .then((result) => {
            visitorCountry = result.country_code;
            visitorIP = result.ip;
            latitude = result.latitude;
            longitude = result.longitude;
            success = true;
            
          })
          .catch((error) => {
            success = false;
          })
        })
        .catch((error) => {
          success = false;
        })
        .finally(e=> save({visitorCountry, visitorIP, latitude, longitude,  visitorBrowser}));
    }
      
    if(!visitor.ip && !success && ! location)
    {
      fetch("https://api.geoapify.com/v1/ipinfo?&apiKey=910e94fb4dea49a4a09fc1e5c653c5bc")
      .then(response => response.json())
      .then(result => {
        visitorCountry = result.country.iso_code;
        visitorIP = result.ip;
        latitude = result.location.latitude;
        longitude = result.location.longitude;
      })
      .catch(error => console.error('error', error))
      .finally(e => save({visitorCountry, visitorIP, latitude, longitude,  visitorBrowser}));
    }
    ///console.log(visitorCountry, visitorIP, latitude, longitude,  visitorBrowser)
  };
}

