import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import ApiClient from "../../utils/api-client";
import { Notification } from '@eds/vanilla';
import EuiLoading from "../../eui-components/loading";
import DialogModal from "../../components/dialog";
import constants from "../../utils/constants";
import AspHelper from "../../utils/asp-helper";
import DateUtils from "../../utils/date-utils";
import AssignProject from "./assignProject"



class AssignedProjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            columns: [{
                title: "Project ID",
                key: "projectId",
                width: "5%",
                sort: 'none'
            },
            {
                title: "Project Name",
                key: "name",
                width: "12%",
                sort: 'none'
            },
            {
                title: "Customer Name",
                key: "customerName",
                width: "12%",
                sort: 'none'
            },
            {
                title: "Valid Until",
                key: "validUntil",
                width: "12%",
                sort: 'desc',
                onCreatedCell: (td, cellData) => {
                    td.innerHTML = cellData?.slice(0, 10) ?? ""
                }
            },
            {
                title: "",
                key: "editId",
                width: "2%",
                hideFilter: true,
                onCreatedCell: (td, cellData) => {
                    if (cellData && (this.props.userData.userData.jobRole == constants.userRoles.ERICSSON_IM || this.props.userData.userData.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                    )) {
                        td.innerHTML = `<span id="edit_" class="tooltip"><i id="edit_" style={font-weight:500px;} class="icon icon-edit color-black text-lg clickable "></i><span class="message top-end">Edit Project</span></span>`;
                        td.querySelector("#edit_").addEventListener('click', () => this.initiateEdit(cellData))
                    }
                    else
                        td.innerHTML = "";
                }
            },
            {
                title: "",
                key: "deleteId",
                width: "2%",
                hideFilter: true,
                onCreatedCell: (td, cellData) => {
                    if (cellData && (this.props.userData.userData.jobRole == constants.userRoles.ERICSSON_IM || this.props.userData.userData.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                    )) {
                        td.innerHTML = `<span id="delete_" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black text-lg clickable "></i><span class="message top-end">Unassign Project</span></span>`;
                        td.querySelector("#delete_").addEventListener('click', () => this.openDeleteDialog(cellData))
                    }
                    else
                        td.innerHTML = "";
                },
            },
            ],
            isLoading: false,
            selectedProject: {},
            openDeleteDialog: false,
            openAssignDialog: false,
            openEditAssignDialog: false
        }
    }


    updateData = () => {
        this.setState({
            isLoading: true
        })
        const filter = this.props.aspResourcesData.filter;
        if (!filter || Object.values(filter).every(x => (x === null || x === ''))) {
            filter.id = this.props.aspResourcesData.resource.id;
            filter.idsList = [];
            filter.idsList.push(this.props.aspResourcesData.resource.id);
        }
        filter.id = parseInt(filter.id ?? "0");
        ApiClient.post("Asp/GetResources", filter)
            .then(response => {
                ApiClient.post("Asp/GetResourceJobs", response.data.iDs)
                    .then(response2 => {
                        var _data = [];
                        var blobs = response.data.profileBlobs;
                        var certificates = [];
                        var resourceProjects = response.data.projects;
                        var resourceJobs = response2.data;
                        var skills = response.data.skills;
                        var faces = response.data.faces;
                        this.props.setResourceUsers(response.data.users);
                        response.data.certifications.forEach(c => {
                            var _c = this.props.aspResourcesData.certificates.filter(x => x.value == c.certificationId);
                            if (_c && _c[0]) {
                                var date = DateUtils.getUtcDate();
                                var diff = DateUtils.dateDifference(new Date(c.validUntil), new Date(date));
                                var element = {
                                    ...c,
                                    validSince: DateUtils.toDateString(c.validSince),
                                    validUntil: DateUtils.toDateString(c.validUntil),
                                    createdate: DateUtils.toDateString(c.createdate),
                                    lastModifiedDateTime: DateUtils.toDateString(c.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                                    createUserName: c.createUserName,
                                    modifiedUserName: c.modifiedUserName,
                                    name: _c[0].text,
                                    code: _c[0].code,
                                    path: c.path,
                                    status: (DateUtils.dateDifference(new Date(c.validUntil), new Date(date)) > 0 ? "Valid" : "Expired"),
                                    _delete: c.id
                                }
                                certificates.push(element);
                            }
                        })
                        this.props.setFilteredResources([]);
                        response.data.resource.forEach(e => {
                            var projects = AspHelper.mapProjects(resourceProjects?.filter(item => item.resourceId == e.id), this.props.aspResourcesData.projects, this.props.userData.customers);
                            var country = this.props.userData.countries.filter(c => c.value === e.countryId);
                            var company = this.props.aspResourcesData.companies.filter(c => c.value === e.aspCompanyId);
                            var cert = certificates.filter(c => c.resourceId === e.id);
                            var jobs = [];
                            var resourceSkills = skills.filter(s => s.resourceId == e.id);
                            var faceId = faces.find(f => f.resourceId == e.id)?.faceId;
                            var fullResourceSkills = AspHelper.getSkillInfo(resourceSkills, this.props.aspResourcesData.skills);
                            var _jobs = resourceJobs.filter(j => j.resourceId == e.id);
                            var inCompletedCerts = [];
                            var validResource = false;
                            _jobs.forEach(j => jobs.push(AspHelper.mapResourcesJobRole(j.value, this.props.aspResourcesData.jobs)));
                            var blob = blobs.filter(b => b.resourceId == e.id);
                            inCompletedCerts = AspHelper.getUnTakenCerts(cert, this.props.aspResourcesData.jobs, certificates, jobs);
                           /* if (e.status == "Active") {
                                var test = true;
                                if (inCompletedCerts) {
                                    inCompletedCerts.forEach(c => {
                                        if (!c.valid)
                                            test = false;
                                    });
                                }
                                if (test)
                                    validResource = true;
                            }
                                */
                            var test = true;
                            if (inCompletedCerts) {
                             inCompletedCerts.forEach(c => {
                                 if (!c.valid)
                                     test = false;
                             });
                         }
                         validResource = test
                            if (jobs)
                            var element = {
                                ...e,
                                projects: projects,
                                status: e.status,
                                country: (country && country[0] ? country[0].text : ""),
                                aspCompany: (company && company[0] ? company[0].text : ""),
                                companyCountry: (company && company[0] ? company[0].countryId : ""),
                                profileImg: (blob && blob[0] ? constants.profileStorage + blob[0].key  : ""),
                                certs: cert,
                                jobs: (jobs ? jobs : []),
                                skills: fullResourceSkills,
                                jobList: (_jobs ? _jobs : []),
                                inCompletedCerts: inCompletedCerts,
                                validResource: validResource,
                                faceId: faceId,
                                path: (faceId ? e.path : null),
                                imagePath: (faceId ? e.imagePath : null)
                            }
                            _data.push(element);
                        });
                        const data = [..._data];
                        const selectedResource = data.find(item => item.id === this.props.aspResourcesData.resource.id);
                        this.props.setAspResource(selectedResource);
                        this.props.setFilteredResources(data);
                        this.props.setSelectedItem(6);
                        var d = Date.now();
                        this.props.updateTable(d);
                        this.setState({ isLoading: false })




                    })
            }).catch(error => {
                console.error(error);;
                alert(error);
            });



    }

    openDeleteDialog = (cellData) => {
        const resource = { ... this.props.aspResourcesData.resource };
        const project = resource.projects.find(p => p.projectId == cellData);
        this.setState({
            selectedProject: project,
            openDeleteDialog: true
        })
    }

    assignProject = () => {
        const today = new Date();
        const assignedProject = { ...this.props.aspResourcesData.assignedProject };
        const diff = DateUtils.dateDifference(new Date(assignedProject.validUntil), new Date(today))
        if (!assignedProject.projectId || !assignedProject.validUntil || !assignedProject.customerId) {
            const notification = new Notification({
                title: 'Assign Project',
                description: 'Please select project to assign',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        if (diff < 1) {
            const notification = new Notification({
                title: 'Assign Project',
                description: 'Valid until date must be in future',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        assignedProject['resourceId'] = parseInt(this.props.aspResourcesData.resource.id);
        assignedProject['resourceEmail'] = this.props.aspResourcesData.resource.email;
        assignedProject['assignedBy'] = parseInt(this.props.userData.userData.id);
        this.props.setAssignedProject(assignedProject);
        this.setState({ isLoading: true, openAssignDialog: false, openEditAssignDialog: false },
            () => ApiClient.post("Projects/AssignResourceProject", assignedProject)
                .then(response => {
                    if (response) {
                        this.setState({
                            isLoading: false
                        })
                        this.updateData();
                        const notificationSuccess = new Notification({
                            title: 'Assign Project',
                            description: 'Project assigned successfully',
                            timeout: 5000,
                            icon: 'icon-check'
                        });
                        notificationSuccess.init();
                        this.props.setAssignedProject({});
                    }
                })
                .catch(error => {
                    alert(error);
                    this.setState({
                        isLoading: false
                    })
                })
        );
    }

    initiateEdit = (id) => {
        const resource = { ... this.props.aspResourcesData.resource };
        const project = resource.projects.find(p => p.projectId == id);
        this.props.setAssignedProject(project);
        this.setState({
            selectedProject: project,
            openEditAssignDialog: true
        });
    }

    deleteProject = () => {
        const project = this.state.selectedProject;
        project['assignDate'] = null;
        this.setState({
            isLoading: true,
            openDeleteDialog: false
        }, () => {
            ApiClient.post("Projects/UnAssignProject", project)
                .then(response => {
                    if (response.data) {
                        const resource = { ... this.props.aspResourcesData.resource }
                        const projects = resource.projects.filter(p => p.projectId !== project.projectId);
                        resource['projects'] = projects;
                        this.props.setAspResource(resource);
                        this.setState({
                            isLoading: false
                        });
                        const notificationSuccess = new Notification({
                            title: 'unassign Project',
                            description: 'Project unassigned successfully',
                            timeout: 5000,
                            icon: 'icon-check'
                        });
                        notificationSuccess.init();
                    }
                    else {
                        this.setState({
                            isLoading: false
                        });
                        const notificationSuccess = new Notification({
                            title: 'unassign Project',
                            description: 'Unassign project faild. Please try again later',
                            timeout: 5000,
                            icon: 'icon-cross'
                        });
                        notificationSuccess.init();
                    }
                })
                .catch(error => {
                    alert(error);
                    this.setState({
                        isLoading: false
                    });
                })
        })
    }

    initiateAssign = () => {
        this.setState({
            openAssignDialog: true
        })
    }

    cancel = () => {
        this.setState({
            openEditAssignDialog: false,
            openAssignDialog: false
        })
        this.props.setAssignedProject({});
    }

    render() {
        const resourceProjects = [... this.props.aspResourcesData.resource.projects];
        return (<EuiRow key={this.state.isLoading} disabled={this.props.aspResourcesData.resource.status == "Blacklisted" ? "disabled" : ""} >
            <EuiLoading isLoading={this.state.isLoading} />
            <ObseleteEuiTable
                classes="tiny"
                columns={this.state.columns}
                data={resourceProjects}
                id="assignedProjects"
                sortable={true}
                paginated={true}
                viewActionButton={true}
                hideSearch={true}
                filter={true}
                actionButton={
                    <button hidden={(this.props.userData.userData.jobRole != constants.userRoles.VALIDATION_OFFICER && this.props.userData.userData.jobRole != constants.userRoles.ERICSSON_IM && this.props.userData.userData.jobRole != constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR || this.props.aspResourcesData.resource.status === "Archived") || this.props.aspResourcesData.resource.status == "Blacklisted" ? true : false}
                        id="assignProject"
                        className="btn primary ml-10"
                        onClick={this.initiateAssign}>{<span > <i class="icon icon-plus"></i> Assign Project</span>}</button>}
                rowsPerPage={resourceProjects.length}
            >
            </ObseleteEuiTable>
            <DialogModal
                id="deleteProject"
                open={this.state.openDeleteDialog}
                title="Unassign Project"
                content={
                    <div>Are you sure you want to unassign this project?</div>}
                buttonName="Unassign"
                type="primary"
                handleClose={() => { this.setState({ openDeleteDialog: false }) }}
                onClick={this.deleteProject}
                mainButtonStyle={{ backgroundColor: 'red' }}
                disableBackdropClick={true}
            />
            <DialogModal
                id="assignProject"
                open={this.state.openAssignDialog}
                title="Assign Project"
                content={<AssignProject />}
                customStyle={{ width: 450, height: 600 }}
                buttonName="Assign"
                contentStyle={{ height: "78%" }}
                type="primary"
                handleClose={this.cancel}
                onClick={this.assignProject}
                disableBackdropClick={true}
            />
            <DialogModal
                id="assignProject"
                open={this.state.openEditAssignDialog}
                title="Edit Project"
                content={<AssignProject />}
                customStyle={{ width: 450, height: 600 }}
                contentStyle={{ height: "78%" }}
                buttonName="Save"
                type="primary"
                handleClose={this.cancel}
                onClick={this.assignProject}
                disableBackdropClick={true}
            />
        </EuiRow>)
    }
}



const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp  });
const mapDispatchToProps = dispatch => {
    return {
        setAssignedProject: (project) => dispatch({ type: "SET_ASSIGNED_PROJECT", payload: project }),
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        changeAspResourcesMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setFilteredResources: (filteredResources) => dispatch({ type: "SET_FILTERED_RESOURCES", payload: filteredResources }),
        setResourceFilter: (filter) => dispatch({ type: "SET_RESOURCE_FILTER", payload: filter }),
        setResourceCompanies: (companies) => dispatch({ type: "SET_RESOURCE_COMPANIES", payload: companies }),
        setResourceCountries: (countries) => dispatch({ type: "SET_RESOURCE_COUNTRIES", payload: countries }),
        setCertificates: (certs) => dispatch({ type: "SET_CERTIFICATES", payload: certs }),
        setJobs: (jobs) => dispatch({ type: "SET_JOBS", payload: jobs }),
        setIDTypes: (types) => dispatch({ type: "SET_ID_TYPES", payload: types }),
        updateTable: (val) => dispatch({ type: "UPDATE_TABLE", payload: val }),
        setSkills: (skills) => dispatch({ type: "SET_SKILLS", payload: skills }),
        setResourceUsers: (users) => dispatch({ type: "SET_USERS", payload: users }),
        setSelectedItem: (selectedItem) => dispatch({ type: "UPDATE_SELECTED_ITEM", payload: selectedItem }),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AssignedProjects));