import React from "react";
import DateUtils from "../../utils/date-utils";
import Table from "../table";
import multiDownload from "multi-download";
import EuiRow from "../../eui-components/row";

class RecordingModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            allRecordingColumns: [
                {
                    title: "Name",
                    field: "recordName",
                    render: rowData => <a onClick={() => { this.props.openDialog && this.props.openDialog(rowData) }}> {rowData.recordName}</ a>,
                },
                {
                    title: "Recorded User",
                    field: "recordedUserName",
                },
                {
                    title: "Duration",
                    field: "duration",
                    render: rowData => rowData.duration + " min"
                },
                {
                    title: "Start Datetime",
                    field: "startDatetime",
                    defaultSort: "desc",
                    render: (rowData) => DateUtils.toDateString(rowData.startDatetime, "YYYY-MM-DD HH:mm")
                },
                {
                    title: "End Datetime",
                    field: "endDatetime",
                    defaultSort: "desc",
                    render: (rowData) => DateUtils.toDateString(rowData.endDatetime, "YYYY-MM-DD HH:mm")
                },
            ],
            recordingsTableRef: React.createRef()
        }
    }

    downloadSelectedFiles = (data) => {
        let files = [...data.map(file => file.recordPath)];
        multiDownload(files, {});
        this.state.recordingsTableRef.current && this.state.recordingsTableRef.current.onAllSelected(false);
    }

    render() {

        return (
            <EuiRow style={{ overflow: "auto", minHeight: 50 }}>
                <Table
                    actions={[{
                        icon: () => <i className="icon icon-download-save"></i>,
                        tooltip: "Download selected recordings",
                        onClick: (event, data) => this.downloadSelectedFiles(data)
                    }
                    ]}
                    titleStyle={{ fontSize: 14 }}
                    columns={this.state.allRecordingColumns}
                    data={this.props.data ?? []}
                    options={{
                        paging: false,
                        search: false,
                        selection: true,
                        cellStyle: { fontSize: "0.875rem", padding: 0, paddingLeft: 10 },
                        showTextRowsSelected: false,
                        showSelectAllCheckbox: false,
                        minBodyHeight: 100
                    }}
                    tableRef={this.state.recordingsTableRef}
                >
                </Table>
            </EuiRow>
        )
    }
}

export default RecordingModal;