/* eslint-disable no-self-compare */
import {
  Box,
  IconButton,
  Stack,
} from "@mui/material";
import {
  Microphone,
  PaperPlaneTilt
} from "phosphor-react";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useDispatch, useSelector } from "react-redux";
import ChatInput from "./ChatInput";
import { isEmpty, isNull, isUndefined } from "lodash";

import { AddDirectMessage, getChatTime } from "../../../store/slices/conversation";
import ButtonRecoredAudio from "./ButtonRecoredAudio";
import useRecorder from "./useRecorder";
import PageHelper from "../../../utils/page-helper";
import { Notification } from "@eds/vanilla";
import useResponsive from "../useResponsive";
import DateUtils from "../../../utils/date-utils";
import { HubConnectionBuilder, LogLevel, HubConnectionState } from "@microsoft/signalr";
import { logger } from "@azure/storage-blob";
import { setIsSentMessage } from "../../../store/slices/chat";
import ApiClient from "../../../utils/api-client";
import constants from "../../../utils/constants";

function linkify(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(
    urlRegex,
    (url) => `<a href="${url}" target="_blank">${url}</a>`
  );
}



const Footer = ({ connectionStatus,createNewConversationOrRoom, connection, refreshAndReconnect }) => {
  const userData = useSelector(state => state.user);

  const user_id = parseInt(userData.userData.userId)
  var ehsPingId = JSON.parse(localStorage.getItem("ehsPingId"));

  const dispatch = useDispatch();
  const activityData = useSelector((state) => state.activity);

  const theme = useTheme();
  const { participantInvited, current_conversation } = useSelector(
    (state) => state.conversation.direct_chat
  );



  const { isSentMsg, room_id } = useSelector((state) => state.chat);

  const [openPicker, setOpenPicker] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputSelection, setInputSelection] = useState(null);
  const [value, setValue] = useState("");

  const inputRef = useRef(null);

  const [state, setState] = useState("");
  const [record, setRecord] = useState(false);

  let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();


  useEffect(() => {
    if (!!audioURL) {
      PageHelper.uploadItems(audioURL, "audio", "chat-files").then(uploadedFiles => {
        sendAudio(uploadedFiles);
        const notification = new Notification({
          title: 'Audio is uploaded successfully!',
          timeout: 5000,
          icon: 'icon-check'
        });
        notification.init();

      }).catch(e => console.error("upload file to azure issue", e))

    }
  }, [audioURL]);

  useEffect(() => {
    if (inputSelection) {
      const input = inputRef.current;
      input.setSelectionRange(inputSelection.selectionStart, inputSelection.selectionEnd)
    }
  }, [inputSelection]);


  // useEffect(() => {
  //   if (connection) {
  //     onConnecting();
  //   }

  // }, [connection]);

  function Buttonstart() {
    setState("red");
    setRecord(true);
    startRecording();
  }

  async function Buttonstop() {
    if (isRecording === true) {
      await stopRecording();
      setState("#4695da");
      setRecord(false);


    } else {
      alert("Give permission audio to record");
    }
  }


  function handleEmojiClick(emoji) {
    const input = inputRef.current;

    if (input) {
      const selectionStart = input.selectionStart;
      const selectionEnd = input.selectionEnd;
      const msg = value.substring(0, selectionStart) + emoji + value.substring(selectionEnd)

      setValue(msg);
      setInputSelection({ selectionStart: selectionStart + emoji.length, selectionEnd : selectionEnd + emoji.length })
      // Move the cursor to the end of the inserted emoji
    }
  }
  function handleClickOutside(e) {
    if (openPicker) {
      setOpenPicker(false);
    }
  }


  async function sendAudio(mediaFile)
  {
    if(connection== null || connection.state!=="Connected"){
      const notification = new Notification({
        title: 'Not connected',
        description: 'Reconnecting.. Please wait!',
        timeout: 5000,
        icon: 'icon-triangle'
      });
      notification.init();
      refreshAndReconnect(); //

    }else{
      createNewConversationOrRoom();
      if (connection)
      {
        // user send a new msg format audio so we need to create a new conversation for this current user
  
        //#region mobile connections
        // const user = (participantInvited || [])?.find((elm) => parseInt(elm?.userId) === parseInt(user_id));
  
        const newMessage = {};
        const arrayMsg = [];
        const userItem = {};
  
        userItem.name = userData.userDetail?.name;
        userItem.email = userData.userDetail?.email;
        userItem.avatar = null
  
        userItem._id = user_id
  
        newMessage.text = linkify(value);
        newMessage.audio = mediaFile[0]?.documentPath;
        newMessage.time = DateUtils.toUtcDate(new Date());
        newMessage.createdAt = DateUtils.toUtcDate(new Date());
        newMessage.from = user_id;
        newMessage.activityId = current_conversation?.activityid;
        newMessage.ehsPingId = current_conversation?.ehsPingId;
        newMessage.user = userItem
        arrayMsg.push(newMessage);
  
        connection.invoke("SendMessageAsync", room_id + "", user_id + "", JSON.stringify(arrayMsg))
          .catch(function (err) {
            console.log("SendMessageAsync", err);
          });
  
        //#endregion
        // my web Hub
        setValue("");
      }
      else
      {
        refreshAndReconnect();
      }
    }
   
  }

  async function sendTextMessage()
  {
    createNewConversationOrRoom();

    if (isEmpty(value))
    {
      const notification = new Notification({
        title: 'Input Text Empty',
        description: 'Please write a text message',
        timeout: 5000,
        icon: 'icon-triangle'
      });
      notification.init();

    }
    else if(connection== null || connection.state!=="Connected"){

      const notification = new Notification({
        title: 'Not connected',
        description: 'Reconnecting.. Please wait!',
        timeout: 5000,
        icon: 'icon-triangle'
      });
      notification.init();
 refreshAndReconnect(); //
    }
    else
    {
      if (connection)
      {

        // user send a new msg format audio so we need to create a new conversation for this current user
        //#region  mobile socket connection
        // const user = (participantInvited || [])?.find((elm) => parseInt(elm?.userId) === parseInt(user_id));
        ///console.log("send text msg user",user,participantInvited)

        const userItem = {}
        userItem.name = userData.userDetail?.name;
        userItem.email = userData.userDetail?.email;
        userItem.avatar = null
        userItem._id = user_id

        const newMessage = {};
        const arrayMsg = [];
        newMessage.text = linkify(value);
        newMessage.time = DateUtils.toUtcDate(new Date());
        newMessage.createdAt = DateUtils.toUtcDate(new Date());
        newMessage.from = user_id;
        newMessage.ehsPingId = ehsPingId;
        newMessage.activityId = current_conversation?.activityid;
        newMessage.user = userItem

        arrayMsg.push(newMessage);

        connection.invoke("SendMessageAsync", room_id + "", user_id+ "", JSON.stringify(arrayMsg))
          .catch(function (err) {
            console.log("SendMessageAsync err", err);
          });

        //#endregion

        setValue("");

      }
      else
      {
        refreshAndReconnect();
      }
    }
  }

  return (
    <>
      <Box
        sx={{
          position: "relative",
          backgroundColor: "transparent !important",
        }}
      >
        <Box
          p={2}// isMobile ? 1 :
          width={"100%"}
          sx={{
            backgroundColor: theme.palette.mode === "light"
              ? "#F8FAFF"
              : theme.palette.background,
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Stack direction="row" alignItems={"center"} spacing={3}>

            <Stack sx={{ width: "100%" }}>
              <Box
                style={{
                  zIndex: 10,
                  position: "fixed",
                  display: openPicker ? "inline" : "none",
                  bottom: 81,
                  right: 100,//isMobile ? 20 : sideBar.open ? 420 : 
                }}
              >
                <Picker

                  onClickOutside={handleClickOutside}
                  theme={theme.palette.mode}
                  data={data}
                  onEmojiSelect={(emoji) => {
                    handleEmojiClick(emoji.native);
                  }} />
              </Box>
              {/* Chat Input isLoading && */}
              {!record &&
                <ChatInput
                  ehsPingId={ehsPingId}
                  id={"key" + value}
                  inputRef={inputRef}
                  value={value}
                  setValue={setValue}
                  openPicker={openPicker}
                  setOpenPicker={setOpenPicker}
                  sendTextMessage={sendTextMessage}
                  connection={connection}
                  setIsLoading={setIsLoading}
                  createNewConversationOrRoom={createNewConversationOrRoom}
                  connectionStatus={connectionStatus}
                  refreshAndReconnect={refreshAndReconnect}
                   />}
            </Stack>

            {<ButtonRecoredAudio record={record} state={state} Buttonstart={Buttonstart} Buttonstop={Buttonstop} />}

            <Box
              sx={{
                height: 48,
                width: 48,
                backgroundColor: theme.palette.primary.main,
                borderRadius: 1.5,
              }}
            >
              <Stack
                sx={{ height: "100%" }}
                alignItems={"center"}
                justifyContent="center"
              >
                <IconButton
                  onClick={() => { sendTextMessage(); }}
                  style={{
                    height: '42px',
                    maxHeight: '42px',
                    Width: '60px',
                    maxWidth: '60px'
                  }}
                >
                  <PaperPlaneTilt height={32} width={32} color="#ffffff" />
                </IconButton>
              </Stack>
            </Box>

          </Stack>
        </Box>
      </Box></>
  );
}

export default Footer;
