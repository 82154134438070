import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import EuiLoading from '../../eui-components/loading';
import EuiRow from '../../eui-components/row';
import EuiTile from '../../eui-components/tile';
import { withLayout } from "../../layout";
import ApiClient from '../../utils/api-client';
import CommonHelper from '../../utils/common-helper';
import constants from '../../utils/constants';
import PageHelper from '../../utils/page-helper';
import SasHelper from "../../utils/sas-helper";

const ReportDownload = (props) => {
  let { link } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [report, setReport] = useState({ });
  const dispatch = useDispatch();
  const toggleNavbar = () => dispatch({ type: "TOGGLE_NAVBAR" })
  const sasToken = useSelector(state => state.complaints.sasToken);

  useEffect(() => {
    toggleNavbar();
    ApiClient.post('Report/GetReportLink', { Id: link }).then(response => {
      
      setReport({...response.data});
      
      if (!sasToken) {
        SasHelper.getSasKey().then(response => {
            dispatch({ type: "SET_SAS_TOKEN", payload: response });
            setIsLoading(false);
        })
      }
      else
      {
        setIsLoading(false);
      }
    }).catch(error => {
      console.error(error);;
      CommonHelper.createNotification({ title: constants.errorMessage, icon: "cross" });
      setIsLoading(false);
    });
  }, []);

  const handleDownload = () => {
    
    PageHelper.download(SasHelper.addSasKey(report.link , sasToken))
  }

  return (
    <EuiRow disabled={isLoading ? "disabled" : ""} key={isLoading}>
      <EuiLoading isLoading={isLoading} />
      <EuiTile styleContent={{ paddingTop: 32, textAlign: 'center',  }} fullscreen>
        <button className="btn primary mt-5" onClick={handleDownload}>
          Download File
        </button>
      </EuiTile>
    </EuiRow>
  )
}

export default withLayout(ReportDownload);