import React from "react";
import EuiProgressBar from "../../eui-components/progressbar";
import CommonHelper from "../../utils/common-helper";

class DocumentLoadingModal extends React.Component {
    render() {
        const completedSize = this.props.percentageArr.filter(d => d.percentage === 100).length;
        const totalSize = this.props.length;
        return (this.props.compressPercentage > 0 ?
            <div style={{ width: 500 }}>
                <label><strong>Compressing files...</strong></label>
                <EuiProgressBar value={this.props.compressPercentage} />
            </div>
            :
            <div style={{ width: 700 }}>
                <label>
                    <strong>
                        {this.props.session.id + "-" + (this.props.session.name || "EHS Ping") + " : " +
                            completedSize + " / " + totalSize + " completed..."
                        }
                    </strong>
                </label>
                <br />
                <div className="scrollable-fix-height">
                    {
                        this.props.percentageArr.map(file => {
                            const fileName = file.recordName || file.name.name || file.name;
                            return <div key={file.id} className="center-container " style={{ display: (file.percentage === 100 ? "none" : null) }} >
                                <label><strong>{fileName + " ( " + CommonHelper.bytesToSize(file.size) + " ) "}</strong></label>
                                <EuiProgressBar value={file.percentage || 0} style={{ width: 400 }} />
                            </div>
                        })
                    }
                </div>
            </div >)
    }
}

export default DocumentLoadingModal;