import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import Table from "../../../components/table/index";
import { ToastContainer, toast } from 'react-toastify';
import SimpleExpansionPanel from "../../../components/expansionpanel";
import PageHelper from "../../../utils/page-helper";
import CreateEditSessionActivity from "./createEditSessionActivity";
import DialogModal from "../../../components/dialog";
import ApiClient from "../../../utils/api-client";
import EuiProgressBar from "../../../eui-components/progressbar";
import { cloneDeep } from "lodash";
import SasHelper from "../../../utils/sas-helper";

class FifthStepContent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            sessionActivityColumns: [{ title: "Activity Name", field: "name", render: (rowData) => this.renderSessionActivity(rowData) }],
            sessionDocumentsColumns:
                [
                    {
                        title: "Name",
                        field: "documentPath",
                        render: (rowData) => <a href={SasHelper.addSasKey(rowData.documentPath)} target="_blank">{rowData.name}</a>,
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "30%" },
                    },
                    {
                        title: "Status",
                        field: "acceptanceStatusId",
                        render: (rowData) => PageHelper.renderStatus(rowData.acceptanceStatusId),
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "20%" },
                    },
                    {
                        title: "Ericsson Comments",
                        field: "ericssonComments",
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "50%" },
                    },
                ],
            sessionActivities: [],
            openDialog: false,
            openDialog1: false,
            currentSessionActivity: { id: 0 },
            newSessionActivity: {},
            percentage: 0,
            openDialogUploadBar: false,
            percentageArr: []
        }
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.sessionData.sessionInfo.id > 0) {
            ApiClient.post("Session/GetSessionDocuments", { id: this.props.sessionData.sessionInfo.id })
                .then(response => {
                    if (this._isMounted) {
                        if (response.data) {
                            this.setState({ sessionActivities: response.data }, () => this.setDocuments());
                        } else {
                            this.setState({ sessionActivities: [] }, () => this.setDocuments());
                        }
                    }
                }).catch(error => {
                    console.error(error);;
                });
        } else {
            this.props.setSessionActivityDocuments([]);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderSessionActivity = (rowData) => {
        return <SimpleExpansionPanel
            id={"accordion" + rowData.id}
            title={rowData.name}
            content={<Table
                columns={this.state.sessionDocumentsColumns}
                data={rowData.documents}
                options={{
                    paging: false,
                    search: false,
                    toolbar: false,
                }}
            >
            </Table>
            }
        />
    }

    toggleDialog = (type = null, rowData = null) => {
        this.setState({ openDialog: !this.state.openDialog }, () => {
            if (type) {
                if (type == "add") {
                    this.setState({ currentSessionActivity: { id: 0 } });
                } else if (type == "edit") {
                    if (rowData) {
                        this.setState({ currentSessionActivity: cloneDeep(rowData) });
                    }
                }
            }
        });
    }

    toggleDialog1 = (rowData = null) => {
        this.setState({ openDialog1: !this.state.openDialog1 }, () => {
            if (rowData) {
                this.setState({ currentSessionActivity: rowData });
            }
        })
    }

    addEditSessionActivity = () => {
        this.setState({ openDialogUploadBar: false, isLoading: true }, () => {
            if (this.state.newSessionActivity.id == 0 && this.state.currentSessionActivity.id == 0) {
                ApiClient.post("Session/AddSessionActivity", this.state.newSessionActivity)
                    .then(response => {
                        
                        if (response.data) {
                            this.setState({ isLoading: false, sessionActivities: [...this.state.sessionActivities, response.data] },
                                () => {
                                    
                                    this.props.setSessionActivityIds(this.state.sessionActivities.map(item => item.id));
                                    this.setDocuments();
                                    toast.success("Session Activity was created succesfully.", { toastId: "sessionActivityAdd", containerId: 'E', position: toast.POSITION.TOP_CENTER });
                                });
                        }
                    })
                    .catch(error => {
                        this.setState({ isLoading: false }, () => console.error(error));
                    })
            } else {
                if (this.state.newSessionActivity.id == 0) {
                    this.setState({ isLoading: false },
                        () => toast.error("Some tasks are working, please try again later!", { toastId: "sessionActivityEdit", containerId: 'E', position: toast.POSITION.TOP_CENTER })
                    );
                    return;
                }
                ApiClient.post("Session/EditSessionActivity", this.state.newSessionActivity)
                    .then(response => {
                        if (response.data) {
                            const index = this.state.sessionActivities.findIndex(item => item.id == this.state.newSessionActivity.id);
                            if (index > -1) {
                                let data = [...this.state.sessionActivities];
                                
                                data[index] = response.data;
                                
                                this.setState({ isLoading: false, sessionActivities: data }, () => this.setDocuments());
                                toast.success("Session Activity was edited succesfully.", { toastId: "sessionActivityEdit", containerId: 'E', position: toast.POSITION.TOP_CENTER })
                            } else {
                                this.setState({ isLoading: false });
                                toast.error("Some errors occur! Please try later", { toastId: "sessionActivityEdit", containerId: 'E', position: toast.POSITION.TOP_CENTER })
                            }
                        }
                    })
                    .catch(error => {
                        this.setState({ isLoading: false },
                            () => {
                                console.error(error);;
                                toast.error("Some errors occur! Please try later", { toastId: "sessionActivityEdit", containerId: 'E', position: toast.POSITION.TOP_CENTER })
                            }
                        );
                    })
            }
        });
    }

    initializePercentageArr = (files) => {
        let percentageArr = [...files];
        percentageArr["percentage"] = 0;
        return percentageArr.map(item => {
            item.percentage = 0
            return item;
        });
    }

    mapSessionDocuments = (files) => {
        return this.state.newSessionActivity.documents.map((item, index) => ({
            ...item,
            documentPath: item.id === 0 ? files[files.indexOf(item.file)].documentPath : item.documentPath,
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude,
        }));
    }

    handleFiles = () => {
        const files = [...this.state.newSessionActivity.documents.map(item => item.file).filter(doc => doc)];
        

        if (files.length === 0) {
            this.addEditSessionActivity();
            return;
        }

        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            toast.error(fileSize, { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            return;
        }

        PageHelper.getCoordinates();

        this.setState({ openDialogUploadBar: true, percentageArr: this.initializePercentageArr(files) }, () => {
            const onProgress = (progressEvent, file) => {
                let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                let percentageArr = [...this.state.percentageArr];
                const index = percentageArr.indexOf(file);
                percentageArr[index].percentage = percentage;
                this.setState({ percentageArr });
            }

            PageHelper.uploadItems(files, onProgress).then(uploadedFiles => {
                const documents = this.mapSessionDocuments(uploadedFiles);
                
                this.setState(prevState => ({
                    ...prevState,
                    newSessionActivity: {
                        ...prevState.newSessionActivity,
                        documents
                    }
                }),
                    () => this.addEditSessionActivity()
                );
            }).catch(error => {
                console.error(error);;
            });
        });
        
    }

    onSubmitDialog = () => {
        
        const documents = [...this.state.newSessionActivity.documents];
        if (documents.length > 0) {
            if (documents.filter(item => item.tableData && (item.tableData.editing || item.tableData.deleting)).length > 0) {
                toast.error("Please finish edit/delete document process!", { toastId: "sessionActivityEditControl", containerId: 'E', position: toast.POSITION.TOP_CENTER })
                return;
            }
        }

        this.setState({ openDialog: false }, () => {
            this.handleFiles()
        });
    }

    renderRow = (rowData) => {
        var selected = (this.state.currentSessionActivity && this.state.currentSessionActivity.id === rowData.id);
        return {
            background: selected ? (this.props.navBarData.theme === "light" ? "silver" : '#353535') : 'inherit',
            //color: selected ? "white" : "inherit",
        };
    }

    setDocuments = () => {
        const documents = this.state.sessionActivities && this.state.sessionActivities.length > 0 && this.state.sessionActivities.map(item => item.documents).reduce((a, b) => { return a.concat(b); });
        
        if (documents) {
            this.props.setSessionActivityDocuments(documents);
        } else {
            this.props.setSessionActivityDocuments([]);
        }
    }

    deleteSessionActivity = () => {
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Session/DeleteSessionActivity", { id: this.state.currentSessionActivity.id, updateUser: this.props.userData.userData.userId })
                .then(response => {
                    if (response.data) {
                        let sessionActivities = [...this.state.sessionActivities];
                        const index = this.state.sessionActivities.indexOf(this.state.currentSessionActivity);
                        if (index > -1) {
                            sessionActivities.splice(index, 1);
                            this.setState({ isLoading: false, sessionActivities }, () => this.setDocuments());
                            toast.success("Session Activity was deleted succesfully.", { toastId: "sessionActivityDelete", containerId: 'E', position: toast.POSITION.TOP_CENTER })
                        }
                    } else {
                        this.setState({ isLoading: false }, () => {
                            
                            toast.error("Some errors occur! Please try later", { toastId: "sessionActivityDelete", containerId: 'E', position: toast.POSITION.TOP_CENTER })
                        });
                    }
                }).catch(error => {
                    this.setState({ isLoading: false }, () => {
                        console.error(error);;
                        toast.error("Some errors occur! Please try later", { toastId: "sessionActivityDelete", containerId: 'E', position: toast.POSITION.TOP_CENTER })
                    });
                })
        });
        this.toggleDialog1();
    }

    render() {
        return (
            <>
                <EuiRow>

                    <Table
                        actions={
                            [
                                {
                                    icon: "add",
                                    isFreeAction: true,
                                    tooltip: "Create Session Activity",
                                    onClick: (event, rowData) => this.toggleDialog("add"),
                                    hidden: this.props.sessionData.sessionInfo.viewOnly
                                },
                                {
                                    icon: "edit",
                                    tooltip: "Edit Session Activity",
                                    onClick: (event, rowData) => this.toggleDialog("edit", rowData),
                                    hidden: this.props.sessionData.sessionInfo.viewOnly
                                },
                                {
                                    icon: "delete",
                                    tooltip: "Delete Session Activity",
                                    onClick: (event, rowData) => this.toggleDialog1(rowData),
                                    hidden: this.props.sessionData.sessionInfo.viewOnly
                                }
                            ]
                        }
                        title="Session Activities"
                        columns={this.state.sessionActivityColumns}
                        data={this.state.sessionActivities}
                        isLoading={this.state.isLoading}
                        options={{
                            paging: false,
                            search: false,
                            header: false,
                            rowStyle: (rowData) => this.renderRow(rowData),
                        }}
                        onRowClick={(event, rowData) => this.setState({ currentSessionActivity: rowData })}
                    >
                    </Table>

                    <DialogModal
                        buttonName={this.state.currentSessionActivity.id == 0 ? "Create" : "Save"}
                        content={<CreateEditSessionActivity
                            sessionActivity={this.state.currentSessionActivity}
                            getNewSessionActivity={(newSessionActivity) => this.setState({ newSessionActivity })}
                        />}
                        handleClose={this.toggleDialog}
                        id="sessionActivityDialog"
                        onClick={this.onSubmitDialog}
                        open={this.state.openDialog}
                        title={this.state.currentSessionActivity.id == 0 ? "Create New Session Activity" : "Edit Session Activity"}
                    >
                    </DialogModal>

                    <DialogModal
                        buttonName="Delete"
                        content={<p>Are you sure you want to delete?</p>}
                        handleClose={this.toggleDialog1}
                        id="deleteDialogActivity"
                        onClick={this.deleteSessionActivity}
                        open={this.state.openDialog1}
                        title="Delete"
                        type="warning"
                        titleId="deleteTitleActivity"
                    >
                    </DialogModal>

                    <DialogModal
                        content={this.state.percentageArr.map(file => {
                            return <div key={file.name} style={{ width: 500 }}>
                                <label><strong>{file.name}</strong></label>
                                <EuiProgressBar value={file.percentage} />
                            </div>
                        })}
                        handleClose={() => this.setState({ openDialogUploadBar: false })}
                        id="uploadBarSessionDialog"
                        titleId="titleuploadBarSessionDialog"
                        open={this.state.openDialogUploadBar}
                        buttonsVisible="false"
                        title="Progress Loading..."
                        closeButtonVisible="false"
                    >
                    </DialogModal>

                    <ToastContainer enableMultiContainer containerId={'E'} />
                </EuiRow >
            </>
        );
    }
}
const mapStateToProps = state => ({ sessionData: state.session, userData: state.user, navBarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
        setSessionActivityIds: (ids) => dispatch({ type: "SET_SESSION_ACTIVITY_IDS", payload: ids }),
        setSessionActivityDocuments: (documents) => dispatch({ type: "SET_SESSION_ACTIVITY_DOCUMENTS", payload: documents })
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FifthStepContent);
