import React from 'react';

const EuiTag = (props) => {

    return (
        <div id={props.id}>
            <kbd id={props.id} onClick={() => props.onClick && props.onClick()}
                className={"tag " + (props.type ?? "big") + " " + (props.className ?? "")}
                style={props.style}>
                <i className={"icon icon-" + (props.icon ?? " hidden ")}></i>
                <span>{props.name}</span>
            </kbd>
        </div>
    );
}
export default EuiTag;