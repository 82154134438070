import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import { Chip } from "@material-ui/core";
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';


class SessionInformation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {            
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <EuiRow>
                <div className="sm-12 md-12 lg-12 xl-12 chip-wrapper" style={{ ...this.props.style, borderBottom: (this.props.hideSessionSummary ? "none" : "") }}>  
                    <div>
                        <i className="icon icon-bookmark chip-icon" style={this.props.titleStyle}><span className="subactivity-title">{this.props.title ?? "Activity Documents Summary"}</span></i>                        
                    </div>
                    <Chip className="summary-chip color-text" style={{ backgroundColor: green[200], fontSize: 14  }} label={"Accepted:" + this.props.totalStatus(2)} ></Chip>
                    <Chip className="summary-chip color-text" style={{ backgroundColor: red[200], fontSize: 14  }} label={"Rejected:" + this.props.totalStatus(3)}></Chip>
                    <Chip className="summary-chip color-text" style={{ backgroundColor: grey[400], fontSize: 14  }} label={"Pending:" + this.props.totalStatus(1)}></Chip>
                    {this.props.reworkTotal ?
                        <Chip className="summary-chip color-text" style={{ backgroundColor: "#DCAF00", fontSize: 14  }} label={"Rework:" + this.props.totalStatus(4)}></Chip>
                        : null
                    }
                </div>
                {this.props.hideSessionSummary ?
                    null :
                    <div className="sm-12 md-12 lg-12 xl-12 chip-wrapper" style={{ ...this.props.style, borderBottom: "none" }}>
                        <div>
                            <i className="icon icon-bookmark chip-icon" style={this.props.titleStyle}><span className="subactivity-title">Session Documents Summary</span></i>
                        </div>
                        <Chip className="summary-chip color-text" style={{ backgroundColor: green[200], fontSize:14 }} label={"Accepted:" + this.props.totalStatus(2,2)} ></Chip>
                        <Chip className="summary-chip color-text" style={{ backgroundColor: red[200], fontSize: 14  }}  label={"Rejected:" + this.props.totalStatus(3,2)}></Chip>
                        <Chip className="summary-chip color-text" style={{ backgroundColor: grey[400], fontSize: 14  }} label={"Pending:" + this.props.totalStatus(1, 2)}></Chip>
                        {this.props.reworkTotal ?
                            <Chip className="summary-chip color-text" style={{ backgroundColor: "#DCAF00", fontSize: 14  }} label={"Rework:" + this.props.totalStatus(4, 2)}></Chip>
                            : null
                        }
                    </div>
                }
            </EuiRow>
        );
    }
}


const mapStateToProps = state => ({ sessionData: state.session, userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionInformation);
