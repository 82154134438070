import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../../layout";
import ApproveSessionContent from "./approveSessionContent";

class ApproveSession extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
    }
    render() {
        return (
            <ApproveSessionContent />
        );
    }
}

const mapStateToProps = state => ({ sessionData: state.session, userData: state.user, navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(ApproveSession)));
