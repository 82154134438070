import React, { useEffect } from 'react';
import { Card, Checkbox, Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { isBoolean } from 'lodash';
import Box from '@material-ui/core/Box';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import VehicleHelper from '../../utils/vehicle-helper';
import DateUtils from '../../utils/date-utils';

const CardStyled = styled(Card)`
  margin-top: 16px;
  padding: 8px;
`;

const MainGrid = styled(Grid)`
  margin-top: 5px;
  padding: 6px;
`;

const useStyles = makeStyles((theme) => ({
  wrapText: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '360px',
    whiteSpace: 'nowrap',
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export default function TableCard(props) {
  const { data } = props;
  const classes = useStyles();


  return (
    <Box style={{ maxHeight: '50vh', padding: '5px', overflow: 'auto' }}>
      {data?.map((obj) => (
        <CardStyled>
    
          { Object?.keys(obj).map((key) => (
            
            <MainGrid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              {/* left item */}
              <Grid item>
                <Typography size="small" variant="subtitle2">
 
                  {key}
                </Typography>
              </Grid>

              {/* right item */}

              {(() => {
                switch (true) {
                  case Array.isArray(obj[key]):
                    return (
                      <Grid item>
                        <Typography
                          className={classes.wrapText}
                          variant="body2"
                        >
                          {obj[key].join(', ')}
                        </Typography>
                      </Grid>
                    );
                  case isBoolean(obj[key]) :
                    return (
                      <Grid item style={{ marginRight: '-12px' }}>
                        <Checkbox
                          disabled
                          checked={!!obj[key]}
                          style={{
                            paddingTop: '0px',
                            paddingBottom: '0px',
                          }}
                        />
                      </Grid>
                    );
                    case key==="isVehicleCompliant":
                    if(obj[key]==="Compliant"){
                      return (
                      <div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>{obj[key]}</div>
                    );
                    }else{
                      return (
                        <div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>{obj[key]}</div>
                     
                    );
                    }
                   
                    case key==="vehicleStatus":
                    return (
                      <div dangerouslySetInnerHTML={{__html: VehicleHelper.vehicleHTMLTableStatus[obj[key]]}}></div>
                     
                    );
                  // eslint-disable-next-line no-lone-blocks
                  {/* case typeof obj[key] === 'string' &&
                    isStringAValidImagePath(obj[key]):
                    return (
                      <Grid item>
                        <Avatar
                          alt=""
                          src={getImageUrl(obj[key])}
                          className={classes.small}
                        />
                      </Grid>
                    ); */}
                  // eslint-disable-next-line no-restricted-globals 
                  case typeof obj[key] !== 'number' &&
                    isNaN(obj[key]) &&
                    moment(obj[key]).isValid():
                    return <Grid item>{
                       obj[key] ?   DateUtils.toDateString(obj[key]): ''
                        }</Grid>;
                  default:
                    return (
                      <Grid item>
                        <Typography
                          className={classes.wrapText}
                          variant="body2"
                        >
                          {obj[key]}
                        </Typography>
                      </Grid>
                    );
                }
              })()}
            </MainGrid>
          ))}
        </CardStyled>
      ))}
    </Box>
  );
}
