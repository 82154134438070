import React from "react";

export default class EuiTable extends React.Component {
    render() {
        const classes = ["table " ];

        return (
            <>
                <table className={classes.join(' ') + this.props.classes}>
                    <thead>
                        <tr>
                            {this.props.columns &&
                                this.props.columns.map(column => {
                                    return <th key={column.field} className={"is-sortable " + column.classes} style={this.props.styleHeader}>{column.title}</th>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data && this.props.data.map(item => {
                            return (
                                <tr key={item.name}>
                                    {item && Object.values(item).map((value, index) => {
                                        return <td key={item.name + index } style={this.props.styleTd}>{value}</td>
                                    })
                                    }
                                </tr>
                            );
                        })
                        }                      
                    </tbody>
                </table>
            </>
        );
    }
}
