const initialState = {
    mod: 1,
    filter: {},
    filteredWorkplans: { data: [], totalCount: 0, initialState: 0 },
    workplan: { id: 0, documents: [], activities: [], ehsPings: [], keyElement: "" },
    documents: [],
    localFiles: "",
    updateElement: "",
    selectedWorkplans: [],
};

export default (state = initialState, action) => {
    switch (action.type) {

        case "SET_WORKPLAN":
            return {
                ...state,
                workplan: action.payload
            };
        case "CHANGE_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_FILTERED_WORKPLANS":
            return {
                ...state,
                filteredWorkplans: action.payload
            };
        case "SET_FILTER":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_DOCUMENTS":
            return {
                ...state,
                documents: action.payload
            }
        case "SET_LOCAL_FILES":
            return {
                ...state,
                localFiles: action.payload
            }
        case "SET_UPDATE_ELEMENT": {
            return {
                ...state,
                updateElement: action.payload
            }
        }
        case "SET_SELECTED_WORKPLANS":
            return {
                ...state,
                selectedWorkplans: action.payload
            }

        default:
            return state;
    }

};
