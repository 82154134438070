import React from "react";
import { RangeSlider } from '@eds/vanilla';

export default class EuiRangeSlider extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }
    componentDidMount() {
        if (this.inputRef.current) {
            this.inputRef.current.addEventListener('change', (evt) => { this.onChange(evt) });
        }

        const ranges = document.querySelectorAll('#' + this.props.id);

        if (ranges) {
            Array.from(ranges).forEach((rangeDOM) => {
                const range = new RangeSlider(rangeDOM);
                if (this.props.blackTheme) {
                    var elements = document.getElementsByClassName("range-limit")
                    for (let i = 0; i < elements?.length; i++) {
                        elements[i].style.color = "#fff";
                    }
                }

                range.init();
            });
        }

    }
    onChange = (e) => {
        this.props.onChange && this.props.onChange(e);
    }

    render() {
        return (
            <>
                <label style={{ fontSize: "14px" }} className={this.props.labelClass} htmlFor={this.props.id}>{this.props.label}</label>
                <input name={this.props.name} value={this.props.value} ref={this.inputRef} className={"range " + (this.props.tresholds ?? "")} onChange={this.props.onChange}
                    type="range" min={this.props.min}
                    max={this.props.max}
                    step={this.props.step}
                    id={this.props.id}
                    multiple={this.props.multiple}
                    values={this.props.values}
                    disabled={this.props.disabled}
                    readOnly={this.props.readonly}
                />
            </>
        )
    }
}