import React from "react";
import { Select } from "@eds/vanilla/select/Select";

class EuiAutocompleteSelect extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new Select(element);
                    component.init();
                });
            }
        }
        else {
            console.error("You should set an id to Select component");
        }
    }

    clearSelectedValue = (e) => {
        const target = e.target;
        const value = target.value;
        if (!value) {
            this.props.onChange && this.props.onChange({ target: { value: 0, name: this.props.name, text: "" } });
        }
    }

    render() {
        const classes = {};
        classes["fullwidth"] = this.props.fullwidth;
        classes["disabled"] = this.props.disabled;
        classes["required"] = this.props.required;
        const p = {
            "data-type": "input-single",
            "selectedOptions": this.props.value
        }

        return (
            <div className={this.props.className + (this.props.disabled  ? " disabled " : "")} style={this.props.divStyle}>
                {this.props.label ? <label className={this.props.label.className} style={{ fontSize: 14, fontWeight: 500, color: "gray",opacity: 2.6, ...this.props.label.style }} htmlFor={this.props.id + "_input"}>{this.props.label.title} </label>
                    : null
                }
                <div id={this.props.id}
                    className={"select pr-1 " + (this.props.disabled  ? "disabled" : "")}
                    style={{ width: "100%", maxWidth: 650,opacity: 2.6, ...this.props.style }}
                    {...p} >

                    <div className="clickable current-options">
                        <input autoComplete="off"
                            type="text"
                            id={this.props.id + "_input"}
                            placeholder={this.props.placeholder ?? "---Select Option---"}
                            {...classes}
                            style={this.props.inputStyle}
                            defaultValue= {this.props.defaultValue ? this.props.defaultValue : ((this.props.options || []).find(option => option.value == this.props.value) || { text: "" }).text}
                            onBlur={(e) => this.clearSelectedValue(e)}
                        />
                    </div>

                    <div className="options-list" style={{ ...this.props.optionListStyle }} >
                        {this.props.options &&
                            this.props.options.map(o => (
                                <div key={o.value}
                                    className={"item " + (o.hidden ? " hidden " : "") + (!o.hidden && this.props.value === o.value ? "active" : "")}
                                    onClick={() => this.props.onChange && this.props.onChange({ target: { value: (o.value ?? o.id), name: this.props.name, text: (o.text ?? o.name) } })}>
                                    {o.text ?? o.name}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div >
        );
    }
}

export default EuiAutocompleteSelect;