const initialState = {
    mod: 1,
    imActivityList: null,
    currentIMActivity: { id: 0 },
    imActivityFilter: {},
    imFilterTypes: ["1", "2"],
    imRatings: [],
};

export default (state = initialState, action) => {
    switch (action.type) {

        case "CHANGE_IM_INBOX_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_IM_ACTIVITY_LIST":
            return {
                ...state,
                imActivityList: action.payload
            };
        case "SET_CURRENT_IM_ACTIVITY":
            return {
                ...state,
                currentIMActivity: action.payload
            };
        case "SET_IM_ACTIVITY_FILTER":
            return {
                ...state,
                imActivityFilter: action.payload,
            };
        case "SET_IM_FILTER_TYPES":
            return {
                ...state,
                imFilterTypes: action.payload
            };
        case "SET_IM_RATINGS":
            return {
                ...state,
                imRatings: action.payload
            };
        default:
            return state;
    }

};
