import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import constants from "../../utils/constants";
import EuiRow from "../../eui-components/row";
import DocumentDialogModal from "../../components/documentDialogModal";
import { Bubble, Composer, GiftedChat, InputToolbar, MessageText, Send } from 'react-native-gifted-chat';
import Constants from "../../utils/constants";
import ApiClient from "../../utils/api-client";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import produce from "immer";
import CommonHelper from "../../utils/common-helper";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import EuiTooltip from "../../eui-components/tooltip";
import { View } from "react-native";
import PageHelper from "../../utils/page-helper";
import DialogModal from "../../components/dialog";
import EuiProgressBar from "../../eui-components/progressbar";
import { Tooltip } from "@material-ui/core";
import * as FileHelper from "../../utils/file-helper";
import EuiLoading from "../../eui-components/loading";
import SasHelper from "../../utils/sas-helper";

class TicketChat extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            openDialogUploadBar: false,
            percentageArr: [],
            editMod: constants.actionMods.EDIT,
            openDialogPreview: false,
            selectedDocument: {},
            messages: [{
                _id: "123456",
                text: this.props.isReadOnly ? 'Read Only Mode' : 'Connecting..',
                createdAt: new Date().getTime(),
                system: true
            }
            ],
            hubConnection: null,
            isFirstMessageOnOpen: true
        }
    }

    componentDidMount() {
        this.getChatHistory();
    }

    onSend = (newMessage = []) => {
        const messageWithSAS = newMessage.map(item => {
            let isFile = false;
            if (item.file?.length > 0) {
                isFile = true;
            }
            return {
                ...item,
                video: item.video?.length > 0 ? SasHelper.addSasKey(item.video , this.props.ticketData.sasToken) : undefined,
                image: isFile ? (item.file?.length > 0 ? SasHelper.addSasKey(item.file , this.props.ticketData.sasToken) : undefined)
                    : (item.image?.length > 0 ? SasHelper.addSasKey(item.image , this.props.ticketData.sasToken) : undefined)
            };
        });

        if (this.state.isFirstMessageOnOpen) {
            this.sendMobileNotification(newMessage[0].text);
        }

        this.setState({ messages: GiftedChat.prepend(this.state.messages, messageWithSAS), isFirstMessageOnOpen: false });

        this.state.hubConnection.invoke("broadcastMessage", this.props.ticketData.currentTicket.id.toString(), `${newMessage[0]?.user?.name}`, JSON.stringify(newMessage)).catch(function (err) {
            
        });
    }

    sendMobileNotification = (message) => {
        const onSuccess = ({ data }) => {
            
        }

        const onFailure = error => {
            
        }

        const payload = {
            UserID: this.props.ticketData.currentTicket.createUserId,
            Title: "Ticket Chat - " + this.props.ticketData.currentTicket.id,
            Message: message
        };

        ApiClient.post("Ticketing/sendMobileNotification", payload)
            .then(onSuccess)
            .catch(onFailure)
    }

    getChatHistory = () => {
        const onSuccess = ({ data }) => {

            const modifiedMessages = data.map(item => {
                if (item.file?.length > 0) {
                    item.image = item.file;
                }
                return item;
            });

            if (modifiedMessages) {
                this.setState({ isLoading: false, messages: this.state.messages.concat(modifiedMessages) }, () => {
                    if (!this.props.isReadOnly) {
                        this.getMobileToken();
                    }
                })

            } else {
                if (!this.props.isReadOnly) {
                    this.getMobileToken();
                }
            }
        }

        const onFailure = error => {
            console.error(error);
            this.setState({ isLoading: false });
            toast.error("An error occured, try again later!", { containerId: 'TC', position: toast.POSITION.TOP_CENTER });
        }

        const payload = {
            ChatGroupCode: this.props.ticketData.currentTicket.id.toString()
        };

        ApiClient.post("Ticketing/GetChatHistory", payload)
            .then(onSuccess)
            .catch(onFailure)
    }

    createHubConnection = (mobileApiToken) => {
        this.setState({
            hubConnection: new HubConnectionBuilder()
                .withUrl(Constants.mobilChatUrl, {
                    accessTokenFactory: async () => {
                        return mobileApiToken;
                    },
                    withCredentials: false
                })
                .configureLogging(LogLevel.Debug)
                .build()
        }, () => {
            this.onConnecting();
        });
    }

    onConnecting = () => {
        const name = this.props.userData.userData.username;

        this.state.hubConnection
            .start()
            .then(() => {
                
                this.setState({
                    messages: produce(this.state.messages, message => {
                        message[0].text = "Connected"
                    }),
                    isLoading: false
                });

                this.state.hubConnection.on(this.props.ticketData.currentTicket.id.toString(), (nick, message) => {
                    const messagesObject = JSON.parse(message);
                    if (name == nick) { return }

                    let messageWithSAS = messagesObject.map(item => {
                        if (item.file?.length > 0) {
                            item.image = item.file;
                        }
                        return {
                            ...item,
                            video: item.video?.length > 0 ? SasHelper.addSasKey(item.video , this.props.ticketData.sasToken) : undefined,
                            image: item.image?.length > 0 ? SasHelper.addSasKey(item.image , this.props.ticketData.sasToken) : undefined,
                            file: item.file?.length > 0 ? SasHelper.addSasKey(item.file , this.props.ticketData.sasToken) : undefined
                        }
                    });

                    this.setState({ messages: GiftedChat.prepend(this.state.messages, messageWithSAS) });
                });


                this.state.hubConnection.onclose((error) => {
                    
                    this.setState({
                        messages: produce(this.state.messages, message => {
                            message[0].text = "Disconnected"
                        })
                    });
                })
            })
            .catch(err => {
                
                this.setState({
                    messages: produce(this.state.messages, message => {
                        message[0].text = "Failed to connect"
                    }),
                    isLoading: false
                });
            });
    }

    getMobileToken = () => {
        ApiClient.post("Ticketing/GetMobileToken", {})
            .then(response => {
                if (response.data) {
                    this.setState({ runOnce: false });

                    

                    this.createHubConnection(response.data);

                } else {
                    this.setState({ isLoading: false });
                    toast.info("Could not connect to chat", { containerId: 'TC', position: toast.POSITION.TOP_CENTER })
                }
            })
            .catch(error => {
                this.setState({ isLoading: false });
                
                toast.error("An error occured, try again later!", { containerId: 'TC', position: toast.POSITION.TOP_CENTER });
            })
    }

    renderVideo = (item) => {
        return <img
            alt={Constants.documentTypes.VIDEO}
            src={ActivityInboxHelper.getPosterPath(Constants.documentTypes.VIDEO)}
            onClick={() => {
            this.setState({
                openDialogPreview: true,
                selectedDocument: {
                    documentPath: item.currentMessage.video,
                    type: Constants.documentTypes.VIDEO
                }
            });
        }} />
    }
    renderImage = (item) => {
        return <img
            width={300}
            height={169}
            alt={Constants.documentTypes.FILE}
            src={item.currentMessage.file?.length > 0 ? ActivityInboxHelper.getPosterPath(Constants.documentTypes.FILE) : item.currentMessage.image}
            onClick={() => {
            this.setState({
                openDialogPreview: true,
                selectedDocument: {
                    documentPath: item.currentMessage.image,
                    type: item.currentMessage.file?.length > 0 ? Constants.documentTypes.OTHER_DOCUMENTS : Constants.documentTypes.IMAGE
                }
            });
        }} />
    }

    handleInportChatFile = (e) => {
        const files = [...e.target.files];

        this.setState({ openDialogUploadBar: true, percentageArr: PageHelper.initializePercentageArr(files) }, () => {
            const onProgress = (progressEvent, file) => {
                let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                let percentageArr = [...this.state.percentageArr];
                const index = percentageArr.indexOf(file);
                percentageArr[index].percentage = percentage;
                this.setState({ percentageArr });
            }

            PageHelper.uploadItems(files, onProgress, constants.ticketFilesContainer).then(uploadedFiles => {
                this.setState({
                    openDialogUploadBar: false
                });
                
                

                const messages = uploadedFiles.map((item) => {
                    const onlineDocPath = item.documentPath;
                    const fileType = FileHelper.getFileType(onlineDocPath);

                    let videoPath, imagePath, filePath;
                    if (fileType === constants.documentTypes.IMAGE) {
                        imagePath = onlineDocPath;
                    } else if (fileType === constants.documentTypes.VIDEO) {
                        videoPath = onlineDocPath;
                    } else {
                        filePath = onlineDocPath;
                    }

                    return {
                        _id: CommonHelper.generateGuid(),
                        text: onlineDocPath.replace(/^.*[\\\/]/, ''),
                        video: videoPath,
                        image: imagePath,
                        file: filePath,
                        createdAt: new Date(),
                        user: {
                            _id: parseInt(this.props.userData.userData.id),
                            avatar: "",
                            name: this.props.userData.userData.username
                        }
                    }
                });

                this.onSend(messages);
            }).catch(error => {
                console.error(error);;
            });
        });
    }

    renderSend = (props) => {
        return (
            <div className="flex-end-content mr-1">

                <EuiTooltip title="Import Files">
                    <label htmlFor={"uploadChatDocument"} >
                        <input
                            style={{ display: "none" }}
                            id={"uploadChatDocument"}
                            name="documentPath"
                            type="file"
                            multiple
                            disabled={this.props.isReadOnly}
                            onChange={e => this.handleInportChatFile(e)}
                        />
                        <Tooltip id="addFileApproveSession" title="Add Document">
                            <i className="icon icon-plus custom-big-icon" style={{ color: "gray" }}></i>
                        </Tooltip>
                    </label>
                </EuiTooltip>

                <Send {...props} disabled={this.props.isReadOnly} containerStyle={{ justifyContent: "center", alignItems: "center" }}>
                    <EuiTooltip title="Send">
                        <i className="icon icon-send custom-big-icon" style={{ color: "#1174E6" }}></i>
                    </EuiTooltip>
                </Send>
            </div>

        );
    }

    renderComposer = (props) => {
        return (
            <Composer {...props} disableComposer={this.props.isReadOnly} textInputStyle={{ border: 0, backgroundColor: "#FAFAFA", border: 0, fontFamily: '"Ericsson Hilda", Helvetica, Arial, sans- serif' }} />
        );
    }

    renderInputToolbar = (props) => {
        return (
            <InputToolbar {...props} containerStyle={{ backgroundColor: "#FAFAFA", border: "1px solid gray", borderRadius: 8, marginTop: 4 }} />
        );
    }

    renderMessageText = (props) => {
        return <MessageText {...props} textStyle={{
            left: { fontFamily: '"Ericsson Hilda", Helvetica, Arial, sans- serif' },
            right: { fontFamily: '"Ericsson Hilda", Helvetica, Arial, sans- serif' }
        }} />
    }

    renderBubble = (props) => {
        return <Bubble {...props}
            wrapperStyle={{
                right: {
                    marginLeft: 20,
                    marginBottom: 2,
                    backgroundColor: "#4D97ED",
                }
            }}
        />
    }

    render() {

        return (
            <EuiRow className="ticket-chat-responsive-container" disabled={this.state.isLoading ? "disabled" : ""}>
                <View style={{ height: "100%", width: "100%" }}>
                    <GiftedChat
                        messages={this.state.messages}
                        onSend={newMessage => {
                            if (newMessage[0]?.text?.length > 0) {
                                newMessage[0]._id = CommonHelper.generateGuid();
                                this.onSend(newMessage);
                            }
                        }}
                        user={{
                            _id: parseInt(this.props.userData.userData.id),
                            avatar: "",
                            name: this.props.userData.userData.username
                        }}
                        renderSend={this.renderSend}
                        renderMessageVideo={this.renderVideo}
                        renderMessageImage={this.renderImage}
                        renderComposer={this.renderComposer}
                        renderInputToolbar={this.props.isReadOnly ? () => null : this.renderInputToolbar}
                        minInputToolbarHeight={this.props.isReadOnly ? 0 : 46}
                        renderMessageText={this.renderMessageText}
                        renderBubble={this.renderBubble}
                        maxInputLength={490}
                        inverted={false}
                        showUserAvatar
                        alwaysShowSend
                        renderUsernameOnMessage
                        parsePatterns={(linkStyle) => [
                            { type: 'phone', style: { marginRight: -10 } },
                        ]}
                    />
                </View>

                <DocumentDialogModal openDialogPreview={this.state.openDialogPreview}
                    handleClose={() => this.setState({ openDialogPreview: false })}
                    id="ticketChatDocumentView"
                    showLocation={false}
                    selectedDocument={this.state.selectedDocument} // 1 image, 2 video, 3 pdf
                >
                </DocumentDialogModal>

                <DialogModal
                    content={this.state.percentageArr.map(file => {
                        return <div key={file.name} style={{ width: 500 }}>
                            <label><strong>{file.name}</strong></label>
                            <EuiProgressBar value={file.percentage} />
                        </div>
                    })}
                    handleClose={() => this.setState({ openDialogUploadBar: false })}
                    id="uploadBarChatDialog"
                    open={this.state.openDialogUploadBar}
                    buttonsVisible="false"
                    title="Loading Progress..."
                    closeButtonVisible="false"
                >
                </DialogModal>

                <EuiLoading size="large" id="ticketChatLoading" isLoading={this.state.isLoading} />

                <ToastContainer enableMultiContainer containerId={'TC'} autoClose={2500} />

            </EuiRow>);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ticketData: state.ticket });
const mapDispatchToProps = dispatch => {
    return {
        setTickets: (tickets) => dispatch({ type: "SET_TICKETS", payload: tickets }),
        setTicketPageMod: (mod) => dispatch({ type: "SET_TICKET_PAGE_MOD", payload: mod }),
        setCurrentTicket: (ticket) => dispatch({ type: "SET_CURRENT_TICKET", payload: ticket })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TicketChat));