import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


export default class AutoCompleteSelect extends React.Component {

    onChange = (event, newValue) => {
        this.props.onChange && this.props.onChange({ target: { value: newValue, name: this.props.name } });
    }

    render() {

        return (
            <Autocomplete
                id={this.props.id}
                disabled={this.props.disabled}
                disableClearable
                forcePopupIcon={false}
                options={this.props.options}
                getOptionLabel={(option) => { return option.text ?? option.name }}
                getOptionSelected={(option, value) => { return (value.value ?? value.id) === (option.value ?? option.id) }}
                style={this.props.style}
                renderInput={(params) => <TextField {...params}
                    margin="none"
                    style={{ paddingRight: 0 }}
                    label={this.props.label}
                    placeholder={this.props.placeholder}
                    required={this.props.required}
                    variant="standard"
                    defaultValue={this.props.value?.text}
                />}
                onChange={(e, newValue) => this.onChange(e, newValue)}
                onInputChange={(e, value) => this.props.onInputChange && this.props.onInputChange(e, value)}
                value={this.props.value}
                loading={this.props.loading}
            />
        );
    }
}
