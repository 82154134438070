import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import ApiClient from "../../utils/api-client";
import EuiTabs from "../../eui-components/tabs";
import EuiLoading from "../../eui-components/loading";

class AssignChecklist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectedTab: "",
            columns: [
                {
                    title: "ID",
                    key: "id",
                    width: "2%"
                },

                {
                    title: "Name",
                    key: "name",
                    sort: "none",
                    width: "70%"
                },
                {
                    title: "Classification",
                    key: "classificationText",
                    width: "25%"

                }

            ],
            selectedTemplates: []
        }
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        }, () => {

            ApiClient.post("ActivityTemplate/GetAllTemplates", this.props.activityData.selectedActivities)
                .then(response => {
                    this.props.setAllTemplates(response.data);
                    this.setState({
                        isLoading: false
                    })
                }).catch(error => {
                    this.setState({
                        isLoading: false
                    })
                    alert(error);
                })
        })

    }

    changeTab = () => {
        var div = document.querySelector(".title.selected");
        this.setState({
            selectedTab: div?.innerText || ""
        });
        this.props.setSelectedTab(div?.innerText || "");

    }

    setSelectedActivities = (e, type) => {
        const detail = e.detail;
        const data = detail.data;

        if (type === "add") {
            this.setState({ selectedTemplates: [...this.state.selectedTemplates, data] }, () => {
                this.props.setSelectedTemplates(this.state.selectedTemplates);
            });
        } else if (type === "remove") {
            this.setState({ selectedTemplates: this.state.selectedTemplates.filter(item => item.id !== data.id) }, () => {
                this.props.setSelectedTemplates(this.state.selectedTemplates);
            });
        }
    }
    render() {
        const titles = [{ value: 1, name: "Customer Templates", selected: "selected" }, { value: 2, name: "Project Templates", selected: "" }];
        const customerChecklists = [...this.props.activityTemplateData.allTemplates.filter(item => item.templatableType === "Customer")];
        const projectChecklists = [...this.props.activityTemplateData.allTemplates.filter(item => item.templatableType === "Project")];
        const contents = [{
            value: 1, children: <ObseleteEuiTable
                classes="tiny split-striped"
                columns={this.state.columns}
                data={customerChecklists}
                id="customerChecklistsTable"
                paginated={true}
                selectable="multi"
                selectRow={(e, type) => this.setSelectedActivities(e, type)}
                rowsPerPage={10}
                search={true}
                viewActionButton={true}
            />, selected: "selected"
        },
        {
            value: 2, children: <ObseleteEuiTable
                classes="tiny split-striped"
                columns={this.state.columns}
                data={projectChecklists}
                id="projectChecklistsTable"
                search={true}
                viewActionButton={true}
                paginated={true}
                rowsPerPage={10}
                selectable="multi"
                selectRow={(e, type) => this.setSelectedActivities(e, type)}

            />, selected: ""
        }]
        return (
            <div className="w-10" style={{ height: "500px" }}>
                < EuiRow key={this.state.isLoading} className='w-10' >
                    <EuiLoading id="assighTemplateLoading" isLoading={this.state.isLoading} />
                    <EuiRow disabled={this.state.isLoading ? "disabled" : ""}>
                        <EuiTabs
                            titlesColumnStyle={{ marginBottom: "10px" }}
                            contentStyle={{ width: "100%" }}
                            onClick={this.changeTab}
                            id="checklistsTabs"
                            titles={titles}
                            contents={contents}
                            tabStyle={{ width: "100%" }}
                            viewActionButton={true}
                        >
                        </EuiTabs>
                    </EuiRow>
                    <EuiRow>

                    </EuiRow></EuiRow>
            </div>
        );
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, activityTemplateData: state.activityChecklists, activityData: state.activity });
const mapDispatchToProps = dispatch => {
    return {
        setAllTemplates: (activity) => dispatch({ type: "SET_ALL_TEMPLATES", payload: activity }),
        setSelectedTemplates: (temps) => dispatch({ type: "SET_SELECTED_TEMPLATES", payload: temps }),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AssignChecklist);

