import React from "react";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DateUtils from './date-utils';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';
import { BlobServiceClient } from "@azure/storage-blob";
import constants from "./constants";
import ActivityInboxHelper from "./activity-inbox-helper";
import CommonHelper from "./common-helper";
import * as JSZip from "jszip";
import SasHelper from "./sas-helper";

export default class PageHelper {

    static coord = { latitude: "", longitude: "" };

    static renderStatus = (status) => {
        if (status === 1) {
            return <span className="flex-start-content"> <HourglassEmptyIcon style={{ padding: 1 }} />Pending </span>;
        } else if (status === 2) {
            return <span className="flex-start-content"> <i className="icon icon-check color-green m-1" /> Accepted </span>;
        } else if (status === 3) {
            return <span className="flex-start-content"> <i className="icon icon-cross color-red m-1" /> Rejected </span>;
        } else if (status === 4) {
            return <span className="flex-start-content"> <i className="icon icon-reload color-yellow m-1" /> Rework </span>;
        }
        return "";
    }
    static mapComplaintStatus = (type) => {
        switch (type) {
            case "Opened":
                return "grey";
            case "Closed":
                return "green";
            case "Resolved":
                return "#0fc373";
            case "Acknowledged":
                return "orange";
            case "Cancel":
                return "red";
            default:
                return "";
        }
    }

    static mapDocumentTypes = (type) => {
        switch (type) {
            case 1:
                return "Site Survey Report";
            case 2:
                return "Floor Plan";
            case 3:
                return "Low Level Design";
            case 4:
                return "High Level Design";
            case 5:
                return "Method of Procedure";
            case 6:
                return "Integration Report";
            default:
                return "";
        }
    }
    static mapRscStatus = (status) => {
        switch (status) {
            case 1:
                return "Pending";
            case 2:
                return "Accepted";
            case 3:
                return "Rejected";
            case 4:
                return "Rework";
            default:
                return "";
        }
    }

    static checkProperties = (obj) => {
        for (var key in obj) {
            if (obj[key] === null || obj[key] === "")
                return false;
        }
        return true;
    }

    static getMeta = (url) => {
        var img = new Image();
        img.src = url;
        let size = { width: 1, height: 1 };
        img.addEventListener("load", () => {
            size = { width: this.naturalWidth, height: this.naturalHeight };
        });
        return size;
    }

    static mapActivityData = (newActivityList, sessionId) => {
        
        newActivityList[0]["tableRef"] = "TableRef";
        newActivityList.map(item => {
            item.tableRef = React.createRef();
            if (item.subActivities && item.subActivities.length > 0) {
                item.subActivities[0]["viewerIsOpen"] = "ViewerIsOpen";
                item.subActivities[0]["currentImage"] = "CurrentImage";
                if (sessionId > 0) {
                    item.subActivities[0]["tableData"] = {};
                }
                item.subActivities.map(subItem => {
                    subItem.viewerIsOpen = false;
                    subItem.currentImage = 0;

                    if (sessionId > 0 && subItem.sessionAcceptance > 0) {
                        subItem.tableData = { id: subItem.id, checked: true };
                    }
                    if (!subItem.documents || subItem.documents.length == 0) {
                        return;
                    }
                    subItem.documents[0]["src"] = "Src";
                    subItem.documents[0]["width"] = "Width";
                    subItem.documents[0]["height"] = "Height";
                    subItem.documents[0]["title"] = "Title";
                    subItem.documents[0]["subActivityName"] = "SubActivityName";

                    subItem.documents.map(subDoc => {
                        subDoc.documentPath = !subDoc.documentPath ? "" : subDoc.documentPath.replace("https://eritopasp1.blob.core.windows.net/", "https://eritop.azureedge.net/");
                        subDoc.subActivityName = subItem.name;

                        let extension = "";
                        const arr = subDoc.documentPath.split(".");
                        if (arr.length > 0) {
                            extension = arr[arr.length - 1];
                        }
                        const subName = subDoc.subActivityName.length > 30 ? subDoc.subActivityName.substring(0, 30) : subDoc.subActivityName;

                        subDoc.name = subDoc.name ?? (subDoc.id + "_" + subName + "." + extension);
                        subDoc.title = subDoc.title ?? (subDoc.id + "_" + subName + "." + extension);

                        subDoc.src = subDoc.documentPath;
                        subDoc.width = 1;
                        subDoc.height = 1;
                        subDoc.title =
                            <ul key={subDoc.id} style={{ color: "rgba(255, 255, 255, 0.9)", listStyleType: "none" }} >
                                <li key={subDoc.id + "rscEngineerName"}>{"RSC Engineer name: " + subDoc.rscEngineerName ?? ""}</li>
                                <li key={subDoc.id + "siteName"}>{"Site Name: " + subDoc.siteName}</li>
                                <li key={subDoc.id + "latitude"}>{"GPS Lat: " + (subDoc.latitude ?? "") + " GPS Long: " + (subDoc.longitude ?? "")}</li>
                                <li key={subDoc.id + "uploadDate"}>{"Datetime: " + DateUtils.toDateString(subDoc.uploadDate, "YYYY-MM-DD HH:mm A")}</li>
                            </ul>
                    })
                })
            }
        });
    }

    static mapApproveSession = (newSession) => {

        if (newSession.workflowId !== 0 && newSession.workflow) {
            newSession.workflow.customerId = newSession.workflow.customer;
            newSession.workflow.countryId = newSession.workflow.country;
        }

        if (newSession && newSession.activities && newSession.activities.length > 0) {
            this.mapActivities(newSession.activities);
        }

        if (newSession && newSession.sessionActivities && newSession.sessionActivities.length > 0) {
            this.mapSessionActivities(newSession.sessionActivities);
        }
    }

    static mapActivities = (activities) => {
        activities[0]["tableRef"] = "TableRef";
        activities.map(item => {
            item.tableRef = React.createRef();
            if (item.subActivities && item.subActivities.length > 0) {
                item.subActivities[0]["opened"] = "opened";
                item.subActivities[0]["tableRef"] = "TableRef";
                item.subActivities.map(subItem => {
                    subItem.opened = "";
                    subItem.tableRef = React.createRef();
                    if (!subItem.documents || subItem.documents.length == 0) {
                        return;
                    }
                    subItem.documents[0]["title"] = "Title";
                    subItem.documents[0]["subActivityName"] = "SubActivityName";

                    subItem.documents.map(subDoc => {
                        subDoc.documentPath = !subDoc.documentPath ? "" : subDoc.documentPath.replace("https://eritopasp1.blob.core.windows.net/", "https://eritop.azureedge.net/");
                        if (subDoc.type === constants.documentTypes.IMAGE) {
                            subDoc.posterPath = subDoc.documentPath;
                        } else {
                            subDoc.posterPath = ActivityInboxHelper.getPosterPath(subDoc.type) ?? subDoc.documentPath;
                        }
                        subDoc.subActivityName = subItem.name;
                        subDoc.ericssonComments = subDoc.ericssonComments ?? "";

                        let extension = "";
                        const arr = subDoc.documentPath.split(".");
                        if (arr.length > 0) {
                            extension = arr[arr.length - 1];
                        }
                        const subName = subDoc.subActivityName.length > 30 ? subDoc.subActivityName.substring(0, 30) : subDoc.subActivityName;

                        subDoc.name = subDoc.name ?? (subDoc.id + "_" + subName + "." + extension);
                        subDoc.title =
                            <ul key={subDoc.id} style={{ listStyleType: "none", fontSize: 12, marginBottom: 0, color: "#fff", paddingInlineStart: 0, marginTop: 5 }} >
                                <li style={{ paddingBottom: 3 }} key={subDoc.id + "siteName"}>{"Site Code: " + subDoc.siteName}</li>
                                <li style={{ paddingBottom: 3 }} key={subDoc.id + "imageId"}>{"Checklist Name" + " : " + subDoc.subActivityName}</li>
                                <li style={{ paddingBottom: 3 }} key={subDoc.id + "latitude"}>{"Latitude: " + (subDoc.latitude ?? "")}</li>
                                <li style={{ paddingBottom: 3 }} key={subDoc.id + "longitude"}>{"Longitude: " + (subDoc.longitude ?? "")}</li>
                                <li style={{ paddingBottom: 3 }} key={subDoc.id + "uploadDate"}>{"Upload Date: " + DateUtils.toDateString(subDoc.uploadDate, "YYYY-MM-DD HH:mm A")}</li>
                            </ul>

                    })
                })
            }
        });
    }

    static mapSessionActivities = (sessionActivities) => {
        const sasToken =  SasHelper.getSasKey(constants.account);

        sessionActivities[0]["opened"] = "opened";
        sessionActivities[0]["tableRef"] = "tableRef";
        sessionActivities.map(item => {
            item.opened = "";
            item.tableRef = React.createRef();
            if (!item.documents && item.documents.length == 0) {
                return;
            }
            item.documents.map(document => {
                document.documentPath = !document.documentPath ? "" : document.documentPath.replace("https://eritopasp1.blob.core.windows.net/", "https://eritop.azureedge.net/");
                if (document.type === constants.documentTypes.IMAGE) {
                    document.posterPath = document.documentPath;
                } else {
                    document.posterPath = ActivityInboxHelper.getPosterPath(document.type) ?? document.documentPath;
                }
                document.name = document.name ?? document.documentPath.split("/")[document.documentPath.split("/").length - 1];
                document.ericssonComments = document.ericssonComments ?? "";
                document.title =
                    <ul key={document.id} style={{ listStyleType: "none", fontSize: 12, marginBottom: 0, color: "#fff", paddingInlineStart: 0, marginTop: 5 }} >
                        <li style={{ paddingBottom: 3 }} key={document.id + "imageId"}>{"Activity Name: " + item.name}</li>
                        <li style={{ paddingBottom: 3 }} key={document.id + "latitude"}>{"Latitude: " + (document.latitude ?? "")}</li>
                        <li style={{ paddingBottom: 3 }} key={document.id + "longitude"}>{"Longitude: " + (document.longitude ?? "")}</li>
                        <li style={{ paddingBottom: 3 }} key={document.id + "uploadDate"}>{"Upload Date: " + DateUtils.toDateString(document.createDate, "YYYY-MM-DD HH:mm A")}</li>
                    </ul>
            });
        });
    }

    static mapSessionStatus = (status) => {
        var result = this.renderSessionStatus(status);
        return result;
    }

    static renderSessionStatus = (status) => {
        switch (status) {
            case 1:
                return <span style={{ display: "flex" }}><FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#008CFF" }} /> Draft </span>;
            case 2:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#878787" }} /> Created </span>;
            case 3:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#1174E6" }} /> Submitted </span>;
            case 4:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#ED0E00" }} /> Rejected </span>;
            case 5:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#288964" }} /> Approved </span>;
            case 7:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#A08209" }} /> Ready For Submisson </span>;
            case 8:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#288964" }} /> Accepted Without OIL </span>;
            case 9:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#288964" }} /> Accepted with OIL </span>;
            case 10:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#288964" }} /> Accepted Conditionally </span>;
            case 11:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#D46617" }} /> First Approval </span>;
            case 12:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#D46617" }} /> Second Approval </span>;
            case 13:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#D46617" }} /> Third Approval </span>;
            case 14:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#D46617" }} /> Fourth Approval </span>;
            case 15:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#D46617" }} /> Fifth Approval </span>;
            case 100:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#1174E6" }} /> Submitted to ECA </span>;
            case 101:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#ED0E00" }} /> Rejected by ECA </span>;
            case 102:
                return <span style={{ display: "flex" }}> <FiberManualRecordRoundedIcon style={{ padding: 2, marginRight: 2, color: "#288964" }} /> Approved by ECA </span>;
            default:
                return "";

        }
    }

    static getDocumentType = (type) => {
        switch (type) {
            case 1:
                return <CropOriginalIcon style={{ marginRight: 5, paddingTop: 2 }} />;
            case 2:
                return <VideocamOutlinedIcon style={{ marginRight: 5 }} />;
            case 3:
                return <PictureAsPdfOutlinedIcon style={{ marginRight: 5 }} />;
            case 4:
                return <DescriptionOutlinedIcon style={{ marginRight: 5 }} />;
        }
    }

    static renderSeverityLevel = (level) => {
        switch (level) {
            case 1:
                return "OK";
            case 2:
                return "Critical";
            case 3:
                return "Major";
            case 4:
                return "Minor";
            case 5:
                return "Observation";
        }
        return "";
    }

    static mapDocumentStatus = (status) => {
        switch (status) {
            case 1:
                return "Pending";
            case 2:
                return "Accepted";
            case 3:
                return "Rejected";
            case 4:
                return "Rework";
        }
    }

    static renderStatusColor = (status) => {
        switch (status) {
            case 1:
                return grey[500];
            case 2:
                return green[500];
            case 3:
                return red[500];
            case 4:
                return "#DCAF00"
        }
        return grey[500];
    }

    static getType = (extension) => {
        if (!extension) {
            return 4;
        } else if (extension.includes("image")) {
            return 1;
        } else if (extension.includes("video")) {
            return 2;
        } else if (extension.includes("application/pdf")) {
            return 3;
        }
        return 4;
    }

    static checkFilesSize = (files) => {
        if(!files || files.length == 0) {
            return "You didn't select any files to upload!";
        }
        for (let i = 0; i < files.length; i++) {
            const element = files[i];
            const fileSize = element.size / 1024 / 1024; // Mb convert
            
            
            if(fileSize == 0) {
                return "Can't upload 0 Mb size document!";
                break;
            }
            if(fileSize > 200) {
                return "Can't upload over 200MB size document!";
                break;
            }
        }
        return false
    }


    static async urlToFile(_data) {
        const asyncFiles = [..._data];
        return await Promise.all(
            asyncFiles.map(async (e) => {
                const response = await fetch(e.url);
                
                const data = await response.blob();
                const extention = e.url.slice(e.url.lastIndexOf('.') + 1);
                
                const file = {
                    ...e,
                    name: e.name + "." + extention,
                    title: e.title + "." + extention,
                    size: data?.size,
                    type: data?.type
                }
                return file;
            })
        ).then(result => {
            
            return result;
        });
    }

    static async uploadFromUrl(files, onProgress, container) {
        const blobServiceClient = await this.getBlobServiceClient();
        let containerClient = ""
        if (container) {
            containerClient = blobServiceClient.getContainerClient(container);
        }
    }


    static async uploadItems(files, onProgress, container) {

        const blobServiceClient = await this.getBlobServiceClient();
        let containerClient = ""
        if (container) {
            containerClient = blobServiceClient.getContainerClient(container);
        }
        else {
            containerClient = blobServiceClient.getContainerClient(constants.containerName);
        }
 
        const asyncFiles = [...files];

        return await Promise.all(asyncFiles.map(async (file) => {
        
            const blobName = new Date().getTime() + "_" + file.name?.replace(/ /g, "_");
            const blockBlobClient = containerClient.getBlockBlobClient(blobName);
            file.documentPath = blockBlobClient.url.split("?")[0];
            file.blobName = blobName;
            if(onProgress==="audio"){
                await blockBlobClient.uploadBrowserData(file)

            }else{

                const uploadBlobResponse = await blockBlobClient.uploadBrowserData(file
                    , {
                        onProgress: (progressEvent) => onProgress(progressEvent, file),
                        blobHTTPHeaders: {
                            blobContentType: "application/octet-stream" //file.type
                        }
                    });
           
            }
           
        
            
            return file;
        })).then(result => {
            
            return result;
        });
    }

    static async downloadBlobAzure(files, onProgress) {
        let totalLength = 0;
        const asyncFiles = [...files];
        let blobServiceClient = await this.getBlobServiceClient();
        const result = await Promise.all(asyncFiles.map(async (file) => {
            const pathArr = (file.documentPath || file.recordPath || "").split("/");
            if (!pathArr || pathArr.length === 0) {
                return null;
            }

            const fileName = decodeURI(pathArr.pop() || "");
            const containerName = pathArr.pop();
            const accountLink = pathArr.pop();

            if (!containerName || !fileName) {
                return null;
            }

            if (accountLink && !accountLink.includes(constants.cdnAccount) && !accountLink.includes(blobServiceClient.accountName)) {
                const accountName = accountLink.replace(constants.storageLink, "");
                
                blobServiceClient = await this.getBlobServiceClient(accountName);
                
            }


            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blockBlobClient = containerClient.getBlockBlobClient(fileName);

            try {
                const downloadBlobResponse = await blockBlobClient.download(0, null
                    , {
                        onProgress: (progressEvent) => onProgress(progressEvent, file),
                    });
                totalLength = totalLength + downloadBlobResponse.contentLength;
                file.size = downloadBlobResponse.contentLength;
                downloadBlobResponse.copySource = (file.documentPath || file.recordPath);
                downloadBlobResponse.contentAsBlob = await downloadBlobResponse.blobBody;
                return downloadBlobResponse;
            } catch (error) {
                console.error(error);;
                return null;
            }
        }));
        return result;
    }

    static async getPropertiesOfBlobAzure(files) {
        const asyncFiles = [...files];
        let blobServiceClient = await this.getBlobServiceClient();
        
        const result = await Promise.all(asyncFiles.map(async (file) => {
            const pathArr = (file.documentPath || file.recordPath || "").split("/");
            if (!pathArr || pathArr.length === 0) {
                return null;
            }

            const fileName = decodeURI(pathArr.pop() || "");
            const containerName = pathArr.pop();
            const accountLink = pathArr.pop();

            if (!containerName || !fileName) {
                return null;
            }

            if (accountLink && !accountLink.includes(constants.cdnAccount) && !accountLink.includes(blobServiceClient.accountName)) {
                const accountName = accountLink.replace(constants.storageLink, "");
                
                blobServiceClient = await this.getBlobServiceClient(accountName);
                
            }

            const containerClient = blobServiceClient.getContainerClient(containerName);
            const blockBlobClient = containerClient.getBlockBlobClient(fileName);
            try {
                const metaData = await blockBlobClient.getProperties();
                file.size = metaData.contentLength;
                return file;
            } catch (error) {
                console.error(error);;
                return null;
            }
        }));
        return result;
    }

    static async getBlobServiceClient(account = constants.account) {
        const sharedKeyCredential = await SasHelper.getSasKey(account);
        return new BlobServiceClient(
            `https://${account}${constants.storageLink}?${sharedKeyCredential}`
        );
    };

    static async blobExist(blobClient) {
        return await blobClient.exists().then(response => {
            if (response) {
                return true;
            }
            else return false;
        })
    }

    static getCoordinates = () => {
        if (this.coord.latitude !== "") {
            return;
        }

        const getPosition = (position) => {
            this.coord.latitude = position.coords.latitude.toString();
            this.coord.longitude = position.coords.longitude.toString();

            this.coord.latitude = this.coord.latitude && this.coord.latitude.length > 8 ? this.coord.latitude.substr(0, 8) : this.coord.latitude;
            this.coord.longitude = this.coord.longitude && this.coord.longitude.length > 8 ? this.coord.longitude.substr(0, 8) : this.coord.longitude;
        };
        const error = (err) => {
            console.warn(`ERROR(${err.code}): ${err.message}`);
        }

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => getPosition(position), error);
        } else {
            alert('It seems like Geolocation, which is required for this page, is not enabled in your browser. Please use a browser which supports it.');
        }
    }

    static getDocumentTypeColor = (type) => {
        switch (type) {
            case 1:
                return blue[500];
            case 2:
                return red[500];
            case 3:
                return green[500];
            case 4:
                return "#242424";
        }
        return blue[500];
    }

    static delay = ms => new Promise(resolve => setTimeout(resolve, ms));

    static download = async (url, name) => {
        const a = document.createElement('a');
        a.download = name;
        a.target = "_blank";
        a.href = url;
        a.rel = "noopener noreferrer";
        a.style.display = 'none';
        document.body.append(a);
        a.click();

        // Chrome requires the timeout
        await this.delay(100);
        a.remove();
    };

    static downloadFiles = async (selectedFiles, rowData) => {
        const files = [...selectedFiles];
        const sasToken = await SasHelper.getSasKey(constants.account);

        for (const [index, item] of files.entries()) {
            const name = item.name ?? item.documentPath.split("/").pop();
            await this.delay(index * 1000);
            this.download(this.addSasKey(item.documentPath, sasToken), name);
        }
        //multiDownload(files, {});
        if (rowData.tableRef && rowData.tableRef.current) {
            rowData.tableRef.current.onAllSelected(false);
        }
    }

    //rework activity document
    static mapActivityDocuments = (uploadedFiles, rowData, createUser) => {
        return uploadedFiles.map((item, index) => {
            let extension = "";
            let name = rowData.name;
            const arr = rowData.name.split(".");
            if (arr.length > 0) {
                extension = arr[arr.length - 1];
                name = arr[0] + "_" + (index + 1) + "." + extension;
            }

            return {
                name: item.name,
                ParentEvidenceId: rowData.id,
                acceptanceStatusId: 2,
                sessionAcceptanceStatus: 4,
                activityId: rowData.activityId,
                subActivityId: rowData.subActivityId,
                latitude: this.coord.latitude,
                longitude: this.coord.longitude,
                uploadDate: new Date(),
                type: rowData.type,
                documentPath: item.documentPath,
                sessionDeleteDate: null,
                sessionDeleteUser: null,
                customerComments: "",
                ericssonComments: "",
                sessionRejectedDate: null,
                sessionRejectedUser: null,
                sessionAcceptedDate: null,
                sessionAcceptedUser: null,
                severityLevel: 0
            };
        });
    }

    //rework session document
    static mapSessionDocuments = (uploadedFiles, rowData, createUser) => {
        return uploadedFiles.map((item, index) => {
            let extension = "";
            let name = rowData.name;
            const arr = rowData.name.split(".");
            if (arr.length > 0) {
                extension = arr[arr.length - 1];
                name = arr[0] + "_" + (index + 1) + "." + extension;
            }

            return {
                name: item.name,
                acceptanceStatusId: 4,
                ParentEvidenceId: rowData.id,
                sessionActivityId: rowData.sessionActivityId,
                latitude: this.coord.latitude,
                longitude: this.coord.longitude,
                documentPath: item.documentPath,
                customerComments: "",
                ericssonComments: "",
                createDate: new Date(),
                createUser,
                type: rowData.type,
                deleteDate: null,
                deleteUser: null
            };
        });
    }

    static uploadNewImg = (rowData, forwardedRef, index) => {
        const editorInstance = forwardedRef.current.imageEditorInst;
        const currentSub = rowData[index]; //turn into index
        const base64File = editorInstance.toDataURL();
        const buffer = atob(base64File.split(',')[1]);
        const nameOfSub = currentSub.name.toString();
        let nameOfImage = "";
        if (nameOfSub.includes("Modified")) {
            nameOfImage = nameOfSub;
        }
        else {
            nameOfImage = '(Modified) ' + nameOfSub;
        }
        const arrayBuffer = new ArrayBuffer(buffer.length);
        const unit8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < buffer.length; i += 1) {
            unit8Array[i] = buffer.charCodeAt(i);
        }
        //unit8Array.map((element, i) => unit8Array[i] = buffer.charCodeAt(i))
        const fileImage = new Blob([arrayBuffer], {
            type: 'image/jpeg',

        });
        
        const files = [fileImage];
        let percentageArr = [...files];
        percentageArr["name"] = nameOfImage;
        percentageArr.map(item => {
            item.name = nameOfImage
        }); //are we going to assign each item to the same name of an image?
        const array = this.initializePercentageArr(files);
        const object = { Array: array, Files: files }
        return object;
    }

    static initializePercentageArr = (files) => {
        let percentageArr = [...files];
        percentageArr["percentage"] = 0;
        return percentageArr.map(item => {
            item.percentage = 0
            return item;
        });
    }

    static getSeverityIcon = (severityLevel) => {
        switch (severityLevel) {
            case "OK":
                return <i className="icon icon-severity-cleared" style={{ fontSize: 21, paddingLeft: 2, color: "#288964" }}></i>
            case "Critical":
                return <i className="icon icon-severity-critical" style={{ fontSize: 21, paddingLeft: 2, color: "#ED0E00" }}></i>
            case "Major":
                return <i className="icon icon-severity-major" style={{ fontSize: 21, paddingLeft: 2, color: "#D46617" }}></i>
            case "Minor":
                return <i className="icon icon-severity-minor" style={{ fontSize: 21, paddingLeft: 2, color: "#1174E6" }}></i>
            case "Observation":
                return <i className="icon icon-severity-indeterminate" style={{ fontSize: 21, paddingLeft: 2, color: "#A08209" }}></i>
            default:
                return ""
        }
    }

    static getDetailPanelTitle = (name, opened) => {
        return <div style={{ display: "flex", alignItems: "center" }}>
            <i className={"icon icon-" + (opened ? "chevron-down" : "chevron-right")} style={{ fontSize: 18 }}></i> {name} </div>;
    }

    static renderRow = (selected) => {
        return {
            background: selected ? '#1174E6' : 'inherit',
            color: selected ? "white" : "inherit",
        };
    }

    static renderSecondaryRow = (selected) => {
        return {
            background: selected ? 'lightgray' : 'inherit',
        };
    }

    static renderDocumentType = (type, documents) => {
        switch (type) {
            case 1:
                return <span style={{ display: "inline-flex", alignItems: "center" }} ><CropOriginalIcon
                    style={{ fontSize: 16, marginRight: 5 }} /> {"Images (" + documents.filter(item => item.type == 1).length + ") "}
                </span>
            case 2:
                return <span style={{ display: "inline-flex", alignItems: "center" }}> <VideocamOutlinedIcon style={{ fontSize: 16, marginRight: 5 }} /> {"Videos (" + documents.filter(item => item.type == 2).length + ") "}
                </span>
            case 3:
                return <span style={{ display: "inline-flex", alignItems: "center" }}> <PictureAsPdfOutlinedIcon style={{ fontSize: 16, marginRight: 5 }} /> {"PDF (" + documents.filter(item => item.type == 3).length + ") "}
                </span>
            default:
                return <span style={{ display: "inline-flex", alignItems: "center" }}><DescriptionOutlinedIcon style={{ fontSize: 16 }} /> {"Other Documents (" + documents.filter(item => item.type == 4).length + ") "}
                </span>
        }
    }

    static renderManualType = (type) => {
        switch (type) {
            case 1:
                return (`<span id = "${type}" style={padding:10px, display: inline-flex, alignItems: center} ><i class = "icon icon-image color-black" style= {font-size: 16px, margin-right: 5 }/></span>`)
            case 2:
                return (`<span id = "${type}" style={padding:10px, display: inline-flex, alignItems: center} ><i class = "icon icon-video-play color-black" style= {font-size: 16px, margin-right: 5 }/></span>`)
            case 3:
                return (`<span id = "${type}" style={padding:10px, display: inline-flex, alignItems: center} ><i class = "icon icon-file color-black" style= {font-size: 16px, margin-right: 5 }/></span>`)
            default:
                return (`<span id = "${type}" style={padding:10px, display: inline-flex, alignItems: center} ><i class = "icon icon-document color-black" style= {font-size: 16px, margin-right: 5 }/></span>`)
        }
    }

    static mapRecordings = (recordings) => {
        if (!recordings || recordings.length === 0) {
            return [];
        }
        return recordings.map(item => {
            const dur = item.duration && item.duration > 0 ? item.duration / 60 : 0;
            item.duration = Math.round((dur + Number.EPSILON) * 100) / 100;
            return item;
        });
    }

    static renderActivityStatusColor = (status) => {
        switch (status) {
            case 1:
                return "gray";
            case 2:
                return "green";
            case 3:
                return "red";
            case 4:
                return "yellow";
            default:
                return "gray";
        }
    }

    static getSelectedDocument = (document) => {
        const selectedDocument = { ...document };
        selectedDocument.documentPath = selectedDocument.documentPath || selectedDocument.recordPath;
        selectedDocument.type = selectedDocument.type || 2;
        selectedDocument.uploadDate = selectedDocument.uploadDate || selectedDocument.createDate || selectedDocument.endDatetime;
        return selectedDocument;
    }

    static mapUsers = (users) => {
        return users.map(item => {
            const user = { ...item };
            user.text = item.text + " - " + item.mail;
            return user;
        });
    }

    static renderVerification = (isActive) => {
        if (isActive === 1) {
            return `<div class="flex-start-content"><i class="icon icon-severity-cleared color-green m-1"></i>Verified</div>`;
        } else if (isActive === 2) {
            return `<div class="flex-start-content"><i class="icon icon-triangle-warning color-red m-1"></i>Expired</div>`
        } else {
            return `<div class="flex-start-content"><i class="icon icon-severity-cleared color-gray m-1"></i>Not Available</div>`;
        }
    }

    static renderTeamVerification = (isActive) => {
        if (!isActive || isActive === 0) {
            return `<div class="flex-start-content"><i class="icon icon-triangle-warning color-red m-1"></i>Not Verified</div>`
        } else {
            return `<div class="flex-start-content"><i class="icon icon-severity-cleared color-green m-1"></i>Verified</div>`;
        }
    }

    static renderTeamActivation = (isActive) => {
        if (!isActive || isActive === 0) {
            return `<div class="flex-start-content"><i class="icon icon-triangle-warning color-red m-1"></i>Not Active</div>`
        } else {
            return `<div class="flex-start-content"><i class="icon icon-severity-cleared color-green m-1"></i>Active</div>`;
        }
    }

    static renderCertificateIcon = (isEricsson) => {
        return isEricsson ? `<i class="icon icon-econ m-1"></i>`
            : `<i class="icon icon-medal m-1"></i>`
    }

    static getVerificationIcon = (status, className) => {
        if (status === null) {
            return <i className={"icon icon-severity-cleared color-gray m-1 icon-normal-weight " + (className ?? "")} ></i>
        } else if (status === true) {
            return <i className={"icon icon-severity-cleared color-green m-1 icon-normal-weight " + (className ?? "")}></i>;
        } else if (status === false) {
            return <i className={"icon icon-triangle-warning color-red m-1 icon-normal-weight " + (className ?? "")}></i>;
        }
    }

    static getVerificationLabel = (status) => {
        if (status === null) {
            return "Not Available"
        } else if (status === true) {
            return "Verified";
        } else if (status === false) {
            return "Not Verified"
        }
    }

    static getVerificationColor = (status) => {
        if (status === null) {
            return "color-gray"
        } else if (status === 'true'|| status === true) {
            return "color-green";
        } else if (status === 'false'|| status === false) {
            return "color-red"
        }
    }

    static renderMandatory = (isMandatory) => {
        return isMandatory ? `<div class="flex-start-content"><i class="icon icon-warning-circle m-1 color-red"></i>Mandatory</div>`
            : `<div class="flex-start-content"><i class="icon icon-eye m-1 text-lg"></i>Conditional</div>`
    }

    static renderVerificationSite = (isOffline) => {
        if (isOffline === true) {
            return <div className="flex-start-content ml-2 w-2"><i className="icon icon-severity-cleared color-red m-1"></i>Off Site Verification</div>;
        } else if (isOffline === false) {
            return <div className="flex-start-content ml-2  w-2"><i className="icon icon-severity-cleared color-green m-1"></i>On Site Verification</div>
        } else {
            return "---";
        }
    }

    static renderPhotoAuthentication = (status) => {
        if (status === true) {
            return <div className="flex-start-content"><i className="icon icon-check color-green icon-lg ml-1 mr-1"></i>Passed</div>;
        } else if (status === false) {
            return <div className="flex-start-content"><i className="icon icon-cross color-red icon-lg ml-1 mr-1"></i>Failed</div>;
        } else {
            return <div className="flex-start-content"><i className="icon icon-alarm-level6 color-gray ml-1 mr-1"></i>Disabled</div>;;
        }
    }

    static getUserStatus = (status) => {
        if (status === false) {
            return <div className="flex-start-content"><i className="icon icon-severity-cleared color-green ml-1 mr-1"></i>Active</div>;
        } else if (status === true) {
            return <div className="flex-start-content"><i className="icon icon-severity-cleared color-red ml-1 mr-1"></i>Passive</div>;
        } else {
            return <div className="flex-start-content"><i className="icon icon-severity-cleared color-gray ml-1 mr-1"></i>Not Available</div>;
        }
    }

    static renderActivityStatus = (status) => {
        switch (status) {
            case 1:
                return `<div class="flex-start-content"><i class="icon icon-severity-cleared color-gray m-1 text-sm"></i>Pending</div>`;
            case 2:
                return `<div class="flex-start-content"><i class="icon icon-severity-cleared color-green m-1 text-sm"></i>Accepted</div>`;
            case 3:
                return `<div class="flex-start-content"><i class="icon icon-severity-cleared color-red m-1 text-sm"></i>Rejected</div>`;
            default:
                return null;
        }
    }

    static renderActivityStatusAsHtml = (status) => {
        switch (status) {
            case 1:
                return <div className="flex-start-content"><i className="icon icon-severity-cleared color-gray m-1"></i>Pending</div>;
            case 2:
                return <div className="flex-start-content"><i className="icon icon-severity-cleared color-green m-1"></i>Accepted</div>;
            case 3:
                return <div className="flex-start-content"><i className="icon icon-severity-cleared color-red m-1"></i>Rejected</div>;
            default:
                return null;
        }
    }

    static getActivityStatus = (status) => {
        switch (status) {
            case 1:
                return <div className="flex-start-content color-white" style={{ fontSize: 14 }}><i className="icon icon-severity-cleared color-gray icon-lg m-1 ml-0 mr-4"></i>Pending</div>;
            case 2:
                return <div className="flex-start-content  color-white" style={{ fontSize: 14 }}><i className="icon icon-severity-cleared color-green icon-lg m-1 ml-0 mr-4"></i>Accepted</div>;
            case 3:
                return <div className="flex-start-content  color-white" style={{ fontSize: 14 }}><i className="icon icon-severity-cleared color-red icon-lg m-1 ml-0 mr-4"></i>Rejected</div>;
            default:
                return null;
        }
    }

    static getEDSDocumentType = (type) => {
        switch (type) {
            case 1:
                return `<i class="icon icon-image m-1"></i>`;
            case 2:
                return `<i class="icon icon-camera-classic m-1"></i>`;
            case 3:
                return `<i class="icon icon-document m-1"></i>`;
            case 4:
                return `<i class="icon icon-file m-1"></i>`;
        }
    }

    static getEDSDocumentTypeColor = (type) => {
        switch (type) {
            case 1:
                return "color-blue"
            case 2:
                return "color-red"
            case 3:
                return "color-green"
            case 4:
                return "color-black"
        }
        return "color-blue";
    }

    static getYesNo = (status) => {
        if (status === 0 || status === false) {
            return "Fail";
        } else if (status === 1 || status === true) {
            return "Success";
        } else {
            return "---";
        }
    }

    static dynamicSort = (property) => {
        var sortOrder = 1;

        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }

        return function (a, b) {
            if (sortOrder == -1) {
                return b[property]?.trim().localeCompare(a[property]);
            } else {
                return a[property]?.trim().localeCompare(b[property]);
            }
        }

    }

    static renderCrtStatus = (status) => {
        switch (status) {
            case "Valid":
                return `<div class="flex-start-content"><i class="icon icon-alarm-level3 color-green m-1 "></i>Valid</div>`;
            case "Expired":
                return `<div class="flex-start-content"><i class="icon icon-alarm-level3 color-red m-1 "></i>Expired</div>`;
            default:
                return null;
        }
    }

    static filterCustomerFiles = (filter, documents, type) => {
        const acceptanceStatus = [];
        acceptanceStatus.push(!filter.accepted ? constants.acceptanceStatusTypes.ACCEPTED : 0);
        acceptanceStatus.push(!filter.rejected ? constants.acceptanceStatusTypes.REJECTED : 0);
        acceptanceStatus.push(!filter.pending ? constants.acceptanceStatusTypes.PENDING : 0);
        const types = [];
        types.push(!filter.images ? constants.documentTypes.IMAGE : 0);
        types.push(!filter.videos ? constants.documentTypes.VIDEO : 0);
        types.push(!filter.pdfs ? constants.documentTypes.PDF : 0);
        types.push(!filter.otherDocuments ? constants.documentTypes.OTHER_DOCUMENTS : 0);

        const files = documents.filter(item => item
            && !acceptanceStatus.includes(type === 2 ? item.acceptanceStatusId : item.sessionAcceptanceStatus)
            && !types.includes(item.type));
        
        
        return files;
    }

    static getFilesSizeByType = (files) => {
        return files.reduce((a, b) => {
            if (b.type === constants.documentTypes.IMAGE) {
                a.images = a.images + b.size;
            } else if (b.type === constants.documentTypes.VIDEO) {
                a.videos = a.videos + b.size;
            } else if (b.type === constants.documentTypes.PDF) {
                a.pdfs = a.pdfs + b.size;
            } else if (b.type === constants.documentTypes.OTHER_DOCUMENTS) {
                a.otherDocuments = a.otherDocuments + b.size;
            } else {
                a.recordings = a.recordings + b.size;
            }
            a.total = a.total + b.size;
            return a;
        }, { total: 0, images: 0, videos: 0, pdfs: 0, otherDocuments: 0, recordings: 0 });
    }

    static getRecordingsOfActivities = (activities) => {
        const recordings = activities && activities.length > 0 ? activities.map(item => item.recordings ?? []).reduce((a, b) => a.concat(b)) : [];
        return this.mapRecordings(recordings);
    }

    static getZipArr = (pathArr) => {
        const zip = new JSZip;
        let chunk = 0;
        let totalSize = 0;
        const zipArr = pathArr.reduce((a, b, index) => {
            a[chunk].file(b.path, b.contentAsBlob);
            totalSize = totalSize + b.contentAsBlob.size;
            if (CommonHelper.bytesToSizeMB(totalSize) > constants.zipFileLimitSize || index === (pathArr.length - 1)) {
                const newZip = new JSZip;
                a.push(newZip);
                totalSize = 0;
                chunk = chunk + 1;
            }
            return a;
        }, [zip]);
        return zipArr;
    }

    static getEricssonUsers = (users) => {
        if (!users || users.length === 0) {
            return [];
        }

        return users.filter((user) => constants.rscRoles.includes(user.role + ""));
    }

    static getSiteSupervisors = (users) => {
        if (!users || users.length === 0) {
            return [];
        }

        return users.filter(user => (user.role + "") === constants.userRoles.RSC_SITE_SUPERVISOR);
    }

    static getVIColor = (viResult) => {

        if (viResult === "Approved") {
            return "green";
        } else if (viResult === "Rejected") {
            return "red";
        } else {
            return "gray";
        }
    }

}
