import { initializeApp } from 'firebase/app';
import firebaseClient from '../../utils/firebase-config';
import { getMessaging, getToken, onMessage ,onNotification} from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = firebaseClient.firebaseConfig;
initializeApp(firebaseConfig);
const messaging = getMessaging();

export const requestForToken = () => {
    return getToken(messaging, { vapidKey: firebaseClient.vapIdKey })
        .then((currentToken) => {
            if (currentToken) {
                return currentToken;
                // update token in DB
            } else {
                // Show permission request UI
                
            }
        })
        .catch((err) => {
            
        });
};
export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            ///console.log("payload", payload)

            resolve(payload);
        });

       
    });
