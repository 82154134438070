import React from 'react';
import { connect } from 'react-redux';
import EuiRow from "../../eui-components/row";
import FormField from "../../eui-components/formfield";
import EuiTextField from "../../eui-components/textfield";

const ErisiteInfo = (props) => {
    return (<EuiRow style={{ paddingLeft: "20px", paddingTop: "30px" }}>
        <FormField className="w-10">
            <EuiTextField
                className="p-1 w-5"
                fullwidth
                readOnly={true}
                style={{ padding: 0, width: "350px", fontSize: "16px" }}
                labelstyle={{ color: "gray" }}
                id="assignmentId"
                name="assignmentId"
                label="Assignment ID"
                value={props.Project.project.assignmentId || ""} />
            <EuiTextField
                className="p-1 w-5"
                fullwidth
                readOnly={true}
                style={{ padding: 0, width: "350px", fontSize: "16px" }}
                labelstyle={{ color: "gray" }}
                id="systemrecordid"
                name="systemRecordid"
                label="System Record ID"
                value={props.Project.project.systemrecordid || ""} />
            <EuiTextField
                className="p-1 w-5"
                fullwidth
                readOnly={true}
                style={{ padding: 0, width: "350px", fontSize: "16px" }}
                labelstyle={{ color: "gray" }}
                id="Erisite Project ID"
                name="ErisiteprojectId"
                label="Erisite Project ID"
                value={props.Project.project.eriProjectId || ""} />
        </FormField>
    </EuiRow>)
}
const mapStateToProps = state => ({ Project: state.projects });
const mapDispatchToProps = dispatch => {
    return {}
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErisiteInfo);