import React from 'react';
import { connect } from 'react-redux';
import EuiRow from "../../eui-components/row";
import { Multiselect } from 'multiselect-react-dropdown';
import EuiSelect from "../../eui-components/select";
import EuiTextField from "../../eui-components/textfield";
import EuiDatepicker from "../../eui-components/datepicker";
import EuiTile from '../../eui-components/tile';
import EuiSwitch from "../../eui-components/switch";





class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: [],
            SelectedControllType: "",
            selectedControll: "",
            QueryList: [],
            QueryItemValue: "",
            OperatorValue :"",
            boolValues: [{ value: -1, Text: "Select value" }],
            boolValues: [{ value: 1, Text: "True" }, { value: 0, Text: "False" }],
            Operators: [
                { text: "Equal", value: "=" },
                { text: "Greater Than", value: ">" },
                { text: "Less Than", value: "<" },
                { text: "Not Equal", value: "<>" },
                { text: "IN", value: "IN" }
            ]



        }
        this.onHandleInt = this.onHandleInt.bind(this)
    }

    componentDidMount() {
        const QueryArea = document.querySelector('#QueryArea');
        QueryArea.innerHTML = this.props.filterSavedQuery;


        this.setState({
            selectedValue: this.props.QueryData
        })
        
        
    }
 

    onSelect = (selectedList, selectedItem) => {
        this.state.selectedValue.push(selectedList)
        this.props.criteria(this.state.selectedValue);

    }

    onHandleInt = e => {
        const re = /^[0-9,]+$/;

        let number = e.target.value;

        // if value is not blank, then test the regex
        if (number === '' || re.test(number)) {
            this.setState({ QueryItemValue: number })
        }
        else {
            e.target.value = '';
        }

    };
    onHandlebool = e => {

        const target = e.target.checked;


        // if value is not blank, then test the regex
        if (target) {
            this.setState({ QueryItemValue: 1 })
        }
        else {
            this.setState({ QueryItemValue: 0 })
        }

    };

    handlecol = (event) => {
        this.setState({
            SelectedControllType: event.target.value,
            selectedControll: event.target.text
        })



    }

    handelOperator = (event) => {
        this.setState({
            OperatorValue: event.target.value,
        })
    }

    renderFilter = () => {


        let value = this.state.SelectedControllType
        let name = this.state.selectedControll

        switch (value) {
            case 'string':
                return <EuiTextField
                    className="m-1 w-10"
                    value={this.state.QueryItemValue}
                    fullwidth
                    id={name}
                    name={name}
                    label={name}
                    labelClass="color-gray"
                    onChange={(e) => {
                        this.setState({ QueryItemValue: e.target.value })
                    }}

                />
            case 'DateTime':
                return <EuiDatepicker className="m-1 w-10" fullwidth id="DateTimeControll" name={name} label={name} labelClassName="color-gray"
                     value={this.state.QueryItemValue} onChange={(e) => { this.setState({ QueryItemValue: e.target.value }) }}/>


            case 'int':
                return <EuiTextField
                    value={this.state.QueryItemValue}
                    fullwidth
                    id={name}
                    name={name}
                    label={name}
                    labelClassName="color-gray"
                    onChange={this.onHandleInt}

                />
            case 'bool':
                return <EuiSwitch id="boolValue"
                    name={name}
                    checked={this.state.QueryItemValue}
                    onChange={(e) => this.onHandlebool(e)}
                    dataEnabled="True"
                    dataDisabled="False"
                    className="p-1 w-4" />
        }

    }

    addQuery = () => {


        let queryValue = this.state.QueryItemValue;

        if (this.state.SelectedControllType === 'bool' && queryValue === '')
        {
            queryValue = 0;
        }
        let index = this.state.selectedValue.findIndex(index => index.name == this.state.selectedControll);
        if (index == -1) {

            this.state.selectedValue.push({ name: this.state.selectedControll, value: queryValue, datatype: this.state.SelectedControllType, operator: this.state.OperatorValue })
        }
        else {
            if (this.state.SelectedControllType === 'DateTime') {
                let Operatorindex = this.state.selectedValue.findIndex(index => index.name == this.state.selectedControll && index.operator === this.state.OperatorValue);
                if (Operatorindex == -1) {
                    this.state.selectedValue.push({ name: this.state.selectedControll, value: queryValue, datatype: this.state.SelectedControllType, operator: this.state.OperatorValue })

                }
                else {
                    this.state.selectedValue.splice(Operatorindex, 1);
                    this.state.selectedValue.push({ name: this.state.selectedControll, value: queryValue, datatype: this.state.SelectedControllType, operator: this.state.OperatorValue })
                }
            
            }
            else {

                this.state.selectedValue.splice(index, 1);
                this.state.selectedValue.push({ name: this.state.selectedControll, value: queryValue, datatype: this.state.SelectedControllType, operator: this.state.OperatorValue })
            }
        }

        let fields = [];
        let myObjStr = this.state.selectedValue.forEach(item => {
            if (item.operator === 'IN') {
                fields.push(item.name + " " + item.operator + " (" + item.value + ")")

            }
            else {
                fields.push(item.name + " " + item.operator + " " + item.value)
            }
        });


        let Query = JSON.stringify(fields).replace(/"/g, "");;
        const QueryArea = document.querySelector('#QueryArea');
        QueryArea.innerHTML = Query;

        this.props.OnSaveQuery(this.state.selectedValue);
        this.setState({
            QueryItemValue: ""

        })


    }



    render() {
        return (

            <EuiRow style={{ width: "400px", height: "350px" }}>
                <div className="column sm-12 md-6 lg-6 xl-6 filled" >

                <div style={{ height: "20px", fontSize: "13px" }}>*Double click or Enter for selecting</div>
            <EuiSelect id="filterfields" name="FilterFields" label="---Select Filter Field---"
                options={this.props.options}
                //value={this.state.selectReportId}
                onChange={e => this.handlecol(e)}
                optionListStyle={{ width: "100%" }} />

                    <EuiSelect id="operators" name="Operators" label="---Select Operator---"
                        options={this.state.Operators}
                        //value={this.state.selectReportId}
                        onChange={e => this.handelOperator(e)}
                        optionListStyle={{ width: "100%" }} />
                    <br></br>
                    {this.renderFilter()}
                    <br></br>
                    <button className="btn primary" id="wizard-previous" onClick={this.addQuery} >Add Filter</button>
                    <br></br>
                    <br></br>
                    <div>
                        <textarea id="QueryArea" placeholder="Query"  style={{ width: "250px", height: "100px" }} ></textarea>
                    </div>
                </div>
            </EuiRow>
        );
    }
}


const mapStateToProps = state => ({

})
const mapDispatchToProps = dispatch => {
    return {

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Filter)
