const initialState = {
    mod: 1,
    filter: {},
    filteredActivities: { data: [], totalCount: 0, initialState: 0 },
    aspPmActivities: [],
    activity: { id: 0, subactivities: [] },
    selectedActivies: [],
    aspCompanies: [],
    teams: [],
    insideWorkplan: false,
    isLoading: false,
    selectedChecklistItem: {},
    aspPmTeamLeaders: [],
    assignedLeader: {},
    aspPmFilterTypes: ["2"],
    usersAllowedDropCamera:{}

};

export default (state = initialState, action) => {
    switch (action.type) {
        
        case "SET_USERS_ALLOWED_DROP_CAMERA":
            return {
                ...state,
                usersAllowedDropCamera: action.payload
            };
        case "SET_ASP_PM_FILTER_TYPES":
            return {
                ...state,
                aspPmFilterTypes: action.payload
            };

        case "SET_ACTIVITY":
            return {
                ...state,
                activity: action.payload
            };
        case "SET_ASP_PM_TEAM_LEADERS":
            return {
                ...state,
                aspPmTeamLeaders: action.payload
            }
        case "SET_ASSIGNED_LEADER":
            return {
                ...state,
                assignedLeader: action.payload
            }
        case "CHANGE_ACTIVITY_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_FILTERED_ACTIVITIES":
            return {
                ...state,
                filteredActivities: action.payload
            };
        case "SET_ASP_PM_ACTIVITIES":
            return {
                ...state,
                aspPmActivities: action.payload
            };
        case "SET_ACTIVITY_FILTER":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_ASP_COMPANIES":
            return {
                ...state,
                aspCompanies: action.payload
            };
        case "SET_TEAMS":
            return {
                ...state,
                teams: action.payload
            }
        case "SET_INSIDE_WORKPLAN":
            return {
                ...state,
                insideWorkplan: action.payload
            }
        case "SET_STATE_LOADING":
            return {
                ...state,
                isLoading: action.payload
            }
        case "SET_SELECTED_ACTIVITIES":
            return {
                ...state,
                selectedActivities: action.payload
            }
        case "SET_SELECTED_CHECKLIST_ITEM":
            return {
                ...state,
                selectedChecklistItem: action.payload
            }
        default:
            return state;
    }

};
