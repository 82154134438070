import React from "react";
import EuiRow from "../../../eui-components/row";
import EuiTabs from "../../../eui-components/tabs";
import EhsInspectionData from "./ehsInspectionData";
import QAInspectionData from "./qaInspectionData";

const RscDashboardPanel = () => {
    const tabContents =
        [{
            value: 1, children: <EhsInspectionData />, selected: "selected", id: "rscEhsInspectorTab"
        },
        { value: 2, children: <QAInspectionData />, selected: "", id: "rscQaInspectorTab" },];
    const tabTitles =
        [{ value: 1, name: "EHS Inspectors", selected: "selected" },
        { value: 2, name: "QA Inspectors", selected: "" }]

    return (
        <EuiRow>
            <EuiTabs
                id="RscDashboardTabs"
                titlesColumnStyle={{ marginBottom: "10px", marginLeft: "10px" }}
                contentStyle={{ width: "100%" }}
                titles={tabTitles}
                contents={tabContents}
                tabStyle={{ width: "100%" }}
            />
        </EuiRow>
    )
}
export default RscDashboardPanel;