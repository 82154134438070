const initialState = {
    mod: 1,
    filter: {},
    filteredTeams: { data: [], totalCount: 0, initialState: 0 },
    team: { id: 0, name: '', customerId: null, comment: '', initialState: 0 },
    certificates: [],
    certificate: { id: 0, minCertificateNumber: 1 , isActive: true},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_TEAM":
            return {
                ...state,
                team: action.payload
            };
        case "SET_ALL_CERTIFICATES":
            return {
                ...state,
                certificates: action.payload
            };
        case "SET_CERTIFICATE":
            return {
                ...state,
                certificate: action.payload
            };
        case "CHANGE_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_FILTERED_TEAMS":
            return {
                ...state,
                filteredTeams: action.payload
            };
        case "SET_FILTER":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_DOCUMENTS":
            return {
                ...state,
                documents: action.payload
            }
        case "SET_LOCAL_FILES":
            return {
                ...state,
                localFiles: action.payload
            }
        case "SET_UPDATE_ELEMENT": {
            return {
                ...state,
                updateElement: action.payload
            }
        }
        default:
            return state;
    }

};
