import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import EhsPingSearch from "./ehsPingSearch";
import constants from "../../utils/constants";
import EhsPingDetails from "./ehsPingDetails";


class EhsPings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
    }

    renderMod = () => {
        if (this.props.ehsPingData.mod === constants.pageMods.VIEW_ONLY) {
            return <EhsPingDetails />
        } else {
            return <EhsPingSearch />;
        }
    }

    render() {
        return (this.renderMod());
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ehsPingData: state.ehsPing });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(EhsPings)));