import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiTile from "../../eui-components/tile";
import EuiRow from "../../eui-components/row";
import UserProfile from "./userProfile";
import UserRoles from "./userRoles";
import UserResetPassword from "./userResetPassword";
import constants from "../../utils/constants";

const UserContent = (props) => {

    const [isLoading, changeIsLoading] = useState(false);

    const currentUser = useSelector(state => state.userAdmin.currentUser);
    const jobRole = useSelector(state => state.user.userData?.jobRole);
    const dispatch = useDispatch();

    const hideRoles = () => {
        return props.isMyProfile &&
            (jobRole === constants.userRoles.CUSTOMER ||
            jobRole === constants.userRoles.ASP_TEAM_LEADER ||
            jobRole === constants.userRoles.TEAM_MEMBER);
    }

    return (
        <EuiTile tileClassName={isLoading ? "disabled" : ""} tileStyle={{ padding: 0, overflow: "hidden" }} key={currentUser}>
            <EuiRow className="flex-baseline">
                <div className={"column sm-12 md-6 " + (hideRoles() ? " lg-6 xl-6 " : " lg-4 xl-4 ") + (props.roleDetailMod ? " overlay-hidden " : "")} style={{ paddingLeft: 30 }} >
                    <UserProfile isMyProfile={props.isMyProfile}/>
                </div>
                <div className={"column sm-12 md-6  container-border-left container-border-right " + (hideRoles() ? " lg-6 xl-6 " : " lg-4 xl-4 ") + (props.roleDetailMod ? " overlay-hidden " : "")}
                     style={{ paddingTop: 12 }}>
                    <UserResetPassword isMyProfile={props.isMyProfile} />
                </div>
                {hideRoles() ? null :
                    <div className={(props.roleDetailMod ? "w-10 column sm-12 md-12 lg-12 xl-12" : "w-3 column sm-12 md-6 lg-4 xl-4")} style={{ paddingRight: (props.roleDetailMod ? null : 30), paddingLeft: 5 }}>
                        <UserRoles setRoleDetailMod={props.setRoleDetailMod} isMyProfile={props.isMyProfile} />
                    </div>
                }
            </EuiRow>
        </EuiTile>
    );

}

export default UserContent;