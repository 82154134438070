const initialState = {
    allRscUsers: [],
    assignedSessions: [],
    allRscInspectors: [],
    selectedInspector: {}
};

export default (state = initialState, action) => {
    switch (action.type) {

        case "SET_ALL_RSC_USERS":
            return {
                ...state,
                allRscUsers: action.payload
            };
        case "SET_ASSIGNED_SESSIONS":
            return {
                ...state,
                assignedSessions: action.payload
            }
        case "SET_ALL_RSC_INSPECTORS":
            return {
                ...state,
                allRscInspectors: action.payload
            }
        case "SET_SELECTED_INSPECTOR":
            return {
                ...state,
                selectedInspector: action.payload
            }
        default:
            return state;
    }

};
