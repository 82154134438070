import React from 'react';
import EuiGuage from "../eui-components/guage";
import EuiKpi from "../eui-components/kpi";
import EuiRow from '../eui-components/row';
class KpiCard extends React.Component {

    render() {
        return (
            <div className={this.props.className ?? "tile sm-12 lg-6 xl-4"} id={this.props.id + "_"} zoom={this.props.zoom} >
                <EuiRow className={this.props.disable ? "disabled" : ""}>
                    <div>
                        <div  >
                            <label id={this.props.id + "_" + "area"} className="title" style={{ fontSize: "18px", width: "200px" }}>{this.props.kpiArea}</label>
                        </div>
                        <div >
                            <label id={this.props.id + "_" + "name"} className="title" style={{ fontSize: "16px", marginTop: "20px", fontWeight: 550 }}>{this.props.kpiName}</label>
                        </div>
                        <EuiRow>
                            {((this.props.actualValue === 0 || this.props.actualValue) ?
                                <div className="column sm-4 md-4 lg-4 xl-4" style={{ marginTop: "25px", marginLeft: "5px" }}>

                                    <span className="text-lg color-gray" style={{ fontSize: "12px" }}>Status</span>
                                </div>
                                : null)}
                        </EuiRow>

                        <EuiGuage id={this.props.id} key={this.props.gaugeSettings}
                            style={{ marginTop: "20px", display: "flex", justifyContent: "flex-start" }}
                            dataSettings={this.props.gaugeSettings}
                            label={this.props.gaugeLabel}
                        ></EuiGuage>
                    </div>
                    <div className="column sm-6 md-6 lg-6 xl-6 filled" style={{ marginLeft: "2%" }} >

                        {((this.props.actualValue === 0 || this.props.actualValue) ?
                            <span style={{
                                display: "flex", justifyContent: "flex-end", marginTop: "40px"
                            }} >
                                <span className="tooltip pointer" hidden={this.props.hideToolTip} style={{ marginTop: "5px", whiteSpace: "pre", width: "50px" }} id={this.props.id + "_" + "tip"}>
                                    <i className="icon icon-info" style={{ fontSize: "20px", marginRight: "15px", marginTop: "12px" }}></i>
                                    <span className={(this.props.tipPosition ? "message " + this.props.tipPosition : "message top")}>{this.props.infoTip}</span>
                                </span>
                                <button className="btn" id={this.props.id} hidden={this.props.hideButton} style={{ ...this.props.ButtonStyle, width: "150px", height: "30px" }} onClick={this.props.onClick}>Show Sites</button>
                            </span> : null)}
                        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "10px" }}>
                            {(this.props.reverse ?
                                (this.props.percentageValue <= 15 ?
                                    <div> <div style={{ display: "flex", alignContent: "right", marginTop: "30px" }}>
                                        <span style={{ display: "flex", alignContent: "right" }}>
                                            <div className="kpi">
                                                {/*<div className="item text-xl">{this.props.percentageValue}%</div>*/}
                                            </div>
                                            <span className="color-green" style={{ marginBottom: "30px" }}>
                                                <i className="icon icon-severity-cleared" style={{ marginTop: "10px" }}></i>
                                            </span>
                                            <span style={{ marginBottom: "30px", marginLeft: "5px" }}>On track</span>
                                        </span>
                                    </div>
                                        <div className="text-lg color-gray"></div>
                                    </div>
                                    : <div> <div style={{ display: "flex", alignContent: "right", marginTop: "30px" }}>
                                        <span style={{ display: "flex", alignContent: "right" }}>
                                            <div className="kpi">
                                                {/*<div className="item text-xl">{this.props.percentageValue}%</div>*/}
                                            </div>
                                            <span className="color-red" style={{ marginBottom: "30px" }}>
                                                <i className="icon icon-severity-cleared" style={{ marginTop: "10px" }}></i>
                                            </span>
                                            <span style={{ marginBottom: "30px", marginLeft: "5px" }}>Off track</span>
                                        </span>
                                    </div>
                                        {/*<div className="text-lg color-gray">{this.props.date}</div>*/}
                                    </div>
                                ) :
                                (this.props.percentageValue >= 85 ?
                                    <div> <div style={{ display: "flex", alignContent: "right", marginTop: "30px" }}>
                                        <span style={{ display: "flex", alignContent: "right" }}>
                                            <div className="kpi">
                                                {/*<div className="item text-xl">{this.props.percentageValue}%</div>*/}
                                            </div>
                                            <span className="color-green" style={{ marginBottom: "30px" }}>
                                                <i className="icon icon-severity-cleared" style={{ marginTop: "10px" }}></i>
                                            </span>
                                            <span style={{ marginBottom: "30px", marginLeft: "5px" }}>On track</span>
                                        </span>
                                    </div>
                                        <div className="text-lg color-gray"></div>
                                    </div>
                                    : <div> <div style={{ display: "flex", alignContent: "right", marginTop: "30px" }}>
                                        <span style={{ display: "flex", alignContent: "right" }}>
                                            <div className="kpi">
                                                {/*<div className="item text-xl">{this.props.percentageValue}%</div>*/}
                                            </div>
                                            <span className="color-red" style={{ marginBottom: "30px" }}>
                                                <i className="icon icon-severity-cleared" style={{ marginTop: "10px" }}></i>
                                            </span>
                                            <span style={{ marginBottom: "30px", marginLeft: "5px" }}>Off track</span>
                                        </span>
                                    </div>
                                        {/*<div className="text-lg color-gray">{this.props.date}</div>*/}
                                    </div>
                                ))}
                        </div>
                        {((this.props.actualValue === 0 || this.props.actualValue) ?
                            <>
                                <EuiKpi
                                    value={this.props.reverse ? 15 : 85}
                                    style={{ width: "200px", height: "20px", paddingBottom: "0px" }}
                                    unit="Target Value"
                                    type="%"
                                    color="thin"
                                    max="100"
                                />
                                <EuiKpi
                                    value={this.props.actualValue}
                                    style={{ width: "300px", height: "20px", paddingBottom: "0px" }}
                                    unit="Actual Value"
                                    type={"/" + this.props.barMax + " Sites"}
                                    color="thin blue"
                                    max={this.props.barMax}

                                />
                            </> : <EuiKpi
                                barLabelStyle={{ marginTop: "60px" }}
                                value={this.props.reverse ? 15 : 85}
                                style={{ width: "200px", height: "20px", paddingBottom: "0px" }}
                                unit="Target Value"
                                type="%"
                                color="thin"
                                max="100"
                            />
                        )}
                        {(this.props.weightage ?
                            <div className="text-lg color-gray">{this.props.weightage}</div>
                            : null)}

                    </div>
                </EuiRow>
            </div >
        );
    }
}

export default (KpiCard);
