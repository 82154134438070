import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ApiClient from "../../../utils/api-client";

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            token: "",
            status: "",
            isLoading: false
        }
    }

    onClick = (event) => {
        var randomString = require('random-string');
        this.props.passwordReset()

        var ResetPasswordToken = randomString();

        this.setState({ token: ResetPasswordToken, isLoading: true })
        ApiClient.post("User/SendToken", {
            Email: this.state.email,
            ResetPasswordToken: ResetPasswordToken,
        }).then(response => {
            if (response.data == true) {

                this.setState({
                    isLoading: false, satus: "An email is sent to you to reset your password!",
                });
                this.props.history.push("/recover-password")

            }
            else {
                this.setState({
                    status: "Email doesn't exist please enter a vaild email!"
                })
            }

        })
        event.preventDefault();

    }


    render() {
        return (
            <div className="signin">

                <div className="watermark">

                    <i className="icon icon-econ"></i>
                    <div className="product">Ericsson Eritop ASP</div>

                </div>
                <div
                    className={
                        "welcome " + (this.state.isLoading === true ? "" : "hidden")
                    }
                >
                    <div className="loading" style={{ alignContent: "center" }}></div>
                </div>
                <form
                    className={this.state.isLoading == false ? "" : "hidden"}
                    onSubmit={this.onSubmit}
                    style={{
                        alignContent: "middle"
                    }}
                >
                    <div className="message"
                        style={{ alignContent: "middle", fontSize: "30px", marginLeft: "30px", marginBottom: "90px" }}
                    >Reset Password</div>
                    <div className="field validate">
                        <label htmlFor="username" style={{ fontSize: 12, opacity: "0.7" }}>Email Address</label>
                        <input
                            type="email"
                            name="username"
                            id="username"
                            tabIndex="0"
                            autoFocus
                            required
                            value={this.state.email}
                            placeholder="Email Address"

                            onChange={e => this.setState({ email: e.target.value })}
                        />
                    </div>
                    <button
                        className="btn big primary"
                        type="submit"
                        onClick={this.onClick}
                    >
                        Send Verification Code
                    </button>
                    <div className={"error " + (this.state.status ? "" : "hidden")}>
                        {this.state.status}
                    </div>

                </form>
            </div>
        );
    }
}
const FormField = (props) => (
    <div style={{ margin: 20, ...props.style }} className="field validate">
        {props.children}
    </div>
);
const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => {
    return {
        passwordReset: () => dispatch({ type: "RESETPASSWORD" })

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PasswordReset));
