import React, { useState, useEffect } from 'react'
import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import EuiBreadcrumb from '../../../eui-components/breadcrumbs';
import EuiHeader from '../../../eui-components/header';
import EuiRow from '../../../eui-components/row';
import EuiTabs from '../../../eui-components/tabs';
import constants from '../../../utils/constants';
import VehicleHelper from '../../../utils/vehicle-helper';
import MonthlyInspectionList from '../../vehicle-inspections/monthlyInspectionList';
import LogVehicle from '../../vehicle-Logs/LogVehicle';
import CreateEditVehicle from './createEditVehicle';



const VehicleTabs = (props) => {

    const currentVehicle = useSelector(state => state.vehicle.currentVehicle);

    const reduxDispatch = useDispatch();

    const [statusDialog, openStatusDialog] = useState({ id: 0, open: false, type: VehicleHelper.vehicleStatusLookup['Under Review'] });

    const updateTabsContent = () => {
        const viewContent = [{
            value: 1,
            children: <CreateEditVehicle id="vehicleInfoTab" openStatusDialog={openStatusDialog} />,
            selected: "selected",
            contentStyle: { width: "100%", maxHeight: 650 }
        },
        {
            value: 2,
            children: <MonthlyInspectionList id="monthlyInspectionTabs" />,
            selected: "",
            contentStyle: { width: "100%", maxHeight: 650 }
        },
        {
            value: 3,
            children: <LogVehicle id="logVehicleTabs" currentVehicle={currentVehicle} />,
            selected: "",
            contentStyle: { width: "100%", maxHeight: 650 }
        }
        ]

        return viewContent;
    }


    return (
        <EuiRow>
            <EuiHeader id="vehicleTabsHeaderId" title={<EuiBreadcrumb id="vehcileDetailBreadcrumb"
                parentName={<div className="flex-start-content"><i className="icon icon-home icon-lg pr-1"></i>Vehicle List</div>}
                childName={currentVehicle.chassisNumber || "New Vehicle"}
                onClickParent={() => { 
                    reduxDispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.LIST });
                    reduxDispatch({ type: "CHANGE_INSPECTION_MOD", payload: constants.pageMods.LIST })

                     }}
                className="p-2"
                style={{ display: "flex", alignItems: "center" }}
            />}
            >
            </EuiHeader>
            <EuiTabs
                titleColumnStyle={{ marginBottom: "10px" }}
                contentStyle={{ width: "100%" }}
                id="VehicleDetailsTabs"
                titles=
                {[
              { id: 1, value: 1, name: "Vehicle information", selected: "selected", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } },
                {
                id: 2, value: 2, name: "Inspection Records", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" }
                },
                {
                id: 3, value: 3, name: "Logs", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" }
                },
                ]}
                contents={updateTabsContent()}
            />
        </EuiRow>
    )


}
export default VehicleTabs;