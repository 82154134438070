import React from "react";
import constants from "./constants";

export default class ActivityHelper {

    static sessionDialogContents = {
        ACTIVITY: { type: 1, title: "Assign to New Site Supervisor", role: constants.userRoles.RSC_SITE_SUPERVISOR },
        EHS: { type: 2, title: "Assign to New EHS Inspector", role: constants.userRoles.RSC_OHS_INSPECTOR }
    };

    static sessionUnAssignDialogContents = {
        ACTIVITY: { type: 1, title: "Un-Assign Selected Activities", name: "activities" },
        EHS: { type: 2, title: "Un-Assign Selected EHS Pings", name: "EHS Pings" }
    };

    static mapRscScopes = (rscScope) => {
        if (!rscScope)
            return 0;
        return constants.rscScopes.find(item => item.text == rscScope)?.value;
    };
    static getActivityStatus = (statusId) => {
        switch (statusId) {
            case 1:
                return "Pending";
            case 2:
                return "Accepted"
            case 3:
                return "Rejected";
            default:
                return null;
        }
    };

    static mapGpoName = (gpoName) => {
        if (!gpoName)
            return 0;
        return constants.globalPlanningObject.find(item => item.text == gpoName)?.value;
    };

    static getDocumentTypesCount = (allDocument, type) => {
        if (!allDocument || allDocument.length === 0) {
            return 0;
        }

        switch (type) {
            case constants.acceptanceStatusTypes.PENDING:
                return allDocument.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING).length;
            case constants.acceptanceStatusTypes.REJECTED:
                return allDocument.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED).length;
            case constants.acceptanceStatusTypes.ACCEPTED:
                return allDocument.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED).length;
            default:
                return 0;
        }

    }

    static mapIMActivites = (activities) => {
        if (!activities || activities.length === 0) {
            return [];
        }

        activities.map(item => {
            item.customerName = item.customer?.text;
            item.workplanName = item.workplan?.text;
            item.projectName = item.project?.text;
            item.siteName = item.site?.text;
            item.aspCompanyAndTeamLead = [item.aspCompany?.text ?? "---", item.teamLead?.text ?? "---"];
            item.teamLeadMail = item.teamLead?.mail ?? "---";
            item.assignId = item.id;
            item.aspResource = item.teamLead;
            item.addInfo = {
                workplanName: item.workplan?.text,
                siteName: item.site?.text,
                projectName: item.project?.text,
            };
        });
    }

    static mapActiveSessions = (activities) => {
        if ((activities || []).length === 0) {
            return [];
        }
        
        activities.map(item => {
            item.aspCompanyAndTeamLead = [item.aspCompanyName ?? "---", item.teamLead?.text, item.teamLead?.code];
            item.rscPersonName = item.rscPerson?.text;
        });
    }

    static mapEHSSessions = (ehsPings) => {
        if ((ehsPings || []).length === 0) {
            return [];
        }
        
        ehsPings.map(item => {
            item.aspCompanyAndTeamLead = [item.aspCompanyName, item.teamLead?.text, item.teamLead?.code];
            item.rscPersonNameEHS = item.rscPerson?.text;
            item.assignedOnEHS = item.assignedOn;
        });
    }
}