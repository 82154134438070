import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import { withRouter } from "react-router-dom";
import EuiLoading from "../../eui-components/loading";

class IntegrationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            integrationFormColumns: [],
            integrationFormRows: [],
            transactionId: 0,
        }
    }

    handleClose = () => {
        this.setState({
            openDialog: false
        })
    }

    componentWillUpdate(nextProps) {
        if(nextProps.data && nextProps.data.records && this.state.transactionId != nextProps.data.transactionId)
        {
            let records = nextProps.data.records
            let object = records[0] || {}
            let integrationFormColumns = Object.keys(object)
                .filter(e=> typeof(object[e]) != 'object' || object[e] instanceof Date)
                .map(c => ({
                    title: c,
                    key: c,
                }))

            let integrationFormRows = records.map(r => {
                 return {
                    ...r,
                    details: r
                 };
            });

            integrationFormColumns.push({
                title: "More Info",
                onCreatedCell: (td, cellData) => {
                    td.innerHTML
                    =`<div id="info_" class="tooltip"><i id="info" class="icon icon-zoom-out text-lg clickable "></i><span class="message top-end">${JSON.stringify(cellData, null, 4)}</span></div>`;
                   
                },
                key: "details",
            })

            this.setState({integrationFormColumns, integrationFormRows, transactionId: nextProps.data.transactionId})
        }
    }


    render()
    {
        return (<EuiRow
            disabled={this.state.isLoading ? "disabled" : ""} key={this.state.isLoading} >
            <EuiLoading id="activityToDetailLoading" isLoading={this.state.isLoading} />

            <ObseleteEuiTable
                key={this.state.integrationFormRows}
                classes="tiny split-striped"
                id="integrationForm"
                columns={this.state.integrationFormColumns}
                data={this.state.integrationFormRows}
                filter={true}
                scroll={false}
                selectable='multi'
                selectRow={(e, type) => this.props.selectRow(e, type)}
                // hideSearch={true}
                sortable={true}
                // totalCount={this.state.integrationFormRows.length}
                // rowsPerPage={10}
                // paginated={true}
            />
        </EuiRow >
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(IntegrationForm));
