import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import PropTypes from "prop-types";
import PageHelper from '../utils/page-helper';
import EuiDropdownMore from '../eui-components/dropdownmore';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import { Tooltip } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import SasHelper from '../utils/sas-helper';

const useStyles = makeStyles((theme) => ({
    titleBar: {
        background:
            'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
            'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    icon: {
        color: 'white',
    },
}));

const AdvancedGridList =
    ({ tileData,
        selectedDocument,
        onClickApprove,
        onClickReject,
        onClickPending,
        onClickChecking,
        viewOnly,
        onClickImage,
        allAcceptOrReject,
        type
    }) => {
        const classes = useStyles();
        const currentInspection = useSelector(state => state.vehicleInspection.currentInspection)
        const [currentStatus, setCurrentStatus] = useState(1)
        const listChecklist = []
        const [sasToken, setSasToken] = useState({});

        useEffect(() => {
            SasHelper.getSasKey().then((response) => {
              setSasToken(response);
            });
        
        }, []);
        const getAcceptanceStatusId = (id) => {
            switch (id) {
                case 1:
                case 2:
                    return 1;
                case 3:
                    return 2;
                case 4:
                    return 3;
                default:
                    return 1;
            }
        }

        useEffect(() => {
            if (!isEmpty(currentInspection?.currentInspectionItems)) {
                var checklist = currentInspection?.currentInspectionItems?.map(item => {
                    // let docs = getDocuments(item);
                    listChecklist.push(item.vehicleInspectionEvidences)

                })
                tileData = [...listChecklist]

                setCurrentStatus(currentInspection?.currentInspectionItems[0]?.vehicleInspectionEvidences[0]?.acceptanceStatusId)

            }
        }, [allAcceptOrReject, currentInspection])


        return (
            <div>
                <GridList cellHeight={300} spacing={2} cols={5} >
                    {
                        (tileData).map((tile) => (

                            <GridListTile key={tile.id} cols={1} rows={1} style={{ width: "auto", margin: "0px 5px" }}>
                                <LazyLoadImage id={tile.id} src={SasHelper.addSasKey(tile.posterPath, sasToken)} alt={tile.name?.name} height="300px" onClick={() => onClickImage && onClickImage(tile)} />
                                {console.log('FiberManualRecordRoundedIcon',  tile.id, tile.acceptanceStatusId)}

                                {type !== 4 &&
                                    <GridListTileBar
                                        title={<>
                                            <FiberManualRecordRoundedIcon
                                                style={{ fontSize: 20, marginTop: 8,
                                                color: PageHelper.renderStatusColor(
                                                    type === 1 ? tile.sessionAcceptanceStatus :
                                                    (tile.acceptanceStatusId ? tile.acceptanceStatusId : currentStatus || setCurrentStatus(tile.statusId))) }}
                                                />
                                            {PageHelper.mapRscStatus(tile.acceptanceStatusId)}
                                        </>}//         allAcceptOrReject,
                                        actionIcon={
                                            viewOnly ? null :
                                                <EuiDropdownMore
                                                    buttonStyle={{ padding: 12 }}
                                                    styleIcon={{ color: "white" }}
                                                    styleMenu={{ bottom: 0 }}
                                                    id={"dropdownmore" + tile.id}
                                                    onClick={() => {
                                                        ///console.log("tileData",tileData,tile)

                                                        selectedDocument(tile)
                                                    }}
                                                    instances={[
                                                        {
                                                            id: "info" + tile.id,
                                                            text: <Tooltip
                                                                style={{ background: "#707070" }}
                                                                id="viewSessionAccepted"
                                                                title={
                                                                    <React.Fragment>
                                                                        {type === 3 ? tile.additionalInfo : tile.title}
                                                                        <br />
                                                                        <ul key={"comment"} style={{ listStyleType: "none", fontSize: 12, marginBottom: 0, color: "#fff", paddingInlineStart: 0, }} >
                                                                            {tile.severityLevel > 0 ? <li style={{ paddingBottom: 3 }} key={"severityLevel"}>{"Severity Level: " + PageHelper.renderSeverityLevel(tile.severityLevel)}</li> : null}
                                                                            {tile.customerComments || tile.reviewComments ? <li style={{ paddingBottom: 3 }} key={"customerComments"}>{(type === 3 ? "RSC Engineer Comments: " + tile.reviewComments : "Customer Comments: " + tile.customerComments)}</li> : null}
                                                                        </ul>
                                                                    </React.Fragment>
                                                                }>
                                                                <div style={{ display: "flex" }}><i className="icon icon-info" style={{ paddingRight: 5, fontSize: 15 }}></i> Info </div>
                                                            </Tooltip>,
                                                            onClick: () => { }
                                                        },
                                                        {
                                                            id: "accepted" + tile.id,
                                                            text: <div style={{ display: "flex" }}><FiberManualRecordRoundedIcon
                                                                style={{ fontSize: 15, marginRight: 5, color: PageHelper.renderStatusColor(2) }} /> Accepted </div>,
                                                            onClick: () => {
                                                                onClickApprove && onClickApprove()
                                                                setCurrentStatus(2)
                                                            }
                                                        },
                                                        {
                                                            id: "rejected" + tile.id,
                                                            text: <div style={{ display: "flex" }}><FiberManualRecordRoundedIcon
                                                                style={{ fontSize: 15, marginRight: 5, color: PageHelper.renderStatusColor(3) }} /> Rejected </div>,
                                                            onClick: () => {
                                                                onClickReject && onClickReject()
                                                                setCurrentStatus(3)
                                                            }
                                                        },
                                                        {
                                                            id: "pending" + tile.id,
                                                            text: <div style={{ display: "flex" }}><FiberManualRecordRoundedIcon
                                                                style={{ fontSize: 15, marginRight: 5, color: PageHelper.renderStatusColor(1) }} /> Pending </div>,
                                                            onClick: () => {
                                                                onClickPending && onClickPending()
                                                                setCurrentStatus(1)
                                                            }
                                                        }
                                                    ]
                                                    }
                                                >
                                                </EuiDropdownMore>
                                        }
                                        className={classes.titleBar}
                                    />

                                }
                            </GridListTile>
                        ))}
                </GridList>
            </div>
        );
    }
AdvancedGridList.propTypes = {
    tileData: PropTypes.array
};

export default AdvancedGridList;
