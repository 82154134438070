import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiRow from "../../eui-components/row";
import FormField from "../../eui-components/formfield";
import EuiTextField from "../../eui-components/textfield";
import EuiCheckbox from "../../eui-components/checkbox";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import constants from "../../utils/constants";

const UpdateSUbactivityItem = (props) => {

    const [nameLengthViolation, setNameLengthViolation] = useState(false);

    const item = useSelector(state => state.activity.selectedChecklistItem);

    const activityTemplateData = useSelector(state => state.activityChecklists);

    const dispatch = useDispatch();

    const handleChange = (e) => {
        const _item = { ...item }
        const target = e.target;
        const name = target.name;
        if (name == 'name') {
            if (target.value.length > 255) {
                setNameLengthViolation(true);
                var trimmedValue = target.value.substring(0, 254);
                _item[name] = trimmedValue;
            }
            else {
                setNameLengthViolation(false);
                _item[name] = target.value;
            }
        }
        else if (name == "itemSortId" || name == "itemCatSortId")
            _item[name] = parseInt(target.value);
        else if (name == "isLiveItem")
            _item[name] = (target.value ? 1 : 0)

        else if (name == 'severity') {
            var textValue = constants.templateSeverityLevels.find(element => element.value == target.value)?.text;
            _item[name] = textValue

        }
        else if (name == "subactivityGroupId") {
            _item[name] = target.value;
            _item["subactivityGroup"] = target.text;
        }
        else if (name == "viCodeId") {
            _item[name] = target.value;
            _item["viCode"] = target.text;

        }
        else
            _item[name] = target.value;
        dispatch({ type: "SET_SELECTED_CHECKLIST_ITEM", payload: _item });
    }

    return (
        <EuiRow>
            <EuiRow className="w-10"  >
                <FormField className="w-10 mt-2 mb-2">
                    <EuiTextField className="w-10" fullwidth id="itemName" name="name" label="Checklist Item Name" labelStyle={{ fontSize: "14px", color: "gray" }}
                        value={item.name} onChange={(e) => handleChange(e)}
                        multiLine={true}
                        labelClass="color-gray required"
                        style={{ height: "60px" }}
                    />
                    <label style={{ color: 'red' }}>*Checklist item name cannot exceed 255 characters</label>
                </FormField>
                <div className="column sm-6 md-6 xl-6">
                    <FormField className="w-10 mt-2 mb-2">
                        <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="groupName">Group Name</label>
                        <EuiAutocompleteSelect className="w-10"
                            key={activityTemplateData?.itemsGroups}
                            fullwidth
                             id="groupName"
                              name="subactivityGroupId"
                            value={item?.subactivityGroupId}
                            optionListStyle={{ width: "100%" }}
                            options={activityTemplateData?.itemsGroups}
                            onChange={(e) => handleChange(e)} />
                    </FormField>
                    <FormField className="w-10 mt-2 mb-2">
                        <label className="color-gray" style={{ fontSize: "14px" }} htmlFor="viCodeId">Vi Code</label>
                        <EuiAutocompleteSelect className="w-10"
                            key={activityTemplateData?.viCodes}
                            fullwidth id="viCodeId" name="viCodeId"
                            value={item?.viCodeId ?? 0}
                            optionListStyle={{ width: "100%" }}
                            options={activityTemplateData?.viCodes}
                            onChange={(e) => handleChange(e)} />
                    </FormField>
                    <FormField className="w-10 mt-2 mb-2">
                        <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="severitySelect">Severity</label>
                        <EuiAutocompleteSelect
                            key={constants.templateSeverityLevels}
                            className="w-10"
                            label="Severity"
                            id="severitysSelect"
                            optionListStyle={{ width: "100%" }}
                            name="severity"
                            options={constants.templateSeverityLevels}
                            value={constants.templateSeverityLevels.find(element => element.text == item?.severity)?.value}
                            onChange={(e) => handleChange(e)}
                            fullwidth
                        />
                    </FormField>

                    <EuiRow>
                        <FormField className="w-5 mt-2 mb-2">
                            <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="itemSortId">Category Sort ID</label>
                            <input type="number" style={{ width: "100px" }} className="w-10" fullwidth id="itemcatSortId" name="itemCatSortId"
                                value={item.itemCatSortId} onChange={(e) => handleChange(e)} />
                        </FormField>

                        <FormField className="w-5 mt-2 mb-2">
                            <label className="color-gray required" style={{ fontSize: "14px" }} htmlFor="itemSortId">Item Sort ID</label>
                            <input type="number" style={{ width: "100px" }} className="w-10" fullwidth id="itemSortId" name="itemSortId"
                                value={item.itemSortId} onChange={(e) => handleChange(e)} />
                        </FormField>
                    </EuiRow>
                    <FormField className="w-10 mt-2 mb-2">
                        <EuiCheckbox
                            label="Is Live"
                            labelClass="color-gray"
                            className="flex-start-content pl-0 pr-1"
                            id="isLiveItem"
                            refValue={item.isLiveItem}
                            onChange={(e) => handleChange(e)}
                            defaultChecked={false}
                            checked={item.isLiveItem}
                            name="isLiveItem" />
                    </FormField>


                </div>
                <div className="column sm-6 md-6 xl-6">

                    <FormField className="w-10 mt-2 mb-2">
                        <EuiTextField className="w-10" fullwidth id="subItemNo" name="subItemNo" labelClass="color-gray" label="Sub Item Number" labelStyle={{ fontSize: "14px", color: "gray" }}
                            value={item?.subItemNo} onChange={(e) => handleChange(e)} />
                    </FormField>
                    <FormField className="w-10 mt-2 mb-2">
                        <label className="color-gray" style={{ fontSize: "14px" }} htmlFor="workInstructionSelect">Work Instruction</label>
                        <EuiAutocompleteSelect
                            disabled={true}
                            key={activityTemplateData?.workManuals}
                            className="w-10"
                            label="Select Work Instruction"
                            id="workInstructionSelect"
                            optionListStyle={{ width: "100%" }}
                            name="workInstructionId"
                            options={activityTemplateData?.workManuals}
                            value={item?.workInstructionId}
                            onChange={(e) => handleChange(e)}
                            fullwidth
                        />
                    </FormField>
                </div>
            </EuiRow >

        </EuiRow >
    );
}


export default UpdateSUbactivityItem;