import React, { useState, useEffect } from "react";
import constants from "../../utils/constants.js";
import EuiRow from "../../eui-components/row";
import { useSelector, useDispatch } from "react-redux";
import EuiRowWithToolbar from "../../eui-components/rowwithtoolbar";
import AdvancedGridList from "../../components/advancedgridlist";
import EuiAccordion from "../../eui-components/accordion";
import PageHelper from "../../utils/page-helper";
import SimpleSlider from "../../components/slider";
import EuiTooltip from "../../eui-components/tooltip";
import DialogModal from "../../components/dialog";
import UserHelper from "../../utils/user-helper";
import DateUtils from "../../utils/date-utils";
import EuiHeader from "../../eui-components/header";
import EuiSelect from "../../eui-components/select";
import VehicleCategoryBox from '../asp-companies/vehicle-management/vehicleInspectionCategory/VehicleCategoryBox';
import ApiClient from "../../utils/api-client.js";
import CommonHelper from "../../utils/common-helper.js";
import { Badge } from "@material-ui/core";
import SasHelper from "../../utils/sas-helper";

const VehicleInspectionChecklist = (props) => {
const {evidences,selectedStatus}=props;
// 
    const [openAllDialog, setOpenAllDialog] = useState(false);
    const [allAcceptOrReject, setAllAcceptOrReject] = useState(0);
    const [checklistItem, setChecklistItem] = useState(0);
    const [openAllChecklistDialog, setOpenAllChecklistDialog] = useState(false);
    const [comment, setComment] = useState();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.userData);
    const users = useSelector(state => state.user.users);
    var inspection = useSelector(state => state.vehicleInspection);
    const currentInspection = useSelector(state => state.vehicleInspection.currentInspection)
    const [openDialog, setOpenDialog] = useState(false);
    const [showAcc, setShowAcc] = useState(true);
    const [openImgPreview, setOpenImgPreview] = useState(false);
    const mod = useSelector(state => state.vehicleInspection.mod);
    const [statusState, setStatus] = useState(0);
    const [tab, setTab] = useState(1);
    const [allDocument, setAllDocument] = useState([]);
    const [inspectionCategory, setInspectionCategory] = useState([]);
    const [isOpenVehicleCategory, setIsOpenVehicleCategory] = useState(false);
    const [vehicleCategory, setVehicleCategory] = useState([])
    const [reloadInspection, setReloadInspection] = useState(false);
    const [sasToken, setSasToken] = useState({});

    // currentInspection?.currentInspectionItems?.map(item => item.vehicleInspectionEvidences).reduce((a, b) => { return a?.concat(b); })
    const [documentViewData, setDocumentViewData] = useState({
        selectedDocument: {
            inspectionChecklistItemId: 0,
            id: 0,
            statusId: 0,
            comments: ""
        },
        imgIndex: 0,
        totalCount: "",
        imgUrlLength: 0,
    });
    useEffect(() => {
        SasHelper.getSasKey().then((response) => {
            setSasToken(response);
        });
    
    }, []);
    // fetch the category inspection items
    const GetAllInspectionsCategory=()=>{
        ApiClient.get("Vehicle/GetAllInspectionsCategory")
        .then(response => {
            if (response.data){
               setInspectionCategory([...response.data])
            }
        })
        .catch(error => {
            console.error(error);;
             CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
        });         
    }
    // useEffect(() => {
    //     if(allDocument.length == 0)
    //     {
    //         setAllDocument([...evidences]);
    //     }
    // }, [evidences]);
    useEffect(() => {
        console.log("evidences GetAllInspectionsCategory",evidences)
        if(inspectionCategory.length===0&& evidences?.length>0){
            GetAllInspectionsCategory()  }
            }, [inspectionCategory,evidences])
        
    const renderStatus = (rowData, preview = "") => {
        const id = rowData?.id + "_status";
        const color = PageHelper.renderActivityStatusColor(getAcceptanceStatusId(rowData.statusId));
        const value = constants.acceptanceStatusLookup[getAcceptanceStatusId(rowData.statusId) || 1];
        const optionList = constants.statusList.filter(item => item.value !== 4).reduce((a, b) => {
            let selected = "";
            if (b.value === getAcceptanceStatusId(rowData.statusId)) {
                selected = "selected";
            }
            a += `<option ${selected} value="${b.text}">${b.text}</option>`;
            return a;
        }, "");

        if (preview === "") {
            return `<div key=${id} class="flex-start-content w-10">
            <i class="icon icon-alarm-level4 color-${color}" style="width:7%;"}> </i>
            <select id="status_activity_document_${rowData.id}" value="${value}"
                  style="height:30px;background:inherit;border:none;">
                  ${optionList}
            </select>           
        </div>`;
        } else {
            return <div key={rowData.id + "_status_preview_"} className="flex-start-content w-10">
                <i className={"icon icon-alarm-level4 color-" + color} style={{ width: "7%" }}> </i>
                <EuiSelect
                    style={{
                        background: "inherit", border: "none",
                        color: "#fff",
                        fontSize: 17
                    }}
                    optionListStyle={{ position: "inherit" }}
                    options={constants.statusList.filter(s => s.value != 4)}
                    value={getAcceptanceStatusId(rowData.statusId) || 1}
                    name={"status" + rowData.id}
                    id={"status" + rowData.id + preview}
                    onChange={e => handleChange(e, preview)}
                />
            </div>
        }
    }
    const renderFilePreview = () => {
        const item = { ...inspection.selectedItem }
        return <SimpleSlider
            id="inspectionVehicleSlider"
            slideData={allDocument} // evidences?.length
            currentSlide={documentViewData.selectedDocument ||evidences[0]}// allDocument[0]
            initialSlide={documentViewData.imgIndex}
            totalCount={documentViewData.totalCount}
            onNext={nextClick}
            onPrev={prevClick}
            editImage={true}
            onClose={() => setOpenImgPreview(false)}
            status={renderStatus(documentViewData.selectedDocument, "preview")}
            comment={documentViewData.selectedDocument.comment ?? ""}
            setImageTrack={(slide) => setImageTrack(slide, item)}
            type={3}
        />
    }
    const getDocuments = (checklistItem) => {
        if ((checklistItem.vehicleInspectionEvidences || []).length === 0) {
            return [];
        }
        checklistItem.vehicleInspectionEvidences.map(item => {
            item.name = item.documentName;
            item.posterPath = SasHelper.addSasKey(item.documentPath, sasToken);
            item.acceptanceStatusId = getAcceptanceStatusId(item.statusId);
            item.comment = item.comment;
            item.additionalInfo = "User Created: " + UserHelper.getUser(users, item.createUserId?.name) + "\n" + "Date time created: " + DateUtils.toDateString(item.createDate, "YYYY-MM-DD HH:mm:ss");
            item.uploadDetails = "User Created: " + UserHelper.getUser(users, item.createUserId?.name) + "\n" + "Date time created: " + DateUtils.toDateString(item.createDate, "YYYY-MM-DD HH:mm:ss");
        });
        return checklistItem.vehicleInspectionEvidences;
    };
    const getAcceptanceStatusId = (id) => {
        switch (id) {
            case 1:
            case 2:
                return 1;
            case 3:
                return 2;
            case 4:
                return 3;
            default:
                return 1;
        }
    }
    const prevClick = () => {
        
        const imgIndex = documentViewData.imgIndex === 0 ? documentViewData.imgUrlLength - 1 : documentViewData.imgIndex - 1;
        const selectedDocument = allDocument[imgIndex];
        
        
        const totalCount = (imgIndex + 1) + "/" + documentViewData.imgUrlLength;
        setDocumentViewData({
            selectedDocument: selectedDocument,
            imgIndex,
            totalCount,
            imgUrlLength: allDocument.length
        });
    }
    const nextClick = () => {
        
        const imgIndex = documentViewData.imgIndex === documentViewData.imgUrlLength - 1 ? 0 : documentViewData.imgIndex + 1;
        const selectedDocument = allDocument[imgIndex];
        const totalCount = (imgIndex + 1) + "/" + documentViewData.imgUrlLength;
        setDocumentViewData({
            selectedDocument: selectedDocument,
            imgIndex,
            totalCount,
            imgUrlLength: allDocument.length
        });
    }
    const selectAndOpenDocument = (document, item) => {
        const documentList = [...item.vehicleInspectionEvidences?.map(i => {
            let mappedItem = {
                ...document,
                ...i,
                id: i.id,
                posterPath: SasHelper.addSasKey(item.documentPath,sasToken),
                name: i.documentName,
                comment: i.comment,
                inspectionChecklistItemId: i.inspectionChecklistItemId,
                type: 1,
                title: (<p style={{ fontSize: '18px', fontWeight: 'bold', color: "white" }}>
                {item.checklistItemName  }</p>),
                acceptanceStatusId: i.acceptanceStatusId ?? 1, // check on prev/next click implementation as well
                uploadDetails: "\n" + "User Created: " + (UserHelper.getUser(users, i.createUserId)?.name ?? ""),
                additionalInfo: "\n" + ", Date time created: " + DateUtils.toDateString(i.createDate, "YYYY-MM-DD HH:mm:ss")
            }
            return mappedItem
        })];
        setAllDocument(documentList);
        setImageTrack(documentList.find(i => i.id === document.id), documentList);
    }
    const setImageTrack = (slide, documentList) => {
        let docs = documentList.length ? documentList : allDocument;
        const imgUrlLength = docs.length;
        let imgIndex = docs.findIndex(item => item.id === slide.id);
        setDocumentViewData({
            selectedDocument: slide,
            imgIndex,
            totalCount: (imgIndex + 1) + "/" + imgUrlLength,
            imgUrlLength
        });
        setOpenImgPreview(true);
    }
    const handleFakeLoading = () => {
        setShowAcc(false);
        setTimeout(() => {
            setShowAcc(true);
            
        }, 500);
    }

    const handleDialog = (value) => {
        const reviewComments = documentViewData.selectedDocument.comment ?? "";
        setComment(reviewComments);
        setStatus(value);
        setTab(1);
        setOpenDialog(true);
    }
    const handleChange = (e, preview = "") => {
        const target = e.target;
        const value = preview === "" ? constants.statusList.find(item => item.text === target.value)?.value : target.value;

        if (value === constants.acceptanceStatusTypes.ACCEPTED || value === constants.acceptanceStatusTypes.REJECTED) {
            
            handleDialog(value);
        } else if (value === constants.acceptanceStatusTypes.PENDING) {
            changeStatus(constants.acceptanceStatusTypes.PENDING); // passed
        }
    }

    const changeStatus = (newStatus) => {
        handleFakeLoading();
        newStatus = newStatus ?? statusState; // is it status ID or different status
        setStatus(newStatus);

        const inspection = { ...currentInspection };
        const status = newStatus ?? statusState; // is it status ID or different status
        const reviewComments = comment;
        const document = { ...documentViewData.selectedDocument };
        document.comment = reviewComments
        document.statusId = (status === constants.acceptanceStatusTypes.ACCEPTED ? 3 : status === constants.acceptanceStatusTypes.REJECTED ? 4 : 2);
        document.approveRejectDateTime = new Date();
        document.smeUserId = parseInt(user.userId);
        const inspectionItems = [...inspection.currentInspectionItems];
        let categoryId = 0;
        if(document?.id>0)
        {
            const i = inspectionItems?.findIndex(item => item.id == document.inspectionChecklistItemId);
            const inspectionItem = inspectionItems[i];
            const documents = inspectionItem && [...inspectionItem.vehicleInspectionEvidences];
           
            documents[documents?.findIndex(item => item?.id === document?.id)] = document;
            inspectionItem.vehicleInspectionEvidences = documents;
            categoryId = inspectionItem.categoryId;
        }

        inspection.currentInspectionItems = inspectionItems;
        console.log("evidencesevidencesevidences",[...inspection.currentInspectionItems])
        console.log("evidencesevidencesevidences ev",evidences)

        let newAllDocument = [...allDocument];

        newAllDocument[newAllDocument.findIndex(item => item.id === document.id)] = document;

        dispatch({ type: "SET_CURRENT_INSPECTION", payload: inspection });
        dispatch({ type: "SET_SELECTED_AND_ALL_DOCUMENT", payload: { allDocument: newAllDocument, selectedDocument: document } }); // look into it upon testing :) 
        setAllDocument(newAllDocument);
        var newdata = {...documentViewData }
        newdata.selectedDocument = document;
        setDocumentViewData(newdata)
        setComment("");
        setStatus(null);
        setTab(categoryId);
    }

    const changeAllStatus = (status) => {
        handleFakeLoading();

        const inspection = { ...currentInspection };

        if ((inspection.currentInspectionItems !== null) && (inspection.currentInspectionItems?.length > 0)) {
            inspection.currentInspectionItems.forEach(subItem => {
                if (subItem?.vehicleInspectionEvidences && subItem.vehicleInspectionEvidences.length > 0) {
                    subItem.vehicleInspectionEvidences.filter(d => d.statusId === constants.acceptanceStatusTypes.ACCEPTED).map(document => {
                        document.statusId = (status == constants.acceptanceStatusTypes.ACCEPTED ? 3 : status === constants.acceptanceStatusTypes.REJECTED ? 4 : 2);
                        document.approveRejectDateTime = new Date();
                        document.smeUserId = parseInt(user.userId);
                    });
                }
            });
        }
        let newAllDocument = [...allDocument];
        if (newAllDocument.length > 0) {
            newAllDocument.filter(d => d.statusId === constants.acceptanceStatusTypes.ACCEPTED).map(document => {
                document.statusId = (status == constants.acceptanceStatusTypes.ACCEPTED ? 3 : status === constants.acceptanceStatusTypes.REJECTED ? 4 : 2);
                document.smeUserId = parseInt(user.userId);
                document.approveRejectDateTime = new Date();
            });
        }
        
        
        dispatch({ type: "SET_CURRENT_INSPECTION", payload: inspection });
        dispatch({ type: "SET_ALL_DOCUMENT", payload: newAllDocument });
        setAllDocument(newAllDocument);
        setOpenAllDialog(false);
    }
    const changeChecklistStatus = (status) => {
        handleFakeLoading();
        const inspection = { ...currentInspection };
        
        ///console.log("testingggggggggggggggggtileData changeChecklistStatus",status)
        if ((inspection.currentInspectionItems !== null) && (inspection.currentInspectionItems?.length > 0)) {
            if (inspection.currentInspectionItems.find(subItem => subItem.id == checklistItem)?.vehicleInspectionEvidences !== null && inspection.currentInspectionItems.find(subItem => subItem.id == checklistItem)?.vehicleInspectionEvidences.length != 0) {
                inspection.currentInspectionItems.find(subItem => subItem.id == checklistItem).vehicleInspectionEvidences.filter(d => d.statusId === constants.acceptanceStatusTypes.ACCEPTED).map(document => {
                    document.statusId = (status == constants.acceptanceStatusTypes.ACCEPTED ? 3 : status === constants.acceptanceStatusTypes.REJECTED ? 4 : 2);
                    document.approveRejectDateTime = new Date();
                    document.smeUserId = parseInt(user.userId);

                });
            }
        }
        let newAllDocument = [...allDocument];
        if (newAllDocument.length > 0) {
            newAllDocument = newAllDocument.filter(i => i.inspectionChecklistItemId == checklistItem);
            if (newAllDocument.length > 0) {
                newAllDocument.filter(d => d.statusId === constants.acceptanceStatusTypes.ACCEPTED).map(document => {
                    document.statusId = (status == constants.acceptanceStatusTypes.ACCEPTED ? 3 : status === constants.acceptanceStatusTypes.REJECTED ? 4 : 2);
                    document.smeUserId = parseInt(user.userId);
                    document.approveRejectDateTime = new Date();
                });
            }
        }
        
        
        dispatch({ type: "SET_CURRENT_INSPECTION", payload: inspection });
        dispatch({ type: "SET_ALL_DOCUMENT", payload: newAllDocument });
        setAllDocument(newAllDocument);
        setOpenAllChecklistDialog(false);
        setChecklistItem(null);
    }
    const renderChecklists = () => {
        let accordionItems = [];
        let outerAccordionItems = [];
        const updateStatus=selectedStatus===2?3:selectedStatus===3?  4:2
        accordionItems = inspection?.currentInspection?.currentInspectionItems?.map(item => {
            let docs = getDocuments(item);

            const newDocs= docs.filter((newVal) => {
                return !selectedStatus ||  parseInt(newVal?.statusId) ===selectedStatus; 
              });
         const checklistItem = {
                categoryId: item.categoryId,
                opened: newDocs?.length > 0 ? "opened" : "",//item.vehicleInspectionEvidences
                id: item.id,
                title: item.checklistItemName,
                titleStyle: { fontWeight:  newDocs?.length > 0 ?'bold' : 500, padding: 3, backgroundColor: newDocs?.length > 0 ?'lightgreen' : 'inherit' },
                contentStyle: { paddingLeft: 10 },
                icon: true,
                titleIcon: newDocs?.length > 0 ? //item.vehicleInspectionEvidences
                    (<> 
                    <span class="tooltip"><i class="icon icon-check icon-frame clickable p-1 text-lg" onClick={() => { setOpenAllChecklistDialog(true); setAllAcceptOrReject(constants.acceptanceStatusTypes.ACCEPTED); setChecklistItem(item.id); }}></i>
                    <span class="message top-start">Accept Checklist Item</span></span>
                    <span class="tooltip"><i class="icon icon-cross icon-frame clickable p-1 text-lg" onClick={() => { setOpenAllChecklistDialog(true); setAllAcceptOrReject(constants.acceptanceStatusTypes.REJECTED); setChecklistItem(item.id); }}></i>
                    <span class="message top-start">Reject Checklist Item</span></span>
                    {item.isMandatory && 
                        <span className="tooltip ml-1 mr-2">
                            <Badge badgeContent={item.minPhoto} color="error" >
                                <i className="icon icon-severity-critical"
                                    style={{ fontSize: 19, bottom:5, left:1, height:20,width:20,position:'relative', paddingLeft: 2, color: "#ED0E00" }}></i>
                            </Badge>
                            <span className="message top-end">{item.minPhoto}</span>
                        </span>
                    }
                    </>) : (<></>)
                ,
                selectable: false,
                content: <>
                    <EuiHeader id={"inspectionsListHeader" + item.id} subTitle={item.comment } style={{ width: "100%" }} />
                    <AdvancedGridList
                        tileData={newDocs}
                        onClickApprove={() =>handleDialog(constants.acceptanceStatusTypes.ACCEPTED)}
                        onClickReject={() => handleDialog(constants.acceptanceStatusTypes.REJECTED)}
                        onClickPending={() => changeStatus(constants.acceptanceStatusTypes.PENDING)}// 
                        selectedDocument={(tile) => setDocumentViewData({selectedDocument: tile})}
                        viewOnly={mod == constants.pageMods.VIEW_ONLY ? true : false}
                        onClickImage={(document) => selectAndOpenDocument(document, item)}
                        type={3}
                        allAcceptOrReject={allAcceptOrReject}

                    />
                </>,

            };
            return checklistItem;
        });
        outerAccordionItems = inspectionCategory?.map(item => {
            const checklistCategory = {
                id: item.id + "category",
                title: item.category ,
                contentStyle: { paddingLeft: 5 },
                selectable: true,
                opened: "opened",
                checklistItem: evidences?.filter(i => i.categoryId ===item.id )[0] ,//inspection?.currentInspection?.currentInspectionItems Itemsinspection?.inspectionItemsList
                content: <EuiAccordion id={"innerchecklistAccordionId" + item.id}
                    style={{ width: "100%" }}
                    items={accordionItems.filter(i => i.categoryId === item.id)}
                    noAutoClose={true}
                    opened= "opened"
                />
            }
            return checklistCategory;
        });
        return(  
            <>
                <VehicleCategoryBox
                    checklistItems={inspection?.currentInspection?.currentInspectionItems }//{  }
                    selectedStatus={selectedStatus }//{inspection?.currentInspection?.currentInspectionItems  }

                    evidencesToUpload={inspection.evidencesToUpload}
                    selectedCategoryId={inspection.selectedItem?.categoryId||tab}
                    outerAccordionItems={outerAccordionItems} 
                    setVehicleCategory={setVehicleCategory}
                    setIsOpenVehicleCategory={setIsOpenVehicleCategory}
                    reloadInspection={reloadInspection}
                    inspectionCategory={inspectionCategory}
                    tab={tab}
                    setTab={setTab}
                />
                
                {  isOpenVehicleCategory &&   
                    <EuiAccordion 
                        id="inspectionChecklistAccordion"
                        noAutoClose={true}
                        style={{ width: "100%" }}
                        items={vehicleCategory}
                        opened="opened"
                    />
                }
            </>
             )

    }
    const handleCommentChange = (e) => {
        const target = e.target;
        const value = target.value;
        setComment(value);
    }
  

    return (
        <>
        <EuiRow key={inspection}>


                <EuiRowWithToolbar className="mt-1" contentStyle={{ overflow: "hidden", height: "100%" }} leftIcons={<>
                    {mod != constants.pageMods.VIEW_ONLY ?
                        <>
                            <EuiTooltip title="Accept All" position="left">
                                <i className={"icon icon-check icon-lg m-1 icon-frame "} onClick={() => { setOpenAllDialog(true); changeStatus(constants.acceptanceStatusTypes.ACCEPTED); setAllAcceptOrReject(constants.acceptanceStatusTypes.ACCEPTED); } }></i>
                            </EuiTooltip>
                            <EuiTooltip title="Reject All" position="left">
                                <i className={"icon icon-cross icon-lg m-1 icon-frame "} onClick={() => { setOpenAllDialog(true); changeStatus(constants.acceptanceStatusTypes.REJECTED); setAllAcceptOrReject(constants.acceptanceStatusTypes.REJECTED); } }></i>
                            </EuiTooltip>
                        </>
                        : null}
                </>}>

                </EuiRowWithToolbar>
                {evidences.length>0&& showAcc && renderChecklists()}
                {openImgPreview ? renderFilePreview() : null}
                <DialogModal
                    buttonName="Ok"
                    content={<EuiRow>
                        <label htmlFor="comments">Comments*</label>
                        <textarea placeholder="Comments"
                            className="fullwidth"
                            id="comments"
                            style={{ height: "150px" }}
                            label="EHS SME Comments"
                            name="comments"
                            defaultValue=""
                            value={comment}
                            onChange={e => handleCommentChange(e)} />
                    </EuiRow>}
                    // change into one comment only we don't need all of this
                    handleClose={() => setOpenDialog(false)}
                    id="approveRejectInspectionDialog"
                    onClick={() => {
                        setOpenDialog(false);
                        changeStatus();
                    } }
                    open={openDialog}
                    title="EHS SME Comments"
                >
                </DialogModal>

                <DialogModal
                    buttonName="Confirm"
                    content={<p>{"Are you sure you want to" + (allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? " accept " : " reject ") + "all pending documents?"}</p>}
                    handleClose={() => setOpenAllDialog(false)}
                    id="allAcceptRejectInspectionDialog"
                    onClick={() => changeAllStatus(allAcceptOrReject)}
                    open={openAllDialog}
                    title={allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "Accept All" : "Reject All"}
                    type={allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "primary" : "warning"}
                >
                </DialogModal>
                <DialogModal
                    buttonName="Confirm"
                    content={<p>{"Are you sure you want to" + (allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? " accept " : " reject ") + "all the pending checklist item documents?"}</p>}
                    handleClose={() => setOpenAllChecklistDialog(false)}
                    id="allAcceptRejectChecklistDialog"
                    onClick={() => changeChecklistStatus(allAcceptOrReject)}
                    open={openAllChecklistDialog}
                    title={allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "Accept All" : "Reject All"}
                    type={allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "primary" : "warning"}
                >
                </DialogModal>
            </EuiRow></>);
}
export default VehicleInspectionChecklist;