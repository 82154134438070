import { FormHelperText, Grid, TextField } from "@material-ui/core";
import React from "react";
import "./ContentDeleteProjectDialog.css";
import { width } from "@material-ui/system";

export default function ContentDeleteProjectDialog(props) {
  const { errorTokenCode, tokenCode, onChangeTokenCode, errorMessageTokenCode } = props;
  return (
    <div class="container-fluid text-center">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-sm-offset-3">
          <div class="new-message-box">
            <div class="new-message-box-danger">
              <div class="info-tab tip-icon-danger" title="error">
                <i></i>
              </div>
              <div class="tip-box-danger">
                <p>
                  <strong>Tip:</strong> you are about to Create delete request for this
                  project.
                </p>
                <p>
                 Once a deletion request is confirmed it will permanently deleted the project after 24 hours
                 and within this period it can be revoked from deletion requests page.{" "}
                </p>
                <p>
                  {" "}
                  Permanently deleting this project will immediately delete all
                  related resources including workplan ehs etc.
                </p>
                <p>
                  Please type the tokenCode recieved by your inbox email to confirm :{" "}
                </p>

                <Grid item xs={12} md={12} style={{width:"300px"}}>
                  <TextField
                    variant={"outlined"}
                    size="small"
                    value={tokenCode}
                    onChange={onChangeTokenCode}
                    label={"token sent by email address"}
                    // inputProps={{
                    //   autocomplete: "off",
                    //   form: { autocomplete: "new-password" },
                    // }}
                  />
                  <FormHelperText error>
                    {errorTokenCode ? errorMessageTokenCode : " "}
                  </FormHelperText>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
