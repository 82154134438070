import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import EuiRow from "../../eui-components/row";
import constants from "../../utils/constants";
import ApiClient from "../../utils/api-client";
import WorkplanHelper from "../../utils/workplan-helper";
import PageHelper from "../../utils/page-helper";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiLoading from "../../eui-components/loading";
import DialogModal from "../../components/dialog";
import EuiSwitch from "../../eui-components/switch";
import { Notification } from '@eds/vanilla';
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import ActivityHelper from "../../utils/activity-helper";
import TeamLeadersListDialog from "./teamLeadersListDialogjs";
import CommonHelper from "../../utils/common-helper";

const AspPmActivities = (props) => {


    const [isLoading, setIsLoading] = useState(false);
    const [activityColumns, setACtivityColumn] = useState(
        [{
            title: "Activity ID",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = `<a id="activity_${cellData}">${cellData}</a>`;
                td.querySelector("#activity_" + cellData).addEventListener('click', () => openActivityDetail(cellData))
            },
            key: "id",
            sort: "asc",
            width: "10%"
        },
        {
            title: "",
            key: "isTemplate",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<span class="tooltip"><i id="assignedicon" class="icon icon-view-list text-lg "></i><span class="message top-end">Activivty assigned to checklist</span></span>`;
                }
                else td.innerHTML = ""
            }
        },
        {
            title: "Activity Name",
            key: "name",
            sort: "none",
            width: "23%"
        },

        {
            title: "Workplan Name",
            key: "workplanName",
            sort: "none",
            headerStyle: props.type !== constants.activityListTypes.ACTIVITY ? "display:none;" : null,
            cellStyle: props.type !== constants.activityListTypes.ACTIVITY ? "display:none;" : null,
            width: "15%"
        },
        {
            title: "Site Name",
            key: "siteName",
            sort: "none",
            headerStyle: props.type !== constants.activityListTypes.ACTIVITY ? "display:none;" : null,
            cellStyle: props.type !== constants.activityListTypes.ACTIVITY ? "display:none;" : null,
            width: "10%"
        },
        {
            title: "ASP Company | Team Leader",
            key: "aspCompanyAndTeamLead",
            sort: "none",
            width: "25%",
            
        },
        {
            title: "Eritop Planned Dates",
            key: "plannedDates",
            hideFilter: true,
            headerStyle: props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
            cellStyle: props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
            sort: "none",
            width: "10%"
        },
        {
            title: "Actual Dates",
            key: "actualDates",
            hideFilter: true,
            headerStyle: props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
            cellStyle: props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
            sort: "none",
            width: "13%"
        },
        {
            title: "Status",
            hideFilter: true,
            key: "acceptanceStatusId",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                const status = PageHelper.renderActivityStatus(cellData);
                td.innerHTML = `${status}`;
            },
            width: "10%"
        },
        {
            hideFilter: true,
            title: "",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<span class="tooltip"><i id="session_${cellData}" class="icon icon-check text-lg clickable"></i><span class="message top-end">Go Customer Session</span></span>`;
                    td.querySelector("#session_" + cellData).addEventListener('click', () => openSessionDetail(cellData))
                }
            },
            key: "sessionId",
            width: "2%"
        },

        ]
    )
    const [activitiesFiltered, setActivitiesFiltered] = useState(false);
    const [selectedActivities, setSelectedActivities] = useState([]);
    const [aspCompanies, setAspCompanies] = useState([]);
    const [teams, setTeams] = useState([]);
    const [companyTeamLeaders, setCompanyTeamLeaders] = useState([]);
    const [openAssignDialog, setOpenAssignDialog] = useState(false);
    const [openUnAssignDialog, setOpenUnAssignDialog] = useState(false);

    const activityData = useSelector(state => state.activity);
    const filter = useSelector(state => state.activity.filter);
    const selectedTeamLeader = activityData.assignedLeader;

    const userData = useSelector(state => state.user);

    const dispatch = useDispatch();

    let history = useHistory();

    const changeFilterActivity = (e) => {
        setActivitiesFiltered(e.target.checked);
        setIsLoading(true);
        setIsLoading(false);

    }

    const activities = [...props.activities];

    const setSelectedActivitiesFun = (e, type) => {
        const detail = e.detail;
        const data = detail.data;

        if (type === "add") {
            setSelectedActivities([...selectedActivities, data]);
            dispatch({ type: "SET_SELECTED_ACTIVITIES", payload: selectedActivities });
        }
        else if (type === "remove") {
            setSelectedActivities(selectedActivities.filter(item => item.id !== data.id));
            dispatch({ type: "SET_SELECTED_ACTIVITIES", payload: selectedActivities });
        }
    }

    const openActivityDetail = (activityId) => {

        const selectedActivity = props.activities.find(item => item.id == activityId) || activityData.activity;

        setIsLoading(true);
        ApiClient.post("Workplan/GetActivityDetails", { id: activityId })
            .then(response => {
                setIsLoading(false);
                const activity = response.data;
                const type = props.type;
                WorkplanHelper.mapActivity(activity, selectedActivity, type);
                dispatch({ type: "SET_ACTIVITY", payload: activity });
                const teamVerificationLogs = ActivityInboxHelper.mapTeamLogs(response.data.verificationLogs?.teamVerificationLogs);
                const resourceVerificationLogs = ActivityInboxHelper.mapResourceLogs(response.data.verificationLogs?.resourceVerificationLogs);
                const teamInfo = response.data.verificationLogs?.teamInfo || {};
                teamInfo.status = teamVerificationLogs.length > 0 ? !teamVerificationLogs.some(item => !item.validStatus) : null;
                teamInfo.verificationSessionId = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].verificationSessionId : "";
                teamInfo.isOffsiteVerification = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].isOffsiteVerification : null;
                teamInfo.resourceStatus = resourceVerificationLogs.length > 0 ? !resourceVerificationLogs.some(item => !item.status) : null;
                const teamsLogInfo = { teamInfo, teamVerificationLogs, resourceVerificationLogs };
                dispatch({ type: "SET_TEAMS_LOG_INFO", payload: teamsLogInfo });
                dispatch({ type: "CHANGE_ACTIVITY_MOD", payload: constants.pageMods.VIEW_ONLY });
                history.push("/activities");
            })
            .catch(error => {
                console.error(error);;
                setIsLoading(false);
            });

    }

    const openSessionDetail = (sessionId) => {
        setIsLoading(true);;
        ApiClient.post("Workplan/GetSessionDetails", { id: sessionId })
            .then(response => {
                setIsLoading(false);
                const session = response.data;
                const sessionRequest = { id: sessionId, name: session.name, viewOnly: true, status: 2, customerUsers: session.customerUsers, responsibleUsers: session.responsibleUsers };
                dispatch({ type: "SET_APPROVE_SESSION", payload: sessionRequest });
                history.push("/approve-session");
            })
            .catch(error => {
                console.error(error);;
                setIsLoading(false);
            });
    }

    const assignTeamLeader = () => {
        const _activities = [...selectedActivities];
        const Ids = _activities.map(item => item.id);
        if (!selectedTeamLeader || !selectedTeamLeader?.value) {
            const notification = new Notification({
                title: 'Please select a team leader to assign',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        let visitor = CommonHelper.getVisitorData();
        const logInfo = {
            userId: parseInt(userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: " assigned team leader: " + selectedTeamLeader.value
        }
        const request = {
            activities: _activities, teamLeader: { id: selectedTeamLeader?.value },
            logInfo: logInfo
        };
        setIsLoading(true);
        setOpenAssignDialog(false);
        ApiClient.post("Activity/AssignTeamLeader", request)
            .then(response => {
                setIsLoading(false);
                var response = response.data;
                if (response?.success) {
                    getActivities();
                    const notification = new Notification({
                        title: 'Team leader Assigned successfully.',
                        timeout: 5000,
                        icon: 'icon-check'
                    });
                    notification.init();
                }
                else {
                    const notification = new Notification({
                        title: 'Team leader Assignment.',
                        description: response.errorMessage ? response.errorMessage : 'Error occured please try again. ',
                        timeout: 150000,
                        icon: 'icon-triangle-warning'
                    });
                    notification.init();
                }
            }).
            catch(error => {
                setIsLoading(false);
                alert(error);
            });
    }

    const unAssignTeamLeader = () => {
        const _activities = [...selectedActivities];
        var closed = _activities.find(item => item.acceptanceStatusId == 2 || item.acceptanceStatusId == 3 || item.actualEnd);
        const logInfo = {
            userId: parseInt(userData.userData.userId),
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude,
        }
        if (closed) {
            const notification = new Notification({
                title: 'Closed activities cannot be assigned',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
        }
        else {
            setIsLoading(true);
            setOpenUnAssignDialog(false);
            const request = {
                activities: _activities,
                logInfo: logInfo
            };
            ApiClient.post("Activity/UnAssignTeamLeader", request)
                .then(response => {
                    setIsLoading(false);
                    if (response.data) {
                        getActivities();
                        const notification = new Notification({
                            title: 'Team leader un-assigned successfully.',
                            timeout: 5000,
                            icon: 'icon-check'
                        });
                        notification.init();
                    }
                })
        }

    }

    const openAssignTeamLeaderDialog = () => {
        const _activities = [...selectedActivities];
        if (!selectedActivities.length) {
            const notification = new Notification({
                title: 'Select at least one activity to assign.',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        if (!selectedActivities.every(item => item.aspCompanyId == selectedActivities[0].aspCompanyId)) {
            const sameLeaderNotification = new Notification({
                title: 'Only activities with same company can be assigned',
                timeout: 5000,
                icon: 'icon-cross'
            });
            sameLeaderNotification.init();
            return;
        }
        var closed = _activities?.find(item => item.acceptanceStatusId == 2 || item.acceptanceStatusId == 3 || item.actualEnd || item.erisiteStatus?.toLowerCase() == "deleted");
        if (closed) {
            const notification = new Notification({
                title: 'Closed and Erisite deleted activities cannot be assigned',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        setCompanyTeamLeaders(activityData.aspPmTeamLeaders.filter(item => item.aspCompanyId == selectedActivities[0].aspCompanyId));
        setOpenAssignDialog(true);
    }

    const openUnAssignTeamLeaderDialog = () => {
        const _activities = [...selectedActivities];
        if (!selectedActivities.length) {
            const notification = new Notification({
                title: 'Select at least one activity to assign.',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        var closed = _activities?.find(item => item.acceptanceStatusId == 2 || item.acceptanceStatusId == 3 || item.actualEnd || item.erisiteStatus?.toLowerCase() == "deleted");
        if (closed) {
            const notification = new Notification({
                title: 'Closed and Erisite deleted activities cannot be un-assigned',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        setOpenUnAssignDialog(true);
    }

    const getActivities = () => {
        const filter = activityData.filter;
        const filterTypes = [...activityData.aspPmFilterTypes];
        if (filterTypes?.includes("1")) {
            filter.assigned = true;
        } else {
            filter.assigned = false;

        } if (filterTypes?.includes("2")) {
            filter.unAssigned = true;
        }
        else {
            filter.unAssigned = false;

        } if (filterTypes?.includes("3")) {
            filter.closed = true;
        } else {
            filter.closed = false;

        }
        filter.userId = parseInt(userData.userData.userId);
        filter.id = parseInt(filter.id ?? "0");
        filter.sessionId = parseInt(filter.sessionId ?? "0");
        if (!filter.teamLeaderIds) {
            filter.teamLeaderIds = [];
        }

        setIsLoading(true)
        ApiClient.post("Activity/GetFilteredActivities", filter)
            .then(response => {
                if (response.data) {

                    dispatch({ type: "SET_FILTERED_ACTIVITIES", payload: { data: [], totalCount: 0 } });

                    const data = [...response.data.data];
                    WorkplanHelper.mapActivities(data);
                    setIsLoading(false)
                    dispatch({ type: "SET_FILTERED_ACTIVITIES", payload: { data, totalCount: response.data.totalCount } });

                    dispatch({ type: "CHANGE_INITIAL_STATE" });

                }

            })
            .catch(error => {
                console.error(error);;
                setIsLoading(false)
            });
    }

    useEffect(() => {
        PageHelper.getCoordinates();
        dispatch({ type: "SET_SELECTED_ACTIVITIES", payload: [] });


    }, []);

    var DD = new Date();
    const _fileName = "pm_activities_details" + DD.toISOString();

    const excelData = activities.map(a => {
        const newActivity = {
            Customer: a.customerName,
            Project: a.projectName,
            FASID: a.workplan?.wpAssignmentId,
            WorkplanName: a.workplanName,
            ErisiteWPID: a.erisiteWorkplanId,
            CustomerSiteID: a.workplan?.site?.customerSiteId,
            SiteName: a.siteName,
            ASPCompanyName: a.aspCompanyName,
            ASPTeamLeader: a.teamLeadName || "",
            ActivityID: a.id,
            ActivityName: a.name,
            GPOName: a.gpoName,
            SessionType: a.rscScope,
            PlannedStart: a.plannedStart,
            PlannedEnd: a.plannedEnd,
            ActualStart: a.actualStart,
            ActualCompleted: a.sentToRsc,
            Accepted: a.actualEnd,
            RSCInspectorName: a.rscPersonName ?? "",
            ActivityStatus: ActivityHelper.getActivityStatus(a.acceptanceStatusId),
            FTR: a.activityRatings?.find(item => item.question == 'FTR Status')?.ratingValue,
            NumberOfSnags: a.activityRatings?.find(item => item.question == 'Number Of Snags/ missing')?.ratingValue,
            OnTimeDelivery: (((a.actualEnd - a.actualStart) > (a.plannedEnd - a.plannedStart) || !a.actualEnd) ? "False" : "True"),
            QiIndex: a.activityRatings?.find(item => item.question == 'QI if Done')?.ratingValue,
            //SiteStatus: a.activityRatings?.find(item => item.question == 'Customer Acceptance Status')?.ratingValue,
        }
        return newActivity;
    })

    return (<EuiRow
        disabled={isLoading ? "disabled" : ""} key={isLoading || props.activities} >
        <EuiLoading id="activityToDetailLoading" isLoading={isLoading} />
        <ObseleteEuiTable
            classes="tiny split-striped"
            columns={activityColumns}
            data={activities}
            id="filteredActivitiesTableAspPm"
            viewActionButton={true}
            actionButton={
                <span>
                    <button id="assignActivityy"
                        style={{ marginLeft: 5 }}
                        className="btn primary m-1"
                        onClick={openAssignTeamLeaderDialog}> Assign Team Leader</button>
                    <button id="assignActivityy"
                        style={{ marginLeft: 5 }}
                        className="btn primary m-1"
                        onClick={openUnAssignTeamLeaderDialog}> Un-Assign Team Leader</button>
                </span>
            }
            filter={true}
            hideSearch={true}
            scroll={false}
            sortable={true}
            paginated={true}
            totalCount={props.activities.length}
            rowsPerPage={8}
            selectable='multi'
            selectRow={(e, type) => setSelectedActivitiesFun(e, type)}
            viewActionIcon={true}
            exportExcel={true}
            Reportdata={excelData}
            excelFileName={_fileName}

        />
        <DialogModal
            buttonName="Assign"
            open={openAssignDialog}
            customStyle={{ width: 500, height: 400 }}
            disableBackdropClick={true}
            // content={<EuiAutocompleteSelect id="teamLeadersNamesAssign" options={companyTeamLeaders} value={selectedTeamLeader || 0} onChange={(e) => setSelectedTeamLeader(e.target.value)} label={{ "title": "Select Team Leader", "className": "color-gray" }} />}
            content={<TeamLeadersListDialog id="teamLeadersNamesAssign" teamLeaders={companyTeamLeaders} />}
            id="assignTeamLeaderDialog"
            handleClose={() => setOpenAssignDialog(false)}
            onClick={assignTeamLeader}
            title="Assign Team Leader"
            type="primary"
            contentStyle={{ height: "68%" }}
        />
        <DialogModal
            buttonName="Un-Assign"
            open={openUnAssignDialog}
            disableBackdropClick={true}
            content={<div>Are you sure you want to un-assign the selected activities? </div>}
            id="assignTeamLeaderDialog"
            handleClose={() => setOpenUnAssignDialog(false)}
            onClick={unAssignTeamLeader}
            title="Assign Team Leader"
            type="primary"
            mainButtonStyle={{ backgroundColor: 'red' }}
        />

    </EuiRow >);

}

export default AspPmActivities;