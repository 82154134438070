import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import constants from "../../utils/constants";
import PageHelper from "../../utils/page-helper";
import DateUtils from "../../utils/date-utils";
import { withRouter } from "react-router-dom";
import ApiClient from "../../utils/api-client";
import EuiLoading from "../../eui-components/loading";


class SiteList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            siteColumns: [{
                title: "System ID",
                onCreatedCell: (td, cellData) => {
                    td.innerHTML = `<a id="site_${cellData}">${cellData}</a>`;
                    td.querySelector("#site_" + cellData).addEventListener('click', () => this.openSiteDetail(cellData))
                },
                key: "id",
                sort: "asc",
                width: "10%"
            },
            {
                title: "Site Code",
                key: "code",
                sort: "none",
                width: "20%"
            },
            {
                title: "Site Name",
                key: "name",
                sort: "none",
                width: "10%"
            },
            {
                title: "Customer Name",
                key: "customerName",
                sort: "none",
                width: "10%"
            },
            {
                title: "Customer Site ID",
                key: "customerSiteId",
                sort: "none",
                width: "10%"
            },
            {
                title: "Coordinates",
                key: "coordinates",
                sort: "none",
                width: "10%"
            },
            {
                title: "Owner Details",
                key: "ownerDetails",
                sort: "none",
                width: "10%"
            },
            ],
            isLoading: false
        }
    }

    componentDidMount() {
    }

    openSiteDetail = (siteId) => {
        const selectedSite = this.props.filteredSites.data.find(item => item.id == siteId);
        this.props.setSite(selectedSite);
        this.props.changeSiteMod(constants.pageMods.VIEW_ONLY);
    }

    render() {
        const filteredSites = this.props.filteredSites;
        return (<EuiRow disabled={this.state.isLoading ? "disabled" : ""}>
            <EuiLoading id="sitesToDetailLoading" isLoading={this.state.isLoading} />
            <ObseleteEuiTable
                classes="tiny split-striped"
                columns={this.state.siteColumns}
                data={filteredSites.data}
                id="sitesListTableId"
                scroll={true}
                sortable={true}
                paginated={true}
                totalCount={filteredSites.totalCount}
                rowsPerPage={10}
            >
            </ObseleteEuiTable>
        </EuiRow >
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, siteData: state.site });
const mapDispatchToProps = dispatch => {
    return {
        setSite: (site) => dispatch({ type: "SET_SITE", payload: site }),
        changeSiteMod: (mod) => dispatch({ type: "CHANGE_SITE_MOD", payload: mod }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SiteList)); 