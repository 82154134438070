import React, { useEffect, useState } from "react"
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useCallback } from "react";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import EuiSwitch from "../../eui-components/switch";
import EuiTooltip from "../../eui-components/tooltip";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import ActivityHelper from "../../utils/activity-helper";
import ApiClient from "../../utils/api-client";
import CommonHelper from "../../utils/common-helper";
import constants from "../../utils/constants";
import PageHelper from "../../utils/page-helper";

const ActiveActivitySessions = forwardRef((props, ref) => {

    const [activeActivityColumns, setColumns] = useState([
        {
            title: "Activity ID",
            key: "id",
            sort: "none",
            width: "10%",
        },
        {
            title: "Name",
            key: "name",
            sort: "none",
            width: "10%"
        },
        {
            title: "Customer",
            key: "customerName",
            sort: "none",
            width: "15%"
        },
        {
            title: "Workplan",
            key: "workplanName",
            sort: "none",
            width: "15%"
        },
        {
            title: "ASP Company | (Team Leader | EHS Auditor)",
            key: "aspCompanyAndTeamLead",
            sort: "none",
            width: "15%"
        },
        {
            title: "RSC QA Supervisor",
            key: "rscPersonName",
            sort: "none",
            width: "10%"
        },
        {
            title: "Sent To RSC Date",
            key: "sentToRSC",
            onCreatedCell: (td, cellData) => CommonHelper.renderTableDate(td, cellData),
            sort: "desc",
            width: "10%"
        },
        {
            title: "Status",
            key: "acceptanceStatusId",
            onCreatedCell: (td, cellData) => {
                const status = PageHelper.renderActivityStatus(cellData);
                td.innerHTML = `${status}`;
            },
            width: "5%",
            sort: "none",
        },
    ]);

    const [activeActivities, setActiveActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showOnlyNotAssignedActivities, changeFilter] = useState(false);

    useEffect(() => {
        setTimeout(() => getActiveActivities({}), 200);
    }, []);

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => setIsLoading(false), 100);
    }, [props.selectedRscCenter]);

    useImperativeHandle(ref, () => ({
        getActiveActivities() {
            getActiveActivities({});
        }
    }));

    const getActiveActivities = useCallback((payload) => {
        setTimeout(() => setIsLoading(true), 100);
        ApiClient.post("Activity/GetActiveQAActivities",payload).then(response => {
            const activities = [...response.data];
            ActivityHelper.mapActiveSessions(activities);
            setActiveActivities(activities);
            setIsLoading(false);
        }).catch(error => {
            console.error(error);;
            CommonHelper.createNotification({ title: constants.errorMessage, icon: "cross" });
            setIsLoading(false);
        });
    }, []);

    const getActionButtons = () => {
        return <>
            <button className={"btn primary mr-2 " + (props.selectedSessions.length === 0 ? "disabled" : "")}
                style={{ marginRight: 10 }}
                onClick={() => props.toggleUnAssignDialog({ open: true, type: ActivityHelper.sessionUnAssignDialogContents.ACTIVITY })}>Un-Assign
            </button>
            <button className={"btn primary mr-2 " + (props.selectedSessions.length === 0 ? "disabled" : "")}
                style={{ marginRight: 10 }}
                onClick={() => props.toggleAssignDialog({ open: true, type: ActivityHelper.sessionDialogContents.ACTIVITY })}>Re-Assign
            </button>
            <EuiTooltip title="Refresh" position="top-start">
                <i className="icon icon-reload icon-lg icon-frame m-1" onClick={() => getActiveActivities()}></i>
            </EuiTooltip>
        </>
    }

    const getActiveActivitiesFiltered = useCallback(() => {
        return showOnlyNotAssignedActivities ? activeActivities.filter(a => a.rscCenter === props.selectedRscCenter && !a.rscPersonName)
            : activeActivities.filter(a => a.rscCenter === props.selectedRscCenter);
    }, [activeActivities, showOnlyNotAssignedActivities, props.selectedRscCenter, isLoading])
  const onNextClick = () => {
    getActiveActivities({})
  }
    return <EuiRow disabled={isLoading ? "disabled" : ""} key={isLoading}>
        <EuiLoading isLoading={isLoading} />
        <ObseleteEuiTable
            classes="tiny split-striped"
            columns={activeActivityColumns}
            data={getActiveActivitiesFiltered()}
            id="activeActivityListTableId"
            viewActionButton={true}
            actionButton={getActionButtons()}
            showNext = {false}
            onNextClick = {onNextClick}
            paginated={true}
            rowsPerPage={10}
            selectable="multi"
            selectRow={(e, type) => props.setSelectedSessions(e, type)}
            filter={true}
            hideSearch={true}
            viewActionIcon={true}
            actionIcon={
                <span className="ml-2">
                    <EuiSwitch
                        id="filterRscActivitiesSwitch"
                        onChange={() => { setIsLoading(true); changeFilter(!showOnlyNotAssignedActivities); setTimeout(() => setIsLoading(false), 1000)}}
                        checked={showOnlyNotAssignedActivities}
                        dataEnabled="Not Assigned Activities"
                        dataDisabled="All Activities"
                        name="rscActivitiesFiltered"
                    />
                </span>
            }
        />
    </EuiRow>
});

export default ActiveActivitySessions;