import React, { useEffect, useCallback, useState } from "react";
import VehicleHelper from '../../utils/vehicle-helper';
import { useDispatch, useSelector } from "react-redux";
import constants from '../../utils/constants';
import ObseleteEuiTable from '../../eui-components/obseleteTable';
import ApiClient from '../../utils/api-client';
import CommonHelper from '../../utils/common-helper';
import DateUtils from "../../utils/date-utils";
import UserHelper from "../../utils/user-helper";

const VehicleInspectionList = (props) => {
    const users = useSelector(state => state.user.users);
    useEffect(() => {
        ApiClient.post("Vehicle/GetChecklistItems")
            .then(response => {
                dispatch({ type: "SET_INSPECTION_ITEMS_LIST", payload: response.data.checklistItems });
            })
            .catch(error => {
                console.error(error);;
                //changeIsLoading(false);
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
            });

    }, []);


    const getVehicleList = useCallback((vehicleId) => {
        const request={
            id:vehicleId
        }
            ApiClient.post("Vehicle/GetVehicle", {...request} )
                .then(response => {
                    const  vehicles=response.data
                    dispatch({ type: "SET_VEHICLE_LIST", payload: vehicles });
                    dispatch({ type: "SET_CURRENT_VEHICLE", payload:vehicles });
                }).catch(error => {
                    console.error(error);;
                });
        
    }, []);

    const dispatch = useDispatch();
    var inspection = useSelector(state => state.vehicleInspection);
    const columns = [
        {
            title: "Inspection ID",
            key: "id",
            width: "7%",
        },
        {
            title: "Name",
            key: "inspectionName",
            sort: "none",
            width: "10%"
        },
        {
            title: "Chassis Number",
            key: "chassisNumber",
            width: "5%"
        }, {
            title: "Country",
            key: "country",
            width: "6%"
        },
        {
            title: "ASP Company",
            key: "aspCompany",
            width: "6%"
        },
        {
            title: "Inspector name",
            key: "submittedUserId",
            width: "6%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var user = UserHelper.getUser(users, cellData);
                    
                    
                    td.innerHTML = user?.name ?? ""
                }
            }
        },
        {
            title: "Submit Date",
            key: "submitDateTime",
            width: "5%",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm:ss")
            }
        },
        {
            title: "Status",
            key: "statusId",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                
                const inspectionStatus = VehicleHelper.inspectionHTMLTableStatus[cellData];
                td.innerHTML = inspectionStatus;
            },
            width: "6%",
            notSearchable: true
        }, 
        // {
        //     title: "Review Date",
        //     key: "approveRejectDateTime",
        //     width: "6%",
        //     onCreatedCell: (td, cellData) => {
        //         td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm:ss")
        //     }
            
        // },
        // {
        //     title: "EHS SME",
        //     key: "smeUserId",
        //     width: "6%",
        //     onCreatedCell: (td, cellData) => {
        //         if (cellData) {
        //             var user = UserHelper.getUser(users, cellData);
                    
                    
        //             td.innerHTML = user?.name ?? ""
        //         }
        //     }
        // },
        {
            title: "",
            key: "id",
            hideFilter: true,
            notSearchable: true,
            width: "2%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const action = `<span class="tooltip"><i id="inspection_view_${cellData?.id}" style="font-weight: bold" class="icon icon-eye color-blue clickable p-1 text-lg"></i><span class="message top-end">Approve/ Reject Inspection</span></span>`
                    td.innerHTML = `${action}`;
                    td.querySelector("#inspection_view_" + cellData.id).addEventListener('click', () => {
                        openInspectionDetail(cellData, constants.pageMods.EDIT);
                    });
                }
                else
                    td.innerHTML = "";
            }
        }
    ];
    const openInspectionDetail = useCallback((inspectionId, mod) => {
        const id = inspectionId;
        let currentInspection = { ...inspection.currentInspection };
        currentInspection.id = inspectionId;
        const name = props.data.find(i => i.id === inspectionId).inspectionName;
        const vehicleId = props.data.find(i => i.id === inspectionId).vehicleId;
        getVehicleList(vehicleId)
        currentInspection.inspectionName = name;
            ApiClient.post("Vehicle/GetVehicleInspectionDetails", { id })
                .then(response => {
                    if (response.data) {
                        currentInspection.currentInspectionItems = response.data;
                        const inspection = {...currentInspection , currentInspectionItems: response.data,inspectionId: inspectionId}

                        dispatch({ type: "SET_CURRENT_INSPECTION", payload: inspection });
                        dispatch({ type: "CHANGE_INSPECTION_MOD", payload: mod });

                    } 
                })
                .catch(error => {
                    console.error(error);;
                    CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
                });
    });
    const renderTable = (
        // eslint-disable-next-line react/jsx-pascal-case
        <ObseleteEuiTable
            key={props.data}
            classes="tiny"
            columns={columns}
            data={props.data}
            exportExcel={true}
            excelFileName={"inspections_List" + new Date().toISOString()}
            Reportdata={props.data}
            id={props.id}
            sortable={true}
            paginated={true}
            filter={true}
            rowsPerPage={10}
            searchStyle={{ width: 300 }}
            viewActionButton={true}
            search={true}
            placeholder="Search inspections..."
            // eslint-disable-next-line react/jsx-no-duplicate-props
            viewActionIcon={true}>
        </ObseleteEuiTable>
    )
    return (
        renderTable
    )
}
export default VehicleInspectionList;