import React from "react";
import { Wizard } from "@eds/vanilla/wizard/Wizard";

export default class EuiFooter extends React.Component {
    componentDidMount() {
        const elements = document.querySelectorAll('.wizard');
        if (elements) {
            Array.from(elements).forEach(element => {
                const component = new Wizard(element);
                component.init();
            });
        }
    }

    render() {
        return (
            <div className="wizard-footer" style={{ ...this.props.style }}>
                <div className="item" >
                    {this.props.buttonsLeft && this.props.buttonsLeft.map(o => {
                        if (o.display) {
                            return;
                        }
                        return <button key={o.id + "key"}
                            className={o.type}
                            type={o.buttonType}
                            id={o.id}
                            onClick={o.onClick}
                            style={{ minWidth: 135, margin: 5, ...o.style }}
                        >{o.name}
                        </button>
                    })
                    }
                </div>
                <div className="item flex-middle-content" style={{ width:"50%", margin: 10}}>
                    {this.props.infoPart}
                </div>
                <div className="item">                   
                    {this.props.buttons && this.props.buttons.map(o => {
                        if (o.display) {
                            return;
                        }
                        return <button key={o.id + "key"}
                            className={o.type}
                            type={o.buttonType}
                            id={o.id}
                            disabled={o.disabled}
                            onClick={o.onClick}
                            style={{ minWidth: 135, margin: 5, ...o.style }}
                        >{o.name}
                        </button>
                    })
                        }                       
                </div>
            </div>
        );
    }
}

