import React, { useState, useEffect } from 'react';
import EuiRow from '../../eui-components/row';
import { useDispatch, useSelector } from "react-redux";
import EuiTextField from '../../eui-components/textfield';
import EuiTile from '../../eui-components/tile';
import DateUtils from '../../utils/date-utils';
import WorkplanHelper from '../../utils/workplan-helper';
import EuiAccordion from '../../eui-components/accordion';
import ActivityDetailsUDFCategories from './ActivityDetailsUDFCategories';

const ErisiteUpdateActivity = (props) => {

    const activity = useSelector(state => state.activity.activity);
    const [listErist, setListErist] = useState([])
    const [listEritop, setListEritop] = useState([])


    useEffect(() => {
        if(activity.highRiskItemConfig)
        {
            const listEristD=[]
            const listEritopD=[]
                const entries = Object.entries(JSON.parse(activity.highRiskItemConfig));
                const keys = Object.keys(JSON.parse(activity.highRiskItemConfig));
                const values = Object.values(JSON.parse(activity.highRiskItemConfig));
                entries.forEach(function (ele, i) {
                    listEristD.push(keys[i]);
                    listEritopD.push(values[i]);
                });
                setListErist(listEristD)
                setListEritop(listEritopD)
        }
      }, [activity.highRiskItemConfig]);

    return (<EuiRow>
        <EuiTile title={props.title} sm="12" md="6" lg="6" xl="6" headerStyle={{ paddingBottom: 10 }} tileStyle={{ padding: 5 }}>
            <EuiTile title="Session Type" titleStyle={{ fontSize: 14 }} tileStyle={{ padding: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="erisiteRscScope" className="w-10" readOnly fullwidth value={props.erisiteActivity?.sessionType || "(Not defined)"}></EuiTextField>
                <i className="icon icon-arrow-right m-1 icon-lg"></i>
            </EuiTile>
            <EuiTile title="Assigned Group" titleStyle={{ fontSize: 14 }} tileStyle={{ padding: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="erisiteAssignedGroup" className="w-10" readOnly fullwidth value={props.erisiteActivity?.assignedWorkgroup || "(Not defined)"}></EuiTextField>
                <i className="icon icon-arrow-right m-1 icon-lg"></i>
            </EuiTile>
            <EuiTile title="Assigned Individual" titleStyle={{ fontSize: 14 }} tileStyle={{ padding: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="erisiteAssignedIndividual" className="w-10" readOnly fullwidth value={WorkplanHelper.getAssignedIndividual(props.erisiteActivity?.assigned)}></EuiTextField>
                <i className="icon icon-arrow-right m-1 icon-lg"></i>
            </EuiTile>
            <EuiTile title="Forecast Start DateTime" titleStyle={{ fontSize: 14 }} tileStyle={{ padding: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="erisiteforecastStartDate" className="w-10" readOnly fullwidth
                    value={(DateUtils.toDateString(props.erisiteActivity?.forecastStartDate, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)") + " Local "}></EuiTextField>
                <i className="icon icon-arrow-right m-1 icon-lg"></i>
            </EuiTile>
            <EuiTile title="Forecast End DateTime" titleStyle={{ fontSize: 14 }} tileStyle={{ padding: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="erisiteforecastEndDate" className="w-10" readOnly fullwidth
                    value={(DateUtils.toDateString(props.erisiteActivity?.forecastEndDate, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)") + " Local "}></EuiTextField>
                <i className="icon icon-arrow-right m-1 icon-lg"></i>
            </EuiTile>
            <EuiTile title="Actual Start DateTime" titleStyle={{ fontSize: 14 }} tileStyle={{ padding: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="erisiteActualStartDate" className="w-10" readOnly fullwidth
                    value={(DateUtils.toDateString(props.erisiteActivity?.actualStartDate, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)") + " Local "}></EuiTextField>
                <i className="icon icon-arrow-right m-1 icon-lg"></i>
            </EuiTile>
            <EuiTile title="Actual End DateTime" titleStyle={{ fontSize: 14 }} tileStyle={{ padding: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="erisiteActualEndDate" className="w-10" readOnly fullwidth
                    value={(DateUtils.toDateString(props.erisiteActivity?.actualEndDate, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)") + " Local "}></EuiTextField>
                <i className="icon icon-arrow-right m-1 icon-lg"></i>
            </EuiTile>
            <EuiTile title="Status" titleStyle={{ fontSize: 14 }} tileStyle={{ padding: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="erisiteStatus" className="w-10" readOnly fullwidth value={props.erisiteActivity?.status}></EuiTextField>
                <i className="icon icon-arrow-right m-1 icon-lg"></i>
            </EuiTile>
        </EuiTile>
        <EuiTile title="Eritop" sm="12" md="6" lg="6" xl="6" headerStyle={{ paddingBottom: 10 }} tileStyle={{ padding: 5 }}>
            <EuiTile title=" " tileStyle={{ padding: 5, marginTop: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="eritopRscScope" className="w-10" readOnly fullwidth value={activity.rscScope || "(Not defined)"} ></EuiTextField>
            </EuiTile>
            <EuiTile title=" " tileStyle={{ padding: 5, marginTop: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="eritopAssignedGroup" className="w-10" readOnly fullwidth value={activity.assignedGroup || "(Not defined)"}></EuiTextField>
            </EuiTile>
            <EuiTile title=" " tileStyle={{ padding: 5, marginTop: 5, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="eritopAssignedIndividual" className="w-10" readOnly fullwidth value={activity.assignedIndividual || "(Not defined)"}></EuiTextField>
            </EuiTile>
            <EuiTile title="" tileStyle={{ padding: 5, marginTop: 18, width: "100%" }} headerStyle={{ paddingBottom: 0 }} >
                <EuiTextField id="eritopForecastStartDate" className="w-10" readOnly fullwidth
                    style={{ padding: 0 }}
                    value={DateUtils.toDateString(activity.plannedStart, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"}></EuiTextField>
                <EuiTextField id="eritopForecastStartDateLocal" className="w-10" readOnly fullwidth
                    style={{ padding: 0 }}
                    value={DateUtils.toLocalDateString(activity.plannedStart) || "(-----,----)"}></EuiTextField>
            </EuiTile>
            <EuiTile title="" tileStyle={{ padding: 5, marginTop: 10, width: "100%" }} headerStyle={{ paddingBottom: 0 }} >
                <EuiTextField id="eritopForecastEndDate" className="w-10" readOnly fullwidth
                    style={{ padding: 0 }}
                    value={DateUtils.toDateString(activity.plannedEnd, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"}></EuiTextField>
                <EuiTextField id="eritopForecastEndDateLocal" className="w-10" readOnly fullwidth
                    style={{ padding: 0 }}
                    value={DateUtils.toLocalDateString(activity.plannedEnd) || "(-----,----)"}></EuiTextField>
            </EuiTile>
            <EuiTile title="" tileStyle={{ padding: 5, marginTop: 24, width: "100%" }} headerStyle={{ paddingBottom: 0 }} >               
                <EuiTextField id="eritopErisiteActualStartDateLocal" className="w-10" readOnly fullwidth
                    style={{ padding: 0 }}
                    value={(DateUtils.toDateString(activity.erisiteActualStart, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)" ) + " Local "}></EuiTextField>
            </EuiTile>
            <EuiTile title="" tileStyle={{ padding: 5, marginTop: 32, width: "100%" }} headerStyle={{ paddingBottom: 0 }} >                
                <EuiTextField id="eritopErisiteEndDateLocal" className="w-10" readOnly fullwidth
                    style={{ padding: 0 }}
                    value={(DateUtils.toDateString(activity.erisiteActualEnd, "YYYY-MM-DD HH:mm:ss", true)  || "(-----,----)") + " Local "}></EuiTextField>
            </EuiTile>
            <EuiTile title="" tileStyle={{ padding: 5, marginTop: 28, width: "100%" }} headerStyle={{ paddingBottom: 0 }} styleContent={{ display: "flex" }}>
                <EuiTextField id="eritopErisiteStatus" className="w-10" readOnly fullwidth value={activity.erisiteStatus}></EuiTextField>
            </EuiTile>
        </EuiTile>
         {/* UDF Categories */}
         <EuiTile title="" sm="12" md="12" lg="12" xl="12" headerStyle={{ paddingBottom: 10 }} tileStyle={{ padding: 5 }}>
         <EuiAccordion 
            id={"UDFCategoriesAccordion_Id"} 
            items={[
                {
                    title: "UDF Categories",
                    titleStyle: { fontWeight: 500 },
                    content: <ActivityDetailsUDFCategories listErist={listErist } listEritop={listEritop }/> 
             } ]}
            >
            </EuiAccordion>
         </EuiTile>
    
    </EuiRow>);

}

export default ErisiteUpdateActivity;
