import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import ApiClient from "../../../utils/api-client";
import EuiTabs from "../../../eui-components/tabs";
import GroupedDocuments from "../../../components/groupedDocuments";
import RecordingModal from "../../../components/recordingModal";
import DocumentDialogModal from "../../../components/documentDialogModal";
import SasHelper from "../../../utils/sas-helper";


class FourthStepContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,            
            openDialogPreview: false,
            selectedDocument: { id: 0 }
        }
    }

    deleteSelectedFiles = (data) => {
        const ids = data.map(item => item.id);
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Session/DeleteSessionDocuments", { ids, deleteUser: this.props.userData.userData.userId })
                .then(response => {
                    if (response.data) {
                        let newSessionSubactivityDocuments = [...this.props.sessionData.sessionSubActivities];
                        newSessionSubactivityDocuments.map(item => {
                            
                            
                            if (data.map(s => s.activityId).includes(item.activityId)) {
                                if (item.subActivities.length > 0) {
                                    item.subActivities.map(subItem => {
                                        
                                        if (data.map(s => s.subActivityId).includes(subItem.id)) {
                                            subItem.documents = [...subItem.documents.filter(document => !ids.includes(document.id))];
                                        }
                                    })
                                }
                            }
                        });
                        
                        this.setState({ isLoading: false }, () => {
                            this.props.setSessionSubActivities(newSessionSubactivityDocuments);
                            this.props.setSessionDocuments(newSessionSubactivityDocuments);
                            toast.success("Documents succesfully deleted.", { toastId: "documentDelete", containerId: 'D', position: toast.POSITION.TOP_CENTER });
                        });
                    } else {
                        this.setState({ isLoading: false }, () => toast.error("Some error occurs, Please try later.", { containerId: 'D', position: toast.POSITION.TOP_CENTER }));
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                })
        })
    }  

    render() {
        const activityDocuments = this.props.sessionData.sessionDocuments.length > 0 ?
            this.props.sessionData.sessionDocuments.filter(item => item.sessionType != 6)
                .map(item => item.subActivities).reduce((a, b) => { return a.concat(b); })
                .filter(subItem => ('tableData' in subItem) && ('checked' in subItem.tableData) && subItem.tableData.checked == true)
                .map(subDoc => subDoc.documents).reduce((a, b) => { return a.concat(b) })
            :
            [];
        const sessionDocuments = [...this.props.sessionData.sessionActivityDocuments];
        const contents = [
            {
                value: 1,
                name: "Activity Documents",
                selected: "selected",
                children: <GroupedDocuments title=""
                    documents={activityDocuments} actions={[{
                        tooltip: 'Delete All Selected Documents',
                        icon: () => <i className="icon icon-trashcan"></i>,
                        onClick: (evt, data) => this.deleteSelectedFiles(data),
                        hidden: this.props.sessionData.sessionInfo.viewOnly
                    }]} />
            },
            { value: 2, name: "Session Documents", selected: "", children: <GroupedDocuments title="" documents={sessionDocuments} /> },
            { value: 3, name: "Live Streaming Recordings", selected: "", children: <RecordingModal openDialog={(rowData) => this.setState({ openDialogPreview: true, selectedDocument: rowData })} data={this.props.liveStreamData.recordings} /> },
        ]
        return (
            <EuiRow>
                <EuiTabs
                    id="sessionDocumentsTab"
                    titles={contents.map(item => ({ value: item.value, name: item.name, selected: item.selected }))}
                    contents={contents}
                    titleStyle={{ fontSize: 15, padding: 10 }}
                    tabStyle={{ width: "100%" }}
                >
                </EuiTabs>

                <DocumentDialogModal openDialogPreview={this.state.openDialogPreview}
                    handleClose={() => this.setState({ openDialogPreview: false })}
                    id="sessionDocumentView"
                    showLocation={false}
                    selectedDocument={{ ...this.state.selectedDocument, documentPath: SasHelper.addSasKey(this.state.selectedDocument.recordPath), uploadDate: this.state.selectedDocument.endDatetime, type: 2 }}
                >
                </DocumentDialogModal>

                <ToastContainer enableMultiContainer containerId={'D'} />
            </EuiRow>
        );
    }
}
const mapStateToProps = state => ({ sessionData: state.session, userData: state.user, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
        setSessionSubActivities: (subActivities) => dispatch({ type: "SET_SESSION_SUB_ACTIVITIES", payload: subActivities }),
        setSessionDocuments: (documents) => dispatch({ type: "SET_SESSION_DOCUMENTS", payload: documents }),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FourthStepContent);
