import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../../layout";
import EHSPingList from "./ehsPingList";
import "../../../css/ehsInboxStyle.css"
import EuiTile from "../../../eui-components/tile";

class ehsPings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
    }

    render() {
        return (<EuiTile fullscreen={true} tileStyle={{ padding: 0 }}><EHSPingList /></EuiTile>);       
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ehsInboxData: state.ehsInbox });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(ehsPings)));