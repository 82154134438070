import React, { useMemo, useState, useEffect } from "react";
import HoverableButton from "../../../../components/Button/HoverableButton";
import constants from "../../../../utils/constants";
import { isEmpty, isUndefined } from "lodash";

export default function VehicleCategoryBox(props) {
  // Declare a new state variable, which we'll call "VehicleCategory"
  const {
    outerAccordionItems,
    setVehicleCategory,
    setIsOpenVehicleCategory,
    checklistItems,
    selectedCategoryId,
    inspectionCategory,
    selectedStatus,
    } = props;
  const [totalPhoto, setTotalPhoto] = useState(0);
  const [vehicleCategoryId, setVehicleCategoryId] = useState(1);

  useEffect(() => {
    if ((!!selectedCategoryId)&& !isUndefined(checklistItems)&&!isEmpty(outerAccordionItems)) {
            var foundEvidence = inspectionCategory?.find(function (i) {
        return i.id === selectedCategoryId;
    });
      const vehicleCategory= outerAccordionItems?.filter((i) => i.id.split('category').shift() === selectedCategoryId.toString())
      const countImg=  getTotalImagesPerItems(foundEvidence)
      setTotalPhoto(countImg)
      setVehicleCategory(vehicleCategory);

      setVehicleCategoryId(selectedCategoryId);
      setIsOpenVehicleCategory(!!selectedCategoryId);
     }
  }, [inspectionCategory,selectedCategoryId,selectedStatus]);

  const handleChecklistCategory= (item) => {
    const category= inspectionCategory?.filter((i) => i.id === item.id)[0]
    const sumImages=getTotalImagesPerItems(category)
    setVehicleCategory(item.outerAccordionItems);
    setIsOpenVehicleCategory(item.id===category.id);
    setTotalPhoto(sumImages);
    setVehicleCategoryId(item.id);
  }


  const getTotalImagesPerItems = (item) => {
    let sum=0
    if(!isUndefined(checklistItems)){
      const res = [...checklistItems];
      if(!isEmpty(item)&&!isUndefined(item)){
        const evidence = res?.filter((i) => i.categoryId ===item.id);
        for (var i = 0; i < evidence?.length; i++) {
          const countImg=  evidence[i]?.vehicleInspectionEvidences?.filter((newVal) => {
            return !selectedStatus || parseInt(newVal?.statusId) === selectedStatus; 
          });
          sum += !isUndefined(evidence[i]?.vehicleInspectionEvidences) &&
          countImg.length;
        }
      }
    }
 
    return sum;
  }
  const getTotalItemsOfInbox = (item) =>
  {
    const res= inspectionCategory?.map(el => 
      el.id===item.id &&
      <p style={{ color: item.id === vehicleCategoryId ? "#FFFFFF" : "" }}>
        { item.category }
        <br></br>
        { getTotalImagesPerItems(item) }
      </p>)
      return res;
  };

  const vehicleCategory = useMemo(() =>
  {
    const checklistCategory=!isUndefined(checklistItems)&& inspectionCategory?.map(item => 
          {
            const element = {
              id: item.id,
              name:item.category,
              outerAccordionItems:outerAccordionItems?.filter((i) => i.title ===item.category),
              checklist:!isUndefined(checklistItems)&& checklistItems?.filter((i) => i.categoryId ===  item.id ),
              title: getTotalItemsOfInbox(item),
              visible:item.id===4?[2, 3]: [2],
              visibleJobRole:item.id===3 && [constants.userRoles.ERICSSON_IM],
              onClick: (el) => {handleChecklistCategory(el); }
            }
            return element
         })
    return checklistCategory;
})
 
  return vehicleCategory?.map((item) => (
    <div className="column sm-12 md-2 filled">
      <HoverableButton
        disabled={false}
        onClick={() => item.onClick && item.onClick(item)}
        title={item.title}
        colorText={"white"}
        backgroundColor={"#1174E6"}
        selectedNumber={vehicleCategoryId === item.id? totalPhoto : 0}
        selected={vehicleCategoryId === item.id}
      ></HoverableButton>
    </div>
  ));
}
