import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ApiClient from "../../../utils/api-client";
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import { Logs } from "../../../utils/Log";
import VehicleHelper from "../../../utils/vehicle-helper";



class TwoFactorAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            verifyCode: "",
            isLoading: false,
            view: "form",
            check: "",
            error: "",
            sent: true,

        }
    }

    resendToken = () => {
        var randomString = require('random-string');
        var _TFAuthToken = randomString();
        ApiClient.post("User/SendTFAuthToken", { Id: parseInt(this.props.user.userData.userId), TwoFactorAuthToken: _TFAuthToken })
            .then(response => {
                if (response.data) {
                    toast.success("Another code is sent to your Email!", { position: toast.POSITION.TOP_CENTER });
                }
            })
    }

    verify = (event) => {
        this.setState({
            sent: true
        })
        let userId =  parseInt(this.props.user.userData.userId)
        ApiClient.post("User/ValidateTFAuthToken", { Id: userId, TwoFactorAuthToken: this.state.verifyCode })
            .then(response => {
                if (response.data) {

                    var userData = jwt_decode(this.props.user.token);
                    this.props.login({
                        userData,
                        token: this.props.user.token,
                        rememberMe: this.props.user.rememberMe,
                        loginRole: this.props.user.loginRole,
                        loggedIn: true

                    });
                    Logs({
                        userId: userId,
                        LogName: "Login",
                        LogAction: "Login",
                        LogObjectId: userId,
                        LogTypeId: VehicleHelper.LogTypeId.USERS,
                    })
                    this.setState({
                        view: "welcome", isLoading: false, error: "The code is expired!"
                    }, () => {
                        setTimeout(() => this.props.history.push("/"), 1000);
                    })

                }
                else {
                    this.setState({
                        error: "The code is expired!",
                    });
                }
            })
        event.preventDefault();
    }

    render() {
        return (
            <div className="signin">

                <div className="watermark">

                    <i className="icon icon-econ"></i>
                    <div className="product">Ericsson Eritop ASP</div>

                </div>
                <div
                    className={
                        "welcome " + (this.state.view === "welcome" ? "" : "hidden")
                    }
                >
                    <div className="message">Welcome</div>
                    <div className="username">{this.props.user.userData.username}</div>
                    <div className="loading" style={{ alignContent: "center" }}></div>
                </div>
                <form
                    className={this.state.view === "form" ? "" : "hidden"}
                    onSubmit={this.onSubmit}
                    style={{
                        alignContent: "center"
                    }}
                >
                    <div className={"error " + (this.state.error ? "" : "hidden")}>
                        {this.state.error}
                    </div>
                    <div className="message"
                        style={{ alignContent: "middle", fontSize: "12px", marginBottom: "10px" }}
                    >Please enter the verification code sent to your Email!</div>
                    <div className="field validate">
                        <input

                            name="Verification Code"
                            id="verificationCode"
                            placeholder="Verification Code"
                            tabIndex="0"
                            autoFocus
                            required
                            value={this.state.verifyCode}
                            onChange={e => this.setState({ verifyCode: e.target.value })}
                        />

                        <div className="hint"></div>
                    </div>
                    <button
                        className="btn big primary"
                        type="submit"
                        onClick={this.verify}
                    >
                        Verify
                    </button>
                    <div className={"error " + (this.state.status ? "" : "hidden")}>
                        {this.state.status}
                    </div>

                    <div className="help" hidden={this.state.sent}>
                        Another code is sent to your Email
                    </div>
                    <div className="help" style={{ height: "20px" }} >
                        <a

                            className="subtle-link"
                            onClick={this.resendToken}

                        >
                            Did not recieve the code? Send again
                        </a>
                    </div>
                </form>
            </div>
        );
    }
}
const FormField = (props) => (
    <div style={{ margin: 20, ...props.style }} className="field validate">
        {props.children}
    </div>
);
const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => {
    return {
        login: ({ userData, token, rememberMe, loginRole, loggedIn }) => dispatch({ type: "LOGIN", payload: { userData, token, rememberMe, loginRole, loggedIn } }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TwoFactorAuth));
