import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import { ToastContainer, toast } from 'react-toastify';
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import ActivityFilter from "./activityFilter";
import ActivityList from "./activityList";
import constants from "../../utils/constants";
import AddEditActivityDialog from "./AddEditActivityDialog";
import EuiDialog from "../../eui-components/dialog";
import ApiClient from "../../utils/api-client";
import EuiLoading from "../../eui-components/loading";
import { Notification } from '@eds/vanilla';
import WorkplanHelper from "../../utils/workplan-helper";
import DateUtils from "../../utils/date-utils";
import DialogModal from "../../components/dialog";
import PageHelper from "../../utils/page-helper";
import AspPmFilter from "./aspPmFilter";
import aspPmActivities from "../asp-pm-activities";
import AspPmActivities from "./aspPmActivities";
import EuiTag from "../../eui-components/tag";
import CommonHelper from "../../utils/common-helper";

class ActivitySearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            initialState: 0,
            Done: false,
            aspCompanies: [],
            teams: [],
            openDialogAdd: false
        }
    }

    componentDidMount() {
        PageHelper.getCoordinates();
        ApiClient.post("Asp/GetAllCompanies", {})
            .then(response => {
                this.setState({
                    aspCompanies: response.data
                });
            })
            .catch(error => {
                alert(error);
            });
    }

    changeInitialState = () => {
        this.setState({ initialState: 1 });
    }

    getFilterPills = () => {
        const pills = this.props.activityData.aspPmFilterTypes;
        return pills?.map(item => {
            const pill = constants.aspPmPills?.find(p => p.value + "" === item);
            return <EuiTag type="small"
                style={{ backgroundColor: pill?.color, borderRadius: 16, alignItems: "center" }} name={pill?.text}  ></EuiTag>
        })
    }

    getActivities = (id) => {
        const filter = {};
        filter.id = parseInt(id);
        filter.pageSize = 30;
        filter.page = 1;
        this.setState({ isLoading: true, Done: true }, () => {
            ApiClient.post("Workplan/GetFilteredActivities", filter)
                .then(response => {
                    this.props.setFilteredActivities({ data: [], totalCount: 0 });
                    const data = [...response.data.data];
                    WorkplanHelper.mapActivities(data);
                    this.setState({ isLoading: false, Done: true }, () => {
                        this.props.setFilteredActivities({ data, totalCount: response.data.totalCount });
                        this.changeInitialState();
                    });
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    AddActivity = () => {
        const activity = { ...this.props.activityData.activity }
        let visitor = CommonHelper.getVisitorData();
        activity.logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: Object.entries(activity).reduce((a, b) => {
                return a + b.join(" : ") + "    |    ";
            }, ""),
        };
        if (!activity['name'] || !activity['workplanModel'] || !activity['aspCompanyId'] || !activity['rscScope'] || !activity['gpoName']) {
            const notification = new Notification({
                title: 'Save Activity',
                description: 'All mandatory data need to be filled to save..',
                timeout: 5000,
                icon: 'icon-triangle-warning'
            });
            notification.init();
            this.setState({
                isLoading: false
            })
        }
        else {
            activity['workplanId'] = parseInt(activity.workplanModel.value);
            var today = Date.now();
            if (activity.plannedStart)
                activity['plannedStart'] = (activity.plannedStartTime ? DateUtils.toDateString((activity.plannedStart.split('T')[0] + "T" + activity.plannedStartTime), "YYYY-MM-DDTHH:mm", true) : DateUtils.toDateString((activity.plannedStart.split('T')[0]), "YYYY-MM-DDTHH:mm", true));
            if (!activity.plannedStart)
                activity['plannedStart'] = DateUtils.toDateString(today, "YYYY-MM-DDTHH:mm", true);
            if (activity.plannedEnd)
                activity['plannedEnd'] = (activity.plannedEndTime ? DateUtils.toDateString((activity.plannedEnd.split('T')[0] + "T" + activity.plannedEndTime), "YYYY-MM-DDTHH:mm", true) : DateUtils.toDateString((activity.plannedEnd.split('T')[0]), "YYYY-MM-DDTHH:mm", true));
            if (!activity.plannedEnd)
                activity['plannedEnd'] = DateUtils.toDateString(today, "YYYY-MM-DDTHH:mm", true);
            if (DateUtils.dateDifference(new Date(activity.plannedEnd), new Date(activity.plannedStart)) < 0) {
                const notification = new Notification({
                    title: 'Activity Data',
                    description: 'Planned end can not be before planned start',
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
            }
            else {
                this.setState({
                    isLoading: true,
                    Done: true,
                    openDialogAdd: false
                })
                ApiClient.post("WorkPlan/CreateUpdateActivity", activity)
                    .then(response => {
                        if (response.data.success) {
                            const notification = new Notification({
                                title: 'Save Activity',
                                description: 'Activity is being saved.',
                                timeout: 5000,
                                icon: 'icon-triangle-check'
                            });

                            notification.init();
                            this.setState({
                                isLoading: false,
                                Done: true
                            });
                            this.getActivities(response.data);
                        }
                        else {
                            this.setState({ isLoading: false });
                            const notification = new Notification({
                                title: 'Save Activity',
                                description: response.data.errorMessage ? response.data.errorMessage : 'Error occured please try again. ',
                                timeout: 150000,
                                icon: 'icon-triangle-warning'
                            });
                            notification.init();
                        }
                    })
                    .catch(error => {
                        this.setState({
                            isLoading: false,
                        })
                        alert(error);
                    })
            }
        }
    }

    render() {
        const leftPanel = {
            title: "Filter By:",
            content: (this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER ?
                <AspPmFilter changeInitialState={() => this.changeInitialState()} />
                :
                <ActivityFilter changeInitialState={() => this.changeInitialState()} />),
        };

        const activities = this.props.activityData.filteredActivities.data;
        const totalCount = this.props.activityData.filteredActivities.totalCount;

        const midPanel = {
            title:
                (this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER ?
                    <div className="flex-start-content "><div className="mr-2" >Filtered Activities</div> {this.getFilterPills()}</div>
                    : "Filtered Activities"),
            content:
                (this.state.initialState === 0 && activities.length === 0 ?
                    <div className="flex-start-content m-3 w-10">Please click on filter to get a list of activities</div> :
                    (this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER ?
                        <AspPmActivities id="filteredActivitiesTable" key={this.props.activityData.filteredActivities} activities={activities} totalCount={totalCount} type={constants.activityListTypes.ACTIVITY} height="530px" />
                        :
                        <ActivityList insideWorkplan={false} id="filteredActivitiesTable" key={this.props.activityData.filteredActivities} activities={activities} totalCount={totalCount} type={constants.activityListTypes.ACTIVITY} height="530px" />
                    ))
        };

        return (<EuiRow disabled={this.state.isLoading ? "disabled" : ""} key={this.state.isLoading} >
            <EuiLoading id="activityLoading" isLoading={this.state.isLoading} />
           {/*  <EuiMultiPanelTile id="activityMultiPanel"
                midPanelButton={true}
                button={<button id="addActivity"
                    style={{ width: 123 }}
                    disabled = {true}
                    hidden={this.props.userData.userData.jobRole === constants.userRoles.ADMIN || this.props.userData.userData.jobRole === constants.userRoles.ERICSSON_IM ? false : true}
                    onClick={() => { this.setState({ openDialogAdd: true }) }}
                    className="btn primary"><span><i className="icon icon-plus" />Add Activity</span></button>}
                className="fullscreen"
                leftPanel={leftPanel}
                leftPanelStyle={(this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER ? { width: "280px" } : null)}
                midPanel={midPanel}
                style={{ paddingRight: 23 }}
            />
            */}
            <EuiMultiPanelTile id="activityMultiPanel"
                midPanelButton={true}
                button={<button id="addActivity"
                    style={{ width: 123 }}
                    disabled = {true}
                    hidden={true}
                    onClick={() => { this.setState({ openDialogAdd: true }) }}
                    className="btn primary"><span><i className="icon icon-plus" />Add Activity</span></button>}
                className="fullscreen"
                leftPanel={leftPanel}
                leftPanelStyle={(this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER ? { width: "280px" } : null)}
                midPanel={midPanel}
                style={{ paddingRight: 23 }}
                />
            <DialogModal
                id="workplanAddActivityDialog"
                dataTrigger="#addActivityy"
                disableBackdropClick={true}
                title="Add Activity"
                content={<AddEditActivityDialog
                    aspCompanies={this.state.aspCompanies}
                    teams={this.state.teams}
                    id="activityDialogAdd"
                    NoWorkplan={true} />}
                buttonName="Add"
                type="primary"
                onCancel={this.cancel}
                contentStyle={{ height: "80%" }}
                onClick={this.AddActivity}
                customStyle={{ width: 450, height: 700 }}
                open={this.state.openDialogAdd}
                handleClose={() => this.setState({ openDialogAdd: false })}

            />
            <ToastContainer enableMultiContainer containerId={'AS'} autoClose={2500} />
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, activityData: state.activity });
const mapDispatchToProps = dispatch => {
    return {
        setActivity: (activity) => dispatch({ type: "SET_ACTIVITY", payload: activity }),
        changeActivityMod: (mod) => dispatch({ type: "CHANGE_ACTIVITY_MOD", payload: mod }),
        setApproveSession: (request) => dispatch({ type: "SET_APPROVE_SESSION", payload: request }),
        setFilteredActivities: (filteredActivities) => dispatch({ type: "SET_FILTERED_ACTIVITIES", payload: filteredActivities }),
        setWorkplan: (workplan) => dispatch({ type: "SET_WORKPLAN", payload: workplan }),
        changeMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setFilteredWorkplans: (filteredWorkplans) => dispatch({ type: "SET_FILTERED_WORKPLANS", payload: filteredWorkplans }),
        setAspPmFIlterTypes: (filterTypes) => dispatch({ type: "SET_ASP_PM_FILTER_TYPES", payload: filterTypes })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ActivitySearch));