import { Box, Grid, makeStyles } from "@material-ui/core";
import React, { useEffect, useState }  from "react";
import './styleButton.css';



const Buttons = ({ filterItem, setItem, menuItems,allEvidances }) =>
{

	const [statusId, setStatusId] = useState(2)
	const [statusColor, setStatusColor] = useState("#ccc")

	const useStyles = makeStyles({

		root: {
			fontSize:"14px",
			fontFamily:"system-ui",
			padding: "0.5em 1em",
			margin: "0.25em",
			borderRadius: "1em",
			border: "none",
			outline: "none",
			backgroundColor: "#dddddd",
			cursor: "pointer",
			':hover': { backgroundColor: 'blue' },
			// 💡 :link, :focus, etc. are also supported
	
			':not(.pill-selected)': { backgroundColor: '#fafafa' },
		},

		pill:{
			fontSize:"14px",
			fontFamily:"system-ui",
			padding: "0.5em 1em",
			margin: "0.25em",
			borderRadius: "1em",
			border: "none",
			outline: "none",
			backgroundColor:statusColor,
			cursor: "pointer",
			':not(.pill-selected)': {
				'&:hover': {
					backgroundColor: "#cccccc"
		
				},
		},
		'& .pill-selected': {
			backgroundColor: "#ff0055",
			color:" #ffffff"
		},
		},


	});
	useEffect(() => {
		if(statusId===2){
			setStatusColor("#36be46")
		}
		else if(statusId===3){
			setStatusColor("#cccccc")
		}else if(statusId===4){
			setStatusColor("#be7136")

		}
	
	}, [statusId])

	const classes = useStyles();
	return (
	<>
		{menuItems.map((Val, id) => {
			return (
				<div>
					<button
						className={"pill  pill-selected"}
						onClick={() =>{
							setStatusId(Val.id)
							filterItem(Val.id)
						}}
						key={id}
					>
						{Val.name} {Val.total}
					</button>
				</div>
						
			);
		})}
		<button
			//  btn-dark text-white p-1 px-3 mx-5 fw-bold btn
			className={"pill  pill-selected"}
			onClick={() => {setStatusId(0);filterItem(0)}}
		>
			All  {allEvidances.length}
		</button> 
	</>
	);
};
 
export default Buttons;