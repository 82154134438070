import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../eui-components/row";
import EuiTextField from "../../../eui-components/textfield";
import EuiAccordion from "../../../eui-components/accordion";
import Table from "../../../components/table";
import PageHelper from "../../../utils/page-helper";
import EuiSelect from "../../../eui-components/select";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import EHSInboxHelper from "../../../utils/ehs-inbox-helper";
import constants from "../../../utils/constants";
import { ToastContainer } from 'react-toastify';
import EuiCheckbox from "../../../eui-components/checkbox";
import EuiSwitch from "../../../eui-components/switch";
import Rater from "react-rater";
import EuiAutocompleteSelect from "../../../eui-components/select/autocompleteselect";
import { filter } from "lodash";
import SasHelper from "../../../utils/sas-helper";


class RateEhsPing extends React.Component {
    constructor(props) {
        super(props); 
        this.state = {
            isPassWithObservation: false,
            isChecked: false,
            counterLimit: 1000,
            ehsPingDocumentColumns: [
                {
                    title: "", field: "name", render: (rowData) => <div>{this.renderStatus(rowData)}<img src={SasHelper.addSasKey(rowData.posterPath,this.props.sasToken) } height="100px" width="100px" alt={rowData.name}></img></div>,
                    cellStyle: { fontSize: "0.8rem", width: "25%" }
                },
                {
                    title: "EHS Site Comment", field: "ohsSiteComment",
                    cellStyle: { fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "30%" }
                },
                {
                    title: "RSC Comment", field: "acceptanceComments",
                    cellStyle: { fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "30%" }
                },
            ],
            templateItemsColumns: [
                {
                    title: "", field: "severity",
                    render: (rowData) => <div className="flex-start-content">
                        {PageHelper.getSeverityIcon(rowData.severity)}
                        <div className="m-1">{rowData.severity} <a>{rowData.ohsItemNo}</a></div>
                    </div>,
                    cellStyle: { fontSize: "0.8rem", width: "10%" }
                },
                {
                    title: "Check Points", field: "checkPoints",
                    cellStyle: { fontSize: "0.8rem", width: "20%", whiteSpace: "pre-wrap" }
                },
                {
                    title: "Evidences Required", field: "evidencesRequired",
                    cellStyle: { fontSize: "0.8rem", width: "20%", whiteSpace: "pre-wrap" }
                },
                {
                    title: "Status", render: (rowData) => <EuiSelect
                        options={rowData.ratingOptions}
                        style={{ border: "none" }}
                        optionListStyle={{ position: "inherit" }}
                        value={rowData.score}
                        name="score"
                        id={"status" + rowData.id}
                        onChange={e => this.handleChange(e, 1)}
                        disabled={(this.props.ehsPing || this.props.ehsInboxData.currentEhsPing).viewOnly}
                    />,
                    cellStyle: { fontSize: "0.8rem", width: "10%" }
                },
                // {
                //     title: "Observations", 
                //         render: (rowData) => <EuiCheckbox 
                //             name="isObservation"
                //             className="m-1"
                //             id={"isObservation" + rowData.id}
                //             disabled={(this.props.ehsPing || this.props.ehsInboxData.currentEhsPing).viewOnly}
                //             label="observations"
                //             defaultValue={ (rowData?.isObservation||false)}
                //             refValue={(rowData?.isObservation||false)}
                //             onChange={(e) => this.handleChange(e, 1)}
                //             style={{ 
                //             justifyContent: "start",
                //              display:
                //              (this.props.ehsPing || this.props.ehsInboxData.currentEhsPing).checklists?.find(item => item.templateItems?.find(x=>x.id=== rowData.id)).templateItems?.find(y=>y.id=== rowData.id)?.isObservationVisible
                //               ?  "inline-block" : "none"
                //            }}
                //              />,
                //          cellStyle: { fontSize: "0.8rem", width: "18%" },
                // },
                {
                    title: "Comments", render: (rowData) => <textarea placeholder="Comments..."
                        readOnly={(this.props.ehsPing || this.props.ehsInboxData.currentEhsPing).viewOnly}
                        className="fullwidth"
                        name="additionalComments"
                        id={"ratingChecklistCommentEhs_" + rowData.id}
                        defaultValue={rowData.additionalComments || ""}
                        onBlur={e => this.handleChange(e, 1)}
                    />,
                    cellStyle: { fontSize: "0.8rem", width: "20%" }
                },     
                {
                    title: "Erisite Action", cellStyle: { fontSize: "0.8rem", width: "5%" },
                    render: (rowData) => EHSInboxHelper.getErisiteAction(rowData.integrationStatus),
                    hidden: this.props.ehsPing ? false : true
                },
                {
                    title: "Erisite Output", cellStyle: { fontSize: "0.8rem", width: "15%" },
                    field: "integrationOutput",
                    render: (rowData) => rowData.integrationStatus === null ? "No match with Erisite Checklist." : (rowData.integrationOutput || "No response from erisite."),
                    hidden: this.props.ehsPing ? false : true
                }
            ],
            approveExcludeSwitchRef: false,
            expandAllDetailPanel: true,
            selectedTemplateItem: { id: 0 },
        }
    }
    componentDidMount(){
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
    }
   
    componentDidUpdate(prevProps, prevState) {
        const ehsPing = this.props.ehsPing || this.props.ehsInboxData.currentEhsPing;

        if(ehsPing.status&&this.state.isPassWithObservation){
            ehsPing.conditionIsObservation="PASS WITH OBSERVATION"
        }else{
            ehsPing.conditionIsObservation=""
  
        }
 
        
      }

      verifiedIsPassWithObservation=(checklists,ehs)=>{

        const resFilter=checklists.map(item => {
            return filter(
                item.templateItems, function(o) {
                   return o.isObservation;
                }
            );
             
            })
            ///console.log("resFilter",resFilter)
            const res=resFilter.map(item => {

                if (Array.isArray(item) && item.length) {
                    ///console.log("resFilter item",item)

                    return{ isChecked:true}
                }
                else {
                    return {isChecked:false};
                }

            })
            const isPassWithObs=checklists.every(item => item.status) && res.filter(item => item.isChecked).length > 0
            this.setState({ isPassWithObservation:  isPassWithObs});
            if(isPassWithObs){
                ehs.conditionIsObservation="PASS WITH OBSERVATION"
            }
      }
    handleChange = (e, type) => {
        if (this.props.ehsPing && type === 3) {
            this.props.openViolationDetailDialog();
            return;
        }

        if (!this.props.ehsPing?.viewOnly) {
            const target = e.target;
            const value = target.value;
            const name = target.name;
            const ehs = { ...this.props.ehsInboxData.currentEhsPing };
            const approveExcludeSwitchRef = this.state.approveExcludeSwitchRef;

            if (type === 1) {
                const templateItem = { ...this.state.selectedTemplateItem };
                templateItem[name] = value;

                const checklists = [...(ehs.checklists || [])];
                if (checklists.length === 0) {
                    return;
                }
                const i = checklists.findIndex(item => item.id === templateItem.ohsChecklistId);// index of item in check 
                const templateItems = checklists[i] && [...checklists[i].templateItems];
                templateItems[templateItems.findIndex(item => item.id === templateItem.id)] = templateItem;
                checklists[i].templateItems = templateItems;        
                if (name === "score") {
                

                    checklists[i].score = templateItems.reduce((a, b) => a + b.score, 0);
                    checklists[i].status = !templateItems.some(t => t.isCritical && t.score === constants.ehsRatingOptions.FAIL);
                    checklists[i].sum = templateItems.reduce((a, b) => a + (b.score === constants.ehsRatingOptions.NA ? 0 : constants.ehsRatingOptions.PASS), 0);
                    ehs.ratingTotal = checklists.reduce((a, b) => a + b.score, 0);
                    ehs.ratingSum = checklists.reduce((a, b) => a + b.sum, 0);
                    ehs.status = checklists.every(item => item.status) && Math.round((ehs.ratingTotal / ehs.ratingSum) * 100) >= constants.ehsRatingLimit;
                    ehs.violation = ehs.status ? false : ehs.violation;
                    this.verifiedIsPassWithObservation(checklists,ehs)
                }
                    if(name === "isObservation"){

                        templateItem[name]=value
                        this.setState({ isChecked: value });
                         this.verifiedIsPassWithObservation(checklists,ehs)

                    }

                ehs.checklists = checklists;
            } else if (type === 2) {
                if (value?.length > this.state.counterLimit) {
                    alert("You can't add more than 1000 characters in the rating comments")
                }
                ehs.ratingComments = value;
                ehs.violationComments = value;
            } else if (type === 3) {
                ehs.violation = value;
            } else if (type === 4) {
                ehs.covid19 = value ? "1" : "0";
            } else if (type === 5) {
                ehs.behaviourRating = e.rating;
            } else if (type === 6) {
                ehs.mainIssue = value;
            } else if (type === 7) {
                ehs.highRiskWorkHold = value;
            }else if (type === 8) {
                if(this.isNFTPDisabled()){
                    ehs.nftpClassificationInsideEHS = 0;
                }else{
                    ehs.nftpClassificationInsideEHS = value;
                }
                 
            }

            
            if (name === "score" && approveExcludeSwitchRef) {
                this.setState({ approveExcludeSwitchRef: false }, () => {
                    this.props.setCurrentEhsPing(ehs);
                    this.setState({ approveExcludeSwitchRef: true });
                });
            } else {
                this.props.setCurrentEhsPing(ehs);
            }
            if (!ehs.violation) {
                if (ehs.plainEhsPing?.violation) {
                    ehs.violation = true;
                    this.props.setCurrentEhsPing(ehs);
                }
            }
        }
    }

    getTemplateItems = (checklist) => {
        if (!checklist || !checklist.templateItems || checklist.templateItems.length === 0) {
            return [];
        }

        if (this.state.approveExcludeSwitchRef) {
            return checklist.templateItems.filter(item => item.score !== constants.ehsRatingOptions.PASS);
        } else {
            ///console.log("checklist.templateItems",checklist.templateItems)
            return checklist.templateItems;
        }
    }

    renderChecklists = (ehsPing) => {
        if (!ehsPing ||
            !ehsPing.checklists ||
            ehsPing.length === 0
        ) {
            return null;
        }
        const checklists = [...ehsPing.checklists];
        const checklistAccordions = checklists.map(item => {
            const checklistItem = {
                id: "ehsRatingItem_" + item.id,
                title: EHSInboxHelper.getRatingChecklistName(item),
                titleStyle: { fontWeight: 700, padding: 3 },
                contentStyle: { paddingLeft: 0 },
                hidden: this.state.approveExcludeSwitchRef && item.templateItems.every(d => d.score === constants.ehsRatingOptions.PASS),
                opened: item.opened,
                content: <EuiRow>
                    {ehsPing.viewOnly ? null :
                        <EuiAccordion id={"ehsRatingImagesAccordion_" + item.id}
                            style={{ width: "100%" }}
                            items={[{
                                id: "ehsRatingimage_" + item.id,
                                title: <div className="flex-middle-content"><i className="icon icon-chevron-right text-lg m-1"></i>Show Images</div>,
                                titleStyle: {
                                    fontWeight: 700,
                                    paddingLeft: 20
                                },
                                contentStyle: { paddingLeft: 0 },
                                content: <Table columns={this.state.ehsPingDocumentColumns}
                                    container={{ style: { background: "inherit", paddingLeft: 20 } }}
                                    data={item.documents}
                                    options={{
                                        search: false,
                                        paging: false,
                                        toolbar: false,
                                        minBodyHeight: 100,
                                    }}
                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: 'No images to display',
                                        }
                                    }}>
                                </Table>
                            }]}
                        />
                    }
                    <Table columns={this.state.templateItemsColumns}
                        container={{ style: { background: "inherit", paddingLeft: 20 } }}
                        data={this.getTemplateItems(item)}
                        isLoading={this.props.isLoading}
                        options={{
                            search: false,
                            paging: false,
                            toolbar: false,
                            minBodyHeight: 100,
                        }}
                        onRowClick={(e, rowData) => this.setState({ selectedTemplateItem: rowData })}
                    >
                    </Table>
                </EuiRow>
            };
            return checklistItem;
        });

        return <EuiAccordion id="ehsRatingAccordion"
            style={{ width: "100%" }}
            items={checklistAccordions}
            noAutoClose={true}
        />;
    }


    renderStatus = (rowData) => {
        const color = PageHelper.renderActivityStatusColor(rowData.acceptanceStatusId);
        return <i className={"icon icon-alarm-level4 m-1 image-status-label color-" + color} > </i>
    }

    isViolationDisabled = () => {
        const ehsPing = this.props.ehsPing || this.props.ehsInboxData.currentEhsPing;
        if (this.props.detailPage) {
            return !(this.props.userData.userData.jobRole === constants.userRoles.RSC_OHS_INSPECTOR &&
                ehsPing.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED &&
                !ehsPing.violation);
        } else {
            return ehsPing.status || ehsPing.viewOnly || ehsPing.plainEhsPing.violation;
        }
    }

          
isNFTPDisabled=()=>{

    const ehsPing = this.props.ehsPing || this.props.ehsInboxData.currentEhsPing;

    const oldnftp = ehsPing.plainEhsPing?.nftpClassificationInsideEHS || ehsPing.nftpClassificationInsideEHS
 // 1. case : EHS Ping not rejected   ehsPing.acceptanceStatusId 
//  ehsPing.acceptanceStatusId !== constants.acceptanceStatusTypes.REJECTED
    if(!oldnftp) {
        if(ehsPing.status){
               // if session status PASS from first time it should be disabled with empty value there's no reason in this case to select 
               ehsPing.nftpClassificationInsideEHS = 0;
           return true;
        }else{
               // if Session status Fail from first time it should be active and mondatory to select the reason
           return false;
        } 
    }
  // 2. case : EHS Ping rejected
    else{
        return false;
    } 

}
    render() {
        const ehsPing = this.props.ehsPing || this.props.ehsInboxData.currentEhsPing;
        return <EuiRow>
            <EuiRow>
                <div className="w-6 p-1" style={{ borderRight: "1px solid #ebebeb" }}>
                    <pre className="small-info">EHS Pass Criteria:  All critical items should Pass and Score equal or greater than 70%</pre>
                    <label className="text-lg fw-2 required" htmlFor="ehsRatingCommentId">Overall Rating Comments</label>
                    <pre style={{ color: "red" }} className="small-info">1000 characters allowed only!</pre>
                    <textarea
                        required
                        maxLength="1000"
                        readOnly={ehsPing.viewOnly}
                        className="fullwidth required "
                        style={{ height: 100, border: (!ehsPing.ratingComments ? 'solid red 1px' : '') }}
                        id="ehsRatingCommentId"
                        name="ehsRatingComment"
                        defaultValue={ehsPing.ratingComments}
                        onBlur={e => this.handleChange(e, 2)} />
                </div>

                <div className="p-1" style={{ borderRight: "3px solid #ebebeb", width: "17%" }}>
                    <EuiTextField
                        className="w-10 m-1 text-lg"
                        fullwidth={true}
                        id="score"
                        name="ratingScore"
                        label="Overall Score"
                        labelstyle={{ fontWeight: 600, fontSize: 16 }}
                        style={{ fontSize: 25 }}
                        value={EHSInboxHelper.getTotalScore(ehsPing.ratingTotal, ehsPing.ratingSum)}
                        readOnly
                    ></EuiTextField>
                    <div className="flex-start-content m-1">
                        <EuiTextField
                            className="m-1 text-lg w-01"
                            id="ratingStatus"
                            name="ratingStatus"
                            label="Status"
                            labelstyle={{ fontWeight: 600, fontSize: 16 }}
                            style={{ fontSize: 25 }}
                            value={ehsPing.status ? "PASS" : "FAIL"}
                            readOnly
                        ></EuiTextField>
                        {
                            ehsPing.status ? <CheckCircleIcon fontSize="large" className="m-1 mt-5 color-green" />
                                : <CancelIcon fontSize="large" className="m-1 mt-5 color-red" />
                        }
                    </div>
                     { ehsPing.viewOnly?
                     
                        <div className="flex-start-content m-1">
                        <EuiTextField
                            className="m-1 text-lg w-01"
                            id="conditionStatus"
                            name="conditionStatus"
                            label="Condition"
                            labelstyle={{ fontWeight: 600, fontSize: 16 }}
                            style={{ fontSize: 14,color: "green" ,    width: 180}}
                            value={ ehsPing?.conditionIsObservation}

                            readOnly
                        ></EuiTextField>
                    </div> 
                     
                      :
                      this.state.isPassWithObservation&&ehsPing.status &&
                      <div className="flex-start-content m-1">
                        <EuiTextField
                            className="m-1 text-lg w-01"
                            id="conditionStatus"
                            name="conditionStatus"
                            label="Condition"
                            labelstyle={{ fontWeight: 600, fontSize: 16 }}
                            style={{ fontSize: 14,color: "green" ,    width: 180}}
                            value={ ehsPing?.conditionIsObservation}

                            readOnly
                        ></EuiTextField>
                    </div>   
                   }
            
                </div>

                <div className="w-2 p-1">
                    <EuiCheckbox name="isEhsViolation"
                        disabled={this.isViolationDisabled()}
                        className="m-1"
                        id="isEhsViolation"
                        label="Report EHS Violation"
                        refValue={(ehsPing.violation || ehsPing.plainEhsPing?.violation) ?? false}
                        onChange={e => this.handleChange(e, 3)}
                        style={{ justifyContent: "start" }} />

                    <EuiCheckbox name="ehsCovid19"
                        disabled={ehsPing.viewOnly}
                        id="ehsCovid19"
                        className="m-1"
                        label="Covid-19"
                        refValue={(ehsPing.covid19 || "0") === "0" ? false : true}
                        onChange={e => this.handleChange(e, 4)}
                        style={{ justifyContent: "start" }} />
                    <EuiCheckbox name="highRiskWorkHold"
                        disabled={ehsPing.viewOnly}
                        id="highRiskWorkHold"
                        className="m-1"
                        label="High Risk Work Hold"
                        refValue={ehsPing.highRiskWorkHold ?? false}
                        onChange={e => this.handleChange(e, 7)}
                        style={{ justifyContent: "start" }} />
                    <div className="flex-start-content">
                        <label className='required' style={{ marginRight: 5, marginLeft: 5, fontSize: 13 }}>Behaviour Rating </label>
                        <Rater total={5}
                            rating={ehsPing.behaviourRating || 5}
                            interactive={!ehsPing.viewOnly}
                            onRate={(event) => {
                                this.handleChange(event, 5);
                            }}
                        />
                    </div>
                    <label className="m-1 required" style={{ fontSize: 14 }} htmlFor="ehsMainIssueRating">Main Issue</label>
                    <EuiAutocompleteSelect
                        required
                        className="m-1"
                        placeholder="---Select EHS Main Issue---"
                        options={constants.ehsMainIssues}
                        value={ehsPing.mainIssue}
                        name="mainIssue"
                        id="ehsMainIssueRating"
                        onChange={e => this.handleChange(e, 6)}
                        disabled={(this.props.ehsPing || this.props.ehsInboxData.currentEhsPing).viewOnly}
                    />
                
                    <label className="m-1 required" style={{ fontSize: 14 }} htmlFor="nftpClassificationInsideEHS">NFTP Classification inside EHS session</label>
                    
                  {  this.isNFTPDisabled()?
                    <>
                  <EuiAutocompleteSelect
                            required
                            className="m-1"
                            placeholder="---Select NFTP Classification inside EHS session---"
                            options={[{ value: "0", text: "" }]}
                            value={0}
                            name="nftpClassificationInsideEHS"
                            id="nftpClassificationInsideEHS"
                            onChange={e => this.handleChange(e, 8)}
                            disabled={(this.props.ehsPing || this.props.ehsInboxData.currentEhsPing).viewOnly
                                || this.isNFTPDisabled()} /> 
                                <label className='m-1 mt-5 color-red' style={{ marginRight: 5, marginLeft: 5, fontSize: 11 }}>there's no NFTP to select once the status pass in the first review </label>
                                </>  
                    :
                    <EuiAutocompleteSelect
                        required
                        className="m-1"
                        placeholder="---Select NFTP Classification inside EHS session---"
                        options={constants.nftpClassificationInsideEHS}
                        value={ehsPing.nftpClassificationInsideEHS}
                        name="nftpClassificationInsideEHS"
                        id="nftpClassificationInsideEHS"
                        onChange={e => this.handleChange(e, 8)}
                        disabled={(this.props.ehsPing || this.props.ehsInboxData.currentEhsPing).viewOnly
                        }

                    />
                  }
                </div>
            </EuiRow>

            <EuiRow className="flex-start-content mb-2 mt-2">
                <EuiSwitch checked={this.state.approveExcludeSwitchRef}
                    name="approveExcludeSwitchRef"
                    onChange={(e) => this.setState({ approveExcludeSwitchRef: e.target.checked })}
                    dataEnabled="Excluding Passed Check points"
                    dataDisabled="Including Passed Check points"
                    className="p-1"
                    style={{ width: "25%" }}
                />
            </EuiRow>

            <EuiRow style={{ maxHeight: this.props.height || 390, overflow: "auto" }}>{this.renderChecklists(ehsPing)}
            </EuiRow>

            <ToastContainer enableMultiContainer containerId={'RE'} autoClose={2500} />
        </EuiRow>
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ehsInboxData: state.ehsInbox ,sasToken: state.complaints.sasToken});
const mapDispatchToProps = dispatch => {
    return {
        setCurrentEhsPing: (ehsPing) => dispatch({ type: "SET_CURRENT_EHS_PING", payload: ehsPing }),
        setEhsPageMod: (mod) => dispatch({ type: "SET_EHS_PAGE_MOD", payload: mod }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RateEhsPing);