import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiTextField from "../../eui-components/textfield";
import EuiTile from "../../eui-components/tile";
import EuiSwitch from "../../eui-components/switch";
import constants from "../../utils/constants";
import EuiTooltip from "../../eui-components/tooltip";
import CommonHelper from "../../utils/common-helper";
import UserAdminHelper from "../../utils/user-admin-helper";
import { toast } from "react-toastify";
import ApiClient from "../../utils/api-client";
import DateUtils from "../../utils/date-utils";
import ApiHelper from "../../utils/api-helper";
import EuiCheckbox from "../../eui-components/checkbox";

const UserResetPassword = (props) => {

    const users = useSelector(state => state.userAdmin.userList);
    const allUsers = useSelector(state => state.user.users);
    const currentUser = useSelector(state => state.userAdmin.currentUser);
    const loginRoles = useSelector(state => state.userAdmin?.currentUser?.roles);
    const mod = useSelector(state => state.userAdmin.mod);
    const [showPass, setShowPass] = useState(false);
    const [verified, setVerified] = useState(false);
    const dispatch = useDispatch();
    const [userServices, setUserServices] = useState([]);
    const [loginRolesState, setLoginRolesState] = useState([]);
    useEffect(() => {
        if (currentUser != null) {
            var loginRolesList = currentUser?.roles?.filter(loginRole => loginRole.active == true).map(i => { return parseInt(i.id) });
            setLoginRolesState(loginRolesList);
            if (loginRolesList != null && userServices?.length == 0 || loginRolesState != loginRoles) {
                ApiClient.post("UserAdmin/GetEmailServicesByLoginRoles", { loginRoles: loginRolesList }).then(response => {
                    if (response != null) {
                        
                        setUserServices(response.data);
                    }
                }).catch(err => console.error(err.response.data));
            }
        }
        if (props.isMyProfile) {
            ApiHelper.setAllUsers((users) => dispatch({ type: "SET_ALL_USERS", payload: users }));
        }
    }, [currentUser, loginRoles]);

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value || target.checked;
        const user = { ...currentUser };
        if (name.includes("Service")) {
            
            value == true ? user.emailSubscriptions.push({ userId: currentUser.id, serviceId: parseInt(name.split(' ')[1]) }) : user.emailSubscriptions = user.emailSubscriptions.filter(x => x.serviceId != parseInt(name.split(' ')[1]));
        }
        
        user[name] = value;
        dispatch({ type: "SET_CURRENT_USER", payload: user });
    }
    const handleEnter = (e) => {
        
        e.key === 'Enter' && verifyPassword();
    }

    const checkConfirmPassword = () => {
        if (!currentUser.newPassword || !CommonHelper.isValidPassword(currentUser.newPassword)) {
            return false;
        }
        return currentUser.newPassword === currentUser.confirmNewPassword;
    }

    const verifyPassword = () => {
        if (!currentUser.oldPassword) {
            toast.error("Please, enter your existing password.", { containerId: "UA", position: toast.POSITION.TOP_CENTER });
            return;
        }
        ApiClient.post("UserAdmin/VerifyPassword", { value: currentUser.id, text: currentUser.oldPassword })
            .then(response => {
                if (!response.data) {
                    toast.error("Password verification failed, please try another one.", { containerId: "UA", position: toast.POSITION.TOP_CENTER });
                }
                setVerified(response.data);
            }).catch(error => {
                console.error(error);;
                toast.error(constants.errorMessage, { containerId: "UA", position: toast.POSITION.TOP_CENTER });
            });
    }

    const renderServices = userServices.map((service, i) => {
        let serviceId = service.value;
        return <EuiSwitch
            id={serviceId}
            className="m-1 ml-2"
            disabled={mod === constants.pageMods.VIEW_ONLY || !props.isMyProfile}
            onChange={(e) => handleChange(e)}
            checked={currentUser.emailSubscriptions?.find(x => x.serviceId == serviceId) != null ? true : false}
            dataEnabled={service.text + " service"}
            dataDisabled={service.text + " service"}
            name={"Service " + serviceId}
        />
    });

    return (
        <div className="ml-1">
            {mod === constants.pageMods.ADD ? null :
                <EuiTextField className="p-1"
                    inputClassName={"validation password-security " + (verified ? "valid" : "")}
                    autoComplete="off"
                    fullwidth
                    readOnly={mod === constants.pageMods.VIEW_ONLY}
                    style={{ padding: 3 }}
                    labelstyle={{ fontWeight: 600 }}
                    id="userAdminOldPassword"
                    name="oldPassword"
                    label="Old Password"
                    placeholder="Please, verify your existing password."
                    value={currentUser.oldPassword || ""}
                    validationMessage={verified ? "Password verification successful." : "Password verification failed."}
                    onChange={(e) => handleChange(e)}
                    onKeyPress={handleEnter}
                    suffix={!currentUser.oldPassword ? null : <div className="suffix icon-inside">
                        <EuiTooltip title="Verify Password">
                            <i className="icon icon-check clickable" onClick={() => verifyPassword()}></i>
                        </EuiTooltip>
                    </div>}
                />
            }
            <EuiTextField className="p-1"
                inputClassName={(showPass ? "" : "password-security") + " validation " + (CommonHelper.isValidPassword(currentUser.newPassword) ? " valid " : "")}
                withIcon={true}
                autoComplete="off"
                fullwidth
                readOnly={mod === constants.pageMods.VIEW_ONLY || (mod === constants.pageMods.EDIT && !verified)}
                required={mod === constants.pageMods.ADD}
                style={{ padding: 3 }}
                labelstyle={{ fontWeight: 600 }}
                id="userNewPassword"
                name="newPassword"
                label="New Password"
                pattern="(?=.*\d)(?=.*[!@#$%^&*._])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                value={currentUser.newPassword || ""}
                validationMessage={CommonHelper.isValidPassword(currentUser.newPassword) ? "Valid Password" : UserAdminHelper.passwordCheckInfo}
                onChange={(e) => handleChange(e)}
                // suffix={<div className="suffix icon-inside" style={{ display: (!currentUser.newPassword ? "none" : "") }}>
                //     <EuiTooltip title="Show Password">
                //         <i className="icon icon-eye clickable" onClick={() => setShowPass(!showPass)}></i>
                //     </EuiTooltip>
                // </div>}
            />
            <EuiTextField className="p-1"
                inputClassName={"password-security validation " + (checkConfirmPassword() ? " valid " : "")}
                autoComplete="off"
                fullwidth
                readOnly={mod === constants.pageMods.VIEW_ONLY || (mod === constants.pageMods.EDIT && !verified)}
                required={mod === constants.pageMods.ADD}
                style={{ padding: 3 }}
                labelstyle={{ fontWeight: 600 }}
                id="userAdminConfirmNewPassword"
                name="confirmNewPassword"
                label="Confirm New Password"
                pattern="(?=.*\d)(?=.*[!@#$%^&*._])(?=.*[a-z])(?=.*[A-Z]).{8,}"
                validationMessage={(checkConfirmPassword() ? " Success Password " : " Confirmation password should be the same. ")}
                value={currentUser.confirmNewPassword || ""}
                onChange={(e) => handleChange(e)}
            />
            <EuiSwitch
                id="activePassiveUserSwitch"
                className="m-1"
                disabled={mod === constants.pageMods.VIEW_ONLY || props.isMyProfile}
                onChange={(e) => handleChange(e)}
                checked={currentUser.active}
                dataEnabled="Active"
                dataDisabled="Not Active"
                name="active"
                onChange={(e) => handleChange(e)}
            />
            {!currentUser.active ?
                <EuiTextField className="p-1"
                    fullwidth
                    multiLine={true}
                    readOnly={mod === constants.pageMods.VIEW_ONLY || props.isMyProfile}
                    style={{ padding: 3 }}
                    labelstyle={{ fontWeight: 600 }}
                    id="userAdminDeactivationReason"
                    placeholder="Please, indicate the reason of user deactivation."
                    name="deactivationReason"
                    label="Deactivation Reason"
                    value={currentUser.deactivationReason || ""}
                    onChange={(e) => handleChange(e)}
                />
                : null
            }
            {mod === constants.pageMods.ADD ? null :
                <>
                    <EuiTextField className="p-1"
                        fullwidth
                        readOnly={true}
                        style={{ padding: 3 }}
                        labelstyle={{ fontWeight: 600 }}
                        id="userAdminUpdateDate"
                        name="updatedAt"
                        label="Last Modified Date"
                        value={DateUtils.toDateString(currentUser.updatedAt, "YYYY-MM-DD HH:mm")}
                    />
                    <EuiTextField className="p-1"
                        fullwidth
                        readOnly={true}
                        style={{ padding: 3 }}
                        labelstyle={{ fontWeight: 600 }}
                        id="userAdminUpdateUser"
                        name="updatedUserId"
                        label="Last Modified User"
                        value={users.find(u => u.id === currentUser.updateUserId)?.name || allUsers?.find(u => u.value === currentUser.updateUserId)?.text}
                    />
                </>
            }
            <label style={{ fontSize: 14, fontWeight: 600, marginLeft: 5 }}>Email Service Subscription</label>
            {userServices.length != 0 ? renderServices : <></>}
        </div>
    );

}

export default UserResetPassword;