import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import ApiClient from '../../utils/api-client';
import constants from '../../utils/constants';
import { ToastContainer, toast } from 'react-toastify';
import WorkplanList from './workplanList';
import WorkplanFilter from './workplanFilter';
import { isEmpty } from 'lodash';
import EuiRow from '../../eui-components/row';
import EuiMultiPanelTile from '../../eui-components/multipaneltile';
import DialogModal from '../../components/dialog';
import WorkplanInfo from './workplanInfo';

function WorkplanSearch(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [openDialog, setOpenDialog] = useState(false)
    const [isDeletedbuttonClicked, setIsDeletedbuttonClicked] = useState(false)


    useEffect(() => {
        setIsLoading(true)
        ApiClient.post("User/GetCustomersWithProjects")
            .then(response => {
                props.setAllCustomersWithProjects(response.data);
                setIsLoading(false)
            }).catch(error => {
                setIsLoading(false)
                console.error(error);;
            })
    }, [])
    const createNewWorkplan = () => {
        ///console.log("props.selectedWorkplans", props)
        const workplan = props.workplanData.workplan;
        if (!workplan.name ||
            !workplan.typeSiteId ||
            !workplan.customerId ||
            !workplan.projectId ||
            !workplan.siteModel?.value ||
            !workplan.wpType ||
            (workplan.wpType === constants.wpTypeFarend && !workplan.farendId)
        ) {
            toast.error("Please, fill the mandatory fields!", { containerId: "WS", position: toast.POSITION.TOP_CENTER });
            return;
        }
        setOpenDialog(false)
        props.createEditWorkplan();
    }
    const leftPanel = {
        title: "Filter By:",
        content: <WorkplanFilter />,
    };
    const filteredWorkplans = props.workplanData.filteredWorkplans;
    const midPanel = {
        title: "Filtered Workplans",
        content: filteredWorkplans.initialState === 0 ? <div className="flex-start-content m-3 w-10" >Please click on filter to get a list of workplans</div>
            : <WorkplanList key={filteredWorkplans.initialState} isDeletedbuttonClicked={isDeletedbuttonClicked} setIsDeletedbuttonClicked={setIsDeletedbuttonClicked} />,
        rightIcon:
            <> <button
                hidden={props.userData.userData.jobRole === "1" || props.userData.userData.jobRole === "0" ? false : true}
                className={"btn primary m-1 " + (props.isLoading ? "loading  disabled" : "")}
                onClick={() => { setOpenDialog(true); props.setWorkplan({ id: 0, createDate: new Date() }) }}>
                <i className="icon icon-plus p-1" />Add Workplan
            </button>,
                <button
                    hidden={isEmpty(props.workplanData.selectedWorkplans)}
                    className={"btn  btn-danger m-1 " + (props.isLoading ? "loading  disabled" : "")}
                    onClick={() => setIsDeletedbuttonClicked(true)}>
                    <i class="icon icon-trashcan color-black  p-1" />Delete Workplans
                </button></>
    };

    return (
        <div>
            <EuiRow>

                <EuiMultiPanelTile id="workplanMultiPanel"
                    className="fullscreen"
                    key={isLoading}
                    leftPanel={leftPanel}
                    midPanel={midPanel}
                    style={{ paddingRight: 23 }} />

                <DialogModal
                    buttonName="Save"
                    content={<WorkplanInfo mod={constants.actionMods.CREATE}
                        key={props.userData.customersWithProjects} />}
                    handleClose={() => setOpenDialog(false)}
                    id="workplanCreateDialog"
                    onClick={() => createNewWorkplan()}
                    open={openDialog}
                    disableBackdropClick={true}
                    title="Create Workplan">
                </DialogModal>


                <ToastContainer enableMultiContainer containerId={'WS'} autoClose={2500} />
            </EuiRow >

        </div>
    )
}


const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, workplanData: state.workplan, selectedWorkplanData: state.selectedWorkplans });
const mapDispatchToProps = dispatch => {
    return {
        setWorkplan: (workplan) => dispatch({ type: "SET_WORKPLAN", payload: workplan }),
        setAllCustomersWithProjects: (customersWithProjects) => dispatch({ type: "SET_ALL_CUSTOMERS_WITH_PROJECTS", payload: customersWithProjects }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WorkplanSearch));
