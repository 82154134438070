import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KpiCard from "../../../components/kpicard";
import EuiLoading from "../../../eui-components/loading";
import EuiRow from "../../../eui-components/row";
import EuiTabs from "../../../eui-components/tabs";
import EhsDetails from "./ehsDetails";
import EhsPerformanceTrend from "./ehsPerformanceTrend";
import EhsPerformanceDetails from "./ehsTeamPerformance";


const EhsDashboardData = (props) => {

    const ehsDashboardData = useSelector(state => state.ehsDashboard);
    const kpis = ehsDashboardData?.kpis;
    const details = ehsDashboardData?.sitesDetails;

    const [trendSelected, setTrendSelected] = useState(false);
    
    useEffect(() => {
        setTrendSelected(false);
    })
    const ehsPass = kpis?.ehsPass ?? 0;
    const ftp = kpis?.firstTimePass ?? 0;
    const criticalViolationReporting = kpis?.criticalViolationReporting ?? 0;
    const rscReviewLt = kpis?.rscReviewLt ?? 0;
    const overallEhsLt = kpis?.overallEhsLt ?? 0
    const overall = kpis?.Overall;

    

    const keyPerformanceIndicator = <EuiRow key={kpis} style={{ marginTop: "5px", backgroundColor: "#ebebeb", zoom: "0.75" }} >
        <KpiCard id="overall" gaugeSettings={`{ "value":${overall ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85,  "color": "gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Overall Performance"
            kpiName=""
            percentageValue={overall}
            infoTip="Overall Performance"
            date="-"
        />
        <KpiCard id="ehsPassed" gaugeSettings={`{ "value":${ehsPass?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85, "color": "gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Quality Control"
            percentageValue={ehsPass?.percentage || 0}
            infoTip="Total EHS passed / Total EHS submitted"
            actualValue={ehsPass?.successItemCount || 0}
            kpiName="EHS Pass"
            date="-"
            weightage={`Weightage: ${ehsPass?.weightage || 0}%`}
            barMax={ehsPass?.totalItemCount || 0}
            hideButton={true} />
        <KpiCard id="ftp" gaugeSettings={`{ "value":${ftp?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85, "color":"gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Quality Control"
            percentageValue={ftp?.percentage || 0}
            infoTip="First time pass with no rejections"
            actualValue={ftp?.successItemCount || 0}
            kpiName="First Time Pass"
            date="-"
            weightage={`Weightage: ${ftp?.weightage || 0}%`}
            barMax={ftp?.totalItemCount || 0}
            tipPosition="left"
            hideButton={true} />
        <KpiCard id="criticalViolationReporting" gaugeSettings={`{ "value":${criticalViolationReporting?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 15, "color": "green", "label": "Eligible"},
                                                  {"from": 15, "to": 100, "color": "gray", "label": "Not Eligible"}
                                                ]}`}
            percentageValue={criticalViolationReporting?.percentage || 0}
            infoTip="Total EHS submitted compares to Total violation reported"
            actualValue={criticalViolationReporting?.successItemCount || 0}
            kpiName="Critical Violation Reporting"
            kpiArea="Performance"
            date="-"
            reverse={true}
            weightage={`Weightage: ${criticalViolationReporting?.weightage || 0}%`}
            barMax={criticalViolationReporting.totalItemCount || 0}
            hideButton={true} />
        <KpiCard id="rscReviewLt" gaugeSettings={`{ "value":${rscReviewLt?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85, "color":"gray",  "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Efficiency"
            kpiName="RSC Review LT <= 15 min"
            percentageValue={rscReviewLt?.percentage || 0}
            infoTip="difference between latest date time sent to RSC and RSC review date time should be <= 15 min"
            actualValue={rscReviewLt?.successItemCount || 0}
            date="-"
            weightage={`Weightage: ${rscReviewLt?.weightage || 0}%`}
            barMax={rscReviewLt?.totalItemCount || 0}
            hideButton={true} />
        <KpiCard id="overallEhsLt" gaugeSettings={`{ "value":${overallEhsLt?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85, "color": "gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Efficiency"
            kpiName="Overall EHS LT <= 45 min"
            percentageValue={overallEhsLt?.percentage || 0}
            infoTip="difference between first upload image date time and RSC review date time should be <= 45 min"
            actualValue={overallEhsLt?.successItemCount || 0}
            date="-"
            weightage={`Weightage: ${overallEhsLt?.weightage || 0}%`}
            barMax={overallEhsLt?.totalItemCount || 0}
            tipPosition="left"
            hideButton={true} />
    </EuiRow>;

    const ehsDetails = <EhsDetails key={details} data={details.sitesDetails} />;

    const performanceDetails = <EhsPerformanceDetails key={details || details.actualGroupingColumns} data={details.performanceDetails} />

    const performanceTrend = <EhsPerformanceTrend selected={trendSelected} style={{ minWidth: "600px" }} className="w-10" id="trends" key={details.sitesDetails || trendSelected} data={details.sitesDetails} />;

    const tabContents =
        [{
            value: 1, children: keyPerformanceIndicator, selected: "selected", id: "performanceIndicatorTab"
        },
        { value: 2, children: performanceDetails, selected: "", id: "performanceDetailsTab" },
        { value: 3, children: performanceTrend, selected: "", id: "performanceTrendTab" },
        {
            value: 4, children: ehsDetails, selected: "", id: "siteDetaileTab"
        }];

    const tabTitles =
        [{ value: 1, name: "Key Performance Indicator", selected: "selected", onClick: () => { setTrendSelected(false) } },
        { value: 2, name: "Team Performance", selected: "", onClick: () => { setTrendSelected(false) } },
        { value: 3, name: "Performance Trend", selected: "", onClick: () => { setTrendSelected(true) } },
        { value: 4, name: "Sites Activity Details", selected: "", onClick: () => { setTrendSelected(false) } }];

    return (<EuiRow key={ehsDashboardData.isLoading}
        disabled={ehsDashboardData.isLoading ? "disabled" : ""}
    >
        <EuiLoading isLoading={ehsDashboardData.isLoading} />
        <EuiTabs
            id="EhsDashboardTabs"
            titlesColumnStyle={{ marginBottom: "10px" }}
            contentStyle={{ width: "100%" }}
            titles={tabTitles}
            contents={tabContents}
            tabStyle={{ width: "100%" }}
            viewActionButton={true}
        />

    </EuiRow>
    );


}

export default EhsDashboardData;