import React, { useEffect, useState } from "react";
import constants from "../../../utils/constants";
import AssignedSessions from "./assignedSessions";
import InspectorsList from "./inspectorsList";
import EuiMultiPanelTile from "../../../eui-components/multipaneltile";
import EuiRow from "../../../eui-components/row";

const EhsInspectionData = () => {
    const leftPanel = {
        title: "RSC EHS Inspectors", content: <InspectorsList mod={constants.userRoles.RSC_OHS_INSPECTOR} />,
        style: { width: "400px" }
    };
    const midPanel = {
        title: <div className="flex-start-content "><div className="mr-2" style={{ color: "gray", fontWeight: 100 }}>
            Assigned EHS Pings
        </div> </div>,
        content: <AssignedSessions mod={constants.userRoles.RSC_OHS_INSPECTOR} />,
        iconTooltip: "Toggle inspectors list"
    };
    return (
        <EuiRow >
            <EuiMultiPanelTile id="ehsInspectorDashbordMultiPanel"
                midPanelButton={true}
                leftPanel={leftPanel}
                midPanel={midPanel}
                leftPanelStyle={{ width: "400px" }}
            />
        </EuiRow>
    )
}
export default EhsInspectionData;