import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import constants from "../../utils/constants";
import EuiTile from "../../eui-components/tile";
import EuiTextField from "../../eui-components/textfield";
import EuiRow from "../../eui-components/row";
import AdvancedGridList from "../../components/advancedgridlist";
import DocumentDialogModal from "../../components/documentDialogModal";
import EuiTabs from "../../eui-components/tabs";
import TicketChat from "./ticketChat";
import SasHelper from "../../utils/sas-helper";


class PreviewTicket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            editMod: constants.actionMods.EDIT,
            openDialogPreview: false,
            selectedDocument: { id: 0 },
            contents: [
                { value: 1, selected: "selected", children: this.ticketDetail() },
                { value: 2, selected: "", children: <TicketChat isReadOnly={true} /> }
            ],
            titles: [
                {
                    value: 1,
                    name: <div className="flex-start-content">Details</div>,
                    selected: "selected"
                },
                {
                    value: 2,
                    name: <div className="flex-start-content">Chat History</div>,
                    selected: ""
                },
            ]
        }
    }



    componentDidMount() {
        
        
    }

    ticketDetail = () => {
        return (
            <EuiTile>
                <EuiTextField
                    className="p-1"
                    fullwidth
                    readOnly
                    id="ticketTitle"
                    name="ticketTitle"
                    label="Short Description"
                    labelstyle={{ fontWeight: 600 }}
                    value={this.props.selectedTicket?.title} />

                <EuiTextField
                    className="p-1"
                    fullwidth
                    readOnly
                    multiLine
                    id="ticketDescription"
                    name="ticketDescription"
                    label="Description"
                    labelstyle={{ fontWeight: 600 }}
                    value={this.props.selectedTicket?.description} />

                <EuiTextField
                    className="p-1"
                    fullwidth
                    readOnly
                    multiLine
                    id="ticketSolution"
                    name="ticketSolution"
                    label="Solution"
                    labelstyle={{ fontWeight: 600 }}
                    value={this.props.selectedTicket?.solution} />

                <EuiRow>
                    <AdvancedGridList
                        tileData={this.props.selectedTicket.ticketDocuments}
                        viewOnly={true}
                        onClickImage={(tile) => { this.setState({ selectedDocument: tile, openDialogPreview: true }) }}
                        type={4}
                    />
                </EuiRow>
            </EuiTile>
            );
    }

    render() {

        return (
            <EuiRow style={{ width: 750, height: 400 }}>
                <EuiTile tileStyle={{ padding: 5 }}>
                    <EuiRow>
                        <EuiTabs id="ticketDetailTab"
                            titles={this.state.titles}
                            contents={this.state.contents}
                            tabStyle={{ width: "100%" }}
                            titleStyle={{ fontSize: 16, fontWeight: 500 }}
                        ></EuiTabs>
                    </EuiRow>

                    <DocumentDialogModal openDialogPreview={this.state.openDialogPreview}
                        handleClose={() => this.setState({ openDialogPreview: false })}
                        id="ticketDocumentView"
                        showLocation={false}
                        selectedDocument={{ ...this.state.selectedDocument, documentPath: SasHelper.addSasKey(this.state.selectedDocument.documentPath), type: this.state.selectedDocument.type }} // 1 image, 2 video, 3 pdf
                    >
                    </DocumentDialogModal>

                    <ToastContainer enableMultiContainer containerId={'ES'} autoClose={2500} />
                </EuiTile>
            </EuiRow>);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ticketData: state.ticket });
const mapDispatchToProps = dispatch => {
    return {
        setTickets: (tickets) => dispatch({ type: "SET_TICKETS", payload: tickets }),
        setTicketPageMod: (mod) => dispatch({ type: "SET_TICKET_PAGE_MOD", payload: mod }),
        setCurrentTicket: (ticket) => dispatch({ type: "SET_CURRENT_TICKET", payload: ticket })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(PreviewTicket));