import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../eui-components/row";
import AutoCompleteMultiSelect from "../../../components/autocompletemultiselect";
import FormField from "../../../eui-components/formfield";
import DialogModal from "../../../components/dialog";
import ApiClient from "../../../utils/api-client";
import { ToastContainer, toast } from "react-toastify";
import { IconButton, Tooltip } from "@material-ui/core";
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import constants from "../../../utils/constants";
import EuiLoading from "../../../eui-components/loading";

class ParticipantsItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            openDialog: false,
            selectedUsers: [],
            selectedCustomers: [],
            offlineParticipants: []
        }
    }

    componentDidMount() {
        if (!this.props.userData.users || this.props.userData.users.length === 0) {
            ApiClient.post("User/GetAllUsers").then(response => {
                this.props.setAllUsers(response.data);
            }).catch(error => {
                console.error(error);;
            });
        }

        ApiClient.post("Session/GetLiveStreamParticipants", { systemId: this.props.liveStreamData.liveSessionInfo.sessionId, type: this.props.liveStreamData.liveSessionInfo.type }).then(response => {
            this.setState({ offlineParticipants: response.data });
        }).catch(error => {
            console.error(error);;
        });
    }

    openDialog = () => {
        this.setState({ openDialog: !this.state.openDialog });
    }

    sendMailSelectedUsers = () => {
        
        
        if (this.state.selectedUsers.length === 0 && this.state.selectedCustomers.length === 0) {
            toast.error("No user selected to send invitation!", { containerId: 'PI', position: toast.POSITION.TOP_CENTER });
            return;
        }

        this.setState({ openDialog: false, isLoading: true }, () => {
            ApiClient.post("Session/SendMailRequestedParticipant", {
                ericssonUsers: this.state.selectedUsers,
                customerUsers: this.state.selectedCustomers,
                sessionId: this.props.liveStreamData.liveSessionInfo.sessionId,
                sessionName: this.props.liveStreamData.liveSessionInfo.sessionName,
                createUser: this.props.userData.userData.userId,
                type: this.props.liveStreamData.liveSessionInfo.type,
                interfaceType: constants.interfaceTypes.WEB
            })
                .then(response => {
                    if (response.data) {                       
                        toast.success("Invitation e-mails were sent to selected people succesfully!", { containerId: 'PI', position: toast.POSITION.TOP_CENTER });                       
                    }
                    this.setState({ isLoading: false });
                }).catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                });
        });
    }

    updateSubscribers = (index, newItem) => {
        const subscribers = [...this.props.liveStreamData.subscribers];
        subscribers[index] = newItem;
        this.props.setSubscribers(subscribers);
    }

    getOfflineParticipants = () => {
        const publisher = this.props.liveStreamData.publisher && JSON.parse(this.props.liveStreamData.publisher.stream.connection.data).clientData;
        const subscribers = this.props.liveStreamData.subscribers && this.props.liveStreamData.subscribers.map(item => JSON.parse(item.stream.connection.data).clientData);
        const onlineParticipants = [...subscribers, publisher];
        
        const allUsers = [...this.state.offlineParticipants, ...this.state.selectedUsers, ...this.state.selectedCustomers];
        const participants = allUsers && allUsers.filter(p => !onlineParticipants.includes(p.text));
        return participants.map(item => <OfflineParticipant participant={item} key={item} deleteParticipant={this.deleteParticipant} />);
    }

    deleteParticipant = (participant) => {
        
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Session/DeleteParticipant", {
                type: this.props.liveStreamData.liveSessionInfo.type,
                systemId: this.props.liveStreamData.liveSessionInfo.sessionId,
                user: participant,
                updateUser: this.props.userData.userData.userId
            })
                .then(response => {
                    if (response.data) {
                        const offlineParticipants = this.state.offlineParticipants.filter(item => item.value != participant.value);
                        const selectedUsers = this.state.selectedUsers.filter(item => item.value != participant.value);
                        const selectedCustomers = this.state.selectedCustomers.filter(item => item.value != participant.value);
                        this.setState({ offlineParticipants, selectedUsers, selectedCustomers, isLoading: false });
                        toast.success("Participant was deleted succesfully.", { containerId: 'PI', position: toast.POSITION.TOP_CENTER });                       
                    }
                    this.setState({ isLoading: false });
                }).catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                });
        });
    }

    render() {
        return (
            <EuiRow style={{ width: this.props.liveStreamData.width }}>
                {this.state.isLoading ? <EuiLoading id="kpiLoading" style={{ top: "35%" }} isLoading={true}/> :
                    <div className="participant-container">
                        <div style={{ margin: "10px 5px" }}>
                            <button className="btn primary" style={{ fontSize: 15 }} onClick={() => this.openDialog()}>Add Participant</button>
                        </div>
                        {
                            this.props.liveStreamData.publisher ?
                                <div className="flex-start-content w-10 m-2"><i className="icon icon-alarm-level5 color-green mr-2"></i> {JSON.parse(this.props.liveStreamData.publisher.stream.connection.data).clientData}</div>
                                :
                                null
                        }
                        {
                            this.props.liveStreamData.subscribers.map((item, index) => {
                                if (item.stream.connection.disposed) {
                                    return null;
                                }
                                return <Participant subscriber={item} updateSubscribers={(newItem) => this.updateSubscribers(index, newItem)} />
                            })
                        }
                        {this.getOfflineParticipants()}
                    </div>
                }

                <DialogModal
                    buttonName="Ok"
                    content={<EuiRow style={{ maxWidth: 600, width: 400 }}>
                        <FormField style={{ width: "100%" }}>
                            <label>Ericsson Users</label>
                            <AutoCompleteMultiSelect
                                id="ericssonParticipant"
                                options={(this.props.userData.users ?? []).filter(item => constants.ericssonEmployeeRoles.includes(item.role))}
                                value={this.state.selectedUsers}
                                onChange={e => this.setState({ selectedUsers: e.target.value })}
                            />
                        </FormField>
                        {this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION ?
                            <FormField style={{ width: "100%" }}>
                                <label>Customer Users</label>
                                <AutoCompleteMultiSelect
                                    id="customerParticipant"
                                    options={(this.props.sessionData.approveSession.currentSession ?? {}).customerUsers || []}
                                    value={this.state.selectedCustomers}
                                    onChange={e => this.setState({ selectedCustomers: e.target.value })}
                                />
                            </FormField>
                            : null
                        }
                    </EuiRow>}
                    handleClose={() => this.setState({ openDialog: false })}
                    id="addParticipantDialog"
                    titleId="titleaddPaticipantDialog"
                    onClick={this.sendMailSelectedUsers}
                    open={this.state.openDialog}
                    title={<div>
                        <div>Add Participant</div>
                        <div className="text-sm">E-mail will be sent to selected users.</div>
                    </div>}
                >
                </DialogModal>

                <ToastContainer enableMultiContainer containerId={'PI'} autoClose={2500} />
            </EuiRow>
        );
    }
}

const OfflineParticipant = (props) => {
    if (!props) {
        return null;
    } else {
        return props.participant ?
            <div className="flex-start-content m-2 ml-0 disabled " key={props.participant}>
                <Tooltip title="Remove Participant">
                    <IconButton onClick={() => props.deleteParticipant(props.participant)}>
                        <i className="icon icon-trashcan color-red m-1 enabled"></i>
                    </IconButton>
                </Tooltip>
                <div style={{ width: "80%", marginLeft: 7 }}>{props.participant.text + " - " + props.participant.mail}</div>
                <div style={{ margin: 5, fontSize: 25, display: "flex" }}>
                    <IconButton>
                        <i className="icon icon-mic-off color-gray"></i>
                    </IconButton>
                    <IconButton >
                        <VideocamOffOutlinedIcon style={{ color: "#6A6A6A", fontSize: 25 }} />
                    </IconButton>
                </div>
            </div>
            :
            null
    }
}

const Participant = (props) => {
    
    if (!props) {
        return null;
    } else {
        return props.subscriber ?
            <div className="flex-start-content m-2" key={props.subscriber}>
                <div className="flex-start-content w-9"><i class="icon icon-alarm-level5 color-green mr-2"></i> {JSON.parse(props.subscriber.stream.connection.data).clientData}</div>
                <div style={{ margin: 5, fontSize: 25, marginLeft: 20, display: "flex" }}>
                    <IconButton onClick={() => { props.subscriber.subscribeToAudio(!props.subscriber.stream.audioActive); props.updateSubscribers(props.subscriber); }}>
                        {props.subscriber.stream.audioActive ?
                            <i className="icon icon-mic color-green"></i> :
                            <i className="icon icon-mic-off color-gray"></i>
                        }
                    </IconButton>
                    <IconButton onClick={() => { props.subscriber.subscribeToVideo(!props.subscriber.stream.videoActive); props.updateSubscribers(props.subscriber); }}>
                        {props.subscriber.stream.videoActive ?
                            <i className="icon icon-camera-classic color-green" ></i> :
                            <VideocamOffOutlinedIcon style={{ color: "#6A6A6A", fontSize: 25 }} />
                        }
                    </IconButton>
                </div>
            </div>
            :
            null
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
        setSubscribers: subscribers => dispatch({ type: "SET_SUBSCRIBERS", payload: subscribers }),
        setAllUsers: users => dispatch({ type: "SET_ALL_USERS", payload: users }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ParticipantsItem);
