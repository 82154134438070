import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class SimpleExpansionPanel extends React.Component {

    render() {
        return (
            <div style={{ width: "100%" }}>
                {this.props.opened ==="" || this.props.opened ==="opened" ?
                    <ExpansionPanel
                        id={this.props.id}
                        style={{
                            backgroundColor: "inherit",
                            borderRadius: 0,
                            boxShadow: "0px 0px 0px transparent",
                            paddingRight: 10,
                            transition: "none",
                            ...this.props.style
                        }}
                        TransitionProps={{ unmountOnExit: true }}
                        expanded={this.props.opened ==="opened" ? true : false}
                        onChange={() => this.props.onChange && this.props.onChange()}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{minHeight:35  , height : 'auto' , overflow : 'hidden'}}
                        >
                            <div style={{ fontWeight: 600 }}>{this.props.title}</div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.props.content}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    :
                    <ExpansionPanel
                        id={this.props.id}
                        style={{
                            backgroundColor: "inherit",
                            borderRadius: 0,
                            boxShadow: "0px 0px 0px transparent",
                            paddingRight: 10,
                            transition: "none",
                            ...this.props.style
                        }}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          style={{ minHeight: 35 }}
                        >
                            <div style={{ fontWeight: 600 }}>{this.props.title}</div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.props.content}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                }
            </div>
        );
    }
}
