const initialState = {
    inspectionItemsList: [],
    selectedInspectionItems: [],
    currentInspection: { id: 0, currentInspectionItems: [] },
    evidencesToUpload: [],
    selectedItem: { id: 0 },
    selectedDocuments: [],
    allDocument: []
};

export default (state = initialState, action) => {
    switch (action.type) {

        case "SET_CURRENT_INSPECTION":
            return {
                ...state,
                currentInspection: action.payload
            };

        case "SET_INSPECTION_ITEMS_LIST":
            return {
                ...state,
                inspectionItemsList: action.payload
            };
        case "SET_INSPECTION_ITEMS_SELECTED_LIST":
            return {
                ...state,
                selectedInspectionItems: action.payload
            };
        case "SET_INSPECTION_EVIDENCES":
            return {
                ...state,
                evidencesToUpload: action.payload
            }
        case "SET_SELECTED_ITEM":
            return {
                ...state,
                selectedItem: action.payload
            }
        case "SET_SELECTED_AND_ALL_DOCUMENTS":
            return {
                ...state,
                selectedDocuments: action.payload.selectedDocuments,
                allDocument: action.payload.allDocument
            }
        case "SET_ALL_DOCUMENT":
            return {
                ...state,
                allDocument: action.payload
            }
        default:
            return state;
    }

};
