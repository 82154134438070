import React from "react";
import {
  Stack
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import StyledChatBox from "./StyledChatBox";
import { useSelector } from "react-redux";
import { Checkbox } from "@material-ui/core";


const UserElement = ({ name, email, mail, id, userId, setListUsers, listUsers }) => {
  const userData = useSelector(state => state.user);
  const user_id = parseInt(userData.userData.userId)

  const theme = useTheme();


  const { conversations } = useSelector((state) => state.conversation.direct_chat);


  const profileAvatar = `https://eu.ui-avatars.com/api/?name=${name?.toString()?.split(" ")[0]}+${name?.toString()?.split(" ")[1]}&size=250$`

  const handleCheckedId = (userId) => {
    let users = [...listUsers];
    if (users.find(e => e.id === userId)) {
      users = users.filter(e => e.id !== userId);
    }

    else {
      const ele = {}
      ele.id = userId || id
      ele.name = name
      ele.email = mail || email
      ele.profileAvatar = profileAvatar
      ele.status = 'invited'
      ele.conversationId = conversations[0].id
      ele.from = user_id
      ele.activityId = conversations[0].activityid
      ele.UserIdSentRequest = userData.userDetail.id
      ele.UserNameSentRequest = userData.userDetail.name
      ele.UserEmailSentRequest = userData.userDetail.email


      users.push(ele);
    }

    setListUsers(users);

  };



  return (
    <StyledChatBox
      sx={{
        width: "100%",
        borderRadius: 1,
        backgroundColor: theme.palette.background.paper,
      }}
      p={1}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems={"center"}>

          <Checkbox
            id="indeterminate-cb"
            size='medium'
            className="bigIcon"
            checked={listUsers.find(e => e.id === userId) !== undefined}
            onClick={() => handleCheckedId(userId)}
          />

          <Stack paddingLeft={2}>
            <label>{name}</label>
            <label>{mail || email}</label>
          </Stack>
        </Stack>

      </Stack>
    </StyledChatBox>
  );
};

export { UserElement };
