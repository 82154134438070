import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import ActivitySearch from "./activitySearch";
import constants from "../../utils/constants";
import ActivityDetails from "./activityDetails";
import GeneralChat from "../chatTools/dashboard";
import ApiClient from "../../utils/api-client";
import EuiLoading from "../../eui-components/loading";
import { setChat } from "../../store/actions/roomAction";


class Activities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }
    getActivityDetails = () => {

        this.setState({ isLoading: true });
        ApiClient.post("Workplan/GetActivityDetails", { id: parseInt(this.props.location?.search?.split("=")[1]) })
            .then(response => {
                this.setState({ isLoading: false }, () => {
                    const activity = response.data;
                    this.props.setActivity(activity);

                });
            })
            .catch(error => {
                console.error(error);;
                this.setState({ isLoading: false });
            });
    }
    componentDidMount() {
        if (parseInt(this.props.location?.search?.split("=")[1]) > 0) {
            this.getActivityDetails();
        }
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
    }

    renderMod = () => {
        if (this.state.isLoading) {
            return <EuiLoading isLoading={this.state.isLoading} />
        }
        var activityId  = this.props.location?.search?.split("=")[1];
        if (parseInt(activityId) > 0) {
            this.props.setChat(activityId, this.props.activityData?.activity?.rscScope, null)

            return <GeneralChat 
            rscScope={this.props.activityData?.activity?.rscScope}
             activityId={parseInt(activityId)}
             ehsPingId={null} />

        }
        if (this.props.activityData.mod === constants.pageMods.VIEW_ONLY) {
            return <ActivityDetails />
        } else {
            return <ActivitySearch />;
        }
    }

    render() {
        return (this.renderMod());
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, activityData: state.activity });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setActivity: (activity) => dispatch({ type: "SET_ACTIVITY", payload: activity }),
        setChat: (activityId, rscScope, ehsPingId) => dispatch(setChat(activityId, rscScope, ehsPingId))

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(Activities)));