import React, { useState, useEffect } from 'react'
import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import EuiLoading from '../../../eui-components/loading';
import EuiTile from '../../../eui-components/tile';
import EuiTooltip from '../../../eui-components/tooltip';
import ObseleteEuiTable from '../../../eui-components/obseleteTable';
import ApiClient from '../../../utils/api-client';
import CommonHelper from '../../../utils/common-helper';
import constants from '../../../utils/constants';
import VehicleHelper from '../../../utils/vehicle-helper';

const VehicleList = (props) => {

    const userJobRole = useSelector(state => state.user.userData.jobRole);
    const loginRole = useSelector(state => state.user.loginRole);

    const [vehicleColumns, setColumns] = useState([
        {
            title: "Vehicle ID",
            key: "id",
            sort: "desc",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const idLink = `<a id="vehicleid_${cellData}">${cellData}</a>`;
                    td.innerHTML = idLink;
                    td.querySelector("#vehicleid_" + cellData).addEventListener('click', () => openVehicleDetail(cellData, constants.pageMods.VIEW_ONLY))
                }
            },
            width: "5%",
        },
        {
            title: "Chassis Number",
            key: "chassisNumber",
            sort: "none",
            width: "10%"
        },
        {
            title: "Reqistration Plate No",
            key: "registrationPlateNo",
            sort: "none",
            width: "15%"
        },
        {
            title: "Manufacturer",
            key: "vehicleManufacturer",
            sort: "none",
            width: "15%"
        },
        // {
        //     title: "Model Name",
        //     key: "vehicleModelName",
        //     sort: "none",
        //     width: "15%"
        // },
        {
            title: "Model Year",
            key: "vehicleModelYear",
            sort: "none",
            width: "10%"
        },
        {
            title: "Status",
            key: "vehicleStatus",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                const vehicleStatus = VehicleHelper.vehicleHTMLTableStatus[cellData];
                td.innerHTML = vehicleStatus;
            },
            width: "15%",
            notSearchable: true
        }, {
            title: "is Vehicle Compliant",
            key: "isVehicleCompliant",
            sort: "none",
        
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML =cellData==="Compliant" ?`<div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>${cellData}</div>`:
                    `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${cellData}</div>`;
            
                }
            },
            width: "15%",
            notSearchable: true,
        }, 
        {
            title: "Validation Status",
            key: "validationStatus",
            sort: "none",
            onCreatedCell: (td, cellData) => {
             if(cellData==="Valid"){ // color: black; 
                td.innerHTML = `<span style = "background-color: #288964; color: white;" class="pill toggle">${cellData}</span>`

             }else   if(cellData==="Expired")
             {
                td.innerHTML = `<span style = "background-color: #DC2D37; color: white; " class="pill toggle">${cellData}</span>`

                // #288964
             }
             
            
            },
            // 
            // onCreatedCell: (td, cellData) => {
            //     if (cellData) {
            //         td.innerHTML =cellData==="Compliant" ?`<div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>${cellData}</div>`:
            //         `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${cellData}</div>`;
            
            //     }
            // },
            width: "15%",
            notSearchable: true,
        },
        {
            title: "vehicle Compliance Status",
            key: "vehicleComplianceStatus",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData;
            },
            width: "15%",
            notSearchable: true
        },
        {
            title: "",
            key: "editModel",
            onCreatedCell: (td, cellData) => {
                if (cellData && [VehicleHelper.vehicleStatusLookup.Created, VehicleHelper.vehicleStatusLookup.Rejected].includes(cellData.status) && VehicleHelper.hasModificationPermit(userJobRole)) {
                    const action = `<span class="tooltip"><i id="vehicle_edit_${cellData.id}" class="icon icon-edit icon-frame clickable p-1 text-lg"></i><span class="message top-end">Edit Vehicle</span></span>`
                    td.innerHTML = `${action}`;
                    td.querySelector("#vehicle_edit_" + cellData.id).addEventListener('click', () => openVehicleDetail(cellData, constants.pageMods.EDIT))
                } else {
                    td.innerHTML = null;
                }
            },
            notSearchable: true,
            width: "2%"
        },
        {
            title: "",
            key: "inactivateModel",
            onCreatedCell: (td, cellData) => {
                if (cellData && VehicleHelper.vehicleStatusLookup.Retired != cellData.status && VehicleHelper.hasModificationPermit(userJobRole)) {
                    const action = `<span class="tooltip"><i id="vehicle_inactivate_${cellData.id}" class="icon icon-trashcan icon-frame clickable p-1 text-lg"></i><span class="message top-end">Retire Vehicle</span></span>`
                    td.innerHTML = `${action}`;
                    td.querySelector("#vehicle_inactivate_" + cellData.id).addEventListener('click', () => props.openStatusDialog({ id: cellData.id, open: true, type: VehicleHelper.vehicleStatusLookup.Retired }))
                } else {
                    td.innerHTML = null;
                }
            },
            notSearchable: true,
            width: "2%"
        },
        {
            title: "",
            key: "inactivateModel",
            onCreatedCell: (td, cellData) => {
                ///console.log("cellData.status",cellData)
                if (cellData && [VehicleHelper.vehicleStatusLookup.Retired].includes(cellData.status)  && VehicleHelper.hasModificationPermit(userJobRole)) {
                    const action = `<span class="tooltip"><i id="vehicle_activate_${cellData.id}" class="icon icon-success icon-frame clickable p-1 text-lg"></i><span class="message top-end">Unretire Vehicle</span></span>`
                    td.innerHTML = `${action}`;
                    td.querySelector("#vehicle_activate_" + cellData.id).addEventListener('click', () => props.openStatusDialog({ id: cellData.id, open: true, type: VehicleHelper.vehicleStatusLookup.Created }))
                } else {
                    td.innerHTML = null;
                }
            },
            notSearchable: true,
            width: "2%"
        },
        // {
        //     title: "",
        //     key: "sentToRscModel",
        //     onCreatedCell: (td, cellData) => {
        //         if (cellData && [VehicleHelper.vehicleStatusLookup.Created, VehicleHelper.vehicleStatusLookup.Rejected].includes(cellData.status) && VehicleHelper.hasModificationPermit(userJobRole)) {
        //             const action = `<span class="tooltip"><i id="vehicle_sentToRsc_${cellData.id}" class="icon icon-send icon-frame clickable p-1 text-lg"></i><span class="message top-end">Send Ericsson Approval</span></span>`
        //             td.innerHTML = `${action}`;
        //             td.querySelector("#vehicle_sentToRsc_" + cellData.id).addEventListener('click', () => props.openStatusDialog({ id: cellData.id, open: true, type: VehicleHelper.vehicleStatusLookup['Under Review'] }))
        //         } else {
        //             td.innerHTML = null;
        //         }
        //     },
        //     notSearchable: true,
        //     width: "2%"
        // },
        {
            title: "",
            key: "approveModel",
            onCreatedCell: (td, cellData) => {
                if (cellData && cellData.status === VehicleHelper.vehicleStatusLookup['Under Review'] && userJobRole === constants.userRoles.RSC_COODINATOR) {
                    const action = `<span class="tooltip"><i id="vehicle_approve_${cellData.id}" class="icon icon-check icon-frame clickable p-1 text-lg"></i><span class="message top-end">Review&Approve Vehicle</span></span>`
                    td.innerHTML = `${action}`;
                    td.querySelector("#vehicle_approve_" + cellData.id).addEventListener('click', () => openVehicleDetail(cellData, constants.pageMods.APPROVE))
                } else {
                    td.innerHTML = null;
                }
            },
            notSearchable: true,
            width: "2%"
        },
        {
            title: "",
            key: "reviseModel",
            onCreatedCell: (td, cellData) => {
                if (cellData && cellData.status === VehicleHelper.vehicleStatusLookup.Approved && VehicleHelper.hasModificationPermit(userJobRole)) {
                    const action = `<span class="tooltip"><i id="vehicle_revise_${cellData.id}" class="icon icon-lock-unlocked icon-frame clickable p-1 text-lg"></i><span class="message top-end">Revise Approved Vehicle</span></span>`
                    td.innerHTML = `${action}`;
                    td.querySelector("#vehicle_revise_" + cellData.id).addEventListener('click', () => props.openStatusDialog({ id: cellData.id, open: true, type: VehicleHelper.vehicleStatusLookup.Created }))
                } else {
                    td.innerHTML = null;
                }
            },
            notSearchable: true,
            width: "2%"
        },
    ]);
    const [isLoading, changeIsLoading] = useState(false);
    const vehicleList = useSelector(state => state.vehicle.vehicleList);
    const selectedAsp = useSelector(state => state.asp.selectedASP);
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.LIST });
        props.getVehicleList();
    }, [selectedAsp.id]);

    
    const openVehicleDetail = useCallback((model, mod) => {
        if (mod != constants.pageMods.ADD) {
            const id = model.id || model;
            changeIsLoading(true);
            ApiClient.post("Vehicle/GetVehicle", { id })
                .then(response => {
                    if (response.data) {
                        dispatch({ type: "SET_CURRENT_VEHICLE", payload: response.data });
                        dispatch({ type: "CHANGE_VEHICLE_MOD", payload: mod });
                    } 
                    changeIsLoading(false);
                })
                .catch(error => {
                    console.error(error);;
                    changeIsLoading(false);
                    CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
                });
            ApiClient.post("Vehicle/GetListOfInspectionsPerVehicle", { id })
                .then(response => {
                    dispatch({ type: "SET_VEHICLE_INSPECTIONS_LIST", payload: response.data });
                })
                .catch(error => {
                    console.error(error);;
                    changeIsLoading(false);
                    CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
                });
        } else {
            dispatch({ type: "SET_CURRENT_VEHICLE", payload: { id: 0 } });
            dispatch({ type: "CHANGE_VEHICLE_MOD", payload: mod });
        }
    });

    const getTableActions = useCallback(() => {
        return <>
            {VehicleHelper.hasModificationPermit(userJobRole) ?
                <EuiTooltip title="Add Vehicle">
                    <i className="icon icon-plus icon-lg icon-frame m-1" onClick={() => openVehicleDetail(null, constants.pageMods.ADD)}></i>
                </EuiTooltip>
                : null
            }
            <EuiTooltip title="Refresh">
                <i className="icon icon-reload icon-lg icon-frame m-1" onClick={() => props.getVehicleList()}></i>
            </EuiTooltip>
        </>;
    });

    return <>
        <EuiTile tileClassName={isLoading ? "disabled" : ""} key={props.isLoading}>
            <EuiLoading id="vehicleListLoading" isLoading={isLoading} />
            {!selectedAsp.id ? <div className="flex-middle-content m-3 w-10" style={{ fontSize: "18px", color: "gray" }}>Select an ASP Company to preview vehicles..</div>
                // eslint-disable-next-line react/jsx-pascal-case
                : <ObseleteEuiTable
                    classes="tiny split-striped"
                    columns={vehicleColumns}
                    data={vehicleList}
                    exportExcel={true}
                    excelFileName={"Vehice_List" + new Date().toISOString()}
                    Reportdata={vehicleList}
                    id="vehicleListTable"
                    viewActionButton={true}
                    actionButton={getTableActions()}
                    sortable={true}
                    paginated={true}
                    rowsPerPage={10}
                    search={true}
                    placeholder="Search vehicle..."
                    searchStyle={{ width: 300 }}
                    viewActionIcon={true}
                >
                </ObseleteEuiTable>
            }
        </EuiTile>

    </>
}
export default VehicleList;