import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EuiHeader from '../../eui-components/header';
import EuiLoading from '../../eui-components/loading';
import ApiClient from '../../utils/api-client';
import CommonHelper from '../../utils/common-helper';
import constants from "../../utils/constants";
import EuiRow from "../../eui-components/row";
import VehicleInspectionList from "../vehicle-inbox/vehicleInspectionList";
import VehicleApprovalTable from "./VehicleApprovalTable";

const VehicleApprovalInbox = () => {
    const userData = useSelector(state => state.user.userData);
    const vehicleUnderReviewList = useSelector(state => state.vehicle.vehicleUnderReviewList);

    const vehicle = useSelector(state => state.vehicle);
    const assignedInspections = vehicle?.assignedInspections || [];
    const unassignedInspections = vehicle?.unassignedInspections || [];
    const allInspections = vehicle?.allInspections || [];
    const dispatch = useDispatch();
    var inspection = useSelector(state => state.vehicleInspection);
    const [isLoading, changeIsLoading] = useState(false);
    const [dataVehicleUnderReviewList, setDataVehicleUnderReviewList] = useState([]);
    useEffect(() => {
        if(dataVehicleUnderReviewList.length===0) {
            getAllVehicleUnderReview();
        }
     
    }, [dataVehicleUnderReviewList]);

    const getMenuList = () => {
        const menu = [
            {
                id: 1, title: "Unassigned Inspections",
                icon: <i className="icon icon-view-list m-1 text-lg"></i>,
                content: <VehicleInspectionList id="unassigned" isLoading={isLoading} data={unassignedInspections} />
            },
            {
                id: 2, title: "Assigned Inspections", icon: <i className="icon icon-check m-1 text-lg"></i>,
                content: <VehicleInspectionList id="assigned" isLoading={isLoading} data={assignedInspections} />,
            }
        ]
        return menu;
    }
 
    const getAllVehicleUnderReview = () => {
        changeIsLoading(true);
        dispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.LIST });

        
        ApiClient.post("Vehicle/GetAllVehicleUnderReview").then(response => {
            dispatch({ type: "SET_VEHICLE_UNDER_REVIEW_LIST", payload: response.data });
            changeIsLoading(false);
            setDataVehicleUnderReviewList(response.data);
        })
            .catch(error => {
                console.error(error);;
                changeIsLoading(false);
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
         });
    }
   
    return (
        <EuiRow style={{ width: "100%" }} key={isLoading} disabled={isLoading ? "disabled" : ""}>
            <EuiLoading id="viewUnassignedInspections" isLoading={isLoading} />
            <EuiHeader title="Vehicle approval inbox" ></EuiHeader>
            <VehicleApprovalTable id="inspectionsID" getAllVehicleUnderReview={getAllVehicleUnderReview} isLoading={isLoading} vehicleUnderReviewData={dataVehicleUnderReviewList} />
        </EuiRow>
    )
}
export default VehicleApprovalInbox;


