const initialState = {
    mod: 1,
    inspectionMod: 1,
    currentVehicle: { id: 0 },
    vehicleList: [],
    vehicleUnderReviewList: [],
    vehicleInspections: [],
    unassignedInspections: [],
    assignedInspections: [],
    allInspections: []
};

export default (state = initialState, action) => {
    switch (action.type) {

        case "SET_CURRENT_VEHICLE":
            return {
                ...state,
                currentVehicle: action.payload
            };
        case "CHANGE_VEHICLE_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_VEHICLE_LIST":
            return {
                ...state,
                vehicleList: action.payload
            };
            case "SET_VEHICLE_UNDER_REVIEW_LIST":
                return {
                    ...state,
                    vehicleUnderReviewList: action.payload
                };
        case "CHANGE_INSPECTION_MOD":
            return {
                ...state,
                inspectionMod: action.payload
            };
        case "SET_VEHICLE_INSPECTIONS_LIST":
            return {
                ...state,
                vehicleInspections: action.payload
            }
        case "SET_UNASSIGNED_INSPECTIONS":
            return {
                ...state,
                unassignedInspections: action.payload
            }
        case "SET_ASSIGNED_INSPECTIONS":
            return {
                ...state,
                assignedInspections: action.payload
            }
        case "SET_ALL_INSPECTIONS":
            return {
                ...state,
                allInspections: action.payload
            }
        default:
            return state;
    }

};
