import React from "react";
import PageHelper from "../utils/page-helper";
import Table from "./table";
import SasHelper from "../utils/sas-helper";
import { Tooltip } from "@mui/material";
import { IconButton } from "@material-ui/core";
import { group } from "d3";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { getContent } from "../utils/file-helper";


export default class GroupedDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gallery : this.props.gallery ?? false,
            groupedDocumentColumns:  [
                {
                    title: "", field: "type",
                    render: rowData => PageHelper.renderDocumentType(rowData, this.props.documents),
                    cellStyle: {display:"flex", padding: 0 , width:200},
                    defaultGroupOrder: 0
                },
                {
                    title: "Name", field: "name", grouping: false,
                    cellStyle: { width: "100%", padding: 0 },
                    
                   render: rowData => <a href={SasHelper.addSasKey(rowData.documentPath)} target="_blank">{rowData.name}</a>,
                 
                },                
            ] ,
            groupedDocumentColumnsGallery : [
                {
                    title: "", field: "type",
                    render: rowData => PageHelper.renderDocumentType(rowData, this.props.documents),
                    cellStyle: {display:"flex", padding: 0 , width:200},
                    defaultGroupOrder: 0
                },
                {
                    title: "Name", field: "name", grouping: false,
                    cellStyle: { width: "100%", padding: 0 },
                    
                   
                   render: rowData => <div className="row" style={{minWidth : 200 , maxWidth : 400, height : rowData.type === 1 ? 200 : 40 }}>{this.getContent(rowData)}</div>
                },                
            ] ,
            tableRef: React.createRef()
        }
    }
    componentDidMount() {

    }

     getContent  (selectedDocument )  {
        const documentPath = selectedDocument.documentPath;
        const documentPathSas = SasHelper.addSasKey(documentPath);
  
        //  if (selectedDocument.type === 1) {
       
            return <LazyLoadImage id={documentPath}  src={documentPathSas} height = { selectedDocument.type === 1 ? 200 : 40} alt={selectedDocument.name}/>;
        //  }
        
          if (selectedDocument.type === 2) {
            let type = documentPath.split(".")[documentPath.split(".").length - 1];
    
            if (type.includes("?sv=")) {
                type = type.split("?sv=")[0];
            }
            return <video controls  name="media">
                <source src={documentPathSas} type={"video/" + type} />
             Your browser does not support HTML5 video.
            </video>
        } else if (selectedDocument.type === 4 || selectedDocument.type === 3) {
            return <iframe src={"https://docs.google.com/viewer?url=" + encodeURIComponent(documentPathSas) + "&embedded=true"}
                className="responsive-dialog-document fit-image" frameBorder="0"
            ></iframe>
        }
        return <div>File type can't resolved, please download document.</div>
    }
    renderGallery() {
    return <div> 
        <Tooltip id="viewList" title="List View">
                                        <IconButton style={{ marginRight: 10, background: (!this.state.gallery ? "silver" : "inherit") }} aria-label="listView" id="listView" onClick={() => this.setState({ gallery: false })}>
                                            <i className="icon icon-view-list" style={{ fontSize: 22 }} ></i>
                                        </IconButton>
                                    </Tooltip>
        <Tooltip id="viewComfy" title="Tile View">
                                        <IconButton style={{ marginRight: 10, background: (this.state.gallery ? "silver" : "inherit") }} aria-label="comfyView" id="comfyView"
                                            onClick={() => { this.setState({ gallery: true }); }}>
                                            <i className="icon icon-view-tiles" style={{ fontSize: 22 }} ></i>
                                        </IconButton>
                                    </Tooltip>
                                    </div> 
    }
                                   
    render() {
        return (
            <Table
                title={this.props.title}
                titleStyle={{ paddingBottom: 10 }}
                columns={this.state.gallery ? this.state.groupedDocumentColumnsGallery : this.state.groupedDocumentColumns}
                data={this.props.documents}
                container =  {{ custom: <div></div> }}
                groupbar={{ custom: <div className="row" style={{}} >{this.renderGallery()}</div> }}
                groupRow={{ custom: true }}
                options={{
                    paging: false,
                    minBodyHeight: 390,                    
                    selection: true,
                    grouping: true,
                    showSelectAllCheckbox: false,
                    header: false,
                    showTextRowsSelected: false                  
                }}    
                tableRef={this.state.tableRef}
        
                actions={[                   
                    {
                        tooltip: 'Download All Selected Documents',
                        icon: () => <i className="icon icon-download-save"></i>,
                        onClick: (evt, data) => PageHelper.downloadFiles(data, { tableRef: this.state.tableRef })
                    },
                    ...(this.props.actions || [])
                ]}
            >
            </Table>
        );
    }
}

