import DateUtils from "./date-utils";

export default class ChecklistHelper {

    static mapChecklists = (checklists, categories, customers, projects) => {

        if (!checklists || !checklists.length)
            return [];
        var result = checklists.map(element => {

            const el = {
                ...element,
                deleteId: element.deleteDate ? null : element.id,
                deleteDate: element.deleteDate ? element.deleteDate?.slice(0, 10) : null,
                editId: element.deleteDate ? null : element.id,
                deletedId: element.deleteDate ? element.id : null,
                classificationName: categories.find(e => e.value == element.templateClassification)?.text,
                customerProjectName: (element.templatableType === "Customer" ? customers.find(c => c.value == element.templatableId)?.text
                    : projects.find(c => c.value == element.templatableId)?.text)
            };
            return el;
        });
        return result;
    }

    static mapTemplate = (template, categories) => {
        if (!template)
            return {};
        template['editId'] = template.id;
        template['deleteId'] = template.id;
        template['itemSortId'] = template.id;
        template['items'] = template.items?.map(item => {
            const el = {
                ...item,
                deleteId: item.id,
                editId: item.id,
                category: categories.find(e => e.value == item.itemCatSortId)?.text,
            }
            return el;
        })
        return template;
    }

    static mapItems = (items, categories) => {
        if (!items || !items.length)
            return [];
        var result = items.map(item => {
            const el = {
                ...item,
                deleteId: item.id,
                editId: item.id,
                category: categories.find(e => e.value == item.itemCatSortId)?.text,
            }
            return el;
        })
        return result;
    }

    static mapSubactivityItemsToActivityDocs(items, docs) {
        items.forEach(item => {
            item.forEach(i => {
                
                if (i.templatePath) {
                    const newPath = docs?.find(u => u.url == i.templatePath)?.documentPath;
                    i.templatePath = newPath;
                }
            })
        });
    }

    static mapItemsForUploading = (files) => {
        var result = files.map(item => {
            const element = item?.contentAsBlob;
            element.url = item.copySource

            return element
        });
        return result;
    }
}
