import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EuiAccordion from "../../../eui-components/accordion";
import ApiHelper from "../../../utils/api-helper";
import EuiLoading from "../../../eui-components/loading";
import constants from "../../../utils/constants";
import EuiTag from "../../../eui-components/tag"
import EuiRow from "../../../eui-components/row";
import EuiTooltip from "../../../eui-components/tooltip";
import ApiClient from "../../../utils/api-client";

const InspectorsList = (props) => {
    const dispatch = useDispatch();
    const rscCenters = useSelector(state => state.user.rscCenters);
    const rscInspectors = useSelector(state => state.rsc.allRscInspectors);
    const setAssignedSessions = (sessions) => dispatch({ type: "SET_ASSIGNED_SESSIONS", payload: sessions });
    const setSelectedInspector = (inspector) => dispatch({ type: "SET_SELECTED_INSPECTOR", payload: inspector });

    const [isLoading, setIsLoading] = useState(false);
    const setDashboardIsLoading = (flag) => dispatch({ type: "SET_DASHBOARD_ISLOADING", payload: flag });
    const mod = props.mod;
    useEffect(() => {
        if (rscInspectors?.length == 0) {
            setIsLoading(true);
            fetchRscUsers();
            setIsLoading(false);
        }
    }, []);
    async function fetchRscUsers() {
        await ApiHelper.setAllRscInspectors((data) => dispatch({ type: "SET_ALL_RSC_INSPECTORS", payload: data }));
    }
    const handleClick = (e) => {
        
        setSelectedInspector(e)
        setIsLoading(true);
        setDashboardIsLoading(true)
        if (e.personType == constants.userRoles.RSC_OHS_INSPECTOR) {
            ApiClient.post("AutoAssignment/GetAssignedEhsPingsByRscPersonId", { id: parseInt(e.id) }).then(response => {
                
                setAssignedSessions(response.data);
                setIsLoading(false);
                setDashboardIsLoading(false)
            }).catch(error => {
                alert("An error occurred while trying to get RSC inspector sessions!");
                setIsLoading(false);
                setDashboardIsLoading(false);
            })
        }
        else {
            ApiClient.post("AutoAssignment/GetAssignedActivitiesByRscPersonId", { id: parseInt(e.id) }).then(response => {
                
                setAssignedSessions(response.data);
                setIsLoading(false);
                setDashboardIsLoading(false)
            }).catch(error => {
                alert("An error occurred while trying to get RSC inspector sessions!");
                setIsLoading(false);
                setDashboardIsLoading(false);
            })
        }
    }
    const getInspectorsList = () => {
        const accordions = rscCenters.filter(i => i.value !== 3 && i.value !== 2).map(item => {
            var assignedSessionsCount = rscInspectors.filter(i => i.rscCenterId == item.value && i.personType == mod).map(i => i.assignedSessions).reduce((sum, num) => sum + num, 0);
            return {
                id: item.value,
                title: mod == 7 ? (item.text + " | total assigned sessions: (" + assignedSessionsCount + ")") : item.text,
                titleStyle: { fontSize: "14" },
                key: item.value,
                content: rscInspectors.filter(i => i.rscCenterId == item.value && i.personType == mod)?.sort((a, b) => b.checkin - a.checkin).map(rscPerson =>
                    <EuiTag
                        id={rscPerson.id + "tag"}
                        key={rscPerson.value}
                        type="big"
                        className={rscPerson.personType == constants.userRoles.RSC_OHS_INSPECTOR ? (rscPerson.checkin ? "green clickable w-10 mt-2" : "red clickable w-10 mt-2") : "blue clickable w-10 mt-2"}
                        onClick={() => handleClick(rscPerson)}
                        name={rscPerson.personType == constants.userRoles.RSC_OHS_INSPECTOR ? (rscPerson.name + " (" + (rscPerson.assignedSessions ?? 0) + ")") : (rscPerson.name)}
                        style={{ marginBottom: "2px" }}
                    >
                    </EuiTag >)
            };
        });
        return accordions;
    }
    return (
        <EuiRow style={{ justifyContent: "end" }} key={isLoading} className={isLoading ? " disabled" : ""}>
            <EuiTooltip title="Refresh inspectors list">
                <i className="icon icon-reload icon-lgr icon-frame m-1 clickable" onClick={() => {
                    setIsLoading(true);
                    fetchRscUsers();
                    setIsLoading(false);
                }
                }></i>
            </EuiTooltip>
            <EuiLoading isLoading={isLoading} />
            <EuiAccordion
                id={"rscInspectorsAccordionsId" + mod}
                key={isLoading}
                style={{ width: "100%", margin: "10px" }}
                items={getInspectorsList()}
                noAutoClose={true}
            />
        </EuiRow>
    )
}
export default InspectorsList;