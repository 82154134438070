import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import DateUtils from "../../../utils/date-utils";
import PageHelper from "../../../utils/page-helper";
import { ToastContainer, toast } from 'react-toastify';
import SimpleExpansionPanel from "../../../components/expansionpanel";
import AdvancedGridList from "../../../components/advancedgridlist";
import Table from "../../../components/table";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MainHeader from "./mainHeader";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import EuiSwitch from "../../../eui-components/switch";

class ActivityDocuments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activitiesColumns:
                [
                    {
                        title: "Site Code", field: "siteCode",
                        render: (rowData) => <b>{rowData.siteCode}</b>,
                        cellStyle: { width: "10%", padding: 0 }
                    },
                    {
                        title: "Work Plan Name", field: "workPlanName",
                        render: (rowData) => <b>{rowData.workPlanName}</b>,
                        cellStyle: { width: "15%", padding: 0 }
                    },
                    { title: "Activity ID", field: "activityId", hidden: true },
                    {
                        title: "Activity Name", field: "activityName",
                        render: (rowData) => <b>{rowData.activityName}</b>,
                        cellStyle: { width: "75%", padding: 0 }
                    }
                ],
            subActivitiesColumns:
                [
                    {
                        title: "Activity Checklist Item", field: "name",
                        cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal" },
                        render: (rowData) => this.renderSubActivityDocuments(rowData)
                    },
                ],
            subActivityDocumentsColumns: [
                {
                    title: "Name", field: "name", render: (rowData) => this.props.renderName(rowData),
                    cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "30%" },
                    defaultSort: "asc",
                    editable: "never"
                },
                {
                    title: "Status", field: "sessionAcceptanceStatus", render: (rowData) => this.props.renderStatus(rowData),
                    cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "10%" },
                    editable: "never"
                },
                {
                    title: "Severity", field: "severityLevel", render: (rowData) => PageHelper.renderSeverityLevel(rowData.severityLevel),
                    cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "10%" },
                    editable: "never"
                },
                {
                    title: "Comments", field: "customerComments",
                    cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "15%" },
                    editable: "never"
                },
                {
                    title: "Ericsson Comments", field: "ericssonComments",
                    cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "15%" },
                },
                {
                    title: "Updated Date", render: (rowData) => DateUtils.toDateString(rowData.sessionAcceptedDate ?? rowData.sessionRejectedDate, "YYYY-MM-DD HH:mm"),
                    cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "10%" },
                    editable: "never"
                },
                {
                    title: "Updated By",
                    render: (rowData) => (!rowData.sessionAcceptedUser || rowData.sessionAcceptedUser == "" ? rowData.sessionRejectedUser : rowData.sessionAcceptedUser),
                    cellStyle: { padding: 0, fontSize: "0.8rem", whiteSpace: "normal", width: "10%" },
                    editable: "never"
                },
                {
                    title: "Actions", render: (rowData) => {
                        return rowData.sessionAcceptanceStatus == 3 ? < label key={rowData.id + "attach"} style={{ paddingLeft: 8 }
                        } htmlFor={"uploadDocumentSubActivityApprove" + rowData.id} >
                            <input
                                style={{ display: "none" }}
                                id={"uploadDocumentSubActivityApprove" + rowData.id}
                                name="documentPath"
                                type="file"
                                multiple
                                onChange={e => this.handleFiles(e, rowData)}
                            />
                            <Tooltip id="addFileApprove" title="Add Document">
                                <AttachFileIcon style={{ fontSize: 20, paddingTop: 4, paddingLeft: 3 }} />
                            </Tooltip>
                        </label>
                            :
                            null
                    },
                    hidden: this.props.sessionData.approveSession.status === 2 || this.props.sessionData.approveSession.viewOnly,
                },
            ],
            selectedRow: { activityId: 0 },
            tableRef: React.createRef(),
            approveExcludeSwitchRef: this.props.sessionData.approveSession.status === 2 ? false : true,
            viewMod: 3,
            expandAllDetailPanel: false
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (this.props.expandActivities !== prevProps.expandActivities) {
            this.expandAllActivitiesDetailPanel();
        }
    }

    renderSubActivityDocuments = (rowData) => {
        return <SimpleExpansionPanel
            key={rowData.id + "_subActivity"}
            id={"approveSubactivityDocumentsAccordion" + rowData.id}
            title={PageHelper.getDetailPanelTitle(rowData.name, rowData.opened)}
            opened={rowData.opened}
            onChange={() => this.togglePanel(rowData)}
            content={<EuiRow>
                {this.setView(rowData)}
            </EuiRow>
            }
        />
    }

    setView = (rowData) => {
        if (this.state.viewMod === 1) {
            return <AdvancedGridList
                tileData={this.getDocumentsByMod(rowData.documents)}
                selectedDocument={(tile) => this.props.setSelectedDocument(tile)}
                onClickApprove={() => this.props.handleDialog(2)}
                onClickReject={() => this.props.handleDialog(3)}
                onClickPending={() => this.props.handlePending(1, 1)}
                viewOnly={this.props.sessionData.approveSession.viewOnly}
                onClickImage={(tile) => this.props.selectAndOpenDocument(tile)}
                type={1}
            />
        } else {
            return <Table
                key={rowData.id + "obseleteTable"}
                columns={this.state.subActivityDocumentsColumns}
                container={{ style: { background: "inherit" } }}
                data={this.getDocumentsByMod(rowData.documents)}
                editable={
                    this.props.sessionData.approveSession.status === 4 || this.props.sessionData.approveSession.status === 101 ?
                        {
                            isEditHidden: rowDataDocument => ![4, 101].includes(rowDataDocument.sessionAcceptanceStatus),
                            isDeleteHidden: rowDataDocument => ![4, 101].includes(rowDataDocument.sessionAcceptanceStatus),
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        const dataUpdate = [...rowData.documents];
                                        
                                        const index = dataUpdate.findIndex(item => item.tableData && item.tableData.editing === "update" && item.tableData.id == oldData.tableData.id);
                                        dataUpdate[index] = newData;
                                        this.updateActivityDocumentList(rowData, dataUpdate);
                                        resolve();
                                    }, 100);
                                }),
                            onRowDelete: oldData =>
                                new Promise((resolve, reject) => {
                                    setTimeout(() => {
                                        
                                        let dataDelete = [...rowData.documents];
                                        const index = dataDelete.findIndex(item => item.tableData && item.tableData.editing === "delete" && item.tableData.id == oldData.tableData.id);
                                        dataDelete.splice(index, 1);
                                        this.updateActivityDocumentList(rowData, dataDelete, oldData);
                                        resolve();
                                    }, 100);
                                })
                        }
                        :
                        {}
                }
                onRowClick={(event, rowData) => this.props.setSelectedDocument(rowData)}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    paging: false,
                    minBodyHeight: 50,
                    rowStyle: (rowData) => this.props.renderRow(rowData),
                    header: false,
                    showTextRowsSelected: false,
                    selection: true
                }}
                actions={[
                    {
                        tooltip: 'Download All Selected Documents',
                        icon: () => <i className="icon icon-download-save"></i>,
                        onClick: (evt, data) => PageHelper.downloadFiles(data, rowData)
                    }
                ]}
                tableRef={rowData.tableRef}
            >
            </Table>
        }

    }

    togglePanel = (rowData) => {
        this.setState({ selectedRow: rowData }, () => {
            let activities = [...this.props.activities]
            if (activities.length > 0) {
                activities.map(item => {
                    if (item.activityId == rowData.activityId) {
                        if (item.subActivities.length > 0) {
                            item.subActivities.map(subItem => {
                                if (subItem.id == rowData.id) {
                                    subItem.opened = subItem.opened == "opened" ? "" : "opened";
                                } else {
                                    subItem.opened = "";
                                }
                            })
                        }
                    }
                });
            }

            this.props.setActivities(activities);
        })
    }

    getSubActivities = (rowDataActivity) => {
        if (rowDataActivity && rowDataActivity.subActivities && rowDataActivity.subActivities.length > 0) {

            if (this.state.approveExcludeSwitchRef) {
                if (this.state.viewMod !== 2) {
                    return rowDataActivity.subActivities.filter(item => item.documents &&
                        item.documents.length > 0 &&
                        item.documents.filter(doc => doc.sessionAcceptanceStatus !== 2).length > 0
                    );
                } else {
                    return rowDataActivity.subActivities.filter(item => item.documents &&
                        item.documents.length > 0 &&
                        item.documents.filter(doc => doc.sessionAcceptanceStatus !== 2 && doc.type === 2).length > 0
                    );
                }
            } else {
                if (this.state.viewMod !== 2) {
                    return rowDataActivity.subActivities.filter(item => item.documents &&
                        item.documents.length > 0
                    );
                } else {
                    return rowDataActivity.subActivities.filter(item => item.documents &&
                        item.documents.length > 0 &&
                        item.documents.filter(doc => doc.type === 2).length > 0
                    );
                }
            }
        } else {
            return [];
        }
    }

    renderRowActivity = (rowData) => {
        var selected = (this.state.selectedRow && this.state.selectedRow.activityId === rowData.activityId);
        return {
            background: selected ? '#1174E6' : (this.props.navBarData.theme === "light" ? "silver" : '#353535'),
            color: selected ? "white" : "inherit"
        };
    }

    changeAllStatus = (status) => {
        let activities = [...this.props.activities];
        if (activities.length > 0) {
            activities.map(item => {
                if (item.subActivities.length > 0) {
                    item.subActivities.map(subItem => {
                        if (subItem.documents.length > 0) {
                            subItem.documents.map(document => {
                                document.sessionAcceptanceStatus = status;
                                document.severityLevel = status == 2 ? 1 : 4;
                                document.sessionAcceptedDate = status == 2 ? new Date() : null;
                                document.sessionAcceptedUser = status == 2 ? this.props.userData.userData.username : "";
                                document.sessionRejectedDate = status == 3 ? new Date() : null;
                                document.sessionRejectedUser = status == 3 ? this.props.userData.userData.username : "";
                            });
                        }
                    })
                }
            });
        }

        let allDocument = [...this.props.allDocument];
        if (allDocument.length > 0) {
            allDocument.map(document => {
                document.sessionAcceptanceStatus = status;
                document.severityLevel = status == 2 ? 1 : 4;
                document.sessionAcceptedDate = status == 2 ? new Date() : null;
                document.sessionAcceptedUser = status == 2 ? this.props.userData.userData.username : "";
                document.sessionRejectedDate = status == 3 ? new Date() : null;
                document.sessionRejectedUser = status == 3 ? this.props.userData.userData.username : "";
            })
        }
        this.props.setActivities(activities);
        this.props.setAllDocument(allDocument);
    }

    handleFiles = (e, rowData) => {
        const files = [...e.target.files];

        
        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            toast.error(fileSize, { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            return;
        }

        PageHelper.getCoordinates();
        this.props.uploadAndInsertItems(rowData, files, this.props.addSubActivityDocument, PageHelper.mapActivityDocuments);
    };

    getDocumentsByMod = (documents) => {
        const mod = this.state.viewMod;
        const switchRef = this.state.approveExcludeSwitchRef;

        if (mod !== 2) {
            if (!switchRef) {
                return documents;
            } else {
                return documents.filter(item => item.sessionAcceptanceStatus !== 2);
            }
        } else {
            if (!switchRef) {
                return documents.filter(item => item.type === 2);
            } else {
                return documents.filter(item => item.sessionAcceptanceStatus !== 2 && item.type === 2);
            }
        }
    }

    updateActivityDocumentList = (rowData, newList, oldData = null) => {
        let activity = this.props.activities.find(item => item.activityId == rowData.activityId);
        if (activity) {
            if (activity.subActivities && activity.subActivities.length > 0) {
                let subActivities = [...activity.subActivities];
                const i = subActivities.findIndex(item => item.id == rowData.id);
                let subActivity = { ...rowData };
                subActivity.documents = [...newList];
                subActivities[i] = subActivity;

                let activities = [...this.props.activities];
                const index = activity.tableData.id;
                activity.subActivities = [...subActivities];
                activities[index] = activity;

                let deletedActivityDocuments = [...this.props.deletedActivityDocuments];
                if (oldData) {
                    let deleteData = { ...oldData };
                    deleteData.sessionDeleteDate = new Date();
                    deleteData.sessionDeleteUser = this.props.userData.userData.userId;
                    deletedActivityDocuments = [...deletedActivityDocuments, deleteData];
                }

                this.props.setActivities(activities);
                this.props.setDeletedActivityDocuments(deletedActivityDocuments);
            }
        }
    }

    toogleAllDetailPanel = () => {
        this.setState({ expandAllDetailPanel: !this.state.expandAllDetailPanel }, () => {
            let activities = [...this.props.activities]
            if (activities.length > 0) {
                activities.map(item => {
                    if (item.subActivities.length > 0) {
                        item.subActivities.map(subItem => {
                            if (this.state.expandAllDetailPanel == true) {
                                subItem.opened = "opened";
                            } else {
                                subItem.opened = "";
                            }
                        })
                    }
                });
            }
            this.props.setActivities(activities);
        });
    }

    expandAllActivitiesDetailPanel = () => {
        if (this.state.tableRef.current) {
            
            if (this.props.activities.length > 0) {
                this.props.activities.map((item, index) => {
                    this.state.tableRef.current.onToggleDetailPanel([index], this.state.tableRef.current.props.detailPanel);
                });
            }
        }
    }

    render() {
        return (
            <EuiRow>
                <Table
                    key="activity-documents-table"
                    container={{ style: { background: "inherit" } }}
                    columns={this.state.activitiesColumns}
                    data={this.props.activities}
                    detailPanel={rowDataActivity => {
                        return (
                            <Table
                                key={rowDataActivity.activityId}
                                columns={this.state.subActivitiesColumns}
                                container={{ style: { paddingLeft: 15, background: "inherit", paddingLeft: 20 } }}
                                data={this.getSubActivities(rowDataActivity)}
                                isLoading={this.props.isLoading}
                                //onRowClick={(event, rowData) => this.setState({ selectedRow: rowDataActivity })}
                                options={{
                                    search: false,
                                    toolbar: false,
                                    header: false,
                                    paging: false
                                }}
                                tableRef={rowDataActivity.tableRef}
                            >
                            </Table>
                        )
                    }}
                    onRowClick={(event, rowData, togglePanel) => { this.setState({ selectedRow: rowData }); togglePanel(); }}
                    isLoading={this.props.isLoading}
                    options={{
                        minBodyHeight: 50,
                        search: false,
                        rowStyle: (rowData) => this.renderRowActivity(rowData),
                        paging: false,
                        header: false
                    }}
                    tableRef={this.state.tableRef}
                    toolbar={{
                        leftCustom:
                            <div className="flex-start-content w-10">
                                {
                                    this.props.allDocument && this.props.allDocument.length < 200
                                        ?
                                        <Tooltip id="allExpandCollapse" title="Expand/Collapse All">
                                            <IconButton style={{ margin: 10, marginLeft: 5 }} aria-label="expandCollapseAll" id="allExpandCollapse" onClick={this.toogleAllDetailPanel}>
                                                <i className={"icon " + (this.state.expandAllDetailPanel == true ? "icon-chevron-down" : "icon-chevron-up")}></i>
                                            </IconButton>
                                        </Tooltip>
                                        :
                                        null
                                }
                                <EuiSwitch checked={this.state.approveExcludeSwitchRef}
                                    name="excludeAcceptedSwitchActivity"
                                    onChange={(e) => this.setState({ approveExcludeSwitchRef: e.target.checked })}
                                    dataEnabled="Excluding Accepted"
                                    dataDisabled="Including Accepted"
                                    className="p-1"
                                />
                                {
                                    //<Tooltip id="excludeSwitchActivity" title="Exclude Accepted">
                                    //    <FormControlLabel
                                    //        style={{ marginTop: 10, marginLeft: 2, fontSize: 14 }}
                                    //        control={
                                    //            <Switch
                                    //                checked={this.state.approveExcludeSwitchRef}
                                    //                onChange={(e) => this.setState({ approveExcludeSwitchRef: e.target.checked })}
                                    //                name="excludeAcceptedSwitchActivity"
                                    //                color="primary"
                                    //                size="small"
                                    //            />
                                    //        }
                                    //        label={<span style={{ fontSize: 12 }}>Exclude Accepted</span>}
                                    //    />
                                    //</Tooltip>
                                }
                            </div>,
                        custom:
                            <div className="flex-end-content w-10">
                                {this.props.sessionData.approveSession.viewOnly ||
                                    this.props.sessionData.approveSession.status === 4 ||
                                    this.props.sessionData.approveSession.status === 101 ? null :
                                    (
                                        <div>
                                            <Tooltip id="allAccept" title="Accept All">
                                                <IconButton style={{ marginRight: 10 }} aria-label="add" id="acceptAll" onClick={() => this.changeAllStatus(2)}>
                                                    <CheckIcon style={{ fontSize: 22 }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip id="allReject" title="Reject All">
                                                <IconButton style={{ marginRight: 10 }} aria-label="reject" id="rejectAll" onClick={() => this.changeAllStatus(3)}>
                                                    <CloseIcon style={{ fontSize: 22 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                                {this.props.sessionData.approveSession.status === 4 ? null :
                                    <Tooltip id="viewComfy" title="Image View">
                                        <IconButton style={{ marginRight: 10, background: (this.state.viewMod == 1 ? "silver" : "inherit") }} aria-label="comfyView" id="comfyView"
                                            onClick={() => { this.setState({ viewMod: 1 }); }}>
                                            <i className="icon icon-view-tiles" style={{ fontSize: 22 }} ></i>
                                        </IconButton>
                                    </Tooltip>
                                }
                                <Tooltip id="viewTile" title="Video View">
                                    <IconButton style={{ marginRight: 10, background: (this.state.viewMod == 2 ? "silver" : "inherit") }} aria-label="tileView" id="tileView" onClick={() => this.setState({ viewMod: 2 })}>
                                        <VideocamOutlinedIcon style={{ fontSize: 22 }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip id="viewList" title="All View">
                                    <IconButton style={{ marginRight: 10, background: (this.state.viewMod == 3 ? "silver" : "inherit") }} aria-label="listView" id="listView" onClick={() => this.setState({ viewMod: 3 })}>
                                        <i className="icon icon-view-list" style={{ fontSize: 22 }} ></i>
                                    </IconButton>
                                </Tooltip>
                            </div>,
                        toolbarHeader: <MainHeader />
                    }}
                >
                </Table>
                <ToastContainer enableMultiContainer containerId={'AD'} autoClose={2500} />
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ sessionData: state.session, userData: state.user, navBarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityDocuments);
