import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import { withLayout } from "../../layout";
import ApiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import DeletionRequestsTable from "./DeletionRequestsTable";


function DeletionRequests(props) {
const [isLoading, setIsLoading] = useState(false)
const [deleteRequestsData, setDeleteRequestsData] = useState([])


    useEffect(() => {
        GetProjectDeletionDetails()
             if (props.navbarData.navBarView) {
                 props.toggleNavbar();
         }
    }, [])
    const rightPanel = null;
    const midPanel = { title: "", content: <DeletionRequestsTable deleteRequestsData={deleteRequestsData} isLoading={isLoading} setDeleteRequestsData={setDeleteRequestsData}/>, hideHeader: true };

const GetProjectDeletionDetails=() => {
    setIsLoading(true)

    ApiClient.post("Projects/GetProjectDeletionDetails")
    .then(response => {
        setIsLoading(false)
        setDeleteRequestsData(response.data)
    })
    .catch(error => {
        console.error(error);
        setDeleteRequestsData([])
        setIsLoading(false)
        toast.error("An error occured! Please try again", { toastId: "documentError", containerId: 'T', position: toast.POSITION.TOP_CENTER });
    })
}

  return (
    <div>
  <EuiMultiPanelTile key={props.activityTemplateData.templates} id="checklistItemsPanel" midPanel={midPanel} rightPanel={rightPanel} />

    </div>
  )
}







const mapStateToProps = state => ({ userData: state.user, aspResourcesData: state.asp, navbarData: state.navigation, activityTemplateData: state.activityChecklists });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        changeMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(DeletionRequests)));