import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import ResourcesSearch from "./resourcesSearch";
import constants from "../../utils/constants";
import ResourceDetails from "./resourcesDetails";
import CreateEditResource from "./createEditResource";
import { ToastContainer } from 'react-toastify';
import PageHelper from "../../utils/page-helper";
import SasHelper from "../../utils/sas-helper";

class AspResources extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        PageHelper.getCoordinates();
    }

    renderMod = () => {
        if (this.props.aspResourcesData.createEditMode) {
            return <CreateEditResource key={this.props.aspResourcesData.jobs}/>
        }
        if (this.props.aspResourcesData.mod === constants.pageMods.LIST) {
            return <ResourcesSearch />;
        } else if (this.props.aspResourcesData.mod === constants.pageMods.VIEW_ONLY) {
            return <ResourceDetails />
        }
    }

    render() {
        return (<div>
            {this.renderMod()}
            < ToastContainer enableMultiContainer containerId={'WD'} autoClose={2500} />
        </div>

        );

    }

}

const mapStateToProps = state => ({ userData: state.user, aspResourcesData: state.asp, navbarData: state.navigation, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setJobs: (jobs) => dispatch({ type: "SET_JOBS", payload: jobs }),
        setFilteredResources: (filteredResources) => dispatch({ type: "SET_FILTERED_RESOURCES", payload: filteredResources }),
        setUserList: (users) => dispatch({ type: "SET_USER_LIST", payload: users }),
        setResourceFilter: (filter) => dispatch({ type: "SET_RESOURCE_FILTER", payload: filter }),
        setResourceCompanies: (companies) => dispatch({ type: "SET_RESOURCE_COMPANIES", payload: companies }),
        setResourceCountries: (countries) => dispatch({ type: "SET_RESOURCE_COUNTRIES", payload: countries }),
        setCertificates: (certs) => dispatch({ type: "SET_CERTIFICATES", payload: certs }),
        setIDTypes: (types) => dispatch({ type: "SET_ID_TYPES", payload: types }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(AspResources)));