import { Box, Checkbox, FormControlLabel, GridList, GridListTile } from '@material-ui/core';
import { Delete, DeleteOutline } from '@material-ui/icons';
import { useDispatch } from "react-redux";

import { isEmpty, isNull } from 'lodash';
import React, { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HoverableButton from '../../components/Button/HoverableButton';
import ApiClient from '../../utils/api-client';
import CommonHelper from "../../utils/common-helper";
import constants from '../../utils/constants';
import VehicleHelper from '../../utils/vehicle-helper';
import SasHelper from '../../utils/sas-helper';


const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <Checkbox
          indeterminate={indeterminate}
          color="secondary"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);


export default function ImagesPreview(props) {
  const dispatch = useDispatch();

  const { tileData, onClickImage, GetVehicleInspectionDetails, setIsLoading,inspection ,inspectionInstanceStatusId} = props;
  const [listCheckListInspection, setListCheckListInspection] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);



  const handleSelectAll = (event) => {
    setSelectedCount(tileData.length)
    tileData.forEach((item) => {
      setListCheckListInspection((state) => ({
        ...state,
        [item.id]:
        {
          id: item.id,
          name: item.name,
          inspectionChecklistItemId: item.inspectionChecklistItemId,
          documentPath: item.documentPath,
        }
      }))
    });
    

    if (!event.target.checked) {
      setSelectedCount(0)
      setListCheckListInspection([])

    }

  };
  const handleChange = (event, item) => {
    setListCheckListInspection((state) => ({
      ...state,
      [item.id]: state[item.id]
        ? null
        : {
          id: item.id,
          name: item.name,
          inspectionChecklistItemId: item.inspectionChecklistItemId,
          documentPath: item.documentPath,
        }
    }));
    if (event.target.checked) {
      setSelectedCount(selectedCount + 1)
    } else {
      setSelectedCount(selectedCount - 1)
      delete listCheckListInspection[item.id];
      deleteObjectFromList()

    }

  };

  function deleteObjectFromList() {
    Object.keys(listCheckListInspection).forEach(key => {
      if (isNull(listCheckListInspection[key])) {
        delete listCheckListInspection[key];
      }
    });
    setListCheckListInspection(listCheckListInspection)
  }


  const deleteVehiclesInspectionChecklistEvidence = () => {

    const list = []
    setIsLoading(true)

    Object.values(listCheckListInspection).forEach(item => { list.push(item) });
    ApiClient.post("Vehicle/DeleteVehiclesInspectionChecklistEvidence", list, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (response.data.success) {
        CommonHelper.createNotification({ title: response.data.responseDetail, icon: "icon-cross", timeout: 5000 });
        setIsLoading(false)
        GetVehicleInspectionDetails()
      }

      URL.revokeObjectURL(listCheckListInspection);
    }).catch(error => {
      setIsLoading(false);
      dispatch({ type: "SET_INSPECTION_EVIDENCES", payload: [] });
      CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
    });
  }

  return (
    <div>

      <div style={{ fontSize: 20, marginTop: -34 }}>
        <FormControlLabel
          label="Select All"
          control={
            <IndeterminateCheckbox
              checked={tileData.length > 0 &&
                selectedCount === tileData.length
              }
              indeterminate={selectedCount > 0 &&
                selectedCount < tileData.length}
              onChange={handleSelectAll}
            />
          }
        />
       { inspectionInstanceStatusId !== VehicleHelper.vehicleStatusLookup['Approved'] &&

       <HoverableButton
          disabled={selectedCount === 0}
          onClick={deleteVehiclesInspectionChecklistEvidence}
          tooltipTitle={"delete selecte img"}
          colorText={"#1174E6"}
          backgroundColor={"GhostWhite"}
          selectedNumber={selectedCount}
          selected={!isEmpty(selectedCount)}
          icon={<Delete />}
          outlinedIcon={<DeleteOutline />}
        >
        </HoverableButton>
       
        }

      </div>
      <GridList cellHeight={216} spacing={2} cols={3} >
        {tileData.map((tile, index) => (
          <GridListTile
            key={tile.id}
            cols={1}
            rows={1}
            style={{ width: "auto", margin: "0px 5px" }}
          >
            {
              <div style={{marginTop: 4, marginLeft: 4, paddingLeft: 4, position: 'absolute', background: 'white', width: '18px', height: '20px' }}>
                <Box 
                  style={{marginTop: '-4px',  display: 'flex', flexDirection: 'column' }}
                  sx={4}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!isEmpty(listCheckListInspection[tile.id])}
                        onChange={(e) => handleChange(e, tile)}
                      />
                    }
                  />
                </Box>
              </div>
            }
            <LazyLoadImage
              id={tile.id}
              src={SasHelper.addSasKey(tile.posterPath)}
              alt={tile.name}
              style={
                {
                  display: 'block',
                  maxWidth: '200px',
                  maxHeight: '200px',
                  width: 'auto',
                  height: 'auto',
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  padding: '5px',
                  background: isEmpty(listCheckListInspection[tile.id])? 'inherit' : '#1174e6',
                  "&:hover": {
                    boxShadow: '0 0 2px 1px #1174e6'
                  },
                }
              }
              onClick={() => onClickImage && onClickImage(tile)} />

          </GridListTile>
        ))}
      </GridList>
    </div>
  )
}
