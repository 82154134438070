import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import constants from "../../utils/constants";
import EuiTile from "../../eui-components/tile";
import EuiTextField from "../../eui-components/textfield";
import EuiRow from "../../eui-components/row";
import DateUtils from "../../utils/date-utils";
import AdvancedGridList from "../../components/advancedgridlist";
import DocumentDialogModal from "../../components/documentDialogModal";
import SasHelper from "../../utils/sas-helper";


class TicketInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            editMod: constants.actionMods.EDIT,
            openDialogPreview: false,
            selectedDocument: {id: 0}
        }
    }

    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
    }

    render() {

        return (
            <EuiTile fullscreen>

                <EuiRow>
                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="ticketTitle"
                        name="title"
                        label="Title"
                        value={this.props.ticketData.currentTicket.title}/>

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="ticketDesc"
                        name="description"
                        label="Description"
                        value={this.props.ticketData.currentTicket.description} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="ticketClassification"
                        name="classification"
                        label="Classification"
                        value={this.props.ticketData.classificationList.find(x => x.id == this.props.ticketData.currentTicket.ticketClassification)?.name} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="severity"
                        name="severity"
                        label="Severity"
                        value={constants.ticketSeverity.find(x => x.value == this.props.ticketData.currentTicket.severity)?.text} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="ticketStatus"
                        name="ticketStatus"
                        label="Status"
                        value={constants.ticketStatus.find(x => x.value == this.props.ticketData.currentTicket.status)?.text} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="contactAddress"
                        name="contactAddress"
                        label="Contact Address"
                        value={this.props.ticketData.currentTicket.contactAddress} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="createDate"
                        name="createDate"
                        label="Create Date"
                        value={DateUtils.toDateString(this.props.ticketData.currentTicket.createDate, "YYYY-MM-DD HH:mm")} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="countryName"
                        name="countryName"
                        label="Country"
                        value={this.props.ticketData.currentTicket.countryName} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="customerName"
                        name="customerName"
                        label="Customer"
                        value={this.props.ticketData.currentTicket.customerName} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="projectName"
                        name="projectName"
                        label="Project"
                        value={this.props.ticketData.currentTicket.projectName} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="siteName"
                        name="siteName"
                        label="Site"
                        value={this.props.ticketData.currentTicket.siteName} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="workplanName"
                        name="workplanName"
                        label="Workplan"
                        value={this.props.ticketData.currentTicket.workplanName} />

                    <EuiTextField className="p-1 w-5"
                        fullwidth
                        readOnly
                        style={{ padding: 0 }}
                        labelstyle={{ color: "gray" }}
                        id="activityName"
                        name="activityName"
                        label="Activity"
                        value={this.props.ticketData.currentTicket.activityName} />


                </EuiRow>

                <EuiRow>
                    <AdvancedGridList
                        tileData={this.props.ticketData.currentTicket.ticketDocuments}
                        viewOnly={true}
                        onClickImage={(tile) => { this.setState({ selectedDocument: tile, openDialogPreview: true}) }}
                        type={4}
                    />
                </EuiRow>

                <DocumentDialogModal openDialogPreview={this.state.openDialogPreview}
                    handleClose={() => this.setState({ openDialogPreview: false })}
                    id="ticketDocumentView"
                    showLocation={false}
                    selectedDocument={{ ...this.state.selectedDocument, documentPath: SasHelper.addSasKey(this.state.selectedDocument.documentPath), type: this.state.selectedDocument.type }} // 1 image, 2 video, 3 pdf
                >
                </DocumentDialogModal>

                <ToastContainer enableMultiContainer containerId={'WD'} autoClose={2500} />

            </EuiTile>);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ticketData: state.ticket, sasToken: state.complaints.sasToken  });
const mapDispatchToProps = dispatch => {
    return {
        setTickets: (tickets) => dispatch({ type: "SET_TICKETS", payload: tickets }),
        setTicketPageMod: (mod) => dispatch({ type: "SET_TICKET_PAGE_MOD", payload: mod }),
        setCurrentTicket: (ticket) => dispatch({ type: "SET_CURRENT_TICKET", payload: ticket }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TicketInfo));