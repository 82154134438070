import React, { useState, useEffect } from 'react'
import { useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DialogModal from '../../../components/dialog';
import EuiTextField from '../../../eui-components/textfield';
import ApiClient from '../../../utils/api-client';
import CommonHelper from '../../../utils/common-helper';
import constants from '../../../utils/constants';
import { Logs } from '../../../utils/Log';
import PageHelper from '../../../utils/page-helper';
import VehicleHelper from '../../../utils/vehicle-helper';
import VehicleList from "../vehicle-management/vehicleList";
import VehicleTabs from './vehicleTabs';

const VehicleManagement = (props) => {
    useEffect(() => {
        PageHelper.getCoordinates()
    }, []);
    const mod = useSelector(state => state.vehicle.mod);
    const userId = useSelector(state => state.user.userData.userId);
    const user = useSelector(state => state.user.userData);

    const selectedAsp = useSelector(state => state.asp.selectedASP);
    const dispatch = useDispatch();

    const [isLoading, changeIsLoading] = useState(false);
    const [modifiedUserComment, setModifiedUserComment] = useState("");
    const [statusDialog, openStatusDialog] = useState({ id: 0, open: false, type: VehicleHelper.vehicleStatusLookup['Under Review'] });

    const statusComments = {
        5: { comment: "Inactivate Vehicle", buttonType: "warning" },
        2: { comment: "Send Vehicle to Ericsson Review", buttonType: "primary" },
        3: { comment: "Approve Vehicle", buttonType: "primary" },
        4: { comment: "Reject Vehicle", buttonType: "warning" },
        1: { comment: "Revise Vehicle", buttonType: "primary" }
    }

    const getVehicleList = useCallback(() => {
        if(selectedAsp.id)
        {
            changeIsLoading(true);
            ApiClient.post("Vehicle/GetVehiclesOfAspCompany", { id: selectedAsp.id })
                .then(response => {
                    const  vehicles=response.data
                    VehicleHelper.mapVehiclesAspCompany(vehicles);
                    dispatch({ type: "SET_VEHICLE_LIST", payload: vehicles });
                    changeIsLoading(false);
                }).catch(error => {
                    console.error(error);;
                    changeIsLoading(false);
                });
        }
    }, [selectedAsp.id]);

    const changeVehicleStatus = useCallback(() => {
        changeIsLoading(true);
        
        const newData={};
        const oldData={};
        const logInfo={};
        logInfo.LogName= "Vehicle";
        logInfo.userId=parseInt(userId);
        logInfo.LogObjectId=statusDialog.id;// id of vehicle
        logInfo.LogAction=statusDialog.type===2?
          VehicleHelper.vehicleAction.SUBMITTED : 
          statusDialog.type===1?VehicleHelper.vehicleAction.UNRETIRED 
            : VehicleHelper.vehicleAction.RETIRED ;
        logInfo.LogTypeId=VehicleHelper.LogTypeId['VEHICLE'];

        oldData.vehicleStatus=1;
        oldData.CreatedUserName=user.name;
        newData.vehicleStatus=statusDialog.type;
        newData.submitedUserName=user.name;
        newData.Comment=modifiedUserComment;

        Logs(logInfo,{
          oldData,
          oldDataDate:new Date(),
          newData,  
       })
        ApiClient.post("Vehicle/ChangeVehicleStatus", {
            id: statusDialog.id,
            vehicleStatus: statusDialog.type,
            modifiedUserid: parseInt(userId),
            modifiedUserComment
        }).then(response => {
            if (response?.data?.success) {
                CommonHelper.createNotification({ title: response?.data?.responseDetail, icon: "icon-check", timeout: 5000 });
                openStatusDialog({ id: 0, open: false, type: VehicleHelper.vehicleStatusLookup['Under Review'] });
                setModifiedUserComment("");
                dispatch({ type: "CHANGE_VEHICLE_MOD", payload: constants.pageMods.LIST });
                getVehicleList();
            } else {
                CommonHelper.createNotification({ title: response?.data?.errorMessage, icon: "icon-cross", timeout: 5000 });
                changeIsLoading(false);
            }
        }).catch(error => {
            console.error(error);;
            changeIsLoading(false);
            CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
        })
    }, [statusDialog, modifiedUserComment]);

    const renderMod = () => {
        if (mod === constants.pageMods.LIST) {
            return <VehicleList openStatusDialog={openStatusDialog} getVehicleList={getVehicleList} isLoading={isLoading} />;
        } else {
            return <VehicleTabs />;
        }
    }

    return (
        <>
            {renderMod()}
            <DialogModal
                buttonName="Confirm"
                title={statusComments[statusDialog.type]?.comment}
                content={<><p>{"Are you sure that you want to " + statusComments[statusDialog.type]?.comment + "?"}</p>
                    <EuiTextField className="p-1"
                        fullwidth
                        required
                        multiLine={true}
                        id="vehicleModifiedUserCommentDialogId"
                        name="modifiedUserComment"
                        label="Comment"
                        value={modifiedUserComment}
                        onChange={(e) => setModifiedUserComment(e.target.value)}
                    />
                </>}
                handleClose={() => openStatusDialog({ id: 0, open: false, type: VehicleHelper.vehicleStatusLookup['Under Review'] })}
                id="vehicleStatusDialogId"
                onClick={changeVehicleStatus}
                open={statusDialog.open}
                type={statusComments[statusDialog.type]?.buttonType}
            />
        </>
    )
}
export default VehicleManagement;