import React from 'react';

class EuiBreadcrumb extends React.Component {
    constructor(props) {
        super(props);

        const breadcrumbElements = document.querySelectorAll(this.props.id);
        if (breadcrumbElements) {
            Array.from(breadcrumbElements).forEach(breadcrumbElement => {
                const iconElement = breadcrumbElement.querySelector('.icon');
                const menuElement = breadcrumbElement.querySelector('.menu');

                iconElement.addEventListener('click', () => {
                    if (menuElement.classList.contains('visible')) {
                        menuElement.classList.remove('visible');
                    } else {
                        menuElement.classList.add('visible');
                    }
                });

                window.addEventListener('click', event => {
                    const target = event.target ? event.target : event.srcElement;
                    if (target !== breadcrumbElement && !breadcrumbElement.contains(target)) {
                        menuElement.classList.remove('visible');
                    }
                });
            });
        }
    }

    render() {
        return (
            <div className={"breadcrumb short " + (this.props.className ?? "")} id={this.props.id} style={this.props.style}>
                <span className="more" onClick={() => this.props.onClickParent && this.props.onClickParent()}>
                    <i className="icon"></i>
                    <div className="menu">
                        <div className="item">{this.props.parentName}</div>
                    </div>
                </span>
                <span className="link" onClick={() => this.props.onClickParent && this.props.onClickParent()}>{this.props.parentName}</span>
                <span className="separator"></span>
                <span className="link">{this.props.childName}</span>
            </div>);
    }
}

export default EuiBreadcrumb;