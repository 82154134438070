import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import EuiTextField from "../../../eui-components/textfield";
import ApiClient from "../../../utils/api-client";
import AutoCompleteSelect from "../../../components/autocompleteselect";
import AutoCompleteMultiSelect from "../../../components/autocompletemultiselect";
import { Tooltip, IconButton } from "@material-ui/core";
import DialogModal from "../../../components/dialog";
import CreateEditWorkflow from "../../workflow/my-workflows/createEditWorkflow";
import EuiCheckbox from "../../../eui-components/checkbox";
import EuiDatepicker from "../../../eui-components/datepicker";
import EuiTimepicker from "../../../eui-components/timepicker";
import constants from "../../../utils/constants";
import EuiSwitch from "../../../eui-components/switch";
import EuiTooltip from "../../../eui-components/tooltip";
import FormField from "../../../eui-components/formfield";
import { cloneDeep } from "lodash";

const userType = { USER: 1, CUSTOMER: 2 };

class FirstStepContent extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            session: {
                id: 0,
                name: "",
                customerId: {
                    value: 0, text: "---Select Customer---",
                    customerUsers: [],
                    projects: [{ value: 0, text: "---Select Project---" }],
                    workflows: [{ id: 0, name: "---Select Workflow Template---" }]
                },
                customerName: "",
                projectName: "",
                workflowId: { id: 0, name: "---Select Workflow Template---" },
                customerUsers: [],
                responsibleUsers: [],
                siteCode: "",
                siteId: 0,
                siteModal: {},
                customerProjectName: "",
                scopeOfWorkDescription: "",
                customerScopeIdentification: " ",
                createDate: new Date(),
                createUser: this.props.userData.userData.userId,
                updateDate: null,
                updateUser: "",
                isLiveStream: 0,
                liveStreamStartDate: null,
                liveStreamStartTime: null,
                liveStreamEndDate: null,
                liveStreamEndTime: null,
                type: false,
                ecaType: false
            },
            customers: [],
            responsibleUsers: [],
            viewOnlyUsers: [],
            viewOnlyCustomers: [],
            openReviewWorkflow: false,
            isLoading: false,
            sites: [],
            openViewOnlyUserDialog: { open: false, type: userType.USER },
            selectedViewOnlyUsers: []
        }
    }
    componentDidMount() {
        this._isMounted = true;
        ApiClient.post("User/GetAllUsers", {}).then(response => {
            if (this._isMounted) {
                
                
                this.setState({
                    responsibleUsers: cloneDeep(response.data?.filter(u => constants.ericssonEmployeeRoles.includes(u.role))),
                    viewOnlyUsers: cloneDeep(response.data?.filter(u => (u.value + "") != constants.userRoles.CUSTOMER || (u.value + "") != constants.userRoles.TEAM_MEMBER))
                },
                    () => {
                        if (this.props.sessionData.sessionInfo.id == 0) {
                            const defaultResponsibleUser = this.state.responsibleUsers.find(item => String(item.value) == this.props.userData.userData.userId);
                            if (defaultResponsibleUser) {
                                this.setState(prevState => ({
                                    session: {
                                        ...prevState.session,
                                        responsibleUsers: [defaultResponsibleUser]
                                    }
                                }));
                            }
                        }
                    }
                );
            }
        }).catch(error => {
            console.error(error);;
        });

        ApiClient.post("Session/GetAllCustomer", {}).then(response => {
            if (this._isMounted) {
                this.setState({
                    customers: [{
                        value: 0, text: "---Select Customer---",
                        workflows: [{ id: 0, name: "---Select Workflow Template---" }],
                        customerUsers: []
                    }, ...response.data]
                }, () => {
                    if (this.props.sessionData.sessionInfo.customerId > 0) {
                        const customer = this.state.customers.find(customer => customer.value == this.props.sessionData.sessionInfo.customerId);
                        if (this.props.sessionData.sessionInfo.id > 0 && customer) {
                            let session = Object.assign(this.state.session, this.props.sessionData.sessionInfo);
                            
                            session.customerId = customer;
                            session.workflowId = customer.workflows && customer.workflows.find(item => item.id == (session.workflowId ?? 0));
                            session.workflowId.customerId = customer;
                            session.workflowId.countryId = customer.country;
                            this.setState({
                                session, responsibleUsers: [...this.state.responsibleUsers, ...(session.responsibleUsers?.filter(r => r.viewOnly) ?? [])],
                                viewOnlyCustomers: cloneDeep(customer.customerUsers ?? [])
                            },
                                () => this.props.setSessionInfo(this.state.session));
                        }
                    }
                });
            }
        }).catch(error => {
            console.error(error);;
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = (event) => {
        const target = event.target;
        
        const value = target.value ?? target.checked;
        
        const name = target.name;

        if (name === "customerId" && value) {
            this.setState(prevState => ({
                viewOnlyCustomers: cloneDeep(value?.customerUsers ?? []),
                session: {
                    ...prevState.session,
                    [name]: value,
                    customerName: value.text,
                    workflowId: this.getDefaultWorkflow(value.workflows, value),
                    customerUsers: [],
                    sites: [],
                    siteCode: "",
                    siteId: 0,
                }
            }), () => {
                this.props.setSessionInfo(this.state.session)
            });
        } else if (name === "workflowId" && value) {
            const newValue = { ...value };
            if (newValue.id !== 0) {
                newValue.customerId = this.state.session.customerId;
                newValue.countryId = this.state.session.customerId.country;
            }
            this.setState(prevState => ({
                session: {
                    ...prevState.session,
                    [name]: newValue,
                }
            }), () => this.props.setSessionInfo(this.state.session));
        } else if (name === "isLiveStream") {
            if (this.props.sessionData.sessionInfo.id > 0) {
                this.setState(prevState => ({
                    session: {
                        ...prevState.session,
                        [name]: value ? 1 : 0,
                    }
                }), () => { this.props.setSessionInfo(this.state.session); });
            } else {
                this.setState(prevState => ({
                    session: {
                        ...prevState.session,
                        [name]: value ? 1 : 0,
                        liveStreamStartDate: null,
                        liveStreamStartTime: null,
                        liveStreamEndDate: null,
                        liveStreamEndTime: null,
                    }
                }), () => { this.props.setSessionInfo(this.state.session);});
            }
        } else if (name === "siteCode" && value) {
            
            this.setState(prevState => ({
                session: {
                    ...prevState.session,
                    siteId: value.value,
                    siteCode: value.text,
                    siteModal: { value: value.value, text: value.text }
                }
            }), () => this.props.setSessionInfo(this.state.session));
        }
        else {
            this.setState(prevState => ({
                session: {
                    ...prevState.session,
                    [name]: value
                }
            }), () => { this.props.setSessionInfo(this.state.session); });
        }
    }

    getDefaultWorkflow = (workflows, customer) => {
        if (workflows && workflows.length > 0) {
            const defaultWorkflow = workflows.find(item => item.default) ?? { id: 0, name: "---Select Workflow Template---" };
            if (defaultWorkflow.id !== 0) {
                defaultWorkflow.customerId = customer;
                defaultWorkflow.countryId = customer.country;
            }
            return defaultWorkflow;
        }
        return { id: 0, name: "---Select Workflow Template---" };
    }

    reviewApproverUsers = () => {
        this.setState({ openReviewWorkflow: true });
    }

    handleRemoteChange = (e, value) => {
        if (!e || !value) {
            return;
        }
        const target = e.target;
        
        const filter = { customerId: this.state.session.customerId.value, code: value }
        
        if (value && value.length > 2) {
            this.setState({ isLoading: true }, () => {
                ApiClient.post("Workplan/GetSitesOfCustomer", filter)
                    .then(response => {
                        this.setState({ sites: response.data, isLoading: false });
                    }).catch(error => {
                        console.error(error);;
                        this.setState({ sites: [], isLoading: false });
                    });
            })
        }
    }

    handleViewOnlyChange = () => {
        const selectedViewOnlyUsers = [...this.state.selectedViewOnlyUsers];
        selectedViewOnlyUsers.map(item => {
            item.viewOnly = true;
        });

        if (this.state.openViewOnlyUserDialog.type === userType.USER) {
            this.setState(prevState => ({
                session: {
                    ...prevState.session,
                    responsibleUsers: [...this.state.session.responsibleUsers, ...selectedViewOnlyUsers],
                }
            }), () => { this.setState({ selectedViewOnlyUsers: [], openViewOnlyUserDialog: { open: false, type: 1 } }); this.props.setSessionInfo(this.state.session);});
        } else if (this.state.openViewOnlyUserDialog.type === userType.CUSTOMER) {
            this.setState(prevState => ({
                session: {
                    ...prevState.session,
                    customerUsers: [...this.state.session.customerUsers, ...selectedViewOnlyUsers],
                }
            }), () => { this.setState({ selectedViewOnlyUsers: [], openViewOnlyUserDialog: { open: false, type: 1 } }); this.props.setSessionInfo(this.state.session); });
        }
    }

    getViewOnlyOptions = () => {
        if (this.state.openViewOnlyUserDialog.type === userType.CUSTOMER) {
            return this.state.viewOnlyCustomers?.filter(item => !this.state.session.customerUsers?.map(r => r.value)?.includes(item.value));
        } else {
            const viewOnlyUsers = this.state.viewOnlyUsers?.filter(item => !this.state.session.responsibleUsers?.map(r => r.value)?.includes(item.value));
            return viewOnlyUsers;
        }
    }

    render() {
        return (
            <EuiRow disabled={this.props.sessionData.sessionInfo.viewOnly ? "disabled" : ""}>
                <div className="column sm-12 md-6 lg-6 xl-6 filled" >
                    <h3 style={{ fontWeight: 400, marginBottom: 5, marginTop: 5 }} >Session Information </h3>
                    <FormField><EuiTextField style={{ marginRight: "0px", width: "85%" }}
                        disabled={true}
                        id="sessionName"
                        label="Session Name*"
                        placeholder="Session name will be created automatically (Site Code + Session ID + Customer Scope Identification)"
                        name="name"
                        required
                        value={this.state.session.name || ""}
                        onChange={e => this.handleChange(e)}
                    />
                    </FormField>
                    <FormField className="w-10">
                        <label htmlFor="customerSites" style={{ color: "gray", fontSize: 14 }}>Site Code*</label>
                        <AutoCompleteSelect
                            disabled={(this.props.sessionData.sessionInfo.id > 0 || this.state.session.customerId.value === 0) ? true : false}
                            required={true}
                            style={{ width: "85%" }}
                            id="customerSites"
                            options={this.state.sites}
                            name="siteCode"
                            onChange={e => this.handleChange(e)}
                            onInputChange={(e, value) => this.handleRemoteChange(e, value)}
                            value={this.props.sessionData.sessionInfo.siteModal}
                            placeholder="Start typing site name, a dropdown with options will appear"
                            isLoading={this.state.isLoading}
                        />
                    </FormField>
                    <FormField className="flex-start-content">
                        <div style={{ width: "85%" }}>
                            <label htmlFor="sessionResponsiblePersons">Session Responsible People*</label>
                            <AutoCompleteMultiSelect
                                id="sessionResponsiblePersons"
                                name="responsibleUsers"
                                options={this.state.responsibleUsers}
                                value={this.state.session.responsibleUsers}
                                onChange={e => this.handleChange(e)}
                            />
                        </div>
                        <div className="mt-4">
                            <EuiTooltip title="Add View Only Users">
                                <i className="icon icon-eye m-1 icon-frame" onClick={() => this.setState({ openViewOnlyUserDialog: { open: true, type: userType.USER } })}></i>
                            </EuiTooltip>
                        </div>
                    </FormField>
                    <FormField>
                        <label htmlFor="scopeOfWorkDefinition">Scope Of Work Description*</label>
                        <textarea placeholder="Scope Of Work Description"
                            className="fullwidth"
                            id="scopeOfWorkDescription"
                            style={{ height: "50px" }}
                            label="Scope Of Work Description"
                            name="scopeOfWorkDescription"
                            required
                            value={this.state.session.scopeOfWorkDescription || ""}
                            onChange={e => this.handleChange(e)}
                        />
                    </FormField>
                    <FormField>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <EuiCheckbox name="isLiveStream"
                                id="isLiveStreamId"
                                label="Is Live Stream"
                                labelStyle={{ marginLeft: 2 }}
                                refValue={this.state.session.isLiveStream === 1 ? true : false}
                                onChange={e => this.handleChange(e)} />
                            <div style={{ display: "flex", justifyContent: "flex-end", width: "85%", alignItems: "center" }}>
                                <EuiDatepicker
                                    id="liveStreamStartDateId"
                                    label="Start DateTime"
                                    dateStyle={{ marginRight: 6, marginLeft: 25 }}
                                    disabled={this.state.session.isLiveStream === 0}
                                    placeholder="YYYY-MM-DD"
                                    name="liveStreamStartDate"
                                    value={this.state.session.liveStreamStartDate}
                                    onChange={e => this.handleChange(e)}
                                />
                                <EuiTimepicker id="liveStreamStartTimeId"
                                    name="liveStreamStartTime"
                                    style={{ marginTop: 25, marginRight: 10 }}
                                    readonly={this.state.session.isLiveStream === 0}
                                    value={this.state.session.liveStreamStartTime}
                                    onChange={(e) => this.handleChange(e)}
                                />
                                <EuiDatepicker
                                    id="liveStreamEndDateId"
                                    label="End DateTime"
                                    disabled={this.state.session.isLiveStream === 0}
                                    dateStyle={{ marginRight: 6 }}
                                    placeholder="YYYY-MM-DD"
                                    name="liveStreamEndDate"
                                    value={this.state.session.liveStreamEndDate}
                                    onChange={e => this.handleChange(e)}
                                />
                                <EuiTimepicker id="liveStreamEndTimeId"
                                    name="liveStreamEndTime"
                                    style={{ marginTop: 25 }}
                                    readonly={this.state.session.isLiveStream === 0}
                                    value={this.state.session.liveStreamEndTime}
                                    onChange={(e) => this.handleChange(e)}
                                />
                            </div>
                        </div>
                    </FormField>
                </div>

                <div className="column sm-12 md-6 lg-6 xl-6 filled">
                    <h3 style={{ fontWeight: 400, marginBottom: 5, marginTop: 5 }}>Customer Information </h3>
                    <FormField>
                        <label htmlFor="sessionCustomerId">Customer*</label>
                        <AutoCompleteSelect
                            disabled={this.props.sessionData.sessionInfo.id > 0 ? true : false}
                            id="sessionCustomerId"
                            options={this.state.customers}
                            name="customerId"
                            style={{ width: "85%" }}
                            onChange={e => this.handleChange(e)}
                            value={this.state.session.customerId}
                        />
                    </FormField>
                    <FormField >
                        <label htmlFor="sessionWorkflowId" id="workflowIdLabel">Approval Flow*</label>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <AutoCompleteSelect
                                disabled={this.state.session.customerId.value > 0 ? false : true}
                                id="sessionWorkflowId"
                                name="workflowId"
                                options={this.state.session.customerId.workflows || [{ value: 0, text: "" }]}
                                style={{ width: "85%" }}
                                onChange={e => this.handleChange(e)}
                                value={this.state.session.workflowId}
                            />
                            {this.state.session.workflowId.id === 0 ?
                                null :
                                <Tooltip title="Review Approval Flow Details">
                                    <IconButton aria-label="review" onClick={() => this.reviewApproverUsers()} style={{ pointerEvents: "auto", opacity: "1" }}>
                                        <i className="icon icon-eye" style={{ fontSize: 18 }}></i>
                                    </IconButton>
                                </Tooltip>
                            }
                        </div>
                    </FormField>
                    <FormField className="flex-start-content">
                        <div style={{ width: "85%" }}>
                            <label htmlFor="customerProjectManagers">Customer Project Managers*</label>
                            <AutoCompleteMultiSelect
                                disabled={this.state.session.customerId.value > 0 ? false : true}
                                id="customerProjectManagers"
                                name="customerUsers"
                                options={this.state.session.customerId.customerUsers}
                                value={this.state.session.customerUsers}
                                onChange={e => this.handleChange(e)}
                            />
                        </div>
                        <div className="mt-4">
                            <EuiTooltip title="Add View Only Customers">
                                <i className="icon icon-eye m-1 icon-frame" onClick={() => this.setState({ openViewOnlyUserDialog: { open: true, type: userType.CUSTOMER } })}></i>
                            </EuiTooltip>
                        </div>
                    </FormField>
                    <FormField>
                        <EuiTextField style={{ marginRight: "0px", width: "85%" }}
                            label="Customer Project Name*"
                            name="customerProjectName"
                            required
                            value={this.state.session.customerProjectName || ""}
                            onChange={e => this.handleChange(e)}
                        />
                    </FormField>
                    <FormField>
                        <EuiTextField style={{ marginRight: "0px", width: "85%" }}
                            label="Customer Scope Identification"
                            name="customerScopeIdentification"
                            optional="optional"
                            value={this.state.session.customerScopeIdentification || ""}
                            onChange={e => this.handleChange(e)}
                        />
                    </FormField>
                    <div className="flex-start-content" style={{ alignItems: "self-end" }}>
                        <FormField>
                            <label htmlFor="sessionTypeId" style={{ fontSize: 14, margin: "5px 0px" }} >{"Session Type" + (this.state.session.type ? " | Approval Mode" : "")}</label>
                            <EuiSwitch
                                id="sessionTypeId"
                                onChange={(e) => this.handleChange(e)}
                                checked={this.state.session.type || false}
                                dataEnabled={constants.customerSessionTypes.ECA}
                                dataDisabled={constants.customerSessionTypes.ERITOP}
                                name="type"
                            />
                        </FormField>
                        {this.state.session.type ?
                            <FormField>
                                <EuiSwitch
                                    id="ecaTypeId"
                                    onChange={(e) => this.handleChange(e)}
                                    checked={this.state.session.ecaType || false}
                                    dataEnabled={constants.ecaSessionType.AUTO}
                                    dataDisabled={constants.ecaSessionType.MANUAL}
                                    name="ecaType"
                                />
                            </FormField>
                            : null
                        }
                    </div>
                </div>

                <DialogModal
                    content={<EuiRow style={{ height: 600, position: "absolute", bottom: "8%" }}>
                        <CreateEditWorkflow
                            editMod={2}
                            workflow={this.state.session.workflowId}
                            changeMod={() => this.setState({ openReviewWorkflow: false })}
                        />
                    </EuiRow>}
                    contentStyle={{ height: 700, width: 900 }}
                    handleClose={() => this.setState({ openReviewWorkflow: false })}
                    id="reviewWorkflowId"
                    open={this.state.openReviewWorkflow}
                    title="Review Approval Flow Details"
                    titleId="reviewWorkflowTitle"
                    buttonsVisible="false"
                >
                </DialogModal>

                <DialogModal
                    buttonName="OK"
                    content={<div style={{ width: 400 }}>
                        <AutoCompleteMultiSelect
                            id="viewOnlyCustomerProjectManagers"
                            name="viewOnlyCustomers"
                            style={{ width: "100%" }}
                            options={this.getViewOnlyOptions()}
                            value={this.state.selectedViewOnlyUsers}
                            onChange={e => this.setState({ selectedViewOnlyUsers: e.target.value })}
                        />
                    </div>}
                    handleClose={() => this.setState({ openViewOnlyUserDialog: { open: false, type: 1 } })}
                    id="viewOnlyUserId"
                    open={this.state.openViewOnlyUserDialog.open}
                    onClick={this.handleViewOnlyChange}
                    title={this.state.openViewOnlyUserDialog.type == userType.USER ? "Add View Only Reviewer" : "Add View Only Customer Reviewer"}
                >
                </DialogModal>

            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session });
const mapDispatchToProps = dispatch => {
    return {
        setSessionInfo: sessionInfo => dispatch({ type: "SET_SESSION_INFO", payload: sessionInfo }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FirstStepContent);
