import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ApiClient from "../../../utils/api-client";
import PageHelper from "../../../utils/page-helper";
import DateUtils from "../../../utils/date-utils";
import constants from "../../../utils/constants";
import EuiDatepicker from "../../../eui-components/datepicker";
import EuiRow from "../../../eui-components/row";
import DashboardHelper from "../../../utils/dashboard-helper";
import productivityDashboardHelper from "../../../utils/productivity-dashboard-helper"
import EuiAccordion from "../../../eui-components/accordion";
import EuiCheckbox from "../../../eui-components/checkbox";
import { Notification } from '@eds/vanilla';
import ProductivityDashboardHelper from "../../../utils/productivity-dashboard-helper";

const ProductivityDashboardFilter = (props) => {
    const dispatch = useDispatch();
    const productivityDashboardData = useSelector(state => state.productivityDashboard);
    const userData = useSelector(state => state.user);
    const resources = useSelector(state => state.productivityDashboard.resources);
    const Project = useSelector(state => state.projects);
    var teamLeadersTemp = [];
    const [isLoading, setIsLoading] = useState(false);
    const [projects, setProjects] = useState(Project.allProjects.data.filter(item => !item.active));
    const [leadersList, setLeadersList] = useState(productivityDashboardData.ehsTeamLeaders);
    const [filterReset, setFilterReset] = useState(false);
    const [filterControls, setFilterControls] = useState({});

    const handleChange = (e, area, item) => {
        const filter = { ...productivityDashboardData?.filter };
        var filterAreaItems = filter[area.name] || [];
        if (area.name === "activityType") {
            const itemIndex = filterAreaItems?.findIndex(element => element == item.text)
            if (itemIndex === -1) {
                filterAreaItems.push(item.text);
            }
            else {
                filterAreaItems.splice(itemIndex);
            }
            filter[area.name] = filterAreaItems;
        }
        else {
            const itemIndex = filterAreaItems?.findIndex(element => element == item.value)
            if (itemIndex === -1) {
                filterAreaItems.push(item.value);
            }
            else {
                filterAreaItems.splice(itemIndex, 1);
            }
            filter[area.name] = filterAreaItems;
            if (area.name == "countries") {
                const filterData = (DashboardHelper.mapFilterControls(userData, projects, productivityDashboardData.rscCountries));
                if (filter[area.name].length) {
                    const filteredCustomersList = filterData.customersList?.filter(item => filter[area.name]?.includes(item.countryId));
                    
                    const filteredAspCompaniesList = filterData.aspCompaniesList.filter(item => filter[area.name]?.includes(item.countryId));
                    filterData.customersList = filteredCustomersList;
                    filterData.aspCompaniesList = filteredAspCompaniesList;
                    filter.customers = filter.customers?.filter(customer => filterData.customersList?.map(e => e.value)?.includes(customer));
                    filter.companies = filteredAspCompaniesList?.filter(company => filterData.aspCompaniesList?.map(e => e.value)?.includes(company))?.map(item => item.value);
                }
                else {
                    const filteredCustomersList = filterData.customersList;
                    
                    const filteredAspCompaniesList = filterData.aspCompaniesList;
                    filterData.customersList = filteredCustomersList;
                    filterData.aspCompaniesList = filteredAspCompaniesList;
                }
                setFilterControls(filterData);
                dispatch({ type: "SET_PRODUCTIVITY_FILTER_CONTROLS", payload: filterData });
            }
            if (area.name == "customers") {
                const filterData = (DashboardHelper.mapFilterControls(userData, projects, productivityDashboardData.rscCountries));
                if (filter[area.name].length) {
                    const filteredProjectsList = filterData.projectsList.filter(item => filter[area.name]?.includes(item.customerId));
                    filter.projects = filter.projects?.filter(project => filteredProjectsList.map(e => e.value)?.includes(project));
                    
                    filterData.projectsList = filteredProjectsList;
                }
                else {
                    const filteredProjectsList = filterData.projectsList;
                    
                    filterData.projectsList = filteredProjectsList;
                    filter.projects = filter.projects?.filter(project => filteredProjectsList?.map(e => e.value)?.includes(project));
                }
                if (filter?.countries?.length) {
                    const customers = filterControls.customersList.filter(c => filter.countries?.includes(c.countryId));
                    filterData.customersList = customers;
                }
                setFilterControls(filterData);
                dispatch({ type: "SET_PRODUCTIVITY_FILTER_CONTROLS", payload: filterData });
            }
            if (area.name == "companies") {
                const leaders = productivityDashboardData.resources?.filter(item => item.isLeader && item.status == 'Active');
                var filteredLeadersList = []
                if (filter[area.name].length) {
                    filteredLeadersList = leaders?.filter(element => filter[area.name]?.includes(element.aspCompanyId));
                    filter.teamLeaders = filter.teamLeaders?.filter(leader => filteredLeadersList?.map(e => e.value)?.includes(leader));
                    
                }
                else {
                    filteredLeadersList = leaders;
                }
                setLeadersList(filteredLeadersList);
            }
        }
        dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_FILTER", payload: filter });
    }
    const getIncentives = async () => {
        const filter = { ...productivityDashboardData?.filter };
        filter["resourceMail"] = userData.userMail;
        filter["userRole"] = parseInt(userData.userData?.jobRole);
        
        
        var countries = filter?.countries;
        const apiObject = {
            startDate: filter.startDate,
            endDate: filter.endDate,
            customerId: filter.customers || [],
            projectId: filter.projects || [],
            teamLeaderIds: filter.teamLeaders || [],
            resourceMail: filter.resourceMail,
            userRole: filter.userRole,
            GpoNames: filter.activityType || [],
            aspCompanyId: filter?.companies?.length ? filter?.companies : (countries?.length ? filterControls.aspCompaniesList?.filter(item => countries?.includes(item.countryId))?.map(i => i.value) : filterControls?.aspCompaniesList?.map(i => i.value))
        };
        if (!apiObject?.aspCompanyId?.length) {
            const filterData = (DashboardHelper.mapFilterControls(userData, projects, productivityDashboardData.rscCountries));
            apiObject.aspCompanyId = filterData.aspCompaniesList.map(i => i.value);
        }
        if (!apiObject.startDate || !apiObject) {
            const notification = new Notification({
                title: 'Please select start and end date to get the data!',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        
        
        
        if (DateUtils.dateDifference(new Date(apiObject.endDate), new Date(apiObject.startDate)) > 365) {
            const notification = new Notification({
                title: 'Search period cannot exceed one year!',
                timeout: 5000,
            });
            notification.init();
            return;
        }
        setIsLoading(true);
        try {
            dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_ISLOADING", payload: true });
            const response = await ApiClient.post("IncentivesPlan/GetProductivityAndUtilizationDataByMonth", apiObject, {}, 100000)
            if (response.data) {
                
                dispatch({ type: "SET_PRODUCTIVITY_RAW_DATA", payload: response.data });
                const activities = response.data.activities;
                const pings = response.data.ohsPings;
                let numberOfMonths = DateUtils.getMonthDifference(new Date(apiObject.startDate), new Date(apiObject.endDate));
                const kpis = ProductivityDashboardHelper.mapProductivityAndUtilization(activities, pings, numberOfMonths || 1);
                const trendData = ProductivityDashboardHelper.getTrendData(activities, pings, 1);
                const detailedData = ProductivityDashboardHelper.getGroupedData(activities, pings, teamLeadersTemp.length ? teamLeadersTemp : resources, filterControls, productivityDashboardData.actualGroupingColumns, numberOfMonths || 1);
                const detailedActivities = ProductivityDashboardHelper.getDetailedActivities(activities, filterControls, resources);
                const detailedPings = ProductivityDashboardHelper.getDetailedPings(pings, filterControls, resources);
                dispatch({ type: "SET_PRODUCTIVITY_KPIS_DETAILS", payload: detailedData });
                dispatch({ type: "SET_PRODUCTIVITY_DETAILED_ACTIVITIES", payload: detailedActivities });
                dispatch({ type: "SET_PRODUCTIVITY_DETAILED_PINGS", payload: detailedPings });
                dispatch({ type: "SET_PRODUCTIVITY_KPIS", payload: kpis });
                dispatch({ type: "SET_TREND_DATA", payload: trendData });
                let startDate = apiObject.startDate;
                let endDate = apiObject.endDate;
                dispatch({ type: "SET_PRODUCTIVITY_SELECTED_DATES", payload: { startDate, endDate } });
                dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_ISLOADING", payload: false });
                setIsLoading(false);

            }
        }
        catch (err) {
            alert(err.message || "Unexpected Error!");
            setIsLoading(false);
            dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_ISLOADING", payload: false });
        }
        finally {
            
            setIsLoading(false);
        }
    }
    const getTeamLeaders = () => {
        if ((!productivityDashboardData.ehsTeamLeaders || !productivityDashboardData.ehsTeamLeaders.length)
        ) {
            dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_ISLOADING", payload: true });
            ApiClient.post("IncentivesPlan/GetDashboardTeamLeaders", { id: parseInt(userData.userData.userId) })
                .then(response => {
                    
                    teamLeadersTemp = response.data;
                    const companies = filterControls?.aspCompaniesList?.map(i => i.value);
                    const leaders = companies?.length ? response.data?.filter(leader => companies?.includes(leader.aspCompanyId) && leader.isLeader) : response.data;
                    setLeadersList(leaders);
                    dispatch({ type: "SET_PRODUCTIVITY_DASHBORAD_TEAM_LEADERS", payload: leaders });
                    dispatch({ type: "SET_PRODUCTIVITY_ALL_RESOURCES", payload: response.data });
                    if (productivityDashboardData?.rawData?.length) {
                        const sitesDetails = productivityDashboardHelper.mapSitesDetails(productivityDashboardData.rawData, response.data, filterControls);
                        var groupedItems = productivityDashboardHelper.mapGroupedEhsData(productivityDashboardData.rawData, response.data, filterControls, productivityDashboardData.actualGroupingColumns);
                        dispatch({ type: "SET_PRODUCTIVITY_KPIS_DETAILS", payload: { performanceDetails: groupedItems, sitesDetails: sitesDetails } });
                    }
                    dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_ISLOADING", payload: false });
                }).
                catch(error => {
                    alert(error);
                });
        }
        /*else if (!productivityDashboardData.ehsTeamLeaders || !productivityDashboardData.ehsTeamLeaders.length) {
            dispatch({ type: "SET_ASP_PM_DASHBORAD_TEAM_LEADERS", payload: activityData.ehsTeamLeaders });
        }*/
        else {
            dispatch({ type: "SET_PRODUCTIVITY_DASHBORAD_TEAM_LEADERS", payload: [] });
            dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_ISLOADING", payload: false });
        }
    }
    const handleDate = (e) => {
        const name = e.target.name;
        const filter = { ...productivityDashboardData?.filter };
        filter[name] = e.target.value;
        dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_FILTER", payload: filter });
    }
    const getProjects = () => {
        ApiClient.post("Projects/GetAllProjects").then(response => {
            setIsLoading(false);
            let projects = response.data.data;
            
            setProjects(projects);
            setFilterControls(DashboardHelper.mapFilterControls(userData, projects, productivityDashboardData.rscCountries));
            dispatch({ type: "SET_PRODUCTIVITY_FILTER_CONTROLS", payload: DashboardHelper.mapFilterControls(userData, projects, productivityDashboardData.rscCountries) });
        }).catch(error => {
            console.error(error);
        });
    }
    useEffect(() => {
        //setIsLoading(true);
        if (!productivityDashboardData.rscCountries?.length) {
            ApiClient.post("IncentivesPlan/GetRscCountries")
                .then(_response => {
                    dispatch({ type: "SET_PRODUCTIVITY_RSC_COUNTRIES", payload: _response.data });
                });
        }
        if (!productivityDashboardData?.ehsTeamLeaders?.length) {
            getTeamLeaders();
        }
        if (!Project?.allProjects || !Project?.allProjects?.data?.length) {
            getProjects();
        }
        else {
            setFilterControls(DashboardHelper.mapFilterControls(userData, Project.allProjects, productivityDashboardData.rscCountries));
            dispatch({ type: "SET_PRODUCTIVITY_FILTER_CONTROLS", payload: DashboardHelper.mapFilterControls(userData, Project.allProjects, productivityDashboardData.rscCountries) });
        }
        
        dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_ISLOADING", payload: false });
        resetFilters();
    }, [])
    const renderAccordions = () => {
        const filter = { ...productivityDashboardData?.filter };
        //
        //const companies = userData?.userDetail.userAspCompanies.map(i => i?.aspCompanyId) || filterControls.aspCompaniesList;
        const companies = filterControls?.aspCompaniesList?.map(i => i.value);
        const leaders = //(leadersList.length ?
            leadersList?.filter(leader => companies?.includes(leader.aspCompanyId) && leader.status == constants.resourceStatus.active && leader.isLeader);
        //: productivityDashboardData.ehsTeamLeaders.filter(leader => companies?.includes(leader.aspCompanyId) && leader.status == constants.resourceStatus.active));//leadersList.filter(leader => companies?.includes(leader.aspCompanyId) && leader.status == constants.resourceStatus.active) ||
        
        var filterAreas = [];
        if (userData.userData.jobRole !== constants.userRoles.ASP_TEAM_LEADER) {
            filterAreas = [
                { name: "countries", items: filterControls.countriesList?.sort(PageHelper.dynamicSort("text")) || [], title: "Select Country" },
                { name: "customers", items: filterControls.customersList?.sort(PageHelper.dynamicSort("text")) || [], title: "Select Customer" },
                { name: "companies", items: filterControls.aspCompaniesList?.sort(PageHelper.dynamicSort("text")) || [], title: "Select ASP Company" },
                { name: "projects", items: filterControls.projectsList?.sort(PageHelper.dynamicSort("text")), title: "Select Project" },
                // { name: "activityType", items: constants.globalPlanningObject, title: "Select Activity Type" },
                { name: "teamLeaders", items: leaders?.sort(PageHelper.dynamicSort("text")), title: "Select Team Leader" },
            ];
        }
        if (userData.userData.jobRole == constants.userRoles.ASP_TEAM_LEADER) {
            filterAreas = [
                { name: "activityType", items: constants.globalPlanningObject, title: "Select Activity Type" },
            ];
        }
        const accordions = filterAreas.map(area => {
            var acc = {
                id: area.name + "Accordion",
                title: area.title,
                titleIcon: (filter[area.name]?.length ? <i className="icon icon-filter" /> : ""),
                titleStyle: { color: "gray", fontSize: "14" },
                key: area.items,
                content: area.items?.map(item =>
                    <EuiCheckbox
                        id={item.value + area.name + "checkbox"}
                        key={item.value}
                        defaultChecked={false}
                        onChange={(e) => handleChange(e, area, item)}
                        label={item.text}
                        labelStyle={{ whiteSpace: "nowrap", fontSize: "13px" }}
                        style={{ marginBottom: "2px" }}
                    >
                    </EuiCheckbox >)
            };
            return acc;
        });
        return <EuiAccordion
            key={productivityDashboardData?.filter}
            className="m-1 w-10" id="dashboardFilterAccordions"
            style={{ width: "100%", marginTop: "20px", overflowY: "scroll", maxHeight: "500px" }}
            items={accordions}
        />;
    }
    const resetFilters = () => {
        const filter = { endDate: null, startDate: null }
        setFilterControls(DashboardHelper.mapFilterControls(userData, projects, productivityDashboardData.rscCountries));
        dispatch({ type: "SET_PRODUCTIVITY_FILTER_CONTROLS", payload: DashboardHelper.mapFilterControls(userData, projects, productivityDashboardData.rscCountries) });
        const companies = filterControls?.aspCompaniesList?.map(i => i.value);
        const leaders = //(leadersList.length ?
            resources?.filter(leader => companies?.includes(leader.aspCompanyId) && leader.status == constants.resourceStatus.active && leader.isLeader);
        setLeadersList(leaders);
        dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_FILTER", payload: filter });
        dispatch({ type: "SET_PRODUCTIVITY_SELECTED_DATES", payload: { startDate: null, endDate: null } });
        dispatch({ type: "SET_PRODUCTIVITY_RAW_DATA", payload: [] });
        dispatch({ type: "SET_PRODUCTIVITY_KPIS", payload: [] });
        dispatch({ type: "SET_PRODUCTIVITY_KPIS_DETAILS", payload: [] });
        setFilterReset(!filterReset);
    }
    return (<EuiRow key={filterReset || productivityDashboardData?.filter} >
        <EuiRow  >
            <EuiDatepicker className="m-1 w-10" fullwidth id="productivityDashboardStartDate" name="startDate" label="Start Month (=)" labelClassName="color-gray"
                value={productivityDashboardData?.filter?.startDate || ""} onChange={(e) => handleDate(e)}
            />  <EuiDatepicker className="m-1 w-10" fullwidth id="productivityDashboardEndDate" name="endDate" label="End Month (=)" labelClassName="color-gray"
                value={productivityDashboardData?.filter?.endDate || ""} onChange={(e) => handleDate(e)}
            />
            {renderAccordions()}
        </EuiRow>
        <EuiRow className="mt-2" style={{ display: "flex", justifyContent: "end" }}>
            <button className="btn m-1" style={{ height: "30px" }} onClick={resetFilters}>Reset Filters </button>
            <button style={{ width: "60px", height: "30px" }} className={"btn primary m-1 " + (isLoading ? "loading disabled" : "")} onClick={getIncentives}>Apply</button>
        </EuiRow>
    </EuiRow >);
}
export default ProductivityDashboardFilter;