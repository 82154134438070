import React, { useEffect, useState } from "react";
import EuiRow from "../../../../eui-components/row";
import ObseleteEuiTable from "../../../../eui-components/obseleteTable";

const SitesDetails = (props) => {
    const columns = [
        {
            title: "Activity ID",
            key: "id",
            sort: "none",
            width: "3%"
        },
        {
            title: "Workplan Name",
            key: "wpName",
            sort: "asc",
            width: "8%",
            //  cellStyle: "white-space:nowrap;"
        },
        {
            title: "Activity Name",
            key: "name",
            sort: "asc",
            width: "10%",
            // cellStyle: "white-space:nowrap;"

        },
        {
            title: "Activity Type",
            key: "gpoName",
            sort: "none",
            width: "7%",
            cellStyle: "white-space:nowrap;"
        },

        {
            title: "ASP Team Leader",
            key: "leaderName",
            sort: "asc",
            width: "7%",
            cellStyle: "white-space:nowrap;"

        },

        /* {
             title: "Planned End",
             key: "plannedEnd",
             sort: "none",
             width: "5%",
             onCreatedCell: (td, cellData) => {
                 td.style = "text-align:center";
             },
 
         },
         {
             title: "Actual End",
             key: "actualEnd",
             sort: "none",
             width: "4%",
             onCreatedCell: (td, cellData) => {
                 td.style = "text-align:center";
             },
 
         },*/
        {
            title: "FTR",
            key: "ftrStatus",
            sort: "none",
            width: "1%",
            onCreatedCell: (td, cellData) => {
                if (cellData == "FTR") {
                    td.innerHTML = `<i  class="icon icon-success color-green" " />`
                }
                else {
                    td.innerHTML = td.innerHTML = `<i  class="icon icon-failed color-red" />`
                }
                td.style = "text-align:center; font-size:19px;";
            },
            hideFilter: true,
        },
        {
            title: "Ready For CA",
            key: "readyForCAStatus",
            sort: "none",
            width: "5%",
            onCreatedCell: (td, cellData) => {

                if (cellData == "Ready For Acceptance") {
                    td.innerHTML = `<i  class="icon icon-success color-green" " />`
                }
                else {
                    td.innerHTML = td.innerHTML = `<i  class="icon icon-failed color-red"  />`
                }
                td.style = "text-align:center; font-size:19px;";
            },
            hideFilter: true

        },
        {
            title: "Snags",
            key: "noSnag",
            sort: "none",
            width: "1%",
            onCreatedCell: (td, cellData) => {

                if (cellData == "0") {
                    td.innerHTML = `<i  class="icon icon-success color-green" style="font-size:19px;" />`;
                }
                else {
                    td.innerHTML = `<i  class="icon icon-failed  color-red" style="font-size:19px;" /> `;
                }
                td.style = "text-align:center;";
            },
            hideFilter: true

        },
        {
            title: "Team Efficiency",
            key: "efficient",
            sort: "none",
            width: "5%",
            textAlign: "center",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<i  class="icon icon-success color-green" " />`
                }
                else {
                    td.innerHTML = `<i  class="icon icon-failed color-red"  />`
                }
                td.style = "text-align:center; font-size:19px;";
            },
            hideFilter: true
        },
        {
            title: "Quality Index<=0.4",
            key: "ehsStatus",
            width: "4%",
            textAlign: "center",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                if (cellData <= 0.4) {
                    td.innerHTML = `<i  class="icon icon-success color-green" style="font-size:19px;" />`;
                }
                else {
                    td.innerHTML = `<i  class="icon icon-failed  color-red" style="font-size:19px;" /> `;
                }
                /*if (cellData <= 1) {
                    td.innerHTML = `<i  class="icon icon-success color-green" " />`
                }
                else if (cellData > 1) {
                    td.innerHTML = `<i  class="icon icon-failed color-red"  />`
                }
                else {
 
                    td.innerHTML = `<span  id="warning" class="tooltip"><i  class="icon icon-triangle-warning color-orange" /><span class="message top-end">No EHS sessions found</span></span>`;
                }*/
                td.style = "text-align:center; ";
            },
            hideFilter: true
        }
    ]


    const data = props.data;
    var DD = new Date();
    const fileName = "dashboard-sitesDetails" + DD.toISOString();
    var excelData = data?.map(e => {
        var mappedItem = {
            BuildHub: e.buildHub,
            CustomerUnit: e.customerUnit,
            Country: e.countryName,
            Customer: e.customerName,
            Project: e.projectName,
            AspCompany: e.companyName,
            WorkplanName: e.wpName,
            //CustomerSiteId: e.customerSiteId,
            ActivityID: e.id,
            ActivityName: e.name,
            ActivityType: e.gpoName,
            ASPTeamLeader: e.leaderName,
            PlannedStart: e.plannedStart?.slice(0, 10),
            PlannedEnd: e.plannedEnd?.slice(0, 10),
            ErisitePlannedStart: e.erisitePlannedStart?.slice(0, 10),
            ErisitePlannedEnd: e.erisitePlannedEnd?.slice(0, 10),
            ActualStart: e.actualStart?.slice(0, 10),
            ActualEnd: e.actualEnd,
            FTRStatus: e.ftrStatus,
            ReadyForCustomerAcceptanceStatus: e.readyForCAStatus?.toUpperCase() == "READY FOR ACCEPTANCE" ? true : false,
            NumberOfSnags: isNaN(parseInt(e.noSnag)) ? "-" : parseInt(e.noSnag),
            QualityIndex: e.ehsStatus?.slice(0, 4)// <= 1 ? "Passed" : (e.ehsStatus > 1 ? "Failed" : "Not Found")
        }
        return mappedItem;
    })
    



    return (<EuiRow key={data}>

        <ObseleteEuiTable
            id="aspPmDashboardSiteDetails"
            columns={columns}
            data={data}
            scroll={false}
            classes="tiny split-striped"
            exportExcel={true}
            Reportdata={excelData}
            excelFileName={fileName}
            filter={true}
            paginated={true}
            rowsPerPage={13}
        />
    </EuiRow>)
}

export default SitesDetails;