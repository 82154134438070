import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import MyReportsTable from "./MyReportsTable";
import "../../css/workplanStyle.css";


class MyReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }
    componentDidMount() { }

    renderMode = () => {
        return <MyReportsTable />
        }
    

    render() {
        return (this.renderMode());
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation});
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(MyReports)));