import React, { useState, useEffect, useRef } from 'react';
import "../../css/sliderStyle.css"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MiniDrawer from '../miniDrawer';
import constants from '../../utils/constants';
import { IconButton } from '@material-ui/core';
import { Tooltip } from "@material-ui/core";
import 'tui-image-editor/dist/tui-image-editor.css'
import "../../css/icon-styles.css"
import PageHelper from '../../utils/page-helper';
import DialogModal from "../dialog";
import { ToastContainer, toast } from 'react-toastify';
import EditInterface from '../ImageEditor/EditInterface'
import ApiClient from "../../utils/api-client";
import EuiProgressBar from "../../eui-components/progressbar";
import * as FileHelper from "../../utils/file-helper";
import VIImagePreview from './viimage';
import SasHelper from "../../utils/sas-helper";

const SimpleSlider =
    ({ userData,
        sessionData,
        id,
        slideData,
        currentSlide,
        totalCount,
        onNext,
        onPrev,
        onClose,
        status,
        comment,
        severityLevel,
        initialSlide,
        style,
        commentTitle,
        siteComment,
        type,
        editImage,
        jobRole
    }) => {
        const [slider, setSlider] = useState(null);
        const [zoom, setZoom] = useState(1);
        const [rotation, setRotation] = useState(0);
        const [open, getOpen] = useState(true);
        const [edit, setEdit] = useState(false);
        const [percentageArray, setPercentageArray] = useState([]);
        const [dialogUploadBar, openDialogUploadBar] = useState(false);
        const imgEditorRef = useRef();  
        const [updated, setUpdated] = useState(false);
        const [showVI, toggleVI] = useState(true);
        const [sasToken, setSasToken] = useState({});
        
        useEffect(() => {
            SasHelper.getSasKey().then((response) => {
                setSasToken(response);
            });
      
        }, []);

        useEffect(() => {
            if (percentageArray != []) {
                setUpdated(true)
            }
            
        }, [percentageArray]);

        const PrevArrow = props => {
            return <div dataRole="none" className="slick-arrow slick-prev" style={{ display: "block" }}>
                <div className="prev-slick-arrow prev-arrow" style={{ height: "50%" }}>
                    <IconButton onClick={() => slider.slickPrev()}>
                        <i className="icon icon-chevron-left slick-icon" ></i>
                    </IconButton>
                </div>
            </div>
        };

        const NextArrow = props => {
            return <div dataRole="none" className="slick-arrow slick-next" style={{ display: "block" }}>
                <div className="next-slick-arrow next-arrow" style={{ height: "50%" }}>
                    <IconButton onClick={() => slider.slickNext()}>
                        <i className="icon icon-chevron-right slick-icon"></i>
                    </IconButton>
                </div>
            </div>
        };

        const settingsMain = {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            lazyLoad: 'ondemand',
            className: "custom-slick-slider",
            centerPadding: "20px",
            centerMode: true,
            initialSlide: initialSlide,
            infinite: true,
            //fade: true,
            speed: 500,
            beforeChange: (current, next) => {
                if ((next - current === 1) || (next - current === -(slideData.length - 1))) {
                    onNext();
                } else if (next - current === -1 || (next - current === (slideData.length - 1))) {
                    onPrev();
                }
                setZoom(1);
                setRotation(0);
            },
        };

        const MainSlide = props => {
            const documentPath = props.slide.documentPath;
            const mimeType = documentPath && documentPath.split(".").pop();
            const type = props.slide.type || FileHelper.getFileType(mimeType)
            if (type === constants.documentTypes.IMAGE) {
                if (props.slide.viapiResultNavigation?.viResultModel?.annotations?.length > 0 && showVI) {
                    return <VIImagePreview VIResponse={props.slide.viapiResultNavigation.viResultModel} fileOnlinePath={SasHelper.addSasKey(documentPath, sasToken)} ></VIImagePreview>;
                }

                return <img className="slick-slide-image"
                    style={{ transform: `rotate(${rotation}deg) scale(${zoom})`, height: "100%", width: "100%", objectFit: "contain" }}
                    src={SasHelper.addSasKey(documentPath, sasToken)}
                />
            } else if (type === constants.documentTypes.VIDEO) {
                return <video controls name="media" width="100%" height="100%">
                    <source src={SasHelper.addSasKey(documentPath, sasToken)} type={"video/" + mimeType} />
                    Your browser does not support HTML5 video.
                </video>
            } else {
                return <iframe src={"https://docs.google.com/viewer?url=" + encodeURIComponent(SasHelper.addSasKey(documentPath, sasToken)) + "&embedded=true"}
                    width="100%" height="100%" frameBorder="0"
                ></iframe>
            }
        };

        const CustomSlide = props => {
            const { slide, style, ...restProps } = props;
            const documentPath = props.slide.documentPath;
            if (editImage && currentSlide.type === constants.documentTypes.IMAGE && jobRole === constants.userRoles.RSC_SITE_SUPERVISOR) {
                return (<div {...restProps} id={"slide-" + slide.id} style={{ height: "100%", ...style }} >
                    <span style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Tooltip title="Edit Image" id="edit" >
                            <IconButton style={{ alignItem: "right" }} aria-label="edit" id="edit" onClick={() => setEdit(true)}  >
                                <i className="icon icon-edit" style={{ fontSize: "30", color: "white" }}></i>
                            </IconButton>
                        </Tooltip>
                    </span>
                    <MainSlide slide={slide} />
                    <div className="slick-slide-label">{totalCount}</div>
                </div >)
            }
            else {
                return (<div {...restProps} id={"slide-" + slide.id} style={{ height: "100%", ...style }} >
                    <MainSlide slide={slide} />
                    <div className="slick-slide-label">{totalCount}</div>
                </div>)
            }
        }

        const uploadNewImg = (rowData) => {
            let index = parseInt(totalCount.substring(0, totalCount.indexOf("/"))) - 1
            const returnedObject = PageHelper.uploadNewImg(rowData, imgEditorRef, index)
            const intializedArray = returnedObject.Array
            const files = returnedObject.Files
            setPercentageArray(intializedArray);
            if (updated) {
                openDialogUploadBar(true);
                if (openDialogUploadBar) {
                    const onProgress = (progressEvent, file) => {
                        let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                        let percentageArr = [...intializedArray];
                        
                        const index = percentageArr.indexOf(file);
                        percentageArr[index].percentage = percentage;
                        setPercentageArray(percentageArr)
                    }
                    PageHelper.uploadItems(files, onProgress, "activity-images").then(uploadedFiles => {
                        updateActivityDocDb(mapActivityDocuments(uploadedFiles, rowData), rowData);
                    }).catch(error => {
                        console.error(error);
                    });
                    
                }
            }
            setEdit(false);
        }

        const updateActivityDocDb = (documents, rowData) => {

            
            if (!documents || documents.length === 0) {
                setPercentageArray([])
                return;
            }

            rowData[parseInt(totalCount.substring(0, totalCount.indexOf("/"))) - 1].documentPath = documents[0].documentPath;
            rowData[parseInt(totalCount.substring(0, totalCount.indexOf("/"))) - 1].posterPath = documents[0].documentPath;
            rowData[parseInt(totalCount.substring(0, totalCount.indexOf("/"))) - 1].name = documents[0].name;

            openDialogUploadBar(false)
            if (!dialogUploadBar) {
                ApiClient.post("Session/UpdateActivityDoc", { EdittedDoc: documents[0], OldDoc: rowData[parseInt(totalCount.substring(0, totalCount.indexOf("/"))) - 1] })
                    .then(response => {
                        setPercentageArray([])
                        toast.success("Document is succesfully updated", { toastId: "documentAdd", containerId: 'T', position: toast.POSITION.TOP_CENTER })
                    })
                    .catch(error => {
                        console.error(error);
                        setPercentageArray([])
                        toast.error("An error occured! Please try again", { toastId: "documentError", containerId: 'T', position: toast.POSITION.TOP_CENTER });
                    })
            }
        }

        const mapActivityDocuments = (uploadedFiles, rowData) => {
            // in this function you can return all the documents detailed data, path, name.. etc 
            return uploadedFiles.map(item => {
                if (rowData) {
                    return {
                        name: item.name,
                        activityId: rowData.activityId,
                        subActivityId: rowData.id,
                        uploadDate: new Date(),
                        documentPath: item.documentPath,
                        acceptanceStatusId: 2,
                        sessionAcceptanceStatus: 1,
                        latitude: PageHelper.coord.latitude,
                        longitude: PageHelper.coord.longitude
                    };
                } else {
                    return {
                        name: item.name,
                        uploadDate: new Date(),
                        documentPath: item.documentPath,
                        acceptanceStatusId: 2,
                        sessionAcceptanceStatus: 1,
                        latitude: PageHelper.coord.latitude,
                        longitude: PageHelper.coord.longitude
                    };
                }
            });
        }
        //inside the slider we will put the ImageEditor and therefore we will have access to the documents url for upload and for the view mode

        return (<div className="App" id={id} style={style}>
            <div className="slider-wrapper" style={{ width: (open ? "80%" : "95%") }}>
                {edit ? (
                    <EditInterface forwardedRef={imgEditorRef}
                        path={SasHelper.addSasKey(slideData[parseInt(totalCount.substring(0, totalCount.indexOf("/"))) - 1].documentPath ,sasToken)}
                        name={slideData[parseInt(totalCount.substring(0, totalCount.indexOf("/"))) - 1].name}
                        handleClick={() => { uploadNewImg(slideData) }}
                        handleClose={() => { setEdit(false) }}
                    />
                )
                    :
                    <>
                        <PrevArrow />
                        <Slider
                            {...settingsMain}
                            //asNavFor={nav2}
                            ref={c => (setSlider(c))}
                        >
                            {slideData.map((slide) => {
                                return <CustomSlide key={slide.id} slide={slide} />
                            }
                            )}
                        </Slider>
                        <NextArrow />
                    </>
                }
            </div>

            <MiniDrawer
                sasToken={sasToken}
                onClose={onClose}
                onEdit={setEdit}
                setZoom={setZoom}
                zoom={zoom}
                setRotation={setRotation}
                rotation={rotation}
                currentSlide={currentSlide}
                getOpen={getOpen}
                comment={comment}
                severityLevel={severityLevel}
                commentTitle={commentTitle}
                siteComment={siteComment}
                status={status}
                acceptanceStatusId={type === 1 ? currentSlide.sessionAcceptanceStatus : currentSlide.acceptanceStatusId}
                showVI={showVI}
                toggleVI={toggleVI}
            >
            </MiniDrawer>

            <DialogModal
                content={
                    <div>
                        {percentageArray.map(file => {
                            return <div key={file.name} style={{ width: 500 }}>
                                <label><strong>{file.name}</strong></label>
                                <EuiProgressBar value={file.percentage} />
                            </div>
                        })
                        }
                    </div>
                }
                handleClose={() => openDialogUploadBar(false)}
                id="uploadBarDialog"
                titleId="titleuploadBarDialog"
                open={dialogUploadBar}
                buttonsVisible="false"
                title="Progress Loading..."
                closeButtonVisible="true"
            >
            </DialogModal>

            <ToastContainer enableMultiContainer containerId={'T'} />

        </div>
        );
    }

export default SimpleSlider;