const initialState = {
    mod: 1,
    vehicleApprovalMod: 1,
    currentVehicleApproval: { id: 0 },
    vehicleApprovalList: [],
    vehicleUnderReviewList: [],
    allVehicleApproval: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        
        case "SET_CURRENT_VEHICLE_APPROVAL":
            return {
                ...state,
                currentVehicleApproval: action.payload
            };
            case "CHANGE_VEHICLE_MOD":
                return {
                    ...state,
                    mod: action.payload
                };
            case "CHANGE_VEHICLE_APPROVAL_MOD":
                return {
                    ...state,
                    vehicleApprovalMod: action.payload
                };

        case "SET_VEHICLE_APPROVAL_LIST":
            return {
                ...state,
                vehicleApprovalList: action.payload
            };
            case "SET_VEHICLE_UNDER_REVIEW_LIST":
                return {
                    ...state,
                    vehicleUnderReviewList: action.payload
                };

        case "SET_ALL_VEHICLE_APPROVAL":
            return {
                ...state,
                allVehicleApproval: action.payload
            }
        default:
            return state;
    }

};
