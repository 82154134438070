import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import { ToastContainer, toast } from 'react-toastify';
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import constants from "../../utils/constants";
import EuiAccordion from "../../eui-components/accordion";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import WorkplanInfo from "./workplanInfo";
import WorkplanDocuments from "./workplanDocuments";
import ErisiteInfo from "./erisiteInfo";
import EhsPingList from "../ehs-pings/ehsPingList";
import ActivityList from "../activities/activityList";
import EuiTile from "../../eui-components/tile";
import MenuMultiPanelTile from "../../components/menuMultiPanel";
import WorkplanHelper from "../../utils/workplan-helper";


class WorkplanDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            editMod: constants.actionMods.VIEW_ONLY
        }
    }

    componentDidMount() {
    }

    getMenuList = () => {
        const workplan = this.props.workplanData.workplan;
        const activities = WorkplanHelper.mapActivities(workplan.activities);

        const user = this.props.userData.userData;
        const menu = [{
            id: 1, title: "Workplan Information",
            icon: <i className="icon icon-info m-1 text-lg"></i>,
            content: <WorkplanInfo mod={this.state.editMod} key={workplan.wpType} />,
            rightIcon: user.jobRole === constants.userRoles.ADMIN || user.jobRole === constants.userRoles.ERICSSON_IM ?
                this.getRightButton()
                : null,

        },
        {
            id: 2, title: "Erisite Information", icon: <i className="icon icon-info m-1 text-lg"></i>,
            hidden: (!workplan.systemrecordid ? true : false),
            content: <ErisiteInfo />
        },
        { id: 3, title: "Workplan Documents", icon: <i className={"icon icon-document m-1 text-lg"}></i>, content: <WorkplanDocuments /> },
        {
            id: 4, title: "Activities", icon: <i className="icon icon-share m-1 text-lg"></i>,
            content: <ActivityList insideWorkplan={true} id="workplanActivitiesTable" activities={activities} type={constants.activityListTypes.WORKPLAN} height="475px" />
        },
        {
            id: 5, title: "EHS Pings", icon: <i className="icon icon-image m-1 text-lg"></i>,
            content: <EhsPingList id="workplanEhsPingsTable" ehsPings={workplan.ohsPings} type={constants.activityListTypes.WORKPLAN} height="475px" />
        },
        ]
        return menu;
    }

    getRightButton = () => {
        if (this.state.editMod === constants.actionMods.VIEW_ONLY) {
            return <button hidden={this.props.userData.userData.jobRole === "1" || this.props.userData.userData.jobRole === "0" ? false : true} className={"btn primary m-1 " + (this.props.isLoading ? "loading  disabled" : "")}
                onClick={() => this.setState({ editMod: constants.actionMods.EDIT })}>
                <i className="icon icon-edit p-1" />Edit Workplan
            </button>
        } else if (this.state.editMod === constants.actionMods.EDIT) {
            return <><button className={"btn primary m-1 "} onClick={() => this.saveWorkplan()}><i className="icon icon-check p-1" />Save</button>
                <button className={"btn primary m-1 "} onClick={this.cancel}><i className="icon icon-cross p-1" />Cancel</button>
            </>
        }
    }

    saveWorkplan = () => {
        const workplan = this.props.workplanData.workplan;
        
        if (!workplan.name ||
            !workplan.typeSiteId ||
            !workplan.customerId ||
            !workplan.projectId ||
            !workplan.siteModel?.value ||
            !workplan.wpType ||
            (workplan.wpType === constants.wpTypeFarend && !workplan.farendSiteModel?.text)
        ) {
            toast.error("Please, fill the mandatory fields!", { containerId: "WD", position: toast.POSITION.TOP_CENTER });
            return;
        }
        this.setState({ editMod: constants.actionMods.VIEW_ONLY }, () => {
            this.props.createEditWorkplan();
        });
    }

    cancel = () => {
        const workplan = this.props.workplanData.filteredWorkplans.data.find(wp => wp.id === this.props.workplanData.workplan?.id);
        this.props.setWorkplan(workplan);
        this.setState({ editMod: constants.actionMods.VIEW_ONLY });

    }

    render() {

        return (<EuiTile key={this.props.workplanData.workplan} fullscreen tileStyle={{ padding: 0 }} key={this.props.workplanData.updateElement}>
            <EuiBreadcrumb id="workplanBreadcrumb"
                parentName="Home"
                childName={this.props.workplanData.workplan.id + "-" + this.props.workplanData.workplan.name}
                onClickParent={() => this.props.changeMod(constants.pageMods.LIST)}
                className="p-2"
            />

            <MenuMultiPanelTile id="workplanDetailsMenuMultiPanel" menuList={this.getMenuList()} key={this.state.editMod + this.props.isLoading} />

            <ToastContainer enableMultiContainer containerId={'WD'} autoClose={2500} />
        </EuiTile >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, workplanData: state.workplan });
const mapDispatchToProps = dispatch => {
    return {
        changeMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setApproveSession: (request) => dispatch({ type: "SET_APPROVE_SESSION", payload: request }),
        setFilteredActivities: (filteredActivities) => dispatch({ type: "SET_FILTERED_ACTIVITIES", payload: filteredActivities }),
        setActivityFilter: (filter) => dispatch({ type: "SET_ACTIVITY_FILTER", payload: filter }),
        changeActivityMod: (mod) => dispatch({ type: "CHANGE_ACTIVITY_MOD", payload: mod }),
        setActivity: (activity) => dispatch({ type: "SET_ACTIVITY", payload: activity }),
        setFilteredEhsPings: (filteredEhsPings) => dispatch({ type: "SET_FILTERED_EHS_PINGS", payload: filteredEhsPings }),
        setEhsPingFilter: (filter) => dispatch({ type: "SET_EHS_PING_FILTER", payload: filter }),
        changeEhsPingMod: (mod) => dispatch({ type: "CHANGE_EHS_PING_MOD", payload: mod }),
        setEhsPing: (ehsPing) => dispatch({ type: "SET_EHS_PING", payload: ehsPing }),
        setWorkplan: (workplan) => dispatch({ type: "SET_WORKPLAN", payload: workplan }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(WorkplanDetails));