import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ApiClient from "../utils/api-client";
import constants from "../utils/constants";
import UserAdminHelper from "../utils/user-admin-helper";
import CommonHelper from "../utils/common-helper";
import { getToken } from "firebase/messaging";
import { authContext, getAuthToken } from "../utils/adalConfig";

class EuiSignIn extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            username: "",
            password: "",
            rememberMe: false,
            view: "form",
            error: "",
            jobRole: 0,
            passwordType: "password",
            loginRoles: constants.loginRoles,
            TFAuthToken: false,
        };
    }

    componentDidMount() {
        ApiClient.setToken(getAuthToken())
        this.setState({ isLoading: true }, () => {
            ApiClient.post("UserAdmin/GetUserLoginRoles")
                .then(response => {
                    this.props.setUserLoginRoles(response.data);
                    ApiClient.post("UserAdmin/GetRscCenters")
                        .then(response => {
                            const rscCenters = response.data;
                            if (rscCenters) {
                                rscCenters.map(r => {
                                    r.value = r.id;
                                    r.text = r.name;
                                });
                            }
                            this.props.setRscCenters(rscCenters);
                            this.setState({ isLoading: false });
                            this.fetchuserAndSetupWeb()
                        }).catch(error => {
                            console.error(error);
                            this.setState({ isLoading: false });
                        });
                }).catch(error => {
                    console.error(error);
                    this.setState({ isLoading: false });
                });
        });
       
    }

    fetchuserAndSetupWeb = () => {
            this.setState({ isLoading: true });
             const onSuccess = (response) => {

                     this.props.setWebToken(response.data.user?.webToken);
                     const loginUser = UserAdminHelper.mapLoginUser(response.data, this.props.userAdminData.loginRoles, this.props?.user?.rscCenters);
                     loginUser.rememberMe = true;
                    loginUser.userMail = loginUser.userDetail.email;

                     this.props.login(loginUser);
                     this.props.setUserRefreshed(false)
                     setTimeout(() => this.props.history.push("/"), 1000);
                   
             };
             const onError = (error) => {
                console.error(error);
                this.setState({
                    isLoading: false,
                    error: "Somthing went wrong!"
                });
             };
            ApiClient.post("User/GetUserConfig",{includeEveryThing : true}).then(onSuccess).catch(onError);
        

       
    };
    routeToAdLogin = () => {
        authContext.login()
    }
    onSubmit = event => {
        if (!this.refs.privacyRef.checked) {
            this.setState({ error: "Please confirm Privacy Policy Agreement!" });
            event.preventDefault();
            return;
        }

        this.setState({ isLoading: true }, () => {
            ApiClient.post("User/Authenticate", {
                NameOrMail: this.state.username,
                Password: this.state.password
            }).then(response => {
                if (response.data.message) {
                    this.setState({
                        isLoading: false,
                        error: response.data.message
                    });
                } else {
                    if (!constants.mfaDisabledList.includes(response.data.user.email)) {
                    // All user shall use MFA
                        this.setState({ view: "welcome", isLoading: false, error: "" }, () => {
                            var randomString = require('random-string');
                            var _TFAuthToken = randomString();
                            this.setState({ TFAuthToken: true })
                            ApiClient.setToken(response.data.token);
                            this.props.setWebToken(response.data.user?.webToken);
                            const loginUser = UserAdminHelper.mapLoginUser(response.data, this.props.userAdminData.loginRoles, this.props.user.rscCenters);
                            loginUser.rememberMe = this.refs.rememberMe.checked;
                            loginUser.userMail = loginUser.userDetail.email;
                            loginUser.loggedIn = false;

                            this.props.login(loginUser);
                            ApiClient.post("User/SendTFAuthToken", { Id: parseInt(response.data.user.id), TwoFactorAuthToken: _TFAuthToken })
                                .then(response => {
                                    if (response.data) {
                                        this.setState({
                                            isLoading: false,
                                            username: loginUser.userMail
                                        });
                                        setTimeout(() => this.props.history.push("/two-factor-auth"), 1000);
                                    }
                                });
                        });
                    }
                    else {
                        ApiClient.setToken(response.data.token);
                        this.props.setWebToken(response.data.user?.webToken);
                        const loginUser = UserAdminHelper.mapLoginUser(response.data, this.props.userAdminData.loginRoles, this.props.user.rscCenters);
                        loginUser.rememberMe = this.refs.rememberMe.checked;
                        loginUser.userMail = loginUser.userDetail.email;

                        this.props.login(loginUser);
                        this.setState({ view: "welcome", isLoading: false, error: "" }, () => {
                            setTimeout(() => this.routeByJobRole(), 1000);
                        })
                    }
                }
            }).catch(error => {
                console.error(error);
                this.setState({
                    isLoading: false,
                    error: "Somthing went wrong!"
                });
            })
        });

        event.preventDefault();
    };

    routeByJobRole = () => {
        //if (this.props.user.userData.jobRole === constants.userRoles.RSC_SITE_SUPERVISOR) {
        //    this.props.history.push("/activity-inbox");
        //} else if (this.props.user.userData.jobRole === constants.userRoles.RSC_OHS_INSPECTOR) {
        //    this.props.history.push("/ehs-inbox");
        //} else {

        if(this.props.location?.state?.redirect)
        {
            this.props.history.push(this.props.location.state.redirect);
        }
        else
        {
            this.props.history.push("/");
        }
        //}
    }

    // showPassword = () => {
    //     if (this.state.passwordType == "password") {
    //         this.setState({ passwordType: "text" });
    //     }
    //     else {
    //         this.setState({ passwordType: "password" });
    //     }
    // }

    onForgot = () => {
        this.props.passwordReset()
        this.props.history.push("/reset-password")
    }

    render() {
        return (
            <div className="signin" >
                <div className="watermark" style={{ paddingBottom: 0, paddingTop: 80 }}>
                    {this.state.view === "welcome" ? "" :
                        <>
                            <i className="icon icon-econ"></i>
                            <div className="product">Ericsson Eritop ASP</div>
                        </>
                    }
                </div>
                <div className={"welcome " + (this.state.view === "welcome" ? "" : "hidden")} style={{ position: "absolute", top: "35%" }}>
                    <div className="message">Welcome</div>
                    <div className="username" hidden={this.state.TFAuthToken} >{this.props.user.userData.username}</div>
                    <div className="loading" style={{ textAlign: "left", marginLeft: 5 }}></div>
                </div>
                <div className={"error " + (this.state.error ? "" : "hidden")}>
                    {this.state.error}
                </div>
                <form style={{alignSelf : 'center' , marginTop : 100}}
                    className={this.state.view === "form" ? "" : "hidden"}
                    onSubmit={this.routeToAdLogin}
                >
                   
                  
                   

                  

                    <button className="btn big primary" type="submit" disabled={this.state.isLoading}>
                        Sign in
                    </button>
                   
                    <label htmlFor="privacy"> You can read more about at <a href="/policy">Privacy Policy</a></label>

                    
                </form>
                {
                    this.state.view === "welcome" ? "" :
                        <div className={"footer"}>© Ericsson AB {new Date().getFullYear()} - All Rights Reserved. <br />
                            No part of this software may be reproduced in any form without the written permission of the copyright holder.
                        </div>
                }

            </div>
        );
    }
    /*
    render() {
        return (
            <div className="signin" >
                <div className="watermark" style={{ paddingBottom: 0, paddingTop: 80 }}>
                    {this.state.view === "welcome" ? "" :
                        <>
                            <i className="icon icon-econ"></i>
                            <div className="product">Ericsson Eritop ASP</div>
                        </>
                    }
                </div>
                <div className={"welcome " + (this.state.view === "welcome" ? "" : "hidden")} style={{ position: "absolute", top: "35%" }}>
                    <div className="message">Welcome</div>
                    <div className="username" hidden={this.state.TFAuthToken} >{this.props.user.userData.username}</div>
                    <div className="loading" style={{ textAlign: "left", marginLeft: 5 }}></div>
                </div>
                <div className={"error " + (this.state.error ? "" : "hidden")}>
                    {this.state.error}
                </div>
                <form
                    className={this.state.view === "form" ? "" : "hidden"}
                    onSubmit={this.onSubmit}
                >
                    <div className="field validate">
                        <label htmlFor="username" style={{ fontSize: 12, opacity: "0.7" }}>Email Address</label>
                        <input
                            type="email"
                            name="username"
                            id="username"
                            placeholder="Email"
                            tabIndex="0"
                            autoFocus
                            required
                            value={this.state.username}
                            onChange={e => this.setState({ username: e.target.value })}
                        />
                    </div>
                    <div className="field validate">
                        <label htmlFor="password" style={{ fontSize: 12, opacity: "0.7" }}>Password</label>
                        <input
                            type={this.state.passwordType}
                            name="password"
                            id="password"
                            placeholder=" "
                            tabIndex="0"
                            required
                            onChange={e => this.setState({ password: e.target.value })}
                        />
                        <div className="suffix icon-inside">
                            // { <span className="reveal tooltip">
                            //     <i className="icon icon-eye" onClick={this.showPassword}></i>{" "}
                            //     <span className="message bottom-end">Show password</span>
                            // </span> }
                        </div>
                    </div>
                    <input type="checkbox" name="remember" id="remember" tabIndex="0" ref="rememberMe" defaultChecked={true} />
                    <label htmlFor="remember">Remember me</label>

                    <input type="checkbox" name="privacy" id="privacy" tabIndex="0" ref="privacyRef" />
                    <label htmlFor="privacy">I read and understand the <a href="/policy">Privacy Policy</a></label>

                    <button className="btn big primary" type="submit" disabled={this.state.isLoading}>
                        Sign in
                    </button>

                    <div className="help" >
                        <a className="subtle-link" onClick={this.onForgot}>
                            Having trouble signing in?
                        </a>
                    </div>
                </form>
                {
                    this.state.view === "welcome" ? "" :
                        <div className={"footer"}>© Ericsson AB {new Date().getFullYear()} - All Rights Reserved. <br />
                            No part of this software may be reproduced in any form without the written permission of the copyright holder.
                        </div>
                }

            </div>
        );
    }*/
}

const mapStateToProps = state => ({ user: state.user, userAdminData: state.userAdmin });
const mapDispatchToProps = dispatch => {
    return {
        passwordReset: () => dispatch({ type: "RESET_PASSWORD" }),
        login: (loginUser) => dispatch({ type: "LOGIN", payload: loginUser }),
        setUserLoginRoles: (loginRoles) => dispatch({ type: "SET_USER_LOGIN_ROLES", payload: loginRoles }),
        setRscCenters: (rscCnters) => dispatch({ type: "SET_RSC_CENTERS", payload: rscCnters }),
        setWebToken: (webToken) => dispatch({type: "SET_WEB_TOKEN", payload: webToken}),
        setUserRefreshed:(userRefreshed)=>dispatch({type: "SET_USER_REFRESHED", payload: userRefreshed})
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EuiSignIn));
