import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../../../css/livestreamStyle.css";
import Clock from 'react-live-clock';
import OpvSessionCustom from "../opv-session/opvSessionCustom";
import VideocamOffOutlinedIcon from '@material-ui/icons/VideocamOffOutlined';
import { Tooltip, IconButton } from "@material-ui/core";
import ApiClient from "../../../utils/api-client";
import { ToastContainer, toast } from "react-toastify";
import Timer from "react-compound-timer";
import constants from "../../../utils/constants";
import DialogModal from "../../../components/dialog";
import FormField from "../../../eui-components/formfield";
import EuiTextField from "../../../eui-components/textfield";
import EuiLoading from "../../../eui-components/loading";

class RecordLiveStream extends React.Component {
    constructor(props) {
        super(props);
        this.opvSessionCustom = React.createRef();
        this.state = {
            audioEnabled: true,
            videoEnabled: false,
            fullScreen: false,
            recordVideoEnable: false,
            recordedUserName: "",
            disabled: " disabled ",
            openDialog: false,
            recordedFileName: ""
        }
    }

    componentDidMount() {
        this.props.setPublisher(undefined);
        
        
    }

    toggleAudio = () => {
        if (!this.props.liveStreamData.publisher || !this.props.liveStreamData.publisher.stream || !this.props.liveStreamData.publisher.stream.connection) {
            toast.error("Please, wait until stream uploaded completely!", { containerId: 'RS', position: toast.POSITION.TOP_CENTER });
            return;
        }

        this.setState({ audioEnabled: !this.state.audioEnabled },
            () => { this.props.liveStreamData.publisher.publishAudio(this.state.audioEnabled); });
    }

    toggleVideo = () => {
        
        if (!this.props.liveStreamData.publisher ||
            !this.props.liveStreamData.publisher.stream ||
            !this.props.liveStreamData.publisher.stream.connection ||
            !this.props.liveStreamData.publisher.stream.connection.stream) {
            toast.error("Please, wait until stream uploaded completely!", { containerId: 'RS', position: toast.POSITION.TOP_CENTER });
            return;
        }

        this.setState({ videoEnabled: !this.state.videoEnabled },
            () => { this.props.liveStreamData.publisher.publishVideo(this.state.videoEnabled); });
    }

    isActive = (type) => {
        if (type === 1) {
            return (this.state.audioEnabled ? "" : "active");
        } else if (type === 2) {
            return (this.state.videoEnabled ? "" : "active")
        } else if (type === 3) {
            return (this.props.liveStreamData.hideOthers ? "active" : "")
        } else if (type === 4) {
            return (this.state.recordVideoEnable ? "active" : "")
        }
    }

    leaveSession = () => {
        
        if (this.state.recordVideoEnable) {
            toast.error("Please, stop recording video before leave!", { containerId: 'RS', position: toast.POSITION.TOP_CENTER });
            return;
        }
        if (this.opvSessionCustom) {
            this.opvSessionCustom.leaveSession();
            this.props.toggleLiveStreamMod(false);
            if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION) {
                this.props.getAllLiveSession();
            } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.ACTIVITY) {
                this.props.history.push("/activity-inbox");
            } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.EHS) {
                this.props.history.push("/ehs-inbox");
            }
            this.props.setLiveSessionInfo({ type: 0, sessionCode: "", latitude: "", longitude: "", streamingUserName: "", streamingUserId: 0, streamingId: 0, sessionId: 0, sessionName: "", siteName: "" });
            
        }
    }

    leaveOfflineSession = () => {
        this.props.toggleLiveStreamMod(false);
        if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.ACTIVITY) {
            this.props.history.push("/activity-inbox");
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.EHS) {
            this.props.history.push("/ehs-inbox");
        }      
        this.props.setLiveSessionInfo({ type: 0, sessionCode: "", latitude: "", longitude: "", streamingUserName: "", streamingUserId: 0, streamingId: 0, sessionId: 0, sessionName: "", siteName: "" });
    }

    hideAllOthers = () => {
        this.props.toggleHideOthers();
    }

    recordVideo = () => {
        
        this.setState({ recordVideoEnable: true, disabled: " " }, () => {
            ApiClient.post("LiveSession/StartRecording",
                {
                    SessionCode: this.props.liveStreamData.liveSessionInfo.sessionCode,
                    recordedUserId: parseInt(this.props.userData.userData.userId),
                    recordedUserName: this.props.userData.userData.username
                })
                .then(response => {
                    
                    if (!response.data.success) {
                        this.setState({ recordVideoEnable: false, disabled: " disabled " }, () => {
                            if (response.data.errorCode === "ALREADY_RECORDED") {
                                toast.error(constants.liveStreamRecordCodes.ALREADY_RECORDED, { containerId: 'RS', position: toast.POSITION.TOP_CENTER });
                                return;
                            } else if (response.data.errorCode === "NO_CONNECTED_PARTICIPANTS") {
                                toast.error(constants.liveStreamRecordCodes.NO_CONNECTED_PARTICIPANTS, { containerId: 'RS', position: toast.POSITION.TOP_CENTER });
                                return;
                            } else if (response.data.errorCode === "SESSION_ALREADY_RECORDING") {
                                this.setState({ recordedUserName: response.data.recordedUserName }, () =>
                                    toast.error(constants.liveStreamRecordCodes.SESSION_ALREADY_RECORDING + " " + response.data.recordedUserName + " is now recording.",
                                        { containerId: 'RS', position: toast.POSITION.TOP_CENTER }));
                                return;
                            }
                        })
                    } else {
                       
                        this.setState({ recordedUserName: this.props.userData.userData.username ,
                            recordingUDID : response.data.recordingId ,
                            eritopRecordingId : response.data.eritopRecordingId

                         });
                    }
                }).catch(error => {
                    console.error(error);;
                });
        });
    }

    stopVideo = () => {
        
        this.setState({ openDialog: false }, () => {
            this.setState({ recordVideoEnable: false, recordedUserName: "", disabled: " disabled " }, () => {
                
                ApiClient.post("LiveSession/StopRecording",
                    {
                        SessionCode: this.props.liveStreamData.liveSessionInfo.sessionCode,
                        recordedUserId: parseInt(this.props.userData.userData.userId),
                        recordName: this.state.recordedFileName,
                        recordCode :  this.state.recordingUDID,
                        eritopRecordingId : this.state.eritopRecordingId
                    })
                    .then(response => {
                        
                    }).catch(error => {
                        console.error(error);;
                    });
            });
        });
    }

    isPublisherReady = () => {
        return !this.props.liveStreamData.publisher || !this.props.liveStreamData.publisher.stream || !this.props.liveStreamData.publisher.stream.connection;
    }

    refreshLiveSession = () => {
        this.props.setPublisher(undefined);
        
        ApiClient.post("ActivityInbox/GetLiveSessionInfo",
            { systemId: this.props.liveStreamData.liveSessionInfo.sessionId, type: this.props.liveStreamData.liveSessionInfo.type })
            .then(response => {
                
                if (response.data) {
                    const liveSessionInfo = { ...response.data };

                    liveSessionInfo.sessionName = this.props.liveStreamData.liveSessionInfo.sessionName;
                    liveSessionInfo.siteName = this.props.liveStreamData.liveSessionInfo.siteName;
                    this.props.setLiveSessionInfo(liveSessionInfo);
                }
            })
            .catch(error => { console.error(error);; })
    }

    render() {

        return (
            <div className="container-fluid p-0">
                <div className={"rowStream no-gutters " + (this.state.fullScreen ? "full-screen" : "")}>
                    <div className="col-md-12">
                        <div id="html-player" className="html-player session-player">
                            {!this.props.liveStreamData.liveSessionInfo.sessionCode ?
                                <div className="initialize">
                                    <div className="d-flex align-items-center flex-column">
                                        <div className="text mt-2" style={{ fontSize: 25 }}>
                                            Live session has not been started. Please <a onClick={() => this.refreshLiveSession()}>refresh</a> to check state or <a onClick={() => this.leaveOfflineSession()}>leave</a> from session!
                                            </div>
                                    </div>
                                </div>
                                :
                                <div>
                                    <OpvSessionCustom onRef={ref => (this.opvSessionCustom = ref)} />
                                    {this.isPublisherReady() ?
                                        <div className="initialize">
                                            <div className="d-flex align-items-center flex-column">
                                                <EuiLoading id="streamLoadId" style={{ top: "43%", }} isLoading={true} />
                                                <div className="text mt-2">Joining live session...</div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            }
                            {this.state.recordVideoEnable || this.state.recordedUserName !== "" ?
                                <div className="video-record-info">
                                    <i className="icon icon-alarm-level6 color-red"></i>
                                    <div>{this.state.recordedUserName + " is recording "}</div>
                                </div>
                                :
                                null
                            }
                            <div className="video-label">
                                <div className="logo mb-1" style={{ width: "24px", height: "24px" }}>
                                    {this.props.liveStreamData.videoLabelItems[2] ?
                                        <i className="icon icon-econ" style={{ fontSize: 25 }}></i> :
                                        null
                                    }
                                </div>
                                {this.props.liveStreamData.videoLabelItems[4] ?
                                    <div className="site-code">{this.props.sessionData.approveSession && this.props.sessionData.approveSession.siteCode}</div> :
                                    null
                                }
                                {this.props.liveStreamData.videoLabelItems[6] ?
                                    <div>{this.props.liveStreamData.liveSessionInfo.sessionCode}</div> :
                                    null
                                }
                                <div className="userLogin" style={{ color: "#fff", fontWeight: 100, fontSize: 16 }}>
                                    {this.props.liveStreamData.videoLabelItems[0] ?
                                        <div className="name">{this.props.userData.userMail}</div> :
                                        null
                                    }
                                    {this.props.liveStreamData.videoLabelItems[3] ?
                                        <div className="coords">{"lati: " + this.props.liveStreamData.liveSessionInfo.latitude + ", long: " + this.props.liveStreamData.liveSessionInfo.longitude} </div> :
                                        null
                                    }
                                </div>
                                {this.props.liveStreamData.videoLabelItems[5] ?
                                    <div>{this.props.liveStreamData.liveSessionInfo.sessionId + " : " + this.props.liveStreamData.liveSessionInfo.sessionName}</div> :
                                    null
                                }
                                {this.props.liveStreamData.videoLabelItems[7] ?
                                    <div>{this.props.liveStreamData.liveSessionInfo.streamingUserName}</div> :
                                    null
                                }
                                {this.props.liveStreamData.videoLabelItems[1] ?
                                    <div className="time"><Clock format={'YYYY-MM-DD HH:mm:ss'} ticking={true} /> </div> :
                                    null
                                }
                            </div>
                            {this.state.fullScreen
                                ?
                                <div className="full-screen-close">
                                    <Tooltip title="Close Full Screen" >
                                        <IconButton onClick={() => this.setState({ fullScreen: false })}>
                                            <i className="icon icon-cross" style={{ fontSize: 24 }} ></i>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>
                {!this.isPublisherReady() ?
                    <div className="row-ui">
                        <div className="ui-block">
                            <div className={"ui-item d-flex flex-column align-items-center " + this.isActive(4) + (this.state.recordVideoEnable ? " disabled " : "")}
                                onClick={() => this.recordVideo()}>
                                <i className={"icon icon-severity-cleared " + (this.state.recordVideoEnable ? "color-red" : "color-green")} style={{ fontSize: 25 }}></i>
                                <span className="mt-1">Record</span>
                            </div>
                            <div className={"ui-item d-flex flex-column align-items-center " + this.state.disabled} onClick={() => this.setState({ openDialog: true })}>
                                <i className="icon icon-video-stop" style={{ fontSize: 25 }}></i>
                                <span className="mt-1">Stop</span>
                            </div>
                        </div>
                        {
                            //<div className="ui-block disabled">
                            //    <div className="ui-item d-flex flex-column align-items-center">
                            //        <i className="icon icon-camera-photo" style={{ fontSize: 25 }}></i>
                            //        <span className="mt-1">Capture</span>
                            //    </div>
                            //</div>
                        }
                        <div className="ui-block">
                            <div className={"ui-item d-flex flex-column align-items-center " + this.isActive(1)}
                                onClick={() => this.toggleAudio()} >
                                <i className={"icon icon-mic" + (this.state.audioEnabled ? "" : "-off")} style={{ fontSize: 25 }}></i>
                                <span className="mt-1">Mic</span>
                            </div>
                            <div className={"ui-item d-flex flex-column align-items-center " + this.isActive(2)}
                                onClick={() => this.toggleVideo()}>
                                {this.state.videoEnabled ?
                                    <i className="icon icon-camera-classic" style={{ fontSize: 25 }}></i>
                                    :
                                    <VideocamOffOutlinedIcon style={{ fontSize: 25 }} />
                                }
                                <span className="mt-1">Video</span>
                            </div>
                            <div className={"ui-item d-flex flex-column align-items-center " + this.isActive(3)}
                                onClick={() => this.hideAllOthers()}>
                                <i className="icon icon-group" style={{ fontSize: 25 }}></i>
                                <span className="mt-1">Hide Participants</span>
                            </div>
                            <div className="ui-item d-flex flex-column align-items-center" onClick={() => this.setState({ fullScreen: true })}>
                                <i className="icon icon-maximize" style={{ fontSize: 25 }}></i>
                                <span className="mt-1">Maximize</span>
                            </div>
                        </div>
                        <div className="ui-block">
                            <div className="playback-timer w-100 h-100 d-flex justify-content-center align-items-center">
                                {this.state.recordVideoEnable ?
                                    <div>
                                        <Timer initialTime={0} formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`} >
                                            <Timer.Hours formatValue={value => `${value}:`} />
                                            <Timer.Minutes formatValue={value => `${value}:`} />
                                            <Timer.Seconds formatValue={value => `${value}`} />
                                        </Timer>
                                    </div>
                                    :
                                    <div>00:00:00</div>
                                }
                            </div>
                        </div>
                        <div className="ui-block">
                            <div className="ui-item d-flex flex-column align-items-center "
                                onClick={() => this.leaveSession()}>
                                <i className="icon icon-logout" style={{ fontSize: 25 }}></i>
                                <span className="mt-1">Leave Session</span>
                            </div>
                        </div>
                    </div> :
                    null
                }
                <DialogModal
                    buttonName="Ok"
                    content={<FormField style={{ width: 400 }}>
                        <EuiTextField id="recordedFileName"
                            label="Name"
                            style={{ width: "100%" }}
                            name="recordedFileName"
                            onChange={(e) => this.setState({ recordedFileName: e.target.value })}
                        >
                        </EuiTextField>
                    </FormField>}
                    handleClose={() => this.setState({ openDialog: false })}
                    id="stopRecordingDialog"
                    onClick={this.stopVideo}
                    open={this.state.openDialog}
                    title="Stop Recording ?"
                    titleId="stopRecordingTitle"
                >
                </DialogModal>
                <ToastContainer enableMultiContainer containerId={'RS'} autoClose={2500} />
            </div>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
        setPublisher: publisher => dispatch({ type: "SET_PUBLISHER", payload: publisher }),
        toggleLiveStreamMod: (mod) => dispatch({ type: "TOGGLE_LIVE_STREAM_MOD", payload: mod  }),
        toggleHideOthers: () => dispatch({ type: "TOGGLE_HIDE_OTHERS" }),
        setRecordings: (recordings) => dispatch({ type: "SET_RECORDINGS", payload: recordings }),
        setLiveSessionInfo: (liveSessionInfo) => dispatch({ type: "SET_LIVE_SESSION_INFO", payload: liveSessionInfo }),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RecordLiveStream));
