import React from "react";

export default class EuiSearchField extends React.Component {
    render() {
        const classes = [];
        this.props.fullwidth && classes.push("fullwidth");
        this.props.disabled && classes.push("disabled");
        this.props.required && classes.push("required");

        return (
            <div className={this.props.className ?? ""}>
                <input type="text" className={classes.join(' ')} {...this.props} />
                <div className="suffix icon-inside"><i className="icon icon-search"></i></div>
            </div>
        );
    }
}
