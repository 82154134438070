import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import EuiTextArea from "../../eui-components/textarea";
import PageHelper from "../../utils/page-helper";
import EuiTile from "../../eui-components/tile";
import { withRouter } from "react-router-dom";

class EhsPingInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    goActivityDetailPage = () => {
        this.props.setActivityFilter({ ...this.props.activityData.filter, id: this.props.ehsPingData.ehsPing.activityId });
        this.props.history.push("/activities");
    }

    goWorkplanDetailPage = () => {
        this.props.setFilter({ ...this.props.workplanData.filter, id: this.props.ehsPingData.ehsPing.workplanId });
        this.props.history.push("/workplans");
    }

    goSiteDetailPage = () => {
        this.props.setSiteFilter({ ...this.props.siteData.filter, id: this.props.ehsPingData.ehsPing.siteId });
        this.props.history.push("/sites");
    }

    render() {
        let parsedHighrisk = "";
        ///console.log(this.props.ehsPingData.ehsPing.HighRiskIntegrationOutput);
        if (this.props.ehsPingData.ehsPing.HighRiskIntegrationOutput !== "") {
            let highRiskObj = JSON.parse(this.props.ehsPingData.ehsPing.HighRiskIntegrationOutput)
            delete highRiskObj.SEVERITY;
            parsedHighrisk = Object.entries(highRiskObj).reduce((acc, [key, value]) => { return acc + `${key}: ${value}\n`; }, '');
        }
        return (<EuiRow className="scrollable">
            <div className="p-1 w-3 pt-2">{PageHelper.renderActivityStatusAsHtml(this.props.ehsPingData.ehsPing.acceptanceStatusId)}</div>
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingCustomerNameFilter" name="customerName" label="Customer Name" value={this.props.ehsPingData.ehsPing.customerName} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingProjectNameFilter" name="projectName" label="Project Name" value={this.props.ehsPingData.ehsPing.projectName} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingSiteNameFilter" name="siteName" label="Site Name"
                value={this.props.ehsPingData.ehsPing.siteName}
                additionalInfo={<a onClick={this.goSiteDetailPage}>{this.props.ehsPingData.ehsPing.siteId}</a>}
            />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingWorkplanNameFilter" name="workplanName" label="Workplan Name"
                value={this.props.ehsPingData.ehsPing.workplanName}
                additionalInfo={<a onClick={this.goWorkplanDetailPage}>{this.props.ehsPingData.ehsPing.workplanId}</a>}
            />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingActivityNameFilter" name="activityName" label="Activity Name"               
                value={this.props.ehsPingData.ehsPing.activityName}
                additionalInfo={<a onClick={this.goActivityDetailPage}>{this.props.ehsPingData.ehsPing.activityId}</a>}
            />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingAspCompanyFilter" name="aspCompany" label="ASP Company Name" value={this.props.ehsPingData.ehsPing.aspCompanyName} />

            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingTeamLeadFilter" name="teamLead" label="Team Leader"
                value={this.props.ehsPingData.ehsPing.teamLead}
                additionalInfo={this.props.ehsPingData.ehsPing.teamLeadMail}
            />

            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingUploadedOnFilter" name="uploadedOn" label="Uploaded On" value={DateUtils.toDateString(this.props.ehsPingData.ehsPing.uploadDate, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingRscPersonFilter" name="rscPerson" label="EHS Supervisor"
                value={this.props.ehsPingData.ehsPing.rscPersonName}
                additionalInfo={this.props.ehsPingData.ehsPing.rscPersonMail}

            />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPinSentToRscOnFilter" name="sentOhsToRsc" label="Latest Submit DateTime to RSC" value={DateUtils.toDateString(this.props.ehsPingData.ehsPing.sentOhsToRsc, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />

            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingAssignedOnFilter" name="assignedOn" label="Assigned to RSC Engineer" value={DateUtils.toDateString(this.props.ehsPingData.ehsPing.assignedOn, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingRatingDateFilter" name="ratingDate" label="Rating Date" value={DateUtils.toDateString(this.props.ehsPingData.ehsPing.ratingDate, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingRscScopeFilter"
                name="rscScope"
                inputClassName="pill-frame"
                label="Session Type"
                value={this.props.ehsPingData.ehsPing.rscScope} />
            <EuiTile title="Eritop & Erisite Integration Information" tileStyle={{ paddingLeft: 5 }} headerStyle={{ paddingBottom: 5 }}>
                <EuiRow>
                    <EuiTextField className="p-1  pl-0 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingIntegrationStatus" name="integrationStatus" label="Erisite Integration Status" value={PageHelper.getYesNo(this.props.ehsPingData.ehsPing.integrationStatus)} />
                    <EuiTextField className="p-1  pl-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="ehsPingIntegationDate" name="integrationDate" label="Erisite Integration Date" value={DateUtils.toDateString(this.props.ehsPingData.ehsPing.integrationDate, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
                    <EuiTextArea className="p-1 pl-0 w-4" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} style={{ minHeight: 200 }} id="activityErisiteIntegrationOutput" name="integrationOutput" label="Erisite Integration Output" value={this.props.ehsPingData.ehsPing.integrationOutput} />
                </EuiRow>
                <EuiRow>
                    <EuiTextArea className="p-1 pl-0 w-4" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} style={{ minHeight: 200 }} id="ehsPingIntegrationHighRisk1" name="HighRiskIntegrationOutput" label="Erisite Integration High Risk" value={parsedHighrisk} />
                </EuiRow>

            </EuiTile>
        </EuiRow >);
    }
}

const mapStateToProps = state => ({
    userData: state.user, navbarData: state.navigation,
    ehsPingData: state.ehsPing,
    activityData: state.activity,
    workplanData: state.workplan,
    siteData: state.site
});
const mapDispatchToProps = dispatch => {
    return {
        setActivityFilter: (filter) => dispatch({ type: "SET_ACTIVITY_FILTER", payload: filter }),
        setFilter: (filter) => dispatch({ type: "SET_FILTER", payload: filter }),
        setSiteFilter: (filter) => dispatch({ type: "SET_SITE_FILTER", payload: filter }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EhsPingInfo));