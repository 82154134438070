import React, { useState, useEffect } from 'react';
import { Timeline } from '@eds/vanilla';

const EuiTimeline = (props) => {

    useEffect(() => {
        if (props.id) {
            const timelines = document.querySelectorAll('#' + props.id);

            if (timelines) {
                Array.from(timelines).forEach((timelineDOM) => {
                    const timeline = new Timeline(timelineDOM, props.data);
                    timeline.init({
                        sorting: "timestamp",
                        headings: props.headings || true
                    });
                });
            }
        }
    }, []);

    return (
        <div className="timeline " id={props.id}></div>
    );
}
export default EuiTimeline;