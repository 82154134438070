import React from "react";


class EuiSwitch extends React.Component {
    render() {
        return (
            <div id={this.props.id} className={this.props.className ?? ""} style={this.props.style}>
                <label className="switch" style={this.props.labelStyle ?? { width: "100%" }}>
                    <input type="checkbox"
                        name={this.props.name}
                        checked={this.props.checked}
                        onChange={() => this.props.onChange && this.props.onChange({ target: { checked: !this.props.checked, name: this.props.name } })}
                        disabled={this.props.disabled} />
                    <i className="ball"></i>
                    <span data-enabled={this.props.dataEnabled} data-disabled={this.props.dataDisabled} style={this.props.dataStyle}></span>
                </label>
            </div>
        );
    }
}

export default EuiSwitch;
