import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import EuiSelect from "../../../eui-components/select";
import FormField from "../../../eui-components/formfield";

class SettingsItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            videoQualityOptions: [{ value: 1, text: "Ultra low" }, { value: 2, text: "Low" }, { value: 3, text: "Medium" }, { value: 4, text: "High" }],
            selectedQuality: 2
        }
    }

    componentDidMount() {

    }


    render() {
        return (
            <div className="settings" style={{ width: this.props.liveStreamData.width, padding: "10px 20px", height:"auto" }}>
                <h3>Video quality</h3>
                <FormField>
                    <EuiSelect
                        style={{ background: "transparent", borderRadius: "0px"}}
                        optionListStyle={{ position: "inherit" }}
                        options={this.state.videoQualityOptions}
                        value={this.state.selectedQuality}
                        name="videoQuality"
                        id="videoQualityId"
                        onChange={e => this.setState({ selectedQuality: e.target.value })}
                    >
                    </EuiSelect>
                </FormField>
            </div>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsItem);
