import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import FormField from "../../../eui-components/formfield";
import AutoCompleteMultiSelect from "../../../components/autocompletemultiselect";
import ApiClient from "../../../utils/api-client";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import WorkflowHelper from "../../../utils/workflow-helper";


class WorkflowStepsMailDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            warning: ""
        }
    }

    componentDidMount() {
        if (this.props.customerId > 0) {
            ApiClient.post("Session/GetCustomerUsersOfCustomer", { id: this.props.customerId }).then(response => {
                if (!response.data || response.data.length === 0) {
                    this.setState({ warning: "There isn't any customer user, Please create user profile for the customer!" });
                    return;
                }
                const users = WorkflowHelper.mapUsers(response.data);
                this.setState({ users }, () => {
                    if (this.props.workflowSteps && this.props.workflowSteps.length > 0)
                        this.props.workflowSteps.map(item => {
                            if (item.workflowStepApproverUsers.length > 0) {
                                WorkflowHelper.mapUserValues(item.workflowStepApproverUsers, users);
                            }
                        });
                });
            }).catch(error => {
                console.error(error);;
            });
        }
    }

    handleChange = (event, index) => {
        const target = event.target;
        const value = target.value
        const checked = target.checked;

        

        const workflowSteps = [...this.props.workflowSteps];
        if (target.type === "checkbox") {
            workflowSteps[index].sendMail = checked;
        } else {
            workflowSteps[index].workflowStepApproverUsers = value;
        }
        this.props.setWorkflowSteps(workflowSteps);
    }

    renderMailApprovals = () => {
        return <div style={{ width: 500 }}>
            {this.props.workflowSteps.map((item, index) => {
                return <FormField key={item.name}>
                    <label>{item.name}</label>
                    <div style={{ display: "flex", alignItems: "start", justifyContent: "space-between" }}>
                        <AutoCompleteMultiSelect
                            id={index + item.name}
                            style={{ width: 294 }}
                            options={this.state.users}
                            value={item.workflowStepApproverUsers}
                            onChange={e => this.handleChange(e, index)}
                        />

                        <FormControlLabel
                            style={{ display: "flex" }}
                            control={
                                <Checkbox
                                    checked={item.sendMail}
                                    onChange={e => this.handleChange(e, index)}
                                    color="primary"
                                />
                            }
                            label={<span style={{ fontSize: 14 }}>Send Mail Notification</span>}
                        />
                    </div>
                </FormField>
            }
            )}
        </div>
    }

    render() {
        return (
            <EuiRow style={this.props.viewOnly ? { pointerEvents: "none", opacity: "0.6" } : {}}>
                {
                    this.state.users && this.state.users.length > 0 ?
                        this.renderMailApprovals() :
                        <div>{this.state.warning}</div>
                }
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkflowStepsMailDialog);
