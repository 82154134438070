import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../../layout";
import EuiRow from "../../../eui-components/row";
import EuiTextField from "../../../eui-components/textfield";
import EuiTile from "../../../eui-components/tile";
import EuiSelect from "../../../eui-components/select";
import ApiClient from "../../../utils/api-client";
import PhoneInput from 'react-phone-input-2'
import { ToastContainer, toast } from 'react-toastify';
import 'react-phone-input-2/lib/style.css';
import { Notification } from '@eds/vanilla';
import ApiHelper from "../../../utils/api-helper";



class CreateCustomerProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            createDate: new Date(),
            customerInfo: {},
            username: "",
            phone: "",
            customerId: 0,
            customers: {},
            customersNames: [],
            countries: [],
            countryId: "",
            country: {},
            customerName: "",
            email: "",
            userid: "",
            createOutput: {},
            userInfo: {},
            status: "",
            languages: [{ value: 1, text: "English" }],
            lang: { value: 0, text: "Select Language" },
            language: "English",
            statusField: "",
            countryValue: [{ value: 0, Text: "Select Country" }],
            customerValue: [{ value: 0, Text: "Select Customer" }],
            create: false,
            countrySelected: "",
            position: "",
            role: 100,
            customerID: "",
            createClicked: true,

            Added: ""

            // jobroles: [{ value: 1, text: "Engineer" }, { value: 2, text: "Technician" }, { value: 3, text: "Rigger" }, { value: 4, text: "Supervisor" }]
        };
    }


    //Load Data
    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        this.setState({ isLoading: true }, () => {
            ApiHelper.setCountriesWithDetails((data) => this.props.setAllCountries(data), (data) => this.props.setAllCustomers(data));
            this.setState({ isLoading: false, cutomersNames: this.props.userData.customers });
        });

    }

    //search customer user
    onClick = event => {
        ApiClient.post("User/GetUserByEmail",
            {
                Email: this.state.email,
                isLoading: true,
            }
        ).then(response => {
            this.setState({
                userid: response.data.id
            })
            ApiClient.post("User/GetCustomerUserById",
                {
                    id: this.state.userid,
                    isLoading: true,

                }
            ).then(response1 => {
                this.setState({
                    userInfo: response.data,
                    customerInfo: response1.data,
                    date: response1.data.createDate,
                    isLoading: false,
                    username: response.data.name,
                    email: response.data.email,
                    customerValue: response1.data.customerId,
                    position: response1.data.position,
                    // jobId: this.state.jobroles[response1.data.position].value
                    phone: response1.data.phoneNumber,
                })


                if (this.state.customerInfo.name == "") {
                    toast.error("No customer exist with this Email!", { containerId: 'CP', position: toast.POSITION.TOP_CENTER });

                    this.setState({
                        status: "No Customer Exist!",
                        position: "",
                        date: null,
                        phone: "",
                        username: ""
                    })
                }
                if (this.state.customerInfo.name != "") {
                    this.setState({

                        status: "Active"
                    })
                }
                ApiClient.post("User/getCustomerById",
                    { customerId: this.state.customerValue }
                ).then(response2 => {
                    this.setState({
                        customers: response2.data,
                        countryValue: response2.data.countryId
                    })
                    ApiClient.post("User/getCountryById",
                        { countryId: this.state.customers.countryId }
                    ).then(response3 => {
                        this.setState({
                            country: response3.data,
                        })
                    })
                })
            })
        })
    }





    //handle select changes
    handleChangeCustomer = (event) => {
        const target = event.target;
        const value = target.value;
        this.setState({

            customerValue: value
        })
    }




    handleChangeCountry = (event) => {
        const target = event.target;
        const value = target.value;
        const mapCustomers = [...this.props.userData.customers];

        mapCustomers.map(cus => {
            cus.hidden = !(value == cus.countryId);
        });

        this.setState({
            customersNames: mapCustomers,
            customerValue: 0,
            countryValue: event.target.value
        })
    }





    //add customerUser
    onSubmit = () => {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(this.state.email)) {

            if (this.state.username == "" || this.state.phone == "" || this.state.position == "" || this.state.countryValue.length >= 1 || this.state.customerValue.length >= 1) {
                toast.warn("Please complete the required fields", { containerId: 'CP', position: toast.POSITION.TOP_CENTER });

            }
            else {
                const customerUser = {
                    email: this.state.email,
                    name: this.state.username,
                    position: this.state.position,
                    phoneNumber: this.state.phone,
                    customerId: this.state.customerValue,
                    createUserId: parseInt(this.props.userData.userData.id),
                    createUser: this.props.userData.userData.username
                }
                this.setState({
                    isLoading: true
                }, () => {
                    ApiClient.post("User/AddCustomerUser", customerUser
                    ).then(response => {
                        this.setState({
                            isLoading: false
                        })
                        if (!response.data.error) {
                            const notification = new Notification({
                                title: 'Add Customer User',
                                description: 'Customer profile created successfully',
                                timeout: 5000,
                                icon: 'icon-check'
                            });
                            notification.init();
                        }
                        else {
                            const notification = new Notification({
                                title: 'Add Customer User',
                                description: response.data.error,
                                timeout: 5000,
                                icon: 'icon-cross'
                            });
                            notification.init();
                        }
                    })
                        .catch(error => {
                            alert(error);
                            this.setState({
                                isLoading: false
                            });
                        })
                })
            }
        }
        else {
            toast.warn("Please enter valid email!", { containerId: 'CP', position: toast.POSITION.TOP_CENTER });
        }
    }



    onCreate = event => {

        this.setState({
            createClicked: false,
            email: '',
            username: '',
            customerInfo: '',
            userInfo: '',
            customers: '',
            status: '',
            phone: '',
            customerID: '',
            position: '',
            countryValue: [{ value: 0, text: "Select Country" }],
            customerValue: [{ value: 0, text: "Select Customer" }],
            date: ''


        })


    }

    render() {
        return (
            <div id="page">
                <EuiTile fullscreen>
                    <EuiRow style={{ fontSize: "14px" }}>
                        <div className="tile sm-12 lg-12 xl-8" id="createProfile">

                            <FormField style={{ height: 30, marginTop: "10px", marginBottom: 0, display: "flex", alignItems: "center" }}>

                                <EuiTextField style={{ marginRight: "0px", width: "100%", height: 25, marginBottom: "9px", marginTop: "0px" }}
                                    className="w-5"
                                    type="email"
                                    label="Email Address *"
                                    id="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={e => this.setState({ email: e.target.value })}
                                />

                                <button className="btn"
                                    onClick={this.onClick}
                                    style={{ marginLeft: "25px" }}
                                    hidden={this.state.create}


                                >Search</button>
                                <span className="tooltip pointer" style={{ marginTop: "5px", whiteSpace: "pre", width: "50px" }} id="clearData">
                                    <i className="icon icon-reload" style={{ fontSize: "20px", marginLeft: "10px" }} onClick={this.onCreate}></i>
                                    <span className={(this.props.tipPosition ? "message " + this.props.tipPosition : "message top")}>Clear Data</span>
                                </span>

                            </FormField>

                            <FormField style={{ height: 30, marginTop: "20px", marginBottom: "15px" }}><EuiTextField style={{ marginRight: "0px", width: "50%", height: 25, marginBottom: "0px", marginTop: "2px" }}
                                label="Name *"
                                name="name"
                                id="name"
                                value={this.state.username}
                                onChange={e => this.setState({ username: e.target.value })}
                            //disabled
                            />
                            </FormField>

                            { /*  <FormField style={{ height: 30, marginTop: "20px", marginBottom: "0px" }}><EuiTextField type="tel" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" style={{ marginRight: "0px", width: "50%", height: 20, marginBottom: "0px", marginTop: "2px" }}
                                label="Mobile Number*"
                                id="phone"
                                value={this.state.phone}
                                onChange={phone => this.setState({ phone })}
                            //disabled
                            />*/}

                            <FormField style={{ height: 30 }}> <EuiTextField style={{ marginRight: "0px", width: "50%", height: 25 }}
                                label="Position *"
                                name="Position"
                                // required
                                value={this.state.position}
                                onChange={e => this.setState({ position: e.target.value })}
                            />

                            </FormField>
                            <FormField style={{ height: 30 }}>
                                <label>Phone Number *</label><PhoneInput
                                    inputStyle={{
                                        paddingBottom: "0px", paddingTop: "0px", height: "25px", width: "50%", background: (document.body.className === "dark" ? "#242424" : "white")
                                    }}
                                    label="Mobile Number*"
                                    placeholder="Mobile Number"
                                    country={'sa'}
                                    value={this.state.phone}
                                    onChange={phone => this.setState({ phone })}
                                    autoFormat={true}
                                    dropdownStyle={{ maxWidth: 470, width: 570, color: "#242424" }}

                                />

                            </FormField>
                            <FormField style={{ height: 30 }}>

                                <label>Country *</label> <EuiSelect
                                    style={{ border: "1px solid #c8c8c8", borderRadius: "0px", width: "50%", height: 26 }}
                                    optionListStyle={{ maxWidth: 470, width: 570, }}
                                    label="---Select Country---"
                                    options={this.props.userData.countries || []}
                                    value={this.state.countryValue}
                                    name="CountryId"
                                    id="CountryrId"
                                    required
                                    onChange={e => this.handleChangeCountry(e)}
                                />

                            </FormField>
                            <FormField style={{ height: 30 }}><label>Customer *</label> <EuiSelect
                                style={{ border: "1px solid #c8c8c8", borderRadius: "0px", width: "50%", height: 26 }}
                                optionListStyle={{ maxWidth: 470, width: 570 }}
                                label="---Select Customer---"
                                options={this.state.customersNames || []}
                                value={this.state.customerValue}
                                name="customerId"
                                id="sessionCustomerId"
                                required
                                onChange={e => this.handleChangeCustomer(e)}
                            />
                            </FormField>
                            <FormField style={{ height: 31 }}>

                                <EuiTextField style={{ marginRight: "0px", width: "50%", height: 25 }}
                                    label="Active Since"
                                    name="Language"
                                    value={this.state.date}
                                    disabled
                                />
                            </FormField>


                            <FormField style={{ height: 30 }}> <EuiTextField style={{ marginRight: "0px", width: "50%", height: 25 }}
                                label="Language"
                                name="Language"
                                value={this.state.language}
                                disabled
                            />


                            </FormField>
                            <FormField style={{ height: 30 }}><EuiTextField style={{ marginRight: "0px", width: "50%", height: 20 }}
                                label="Status"
                                name="userstatus"
                                disabled
                                value={this.state.status}

                            />
                            </FormField>
                            <button className={"btn primary m-1 " + (this.state.isLoading ? "loading disabled" : "")}
                                style={{ width: "20px", margin: 15 }}
                                onClick={this.onSubmit}
                            // disabled={this.state.createClicked}
                            >

                                Create
                            </button>

                        </div>
                    </EuiRow>
                </EuiTile>
                <ToastContainer enableMultiContainer containerId={'CP'} autoClose={2500} />
            </div >
        );
    }
}

const FormField = (props) => (
    <div style={{ margin: 15, ...props.style }} className="field validate">
        {props.children}
    </div>
);

const mapStateToProps = state => ({ navbarData: state.navigation, userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setAllCountries: (countries) => dispatch({ type: "SET_ALL_COUNTRIES", payload: countries }),
        setAllCustomers: (customers) => dispatch({ type: "SET_ALL_CUSTOMERS", payload: customers }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(CreateCustomerProfile)));

