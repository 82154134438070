import React, { useState } from "react";
import { useDispatch } from "react-redux";

import EuiCard from "../../../eui-components/card";
import EuiRow from "../../../eui-components/row";
import EuiCheckbox from "../../../eui-components/checkbox";

const ManageColumnsDialog = (props) => {
    const dispatch = useDispatch();

    const [listOfColumns, setListOfColums] = useState([
        { name: "Build Hub", checked: false, key: "buildHub" },
        { name: "Customer Unit", checked: false, key: "customerUnit" },
        { name: "Country", checked: false, key: "countryId" },
        { name: "Customer", checked: false, key: "customerId" },
        { name: "Project", checked: false, key: "projectId" },
        { name: "ASP Company", checked: false, key: "aspCompanyId" },
        { name: "Team Leader", checked: false, key: "resourceId" },
    ]);

    const handleChange = (e) => {
        const listOfColumnsCopy = [...listOfColumns];
        const selectedColumn = listOfColumnsCopy.find(item => item.name == e.target.name);
        if (listOfColumnsCopy.filter(item => item.checked)?.length < 2 || selectedColumn.checked) {
            selectedColumn.checked = !selectedColumn.checked;
            setListOfColums(listOfColumnsCopy);
        }
        const groupingColumns = listOfColumns?.filter(item => item.checked)?.map(item => item.key);
        dispatch({ type: "SET_PRODUCTIVITY_TEAM_GROUPING_COLUMNS", payload: groupingColumns });
    };

    const renderCards = () => {
        const cards = listOfColumns.map(item => {
            var card = <EuiCard className="draggable" id={item.name + "cardId"}
                title={item.name}
                actionIcon={
                    <EuiCheckbox key={listOfColumns}
                        id={item.name + "cardCheckbox"}
                        name={item.name}
                        label=" "
                        refValue={item.checked}
                        onChange={(e) => handleChange(e)}
                    />
                }
            />;
            return card;
        }
        )
        return cards;
    }

    return (<EuiRow className="w-10">
        <EuiRow className="mb-3" style={{ color: "red" }}> *Select maximum two columns to group by</EuiRow>
        <div className="drop-area w-10">
            <div className="empty-state">
                <div><i className="icon icon-plus text-xl"></i></div>
                <div>Empty container</div>
                <div className="color-gray">Add an item by dragging into this container</div>
            </div>
            {renderCards()}
        </div>
    </EuiRow >)
}
export default ManageColumnsDialog;