const initialState = {
    countryCustomersList: [],
    mode: "initial",
    selectedCustomer: {},
    selectedCountry: "",
    editCheckData: false,
    rscCustomers: []

}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_COUNTRY_CUSTOMERS_LIST": {
            return {
                ...state,
                countryCustomersList: action.payload
            };
        }
        case "VIEW_CUSTOMER": {
            return {
                ...state,
                mode: action.payload.mode,
                selectedCustomer: action.payload.selectedCustomer,
                selectedCountry: action.payload.selectedCountry
            };
        }
        case "EDIT_CUSTOMER": {
            return {
                ...state,
                mode: action.payload,
                selectedCustomer: action.payload.selectedCustomer,
                selectedCountry: action.payload.selectedCountry
            };
        }
        case "EDIT_CHECK_DATA": {
            return {
                ...state,
                editCheckData: action.payload
            };
        }
        case "SET_RSC_CUSTOMERS": {
            return {
                ...state,
                rscCustomers: action.payload
            }
        }
        default:
            return state;


    }
}

