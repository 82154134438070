import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import constants from "../../utils/constants";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import CommonHelper from "../../utils/common-helper";
import EuiNumberField from "../../eui-components/numberfield";
import Googlemaps from "../../components/Map/Googlemaps";


class SiteInfo extends React.Component {
   
    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        const text = target.text;
        
        const site = { ...this.props.siteData.site };
        if (name === "customerId") {
            site.customerName = text;
        }
        site[name] = value;
        if (name === "eritopOnsiteRadius" || name === "eritopVehicleOnsiteRadius") {
            site[name] = +value;
        }
        this.props.setSite(site);
    }

    render() {
        return (
         <div className="row" >
         <div className="column sm-12 md-6 lg-6 xl-6 filled" style={{}}>
        <EuiRow>
            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                required
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteName"
                name="name"
                label="Site Name*"
                value={this.props.siteData.site.name}
                onChange={(e) => this.handleChange(e)} />

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                required
                style={{ padding: 0  }}
                labelstyle={{ color: "gray" }}
                id="siteCode" name="code"
                label="Site Code*"
                value={this.props.siteData.site.code}
                onChange={(e) => this.handleChange(e)} />

            {this.props.mod === constants.actionMods.VIEW_ONLY ?
                <EuiTextField className="p-1 w-5"
                    fullwidth
                    readOnly
                    style={{ padding: 0 }}
                    labelstyle={{ color: "gray" }}
                    id="customerSiteText" name="customerName"
                    label="Customer*"
                    value={this.props.siteData.site.customerName || ""}
                />
                :
                <EuiAutocompleteSelect
                    className="p-1 w-5"
                    fullwidth
                    required
                    label={{ title: "Customer*", className: "color-gray" }}
                    id="customerSiteSelect"
                    name="customerId"
                    options={this.props.userData.customers || []}
                    value={this.props.siteData.site.customerId || 0}
                    onChange={(e) => this.handleChange(e)}
                    placeholder="---Select Customer---"
                />
            }

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteCustomerSiteId"
                name="customerSiteId"
                label="Customer Site Id"
                value={this.props.siteData.site.customerSiteId}
                onChange={(e) => this.handleChange(e)} />


            <EuiTextField className="p-1 w-10"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteCustomerSiteName"
                name="customerSiteName"
                label="Customer Site Name"
                value={this.props.siteData.site.customerSiteName}
                onChange={(e) => this.handleChange(e)} />

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteLatitude"                
                name="latitude"
                label="Latitude"
                inputClassName={"validation " + (CommonHelper.isValidCoordinate() ? " valid " : "")}
                pattern="-?[0-9]{1,3}[.][0-9]+"
                validationMessage="Coordinates can only contain numbers and dot"
                value={this.props.siteData.site.latitude}
                onChange={(e) => this.handleChange(e)} />

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteLongitude"
                name="longitude"
                label="Longitude"
                inputClassName={"validation " + (CommonHelper.isValidCoordinate() ? " valid " : "")}
                pattern="-?[0-9]{1,3}[.][0-9]+"
                validationMessage="Coordinates can only contain numbers and dot"
                value={this.props.siteData.site.longitude}
                onChange={(e) => this.handleChange(e)} />

            
            <EuiNumberField
                id="onsiteRadius"
                name="eritopOnsiteRadius"
                fullwidth
                className="p-1 w-5"
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                label="Onsite radius(meter)"
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                disabled={this.props.mod === constants.actionMods.VIEW_ONLY}
                min={200}
                max={null}
                value={this.props.siteData.site.eritopOnsiteRadius}
                onChange={(e) => this.handleChange(e)}
            />
            
            <EuiNumberField
                id="vehicleOnsiteRadius"
                name="eritopVehicleOnsiteRadius"
                fullwidth
                className="p-1 w-5"
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                label="Vehicle onsite radius (meter)"
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                disabled={this.props.mod === constants.actionMods.VIEW_ONLY}
                min={200}
                max={null}
                value={this.props.siteData.site.eritopVehicleOnsiteRadius}
                onChange={(e) => this.handleChange(e)}
            />

            <EuiTextField className="p-1 w-10"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteAddress"
                name="address"
                label="Site Address"
                value={this.props.siteData.site.address}
                onChange={(e) => this.handleChange(e)} />

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteOwnerName"
                name="ownerName"
                label="Owner Name"
                value={this.props.siteData.site.ownerName}
                onChange={(e) => this.handleChange(e)} />

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteOwnerEmail"
                name="ownerEmail"
                label="Owner Email"
                value={this.props.siteData.site.ownerEmail}
                onChange={(e) => this.handleChange(e)} />

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteOwnerPhone"
                name="ownerPhone"
                label="Owner Phone"
                value={this.props.siteData.site.ownerPhone}
                onChange={(e) => this.handleChange(e)} />

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteOwnerAddress"
                name="ownerAddress"
                label="Owner Address"
                value={this.props.siteData.site.ownerAddress}
                onChange={(e) => this.handleChange(e)} />

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteCity"
                name="city"
                label="City Name"
                value={this.props.siteData.site.city}
                onChange={(e) => this.handleChange(e)} />

            <EuiTextField className="p-1 w-5"
                fullwidth
                readOnly={this.props.mod === constants.actionMods.VIEW_ONLY}
                style={{ padding: 0 }}
                labelstyle={{ color: "gray" }}
                id="siteRegion"
                name="region"
                label="Region Name"
                value={this.props.siteData.site.region}
                onChange={(e) => this.handleChange(e)} />            
        </EuiRow>
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6 filled" style={{}}>
            <Googlemaps origin = {{ lat: parseFloat(this.props.siteData.site.latitude), lng: parseFloat(this.props.siteData.site.longitude )}} />
        </div>
        </div>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, siteData: state.site });
const mapDispatchToProps = dispatch => {
    return {
        setSite: (site) => dispatch({ type: "SET_SITE", payload: site }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteInfo); 