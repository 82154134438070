import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import { toast } from "react-toastify";
import ApiClient from "../../utils/api-client";
import EuiDatepicker from "../../eui-components/datepicker";
import constants from "../../utils/constants";
import EuiPillGroup from "../../eui-components/pillgroup";
import ActivityHelper from "../../utils/activity-helper";


const IMActivityFilter = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const imActivityFilter = useSelector(state => state.imInbox.imActivityFilter);
    const imFilterTypes = useSelector(state => state.imInbox.imFilterTypes );
    const userId = useSelector(state => state.user.userData.userId);
    const imActivities = useSelector(state => state.imInbox.imActivityList);

    const dispatch = useDispatch();

    const getActivities = () => {
        const filter = { ...imActivityFilter };

        //if (!filter || Object.values(filter).every(x => (!x || x === ''))) {
        //    toast.error("Please, fill any field to get related activities", { containerId: "IM", position: toast.POSITION.TOP_CENTER });
        //    return;
        //}

        filter.id = parseInt(filter.id ?? "0");
        filter.filterTypes = imFilterTypes.map(item => parseInt(item));
        filter.userId = parseInt(userId);

        setIsLoading(true);
        ApiClient.post("Activity/GetIMFilteredActivities", filter)
            .then(response => {
                if (response.data) {
                    const activities = response.data;
                    
                    ActivityHelper.mapIMActivites(activities);
                    
                    dispatch({ type: "SET_IM_ACTIVITY_LIST", payload: activities });
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error(error);;
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getActivities();
    }, []);

    const handleChange = (e) => {
        const filter = { ...imActivityFilter };

        const target = e.target;
        const name = target.name;
        const value = target.value;
        filter[name] = value || "";

        
        dispatch({ type: "SET_IM_ACTIVITY_FILTER", payload: filter });
    }

    const handleFilterTypes = (pills) => {
        
        dispatch({ type: "SET_IM_FILTER_TYPES", payload: [...pills] });
    }
    
    return (<EuiRow>
        <EuiPillGroup id="imActivityPillsId" pills={constants.pills} onChange={(filteredPills) => handleFilterTypes(filteredPills)} />
        <EuiTextField className="m-1 w-10" fullwidth id="activityIdFilter" name="id" label="Activity ID (=)" labelClass="color-gray"
            value={imActivityFilter.id || ""} onChange={(e) => handleChange(e)}
            clear={true}
            onClear={() => handleChange({ target: { value: "", name: "id" } })}
        />
        <EuiTextField className="m-1 w-10" fullwidth id="activityErisiteWorkplanIdFilter" name="erisiteWorkplanId" label="Erisite Workplan ID(=)" labelClass="color-gray"
            value={imActivityFilter.erisiteWorkplanId || ""} onChange={(e) => handleChange(e)}
            clear={true}
            onClear={() => handleChange({ target: { value: "", name: "erisiteWorkplanId" } })}
        />
        <EuiTextField className="m-1 w-10" fullwidth id="activityNameFilter" name="name" label="Activity Name (*)" labelClass="color-gray"
            value={imActivityFilter.name || ""} onChange={(e) => handleChange(e)}
            clear={true}
            onClear={() => handleChange({ target: { value: "", name: "name" } })}
        />
        <EuiTextField className="m-1 w-10" fullwidth id="activityWorkplanNameFilter" name="workplanName" label="Workplan Name (*)" labelClass="color-gray"
            value={imActivityFilter.workplanName || ""} onChange={(e) => handleChange(e)}
            clear={true}
            onClear={() => handleChange({ target: { value: "", name: "workplanName" } })}
        />

        <EuiDatepicker className="m-1 w-10" fullwidth id="activityActualStartFilter" name="actualStartDate" label="Actual Start Date (=)" labelClassName="color-gray"
            value={imActivityFilter.actualStart || ""} onChange={(e) => handleChange(e)}

        />

        <EuiTextField className="m-1 w-10" fullwidth id="activityCustomerSiteIdFilter" name="customerSiteId" label="Customer Site ID (*)" labelClass="color-gray"
            value={imActivityFilter.customerSiteId || ""} onChange={(e) => handleChange(e)}
            clear={true}
            onClear={() => handleChange({ target: { value: "", name: "customerSiteId" } })}
        />

        <button className={"btn primary m-1 " + (isLoading ? "loading disabled" : "")} onClick={() => getActivities()}>Search</button>
    </EuiRow >);
}

export default IMActivityFilter;