import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import WorkplanSearch from "./workplanSearch";
import constants from "../../utils/constants";
import WorkplanDetails from "./workplanDetails";
import "../../css/workplanStyle.css";
import WorkplanHelper from "../../utils/workplan-helper";
import PageHelper from "../../utils/page-helper";
import ApiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import CommonHelper from "../../utils/common-helper";

class Workplans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }

        this.setState({ isLoading: true }, () => {
            ApiClient.post("User/GetCustomersWithProjects")
                .then(response => {
                    this.props.setAllCustomersWithProjects(response.data);
                    
                    this.setState({ isLoading: false });
                }).catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                })
        });
    }

    createEditWorkplan = () => {
        
        const workplan = this.props.workplanData.workplan;
        const oldWorkplan = workplan.id > 0 ? this.props.workplanData.filteredWorkplans.data.find(item => item.id === workplan.id) : workplan;
        const containerId = workplan.id > 0 ? "WD" : "WS";
        workplan.siteId = workplan.siteModel?.value;
        workplan.farendId = parseInt(workplan.farendId);
        workplan.lastModifiedUser = parseInt(this.props.userData.userData.id);
        let visitor = CommonHelper.getVisitorData();
        workplan.logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: Object.entries(oldWorkplan).reduce((a, b) => {
                if (b[0].startsWith("eri") || b[0].startsWith("material") || !["string", "number"].includes(typeof b[1])) {
                    return a;
                }
                return a + b.join(" : ") + "    |    ";
            }, ""),
        };
        
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/CreateEditWorkplan", workplan).then(response => {
                if (response) {
                    toast.success(constants.processPozitiveMessage, { containerId: containerId, position: toast.POSITION.TOP_CENTER });
                    const filter = this.props.workplanData.filter;
                    const initialState = new Date();
                    ApiClient.post("Workplan/GetFilteredWorkplans", filter)
                        .then(response => {
                            const data = [...response.data.data];
                            WorkplanHelper.mapWorkplans(data);
                            
                            this.props.setFilteredWorkplans({ data, totalCount: response.data.totalCount, initialState: initialState.getTime() });
                        })
                        .catch(error => {
                            console.error(error);;
                        });
                } else {
                    toast.error(constants.processNegativeMessage, { containerId: "WS", position: toast.POSITION.TOP_CENTER });
                }
                this.setState({ isLoading: false });
            }).catch(error => {
                console.error(error);;
            });
        });
    }

    renderMod = () => {
        if (this.props.workplanData.mod === constants.pageMods.VIEW_ONLY) {
            return <WorkplanDetails key={this.props.workplanData.updateElement} isLoading={this.state.isLoading} createEditWorkplan={this.createEditWorkplan} />
        } else {
            return <WorkplanSearch isLoading={this.state.isLoading} createEditWorkplan={this.createEditWorkplan} />;
        }
    }

    render() {
        return (this.renderMod());
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, workplanData: state.workplan });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setFilteredWorkplans: (filteredWorkplans) => dispatch({ type: "SET_FILTERED_WORKPLANS", payload: filteredWorkplans }),
        setAllCustomersWithProjects: (customersWithProjects) => dispatch({ type: "SET_ALL_CUSTOMERS_WITH_PROJECTS", payload: customersWithProjects }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(Workplans)));