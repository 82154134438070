import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTabs from "../../eui-components/tabs";
import AddCertificateDialog from "./addCertificateDialog"
import EuiLoading from "../../eui-components/loading";
import EuiDialog from "../../eui-components/dialog";
import ApiClient from "../../utils/api-client";
import DateUtils from "../../utils/date-utils";
import AspHelper from "../../utils/asp-helper";
import constants from "../../utils/constants";
import PageHelper from "../../utils/page-helper";
import DialogModal from "../../components/dialog";
import CommonHelper from "../../utils/common-helper";
import SasHelper from "../../utils/sas-helper";


class JobRoles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpened: false,
            titles: [],
            contents: [],
            Done: false,
            columns: [{
                title: "Course Code",
                key: "code",
                width: "12%",
                sort: 'none'
            },
            {
                title: "Course Name",
                key: "name",
                width: "20%",
                sort: 'none'

            },
            {
                title: "Category",
                key: "category",
                width: "8%",
                onCreatedCell: (td, cellData) => {
                    if (cellData == "Mandatory")
                        td.innerHTML = `<div className="flex-start-content"><i class="icon icon-warning-circle color-red m-1 "></i>${cellData}</div>`
                    else
                        td.innerHTML = `<div className="flex-start-content"><i class="icon icon-warning-circle color-green m-1 "></i>${cellData}</div>`
                },
                sort: 'asc',

            },
            {
                title: "Valid Since",
                key: "validSince",
                width: "9%",
                sort: 'none'

            },
            {
                title: "Expiry Date",
                key: "validUntil",
                width: "9%",
                sort: 'none'

            },

            {
                title: "Status",
                key: "status",
                width: "8%",
                onCreatedCell: (td, cellData) => {
                    if (cellData) {
                        td.innerHTML = PageHelper.renderCrtStatus(cellData);
                    }
                    else
                        td.innerHTML = ""

                },
                sort: 'none'
            },
            {
                title: "Certificate",
                key: "path",
                width: "9%",
                onCreatedCell: (td, cellData) => {
                    if (cellData) {
                        var r = Math.floor(Math.random() * 1000) + 1;
                        td.innerHTML = `<a id="certificate_"  ><span id="certificate_" href=${SasHelper.addSasKey(cellData, this.props.sasToken)} target="_blank"><i class="icon icon-download"></i>Certificate</span></a>`;
                        if (td.querySelector("#certificate_")) {
                            td.querySelector("#certificate_").addEventListener('click', () => this.openViewCrtDialog(r, SasHelper.addSasKey(cellData, this.props.sasToken)));
                        }
                    }
                    else
                        td.innerHTML = ""
                },
                sort: 'none'
            },
            {
                title: "Last Modified",
                key: "lastModifiedDateTime",
                width: "13%",
                sort: 'none'
            },
            {
                title: "Modified By",
                key: "modifiedUserName",
                width: "16%",
                sort: 'none'
            },
            {
                title: "",
                onCreatedCell: (td, cellData) => {
                    if (cellData && (this.props.userData.userData.jobRole == constants.userRoles.ERICSSON_IM || this.props.userData.userData.jobRole == constants.userRoles.RSC_OHS_INSPECTOR || this.props.userData.userData.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR || this.props.userData.userData.jobRole == constants.userRoles.VALIDATION_OFFICER
                    )) {
                        td.innerHTML = `<span id="addB_" class="tooltip" clickable><i id="addB_" class="icon icon-plus text-lg clickable "></i><span class="message top-end">Add certificate</span></span>`;
                        td.querySelector("#addB_").addEventListener('click', () => this.openEditDialog(cellData))
                    }
                    else
                        td.innerHTML = "";
                },
                key: "id",
                width: "1%",
            }
            ],
            isLoading: true,
            selectedTab: "",
            data: [],
            test: false,
            allData: [],
            ViewCertDialogOpened: false,
            certFile: "",
            type: "",
            selectedCertId: "",
            crtDone: false,
            openViewCrtDialog: false
        }
    }

    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        var titles = [];
        var contents = [];
        if (this.props.aspResourcesData.resource?.inCompletedCerts && this.props.aspResourcesData.resource.inCompletedCerts.length) {
            this.props.aspResourcesData.resource.inCompletedCerts.forEach(e => {
                var _data = e.certificates;
                var data = [];
                _data.forEach(d => {
                    var _c = this.props.aspResourcesData.certificates.filter(x => x.value == d.certificateId);
                    if (d.added) {
                        var addedCert = { ...this.props.aspResourcesData.resource.certs.filter(c => c.certificationId == d.certificateId)[0] };
                        addedCert.id = null;
                    }

                    var element = {
                        ...d,
                        ...addedCert,
                        name: _c[0].text,
                        code: _c[0].code,
                        category: (d.isMandatory ? "Mandatory" : "Optional")
                    }
                    data.push(element);
                })
                var index = "table_" + this.props.aspResourcesData.resource.inCompletedCerts.indexOf(e);
                var title = {
                    value: this.props.aspResourcesData.resource.inCompletedCerts.indexOf(e),
                    name: e.job.text,
                    selected: (this.props.aspResourcesData.resource.inCompletedCerts.indexOf(e) == 0 ? "selected" : ""),
                }
                var content = {
                    value: this.props.aspResourcesData.resource.inCompletedCerts.indexOf(e),
                    children: <EuiRow className="w-10" fullwidth>
                        <ObseleteEuiTable
                            classes="tiny"
                            columns={this.state.columns}
                            data={data}
                            id={index}
                            //scroll={true}
                            sortable={true}
                            // filter={true}
                            //paginated={true}
                            // totalCount={totalCount}
                            rowsPerPage={13}
                        >
                        </ObseleteEuiTable>

                    </EuiRow>,
                    selected: (this.props.aspResourcesData.resource.inCompletedCerts.indexOf(e) == 0 ? "selected" : "")
                }

                titles.push(title);
                contents.push(content);
            });
        }
        this.setState({
            titles: titles,
            contents: contents,
            isLoading: false,
            selectedTab: titles[0]?.name || ""
        })
    }


    openEditDialog = (cellData) => {
        if (!this.state.dialogOpened) {
            var div = document.querySelector(".title.selected");
            var selectedTab = div?.innerText;
            const jobMandatories = this.props.aspResourcesData.jobs.filter(j => j.text == selectedTab)[0].mandatoryCerts;
            const selectedCertificate = jobMandatories.filter(c => c.id == cellData)[0];
            var certificate = this.props.aspResourcesData.certificates?.filter(e => e.value ===
                selectedCertificate?.certificateId)[0];
            certificate['id'] = 0;
            certificate['validSince'] = '';
            certificate['validUntil'] = '';
            certificate["resourceId"] = this.props.aspResourcesData.resource.id;
            certificate["certificationId"] = selectedCertificate.certificateId;
            certificate["name"] = certificate.name;
            certificate['path'] = '';
            // certificate["id"] = selectedCertificate.id;


            this.setState({
                // editIcon: "add_" + cellData,
                Done: true,
                dialogOpened: true,
                crtDone: true
            })
            this.props.setCertificate(certificate);
            document.querySelector("#addB_").click();

        }
    }

    onCancel = () => {
        var crt = this.props.aspResourcesData.certificate;
        crt = {};
        this.props.setCertificate(crt);
        this.props.disableAddCertForName(false);
        this.props.disableAddCertForDate(false);
        this.setState({
            toggleDialog: !this.state.togglaDialog,
            dialogOpened: false
        })
    }

    add = () => {
        var currentCertificate = { ...this.props.aspResourcesData.certificate };
        currentCertificate["createuser"] = parseInt(this.props.userData.userData.id);
        currentCertificate["createUserName"] = this.props.userData.userData.username;
        currentCertificate["modifiedUserName"] = this.props.userData.userData.username;
        currentCertificate["createdate"] = DateUtils.toDateString(DateUtils.getUtcDate(), "", true);
        currentCertificate["lastModifiedDateTime"] = DateUtils.toDateString(DateUtils.getUtcDate(), "", true);
        currentCertificate["lastModifiedUser"] = parseInt(this.props.userData.userData.id);

        this.props.setCertificate(currentCertificate);
        const resource = { ...this.props.aspResourcesData.resource }
        this.props.setCertificate(currentCertificate);
        var _c = resource["certs"];
        resource["certs"] = [];
        if (Object.keys(currentCertificate).length === 0) {
            this.props.setAddCrtNotify(true);
            this.setState({
                testinitialState: !this.state.testinitialState
            })
        }
        else if (!currentCertificate.certificationId
            || !currentCertificate.validSince
            || !currentCertificate.validUntil) {
            this.props.setAddCrtNotify(true);

            this.setState({
                testinitialState: !this.state.testinitialState
            })
        }
        else {
            if (Object.keys(currentCertificate).length) {
                this.props.setAddCrtNotify(false);
                let visitor = CommonHelper.getVisitorData();
                currentCertificate.logInfo = {
                    userId: parseInt(this.props.userData.userData.userId),
                    logBrowser: visitor.vBrowser,
                    logIP: visitor.vIP,
                    logLocation: visitor.vCountry,
                    latitude: visitor.latitude,
                    longitude: visitor.longitude,
                    logDetail: Object.entries(currentCertificate).reduce((a, b) => {
                        return a + b.join(" : ") + " , ";
                    }, ""),
                };
                this.setState({ isLoading: true, Done: true, crtDone: true }, () => {
                    ApiClient.post("Asp/AddCertificate", currentCertificate)
                        .then(response => {
                            currentCertificate["id"] = response.data;
                            if (_c)
                                _c.push(currentCertificate);
                            else
                                _c = currentCertificate;
                            resource["certs"] = _c;
                            this.updateData();
                            /*  var d = Date.now();
                              this.props.updateTable(d);*/
                            this.setState({

                                initialState: !this.state.initialState,
                                certs: _c
                            })

                            //this.updateTabs();

                        })
                        .catch(error => {
                            alert(error);
                            this.setState({ isLoading: false });
                        });
                });
            }
        }
    }

    updateData = () => {
        this.setState({
            isLoading: true
        })
        const filter = this.props.aspResourcesData.filter;
        if (!filter || Object.values(filter).every(x => (x === null || x === ''))) {
            filter.id = this.props.aspResourcesData.resource.id;
            filter.idsList = [];
            filter.idsList.push(this.props.aspResourcesData.resource.id);
        }
        filter.id = parseInt(filter.id ?? "0");
        ApiClient.post("Asp/GetResources", filter)
            .then(response => {
                ApiClient.post("Asp/GetResourceJobs", response.data.iDs)
                    .then(response2 => {
                        var _data = [];
                        var blobs = response.data.profileBlobs;
                        var resourceProjects = response.data.projects;
                        var certificates = [];
                        var resourceJobs = response2.data;
                        var skills = response.data.skills;
                        var faces = response.data.faces;
                        this.props.setResourceUsers(response.data.users);
                        response.data.certifications.forEach(c => {
                            var _c = this.props.aspResourcesData.certificates.filter(x => x.value == c.certificationId);
                            if (_c && _c[0]) {
                                var date = DateUtils.getUtcDate();
                                var diff = DateUtils.dateDifference(new Date(c.validUntil), new Date(date));
                                var element = {
                                    ...c,
                                    validSince: DateUtils.toDateString(c.validSince),
                                    validUntil: DateUtils.toDateString(c.validUntil),
                                    createdate: DateUtils.toDateString(c.createdate),
                                    lastModifiedDateTime: DateUtils.toDateString(c.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                                    createUserName: c.createUserName,
                                    modifiedUserName: c.modifiedUserName,
                                    name: _c[0].text,
                                    code: _c[0].code,
                                    path: c.path,
                                    status: (new Date(c.validUntil).getTime() - date.getTime() > 0 ? "Valid" : "Expired"),
                                    _delete: c.id
                                }
                                certificates.push(element);
                            }
                        })
                        this.props.setFilteredResources([]);
                        response.data.resource.forEach(e => {
                            var projects = AspHelper.mapProjects(resourceProjects?.filter(item => item.resourceId == e.id), this.props.aspResourcesData.projects, this.props.userData.customers);
                            var country = this.props.userData.countries.filter(c => c.value === e.countryId);
                            var company = this.props.aspResourcesData.companies.filter(c => c.value === e.aspCompanyId);
                            var cert = certificates.filter(c => c.resourceId === e.id);
                            var jobs = [];
                            var resourceSkills = skills.filter(s => s.resourceId == e.id);
                            var faceId = faces.find(f => f.resourceId == e.id)?.faceId;
                            var fullResourceSkills = AspHelper.getSkillInfo(resourceSkills, this.props.aspResourcesData.skills);
                            var _jobs = resourceJobs.filter(j => j.resourceId == e.id);
                            var inCompletedCerts = [];
                            var validResource = false;
                            _jobs.forEach(j => jobs.push(AspHelper.mapResourcesJobRole(j.value, this.props.aspResourcesData.jobs)));
                            var blob = blobs.filter(b => b.resourceId == e.id);
                            inCompletedCerts = AspHelper.getUnTakenCerts(cert, this.props.aspResourcesData.jobs, certificates, jobs);
                           /* if (e.status == "Active" && jobs.length) {
                                var test = true;
                                if (inCompletedCerts) {
                                    inCompletedCerts.forEach(c => {
                                        if (!c.valid)
                                            test = false;
                                    });
                                }
                                if (test)
                                    validResource = true;
                            }
                                */
                            var test = true;
                            if (inCompletedCerts) {
                             inCompletedCerts.forEach(c => {
                                 if (!c.valid)
                                     test = false;
                             });
                         }
                         validResource = test
                            if (jobs)
                            var element = {
                                ...e,
                                projects: projects,
                                status: e.status,
                                country: (country && country[0] ? country[0].text : ""),
                                aspCompany: (company && company[0] ? company[0].text : ""),
                                companyCountry: (company && company[0] ? company[0].countryId : ""),
                                profileImg: (blob && blob[0] ? constants.profileStorage + blob[0].key : ""),
                                certs: cert,
                                jobs: (jobs ? jobs : []),
                                skills: fullResourceSkills,
                                jobList: (_jobs ? _jobs : []),
                                inCompletedCerts: inCompletedCerts,
                                validResource: validResource,
                                faceId: faceId,
                                path: (faceId ? e.path : null),
                                imagePath: (faceId ? e.imagePath : null)
                            }
                            _data.push(element);
                        });
                        const data = [..._data];
                        const selectedResource = data.find(item => item.id === this.props.aspResourcesData.resource.id);
                        this.props.setAspResource(selectedResource);
                        this.props.setFilteredResources(data);
                        this.props.setSelectedItem(3);
                        var d = Date.now();
                        this.props.updateTable(d);
                        this.setState({ isLoading: false })


                    })
            }).catch(error => {
                console.error(error);;
                alert(error);
            });

    }

    openViewCrtDialog = (r, cellData) => {
        var splittedString = cellData.split(".");
        var type = "";
        if (!splittedString) {
            const notification = new Notification({
                title: 'Link Error',
                description: 'Certificate link is corrupted.',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        var extention = splittedString[splittedString.length - 1];
        if (extention == "jpg" || extention == "jpeg" || extention == "png" || extention == "PNG") {
            type = "img";
            this.setState({
                certFile: cellData,
                type: type,
                openViewCrtDialog: true
            });
        }
        else if (splittedString[splittedString.length - 1] == "pdf") {
            type = "pdf";
            this.setState({
                certFile: cellData,
                type: type,
                openViewCrtDialog: true
            });
        }
        else {
            type = "";
            this.setState({
                certFile: cellData,
                type: type,
                openViewCrtDialog: true
            });
        };

    }

    cancelViewCert = () => {
        this.setState({
            openViewCrtDialog: false,

        })
    }

    download = () => {
        const link = document.createElement('a');
        link.href = SasHelper.addSasKey(this.state.certFile, this.props.sasToken);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    render() {
        return (
            <EuiRow key={this.state.selectedTab} disabled={this.state.isLoading || this.props.aspResourcesData.resource.status == "Blacklisted" ? "disabled" : ""} >
                <EuiLoading id="jobRoles" isLoading={this.state.isLoading} />
                <>
                    <EuiTabs
                        titlesColumnStyle={{ marginBottom: "10px" }}
                        contentStyle={{ width: "100%" }}
                        // onClick={this.changeTab}
                        id="jobRoles"
                        titles={this.state.titles}
                        contents={this.state.contents}>
                    </EuiTabs>
                    {(document.querySelector('#addB_') ?
                        <EuiDialog
                            id="jobaddCert"
                            dataTrigger="#addB_"
                            title="Add Certificate"
                            body={<AddCertificateDialog id="jobaddCert" toggle={this.state.toggleDialog} edit={true} initialState={this.state.testinitialState} />}
                            buttonName="Add Certificate"
                            type={(this.props.aspResourcesData.disableAddCertForName ||
                                this.props.aspResourcesData.disableAddCertForDate ? "primary disabled" : "primary")}
                            onCancel={this.onCancel}
                            onClick={this.add}
                            contentStyle={{ width: "500px" }}
                            bodyStyle={{ height: "500px" }}
                        //   done={this.state.crtDone}

                        />
                        : null)}
                    <DialogModal
                        fullWidth={true}
                        id="viewCrtDialog"
                        open={this.state.openViewCrtDialog}
                        title="Certificate"
                        // contentStyle={{ width: "90%", height: "90%" }}
                        // bodyStyle={{ width: "100%", height: "90%" }}
                        content={
                            <div className="document-container">{(this.state.type === "img" ? <img className="responsive-document fit-image" src={SasHelper.addSasKey(this.state.certFile, this.props.sasToken)} />
                                : <iframe className="responsive-document fit-image" id="iframepdf" src={"https://docs.google.com/viewer?url=" + encodeURIComponent(SasHelper.addSasKey(this.state.certFile, this.props.sasToken)) + "&embedded=true"} frameBorder="0" />)}</div>}

                        buttonName="Download"
                        type="primary"
                        handleClose={this.cancelViewCert}
                        onClick={this.download}
                    />
                </>
            </EuiRow>
        )
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        changeAspResourcesMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setCertificate: (certificate) => dispatch({ type: "SET_CERTIFICATE", payload: certificate }),
        disableAddCertForName: (state) => dispatch({ type: "DISABLE_ADD_CERT_FOR_NAME", payload: state }),
        disableAddCertForDate: (state) => dispatch({ type: "DISABLE_ADD_CERT_FOR_DATE", payload: state }),
        setAddCrtNotify: (state) => dispatch({ type: "SET_ADD_CRT_NOTIFY", payload: state }),
        setFilteredResources: (filteredResources) => dispatch({ type: "SET_FILTERED_RESOURCES", payload: filteredResources }),
        updateTable: (val) => dispatch({ type: "UPDATE_TABLE", payload: val }),
        setSelectedItem: (selectedItem) => dispatch({ type: "UPDATE_SELECTED_ITEM", payload: selectedItem }),
        setSkills: (skills) => dispatch({ type: "SET_SKILLS", payload: skills }),
        setResourceUsers: (users) => dispatch({ type: "SET_USERS", payload: users }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JobRoles);


