import { Stack, Box } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { ChatHeader, ChatFooter } from "../../../components/chaTools/Chat";
import {
  DocMsg,
  LinkMsg,
  AudioMsg,
  ImageMsg,
  TextMsg,
  Timeline,
  VideoMsg,
} from "../sections/Messages";
import { Typography } from "@material-ui/core";
import SasHelper from "../../../utils/sas-helper";

const Conversation = ({ menu, activityId }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user);
  const userId = parseInt(userData.userData.userId);
  const sasToken = useSelector((state) => state.complaints.sasToken);
  const { current_messages } = useSelector(
    (state) => state.conversation.direct_chat
  );

  useEffect(() => {
    if (!sasToken) {
      SasHelper.getSasKey().then((response) => {
        dispatch({ type: "SET_SAS_TOKEN", payload: response });
      });
    }
  }, [sasToken]);

  useEffect(() => {
    // Scroll to the bottom of the message list when new messages are added
    console.log('current_messages from conversation', current_messages)
  }, [current_messages]);

  return (
    <Box p={3} xs={{ with: 20 }}>
      <Stack spacing={3}>
        {(current_messages || []).map((el, idx) => {
          switch (el.type) {
            case "divider":
              return (
                // Timeline
                <Timeline el={el} />
              );
            case "msg":
              switch (el.subtype) {
                case "img":
                  return (
                    // Media Message
                    <ImageMsg el={el} menu={menu} sasToken={sasToken} activityId={activityId} />
                  );
                case "audio":
                  return (
                    // Media audio
                    <AudioMsg el={el} menu={menu} sasToken={sasToken} activityId={activityId} />
                  );
                case "video":
                  return (
                    // Media audio
                    <VideoMsg el={el} menu={menu} sasToken={sasToken} activityId={activityId} />
                  );
                case "doc":
                  return (
                    // Doc Message
                    <DocMsg el={el} menu={menu} sasToken={sasToken} activityId={activityId} />
                  );
                case "Link":
                  return (
                    //  Link Message
                    <LinkMsg el={el} menu={menu} activityId={activityId} />
                  );

                default:
                  return (
                    // Text Message
                    <TextMsg el={el} menu={menu} activityId={activityId} />
                  );
              }

            default:
              return <></>;
          }
        })}
      </Stack>
    </Box>
  );
};

const ChatComponent = (props) => {
  const {
    rscScope,
    ehsPingId,
    activityId,
    createNewConversationOrRoom,
    connectionStatus,
    connection,
    filterConversation,
    setFilterConversation,
    refreshAndReconnect,
  } = props
  const theme = useTheme();
  const activityData = useSelector((state) => state.activity);

  const messageListRef = useRef(null);

  const { current_messages } = useSelector(
    (state) => state.conversation.direct_chat
  );

  useEffect(() => {
    // Scroll to the bottom of the message list when new messages are added
    messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
  }, [current_messages]);

 

  return (
    // 
    <Stack width={"auto"} height={"100%"} maxHeight={"100vh"} >
      {/*  */}
      <ChatHeader
        connectionStatus={connectionStatus}
        connection={connection}
        ehsPingId={ehsPingId}
        activityId={activityId}
        rscScope={rscScope}
        filterConversation={filterConversation}
        setFilterConversation={setFilterConversation}
        refreshAndReconnect={refreshAndReconnect}
      />
      <Box
        ref={messageListRef}
        height={"100vh"}
        sx={{
          position: "relative",
          flexGrow: 1,
          width: "100%",
          overflow: "hidden",
          overflowY: "scroll",
          backgroundColor:
            theme.palette.mode === "light"
              ? "#F0F4FA"
              : theme.palette.background,

          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",


        }}
      >
        <Conversation  menu={true} ehsPingId={ehsPingId} activityId={activityId} />
      </Box>

      {/*  activity not complited so allow chat*/}
      {
        // CHECK IF WE ARE IN EHS MANAGEMENT PAGE  activityData.activity.acceptanceStatusId
        ehsPingId > 0 ?
							// CHECK IF EHS PING NOT ACCEPTED
							 activityData.activity.acceptanceStatusId !== 2 ?
							  // THEN DISPLAY CHAT INPUT OR ALLOW USER TO CHAT
											  (<ChatFooter refreshAndReconnect={refreshAndReconnect} connection={connection} createNewConversationOrRoom={createNewConversationOrRoom}
                                  connectionStatus={connectionStatus}

                        />)
											  :
											  <Typography variant="h4" align="center" color="forestgree">
												This EHSping Id :{ehsPingId}  already Accepted
											  </Typography>

			
          :
          // CHEK IF WE ARE IN ACTIVITY PAGE THEN CHECK IF ACTIVITY NOT COMPLITED
          activityData.activity.acceptanceStatusId !== 2 ?
            // THEN DISPLAY CHAT INPUT OR ALLOW USER TO CHAT
            <ChatFooter refreshAndReconnect={refreshAndReconnect} connection={connection} createNewConversationOrRoom={createNewConversationOrRoom}/> :
            <Typography variant="h4" align="center" color="forestgree">
              This activity Id : {activityId}  already completed
            </Typography>

      }
    </Stack>
  );
};

export default ChatComponent;

export { Conversation };
