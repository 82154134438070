import React, { Component } from 'react';
import BpmnModeler from 'bpmn-js/lib/Modeler';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-font/dist/css/bpmn-embedded.css';
import { emptyBpmn } from '../../../assets/emptyBpmn';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda';
import WorkflowHelper from '../../../utils/workflow-helper';

class BpmnModelerComponent extends Component {

    componentDidMount = () => {
        WorkflowHelper.modeler = new BpmnModeler({
            container: '#bpmnview',
            keyboard: {
                bindTo: window
            },
            // propertiesPanel: {
            //     parent: '#propview'
            // },
            additionalModules: [
                propertiesPanelModule,
                propertiesProviderModule
            ],
            moddleExtensions: {
                camunda: camundaModdleDescriptor
            }
        });

        this.newBpmnDiagram();
    }

    componentDidUpdate(prevProps) {
        if (this.props.diagramXml !== prevProps.diagramXml) {
            this.newBpmnDiagram();
        }
    }

    newBpmnDiagram = () => {      
        if (this.props.editMod !== 0 && this.props.diagramXml) {           
            this.openBpmnDiagram(this.props.diagramXml);
        } else {
            this.openBpmnDiagram(emptyBpmn);
        }       
    }

    async openBpmnDiagram(xml) {
        try {
            const result = await WorkflowHelper.modeler.importXML(xml);
            const { warnings } = result;
            
            var canvas = WorkflowHelper.modeler.get('canvas');
            canvas.zoom('fit-viewport');
        } catch (err) {
            
        }

        //WorkflowHelper.modeler.importXML(xml, (error) => {
        //    if (error) {
        //        return 
        //    }
        //    var canvas = WorkflowHelper.modeler.get('canvas');
        //    canvas.zoom('fit-viewport');
        //});
    }

    //saveDiagram = () => {
    //    WorkflowHelper.modeler.saveSVG((err, svg) => {
    //        const blob = new Blob([svg], { type: 'text/plain;charset=utf-8' });
    //        
    //        FileSaver.saveAs(blob, 'bpmnSample.bpmn');
    //    });     
    //}

    //saveXML = () => {
    //    WorkflowHelper.modeler.saveXML({ format: true }, (err, xml) => {
    //        if (err) {
    //            return console.error('could not export BPMN 2.0 diagram xml', err);
    //        }
    //        const xmlClone = xml;
    //        
    //        const blob = new Blob([xml], { type: 'text/plain;charset=utf-8' });
    //        FileSaver.saveAs(blob, 'bpmnSample.bpmn');
    //    });
    //}

    //importFile = (e) => {
    //    const target = e.target;
    //    const file = target.files[0];
    //    let reader = new FileReader();
    //    reader.onload = e => {
    //        const data = e.target.result;
    //        
    //        this.openBpmnDiagram(data);
    //    }
    //    reader.readAsText(file);
    //}

    render = () => {
        return (
            <div id="bpmncontainer" style={{width:"100%"}}>               
                <div id="bpmnview" style={{ height: 450 }}></div>               
            </div>
        );
    }
}

export default BpmnModelerComponent;
