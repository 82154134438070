import React, { useEffect, useState } from 'react'
import { Modal, ModalGateway } from 'react-images'
import EditImage from './index.js'
import { IconButton } from '@material-ui/core';
import { Tooltip } from "@material-ui/core";
import 'tui-image-editor/dist/tui-image-editor.css'
import "../../css/icon-styles.css"
import "../../css/sliderStyle.css"
import SasHelper from '../../utils/sas-helper.js';

const EditInterface = (props) => {
   const { forwardedRef, path, name } = props
   const [sasToken, setSasToken] = useState({});
   useEffect(() => {
        SasHelper.getSasKey().then((response) => {
            setSasToken(response);
        });
    }, []);

    return (
        <ModalGateway>
            <Modal >
                <div>
                    <span style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Tooltip id="save" title="Save Image" className="icons-styling">
                            <IconButton style={{ display: "flex", justifyContent: "flex-end", zIndex: 50 }} aria-label="upload" id="upload" onClick={props.handleClick}>
                                <i className="icon icon-upload" style={{ fontSize: 25, color: "white", alignItem: "right", paddingTop: "10px" }}></i>
                            </IconButton>
                        </Tooltip>
                        <Tooltip id="close" title="close edit mode" className="icons-styling">
                            <IconButton style={{ display: "flex", justifyContent: "flex-end", zIndex: 50, paddingRight: "30px" }} aria-label="close" id="close" onClick={props.handleClose}>
                                <i className="icon icon-cross" style={{ fontSize: 25, color: "white", alignItem: "right", paddingTop: "10px" }}></i>
                            </IconButton>
                        </Tooltip>
                    </span>
                    <EditImage forwardedRef={forwardedRef} path={SasHelper.addSasKey(path, sasToken)} name={name}  />
                </div>
            </Modal>
        </ModalGateway >
        ) 
}
export default EditInterface