import React from "react";
import constants from "./constants";
import ActivityInboxHelper from "./activity-inbox-helper";
import DateUtils from "./date-utils";
import PageHelper from "./page-helper";


export default class EHSInboxHelper {

    static mapEhsPings = (data) => {
        if (!data || data.length === 0) {
            return [];
        }

        return data.map(item => {
            const ehsPing = {
                plainEhsPing: item.ehsPing,
                ...item.ehsPing,
                id: item.ehsPing.id,
                activityId: item.activity && item.activity.id,
                name: item.activity && item.activity.name,
                acceptanceStatusId: item.ehsPing.acceptanceStatusId,
                customerId: item.customer && item.customer.id,
                customerName: item.customer && item.customer.name,
                projectName: item.project && item.project.name,
                workplanName: item.workplan && item.workplan.name,
                siteCode: item.site && item.site.code,
                siteName: item.site && item.site.name,
                aspCompany: item.aspCompany && item.aspCompany.name,
                aspCompanyId: item.aspCompany && item.aspCompany.id,

                sentOhsToRsc: item.ehsPing && item.ehsPing.sentOhsToRsc,
                assigned: item.ehsPing && item.ehsPing.assigned,
                aspResource: item.aspResource,
                aspTeamLead: item.aspResource && item.aspResource.name,
                addInfo: {
                    workplanName: item.workplan && item.workplan.name,
                    siteName: item.site && item.site.name,
                    projectName: item.project && item.project.name
                },
                activity: {
                    id: item.activity?.id,
                    systemrecordid: item.activity?.systemrecordid,
                    rscScope: item.activity?.rscScope,
                    erisiteStatus: item.activity?.erisiteStatus,
                    resourceId: item.activity?.resourceId,
                    completed: item.activity?.completed,
                    ...item.activity
                },
                ratingComments: item.ehsPing.ratingComments,
                projectId: item.project?.id
            };
            if (item.streamings && item.streamings.length > 0) {
                item.streamings.sort((a, b) => parseInt(b.id) - parseInt(a.id));
                const liveSessionStatus = item.streamings[0].status;
                const liveSessionInfo = {
                    sessionId: item.ehsPing.id,
                    sessionName: item.activity && item.activity.name,
                    sessionCode: item.streamings[0].sessionCode,
                    latitude: item.streamings[0].latitude,
                    longitude: item.streamings[0].longitude,
                    streamingUserName: item.streamings[0].streamingUserName + constants.streamerSuffix,
                    streamingUserId: item.streamings[0].streamingUserId,
                    streamingId: item.streamings[0].id,
                    siteName: item.site?.name,
                    activityId: item.activity.id,
                    rscScope: item.activity?.rscScope,
                };
                
                ehsPing["liveSessionStatus"] = liveSessionStatus;
                ehsPing["liveSessionInfo"] = liveSessionInfo;
            } else {
                ehsPing["liveSessionInfo"] = { sessionId: item.ehsPing.id, sessionName: item.activity && item.activity.name, sessionCode: null };
                ehsPing["liveSessionStatus"] = 0;
            }
            return ehsPing;
        });
    }

    static mapChecklists = (checklists, aspResource, addInfo) => {
        if (!checklists || checklists.length == 0) {
            return [];
        }
        return checklists.map(item => {          
            item.opened = "";
            item.tableRef = React.createRef();
            item.documents = this.mapDocuments(item.documents, aspResource, addInfo, item.name);
            item.templateItems = this.mapTemplateItems(item.templateItems);
            return item;
        });
    }

    static mapTemplateItems = (templateItems) => {
        if (!templateItems || templateItems.length === 0) {
            return [];
        }

        return templateItems.map(item => {
            const newItem = { ...item };
            newItem.ratingOptions = item.ratingOptions &&
                item.ratingOptions.split(':')
                    .reduce((a, b, index, arr) => {
                        if (index % 2 === 0) {
                            return a;
                        } else {
                            const newObj = { text: b.toUpperCase(), value: parseInt(arr[index + 1]) };
                            a.push(newObj);
                            return a;
                        }
                    }, []);          
            return newItem;
        });

    }

    static mapDocuments = (documents, aspResource, addInfo, checklistName) => {
        if (!documents || documents.length === 0) {
            return [];
        }

        return documents.map(item => {
            const newItem = { ...item };
            newItem.name = newItem.name ?? newItem.imagePath.split("/").pop();
            newItem.title = <p className="info-title mb-2" style={{ color: "#fff" }}>{checklistName}</p>
            newItem.documentPath = newItem.imagePath;
            const viInfo = newItem.viapiResultNavigation?.viResultModel?.conclusions?.map((c, index) =>
                <li className="info-list-item" key={newItem.id + c.category + index}><span className="info-label">{c.category}</span><div className="pt-1"> {c.result}</div></li>);
            newItem.isVIImage = viInfo ? true : false;
            newItem.isVIDrawing = newItem.viapiResultNavigation?.viResultModel?.annotations?.length > 0 ? true : false;
            newItem.uploadDetails =
                <ul className="infoList mb-2" style={{ paddingInlineStart: 0, color: "#fff" }} >
                    <li className="info-list-item" key={newItem.id + "aspName"}>{aspResource?.name ?? ""}</li>
                    <li className="info-list-item" key={newItem.id + "aspPhone"}>{aspResource?.phone ?? ""}</li>
                    <li className="info-list-item" key={newItem.id + "aspMail"}>{aspResource?.email ?? ""}</li>
                </ul>
            newItem.additionalInfo = <span>
                <ul className="infoList" style={{ paddingInlineStart: 0, color: "#fff" }} >
                    <li className="info-list-item" key={newItem.id + "project"}><span className="info-label">Project: </span>{addInfo.projectName}</li>
                    <li className="info-list-item" key={newItem.id + "workplan"}><span className="info-label">Workplan: </span> {addInfo.workplanName}</li>
                    <li className="info-list-item" key={newItem.id + "site"}><span className="info-label">Site: </span> {addInfo.siteName}</li>
                    <li className="info-list-item" key={newItem.id + "latitude"}><span className="info-label">Latitude: </span> {ActivityInboxHelper.getLatitude(newItem.latitude)}</li>
                    <li className="info-list-item" key={newItem.id + "longitude"}><span className="info-label">Longitude: </span> {ActivityInboxHelper.getLongitude(newItem.longitude)}</li>
                    <li className="info-list-item" key={newItem.id + "uploadTime"}>
                        <span className="info-label">Upload Time: </span> {!newItem.uploadDate || !newItem.uploadStartDate ? "---"
                            : DateUtils.dateDifferenceInSeconds(newItem.uploadDate, newItem.uploadStartDate)}
                    </li>
                    <li className="info-list-item" key={newItem.id + "imReviewDate"}><span className="info-label">Actual Review DateTime </span>
                        <div className="pt-1">{DateUtils.toDateString(newItem.reviewDate, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"}</div>
                    </li>
                    <li className="info-list-item" key={newItem.id + "reviewEngineer"}><span className="info-label">Reviewer</span><div className="pt-1"> {newItem.reviewerUserName || "---"}</div></li>
                    {viInfo ?
                        <>
                            <div className="info-list-item" style={{ color: "#fff" }}>
                                <div className="info-label pb-1 pt-1" style={{ color: "yellow" }}>VI Inspection Result</div>
                                <li className="info-list-item" key={newItem.id + "viCode"}><div className="pt-1 pb-1">{newItem.viDocTag}</div></li>
                                {viInfo}
                                <li className="info-list-item" key={newItem.id + "viReason"}><div className="pt-1">{newItem.viapiResultNavigation?.viResultModel?.reason}</div></li>
                                <li className="info-list-item" key={newItem.id + "viResult"}>
                                    <div className="pt-1" style={{ color: PageHelper.getVIColor(newItem.viapiResultNavigation?.viResultModel?.result) }}>{newItem.viapiResultNavigation?.viResultModel?.result}</div>
                                </li>
                            </div>
                        </>
                        : null
                    }
                </ul>
            </span>
            if (newItem.type === constants.documentTypes.IMAGE) {
                newItem.posterPath = newItem.imagePath;
            } else {
                newItem.posterPath = ActivityInboxHelper.getPosterPath(newItem.type) ?? newItem.imagePath;
            }
            return newItem;
        });
    }

    static getChecklistColor = (ehsPing) => {

        const documents = ehsPing && ehsPing.documents;
        if (!documents || documents.length === 0) {
            return "";
        } else if (documents.every(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED)) {
            return "green";
        } else if (documents.every(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED)) {
            return "red";
        } else if (documents.every(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING)) {
            return "gray";
        } else {
            return "orange";
        }
    }

    static getChecklistCount = (rowData, approveExcludeSwitchRef) => {
        if (rowData && rowData.documents && rowData.documents.length > 0) {
            if (approveExcludeSwitchRef) {
                return rowData.documents.filter(doc => doc.acceptanceStatusId !== constants.acceptanceStatusTypes.ACCEPTED).length;
            } else {
                return rowData.documents.length;
            }
        } else {
            return 0;
        }
    }

    static getChecklistName = (rowData, approveExcludeSwitchRef) => {
        const color = this.getChecklistColor(rowData);
        const icon = color === "" ? "icon-circle" : "icon-alarm-level5 color-" + color;
        return <i className={"flex-middle-content text-sm icon " + icon}>
            <span className="subactivity-title">
                {rowData.name + " ( " + this.getChecklistCount(rowData, approveExcludeSwitchRef) + " ) "}
            </span>
        </i>
    }

    static getChecklists = (rowDataEhs, approveExcludeSwitchRef) => {
        if (rowDataEhs && rowDataEhs.checklists && rowDataEhs.checklists.length > 0) {
            if (approveExcludeSwitchRef) {
                return rowDataEhs.checklists.filter(item => item.documents &&
                    item.documents.length > 0 &&
                    item.documents.filter(doc => doc.acceptanceStatusId !== constants.acceptanceStatusTypes.ACCEPTED).length > 0
                );
            } else {
                return rowDataEhs.checklists;
            }
        } else {
            return [];
        }
    }

    static getTotalScore = (total, sum) => {
        if (!total || !sum) {
            return "0/0(0%)";
        }

        const percentage = Math.round((total / sum) * 100);
        return total + "/" + sum + "(" + percentage + "%)";
    }

    static getRatingChecklistName = (checklist) => {
        const color = this.getCheckpointsColor(checklist);
        const icon = color === "" ? "icon-circle" : "icon-alarm-level5 color-" + color;
        return <i className={"flex-middle-content text-sm icon " + icon}>
            <span className="subactivity-title" style={{ margin: 7 }}>
                {checklist.name}
            </span>
        </i>
    }

    static getCheckpointsColor = (checklist) => {
        const checkPoints = checklist && checklist.templateItems;
        if (!checkPoints || checkPoints.length === 0) {
            return "";
        } else if (checkPoints.every(item => item.score === constants.ehsRatingOptions.PASS)) {
            return "green";
        } else if (checkPoints.every(item => item.score === constants.ehsRatingOptions.FAIL)) {
            return "red";
        } else if (checkPoints.every(item => item.score === constants.ehsRatingOptions.NA)) {
            return "gray";
        } else {
            return "orange";
        }
    }

    static getErisiteAction = (status) => {
        if (status === true) {
            return <i className="icon icon-check p-1 color-green icon-lg"></i>;
        } else if (status === false) {
            return <i className="icon icon-triangle-warning p-1 color-red icon-lg"></i>;
        } else {
          return <i className="icon icon-help p-1 color-gray icon-lg"></i>;
        }
    }

}
