import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import EuiTile from "../../eui-components/tile";
import PageHelper from "../../utils/page-helper";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import DialogModal from "../../components/dialog";
import { setCertificate } from "../../store/actions/teamAction";

class TeamContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            openDialog: false,
            openDeleteDialog: {},
            certificationColumns:
                [
                    {
                        title: "Course Number",
                        key: "code",
                        width: "20%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Course Name",
                        key: "name",
                        width: "35%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Required Certificates",
                        key: "minCertificateNumber",
                        width: "10%",
                        headerStyle: "padding:1px;text-align:center;",
                        cellStyle: "text-align:center;"
                    },
                    {
                        title: "Active",
                        key: "isActive",
                        onCreatedCell: (td, cellData) => {
                            const icon = PageHelper.renderTeamActivation(cellData);
                            td.innerHTML = `${icon}`;
                            td.style.fontSize = "13px";
                        },
                        width: "15%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "",
                        onCreatedCell: this.onCreateEditCell,
                        key: "id",
                        width: "2%",
                    },
                    {
                        title: "",
                        onCreatedCell: this.onCreateStatusCell,
                        key: "id",
                        width: "2%",
                    },
                ],
        }
    }


    onCreateEditCell = (td, cellData) => {
        if (cellData) {
            td.innerHTML = `<span id="edit_" class="tooltip">
                <i id="edit_" style={font-weight:500px;} class="icon icon-edit color-black text-lg clickable  "></i>
                <span class="message top-end">Edit certificate</span>
            </span>`;
            td.querySelector("#edit_").addEventListener('click', () => this.openEditDialog(cellData))
        }
        else
            td.innerHTML = "";
    }

    openEditDialog = (id) => {
        let teamCertificate = this.props.teamData.team.teamCertificates.find(tc => tc.userCertificates.id === id)
        this.props.setCertificate(teamCertificate)
        this.props.openAddCertificate();
    }

    onCreateStatusCell = (td, cellData) => {
        if (cellData) {
            let teamCertificate = this.props.teamData.team.teamCertificates.find(tc => tc.userCertificates.id === cellData)
            let icon = teamCertificate.isActive ? 'cross' : 'check'
            let text = teamCertificate.isActive ? 'Disable' : 'Enable'
            td.innerHTML = `<span id="delete_" class="tooltip">
                    <i id="delete_" style={font-weight:500px;} class="icon icon-${icon} color-black  text-lg clickable "></i>
                    <span class="message top-end">${text} certificates</span>
                </span>`;
            td.querySelector("#delete_").addEventListener('click', () => this.openDeleteDialog(teamCertificate))
        }
        else
            td.innerHTML = "";
    }

    openDeleteDialog = (teamCertificate) => {
        this.setState({ openDeleteDialog: teamCertificate })
        let certificate = { ...teamCertificate, isActive: !teamCertificate.isActive }
        this.props.setCertificate(certificate)
    }

    updateCertificateStatus = () => {
        this.props.createEditTeamCertificate()
    }

    componentDidMount() {

    }


    render() {
        return (<EuiRow>

            <EuiTile styleContent={{ display: "flex" }}>
                <div className="flex-start-content w-8 pl-1">
                    <EuiTextField
                        id="teamNoId"
                        className="p-1 w-5"
                        name="teamNo"
                        label="Team No : "
                        fullwidth
                        value={this.props.teamData.team.id}
                        readOnly
                        labelstyle={{ fontWeight: 600, fontSize: 15 }}
                    />
                    <EuiTextField
                        id="teamNameId"
                        className="p-1 w-5"
                        fullwidth
                        name="teamName"
                        label="Team Name : "
                        value={this.props.teamData.team.name}
                        readOnly
                        labelClass="left"
                        labelstyle={{ fontWeight: 600, fontSize: 15 }}
                    />
                    <EuiTextField
                        id="teamCustomerId"
                        className="p-1 w-5"
                        fullwidth
                        name="teamCustomerId"
                        label="Team Customer Id : "
                        value={this.props.teamData.team.customerId}
                        readOnly
                        labelClass="left"
                        labelstyle={{ fontWeight: 600, fontSize: 15 }}
                    />
                    <EuiTextField
                        id="teamCustomerName"
                        className="p-1 w-5"
                        fullwidth
                        name="teamCustomerName"
                        label="Team Customer Name : "
                        value={this.props.teamData.team.customerName}
                        readOnly
                        labelClass="left"
                        labelstyle={{ fontWeight: 600, fontSize: 15 }}
                    />
                </div>
            </EuiTile>

            <EuiTile>
                <ObseleteEuiTable
                    classes="very-tiny"
                    columns={this.state.certificationColumns}
                    data={this.props.teamData.team.teamCertificates.map(e => ({ ...e, ...e.userCertificates }))}
                    hideTableInfo={true}
                    id="certificateTable"
                    scroll={true}
                    sortable={true}
                    styleHeader={{ fontSize: 20 }}
                />
            </EuiTile>

            <DialogModal
                id="deactivateCertificate"
                open={this.state.openDeleteDialog.id}
                title={this.state.openDeleteDialog.isActive ? "Deactivate Certificate" : "Activate Certificate"}
                content={
                    <div>
                        Are you sure you want to {this.state.openDeleteDialog.isActive ? 'Deactivate' : 'Activate'} this certificate "{this.state.openDeleteDialog?.userCertificates?.name}" ?
                    </div>}
                buttonName={this.state.openDeleteDialog.isActive ? "Deactivate" : "Activate"}
                type="primary"
                handleClose={() => { this.setState({ openDeleteDialog: {} }) }}
                onClick={this.updateCertificateStatus}
                mainButtonStyle={{ backgroundColor: 'red' }}
                disableBackdropClick={true}
            />

        </EuiRow>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.user,
    sessionData: state.session,
    teamData: state.team,
});
const mapDispatchToProps = dispatch => {
    return {
        setCertificate: (certificate) => setCertificate(certificate)(dispatch),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamContent);
