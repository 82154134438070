import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import constants from "../../utils/constants";
import PageHelper from "../../utils/page-helper";
import DateUtils from "../../utils/date-utils";
import { withRouter } from "react-router-dom";
import ApiClient from "../../utils/api-client";
import WorkplanHelper from "../../utils/workplan-helper";
import EuiLoading from "../../eui-components/loading";
import AddEditActivityDialog from "./AddEditActivityDialog";
import { Notification } from '@eds/vanilla';
import EuiSwitch from "../../eui-components/switch";
import DialogModal from "../../components/dialog";
import AssignChecklist from "./assignChecklist";
import EuiProgressBar from "../../eui-components/progressbar";
import ChecklistHelper from "../../utils/checklist-helper";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import ApiHelper from "../../utils/api-helper";
import CommonHelper from "../../utils/common-helper";
import SasHelper from "../../utils/sas-helper";

class ActivityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activityColumns: [{
                title: "Activity ID",
                onCreatedCell: (td, cellData) => {
                    let id = cellData.split(' ')[1]
                    td.innerHTML = `<a id="activity_${id}">${cellData}</a>`;
                    ///console.log(cellData)
                    td.querySelector("#activity_" + id).addEventListener('click', () => this.openActivityDetail(id))
                },
                key: "customId",
                sort: "asc",
                width: "10%"
            },
            {
                title: "",
                key: "isTemplate",
                hideFilter: true,
                onCreatedCell: (td, cellData) => {
                    if (cellData) {
                        td.innerHTML = `<span class="tooltip"><i id="assignedicon" class="icon icon-view-list text-lg "></i><span class="message top-end">Activivty assigned to checklist</span></span>`;
                    }
                    else td.innerHTML = ""
                }
            },
            {
                title: "Activity Name",
                key: "name",
                sort: "none",
                width: "23%",
                onCreatedCell: (td, cellData) => {
                    if (cellData.substr(cellData.length - 4) == 'icon') {
                        td.innerHTML = `<span>${cellData.substr(0, cellData.length - 5)}` + ` <span class="tooltip" ><i id="nonApplicableicon" class="icon icon-warning-circle text-lg"></i><span class="message top-end">Activivty is not applicable</span></span ></span>`;
                    }
                }
            },

            {
                title: "Workplan Name",
                key: "workplanName",
                sort: "none",
                headerStyle: this.props.type !== constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                cellStyle: this.props.type !== constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                width: "15%"
            },
            {
                title: "Site Name",
                key: "siteName",
                sort: "none",
                headerStyle: this.props.type !== constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                cellStyle: this.props.type !== constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                width: "10%"
            },
            {
                title: "ASP Company | (Team Leader | EHS Auditor)",
                key: "aspCompanyAndTeamLead",
                sort: "none",
                width: "25%"
            },
            {
                title: "Eritop Planned Dates",
                key: "plannedDates",
                hideFilter: true,
                headerStyle: this.props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                cellStyle: this.props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                sort: "none",
                width: "10%"
            },
            {
                title: "Actual Dates",
                key: "actualDates",
                hideFilter: true,
                headerStyle: this.props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                cellStyle: this.props.type === constants.activityListTypes.ACTIVITY ? "display:none;" : null,
                sort: "none",
                width: "13%"
            },
            {
                title: "Status",
                hideFilter: true,
                key: "acceptanceStatusId",
                sort: "none",
                onCreatedCell: (td, cellData) => {
                    const status = PageHelper.renderActivityStatus(cellData);
                    td.innerHTML = `${status}`;
                },
                width: "10%"
            },
            {
                hideFilter: true,
                title: "",
                onCreatedCell: (td, cellData) => {
                    if (cellData) {
                        td.innerHTML = `<span class="tooltip"><i id="session_${cellData}" class="icon icon-check text-lg clickable"></i><span class="message top-end">Go Customer Session</span></span>`;
                        td.querySelector("#session_" + cellData).addEventListener('click', () => this.openSessionDetail(cellData))
                    }
                },
                key: "sessionId",
                width: "2%"
            },
            {
                hideFilter: true,
                title: "",
                onCreatedCell: (td, cellData) => {
                    var id = cellData?.split("_")[0];
                    var actualEnd = cellData?.split("_")[1];
                    if ((actualEnd == "null" || actualEnd == "") && id) {
                        if (this.props.userData.userData.jobRole === constants.userRoles.ERICSSON_IM || this.props.userData.userData.jobRole === constants.userRoles.ADMIN) {
                            td.innerHTML = `<span class="tooltip"><i id="EditActivityy" class="icon icon-edit text-lg clickable "></i><span class="message top-end">Edit Activity</span></span>`;
                            td.querySelector("#EditActivityy").addEventListener('click', () => this.initiateEditActivity(id))
                        }
                        else {
                            td.innerHTML = ""
                        }
                    }
                    else td.innerHTML = ""

                },
                key: "editId",
                width: "2%"
            },
            {
                hideFilter: true,
                title: "",
                onCreatedCell: (td, cellData) => {
                    if (this.props.userData.userData.jobRole === constants.userRoles.RSC_COODINATOR && cellData.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING) {
                        const selectedActivity = this.props.insideWorkplan ? { ...this.props.workplanData.workplan?.activities.find(a => a.id === cellData.id) }
                            : { ... this.props.activities.find(a => a.id === cellData.id) };
                        td.innerHTML = `<span class="tooltip"><i id="assignActivityToRsc" class="icon icon-group icon-lg clickable "></i><span class="message top-end">Assign to New Site Supervisor</span></span>`;
                        td.querySelector("#assignActivityToRsc").addEventListener('click', () => {
                            this.setState({ openDialogAssignUsers: true, selectedActivity });
                        });
                    } else {
                        td.innerHTML = "";
                    }

                },
                key: "assignActivityId",
                width: "2%"
            },
            {
                hideFilter: true,
                title: "",
                onCreatedCell: (td, cellData) => {
                    var id = cellData?.split("_")[0];
                    var actualEnd = cellData?.split("_")[1];
                    var activity = {};
                    if (this.props.insideWorkplan)
                        activity = { ...this.props.workplanData.workplan?.activities.find(a => a.id === parseInt(id)) }
                    else
                        activity = { ... this.props.activities.find(a => a.id === parseInt(id)) }

                    if ((actualEnd == "null" || actualEnd == "" && id && !activity.sessionId)) {
                        if (this.props.userData.userData.jobRole === constants.userRoles.ERICSSON_IM) {
                            td.innerHTML = `<span class="tooltip"><i id="resetActivityy" class="icon icon-trashcan text-lg clickable "></i><span class="message top-end">Reset Activity</span></span>`;
                            td.querySelector("#resetActivityy").addEventListener('click', () => this.initiateResetActivity(cellData))
                        }
                        else {
                            td.innerHTML = ""
                        }
                    }
                    else td.innerHTML = ""
                },
                key: "resetId",
                width: "2%"


            }
            ],
            isLoading: false,
            Done: false,
            activitiesFiltered: true,
            activityList: this.props.activities,
            teamLeaders: [],
            aspCompanies: [],
            teams: [],
            openDialog: false,
            openDialogReset: false,
            openDialogAdd: false,
            selectedActivities: [],
            openAssignActivityDialog: false,
            percentageArr: [],
            files: [],
            uploadedDocs: [],
            enableSelect: false,
            percentageApi: 0,
            openDialogSaveBar: false,
            openDialogAssignUsers: false,
            userColumns: [
                {
                    title: "ID",
                    key: "value",
                    sort: "none",
                    width: "3%"
                },
                {
                    title: "Name",
                    key: "text",
                    sort: "none",
                    width: "80%"
                }


            ],
            selectedActivity: { id: 0 },
            selectedRscUser: { id: 0 },
            hideAssigned: false
        }
    }

    handleClose = () => {
        this.setState({
            openDialog: false
        })
    }

    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
        PageHelper.getCoordinates();
        this.props.setSelectedActivities([]);
        ApiHelper.setAllUsers((data) => this.props.setAllUsers(data));

        ApiClient.post("Asp/GetAllCompanies", {})
            .then(response => {
                this.props.insideWorkplan ? this.props.setAspCompanies(response.data.filter(item => item.countryId === this.props.workplanData.workplan.countryId)) : this.props.setAspCompanies(response.data)
                this.setState({
                    aspCompanies: this.props.insideWorkplan ? response.data.filter(item => item.countryId === this.props.workplanData.workplan.countryId) : response.data
                });
            })
            .catch(error => {
                alert(error);
            });

        this.props.setInsideWorkplan(this.props.insideWorkplan)
        this.setState({
            isLoading: true
        }, () => {
            this.setState({
                isLoading: false
            });
        });
    }

    getActivityDetails = (activityId) => {
        const selectedActivity = (this.props.insideWorkplan ? this.props.workplanData.workplan.activities.find(item => item.id == activityId) : this.props.activities.find(item => item.id == activityId)) || this.props.activityData.activity;
        ///console.log("selectedActivity getActivityDetails",selectedActivity)

        ApiClient.post("Workplan/GetActivityDetails", { id: activityId })
            .then(response => {
                this.setState({ isLoading: false }, () => {
                    const activity = response.data;
                    const type = this.props.type;
                    WorkplanHelper.mapActivity(activity, selectedActivity, type);
                    this.props.setActivity(activity);

                });
                ///console.log("getActivityDetails today",response.data)  
            })
            .catch(error => {
                console.error(error);;
                this.setState({ isLoading: false });
            });
    }

    openActivityDetail = (activityId) => {

        const selectedActivity = (this.props.insideWorkplan ? this.props.workplanData.workplan.activities.find(item => item.id == activityId) : this.props.activities.find(item => item.id == activityId)) || this.props.activityData.activity;

        if (this.props.insideWorkplan) {
            selectedActivity["customer"] = this.props.workplanData.workplan.customer;
            selectedActivity['customerName'] = this.props.workplanData.workplan.customerName;
            selectedActivity['projectName'] = this.props.workplanData.workplan.projectName;
            selectedActivity['siteName'] = this.props.workplanData.workplan.siteName;
            selectedActivity['workplanName'] = this.props.workplanData.workplan.name;

        }
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/GetActivityDetails", { id: activityId })
                .then(response => {
                    this.setState({ isLoading: false }, () => {
                        const activity = response.data;
                        const type = this.props.type;
                        WorkplanHelper.mapActivity(activity, selectedActivity, type);
                        this.props.setActivity(activity);
                        const teamVerificationLogs = ActivityInboxHelper.mapTeamLogs(response.data.verificationLogs?.teamVerificationLogs);
                        const resourceVerificationLogs = ActivityInboxHelper.mapResourceLogs(response.data.verificationLogs?.resourceVerificationLogs);
                        const teamInfo = response.data.verificationLogs?.teamInfo || {};
                        teamInfo.status = teamVerificationLogs.length > 0 ? !teamVerificationLogs.some(item => !item.validStatus) : null;
                        teamInfo.verificationSessionId = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].verificationSessionId : "";
                        teamInfo.isOffsiteVerification = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].isOffsiteVerification : null;
                        teamInfo.resourceStatus = resourceVerificationLogs.length > 0 ? !resourceVerificationLogs.some(item => !item.status) : null;
                        const teamsLogInfo = { teamInfo, teamVerificationLogs, resourceVerificationLogs };
                        this.props.setTeamsLogInfo(teamsLogInfo);
                        this.props.changeActivityMod(constants.pageMods.VIEW_ONLY);
                        this.props.history.push("/activities");
                    });
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    openSessionDetail = (sessionId) => {
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/GetSessionDetails", { id: sessionId })
                .then(response => {
                    this.setState({ isLoading: false }, () => {
                        const session = response.data;
                        const sessionRequest = { id: sessionId, name: session.name, viewOnly: true, status: 2, customerUsers: session.customerUsers, responsibleUsers: session.responsibleUsers };
                        this.props.setApproveSession(sessionRequest);
                        this.props.history.push("/approve-session");
                    });
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    initiateEditActivity = (cellData) => {
        this.props.setActivity({});
        var activity = {};
        if (this.props.insideWorkplan)
            activity = { ...this.props.workplanData.workplan?.activities.find(a => a.id === parseInt(cellData)) }
        else
            activity = { ... this.props.activities.find(a => a.id === parseInt(cellData)) }
        if (activity.erisiteStatus?.toLowerCase() == "deleted") {
            const notification = new Notification({
                title: 'Activity is Deleted in Erisite, thus it cannot be edited',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        this.setState({
            isLoading: true
        })
        activity.plannedStartTime = activity.plannedStart?.split('T', 4)[1] || "00:00";
        activity.plannedEndTime = activity.plannedEnd?.split('T', 4)[1] || "00:00";
        this.props.setActivity(activity);
        if (this.props.activityData.activity.aspCompanyId) {
            const endPoint = this.props.activityData.activity?.isEHSAuditorActivity ? "Workplan/GetEligibleEHSAuditorByWorkplanId" : "Workplan/GetTeamLeadersByAspCompany"
            const payload = { Id: this.props.activityData.activity.aspCompanyId , aspCompanyId : this.props.activityData.activity.aspCompanyId  ,
                             workPlanId : this.props.activityData?.activity?.workplanId
                             }
            ApiClient.post(endPoint, payload)
                .then((response1) => {
                    this.setState({
                        teamLeaders: response1.data,
                        aspCompanies: (this.props.insideWorkplan ? [...this.props.activityData.aspCompanies.filter(item => item.countryId == this.props.workplanData.workplan.countryId)] : [...this.props.activityData.aspCompanies.filter(item => item.countryId == this.props.activityData.activity.countryId)]),
                        openDialog: true,
                        isLoading: false
                    });
                })
                .catch(error => {
                    alert(error);
                    this.setState({
                        teamLeaders: [],
                        aspCompanies: (this.props.insideWorkplan ? [...this.props.activityData.aspCompanies.filter(item => item.countryId == this.props.workplanData.workplan.countryId)] : [...this.props.activityData.aspCompanies.filter(item => item.countryId == this.props.activityData.activity.countryId)]),
                        openDialog: true,
                        isLoading: false
                    });
                });
        }
        else {
            this.setState({
                teamLeaders: [],
                openDialog: true,
                aspCompanies: (this.props.insideWorkplan ? [...this.props.activityData.aspCompanies.filter(item => item.countryId == this.props.workplanData.workplan.countryId)] : [...this.props.activityData.aspCompanies.filter(item => item.countryId == this.props.activityData.activity.countryId)]),
                isLoading: false
            });
        }
    }

    initiateResetActivity = (cellData) => {
        this.props.setActivity({});
        var activity = {};
        if (this.props.insideWorkplan)
            activity = { ...this.props.workplanData.workplan?.activities.find(a => a.id === parseInt(cellData)) }
        else
            activity = { ... this.props.activities.find(a => a.id === parseInt(cellData)) }
        if (activity.erisiteStatus?.toLowerCase() == "deleted") {
            const notification = new Notification({
                title: 'Activity is Deleted in Erisite, thus it cannot be edited',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }

        this.props.setActivity(activity);
        this.setState({ openDialogReset: true })
    }

    addActivity = () => {
        this.props.setActivity({ id: 0, subactivities: [] });
        this.setState({
            openDialogAdd: true
        })
    }

    changeInitialState = () => {
        this.setState({ initialState: 1 });
    }

    getActivities = (id) => {
        const filter = { ...this.props.activityData.filter };
        /*if (!filter || Object.values(filter).every(x => (!x || x === '')))
            filter.id = id
            */
        filter.pageSize = 30;
        filter.page = 1;
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/GetFilteredActivities", filter)
                .then(response => {
                    const data = [...response.data.data];
                    const initialState = new Date();
                    WorkplanHelper.mapActivities(data);
                    this.setState({ isLoading: false, Done: true }, () => {
                        this.props.setFilteredActivities({ data, totalCount: response.data.totalCount, initialState: initialState });
                        this.changeInitialState();
                    });
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    changeFilterActivity = (e) => {
        this.setState({
            activitiesFiltered: e.target.checked,
            isLoading: true
        }, () => {
            this.setState({
                isLoading: false
            });
        })
    }
    hideAssignedActivity = (e) => {
        this.setState({
            hideAssigned: e.target.checked,
            isLoading: true
        }, () => {
            this.setState({
                isLoading: false
            });
        })
    }

    createUpdateActivity = () => {
        const activity = { ...this.props.activityData.activity }
        const oldActivity = (activity.id > 0 ? (this.props.insideWorkplan ? this.props.workplanData.workplan.activities.find(item => item.id === activity.id) : this.props.activities.find(item => item.id == activity.id)) : activity);
        if (!activity['name'] || !activity['aspCompanyId'] || !activity['rscScope'] || !activity['gpoName']) {
            const notification = new Notification({
                title: 'Save Activity',
                description: 'All mandatory data need to be filled to save..',
                timeout: 5000,
                icon: 'icon-check'
            });
            notification.init();
        }
        else {
            activity['workplanId'] = parseInt(this.props.activityData.activity.workplanId) || parseInt(this.props.workplanData.workplan.id);
            var today = Date.now();
            if (activity.plannedStart)
                activity['plannedStart'] = (activity.plannedStartTime ? DateUtils.toDateString((activity.plannedStart.split('T')[0] + "T" + activity.plannedStartTime), "YYYY-MM-DDTHH:mm", true) : DateUtils.toDateString((activity.plannedStart.split('T')[0]), "YYYY-MM-DDTHH:mm", true));
            if (!activity.plannedStart)
                activity['plannedStart'] = DateUtils.toDateString(today, "YYYY-MM-DDTHH:mm", true);
            if (activity.plannedEnd)
                activity['plannedEnd'] = (activity.plannedEndTime ? DateUtils.toDateString((activity.plannedEnd.split('T')[0] + "T" + activity.plannedEndTime), "YYYY-MM-DDTHH:mm", true) : DateUtils.toDateString((activity.plannedEnd.split('T')[0]), "YYYY-MM-DDTHH:mm", true));
            if (!activity.plannedEnd)
                activity['plannedEnd'] = DateUtils.toDateString(today, "YYYY-MM-DDTHH:mm", true);
            if (DateUtils.dateDifference(new Date(activity.plannedEnd), new Date(activity.plannedStart)) < 0) {
                const notification = new Notification({
                    title: 'Activity Data',
                    description: 'Planned end can not be before planned start',
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
            }
            else {
                let visitor = CommonHelper.getVisitorData();
                activity.logInfo = {
                    userId: parseInt(this.props.userData.userData.userId),
                    logBrowser: visitor.vBrowser,
                    logIP: visitor.vIP,
                    logLocation: visitor.vCountry,
                    latitude: visitor.latitude,
                    longitude: visitor.longitude,
                    logDetail: Object.entries(oldActivity).reduce((a, b) => {
                        return a + b.join(" : ") + "    |    ";
                    }, ""),
                };
                this.setState({
                    isLoading: true,
                    openDialog: false,
                    openDialogAdd: false,
                }, () => {
                    ApiClient.post("WorkPlan/CreateUpdateActivity", activity)
                        .then(response1 => {
                            if (response1.data.success) {
                               // activity.id = response1.data;
                               activity.id = parseInt(response1.data.data);
                                const notification = new Notification({
                                    title: 'Save Activity',
                                    description: 'Activity is being saved.',
                                    timeout: 5000,
                                    icon: 'icon-check'
                                });
                                notification.init();
                                if (this.props.insideWorkplan) {
                                    const selectedWorkplan = this.props.workplanData.filteredWorkplans.data.find(item => item.id === parseInt(this.props.workplanData.workplan.id));
                                    var id = parseInt(this.props.workplanData.workplan.id);
                                    ApiClient.post("Workplan/GetWorkplanDetails", { id: id })
                                        .then(response => {
                                            const workplan = { ...response.data };
                                            workplan["customer"] = { ...selectedWorkplan?.customer };
                                            workplan["activities"] = response.data.activities;
                                            WorkplanHelper.mapWorkplan(workplan);
                                            this.props.setWorkplan(workplan);
                                            this.setState({
                                                isLoading: false
                                            });

                                        })
                                        .catch(error => {
                                            console.error(error);;
                                            this.setState({ isLoading: false });
                                        });
                                }
                                else {
                                    const filter = { ...this.props.activityData.filter };
                                    delete filter["page"];
                                    delete filter["pageSize"];

                                    if (!filter || Object.values(filter).every(x => (!x || x === ''))) {
                                        filter['id'] = activity.id;
                                    }
                                    filter.pageSize = 30;
                                    filter.page = 1;
                                    this.setState({ isLoading: true }, () => {
                                        ApiClient.post("Workplan/GetFilteredActivities", filter)
                                            .then(response => {
                                                const data = [...response.data.data];
                                                const initialState = new Date();
                                                WorkplanHelper.mapActivities(data);

                                                this.props.setFilteredActivities({ data, totalCount: response.data.totalCount, initialState: initialState });
                                                this.changeInitialState();
                                                this.setState({ isLoading: false });

                                            })
                                            .catch(error => {
                                                console.error(error);;
                                                this.setState({ isLoading: false });
                                            });
                                    });
                                    // this.setState({ isLoading: true }, () => this.getActivities(response.data));
                                }
                                const notification2 = new Notification({
                                    title: 'Activity Save',
                                    description: 'Activity is successfully Saved.',
                                    timeout: 5000,
                                    icon: 'icon-check'
                                });
                                notification2.init();
                            }
                            else {
                                this.setState({ isLoading: false });
                                const notification = new Notification({
                                    title: 'Save Activity',
                                    description: response1.data.errorMessage ? response1.data.errorMessage :  'Error occured please try again. ',
                                    timeout: 150000,
                                    icon: 'icon-triangle-warning'
                                });
                                notification.init();
                            }
                        })
                        .catch(error => {
                            this.setState({
                                isLoading: false
                            })
                            alert(error);
                        });
                });

            }
        }
    }

    resetActivity = () => {
        const activity = { ...this.props.activityData.activity }
        const oldActivity = (activity.id > 0 ? (this.props.insideWorkplan ? this.props.workplanData.workplan.activities.find(item => item.id === activity.id) : this.props.activities.find(item => item.id == activity.id)) : activity);
        this.setState({
            openDialogReset: false,
            isLoading: true
        });
        let visitor = CommonHelper.getVisitorData();
        activity.logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: Object.entries(oldActivity).reduce((a, b) => {
                return a + b.join(" : ") + "    |    ";
            }, ""),
        };
        ApiClient.post("Workplan/ResetActivity", { id: activity.id, logInfo: activity.logInfo })
            .then(response => {
                if (response.data) {
                    activity.id = response.data;
                    const notification = new Notification({
                        title: 'Activity Reset',
                        description: 'Activity is being reset.',
                        timeout: 5000,
                        icon: 'icon-check'
                    });
                    notification.init();
                    if (this.props.insideWorkplan) {
                        const selectedWorkplan = this.props.workplanData.filteredWorkplans.data.find(item => item.id === parseInt(this.props.workplanData.workplan.id));
                        var id = parseInt(this.props.workplanData.workplan.id);
                        ApiClient.post("Workplan/GetWorkplanDetails", { id: id })
                            .then(response => {
                                const workplan = { ...response.data };
                                workplan["customer"] = { ...selectedWorkplan?.customer };
                                workplan["activities"] = response.data.activities;
                                WorkplanHelper.mapWorkplan(workplan);
                                this.props.setWorkplan(workplan);
                                this.setState({
                                    isLoading: false
                                });

                            })
                            .catch(error => {
                                console.error(error);;
                                this.setState({ isLoading: false });
                            });
                    }
                    else {
                        const filter = { ...this.props.activityData.filter };
                        delete filter["page"];
                        delete filter["pageSize"];

                        if (!filter || Object.values(filter).every(x => (!x || x === ''))) {
                            filter['id'] = activity.id;
                        }
                        filter.pageSize = 30;
                        filter.page = 1;
                        this.setState({ isLoading: true }, () => {
                            ApiClient.post("Workplan/GetFilteredActivities", filter)
                                .then(response => {
                                    const data = [...response.data.data];
                                    const initialState = new Date();
                                    WorkplanHelper.mapActivities(data);

                                    this.props.setFilteredActivities({ data, totalCount: response.data.totalCount, initialState: initialState });
                                    this.changeInitialState();
                                    this.setState({ isLoading: false });

                                })
                                .catch(error => {
                                    console.error(error);;
                                    this.setState({ isLoading: false });
                                });
                        });
                    }
                }
                else {
                    this.setState({ isLoading: false });
                    const notification = new Notification({
                        title: 'Reset Activity',
                        description: 'Error occured please try again.',
                        timeout: 5000,
                        icon: 'icon-triangle-warning'
                    });
                    notification.init();
                }
                const notification2 = new Notification({
                    title: 'Activity Reset',
                    description: 'Activity is successfully reset.',
                    timeout: 5000,
                    icon: 'icon-check'
                });
                notification2.init();
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                })
                alert(error);
            })
    }

    setSelectedActivities = (e, type) => {
        const detail = e.detail;
        const data = detail.data;

        if (type === "add") {
            this.setState({ selectedActivities: [...this.state.selectedActivities, data] }, () => {
                this.props.setSelectedActivities(this.state.selectedActivities);
            });
        } else if (type === "remove") {
            this.setState({ selectedActivities: this.state.selectedActivities.filter(item => item.id !== data.id) }, () => {
                this.props.setSelectedActivities(this.state.selectedActivities);
            });
        }
    }

    openAssignActivityDialog = () => {
        if (!this.state.enableSelect) {
            const notification = new Notification({
                title: 'Checklist Warning',
                description: 'Please enable checklist assignment',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        if (!this.props.activityData.selectedActivities?.length) {
            const notification = new Notification({
                title: 'Checklist Warning',
                description: 'Please select at least one activity to assign',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        if (!this.props.activityData.selectedActivities.every(item => item.projectId == this.props.activityData.selectedActivities[0].projectId)) {
            const sameProjectnotification = new Notification({
                title: 'Assign Checklist',
                description: 'Only activities with same project can be assigned',
                timeout: 5000,
                icon: 'icon-cross'
            });
            sameProjectnotification.init();
            return;
        }

        this.setState({
            openAssignActivityDialog: true
        })
    }

    onUploadProgress = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percentageApi = Math.floor((loaded * 100) / total);
        this.setState({ percentageApi, openDialogSaveBar: true });
    }

    assignChecklist = () => {
        const logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude,
        }
        if (!this.props.activityTemplateData.selectedTemplates.length || !this.state.enableSelect) {
            const notification = new Notification({
                title: 'Assign Checklist Failed',
                description: 'Select at least one template to assign.',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        this.setState({
            isLoading: true
        })
        const activities = this.props.activityData.selectedActivities;
        if (!this.props.activityTemplateData.selectedTemplates || !this.props.activityTemplateData.selectedTemplates.length) {
            const notification = new Notification({
                title: 'Assign Checklist Failes',
                description: 'No templates selected! please slect a template to assign.',
                timeout: 5000,
                icon: 'icon-check'
            });
            notification.init();
            return;
        }
        const items = this.props.activityTemplateData.selectedTemplates?.map(item => item.items);
        var documents = [];
        if (items && items.length) {
            items.forEach(d => {
                if (d && d.length)
                    d.forEach(e => { documents.push({ documentPath: SasHelper.addSasKey(e.templatePath, this.props.sasToken), name: e.name, templateId: e.subactivityTemplateId }) })
            });
        }
        var itemsWithFiles = documents.filter(item => item.documentPath);
        if (itemsWithFiles.length) {
            this.setState({
                openDialogUploadBar: true,
                percentageArr: PageHelper.initializePercentageArr(itemsWithFiles),
            }, () => {
                const onProgress = (progressEvent, file) => {
                    let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                    let percentageArr = [...this.state.percentageArr];
                    const index = percentageArr.indexOf(file);
                    percentageArr[index].percentage = percentage;
                    this.setState({ percentageArr });
                }

                PageHelper.downloadBlobAzure(itemsWithFiles, onProgress)
                    .then(response => {
                        if (response) {
                            const files = response;
                            const mappedFiles = ChecklistHelper.mapItemsForUploading(files);

                            this.setState({
                                openDialogUploadBar: true,
                                percentageArr: PageHelper.initializePercentageArr(mappedFiles),
                            }, () => {
                                const onProgress2 = (progressEvent, file) => {
                                    let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                                    let percentageArr = [...this.state.percentageArr];
                                    const index = percentageArr.indexOf(file);
                                    percentageArr[index].percentage = percentage;
                                    this.setState({ percentageArr });
                                }
                                PageHelper.uploadItems(mappedFiles, onProgress2, "activity-docs").then(uploadedFiles => {

                                    this.setState({
                                        openDialogUploadBar: false,
                                        uploadedDocs: uploadedFiles,
                                        isLoading: true,
                                        openAssignActivityDialog: false,

                                    }, () => {
                                        ChecklistHelper.mapSubactivityItemsToActivityDocs(items, this.state.uploadedDocs);

                                        ApiClient.post("ActivityTemplate/AssignChecklistItems", { Activities: activities, TemplateItems: items, LogInfo: logInfo }, {}, 700000, this.onUploadProgress)
                                            .then(response => {
                                                if (response.data) {
                                                    this.setState({ selectedActivities: [] })
                                                    this.props.setSelectedTemplates([]);
                                                    this.props.setSelectedActivities([]);
                                                    const notification = new Notification({
                                                        title: 'Assign Checklist',
                                                        description: 'Checklist assigned successfully!',
                                                        timeout: 5000,
                                                        icon: 'icon-check'
                                                    });
                                                    notification.init();
                                                }
                                                this.setState({ isLoading: false, openDialogSaveBar: false });

                                            })
                                            .catch(error => {
                                                alert(error);
                                                this.setState({ isLoading: false, openDialogSaveBar: false });

                                            })
                                    });
                                }).catch(error => {
                                    alert(error);
                                    console.error(error);;
                                });

                            }
                            )
                        }

                    });
            });
        }
        else {
            this.setState({
                openDialogUploadBar: false,
                isLoading: true,
                openAssignActivityDialog: false,

            }, () => {

                ApiClient.post("ActivityTemplate/AssignChecklistItems", { Activities: activities, TemplateItems: items, LogInfo: logInfo }, {}, 700000, this.onUploadProgress)
                    .then(response => {
                        if (response.data) {
                            this.setState({ selectedActivities: [] })
                            this.props.setSelectedTemplates([]);
                            this.props.setSelectedActivities([]);
                            const notification = new Notification({
                                title: 'Assign Checklist',
                                description: 'Checklist assigned successfully!',
                                timeout: 5000,
                                icon: 'icon-check'
                            });
                            notification.init();
                        }
                        this.setState({ isLoading: false, openDialogSaveBar: false });
                    })
                    .catch(error => {
                        alert(error);
                        this.setState({ isLoading: false, openDialogSaveBar: false });

                    })
            });

        }
    }

    onCreatedHead = (th, headData) => {
    }

    toggleSelection = (e) => {
        this.props.setSelectedActivities([]);
        this.setState({
            enableSelect: e.target.checked,
            isLoading: true
        }, () => {
            this.setState({
                isLoading: false
            });
        })
    }

    assignActivityToRsc = (e) => {
        if (this.state.selectedRscUser.id === 0) {
            const notification = new Notification({
                title: 'Please, select one site supervisor.',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }

        const userData = this.props.userData;
        this.setState({ openDialogAssignUsers: false, isLoading: true }, () => {
            const logInfo = {
                userId: parseInt(this.props.userData.userData.userId),
                latitude: PageHelper.coord.latitude,
                longitude: PageHelper.coord.longitude,
            }
            ApiClient.post("Activity/ChangeActivityAssignment", {
                id: this.state.selectedActivity.id,
                updateUser: this.state.selectedRscUser.value + "",
                logInfo,
                rscCenterId: userData.rscPerson?.rscCenterId,
                userRole: this.state.selectedRscUser.role,
            }).then(response => {
                if (response.data) {
                    const notification = new Notification({
                        title: 'Activity has been assigned to new site supervisor',
                        timeout: 5000,
                        icon: 'icon-cross'
                    });
                    notification.init();
                } else {
                    const notification = new Notification({
                        title: 'Site Supervisor not found, activity assignment cleared, any site supervisor can assign now',
                        timeout: 10000,
                        icon: 'icon-cross'
                    });
                    notification.init();
                }
                this.setState({ isLoading: false, selectedRscUser: { id: 0 } });

                //const filter = { ...this.props.activityData.filter };
                //ApiClient.post("Workplan/GetFilteredActivities", filter)
                //    .then(response1 => {
                //        const data = [...response1.data.data];
                //        const initialState = new Date();
                //        WorkplanHelper.mapActivities(data);

                //        this.props.setFilteredActivities({ data, totalCount: response1.data.totalCount, initialState: initialState });
                //        this.changeInitialState();
                //        if (response.data) {
                //            const notification = new Notification({
                //                title: 'Activity has been assigned to new site supervisor',
                //                timeout: 5000,
                //                icon: 'icon-cross'
                //            });
                //            notification.init();
                //        } else {
                //            const notification = new Notification({
                //                title: 'Site Supervisor not found, activity assignment cleared, any site supervisor can assign now',
                //                timeout: 10000,
                //                icon: 'icon-cross'
                //            });
                //            notification.init();
                //        }
                //        this.setState({ isLoading: false, selectedRscUser: { id: 0 } });
                //    })
                //    .catch(error => {
                //        console.error(error);;
                //        this.setState({ isLoading: false });
                //    });
            }).catch(error => {
                console.error(error);;
                this.setState({ isLoading: false });
            });
        });
    }

    render() {
        const userData = this.props.userData.userData;
        var activities = [];
        (this.props.insideWorkplan ?
            activities = this.state.activitiesFiltered ? [...this.props.workplanData.workplan?.activities?.filter(item => !(item.erisiteStatus?.toLowerCase() == 'not applicable' && item.systemrecordid))] : [...this.props.workplanData.workplan?.activities]
            :
            activities = this.state.activitiesFiltered ? [...this.props.activities?.filter(item => !(item.erisiteStatus?.toLowerCase() == 'not applicable' && item.systemrecordid))] : [...this.props.activities]
        );
        activities = this.state.hideAssigned ? activities.filter(item => !item.isTemplate) : activities;

        activities.map(e => {
            (e.erisiteStatus?.toLowerCase() === 'not applicable' && e.systemrecordid && e.name.substr(e.name.length - 4) !== 'icon' ? e.name = e.name + "_icon" : e.name = e.name);
        });

        if (this.state.enableSelect)
            activities = activities.filter(item => !constants.notAssignableSessionType.includes(item.rscScope) && !item.actualEnd && item.erisiteStatus?.toLowerCase() !== "deleted")
        if (!activities) {
            return <div className="flex-middle-content m-3 w-10 text-lg" > Please click on filter to get a list of activities</div >
        }
        else {
            const excelData = activities?.map(a => ({
                ActivityId: a.id, ActivityName: a.name,
                WorkplanName: a.workplanName,
                SiteName: a.siteName, AspCompany: a.aspCompanyName,
                TeamLeader: a.teamLeadName,
                Status: PageHelper.mapRscStatus(a.acceptanceStatusId),
                IsExmpted: a.isExempted ? "Yes" : "No"

            }));
            return (<EuiRow
                disabled={this.state.isLoading ? "disabled" : ""} key={this.state.isLoading || this.props.activities} >
                <EuiLoading id="activityToDetailLoading" isLoading={this.state.isLoading} />
                <ObseleteEuiTable
                    classes="tiny split-striped"
                    columns={this.state.activityColumns}
                    data={activities}
                    exportExcel={true}
                    excelFileName={"Activity_List" + new Date().toISOString()}
                    Reportdata={excelData}
                    id="filteredActivitiesTable"
                    viewActionButton={true}
                    onCreatedHead={(th, headData) => this.onCreatedHead(th, headData)}
                    actionButton={
                        <span>
                            <button hidden={(document.querySelector("#addActivity") || (userData.jobRole != constants.userRoles.ERICSSON_IM && userData.jobRole != constants.userRoles.ADMIN) ? true : false)}
                                style={{ width: 123 }}
                                id="addActivityy"
                                className="btn primary m-1"
                                onClick={this.addActivity}>{<span > <i class="icon icon-plus"></i> Add Activity</span>}</button>
                            <button hidden={userData.jobRole != constants.userRoles.ERICSSON_IM} id="assignActivityy"
                                style={{ width: 123, marginLeft: 5 }}
                                className="btn primary m-1"
                                onClick={this.openAssignActivityDialog}> Assign Checklist</button>
                        </span>
                    }
                    filter={true}
                    hideSearch={true}
                    scroll={false}
                    sortable={true}
                    paginated={true}
                    totalCount={(this.props.insideWorkplan ? this.props.workplanData.workplan?.activities.length : this.props.activities.length)}
                    rowsPerPage={10}
                    selectable={this.state.enableSelect ? 'multi' : ''}
                    selectRow={(e, type) => this.setSelectedActivities(e, type)}
                    viewActionIcon={true}
                    actionIcon={
                        <EuiRow className="ml-1 w-10">
                            <span>
                                <EuiSwitch
                                    id="filterActivityCheckbox"
                                    onChange={(e) => this.changeFilterActivity(e)}
                                    checked={this.state.activitiesFiltered}
                                    dataEnabled="Hide Erisite Not Applicable"
                                    dataDisabled="All Activities"
                                    name="activitiesFiltered"
                                />
                            </span>
                            <span className="ml-1">
                                <EuiSwitch
                                    id="filterActivityCheckbox"
                                    onChange={(e) => this.hideAssignedActivity(e)}
                                    checked={this.state.hideAssigned}
                                    dataEnabled="Hide Assigned Activities"
                                    dataDisabled="Hide Assigned Activities"
                                    name="activitiesFiltered"
                                />
                            </span>
                            <span className="ml-1" hidden={userData.jobRole != constants.userRoles.ERICSSON_IM}>
                                <EuiSwitch
                                    id="toggleSelectionCheckbox"
                                    onChange={(e) => this.toggleSelection(e)}
                                    checked={this.state.enableSelect}
                                    dataEnabled="Enabled checklist assignment"
                                    dataDisabled="Checklist assignment is disabled"
                                    name="selectSwitch"
                                />
                            </span>
                            {(this.state.enableSelect ?
                                <span className="ml-1">
                                    <label style={{ color: "gray", fontSize: "12px", marginTop: "2px" }}>(Selection exclude Remote OHS Inspection, Remote Customer Acceptance, completed and Erisite deleted sessions) </label>
                                </span>
                                : null)}
                        </EuiRow>
                    }
                />

                <DialogModal
                    id="workplanAddActivityDialog"
                    dataTrigger="#addActivityy"
                    disableBackdropClick={true}
                    title="Add Activity"
                    content={<AddEditActivityDialog
                        insideWorkplan={true}
                        teamLeaders={[]}
                        aspCompanies={this.state.aspCompanies}
                        teams={this.state.teams}
                        id="AddActivityD" />}
                    buttonName="Add"
                    type="primary"
                    onClick={this.createUpdateActivity}
                    customStyle={{ width: 450, height: 700 }}
                    open={this.state.openDialogAdd}
                    handleClose={() => this.setState({ openDialogAdd: false })}
                    contentStyle={{ height: "80%" }}
                ></DialogModal>

                <DialogModal
                    buttonName="Save"
                    customStyle={{ width: 450, height: 750 }}
                    disableBackdropClick={true}
                    content={<AddEditActivityDialog
                        teamLeaders={this.state.teamLeaders}
                        aspCompanies={this.state.aspCompanies}
                        teams={this.state.teams}
                        edit={true}
                        id="EditactivityDialog"
                    />}
                    handleClose={() => this.setState({ openDialog: false })}
                    id="workplanActivityEdit"
                    onClick={this.createUpdateActivity}
                    open={this.state.openDialog}
                    title="Edit Activity"
                    type="primary"
                    // display: "none"
                    contentStyle={{ height: "80%" }}
                >
                </DialogModal>

                <DialogModal
                    buttonName="Assign"
                    content={<AssignChecklist />}
                    handleClose={() => this.setState({ openAssignActivityDialog: false })}
                    id="assignTemplateDialog"
                    onClick={this.assignChecklist}
                    open={this.state.openAssignActivityDialog}
                    title="Assign Template"
                    type="primary"
                    contentStyle={{ height: "80%" }}
                    customStyle={{ width: "700px" }}
                >
                </DialogModal>

                <DialogModal
                    buttonName="Reset"
                    content={<>
                        <div>Documents, checklist items, ratings and all assignments will be deleted.</div>
                        <div>Are you sure you want to reset this activity? </div>
                    </>
                    }
                    handleClose={() => this.setState({ openDialogReset: false })}
                    id="downloadSessionFilter"
                    onClick={this.resetActivity}
                    open={this.state.openDialogReset}
                    title="Reset Warning"
                    type="primary"
                    mainButtonStyle={{ backgroundColor: 'red' }}
                >
                </DialogModal>

                <DialogModal
                    content={this.state.percentageArr.map(file => {
                        return <div key={file.name} style={{ width: 500 }}>
                            <label><strong>{file.name}</strong></label>
                            <EuiProgressBar value={file.percentage} />
                        </div>
                    })}
                    handleClose={() => this.setState({ openDialogUploadBar: false })}
                    id="uploadBarActivityDocs"
                    titleId="titleuploadBarActivityDocsDialog"
                    open={this.state.openDialogUploadBar}
                    buttonsVisible="false"
                    title="Progress Loading..."
                    closeButtonVisible="false"
                >
                </DialogModal>

                <DialogModal
                    content={<div key="apiRequest" style={{ width: 500 }}>
                        <label><strong>Assigning Templates</strong></label>
                        <EuiProgressBar value={this.state.percentageApi} />
                    </div>
                    }
                    handleClose={() => this.setState({ openDialogSaveBar: false })}
                    id="uploadBarActivityDocs"
                    titleId="titleuploadBarActivityDocsDialog"
                    open={this.state.openDialogSaveBar}
                    buttonsVisible="false"
                    title="Progress Loading..."
                    closeButtonVisible="false"
                >
                </DialogModal>

                <DialogModal
                    buttonName="Assign"
                    content={<EuiRow style={{ width: 400 }}>
                        <ObseleteEuiTable
                            hideTableInfo={true}
                            classes="tiny split-striped"
                            columns={this.state.userColumns}
                            data={PageHelper.getSiteSupervisors(this.props.userData.users)}
                            id="assignActivityRscTable"
                            selectable='single'
                            rowsPerPage={10}
                            search={true}
                            viewActionButton={true}
                            height="464px"
                        />
                    </EuiRow>
                    }
                    handleClose={() => this.setState({ openDialogAssignUsers: false, selectedRscUser: { id: 0 } })}
                    id="assignActivityToRscDialog"
                    onClick={this.assignActivityToRsc}
                    open={this.state.openDialogAssignUsers}
                    title="Assign Activity to Site Supervisor"
                    type="primary"
                >
                </DialogModal>

            </EuiRow >
            );
        }
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, activityData: state.activity, workplanData: state.workplan, activityTemplateData: state.activityChecklists,sasToken: state.complaints.sasToken  });
const mapDispatchToProps = dispatch => {
    return {
        setActivity: (activity) => dispatch({ type: "SET_ACTIVITY", payload: activity }),
        changeActivityMod: (mod) => dispatch({ type: "CHANGE_ACTIVITY_MOD", payload: mod }),
        setApproveSession: (request) => dispatch({ type: "SET_APPROVE_SESSION", payload: request }),
        setFilteredActivities: (filteredActivities) => dispatch({ type: "SET_FILTERED_ACTIVITIES", payload: filteredActivities }),
        setWorkplan: (workplan) => dispatch({ type: "SET_WORKPLAN", payload: workplan }),
        changeMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setFilteredWorkplans: (filteredWorkplans) => dispatch({ type: "SET_FILTERED_WORKPLANS", payload: filteredWorkplans }),
        setUpdateElement: (element) => dispatch({ type: "SET_UPDATE_ELEMENT", payload: element }),
        setAspCompanies: (companies) => dispatch({ type: "SET_ASP_COMPANIES", payload: companies }),
        setTeams: (teams) => dispatch({ type: "SET_TEAMS", payload: teams }),
        setInsideWorkplan: (status) => dispatch({ type: "SET_INSIDE_WORKPLAN", payload: status }),
        setStatusLoading: (status) => dispatch({ type: "SET_STATE_LOADING", payload: status }),
        setSelectedActivities: (selectedActivities) => dispatch({ type: "SET_SELECTED_ACTIVITIES", payload: selectedActivities }),
        setTeamsLogInfo: (teamsLogInfo) => dispatch({ type: "SET_TEAMS_LOG_INFO", payload: teamsLogInfo }),
        setAllUsers: (users) => dispatch({ type: "SET_ALL_USERS", payload: users }),
        setSelectedTemplates: (temps) => dispatch({ type: "SET_SELECTED_TEMPLATES", payload: temps }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })


    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ActivityList));
