import React from "react";

class EuiTimepicker extends React.Component {
   
    render() {
        return (            
            <input                
                id={this.props.id}
                name={this.props.name}
                type="time"
                readOnly={this.props.readonly}
                style={ this.props.style }               
                step={this.props.step}
                min={this.props.min }
                max={this.props.max }
                value={this.props.value ?? ""}
                onChange={(e) => this.props.onChange && this.props.onChange(e)}           
            >                
             </input>
           
        );
    }
}

export default EuiTimepicker;
