import React from "react";

export default class EuiRowWithToolbar extends React.Component {

    render() {
        return <div className={"column sm-12 md-12 lg-12 xl-12 mb-2 " + (this.props.className ?? "")} xstyle={{ ...this.props.style }}>
            <div className="my-toolbar" style={this.props.toolBarStyle}>
                <div className={"flex-start-content " + (this.props.widthright ?? "w-5")}>
                    {this.props.rightIcons}
                </div>
                <div className={"flex-end-content " + (this.props.widthleft ?? "w-5")}>
                    {this.props.leftIcons}
                </div>
            </div>
            <div className="scrollable-container-with-toolbar" style={this.props.contentStyle}>
                {this.props.children}
            </div>
        </div>
    }
}
