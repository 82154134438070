import React from "react";
import EuiRow from "../../eui-components/row";
import EuiTile from "../../eui-components/tile";
import EuiSwitch from "../../eui-components/switch";
import EuiCheckbox from "../../eui-components/checkbox";
import CommonHelper from "../../utils/common-helper";

class DownloadFilterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        return (<EuiRow>
            <EuiTile styleContent={{ display: "flex" }} tileStyle={{ padding: 5, border: "1px solid #bbbb" }} >
                <EuiSwitch id="emptyChecklistSwitchDownload"
                    name="emptyChecklist"
                    checked={this.props.downloadFilter.emptyChecklist}
                    onChange={(e) => this.props.handleDownloadFilter(e)}
                    dataEnabled="Excluding Empty Checklists"
                    dataDisabled="Including Empty Checklists"
                    className="p-1 w-4">
                </EuiSwitch>
                <EuiSwitch id="excludeRecordingsSwitchDownload"
                    name="recordings"
                    checked={this.props.downloadFilter.recordings}
                    onChange={(e) => this.props.handleDownloadFilter(e)}
                    dataEnabled={"Including Live Streaming Recordings " + "( " + CommonHelper.bytesToSize(this.props.allDocumentTypeSizes.recordings) + " )"}
                    dataDisabled={"Excluding Live Streaming Recordings " + "( " + CommonHelper.bytesToSize(this.props.allDocumentTypeSizes.recordings) + " )"}
                    className="p-1">
                </EuiSwitch>
            </EuiTile>
            <EuiTile title="Type" styleContent={{ display: "flex" }} tileStyle={{ padding: 5, border: "1px solid #bbbb" }} >
                <EuiCheckbox name="images"
                    className="p-2"
                    id="downloadImages"
                    label={"Images " + "( " + CommonHelper.bytesToSize(this.props.allDocumentTypeSizes.images) + " )"}
                    refValue={this.props.downloadFilter.images}
                    onChange={(e) => this.props.handleDownloadFilter(e)} />
                <EuiCheckbox name="videos"
                    className="p-2"
                    id="downloadVideos"
                    label={"Videos " + "( " + CommonHelper.bytesToSize(this.props.allDocumentTypeSizes.videos) + " )"}
                    refValue={this.props.downloadFilter.videos}
                    onChange={(e) => this.props.handleDownloadFilter(e)} />
                <EuiCheckbox name="pdfs"
                    className="p-2"
                    id="downloadPdfs"
                    label={"Pdfs " + "( " + CommonHelper.bytesToSize(this.props.allDocumentTypeSizes.pdfs) + " )"}
                    refValue={this.props.downloadFilter.pdfs}
                    onChange={(e) => this.props.handleDownloadFilter(e)} />
                <EuiCheckbox name="otherDocuments"
                    className="p-2"
                    id="downloadOtherDocuments"
                    label={"Other Documents " + "( " + CommonHelper.bytesToSize(this.props.allDocumentTypeSizes.otherDocuments) + " )"}
                    refValue={this.props.downloadFilter.otherDocuments}
                    onChange={(e) => this.props.handleDownloadFilter(e)} />
            </EuiTile>
            <EuiTile title="Acceptance Category" styleContent={{ display: "flex" }} tileStyle={{ padding: 5, border: "1px solid #bbbb" }}>
                <EuiCheckbox name="accepted"
                    className="p-2"
                    id="downloadAccepted"
                    label="Accepted"
                    refValue={this.props.downloadFilter.accepted}
                    onChange={(e) => this.props.handleDownloadFilter(e)} />
                <EuiCheckbox name="rejected"
                    className="p-2"
                    id="downloadRejected"
                    label="Rejected"
                    refValue={this.props.downloadFilter.rejected}
                    onChange={(e) => this.props.handleDownloadFilter(e)} />
                <EuiCheckbox name="pending"
                    className="p-2"
                    id="downloadPending"
                    label="Pending"
                    refValue={this.props.downloadFilter.pending}
                    onChange={(e) => this.props.handleDownloadFilter(e)} />
            </EuiTile>
        </EuiRow>)
    }
}

export default DownloadFilterModal;