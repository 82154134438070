import React from "react";
import { useEffect } from "react";
import { Pill } from '@eds/vanilla';

const EuiPills = (props) => {

    useEffect(() => {
        if (props.id) {
            const pills = this.elem.nativeElement.querySelectorAll('.pill.toggle');
            if (pills) {
                Array.from(pills).forEach(p => {
                    const pill = new Pill(p, null);
                    pill.init();
                });
            }
        } else {
            console.error("You should set an id to Pills component");
        }
    }, []);

    return (<div id={props.id} className={props.className}>
        {props.pills && props.pills.map(item => {
            return <span key={item.value}
                class={"pill toggle " + (props.pillClassName ?? "") + (props.selectedPill !== item.value ? " unselected " : "")}
                style={{ backgroundColor: item.color }}
                onClick={() => props.onClick && props.onClick(item.value)}>{item.text}</span>
        })}
    </div>);
}

export default EuiPills;