import React from "react";
import constants from "./constants";
import { groupBy } from "lodash";
import DateUtils from "./date-utils";
import PageHelper from "./page-helper";


export default class ActivityInboxHelper {

    static mapActivities = (data) => {
        if (!data || data.length === 0) {
            return [];
        }

        return data.map(item => {
            const activity = {
                plainActivity: item.activity,
                id: item.activity.id,
                name: item.activity.name,
                customerName: item.customer && item.customer.name,
                workplanName: item.workplan && item.workplan.name,
                siteName: item.site && item.site.name,
                aspCompany: item.aspCompany && item.aspCompany.name,
                sentToRsc: item.activity && (item.activity.sentToRSC || item.completedMaxDate),
                assigned: item.activity && (item.activity.assigned ?? 0) === 0 ? false : true,
                aspResource: item.aspResource,
                addInfo: {
                    workplanName: item.workplan && item.workplan.name,
                    siteName: item.site && item.site.name,
                    projectName: item.project && item.project.name
                },
                percentage: item.percentage,
                completedMaxDate: item.completedMaxDate,
                resourceId: item.activity?.resourceId
            };
            if (item.streamings && item.streamings.length > 0) {
                item.streamings.sort((a, b) => parseInt(b.id) - parseInt(a.id));
                const liveSessionStatus = item.streamings[0].status;
                const liveSessionInfo = {
                    sessionId: item.activity.id,
                    sessionName: item.activity.name,
                    sessionCode: item.streamings[0].sessionCode,
                    latitude: item.streamings[0].latitude,
                    longitude: item.streamings[0].longitude,
                    streamingUserName: item.streamings[0].streamingUserName + constants.streamerSuffix,
                    streamingUserId: item.streamings[0].streamingUserId,
                    streamingId: item.streamings[0].id,
                    siteName: item.site?.name,
                    activityId: item.activity?.id,
                    rscScope: item.activity?.rscScope,

                };
                
                activity["liveSessionStatus"] = liveSessionStatus;
                activity["liveSessionInfo"] = liveSessionInfo;
            } else {
                activity["liveSessionInfo"] = { sessionId: item.activity.id, sessionName: item.activity.name, sessionCode: null };
                activity["liveSessionStatus"] = 0;
            }
            return activity;
        });
    }

    static mapSubactivities = (subactivities, aspResource, addInfo) => {
        if (!subactivities || subactivities.length == 0) {
            return [];
        }
        return subactivities.map(item => {
            item.opened = "";
            item.tableRef = React.createRef();
            item.completed = (item.completed || false) ? true : false;
            item.documents = this.mapDocuments(item.activityDocuments, aspResource, addInfo, item.name, item.viCode);
            return item;
        });
    }

    static mapDocuments = (documents, aspResource, addInfo, checklistName, viCode) => {
        if (!documents || documents.length === 0) {
            return [];
        }

        return documents.map(item => {
            const newItem = { ...item };
            const name = newItem.name ?? newItem.documentPath.split("/").pop();
            newItem.nameAndId = { id: newItem.id, name, type: newItem.type };
            newItem.name = name;
            newItem.reviewerUserName = newItem.rscPerson?.text ?? newItem.imUser?.text;
            newItem.imName = newItem.imUser?.text;
            newItem.title = <p className="info-title mb-2" style={{ color: "#fff" }}>{checklistName}</p>
            const viInfo = newItem.viapiResultNavigation?.viResultModel?.conclusions?.map((c, index) =>
                <li className="info-list-item" key={newItem.id + c.category + index}><span className="info-label">{c.category}</span><div className="pt-1"> {c.result}</div></li>);
            newItem.isVIImage = viInfo ? true : false;
            newItem.isVIDrawing = newItem.viapiResultNavigation?.viResultModel?.annotations?.length > 0 ? true : false;
            newItem.uploadDetails =
                <ul className="infoList mb-2" style={{ paddingInlineStart: 0, color: "#fff" }} >
                    <li className="info-list-item" key={newItem.id + "aspName"}>{aspResource.name ?? ""}</li>
                    <li className="info-list-item" key={newItem.id + "aspPhone"}>{aspResource.phone ?? ""}</li>
                    <li className="info-list-item" key={newItem.id + "aspMail"}>{aspResource.email ?? ""}</li>
                </ul>
            newItem.additionalInfo = <span>
                <ul className="infoList" style={{ paddingInlineStart: 0, color: "#fff" }} >
                    <li className="info-list-item" key={newItem.id + "project"}><span className="info-label">Project: </span>{addInfo.projectName}</li>
                    <li className="info-list-item" key={newItem.id + "workplan"}><span className="info-label">Workplan: </span> {addInfo.workplanName}</li>
                    <li className="info-list-item" key={newItem.id + "site"}><span className="info-label">Site: </span> {addInfo.siteName}</li>
                    <li className="info-list-item" key={newItem.id + "latitude"}><span className="info-label">Latitude: </span> {this.getLatitude(newItem.latitude)}</li>
                    <li className="info-list-item" key={newItem.id + "longitude"}><span className="info-label">Longitude: </span> {this.getLongitude(newItem.longitude)}</li>
                    <li className="info-list-item" key={newItem.id + "uploadTime"}>
                        <span className="info-label">Upload Time: </span> {!newItem.uploadDate || !newItem.uploadStartDate ? "---"
                            : DateUtils.dateDifferenceInSeconds(newItem.uploadDate, newItem.uploadStartDate)}
                    </li>
                    <li className="info-list-item" key={newItem.id + "imReviewDate"}><span className="info-label">Actual Review DateTime </span>
                        <div className="pt-1">{DateUtils.toDateString(newItem.reviewDate, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"}</div>
                    </li>
                    <li className="info-list-item" key={newItem.id + "reviewEngineer"}><span className="info-label">Reviewer</span><div className="pt-1"> {newItem.reviewerUserName || "---"}</div></li>
                    {viInfo ?
                        <>
                            <div className="info-list-item" style={{ color: "#fff" }}>
                                <div className="info-label pb-1 pt-1" style={{ color: "yellow" }}>VI Inspection Result</div>
                                <li className="info-list-item" key={newItem.id + "viCode"}><div className="pt-1 pb-1">{viCode}</div></li>
                                {viInfo}
                                <li className="info-list-item" key={newItem.id + "viReason"}><div className="pt-1">{newItem.viapiResultNavigation?.viResultModel?.reason}</div></li>
                                <li className="info-list-item" key={newItem.id + "viResult"}>
                                    <div className="pt-1" style={{ color: PageHelper.getVIColor(newItem.viapiResultNavigation?.viResultModel?.result) }}>{newItem.viapiResultNavigation?.viResultModel?.result}</div>
                                </li>
                            </div>
                        </>
                        : null
                    }

                </ul>
            </span>
            if (newItem.type === constants.documentTypes.IMAGE) {
                newItem.posterPath = newItem.documentPath;
            } else {
                newItem.posterPath = this.getPosterPath(newItem.type) ?? newItem.documentPath;
            }
            return newItem;
        });
    }

    static mapTeamLogs = (teamVerificationLogs) => {
        if (!teamVerificationLogs || teamVerificationLogs.length === 0) {
            return [];
        }

        return teamVerificationLogs.map(item => {
            const newLog = { ...item };
            newLog.isEricsson = false;
            newLog.certificateIdentity = newLog.userCertificate?.code;
            newLog.certificateName = newLog.userCertificate?.name;
            return newLog;
        });
    }

    static mapResourceLogs = (resourseVerificationLogs) => {
        if (!resourseVerificationLogs || resourseVerificationLogs.length === 0) {
            return [];
        }

        const newGroupedList = groupBy(resourseVerificationLogs, 'userId');
        
        const result = Object.keys(newGroupedList).map(item => {
            const groupedRoles = groupBy(newGroupedList[item], 'jobId');
            
            const newObj = {
                verificationSessionId: newGroupedList[item][0].verificationSessionId,
                isOffsiteVerification: newGroupedList[item][0].isOffsiteVerification,
                user: newGroupedList[item][0].user,
                cmsUser: newGroupedList[item][0].cmsUser,
                roles: Object.keys(groupedRoles).map(j => {
                    const newRoleObj = {
                        job: groupedRoles[j][0].job,
                        certificates: groupedRoles[j].map(u => {
                            const newLog = { ...u };
                            newLog.certificateIdentity = newLog.userCertificate?.code;
                            newLog.certificateName = newLog.userCertificate?.name;
                            newLog.certificateLink = { documentPath: newLog.certificateLink, type: newLog.type, uploadDate: newLog.certificateDateTaken };
                            return newLog;
                        }).sort((a, b) => parseInt(b.id) - parseInt(a.id))
                            .reduce((a, b) => {
                                if (a.map(c => c.certificateId).includes(b.certificateId)) {
                                    return a;
                                } else {
                                    a.push(b);
                                    return a;
                                }
                            }, []),
                        status: groupedRoles[j].length > 0 ? !groupedRoles[j].some(v => v.isMandatory && v.certificateStatus != 1) : null
                    }
                    return newRoleObj;
                }),
                status: newGroupedList[item].length > 0 ? !newGroupedList[item].some(v => v.isMandatory && v.certificateStatus != 1) && !(newGroupedList[item][0].user.isPhotoVerified === false) : null
            }
            return newObj;
        });
        console.table(result);
        return result;
    }

    static getLatitude = (latitude) => {
        return latitude && latitude.length > 8 ?
            latitude.substr(0, 8) :
            latitude;
    }

    static getLongitude = (longitude) => {
        return longitude && longitude.length > 8 ?
            longitude.substr(0, 8) :
            longitude
    }

    static getPosterPath = (type) => {
        if (type === constants.documentTypes.VIDEO) {
            return require("../assets/video-poster.png");
        } else if (type === constants.documentTypes.PDF) {
            return require("../assets/pdf-poster.jpg");
        } else if (type === constants.documentTypes.OTHER_DOCUMENTS) {
            return require("../assets/excel-poster.jpg");
        } else if (type === constants.documentTypes.FILE) {
            return require("../assets/file-poster.png");
        } else {
            return null;
        }
    }

    static getColorClass = (status) => {
        if (status === constants.acceptanceStatusTypes.ACCEPTED) {
            return "color-green";
        } else if (status === constants.acceptanceStatusTypes.REJECTED) {
            return "color-red";
        } else {
            return "";
        }
    }

    static getSubActivityColor = (subActivity) => {
        const documents = subActivity && subActivity.documents;
        if (!documents || documents.length === 0) {
            return "";
        } else if (documents.every(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED)) {
            return "green";
        } else if (documents.every(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED)) {
            return "red";
        } else if (documents.every(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING)) {
            return "gray";
        } else {
            return "orange";
        }
    }

    static getPercentageColor = (percentage) => {
        if (percentage < 50) {
            return "red";
        } else if (percentage >= 50) {
            return "blue";
        }
    }
}
