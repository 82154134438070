import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../../layout";
import EuiTile from "../../../eui-components/tile";
import RscDashboardPanel from "./rscDashboardPanel"
class RscDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
    }
    renderMod = () => {
        return <RscDashboardPanel />
    }

    render() {
        return (<EuiTile fullscreen={true} tileStyle={{ padding: 0 }}>{this.renderMod()}</EuiTile>);
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
     }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(RscDashboard)));