import { Avatar, Badge, DialogContentText, Grid, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect }  from 'react'
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import { green,orange,red } from '@material-ui/core/colors';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import EuiRow from '../../../eui-components/row';
import EuiTextField from '../../../eui-components/textfield';
import DateUtils from '../../../utils/date-utils';
const useStyles = makeStyles((theme) => ({
    smallAvatar: {
      width: theme.spacing(19),
      height: theme.spacing(19),
      marginTop: '-25px',
    },
    locationPosition:{
       marginTop: 15
    },
    typography: {
      fontSize: 17,
      fontWeight: 520,
      // fontWeight: 'bold',
    },
    red: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
      },
      green: {
        color: '#fff',
        backgroundColor: green[500],
      },
      orange: {
        color: '#fff',
        backgroundColor: orange[500],
      },
  }));
  

export default function VehicleDailyInspectionsDetailsEHS(props) {
  const {delaiyInspectionDetails}=props
  const classes = useStyles();

  return (
    <EuiRow >
      <EuiRow >
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Off Site Inspection Site DateTime :</b> {DateUtils.toDateString(delaiyInspectionDetails.offSiteInspectionSiteDateTime , "YYYY-MM-DD HH:MM:SS")}
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6  filled">
          <b>Estimate Arrival DateTime :</b> {DateUtils.toDateString(delaiyInspectionDetails.estimateArrivalDateTime , "YYYY-MM-DD HH:MM:SS")}
        </div>
      </EuiRow >
      <EuiRow >

        <div className="column sm-12 md-6 lg-6 xl-6  filled">
          <b>Travel Durations (hours) :</b>  {+delaiyInspectionDetails.travelDurations}
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b> Travel Distance (km) :</b> {(Math.round(delaiyInspectionDetails.travelDistance * 100) / 100).toFixed(2)}
        </div> 
        <div className="column sm-12 md-6 lg-6 xl-6  filled">
          <b>Actual Team on Site DateTime : </b>{DateUtils.toDateString(delaiyInspectionDetails.actualTeamOnSiteDateTime , "YYYY-MM-DD HH:MM:SS")}
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6  filled">
          <b>Audit ID : </b>{delaiyInspectionDetails?.auditID}
        </div>
        
        <div className="column sm-12 md-12 lg-12 xl-12 filled">
          <b>Off-Site Coordinates (where vehicle is audited)</b>
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Latitude : </b>{delaiyInspectionDetails.offSiteLatitude}
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Longtitude :</b> {delaiyInspectionDetails.offSiteLongitude}
        </div>
        <div className="column sm-12 md-12 lg-12 xl-12 filled">
          <b>Site Coordinates</b>
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Latitude :</b> {delaiyInspectionDetails.siteLatitude}
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Longtitude :</b> {delaiyInspectionDetails.siteLongitude}
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Score :</b> {delaiyInspectionDetails.score }
        </div>'
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Vehicle Compliance Status(when Audited) :</b> {delaiyInspectionDetails?.complianceStatusWhenAudited }
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Last Service Date(when Audited) :</b> {DateUtils.toDateString(delaiyInspectionDetails?.lastServiceDateWhenAudited , "YYYY-MM-DD HH:MM:SS") }
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Next Service Mileage(when Audited) :</b> {delaiyInspectionDetails?.nextServiceMileage }
        </div>
        
        
        
    </EuiRow>
  </EuiRow>
  )
}
