import React from "react";

export default class EuiProgressBar extends React.Component {

    render() {
        return (
            <div className={"progress-bar flex-middle-content " + (this.props.color ?? "blue")} style={{ ...this.props.style }}>
                <progress value={this.props.value} max="100"></progress>
                <span>{this.props.value}<span className="unit">%</span></span>
            </div>
        );
  }
}
