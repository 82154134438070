/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, IconButton, Slide, Stack, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  // FriendElement,
  UserElement,
} from "../../../components/chaTools/UserElement";
import { Notification } from "@eds/vanilla";
import CloseIcon from '@material-ui/icons/Close';

import {  DialogTitle } from "@material-ui/core";
import EuiSearchField from "../../../eui-components/searchfield";
import ApiClient from "../../../utils/api-client";
import { FetchDirectConversations, FetchParticipantInvitedConversations } from "../../../store/slices/conversation";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UsersList = (props) => {
  const dispatch = useDispatch();
  const { handleClose, handleLoad } = props;

  const [searchValue, setSearchValue] = useState("")
  const [listUsers, setListUsers] = useState([])

  const { users } = useSelector((state) => state.chat);
  const { participantInvited, conversations } = useSelector(
    (state) => state.conversation.direct_chat
  );

  const [filteredUsers, setFilteredUsers] = useState();

  useEffect(() => {
    if (participantInvited) {
      const usersToRemove = participantInvited;
      const myArray = (users || [])?.filter(
        (ar) => !usersToRemove.find((rm) => rm.userName.toString()?.toUpperCase().trim() === ar.name.toString()?.toUpperCase().trim())
      );

      if (!filteredUsers) {
        setFilteredUsers(myArray);
      }
    }
  }, [filteredUsers, setFilteredUsers, participantInvited]);

  const handleSearchUsers = (e) => {
    const valueSearch = e.target.value.toString()?.toUpperCase().trim()
    setSearchValue(e.target.value)
    const oldUsers = users;
    var result = oldUsers.filter(item => item.name?.toString()?.toUpperCase()?.trim().includes(valueSearch) || item.email?.toString()?.toUpperCase().trim()?.includes(valueSearch));
    ///console.log("search items",result)  
    setFilteredUsers(result)

  }

  const GetAllConversationsByActivityId = () => {
    var request = {
      activityId: conversations[0].activityid,// 6645757
      conversationId: conversations[0].id, //
    };

    ApiClient.post("Chat/GetAllConversationsByActivityId", request).then(
      (data) => {
        handleLoad(false);

        // dispatch action
        dispatch(FetchDirectConversations({ conversations: data.data }));

        let participantInvited = data.data[0]?.participants;
        participantInvited = (participantInvited || [])?.map(function (ele) {
          return { ...ele, activityid: data.data[0].activityid };
        });

        dispatch(
          FetchParticipantInvitedConversations({
            participantInvited: participantInvited,
          })
        );
        if (participantInvited) {
          const usersToRemove = participantInvited;
          const myArray = (users || [])?.filter(
            (ar) => !usersToRemove.find((rm) => rm.userName.toString()?.toUpperCase().trim() === ar.name.toString()?.toUpperCase().trim())
          );

          setFilteredUsers(myArray);

        }
      }
    );
  };

  const friendRequest = (() => {
    handleLoad(true);
    ApiClient.post("Chat/SendfriendRequest", listUsers).then(response => {
      console.log("response SendfriendRequest", response)
      GetAllConversationsByActivityId()
      handleClose()
      const notification = new Notification({
        title: 'your request has been submitted successfully Check participants List!',
        timeout: 5000,
        icon: 'icon-check'
      });
      notification.init();
    }).catch(error => {
      console.error(error);
      handleLoad(false);

    });

  });

  return (
    <>
      <Box display="flex" direction="row" justifyContent="space-between">
        <EuiSearchField id={"search_" + searchValue}
          value={searchValue}
          placeholder={"Search..."}
          onChange={(e) => handleSearchUsers(e)}
          style={{ width: 250, margin: 0 }}
        />
        <button
          className="btn primary"
          onClick={() => {
            friendRequest();
          }}
        >
          Send Invitation
        </button>

      </Box>
      {/* </Stack> */}
      {(filteredUsers?.slice(0, 8) || []).map((el, idx) => {
        return <UserElement listUsers={listUsers} setListUsers={setListUsers} key={idx} {...el} />;
      })}
    </>
  );
};

const Friends = ({ open, handleClose, handleLoad }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      width={620}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{ p: 1 }}
    >
      <DialogTitle>{"Add Participants"}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack sx={{minHeight:500, maxHeight:500}}>
          <Stack spacing={1}>
            <UsersList handleLoad={handleLoad} handleClose={handleClose} />
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default Friends;
