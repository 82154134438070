import React from 'react'
import EuiTile from '../../eui-components/tile';
import EuiLoading from '../../eui-components/loading';
import EuiRow from '../../eui-components/row';
import VehicleHelper from '../../utils/vehicle-helper';
import EuiAutocompleteSelect from '../../eui-components/select/autocompleteselect';
import EuiTextField from '../../eui-components/textfield';
import EuiNumberField from '../../eui-components/numberfield';
import EuiDatepicker from '../../eui-components/datepicker';
import EuiCheckbox from '../../eui-components/checkbox';
import EuiHeader from '../../eui-components/header';

export default function VehicleInformationContent(props) {
    const {vehicleReducer,isLoading,setOpenVehicleInfo}=props;
  return (
    <>
        <div>
       <EuiHeader id="inspectionHeaderId"
            rightActions={
                <button className={"btn p-1"}
                    onClick={() => setOpenVehicleInfo(false)}>
                    <i className="icon icon-arrow-left p-1"></i>
                    Back
                </button>
            }
            
            >
        </EuiHeader>
   
       </div>
    <EuiTile title= {"Vehicle Id : " +vehicleReducer.id } contentClassName="tile-bordered" className={isLoading ? " disabled " : ""}>
        <EuiLoading id="vehicleDetailLoading" isLoading={isLoading} />
   
    
        <EuiRow>
            <div className="column sm-12 md-4 lg-4 xl-4 filled">
                {
                    VehicleHelper.vehicleHTMLStatus[vehicleReducer.vehicleStatus]
                }
                <EuiAutocompleteSelect 
                    id="vehicleTypeSelect"
                    className="p-2"
                    fullwidth
                    style={{ padding: 3 }}
                    disabled={true}
                    name="vehicleType"
                    label={{ title: "Vehicle Type *" }}
                    placeholder="---Select Vehicle Type---"
                    options={VehicleHelper.vehicleTypesOptions}
                    value={VehicleHelper.vehicleTypes[vehicleReducer?.vehicleType] || vehicleReducer?.vehicleType}
                    optionListStyle={{ width: "100%" ,padding: 3 ,opacity: "1.6"}}

                    required
                />
                <EuiTextField className="p-1"
                    fullwidth
                    required
                    readOnly={true}
                    style={{ padding: 3 }}
                    id="vehicleChassisNumberId"
                    inputClassName={"validation " + (VehicleHelper.isValidChassisNumber() ? " valid " : "")}
                    name="chassisNumber"
                    label="Chassis Number *"
                    pattern="[A-Za-z0-9]+"
                    validationMessage={VehicleHelper.isValidChassisNumber() ? "Valid Chassis Number" : "Only numbers and letters can be used and have to be unique."}
                    value={vehicleReducer?.chassisNumber || ""}
                />
                <EuiTextField className="p-1"
                    fullwidth
                    required
                    readOnly={true}
                    style={{ padding: 3 }}
                    id="registrationPlateNoId"
                    name="registrationPlateNo"
                    label="Registration Plate No *"
                    value={vehicleReducer?.registrationPlateNo || ""}
                />
                <EuiTextField className="p-1"
                    fullwidth
                    required
                    readOnly={true}
                    style={{ padding: 3 }}
                    id="vehicleManufacturerNameId"
                    name="vehicleManufacturer"
                    label="Manufacturer *"
                    value={vehicleReducer?.vehicleManufacturer || ""}
                />
                     <EuiTextField className="p-1"
                    fullwidth
                    required
                    readOnly
                    style={{ padding: 3 ,color: vehicleReducer.isVehicleCompliant==="Compliant" ?"green":"red"}}
                    id="IsVehicleCompliant"
                    name="IsVehicleCompliant"
                    label="Is Vehicle Compliant"
                    value={vehicleReducer.isVehicleCompliant || ""}
                />
                <EuiTextField className="p-1"
                    fullwidth
                    required
                    readOnly={true}
                    style={{ padding: 3 }}
                    id="vehicleModelNameId"
                    name="vehicleModelName"
                    label="Model Name *"
                    value={vehicleReducer?.vehicleModelName || ""}
                />
                <EuiRow>
                    <div className="column sm-4 md-4 lg-4 xl-4 filled">
                        <EuiNumberField
                            id="vehicleModelYearId"
                            className="ml-1"
                            disabled={true}
                            name="vehicleModelYear"
                            min={1950}
                            max={new Date().getFullYear() + 1}
                            label="Model Year"
                            style={{ width: "70%" }}
                            value={vehicleReducer?.vehicleModelYear}
                        />
                    </div>
                    <div className="column sm-8 md-8 lg-8 xl-8 filled">
                        <EuiNumberField
                            id="kmReadingId"
                            className="mr-1"
                            disabled={true}
                            name="kmReading"
                            label="Current Vehicle Mileage"
                            style={{ width: "100%", }}
                            value={vehicleReducer?.kmReading||0}
                        />
                    </div>
                </EuiRow>
                <EuiRow>
                    <div className="column sm-6 md-6 lg-6 xl-6 filled">
                        <EuiNumberField name="lastServiceKmReading"
                            id="lastServiceKmReadingId"
                            className="ml-1 mt-1"
                            disabled={true}
                            label="KM Reading of Last Service"
                            value={vehicleReducer?.lastServiceKmReading}
                            style={{ width: "100%" }}
                        />
                    </div>
                    <div className="column sm-6 md-6 lg-6 xl-6 filled">
                        <EuiDatepicker
                            id="lastServiceDatetimeId"
                            className="mt-1 ml-1 mr-1"
                            disabled={true}
                            label="Last Service DateTime"
                            placeholder="YYYY-MM-DD"
                            name="lastServiceDatetime"
                            value={vehicleReducer?.lastServiceDatetime}
                                />
                    </div>
              
                    <div className="column sm-2 md-2 lg-6 xl-6 filled">
                        <EuiNumberField
                           name="mileage"
                            id="mileageId"
                            className="ml-1 mt-1"
                            min={vehicleReducer?.kmReading}
                            label="Next Service Mileage"
                            disabled={true}

                            value={vehicleReducer?.mileage}
                            style={{ width: "100%" }}
                            inputClassName={"validation " + (parseInt(vehicleReducer?.mileage)>=  parseInt(vehicleReducer?.kmReading) ? " valid " : "")}
                            validationMessage={ parseInt(vehicleReducer?.mileage)>=  parseInt(vehicleReducer?.kmReading)? "Valid next service mileage" :  ''}

                        />
                    </div> 
                </EuiRow>
            </div>

            <div className="column sm-12 md-8 lg-8 xl-8 filled">
                <EuiCheckbox name="fittedWithAbs"
                    id="fittedWithAbsId"
                    className="checkbox-padding"
                    disabled={true}
                    label="Fitted with ABS braking system"
                    labelStyle={{ marginLeft: 2 }}
                    refValue={vehicleReducer?.fittedWithAbs === 1 ? true : false}
                />
                <EuiCheckbox name="fittedWithSeatBelt"
                    id="fittedWithSeatBeltId"
                    className="checkbox-padding"
                    disabled={true}
                    label="Fitted with Seat belts and headrests applicable to all seats"
                    labelStyle={{ marginLeft: 2 }}
                    refValue={vehicleReducer?.fittedWithSeatBelt === 1 ? true : false}
                />
                <EuiCheckbox name="fittedWithHeadRests"
                    id="fittedWithHeadRestsId"
                    className="checkbox-padding"
                    disabled={true}
                    label="Fitted with headrests "
                    labelStyle={{ marginLeft: 2 }}
                    refValue={vehicleReducer?.fittedWithHeadRests === 1 ? true : false}
                />
                <EuiCheckbox name="fittedWithRolloverProtection"
                    id="fittedWithRolloverProtectionId"
                    className="checkbox-padding"
                    disabled={true}
                    label="Fitted with roll-over protection / Bars (pickups or similar)"
                    labelStyle={{ marginLeft: 2 }}
                    refValue={vehicleReducer?.fittedWithRolloverProtection === 1 ? true : false}
                />
                <EuiCheckbox name="fittedWithApprTyres"
                    id="fittedWithApprTyresId"
                    className="checkbox-padding"
                    disabled={true}
                    label="Fitted with Appropriate Tyres / Replaced considering milage usage and life time"
                    labelStyle={{ marginLeft: 2 }}
                    refValue={vehicleReducer?.fittedWithApprTyres === 1 ? true : false}
                />
                <EuiCheckbox name="fittedWithHeadsFreeKit"
                    id="fittedWithHeadsFreeKitId"
                    className="checkbox-padding"
                    disabled={true}
                    label="Fitted with Hands-free Kits (where safe and legally to do so)"
                    labelStyle={{ marginLeft: 2 }}
                    refValue={vehicleReducer?.fittedWithHeadsFreeKit === 1 ? true : false}
                />
                   <EuiCheckbox name="roadworthyAndInsured"
                    id="roadworthyAndInsuredId"
                    className="checkbox-padding"
                    disabled={true}
                    label="Roadworthy And Insured"
                    labelStyle={{ marginLeft: 2 }}
                    refValue={vehicleReducer?.roadworthyAndInsured === 1 ? true : false}
                />
                <div className="tile-title">Emergency Requirements</div>
                <div className="content tile-bordered mt-2" style={{ paddingLeft: 2 }}>
                    <EuiCheckbox name="emergencyEqptTriAngles"
                        id="emergencyEqptTriAnglesId"
                        className="checkbox-padding"
                        disabled={true}
                        label="Minimum emergency equipment 2x emergency tri-angles"
                        labelStyle={{ marginLeft: 2 }}
                        refValue={vehicleReducer?.emergencyEqptTriAngles === 1 }
                    />
                    <EuiCheckbox name="emergencyEqpt25Fe"
                        id="emergencyEqpt25FeId"
                        className="checkbox-padding"
                        disabled={true}
                        label="Minimum emergency equipment  1x 2.5 Kg FE"
                        labelStyle={{ marginLeft: 2 }}
                        refValue={vehicleReducer?.emergencyEqpt25Fe === 1}
                    />
                    <EuiCheckbox name="emergencyEqptFaKit"
                        id="emergencyEqptFaKitId"
                        className="checkbox-padding"
                        disabled={true}
                        label="Minimum emergency equipment  1 x FA Kit"
                        labelStyle={{ marginLeft: 2 }}
                        refValue={vehicleReducer?.emergencyEqptFaKit === 1 }
                    />
                    <EuiCheckbox name="emergencyEqptHighReflectorVest"
                        id="emergencyEqptHighReflectorVestId"
                        className="checkbox-padding"
                        disabled={true}
                        label="Minimum emergency equipment  High-visible reflector vest"
                        labelStyle={{ marginLeft: 2 }}
                        refValue={vehicleReducer?.emergencyEqptHighReflectorVest === 1 }
                    />
                </div>
                <div className="tile-title">GPS/Telematics Requirements</div>
                <div className="content tile-bordered mt-2" style={{ paddingLeft: 2 }}>
                    <EuiCheckbox name="gpsInstalled"
                        id="gpsInstalledId"
                        className="checkbox-padding"
                        disabled={true}
                        label="Installed in vehicle"
                        labelStyle={{ marginLeft: 2 }}
                        refValue={vehicleReducer?.gpsInstalled === 1}
                    />
                    <EuiCheckbox name="gpsSystemAccessGranted"
                        id="gpsSystemAccessGrantedId"
                        className="checkbox-padding"
                        disabled={true}
                        label="System Access Granted"
                        labelStyle={{ marginLeft: 2 }}
                        refValue={vehicleReducer?.gpsSystemAccessGranted === 1 }
                    />
                    <EuiCheckbox name="gpsLiveTracking"
                        id="gpsLiveTrackingId"
                        className="checkbox-padding"
                        disabled={true}
                        label="Active/live tracking Reports"
                        labelStyle={{ marginLeft: 2 }}
                        refValue={vehicleReducer?.gpsLiveTracking === 1 }
                    />
                    <EuiCheckbox name="driverBehaviourMonitor"
                        id="driverBehaviourMonitoredId"
                        className="checkbox-padding"
                        disabled={true}
                        label="Driver Behaviour Monitor"
                        labelStyle={{ marginLeft: 2 }}
                        refValue={vehicleReducer?.driverBehaviourMonitor === 1 }
                    />
               
                </div>
           
            </div>

          

        </EuiRow>
    </EuiTile>
  
    

</>
  )
}
