import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import constants from "../../utils/constants";
import ApiClient from "../../utils/api-client";
import EuiLoading from "../../eui-components/loading";
import { changeMod, setFilteredTeams, setTeam } from "../../store/actions/teamAction";

class TeamList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            teamColumns: [
                {
                    title: "Team ID",
                    onCreatedCell: (td, cellData) => {
                        td.innerHTML = `<a id="team_${cellData}">${cellData}</a>`;
                        td.querySelector("#team_" + cellData).addEventListener('click', () => this.openTeamDetail(cellData))
                    },
                    key: "id",
                    width: "10%",
                    sort: "desc",
                    cellStyle: "white-space:nowrap; font-size: 13px"
                },
                {
                    title: "Team Name",
                    key: "name",
                    width: "20%",
                    sort: "none",
                    cellStyle: "white-space:nowrap; font-size: 13px"
                },
                {
                    title: "Team Type",
                    key: "type",
                    width: "20%",
                    cellStyle: "white-space:nowrap; font-size: 13px"
                },
                {
                    title: "Customer Name",
                    key: "customerName",
                    width: "20%",
                    cellStyle: "white-space:nowrap; font-size: 13px"
                },
                {
                    title: "Comment",
                    key: "comment",
                    width: "10%",
                    cellStyle: "white-space:nowrap; font-size: 13px"
                },
            ],
            isLoading: false,
            teamFiltered: true,
             fileName : "teams_details" + new Date().toISOString()
        }
    }

    
    openTeamDetail = (teamId) => {
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Team/GetTeamDetails", { id: teamId })
                .then(response => {
                    this.setState({ isLoading: false }, () => {
                        const team = { ...response.data }
                        this.props.setTeam(team);
                        this.props.changeMod(constants.pageMods.VIEW_ONLY);
                    });
                })
                .catch(error => {
                    console.error(error);
                    this.setState({ isLoading: false });
                });
        });
    }

    changeFilterTeams = (e) => {

    }


    render() {
        const filteredTeams = [...this.props.teamData.filteredTeams.data];
        return (<EuiRow key={this.state.isLoading} disabled={this.state.isLoading ? "disabled" : ""}>
            <EuiLoading id="teamToDetailsLoading" isLoading={this.state.isLoading} />
            <ObseleteEuiTable
                classes="tiny striped"
                // key={filteredTeams.length}
                columns={this.state.teamColumns}
                data={filteredTeams}
                id="teamFilteredTable"
                scroll={true}
                sortable={true}
                paginated={true}
                viewActionIcon={true}
                totalCount={this.props.teamData.filteredTeams.data.length}
                hideTableInfo={false}
              
            exportExcel={true}
            Reportdata={filteredTeams}
            excelFileName={this.state.fileName}
                // actionIcon={
                //     <EuiRow className="ml-1 w-10">
                //         <span >
                //             <EuiSwitch
                //                 id="filterTeamCheckbox"
                //                 onChange={(e) => this.changeFilterTeams(e)}
                //                 checked={this.state.teamFiltered}
                //                 dataEnabled="Cancelled teams excluded "
                //                 dataDisabled="All teams"
                //                 name="teamssFiltered"
                //             />
                //         </span>
                //     </EuiRow>}
                rowsPerPage={10}
            >
            </ObseleteEuiTable>
        </EuiRow >
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, teamData: state.team });
const mapDispatchToProps = dispatch => {
    return {
        setTeam: (team) => setTeam(team)(dispatch),
        changeMod: (mod) => changeMod(mod)(dispatch),
        setFilteredTeams: (filteredTeams) => setFilteredTeams(filteredTeams)(dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamList);