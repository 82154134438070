import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageHelper from "../../utils/page-helper";
import { ToastContainer, toast } from 'react-toastify';
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTile from "../../eui-components/tile";
import ApiClient from "../../utils/api-client";
import TicketHelper from "../../utils/ticket-helper";
import DialogModal from "../../components/dialog";
import AssignTicketDialog from "./assignTicketDialog";
import Constants from "../../utils/constants";
import EuiLoading from "../../eui-components/loading";
import constants from "../../utils/constants";
import DateUtils from "../../utils/date-utils";

class TicketFilteredList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            openAssignDialog: false,
            selectedTickets: [],
            ticketColumns: [
                {
                    title: "Ticket ID",
                    onCreatedCell: (td, cellData) => {
                        td.innerHTML = `<a id="ticket_${cellData}">${cellData}</a>`;
                        td.querySelector("#ticket_" + cellData).addEventListener('click', () => {
                            this.openTicketDetail(cellData);
                        })
                    },
                    key: "id",
                    sort: "desc",
                    width: "10%"
                },
                {
                    title: "Ticket Title",
                    key: "title",
                    sort: "none",
                    width: "20%"
                },
                {
                    title: "Country",
                    key: "countryName",
                    sort: "none",
                    width: "10%"
                },
                {
                    title: "Customer",
                    key: "customerName",
                    sort: "none",
                    width: "15%"
                },
                {
                    title: "Classification",
                    key: "ticketClassificationName",
                    sort: "none",
                    width: "10%"
                },
                {
                    title: "Assigned User",
                    onCreatedCell: (td, cellData) => {
                        
                        
                        const userName = this.props.userData.users?.find(x => x.userId == cellData)?.text;
                        td.innerHTML = `<span id="ticket_${cellData}">${userName || ""}</span>`;
                    },
                    key: "assignedUserId",
                    sort: "none",
                    width: "10%"
                },
                {
                    title: "Contact Address",
                    key: "contactAddress",
                    sort: "none",
                    width: "10%"
                },
                {
                    title: "Date Submitted",
                    onCreatedCell: (td, cellData) => {
                        td.innerHTML = `<span id="ticket_${cellData}">${DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm")}</span>`;
                    },
                    key: "createDate",
                    sort: "none",
                    width: "15%"
                }
            ]
        }
    }

    componentDidMount() {
        PageHelper.getCoordinates();
        this.props.setSelectedTickets([]);
    }

    openTicketDetail = (ticketId) => {
        if (this.props.previewSelectedTicket) {
            const selected = this.props.initialTickets.find(x => x.id === ticketId);
            this.props.previewSelectedTicket(selected);
            //
            return;
        }
        const ticket = this.props.ticketData.tickets.tickets.find(x => x.id === ticketId);
        //
        this.props.setCurrentTicket(ticket);

        this.props.setTicketPageMod(constants.pageMods.EDIT);

        this.props.setSelectedTickets([]);
    }

    setSelectedTickets = (e, type) => {
        const detail = e.detail;
        const data = detail.data;

        if (type === "add") {
            this.setState({ selectedTickets: [...this.state.selectedTickets, data] }, () => {
                this.props.setSelectedTickets(this.state.selectedTickets);
            });
        } else if (type === "remove") {
            this.setState({ selectedTickets: this.state.selectedTickets.filter(item => item.id !== data.id) }, () => {
                this.props.setSelectedTickets(this.state.selectedTickets);
            });
        }
    }

    render() {
        return (<EuiRow disabled={this.state.isLoading ? "disabled" : ""}>
            <ObseleteEuiTable
                classes="tiny split-striped"
                columns={this.state.ticketColumns}
                data={this.props.initialTickets || this.props.ticketData.tickets.tickets}
                id={this.props.id}
                scroll={true}
                sortable={true}
                paginated={true}
                rowsPerPage={15}
                filter={this.props.enableFilter}
                selectable={this.props.enableMultiSelection ? "multi" : ""}
                selectRow={(e, type) => this.setSelectedTickets(e, type)}
            />

        </EuiRow >)
    }c
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ticketData: state.ticket });
const mapDispatchToProps = dispatch => {
    return {
        setTickets: (tickets) => dispatch({ type: "SET_TICKETS", payload: tickets }),
        setTicketPageMod: (mod) => dispatch({ type: "SET_TICKET_PAGE_MOD", payload: mod }),
        setCurrentTicket: (ticket) => dispatch({ type: "SET_CURRENT_TICKET", payload: ticket }),
        setSelectedTickets: (selectedTickets) => dispatch({ type: "SET_SELECTED_TICKETS", payload: selectedTickets })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TicketFilteredList));