const initialState = {
    sessionInfo: {
        id:0,
        name: "",
        customerId: 0,
        //projectId: 0,
        customerUsers: [],
        responsibleUsers:[],
        siteCode: "",
        customerProjectName: "",
        scopeOfWorkDefinition: "",
        customerScopeIdentification: "",
        createUser: "",
        siteCode: "",
        viewOnly:false,
    },  
    sessionSummary: [],
    sessionDocuments: [],
    sessionActivities: [],
    sessionSubActivities: [],
    sessionAllActivities: [],
    sessionRemoteActivities: [],
    sessionActivityDocuments: [],

    sessionActivityIds:[],

    approveSession: {
        id: 0,
        name: "",
        siteCode:"",
        viewOnly: false,
        status: 2,
        customerUsers: [],
        responsibleUsers: [],
        currentSession: {id:0, activities: [], sessionActivities: []}
    },

    allSession: [],
    customerUsers: []
};

export default (state = initialState, action) => {
    switch (action.type) {       
        case "SET_SESSION_INFO":
            return {
                ...state,
                sessionInfo : action.payload
            };
        case "SET_SESSION_SUMMARY":
            return {
                ...state,
                sessionSummary: action.payload
            };
        case "SET_SESSION_DOCUMENTS":
            return {
                ...state,
                sessionDocuments: action.payload
            };
        case "SET_SESSION_ACTIVITIES":
            return {
                ...state,
                sessionActivities: action.payload
            };
        case "SET_SESSION_SUB_ACTIVITIES":
            return {
                ...state,
                sessionSubActivities: action.payload
            };
        case "SET_SESSION_ALL_ACTIVITIES":
            return {
                ...state,
                sessionAllActivities: action.payload
            };
        case "SET_APPROVE_SESSION":
            return {
                ...state,
                approveSession: action.payload
            };
        case "SET_ALL_SESSION":
            return {
                ...state,
                allSession: action.payload
            };
        case "SET_SESSION_REMOTE_ACTIVITIES":
            return {
                ...state,
                sessionRemoteActivities : action.payload
            };
        case "SET_SESSION_ACTIVITY_IDS":
            return {
                ...state,
                sessionActivityIds: action.payload
            };
        case "SET_CUSTOMER_USERS":
            return {
                ...state,
                customerUsers: action.payload
            };
        case "SET_SESSION_ACTIVITY_DOCUMENTS":
            return {
                ...state,
                sessionActivityDocuments: action.payload
            };
        default:
            return state;
    }
};
