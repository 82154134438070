const initialState = {
    complaintScopes: [],
    complaintTypes: [],
    resourceUsers: [],
    complaintDocuments: [],
    addedComplaint: { id: 0 },
    complaintsList: [],
    aspContacts: [],
    sasToken: "",
    pageMod: "",
    isLoading: false,
    complaintRecipients: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "ADD_COMPLAINT_SCOPES":
            return {
                ...state,
                complaintScopes: action.payload
            }
        case "ADD_COMPLAINT_TYPES":
            return {
                ...state,
                complaintTypes: action.payload
            }
        case "ADD_RESOURCE_USERS":
            return {
                ...state,
                resourceUsers: action.payload
            }
        case "SET_COMPLAINT_DOCUMENTS":
            return {
                ...state,
                complaintDocuments: action.payload
            }
        case "SET_ADDED_COMPLAINT":
            return {
                ...state,
                addedComplaint: action.payload
            }
        case "SET_ALL_COMPLAINTS":
            return {
                ...state,
                complaintsList: action.payload
            }
        case "SET_ASP_CONTACTS":
            return {
                ...state,
                aspContacts: action.payload
            }
        case "SET_SAS_TOKEN":
            return {
                ...state,
                sasToken: action.payload
            }
        case "SET_PAGE_MOD":
            return {
                ...state,
                pageMod: action.payload
            }
        case "SET_IS_LOADING":
            return {
                ...state,
                isLoading: action.payload
            }
        case "SET_COMPLAINT_RECIPIENTS":
            return {
                ...state,
                complaintRecipients: action.payload
            }
        default:
            return state;
    }
};