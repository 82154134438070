import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import green from '@material-ui/core/colors/green';
import "@eds/vanilla/eds.min.css";
import 'react-toastify/dist/ReactToastify.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';
import "./css/common.css";
import { trackWindowScroll } from "react-lazy-load-image-component";
import { isLoggedIn } from "./utils/adalConfig";

const theme = createMuiTheme({
    palette: {
        primary: { main: "#1174E6" },
        secondary: { main: "#1174E6" },
    },
    status: {
    },
    typography: {
        fontFamily: '"Ericsson Hilda", Helvetica, Arial, sans-serif',
        backgroundColor: "inherit",
        color: "inherit",
        fontSize: 14,
        fontWeight: "inherit",
        lineHeight: "inherit"
    },
    spacing: 3,
    overrides: {
        MuiInputBase: {
            root: {
                paddingTop: 3,
                backgroundColor: "inherit"
            },
            input: {
                backgroundColor: "inherit"
            }
        },
        MuiFormLabel: {
            root: {
                color: "inherit",
                fontSize: 17,
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: "1.25rem"
            },
            fontSizeSmall: {
                fontSize: "1rem",
            }
        },
        MuiIconButton: {
            root: {
                padding: 5,
                color: "inherit",
            },
            sizeSmall: {
                padding: 1
            },
            colorInherit: {
                color: "inherit"
            }
        },
        MuiChip: {
            label: {
                overflow: "inherit",
                paddingLeft: 7,
                paddingRight: 7,
            },
            root: {
                height: 25,
                fontSize: "12px",
                color: "#fff",
                backgroundColor: green[500]
            },
        },
        MuiTableCell: {
            sizeSmall: {
                padding: "3px 12px 3px 8px"
            },
            footer: {
                color: "inherit"
            },
            body: {
                color: "inherit"
            }
        },
        MuiTableRow: {
            root: {
                "&:hover": {
                    color: "inherit"
                },
                color: "inherit",
                backgroundColor: "inherit"
            }
        },
        MuiTable: {
            root: {
                overflow: "hidden"
            }
        },
        MuiDialog: {
            paperWidthSm: {
                maxWidth: 1000
            },
        },
        MuiExpansionPanelSummary: {
            root: {
                height: 30,
                minHeight: 20,
                transition: "none",
            }
        },
        MuiTypography: {
            h6: {
                fontSize: "1rem",
            },
            body1: {
                fontSize: 14,
            }
        },
        MuiDivider: {
            root: {
                backgroundColor: "#3e3e3e"
            }
        },
        MuiDrawer: {
            paper: {
                backgroundColor: "#2B2B2B",
            },
            paperAnchorDockedRight: {
                borderLeftColor: "#3e3e3e"
            }
        },
        MuiPaper: {
            root:
            {
                backgroundColor: "inherit",
                color: "inherit",
                //transition:"none"
            }
        },
        MuiTablePagination: {
            root: {
                color: "inherit",
            }
        },
        MuiButton: {
            root: {
                color: "inherit"
            }
        },

        MuiCheckbox: {
            root:
            {
                color: "inherit",
                padding: 5
            }
        },
        MuiTableSortLabel: {
            root: {
                "&$active": {
                    color: "inherit",
                    '&& $icon': {
                        color: "inherit"
                    }
                },

            },

        },
        MuiAutocomplete: {
            option: {
                backgroundColor: "#fff",
                color: '#242424',
                '&[data-focus="true"]': {
                    backgroundColor: '#dcdcdc',
                },
                '&[data-selected="true"]': {
                    backgroundColor: '#dcdcdc',
                },
            },
        },

        MuiInputAdornment: {
            positionStart: {
                marginRight: 0
            },
            positionEnd: {
                marginRight: 0
            },
        },

        MuiSwitch: {
            root: {
                marginTop: 3
            }
        }
    }
});

var userchecked = function (event) {
    if (!event) { event = window.event; } // ie suq
    if (!event.newValue) return;
    if (event.key == 'UserCheckout') {
        window.location = '/';
        window.location.reload(true);
    }
};

// listen for changes to localStorage
if (window.addEventListener) {
    window.addEventListener("storage", userchecked, false);
} else {
    window.attachEvent("onstorage", userchecked);
};

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </PersistGate>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
