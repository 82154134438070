import React from 'react';
import { connect } from 'react-redux';
import Table from "../../../components/table/index";
import EuiRow from "../../../eui-components/row";
import AutoCompleteSelect from "../../../components/autocompleteselect";
import ApiClient from "../../../utils/api-client";
import DialogModal from "../../../components/dialog";
import Filter from "./filter";
import { toast } from 'react-toastify';

class SessionReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openFilterDialog: false,
            reports: [],
            reportsValue: [{ value: 0, Text: "Select Report" }],
            availableFields: [{ title: "Field Name", field: "name" }],
            fieldsReport: [],
            reportField: [],
            reportsValue: [],
            reportsCount: 0,
            availableFields: [{ title: "Column Name", field: "name" }],
            fieldsAv: [{ name: "Customer Name", property: "CustomerName" }, { name: "Customer ID", property: "CustomerId" }, { name: "Customer Project Name", property: "CustomerProjectName" }, { name: "Create Date", property: "CreateDate" }, { name: "Create User", property: "CreateUser" }, { name: "Update Date", property: "UpdateDate" }, { name: "Update User", property: "UpdateUser" }, { name: "Customer Scope Identification", property: "CustomerScopeIdentification" }, { name: "Scope Of Work Description", property: "ScopeOfWorkDescription" }, { name: "Session Status", property: "Status" }, { name: "Site Code", property: "SiteCode" }, { name: "Submit Date", property: "SubmitDate" }, { name: "Submit User", property: "SubmitUser" }, { name: "Approve Date", property: "ApproveDate" }, { name: "Approve User", property: "ApproveUser" }, { name: "Reject Date", property: "RejectDate" }, { name: "Reject User", property: "RejectUser" }, { name: "Rating", property: "Rating" }],
            fieldsReport: [{ title: "Column Name", field: "name" }, { title: "Criteria", field: "criteria" }],
            reportField: [{ name: "Session ID", property: "Id", tableData: { id: 0 } }, { name: "Session Name", property: "Name", tableData: { id: 1 } }],
            rowData: [],
            reportName: "",
            index: 0,
            showInputField: false,
            options: [],
            criteria: [],
            criteriaList: [],
            index: 1,
            reportssValue: [],
            cols: [],
            selectReportId: 0,
            enableSelect: false,
            enableAddbtn: false,
            enableDelRename: false,
            exportEnable: false,
            openDialogRename: false,
            reportNewName: "",
            reportToBeUpdatedID: "",
            filterHide: false,
            openDialogDelete: false,
            enableEditbtn: false,
            hideEdit: true,
            openDialogAdd: false,
            reportChanged: false,
        }

    }



    componentDidMount() {
        ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GetAllReports", { Id: parseInt(this.props.userData.userData.id) })
            .then(response => {
                if (response.data != null) {
                    this.setState({ reportssValue: [] })
                    response.data.forEach(item => {
                        this.state.reportssValue.push(item)
                    })
                    this.setState({
                        cols: response.data.map(item => {
                            let properties = {
                                "column": item.sessionReportColumns
                            }; return properties;
                        })
                    })

                    // this.setState({ fieldsAv: this.state.reportField })
                }
                let reports = this.state.reportssValue.map(item => {
                    let properties = { "value": item.id, "text": item.name }; return properties;
                });
                this.setState({
                    reportsValue: reports
                })
            })



    }



    availableSelectChange = (rows) => {
        this.setState({ fieldsReport: rows })

    }

    Add = () => {
        this.setState({
            openDialogAdd: false,
            reportChanged: true,
            reportField: [{ name: "Session ID", property: "Id", tableData: { id: 0 } }, { name: "Session Name", property: "Name", tableData: { id: 1 } }]

        })
        let fields = [{ name: "Session ID", property: "Id", tableData: { id: 0 } }, { name: "Session Name", property: "Name", tableData: { id: 1 } }];
        if (this.state.reportName == "" || this.state.reportName == null) {
            toast.error("Please enter a report name", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });

        }
        else {
            // toast.success("Report is being saved", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
            ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/AddReportAndCols", { ReportName: this.state.reportName, CreateUser: parseInt(this.props.userData.userData.userId), RunTimes: 1, Fields: fields }
            ).then(response => {
                if (response.data == true) {
                    this.props.report(fields, this.state.reportName, this.state.reportToBeUpdatedID, this.state.reportChanged);
                    this.componentDidMount();
                    toast.success("Report Added Successfully", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
                    this.setState({

                        enableSelect: false,
                        showInputField: false,
                        selectReportId: "",
                        enableAddbtn: false
                    })

                    // this.setState({ fieldsAv: this.state.reportField })
                }
            })
        }
        this.props.report(this.state.reportField, this.state.reportName, this.state.reportToBeUpdatedID, true);

    }


    handleChange = (event) => {
        if (this.state.reportChanged) {
            ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GetAllReports", { Id: parseInt(this.props.userData.userData.id) })
                .then(response => {
                    if (response.data != null) {
                        this.setState({ reportssValue: [] })
                        response.data.forEach(item => {
                            this.state.reportssValue.push(item)
                        })
                        this.setState({
                            cols: response.data.map(item => {
                                let properties = {
                                    "column": item.sessionReportColumns
                                }; return properties;
                            })
                        })

                        // this.setState({ fieldsAv: this.state.reportField })
                    }
                    let reports = this.state.reportssValue.map(item => {
                        let properties = { "value": item.id, "text": item.name }; return properties;
                    });
                    this.setState({
                        reportsValue: reports
                    })
                })
        }

        this.setState({
            selectReportId: event.target.value,
            enableSelect: true,
            enableDelRename: true,
            filterHide: false,
            hideEdit: false,
            enableEditbtn: false,
            reportChanged: false,
        })
        let fields = [{ name: "Session ID", property: "Id", tableData: { id: 0 } }, { name: "Session Name", property: "Name", tableData: { id: 1 } }]
        let criteria = [];
        this.state.fieldsAv.forEach(element => element.tableData.checked = false);

        this.state.cols.forEach(item => {
            item.column.forEach(col => {
                if (col.reportId == event.target.value.value) {
                    if (fields.findIndex(item => item.name == col.columnName) == -1) {
                        let index = this.state.fieldsAv.findIndex(item => item.name == col.columnName);
                        if (index != -1) {
                            fields.push({ name: col.columnName, property: this.state.fieldsAv[index].property, tableData: { id: fields.length, checked: true } });
                            this.state.fieldsAv[index].tableData.checked = true;
                            criteria.push({
                                'columnId': fields.length - 1, 'column': col.criteria, 'colName': col.columnName
                            })
                        }
                    }
                    else {
                        let criteriaIndex = criteria.findIndex(item => item.colName == col.columnName);
                        if (criteriaIndex != -1) {
                            if (criteria[criteriaIndex].column != "null" && criteria[criteriaIndex].column != col.criteria && criteria[criteriaIndex].column)
                                criteria[criteriaIndex].column = criteria[criteriaIndex].column + ", " + col.criteria;
                            else
                                criteria[criteriaIndex].column = col.criteria;
                        }
                    }

                    fields.forEach(item => { if (criteria.findIndex(c => c.colName == item.name) != -1) item.criteria = criteria[criteria.findIndex(c => c.colName == item.name)].column });

                }
            })
        })
        this.setState({
            reportName: event.target.value.text,
            reportNewName: event.target.value.text,
            reportField: fields,
            reportToBeUpdatedID: event.target.value.value
        })
        this.props.report(fields, event.target.value.text, event.target.value.value, this.state.reportChanged);
    }


    handleFilter = (event, rowData) => {
        let i = 0;
        this.setState({ reportChanged: true })
        this.props.sessionData.allSession.map(item => {
            i++;
            item['id'] = i;
        });
        let customerNamee = [];
        let customerId = [];
        let customerProjectName = [];
        let createDate = [];
        let createUser = [];
        let projectName = [];
        let siteCode = [];
        let sessionStatus = [];
        let customerScopeId = [];
        let projectId = [];
        let scopeWorkDescription = [];
        let rating = [];
        let submitDate = [];
        let updateDate = [];
        let updateUser = [];
        let submitUser = [];
        let rejectDate = [];
        let rejectUser = [];
        let approveDate = [];
        let approveUser = [];

        this.props.sessionData.allSession.forEach(item => {
            if (customerNamee.length >= 1) {
                let index = customerNamee.findIndex(index => index.name == item.customerName);
                if (index == -1) {
                    let properties = { "name": item.customerName, "id": customerNamee.length + 1 }; customerNamee.push(properties);
                }
            }
            else {
                let properties = {
                    "name": item.customerName, "id": item.id
                }; customerNamee.push(properties);
            }
            if (approveUser.length >= 1) {
                let index = approveUser.findIndex(index => index == parseInt(item.approveUser));
                if (index == -1 && parseInt(item.approveUser)) {
                    approveUser.push(parseInt(item.approveUser));
                }
            }
            else {
                if (parseInt(item.approveUser))
                    approveUser.push(parseInt(item.approveUser));
            }

            if (rejectUser.length >= 1) {
                let index = rejectUser.findIndex(index => index == parseInt(item.rejectUser));
                if (index == -1 && parseInt(item.rejectUser)) {
                    rejectUser.push(parseInt(item.rejectUser));
                }
            }
            else {
                if (parseInt(item.rejectUser))
                    rejectUser.push(parseInt(item.rejectUser));
            }

            if (customerId.length >= 1) {
                let index = customerId.findIndex(index => index.name == item.customerId);
                if (index == -1) {
                    let properties = { "name": item.customerId, "id": customerId.length + 1 }; customerId.push(properties);
                }
            }
            else {
                let properties = {
                    "name": item.customerId, "id": item.id
                }; customerId.push(properties);
            }
            if (customerProjectName.length >= 1) {
                let index = customerProjectName.findIndex(index => index.name == item.customerProjectName);
                if (index == -1) {
                    let properties = { "name": item.customerProjectName, "id": customerId.length + 1 }; customerProjectName.push(properties);
                }
            }
            else {
                let properties = {
                    "name": item.customerProjectName, "id": item.id
                }; customerProjectName.push(properties);
            }

            if (createDate.length >= 1) {
                let index = createDate.findIndex(index => index.name == item.createDate);
                if (index == -1) {
                    let properties = { "name": item.createDate, "id": createDate.length + 1 }; createDate.push(properties);
                }
            }
            else {
                let properties = {
                    "name": item.createDate, "id": item.id
                }; createDate.push(properties);
            }
            if (createUser.length >= 1) {
                let index = createUser.findIndex(index => index == parseInt(item.createUser));
                if (index == -1 && parseInt(item.createUser)) {
                    createUser.push(parseInt(item.createUser));
                }
            }
            else {
                if (parseInt(item.createUser))
                    createUser.push(parseInt(item.createUser));
            }
            if (projectName.length >= 1) {
                let index = projectName.findIndex(index => index.name == item.projectName);
                if (index == -1) {
                    let properties = { "name": item.projectName, "id": projectName.length + 1 }; projectName.push(properties);
                }
            }
            else {
                let properties = {
                    "name": item.projectName, "id": item.id
                }; projectName.push(properties);
            }
            if (siteCode.length >= 1) {
                let index = siteCode.findIndex(index => index.name == item.siteCode);
                if (index == -1) {
                    let properties = { "name": item.siteCode, "id": siteCode.length + 1 }; siteCode.push(properties);
                }
            }
            else {
                let properties = {
                    "name": item.siteCode, "id": item.id
                }; siteCode.push(properties);
            }
            if (rejectDate.length >= 1) {
                let index = rejectDate.findIndex(index => index.name == item.rejectDate);
                if (index == -1) {
                    if (item.rejectDate != null && item.rejectDate != "") {
                        let properties = { "name": item.rejectDate, "id": rejectDate.length + 1 }; rejectDate.push(properties);
                    }
                }
            }
            else {
                let properties = {
                    "name": item.rejectDate, "id": item.id
                }; rejectDate.push(properties);
            }
            if (approveDate.length >= 1) {
                let index = approveDate.findIndex(index => index.name == item.approveDate);
                if (index == -1) {
                    if (item.approveDate != null && item.approveDate != "") {
                        let properties = { "name": item.approveDate, "id": approveDate.length + 1 }; approveDate.push(properties);
                    }
                }
            }
            else {
                let properties = {
                    "name": item.approveDate, "id": item.id
                }; approveDate.push(properties);
            }

            if (sessionStatus.length >= 1) {
                let s = "";
                switch (item.status) {
                    case 1:
                        s = "Draft";
                        break;
                    case 2:
                        s = "Created";
                        break;
                    case 3:
                        s = "Submitted";
                        break;
                    case 4:
                        s = "Rejected";
                        break;
                    case 5:
                        s = "Approved";
                        break;
                    case 7:
                        s = "Ready For Submisson";
                        break;
                    case 8:
                        s = "Accepted Without OIL";
                        break;
                    case 9:
                        s = "Accepted with OIL ";
                        break;
                    case 10:
                        s = "Accepted Conditionally ";
                        break;
                    default:
                        s = "";
                }
                let index = sessionStatus.findIndex(index => index.name == s);
                if (index == -1) {

                    let properties = { "name": s, "id": sessionStatus.length + 1 }; sessionStatus.push(properties);
                }
            }
            else {
                let s = "";
                switch (item.status) {
                    case 1:
                        s = "Draft";
                        break;
                    case 2:
                        s = "Created";
                        break;
                    case 3:
                        s = "Submitted";
                        break;
                    case 4:
                        s = "Rejected";
                        break;
                    case 5:
                        s = "Approved";
                        break;
                    case 7:
                        s = "Ready For Submisson";
                        break;
                    case 8:
                        s = "Accepted Without OIL";
                        break;
                    case 9:
                        s = "Accepted with OIL ";
                        break;
                    case 10:
                        s = "Accepted Conditionally ";
                        break;
                    default:
                        s = "";
                }
                let properties = {
                    "name": s, "id": item.id
                }; sessionStatus.push(properties);
            }

            if (customerScopeId.length >= 1) {
                let index = customerScopeId.findIndex(index => index.name == item.customerScopeIdentification);
                if (index == -1) {
                    if (item.customerScopeId != null && item.customerScopeId != "") {
                        let properties = { "name": item.customerScopeIdentification, "id": customerScopeId.length + 1 }; customerScopeId.push(properties);
                    }
                }
            }
            else {
                let properties = {
                    "name": item.customerScopeIdentification, "id": item.id
                }; customerScopeId.push(properties);
            }
            if (scopeWorkDescription.length >= 1) {
                let index = scopeWorkDescription.findIndex(index => index.name == item.scopeOfWorkDescription);
                if (index == -1) {
                    if (item.scopeOfWorkDescription != null && item.scopeOfWorkDescription != "") {
                        let properties = { "name": item.scopeOfWorkDescription, "id": scopeWorkDescription.length + 1 }; scopeWorkDescription.push(properties);
                    }
                }
            }
            else {
                let properties = {
                    "name": item.scopeOfWorkDescription, "id": item.id
                }; scopeWorkDescription.push(properties);
            }
            if (projectId.length >= 1) {
                let index = projectId.findIndex(index => index.name == item.ProjectId);
                if (index == -1) {
                    let properties = { "name": item.ProjectId, "id": projectId.length + 1 }; projectId.push(properties);
                }
            }
            else {
                let properties = {
                    "name": item.ProjectId, "id": item.id
                }; projectId.push(properties);
            }

            if (rating.length >= 1) {
                let index = rating.findIndex(index => index.name == item.rating);
                if (index == -1) {
                    if (item.rating != "" && item.rating != null) {
                        let properties = { "name": item.rating, "id": rating.length + 1 }; rating.push(properties);
                    }
                }
            }
            else {
                let properties = {
                    "name": item.rating, "id": item.id
                }; rating.push(properties);
            }
            if (submitDate.length >= 1) {
                let index = submitDate.findIndex(index => index.name == item.submitDate);
                if (index == -1) {
                    if (item.submitDate != null && item.submitDate != "") {
                        let properties = { "name": item.submitDate, "id": submitDate.length + 1 }; submitDate.push(properties);
                    }
                }
            }
            else {
                let properties = {
                    "name": item.submitDate, "id": item.id
                }; submitDate.push(properties);
            }
            if (updateDate.length >= 1) {
                let index = updateDate.findIndex(index => index.name == item.updateDate);
                if (index == -1) {
                    if (item.updateDate != null && item.updateDate != "") {
                        let properties = { "name": item.updateDate, "id": updateDate.length + 1 }; updateDate.push(properties);
                    }
                }
            }
            else {
                let properties = {
                    "name": item.updateDate, "id": item.id
                }; updateDate.push(properties);
            }
            if (updateUser.length >= 1) {
                let index = updateUser.findIndex(index => index == parseInt(item.updateUser));
                if (index == -1 && parseInt(item.updateUser)) {
                    updateUser.push(parseInt(item.updateUser));
                }
            }
            else {
                if (parseInt(item.updateUser))
                    updateUser.push(parseInt(item.updateUser));
            }
            if (submitUser.length >= 1) {
                let index = submitUser.findIndex(index => index == parseInt(item.submitUser));
                if (index == -1 && parseInt(item.submitUser)) {
                    submitUser.push(parseInt(item.submitUser));
                }
            }
            else {
                if (parseInt(item.submitUser))
                    submitUser.push(parseInt(item.submitUser));
            }

        });

        switch (rowData.name) {
            case "Rating": this.setState({
                options: rating,
                openFilterDialog: true,
                rowData: rowData,

            }); break;





            case "Project ID": this.setState({
                options: projectId,
                openFilterDialog: true,
                rowData: rowData,

            }); break;
            case "Customer Scope Identification": this.setState({
                options: customerScopeId,
                openFilterDialog: true,
                rowData: rowData,

            }); break;
            case "Submit Date": this.setState({
                options: submitDate,
                openFilterDialog: true,
                rowData: rowData,

            }); break;
            case "Update Date": this.setState({
                options: updateDate,
                openFilterDialog: true,
                rowData: rowData,

            }); break;
            case "Submit User": ApiClient.post("User/GetListOfUsersByID", submitUser)
                .then(response => {
                    let submitUsers = []
                    response.data.map(u => { let pros = { "name": u, "id": submitUsers.length }; submitUsers.push(pros); })
                    this.setState({
                        options: submitUsers,
                        openFilterDialog: true,
                        rowData: rowData,

                    });
                }); break;

            case "Update User": ApiClient.post("User/GetListOfUsersByID", updateUser)
                .then(response => {
                    let updateUsers = []
                    response.data.map(u => { let pros = { "name": u, "id": updateUsers.length }; updateUsers.push(pros); })
                    this.setState({
                        options: updateUsers,
                        openFilterDialog: true,
                        rowData: rowData,

                    });
                }); break;

            case "Approve User":
                ApiClient.post("User/GetListOfUsersByID", approveUser)
                    .then(response => {

                        this.setState({
                            options: response.data,
                            openFilterDialog: true,
                            rowData: rowData,

                        });
                    })
                break;

            case "Create User": ApiClient.post("User/GetListOfUsersByID", createUser)
                .then(response => {
                    let createUsers = []
                    response.data.map(u => { let pros = { "name": u, "id": createUsers.length }; createUsers.push(pros); })
                    this.setState({
                        options: createUsers,
                        openFilterDialog: true,
                        rowData: rowData,

                    });
                })
                break;
            case "Reject User": ApiClient.post("User/GetListOfUsersByID", rejectUser)
                .then(response => {
                    let rejectUsers = []
                    response.data.map(u => { let pros = { "name": u, "id": rejectUsers.length }; rejectUsers.push(pros); })
                    this.setState({
                        options: rejectUsers,
                        openFilterDialog: true,
                        rowData: rowData,

                    });
                })
                break;

            case "Scope Of Work Description": this.setState({
                options: scopeWorkDescription,
                openFilterDialog: true,
                rowData: rowData,

            }); break;
            case "Project ID": this.setState({
                options: projectId,
                openFilterDialog: true,
                rowData: rowData,

            }); break;
            case "Customer Name": this.setState({
                options: customerNamee,
                openFilterDialog: true,
                rowData: rowData,
            }); break;
            case "Customer ID": this.setState({
                options: customerId,
                openFilterDialog: true,
                rowData: rowData,

            });
                break;
            case "Customer Project Name": this.setState({
                options: customerProjectName,
                openFilterDialog: true,
                rowData: rowData,

            });
                break;
            case "Create Date": this.setState({
                options: createDate,
                openFilterDialog: true,
                rowData: rowData,

            });
                break;

            case "Session Status": this.setState({
                options: sessionStatus,
                openFilterDialog: true,
                rowData: rowData,

            });
                break;
            case "Project Name": this.setState({
                options: projectName,
                openFilterDialog: true,
                rowData: rowData,

            });
                break;
            case "Site Code": this.setState({
                options: siteCode,
                openFilterDialog: true,
                rowData: rowData,

            });
                break;
            case "Reject Date": this.setState({
                options: rejectDate,
                openFilterDialog: true,
                rowData: rowData,

            });
            case "Approve Date": this.setState({
                options: approveDate,
                openFilterDialog: true,
                rowData: rowData,

            });
                break;
            default: this.setState({
                options: [],
                openFilterDialog: true,
                rowData: rowData,

            });
        }
        this.props.report(this.state.reportField, this.state.reportName, this.state.reportToBeUpdatedID, true);
        ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/EditReportCols", { ReportID: this.state.reportToBeUpdatedID, Fields: this.state.reportField });

    }

    setCriteria = () => {
        this.setState({
            criteriaList: this.state.criteria,
            openFilterDialog: false
        })
        let criteria = "";
        this.state.criteria[0].forEach(item => criteria = criteria + item.name + ", ")

        this.state.reportField[this.state.rowData.tableData.id].criteria = criteria;
        ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/EditReportCols", { ReportID: this.state.reportToBeUpdatedID, Fields: this.state.reportField });

    }
    getCriteria = (criteria) => {
        this.setState({
            criteria: criteria
        })

    }

    RenameReport = () => {
        if (!this.state.reportNewName) {
            toast.error("Please enter a new report name", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });

        }
        else {
            this.setState({
                selectReportId: { text: this.state.reportNewName, value: this.state.reportToBeUpdatedID },
                openDialogRename: false,
            })
            ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/RenameSessionReport", { Id: this.state.reportToBeUpdatedID, Name: this.state.reportNewName }

            ).then(response => {
                if (response.data == true) {
                    toast.success("Report name changed Successfully", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
                    ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GetAllReports", { Id: parseInt(this.props.userData.userData.id) })
                        .then(response => {
                            if (response.data != null) {

                                this.setState({
                                    reportssValue: response.data,
                                    cols: response.data.map(item => {
                                        let properties = {
                                            "column": item.sessionReportColumns
                                        }; return properties;
                                    }),
                                    enableSelect: false,
                                    showInputField: false,
                                    enableAddbtn: false,
                                })

                                // this.setState({ fieldsAv: this.state.reportField })
                            }
                            let reports = this.state.reportssValue.map(item => {
                                let properties = { "value": item.id, "text": item.name }; return properties;
                            });
                            this.setState({
                                reportsValue: reports
                            })
                        })
                }
            })

        }
    }

    delete = () => {
        this.setState({
            openDialogDelete: false,
            reportField: [{ name: "Session ID", property: "Id", tableData: { id: 0 } }, { name: "Session Name", property: "Name", tableData: { id: 1 } }]

        })
        ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/DeleteSessionReport", { Id: this.state.reportToBeUpdatedID }

        ).then(response => {
            if (response.data == true) {
                toast.success("Report is deleted Successfully", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
                ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/GetAllReports", { Id: parseInt(this.props.userData.userData.id) })
                    .then(response => {
                        if (response.data != null) {
                            this.setState({
                                reportssValue: response.data,
                                cols: response.data.map(item => {
                                    let properties = {
                                        "column": item.sessionReportColumns
                                    }; return properties;
                                }),
                                enableSelect: false,
                                showInputField: false,
                                enableAddbtn: false,
                                enableDelRename: false,
                                selectReportId: ""
                            })

                            // this.setState({ fieldsAv: this.state.reportField })
                        }
                        let reports = this.state.reportssValue.map(item => {
                            let properties = { "value": item.id, "text": item.name }; return properties;
                        });
                        this.setState({
                            reportsValue: reports
                        })
                    })
            }
        })
    }

    editReport = () => {
        if (this.state.reportName == "" || this.state.reportName == null) {
            toast.error("Please select a report", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });

        }
        else {
            toast.success("Report is being saved", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
            ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/EditReportCols", { ReportID: this.state.reportToBeUpdatedID, Fields: this.state.reportField }
            ).then(response => {
                if (response.data) {
                    toast.success("Report updated successfully", { containerId: 'MS', position: toast.POSITION.TOP_CENTER });
                    this.setState({
                        enableSelect: false,
                        filterHide: true,
                        enableEditbtn: false,

                    })
                    this.props.report(this.state.reportField, this.state.reportName, this.state.reportToBeUpdatedID);


                }
            })
        }
    }
    selectionChange = (rows) => {
        var fields = this.state.reportField;
        this.setState({ rowData: rows, reportChanged: true });
        if (rows.length > this.state.reportField.length - 3) {
            rows.forEach(r => {
                let index = this.state.reportField.findIndex(i => i.name == r.name);
                if (index == -1) {
                    this.state.reportField.push(r);
                }
            })
            fields = this.state.reportField;
        }
        else {
            this.state.reportField.forEach(f => {
                if (f.name != "Session Name" && f.name != "Session ID") {
                    let index = rows.findIndex(i => i.name == f.name);
                    if (index == -1) {
                        this.setState({
                            reportField: this.state.reportField.filter(item => item.name != f.name)

                        })
                        fields = this.state.reportField.filter(item => item.name != f.name);
                    }
                }
            })
        }
        this.props.report(this.state.reportField, this.state.reportName, this.state.reportToBeUpdatedID, true);
        ApiClient.post("https://rsc-eritop.azurewebsites.net/api/Report/EditReportCols", { ReportID: this.state.reportToBeUpdatedID, Fields: fields });

    }

    sortDown = (event, rowData) => {
        this.setState({ reportChanged: true })
        let x = rowData.tableData.id;
        if (x < this.state.reportField.length - 1) {
            const temp = this.state.reportField;
            const row = temp[x]
            temp[x] = temp[x + 1];
            temp[x + 1] = row
            this.setState({
                reportField: temp
            })
        }
        this.props.report(this.state.reportField, this.state.reportName, this.state.reportToBeUpdatedID, true);

    }
    sortUp = (event, rowData) => {
        this.setState({ reportChanged: true })
        let x = rowData.tableData.id;
        if (x > 0) {
            const temp = this.state.reportField;
            const row = temp[x - 1]
            temp[x - 1] = temp[x];
            temp[x] = row
            this.setState({
                reportField: temp
            })
        }
        this.props.report(this.state.reportField, this.state.reportName, this.state.reportToBeUpdatedID, true);

    }
    render() {
        return (
            <EuiRow style={{ width: "800px", height: "600px" }}>
                <div className="column sm-12 md-6 lg-6 xl-6 filled" hidden={this.state.showInputField}>
                    <FormField style={{ marginTop: "30px" }} >
                        <AutoCompleteSelect
                            //disabled={this.state.session.customerId.value > 0 ? false : true}
                            id="reports"
                            name="Reports"
                            // style={{ width: "50%" }}
                            options={this.state.reportsValue}
                            onChange={e => this.handleChange(e)}
                            value={this.state.selectReportId}
                        />
                    </FormField>
                </div>

                <div className="column sm-12 md-6 lg-6 xl-6 filled" style={{ padding: "35px" }}>
                    <button className="btn primary" onClick={() => this.setState({ openDialogAdd: true })}>Create New</button>
                    <button className="btn primary" disabled={!this.state.enableDelRename} onClick={() => { this.setState({ openDialogRename: true }) }} >Rename</button>
                    <button className="btn primary" disabled={!this.state.enableDelRename} onClick={() => { this.setState({ openDialogDelete: true }) }}>Delete</button>
                    {/*  <button className="btn primary">Copy</button>*/}
                </div>
                <EuiRow style={{ width: "700px" }}>
                    <div className="column sm-12 md-6 lg-6 xl-6 filled" >

                        <Table
                            title="Available Fields"
                            columns={this.state.availableFields}
                            data={this.state.fieldsAv}
                            options={{
                                search: false,
                                selection: this.state.enableSelect,
                                showTextRowsSelected: true,
                                minBodyHeight: 500,
                                paging: false,
                                selectionProps: rowData =>
                                    ({
                                        color: "primary",
                                        style: {
                                            padding: 3,
                                            marginLeft: 17
                                        }
                                    })
                            }}
                            onSelectionChange={(rows) => this.selectionChange(rows)}
                        //                            onSelectionChange={(rows) => { this.onSelecting(rows) }}

                        >
                        </Table>
                    </div >
                    <div className="column sm-12 md-6 lg-6 xl-6 filled" >
                        <Table
                            style={{ height: "506px" }}
                            actions={[
                                {
                                    icon: (rowData) => <i className="icon icon-filter" style={{ fontSize: 19 }} hidden={this.state.filterHide}></i>,
                                    tooltip: "Filter",
                                    onClick: (event, rowData) => this.handleFilter(event, rowData)
                                    //isFreeAction: true

                                },
                                {
                                    icon: (rowData) => <i className="icon icon-sort-up" style={{ fontSize: 19 }} hidden={this.state.filterHide}></i>,
                                    tooltip: "Move up",
                                    onClick: (event, rowData) => this.sortUp(event, rowData)
                                },
                                {
                                    icon: (rowData) => <i className="icon icon-sort-down" style={{ fontSize: 19 }} hidden={this.state.filterHide}></i>,
                                    tooltip: "Move down",
                                    onClick: (event, rowData) => this.sortDown(event, rowData)
                                }
                            ]}
                            title="Report Fields"
                            columns={this.state.fieldsReport}
                            data={this.state.reportField}
                            options={{
                                search: false,
                                // selection: true,
                                showTextRowsSelected: false,
                                minBodyHeight: 500,
                                //  filtering: true,
                                paging: false,
                                selectionProps: rowData =>
                                    ({
                                        color: "primary",
                                        style: {
                                            padding: 3,
                                            marginLeft: 17
                                        }
                                    })
                            }}
                            onSelectionChange={(rows) => { this.selectionChange(rows) }}

                        >
                        </Table>
                    </div>
                </EuiRow>
                <DialogModal
                    style={{ width: "500px" }}
                    buttonName="Set Filter"
                    content={<Filter options={this.state.options} criteria={this.getCriteria} />}
                    handleClose={() => this.setState({ openFilterDialog: false })}
                    id="CriteriaDialog"
                    onClick={this.setCriteria}
                    open={this.state.openFilterDialog}
                    title={(this.state.rowData.length == 0 ? "" : this.state.rowData.name + " Criteria")}
                    titleId="filterTitle"
                >
                </DialogModal>
                <DialogModal
                    buttonName="Rename"
                    content={<input style={{ width: "300px" }} value={this.state.reportNewName} onChange={(e) => { this.setState({ reportNewName: e.target.value }) }} />}
                    handleClose={() => this.setState({ openDialogRename: false })}
                    open={this.state.openDialogRename}
                    id="rename"
                    onClick={this.RenameReport}
                    title="Rename Report"
                    titleId="renameTitle"
                ></DialogModal>
                <DialogModal
                    buttonName="Create"

                    content={<input style={{ width: "300px" }} onChange={(e) => { this.setState({ reportName: e.target.value }) }} />}
                    handleClose={() => this.setState({ openDialogAdd: false })}
                    open={this.state.openDialogAdd}
                    id="add"
                    onClick={this.Add}
                    title="New Report"
                    titleId="newTitle"
                ></DialogModal>
                <DialogModal
                    buttonName="Delete"
                    content={<p>you sure you want to delete the report?</p>}
                    handleClose={() => this.setState({ openDialogDelete: false })}
                    open={this.state.openDialogDelete}
                    id="delete"
                    onClick={this.delete}
                    title="Delete Report"
                    titleId="deleteTitle"
                ></DialogModal>

            </EuiRow >
        );
    }
}

const FormField = (props) => (
    <div style={{ margin: 10, ...props.style }} className="field validate">
        {props.children}
    </div>
);


const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, sessionData: state.session });
const mapDispatchToProps = dispatch => {
    return {
        sessionReport: (reportFields, reportName) => dispatch({ type: "SESSION_REPORT", payload: { reportFields, reportName } }),
        setAllSession: allSession => dispatch({ type: "SET_ALL_SESSION", payload: allSession }),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SessionReport);
