import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import EuiTextField from "../../../eui-components/textfield";
import { ToastContainer, toast } from 'react-toastify';
import FormField from "../../../eui-components/formfield";
import AutoCompleteSelect from "../../../components/autocompleteselect";
import BpmnModelerComponent from "./bpmnModelerComponent";
import ApiClient from "../../../utils/api-client";
import DialogModal from "../../../components/dialog";
import WorkflowStepsMailDialog from "./workflowStepsMailDialog";
import WorkflowHelper from "../../../utils/workflow-helper";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BpmnViewerComponent from "./bpmnViewerComponent";
import DateUtils from "../../../utils/date-utils";
import CustomFooter from "../../../components/customFooter";


class CreateEditWorkflow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            workflow: { id: 0, countryId: { value: 0, text: "---Select Country---" }, customerId: { value: 0, text: "---Select Customer---" }, default: false, diagramXml: "" },
            workflowSteps: [],
            countries: [],
            customers: [],
            openSaveWorkflow: false,
        }
    }

    componentDidMount() {
        ApiClient.post("User/GetAllCountries")
            .then(response => {
                if (response) {
                    this.setState({ countries: [{ value: 0, text: "---Select Country---" }, ...response.data] }, () => {
                        if (this.props.workflow && this.props.workflow.id !== 0) {
                            this.getPropsAndSteps();
                        }
                    });
                }
            })
            .catch(error => {
                console.error(error);;
            });
    }

    getPropsAndSteps = () => {
        ApiClient.post("Workflow/GetWorkflowStepsOfWorkflow", { id: this.props.workflow.id })
            .then(response => {
                const workflowSteps = response.data;
                const customers = this.getCustomers(this.props.workflow.countryId);
                this.setState({ customers, workflow: Object.assign(this.state.workflow, this.props.workflow), workflowSteps });
            })
            .catch(error => {
                console.error(error);;
            });
    }

    getCustomers = (countryId) => {
        return (this.state.countries.find(item => item.value === countryId) || {}).customers ?? [];
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const checked = target.checked;

        if (name === "countryId") {
            const customers = [{ value: 0, text: "---Select Customer---" }, ...this.getCustomers(value.value)];
            this.setState(prevState => ({
                customers,
                workflow: {
                    ...prevState.workflow,
                    [name]: value,
                    customerId: { value: 0, text: "---Select Customer---" }
                }
            }));
        } else {
            if (target.type === "checkbox") {
                this.setState(prevState => ({
                    workflow: {
                        ...prevState.workflow,
                        [name]: checked
                    }
                }));
            } else {
                this.setState(prevState => ({
                    workflow: {
                        ...prevState.workflow,
                        [name]: value
                    }
                }));
            }
        }
    }

    openWorkflow = () => {
        if (this.state.workflow.status === 1) {
            this.saveWorkflowDraft();
        } else if (this.state.workflow.status === 3) {
            this.setState({ openSaveWorkflow: true });
        }
    }

    saveWorkflow = () => {
        const steps = [...this.state.workflowSteps];
        let message = "";
        steps.some(step => {
            if (step.workflowStepApproverUsers.length === 0) {
                message = "At least one approver person must be selected!";
                return;
            }
        });
        if (message) {
            toast.error(message, { containerId: 'W', position: toast.POSITION.TOP_CENTER });
            return;
        }       
        this.setState({ openSaveWorkflow: false }, () => {          
            this.props.setIsLoading();
            if (this.state.workflow.status === 3) {
                this.saveWorkflowInProgress();
            } else if (this.state.workflow.status === 1) {
                this.releaseWorkflow();
            }           
        });
    }   

    getTitle = () => {
        return WorkflowHelper.getTitle(this.props.editMod);
    }

    checkFields = (workflow) => {
        if (!workflow.name || !workflow.countryId.value || !workflow.customerId.value) {
            toast.error("Please, fill the required fields!", { containerId: 'W', position: toast.POSITION.TOP_CENTER });
            return false;
        } 
        return true;
    }

    async releaseWorkflow() {
        try {
            const result = await WorkflowHelper.modeler.saveXML({ format: true });
            const { xml } = result;
            
            
            

            const workflow = { ...this.state.workflow };
            workflow.diagramXml = xml;
            workflow.workflowSteps = this.state.workflowSteps;
            workflow.countryId = this.state.workflow.countryId.value;
            workflow.customerId = this.state.workflow.customerId.value;
            workflow.updateDate = new Date();
            workflow.updateUser = this.props.userData.userData.userId;
            workflow.status = 2;

            
            ApiClient.post("Workflow/EditWorkflow", workflow)
                .then(response => {
                    if (response.data) {
                        this.props.setIsLoading();
                        this.props.changeMod();
                        this.props.getWorkflows();
                        toast.success(workflow.name + " workflow released succesfully", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
                    } else {
                        this.props.setIsLoading();
                        toast.error("Some problems occured! Please try later!", { containerId: 'W', position: toast.POSITION.TOP_CENTER });
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ openSaveWorkflow: false }, () => this.props.setIsLoading());

                });
        } catch (err) {
            toast.error("Some problems occur, while creating diagram xml", { containerId: 'W', position: toast.POSITION.TOP_CENTER });
            
        }
    }

    async createWorkflowDraft() {
        const workflow = { ...this.state.workflow };
        if (!this.checkFields(workflow)) {
            return;
        }
        this.props.setIsLoading();
        try {
            const result = await WorkflowHelper.modeler.saveXML({ format: true });
            const { xml } = result;
            
            
            workflow.diagramXml = xml;
            workflow.countryId = this.state.workflow.countryId.value;
            workflow.customerId = this.state.workflow.customerId.value;
            workflow.createDate = new Date();
            workflow.createUser = this.props.userData.userData.userId;
            workflow.status = 1;

            
            if (!workflow) {
                this.props.setIsLoading();
                toast.error("Some problems occured! Please try later!", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
                return;
            }
            ApiClient.post("Workflow/AddWorkflow", workflow)
                .then(response => {
                    if (response.data) {
                        this.props.setIsLoading();
                        this.props.changeMod();
                        this.props.getWorkflows();
                        toast.success("New workflow submitted succesfully", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
                    } else {
                        this.props.setIsLoading();
                        toast.error("Can't create new workflow with the same name! Please change the name.", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
                    }
                })
                .catch(error => {
                    console.error(error);;
                    this.props.setIsLoading();

                });
        } catch (err) {
            this.props.setIsLoading();
            toast.error("Some problems occur, while creating diagram xml", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
            
        }
    }

    async saveWorkflowDraft() {
        const workflow = { ...this.state.workflow };
        if (!this.checkFields(workflow)) {
            return;
        }
        this.props.setIsLoading();
        try {
            const result = await WorkflowHelper.modeler.saveXML({ format: true });
            const { xml } = result;
            
            
            workflow.diagramXml = xml;
            workflow.countryId = workflow.countryId.value;
            workflow.customerId = workflow.customerId.value;
            workflow.updateDate = new Date();
            workflow.updateUser = this.props.userData.userData.userId;

            
            this.editWorkflow(workflow);
        } catch (err) {
            this.props.setIsLoading();
            toast.error("Some problems occur, while creating diagram xml", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
            
        }
    }

    saveWorkflowInProgress = () => {
        const workflow = { ...this.state.workflow };
        workflow.workflowSteps = this.state.workflowSteps;
        workflow.countryId = this.state.workflow.countryId.value;
        workflow.customerId = this.state.workflow.customerId.value;
        workflow.updateDate = new Date();
        workflow.updateUser = this.props.userData.userData.userId;

        
        this.editWorkflow(workflow);
    }

    editWorkflow = (workflow) => {
        if (!workflow) {
            this.props.setIsLoading();
            toast.error("Some problems occured! Please try later!", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
            return;
        }
        ApiClient.post("Workflow/EditWorkflow", workflow)
            .then(response => {
                if (response.data) {
                    this.props.changeMod();
                    this.props.setIsLoading();
                    this.props.getWorkflows();
                    toast.success(workflow.name + " workflow updated succesfully", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
                } else {
                    this.props.setIsLoading();
                    toast.error("Can't edit workflow with the same name! Please change the name.", { containerId: 'WL', position: toast.POSITION.TOP_CENTER });
                }
            })
            .catch(error => {
                console.error(error);;
                this.props.setIsLoading();
            });
    }

    setWorkflowSteps = (workflowSteps) => {
        this.setState({ workflowSteps });
    }

    setWorkflowStepAndOpenDialog = () => {
        const startEvent = WorkflowHelper.getElements('bpmn:StartEvent');
        if (startEvent.length === 0 || startEvent.length > 1) {
            toast.error("There must be one Start Event for workflow!", { containerId: 'W', position: toast.POSITION.TOP_CENTER });
            return;
        }
        const endEvent = WorkflowHelper.getElements('bpmn:EndEvent');
        if (endEvent.length === 0 || endEvent.length > 1) {
            toast.error("There must be one End Event for workflow!", { containerId: 'W', position: toast.POSITION.TOP_CENTER });
            return;
        }
        const tasks = WorkflowHelper.getElements('bpmn:Task');
        if (tasks.length === 0) {
            toast.error("There should be at least 1 task and 1 gateway for workflow diagram!", { containerId: 'W', position: toast.POSITION.TOP_CENTER });
            return;
        }           
        const errMessage = WorkflowHelper.checkTaskOutgoings(tasks);
        if (errMessage) {
            toast.error(errMessage, { containerId: 'W', position: toast.POSITION.TOP_CENTER });
            return;
        }
        const workflowSteps = WorkflowHelper.getWorkflowStepsFromTask(tasks, this.props.userData.userData.userId, this.state.workflow.id);
        this.setState({ workflowSteps }, () => {
            this.setState({ openSaveWorkflow: true })
        });
    }

    handleWorkflowAction = () => {
        if (this.props.editMod === 0) {
            this.createWorkflowDraft();
        } else if (this.props.editMod === 1) {
            this.openWorkflow();
        } else if (this.props.editMod === 2) {
            this.setState({ openSaveWorkflow:true});
        }
    }

    isViewerWorkflow = () => {
        return this.props.workflow.status === 3 || this.props.editMod === 2;
    }

    render() {
        return (
            <EuiRow>
                <EuiRow style={this.props.editMod === 2 ? { pointerEvents: "none", opacity: "0.6" } : {}}>
                    <div className="column sm-12 md-5 lg-5 xl-5 filled" >
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <FormField style={{width:"70%"}}>
                                <EuiTextField
                                    label="Workflow Name*"
                                    name="name"
                                    fullwidth="true"
                                    required
                                    value={this.state.workflow.name || ""}
                                    onChange={e => this.handleChange(e)}
                                />
                            </FormField>
                            <FormField>
                                <FormControlLabel
                                    style={{ display: "flex", paddingTop: 15 }}
                                    control={
                                        <Checkbox
                                            checked={this.state.workflow.default}
                                            onChange={e => this.handleChange(e)}
                                            name="default"
                                            color="primary"
                                        />
                                    }
                                    label={<span style={{ fontSize: 14 }}>Is Default</span>}
                                />
                            </FormField>                          
                        </div>
                        <FormField>
                            <label htmlFor="workflowDescription">Description </label>
                            <textarea placeholder="Description"
                                id="workflowDescription"
                                className="fullwidth"
                                label="Description"
                                name="description"
                                value={this.state.workflow.description || ""}
                                onChange={e => this.handleChange(e)}
                            />

                        </FormField>
                    </div>
                    <div className="column sm-12 md-3 lg-3 xl-3 filled" >
                        <FormField >
                            <label htmlFor="workflowCountry">Country*</label>
                            <AutoCompleteSelect
                                disabled={this.props.workflow.status === 3}
                                id="workflowCountry"                                
                                name="countryId"
                                options={this.state.countries}
                                value={this.state.workflow.countryId}
                                onChange={e => this.handleChange(e)}
                            />
                        </FormField>
                        <FormField>
                            <label htmlFor="workflowCustomer">Customer*</label>
                            <AutoCompleteSelect
                                disabled={this.props.workflow.status === 3}
                                id="workflowCustomer"                               
                                name="customerId"
                                options={this.state.customers}
                                value={this.state.workflow.customerId}
                                onChange={e => this.handleChange(e)}
                            />
                        </FormField>
                    </div>
                    {this.props.editMod === 2 ?
                        <div className="column sm-12 md-4 lg-4 xl-4 filled" >
                            <FormField style={{paddingTop:8}}>
                                <EuiTextField
                                    label="Owner"
                                    name="name"
                                    fullwidth="true"                                    
                                    value={this.state.workflow.createUser || ""}
                                    readOnly
                                />
                            </FormField>
                            {
                                //<FormField >
                                //    <EuiTextField
                                //        label="Update User"
                                //        name="name"
                                //        fullwidth="true"
                                //        required
                                //        value={this.state.workflow.updateUser || ""}
                                //    />
                                //</FormField>
                            }
                            <FormField style={{ paddingTop: 9 }} >
                                <EuiTextField
                                    label="Update Date"
                                    name="name"
                                    fullwidth="true"                                    
                                    value={DateUtils.toDateString(this.state.workflow.updateDate, "YYYY-MM-DD HH:mm:ss") || ""}
                                    readOnly
                                />
                            </FormField>
                        </div>
                        :
                        null
                    }
                </EuiRow>

                <EuiRow style={(this.isViewerWorkflow() ? { pointerEvents: "none", opacity: "0.6" } : {})}>
                    {this.isViewerWorkflow()
                        ?
                        <BpmnViewerComponent diagramXml={this.props.workflow.diagramXml} />
                        :
                        <BpmnModelerComponent editMod={this.props.editMod} diagramXml={this.props.workflow.diagramXml} />
                    }
                </EuiRow>

                <CustomFooter id="workflowFooter"                       
                        buttons={[
                            {
                                type: "btn primary",
                                name: this.getTitle(),
                                id: "workflowSave",
                                onClick: () => this.handleWorkflowAction(),
                                style: { margin: 5 }
                            },
                            {
                                type: "btn primary",
                                name: "Validate & Release",
                                id: "workflowRelease",
                                onClick: () => this.setWorkflowStepAndOpenDialog(),
                                style: { margin: 5 , minWidth:130},
                                display: this.state.workflow.status !== 1
                            }
                        ]}
                        buttonsLeft={[
                            {
                                type: "btn",
                                buttonType: "button",
                                name: "Cancel",
                                id: "cancelWorkflowEdit",
                                onClick: () => this.props.changeMod(),
                                style: { margin: 5 },
                            },
                        ]}
                    >
                </CustomFooter>


                {
                    //<EuiFooter id="workflowFooter"
                    //    style={{ paddingLeft: 5, paddingTop:5 }}
                    //    buttons={[
                    //        {
                    //            type: "btn primary",
                    //            name: this.getTitle(),
                    //            id: "workflowSave",
                    //            onClick: () => this.handleWorkflowAction(),
                    //            style: { margin: 5 }
                    //        },
                    //        {
                    //            type: "btn primary",
                    //            name: "Validate & Release",
                    //            id: "workflowRelease",
                    //            onClick: () => this.setWorkflowStepAndOpenDialog(),
                    //            style: { margin: 5 },
                    //            display: this.state.workflow.status !== 1
                    //        }
                    //    ]}
                    //    buttonsLeft={[
                    //        {
                    //            type: "btn",
                    //            buttonType: "button",
                    //            name: "Cancel",
                    //            id: "cancelWorkflowEdit",
                    //            onClick: () => this.props.changeMod(),
                    //            style: { margin: 5 },
                    //        },
                    //    ]}
                    //>
                    //</EuiFooter>
                }

                <DialogModal
                    buttonName="Confirm"
                    content={<WorkflowStepsMailDialog
                        workflowSteps={this.state.workflowSteps}
                        setWorkflowSteps={this.setWorkflowSteps}
                        viewOnly={this.props.editMod === 2}
                        customerId={this.state.workflow.customerId.value ?? this.state.workflow.customerId.id}
                    />}
                    handleClose={() => this.setState({ openSaveWorkflow: false })}
                    id="saveWorkflowId"
                    onClick={this.saveWorkflow}
                    open={this.state.openSaveWorkflow}
                    title="Manage Workflow Receipent"
                    titleId="saveWorkflowTitle"
                    buttonsVisible={this.props.editMod === 2 ? "false" : ""}
                >
                </DialogModal>

                <ToastContainer enableMultiContainer containerId={'W'} autoClose={2500} />
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEditWorkflow);
