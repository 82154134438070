import { OpenVidu } from 'openvidu-browser';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import UserVideoComponent from './userVideoComponent';
import { connect } from 'react-redux';
import ApiClient from '../../../utils/api-client';
import { toast } from 'react-toastify';
import constants from '../../../utils/constants';


class OpvSessionCustom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mySessionId: this.props.liveStreamData.liveSessionInfo.sessionCode,
            myUserName: this.props.userData.userData.username,
            session: undefined,
            mainStreamManager: undefined,
        };

        this.joinSession = this.joinSession.bind(this);
        this.handleMainVideoStream = this.handleMainVideoStream.bind(this);
        this.onbeforeunload = this.onbeforeunload.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this)
        window.addEventListener('beforeunload', this.onbeforeunload);
        this.joinSession();
    }

    componentWillUnmount() {
        this.props.onRef(undefined)
        window.removeEventListener('beforeunload', this.onbeforeunload);
    }

    onbeforeunload(event) {
        this.leaveSession();
    }

    handleMainVideoStream(stream) {
        if (this.state.mainStreamManager !== stream) {
            this.setState({
                mainStreamManager: stream
            });
        }
    }

    deleteSubscriber(streamManager) {
        let subscribers = this.props.liveStreamData.subscribers;
        let index = subscribers.indexOf(streamManager, 0);
        
        if (index > -1) {
            subscribers.splice(index, 1);
            this.props.setSubscribers(subscribers);
        }
        if (JSON.parse(streamManager.stream.connection.data).clientData.includes(constants.streamerSuffix)) {
            this.leaveSession();

            if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION) {
                this.props.toggleLiveStreamMod(false);
                toast.error("Live Session was ended by the streamer. Please join new session.", { containerId: 'LS', position: toast.POSITION.TOP_CENTER });
            } else {
                this.props.setLiveSessionInfo({
                    type: this.props.liveStreamData.liveSessionInfo.type,
                    sessionCode: null,
                    latitude: "",
                    longitude: "",
                    streamingUserName: "",
                    streamingUserId: 0,
                    streamingId: 0,
                    sessionId: this.props.liveStreamData.liveSessionInfo.sessionId,
                    sessionName: this.props.liveStreamData.liveSessionInfo.sessionName,
                    siteName: this.props.liveStreamData.liveSessionInfo.siteName,
                    activityId: this.props.liveStreamData.liveSessionInfo.sessionId,
                });
                this.props.toggleSlidePopup();
            }
            return;
        }
    }

    joinSession() {
        this.OV = new OpenVidu();
        this.setState({ session: this.OV.initSession() },
            () => {
                var mySession = this.state.session;

                // --- 3) Specify the actions when events take place in the session ---

                // On every new Stream received...
                mySession.on('streamCreated', (event) => {
                    // Subscribe to the Stream to receive it. Second parameter is undefined
                    // so OpenVidu doesn't create an HTML video by its own
                    var subscriber = mySession.subscribe(event.stream, undefined);
                    
                    var subscribers = this.props.liveStreamData.subscribers;
                    subscribers.push(subscriber);

                    

                    // Update the state with the new subscribers
                    this.props.setSubscribers(subscribers);
                });

                // On every Stream destroyed...
                mySession.on('streamDestroyed', (event) => {

                    // Remove the stream from 'subscribers' array
                    this.deleteSubscriber(event.stream.streamManager);
                });

                // --- 4) Connect to the session with a valid user token ---              
                ApiClient.post("LiveSession/GenerateLiveSessionToken", { sessionId: this.props.liveStreamData.liveSessionInfo.sessionCode })
                    .then(response1 => {

                        if (response1.data === "") {
                            if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION) {
                                this.props.toggleLiveStreamMod(false);
                                toast.error("Live Session has already ended!", { containerId: 'LS', position: toast.POSITION.TOP_CENTER });
                                return;
                            } else {
                                this.props.setLiveSessionInfo({
                                    type: this.props.liveStreamData.liveSessionInfo.type,
                                    sessionCode: null,
                                    latitude: "",
                                    longitude: "",
                                    streamingUserName: "",
                                    streamingUserId: 0,
                                    streamingId: 0,
                                    sessionId: this.props.liveStreamData.liveSessionInfo.sessionId,
                                    sessionName: this.props.liveStreamData.liveSessionInfo.sessionName,
                                    siteName: this.props.liveStreamData.liveSessionInfo.siteName,
                                    activityId: this.props.liveStreamData.liveSessionInfo.sessionId,
                                    rscScope: this.props.liveStreamData.liveSessionInfo.rscScope
                                });
                                this.props.toggleSlidePopup();
                                return;
                            }
                        }
                        this.connectSession(response1.data, mySession);
                    }).catch(error => {
                        console.error(error);;
                    });
            },
        );
    }

    leaveSession = () => {
        // --- 7) Leave the session by calling 'disconnect' method over the Session object ---
        const mySession = this.state.session;

        if (mySession) {
            mySession.disconnect();
        }
        

        // Empty all properties...
        this.OV = null;
        this.setState({
            session: undefined,
            mySessionId: this.props.liveStreamData.liveSessionInfo.sessionCode,
            myUserName: this.props.userData.userData.username,
            mainStreamManager: undefined,
        }, () => {
            this.props.setPublisher(undefined);
            this.props.setSubscribers([]);
            
        });

    }

    connectSession = (token, mySession) => {
        // First param is the token got from OpenVidu Server. Second param can be retrieved by every user on event
        // 'streamCreated' (property Stream.connection.data), and will be appended to DOM as the user's nickname
        mySession.connect(token, { clientData: this.state.myUserName })
            .then(() => {

                // --- 5) Get your own camera stream ---

                // Init a publisher passing undefined as targetElement (we don't want OpenVidu to insert a video
                // element: we will manage it on our own) and with the desired properties
                let publisher = this.OV.initPublisher(undefined, {
                    audioSource: undefined, // The source of audio. If undefined default microphone
                    videoSource: undefined, // The source of video. If undefined default webcam
                    publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
                    publishVideo: false, // Whether you want to start publishing with your video enabled or not
                    resolution: '1280x720', // The resolution of your video
                    frameRate: 30, // The frame rate of your video
                    insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
                    mirror: false, // Whether to mirror your local video or not
                });


                // --- 6) Publish your stream ---

                mySession.publish(publisher);
                let mainStreamManager = publisher;
                this.props.liveStreamData.subscribers.map((sub, i) => {
                    if (JSON.parse(sub.stream.connection.data).clientData === this.props.liveStreamData.liveSessionInfo.streamingUserName) {
                        mainStreamManager = sub;
                    }
                });

                // Set the main video in the page to display our webcam and store our Publisher
                this.setState({
                    mainStreamManager,
                }, () => { this.props.setPublisher(publisher) });
            })
            .catch((error) => {
                
            });

    }

    render() {
        return (
            <div className="container">
                {this.state.session === undefined ?
                    null
                    :
                    <div id="session">
                        {this.state.mainStreamManager !== undefined ? (
                            <div id="main-video" className="w-100 h-100">
                                <UserVideoComponent streamManager={this.state.mainStreamManager} />
                            </div>
                        ) : null}
                        <div id="video-container" className="col-md-6">
                            <div style={{                                
                                alignItems: "center",
                                justifyContent: "flex-start",
                                overflow: "scroll",
                                width: "100%",
                                display: (this.props.liveStreamData.hideOthers ? "none" : "flex")
                            }}>
                                {this.props.liveStreamData.publisher !== undefined ? (
                                    <div className="stream-container" onClick={() => this.handleMainVideoStream(this.props.liveStreamData.publisher)}>
                                        <UserVideoComponent streamManager={this.props.liveStreamData.publisher} />
                                    </div>
                                ) : null}

                                {this.props.liveStreamData.subscribers.map((sub, i) => {
                                    if (sub.stream.connection.disposed) {
                                        return null;
                                    }
                                    return <div key={i} className="stream-container" onClick={() => this.handleMainVideoStream(sub)}>
                                        <UserVideoComponent streamManager={sub} />
                                    </div>
                                })
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
        setPublisher: publisher => dispatch({ type: "SET_PUBLISHER", payload: publisher }),
        setSubscribers: subscribers => dispatch({ type: "SET_SUBSCRIBERS", payload: subscribers }),
        toggleLiveStreamMod: (mod) => dispatch({ type: "TOGGLE_LIVE_STREAM_MOD", payload: mod }),
        setLiveSessionInfo: (liveSessionInfo) => dispatch({ type: "SET_LIVE_SESSION_INFO", payload: liveSessionInfo }),
        toggleSlidePopup: () => dispatch({ type: "TOGGLE_SLIDE_POPUP" }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(OpvSessionCustom));