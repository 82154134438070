const initialState = {
    userManuals: [],
    selectedJobRoles: [],
    documentName: "",
    userManualsList: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER_MANUALS":
            return {
                ...state,
                userManuals: action.payload
            }
         case "SET_SELECTED_JOB_ROLES":
            return {
                ...state,
                selectedJobRoles: action.payload
            }
        case "SET_ITEM_NAME":
    return {
        ...state,
        documentName: action.payload
            }
        case "SET_USER_MANUALS_LIST":
            return {
            ...state,
            userManualsList: action.payload
            }
        default:
    return state;
}
};
