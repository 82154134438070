import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../eui-components/row";
import FormField from "../../../eui-components/formfield";
import EuiTextField from "../../../eui-components/textfield";
import PageHelper from "../../../utils/page-helper";
import DateUtils from "../../../utils/date-utils";
import EuiTextArea from "../../../eui-components/textarea";

class RateInfo extends React.Component {
   
    render() {
        return (<EuiRow>
            <div className="w-10" >
                <div className="flex-baseline">
                    <FormField style={{ width: "50%", padding: 0 }}>
                        <EuiTextField
                            label="Status"
                            labelstyle={{ fontSize: 14, fontWeight: 600 }}
                            name="activityStatus"
                            fullwidth="true"
                            value={PageHelper.mapRscStatus(this.props.activity.acceptanceStatusId)}
                            readOnly
                        />
                    </FormField>
                    <FormField style={{ width: "50%", padding: 0  }}>
                        <EuiTextField
                            label="Rating Date"
                            labelstyle={{ fontSize: 14, fontWeight: 600 }}
                            name="ratingDate"
                            fullwidth="true"
                            value={DateUtils.toDateString(this.props.activity.ratingDate, "YYYY-MM-DD HH:mm:ss") || ""}
                            readOnly
                        />
                    </FormField>
                </div>
                <FormField style={{ width: "100%", padding: 0 }}>
                    <EuiTextArea
                        label="Comments"
                        labelstyle={{ fontSize: 14, fontWeight: 600 }}
                        style={{ height: 150, border: "1px solid lightgray" }}
                        name="ratingCommentName"
                        fullwidth
                        value={this.props.activity.ratingComments || ""}
                        readOnly
                    />
                </FormField>
            </div>
        </EuiRow>);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RateInfo);