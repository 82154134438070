import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import { ToastContainer, toast } from 'react-toastify';
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import SiteFilter from "./siteFilter";
import SiteList from "./siteList";
import ApiClient from "../../utils/api-client";
import EuiLoading from "../../eui-components/loading";
import DialogModal from "../../components/dialog";
import SiteInfo from "./siteInfo";
import constants from "../../utils/constants";
import CommonHelper from "../../utils/common-helper";

class SiteSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            openDialog: false
        }
    }

    componentDidMount() {
        if ((this.props.userData.customers || []).length > 0) {
            return;
        }
        this.setState({ isLoading: true }, () => {
            ApiClient.post("User/GetCustomers")
                .then(response => {
                    this.props.setAllCustomers(response.data);
                    
                    this.setState({ isLoading: false });
                }).catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                })
        });
    }

    createNewSite = () => {
        const site = this.props.siteData.site;
        if (!site.name || !site.code || !site.customerId) {
            toast.error("Please, fill the mandatory fields!", { containerId: "SS", position: toast.POSITION.TOP_CENTER });
            return;
        } else if (!CommonHelper.isValidCoordinate(site.latitude) || !CommonHelper.isValidCoordinate(site.longitude)) {
            toast.error("Coordinates can only contain numbers and dot", { containerId: "SS", position: toast.POSITION.TOP_CENTER });
            return;
        }

        this.setState({ openDialog: false }, () => {
            this.props.createEditSite();
        });
    }

    render() {
        const user = this.props.userData.userData;
        const leftPanel = {
            title: "Filter By:",
            content: <SiteFilter key={this.props.userData.customers} />,
        };
        const filteredSites = this.props.siteData.filteredSites;
        const midPanel = {
            title: "Filtered Sites",
            content: filteredSites.initialState === 0 ? <div className="flex-start-content m-3 w-10">Please click on filter to get a list of sites</div>
                : <SiteList id="filteredSitesTable" key={filteredSites.initialState} filteredSites={filteredSites} />,
            rightIcon: user.jobRole === constants.userRoles.ADMIN || user.jobRole === constants.userRoles.ERICSSON_IM ?
                <button className={"btn primary m-1 " + (this.state.isLoading ? "loading  disabled" : "")}
                    onClick={() => this.setState({ openDialog: true }, () => this.props.setSite({ id: 0, createDate: new Date() }))}>
                    <i className="icon icon-plus p-1" />Add Site
                    </button>
                : null,
        };

        return (<EuiRow disabled={this.state.isLoading ? "disabled" : ""}>

            <EuiLoading id="sitesSearchLoading" isLoading={this.state.isLoading} />

            <EuiMultiPanelTile id="siteMultiPanel"
                className="fullscreen"
                key={this.props.isLoading}
                leftPanel={leftPanel}
                midPanel={midPanel}
                style={{ paddingRight: 23 }}
            />

            <DialogModal
                buttonName="Save"
                content={<SiteInfo mod={constants.actionMods.CREATE} />}
                handleClose={() => this.setState({ openDialog: false })}
                id="siteCreateDialog"
                onClick={() => this.createNewSite()}
                open={this.state.openDialog}
                title="Create Site">
            </DialogModal>

            <ToastContainer enableMultiContainer containerId={'SS'} autoClose={2500} />
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, siteData: state.site });
const mapDispatchToProps = dispatch => {
    return {
        setAllCustomers: (customers) => dispatch({ type: "SET_ALL_CUSTOMERS", payload: customers }),
        setSite: (site) => dispatch({ type: "SET_SITE", payload: site }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SiteSearch)); 