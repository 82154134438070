var userLoginRolesState = localStorage.getItem("userLoginRoles");

const initialState = {
    mod: 1,
    userList: [],
    currentUser: { id: 0 },
    roleEditMod: false,
    loginRoles: [],
    manualUpdate: false,
};

if (userLoginRolesState) {
    initialState.loginRoles = JSON.parse(userLoginRolesState);
}

export default (state = initialState, action) => {
    switch (action.type) {

        case "SET_CURRENT_USER":
            return {
                ...state,
                currentUser: action.payload
            };
        case "CHANGE_USER_ADMIN_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_USER_LIST":
            return {
                ...state,
                userList: action.payload
            };
        case "TOGGLE_ROLE_EDIT_MOD":
            return {
                ...state,
                roleEditMod: action.payload
            };
        case "SET_USER_LOGIN_ROLES":
            localStorage.setItem("userLoginRoles", JSON.stringify(action.payload));
            return {
                ...state,
                loginRoles: action.payload
            };
        case "TOGGLE_MANUAL_UPDATE":
            return {
                ...state,
                manualUpdate: !state.manualUpdate
            };
        default:
            return state;
    }

};
