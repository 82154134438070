import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiRow from "../../../eui-components/row";
import { useEffect } from "react";
import constants from "../../../utils/constants";
import EuiNewMultiSelect from "../../../eui-components/select/newmultiselect";

const Role18Content = (props) => {

    const countries = useSelector(state => state.user.countries);
    const allCustomers = useSelector(state => state.user.customers);
    const roleEditMod = useSelector(state => state.userAdmin.roleEditMod);
    const userId = useSelector(state => state.user.userData.userId);

    const [customers, setCustomers] = useState(allCustomers);
    const [selectedCountries, setSelectedCountries] = useState(countries?.filter(c => props.selectedRoleItems?.map(s => s?.countryId)?.includes(c.value)));

    const dispatch = useDispatch();

    //useEffect(() => {
    //    if ((props.selectedRoleItems ?? []).length === 0 && props.selectedAction === 0) {
    //        
    //        setCountries([]);
    //        setCustomers([]);
    //    }
    //}, [props.selectedRoleItems]);

    const handleChange = (e, selectedItems = null, name) => {
        const target = e.target;
        const value = target.value;

        
        
        if (!selectedItems) {
            return;
        }
        if (name === "countryId") {
            const selectedCountryItems = countries.filter(c => selectedItems.includes(c.text.trim()));
            
            
            const countryIds = selectedCountryItems.map(s => s.value);
            //If country removal happen then trigger custom event
            const element = document.querySelector("#" + "multiSelect_" + props.selectedRole.role);
            element.dispatchEvent(new CustomEvent("change", { detail: { selectedCountryIds: countryIds } }));
            const mapCustomers = [...customers];
            mapCustomers.map(cus => {
                cus.hidden = !countryIds.includes(cus.countryId);
            });
            setCustomers(mapCustomers);
            setSelectedCountries(selectedCountryItems);
        } else if (name === "assignedCustomerRole1") {
            addSelectedCustomers(e, selectedItems);
        }
    }

    const addSelectedCustomers = (e, selectedItems) => {
        
        const mapItems = getMappedItems(selectedItems, e.detail?.selectedCountryIds);
        
        props.setSelectedRoleItems(mapItems);
    };

    const getMappedItems = (selectedItems, selectedCountryIds) => {
        
        if (!selectedItems) {
            return [];
        }
        return selectedItems.map((c, index) => {
            const cus = customers && customers.find(item => item.text.trim() === c.trim().toString());
            if (cus && (!selectedCountryIds || selectedCountryIds.includes(cus.countryId))) {
                cus.loginRoleId = props.selectedRole.role;
                cus.customerId = cus.value;
                cus.createDate = new Date();
                cus.createUserId = parseInt(userId);
                return cus;
            }
        })?.filter(m => m);
    }

    return (props.selectedAction === constants.actionTypes.DELETE ? <EuiRow className="flex-middle-content w-10 p-3 text-lg">Are you sure you want to delete this role?</EuiRow> :
        <EuiRow>
            <label style={{ fontWeight: 500, fontSize: 14, width: "100%", padding: 5 }}>Select country to list customers</label>
            <EuiNewMultiSelect
                disabled={!roleEditMod}
                className="m-1 pr-0"
                divStyle={{ width: "100%" }}
                style={{ maxWidth: "100%", width: "38%", marginRight: 20 }}
                fullwidth
                label={{ title: "Select country to list customers", style: { fontWeight: 500 } }}
                id={"userAdminCountries_" + props.selectedRole.role}
                name="countryId"
                options={countries}
                value={selectedCountries?.map(c => c.text)}
                onChange={(e, options) => handleChange(e, options, "countryId")}
                placeholder="---Select Country---"
            />
            <EuiNewMultiSelect
                key={selectedCountries}
                className="m-1 "
                style={{ maxWidth: "100%", width: "58%" }}
                disabled={!roleEditMod || (selectedCountries?.length) === 0}
                id={"multiSelect_" + props.selectedRole.role}
                placeholder="---Select Customers---"
                name="assignedCustomerRole1"
                options={customers}
                value={props.selectedRoleItems?.map(i => i.text)}
                onChange={(e, options) => handleChange(e, options, "assignedCustomerRole1")}
            />
        </EuiRow >
    );

}

export default Role18Content;