import React from 'react';
import { connect } from 'react-redux';
import Table from "../../components/table/index";
import EuiRow from "../../eui-components/row";
import ApiClient from "../../utils/api-client";
import DialogModal from "../../components/dialog";
import Filter from "./filter";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import { ToastContainer, toast } from 'react-toastify';
import { Notification } from '@eds/vanilla';
import EuiLoading from "../../eui-components/loading"
import EuiTile from '../../eui-components/tile';
import EuiSelect from "../../eui-components/select";
import { CustomListBuilder } from './CustomListBuilder';



const customerStatus = [3, 4, 5, 8, 9, 10, 11, 12, 13, 14, 15];


class ReportBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openFilterDialog: false,
            reports: [],
            sources: [],
            reportsValue: [{ value: 0, Text: "Select Report", filterJson:"" }],
            sourcesValue: [{ value: 0, Text: "Select Resource" }],
            QueryData: [],

            //availableFields: [{ title: "Field Name", field: "name" }],
            fieldsReport: [],
            reportField: [],
            reportsValue: [],
            sourcesValue: [],
            reportsCount: 0,
            availableFields: [{ title: "Column Name", field: "name" }],
            documents: [],
            fieldsAv: [],
            fieldsAvilable: [],


            fieldsReport: [{ title: "Column Name", field: "name" }
                //, { title: "Criteria", field: "criteria" }
            ],
            reportField: [{ name: "Activity Name", property: "activityName" }, { name: "Session ID", property: "id", tableData: { id: 0 } }, { name: "Session Name", property: "name", tableData: { id: 1 } }],
            rowData: [],
            reportName: "",
            index: 0,
            showInputField: false,
            options: [],
            filterSavedQuery:"",
            criteria: [],
            criteriaList: [],
            index: 1,
            reportssValue: [],
            sourcessValue: [],
            cols: [],
            selectReportId: 0,
            selectSourceId: 0,
            enableSelect: false,
            enableAddbtn: false,
            enableUpdate: false,
            enableCreate: false,
            exportEnable: false,
            openDialogRename: false,
            reportNewName: "",
            reportToBeUpdatedID: "",
            filterHide: false,
            openDialogDelete: false,
            enableEditbtn: false,
            hideEdit: true,
            openDialogAdd: false,
            reportChanged: false,
            sourceChanged: false,
            allData: [],
            filteredData: [],
            isLoading: true,
        }

    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        ApiClient.post("ReportBuilder/GetSources")
            .then(response => {
                this.setState({ isLoading: false }, () => {
                    if (response.data != null) {
                        this.setState({ sourcessValue: [] })
                        // this.setState({ fieldsAvilable: [] })
                        response.data.forEach(item => {
                            this.state.sourcessValue.push(item)
                        })

                        response.data.forEach(item => {
                            item.columns.forEach(col => {
                                this.state.fieldsAvilable.push(col)
                            })
                        })

                        //let sourcescol = this.state.fieldsAvilable.map(item => {
                        //    let properties = { "property": item.name, "name": item.title }; return properties;
                        //});

                        let sources = this.state.sourcessValue.map(item => {
                            let properties = { "value": item.id, "text": item.name }; return properties;
                        });
                        this.setState({
                            sourcesValue: sources
                        })
                        //this.setState({
                        //    fieldsAv: sourcescol
                        //})

                    }
                })
            });


    }

    availableSelectChange = (rows) => {
        this.setState({ fieldsReport: rows });
    }

    Add = () => {
        this.setState({
            openDialogAdd: false,
            reportChanged: true,
            reportField: [{ name: "Activity Name", property: "activityName" }, { name: "Session ID", property: "id", tableData: { id: 0 } }, { name: "Session Name", property: "name", tableData: { id: 1 } }]

        })


        let fields = [];
        const secondaryListContainer = document.querySelector('.lb-secondary .lb-list').querySelectorAll('.lb-item');
        secondaryListContainer.forEach(item => {
            fields.push(item.innerText)
        });
        let columns = fields.map(item => {
            let properties = { "name": item, "property": item }; return properties;
        });


        if (this.state.reportName == "" || this.state.reportName == null) {
            toast.error("Please enter a report template name", { containerId: 'MS', position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

        }
        else if (fields.length === 0)
        {
            toast.error("Please select report template columns to be saved", { containerId: 'MS', position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

        }
        else {
            ApiClient.post("ReportBuilder/AddUpdateReportTemplateAndConfiguration", {
                ReportName: this.state.reportName, CreateUser: parseInt(this.props.userData.userData.userId), RunTimes: 0, Fields: columns, Type: 1, SourceId: this.state.selectSourceId,
                Filters: this.state.criteriaList, FilterJson: JSON.stringify(this.state.criteriaList), Update: false
            }
            
            ).then(response => {
                if (response.data == true) {
                    this.GetUserReports(this.state.selectSourceId);
                    this.emptyLists();
                    toast.success("Report Template Added Successfully", { containerId: 'MS', position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                    this.setState({

                        enableSelect: false,
                        showInputField: false,
                        selectReportId: "",
                        //selectSourceId: "",
                        enableAddbtn: false
                    })

                    // this.setState({ fieldsAv: this.state.reportField })
                }
            })
        }

    }


    UpdateReport = () => {
        
       
        let fields = [];
        const secondaryListContainer = document.querySelector('.lb-secondary .lb-list').querySelectorAll('.lb-item');
        secondaryListContainer.forEach(item => {
            fields.push(item.innerText)
        });
        let columns = fields.map(item => {
            let properties = { "name": item, "property": item }; return properties;
        });


        if (this.state.selectReportId === 0 || this.state.selectReportId === null) {
            toast.error("Please select report template to be updated", { containerId: 'MS', position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

        }
        else if (fields.length === 0) {
            toast.error("Please select report template columns to be updated", { containerId: 'MS', position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

        }
        else {
            ApiClient.post("ReportBuilder/AddUpdateReportTemplateAndConfiguration", {
                CreateUser: parseInt(this.props.userData.userData.userId), Fields: columns, Type: 1, ReportID: this.state.selectReportId,
                SourceId: this.state.selectSourceId, Filters: this.state.criteriaList, FilterJson: JSON.stringify(this.state.criteriaList), Update: true
            }
            ).then(response => {
                if (response.data == true) {
                    this.GetUserReports(this.state.selectSourceId);
                    this.emptyLists();
                    toast.success("Report Template Updated Successfully", { containerId: 'MS', position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
                    this.setState({

                        enableSelect: false,
                        showInputField: false,
                        selectReportId: "",
                        //selectSourceId: "",
                        enableAddbtn: false
                    })

                    // this.setState({ fieldsAv: this.state.reportField })
                }
            })
        }
    }

    emptyLists = () => {
        var mainListContainer = document.querySelector('.lb-main .lb-list');
        var mainItems = mainListContainer.querySelectorAll('.lb-item');
        mainItems.forEach(item => {
            mainListContainer.removeChild(item);
        });
        var secondaryListContainer = document.querySelector('.lb-secondary .lb-list');
        var items = secondaryListContainer.querySelectorAll('.lb-item');
        items.forEach(item => {
            secondaryListContainer.removeChild(item);
        });

        let listcol = this.state.fieldsAvilable.filter((item) => {
            return item.value === this.state.selectSourceId;
        }).map(item => {
            let properties = { "title": item.name }; return properties;
        });


        //let listcol = this.state.fieldsAvilable.map(item => {
        //    let properties = { "title": item }; return properties;
        //});
        const listBuilders = document.querySelectorAll('.list-builder');

        if (listBuilders) {
            Array.from(listBuilders).forEach((listBuilderDOM) => {
                const listBuilder = new CustomListBuilder(listBuilderDOM, listcol);
                listBuilder.init();
            });
        }
        const QueryArea = document.querySelector('#QueryAreaP');
        QueryArea.innerHTML = "";

    }

    DeleteFilter = () => {
        const QueryArea = document.querySelector('#QueryAreaP');
        QueryArea.innerHTML = "";

        this.setState({
            criteriaList: [],
            QueryData:[]
        })

    }

    GetUserReports = (a) =>
    {

        ApiClient.post("ReportBuilder/GetUserReportTemplates", { SourceId: a ,UserId: parseInt(this.props.userData.userData.id) })
            .then(response => {
                if (response.data != null)
                {
                    this.setState({ reportssValue: [] })
                    response.data.forEach(item => {
                        this.state.reportssValue.push(item)
                    })

                }
                let reports = this.state.reportssValue.map(item =>
                {
                    let properties = { "value": item.id, "text": item.name, "filterJson": item.filterJson }; return properties;
                });
                this.setState({
                    reportsValue: reports
                })
            });

    }

    handleChange = (event) => {


        this.emptyLists();

        var deafultList = document.querySelector('.lb-main .lb-list');
        var deafultListItems = deafultList.querySelectorAll('.lb-item');
        deafultListItems.forEach(item => {
            item.firstChild.checked = false;
        });
        
        ApiClient.post("ReportBuilder/GetTemplateColumns", { Id: parseInt(event.target.value) })
                .then(response => {
                    if (response.data != null) {

                        var secondaryListContainer = document.querySelector('.lb-secondary .lb-list');


                        response.data.forEach(item => {
                            if (Array.from(deafultListItems).filter(e => e.innerText === item.columnName).length > 0) {
                                var elemnt = Array.from(deafultListItems).filter(e => e.innerText.trim() === item.columnName.trim())[0];
                                // item.firstChild.checked = true;
                                deafultList.removeChild(elemnt);
                                secondaryListContainer.appendChild(elemnt);
                            }
                        });
                        if (Array.from(this.state.reportsValue).filter(e => e.value === event.target.value).length > 0) {
                            var report = Array.from(this.state.reportsValue).filter(e => e.value === event.target.value)[0];
                            var filterjsonobject = report.filterJson;
                            if (filterjsonobject) {
                                var savedQuery = JSON.parse(filterjsonobject);

                                let fields = [];
                                let myObjStr = savedQuery.forEach(item => {
                                    if (item.operator === 'IN') {
                                        fields.push(item.name + " " + item.operator + " (" + item.value + ")")

                                    }
                                    else {
                                        fields.push(item.name + " " + item.operator + " " + item.value)
                                    }
                                });
                                let Query = JSON.stringify(fields).replace(/"/g, "");;
                                const QueryArea = document.querySelector('#QueryAreaP');
                                QueryArea.innerHTML = Query;

                                this.setState({
                                    QueryData: savedQuery,
                                    criteriaList: this.state.QueryData,
                                    openFilterDialog: false
                                });
                            }
                        }

                    }

                })
        //}

        this.setState({
            selectReportId: event.target.value,
            enableSelect: true,
            enableUpdate: true,
            enableCreate: false,
            filterHide: false,
            hideEdit: false,
            enableEditbtn: false,
            reportChanged: false,
        })
       
    }

    handleSourceChange = (event) => {
        let sourcescol = this.state.fieldsAvilable.filter((item) => {
            return item.value === event.target.value;
        }).map(item => {
            let properties = { "title": item.name, "datatype": item.datatype }; return properties;
        });

        var mainListContainer = document.querySelector('.lb-main .lb-list');
        var mainItems = mainListContainer.querySelectorAll('.lb-item');
        mainItems.forEach(item => {
            mainListContainer.removeChild(item);
        });
        var secondaryListContainer = document.querySelector('.lb-secondary .lb-list');
        var items = secondaryListContainer.querySelectorAll('.lb-item');
        items.forEach(item => {
            secondaryListContainer.removeChild(item);
        });


        const listBuilders = document.querySelectorAll('.list-builder');

        if (listBuilders) {
            Array.from(listBuilders).forEach((listBuilderDOM) => {
                const listBuilder = new CustomListBuilder(listBuilderDOM, sourcescol);
                listBuilder.init();
            });
        }

        
        
     
        this.setState({
            selectSourceId: event.target.value,
            enableSelect: true,
            enableCreate: true,
            enableUpdate : false
        })
        this.GetUserReports(event.target.value);
    }

    HanFilter = () => {

        let fields = [];
        let data = [];
        const secondaryListContainer = document.querySelector('.lb-secondary .lb-list').querySelectorAll('.lb-item');
        secondaryListContainer.forEach(item => {
            fields.push(item.innerText)
        });

        fields.forEach(filed => {
            data.push(this.state.fieldsAvilable.filter((item) => 
                item.name.trim() === filed && item.value === this.state.selectSourceId)[0]);
    });
       
   
        let filterFields = data.map(item => {
            let properties = { "value": item.datatype, "text": item.name }; return properties;
        });

        const QueryArea = document.querySelector('#QueryAreaP');

        this.setState({
            filterSavedQuery: QueryArea.innerHTML,
            options: filterFields,
            openFilterDialog: true,
        });
    }

  

    setCriteria = () => {

        let fields = [];
        let myObjStr = this.state.QueryData.forEach(item => {
             if (item.operator === 'IN') {
                fields.push(item.name + " " + item.operator + " (" + item.value + ")")

            }
            else {
                fields.push(item.name + " " + item.operator + " " + item.value)
            }
        });


        let Query = JSON.stringify(fields).replace(/"/g, "");
        const QueryArea = document.querySelector('#QueryAreaP');
        QueryArea.innerHTML = Query;

        this.setState({
            criteriaList: this.state.QueryData,
            openFilterDialog: false
        })
       
    }

    SaveChanges = () => {
        if (this.state.selectReportId === 0 || this.state.selectReportId === null) {
            toast.error("Please select report template to genrate data.", { containerId: 'MS', position: toast.POSITION.TOP_CENTER, autoClose: 3000 });

        }
        else {
            toast.success("The report will be generated and can be downloaded from my Reports soon!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER, autoClose: 5000 });
            //this.setState({ isLoading: true });
            ApiClient.post("ReportBuilder/SaveTemplateAndUploadData", { SourceId: this.state.selectSourceId, ReportId: this.state.selectReportId, UserId: parseInt(this.props.userData.userData.id) }, {}, 3000000
            ).then(response => {
                if (response.data) {
                   // this.setState({ isLoading: false });
                }
            }).catch(error => {
                this.setState({ isLoading: false });
                toast.error("An error occured, try again later!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER, autoClose: 3000 });
            })
        }
    }
    getQueryData = (query) => {
        this.setState({ QueryData: query });
    }
    MyReports = () => {
        this.props.history.push("/my-reports")
    }

    cancelFilter = () => {

        this.setState({ openFilterDialog: false, QueryData: [] });

    }
    MoveUp = () => {
        var secondaryListContainer = document.querySelector('.lb-secondary .lb-list');
        var items = secondaryListContainer.querySelectorAll('.lb-item');
        var arr = Array.prototype.slice.call(items); // Now it's an Array.

        arr.forEach(item => {
            if (item.firstChild.checked === true) {
                var index = arr.indexOf(item);
                if (index != 0) {
                    let temp = arr[index];
                    arr[index] = arr[index - 1];
                    arr[index - 1] = temp;
                }
                item.firstChild.checked = false
            }
        });

        var secondaryListContainer = document.querySelector('.lb-secondary .lb-list');
        var items = secondaryListContainer.querySelectorAll('.lb-item');
        items.forEach(item => {
            secondaryListContainer.removeChild(item);
        });

        arr.forEach(item => {
            secondaryListContainer.appendChild(item);

        });


    }

    MoveDown = () => {
        var secondaryListContainer = document.querySelector('.lb-secondary .lb-list');
        var items = secondaryListContainer.querySelectorAll('.lb-item');
        var arr = Array.prototype.slice.call(items); // Now it's an Array.

        arr.forEach(item => {
            if (item.firstChild.checked === true) {
                var index = arr.indexOf(item);
                if (index != arr.length -1) {
                    let temp = arr[index];
                    arr[index] = arr[index +1];
                    arr[index +1] = temp;
                }
                item.firstChild.checked = false

            }
        });

        var secondaryListContainer = document.querySelector('.lb-secondary .lb-list');
        var items = secondaryListContainer.querySelectorAll('.lb-item');
        items.forEach(item => {
            secondaryListContainer.removeChild(item);
        });

        arr.forEach(item => {
            secondaryListContainer.appendChild(item);

        });


    }
    
    render() {
        const headers = this.state.reportField.map(item => { let properties = { "key": item.property, "label": item.name }; return properties; });
        return (
            (<EuiTile fullscreen>{this.state.isLoading ? <EuiLoading size="large" isLoading={true} /> :
                <EuiRow >
                    <FormField style={{ marginTop: "30px", width: "20%" }}>
                    <label style={{ fontSize: "14px" }} htmlFor="Source">Source</label>
                    <EuiSelect id="idType" name="Sources" label="---Select Source---"
                        options={this.state.sourcesValue}
                        value={this.state.selectSourceId}
                        onChange={e => this.handleSourceChange(e)}
                        optionListStyle={{ width: "100%" }}
                    />
                    </FormField>
                    <FormField style={{ marginTop: "30px", width: "20%" }}>
                        <label style={{ fontSize: "14px" }} htmlFor="reports">User Report Templates</label>
                        <EuiSelect id="reports" name="Reports" label="---Select Report Template---"
                                options={this.state.reportsValue}
                                value={this.state.selectReportId}
                                onChange={e => this.handleChange(e)}
                                optionListStyle={{ width: "100%" }}
                            />
                     </FormField>

                    <EuiRow >
                    <div className="column sm-12 md-6 lg-6 xl-6 filled" style={{ padding: "35px" }}>
                        <button className="btn primary" disabled={!this.state.enableCreate} onClick={() => this.setState({ openDialogAdd: true })}>Create Report Template</button>
                        <button className="btn primary" disabled={!this.state.enableUpdate} onClick={this.UpdateReport } >Update Report</button>
                        <button className="btn primary" onClick={this.MyReports}>My Reports</button>
                        {/*  <button className="btn primary">Copy</button>*/}
                        </div>
                    </EuiRow>
                    <div class="list-builder">
                        <div class="lb-block lb-main">
                            <div class="lb-header">

                                <div class="title">Source Columns available</div>

                                <div class="lb-filter">
                                    <input type="text" class="lb-filter-input lb-filter-main" placeholder="Type to search"/> 
                                </div>

                                <div class="lb-controls">
                                    <div class="lb-header-cb">
                                        <input type="checkbox" class="lb-primary-cb-all" id="lb-primary-cb-all"/>
                                            <label for="lb-primary-cb-all">Select all items</label>
                                    </div>

                                    <div class="lb_header-counters">
                                        <span class="lb-counter-total">0</span> |
                                        <span class="lb-counter-selected">0</span> selected
                                    </div>
                                </div>
                            </div>

                            <div class="lb-list" id="TargetList">
                                <p class="lb-placeholder">The list is empty.</p>
                            </div>
                        </div>

                        <div class="lb-transfer-btns">
                            <button ><i class="icon icon-chevron-up" onClick={this.MoveUp}></i></button>
                            <br></br>
                            <button class="btn lb-add-btn" disabled><i class="icon icon-chevron-right"></i></button>
                            <button class="btn lb-remove-btn" disabled><i class="icon icon-chevron-left"></i></button>
                            <br></br>
                            <button ><i class="icon icon-chevron-down" onClick={this.MoveDown}></i></button>

                        </div>

                        <div class="lb-block lb-secondary">
                            <div class="lb-header">
                                <div class="title">Source Columns assigned</div>

                                <div class="lb-filter">
                                    <input type="text" class="lb-filter-input lb-filter-secondary" placeholder="Type to search"/>
                                </div>

                                <div class="lb-controls">
                                    <div class="lb-header-cb">
                                        <input type="checkbox" class="lb-secondary-cb-all" id="lb-secondary-cb-all"/>
                                            <label for="lb-secondary-cb-all">Select all items</label>
                                    </div>

                                    <div class="lb_header-counters">
                                        <span class="lb-counter-total">0</span> |
                                        <span class="lb-counter-selected">0</span> selected
                                    </div>
                                </div>
                            </div>

                            <div class="lb-list">
                                <p class="lb-placeholder">The list is empty.</p>
                                <button className="btn primary" id="wizard-previous" onClick={this.HanFilter} >Set Filter</button>

                            </div>
                            <div>
                            </div>
                        </div>
                        <div className="column sm-12 md-6 lg-6 xl-6 filled">
                            <label style={{ fontSize: "15px", fontWeight: "bold" }} htmlFor="Source">Filter Parameters</label>
                            <textarea id="QueryAreaP" placeholder="Filter Query" style={{ width: "90%", height: "70%" }} ></textarea>
                            <button className="btn primary" id="wizard-previous" onClick={this.DeleteFilter}>Delete Filter</button>

                        </div>
                    </div>
                  
                    <EuiRow >

                        <EuiRow>
                            <div className="column sm-12 md-12 lg-12 xl-12 filled" >
                                <div className="wizard-footer">
                                    <div className="item" style={{ marginRight: "20px" }}>
                                        <button className="btn primary" id="wizard-previous" onClick={this.SaveChanges}>Genrate Report</button>
                                    </div >
                                </div>
                            </div>
                        </EuiRow>
                    </EuiRow>
                    <DialogModal
                        style={{ width: "500px" }}
                        buttonName="Set Filter"
                        content={<Filter OnSaveQuery={this.getQueryData} options={this.state.options} filterSavedQuery={this.state.filterSavedQuery} QueryData={this.state.QueryData} />}
                        handleClose={this.cancelFilter}
                        id="CriteriaDialog"
                        onClick={this.setCriteria}
                        open={this.state.openFilterDialog}
                        title={(this.state.rowData.length == 0 ? "" : this.state.rowData.name + " Criteria")}
                        titleId="filterTitle"
                    >
                    </DialogModal>
                    <DialogModal
                        buttonName="Rename"
                        content={<input style={{ width: "300px" }} value={this.state.reportNewName} onChange={(e) => { this.setState({ reportNewName: e.target.value }) }} />}
                        handleClose={() => this.setState({ openDialogRename: false })}
                        open={this.state.openDialogRename}
                        id="rename"
                        onClick={this.RenameReport}
                        title="Rename Report"
                        titleId="renameTitle"
                    ></DialogModal>
                    <DialogModal
                        buttonName="Create"

                        content={<input style={{ width: "300px" }} onChange={(e) => { this.setState({ reportName: e.target.value }) }} />}
                        handleClose={() => this.setState({ openDialogAdd: false })}
                        open={this.state.openDialogAdd}
                        id="add"
                        onClick={this.Add}
                        title="New Report"
                        titleId="newTitle"
                    ></DialogModal>
                    <DialogModal
                        buttonName="Delete"
                        content={<p>you sure you want to delete the report?</p>}
                        handleClose={() => this.setState({ openDialogDelete: false })}
                        open={this.state.openDialogDelete}
                        id="delete"
                        onClick={this.delete}
                        title="Delete Report"
                        titleId="deleteTitle"
                    ></DialogModal>

                </EuiRow>}
            </EuiTile>)
        );
    }
}

const FormField = (props) => (
    <div style={{ margin: 10, ...props.style }} className="field validate">
        {props.children}
    </div>
);


const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, sessionData: state.session });
const mapDispatchToProps = dispatch => {
    return {
        sessionReport: (reportFields, reportName) => dispatch({ type: "SESSION_REPORT", payload: { reportFields, reportName } }),
        setAllSession: allSession => dispatch({ type: "SET_ALL_SESSION", payload: allSession }),
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(ReportBuilder)));

