const RatingDialogReducer = (state, action) => {
    switch (action.type) {
        case "HANDLE_DIALOG_CHANGE":
            return {
                ...state,
                [action.field]: action.payload,
            } 
        case "SET_IM_RATINGS":
            return {
                ...state,
                ratings: action.payload,
            }
        case "SET_SELECTED_ANSWER":
            return {
                ...state,
                selectedAnswer: action.payload,
            }
        case "SET_COMMENT":
            return {
                ...state,
                comment: action.payload,
            }
        default:
            return state;
    }
};

export default RatingDialogReducer;