import React from "react";
import EuiRow from "../../eui-components/row";
import ApiClient from "../../utils/api-client";
import constants from "../../utils/constants";
import EuiTextField from "../../eui-components/textfield";
import Rater from "react-rater";
import { useEffect } from "react";
import { useReducer } from "react";
import RatingDialogReducer from "./ratingDialogReducer";
import EuiTable from "../../eui-components/table";
import EuiHeader from "../../eui-components/header";
import { useState } from "react";
import RadioButtons from "../../components/radioButtons";
import EuiTile from "../../eui-components/tile";
import { useDispatch, useSelector } from "react-redux";
import EuiLoading from "../../eui-components/loading";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTextArea from "../../eui-components/textarea";
import { toast } from "react-toastify";
import PageHelper from "../../utils/page-helper";
import { useHistory } from "react-router-dom";

const RateActivity = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [ratingColumns, setRatingColumns] = useState([{
        key: props.viewOnly ? 'question' : 'name',
        title: 'Question',
        width: "30%"
    },
    {
        key: 'answer',
        title: 'Answer',
    },]);
    const [tableRefresh, setTableRefresh] = useState(false);

    const currentImActivity = useSelector(state => state.imInbox.currentIMActivity)
    const user = useSelector(state => state.user.userData);
    const activityFilter = useSelector(state => state.activity.filter);
    const reduxDispatch = useDispatch();
    const [ratingReducer, dispatch] = useReducer(RatingDialogReducer, { ratings: [], comment: "" });

    const history = useHistory();

    useEffect(() => {
        if (props.viewOnly) {
            return;
        }
        setIsLoading(true);
        ApiClient.post("ActivityInbox/GetRatingStatuses")
            .then(response => {
                
                const ratings = mapRatings(response.data);
                
                setIsLoading(false);
                dispatch({ type: "SET_IM_RATINGS", payload: ratings });
                setTableRefresh(!tableRefresh);
            })
            .catch(error => {
                setIsLoading(false);
                console.error(error);;
            });
    }, []);

    useEffect(() => {
        if (ratingReducer.selectedAnswer && ratingReducer.selectedAnswer.value) {
            handleChange(ratingReducer.selectedAnswer);
        }
    }, [ratingReducer.selectedAnswer]);

    const mapRatings = (data) => {
        
        if (!data || data.length === 0) {
            return [];
        }
        const documents = props.allDocument;
        return data.map(item => {
            const newItem = { ...item };
            newItem.activityId = props.activityId;
            newItem.question = newItem.name;
            newItem.ratingValue = currentImActivity.ratings?.find(r => r.question == item.name)?.ratingValue;

            if (documents && documents.length > 0) {
                if (newItem.id === constants.qiIfDone) {
                    newItem.ratingValue = documents.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED && d.rscSeverityLevel)
                        .reduce((a, b) => {
                            a = a + constants.severityLevels.find(s => s.value === b.rscSeverityLevel)?.rate;
                            return a;
                        }, 0)?.toString();
                } else if (newItem.id === constants.numberOfSnags) {
                    newItem.ratingValue = documents.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED
                        && d.rscSeverityLevel
                        && d.rscSeverityLevel != constants.severityLevelsLookup.OBSERVATION)?.length?.toString();
                }
            }
            delete newItem["id"];
            return newItem;
        })
    }

    const handleChange = (rowData) => {
        const value = rowData.value;
        const newRatings = [...ratingReducer.ratings];
        newRatings.map(item => {
            if (item.question == rowData.question) {
                item.ratingValue = (value ?? "").toString();
            }
        });
        dispatch({ type: "SET_IM_RATINGS", payload: newRatings });
        if (rowData.type === "star") {
            setTableRefresh(!tableRefresh);
        }
    }

    const renderAnswers = (rowData) => {
        if (rowData.type === constants.ratingStatusTypes[1]) {
            let stars = ``;
            [1, 2, 3, 4, 5].map(item => {
                const isActive = rowData.ratingValue >= item ? "is-active" : "";
                const isDisabled = props.viewOnly || rowData.isDisabled ? "is-disabled" : "";
                stars += `<div><div id="star_${item}" class="react-rater-star ${isActive}  ${isDisabled}">★</div></div>`;
            });
            return `<div class="react-rater" >
                    ${stars}
                  </div>`;
        } else if (rowData.type === constants.ratingStatusTypes[2]) {
            let radios = `<div class="row">`;
            rowData.ratingStatusItems.map(item => {
                const checked = item.name === rowData.ratingValue ? "checked" : ""
                const disabled = props.viewOnly || rowData.isDisabled ? "disabled" : "";
                radios += `<div class="m-1"><input id="${rowData.name.replace(/[\s%\/]/g, '')}_${item.name.replace(/[\s%\/]/g, '')}" 
                                    class="rating-radios" name="${rowData.name}" type="radio" value="${item.name}" ${checked} ${disabled}>
                        <label for="${rowData.name.replace(/[\s%\/]/g, '')}_${item.name.replace(/[\s%\/]/g, '')}">${item.name}</label> </div>`
            });
            radios += `</div>`
            return radios;
        } else {
            const disabled = props.viewOnly || rowData.isDisabled ? "disabled" : "";
            const text = `<input id="id_${rowData.name.replace(/[\s%\/]/g, '')}" type="text" class="rating-text" value="${rowData.ratingValue ?? ""}" 
                     name="${rowData.name.replace(/[\s%\/]/g, '')}" 
                     style="border:none;width:100%;margin:4px;margin-left:0px"
                     ${disabled}
                      >`;
            return text;
        }
    }

    const renderRow = (tr, rowData) => {
        tr.getElementsByTagName("td")[1].innerHTML = renderAnswers(rowData);
        const stars = tr.getElementsByTagName("td")[1].querySelectorAll(".react-rater-star");
        for (let i = 0; i < stars?.length; i++) {
            stars[i].addEventListener('click', (e) => { dispatch({ type: "SET_SELECTED_ANSWER", payload: { ...rowData, value: i + 1 } }); });
        }

        const radios = tr.getElementsByTagName("td")[1].querySelectorAll(".rating-radios");
        for (let i = 0; i < radios?.length; i++) {
            radios[i].addEventListener('change', (e) => { dispatch({ type: "SET_SELECTED_ANSWER", payload: { ...rowData, value: e.target.value } }); });
        }

        const texts = tr.getElementsByTagName("td")[1].querySelectorAll(".rating-text");
        for (let i = 0; i < texts?.length; i++) {
            texts[i].addEventListener('change', (e) => { dispatch({ type: "SET_SELECTED_ANSWER", payload: { ...rowData, value: e.target.value } }); });
        }
    }

    const approveRejectActivity = (type) => {
        
        setIsLoading(true);
        ApiClient.post("Activity/SaveIMActivityApproval", {
            id: currentImActivity.id,
            name: currentImActivity.name,
            acceptanceStatusId: type,
            ratingComments: ratingReducer.comment,
            systemrecordid: currentImActivity.systemrecordid,
            externalSystemRecordId: currentImActivity.externalSystemrecordid,
            rscScope: currentImActivity.rscScope,
            actualStart: currentImActivity.actualStart,
            aspUserId: currentImActivity.userId,
            rscUserName: user.username,
            WorkplanName: currentImActivity.workplanName,
            documents: currentImActivity?.subactivities?.map(item => item.documents).reduce((a, b) => { return a?.concat(b); }),
            ratings: ratingReducer.ratings,
            userId: parseInt(user.userId),
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude,
            resourceId: currentImActivity.resourceId
        }, {}, 120000).then(response => {
            if (response.data) {
                toast.success(constants.processPozitiveMessage, { containerId: 'AI', position: toast.POSITION.TOP_CENTER });
            } else {
                toast.error(constants.errorMessage, { containerId: 'AI', position: toast.POSITION.TOP_CENTER });
            }
            setIsLoading(false);
            props.setOpenDialogRating(false);
            if (type != constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
                reduxDispatch({ type: "CHANGE_IM_INBOX_MOD", payload: constants.pageMods.LIST });
                reduxDispatch({ type: "TOGGLE_LIVE_STREAM_MOD", payload: false });
                reduxDispatch({ type: "SET_ACTIVITY_FILTER", payload: { ...activityFilter, id: currentImActivity.id } });
                history.push("/activities");
            }
        }).catch(error => {
            toast.error(constants.errorMessage, { containerId: 'AI', position: toast.POSITION.TOP_CENTER });
            console.error(error);;
            setIsLoading(false);
        });

    }


    return (<EuiTile tileStyle={{ padding: 0 }} className={isLoading ? "disabled" : ""}>
        <EuiHeader id="ratingImActivityHeaderId"
            style={{ height: 60 }}
            title={<button className={"btn primary p-1"} style={{ marginRight: 8, marginTop: 10, width: 95 }} onClick={() => props.setOpenDialogRating(false)}><i className="icon icon-cross p-1"></i>Cancel</button>}
            detail={<EuiTextArea value={ratingReducer.comment} style={{ width: "100%" }} divStyle={{ width: "100%" }} onChange={(e) => dispatch({ type: "SET_COMMENT", payload: e.target.value })}></EuiTextArea>}
            rightActions={<div>
                <button className={"btn primary p-1"} onClick={() => approveRejectActivity(constants.activityInboxApprovalTypes.APPROVE)}><i className="icon icon-success p-1"></i>Approve</button>
                <button className={"btn warning p-1"} onClick={() => approveRejectActivity(constants.activityInboxApprovalTypes.REJECT)}><i className="icon icon-failed p-1"></i>Reject</button>
            </div>}>
        </EuiHeader>
        <EuiLoading id="imInboxRatingLoading" isLoading={isLoading} />
        <EuiRow key={tableRefresh} className=" p-1 ">
            <ObseleteEuiTable
                classes="tiny"
                columns={ratingColumns}
                data={ratingReducer.ratings}
                id={"imActivityRatingsTable"}
                scroll={true}
                onCreatedRow={(tr, rowData) => renderRow(tr, rowData)}
            >
            </ObseleteEuiTable>
        </EuiRow>
    </EuiTile >);
}

export default RateActivity;


