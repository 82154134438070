import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import KpiCard from "../../../../components/kpicard";
import EuiLoading from "../../../../eui-components/loading";
import EuiRow from "../../../../eui-components/row";
import EuiTabs from "../../../../eui-components/tabs";
import AspPmPerformanceDetails from "./performanceDetails";
import PerformanceTrend from "./performanceTrend";
import SitesDetails from "./sitesDetails";


const DashboardData = (props) => {

    const dashboardData = useSelector(state => state.dashboard);
    const kpis = dashboardData?.kpis;
    const details = dashboardData?.sitesDetails;

    const [trendSelected, setTrendSelected] = useState(false);
    
    useEffect(() => {
        setTrendSelected(false);
    })
    const ericssonFTR = kpis.EricssonFTR ?? 0;
    const noSnags = kpis.NoSnags ?? 0;
    const teamEffiency = kpis.TeamEfficiency ?? 0;
    const readyForCustomerAcceptance = kpis.ReadyForCUstomeAcceptance ?? 0;
    const ehsPerformance = kpis.OhsPerformance ?? 0
    const overall = kpis.Overall;

    

    const keyPerformanceIndicator = <EuiRow key={kpis} style={{ marginTop: "5px", backgroundColor: "#ebebeb", zoom: "0.75" }} >
        <KpiCard id="overall" gaugeSettings={`{ "value":${overall ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85,  "color": "gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Overall Performance"
            kpiName=""
            percentageValue={overall}
            infoTip="Overall Performance"
            date="-"
        />
        <KpiCard id="EricssonFTR" gaugeSettings={`{ "value":${ericssonFTR?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85, "color": "gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Quality Control"
            percentageValue={ericssonFTR?.percentage || 0}
            infoTip="RSC QA Inspector update in ERITOP ASP activity accepted as FTR"
            actualValue={ericssonFTR?.successItemCount || 0}
            kpiName="Ericsson FTR"
            date="-"
            weightage={`Weightage: ${ericssonFTR?.weightage || 0}%`}
            barMax={ericssonFTR?.totalItemCount || 0}
            hideButton={true} />
        <KpiCard id="NoOfSnags" gaugeSettings={`{ "value":${noSnags?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85, "color":"gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Quality Control"
            percentageValue={noSnags?.percentage || 0}
            infoTip="How many rejected items in the activity"
            actualValue={noSnags?.successItemCount || 0}
            kpiName="No Snags"
            date="-"
            weightage={`Weightage: ${noSnags?.weightage || 0}%`}
            barMax={noSnags?.totalItemCount || 0}
            tipPosition="left"
            hideButton={true} />
        <KpiCard id="TeamEfficiencey" gaugeSettings={`{ "value":${teamEffiency?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85, "color": "gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            percentageValue={teamEffiency?.percentage || 0}
            infoTip="Actual duration of activity accepted by RSC  should (be less then or equal to )  < =  SLA duration defined per model sites"
            actualValue={teamEffiency?.successItemCount || 0}
            kpiName="Team Efficiency"
            kpiArea="Performance"
            date="-"
            weightage={`Weightage: ${teamEffiency?.weightage || 0}%`}
            barMax={teamEffiency.totalItemCount || 0}
            hideButton={true} />
        <KpiCard id="readyForCustomerAcceptance" gaugeSettings={`{ "value":${readyForCustomerAcceptance?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85, "color":"gray",  "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Performance"
            kpiName="Ready For Customer Acceptance"
            percentageValue={readyForCustomerAcceptance?.percentage || 0}
            infoTip="RSC QA Inspector update in ERITOP ASP activity ready for customer acceptance satus "
            actualValue={readyForCustomerAcceptance?.successItemCount || 0}
            date="-"
            weightage={`Weightage: ${readyForCustomerAcceptance?.weightage || 0}%`}
            barMax={readyForCustomerAcceptance?.totalItemCount || 0}
            hideButton={true} />
        <KpiCard id="EHSCompliance" gaugeSettings={`{ "value":${ehsPerformance?.percentage ?? 0},"min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85, "color": "gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
            kpiArea="Performance"
            kpiName="Quality Index <= 0.4"
            percentageValue={ehsPerformance?.percentage || 0}
            infoTip="Quality index is less than or equal 0.4 "
            actualValue={ehsPerformance?.successItemCount || 0}
            date="-"
            weightage={`Weightage: ${ehsPerformance?.weightage || 0}%`}
            barMax={ehsPerformance?.totalItemCount || 0}
            tipPosition="left"
            hideButton={true} />
    </EuiRow>;

    const siteDetails = <SitesDetails key={details} data={details.sitesDetails} />;

    const performanceDetails = <AspPmPerformanceDetails key={details || dashboardData.actualGroupingColumns} data={details.performanceDetails} />

    const performanceTrend = <PerformanceTrend selected={trendSelected} style={{ minWidth: "600px" }} className="w-10" id="trends" key={details.sitesDetails || trendSelected} data={details.sitesDetails} />;

    const tabContents =
        [{ value: 1, children: keyPerformanceIndicator, selected: "selected", id: "performanceIndicatorTab" },
        { value: 2, children: performanceDetails, selected: "", id: "performanceDetailsTab" },
        { value: 3, children: performanceTrend, selected: "", id: "performanceTrendTab" },
        { value: 4, children: siteDetails, selected: "", id: "siteDetaileTab" }];

    const tabTitles =
        [{ value: 1, name: "Key Performance Indicator", selected: "selected", onClick: () => { setTrendSelected(false) } },
        { value: 2, name: "Team Performance", selected: "", onClick: () => { setTrendSelected(false) } },
        { value: 3, name: "Performance Trend", selected: "", onClick: () => { setTrendSelected(true) } },
        { value: 4, name: "Sites Activity Details", selected: "", onClick: () => { setTrendSelected(false) } }];

    return (<EuiRow key={dashboardData.isLoading}
        disabled={dashboardData.isLoading ? "disabled" : ""}
    >
        <EuiLoading isLoading={dashboardData.isLoading} />
        <EuiTabs
            id="aspPmDashboardTabs"
            titlesColumnStyle={{ marginBottom: "10px" }}
            contentStyle={{ width: "100%" }}
            id="checklistsTabs"
            titles={tabTitles}
            contents={tabContents}
            tabStyle={{ width: "100%" }}
            viewActionButton={true}
        />

    </EuiRow>
    );


}

export default DashboardData;