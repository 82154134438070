import {dispatch} from "../store";
import ApiClient from "./api-client";
import constants from "./constants";
import DateUtils from "./date-utils";

export default class SasHelper {

    static async getSasKey(accountName = constants.account) {
        let key = 'GenerateSasToken';
        let sastoken = localStorage.getItem(key);
        if(this.isSasTokenValid(sastoken)) // check expired
        {
            return Promise.resolve(sastoken);
        }
        else
        {
            return await ApiClient.post("Session/GenerateSasToken", { text: accountName }).then(
                (res) => {
                    localStorage.setItem(key, res.data);
                    dispatch({ type: "SET_SAS_TOKEN", payload: res.data })

                    return res.data;
                }
            ).catch(error => {
                console.error(error);
            });
        }
    };

    
    static isSasTokenValid(s, isUrl = false)
    {
        if(!s)
        {
            return false;
        }
        else
        {
            try
            {
                var url = new URL(isUrl? s : constants.baseUrl + '?' + s);
                var st = url.searchParams.get("st");
                var se = url.searchParams.get("se");

                if(!se)
                {
                    return false;
                }

                var currentDate = DateUtils.getUtcDate();
                var startDate = new Date(st);
                var expiryDate = new Date(se);
                return currentDate >= startDate && currentDate < expiryDate;
            }
            catch (error)
            {
                return false;
            }
        }

    }

    static addSasKey(link, sastoken = '')
    {
        if(!link || !link.includes('eritop'))
        {
            return link;
        }
        if(link.includes('?'))
        {
            link = link.substring(0, link.indexOf('?'));
        }
        // if(this.isSasTokenValid(link, true))
        // {
        //     return link;
        // }
        if(this.isSasTokenValid(sastoken))
        {
            return link + '?' + sastoken;
        }
        sastoken = localStorage.getItem('GenerateSasToken')
        if(this.isSasTokenValid(sastoken))
        {
            return link + '?' + sastoken;
        }
        this.getSasKey();
        
        return link + '?' + sastoken;
    }
}
