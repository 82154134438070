import React, { useState, useEffect } from "react";
import EuiRow from "../../eui-components/row";
import { useSelector, useDispatch } from "react-redux";
import EuiRowWithToolbar from "../../eui-components/rowwithtoolbar";
import constants from "../../utils/constants";
import EuiSwitch from "../../eui-components/switch";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import AdvancedGridList from "../../components/advancedgridlist";
import EuiAccordion from "../../eui-components/accordion";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import PageHelper from "../../utils/page-helper";
import { toast } from "react-toastify";
import SimpleSlider from "../../components/slider";
import EuiPillGroup from "../../eui-components/pillgroup";
import ActivityHelper from "../../utils/activity-helper";
import EuiTooltip from "../../eui-components/tooltip";
import IMChecklistInfo from "./imChecklistInfo";
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import { useReducer } from "react";
import AcceptRejectDialogReducer from "./acceptRejectDialogReducer";
import DialogModal from "../../components/dialog";
import SeverityLevelDialog from "../sessions/approve-session/severityLevelDialog";
import EuiSelect from "../../eui-components/select";



const IMActivityChecklists = (props) => {

    const mod = useSelector(state => state.imInbox.mod);
    const currentImActivity = useSelector(state => state.imInbox.currentIMActivity);
    const user = useSelector(state => state.user.userData);
    const reduxDispacth = useDispatch();

    const [acceptRejectDialog, dispatch] = useReducer(AcceptRejectDialogReducer, {
        openDialog: false,
        reviewComments: "",
        status: 1,
        rejectReason: 5,
        selectedDocument: { id: 0, acceptanceStatusId: 1 },
        imgIndex: 0,
        totalCount: "",
        imgUrlLength: 0,
        openDialogPreview: false,
        allDocument: currentImActivity?.subactivities?.map(item => item.documents).reduce((a, b) => { return a?.concat(b); })
    });
    const [isLoading, setIsLoading] = useState(false);
    const [viewMod, setViewMod] = useState(constants.documentsView.GRID);
    const [approveExcludeSwitchRef, setApproveExcludeSwitchRef] = useState(false);
    const [completedExcludeSwitchRef, setCompletedExcludeSwitchRef] = useState(false);
    const [checklistDocumentsColumns, setChecklistDocumentsColumns] = useState([
        {
            title: "Name",
            key: "nameAndId",
            width: "10%"
        },
        {
            title: "Status",
            key: "acceptanceStatusId",
            width: "10%"
        },
        {
            title: "Site ASP Comments",
            key: "siteAspComments",
            width: "20%"
        },
        {
            title: "IM Comments",
            key: "reviewComments",
            width: "20%"
        },
        {
            title: "IM Name",
            key: "imName",
            width: "10%"
        },
    ]);
    const [filterTypes, setFilterTypes] = useState([constants.acceptanceStatusTypes.ACCEPTED + "", constants.acceptanceStatusTypes.REJECTED + "", constants.acceptanceStatusTypes.PENDING + ""]);
    const [allAcceptOrReject, setAllAcceptOrReject] = useState(0);
    const [openAllDialog, setOpenAllDialog] = useState(false);
    const [tableRefresh, toggleTableRefresh] = useState(false);

    const pills = [{ value: 1, text: ("Pending " + "(" + ActivityHelper.getDocumentTypesCount(acceptRejectDialog.allDocument, 1) + ")"), color: "#EBEBEB", style: { marginBottom: 0 } },
    { value: 2, text: ("Accepted " + "(" + ActivityHelper.getDocumentTypesCount(acceptRejectDialog.allDocument, 2) + ")"), color: "#E6F1EB", style: { marginBottom: 0 } },
    { value: 3, text: ("Rejected " + "(" + ActivityHelper.getDocumentTypesCount(acceptRejectDialog.allDocument, 3) + ")"), color: "#F9E2E1", style: { marginBottom: 0 } }];


    useEffect(() => {
        toggleTableRefresh(!tableRefresh);
    }, [filterTypes]);

    useEffect(() => {
        
        if (acceptRejectDialog.selectedDocument.event) {
            handleChange(acceptRejectDialog.selectedDocument.event);
        }
    }, [acceptRejectDialog.selectedDocument]);

    const renderRow = (tr, rowData) => {
        if (rowData.id === acceptRejectDialog.selectedDocument.id) {
            tr.style.background = "#E9F0F9";
        }
        tr.getElementsByTagName("td")[0].innerHTML = renderName(rowData);
        tr.getElementsByTagName("td")[0].querySelector("#imactivityDocumentName_" + rowData.id).addEventListener('click', () => selectAndOpenDocument(rowData));
        tr.getElementsByTagName("td")[1].innerHTML = renderStatus(rowData);
        tr.getElementsByTagName("td")[1].querySelector("#status_activity_document_" + rowData.id)
            .addEventListener('change', (e) => { dispatch({ type: "SET_SELECTED_DOCUMENT", payload: { ...rowData, event: e } }); });
    }

    const renderChecklists = () => {
        const checklists = currentImActivity.subactivities;
        
        const checklistAccordions = checklists?.map(item => {
            const checklistItem = {
                id: "imActivityChecklist_" + item.id,
                hidden: isHiddenChecklist(item),
                title: getSubActivityName(item),
                titleStyle: { fontWeight: 700, padding: 3 },
                content: <EuiMultiPanelTile key={item.id}
                    id={"imChecklistMultiPanel_" + item.id}
                    midPanelButton={true}
                    midPanel={{
                        title: "",
                        trigger: "Checklist Information",
                        triggerStyle: { marginLeft: 10 },
                        triggerId: "imChecklistTrigger_" + item.id,
                        hideSeperator: true,
                        hideHeader: true,
                        style: { paddingRight: 0 },
                        leftClassName: "pl-2",
                        content: <>
                            <div className={viewMod !== constants.documentsView.LIST ? "hidden" : ""} key={tableRefresh}>
                                <ObseleteEuiTable
                                    classes="tiny"
                                    columns={checklistDocumentsColumns}
                                    data={getDocuments(item)}
                                    id={"imActivityChecklistTable_" + item.id}
                                    scroll={true}
                                    sortable={true}
                                    onCreatedRow={(tr, rowData) => renderRow(tr, rowData)}
                                >
                                </ObseleteEuiTable>
                            </div >
                            {
                                viewMod !== constants.documentsView.GRID ? null :
                                    <AdvancedGridList
                                        tileData={getDocuments(item)}
                                        selectedDocument={(tile) => dispatch({ type: "SET_SELECTED_DOCUMENT", payload: tile })}
                                        onClickApprove={() => handleDialog(constants.acceptanceStatusTypes.ACCEPTED)}
                                        onClickReject={() => handleDialog(constants.acceptanceStatusTypes.REJECTED)}
                                        onClickPending={() => changeStatus(constants.acceptanceStatusTypes.PENDING)}
                                        viewOnly={currentImActivity.viewOnly}
                                        onClickImage={(tile) => { selectAndOpenDocument(tile) }}
                                        type={3}
                                    />
                            }
                        </>
                    }}
                    rightPanel={{
                        title: "Checklist Information", hidden: true, content: <IMChecklistInfo rowData={item} changeChecklistAllStatus={(checklist, status) => changeChecklistAllStatus(item, status)} />
                    }}
                    style={{ padding: 5 }}
                />


            };
            return checklistItem;
        });
        return <EuiAccordion id="imActivityChecklistAccordion"
            style={{ width: "100%" }}
            items={checklistAccordions}
        />;
    }

    const renderStatus = (rowData, preview = "") => {
        const id = rowData.id + "_status";
        const color = PageHelper.renderActivityStatusColor(rowData.acceptanceStatusId);
        const disabled = currentImActivity.viewOnly ? "disabled" : "";
        const value = constants.acceptanceStatusLookup[rowData.acceptanceStatusId || 1];

        const optionList = constants.statusList.filter(item => item.value !== 4).reduce((a, b) => {
            let selected = "";
            if (b.value === rowData.acceptanceStatusId) {
                selected = "selected";
            }
            a += `<option ${selected} value="${b.text}">${b.text}</option>`;
            return a;
        }, "");

        if (preview === "") {
            return `<div key=${id} class="flex-start-content w-10">
            <i class="icon icon-alarm-level4 color-${color}" style="width:7%;"}> </i>
            <select id="status_activity_document_${rowData.id}" value="${value}"
                  ${disabled}
                  style="height:30px;background:inherit;border:none;">
                  ${optionList}
            </select>           
        </div>`;
        } else {
            return <div key={rowData.id + "_status_preview"} className="flex-start-content w-10">
                <i className={"icon icon-alarm-level4 color-" + color} style={{ width: "7%" }}> </i>
                <EuiSelect
                    style={{
                        background: "inherit", border: "none",
                        color: "#fff",
                        fontSize: 17
                    }}
                    optionListStyle={{ position: "inherit" }}
                    options={constants.statusList.filter(s => s.value != 4)}
                    value={rowData.acceptanceStatusId || 1}
                    name={"status" + rowData.id}
                    id={"status" + rowData.id + preview}
                    onChange={e => handleChange(e, preview)}
                    disabled={currentImActivity.viewOnly}
                />
            </div>
        }
    }

    const isHiddenChecklist = (checklist) => {
        return (approveExcludeSwitchRef && ((checklist.documents || []).length === 0
            || checklist.documents.filter(doc => doc.acceptanceStatusId !== constants.acceptanceStatusTypes.ACCEPTED).length === 0))
            || (completedExcludeSwitchRef && !checklist.completed)
            //|| getDocuments(checklist).length === 0
    }

    const getSubActivityName = (rowData) => {
        const color = ActivityInboxHelper.getSubActivityColor(rowData);
        const icon = color === "" ? "icon-circle" : "icon-alarm-level5 color-" + color;
        return <i className={"flex-middle-content text-sm icon " + icon}>
            <span className="subactivity-title">
                {rowData.name + " ( " + (rowData.documents ?? []).length + " ) "}
            </span>
            {rowData.completed ? <i className="icon icon-check text-lg color-green"></i> : <i className="icon icon-minus text-lg color-red"></i>}

        </i>
    }

    const getDocuments = (checklistItem) => {
        if ((checklistItem.documents || []).length === 0) {
            return [];
        }
        
        if (filterTypes.length === 3) {
            return checklistItem.documents;
        } else if (filterTypes.length === 2) {
            if (filterTypes.reduce((a, b) => a + parseInt(b), 0) === 3) {
                return checklistItem.documents.filter(item => item.acceptanceStatusId != constants.acceptanceStatusTypes.REJECTED);
            } else if (filterTypes.reduce((a, b) => a + parseInt(b), 0) === 4) {
                return checklistItem.documents.filter(item => item.acceptanceStatusId != constants.acceptanceStatusTypes.ACCEPTED);
            } else if (filterTypes.reduce((a, b) => a + parseInt(b), 0) === 5) {
                return checklistItem.documents.filter(item => item.acceptanceStatusId != constants.acceptanceStatusTypes.PENDING);
            }
        } else if (filterTypes.length === 1) {
            if (filterTypes.reduce((a, b) => a + parseInt(b), 0) === 1) {
                return checklistItem.documents.filter(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING);
            } else if (filterTypes.reduce((a, b) => a + parseInt(b), 0) === 2) {
                return checklistItem.documents.filter(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED);
            } else if (filterTypes.reduce((a, b) => a + parseInt(b), 0) === 3) {
                return checklistItem.documents.filter(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED);
            }
        } else {
            return [];
        }
    }

    const renderName = (rowData) => {
        const view = PageHelper.getEDSDocumentType(rowData.type);
        let color = PageHelper.getEDSDocumentTypeColor(rowData.type);
        return `<a id="imactivityDocumentName_${rowData.id}" class="flex-start-content ${color}">${view}<span>${rowData.name}</span></a>`;
    }

    const selectAndOpenDocument = (document) => {
        
        if (!document) {
            toast.error("Coudn't open the file!", { containerId: 'IM', position: toast.POSITION.TOP_CENTER });
            return;
        }
        
        setImageTrack(document);
    }

    const setImageTrack = (slide) => {
        const imgUrlLength = acceptRejectDialog.allDocument.length;
        const imgIndex = acceptRejectDialog.allDocument.findIndex(item => item.id === slide.id);
        dispatch({
            type: "SET_DOCUMENT_VIEW_DIALOG", payload: {
                selectedDocument: slide,
                imgIndex,
                imgUrlLength,
                totalCount: (imgIndex + 1) + "/" + imgUrlLength,
                openDialogPreview: true,
            }
        });
        toggleTableRefresh(!tableRefresh);
    }

    const nextClick = () => {
        const imgIndex = acceptRejectDialog.imgIndex === acceptRejectDialog.imgUrlLength - 1 ? 0 : acceptRejectDialog.imgIndex + 1;
        const selectedDocument = acceptRejectDialog.allDocument[imgIndex];
        const totalCount = (imgIndex + 1) + "/" + acceptRejectDialog.imgUrlLength;
        dispatch({
            type: "SET_DOCUMENT_VIEW_DIALOG", payload: {
                imgIndex,
                selectedDocument,
                totalCount,
            }
        });
        toggleTableRefresh(!tableRefresh);
    }

    const prevClick = () => {
        const imgIndex = acceptRejectDialog.imgIndex === 0 ? acceptRejectDialog.imgUrlLength - 1 : acceptRejectDialog.imgIndex - 1;
        const selectedDocument = acceptRejectDialog.allDocument[imgIndex];
        const totalCount = (imgIndex + 1) + "/" + acceptRejectDialog.imgUrlLength;
        dispatch({
            type: "SET_DOCUMENT_VIEW_DIALOG", payload: {
                imgIndex,
                selectedDocument,
                totalCount,
            }
        });
        toggleTableRefresh(!tableRefresh);
    }

    const renderFilePreview = () => {
        const comments = acceptRejectDialog.selectedDocument.reviewComments ?? "";
        const severityLevel = PageHelper.renderSeverityLevel(acceptRejectDialog.selectedDocument.rscSeverityLevel);
        return <SimpleSlider
            id="imActivitySlider"
            slideData={acceptRejectDialog.allDocument}
            currentSlide={acceptRejectDialog.selectedDocument}
            initialSlide={acceptRejectDialog.imgIndex}
            totalCount={acceptRejectDialog.totalCount}
            onNext={nextClick}
            onPrev={prevClick}
            onClose={() => dispatch({ type: "TOGGLE_DOCUMENT_DIALOG" })}
            status={renderStatus(acceptRejectDialog.selectedDocument, "preview")}
            severityLevel={severityLevel}
            comment={comments}
            setImageTrack={(slide) => setImageTrack(slide)}
            commentTitle="IM Comments"
            siteComment={acceptRejectDialog.selectedDocument.siteAspComments}
            type={3}
            editImage={true}
            jobRole={user.jobRole}
        />
    }

    const handleChangeFilter = (filterTypes) => {
        setFilterTypes(filterTypes);
    }

    const handleChange = (e, preview = "") => {
        
        const target = e.target;
        const value = preview === "" ? constants.statusList.find(item => item.text === target.value)?.value : target.value;

        if (value === constants.acceptanceStatusTypes.ACCEPTED || value === constants.acceptanceStatusTypes.REJECTED) {
            handleDialog(value);
        } else if (value === constants.acceptanceStatusTypes.PENDING) {
            changeStatus(constants.acceptanceStatusTypes.PENDING);
        }
    }

    const handleDialog = (value) => {
        
        const reviewComments = acceptRejectDialog.selectedDocument.reviewComments;
        const rejectReason = acceptRejectDialog.selectedDocument.rscSeverityLevel || 5;
        dispatch({
            type: "SET_ACCEPT_REJECT_DIALOG",
            payload: {
                openDialog: true,
                reviewComments,
                rejectReason: value === constants.acceptanceStatusTypes.REJECTED ? rejectReason : null,
                status: value,
            }
        });

        
    }

    const changeStatus = (newStatus) => {
        
        const activity = { ...currentImActivity };
        const status = newStatus ?? acceptRejectDialog.status;
        const reviewComments = newStatus ? null : acceptRejectDialog.reviewComments;
        const rejectReason = newStatus ? 0 : acceptRejectDialog.rejectReason;
        const document = { ...acceptRejectDialog.selectedDocument };
        document.reviewComments = reviewComments
        document.acceptanceStatusId = status;
        document.reviewDate = new Date();
        document.imUserId = parseInt(user.userId);
        document.imName = user.username;
        document.rscSeverityLevel = rejectReason;
        if (document.event) {
            delete document["event"];
        }

        const subactivities = [...activity.subactivities];
        const i = subactivities.findIndex(item => item.id == document.subactivityId);
        const documents = subactivities[i] && [...subactivities[i].documents];
        documents[documents.findIndex(item => item.id === document.id)] = document;
        subactivities[i].documents = documents;
        activity.subactivities = subactivities;

        let newAllDocument = [...acceptRejectDialog.allDocument];
        newAllDocument[newAllDocument.findIndex(item => item.id === document.id)] = document;

        if (!newAllDocument.some(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING)) {
            activity.readyForRate = true;
        } else {
            activity.readyForRate = false;
        }

        reduxDispacth({ type: "SET_CURRENT_IM_ACTIVITY", payload: activity });
        dispatch({ type: "SET_SELECTED_AND_ALL_DOCUMENT", payload: { allDocument: newAllDocument, selectedDocument: document } });
        toggleTableRefresh(!tableRefresh);
    }

    const changeAllStatus = (status) => {
        const activity = { ...currentImActivity };

        if (activity.subactivities && activity.subactivities.length > 0) {
            activity.subactivities.map(subItem => {
                if (subItem.documents && subItem.documents.length > 0) {
                    subItem.documents.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING).map(document => {
                        document.acceptanceStatusId = status;
                        document.reviewDate = new Date();
                        document.imUserId = parseInt(user.userId);
                        document.imName = user.username;
                        document.rscSeverityLevel = status === constants.acceptanceStatusTypes.REJECTED ? acceptRejectDialog.rejectReason : null;
                    });
                }
            });
        }
        activity.readyForRate = true;
        

        let newAllDocument = [...acceptRejectDialog.allDocument];
        if (newAllDocument.length > 0) {
            newAllDocument.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING).map(document => {
                document.acceptanceStatusId = status;
                document.imUserId = parseInt(user.userId);
                document.imName = user.username;
                document.rscSeverityLevel = status === constants.acceptanceStatusTypes.REJECTED ? acceptRejectDialog.rejectReason : null;
            });
        }

        reduxDispacth({ type: "SET_CURRENT_IM_ACTIVITY", payload: activity });
        dispatch({ type: "SET_ALL_DOCUMENT", payload: newAllDocument });
        toggleTableRefresh(!tableRefresh);
        setOpenAllDialog(false);
    }

    const changeChecklistAllStatus = (checklist, status) => {
        if (!checklist || !checklist.documents || checklist.documents.length === 0) {
            return;
        }

        const activity = { ...currentImActivity };

        if (activity.subactivities && activity.subactivities.length > 0) {
            activity.subactivities.filter(s => s.id === checklist.id).map(subItem => {
                if (subItem.documents && subItem.documents.length > 0) {
                    subItem.documents.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING).map(document => {
                        document.acceptanceStatusId = status;
                        document.reviewDate = new Date();
                        document.imUserId = user.userId;
                        document.imName = user.username;
                        document.rscSeverityLevel = status === constants.acceptanceStatusTypes.REJECTED ? acceptRejectDialog.rejectReason : null;
                    });
                }
            });
        }
        

        let newAllDocument = [...acceptRejectDialog.allDocument];
        if (newAllDocument.length > 0) {
            newAllDocument.filter(d => d.subactivityId === checklist.id && d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING).map(document => {
                document.acceptanceStatusId = status;
                document.imUserId = user.userId;
                document.imName = user.username;
                document.rscSeverityLevel = status === constants.acceptanceStatusTypes.REJECTED ? acceptRejectDialog.rejectReason : null;
            });
        }

        if (!newAllDocument.some(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING)) {
            activity.readyForRate = true;
        } else {
            activity.readyForRate = false;
        }

        reduxDispacth({ type: "SET_CURRENT_IM_ACTIVITY", payload: activity });
        dispatch({ type: "SET_ALL_DOCUMENT", payload: newAllDocument });
        toggleTableRefresh(!tableRefresh);
        setOpenAllDialog(false);
    }


    return (<EuiRow>
        <EuiRowWithToolbar className={"mt-1" + (isLoading ? "disabled" : "")} contentStyle={{ overflow: "hidden", height: "100%" }} leftIcons={<>
            <EuiSwitch id="imActivityViewCompletedSwith"
                checked={completedExcludeSwitchRef}
                onChange={(e) => setCompletedExcludeSwitchRef(e.target.checked)}
                dataEnabled="Hiding Completed"
                dataDisabled="Showing Completed"
                className="p-1"
            />
            {mod != constants.pageMods.VIEW_ONLY ?
                <>
                    <EuiTooltip title="Accept All" position="left">
                        <i className={"icon icon-check icon-lg m-1 icon-frame "} onClick={() => { setOpenAllDialog(true); setAllAcceptOrReject(constants.acceptanceStatusTypes.ACCEPTED) }}></i>
                    </EuiTooltip>
                    <EuiTooltip title="Reject All" position="left">
                        <i className={"icon icon-cross icon-lg m-1 icon-frame "} onClick={() => { setOpenAllDialog(true); setAllAcceptOrReject(constants.acceptanceStatusTypes.REJECTED) }}></i>
                    </EuiTooltip>
                </>
                : null
            }
            <i className={"icon icon-view-list icon-lg m-1 " + (viewMod === constants.documentsView.LIST ? "fw-3" : "")} onClick={() => setViewMod(constants.documentsView.LIST)}></i>
            <i className={"icon icon-view-tiles icon-lg m-1 " + (viewMod === constants.documentsView.GRID ? "fw-3" : "")} onClick={() => setViewMod(constants.documentsView.GRID)}></i>
        </>
        }
            rightIcons={<>
                <EuiPillGroup id="imActivityChecklistPillsId" pills={pills} onChange={(filteredPills) => handleChangeFilter(filteredPills)} />
            </>}
        >
            {renderChecklists()}

        </EuiRowWithToolbar >

        {
            (acceptRejectDialog.openDialogPreview ?
                renderFilePreview()
                :
                null)
        }


        <DialogModal
            buttonName="Ok"
            content={<SeverityLevelDialog rejectReason={acceptRejectDialog.rejectReason}
                rejectDescription={acceptRejectDialog.reviewComments}
                getRejectReason={(rejectReason) => dispatch({ type: "HANDLE_DIALOG_CHANGE", field: "rejectReason", payload: rejectReason })}
                getRejectDescription={(rejectDescription) => dispatch({ type: "HANDLE_DIALOG_CHANGE", field: "reviewComments", payload: rejectDescription })}
            />}
            handleClose={() => dispatch({ type: "TOGGLE_DIALOG" })}
            id="approveRejectImActivityDialog"
            onClick={() => { dispatch({ type: "TOGGLE_DIALOG" }); changeStatus(); }}
            open={acceptRejectDialog.openDialog ?? false}
            title="IM Comments"
        >
        </DialogModal>

        <DialogModal
            buttonName="Confirm"
            content={<p>{"Are you sure you want to" + (allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? " accept " : " reject ") + "all pending documents?"}</p>}
            handleClose={() => setOpenAllDialog(false)}
            id="allAcceptRejectimActivityDialog"
            onClick={() => changeAllStatus(allAcceptOrReject)}
            open={openAllDialog}
            title={allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "Accept All" : "Reject All"}
            type={allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "primary" : "warning"}
        >
        </DialogModal>

    </EuiRow>);

}

export default IMActivityChecklists;