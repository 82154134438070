import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import { toast } from "react-toastify";
import WorkplanHelper from "../../utils/workplan-helper";
import ApiClient from "../../utils/api-client";
import EuiDatepicker from "../../eui-components/datepicker";


class EhsPingFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        const filter = { ...this.props.ehsPingData.filter };
        if (name == "ratingDate" && !value)
            filter[name] = null;
        else
            filter[name] = value || "";
        this.props.setEhsPingFilter(filter);
    }

    getEhsPings = (page, pageSize) => {
        const filter = this.props.ehsPingData.filter;

        delete filter["page"];
        delete filter["pageSize"];

        if (!filter || Object.values(filter).every(x => (!x || x === ""))) {
            toast.error("Please, fill any field to get related activities", { containerId: "ES", position: toast.POSITION.TOP_CENTER });
            return;
        }
        filter.page = page;
        filter.pageSize = pageSize;
        filter.id = parseInt(filter.id ?? "0");
        filter.activityId = parseInt(filter.activityId ?? "0");
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/GetFilteredEhsPings", filter)
                .then(response => {
                    this.props.setFilteredEhsPings({ data: [], totalCount: 0 });
                    const data = [...response.data.data];
                    WorkplanHelper.mapEhsPings(data);
                    
                    this.setState({ isLoading: false }, () => {
                        this.props.setFilteredEhsPings({ data, totalCount: response.data.totalCount });
                        
                        this.props.changeInitialState();
                    });
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });

    }

    render() {
        return (<EuiRow>
            <EuiTextField className="m-1 w-10" fullwidth id="ehsPingIdFilter" name="id" label="EHS Ping ID (=)" labelClass="color-gray"
                value={this.props.ehsPingData.filter.id || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "id" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="ehsPingActivityIdFilter" name="activityId" label="Activity ID (=)" labelClass="color-gray"
                value={this.props.ehsPingData.filter.activityId || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "activityId" } })}
            />

            <EuiTextField className="m-1 w-10" fullwidth id="ehsWorkplanNameFilter" name="workplanName" label="Workplan Name (*)" labelClass="color-gray"
                value={this.props.ehsPingData.filter.workplanName || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "workplanName" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="ehsProjectNameFilter" name="projectName" label="Project Name (*)" labelClass="color-gray"
                value={this.props.ehsPingData.filter.projectName || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "projectName" } })}
            />
            <EuiDatepicker className="m-1 w-10" fullwidth id="ehsPingsRatingDate" name="ratingDate" label="Rating Date (=)" labelClassName="color-gray"
                value={this.props.ehsPingData.filter.ratingDate || ""} onChange={(e) => this.handleChange(e)}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="ehsSiteSupervisorNameFilter" name="ehsSupervisorName" label="EHS Supervisor Name (*)" labelClass="color-gray"
                value={this.props.ehsPingData.filter.ehsSupervisorName || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "ehsSupervisorName" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="ehsPingTeamLeadFilter" name="teamLead" label="Team Lead (*)" labelClass="color-gray"
                value={this.props.ehsPingData.filter.teamLead || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "teamLead" } })}
            />

            <button className={"btn primary m-1 " + (this.state.isLoading ? "loading disabled" : "")} onClick={() => this.getEhsPings(1, 30)}>Search</button>
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ehsPingData: state.ehsPing });
const mapDispatchToProps = dispatch => {
    return {
        setFilteredEhsPings: (filteredEhsPings) => dispatch({ type: "SET_FILTERED_EHS_PINGS", payload: filteredEhsPings }),
        setEhsPingFilter: (filter) => dispatch({ type: "SET_EHS_PING_FILTER", payload: filter }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EhsPingFilter);