import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTile from "../../eui-components/tile";
import ObseleteEuiTable from "../../eui-components/obseleteTable";

class TeamLogs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            openDialog: false,
            openDeleteDialog: {},
            certificationColumns:
                [
                    {
                        title: "User Name",
                        key: "user",
                        onCreatedCell: (td, cellData) => {
                            td.innerHTML = `${cellData.name}`;
                        },
                        width: "15%",
                        headerStyle: "padding:2px;"
                    },
                    {
                        title: "User Email",
                        key: "user",
                        onCreatedCell: (td, cellData) => {
                            td.innerHTML = `${cellData.mail}`;
                        },
                        width: "15%",
                        headerStyle: "padding:2px;"
                    },
                    {
                        title: "Action",
                        key: "logAction",
                        width: "5%",
                        headerStyle: "padding:2px;"
                    },
                    {
                        title: "Log Name",
                        key: "logName",
                        width: "5%",
                        headerStyle: "padding:2px;"
                    },
                    {
                        title: "Log Detail",
                        key: "logDetail",
                        onCreatedCell: (td, cellData) => {
                            let data = null;
                            try {
                                data = JSON.parse(cellData);
                            }
                            catch (e) {
                                data = null;
                            }
                            finally {
                                if (data) {
                                    let detail = ''
                                    if (data.oldData.minCertificateNumber !== data.newData.minCertificateNumber)
                                        detail += `Required certificate changed: ${data.newData.minCertificateNumber}. \n`;
                                    if (data.oldData.isActive !== data.newData.isActive) {
                                        let s = data.newData.isActive ? 'Certificate Reactivated' : 'Certificate deactivated';
                                        detail += `${s} : ${data.newData.userCertificates.name}. \n`;
                                    }
                                    if (data.oldData.name !== data.newData.name)
                                        detail += `Name changed to : ${data.newData.name}. \n`;
                                    if (data.oldData.customerId !== data.newData.customerId)
                                        detail += `CustomerId changed to : ${data.newData.customerId}. \n`;
                                    if (data.oldData.comment !== data.newData.comment)
                                        detail += 'Comment changed.'
                                    td.innerHTML = `${detail}`;
                                }
                                else {
                                    td.innerHTML = `${cellData}`;
                                }
                            }
                        },
                        width: "25%",
                        headerStyle: "padding:1px;"
                    },
                    {
                        title: "Date Time",
                        key: "dateTimeUTC",
                        width: "15%",
                        headerStyle: "padding:1px;"
                    }
                ],
        }
    }

    render() {
        return (<EuiRow>
            <EuiTile>
                <ObseleteEuiTable
                    classes="very-tiny"
                    columns={this.state.certificationColumns}
                    data={this.props.teamData.team.teamActivityLogs}
                    hideTableInfo={true}
                    id="teamActivityLogsTable"
                    scroll={true}
                    sortable={true}
                    styleHeader={{ fontSize: 20 }}
                />
            </EuiTile>
        </EuiRow>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.user,
    sessionData: state.session,
    teamData: state.team,
});
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamLogs);
