import React from 'react';
import { Button,  Badge } from '@material-ui/core';
import PropTypes from 'prop-types';

export default function Pill(props) {

	const {
		disabled,
		onClick,
		selected,
		title,
		className
	} = props;

	return <button
		{...props}
		className={"pill " + (selected? "pill-selected ": '') + className }
		disabled={disabled}
		onClick={onClick}
		>
		{title}
	</button>
}
