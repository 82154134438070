import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import constants from "../utils/constants";
import DialogModal from "../components/dialog";
import PrivacyPolicyDetails from '../pages/policy/PrivacyPolicyDetails';
import EuiSelect from "../eui-components/select";
import DateUtils from "../utils/date-utils";
import packageJson from "../../package.json";
import ApiClient from "../utils/api-client";
import AutoAssignmentHelper from "../utils/auto-assignment-helper";
import { authContext } from "../utils/adalConfig";

class Syspanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            theme: "Light",
            openDialog: false,
            rscCenters: this.props.userData?.rscCenters?.map(r => {
                return {
                    ...r,
                    hidden: !this.props.userData?.userRscCenters?.find(c => c.value === r.value),
                }
            }),
            rscPerson: this.props.userData?.rscPerson
        };
    }

    userRole = () => {
        const role = this.props.userData?.loginRole;
        return constants.loginRoles.find(l => l.value === role)?.text;
    }

    changeTheme = () => {
        if (document.body.className === "light") {
            document.body.className = "dark"
            this.props.changeTheme("dark");
        }
        else {
            document.body.className = "light";
            this.props.changeTheme("light");
        }
    }

    handlePrivacyClick = () => {
        this.setState({ openDialog: true })
        this.props.togglePanel();
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        const userData = { ...this.props.userData.userData };
        const user = { ...this.props.userData };

        if (name === "jobRole") {
            userData.jobRole = value.toString();
            user.userData = userData;
            if (constants.rscRoles.includes(value.toString())) {
                user.userRscCenters = user.loginRoles.find(l => l.value === value)?.rscCenters;
                const rscCenters = this.state.rscCenters;
                rscCenters.forEach(r => {
                    r.hidden = !user.userRscCenters.find(c => c.value === r.value);
                });

                ApiClient.post("AutoAssignment/GetRscPersonLastStatus", { id: user.userRscCenters?.[0]?.id })
                    .then(response => {
                        if (response.data.success) {

                            AutoAssignmentHelper.mapRscPerson(response.data);

                            this.setState({ rscCenters, rscPerson: { ...user.userRscCenters[0], ...response.data } }, () => {
                                user.rscPerson = this.state.rscPerson;
                                this.props.login(user);
                                this.props.setRscPerson(this.state.rscPerson);
                                this.props.history.push("/");
                                ///console.log('reload');
                                window.location.reload();
                            });
                        }
                    })
                    .catch(error => { console.error(error) });

            } else {
                user.rscPerson = { id: 0 };
                this.props.login(user);
                this.props.history.push("/");
                ///console.log('reload');
                window.location.reload();
            }
            this.props.login(user)
        } else if (name === "rscCenter") {
            var selectedRscPerson = user.userRscCenters.find(item => item.value === value);
            ApiClient.post("AutoAssignment/GetRscPersonLastStatus", { id: selectedRscPerson?.id })
                .then(response => {
                    if (response.data.success) {

                        AutoAssignmentHelper.mapRscPerson(response.data);

                        this.setState({ rscPerson: { ...selectedRscPerson, ...response.data } }, () => {
                            user.rscPerson = this.state.rscPerson;
                            this.props.login(user);
                            this.props.setRscPerson(this.state.rscPerson);
                            this.props.history.push("/");
                            ///console.log('reload');
                            window.location.reload();
                        });
                    }
                })
                .catch(error => { console.error(error) });
        }

    }

    logout = () => {

        const rscPerson = this.props.userData?.rscPerson;

        if (rscPerson?.id && rscPerson?.checkin) {

            var request = {
                rscPersonId: rscPerson.id,
                checkin: false,
            };
            async function checkout() {
                await ApiClient.post("AutoAssignment/CheckinCheckout", request)
                    .then(r=> ApiClient.checked(request));
            }
            checkout();
        }

        this.props.togglePanel();
        this.props.logout();
        authContext.logOut()
       // this.props.history.push("/signin");
    }

    render() {
        return (
            <>
                <aside className={"syspanel " + (this.props.navData.panelView ? "" : "hidden")} style={{ zIndex: 9999, width: (this.props.navData.panelView ? "auto" : null) }}>
                    <div className="settings">
                        <div className="syspanel-top">
                            <span className="syspanel-title"></span>
                            <i
                                onClick={this.props.togglePanel}
                                className="syspanel-close icon icon-cross"
                            ></i>
                        </div>
                        <div className="row">
                            <div className="column sm-12 container">
                                <div className="profile">
                                    <i className="icon icon-profile"></i>
                                    <div className="username">
                                        {
                                       // this.props.userData.userData.username
                                       this.props.userData?.userData?.name
                                        }
                                    </div>
                                    <div className="title m-1" style={{ fontWeight: "lighter", color: "rgb(118,118,118)", fontSize: "14px" }}>
                                        {
                                       // this.props.userData.userMail
                                       this.props.userData?.userData?.userMail ? this.props.userData?.userData?.userMail : this.props.userData?.userData?.unique_name
                                        }
                                    </div>
                                    <div className="content" style={{ fontFamily: "Ericsson Hilda, Helvetica, Arial, sans-serif" }}>
                                        <div className="item"><p className="title">Links</p></div>
                                        <div className="item">
                                            <p className="title" style={{ color: "#A0A0A0", fontSize: "14px", margin: "0 0 14px" }}>Login Role</p>
                                            <div className="left" />
                                            <div className="right" style={{ fontSize: "14px", color: "rgb(160, 160, 160)" }}> {this.userRole()}</div>
                                        </div>
                                        <div className="item flex-start-content mb-2">
                                            <p className="title" style={{ color: "#A0A0A0", fontSize: "15px", marginBottom: 0, marginRight: 18 }}>Job Role</p>
                                            <div className="left" />
                                            <div className="right" style={{ fontSize: "14px", color: "rgb(160, 160, 160)", width: 240 }}>
                                                <EuiSelect className="m-1 w-10"
                                                    fullwidth
                                                    id="userJobRoleSysbar"
                                                    name="jobRole"
                                                    options={this.props.userData.loginRoles || []}
                                                    value={parseInt(this.props.userData.userData.jobRole || "-1")}
                                                    onChange={(e) => this.handleChange(e)}
                                                    placeholder="Primary Role"
                                                    style={{ color: "#A0A0A0", border: "none" }}
                                                />
                                            </div>
                                        </div>
                                        {constants.rscRoles.includes(this.props.userData.userData.jobRole) ?
                                            <div className="item flex-start-content mb-2">
                                                <p className="title" style={{ color: "#A0A0A0", fontSize: "15px", marginBottom: 0 }}>RSC Center</p>
                                                <div className="left" />
                                                <div className="right" style={{ fontSize: "14px", color: "rgb(160, 160, 160)", width: 240 }}>
                                                    <EuiSelect className="m-1 w-10"
                                                        fullwidth
                                                        id="userRscCenterSysbar"
                                                        name="rscCenter"
                                                        options={this.state.rscCenters || []}
                                                        value={this.state.rscPerson?.rscCenterId}
                                                        onChange={(e) => this.handleChange(e)}
                                                        placeholder="---Select RSC Center---"
                                                        style={{ color: "#A0A0A0", border: "none" }}
                                                    />
                                                </div>
                                            </div>
                                            : null
                                        }
                                        <div className="item mt-3">
                                            <p className="title" style={{ color: "#A0A0A0", fontSize: "14px", margin: "0 0 14px" }}>Switch Theme</p>
                                            <div className="left" />
                                            <div className="right">
                                                <label className="switch disabled">
                                                    <input type="checkbox" defaultChecked onChange={this.changeTheme} />
                                                    <i className="ball" />
                                                    <span data-enabled="Light" data-disabled="Dark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{ marginBottom: 100, marginTop: "100px" }} className="bottom">
                                    <button
                                        type="button"
                                        className="btn big"
                                        onClick={() => this.logout()}
                                    >
                                        <i className="icon icon-logout"></i> Sign out
                                    </button>
                                    <p style={{ textAlign: "center" }}>
                                        <button className="btn text" style={{ padding: "6px 12px", color: "#0069C2", border: "none", fontSize: "14px" }} onClick={this.handlePrivacyClick}>Privacy</button>
                                        <br />
                                        <span style={{ fontSize: "14px", color: "#FFFFFFCC" }}>{new Date().getFullYear()} @Ericsson AB</span>
                                        <br />
                                        <span style={{ fontSize: "14px", color: "#FFFFFFCC" }}>All Rights Reserved</span>
                                        <br />
                                        <br />
                                        <span style={{ fontSize: "14px", color: "#FFFFFFCC" }}>{DateUtils.toDateString(packageJson.buildDate, "YYYY-MM-DD HH:mm")}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>

                <DialogModal
                    buttonName="OK"
                    buttonsVisible="false"
                    content={
                        <div>
                            <div className="pl-3">
                                <a className="privacy-link" target="_blank" onClick={() => window.open(require("../assets/Privacy Notice and Consent_ERITOPASP.docx.pdf"))} >Privacy Notice and Consent of ERITOP ASP</a>
                                <a style={{ paddingLeft: "15px" }} href={require("../assets/Privacy Notice and Consent_ERITOPASP.docx.pdf")} target="_blank" download="" className="download-privacy"><i className="icon icon-download-save"></i></a>
                            </div>
                            <PrivacyPolicyDetails />
                            <button style={{ float: "right" }} type="button" className="btn primary" onClick={() => this.setState({ openDialog: false })}>OK</button>

                        </div>
                    }
                    handleClose={() => this.setState({ openDialog: false })}
                    id="privacy"
                    onClick={() => this.setState({ openDialog: false })}
                    open={this.state.openDialog}
                    title="Privacy"
                >
                </DialogModal>

            </>
        );
    }
}

const mapStateToProps = state => ({
    navData: state.navigation,
    userData: state.user
});
const mapDispatchToProps = dispatch => {
    return {
        togglePanel: () => dispatch({ type: "TOGGLE_PANEL" }),
        logout: () => dispatch({ type: "LOGOUT" }),
        changeTheme: (theme) => dispatch({ type: "CHANGE_THEME", payload: theme }),
        login: (user) => dispatch({ type: "LOGIN", payload: user }),
        setRscPerson: (rscPerson) => dispatch({ type: "SET_RSC_PERSON", payload: rscPerson }),

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Syspanel));
