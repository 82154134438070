import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../eui-components/row";
import EuiTabs from "../../../eui-components/tabs";
import GroupedDocuments from "../../../components/groupedDocuments";
import ApiClient from "../../../utils/api-client";
import Table from "../../../components/table";
import { ToastContainer, toast } from 'react-toastify';
import RecordingModal from "../../../components/recordingModal";
import DocumentDialogModal from "../../../components/documentDialogModal";
import constants from "../../../utils/constants";
import SasHelper from "../../../utils/sas-helper";


class FilesItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,          
            recordingColumns: [{
                title: "Name",
                field: "recordName",
                render: rowData => <a id="recordPath" onClick={(e) => this.checkIsReady(e, rowData)} > {this.renderRecordName(rowData)}</a >,
                cellStyle: { width: "60%", padding: "0px 10px" }
            },
            { title: "Recorded User", field: "recordedUserName", editable: "never", cellStyle: { width: "20%", padding: "0px 10px" } },
            {
                title: "Duration",
                field: "duration",
                editable: "never",
                cellStyle: { width: "10%", padding: "0px 10px" },
                render: rowData => this.renderDuration(rowData)
            },
            {
                title: "Status",
                field: "isReady",
                editable: "never",
                cellStyle: { width: "10%", padding: "0px 10px" },
                render: rowData => rowData.isReady ? "Available" : "Processing..."
            },
            ],
            openDialogPreview: false,
            selectedDocument: { id: 0 },
            recordingsTableRef: React.createRef(),
            recordings: []
        }
    }

    componentDidMount() {
        this.getRecordings();
    }

    renderDuration = (rowData) => {
        const dur = rowData.duration && rowData.duration > 0 ? rowData.duration / 60 : 0;
        return Math.round((dur + Number.EPSILON) * 100) / 100 + " min";
    }

    renderRecordName = (rowData) => {
        let name = rowData.recordPath ? rowData.recordPath.split("/")[rowData.recordPath.split("/").length - 1] : "";
        return rowData.recordName ?? name;
    }

    updateRecording = (newData, type) => {
        let updateData = { ...newData };
        if (type === "delete") {
            updateData.isDeleted = true;
            updateData.deleteDatetime = new Date();
            updateData.deleteUserId = parseInt(this.props.userData.userData.userId);
            updateData.deleteSessionId = this.props.liveStreamData.liveSessionInfo.streamingId;
        }
        
        ApiClient.post("Session/UpdateRecordingOfLiveStream", updateData)
            .then(response => {
                if (!response.data) {
                    toast.error("Some problems occured, please try later!", { containerId: 'FL', position: toast.POSITION.TOP_CENTER });
                }
            }).catch(error => {
                console.error(error);;
            })
    }

    getRecordings = () => {
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Session/GetRecordingsOfLiveStream", { id: this.props.liveStreamData.liveSessionInfo.streamingId })
                .then(response => {
                    const recordings = response.data;
                    this.setState({ isLoading: false, recordings });
                    
                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        })
    }

    getRecordingContent = () => {
        return <Table
            actions={[{
                icon: () => <i className="icon icon-reload"></i>,
                tooltip: "Refresh Recordings",
                onClick: (event) => this.getRecordings(),
                isFreeAction: true
            }
            ]}
            columns={this.state.recordingColumns}
            data={this.state.recordings}
            isLoading={this.state.isLoading}
            options={{
                pageSize: 5,
                pageSizeOptions: [5],
                search: false,
                actionsCellStyle: { width: "10%", padding: "0px 10px" }
            }}
            editable={{
                onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataUpdate = [...this.state.recordings];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            this.updateRecording(newData, "update");
                            this.setState({ recordings: [...dataUpdate] });
                            resolve();
                        }, 1000)
                    }),
                onRowDelete: oldData =>
                    new Promise((resolve, reject) => {
                        setTimeout(() => {
                            const dataDelete = [...this.state.recordings];
                            const index = oldData.tableData.id;
                            dataDelete.splice(index, 1);
                            this.updateRecording(oldData, "delete");
                            this.setState({ recordings: [...dataDelete] });
                            resolve()
                        }, 1000)
                    }),
            }}
        >
        </Table >
    }

    checkIsReady = (e, rowData) => {
        if (!rowData.isReady) {
            this.getRecordings();
            toast.error("Recording is still processing! Refresh again later.", { containerId: 'FL', position: toast.POSITION.TOP_CENTER });
        } else {
            this.setState({ openDialogPreview: true, selectedDocument: rowData });
        }
    }

    getSessionContent = () => {
        const activityDocuments = this.props.sessionData.approveSession.currentSession &&
            this.props.sessionData.approveSession.currentSession.activities &&
            this.props.sessionData.approveSession.currentSession.activities.map(item => item.subActivities).length > 0 &&
            this.props.sessionData.approveSession.currentSession.activities.map(item => item.subActivities).reduce((a, b) => { return a.concat(b); }).map(subDoc => subDoc.documents).length > 0
            ?
            [...this.props.sessionData.approveSession.currentSession.activities.map(item => item.subActivities).reduce((a, b) => { return a.concat(b); })
                .map(subDoc => subDoc.documents).reduce((a, b) => { return a.concat(b) })]
            :
            [];
        const sessionDocuments = this.props.sessionData.approveSession.currentSession &&
            this.props.sessionData.approveSession.currentSession.sessionActivities &&
            this.props.sessionData.approveSession.currentSession.sessionActivities.map(subDoc => subDoc.documents).length > 0
            ?
            [...this.props.sessionData.approveSession.currentSession.sessionActivities
                .map(subDoc => subDoc.documents).reduce((a, b) => { return a.concat(b) })]
            :
            [];

        const contents = [
            { value: 1, selected: "selected", children: <GroupedDocuments title="" documents={activityDocuments} /> },
            { value: 2, selected: "", children: <GroupedDocuments title="" documents={sessionDocuments} /> },
            {
                value: 3,
                selected: "",
                children: <RecordingModal openDialog={(rowData) => this.setState({ openDialogPreview: true, selectedDocument: rowData })} data={this.props.liveStreamData.recordings} />
            },
            { value: 4, selected: "", children: this.getRecordingContent() }
        ];
        return contents;
    }

    getActivityContent = () => {
        
        let activityDocuments = [];
        if (this.props.activityInboxData.currentActivity &&
            this.props.activityInboxData.currentActivity.subActivities &&
            this.props.activityInboxData.currentActivity.subActivities.map(item => item.documents && item.documents.length > 0).length > 0) {
            activityDocuments = this.props.activityInboxData.currentActivity
                .subActivities.map(item => item.documents)
                .reduce((a, b) => { return a.concat(b); });
        }
        const contents = [
            { value: 1, selected: "selected", children: <GroupedDocuments title="" documents={activityDocuments} /> },
            {
                value: 3,
                selected: "",
                children: <RecordingModal openDialog={(rowData) => this.setState({ openDialogPreview: true, selectedDocument: rowData })} data={this.props.liveStreamData.recordings} />
            },
            { value: 2, selected: "", children: this.getRecordingContent() }
        ]
        return contents;
    }

    getEhsContent = () => {
        
        let ehsDocuments = [];
        if (this.props.ehsInboxData.currentEhsPing &&
            this.props.ehsInboxData.currentEhsPing.checklists &&
            this.props.ehsInboxData.currentEhsPing.checklists.map(item => item.documents && item.documents.length > 0).length > 0) {
            ehsDocuments = this.props.ehsInboxData.currentEhsPing
                .checklists.map(item => item.documents)
                .reduce((a, b) => { return a.concat(b); });
        }
        const contents = [
            { value: 1, selected: "selected", children: <GroupedDocuments title="" documents={ehsDocuments} /> },
            {
                value: 3,
                selected: "",
                children: <RecordingModal openDialog={(rowData) => this.setState({ openDialogPreview: true, selectedDocument: rowData })} data={this.props.liveStreamData.recordings} />
            },
            { value: 2, selected: "", children: this.getRecordingContent() }
        ]
        return contents;
    }

    getVssContent = () => {
        
        let activityDocuments = [];
        if (this.props.imInboxData.currentIMActivity &&
            this.props.imInboxData.currentIMActivity.subactivities &&
            this.props.imInboxData.currentIMActivity.subactivities.map(item => item.documents && item.documents.length > 0).length > 0) {
            activityDocuments = this.props.imInboxData.currentIMActivity
                .subactivities.map(item => item.documents)
                .reduce((a, b) => { return a.concat(b); });
        }
        const contents = [
            { value: 1, selected: "selected", children: <GroupedDocuments title="" documents={activityDocuments} /> },
            {
                value: 3,
                selected: "",
                children: <RecordingModal openDialog={(rowData) => this.setState({ openDialogPreview: true, selectedDocument: rowData })} data={this.props.liveStreamData.recordings} />
            },
            { value: 2, selected: "", children: this.getRecordingContent() }
        ]
        return contents;
    }

    getContent = () => {
        if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION) {
            return this.getSessionContent();
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.ACTIVITY) {
            return this.getActivityContent();
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.EHS) {
            return this.getEhsContent();
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.VSS) {
            return this.getVssContent();
        }
        return [];
    }

    getTitles = () => {
        const titles = [{ value: 1, name: "Activities", selected: "selected" },
            { value: 2, name: "Sessions", selected: "" },
            { value: 3, name: "Previous Recordings", selected: "" },
            { value: 4, name: "Current Recordings", selected: "", onClick : () => this.getRecordings() }
        ];
        if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION) {
            return titles;
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.ACTIVITY) {
            return titles.filter(item => item.value !== 2);
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.EHS) {
            return [{ value: 1, name: "EHS Pings", selected: "selected" }, ...titles.filter(item => item.value > 2)];
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.VSS) {
            return [{ value: 1, name: "VSS/VCOP Activities", selected: "selected" }, ...titles.filter(item => item.value > 2)];
        }
        return [];
    }

    render() {

        return (
            <EuiRow style={{ width: this.props.liveStreamData.width }}>

                <EuiTabs
                    id="filesItemTab"
                    titles={this.getTitles()}
                    contents={this.getContent()}
                    contentStyle={{ width: this.props.liveStreamData.width }}
                >
                </EuiTabs>

                <DocumentDialogModal openDialogPreview={this.state.openDialogPreview}
                    handleClose={() => this.setState({ openDialogPreview: false })}
                    id="filesItemDocumentView"
                    showLocation={false}
                    selectedDocument={{ ...this.state.selectedDocument, documentPath: SasHelper.addSasKey(this.state.selectedDocument.recordPath), uploadDate: this.state.selectedDocument.endDatetime, type: 2 }}
                >
                </DocumentDialogModal>

                <ToastContainer enableMultiContainer containerId={'FL'} />

            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.user,
    sessionData: state.session,
    liveStreamData: state.liveStream,
    activityInboxData: state.activityInbox,
    ehsInboxData: state.ehsInbox,
    imInboxData: state.imInbox
});
const mapDispatchToProps = dispatch => {
    return {
        setRecordings: (recordings) => dispatch({ type: "SET_RECORDINGS", payload: recordings }),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FilesItem);
