/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from 'react'
import EuiRow from '../../../eui-components/row';
import ApiClient from '../../../utils/api-client';

import ObseleteTable from "../../../eui-components/obseleteTable";
import { Card, CardContent, makeStyles } from '@material-ui/core';
import VehicleDailyInspectionsDetailsEHS from './VehicleDailyInspectionsDetailsEHS';
import DateUtils from '../../../utils/date-utils';
import constants from '../../../utils/constants';
import CommonHelper from '../../../utils/common-helper';
import EuiLoading from '../../../eui-components/loading';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    justifyContent: 'center',
    justify:"center"
  },
  title: {
    fontSize: 14,
  },
 
});

export default function VehicleDailyInspectionsEHS(props) {
const {vehicleId ,setIsPassedDailyInspection,dailyInspection}=props;
  const [allVehicleInspectionQuestions, setAllVehicleInspectionQuestions] = useState([])
  const [delaiyInspectionDetails, setDelaiyInspectionDetails] = useState({})
  const [isLoading, setIsLoading] = useState(false);


  const classes = useStyles();

  const columns = [
    {
        title: "Question",
        key: "question",
        sort: "none",
        width: "10%",
        hideFilter: true,

    },
    {
      title: "Answer",
      key: "answer",
      width: "1%",
      onCreatedCell: (td, cellData) => {
        if( cellData==="yes" ){
          td.innerHTML = `<span><i id="PASS_" class="icon icon-alarm-level3 text-lg color-green "></i>${cellData}</span>`
        }else if(cellData==="no"){
          td.innerHTML = `<span ><i id="NO_" class="icon icon-alarm-level3 text-lg color-red "></i>${cellData}</span>`
        }else  if(cellData==="na"){
          td.innerHTML = `<span ><i id="NA_" class="icon icon-alarm-level3 text-lg color-grey "></i>${cellData}</span>`

        }
        
      },
      hideFilter: true,

  },
   {
    title: "Comment",// EHS Passed
    key: "comment",
    sort: "none",
    width: "5%",
    hideFilter: true,
  }

];

const getScore=(answer)=>{

  if(answer){
      const answerTotal=answer.length
      const numberOfNa=answer.filter(i => i.answer ==="na").length
      const numberOfYes=answer.filter(i => i.answer ==="yes").length
      const totalNa=answerTotal-numberOfNa===0?1:answerTotal-numberOfNa
      return ((numberOfYes/totalNa)*100).toFixed(2)+'%'
  }
  return 'NA'
  }
  const checkIsApprovedDailyInspection=(answer,score)=>{

    if(answer){
        const answerTotal=answer.length
        const numberOfYes=answer.filter(i => i.answer ==="yes").length
        setIsPassedDailyInspection(numberOfYes===answerTotal&&score>0)
    }else{
      setIsPassedDailyInspection(false)
      }
  }

  
  function getListOfInspectionsPerVehicle(){
            // if activityID is not null and inspectionName is null then daily inspection 
            setIsPassedDailyInspection(false)
            ///console.log("dailyInspection",dailyInspection)
            if(dailyInspection?.length>0){
              setIsLoading(false)
              // const delayInspectionFilterResult=dailyInspection.filter(filter => filter.activityId>0)[0]
              // if activityID is null and inspectionName is not null then monthly inspection 

              const listDailyInspection=[]
              const ele={}
              dailyInspection?.length>0 && dailyInspection.forEach(element => {
                listDailyInspection.push(...element.vehicleInspectionAnswers)
          });
           let score=getScore(listDailyInspection)
           ele.id = dailyInspection[0]?.id
           ele.auditID = dailyInspection[0]?.auditID
           ele.complianceStatusWhenAudited  = dailyInspection[0]?.complianceStatusWhenAudited;
           ele.lastServiceDateWhenAudited = dailyInspection[0]?.lastServiceDateWhenAudited;
           ele.nextServiceMileage = dailyInspection[0]?.nextServiceMileage;
           ele.activityId=dailyInspection[0]?.activityId
           ele.actualTeamOnSiteDateTime=!isEmpty(dailyInspection[0]?.actualTeamOnSiteDateTime)?DateUtils.toDateString(dailyInspection[0]?.actualTeamOnSiteDateTime, 'YYYY-MM-DD HH:mm:ss')  : " -"; 
           ele.estimateArrivalDateTime=!isEmpty(dailyInspection[0]?.estimateArrivalDateTime)?DateUtils.toDateString(dailyInspection[0]?.estimateArrivalDateTime, 'YYYY-MM-DD HH:mm:ss')  : " -"; 
           ele.offSiteInspectionSiteDateTime=!isEmpty(dailyInspection[0]?.offSiteInspectionSiteDateTime)?dailyInspection[0]?.offSiteInspectionSiteDateTime : " -"; 
           ele.offSiteLatitude=!isEmpty(dailyInspection[0]?.offSiteLatitude)?dailyInspection[0]?.offSiteLatitude : " -";
           ele.offSiteLongitude=!isEmpty(dailyInspection[0]?.offSiteLongitude)?dailyInspection[0]?.offSiteLongitude : " -"; 
           ele.siteLatitude=!isEmpty(dailyInspection[0]?.siteLatitude)?dailyInspection[0]?.siteLatitude : " -"; 
           ele.siteLongitude=!isEmpty(dailyInspection[0]?.siteLongitude)?dailyInspection[0]?.siteLongitude : " -";   
           ele.travelDistance=!isEmpty(dailyInspection[0]?.travelDistance)?dailyInspection[0]?.travelDistance : " -";  
           ele.travelDurations=parseFloat(Math.abs(dailyInspection[0]?.travelDurations)).toFixed(2);
           ele.userId=dailyInspection[0]?.userId
              ele.distance=!isEmpty(dailyInspection[0]?.distance)?dailyInspection[0]?.distance : " -"; 
              ele.latitude=!isEmpty(dailyInspection[0]?.latitude)?dailyInspection[0]?.latitude : " -"; 
              ele.longitude=!isEmpty(dailyInspection[0]?.longitude)?dailyInspection[0]?.longitude : " -";  
              ele.createDate=!isEmpty(DateUtils.toDateString(dailyInspection[0]?.createDate, 'YYYY-MM-DD HH:mm:ss') )?  DateUtils.toDateString(dailyInspection[0]?.createDate, 'YYYY-MM-DD HH:mm:ss') : " -"; 
              ele.inspectorName=!isEmpty(dailyInspection[0]?.inspectorName)? dailyInspection[0]?.inspectorName : " -";  
              ele.score=score
            
        
              setDelaiyInspectionDetails(ele)
              checkIsApprovedDailyInspection(listDailyInspection, Number(score.slice(0, -4)))
              setAllVehicleInspectionQuestions(listDailyInspection)
            }
  }
useEffect(() => {
 getListOfInspectionsPerVehicle();
}, [])


  return (
    <>
   { isLoading?
  <EuiLoading id="activityDocumentsLoading" isLoading={isLoading} />:

    <><Card className={classes.root}>
          <CardContent className={classes.root}>
            <VehicleDailyInspectionsDetailsEHS delaiyInspectionDetails={delaiyInspectionDetails} />
          </CardContent>

        </Card><EuiRow key={allVehicleInspectionQuestions}>

            <ObseleteTable
              id={"inspectionTbl_" + vehicleId}
              columns={columns}
              data={allVehicleInspectionQuestions}
              scroll={false}
              classes="tiny split-striped"
              // exportExcel={true}
              // Reportdata={excelData}
              // excelFileName={fileName}
              filter={true}
              hideTableInfo={true}
              paginated={false}
              />


          </EuiRow></>}
      
      </>
  )
}
