import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import constants from "../../utils/constants";
import EuiTile from "../../eui-components/tile";
import MenuMultiPanelTile from "../../components/menuMultiPanel";
import TeamInfo from "./teamInfo";
import { changeMod, setAllCertificates, setCertificate, setTeam } from "../../store/actions/teamAction";
import TeamContent from "./teamContent";
import AddCertificates from "./addCertificates";
import DialogModal from "../../components/dialog";
import ApiClient from "../../utils/api-client";
import TeamLogs from "./teamLogs";
import { Logs } from "../../utils/Log";
import VehicleHelper from "../../utils/vehicle-helper";

class TeamDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            editMod: constants.actionMods.VIEW_ONLY
        }
    }
    

    componentDidMount() {
        if (this.props.userData.certificates) {
            return;
        }
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Asp/GetCertificates")
                .then(response => {
                    this.props.setAllCertificates(response.data);
                }).finally(() => {
                    this.setState({ isLoading: false });
                })
        });
    }

    getMenuList = () => {
        const menu = [{
            id: 1, title: "Team Information",
            icon: <i className="icon icon-info m-1 text-lg"></i>,
            content: <TeamInfo mod={this.state.editMod} />,
            // we can edit team in case the user who created login
            rightIcon: parseInt( this.props.teamData.team.userId)=== parseInt(this.props.userData.userData.userId) 
                &&
                this.getRightButton() 
                //TODO  user.jobRole === constants.userRoles.ADMIN || user.jobRole === constants.userRoles.ERICSSON_IM ?
        },
        {
            id: 2, title: "Team Certifications", icon: <i className={"icon icon-medal m-1 text-lg "}></i>,
            content: <TeamContent openAddCertificate={() => this.setState({ openDialog: true })} createEditTeamCertificate={this.createEditTeamCertificate} key={this.props.teamData.team.initialState} />,
            rightIcon: parseInt( this.props.teamData.team.userId)=== parseInt(this.props.userData.userData.userId) 
                &&
                this.getAddCertificate()
                //TODO  user.jobRole === constants.userRoles.ADMIN || user.jobRole === constants.userRoles.ERICSSON_IM ?
        },
        {
            id: 3, title: "Team Logs", icon: <i className={"icon icon-time m-1 text-lg "}></i>,
            content: <TeamLogs key={this.props.teamData.team.initialState} />,
        }]
        return menu;
    }

    

    getAddCertificate = () => {
        const isLoading = this.state.isLoading || this.props.isLoading
        
        return <button
            //TODO hidden={this.props.userData.userData.jobRole === "1" || this.props.userData.userData.jobRole === "0" ? false : true
            className={"btn primary m-1 " + (isLoading ? "loading  disabled" : "")}
            onClick={() => this.openAddCertificate()}
        >
            <i className="icon icon-plus p-1" />Add Certificate
        </button>
    }

    openAddCertificate = () => {
        this.props.setCertificate({ id: 0, minCertificateNumber: 1, isActive: true })
        this.setState({ openDialog: true })
    }

    getRightButton = () => {
        const isLoading = this.state.isLoading || this.props.isLoading

        if (this.state.editMod === constants.actionMods.VIEW_ONLY) {
            return <button
                //TODO hidden={this.props.userData.userData.jobRole === "1" || this.props.userData.userData.jobRole === "0" ? false : true
                className={"btn primary m-1 " + (isLoading ? "loading  disabled" : "")}
                onClick={() => this.setState({ editMod: constants.actionMods.EDIT })}
            >
                <i className="icon icon-edit p-1" />Edit Team
            </button>
        } else if (this.state.editMod === constants.actionMods.EDIT) {
            return <>
                <button
                    className={"btn primary m-1 "}
                    onClick={() => this.saveTeam()}
                >
                    <i className="icon icon-check p-1" />
                    Save
                </button>
                <button
                    className={"btn primary m-1 "}
                    onClick={this.cancel}
                >
                    <i className="icon icon-cross p-1" />
                    Cancel
                </button>
            </>
        }
    }

    saveTeam = () => {
        this.setState({ editMod: constants.actionMods.VIEW_ONLY }, () => {
            this.props.createEditTeam();
        });
    }

    cancel = () => {
        const team = this.props.teamData.filteredTeams.data.find(wp => wp.id === this.props.teamData.team?.id);
        this.props.setTeam(team);
        this.setState({ editMod: constants.actionMods.VIEW_ONLY });
    }

    createEditTeamCertificate = () => {
        const certificate = this.props.teamData.certificate;
        if (!certificate.certificateId) {
            toast.error("Please, fill the Certification field!", { containerId: "WD", position: toast.POSITION.TOP_CENTER });
            return;
        }
        if (certificate.minCertificateNumber <= 0) {
            toast.error("Team should at least have 1 required certification!", { containerId: "WD", position: toast.POSITION.TOP_CENTER });
            return;
        }
        const oldCertificate = certificate.id ? this.props.teamData.team.teamCertificates.find(item => item.id === certificate.id) : certificate; 

        const containerId = certificate.id > 0 ? "WD" : "WS";
        certificate.teamId = this.props.teamData.team.id;
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Team/CreateEditCertificate", certificate).then(response => {
                this.setState({ openDialog: false })
                if (response) {
                    Logs({
                        LogName: 'Team Certificate',
                        userId: parseInt(this.props.userData.userData.userId),
                        LogObjectId: certificate.teamId,
                        LogAction: certificate.id ? 'Updated Certificate' : 'Added Certificate',
                        LogTypeId: VehicleHelper.LogTypeId.TEAM,
                    },
                        certificate.id ?
                            {
                                oldData: oldCertificate,
                                oldDataDate: new Date(),
                                newData: certificate,
                            } : 'Added Certificate').then(r => {
                                ApiClient.post("Team/GetTeamDetails", { id: certificate.teamId })
                                    .then(response => {
                                        this.setState({ isLoading: false }, () => {
                                            const team = { ...response.data }
                                            this.props.setTeam({ ...team, initialState: initialState.getTime() });
                                        });
                                    })
                            })
                    toast.success(constants.processPozitiveMessage, { containerId: containerId, position: toast.POSITION.TOP_CENTER });
                    const initialState = new Date();

                } else {
                    toast.error(constants.processNegativeMessage, { containerId: "WS", position: toast.POSITION.TOP_CENTER });
                }
            }).catch(error => {
                console.error(error);
                toast.error("Error Occured!" + error, { containerId: "WD", position: toast.POSITION.TOP_CENTER });
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        });
    }
    


    render() {
        const isLoading = this.state.isLoading || this.props.isLoading

        return (
            <EuiTile
                fullscreen
                tileStyle={{ padding: 0 }}
                key={this.props.teamData.updateElement}
            >
                <EuiBreadcrumb
                    id="teamBreadcrumb"
                    parentName="Home"
                    childName={this.props.teamData.team.id + "-" + this.props.teamData.team.name}
                    onClickParent={() => this.props.changeMod(constants.pageMods.LIST)}
                    className="p-2"
                />

                <MenuMultiPanelTile
                    id="teamDetailsMenuMultiPanel"
                    menuList={this.getMenuList()}
                    key={this.state.editMod + isLoading}
                    selectedItem={this.props.teamData.team.initialState ? 2 : 1}
                />

                <DialogModal
                    buttonName="Save"
                    content={<AddCertificates key={this.props.userData.customersWithProjects} />}
                    handleClose={() => this.setState({ openDialog: false })}
                    id="teamCreateDialog"
                    onClick={() => this.createEditTeamCertificate()}
                    open={this.state.openDialog}
                    disableBackdropClick={true}
                    title="Add Certificates"
                />

                <ToastContainer enableMultiContainer containerId={'WD'} autoClose={2500} />
            </EuiTile>);
    }
}

const mapStateToProps = state => ({
    userData: state.user,
    navbarData: state.navigation,
    teamData: state.team
});
const mapDispatchToProps = dispatch => {
    return {
        changeMod: (mod) => changeMod(mod)(dispatch),
        setTeam: (team) => setTeam(team)(dispatch),
        setAllCertificates: (certificates) => setAllCertificates(certificates)(dispatch),
        setCertificate: (certificate) => setCertificate(certificate)(dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TeamDetails));