import React, { useState, useCallback, useEffect } from "react";
import EuiRow from "../../eui-components/row";
import EuiHeader from "../../eui-components/header";
import constants from "../../utils/constants";
import { useSelector, useDispatch } from "react-redux";
import VehicleInspectionChecklist from "./vehicleInspectionChecklist";
import ApiClient from "../../utils/api-client";
import EuiLoading from "../../eui-components/loading";
import CommonHelper from "../../utils/common-helper";
import { useHistory } from "react-router-dom";
import VehicleHelper from "../../utils/vehicle-helper";
import DateUtils from "../../utils/date-utils";
import { Logs } from "../../utils/Log";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import VehicleInformationContent from "./vehicleInformationContent";
import Buttons from "./FilterEvidancesByStatus/Buttons";
// import Data from "./FilterEvidancesByStatus/Data";

// Approve, Reject, no save and finsih later
const RateInspection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openVehicleInfo, setOpenVehicleInfo] = useState(false);
  const [item, setItem] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user.userData);
  const currentInspection = useSelector(
    (state) => state.vehicleInspection.currentInspection
  );
  const inspectionChecklistItems = useSelector(
    (state) => state.vehicleInspection.currentInspection.currentInspectionItems
  );
  const vehicleList = useSelector((state) => state.vehicle.vehicleList);

  const [vehicleColumns, setColumns] = useState([
    {
      title: "Vehicle ID",
      key: "id",
      sort: "desc",
      onCreatedCell: (td, cellData) => {
        if (cellData) {
          const idLink = `<a id="vehicleid_${cellData}">${cellData}</a>`;
          td.innerHTML = idLink;
          td.querySelector("#vehicleid_" + cellData).addEventListener(
            "click",
            () => openVehicleDetail(cellData)
          );
        }
      },
      width: "5%",
    },
    {
      title: "Chassis Number",
      key: "chassisNumber",
      sort: "none",
      width: "10%",
    },
    {
      title: "Reqistration Plate No",
      key: "registrationPlateNo",
      sort: "none",
      width: "15%",
    },
    {
      title: "Manufacturer",
      key: "vehicleManufacturer",
      sort: "none",
      width: "15%",
    },
    {
      title: "Model Name",
      key: "vehicleModelName",
      sort: "none",
      width: "15%",
    },
    {
      title: "Model Year",
      key: "vehicleModelYear",
      sort: "none",
      width: "10%",
    },
    {
      title: "Status",
      key: "vehicleStatus",
      sort: "none",
      onCreatedCell: (td, cellData) => {
        const vehicleStatus = VehicleHelper.vehicleHTMLTableStatus[cellData];
        td.innerHTML = vehicleStatus;
      },
      width: "15%",
      notSearchable: true,
    },
    {
      title: "is Vehicle Compliant",
      key: "isVehicleCompliant",
      sort: "none",

      onCreatedCell: (td, cellData) => {
        if (cellData) {
          td.innerHTML =
            cellData === "Compliant"
              ? `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>${cellData}</div>`
              : `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${cellData}</div>`;
        }
      },
      width: "15%",
      notSearchable: true,
    },
    // {
    //   title: "Validation Status",
    //   key: "validationStatus",
    //   sort: "none",

    //   // onCreatedCell: (td, cellData) => {
    //   //     if (cellData) {
    //   //         td.innerHTML =cellData==="Compliant" ?`<div class="flex-start-content"><i class="icon icon-alarm-level5 color-green m-1"></i>${cellData}</div>`:
    //   //         `<div class="flex-start-content"><i class="icon icon-alarm-level5 color-red m-1"></i>${cellData}</div>`;

    //   //     }
    //   // },
    //   width: "15%",
    //   notSearchable: true,
    // },
    {
      title: "vehicle Compliance Status",
      key: "vehicleComplianceStatus",
      sort: "none",
      onCreatedCell: (td, cellData) => {
        td.innerHTML = cellData;
      },
      width: "15%",
      notSearchable: true,
    },
  ]);
  const openVehicleDetail = useCallback(() => {
    setOpenVehicleInfo(true);
  });

  const createLog = (request) => {
    let visitor = CommonHelper.getVisitorData();
    const newData = {};
    const oldData = {};
    const logInfo = {};
    logInfo.LogName = "Inspection";
    logInfo.userId = parseInt(user.userId);
    logInfo.LogObjectId =vehicleList.id  //request.id;
    logInfo.LogAction =
      request.statusId === constants.activityInboxApprovalTypes.APPROVE //constants.activityInboxApprovalTypes.APPROVE
        ? VehicleHelper.vehicleAction.REJECTED :VehicleHelper.vehicleAction.APPROVED
        
        
    logInfo.LogTypeId = VehicleHelper.LogTypeId["VEHICLE"];
    logInfo.latitude = Number(visitor.latitude).toString();
    logInfo.longitude = Number(visitor.longitude).toString();

    // new data is
    if (request.statusId === constants.activityInboxApprovalTypes.APPROVE) {
      newData.status = request.statusId;
      newData.approvedDatetimeUtc = DateUtils.toUtcDate(new Date());
    } else {
      newData.status = request.statusId;
      newData.rejectedDatetimeUtc = DateUtils.toUtcDate(new Date());
      newData.UserComment = request.modifiedUserComment;
    }

    // old data is
    oldData.status = request.statusId;
    oldData.submitDatetimeUtc = currentInspection.submitDatetimeUtc;
    Logs(logInfo, {
      oldData,
      oldDataDate: currentInspection.createDatetimeUtc,
      newData,
    });
  };

  const approveRejectInspection = (type) => {
    if(pending !== 0)
    {
      CommonHelper.createNotification({ title: "Alert", description: "Please accept / reject all pending evidences before continuing!", icon: "cross" });
      return ;
    }
    else
    {

    // type is the status ID
    setIsLoading(true);

    var docs = inspectionChecklistItems
      .map((item) => item.vehicleInspectionEvidences)
      .reduce((a, b) => {
        return a?.concat(b);
      });
    var request = {
      id:parseInt(currentInspection?.inspectionId),
      statusId:
        type === constants.activityInboxApprovalTypes.APPROVE
          ? parseInt(constants.vehicleStatuses.ACCEPTED)
          : parseInt(constants.vehicleStatuses.REJECTED),
      modifiedUserId: parseInt(user.id),
      smeUserId: parseInt(user.id),
      InspectionChecklistItems: inspectionChecklistItems,
      VehicleInspectionEvidences: inspectionChecklistItems
        .map((item) => item.vehicleInspectionEvidences)
        .reduce((a, b) => {
          return a?.concat(b);
        }),
    };
    createLog(request);
      ApiClient.post("Vehicle/ApproveRejectVehicleInspection", request, {})
      .then((response) => {
        setIsLoading(false);

        if (response.data) {
          CommonHelper.createNotification({
            title: "Vehicle Inspection Status is updated successfully!",
            icon: "icon-check",
            timeout: 5000,
          });
        }
        if (
          type !== constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER
        ) {
          dispatch({
            type: "CHANGE_INSPECTION_MOD",
            payload: constants.pageMods.LIST,
          });
          history.push("/vehicle-inbox");
        }
      })
      .catch((error) => {
        CommonHelper.createNotification({
          title: constants.errorMessage,
          icon: "icon-check",
          timeout: 5000,
        });
        console.error(error);
        setIsLoading(false);
      });

    }

  }

  const getRightActions = () => {
    return (
      <div>
        <button
          className={"btn p-1"}
          onClick={() =>
            dispatch({
              type: "CHANGE_INSPECTION_MOD",
              payload: constants.pageMods.LIST,
            })
          }
        >
          <i className="icon icon-arrow-left p-1"></i>
          Back
        </button>
        <button
          className={"btn primary p-1"}
          onClick={() =>
            approveRejectInspection(
              constants.activityInboxApprovalTypes.APPROVE
            )
          }
          disabled={isLoading}
        >
          <i className="icon icon-success p-1"></i>Approve
        </button>
        <button
          className={"btn warning p-1"}
          disabled={isLoading}
          onClick={() =>
            approveRejectInspection(constants.activityInboxApprovalTypes.REJECT)
          }
        >
          <i className="icon icon-failed p-1"></i>Reject
        </button>
      </div>
    );
  };




  // const menuItems = [...new Set(Data.map((Val) => Val.category))];
  const [selectedStatus, setSelectedStatus] = useState(2)
  const [menuItems, setMenuItems] = useState([])
  const [allEvidances, setAllEvidances] = useState([])
  const [pending, setPending] = useState(1)


  useEffect(() => {
    console.log("vehicleListvehicleList",vehicleList)
    const list = [];
    currentInspection.currentInspectionItems.map(item =>
      item.vehicleInspectionEvidences.map(dd => {
        const ele = { ...dd }
        ele.categoryId = item.categoryId
        ele.acceptanceStatusId = dd.statusId
        list.push(ele)
      }))
    const totalPending = list.filter((newVal) => {
      return parseInt(newVal?.statusId) === 2;
      // comparing category for displaying data
    })?.length;
    const totalRejected = list.filter((newVal) => {
      return parseInt(newVal?.statusId) === 4;
      // comparing category for displaying data
    })?.length;
    const totalApproved = list.filter((newVal) => {
      return parseInt(newVal?.statusId) === 3;
      // comparing category for displaying data
    })?.length;
    setMenuItems([{ id: 2, name: 'Pending', total: totalPending }, { id: 3, name: 'Approved', total: totalApproved }, { id: 4, name: 'Rejected', total: totalRejected }])
    ///console.log("totalPendingtotalPendingtotalPending", totalPending, list)
    setPending(totalPending)
    setItem(list);
    setAllEvidances(list)
  }, [currentInspection])


  const filterItem = (statusId) => {

    const list = [];
    currentInspection.currentInspectionItems.map(item =>
      item.vehicleInspectionEvidences.map(dd => {
        const ele = { ...dd }
        ele.categoryId = item.categoryId
        list.push(ele)
      }))
    const oldItemS = [...list]


    setSelectedStatus(statusId)
    const newItem = oldItemS.filter((newVal) => {
      return !statusId || parseInt(newVal?.statusId) === parseInt(statusId);
    });
    setItem(newItem);
  };

  return (
    <EuiRow key={isLoading} disabled={isLoading ? "disbaled" : ""}>
      <EuiLoading id="inspectionInboxLoading" isLoading={isLoading} />
      <h3>Vehicle Information</h3>

      <EuiRow>
        {openVehicleInfo ? (
          <VehicleInformationContent
            isLoading={isLoading}
            setOpenVehicleInfo={setOpenVehicleInfo}
            vehicleReducer={vehicleList}
          />
        ) : (
          <EuiRow>
            <ObseleteEuiTable
              classes="tiny split-striped"
              columns={vehicleColumns}
              data={[vehicleList]}
              exportExcel={true}
              excelFileName={"Vehice_List" + new Date().toISOString()}
              Reportdata={[vehicleList]}
              id="vehicleListTable"
            ></ObseleteEuiTable>
          </EuiRow>
        )}
        <EuiRow className="column sm-12 md-2 filled">
          <h3>Evidence Status</h3>
        </EuiRow>
        <EuiRow>
          <Buttons filterItem={filterItem} setItem={setItem} menuItems={menuItems} allEvidances={allEvidances} />
        </EuiRow>

        <EuiHeader
          id="inspectionHeaderId"
          title={
            currentInspection.id + " - " + currentInspection.inspectionName
          }
          rightActions={getRightActions()}
        ></EuiHeader>
        {isLoading ? <></> : <VehicleInspectionChecklist evidences={item} selectedStatus={selectedStatus} />}
      </EuiRow>
    </EuiRow>
  );
};
export default RateInspection;
