import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DialogModal from "../../components/dialog";
import { Notification } from '@eds/vanilla';
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import EuiTile from "../../eui-components/tile";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import ApiClient from "../../utils/api-client";
import constants from "../../utils/constants";
import ChecklistHelper from "../../utils/checklist-helper";
import AddActivityTemplate from "./addActivityTemplate";
import CommonHelper from "../../utils/common-helper";

class ChecklistItemsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            activityCheckLists: [],
            columns: [
                {
                    title: "ID",
                    onCreatedCell: (td, cellData) => {
                        td.innerHTML = `<a id="checklist_${cellData}">${cellData}</a>`;
                        td.querySelector("#checklist_" + cellData).addEventListener('click', () => this.openChecklistSubItems(cellData))
                    },
                    key: "id",
                    width: "2%",
                    sort: "desc",
                    cellStyle: "white-space:nowrap; font-size: 13px",
                    sort: "none"
                },
                {
                    title: "",
                    key: "publicTemplate",
                    onCreatedCell: (td, cellData) => {
                        if (cellData) {
                            td.innerHTML = `<span id="public_" class="tooltip"><i id="edit_" style={font-weight:500px;} class="icon icon-group color-black text-lg clickable  "></i><span class="message top-end">Public Template</span></span>`;
                        }
                        else
                            td.innerHTML = "";
                    },
                    width: "1%",
                    hideFilter: true

                },
                {
                    title: "Name",
                    key: "name",
                    width: "6%",
                    sort: "none"

                },
                {
                    title: "Type",
                    key: "templatableType",
                    width: "6%",
                    sort: "none"
                },
                {
                    title: "Customer/Project",
                    key: "customerProjectName",
                    width: "6%",
                    sort: "none"

                },
                {
                    title: "Classification",
                    key: "classificationName",
                    width: "6%",
                    sort: "none"
                },
                {
                    title: "Last Modified User",
                    key: "createdBy",
                    width: "6%",
                    sort: "none"
                },
                {
                    title: "",
                    key: "editId",
                    width: "1%",
                    hideFilter: true,
                    onCreatedCell: (td, cellData) => {
                        if (cellData) {
                            td.innerHTML = `<span id="edit_" class="tooltip"><i id="edit_" style={font-weight:500px;} class="icon icon-edit color-black  text-lg clickable "></i><span class="message top-end">Edit Template</span></span>`;
                            td.querySelector("#edit_").addEventListener('click', () => { this.openEditDialog(cellData) })
                        }

                    },
                },
                {
                    title: "",
                    key: "deleteId",
                    width: "1%",
                    hideFilter: true,
                    onCreatedCell: (td, cellData) => {
                        if (cellData && this.props.userData.userData.jobRole === constants.userRoles.ERICSSON_IM) {
                            td.innerHTML = `<span id="delete_" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable "></i><span class="message top-end">Delete Template</span></span>`;
                            td.querySelector("#delete_").addEventListener('click', () => { this.openDeleteDialog(cellData) })
                        }
                        else
                            td.innerHTML = "";
                    },
                },
                {
                    title: "",
                    key: "deletedId",
                    width: "1%",
                    hideFilter: true,
                    onCreatedCell: (td, cellData) => {
                        if (cellData) {
                            let selectedTemplate = this.props.activityTemplateData.templates.find(item => item.id === cellData);
                            td.innerHTML = `<span id="deleted_" class="tooltip"><i id="deleted_" style={font-weight:500px;} class="icon icon-block color-black  text-lg clickable "></i>
                                <span class="message top-end">
                                <span>Deleted By: ${selectedTemplate.deletedBy}<span>
                                <br>
                                <span >Delete Date: ${selectedTemplate.deleteDate}</span>
                                </span>
                                </span>`;
                        }
                    },

                },


            ],
            data: [],
            openAddDialog: false,
            openDeleteDialog: false,
            openEditDialog: false,
        }
    }

    componentDidMount() {
        ApiClient.post("ActivityTemplate/GetUserTemplates", { id: parseInt(this.props.userData.userData.id) })
            .then(response => {
                if (response.data) {

                    const templates = response.data;
                    this.setState({
                        isLoading: false,
                    })

                    if (!this.props.activityTemplateData.projects.length || !this.props.activityTemplateData.customers.length) {
                        this.setState({
                            isLoading: true
                        }, () => {
                            ApiClient.post("ActivityTemplate/GetData", {})
                                .then(response => {
                                    if (response.data) {
                                        this.props.setData({ projects: response.data.projects, customers: response.data.customers, categories: response.data.categories, workManuals: response.data.workManuals, itemsGroups: response.data.itemsGroups, viCodes: response.data.viCodes });
                                        this.setState({
                                            isLoading: false
                                        })
                                        const temps = ChecklistHelper.mapChecklists(templates, this.props.activityTemplateData?.categories, this.props.activityTemplateData?.customers,
                                            this.props.activityTemplateData.projects);
                                        this.props.setTemplates(temps);

                                    }
                                }).
                                catch(error => {
                                    alert(error);
                                    this.setState({
                                        isLoading: false
                                    })
                                })
                        })

                    }
                    else {
                        const temps = ChecklistHelper.mapChecklists(templates, this.props.activityTemplateData?.categories, this.props.activityTemplateData?.customers,
                            this.props.activityTemplateData.projects);
                        this.props.setTemplates(temps);
                    }

                }
            })
            .catch(error => {
                alert(error);
                this.setState({
                    isLoading: false
                });
            });

    }

    cancelAdd = () => {
        this.setState({
            openAddDialog: false,
            openEditDialog: false

        });
        this.props.setFillData(false);
        this.props.setSelectedTemplate({})
    }

    openDeleteDialog = (id) => {
        const template = { ...this.props.activityTemplateData.templates.find(item => item.id == id) };
        this.props.setSelectedTemplate(template);
        if (parseInt(this.props.userData.userData.id) == template.userId || template.publicTemplate) {
            this.setState({
                openDeleteDialog: true
            })
        }
        else {
            const notification = new Notification({
                title: "",
                description: 'Only public templates and templates created by you can be deleted',
                icon: ''
            });
            notification.init();
        }
    }

    openChecklistSubItems = (id) => {
        const selectedTemplate = { ... this.props.activityTemplateData.templates.find(item => item.id == id) };
        this.setState({
            isLoading: true
        }, () => {
            ApiClient.post("ActivityTemplate/GetTemplateItems", { id: id })
                .then(response => {
                    if (response.data) {
                        selectedTemplate['items'] = response.data;
                        this.props.setSelectedTemplate(selectedTemplate);
                        this.setState({
                            isLoading: false
                        });
                    }
                    ChecklistHelper.mapTemplate(selectedTemplate, this.props.activityTemplateData.categories);
                    selectedTemplate.items.sort((a, b) => (
                        ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) === (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity)) ?
                            (((a.itemSortId ? parseInt(a.itemSortId) : Infinity) === (b.itemSortId ? parseInt(b.itemSortId) : Infinity)) ?
                                (a.name - b.name)
                                :
                                ((a.itemSortId ? parseInt(a.itemSortId) : Infinity) - (b.itemSortId ? parseInt(b.itemSortId) : Infinity)))
                            :

                            ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) - (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity))
                    ));
                    this.props.setSelectedTemplate(selectedTemplate);
                    this.props.changeCheckListMod(constants.pageMods.SUB_LIST);
                })
                .catch(error => {
                    alert(error);
                    this.setState({
                        isLoading: false
                    });
                });
        });

    }

    addTemplate = () => {
        const template = { ...this.props.activityTemplateData.selectedTemplate };
        template['userId'] = parseInt(this.props.userData.userData.id);
        if (!template.templatableType)
            template['templatableType'] = "Customer";
        if (!template.name || !template.templatableId || !template.templateClassification) {
            const notification = new Notification({
                title: 'Add Activity Template',
                description: 'Please fill all required data to save',
                timeout: 5000,
                icon: 'icon-triangle'
            });
            notification.init();
        }
        else {
            this.setState({ isLoading: true },
                () => {
                    this.props.setSelectedTemplate(template);
                    ApiClient.post("ActivityTemplate/AddActivityTemplate", template)
                        .then(response => {
                            if (response.data) {
                                const templates = response.data;
                                this.props.setSelectedTemplate({});
                                const temps = ChecklistHelper.mapChecklists(templates, this.props.activityTemplateData.categories, this.props.activityTemplateData?.customers,
                                    this.props.activityTemplateData?.projects);
                                this.props.setTemplates(temps);
                                this.setState({
                                    isLoading: false,
                                    openAddDialog: false,
                                    openEditDialog: false,
                                });
                                const notification = new Notification({
                                    title: 'Add Activity Template',
                                    description: 'Template added successfully',
                                    timeout: 5000,
                                    icon: 'icon-Check'
                                });
                                notification.init();

                            }
                            else {
                                this.setState({
                                    isLoading: false,
                                });
                                const notification = new Notification({
                                    title: 'Add Activity Template Failed',
                                    description: 'Template with the same name already exist. Please change the name as template name should be unique per customer / project',
                                    icon: 'icon-cross'
                                });
                                notification.init();
                            }
                        }).
                        catch(error => {
                            alert(error);
                            this.setState({
                                isLoading: false,
                                openAddDialog: false,
                                openEditDialog: false
                            })
                        })
                });
            const notification = new Notification({
                title: 'Add Activity Template',
                description: 'Template is being added',
                timeout: 5000,
                icon: 'icon-check'
            });
            notification.init();


        }
    }

    deleteTemplate = () => {
        const template = { ...this.props.activityTemplateData.selectedTemplate };
        template.userId = parseInt(this.props.userData.userDetail.id);
        const templates = [... this.props.activityTemplateData.templates.filter(item => item.id != template.id)];
        this.setState({
            isLoading: true, openDeleteDialog: false
        },
            () => {
                ApiClient.post("ActivityTemplate/DeleteTemplate", template)
                    .then(response => {
                        if (response.data) {
                            this.props.setTemplates(templates);
                            this.props.setSelectedTemplate({});
                            this.setState({
                                isLoading: false,
                            });
                            const notification = new Notification({
                                title: '',
                                description: 'Template deleted successfully',
                                timeout: 5000,
                                icon: ''
                            });
                            notification.init();
                        }
                        else {
                            this.setState({
                                isLoading: false,
                            });
                            CommonHelper.createNotification({
                                title: '',
                                description: 'Template is assigned to activities and cannot be deleted.',
                                icon: ''
                            });

                        }
                    }).
                    catch(error => {
                        alert(error);
                        this.setState({
                            isLoading: false,
                        })
                    })
            })
    }

    openEditTemp = () => {
        this.props.changeCheckListMod(constants.pageMods.EDIT);
    }

    initiateAdd = () => {
        this.props.setSelectedTemplate({});
        this.setState({ openAddDialog: true });
    }

    openEditDialog = (id) => {
        const template = { ...this.props.activityTemplateData.templates.find(item => item.id == id) };
        this.props.setSelectedTemplate(template);
        if (parseInt(this.props.userData.userData.id) == template.userId || template.publicTemplate) {
            this.setState({
                openEditDialog: true
            });
        }
        else {
            const notification = new Notification({
                title: "",
                description: 'Only public templates and templates created by you can be edited',
                icon: ''
            });
            notification.init();
        }
    }

    onCreatedRow = (tr, rowData) => {
        if (rowData.deleteDate)
            tr.style.color = "#2424247d";
    }

    render() {
        return (
            <EuiTile fullscreen>
                <EuiRow key={this.state.isLoading}
                    style={{ marginLeft: "15px" }}
                    disabled={this.state.isLoading ? "disabled" : ""}>
                    <EuiLoading id="activtyChecklistListLoading" isLoading={this.state.isLoading} />
                    <ObseleteEuiTable
                        style={{ marginLeft: "15px" }}
                        classes="tiny stripes"
                        columns={this.state.columns}
                        data={this.props.activityTemplateData.templates}
                        id="activityChecklistList"
                        filter={true}
                        hideSearch={true}
                        sortable={true}
                        paginated={true}
                        rowsPerPage={10}
                        viewActionButton={true}
                        onCreatedRow={(tr, rowData) => this.onCreatedRow(tr, rowData)}
                        actionButton={
                            <button hidden={(this.props.userData.userData.jobRole != constants.userRoles.ERICSSON_IM && this.props.userData.userData.jobRole != constants.userRoles.ADMIN ? true : false)}
                                id="addTemplate"
                                className="btn primary ml-10"
                                onClick={this.initiateAdd}>{<span > <i class="icon icon-plus"></i> Template</span>}</button>
                        }                >
                    </ObseleteEuiTable>

                    <DialogModal
                        id="addTemplate"
                        open={this.state.openAddDialog}
                        title="Add Activity Template"
                        content={<AddActivityTemplate />}
                        buttonName="Add"
                        type="primary"
                        handleClose={this.cancelAdd}
                        onClick={this.addTemplate}
                        customStyle={{ width: 430 }}
                    />
                    <DialogModal
                        id="editTemplate"
                        open={this.state.openEditDialog}
                        title="Edit Activity Template"
                        content={<AddActivityTemplate edit={true} />}
                        buttonName="Save"
                        type="primary"
                        handleClose={this.cancelAdd}
                        onClick={this.addTemplate}
                        customStyle={{ width: 430 }}
                    />
                    <DialogModal
                        id="deleteTemplate"
                        open={this.state.openDeleteDialog}
                        title="Delete Template"
                        content={
                            <div>Are you sure you want to Delete this template?</div>}
                        buttonName="Delete"
                        type="primary"
                        handleClose={() => { this.setState({ openDeleteDialog: false }) }}
                        onClick={this.deleteTemplate}
                        mainButtonStyle={{ backgroundColor: 'red' }}
                    />
                </EuiRow >
            </EuiTile>
        );
    }

}

const mapStateToProps = state => ({ userData: state.user, aspResourcesData: state.asp, navbarData: state.navigation, activityTemplateData: state.activityChecklists, userAdminData: state.userAdmin });
const mapDispatchToProps = dispatch => {
    return {
        changeCheckListMod: (mod) => dispatch({ type: "CHANGE_MOD", payload: mod }),
        setSelectedTemplate: (selectedTemplate) => dispatch({ type: "SET_SELECTED_TEMPLATE", payload: selectedTemplate }),
        setItem: (item) => dispatch({ type: "SET_ITEM", payload: item }),
        setTemplates: (temps) => dispatch({ type: "SET_TEMPLATES", payload: temps }),
        setData: (data) => dispatch({ type: "SET_DATA", payload: data }),
        setFillData: (value) => dispatch({ type: "SET_FILL_DATA", payload: value })

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ChecklistItemsList));