import React from "react";
import { connect } from "react-redux";
import packageData from '../../package.json';
import ApiClient from "../utils/api-client";
import { withRouter } from "react-router-dom";
import EuiSwitch from "../eui-components/switch";
import UserHelper from "../utils/user-helper";
import EuiTimeline from "../eui-components/timeline";
import DialogModal from "../components/dialog";
import EuiRangeSlider from "../eui-components/range";
import CommonHelper from "../utils/common-helper";
import constants from "../utils/constants";
import AutoAssignmentHelper from "../utils/auto-assignment-helper";

class Sysbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            openDialog: false,
        };
    }

    clearCache = () => {
        ApiClient.post("Session/ClearCache")
            .then(response => {
                if (response.data) {
                    window.location.reload();
                    this.props.history.push("/");
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    routeMainPage = () => {
        this.props.user.token === "" ? this.props.history.push("/signin") : this.props.history.push("/");
    }

    checkinCheckout = () => {
        const path = this.props.location.pathname;
        const rscPerson = this.props.user?.rscPerson;

        if (rscPerson.checkin &&
            (path === "/live-streaming" ||
                (rscPerson.personType?.toString() === constants.userRoles.RSC_SITE_SUPERVISOR && path === "/activity-inbox") ||
                (rscPerson.personType?.toString() === constants.userRoles.RSC_OHS_INSPECTOR && path === "/ehs-inbox"))
        ) {
            CommonHelper.createNotification({ title: "Warning", description: "Please, leave from session itself or inbox to proceed with check-out." });
            return;
        }

        var request = {
            rscPersonId: rscPerson.id,
            checkin: !rscPerson.checkin,
        };

        ApiClient.post("AutoAssignment/CheckinCheckout", request)
            .then(response => {
                if (response.data) {
                    ApiClient.checked(request);
                    AutoAssignmentHelper.mapRscPerson(response.data);
                    this.props.setRscPerson({
                        ...rscPerson,
                        ...response.data
                    });
                } else {
                    CommonHelper.createNotification({ title: response.data.errorDetail, icon: "cross" });
                }
            })
            .catch(error => {
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "cross" });
                console.error(error);
            });
    }

    changeECAConfig = () => {

        const type = !this.props.user.ecaConfig ? constants.ecaConfigurations.DEMO : constants.ecaConfigurations.QA;
        ApiClient.post("Session/ChangeECAConfiguration", { id: type })
            .then(response => {
                if (response.data) {
                    this.props.setECAConfig(!this.props.user.ecaConfig);
                    CommonHelper.createNotification({ title: "Success", description: "ECA Configuration changed successfully", icon: "check", timeout: 5000 });
                } else {
                    CommonHelper.createNotification({ title: "Error", description: "Prod database configuation can't be changed!", icon: "cross" });
                }
            })
            .catch(error => {
                console.error(error);
                CommonHelper.createNotification({ title: "Error", description: error, icon: "cross" });
            });
    }

    render() {
        const rscPerson = this.props.user.rscPerson;

        return (<>
            <header className="sysbar">
                <div className="items-container">
                    <div className="item">
                        <i className="icon icon-econ"></i>
                        <span className="product" style={{ fontSize: 21 }} onClick={this.routeMainPage}>Ericsson Eritop ASP</span>
                        <span className="acronym">EASP</span>
                    </div>
                </div>
                {this.props.policy ?
                    null :
                    <div className="items-container">
                        {
                            !UserHelper.isRscInspector(this.props.user.userData.jobRole) ? null :
                                <>
                                    <div className="item hover" key={"timeline_" + rscPerson?.checkin}>
                                        <span className="tooltip pointer">
                                            <i className="icon icon-time" style={{ fontSize: 21, color: (rscPerson?.checkin ? "green" : "red") }} onClick={() => this.setState({ openDialog: !this.state.openDialog })}></i>
                                            <span className={"message black left "}>
                                                <CheckInPanel id="tooltipPanel" rscPerson={this.props.user.rscPerson} checkinCheckout={() => this.checkinCheckout()} />
                                            </span>
                                        </span>
                                    </div>
                                </>
                        }
                        {constants.baseUrl === constants.prodURL ? null :
                            <div className="item hover">
                                <EuiSwitch
                                    id="ecaConfigSwitch"
                                    onChange={() => this.changeECAConfig()}
                                    checked={this.props.user.ecaConfig}
                                    dataEnabled="DEMO"
                                    dataDisabled="QA"
                                    name="ecaConfig"
                                />
                            </div>
                        }
                        <div className="item hover">
                            <span className="tooltip pointer">
                                <i className="icon icon-reload" style={{ fontSize: 21 }} onClick={() => this.clearCache()}></i>
                                <span className="message bottom">Refresh this page manually.</span>
                            </span>
                        </div>
                        <div className="item hover">
                            <a href="https://gitlab.internal.ericsson.com/eritopasp/eritopasp/-/releases" target="_blank">
                                v{packageData.version}
                            </a>
                        </div>
                        <div className="item notification-log-trigger">
                            <span className="notification-log-number"></span>
                            <i className="icon icon-notification"></i>
                            <i className="icon icon-notification-ring"></i>
                        </div>
                        <div className="item hover settings-trigger" onClick={this.props.togglePanel}>
                            <span style={{ fontSize: 20, display: "flex", alignItems: "center" }}>
                                <i style={{ fontSize: 21, paddingRight: 2 }} className="icon icon-profile"></i>
                                {this.props.user.userData.username}
                            </span>
                        </div>
                    </div>
                }
            </header>

            <DialogModal
                buttonsVisible="false"
                content={<div style={{ minHeight: 150, minWidth: 250 }}>  <CheckInPanel id="checkDialogPanel" rscPerson={this.props.user.rscPerson}
                    checkinCheckout={() => this.checkinCheckout()} /></div>}
                handleClose={() => this.setState({ openDialog: false })}
                id="privacy"
                onClick={() => this.setState({ openDialog: false })}
                open={this.state.openDialog}
                title="Check-In / Check-Out"
            >
            </DialogModal>

        </>
        );
    }
}

const CheckInPanel = props => {
    const rscPerson = props.rscPerson;
    return <div {...props}>
        <div className="hover flex-middle-content p-1">
            <EuiSwitch id={props.id + "checkinSwitch"}
                onChange={() => props.checkinCheckout(rscPerson)}
                checked={rscPerson?.checkin}
                dataEnabled="Checked-In"
                dataDisabled="Checked-Out"
                name="checkinSwitchName" />
        </div>
        <div className="pl-2 pr-2" style={{ minWidth: 200 }} key={"range_" + rscPerson?.lastTotalProcessed}>
            <EuiRangeSlider id={props.id + "checkinRange"} value={rscPerson?.lastTotalProcessed ?? 0} min="0"
                max={100}
                step="1" disabled={true} blackTheme={true} />
        </div>
        {rscPerson?.dailyCheckinHistory?.length > 0 &&
            <EuiTimeline data={rscPerson?.dailyCheckinHistory} id={props.id + "checkinTimeline"} />
        }
    </div>
}

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => {
    return {
        togglePanel: () => dispatch({ type: "TOGGLE_PANEL" }),
        setRscPerson: (data) => dispatch({ type: "SET_RSC_PERSON", payload: data }),
        setECAConfig: (ecaConfig) => dispatch({ type: "SET_ECA_CONFIG", payload: ecaConfig })
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Sysbar));
