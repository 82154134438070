import React from "react";
import EuiRow from "../../../../eui-components/row";
import EuiMultiPanelTile from "../../../../eui-components/multipaneltile";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import DashboardData from "./dashboard";
import AspPmDashboardFilter from "./dashboardFilter";

const AspPmDashboardSearch = (props) => {
    const dashboardData = useSelector(state => state.dashboard);
    const incentives = dashboardData.kpis || [];
    const leftPanel = {
        title: "Filter By", content: <AspPmDashboardFilter />,
        style: { width: "400px" }
    };
    const midPanel = {
        title: <div className="flex-start-content "><div className="mr-2" style={{ color: "gray", fontWeight: 100 }}>
            {(!dashboardData.dateSelected?.startDate || !dashboardData?.dateSelected?.endDate ? "" : new Date(dashboardData?.dateSelected?.startDate).toDateString() + "-" + new Date(dashboardData?.dateSelected?.endDate).toDateString())}
        </div> </div>,
        content: <DashboardData data={incentives} />,
    };
    return (<EuiRow style={{ zoom: "0.9" }}>
        <EuiMultiPanelTile id="aspPmDashbordMultiPanel"
            midPanelButton={true}
            leftPanel={leftPanel}
            midPanel={midPanel}
            leftPanelStyle={{ width: "400px" }}
        />
        <ToastContainer enableMultiContainer containerId={'IM'} autoClose={2500} />
    </EuiRow>);
}
export default AspPmDashboardSearch;