const initialState = {   
    mod: 0,  
    ehsPings: [],
    currentEhsPing: { id: 0 },
    ratings: []
};

export default (state = initialState, action) => {
    switch (action.type) {
       
        case "SET_EHS_PAGE_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_EHS_PINGS":
            return {
                ...state,
                ehsPings: action.payload
            };
        case "SET_CURRENT_EHS_PING":
            return {
                ...state,
                currentEhsPing: action.payload
            };
        case "SET_EHS_RATINGS":
            return {
                ...state,
                ratings: action.payload
            };
       
        default:
            return state;
    }

};
