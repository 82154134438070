import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import constants from "../../utils/constants";
import ProjectSearch from './ProjectSearch.js'
import ProjectDetails from './ProjectDetails.js';
import "../../css/workplanStyle.css";
import ApiClient from "../../utils/api-client";
import PageHelper from "../../utils/page-helper";
import ApiHelper from "../../utils/api-helper";

class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }
    componentDidMount() {
        PageHelper.getCoordinates();
        ApiHelper.setCountriesWithDetails((data) => this.props.setCountriesList(data), (data) => this.props.setCustomersList(data));
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        if (!this.props.userData.users || this.props.userData.users?.length == 0) {
            ApiClient.post("user/getAllUsers").then(response => {
                this.props.setUsers(response.data)
            }).catch(error => {
                console.error(error);
            });
        }
    }

    renderMode = () => {
        if (this.props.Projects.mod === constants.pageMods.VIEW_ONLY) {
            return <ProjectDetails />
        } else {
            return <ProjectSearch />;
        }
    }

    render() {
        return (this.renderMode());
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, Projects: state.projects, userAdmin: state.userAdmin });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setUsers: (users) => dispatch({ type: "SET_ALL_USERS", payload: users }),
        setCountriesList: (countries) => dispatch({ type: "SET_ALL_COUNTRIES", payload: countries }),
        setCustomersList: (customers) => dispatch({ type: "SET_ALL_CUSTOMERS", payload: customers }),
        setAllProjects: (allProjects) => dispatch({ type: "SET_ALL_PROJECTS", payload: allProjects })
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(Projects)));