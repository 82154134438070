import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import constants from "../../utils/constants";
import TeamSearch from "./teamSearch";
import TeamDetails from "./teamDetails";
import ApiClient from "../../utils/api-client";
import { toast } from "react-toastify";
import { setFilteredTeams, setTeam } from "../../store/actions/teamAction";
import VehicleHelper from "../../utils/vehicle-helper";
import { Logs } from "../../utils/Log";

class Teams extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        const initialState = new Date();
        const filteredTeams = [...this.props.teamData.filteredTeams.data];
        if(filteredTeams?.length===0){
            ApiClient.post("Team/GetFilteredTeams", 
            {
                userId:parseInt(this.props.userData.userData.userId), page: 1, pageSize: 30 })
                .then(response => {
                    const data = [...response.data.data];
                    this.props.setFilteredTeams({ data, totalCount: response.data.totalCount, initialState: initialState.getTime() });
                })
                .catch(error => {
                    console.error(error);
                }
                );
        }
    }

    createEditTeam = () => {
        const team = this.props.teamData.team;
        team.userId= parseInt(this.props.userData.userData.userId)

        if (!team.name) {
            toast.error("Please, fill the Team name field!", { containerId: "WD", position: toast.POSITION.TOP_CENTER });
            return;
        }
        if (team.name.trim().lenght < 2) {
            toast.error("Please, fill at least 3 characters to the team Name!", { containerId: "WD", position: toast.POSITION.TOP_CENTER });
            return;
        }
        const oldTeam = team.id ? this.props.teamData.filteredTeams.data.find(item => item.id === team.id) : team;

        const containerId = team.id > 0 ? "WD" : "WS";
        if(!team.customerId)
        {
            team.customerId = null
        }
        ///console.log("team userId",team)
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Team/CreateEditTeam", team).then(response => {
                if (response.data) {
                    Logs({
                        LogName: 'Team',
                        userId: parseInt(this.props.userData.userData.userId),
                        LogObjectId: response.data,
                        LogAction: oldTeam.id ? 'Updated' : 'Created',
                        LogTypeId: VehicleHelper.LogTypeId.TEAM,
                    },
                        oldTeam.id ?
                            {
                                oldData: oldTeam,
                                oldDataDate: new Date(),
                                newData: team,
                            } : 'Created Team').then(r => {
                                if (team.id ) {
                                    ApiClient.post("Team/GetTeamDetails", { id: team.id })
                                        .then(response => {
                                            this.setState({ isLoading: false }, () => {
                                                const team = { ...response.data }
                                                this.props.setTeam(team);
                                            });
                                        })
                                        .catch(error => {
                                            this.setState({ isLoading: false });
                                        });
                                }
                            })
                    toast.success(constants.processPozitiveMessage, { containerId: containerId, position: toast.POSITION.TOP_CENTER });
                    const initialState = new Date();
                    ApiClient.post("Team/GetFilteredTeams", { name: team.name, page: 1, pageSize: 30 })
                        .then(response => {
                            const data = [...response.data.data];
                            this.props.setFilteredTeams({ data, totalCount: response.data.totalCount, initialState: initialState.getTime() });
                        })
                        .catch(error => {
                            console.error(error);
                        });
                } else {
                    toast.error(constants.processNegativeMessage, { containerId: "WS", position: toast.POSITION.TOP_CENTER });
                }
                this.setState({ isLoading: false });
            }).catch(error => {
                console.error(error);
            });
        });
    }

    renderMod = () => {
        if (this.props.teamData.mod === constants.pageMods.VIEW_ONLY) {
            return <TeamDetails isLoading={this.state.isLoading} createEditTeam={this.createEditTeam} key={this.props.teamData.team.initialState} />
        } else {
            return <TeamSearch isLoading={this.state.isLoading} createEditTeam={this.createEditTeam} />;
        }
    }

    render() {
        return (this.renderMod());
    }

}

const mapStateToProps = state => ({
    userData: state.user,
    navbarData: state.navigation,
    teamData: state.team
});
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setFilteredTeams: (filteredTeams) => setFilteredTeams(filteredTeams)(dispatch),
        setTeam: (team) => setTeam(team)(dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(Teams)));