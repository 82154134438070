import React from 'react'
import TimerController from './TimerController';
import { Microphone } from 'phosphor-react';

const ButtonRecoredAudio = (props) => {

    const {record,state,Buttonstart,Buttonstop}=props;

  return (
    <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }}
  >
    <button
      className={"btn btn-primary button"}
      style={{
        backgroundColor: state === "" ? "#4695da" : state,
        color: "white",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
        margin: 10,
        maxHeight: '42px'
      }}
      onClick={() => {
        Buttonstart();
        setTimeout(() => Buttonstop(), 500);
      }}
      // onTouchStart={() => setTimeout(() => Buttonstart(), 500)}
      // onMouseDown={() => setTimeout(() => Buttonstart(), 500)}
      // onBlur={() => setTimeout(() => Buttonstop(), 500)}
      // onMouseUp={() => setTimeout(() => Buttonstop(), 500)}
    >
      {record === true ? (
        <>
          <span style={{ marginLeft: "20px" ,width:"100%"}}>
            <TimerController record={record} />
          </span>
        </>
      ) : (
        <Microphone size={32} />

      )}
    </button>
    <div
      style={{ marginRight: 10, display: "flex", flexDirection: "column" }}
    >
    </div>
  </div>

  )
}

export default ButtonRecoredAudio