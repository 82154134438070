import React, { useState, useEffect } from 'react';
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import AddEhsCompliance from './addEhsCompliance';
import { useDispatch, useSelector } from "react-redux";
import ApiClient from "../../utils/api-client";
import EuiRow from "../../eui-components/row";
import DialogModal from "../../components/dialog";
import DateUtils from "../../utils/date-utils";
import { Notification } from '@eds/vanilla';
import EuiLoading from "../../eui-components/loading";
import EuiSwitch from "../../eui-components/switch";
import constants from '../../utils/constants';

const EhsCompliance = () => {
    const dispatch = useDispatch();
    const [aspEhsComplianceData, setEhsComplianceData] = useState([]);
    const user = useSelector(state => state.user);
    const selectedASP = useSelector(state => state.asp.selectedASP);
    const selectedCountry = useSelector(state => state.asp.selectedCountry);
    const countries = useSelector(state => state.user.countries);
    const [openDialog, setOpenDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [warning, setWarning] = useState(false);
    const [deleteId, setDeleteId] = useState();
    var addedEhsCompliance = useSelector(state => state.asp.ehsCompliance);
    const [aspState, setAspState] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const approvedRoles = [constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR, constants.userRoles.ASP_MANAGER];
    useEffect(() => {
        if (Object.values(selectedASP).length !== 0 && selectedASP !== aspState) {
            setAspState(selectedASP);
            setEhsComplianceData([]);
        }
        if (aspEhsComplianceData?.length === 0 && Object.values(selectedASP).length !== 0 && countries !== null) {
            setIsLoading(true);
            ApiClient.post("Asp/GetAspEhsComplianceByCompanyId", { id: parseInt(selectedASP?.id) }).then(response => {
                var ehsComplianceData = response?.data?.map(e => { return { ...e, editId: e.id, deleteId: e.id, statusData: e.status == true ? "Active" : "Deleted", customerName: e.customerId !== null ? (e.customerName == null ? countries.find(c => c.text == selectedCountry)?.customers?.find(i => i.value == e.customerId)?.text : e.customerName) : "" } })
                setEhsComplianceData(ehsComplianceData);
                setIsLoading(false);
            }).catch(err => console.error(err.response.data));
        }
        if (openDialog) {
            dispatch({ type: "SET_EHS_COMPLIANCE", payload: { id: 0 } });
        }
    }, [selectedASP, aspState, openDialog, countries]);


    const AddEhsComplianceToList = () => {
        if (!addedEhsCompliance.reportingPeriodId || !addedEhsCompliance.customerId || !addedEhsCompliance.compliancePct) {
            const notification = new Notification({
                title: 'Please add all mandatory fields',
                icon: 'icon-warning-circle',
                timeout: 10000,
            })
            notification.init();
            return;
        }
        else if (addedEhsCompliance.compliancePct > 100 || addedEhsCompliance.compliancePct < 0) {
            const notification = new Notification({
                title: 'Compliance percentage must be between 0 and 100',
                icon: 'icon-warning-circle',
                timeout: 10000,
            })
            notification.init();
            return;
        }
        var ehsCompliance = {
            aspCompanyId: parseInt(selectedASP.id),
            reportingPeriodId: parseInt(addedEhsCompliance.reportingPeriodId),
            customerId: parseInt(addedEhsCompliance.customerId),
            createUserId: parseInt(user.userData.userId),
            compliancePct: parseFloat(addedEhsCompliance.compliancePct),
            status: true
        }
        setIsLoading(true);
        ApiClient.post("Asp/CheckIfEHSPeriodIsNotDuplicated", ehsCompliance).then(response => {
            if (response.data.length > 0) {
                const notification = new Notification({
                    title: 'An error occurred!',
                    description: response.data,
                    icon: 'icon-warning-circle',
                    timeout: 60000,
                })
                notification.init();
                setOpenDialog(false);
                setIsLoading(false);
            }
            else {
                ApiClient.post("Asp/AddEditDeleteASPEhsCompliance", ehsCompliance).then(response => {
                    ApiClient.post("Asp/GetAspEhsComplianceByCompanyId", { id: parseInt(selectedASP.id) }).then(response => {
                        var ehsComplianceData = response.data.map(e => { return { ...e, editId: e.id, deleteId: e.id, statusData: e.status == true ? "Active" : "Deleted", customerName: e.customerId !== null ? (e.customerName == null ? countries.find(c => c.text == selectedCountry)?.customers?.find(i => i.value == e.customerId)?.text : e.customerName) : "" } });
                        setOpenDialog(false);
                        setEhsComplianceData(ehsComplianceData);
                        const notification = new Notification({
                            title: 'EHS Compliance is Added Successfully',
                            icon: 'icon-check',
                            timeout: 10000,
                        })
                        notification.init();
                        setIsLoading(false);
                    });
                }).catch(error => {
                    const notification = new Notification({
                        title: 'An error occurred try again later',
                        icon: 'icon-warning-circle',
                        timeout: 10000,
                    })
                    notification.init();
                    setOpenDialog(false);
                    setIsLoading(false);
                });
            }

        }).catch(error => {
            console.error(error.response.data);
            const notification = new Notification({
                title: 'An error occurred while checking data, please try again',
                icon: 'icon-warning-circle',
                timeout: 60000,
            })
            notification.init();
            setOpenDialog(false);
            setIsLoading(false);
            return;
        })
    }
    const EditEhsCompliance = () => {
        if (!addedEhsCompliance.reportingPeriodId || !addedEhsCompliance.compliancePct) {
            const notification = new Notification({
                title: 'Please add all mandatory fields',
                icon: 'icon-warning-circle',
                timeout: 10000,
            })
            notification.init();
            return;
        }
        else if (addedEhsCompliance.compliancePct > 100 || addedEhsCompliance.compliancePct < 0) {
            const notification = new Notification({
                title: 'Compliance percentage must be between 0 and 100',
                icon: 'icon-warning-circle',
                timeout: 10000,
            })
            notification.init();
            return;
        }
        var editedEhsCompliance = {
            id: parseInt(addedEhsCompliance.id),
            customerId: addedEhsCompliance.customerId != null ? parseInt(addedEhsCompliance.customerId) : null,
            aspCompanyId: parseInt(selectedASP.id),
            reportingPeriodId: parseInt(addedEhsCompliance.reportingPeriodId),
            modifiedUserId: parseInt(user.userData.userId),
            compliancePct: parseFloat(addedEhsCompliance.compliancePct),
            status: true
        }
        setIsLoading(true);
        ApiClient.post("Asp/AddEditDeleteASPEhsCompliance", editedEhsCompliance).then(response => {
            ApiClient.post("Asp/GetAspEhsComplianceByCompanyId", { id: parseInt(selectedASP.id) }).then(response => {
                var ehsComplianceData = response.data.map(e => { return { ...e, editId: e.id, deleteId: e.id, statusData: e.status == true ? "Active" : "Deleted", customerName: e.customerId !== null ? (e.customerName == null ? countries.find(c => c.text == selectedCountry)?.customers?.find(i => i.value == e.customerId)?.text : e.customerName) : "" } })
                setOpenEditDialog(false);
                setEhsComplianceData(ehsComplianceData);
                setIsLoading(false);
                const notification = new Notification({
                    title: 'EHS Compliance is Edited Successfully',
                    icon: 'icon-check',
                    timeout: 10000,
                })
                notification.init();
            });
        }).catch(error => {
            console.error(error.response.data)
            const notification = new Notification({
                title: 'An error occurred try again later',
                icon: 'icon-warning-circle',
                timeout: 10000,
            })
            notification.init();
            setOpenEditDialog(false);
            setIsLoading(false);
        });
    }
    const editMode = (cellData) => {
        dispatch({ type: "SET_EHS_COMPLIANCE", payload: cellData });
        setOpenEditDialog(true);
    }
    const deleteMode = (cellData) => {
        setWarning(true);
        setDeleteId(cellData);
    }
    const confirmDelete = () => {
        var deletedCompliance = aspEhsComplianceData.find(e => e.id == deleteId);
        var deletedEhsCompliance = {
            id: parseInt(deletedCompliance.id),
            customerId: parseInt(deletedCompliance.customerId),
            aspCompanyId: parseInt(selectedASP.id),
            reportingPeriodId: parseInt(deletedCompliance.reportingPeriodId),
            deleteUserId: parseInt(user.userData.userId),
            compliancePct: parseFloat(deletedCompliance.compliancePct),
            modifiedUserId: parseInt(deletedCompliance.modifiedUserId),
            status: false
        }
        setIsLoading(true)
        ApiClient.post("Asp/AddEditDeleteASPEhsCompliance", deletedEhsCompliance).then(response => {
            setWarning(false);
            ApiClient.post("Asp/GetAspEhsComplianceByCompanyId", { id: parseInt(selectedASP.id) }).then(response => {
                var ehsComplianceData = response.data.map(e => { return { ...e, editId: e.id, deleteId: e.id, statusData: e.status == true ? "Active" : "Deleted", customerName: e.customerId !== null ? (e.customerName == null ? countries.find(c => c.text == selectedCountry)?.customers?.find(i => i.value == e.customerId)?.text : e.customerName) : ""} });
                setEhsComplianceData(ehsComplianceData);
                setIsLoading(false);
                const notification = new Notification({
                    title: 'EHS Compliance is marked deleted Successfully',
                    icon: 'icon-check',
                    timeout: 10000,
                })
                notification.init();
            });
        }).catch(error => {
            const notification = new Notification({
                title: 'An error occurred try again later',
                icon: 'icon-warning-circle',
                timeout: 10000,
            })
            notification.init();
            setWarning(false);
            setIsLoading(false);
        });
    }
    //const setFilterEHSCompliance = (e) => {
    //    e.target.checked == true ? setFilteredEhsCompliance(true) : setFilteredEhsCompliance(false);
    //}
    const columns = [
        {
            title: "Reporting Period",
            key: "reportingPeriodName",
            sort: "desc",
            width: "10%"
        },
        {
            title: "EHS Compliance %",
            key: "compliancePct",
            width: "8%",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%"
            }
        },
        {
            title: "Customer",
            key: "customerName",
            width: "8%",
        },
        {
            title: "Status",
            key: "statusData",
            width: "5%",
            onCreatedCell: (td, cellData) => {
                cellData == "Active" ? td.innerHTML = `<span><i id="active_" class="icon icon-alarm-level3 text-lg color-green "></i>Active</span>` : td.innerHTML = `<span ><i id="deleted_" class="icon icon-alarm-level3 text-lg color-gray "></i>Deleted</span>`
            }
        },
       {
            title: "Modified User Name",
            key: "modifiedUserName",
            sort: "none",
            width: "10%"
        },
        {
            title: "Modified Date and Time",
            key: "modifiedDateTimeUtc",
            sort: "none",
            width: "10%",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm")
            }
        },
        {
            title: "",
            key: "editId",
            width: "1%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                if (approvedRoles.includes(user.userData.jobRole)) {
                    var ehsCompliance = aspEhsComplianceData.find(i => i.id == cellData);
                    td.innerHTML = `<span  id=${"editCell_" + cellData} class="tooltip"><i id="edit_" style={font-weight:500px;} class="icon icon-edit color-black  text-lg clickable "></i><span class="message top-end">Edit</span></span>`
                    td.querySelector("#edit_").addEventListener('click', () => editMode(ehsCompliance));
                }
                else {
                    td.innerHTML = ""
                }
            }
        },
        {
            title: "",
            key: "deleteId",
            width: "1%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                var ehsCompliance = aspEhsComplianceData.find(i => i.id == cellData);
                if (ehsCompliance.status == true && approvedRoles.includes(user.userData.jobRole)) {
                    td.innerHTML = `<span  id= ${"deleteCell_" + cellData} class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable "></i><span class="message top-end">Delete</span></span>`
                    td.querySelector("#delete_").addEventListener('click', () => deleteMode(cellData))
                }
                else {
                    td.innerHTML = ""
                }
               }
        }]
    return (
        <EuiRow className={isLoading ? " disabled " : ""} key={isLoading + countries}>
            <EuiLoading id="AspEhsComplianceLoading" isLoading={isLoading} />
            <DialogModal
                buttonName="Delete"
                type="warning"
                title="Delete reporting period"
                content="Are you sure you want to delete this reporting period?"
                handleClose={() => setWarning(false)}
                mainButtonStyle={{ backgroundColor: "red" }}
                id="alertAspEhsComp"
                onClick={confirmDelete}
                open={warning}
                style={{ width: "380px", height: "50%" }}
                contentStyle={{ width: "380px" }}
                disableBackdropClick={true}
            />
            <DialogModal
                buttonName="Save"
                title="Add EHS Compliance"
                content={<AddEhsCompliance />}
                handleClose={() => {
                    setOpenDialog(false)
                    dispatch({ type: "SET_EHS_COMPLIANCE", payload: { id: 0 } });
                }
                }
                id="AddEhsComplianceId"
                onClick={AddEhsComplianceToList}
                open={openDialog}
                disableBackdropClick={true}
            />
            <DialogModal
                buttonName="Save"
                title="Edit EHS Compliance"
                content={<AddEhsCompliance mod={constants.actionMods.EDIT} />}
                handleClose={() => {
                    dispatch({ type: "SET_EHS_COMPLIANCE", payload: { id: 0 } });
                    setOpenEditDialog(false)
                }}
                id="EditEhsComplianceId"
                onClick={EditEhsCompliance}
                open={openEditDialog}
                disableBackdropClick={true}
            />
            {Object.values(selectedASP).length != 0 ? <>
                <button id="addEhsCompliance" hidden={ !approvedRoles.includes(user.userData.jobRole) || (countries.find(x => x.text == selectedCountry).customers.length == 0)} style={{ float: "right", marginLeft: 'auto', marginRight: 0 }} className="btn primary ml-10" onClick={() => setOpenDialog(true)}>{<span > <i class="icon icon-plus"></i>Add EHS Compliance</span>}</button>
                <ObseleteEuiTable
                data={/*deletedEhsCompliance ?*/ aspEhsComplianceData /*.filter(i => i.deleteDateTimeUtc != null) : aspEhsComplianceData.filter(i => i.deleteDateTimeUtc == null)*/}
                columns={columns}
                classes="tiny"
                id="aspEhsCompliance"
                style={{ padding: 0 }}
                styleHeader={{ width: "100%", paddingLeft: "15px" }}
                styleTd={{ width: "100%", paddingRight: 0, paddingTop: 0, paddingLeft: 0 }}
                scroll={false}
                key={aspEhsComplianceData}
                sortable={true}
                filter={true}
                rowsPerPage={10}
                exportExcel={true}
                Reportdata={aspEhsComplianceData.map(i => {
                    return {
                        Reporting_Period: i.reportingPeriodName,
                        Compliance_Percentage: i.compliancePct + '%',
                        Customer: i.customerName ?? i.customerId,
                        Modified_User: i.modifiedUserName,
                        Modified_Time_UTC: i.modifiedDateTimeUtc
                    }
                })}
                excelFileName={"EHS Compliance for " + selectedASP.name}
            />
                <p style={{padding: "5px", fontWeight: 'bold'}}>* Deleted EHS Compliance periods are not calculated</p></> : <></>}
        
        </EuiRow>
        )
}
export default EhsCompliance;