import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../../eui-components/row";
import ApiClient from "../../../utils/api-client";
import Table from "../../../components/table";
import constants from "../../../utils/constants";
import EuiTextField from "../../../eui-components/textfield";
import Rater from "react-rater";
import RadioButtons from "../../../components/radioButtons";
import RateInfo from "./rateInfo";
import EuiTile from "../../../eui-components/tile";
import EHSInboxHelper from "../../../utils/ehs-inbox-helper";
import { trim, upperCase } from "lodash";

class RateActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isFTRStatusSelected:false,
            ratingColumns: [{ field: this.props.viewOnly ? 'question' : 'name', title: 'Question', cellStyle: { padding: 0, fontSize: "0.875rem", width: "20%" } },
            { field: 'type', title: 'Answer', render: rowData => this.renderAnswers(rowData), cellStyle: { padding: 0, fontSize: "0.875rem" }, width: "20%" },
            {
                title: "Erisite Action", cellStyle: { fontSize: "0.8rem", fontSize: "0.875rem", width: "5%" },
                render: (rowData) => EHSInboxHelper.getErisiteAction(rowData.integrationStatus),
                hidden: this.props.activity ? false : true
            },
            {
                title: "Erisite Output", cellStyle: { fontSize: "0.8rem", fontSize: "0.875rem", width: "20%" },
                field: "integrationOutput",
                render: (rowData) => rowData.integrationStatus === null ? "No match with Erisite Checklist." : (rowData.integrationOutput || "No response from erisite."),
                hidden: this.props.activity ? false : true
            }

            ],
        }
    }

    componentDidMount() {
        if (this.props.viewOnly) {
            return;
        }
        this.setState({ isLoading: true }, () => {
            ApiClient.post("ActivityInbox/GetRatingStatuses")
                .then(response => {
                    const activity = this.props.activityInboxData.currentActivity;
                    let ratingList = [];
                    if (activity?.plainActivity?.rscScope == constants.remoteSiteSupervision) {
                        ratingList = [...response.data.filter(item => item.isSupervision)];
                    }
                    else {
                        ratingList = [...response.data.filter(item => item.isActive)];
                    }
                    const ratings = this.mapRatings(ratingList);
                    this.setState({ isLoading: false }, () => this.props.setRatings(ratings));
                })
                .catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                });
        });
    }

    mapRatings = (data) => {
        if (!data || data.length === 0) {
            return [];
        }
        const documents = this.props.allDocument;
        return data.map(item => {
            const newItem = { ...item };
            newItem.activityId = this.props.activityId;
            newItem.question = newItem.name;
            newItem.ratingValue = this.props.activityInboxData.ratings?.find(r => r.question == item.name)?.ratingValue;

            const activity = this.props.activityInboxData.currentActivity;

            if (documents && documents.length > 0) {
                if (newItem.id === constants.qiIfDone) {
                    newItem.ratingValue = documents.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED && d.rscSeverityLevel)
                        .reduce((a, b) => {
                            a = a + constants.severityLevels.find(s => s.value === b.rscSeverityLevel)?.rate;
                            return a;
                        }, 0)?.toString();
                } else if (newItem.id === constants.numberOfSnags) {
                    newItem.ratingValue = documents.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED
                        && d.rscSeverityLevel
                        && d.rscSeverityLevel != constants.severityLevelsLookup.OBSERVATION)?.length?.toString();
                }
            }

                delete newItem["id"];
// add start (*) to mondatory Question for each row except row in the below
                    if( upperCase(trim(newItem.name)) ===upperCase(trim("Number Of Snags/missing"))||
                        upperCase(trim(newItem.name))  ===upperCase(trim("RA Tools")) ||
                        upperCase(trim(newItem.name))  === upperCase(trim("SRS Tools"))    ||
                        upperCase(trim(newItem.name))  ===upperCase(trim("IMK tool")))
                     {
                         newItem.name = (newItem.name).toString();
                      }else{
                                    newItem.name = (newItem.name + " *").toString();
                           }
                  
          return newItem;
        })
    }

    handleChange = (e, rowData) => {
        const target = e.target;
        const value = target.value || e.rating;
        let ratings = [...this.props.activityInboxData.ratings];
        if( upperCase(trim(rowData.question))===upperCase(trim("FTR Status"))){
            if(upperCase(trim(value)) ===upperCase(trim("FTR")) ){
                this.setState({ isFTRStatusSelected:true})
                
            }else{
                this.setState({ isFTRStatusSelected:false})

            }
        }
        ratings = ratings.map(item => {
            const newItem = { ...item };
            if (item.question === rowData.question) {
                newItem.ratingValue = (value ?? "").toString();
            }
            return newItem;
        });

        this.props.setRatings(ratings);
    }

    renderAnswers = (rowData) => {
        if (rowData.type === constants.ratingStatusTypes[1]) {
            return <Rater total={5}
                rating={rowData.ratingValue ?? 0}
                interactive={!this.props.viewOnly || rowData.isDisabled}
                onRate={(event) => this.handleChange(event, rowData)}
            />
        } else if (rowData.type === constants.ratingStatusTypes[2]) {
            
            if(upperCase(trim(rowData.question))  ===  upperCase(trim("Main Issue"))){
           
                    return <RadioButtons name={rowData.question}
                    value={this.state.isFTRStatusSelected?"": rowData.ratingValue ?? ""}
                    values={rowData.ratingStatusItems}
                    handleChange={(e) => this.handleChange(e, rowData)}
                    disabled={this.state.isFTRStatusSelected} />;
                
            }else{
                return <RadioButtons name={rowData.question}
                value={rowData.ratingValue ?? ""}
                values={rowData.ratingStatusItems}
                handleChange={(e) => this.handleChange(e, rowData)}
                disabled={this.props.viewOnly || rowData.isDisabled} />;
            }
        } else {
            if (rowData?.name?.includes("Snag List")) {
                return <textarea name={rowData.question?.replace(/\s/g, '')}
                    value={rowData.ratingValue ?? ""}
                    onChange={(e) => this.handleChange(e, rowData)}
                    style={{ border: "none", width: "100%", margin: 4, marginLeft: 0 }}
                    disabled={this.props.viewOnly || rowData.isDisabled}
                />
            }
            else {
                return <EuiTextField name={rowData.question?.replace(/\s/g, '')}
                    value={rowData.ratingValue ?? ""}
                    onChange={(e) => this.handleChange(e, rowData)}
                    style={{ border: "none", width: "100%", margin: 4, marginLeft: 0 }}
                    disabled={this.props.viewOnly || rowData.isDisabled}
                />
            }
        }
    }


    render() {
        return (<EuiRow>
            {this.props.viewOnly ? <RateInfo activity={this.props.activity} /> : null}
            <EuiRow>
                <Table
                    columns={this.state.ratingColumns}
                    data={this.props.activity?.ratings || this.props.activityInboxData.ratings}
                    isLoading={this.state.isLoading}
                    options={{
                        search: false,
                        header: false,
                        paging: false,
                        toolbar: false,
                    }}
                >
                </Table>
            </EuiRow>
        </EuiRow>);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, activityInboxData: state.activityInbox });
const mapDispatchToProps = dispatch => {
    return {
        setRatings: (ratings) => dispatch({ type: "SET_RATINGS", payload: ratings }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RateActivity);
