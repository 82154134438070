import React from "react";
import {
	Box
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import '../../../pages/vehicle-inbox/FilterEvidancesByStatus/styleButton.css';
import EuiRow from "../../../eui-components/row";
import Pill from "../../pill";
import constants from "../../../utils/constants";
import { HubConnectionState } from "@microsoft/signalr";

const ChatHeader = (props) => {
	const { activityId, ehsPingId, rscScope, refreshAndReconnect, filterConversation, setFilterConversation, connection, connectionStatus } = props;

	const theme = useTheme();
	const {  } = props;

	return (
		<Box
			p={2}
			width={"100%"}
			sx={{
				backgroundColor:
					theme.palette.mode === "light"
						? "#F8FAFF"
						: theme.palette.background,
				boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
			}}
		>
			<EuiRow>
				<EuiRow>
					<div className="column sm-12 md-6 lg-6 xl-6 filled">
						<b>Activity ID  :</b>  {activityId}
					</div>
					<div className="column sm-12 md-6 lg-6 xl-6 filled">
						<b>Filter Conversation</b>
						<div className="column sm-12 md-6 lg-6 xl-6 filled">
							<Pill
								onClick={() => {
									setFilterConversation(constants.FilterConversation.EHS);

								}}
								key={activityId}
								title="EHS"
								selected={filterConversation == constants.FilterConversation.EHS}
							/>
							<Pill
								onClick={() => {
									setFilterConversation(constants.FilterConversation.Activity);
								}}
								key={activityId}
								title="Activity"
								selected={filterConversation == constants.FilterConversation.Activity}
							/>
							<Pill
								onClick={() => {
									setFilterConversation(constants.FilterConversation.All);
								}}
								key={activityId}
								title="Show All"
								selected={filterConversation == constants.FilterConversation.All}
							/>
						</div>
					</div>
					<div className="column sm-12 md-6 lg-6 xl-6 filled" style={{ marginTop: -30 }}>
						<b> Session Type :</b>  {rscScope}

					</div>
					<div className="row sm-12 md-6 lg-6 xl-6 filled">
						<Pill
							style={{width: 192, color: 'white' , backgroundColor: connectionStatus == HubConnectionState.Disconnected? 'red': 'green'}}
							onClick={() => {
								refreshAndReconnect()
							}}
							key={activityId}
							title={ connectionStatus}
						/>
						
					</div>
				</EuiRow>

			</EuiRow>

		</Box>
	);
};

export default ChatHeader;
