import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import KpiCard from "../../../components/kpicard";
import EuiVerticalBarChart from "../../../eui-components/lineChart";
import EuiLoading from "../../../eui-components/loading";
import EuiRow from "../../../eui-components/row";
import EuiTabs from "../../../eui-components/tabs";
import ProductivityPerformanceDetails from "./productivityDashboardPerformance";

const ProductivityDashboardIndicator = (props) => {

    const productivityDashboardData = useSelector(state => state.productivityDashboard);
    const kpis = productivityDashboardData?.kpis;
    const trendData = productivityDashboardData.trendData;
    const details = productivityDashboardData?.sitesDetails;
    const [trendSelected, setTrendSelected] = useState(false);

    useEffect(() => {
        setTrendSelected(false);
    })

    const utilization = kpis?.utilization ?? 0;
    const productivity = !kpis?.productivity || kpis?.productivity == NaN ? 0 : kpis?.productivity;
    const data = {
        "common": trendData.trendDates || ["1-1-2020"],
        "series": [{ "name": "Productivity", "values": trendData.productivityTrend || [] }, { "name": "Utilization", "values": trendData.utilizationTrend || [] }]
    }

    let uti = trendData.utilizationTrend?.reduce((a, b) => {
        a += b;
        return a;
    }, 0) / trendData.utilizationTrend?.length || 0;
    let prod = trendData.productivityTrend?.reduce((a, b) => {
        a += b;
        return a;
    }, 0) / trendData.productivityTrend?.length || 0;
    //let uti = utilization;
    //let prod = productivity
    const keyPerformanceIndicator = <EuiRow key={kpis} style={{ marginTop: "5px", backgroundColor: "#ebebeb", zoom: "0.75" }} >
        <EuiRow>
            <KpiCard className="tile sm-12 lg-6 xl-6" id="productivity" gaugeSettings={`{"units": "Productivity", "value":${prod ? parseFloat(prod).toFixed(1) : 0},"size":"large","min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85,  "color": "gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
                kpiArea="Productivity"
                kpiName=""
                percentageValue={productivity}
                infoTip="productivity"
                date="-"
                gaugeSize="large"
            />
            <KpiCard className="tile sm-12 lg-6 xl-6" id="utilization" gaugeSettings={`{"value":${uti ? parseFloat(uti).toFixed(1) : 0},"size":"large","min": 0, "max": 100, "units": "%",
                                            "limits": [
                                                  {"from": 0, "to": 85,  "color": "gray", "label": "Not Eligible"},
                                                  {"from": 85, "to": 100, "color": "green", "label": "Eligible"}
                                                ]}`}
                kpiArea="Utilization"
                kpiName=""
                percentageValue={utilization}
                infoTip="productivity"
                date="-"
                gaugeSize="large" />
        </EuiRow>
        <EuiRow className="w-10">
            <div className="tile sm-12 lg-12 xl-12"  >
                <EuiRow style={{ overflowY: "scroll", maxHeight: "600px" }}>
                    <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                        <EuiVerticalBarChart id="pdashboardTrend" data={data} height={200} unit="%" />
                    </EuiRow>
                </EuiRow>
            </div>
        </EuiRow>
    </EuiRow>;

    const performanceDetails = <ProductivityPerformanceDetails key={details || details.actualGroupingColumns} data={details.performanceDetails} />

    const tabContents = [
        { value: 1, children: keyPerformanceIndicator, selected: "selected", id: "performanceIndicatorTab" },
        { value: 2, children: performanceDetails, selected: "", id: "performanceDetailsTab" }];

    const tabTitles =
        [{ value: 1, name: "Key Performance Indicator", selected: "selected", onClick: () => { setTrendSelected(false) } },
        { value: 2, name: "Team Performance", selected: "", onClick: () => { setTrendSelected(false) } }];

    return (
        <EuiRow key={productivityDashboardData.isLoading} disabled={productivityDashboardData.isLoading ? "disabled" : ""}>
            <EuiLoading isLoading={productivityDashboardData.isLoading} />
            <EuiTabs
                id="productivityDashboardTabs"
                titlesColumnStyle={{ marginBottom: "10px" }}
                contentStyle={{ width: "100%" }}
                titles={tabTitles}
                contents={tabContents}
                tabStyle={{ width: "100%" }}
                viewActionButton={true}
            />
        </EuiRow>
    );

}
export default ProductivityDashboardIndicator;