import React, { useEffect, useState } from "react";
import EuiRow from "../../../eui-components/row";
import ObseleteEuiTable from "../../../eui-components/obseleteTable";
import DateUtils from "../../../utils/date-utils";
const EhsDetails = (props) => {
    const columns = [
        {
            title: "Ping ID",
            key: "id",
            sort: "none",
            width: "3%"
        }, {
            title: "Activity ID",
            key: "activityId",
            sort: "none",
            width: "3%"
        },
        {
            title: "Workplan Name",
            key: "wpName",
            sort: "asc",
            width: "8%",
            //  cellStyle: "white-space:nowrap;"
        },
        {
            title: "ASP Team Leader",
            key: "leaderName",
            sort: "asc",
            width: "5%",
            cellStyle: "white-space:nowrap;"
        },
        {
            title: "EHS Supervisor",
            key: "supervisorName",
            sort: "asc",
            width: "7%",
            cellStyle: "white-space:nowrap;"
        },
        {
            title: "Review Date",
            key: "ratingDate",
            sort: "none",
            width: "5%",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm")
            },
            cellStyle: "white-space:nowrap;"
        },
        {
            title: "EHS Passed",
            key: "ehsPass",
            sort: "none",
            width: "1%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<i  class="icon icon-success color-green" " />`
                }
                else {
                    td.innerHTML = td.innerHTML = `<i  class="icon icon-failed color-red" />`
                }
                td.style = "text-align:center; font-size:19px;";
            },
            hideFilter: true,
        },
        {
            title: "First Time Pass",
            key: "ftp",
            sort: "none",
            width: "5%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<i  class="icon icon-success color-green" " />`
                }
                else {
                    td.innerHTML = td.innerHTML = `<i  class="icon icon-failed color-red"  />`
                }
                td.style = "text-align:center; font-size:19px;";
            },
            hideFilter: true
        },
        {
            title: "Critical Violation Reported",
            key: "criticalViolationReporting",
            sort: "none",
            width: "1%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<i  class="icon icon-failed  color-red" style="font-size:19px;" /> `;
                }
                else {
                    td.innerHTML = `<i  class="icon icon-success color-green" style="font-size:19px;" />`;
                }
                td.style = "text-align:center;";
            },
            hideFilter: true
        },
        {
            title: "RSC Review LT<=15min",
            key: "rscReviewLt",
            sort: "none",
            width: "5%",
            textAlign: "center",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<i  class="icon icon-success color-green" " />`
                }
                else {
                    td.innerHTML = `<i  class="icon icon-failed color-red"  />`
                }
                td.style = "text-align:center; font-size:19px;";
            },
            hideFilter: true
        },
        {
            title: "Overall EHS LT<=45min",
            key: "overallEhsLt",
            width: "4%",
            textAlign: "center",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    td.innerHTML = `<i  class="icon icon-success color-green" style="font-size:19px;" />`;
                }
                else {
                    td.innerHTML = `<i  class="icon icon-failed  color-red" style="font-size:19px;" /> `;
                }
                td.style = "text-align:center; ";
            },
            hideFilter: true
        }
    ]
    const data = props.data;
    var DD = new Date();
    const fileName = "dashboard-sitesDetails" + DD.toISOString();
    var excelData = data?.map(e => {
        var mappedItem = {
            BuildHub: e.buildHub,
            CustomerUnit: e.customerUnit,
            Country: e.countryName,
            Customer: e.customerName,
            Project: e.projectName,
            AspCompany: e.companyName,
            WorkplanName: e.wpName,
            //CustomerSiteId: e.customerSiteId,
            ActivityID: e.activityId,
            ActivityName: e.activity?.name,
            EHSPingId: e.id,
            ASPTeamLeader: e.leaderName,
            EHSSupervisor: e.supervisorName,
            UploadDate: e.uploadDate?.slice(0, 16),
            SentToRsc: e.sentOhsToRsc?.slice(0, 16),
            RatingDate: e.ratingDate?.slice(0, 16),
            EHSPassed: e.ehsPass ? "Yes" : "No",
            FirstTimePassed: e.ftp ? "Yes" : "No",
            CriticalViolationReported: e.criticalViolationReporting ? "Yes" : "No",
            RscReviewLT: parseInt(DateUtils.dateDifferenceInMinutes(e.ratingDate, e.sentOhsToRsc)) + "min",
            overallEhsLt: parseInt(DateUtils.dateDifferenceInMinutes(e.ratingDate, e.uploadDate)) + "min"
        }
        return mappedItem;
    })
    
    return (<EuiRow key={data}>
        <ObseleteEuiTable
            id="ehsDashboardSiteDetails"
            columns={columns}
            data={data}
            scroll={false}
            classes="tiny split-striped"
            exportExcel={true}
            Reportdata={excelData}
            excelFileName={fileName}
            filter={true}
            paginated={true}
            rowsPerPage={13}
        />
    </EuiRow>)
}
export default EhsDetails;
