import React from "react";
import { MultiPanelTile } from "@eds/vanilla/multi-panel-tile/MultiPanelTile";


class EuiMultiPanelTile extends React.Component {

    componentDidMount() {
        if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new MultiPanelTile(element);
                    component.init();
                });
            }
        }
        else {
            console.error("You should set an id to MultiPanelTile component");
        }
    }

    render() {
        return (
            <div className={"tile sm-12 " + (this.props.className ?? "")} id={this.props.id} style={this.props.style}>
                <div className="content" style={this.props.style}>
                    <div className="row multi-panel-tile">
                        <div className={"left-panel resize horizontal " + (!this.props.leftPanel ? "hidden" : "")} style={this.props.leftPanelStyle} >
                            <div className="header" style={{ paddingBottom: 15, ...this.props.leftPanel?.headerStyle }}>
                                <div className="left">
                                    <span className="title">{this.props.leftPanel?.title}</span>
                                </div>
                                <div className="right">
                                    <span className="tooltip pointer">
                                        <i className="icon icon-cross actionable left-panel-trigger"></i>
                                        <span className="message bottom-end">Collapse panel</span>
                                    </span>
                                </div>
                            </div>
                            <div className="content">
                                {this.props.leftPanel?.content}
                            </div>
                        </div>
                        <div className={"mid-panel " + (!this.props.midPanel ? " hidden " : "")} style={{ ...this.props.midPanel?.style }}>
                            <div className={"header "} style={{ paddingBottom: 5, display: (this.props.midPanel?.hideHeader ? "none" : null) }}>
                                <div className={"left " + (this.props.midPanel?.leftClassName ?? "")}>
                                    <span className="tooltip pointer">
                                        <i className={"icon " + (this.props.midPanel?.icon ?? "icon-filter") + " actionable left-panel-trigger"}
                                            style={{ display: (!this.props.leftPanel ? "none" : null) }}></i>
                                        <span className="message bottom" style={{ left: "-10px" }}>{this.props.midPanel?.iconTooltip ?? "Toggle filters"}</span>
                                    </span>
                                    {this.props.midPanel?.hideSeperator ? null : <span className="separator"></span>}
                                    <span className="title">{this.props.midPanel?.title}</span>
                                    <span className="subtitle">{this.props.midPanel?.subtitle}</span>

                                </div>
                                <div className="right">
                                    {(this.props.midPanelButton ?
                                        this.props.button : null)}
                                    {this.props.midPanel?.rightIcon}
                                </div>
                            </div>
                            <div className="content">
                                <p style={{ marginBlockEnd: 2, ...(this.props.midPanel?.triggerStyle ?? {}) }}>
                                    <a className="right-panel-trigger" id={this.props.midPanel?.triggerId} >{this.props.midPanel?.trigger}</a>
                                </p>
                                {this.props.midPanel?.content}
                            </div>
                        </div>
                        <div className={"right-panel " + (!this.props.rightPanel || this.props.rightPanel.hidden ? " hidden " : "")} style={{ ...this.props.rightPanel?.style }}>
                            <div className="header" style={{ paddingBottom: 15 }}>
                                <div className="left">
                                    <span className="title">{this.props.rightPanel?.title}</span>
                                    <span className="subtitle">{this.props.rightPanel?.subtitle}</span>
                                </div>
                                <div className="right">
                                    <span className="tooltip pointer">
                                        <i className="icon icon-cross actionable right-panel-trigger"></i>
                                        <span className="message bottom-end">Collapse panel</span>
                                    </span>
                                </div>
                            </div>
                            <div className="content">
                                {this.props.rightPanel?.content}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default EuiMultiPanelTile;
