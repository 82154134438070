import React from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import ApiClient from "../../../utils/api-client";

class RecoverPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            token: "",
            newPass: "",
            confirmPass: "",
            status: "",
            error: "",
            newPassType: "password",
            confirmPassType: "password",
            verifyCodeType: "password",
            passwordUpdatedFlag: true,
            isLoading: false,
            view: "form"

        }

    }

    componentDidMount() {
        this.props.passwordReset();


    }

    showNewpassword = () => {
        if (this.state.newPassType == "password") {
            this.setState({ newPassType: "text" });
        }
        else {
            this.setState({ newPassType: "password" });
        }
    }

    showConfirmPass = () => {
        if (this.state.confirmPassType == "password") {
            this.setState({ confirmPassType: "text" });
        }
        else {
            this.setState({ confirmPassType: "password" });
        }
    }

    showVerifyCode = () => {
        if (this.state.verifyCodeType == "password") {
            this.setState({ verifyCodeType: "text" });
        }
        else {
            this.setState({ verifyCodeType: "password" });
        }
    }

    onClick = (event) => {
        if (this.state.confirmPass == this.state.newPass) {
            this.setState({ isLoading: true }, () => {
                ApiClient.post("User/ValidateAndResetPassword", {
                    newPass: this.state.newPass,
                    confirmNewPass: this.state.confirmPass,
                    email: this.state.email,
                    token: this.state.token,

                }
                ).then(respone => {
                    if (respone.data == "Your password is changed successfully you can now sign in!") {
                        /* this.setState({
                             status: respone.data,
                             passwordUpdatedFlag: false
                         })*/
                        this.setState({ view: "welcome", error: "", isLoading: false, passwordUpdatedFlag: false }, () => {
                            this.props.history.push("/signin");
                        });
                    }
                    else {
                        this.setState({
                            isLoading: false,
                            status: respone.data,
                        })

                    }
                })
            })
        }
        else {
            this.setState({
                status: "Your passwords don't match!"

            })
        }
        event.preventDefault();
    }
    onSignIn = () => {
        this.props.history.push("/signin")
    }

    render() {
        return (
            <div className="signin">

                <div className="watermark">

                    <i className="icon icon-econ"></i>
                    <div className="product">Ericsson Eritop ASP</div>

                </div>
                <div

                    className={
                        "welcome " + (this.state.isLoading === true ? "" : "hidden")
                    }
                >
                    <div className={"message " + (this.state.view === "welcome" ? "" : "hidden")} style={{ alignContent: "center" }}>You can now sign in with the new password</div>

                    <div className="loading" style={{ alignContent: "center" }}></div>
                </div>
                <form
                    className={this.state.isLoading == false ? "" : "hidden"}
                    onSubmit={this.onSubmit}
                >
                    <div className="message"
                        style={{ alignContent: "middle", fontSize: "30px", marginLeft: "30px", marginBottom: "30px" }}
                    >Reset Password</div>
                    <div className={"error " + (this.state.status ? "" : "hidden")}>
                        {this.state.status}
                    </div>
                    <div className="field validate">
                        <label htmlFor="username" style={{ fontSize: 12, opacity: "0.7" }}>Email Address</label>
                        <input
                            type="email"
                            name="username"
                            id="username"
                            placeholder=""
                            tabIndex="0"
                            autoFocus
                            required
                            value={this.state.email}
                            onChange={e => this.setState({ email: e.target.value })}
                        />
                    </div>
                    <div className="field validate">
                        <label htmlFor="password" style={{ fontSize: 12, opacity: "0.7" }}>New Password</label>
                        <input
                            type={this.state.newPassType}
                            name="password"
                            placeholder=""
                            id="password"
                            tabIndex="0"
                            autoFocus
                            required
                            onChange={e => this.setState({ newPass: e.target.value })}
                        />
                    </div>
                    <div className="field validate">
                        <label htmlFor="password confirm" style={{ fontSize: 12, opacity: "0.7" }}>Confirm New password</label>
                        <input
                            type={this.state.confirmPassType}
                            name="password confirm"
                            id="password confirm"
                            placeholder=""
                            tabIndex="0"
                            required
                            onChange={e => this.setState({ confirmPass: e.target.value })}
                        />
                    </div>
                    <div className="field validate">
                        <label htmlFor="password code" style={{ fontSize: 12, opacity: "0.7" }}>Verification Code</label>
                        <input
                            type={this.state.verifyCodeType}
                            name="password code"
                            id="password code"
                            tabIndex="0"
                            required
                            onChange={e => this.setState({ token: e.target.value })}
                        />
                    </div>
                    <button
                        className="btn big primary"
                        type="submit"
                        onClick={this.onClick}
                    >
                        Reset Password
                    </button>
                    <div className="help" hidden={this.state.passwordUpdatedFlag} >
                        <a hidden={this.state.passwordUpdatedFlag}
                            style={{ fontSize: "13px" }}
                            className="subtle-link"
                            onClick={this.onSignIn}

                        >
                            Sign In
                        </a>
                    </div>

                </form>
            </div>
        );
    }
}

const FormField = (props) => (
    <div style={{ margin: 20, ...props.style }} className="field validate">
        {props.children}
    </div>
);
const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => {
    return {
        passwordReset: () => dispatch({ type: "RESETPASSWORD" })

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(RecoverPassword));
