import React from "react";

export default class FormField extends React.Component {
    render() {
        return (
            <div style={{ padding: 5, ...this.props.style }} className={"field validate " + (this.props.className ?? "")}>
                {this.props.children}
            </div>
        );
    }
}
