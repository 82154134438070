import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import Table from "../../../components/table";
import constants from "../../../utils/constants";


class RcaDocuments extends React.Component {
    constructor(props) {
        super(props);

        this.state = {                   
            activitiesColumns:
                [
                    {
                        title: "Site Code", field: "siteCode",                       
                        width: "10%"
                    },
                    {
                        title: "Work Plan Name", field: "workPlanName",                      
                        width: "30%"
                    },
                    {
                        title: "Activity ID", field: "activityId",
                        width: "10%"
                    },
                    {
                        title: "Activity Name", field: "activityName",
                        width: "30%"
                    }
                ],
        }
    }

    componentDidMount() {          
    } 

    render() {
        return (
            <EuiRow>
                <Table
                    container={{ style: { background: "inherit" } }}
                    columns={this.state.activitiesColumns}
                    data={this.props.rcaActivities}
                    isLoading={this.props.isLoading}
                    options={{                       
                        search: false,                       
                        paging: false,                       
                    }}                   
                >
                </Table>               
            </EuiRow>          
        );
    }
}

const mapStateToProps = state => ({ sessionData: state.session, userData : state.user });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RcaDocuments);
