/* eslint-disable react/jsx-pascal-case */
import React, {  useCallback, useEffect, useState } from 'react'
import EuiRow from '../../../eui-components/row';
import ApiClient from '../../../utils/api-client';
import CommonHelper from '../../../utils/common-helper';
import constants from '../../../utils/constants';
import EuiSelect from '../../../eui-components/select';
import FormField from '../../../eui-components/formfield';
import EuiHeader from '../../../eui-components/header';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';

import ImagesPreviewEHS from './ImagesPreviewEHS';
import EuiAccordion from '../../../eui-components/accordion';
import VehicleCategoryBox from '../../asp-companies/vehicle-management/vehicleInspectionCategory/VehicleCategoryBox';
import { isEmpty } from 'lodash';
import EuiLoading from '../../../eui-components/loading';
import EuiTextField from '../../../eui-components/textfield';
import DateUtils from '../../../utils/date-utils';
import VehicleHelper from '../../../utils/vehicle-helper';
import { useSelector } from 'react-redux';
import { Badge } from '@material-ui/core';
import SasHelper from '../../../utils/sas-helper';



export default function VehicleMonthlyInspectionsEHS(props) {
  var inspection = useSelector(state => state.vehicleInspection);

const {monthlyInspection,vehicleId,setIsPassedMonthlyInspections,EHSPingId}=props;
const [allChecklistItems, setAllChecklistItems] = useState([])
const [inspectionsList, setInspectionsList] = useState([])
const [isOpenVehicleCategory, setIsOpenVehicleCategory] = useState(false)
const [vehicleCategory, setVehicleCategory] = useState([])
const [reloadInspection, setReloadInspection] = useState(false);
const [inspectionCategory, setInspectionCategory] = useState([])



const getListOfChecklistBySelectedInspectionId = (id) => {   

  ApiClient.post("Vehicle/GetVehicleInspectionDetails", { id })
  .then(response => {
    let currentInspection = { ...inspection.currentInspection };
    currentInspection.currentInspectionItems = response?.data;
    setAllChecklistItems(currentInspection)
  
  })
  .catch(error => {
      // setAllChecklistItems([])
      // setIsLoading(false);
      // setOpenUploadDialog(false);
      //  dispatch({ type: "SET_INSPECTION_EVIDENCES", payload: [] });
      CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
  });
}



const GetAllInspectionsCategory=()=>{
  ApiClient.get("Vehicle/GetAllInspectionsCategory")
  .then(response => {

      if (response.data){
          setInspectionCategory(response.data)
      }
  })
  .catch(error => {
    CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
  });         
}


useEffect(() => {
  if(inspectionCategory.length===0){
      GetAllInspectionsCategory()}
      }, [inspectionCategory])


 useEffect(() => {
 if(!isEmpty(monthlyInspection)){
  console.log("hiiiiiiiiiiii monthlyInspection",monthlyInspection)
    getListOfChecklistBySelectedInspectionId(monthlyInspection[0]?.id)
 }
 }, [monthlyInspection]);

 
  const getAcceptanceStatusId = (id) => {
    switch (id) {
        case 1:
        case 2:
            return 1;
        case 3:
            return 2;
        case 4:
            return 3;
        default:
            return 1;
    }
}
  const getDocuments = (checklistItem) => {
    if ((checklistItem.vehicleInspectionEvidences || []).length === 0) {
        return [];
    }
    checklistItem.vehicleInspectionEvidences.map(item => {
        item.name = item.documentName;
        item.posterPath = SasHelper.addSasKey(item.documentPath);
        item.acceptanceStatusId = getAcceptanceStatusId(item.statusId);
        item.comment = item.comment;
        // item.additionalInfo = "User Created: " + UserHelper.getUser(users, item.createUserId?.name) + "\n" + "Date time created: " + DateUtils.toDateString(item.createDate, "YYYY-MM-DD HH:MM:SS");
        // item.uploadDetails = "User Created: " + UserHelper.getUser(users, item.createUserId?.name) + "\n" + "Date time created: " + DateUtils.toDateString(item.createDate, "YYYY-MM-DD HH:MM:SS");
    });
    return checklistItem.vehicleInspectionEvidences;
}
  
  const renderChecklists = () => {
    let accordionItems = [];
    let outerAccordionItems = [];
        accordionItems = allChecklistItems?.currentInspectionItems?.map(item => {
            const checklistItem = {
               categoryId: item.categoryId,
                id: item.id,
                opened: "opened",
                title: item.checklistItemName,
                titleStyle: { fontWeight: 500, padding: 3 },
                contentStyle: { paddingLeft: 10 },
                selectable: true,
                icon: true,
                titleIcon: 
                  <div key={item.id}> 
                    <span className="tooltip mr-2" >
                        <Badge badgeContent={ item.vehicleInspectionEvidences?.length} color="primary" >
                            <i class='icon icon-attach'
                                    style={{ fontSize: 19, bottom:5, right:2, height:20,width:20,position:'relative', paddingLeft: 2 }}></i>
                        </Badge>
                        <span class="message top-end">Total of Evidence Uploaded</span>
                    </span>
                    {item.isMandatory && 
                        <span className="tooltip mr-2">
                            <Badge badgeContent={item.minPhoto} color="error" >
                                <i className="icon icon-severity-critical"
                                    style={{ fontSize: 19, bottom:5, left:1, height:20,width:20,position:'relative', paddingLeft: 2, color: "#ED0E00" }}></i>
                            </Badge>
                            <span className="message top-end">{item.minPhoto}</span>
                        </span>
                    }
                </div>,
            content: <>
                
                  <EuiHeader id={"inspectionHeader" + item.id} style={{ width: "100%" }} />
                  <ImagesPreviewEHS   
                            tileData={getDocuments(item)}
                            // inspection={inspection}
                            // setIsLoading={setIsLoading}
                            // onClickImage={(document) => selectAndOpenDocument(document, item)}
                            setIsOpenVehicleCategory={setIsOpenVehicleCategory}
                      />
            
                </>,

            };
            return checklistItem;
        });
        outerAccordionItems = inspectionCategory?.map(item => {
          const checklistCategory = {
              id:item.id + " category", 
              title:item.category,
              opened: "opened",
              contentStyle: { paddingLeft: 5 },
              selectable: true,
              checklistItem:allChecklistItems?.currentInspectionItems?.filter(i => i.categoryId ===item.id)[0] ,
             content: <EuiAccordion id={"innerchecklistAccordion" + item.id}
                  style={{ width: "100%" }}
                  items={accordionItems?.filter(i => i.categoryId === item.id)}
              />
          }
          return checklistCategory;
      });


        return(  
        <>
             <VehicleCategoryBox
                 checklistItems={allChecklistItems?.currentInspectionItems ||[] }
                //  selectedCategoryId={inspection.selectedItem?.categoryId||1}
                 outerAccordionItems={outerAccordionItems} 
                 setVehicleCategory={setVehicleCategory}
                 setIsOpenVehicleCategory={setIsOpenVehicleCategory}
                 reloadInspection={reloadInspection}
                 inspectionCategory={inspectionCategory}
            
                 />
                
            
          {  isOpenVehicleCategory &&   
               <EuiAccordion 
                        id="inspectionChecklistAccordionId"
                        noAutoClose={true}
                        style={{ width: "100%" }}
                        items={vehicleCategory}
                 />
         }
        </>
         )
}



const getVehicleStatus = useCallback(() => {
  return VehicleHelper.vehicleHTMLStatus[monthlyInspection[0].statusId];

}, [monthlyInspection[0].vehicle.vehicleStatus]);

  return ( 
    <>
      {isEmpty(monthlyInspection)?
  <EuiLoading id="activityDocumentsLoading" />:
    <>
    <EuiRow >
      <EuiRow >
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b>Vehicle Inspection Id:</b> { monthlyInspection[0].id}
        </div>
        <div className="column sm-12 md-6 lg-6 xl-6  filled">
          <b>inspection Name :</b> {monthlyInspection[0].inspectionName}
        </div>
      </EuiRow >
      <EuiRow >

        <div className="column sm-12 md-6 lg-6 xl-6  filled">
          <b>Valid Until :</b>  {DateUtils.toDateString(monthlyInspection[0].vehicle.validUntil, 'YYYY-MM-DD HH:mm:ss')    || ""}
        </div>
    
        <div className="column sm-12 md-6 lg-6 xl-6 filled">
          <b></b>{getVehicleStatus()}
        </div>
      
    </EuiRow>
  </EuiRow>
        
        <EuiRow style={{ width: "1000px" }}>
            {renderChecklists()}
          </EuiRow></>
      }
      </>
  )
}
