import DateUtils from "./date-utils";

export default class AspHelper {
    static mapResourcesJobRole = (roleId, allRoles) => {
        return allRoles?.find(item => item.value == roleId)?.text;
    }

    static mapIdType = (Id) => {
        switch (Id) {
            case 1:
                return "Passport";
            case 2:
                return "Emirates ID";
            case 3:
                return "Iqama ID";
            case 4:
                return "National ID";
            default: return ""
        }
    }

    static mapLegalStatus = (status) => {
        switch (status) {
            case 1:
                return "On Sponsership";
            case 2:
                return "Contracted";
            default: return ""
        }
    }
    static mapStatus = (status) => {
        switch (status) {
            case "Created":
                return 1;
            case "Submitted":
                return 2;
            case "Approved":
                return 3;
            case "Rejected":
                return 4;
            case "Suspended":
                return 5;
            default: return ""
        }
    }

    static mapStatusIDs = (status) => {
        switch (status) {
            case 1:
                return "Created";
            case 2:
                return "Submitted";
            case 3:
                return "Approved";
            case 4:
                return "Rejected";
            case 5:
                return "Suspended";
            default: return ""
        }
    }

    static GetRoleMandatoryCertifictes(jobsList, mandatoriesList) {
        var result = [];
        var jobs = jobsList.map(e => e.value);
        if (!mandatoriesList)
            return null;
        mandatoriesList.forEach(m => {
            if (jobs.includes(m.value)) {
                if (m.mandatoryCerts) {
                    m.mandatoryCerts.forEach(c => {
                        result.push(c.certificateId);
                    })
                }
            }
        });
        return result;
    }

    static getUnTakenCerts = (certs, _jobs, certificates, jobs) => {
        
        var result = [];
        var resourceJobs = [];
        _jobs.forEach(j => {
            if (jobs.findIndex(x => x == j.text) !== -1)
                resourceJobs.push(j);
        })
        if (resourceJobs.length == 0 || !resourceJobs)
            return null;

        resourceJobs.forEach(j => {
            var certificates = [];

            
            if (j.mandatoryCerts) {
                j.mandatoryCerts.forEach(c => {
                    var added = false;
                    var CertificateValid = false;
                    if (certs.findIndex(item => item.certificationId == c.certificateId) == -1) {
                        
                        added = false;
                        CertificateValid = false;
                    }
                    else {
                        
                        added = true;
                        CertificateValid = (certs.filter(item => item.certificationId == c.certificateId)[0].status == "Valid" ? true : false)
                    }
                    var element = {
                        ...c,
                        id: (added ? null : c.id),
                        added: added,
                        valid: CertificateValid

                    }
                    certificates.push(element);

                });
                var mandatories = certificates.filter(c => c.isMandatory == 1 && (c.added == false || c.valid == false));
                var valid = true;
                if (mandatories.length)
                    valid = false;

            }
            else {
                var certificates = [];
                var valid = true;
            }
            var element = {
                job: j,
                certificates: certificates,
                valid: valid

            }
            result.push(element);
        });
        
        return result
    }

    static getCountryName = (countryId, countries) => {
        if (countryId)
            return countries.filter(c => c.value == countryId)[0].text;
        else return "";
    }

    static getSkillInfo = (resourcesSkills, allSkills) => {
        
        
        var result = [];

        if (!resourcesSkills) {
            return [];
        }

        resourcesSkills.forEach(s => {
            var fullSkill = allSkills.find(skill => skill.id == s.skillId);
            var item = {
                ...fullSkill,
                ...s,
                lastModifiedDateTime: DateUtils.toDateString(s.lastModifiedDateTime, "YYYY-MM-DD HH:mm"),
                skillId: s.skillId,
                id: s.id
            }
            result.push(item);
        })
        
        return result;
    }

    static getProfileCreationState = (resource, users) => {
        
        if (!users || !resource)
            return "Not Created"

        var user = users.find(item => item.email == resource.email);
        
        if (!user)
            return "Not Created"
        else
            return DateUtils.toDateString(user.createdAt, "YYYY-MM-DD HH:mm");
    }

    static mapProjects = (resourceProjects, allProjects, allCustomers) => {
        
        
        var result = resourceProjects.map(e => {
            const project = allProjects?.find(item => item.value == e.projectId);
            const element = {
                ...e,
                name: project?.text,
                assignDate: DateUtils.toDateString(e.assignDate, "YYYY-MM-DD HH:mm"),
                deleteId: e.projectId,
                editId: e.projectId,
                customerName: allCustomers?.find(item => item.value == project.customerId)?.text,
            }
            return element;
        });
        return result;
    }

}