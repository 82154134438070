import React from "react";
import ActivityInboxHelper from "./activity-inbox-helper";
import constants from "./constants";

export default class TicketHelper {

    static mapTickets = (tickets, classificationList) => {
        if (!tickets)
            return [];
        tickets.map((ticket) => {
            ticket.assignTicket = ticket.id;
            ticket.ticketClassificationName = classificationList.find(x => x.id == ticket.ticketClassification)?.name;

            if (ticket.ticketDocuments) {
                ticket.ticketDocuments.map((doc) => {
                    doc.posterPath = ActivityInboxHelper.getPosterPath(doc.type) || doc.documentPath;
                });
            }
        })
    }
}