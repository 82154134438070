import React from "react";

export default class CustomFooter extends React.Component {
    render() {
        return (
            <div style={{
                display: "flex", justifyContent: "space-between",               
                width: "100%",
                borderTop: "1px solid #c8c8c8",
                ...this.props.style
            }} id={this.props.id}>
                <div className="flex-start-content">
                    {this.props.buttonsLeft && this.props.buttonsLeft.map(o => {
                        if (o.display) {
                            return;
                        }
                        return <button key={o.id + "key"}
                            className={o.type}
                            type={o.buttonType}
                            id={o.id}
                            onClick={o.onClick}
                            style={{ width: "inherit", ...o.style }}
                        >{o.name}
                        </button>
                    })
                    }
                </div>
                <div className="flex-end-content">
                    {this.props.infoPart}
                </div>
                <div className="flex-end-content">
                    {this.props.buttons && this.props.buttons.map(o => {
                        if (o.display) {
                            return;
                        }
                        return <button key={o.id + "key"}
                            className={o.type}
                            type={o.buttonType}
                            id={o.id}
                            disabled={o.disabled}
                            onClick={o.onClick}
                            style={{ width: "inherit", ...o.style }}
                        >{o.name}
                        </button>
                    })
                    }
                </div>
            </div>
        );
    }
}

