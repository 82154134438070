/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import EuiTile from "../../eui-components/tile";
import EuiTextField from "../../eui-components/textfield";
import EuiRow from "../../eui-components/row";

export default function ActivityDetailsUDFCategories(props) {
  const { listErist ,listEritop} = props;

  return (

    listErist?.map( (ele, i)=> {
return (
        <>
<EuiRow>
        <EuiTile sm="12" md="6" lg="6" xl="6" headerStyle={{ paddingBottom: 10 }} tileStyle={{ padding: 5 }}>
         <EuiTile
             title={''}
             titleStyle={{ fontSize: 14 }}
             tileStyle={{ padding: 5, width: "100%" }}
             headerStyle={{ paddingBottom: 0 }}
             styleContent={{ display: "flex" }}
         >
          <EuiTextField
                 id={"Erisite"+i}
                 className="w-10"
                 readOnly
                 fullwidth
                 value={listErist[i]||"(Not defined)"}
          >

          </EuiTextField>
             <i className="icon icon-arrow-right m-1 icon-lg"></i>
        </EuiTile>
     </EuiTile>
     <EuiTile sm="12" md="6" lg="6" xl="6" headerStyle={{ paddingBottom: 10 }} tileStyle={{ padding: 5 }}>
                <EuiTile
                    title={""}
                    tileStyle={{ padding: 5, marginTop: 5, width: "100%" }}
                    headerStyle={{ paddingBottom: 0 }}
                    styleContent={{ display: "flex" }}
                >
                    <EuiTextField
                        id={"eritop"+i}
                        className="w-10"
                        readOnly
                        fullwidth
                        value={listEritop[i]||"(Not defined)"}
                    >  
                    </EuiTextField>
                </EuiTile>
         </EuiTile>
         </EuiRow> </>
)  

 }));
}
