import React from "react";
import { Gauge } from '@eds/vanilla/gauge/Gauge';

class EuiGuage extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const gaugeDOM = document.getElementById(this.props.id);
        const gauge = new Gauge(gaugeDOM);
        gauge.init();

    }

    render() {
        return (
            <div className="Gauge" style={this.props.style}>
                <div className="eds-gauge" id={this.props.id} data-settings={this.props.dataSettings}
                    size={this.props.size}
                ></div>
                <div style={{
                    display: "flex", justifyContent: "center"
                }}>
                    <div className="color-gray">{this.props.label}</div>
                </div>
                <div style={{
                    display: "flex", justifyContent: "center"
                }}>
                    <div className="item">
                        <span className={"text-xl " + (this.props.total ? "" : "hidden")}>{this.props.itemNumber}</span>
                        <span className="item text-lg" hidden={(this.props.total ? false : true)}>/{this.props.total}</span>
                        <span className="text-lg color-gray">{this.props.unit}</span>
                    </div>
                </div>
            </div>
        );

    }
}
export default EuiGuage;
