const initialState = {   
    mod: 1,  
    tickets: { tickets: [], initialState: false },
    selectedTickets: [],
    classificationList: [],
    sasToken: "",
    ticketSelectedTab: 0,
    currentTicket: { id: 0 },
    assignTicketInfo: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
       
        case "SET_TICKET_PAGE_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_TICKETS":
            return {
                ...state,
                tickets: action.payload
            };
        case "SET_CURRENT_TICKET":
            return {
                ...state,
                currentTicket: action.payload
            };
        case "SET_CLASSIFICATION_LIST":
            return {
                ...state,
                classificationList: action.payload
            };
        case "SET_SAS_TOKEN":
            return {
                ...state,
                sasToken: action.payload
            };
        case "SET_TICKET_SELECTED_TAB":
            return {
                ...state,
                ticketSelectedTab: action.payload
            };
        case "SET_SELECTED_TICKETS":
            return {
                ...state,
                selectedTickets: action.payload
            };
        case "SET_ASSIGN_TICKET_INFO":
            return {
                ...state,
                assignTicketInfo: action.payload
            };
       
        default:
            return state;
    }

};