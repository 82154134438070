import React from "react";
import { Wizard } from "@eds/vanilla/wizard/Wizard";

class EuiWizard extends React.Component {

    componentDidMount() {
        if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new Wizard(element);
                    component.init();
                });
            }
        }
        else {
            console.error("You should set an id to Wizard component");
        }
    }

    onClick = (event) => {
        if (this.props.onNextClick) {
            this.props.onNextClick(event);
        }
    }
    onPreviousClick = (event) => {
        if (this.props.onPreviousClick) {
            this.props.onPreviousClick(event);
        }
    }

    render() {
        return (
            <>
                <div id={this.props.id} className="wizard">
                    <div className="wizard-steps">
                        <div className="arrow left">
                            <i className="icon icon-arrow-left"></i>
                        </div>

                        <div className="steps">
                            {
                                this.props.steps && this.props.steps.map(item =>
                                    <div key={item.description} className={"step " + item.current + " " + item.completed + " " + item.visible}>
                                        <div className="prevbar"></div>
                                        <div className="nextbar"></div>
                                        <div className="sphere"></div>
                                        <div className="description">{item.description}</div>
                                    </div>
                                )
                            }
                        </div>

                        <div className="arrow right visible">
                            <i className="icon icon-arrow-right"></i>
                        </div>
                    </div>

                    <div className="wizard-content" style={{ marginTop: 10, marginBottom: 0 }}>
                        {
                            this.props.contents && this.props.contents.map((item, index) =>
                                <div key={item.title} className={"content " + item.hidden} style={{ marginBottom:10 }}>
                                    <div className="title" style={{marginBottom:8}}>{item.title}</div>
                                    <div className="subtitle">
                                        <span className="current">{"step " + (index + 1)}</span>
                                        <span className="total">{" of " + this.props.contents.length}</span>
                                    </div>
                                    {item.content}
                                </div>
                            )
                        }
                    </div>
                    <div className="wizard-footer">
                        <div className="item">
                            <button className="btn" onClick={() => this.props.onCancelClick && this.props.onCancelClick()}>Cancel</button>
                            {
                               // <button className="btn" onClick={() => this.props.onSaveClick && this.props.onSaveClick()}>Save & finish later</button>
                            }
                        </div>

                        <div className="item">
                            <button className="btn" id="wizard-previous" onClick={(event) => this.onPreviousClick(event)} >
                                <i className="icon icon-arrow-left"></i>
                                <span className="term" >Previous</span>
                            </button>
                            <button className="btn primary" id="wizard-next" onMouseUp={(event) => this.onClick(event)}>
                                <span className="term" >Next</span>
                                <i className="icon icon-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default EuiWizard;
