import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EuiRow from "../../../../eui-components/row";
import ObseleteEuiTable from "../../../../eui-components/obseleteTable";
import DialogModal from "../../../../components/dialog/index";
import ManageColumnsDialog from "./manageColumnsDialog";
import DashboardHelper from "../../../../utils/dashboard-helper";

const AspPmPerformanceDetails = (props) => {

    const dispatch = useDispatch();

    const data = props.data;
    const dashboardData = useSelector(state => state.dashboard);
    const resources = useSelector(state => state.dashboard.resources);

    
    var columns = [
        {
            title: "Build Hub",
            key: "buildHub",
            sort: "none",
            width: "15%",
            headerStyle: !dashboardData.actualGroupingColumns?.includes("buildHub") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !dashboardData.actualGroupingColumns?.includes("buildHub") ? "display:none;" : null,
        },
        {
            title: "Customer Unit",
            key: "customerUnit",
            sort: "none",
            width: "15%",
            headerStyle: !dashboardData.actualGroupingColumns?.includes("customerUnit") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !dashboardData.actualGroupingColumns?.includes("customerUnit") ? "display:none;" : null,
        },
        {
            title: "Country",
            key: "countryName",
            sort: "none",
            width: "15%",
            headerStyle: !dashboardData.actualGroupingColumns?.includes("countryId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !dashboardData.actualGroupingColumns?.includes("countryId") ? "display:none;" : null,
        },
        {
            title: "Customer",
            key: "customerName",
            sort: "none",
            width: "15%",
            headerStyle: !dashboardData.actualGroupingColumns?.includes("customerId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !dashboardData.actualGroupingColumns?.includes("customerId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "Project",
            key: "projectName",
            sort: "none",
            width: "15%",
            headerStyle: !dashboardData.actualGroupingColumns?.includes("projectId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !dashboardData.actualGroupingColumns?.includes("projectId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "ASP Company",
            key: "aspCompanyName",
            sort: "none",
            width: "15%",
            headerStyle: !dashboardData.actualGroupingColumns?.includes("aspCompanyId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !dashboardData.actualGroupingColumns?.includes("aspCompanyId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "ASP Team Leader",
            key: "teamLeaderName",
            sort: "none",
            width: "15%",
            headerStyle: !dashboardData.actualGroupingColumns?.includes("resourceId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !dashboardData.actualGroupingColumns?.includes("resourceId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "Completed",
            key: "completed",
            sort: "none",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                td.style = "text-align:center";
            },
            width: "1%"
        },
        {
            title: "FTR",
            key: "ftr",
            sort: "none",
            width: "1%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            }
        },
        {
            title: "No Snags",
            key: "noSnags",
            sort: "none",
            width: "3%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            }
        },
        {
            title: "Ready For CA",
            key: "readyForCustomerAcceptance",
            sort: "none",
            width: "5%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            },

        },
        {
            title: "Team Efficiency",
            hideFilter: true,
            key: "teamEfficiency",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            },
            width: "4%",
            hideFilter: true

        },
        {
            title: "Quality Index<=0.4",
            sort: "none",
            key: "ehsPerformance",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%"
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            },
            width: "4%",
            hideFilter: true
        },
        {
            title: "Overall Performance",
            sort: "desc",
            key: "overall",
            width: "5%",
            textAlign: "center",
            onCreatedCell: (td, cellData) => {

                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            },
            hideFilter: true
        },

    ];

    const [openColumnsDialog, setOpenColumnsDialog] = useState(false);

    
    var DD = new Date();
    const fileName = "dashboard-teamsPerformanceDetails" + DD.toISOString();
    var excelData = data?.map(e => {
        var mappedItem = {
            BuildHub: e.buildHub,
            CustomerUnit: e.customerUnit,
            Country: e.countryName,
            Customer: e.customerName,
            Project: e.projectName,
            AspCompany: e.companyName,
            ASPTeamLeader: e.teamLeaderName,
           // TeamLeaderEmail: e.teamLeaderEmail,
            NumberOfAssignedActivities: e.assigned,
            NumberOfCompletedActivities: e.completed,
            EricssonFTR: e.ftr,
            NoSnags: e.noSnags,
            ReadyForCustomerAcceptance: e.readyForCustomerAcceptance,
            TeamEfficiency: e.teamEfficiency,
            QualityIndex: e.ehsPerformance,
            OverallPerformance: e.overall
        };
        if (!dashboardData.actualGroupingColumns.includes("resourceId"))
            delete mappedItem["ASPTeamLeader"];
        if (!dashboardData.selectedGroupingColumns.includes("buildHub"))
            delete mappedItem["BuildHub"];
        if (!dashboardData.selectedGroupingColumns.includes("customerUnit"))
            delete mappedItem["CustomerUnit"];
        if (!dashboardData.selectedGroupingColumns.includes("countryId"))
            delete mappedItem["Country"];
        if (!dashboardData.selectedGroupingColumns.includes("customerId"))
            delete mappedItem["Customer"];
        if (!dashboardData.selectedGroupingColumns.includes("projectId"))
            delete mappedItem["Project"];
        if (!dashboardData.selectedGroupingColumns.includes("aspCompanyId"))
            delete mappedItem["AspCompany"];
        if (!dashboardData.selectedGroupingColumns.includes("resourceId"))
            delete mappedItem["ASPTeamLeader"];

        return mappedItem;
    });

    const applyColumnsUpdates = () => {

        if (!dashboardData.selectedGroupingColumns || !dashboardData.selectedGroupingColumns.length) {
            alert("put notification to select at least one column");
            return;
        }
        dispatch({ type: "SET_ACTUAL_TEAM_GROUPING_COLUMNS", payload: dashboardData.selectedGroupingColumns });

        const sitesDetails = DashboardHelper.mapSitesDetailsActivities(dashboardData.rawData, resources, dashboardData.filterControls);
        var itemsGrouppedByLeaderAndType = DashboardHelper.mapDetailsIncentivesData(dashboardData.rawData, resources, dashboardData.filterControls, dashboardData.selectedGroupingColumns);
        
        dispatch({ type: "SET_DASHBOARD_ISLOADING", payload: true });
        dispatch({ type: "SET_KPIS_DETAILS", payload: { performanceDetails: itemsGrouppedByLeaderAndType, sitesDetails: sitesDetails } });
        dispatch({ type: "SET_DASHBOARD_ISLOADING", payload: false });

        setOpenColumnsDialog(false);
    }

    const initiateOpenColumnsDialog = () => {
        setOpenColumnsDialog(true);
    }


    return (<EuiRow key={data}>
        <DialogModal
            id="manageColumnsQualityDashboardPerformanceDetails"
            disableBackdropClick={true}
            title="Grouping Columns"
            content={<ManageColumnsDialog />}
            buttonName="Apply"
            type="primary"
            onClick={applyColumnsUpdates}
            customStyle={{ width: 400, height: 650 }}
            open={openColumnsDialog}
            handleClose={() => setOpenColumnsDialog(false)}
            contentStyle={{ height: "70%", width: "100%" }} />
        <ObseleteEuiTable
            key={dashboardData.actualGroupingColumns}
            id="aspPmDashboardPerformanceDetails"
            columns={columns}
            data={data}
            filter={true}
            viewActionButton={true}
            hideSearch={true}
            actionButton={<span className="tooltip"><i className="icon icon-settings" style={{ fontSize: "25px", marginRight: "15px" }} onClick={initiateOpenColumnsDialog} /><span className="message left">Edit Grouping Columns</span></span>}
            scroll={false}
            hideSearch={true}
            classes="tiny split-striped"
            exportExcel={true}
            Reportdata={excelData}
            paginated={true}
            rowsPerPage={13}
            excelFileName={fileName}
        />
    </EuiRow>)
}

export default AspPmPerformanceDetails;