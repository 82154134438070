import React from 'react';
import { connect } from "react-redux";

class Report extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: "photoReport",
            sheetdisable: ""

        }
    }

    componentDidMount() {

        this.props.getReport("photoReport")
        if (this.props.userData.userData.jobRole != 100) {
            this.setState({
                sheetdisable: true
            })
        }
        else {
            this.setState({
                sheetdisable: false
            })
        }
    }
    handleChange = (e) => {
        this.setState({
            selectedOption: e.target.value
        })
        this.props.getReport(e.target.value)
    }

    render() {
        return (
            <div style={{ color: "b1b1b1", width: "300px", height: "100px" }}>

                <input id="rbtn" name="group" type="radio" value="photoReport"
                    checked={this.state.selectedOption === "photoReport"}
                    onChange={this.handleChange}
                />
                <label for="rbtn" style={{ fontSize: "17px" }}>Excel Photo Report</label>


                <input id="rbtn1" name="group" type="radio" value="observationSheet"
                    checked={this.state.selectedOption === "observationSheet"}
                    onChange={this.handleChange}
                //   disabled={this.state.sheetdisable}
                />
                <label for="rbtn1" style={{ fontSize: "17px" }}>Observation Sheet</label>

                <input id="rbtn2" name="group" type="radio" value="detailedObservationSheet"
                    checked={this.state.selectedOption === "detailedObservationSheet"}
                    onChange={this.handleChange}
                // disabled={this.state.sheetdisable}
                />
                <label for="rbtn2" style={{ fontSize: "17px" }}>Observation Sheet (Detailed)</label>

                <input id="rbtn3" name="group" type="radio" value="excelObservationSheet"
                    checked={this.state.selectedOption === "excelObservationSheet"}
                    onChange={this.handleChange}
                // disabled={this.state.sheetdisable}
                />
                <label for="rbtn3" style={{ fontSize: "17px" }}>Snags Report</label>


            </div>
        );
    }
}
const mapStateToProps = state => ({ navbarData: state.navigation, userData: state.user });
const mapDispatchToProps = dispatch => {
    return {
        getReport: (reportType) => dispatch({ type: "GET_REPORT", payload: reportType }),
        login: ({ userData, token, rememberMe, loginRole }) => dispatch({ type: "LOGIN", payload: { userData, token, rememberMe, loginRole } }),


    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Report);
/*  onClick={e => {
                        this.props.changePage(nav);
                        e.stopPropagation();
                        this.props.history.push(nav.to);
                    }}>
                    <span>{nav.title}</span>*/