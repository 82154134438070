import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import constants from "../../utils/constants";
import DateUtils from "../../utils/date-utils";
import PageHelper from "../../utils/page-helper";

class ActivityInfo extends React.Component {

    goWorkplanDetailPage = () => {
        this.props.setFilter({ ...this.props.workplanData.filter, id: this.props.activityData.activity.workplanId });
        this.props.history.push("/workplans");
    }

    goSiteDetailPage = () => {
        this.props.setSiteFilter({ ...this.props.siteData.filter, id: this.props.activityData.activity.siteId });
        this.props.history.push("/sites");
    }

    render() {
        return (<EuiRow>
            <div className="w-3 pt-2">{PageHelper.renderActivityStatusAsHtml(this.props.activityData.activity.acceptanceStatusId)}</div>
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityCustomerName" name="customerName" label="Customer Name" value={this.props.activityData.activity.customerName} />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityProjectName" name="projectName" label="Project Name" value={this.props.activityData.activity.projectName} />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activitySiteName" name="siteName" label="Site Name"
                value={this.props.activityData.activity.siteName}
                additionalInfo={<a onClick={this.goSiteDetailPage}>{this.props.activityData.activity.siteId}</a>}
            />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityWorkplanName" name="workplanName" label="Workplan Name"
                value={this.props.activityData.activity.workplanName}
                additionalInfo={<a onClick={this.goWorkplanDetailPage}>{this.props.activityData.activity.workplanId}</a>}
            />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityAspCompany" name="aspCompany" label="ASP Company Name" value={this.props.activityData.activity.aspCompanyName} />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityRscScope" name="rscScope" label="Session Type" value={this.props.activityData.activity.rscScope} />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityTeamLead" name="teamLead" label="Team Leader"
                value={this.props.activityData.activity.teamLeadName}
                additionalInfo={this.props.activityData.activity.teamLeadMail}
            />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }}
                id="activityRscPerson"
                name="rscPerson"
                label={this.props.activityData.activity.rscScope === constants.imActivitySessionType ? "Implementation Manager" : "RSC QA Supervisor"}
                value={this.props.activityData.activity.rscPersonName}
                additionalInfo={this.props.activityData.activity.rscPersonMail}
            />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }}
                id="activityForecastConfirmationDate"
                name="forecastConfirmationDate"
                label="Forecast Confirmation Date"
                value={DateUtils.toDateString(this.props.activityData.activity.forecastConfirmationDate, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
            <EuiTextField className="p-2 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityForecastConfirmationUser"
                name="forecastConfirmationUserName"
                label="Forecast Confirmation User"
                value={this.props.activityData.activity.forecastConfirmationUserName} />
            <EuiTextField className="p-2 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="globalPlanningObjectName" name="gpoName" label="Global Planning Object" value={this.props.activityData.activity.gpoName} />

            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityPlannedStart" name="plannedStart" label="Eritop Planned Start DateTime" value={DateUtils.toDateString(this.props.activityData.activity.plannedStart, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityPlannedEnd" name="plannedEnd" label="Eritop Planned End DateTime" value={DateUtils.toDateString(this.props.activityData.activity.plannedEnd, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activitySentToRsc" name="sentToRsc" label="Latest Submit DateTime to RSC" value={DateUtils.toDateString(this.props.activityData.activity.sentToRSC, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityActualStart" name="actualStart" label="Eritop Actual Start DateTime" value={DateUtils.toDateString(this.props.activityData.activity.actualStart, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityActualEnd" name="actualEnd" label="Eritop Actual End DateTime" value={DateUtils.toDateString(this.props.activityData.activity.actualEnd, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
            <EuiTextField className="p-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityAssignedOn" name="assignedOn" label="Assigned to RSC" value={DateUtils.toDateString(this.props.activityData.activity.assignedOn, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
        </EuiRow >);
    }
}

const mapStateToProps = state => ({
    userData: state.user, navbarData: state.navigation,
    activityData: state.activity,
    workplanData: state.workplan,
    siteData: state.site
});
const mapDispatchToProps = dispatch => {
    return {
        setFilter: (filter) => dispatch({ type: "SET_FILTER", payload: filter }),
        setSiteFilter: (filter) => dispatch({ type: "SET_SITE_FILTER", payload: filter }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ActivityInfo));