import { ListBuilder } from '@eds/vanilla';


export class CustomListBuilder extends ListBuilder {




  _addItems(){
    const mainList = this.container.querySelector('.lb-main');
    const secondaryListContainer = this.container.querySelector('.lb-secondary .lb-list');

    mainList.querySelectorAll('.lb-item').forEach((mainListItem) => {
      if (mainListItem.firstChild.checked) {
        mainListItem.firstChild.checked = false;
        mainListItem.classList.add('highlight');
        secondaryListContainer.append(mainListItem);
        setTimeout(() => mainListItem.classList.remove('highlight'), 1000);
        this.output.push(mainListItem.getElementsByTagName('label')[0].innerText);
      }
    });

    mainList.querySelector('.lb-header-cb').firstChild.checked = false;
    this._updateSelectedCounter(this.container.querySelector('.lb-main'));
    this._updateLists();

    const targetBlockInput = secondaryListContainer.closest('.lb-block').querySelector('.lb-filter-input');
    const keyupEvt = new Event('keyup');

    targetBlockInput.dispatchEvent(keyupEvt);
  }

}