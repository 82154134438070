const initialState = {
    mod: 1,
    filter: {},
    filteredProjects: {
        data: [], totalCount: 0, initialState: 0
    },
    project: {
        id: 0,
    },
    member: {},
    isLoading: false,
    displayRightButton: false,
    allProjects: {
        data: [], totalCount: 0
    }
};
export default (state = initialState, action) => {
    switch (action.type) {

        case "SET_PROJECT":
            return {
                ...state,
                project: action.payload
            };
        case "CHANGE_PROJECT_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_FILTERED_PROJECTS":
            return {
                ...state,
                filteredProjects: action.payload
            };
        case "SET_TEAM_MEMBER":
            return {
                ...state,
                member: action.payload 
            }
        case "SET_TEAM_MEMBERS":
            return {
                ...state,
                representatives: action.payload
            };
        case "SET_PROJECT_FILTER":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_RIGHT_BUTTON":
            return {
                ...state,
                displayRightButton: action.payload
            };
        case "SET_ALL_PROJECTS":
            return {
                ...state,
                allProjects: action.payload
            };
        default:
            return state;
    }

};
