import React from "react";

export default class EuiCheckbox extends React.Component {

    render() {

        return (
            <div className={"flex-start-content " + (this.props.className ?? "")} style={this.props.style}>
                <input type="checkbox"
                    disabled={this.props.disabled}
                    name={this.props.name}
                    id={this.props.id} tabIndex="0"
                    ref={this.props.refValue}
                    onChange={() => this.props.onChange && this.props.onChange({ target: { value: !this.props.refValue, name: this.props.name } })}
                    defaultChecked={this.props.defaultChecked}
                    checked={this.props.refValue}
                />
                {this.props.label && <label style={this.props.labelStyle} htmlFor={this.props.id}>{this.props.label}</label>}
            </div>
        );
    }
}
