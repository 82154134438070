import React from 'react';
import { connect } from 'react-redux';
import EuiRow from "../../../eui-components/row";
import { Multiselect } from 'multiselect-react-dropdown';

class Filter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: [],
        }

    }

    onSelect = (selectedList, selectedItem) => {
        this.state.selectedValue.push(selectedList)
        this.props.criteria(this.state.selectedValue);

    }

    render() {
        return (
            <EuiRow style={{ width: "400px", height: "350px" }}>
                <div className="column sm-12 md-6 lg-6 xl-6 filled" >

                    <div style={{ height: "20px", fontSize: "13px" }}>*Double click or Enter for selecting</div>
                    <Multiselect
                        style={{
                            multiselectContainer: { width: "300px" },
                            searchBox: { width: "400px" },
                            inputField: { width: "300px" }
                        }}
                        showCheckbox={true}
                        options={this.props.options} // Options to display in the dropdown
                        selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                        onSelect={(selectedList, selectedItem) => this.onSelect(selectedList)} // Function will trigger on select event
                        onRemove={this.onRemove} // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                    />
                </div>
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({

})
const mapDispatchToProps = dispatch => {
    return {

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Filter)
