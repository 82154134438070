import React from "react";
import { TabGroup } from "@eds/vanilla/tab/TabGroup";

class EuiTabs extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new TabGroup(element);
                    component.init();
                });
            }
        }
        else {
            console.error("You should set an id to TabGroup component");
        }
    }


    render() {
        return (
            <>
                <div id={this.props.id} className="tabs" style={this.props.tabStyle}>
                    <div className="titles" style={this.props.titlesColumnStyle}>
                        {this.props.titles && this.props.titles.map(title => (
                            <div tabIndex="0"
                                key={title.value}
                                className={"title " + (title.selected ?? "") + " " + (title.disabled ?? "")}
                                onClick={() => title.onClick && title.onClick()}
                                style={this.props.titleStyle}>
                                {title.name}
                            </div>
                        ))}
                    </div>
                    <div className="contents" style={{ paddingTop: 0, backgroundColor: "#fafafa", ...this.props.contentStyle }}>
                        {this.props.contents && this.props.contents.map(content => (
                            <div key={content.value} id={content.id} className={"content " + content.selected} style={{ marginTop: 0, width: "100%", padding: 11 }}>
                                {content.children}
                            </div>
                        ))
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default EuiTabs;
