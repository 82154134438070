import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import EuiTextField from "../../../eui-components/textfield";
import Table from "../../../components/table/index";
import AddIcon from '@material-ui/icons/Add';
import { ToastContainer, toast } from 'react-toastify';
import PageHelper from "../../../utils/page-helper";


class CreateEditSessionActivity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            sessionActivity: {
                id: 0,
                sessionId: this.props.sessionData.sessionInfo.id == 0 ? null : this.props.sessionData.sessionInfo.id,
                name: "",
                createDate: new Date(),
                createUser: this.props.userData.userData.userId,
                updateDate: null,
                updateUser: null,
                documents: []
            },
            documentPath: {},
            documentColumns:
                [
                    { title: "Name", field: "name", editable: 'never' },
                    { title: "Status", field: "acceptanceStatusId", render: (rowData) => PageHelper.renderStatus(rowData.acceptanceStatusId), editable: 'never' },
                    {
                        title: "Ericsson Comments", field: "ericssonComments",
                    }
                ],
            selectedFileList: [],
        }
    }
    componentDidMount() {
        if (this.props.sessionActivity) {

            this.setState({ sessionActivity: Object.assign(this.state.sessionActivity, this.props.sessionActivity) },
                () => {
                    this.props.getNewSessionActivity(this.state.sessionActivity)
                    
                }
            );
        }
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState(prevState => ({
            sessionActivity: {
                ...prevState.sessionActivity,
                [name]: value
            }
        }), () => this.props.getNewSessionActivity(this.state.sessionActivity));
    }

    handleFiles = (e) => {
        const files = [...e.target.files];

        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            toast.error(fileSize, { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            return;
        }

        const documents = files.map((item, index) => {
            return {
                id: 0,
                sessionActivityId: this.state.sessionActivity.id,
                name: item.name,
                documentPath: "",
                latitude: "",
                longitude: "",
                customerComments: "",
                ericssonComments: "",
                acceptanceStatusId: this.state.sessionActivity.acceptanceStatusId ?? 1,
                createDate: new Date(),
                createUser: this.props.userData.userData.userId,
                updateDate: null,
                updateUser: null,
                deleteDate: null,
                deleteUser: null,
                type: PageHelper.getType(item.type),
                file: item
            };
        });

        

        this.setState(prevState => ({
            sessionActivity: {
                ...prevState.sessionActivity,
                documents: [...prevState.sessionActivity.documents, ...documents]
            }
        }), () => this.props.getNewSessionActivity(this.state.sessionActivity))

    };

    render() {
        return (
            <EuiRow>
                <EuiTextField style={{ marginBottom: 10, marginLeft: 10 , width:"50%"}}
                    label="Activity Name"
                    labelClass="left"
                    name="name"
                    className="w-10"
                    defaultValue={this.state.sessionActivity.name || ""}
                    onBlur={e => this.handleChange(e)}
                />
                <Table
                    title="Documents"
                    columns={this.state.documentColumns}
                    data={this.state.sessionActivity.documents.filter(item => !item.deleteDate)}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        oldData.ericssonComments = newData.ericssonComments;
                                        let data = [...this.state.sessionActivity.documents];
                                        data[data.indexOf(oldData)] = oldData;
                                        
                                        this.setState(prevState => ({
                                            sessionActivity: {
                                                ...prevState.sessionActivity,
                                                documents: [...data]
                                            }
                                        }), () => this.props.getNewSessionActivity(this.state.sessionActivity));
                                    }
                                }, 100);
                            }),
                        onRowDelete: oldData =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        let data = [...this.state.sessionActivity.documents];
                                        if (oldData.id !== 0) {
                                            oldData.deleteDate = new Date();
                                            oldData.deleteUser = this.props.userData.userData.userId;
                                            data[data.indexOf(oldData)] = oldData;
                                        } else {
                                            data.splice(data.indexOf(oldData), 1);
                                        }
                                        
                                        this.setState(prevState => ({
                                            sessionActivity: {
                                                ...prevState.sessionActivity,
                                                documents: [...data]
                                            }
                                        }), () => this.props.getNewSessionActivity(this.state.sessionActivity));
                                    }
                                }, 100);
                            })
                    }}
                    isLoading={this.state.isLoading}
                    options={{
                        search: false,
                        showTextRowsSelected: false,
                        minBodyHeight: 300,
                        paging: false,
                        selectionProps: rowData =>
                            ({
                                color: "primary",
                                style: {
                                    padding: 3,
                                    marginLeft: 17
                                }
                            })
                    }}
                    actions={[
                        {
                            tooltip: 'Add Document',
                            icon: () => <label htmlFor="uploadSessionDocument">
                                <input
                                    style={{ display: "none" }}
                                    id="uploadSessionDocument"
                                    name="documentPath"
                                    type="file"
                                    multiple
                                    onChange={e => this.handleFiles(e)}
                                />
                                <AddIcon />
                            </label>,
                            onClick: (evt, data) => { },
                            isFreeAction:true
                        },
                    ]}
                    //toolbar={{
                    //    custom:
                    //        <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-end" }}>
                    //            <Tooltip id="addSessionFile" title="Add Document">
                    //                <label htmlFor="uploadSessionDocument">
                    //                    <input
                    //                        style={{ display: "none" }}
                    //                        id="uploadSessionDocument"
                    //                        name="documentPath"
                    //                        type="file"
                    //                        multiple
                    //                        onChange={e => this.handleFiles(e)}
                    //                    />
                    //                    <AddIcon />
                    //                </label>
                    //            </Tooltip>
                    //        </div>
                    //}}
                >
                </Table>
                <EuiRow style={{ paddingTop: 10, fontSize: 14 }}><b>Note: Can't upload over 200MB size document!</b></EuiRow>
                <ToastContainer enableMultiContainer containerId={'G'} />
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateEditSessionActivity);
