import update from 'react-addons-update';

const initialState = {
    show: false,
    width: 40,
    mod: false,
    content: { title: "", htmlContent: null },
    videoLabelItems: [false, false, false, false, false, false, false, false],
    liveSessionInfo: { sessionCode: "", latitude: "", longitude: "", streamingUserName:"", streamingUserId:0, streamingId:0, sessionId:0, sessionName:"", siteName: ""},
    publisher: undefined,
    hideOthers: true,
    subscribers: [],
    recordings: [],
    teamsLogInfo: {
        teamInfo: {},
        teamVerificationLogs: [],
        resourceVerificationLogs:[]
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "TOGGLE_SLIDE_POPUP":
            return {
                ...state,
                show: !state.show
            };
        case "CHANGE_WIDTH_SLIDE_POPUP":
            return {
                ...state,
                width: action.payload
            };
        case "TOGGLE_LIVE_STREAM_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_CONTENT":
            return {
                ...state,
                content: action.payload
            };
        case "CHANGE_VIDEO_LABEL":
            return update(state, {
                videoLabelItems: {
                    [action.payload]: { $set: !state.videoLabelItems[action.payload] }
                }
            });
        case "SET_LIVE_SESSION_INFO":
            return {
                ...state,
                liveSessionInfo: action.payload
            };
        case "SET_PUBLISHER":
            return {
                ...state,
                publisher: action.payload
            };
        case "TOGGLE_HIDE_OTHERS":
            return {
                ...state,
                hideOthers: !state.hideOthers
            };
        case "SET_SUBSCRIBERS":
            return {
                ...state,
                subscribers: action.payload
            };
        case "SET_MOBIL_TOKEN":
            return {
                ...state,
                moiblToken: action.payload
            };
        case "SET_RECORDINGS":
            return {
                ...state,
                recordings: action.payload
            };
        case "SET_TEAMS_LOG_INFO":
            return {
                ...state,
                teamsLogInfo: action.payload
            };
        default:
            return state;
    }

};
