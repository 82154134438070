import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EuiRow from "../../../eui-components/row";
import ObseleteEuiTable from "../../../eui-components/obseleteTable";
import DialogModal from "../../../components/dialog/index";
import ManageColumnsDialog from "./manageColumnsDialog";
import EhsDashboardHelper from "../../../utils/ehs-dashboard-helper";

const EhsPerformanceDetails = (props) => {
    const dispatch = useDispatch();
    const data = props.data;
    const ehsDashboardData = useSelector(state => state.ehsDashboard);
    const resources = useSelector(state => state.ehsDashboard.resources);
    
    var columns = [
        {
            title: "Build Hub",
            key: "buildHub",
            sort: "none",
            width: "15%",
            headerStyle: !ehsDashboardData.actualGroupingColumns?.includes("buildHub") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !ehsDashboardData.actualGroupingColumns?.includes("buildHub") ? "display:none;" : null,
        },
        {
            title: "Customer Unit",
            key: "customerUnit",
            sort: "none",
            width: "15%",
            headerStyle: !ehsDashboardData.actualGroupingColumns?.includes("customerUnit") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !ehsDashboardData.actualGroupingColumns?.includes("customerUnit") ? "display:none;" : null,
        },
        {
            title: "Country",
            key: "countryName",
            sort: "none",
            width: "15%",
            headerStyle: !ehsDashboardData.actualGroupingColumns?.includes("countryId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !ehsDashboardData.actualGroupingColumns?.includes("countryId") ? "display:none;" : null,
        },
        {
            title: "Customer",
            key: "customerName",
            sort: "none",
            width: "15%",
            headerStyle: !ehsDashboardData.actualGroupingColumns?.includes("customerId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !ehsDashboardData.actualGroupingColumns?.includes("customerId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "Project",
            key: "projectName",
            sort: "none",
            width: "15%",
            headerStyle: !ehsDashboardData.actualGroupingColumns?.includes("projectId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !ehsDashboardData.actualGroupingColumns?.includes("projectId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "ASP Company",
            key: "aspCompanyName",
            sort: "none",
            width: "15%",
            headerStyle: !ehsDashboardData.actualGroupingColumns?.includes("aspCompanyId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !ehsDashboardData.actualGroupingColumns?.includes("aspCompanyId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "ASP Team Leader",
            key: "teamLeaderName",
            sort: "none",
            width: "15%",
            headerStyle: !ehsDashboardData.actualGroupingColumns?.includes("resourceId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !ehsDashboardData.actualGroupingColumns?.includes("resourceId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "Activity ID",
            key: "activityId",
            sort: "none",
            width: "15%",
            headerStyle: !ehsDashboardData.actualGroupingColumns?.includes("activity") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !ehsDashboardData.actualGroupingColumns?.includes("activity") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "Activity Name",
            key: "activityName",
            sort: "none",
            width: "15%",
            headerStyle: !ehsDashboardData.actualGroupingColumns?.includes("activity") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !ehsDashboardData.actualGroupingColumns?.includes("activity") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "Submitted",
            key: "submitted",
            sort: "none",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                td.style = "text-align:center";
            },
            width: "1%"
        },
        {
            title: "Ehs Passed",
            key: "ehsPass",
            sort: "none",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                td.style = "text-align:center";
            },
            width: "1%"
        },
        {
            title: "First Time Passed",
            key: "ftp",
            sort: "none",
            width: "1%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            }
        },
        {
            title: "Critical Violation Reported",
            key: "criticalViolationReporting",
            sort: "none",
            width: "3%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            }
        },
        {
            title: "RSC Review LT<=15min",
            key: "rscReviewLt",
            sort: "none",
            width: "5%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            },
        },
        {
            title: "Overall EHS Review LT<=45min",
            hideFilter: true,
            key: "overallEhsLt",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            },
            width: "4%",
            hideFilter: true
        },
        {
            title: "Overall Performance",
            sort: "desc",
            key: "overall",
            width: "5%",
            textAlign: "center",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            },
            hideFilter: true
        },
    ];
    const [openColumnsDialog, setOpenColumnsDialog] = useState(false);
    
    var DD = new Date();
    const fileName = "dashboard-teamsPerformanceDetails" + DD.toISOString();
    var excelData = data?.map(e => {
        var mappedItem = {
            BuildHub: e.buildHub,
            CustomerUnit: e.customerUnit,
            Country: e.countryName,
            Customer: e.customerName,
            Project: e.projectName,
            AspCompany: e.companyName,
            ASPTeamLeader: e.teamLeaderName,
            EHSPassed: e.EHSPassed,
            FirstTimePassed: e.ftp,
            CriticalViolationReported: e.criticalViolationReporting,
            RscReviewLT: e.rscReviewLt,
            OverallEhsLt: e.overallEhsLt,
            OverallPerformance: e.overall
        };
        if (!ehsDashboardData.actualGroupingColumns.includes("resourceId"))
            delete mappedItem["ASPTeamLeader"];
        if (!ehsDashboardData.selectedGroupingColumns.includes("buildHub"))
            delete mappedItem["BuildHub"];
        if (!ehsDashboardData.selectedGroupingColumns.includes("customerUnit"))
            delete mappedItem["CustomerUnit"];
        if (!ehsDashboardData.selectedGroupingColumns.includes("countryId"))
            delete mappedItem["Country"];
        if (!ehsDashboardData.selectedGroupingColumns.includes("customerId"))
            delete mappedItem["Customer"];
        if (!ehsDashboardData.selectedGroupingColumns.includes("projectId"))
            delete mappedItem["Project"];
        if (!ehsDashboardData.selectedGroupingColumns.includes("aspCompanyId"))
            delete mappedItem["AspCompany"];
        if (!ehsDashboardData.selectedGroupingColumns.includes("resourceId"))
            delete mappedItem["ASPTeamLeader"];
        if (!ehsDashboardData.selectedGroupingColumns.includes("activityId")) {
            delete mappedItem["activityId"];
        }
        return mappedItem;
    });
    const applyColumnsUpdates = () => {
        if (!ehsDashboardData.selectedGroupingColumns || !ehsDashboardData.selectedGroupingColumns.length) {
            alert("put notification to select at least one column");
            return;
        }
        dispatch({ type: "SET_EHS_ACTUAL_TEAM_GROUPING_COLUMNS", payload: ehsDashboardData.selectedGroupingColumns });
        const sitesDetails = EhsDashboardHelper.mapSitesDetails(ehsDashboardData.rawData, resources, ehsDashboardData.filterControls);
        var itemsGrouppedByLeaderAndType = EhsDashboardHelper.mapGroupedEhsData(ehsDashboardData.rawData, resources, ehsDashboardData.filterControls, ehsDashboardData.selectedGroupingColumns);
        
        dispatch({ type: "SET_EHS_DASHBOARD_ISLOADING", payload: true });
        dispatch({ type: "SET_EHS_KPIS_DETAILS", payload: { performanceDetails: itemsGrouppedByLeaderAndType, sitesDetails: sitesDetails } });
        dispatch({ type: "SET_EHS_DASHBOARD_ISLOADING", payload: false });
        setOpenColumnsDialog(false);
    }
    const initiateOpenColumnsDialog = () => {
        setOpenColumnsDialog(true);
    }
    return (<EuiRow key={data}>
        <DialogModal
            id="ehsManageColumnsQualityDashboardPerformanceDetails"
            disableBackdropClick={true}
            title="Grouping Columns"
            content={<ManageColumnsDialog />}
            buttonName="Apply"
            type="primary"
            onClick={applyColumnsUpdates}
            customStyle={{ width: 400, height: 650 }}
            open={openColumnsDialog}
            handleClose={() => setOpenColumnsDialog(false)}
            contentStyle={{ height: "70%", width: "100%" }} />
        <ObseleteEuiTable
            key={ehsDashboardData.actualGroupingColumns}
            id="ehsDashboardPerformanceDetails"
            columns={columns}
            data={data}
            filter={true}
            viewActionButton={true}
            hideSearch={true}
            actionButton={<span className="tooltip"><i className="icon icon-settings" style={{ fontSize: "25px", marginRight: "15px" }} onClick={initiateOpenColumnsDialog} /><span className="message left">Edit Grouping Columns</span></span>}
            scroll={false}
            hideSearch={true}
            classes="tiny split-striped"
            exportExcel={true}
            Reportdata={excelData}
            paginated={true}
            rowsPerPage={13}
            excelFileName={fileName}
        />
    </EuiRow>)
}
export default EhsPerformanceDetails;