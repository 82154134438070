export const setTeam = (team) => dispatch => {
    dispatch({
        type: "SET_TEAM",
        payload: team
    });
};

export const setAllCertificates = (certificates) => dispatch => {
    dispatch({
        type: "SET_ALL_CERTIFICATES",
        payload: certificates
    });
};

export const setCertificate = (certificate) => dispatch => {
    dispatch({
        type: "SET_CERTIFICATE",
        payload: certificate
    });
};

export const changeMod = (mod) => dispatch => {
    dispatch({
        type: "CHANGE_MOD",
        payload:  mod
    });
};

export const setFilteredTeams = (filteredTeams) => dispatch => {
    dispatch({
        type: "SET_FILTERED_TEAMS",
        payload: filteredTeams
    });
};

export const setFilter = (filter) => dispatch => {
    dispatch({
        type: "SET_FILTER",
        payload: filter
    });
};

export const setDocuments = (docs) => dispatch => {
    dispatch({
        type: "SET_DOCUMENTS",
        payload: docs
    });
};

export const setLocalFiles = (files) => dispatch => {
    dispatch({
        type: "SET_LOCAL_FILES",
        payload: files
    })
}

export const setUpdateElement = (element) => dispatch => {
    dispatch({
        type: "SET_UPDATE_ELEMENT",
        payload: element
    })
}