import React, { useState } from "react"
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import EuiTile from "../../eui-components/tile";
import EuiTextArea from "../../eui-components/textarea";
import { useSelector, useDispatch } from "react-redux";
import { Notification } from '@eds/vanilla';
import PageHelper from "../../utils/page-helper";
import ObseleteEuiTable from '../../eui-components/obseleteTable'
import EuiTabs from "../../eui-components/tabs";
import ComplaintInfo from "./complaintInfo";
import ComplaintDocuments from "./complaintDocuments";
import constants from "../../utils/constants";
import { withLayout } from '../../layout';
import EuiLoading from "../../eui-components/loading";
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import ApiClient from '../../utils/api-client';
import DateUtils from "../../utils/date-utils";

const ComplaintViewDetails = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);
    const complaint = useSelector(state => state.complaints.addedComplaint);
    const isLoading = useSelector(state => state.complaints.isLoading);
    const sendAcknowlegment = () => {
        dispatch({ type: "SET_IS_LOADING", payload: true });
        ApiClient.post("UserAdmin/SendAcknowledgment", { statusId: parseInt(constants.complaintStatus.Acknowledged), createUserId: parseInt(user.userData.userId), complaintId: parseInt(complaint.id) }).then(response => {
            const notification = new Notification({
                title: 'Acknowledgment is done successfully!',
                timeout: 5000,
                icon: 'icon-check'
            });
            notification.init();
            var userAspCompaniesList = user.userDetail?.userAspCompanies.map(i => parseInt(i.aspCompanyId));
            if (userAspCompaniesList != null || userAspCompaniesList.length != 0) {
                ApiClient.post("UserAdmin/GetComplaintsByASPCompanyId", { userAspCompanies: userAspCompaniesList, createUserId: parseInt(user.userData.userId) }).then(response => {
                                        dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                    dispatch({ type: "SET_ADDED_COMPLAINT", payload: response.data.find(i => i.id == complaint.id) });
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                }).catch(error => {
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                })
            }
            else {
                ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                                        dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } })});(response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                    dispatch({ type: "SET_ADDED_COMPLAINT", payload: response.data.find(i => i.id == complaint.id) });
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                }).catch(error => {
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                })
            }
        }).catch(error => {
            const notification = new Notification({
                title: 'An error occurred while sending the acknowledgment!',
                timeout: 5000,
                icon: 'icon-circle-warning'
            });
            notification.init();
            dispatch({ type: "SET_IS_LOADING", payload: false });
        });
    }
    return (<EuiTile>
        <EuiLoading id="complaintviewDetailsLoading" isLoading={isLoading} />
        <div className="w-10">
            <EuiBreadcrumb id="compalintviewDetailsBreadcrumb"
                parentName="Home"
                childName={complaint.id ?? ""}
                onClickParent={() => {
                    dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.LIST });
                    dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                }}
                className="p-2" />
            </div>
        <EuiTabs
            id="complaintViewDetailsTabs"
            titles={[{ id: 1, value: 1, name: "Complaint Details", selected: "selected" },
            { id: 2, value: 2, name: "Evidence" },
            { id: 3, value: 1, name: "Complaint Recipients", selected: "false" },
                ,]}
            contents={[{ value: 1, children: <ComplaintInfo sendAcknowledgment={() => sendAcknowlegment()} mod={constants.actionMods.VIEW_ONLY} />, selected: "selected" },
                { value: 2, children: <ComplaintDocuments mod={constants.actionMods.VIEW_ONLY} />, selected: "false" },
                { value: 3, children: <ComplaintInfo mod="Corrective actions" />, selected: "false" }
                ]}
            tabStyle={{ width: "100%" }}
        />
        { /* Supporting docs here*/}
    </EuiTile>)
}
export default withLayout(ComplaintViewDetails);