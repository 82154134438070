const initialState = {
    mod: 1,
    filter: {},
    filteredEhsPings: { data: [], totalCount: 0 },
    ehsPing: { id: 0, checklists: [] }
};

export default (state = initialState, action) => {
    switch (action.type) {

        case "SET_EHS_PING":
            return {
                ...state,
                ehsPing: action.payload
            };
        case "CHANGE_EHS_PING_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_FILTERED_EHS_PINGS":
            return {
                ...state,
                filteredEhsPings: action.payload
            };
        case "SET_EHS_PING_FILTER":
            return {
                ...state,
                filter: action.payload
            };

        default:
            return state;
    }

};
