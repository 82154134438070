import React, { Component } from 'react';
import BpmnViewer from 'bpmn-js/lib/Viewer';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-font/dist/css/bpmn-embedded.css';
import { emptyBpmn } from '../../../assets/emptyBpmn';
import propertiesPanelModule from 'bpmn-js-properties-panel';
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda';
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda';
import FileSaver from 'file-saver';
import WorkflowHelper from '../../../utils/workflow-helper';

class BpmnViewerComponent extends Component {

    componentDidMount = () => {
        WorkflowHelper.viewer = new BpmnViewer({
            container: '#bpmnview',
            //keyboard: {
            //    bindTo: window
            //},
            //// propertiesPanel: {
            ////     parent: '#propview'
            //// },
            //additionalModules: [
            //    propertiesPanelModule,
            //    propertiesProviderModule
            //],
            //moddleExtensions: {
            //    camunda: camundaModdleDescriptor
            //}
        });

        this.newBpmnDiagram();
    }

    componentDidUpdate(prevProps) {
        if (this.props.diagramXml !== prevProps.diagramXml) {
            this.newBpmnDiagram();
        }
    }

    newBpmnDiagram = () => {      
        if (this.props.diagramXml) {           
            this.openBpmnDiagram(this.props.diagramXml);
        }   
    }

    async openBpmnDiagram(xml) {
        try {
            const result = await WorkflowHelper.viewer.importXML(xml);
            const { warnings } = result;
            
            var canvas = WorkflowHelper.viewer.get('canvas');
            canvas.zoom('fit-viewport');
        } catch (err) {
            
        }       
    }
   
    render = () => {
        return (
            <div id="bpmncontainer" style={{width:"100%"}}>               
                <div id="bpmnview" style={{ height: 450 }}></div>               
            </div>
        );
    }
}

export default BpmnViewerComponent;
