import React, { useMemo } from "react";
import { connect } from "react-redux";
import EuiRow from "../../../eui-components/row";
import EuiTabs from "../../../eui-components/tabs";
import PageHelper from "../../../utils/page-helper";
import VehiclesRecordsEHS from "./VehiclesRecordsEHS";
import Cookies from "js-cookie";


function VehiclesEHS(props) {


    const titles = useMemo(() =>
        [{
            value: 1,
            name: <div className="flex-start-content">
                {
                    PageHelper.getVerificationIcon(Cookies.get('vehicleStatusIsGreen'), "text-lg")
                }
                Vehicles
            </div>,
            selected: "selected"
        }
        ]
    )

    const content = [{ value: 1, selected: "selected", children: <VehiclesRecordsEHS /> }];

    return (<EuiRow>
        <EuiTabs id="vehiclesRecordsTab"
            titles={titles}
            contents={content}
            tabStyle={{ width: "100%" }}
            titleStyle={{ fontSize: 16, fontWeight: 500 }}
        ></EuiTabs>
    </EuiRow>

    );
}

const mapStateToProps = state => ({
    vehicles: state.vehicle?.vehicleList,
    userData: state.user,
    sessionData: state.session,
    liveStreamData: state.liveStream,
}
);

export default connect(
    mapStateToProps
)(VehiclesEHS);
