import React from "react";
import { Accordion } from "@eds/vanilla/accordion/Accordion";
import EuiCheckbox from "./checkbox";

class EuiAccordion extends React.Component {
    componentDidMount() {
        if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new Accordion(element);
                    component.init();
                });
            }
        }
        else {
            console.error("You should set an id to Accordion component");
        }
    }

    render() {
        return (
            <div className={this.props.noAutoClose ? "accordion lines" : "accordion lines autoclose"} id={this.props.id} style={this.props.style}>
                <ul>
                    {this.props.items && this.props.items.map(item => {
                        return <li key={item.id ?? item.title}
                            className={item.opened ?? ""}
                            tabIndex="0"
                            style={{ display: (item.hidden ? "none" : "") }}
                        >
                            <div className="title" style={{ ...item.titleStyle }}>
                                <span className="flex-start-content ml-2" style={{ ...item.titleIconStyle }}>
                                    {(this.props.selectable ?
                                        <EuiCheckbox
                                            className="mr-1"
                                            id={item.id}
                                            name={item.name}
                                            onChange={item.onCheckboxChangeChange}
                                            refValue={item.checked}
                                            defaultChecked={false}
                                            label=" "
                                        />
                                        : null)}

                                    {item.titleIcon}
                                    {item.title}
                                    {(
                                        this.props.icon ?
                                            (this.props.iconComponent)
                                            : null)}
                                </span>
                            </div>
                            <div className="content" style={item.contentStyle}>
                                {item.content}
                            </div>

                        </li>
                    })
                    }
                </ul>
            </div>
        );
    }
}

export default EuiAccordion;
