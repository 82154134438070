import React from 'react'
class PrivacyPolicyDetails extends React.Component {
    render() {
        return(
        <div style={{ padding: "15px 15px", fontSize: "15px" }}>
            <p>The scope of this notice is related to Ericsson Market Area Middle East and Africa (MMEA) processing personal data in the role of employer or purchaser for services or vendor of operators (customers).</p>
            <p>The personal data is being collected by the Controller, at local Ericsson legal entity where work is assigned or Ericsson AB, 16480 Stockholm, Sweden. Local Ericsson legal entities are personal data controllers for personal data processed in local systems. </p>
            <p>Your personal data is collected and processed as it is necessary to support the management and organization of Ericsson and its workforce as well as to support product and business processes. Data is also processed to manage (external) workforce, consultants, Approved Service Provider (ASP)s, vendors as well as customers.</p>

            <p>Processing of the Personal data is necessary to provide access to the ERITOP tool by which remote site activity acceptance is done with vendors and customers so as to enable full control on the ASP work done onsite..</p>
            <p>Personal data being processed for Ericsson Employees and Service Providers for Ericsson (Ericsson vendors) or Third Party Providers is first name, last name, national ID, nationality, address, phone number, email address, IP-address, mac address, personal files (work related evaluations), photos, time reports, performance, relevant employment experience, electronic or digital signature training records, user ID, system logs and access logs, site location and traffic data in communication applications.</p>
            <p>Personal data being process for operators (Customers) are name, last name, email address, mac address, phone number, system logs and access logs, electronic or digital signatures, site location and traffic data in communication applications.</p>

            <p>There is no profiling or automated decision making as part of this processing.</p>

            <p>The data is processed in cloud systems which is also transferred to Ericsson Group Companies that can be located in any country of the world and such transfer is made under Ericsson Binding Corporate Rules.</p>
            <p>The personal data originates from data subjects, the Ericsson employee, service providers or customers.</p>
            <p>The data will be stored as per Employee and/or contracts end date and/or minimum 5 year after data being processed and/or until data is required to validate the records. Data which is incorrect can be requested to be corrected, under certain condition can be erased by sending a request to Ericsson responsible or can be contacted by email <a href="top@ericsson.onmicrosoft.com" >top@ericsson.onmicrosoft.com.</a></p>
            <p>If you have any questions or concerns regarding the processing of personal data you may contact <a href="https://www.ericsson.com/en/legal/privacy"> Data Protection Officer </a> or you can file a complaint with your local Data Protection Supervisory Authority.</p>
            </div>)
    }
}

export default PrivacyPolicyDetails