import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import constants from "../utils/constants";
import ApiHelper from "../utils/api-helper";
import { authContext } from "../utils/adalConfig";

class Appnav extends React.Component {
    constructor(props) {
        super(props);

        const selectedPath = this.getPath();
        const isEritopUser = this.props?.userData?.userDetail?.email

        this.state = {
            ISEritopUser : isEritopUser , 
            navList: [
                {
                    title: "Welcome",
                    to: "/",
                    disabled: false,
                    visible: [1, 2, 3, 4],
                    hide: isEritopUser ? [] : ["0"]
                },
                {
                    title: "Performance Dashboard",
                    disabled: false,
                    subNavList: [
                        {
                            title: "Quality Dashboard",
                            to: "/quality-dashboard",
                            disabled: false,
                            visible: [3, 2, 4],
                            hide: ["0"]
                        },
                        {
                            title: "EHS Dashboard",
                            to: "/ehs-dashboard",
                            disabled: false,
                            visible: [3, 2, 4],
                            hide: ["0"]

                        },
                        {
                            title: "Productivity And Utilization Dashboard",
                            to: "/productivity-utilization",
                            disabled: false,
                            visible: [3, 2, 4],
                            hide: ["0"]
                        }
                    ],
                    hidden: ["0", "100"],
                    visible: [3, 2],
                    hide: ["0", "100"]
                },

                {
                    title: <a className={selectedPath ? " active " : ""} onClick={(e) => { this.props.history.push("/inbox"); }}>Sessions Inbox</a>,
                    subTitle: selectedPath?.title || "",
                    to: selectedPath?.path || "/inbox",
                    disabled: false,
                    visible: [1, 2, 3],
                    hide: []
                },
                {
                    title: "Customers",
                    to: "/customers",
                    disabled: false,
                    visible: [2],
                    hide: []
                },
                {
                    title: "ASP Companies",
                    to: "/asp-companies",
                    disabled: false,
                    visible: [2, 3],
                    hide: []
                },
                {
                    title: "Resource Management",
                    to: "/asp-resources",
                    disabled: false,
                    visible: [2, 3, 4],
                    hide: ["4"]
                },
                {
                    title: (this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER ? "My Assignments" : "Activities"),
                    to: "/activities",
                    disabled: false,
                    visible: [3],
                    hide: []

                },
                {
                    title: "Customer Sessions",
                    subNavList: [
                        {
                            to: "/create-session",
                            title: "Create Session",
                            subTitle: "",
                            disabled: false,
                            visible: [2, 3],
                            hide: []
                        },
                        {
                            to: "/approve-session",
                            title: this.props.sessionData.approveSession.status === 2 ? "Approve Session" :
                                this.props.sessionData.approveSession.status === 4 ? "Rejected Session" :
                                    "Submitted Session",
                            subTitle: this.props.sessionData.approveSession.name,
                            disabled: true,
                            visible: [1, 2],
                            hide: []
                        },
                    ],
                    hidden: [4]
                },
                {
                    title: "Sites",
                    to: "/sites",
                    disabled: false,
                    visible: [2],
                    hide: []
                },
                {
                    title: "Projects",
                    to: "/projects",
                    disabled: false,
                    visible: [2],
                    hide: []
                },
                {
                    title: "Workplans",
                    to: "/workplans",
                    disabled: false,
                    visible: [2, 4],
                    hide: []
                },
                {
                    title: "Teams",
                    to: "/teams",
                    disabled: false,
                    visible: [2, 4],
                    hide: []
                },
                {
                    title: "Activities",
                    to: "/activities",
                    disabled: false,
                    visible: [2, 4],
                    hide: []
                },
                {
                    title: "Deletions Requests",
                    to: "/deletion-Requests",
                    disabled: false,
                    visible: [2],           
                    hide: ["2", "3", "4","5","6","7","8","9", "10", "11", "12","100" , "33"]

                },
                {
                    title: "Checklist Templates",
                    to: "/activity-checklist-items",
                    disabled: false,
                    visible: [2],
                    hide: []

                },
                {
                    title: "EHS Management",
                    to: "/ehs-pings",
                    disabled: false,
                    visible: [2, 4],
                    hide: []
                },
                {
                    title: "Data Connector",
                    to: "/integration_api",
                    disabled: false,
                    visible: [2],
                    hide: []
                },
                {
                    title: "Report Builder",
                    visible: [1, 2],
                    subNavList: [
                        {
                            title: "Report Templates",
                            to: "/report-builder",
                            disabled: false,
                            visible: [1, 2],
                            hide: []
                        },
                        {
                            title: "My Reports",
                            to: "/my-reports",
                            disabled: false,
                            visible: [1, 2],
                            hide: []
                        },

                    ],
                    hidden: [3, 4],
                    hide: []

                },
                {
                    title: "RSC Inbox Dashboard",
                    to: "/rsc-dashboard",
                    disabled: false,
                    visible: [2],
                    hide: ["0", "1", "6", "7", "8", "10", "11", "12"]
                },
                {
                    title: "RSC Reassignment",
                    to: "/rsc-assignment",
                    disabled: false,
                    visible: [2],
                    hide: ["0", "1", "6", "7", "8", "10", "11", "12"]
                },
                {
                    title: "Reports",
                    visible: [1, 2],
                    subNavList: [
                        {
                            title: "Customer Session Report",
                            to: "/customer-session-report",
                            disabled: false,
                            visible: [1, 2],
                            hide: []
                        },

                    ],
                    hidden: [3, 4],
                    hide: []

                },
                {
                    title: "Admin",
                    subNavList: [
                        {
                            title: "User Administration",
                            to: "/users",
                            subTitle: "",
                            disabled: false,
                            visible: [2],
                            hide: ["1", "5", "6", "7", "8", "10", "11", "12"]
                        },
                        {
                            to: "/my-profile",
                            title: "My Profile",
                            subTitle: "",
                            disabled: false,
                            visible: [1, 2, 3, 4],
                            hide: []
                        },
                        {
                            to: "/create-customer-profile",
                            title: "Customer Profile",
                            subTitle: "",
                            disabled: false,
                            visible: [2],
                            hide: []

                        },
                        {
                            to: "/workflows",
                            title: "Approval Flows",
                            subTitle: "",
                            disabled: false,
                            visible: [2],
                            hide: []
                        },
                    ],
                    hidden: [],
                    hide: []

                }, {
                    title: "Complaint Box",
                    to: "/complaints",
                    visible: [2, 3, 4],
                    disabled: false,
                    hidden: [],
                    hide: []
                },
                {
                    title: "User Manuals",
                    to: "/user-manuals",
                    visible: [1, 2, 3, 4],
                    disabled: false,
                    hidden: [],
                    hide: []
                    /*   subNavList: [
                           {
                               title: "User Manuals",
                               to: "/user-manuals",
                               subTitle: "",
                               disabled: false,
                               visible: [1, 2, 3, 4],
                               hide: []
                           },
                       ],*/
                    // check on visibility condition
                },
                {
                    to: "/signin",
                    title: "Sign Out",
                    subTitle: "",
                    disabled: false,
                    visible: [1, 2, 3, 4],
                    hide: []
                },
            ]
        };
    }


    componentDidMount() {
        const currentNav = this.findCurrentPage(this.state.navList, this.props.location.pathname);
        if (currentNav) {
            this.props.changePage(currentNav);
        }
    }

    findCurrentPage = (navList, path) => {
        let result;

        for (let i = 0; i < navList.length; i++) {
            const item = navList[i];

            if (item.to) {
                if (item.to === path) {
                    result = item;
                    break;
                }
            }
            else {
                result = this.findCurrentPage(item.subNavList, path);
                if (result) {
                    break;
                }
            }
        }

        return result;
    }

    isGroupActive = (item) => {
        if (this.props.navData.openedGroups.indexOf(item.title) === -1) {
            if (item.subNavList.indexOf(this.props.navData.currentPage) !== -1) {
                return true;
            }
        }

        return false;
    }

    getPath = () => {
        const path = this.props.location.pathname;
        const inboxItems = [{ id: 1, title: "Customer Sessions Inbox", path: "/my-customer-sessions" },
        { id: 2, title: "Quality/Supervision Inbox " + (ApiHelper.getRscCenter(constants.userRoles.RSC_SITE_SUPERVISOR)), path: "/activity-inbox" },
        { id: 3, title: "EHS Inbox " + (ApiHelper.getRscCenter(constants.userRoles.RSC_OHS_INSPECTOR)), path: "/ehs-inbox" },
        { id: 4, title: "Tickets", path: "/ticket-inbox" },
        { id: 5, title: "Implementation Inbox", path: "/im-inbox" },
        { id: 7, title: "Live Streaming Sessions " + " ( " + this.getLiveSessionType() + " ) ", path: "/live-streaming" },
        { id: 6, title: "", path: "/inbox" },
        ];

        return inboxItems.find(item => item.path === path);
    }

    getLiveSessionType = () => {
        return this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION ? "Remote Customer Acceptance" :
            this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.ACTIVITY ? "RSC Remote Site Supervision / Remote Quality Audit" :
                this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.EHS ? "Remote EHS Session" :
                    this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.VSS ? "VSS / VCOP Session" :
                        "Remote Customer Acceptance";
    }

    renderTree = (navList) => {
        return (
            <ul>
                {navList.map(nav => {
                    if (nav.to) {
                        if ((!nav.visible.includes(this.props.userData.loginRole) || nav.hide?.includes(this.props.userData.userData.jobRole))) {
                  
                            return null;
                        }
                        else {
                            return (
                                
                                <li key={nav.title}
                                    style={(nav.disabled === true && this.props.sessionData.approveSession.id > 0) || nav.disabled === false ?
                                        { ...nav.style, minHeight: 38 } :
                                        { pointerEvents: "none", opacity: "0.6", minHeight: 38, ...nav.style }
                                    }
                                    onClick={e => {
                                        if (nav.to === "/signin") {
                                            
                                            this.props.logout();
                                            authContext.logOut()
                                        } else {
                                            this.props.changePage(nav);
                                        }
                                        e.stopPropagation();
                                        this.props.history.push(nav.to);
                                    }}>
                                    <span className={"item " + (this.props.location.pathname === nav.to ? "active" : "")} style={{ fontWeight: "inherit" }}>{nav.title}</span>
                                </li>
                            );
                        }
                    }
                    else {
                        if (nav.hidden.includes(this.props.userData.loginRole) || nav.hide?.includes(this.props.userData.userData.jobRole)) {
                            return null;
                        }
                        return (
                            <li key={nav.title}
                                style={{ ...nav.style, minHeight: 38 }}
                                onClick={e => {
                                    const index = this.props.navData.openedGroups.indexOf(nav.title);
                                    if (index == -1) {
                                        this.props.changeOpenedGroups([...this.props.navData.openedGroups, nav.title]);
                                    }
                                    else {
                                        const openedGroups = [...this.props.navData.openedGroups];
                                        openedGroups.splice(index, 1);
                                        this.props.changeOpenedGroups(openedGroups);
                                    }
                                }}>
                                <span className={`title${this.props.navData.openedGroups.indexOf(nav.title) != -1 ? " opened " : " closed "}item${(this.isGroupActive(nav) ? " active" : "")}`}><b>{nav.title}</b></span>
                                {this.renderTree(nav.subNavList)}
                            </li>
                        );
                    }
                })}
            </ul>
        )
    }


    render() {
        return (
            <div className={"appnav overable " + (this.props.navData.navBarView && this.state.ISEritopUser  ? "" : "hidden")}>
                <div className="tree navigation">
                    {this.renderTree(this.state.navList)}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ navData: state.navigation, sessionData: state.session, userData: state.user, activityInboxData: state.activityInbox, liveStreamData: state.liveStream });
const mapDispatchToProps = dispatch => {
    return {
        changePage: currentPage =>
            dispatch({
                type: "CHANGE_PAGE",
                payload: currentPage
            }),
        changeOpenedGroups: openedGroups =>
            dispatch({
                type: "CHANGE_OPENED_GROUPS",
                payload: openedGroups
            }),
        logout: () => dispatch({ type: "LOGOUT" }),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Appnav));
