import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import constants from "../../utils/constants";
import SiteSearch from "./siteSearch";
import SiteDetails from "./siteDetails";
import ApiClient from "../../utils/api-client";
import SiteHelper from "../../utils/site-helper";
import { toast } from "react-toastify";
import PageHelper from "../../utils/page-helper";
import CommonHelper from "../../utils/common-helper";

class Sites extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        PageHelper.getCoordinates();
    }

    createEditSite = () => {
        
        const site = this.props.siteData.site;
        const oldSite = site.id > 0 ? this.props.siteData.filteredSites.data.find(item => item.id === site.id) : site;
        const containerId = site.id > 0 ? "SD" : "SS";
        site.active = true;
        let visitor = CommonHelper.getVisitorData();

        site.logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: Object.entries(oldSite).reduce((a, b) => {
                if (b[0].startsWith("eri") || !["string", "number"].includes(typeof b[1])) {
                    return a;
                }
                return a + b.join(" : ") + "    |    ";
            }, ""),
        };
        
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/CreateEditSite", site).then(response => {
                if (response) {
                    toast.success(constants.processPozitiveMessage, { containerId: containerId, position: toast.POSITION.TOP_CENTER });
                    const filter = this.props.siteData.filter;
                    const initialState = new Date();
                    ApiClient.post("Workplan/GetFilteredSites", filter)
                        .then(response => {
                            const data = [...response.data.data];
                            SiteHelper.mapSites(data);
                            
                            this.props.setFilteredSites({ data, totalCount: response.data.totalCount, initialState: initialState.getTime() });
                        })
                        .catch(error => {
                            console.error(error);;
                        });
                } else {
                    toast.error(constants.processNegativeMessage, { containerId: "SS", position: toast.POSITION.TOP_CENTER });
                }
                this.setState({ isLoading: false });
            }).catch(error => {
                console.error(error);;
            });
        });
    }

    renderMod = () => {
        if (this.props.siteData.mod === constants.pageMods.VIEW_ONLY) {
            return <SiteDetails createEditSite={this.createEditSite} isLoading={this.state.isLoading} />
        } else {
            return <SiteSearch createEditSite={this.createEditSite} isLoading={this.state.isLoading} />;
        }
    }

    render() {
        return (this.renderMod());
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, siteData: state.site });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setFilteredSites: (filteredSites) => dispatch({ type: "SET_FILTERED_SITES", payload: filteredSites }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(Sites)));