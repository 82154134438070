import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../../eui-components/row";
import PageHelper from "../../../utils/page-helper";
import { ToastContainer, toast } from 'react-toastify';
import SimpleExpansionPanel from "../../../components/expansionpanel";
import AdvancedGridList from "../../../components/advancedgridlist";
import Table from "../../../components/table";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import EuiFooter from "../../../layout/footer";
import constants from "../../../utils/constants";
import DialogModal from "../../../components/dialog";
import SimpleSlider from "../../../components/slider";
import EuiSelect from "../../../eui-components/select";
import FormField from "../../../eui-components/formfield";
import SummaryDocuments from "../../sessions/approve-session/summaryDocuments";
import ApiClient from "../../../utils/api-client";
import EuiAccordion from "../../../eui-components/accordion";
import EHSInboxHelper from "../../../utils/ehs-inbox-helper";
import RateEhsPing from "./rateEhsPing";
import EhsPingViolation from "./ehsPingViolation";
import EuiSwitch from "../../../eui-components/switch";
import CommonHelper from "../../../utils/common-helper"
import EuiLoading from "../../../eui-components/loading"
import { isEmpty } from "lodash";
import DateUtils from "../../../utils/date-utils";
import EuiTile from "../../../eui-components/tile";
import EuiTextArea from "../../../eui-components/textarea";




class ApproveEhsPing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            checklistColumns:
                [
                    {
                        title: "", field: "name",
                        render: (rowData) => this.renderEhsPingDocuments(rowData),
                        cellStyle: { padding: 0, fontSize: "0.875rem" }
                    },
                ],
            ehsPingDocumentColumns: [
                {
                    title: "Name", field: "name", render: (rowData) => this.renderName(rowData),
                    cellStyle: { padding: 3, fontSize: "0.8rem", width: "25%" }
                },
                {
                    title: "Status", field: "acceptanceStatusId",
                    render: (rowData) => {
                        return this.renderStatus(rowData)
                    },
                    cellStyle: { padding: 3, width: "10%" }
                },
                {
                    title: "EHS Site Comments", field: "ohsSiteComment",
                    cellStyle: { padding: 3, fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "30%" }
                },
                {
                    title: "RSC Engineer Comments", field: "acceptanceComments",
                    cellStyle: { padding: 3, fontSize: "0.8rem", whiteSpace: "pre-wrap", width: "30%" }
                },
                {
                    title: "RSC Engineer Name", field: "rscPerson", render: (rowData) => rowData.rscPerson?.name,
                    cellStyle: { padding: 3, fontSize: "0.8rem", width: "5%" }
                },
            ],
            statusList: constants.statusList.filter(item => item.value !== 4),
            approveExcludeSwitchRef: false,
            viewMod: 3,
            tableRef: React.createRef(),
            selectedRow: { id: 0 },
            selectedDocument: { id: 0 },
            expandAllDetailPanel: false,

            penDialog: false,
            openDialogSendBack: false,
            openDialogPreview: false,
            openDialogRating: false,
            openDialogViolation: false,
            openDialogReject: false,
            openDialogDelete: false,
            openDialogInfo: false,
            overallEHSComment:"",
            reviewComments: "",
            status: 3,

            allDocument: [],
            imgIndex: 0,
            imgUrlLength: 0,
            totalCount: "",

            sessionComment: "",
            hideEmpty: false,

            selectedDeleteDocuments: [],
            selectedChecklist: { id: 0 },

            allAcceptOrReject: 0,
            openAllDialog: false
        }
    }

    componentDidMount() {
        
        if (this.props.ehsInboxData.currentEhsPing &&
            this.props.ehsInboxData.currentEhsPing.checklists &&
            this.props.ehsInboxData.currentEhsPing.checklists.map(item => item.documents && item.documents.length > 0).length > 0) {
            const allDocument = this.props.ehsInboxData.currentEhsPing
                .checklists.map(item => item.documents)
                .reduce((a, b) => { return a.concat(b); });
            this.setState({ allDocument, imgUrlLength: allDocument.length });
        }
    }

    renderEhsPingDocuments = (rowData) => {
        return <SimpleExpansionPanel
            key={rowData.id + "_checklist"}
            id={"approveEhsPingDocumentsAccordion" + rowData.id}
            title={EHSInboxHelper.getChecklistName(rowData, this.state.approveExcludeSwitchRef)}
            opened={rowData.opened}
            onChange={() => this.togglePanel(rowData)}
            content={<EuiRow>
                {this.setView(rowData)}
            </EuiRow>
            }
            style={{ backgroundColor: "#f2f2f2", display: (this.state.hideEmpty && (!rowData.documents || rowData.documents.length === 0) ? "none" : null) }}
        />
    }

    renderStatus = (rowData, preview = "") => {
        const color = PageHelper.renderActivityStatusColor(rowData.acceptanceStatusId);
        return <div key={rowData.id + "_status"} className="flex-start-content w-10">
            <i className={"icon icon-alarm-level4 color-" + color} style={{ width: "14%" }}> </i>
            <EuiSelect
                style={{
                    background: "inherit", border: "none",
                    color: (this.state.openDialogPreview || this.props.navbarData.theme === "dark" ? "#fff" : "#242424"),
                    fontSize: (this.state.openDialogPreview ? 17 : "0.8rem")
                }}
                optionListStyle={{ position: "inherit" }}
                options={this.state.statusList}
                value={rowData.acceptanceStatusId}
                name={"status" + rowData.id}
                id={"status" + rowData.id + preview}
                onChange={e => this.handleChange(e)}
                disabled={this.props.ehsInboxData.currentEhsPing.viewOnly}
            />
        </div>
    }

    renderFilePreview = () => {
        const allImages = [...this.state.allDocument];
        const comments = this.state.selectedDocument.acceptanceComments ?? "";
        return <SimpleSlider
            id="ehsInboxSlider"
            slideData={allImages}
            currentSlide={this.state.selectedDocument}
            initialSlide={this.state.imgIndex}
            totalCount={this.state.totalCount}
            onNext={this.nextClick}
            onPrev={this.prevClick}
            onClose={this.toogleModal}
            status={this.renderStatus(this.state.selectedDocument, "preview")}
            comment={comments}
            setImageTrack={(slide) => this.setImageTrack(slide)}
            commentTitle="RSC Engineer Comments"
            siteComment={this.state.selectedDocument.ohsSiteComment}
            type={3}
        />
    }

    renderName = (rowData) => {
        const view = PageHelper.getDocumentType(rowData.type);
        let color = PageHelper.getDocumentTypeColor(rowData.type);
        if (color === "#242424" && this.props.navbarData.theme === "dark") {
            color = "#fff";
        }
        return <a key={rowData.id + "_name"} onClick={() => this.selectAndOpenDocument(rowData)} style={{ display: "flex", color }}>{view}<span style={{ paddingTop: 3 }}>{rowData.name}</span></a>;
    }

    selectAndOpenDocument = (rowData) => {
        if (!rowData.documentPath) {
            toast.error("Can not open document before saving!", { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
            return;
        }
        this.setImageTrack(rowData);
    }

    nextClick = () => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === prevState.imgUrlLength - 1 ? 0 : prevState.imgIndex + 1,
            };
        }, () => {
            const selectedDocument = this.state.allDocument[this.state.imgIndex];
            
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument });
        });
    }

    prevClick = () => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === 0 ? prevState.imgUrlLength - 1 : prevState.imgIndex - 1,
            };
        }, () => {
            const selectedDocument = this.state.allDocument[this.state.imgIndex];
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument })
        });
    }

    setImageTrack = (slide) => {
        const allImages = [...this.state.allDocument];
        const imgUrlLength = allImages.length;
        let imgIndex = allImages.findIndex(item => item.id === slide.id);
        this.setState({ selectedDocument: slide, imgIndex, imgUrlLength, totalCount: (imgIndex + 1) + "/" + imgUrlLength }, () => { this.setState({ openDialogPreview: true }); });
    }

    toogleModal = () => {
        this.setState({ openDialogPreview: false });
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        if (value === 3 || value === 2) {
            this.handleDialog(value);
        } else if (value == 1) {
            this.handlePending();
        }
    }
    

    handleDialogSendBack = () => {
        const overallEHSComment = this.state.overallEHSComment;
        
        this.setState({
            openDialogSendBack: true,
            overallEHSComment,
        });
    }
    handleDialog = (value) => {
        const reviewComments = this.state.selectedDocument.acceptanceComments;
        this.setState({
            openDialog: true,
            reviewComments,
            status: value
        });
    }

    handlePending = () => {
        this.setState({ reviewComments: "", status: 1 }, () => { this.changeStatus(); });
    }

    changeStatus = () => {
        const ehs = { ...this.props.ehsInboxData.currentEhsPing };
        const status = this.state.status;
        const document = { ...this.state.selectedDocument };
        document.acceptanceComments = this.state.reviewComments;
        document.acceptanceStatusId = status;
        document.acceptanceDate = new Date();
        document.rscPersonId = this.props.userData.rscPerson.id;
        document.rscPerson = this.props.userData.rscPerson;


        const checklists = [...ehs.checklists];
        const i = checklists.findIndex(item => item.id == document.ohsChecklistId);
        const documents = checklists[i] && [...checklists[i].documents];
        documents[documents.findIndex(item => item.id === document.id)] = document;
        checklists[i].documents = documents;
        ehs.checklists = checklists;

        
        this.props.setCurrentEhsPing(ehs);

        let allDocument = [...this.state.allDocument];
        allDocument[allDocument.findIndex(item => item.id === document.id)] = document;
        

        this.setState({ allDocument, selectedDocument: document });
    }

    changeAllStatus = (status) => {
        const ehs = { ...this.props.ehsInboxData.currentEhsPing };

        if (ehs.checklists && ehs.checklists.length > 0) {
            ehs.checklists.map(subItem => {
                if (subItem.documents && subItem.documents.length > 0) {
                    subItem.documents.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING).map(document => {
                        document.acceptanceStatusId = status;
                        document.acceptanceDate = new Date();
                        document.rscPersonId = this.props.userData.rscPerson.id;
                        document.rscPerson = this.props.userData.rscPerson;

                    });
                }
            });
        }
        

        let allDocument = [...this.state.allDocument];
        if (allDocument.length > 0) {
            allDocument.filter(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING).map(document => {
                document.acceptanceStatusId = status;
                document.acceptanceDate = new Date();
                document.rscPersonId = this.props.userData.rscPerson.id;
                document.rscPerson = this.props.userData.rscPerson;
            });
        }
        

        this.props.setCurrentEhsPing(ehs);
        this.setState({ allDocument, openAllDialog: false });
    }

    renderRow = (rowData) => {
        var selected = (this.state.selectedDocument && this.state.selectedDocument.id === rowData.id);
        var background = 'silver';
        if (this.props.navbarData.theme === "dark") {
            background = "#353535";
        }
        return {
            background: selected ? background : 'inherit',
        }
    }

    setView = (rowData) => {
        if (this.state.viewMod === 1) {
            return <AdvancedGridList
                tileData={!this.state.approveExcludeSwitchRef ?
                    rowData.documents :
                    rowData.documents.filter(item => item.acceptanceStatusId != 2)}
                selectedDocument={(tile) => this.setState({ selectedDocument: tile })}
                onClickApprove={() => this.handleDialog(2)}
                onClickReject={() => this.handleDialog(3)}
                onClickPending={() => this.handlePending()}
                viewOnly={this.props.ehsInboxData.currentEhsPing.viewOnly}
                onClickImage={(tile) => { this.selectAndOpenDocument(tile) }}
                type={3}
            />
        } else {
            return <Table
                key={rowData.id + "obseleteTable"}
                columns={this.state.ehsPingDocumentColumns}
                container={{ style: { background: "inherit" } }}
                data={this.getDocumentsByMod(rowData.documents)}
                onRowClick={(event, rowData) => this.setState({ selectedDocument: rowData })}
                options={{
                    actionsColumnIndex: -1,
                    search: false,
                    paging: false,
                    minBodyHeight: 50,
                    showTextRowsSelected: false,
                    selection: true,
                    headerStyle: {
                        padding: 2, fontSize: "0.875rem", background: 'transparent',
                        color: "inherit",
                        whiteSpace: "nowrap",
                        fontWeight: 500,
                    },
                    rowStyle: (rowData) => this.renderRow(rowData),
                }}
                actions={[
                    {
                        tooltip: 'Download All Selected Documents',
                        icon: () => <i className="icon icon-download-save"></i>,
                        onClick: (evt, data) => PageHelper.downloadFiles(data, rowData)
                    },
                    {
                        tooltip: 'Delete All Selected Documents',
                        icon: () => <i className="icon icon-trashcan"></i>,
                        onClick: (evt, data) => this.setState({ selectedDeleteDocuments: data, selectedChecklist: rowData, openDialogDelete: true }),
                        hidden: this.props.ehsInboxData.currentEhsPing.viewOnly
                    }
                ]}
                tableRef={rowData.tableRef}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No images to display',
                    }
                }}
            >
            </Table>
        }

    }

    getDocumentsByMod = (documents) => {
        const mod = this.state.viewMod;
        const switchRef = this.state.approveExcludeSwitchRef;

        if (mod !== 2) {
            if (!switchRef) {
                return documents;
            } else {
                return documents.filter(item => (item.acceptanceStatusId) !== 2);
            }
        } else {
            if (!switchRef) {
                return documents.filter(item => item.type === 2);
            } else {
                return documents.filter(item => (item.acceptanceStatusId) !== 2 && item.type === 2);
            }
        }
    }

    togglePanel = (rowData) => {
        this.setState({ selectedRow: rowData }, () => {
            let ehs = { ...this.props.ehsInboxData.currentEhsPing }
            if (ehs.id > 0) {
                ehs.checklists.map(subItem => {
                    if (subItem.id == rowData.id) {
                        subItem.opened = subItem.opened == "opened" ? "" : "opened";
                    } else {
                        subItem.opened = "";
                    }
                })
            }
            this.props.setCurrentEhsPing(ehs);
        });
    }

    toogleAllDetailPanel = () => {
        this.setState({ expandAllDetailPanel: !this.state.expandAllDetailPanel }, () => {
            const ehs = { ...this.props.ehsInboxData.currentEhsPing };

            if (ehs.checklists) {
                ehs.checklists.map(subItem => {
                    if (this.state.expandAllDetailPanel === true) {
                        subItem.opened = "opened";
                    } else {
                        subItem.opened = "";
                    }
                });
            }
            this.props.setCurrentEhsPing(ehs);
        });
    }

    totalStatus = (type) => {
        return this.state.allDocument.filter(item => item.acceptanceStatusId === type).length;
    }

    getRatings = () => {
        const checklists = this.props.ehsInboxData.currentEhsPing.checklists || [];
        const templateItems = checklists.map(item => item.templateItems &&
            item.templateItems.map(t => {
                const newTemp = { ...t };
                newTemp.id = 0;
                newTemp.subCategory = item.name;
                return newTemp;
            })) || [];
        
        return templateItems.reduce((a, b) => a.concat(b)) || [];

    }

    updateEhsPing = (ehs) => {
        ehs.acceptanceStatusId = this.props.ehsInboxData.currentEhsPing.status ? constants.acceptanceStatusTypes.ACCEPTED : constants.acceptanceStatusTypes.REJECTED;
        ehs.closed = this.props.ehsInboxData.currentEhsPing.status ? 1 : 0;
        ehs.ratingDate = DateUtils.getUtcDate();
        ehs.ratingComments = this.props.ehsInboxData.currentEhsPing.ratingComments;
        ehs.ratingSummary = EHSInboxHelper.getTotalScore(this.props.ehsInboxData.currentEhsPing.ratingTotal, this.props.ehsInboxData.currentEhsPing.ratingSum);
        ehs.ratingTotal = this.props.ehsInboxData.currentEhsPing.ratingTotal;
        ehs.scoreTotal = this.props.ehsInboxData.currentEhsPing.ratingSum;
        ehs.ehsRatings = this.getRatings();
        const ehsViolationSentUsers = this.props.ehsInboxData.currentEhsPing.violationSentUsers ?? [];
        if (ehsViolationSentUsers && ehsViolationSentUsers.length > 0) {
            ehsViolationSentUsers.map(user => {
                user.ohsPingId = ehs.id;
                user.userId = user.value;
                user.userEmail = user.mail;
                user.userName = user.text;
                user.createDate = new Date();
                user.createUser = this.props.userData.userData.userId;
                user.id = 0;
            });
        }
        ehs.violation = this.props.ehsInboxData.currentEhsPing.violation;
        ehs.ehsViolationSentUsers = [...ehsViolationSentUsers, ...(this.props.ehsInboxData.currentEhsPing.externalUsers ?? [])];
        ehs.violationComments = ehs.violation ? this.props.ehsInboxData.currentEhsPing.violationComments : null;
        ehs.covid19 = this.props.ehsInboxData.currentEhsPing.covid19;
        ehs.behaviourRating = this.props.ehsInboxData.currentEhsPing.behaviourRating;
        ehs.mainIssue = this.props.ehsInboxData.currentEhsPing.mainIssue || null;
        ehs.nftpClassificationInsideEHS = this.props.ehsInboxData.currentEhsPing.nftpClassificationInsideEHS || null;
        ehs.conditionIsObservation=this.props.ehsInboxData.currentEhsPing.conditionIsObservation 

        
        ehs.viewOnly = this.props.ehsInboxData.currentEhsPing.status;
        ehs.violationDate = (ehs.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED) && this.props.ehsInboxData.currentEhsPing.violation ? new Date() : null;
    }

    checkPendingItems = () => {
        if (this.state.allDocument.some(item => item.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING)) {
            toast.warn(constants.processWarnMessage, { containerId: 'AE', position: toast.POSITION.BOTTOM_RIGHT })
            return;
        } else {
            if (this.props.ehsInboxData.currentEhsPing.acceptanceStatusId === constants.acceptanceStatusTypes.PENDING) {
                this.initializeRating();
            }
            this.setState({ openDialogRating: true });
        }
    }

    initializeRating = () => {
        const ehs = { ...this.props.ehsInboxData.currentEhsPing };
        const checklists = [...ehs.checklists];

        checklists.map(item => {
            const status = item.documents && item.documents.length > 0 && item.documents.every(d => d.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED);
            const comments = item.documents && item.documents.filter(d => d.acceptanceComments).reduce((a, b) => a.concat(b.acceptanceComments + "\n"), "");
            item.templateItems.map(t => {
                t.score = status ? constants.ehsRatingOptions.PASS : t.ratingOptions.find(r => r.value === 0)?.value ?? constants.ehsRatingOptions.FAIL;
                t.additionalComments = comments;
                t.ohsPingId = ehs.id;
            });
            item.score = item.templateItems.reduce((a, b) => a + b.score, 0);
            item.sum = item.templateItems.reduce((a, b) => a + (b.score === constants.ehsRatingOptions.NA ? 0 : constants.ehsRatingOptions.PASS), 0);
            item.status = !item.templateItems.some(t => t.isCritical && t.score === constants.ehsRatingOptions.FAIL);
            item.opened = "opened";
        });

        ehs.checklists = checklists;
        ehs.ratingTotal = checklists.reduce((a, b) => a + b.score, 0);
        ehs.ratingSum = checklists.reduce((a, b) => a + b.sum, 0);
        ehs.status = checklists.every(item => item.status) && Math.round((ehs.ratingTotal / ehs.ratingSum) * 100) >= constants.ehsRatingLimit;
        ehs.violationSentUsers = [{
            value: parseInt(this.props.userData.userData.userId),
            mail: this.props.userData.userMail,
            role: parseInt(this.props.userData.userData.jobRole),
            text: this.props.userData.userData.username
        }];
        ehs.behaviourRating = 5;
        
        this.props.setCurrentEhsPing(ehs);
    }

    saveEhsPing = (type) => {
        const currentEhsPing = this.props.ehsInboxData.currentEhsPing;
        const ehsPing = { ...currentEhsPing.plainEhsPing };
        const rateDate= new Date(DateUtils.toDateString(DateUtils.getUtcDate(), "YYYY-MM-DD HH:mm:ss") )
        const assignedOn=new Date(DateUtils.toDateString(ehsPing.assignedOn, "YYYY-MM-DD HH:mm:ss"))


        if (type != constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
            if (!currentEhsPing.violationComments) {
                toast.error("Issues field must be filled to save.", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
                return;
            }
            this.setState({ openDialogViolation: false })
        }
        if (this.state.isLoading) {
            toast.error("Transaction is processing. Please wait!", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
            return;
        }
        // compare ratingDate with assigned on

if(rateDate<assignedOn){
    toast.warn(`please check your time zone , rate date : ${rateDate} its less than assigned on : ${assignedOn}`, { containerId: 'RE', position: toast.POSITION.TOP_CENTER })
    return;
}
        ehsPing.documents = this.state.allDocument;
        ehsPing.logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            latitude: PageHelper.coord.latitude,
            longitude: PageHelper.coord.longitude,
        };
        ehsPing.activity = currentEhsPing.activity;
        if (type !== constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
            this.updateEhsPing(ehsPing);
        }
        
        ehsPing.highRiskWorkHold = currentEhsPing.highRiskWorkHold
        this.setState({
            isLoading: true, openDialogInfo: type != constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER,
            openDialogReject: false, openDialogViolation: false
        }, () => {
            ApiClient.post("EhsInbox/saveEhsPing", ehsPing, {}, 120000)
                .then(response => {
                    if (response.data) {
                        if (type !== constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
                            const mappedEhsPing = { ...this.props.ehsInboxData.currentEhsPing };
                            this.updateEhsPing(mappedEhsPing);
                            
                            this.props.setCurrentEhsPing(mappedEhsPing);
                        }
                        toast.success("Eritop EHS session update is done successfully.", { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
                        CommonHelper.createNotification({ title: "Eritop EHS session update is done successfully.", icon: "icon-check", timeout: 50000 });
                    } else {
                        CommonHelper.createNotification({ title: "An error occured while saving data! Please redo EHS rating.", icon: "icon-cross", timeout: 50000 });
                        toast.error(constants.processNegativeMessage, { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
                        toast.error("An error occured while saving data! Please redo EHS rating.", { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
                        this.setState({ isLoading: false, openDialogInfo: false, openDialogRating: false },
                            () => {
                                if (type !== constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
                                    this.props.toggleLiveStreamMod(false);
                                    this.props.history.push("/ehs-inbox");
                                }
                            });
                        return;
                    }

                    // Update erisite items upon success: 
                    ApiClient.post("EhsInbox/UpdateEHSChecklistItemsOnErisite", ehsPing, {}, 120000).then(response =>
                    {
                        this.updateErisite(ehsPing, type);
                        CommonHelper.createNotification({ title: "Erisite checklist items are updated successfully!", icon: "icon-check", timeout: 50000 });
                        this.setState({ isLoading: false, openDialogRating: false, openDialogInfo: false },
                            () => {
                                if (type !== constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
                                    this.props.toggleLiveStreamMod(false);
                                    this.props.history.push("/ehs-inbox");
                                }
                            });
                    }).catch(error => {
                        CommonHelper.createNotification({ title: constants.erisiteUpdateError, icon: "icon-cross", timeout: 50000 });
                        toast.error(constants.erisiteUpdateError, { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
                        this.setState({ isLoading: false, openDialogInfo: false, openDialogRating: false },
                            () => {
                                if (type !== constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
                                    this.props.toggleLiveStreamMod(false);
                                    this.props.history.push("/ehs-inbox");
                                }
                            });
                    })

                })
                .catch(error => {
                    
                    toast.error(constants.erisiteUpdateError, { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
                    this.setState({ isLoading: false, openDialogInfo: false, openDialogRating: false },
                        () => {
                            if (type !== constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
                                this.props.toggleLiveStreamMod(false);
                                this.props.history.push("/ehs-inbox");
                            }
                        });
                })
                .finally(() => {
                    // this.setState({ isLoading: false, openDialogInfo: false, openDialogRating: false },
                    //     () => {
                    //         if (type !== constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
                    //             this.props.toggleLiveStreamMod(false);
                    //             this.props.history.push("/ehs-inbox");
                    //         }
                    //     });
                });
        });
    }
    
    updateErisite = (ehsPing, type) => {
        const activity = ehsPing.activity;
        const request = {
            activityId: activity.id,
            systemRecordId: activity.systemrecordid?.toString(),
            assignedWorkGroup: activity.assignedGroup,
            actualStartDate: ehsPing.assignedOn,
            actualEndDate: ehsPing.ratingDate,
            activityAction: constants.erisiteActions[activity.erisiteStatus],
            updateUserId: parseInt(this.props.userData.userData.userId),
            rscScope: activity.rscScope,
            integrationStatus: activity.integrationStatus,
            ohsPingId: ehsPing.id,
            acceptanceStatusId: ehsPing.acceptanceStatusId,
            resourceId: activity.resourceId,
            externalSystemRecordId: activity.externalSystemRecordId
        };
        
        this.setState({ isLoading: true }, () => {
            const api = activity.externalSystemRecordId ? 'SiteTracker/UpdateChecklistItemsAndActualDates ' : 'Erisite/UpdateChecklistItemsAndActualDates'
            ApiClient.post(api, request, {}, 1200000)
                .then(response => {
                    if (response.data.status) {
                        toast.success(response.data.errorMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                    } else {
                        toast.error(response.data.errorMessage, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                    }
                    ehsPing.integrationStatus = response.data.status;
                    ehsPing.integrationDate = DateUtils.toUtcDate(new Date());
                    ehsPing.integrationOutput = response.data.errorMessage;
                    this.props.setEhsPing(ehsPing);
                    this.setState({ isLoading: false });
                }).catch(error => {
                    console.error(error);;
                    toast.error(constants.erisiteUpdateError, { containerId: "ED", position: toast.POSITION.TOP_CENTER });
                    this.setState({ isLoading: false });
                })
                .finally(() => {
                    this.setState({ isLoading: false, openDialogInfo: false, openDialogRating: false },
                        () => {
                            if (type !== constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) {
                                this.props.toggleLiveStreamMod(false);
                                this.props.history.push("/ehs-inbox");
                            }
                        });
                });
        });
    }

    SentBackEhsPingToAspTeam = (type) => {
        const currentEhsPing = this.props.ehsInboxData.currentEhsPing;
        const ehsPing = { ...currentEhsPing.plainEhsPing };
        var user = this.props.userData;

        const overallEHSComment = this.state.overallEHSComment;
            if (!overallEHSComment) {
                toast.error("Issues field must be filled to save.", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
                return;
            }
            ehsPing.overallEHSComment= this.state.overallEHSComment;
            this.setState({ openDialogSendBack: false })
        let visitor = CommonHelper.getVisitorData();
        ehsPing.logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            logDetail: "RSC Person Name: " + user.userData.username + ", RSC Person ID: " + user.rscPerson?.id,
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry

        };
        this.setState({
            isLoading: true, 
			openDialogInfo: false,
           openDialogSendBack: false
        }, () => {
            const res={}
            res.logInfo={...ehsPing }
            const request={}
            request.id=ehsPing.id
            request.overallEHSComment=ehsPing.overallEHSComment
            request.logInfo=ehsPing.logInfo
        
            ApiClient.post("EhsInbox/SentBackEhsPingToAspTeam",request).then(response => {
                    if (response) {
                        CommonHelper.createNotification({ title: "Eritop EHS session is sent back to ASP team successfully.", icon: "icon-check", timeout: 50000 });
                    } else {
                        this.setState({ isLoading: false, openDialogInfo: false, openDialogRating: false });
                        return;
                    }
                })
                .catch(error => {
                    CommonHelper.createNotification({ title: "An error occured while send back to ASP team!", icon: "icon-cross", timeout: 50000 });
 
                    toast.error(constants.erisiteUpdateError, { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
                    this.setState({ isLoading: false, openDialogInfo: false, openDialogRating: false });
                })
                .finally(() => {
                    this.setState({ isLoading: false, openDialogInfo: false, openDialogRating: false },
                        () => {
                               this.props.toggleLiveStreamMod(false);
                                this.props.history.push("/ehs-inbox");
                           
                        });
                });
        });
    }

    handleEhsPingDialog = () => {
        const ehsPing = this.props.ehsPing || this.props.ehsInboxData.currentEhsPing;
        
        
        if (!ehsPing.ratingComments) {
            toast.error("Overall Comment must be filled to proceed.", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
            return;
        }
        else if (!ehsPing.behaviourRating) {
            toast.error("Behaviour Rating must be filled to proceed.", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
            return;
        } 
        else if (!ehsPing.mainIssue) {
            toast.error("Main Issue must be selected to proceed.", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
            return;
        }
        // this.state.nftpOldValue   empty and status false ehsPing.acceptanceStatusId
        // if nft not selected and status failed and first review (session not yet rejected)
        // if nftpOldValue is  null => and status fail 
     

        else if (!ehsPing.nftpClassificationInsideEHS && !ehsPing.status) {
            toast.error("NFTP Classification Inside EHS must be selected to proceed.1", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
            return;
        }

        else if (ehsPing.plainEhsPing.nftpClassificationInsideEHS &&!ehsPing.nftpClassificationInsideEHS) {
            toast.error("NFTP Classification Inside EHS must be selected to proceed.2", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
            return;
        }
        else {
            if (this.getRatings().some(item => item.score === constants.ehsRatingOptions.PASS &&item.isObservation&&isEmpty(item.additionalComments))) {
                toast.error("additional Comment must be filled to proceed", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
                return;
            }
            if (this.getRatings().some(item => item.score === constants.ehsRatingOptions.FAIL && !item.additionalComments)) {
                toast.error("All failed check point's item should be filled, please.", { containerId: 'RE', position: toast.POSITION.TOP_CENTER });
                return;
            }

            if (this.props.ehsInboxData.currentEhsPing.violation) {
                this.setState({ openDialogViolation: true });
            } else if (!this.props.ehsInboxData.currentEhsPing.status) {
                this.setState({ openDialogReject: true });
            } else {

                this.saveEhsPing(constants.activityInboxApprovalTypes.APPROVE);
            }
        }
    }

    deleteSelectedFiles = () => {
        const data = this.state.selectedDeleteDocuments;
        const rowData = this.state.selectedChecklist;

        if (!data || data.length === 0) {
            this.setState({ openDialogDelete: false });
            toast.error("Please, select any document to delete!", { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
            return;
        } else if (data.some(item => item.acceptanceStatusId !== constants.acceptanceStatusTypes.REJECTED)) {
            this.setState({ openDialogDelete: false });
            toast.error("Please, select only rejected documents to delete!", { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
            return;
        }

        const ids = data.map(item => item.id);
        let visitor = CommonHelper.getVisitorData();
        const logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            logObjectId: this.state.selectedDeleteDocuments[0].ohsPingsId,
            logDetail: rowData.name,
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
        };
        this.setState({ isLoading: true }, () => {
            ApiClient.post("EhsInbox/HardDeleteDocuments", { ids, deleteUser: this.props.userData.userData.userId, logInfo }).then(response => {
                if (response.data) {
                    const ehs = { ...this.props.ehsInboxData.currentEhsPing };
                    const checklists = [...ehs.checklists];
                    const i = checklists.findIndex(item => item.id == this.state.selectedDeleteDocuments[0].ohsChecklistId);
                    const documents = checklists[i] &&
                        [...(checklists[i].documents && checklists[i].documents.filter(item => !ids.includes(item.id)))];
                    checklists[i].documents = documents;

                    ehs.checklists = checklists;
                    
                    this.props.setCurrentEhsPing(ehs);

                    let allDocument = [...this.state.allDocument];
                    allDocument = allDocument.filter(item => !ids.includes(item.id));
                    

                    this.setState({ allDocument });
                } else {
                    toast.error(constants.processNegativeMessage, { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
                }
                this.setState({ isLoading: false, openDialogDelete: false });
            }).catch(error => {
                toast.error(constants.errorMessage, { containerId: 'AE', position: toast.POSITION.TOP_CENTER });
                this.setState({ isLoading: false, openDialogDelete: false })
                console.error(error);;
            });
        });
    }

    render() {
        let parsedHighrisk = "";
        let Activity_highRiskItemConfig = this.props.ehsInboxData.currentEhsPing.Activity_highRiskItemConfig ;
        ///console.log(this.props.ehsInboxData.currentEhsPing.Activity_highRiskItemConfig);
        if (Activity_highRiskItemConfig !== "" && Activity_highRiskItemConfig != null) {
            let highRiskObj = JSON.parse(this.props.ehsInboxData.currentEhsPing.Activity_highRiskItemConfig)
            delete highRiskObj.SEVERITY;
            parsedHighrisk = Object.entries(highRiskObj).reduce((acc, [key, value]) => { return acc + `${key}: ${value}\n`; }, '');
        }

        return (<EuiRow style={{ width: "100%", minWidth: 1380 }}>

            <EuiAccordion id="ehsInboxAccordion" style={{ width: "100%" }} items={[
                {
                    title: this.props.ehsInboxData.currentEhsPing.workplanName + " : " + this.props.ehsInboxData.currentEhsPing.id,
                    titleIcon: <i className="icon icon-document" style={{ fontSize: 17, margin: "0px 5px" }}></i>,
                    titleStyle: { paddingLeft: 0, fontWeight: 700, },
                    contentStyle: { paddingLeft: 0 },
                    opened: "opened",
                    content: <Table
                        columns={this.state.checklistColumns}
                        container={{ style: { background: "inherit", paddingLeft: 20 } }}
                        data={EHSInboxHelper.getChecklists(this.props.ehsInboxData.currentEhsPing, this.state.approveExcludeSwitchRef)}
                        isLoading={this.state.isLoading}
                        options={{
                            search: false,
                            paging: false,
                            minBodyHeight: 100,
                        }}
                        tableRef={this.state.tableRef}
                        toolbar={{
                            leftCustom:
                                <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "flex-start" }}>
                                    {
                                        <Tooltip title="Expand/Collapse All">
                                            <IconButton style={{ margin: 10, marginLeft: 5 }} aria-label="expandCollapseAll" onClick={this.toogleAllDetailPanel}>
                                                <i className={"icon " + (this.state.expandAllDetailPanel == true ? "icon-chevron-down" : "icon-chevron-up")}></i>
                                            </IconButton>
                                        </Tooltip>
                                    }
                                    <div style={{ fontSize: 18, textAlign: "left", fontWeight: 500, color: "inherit", paddingLeft: 0 }}>
                                        {this.props.ehsInboxData.currentEhsPing.activityId + " - " + this.props.ehsInboxData.currentEhsPing.name}
                                    </div>
                                </div>,
                            custom:
                                <div className="flex-end-content w-10">
                                    <EuiSwitch checked={this.state.hideEmpty}
                                        name="hideEmptySwitchEhs"
                                        onChange={(e) => this.setState({ hideEmpty: e.target.checked })}
                                        dataEnabled="Hiding Empty"
                                        dataDisabled="Showing Empty"
                                        className="p-1"
                                    />

                                    <EuiSwitch checked={this.state.approveExcludeSwitchRef}
                                        name="approveExcludeSwitchRef"
                                        onChange={(e) => this.setState({ approveExcludeSwitchRef: e.target.checked })}
                                        dataEnabled="Excluding Accepted"
                                        dataDisabled="Including Accepted"
                                        className="p-1"
                                    />
                                    {this.props.ehsInboxData.currentEhsPing.viewOnly ? null :
                                        <div>

                                            <Tooltip title="Accept All">
                                                <IconButton style={{ marginRight: 10 }} aria-label="add" onClick={() => this.setState({ allAcceptOrReject: 2, openAllDialog: true })}>
                                                    <CheckIcon style={{ fontSize: 22 }} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Reject All">
                                                <IconButton style={{ marginRight: 10 }} aria-label="reject" onClick={() => this.setState({ allAcceptOrReject: 3, openAllDialog: true })}>
                                                    <CloseIcon style={{ fontSize: 22 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    }
                                    <Tooltip title="Tile View">
                                        <IconButton style={{ marginRight: 10, background: (this.state.viewMod === 1 ? "silver" : "inherit") }} aria-label="comfyView"
                                            onClick={() => { this.setState({ viewMod: 1 }); }}>
                                            <i className="icon icon-view-tiles" style={{ fontSize: 22 }} ></i>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="List View">
                                        <IconButton style={{ marginRight: 10, background: (this.state.viewMod === 3 ? "silver" : "inherit") }} aria-label="listView"
                                            onClick={() => this.setState({ viewMod: 3 })}>
                                            <i className="icon icon-view-list" style={{ fontSize: 22 }} ></i>
                                        </IconButton>
                                    </Tooltip>
                                </div>,
                        }}
                    >
                    </Table>
                },
            ]} />

            <SummaryDocuments reworkTotal={false}
                totalStatus={(type) => this.totalStatus(type)}
                hideSessionSummary={true}
                style={{ borderBottom: "none", marginLeft: 0 }}
                title="Summary"
            ></SummaryDocuments>

            <EuiTile title="Eritop & Erisite Integration Information" tileStyle={{ paddingLeft: 5 }} headerStyle={{ paddingBottom: 5 }}>          
                <EuiRow>
                    <EuiTextArea className="p-1 pl-0 w-4" fullwidth readOnly style={{ padding: 0, minHeight: 128 }} labelstyle={{ color: "gray" }} id="ehsPingIntegrationHighRisk" name="HighRiskIntegrationOutput" label="Erisite Integration High Risk" value={parsedHighrisk} />
                </EuiRow>
            </EuiTile>

            <EuiFooter id="approveEhsFooter"
                style={{ paddingBottom: 15 }}
                buttons={[
                    // {
                    //     type: "btn secondary",
                    //     name:"Send Back Ehs Ping",
                    //     id: "sendBackToAspTeamEhsPing",
                    //     onClick: () => this.handleDialogSendBack(),
                    //     style: { marginTop: 15 }
                    // },
                    {
                        type: "btn primary",
                        name: this.props.ehsInboxData.currentEhsPing.viewOnly ? "Rating Details" : "Rate This Ping",
                        id: "rateEhsButton",
                        onClick: () => this.checkPendingItems(),
                        style: { marginTop: 15 }
                    }
                ]}
                buttonsLeft={[
                    {
                        type: "btn",
                        name: "Save&Finish Later",
                        id: "saveEhsButton",
                        onClick: () => { this.saveEhsPing(constants.activityInboxApprovalTypes.SAVE_AND_FINISH_LATER) },
                        style: { marginTop: 15, },
                        display: this.props.ehsInboxData.currentEhsPing.viewOnly
                    },
                    {
                        type: "btn",
                        buttonType: "button",
                        name: "Cancel",
                        id: "cancelEhsButton",
                        onClick: () => { alert("Canceled") },
                        display: this.props.routing,
                        style: { marginTop: 15, },
                    },
                ]}
            ></EuiFooter>

            {this.state.openDialogPreview ?
                this.renderFilePreview()
                :
                null
            }

            <DialogModal
                buttonName="Ok"
                content={<FormField> {//<label htmlFor="rejectDescriptionActivity">Rewiew Comments</label>
                }
                    <textarea placeholder="Comments"
                        className="fullwidth"
                        id="rejectDescriptionEhs"
                        style={{ height: 150, width: 400 }}
                        label="Description"
                        name="rejectDescription"
                        defaultValue={this.state.reviewComments}
                        onBlur={e => this.setState({ reviewComments: e.target.value })}
                    /></FormField>}
                handleClose={() => this.setState({ openDialog: false })}
                id="rejectEhsDialog"
                onClick={() => this.setState({ openDialog: false }, () => this.changeStatus())}
                open={this.state.openDialog}
                title="RSC Engineer Comments"
            >
            </DialogModal>
            <DialogModal
            buttonName="Ok"
            content={
                <EuiRow>
                     <textarea placeholder="Enter reason for sent back to ASP team"
                        className="fullwidth"
                        style={{ height: 150, width: 400 }}
                        id="overallEHSComments"
                        label="Send Back Session to ASP team"
                        name="overallEHSComments"
                        onBlur={e => this.setState({ overallEHSComment: e.target.value })}
                    />
                </EuiRow>}
            handleClose={() => this.setState({ openDialogSendBack: false })}
            id="sendBackDialog"
            onClick={() => this.SentBackEhsPingToAspTeam()}
            open={this.state.openDialogSendBack}
            title="Send Back Session to ASP team"
        >
        </DialogModal>

            <DialogModal
                buttonName={<div className="flex-middle-content"><i className="icon icon-check " style={{ marginRight: 10, paddingTop: 2 }}></i>Save</div>}
                buttonsStyle={{ display: (this.props.ehsInboxData.currentEhsPing.closed === 1 ? "none" : "") }}
                cancelButton={<div className="flex-middle-content"><i className="icon icon-cross " style={{ marginRight: 10, paddingTop: 2 }}></i>Cancel</div>}
                content={<RateEhsPing isLoading={this.state.isLoading} />}
                contentStyle={{ padding: "0px 24px" }}
                handleClose={() => this.setState({ openDialogRating: false })}
                id="ratingEhsDialog"
                onClick={() => this.handleEhsPingDialog()}
                open={this.state.openDialogRating}
                title={"EHS Ping ID: " + this.props.ehsInboxData.currentEhsPing.id}
                titleStyle={{ fontSize: 20 }}
                closeButtonVisible={this.props.ehsInboxData.currentEhsPing.closed === 1 ? null : "false"}
                fullScreen={true}
                buttonsSeperate={true}
            >
            </DialogModal>

            <DialogModal
                buttonName="Save & Send"
                content={<EhsPingViolation />}
                handleClose={() => this.setState({ openDialogViolation: false })}
                id="ehsViolationDialog"
                onClick={() => this.saveEhsPing(constants.activityInboxApprovalTypes.REJECT)}
                open={this.state.openDialogViolation}
                title="Reporting EHS Violation"
            >
            </DialogModal>

            <DialogModal
                buttonName="Confirm"
                content={<p>Are you sure you want to reject without reporting EHS violation?</p>}
                handleClose={() => this.setState({ openDialogReject: false })}
                id="ehsRejectDialog"
                onClick={() => this.saveEhsPing(constants.activityInboxApprovalTypes.REJECT)}
                open={this.state.openDialogReject}
                title="Reject"
                type="warning"
            >
            </DialogModal>

            <DialogModal
                buttonName="Confirm"
                content={<p>Are you sure you want to delete these documents permanently?</p>}
                handleClose={() => this.setState({ openDialogDelete: false })}
                id="ehsDeleteDialog"
                onClick={this.deleteSelectedFiles}
                open={this.state.openDialogDelete}
                title="Delete"
                type="warning"
            >
            </DialogModal>

            <DialogModal
                content={<div style={{ justifyContent: "center", display: "flex", flexDirection: "column", alignItems:"center" }}>
                    <div style={{marginBottom: "5%"}}><EuiLoading style={{ opacity: "0.5", position: "unset" }} size="large" isLoading={true} />
                    </div>
                    <div style={{ fontWeight: "bold", marginTop: "5%"}}>Please wait few minutes.. </div>
                    <div style={{ marginTop: "2%", marginBottom:"5%" }}>EHS session ratings and Erisite updates are being performed!</div>
                    </div>
                }
                id="ehsInfoWaitDialog"
                open= {this.state.openDialogInfo}
                title={<p style={{fontWeight: "bold", fontSize: "17px"}}>Processing...</p>}
                buttonsVisible="false"
                closeButtonVisible="false"
                disableBackdropClick={true}
            >
            </DialogModal>

            <DialogModal
                buttonName="Confirm"
                content={<p>{"Are you sure you want to" + (this.state.allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? " accept " : " reject ") + "all pending documents?"}</p>}
                handleClose={() => this.setState({ openAllDialog: false })}
                id="allAcceptRejectDialog"
                onClick={() => this.changeAllStatus(this.state.allAcceptOrReject)}
                open={this.state.openAllDialog}
                title={this.state.allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "Accept All" : "Reject All"}
                type={this.state.allAcceptOrReject === constants.acceptanceStatusTypes.ACCEPTED ? "primary" : "warning"}
            >
            </DialogModal>

            <ToastContainer enableMultiContainer containerId={'AE'} autoClose={2500} />
        </EuiRow >);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ehsInboxData: state.ehsInbox });
const mapDispatchToProps = dispatch => {
    return {
        setCurrentEhsPing: (ehsPing) => dispatch({ type: "SET_CURRENT_EHS_PING", payload: ehsPing }),
        setEhsPageMod: (mod) => dispatch({ type: "SET_EHS_PAGE_MOD", payload: mod }),
        toggleLiveStreamMod: (mod) => dispatch({ type: "TOGGLE_LIVE_STREAM_MOD", payload: mod }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ApproveEhsPing));