import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { SimpleBarStyle } from "../../../components/chaTools/Scrollbar";
import ApiClient from "../../../utils/api-client";
import {
  FetchCurrentMessages,
  FetchDirectConversations,
  FetchParticipantInvitedConversations,
  SetCurrentConversation,
} from "../../../store/slices/conversation";
import Friends from "../sections/Friends";
import useResponsive from "../../../components/chaTools/useResponsive";
import BottomNav from "../../../components/chaTools/BottomNav";
import { isEmpty } from "lodash";
import { FetchUsers, SelectConversation } from "../../../store/slices/chat";
import ListParticipants from "../../../components/chaTools/ListParticipants";
import { setRoomId } from "../../../store/actions/roomAction";

const Chats = (props) => {
  const { activityId, ehsPingId, filterConversation, handleLoad, createNewConversationOrRoom, retry } = props;
  const theme = useTheme();
  const isDesktop = useResponsive("up", "md");
  const { room_id } = useSelector((state) => state.chat);
  const currentImActivity = useSelector(state => state.imInbox.currentIMActivity);
  const currentEhsActivity = useSelector(state => state.ehsInbox.currentEhsPing);
  const roomReducer = useSelector((state) => state.room);
  

  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.chat);

  const { conversations, participantInvited } = useSelector(
    (state) => state.conversation.direct_chat
  );
  useEffect(() => {
    if (isEmpty(users)) {
      dispatch(FetchUsers());
    }
  }, [users]);


  const GetAllConversationsByActivityId = () => {
    var request = {
      activityId:roomReducer.activityId,
      conversationId: room_id, 

    };
    handleLoad(true);
    if(request.activityId)
    ApiClient.post("Chat/GetAllConversationsByActivityId", request).then(
      (data) => {
        if (!data.data) {
          createNewConversationOrRoom().then(() => {
            GetAllConversationsByActivityId();
          });
        }
        else {

          // dispatch action
          dispatch(FetchDirectConversations({ conversations: data.data }));
          let participants = data.data[0]?.participants;
          participants = (participants || [])?.map(function (ele) {
            return { ...ele, activityid: data.data[0].activityid };
          });
          dispatch(SelectConversation({ room_id: data.data[0].id }));
          
          dispatch(setRoomId(data.data[0]?.id))

          dispatch(
            FetchParticipantInvitedConversations({
              participantInvited: participants,
            })
          );
          handleLoad(false);
        }

      }
    );
  };

  useEffect(() => {
    if(roomReducer.activityId)
    {
      GetAllConversationsByActivityId();
    }

  }, [roomReducer.activityId]);

  useEffect(() => {

    console.log("new room redux from GetMessages",room_id);
    if (room_id != null) {
      const current = conversations.find((el) => el?.id === room_id);
      const request = {
        ConversationId: current?.id,
        EhsPingId: ehsPingId,
        Activityid: activityId,
        FilterConversation: filterConversation
      };
      handleLoad(true);

      if (room_id > 0) {

        ApiClient.post("Chat/GetMessages", request).then((data) => {
          // dispatch action
          dispatch(FetchCurrentMessages({ messages: data.data || [] }));
          handleLoad(false);
        });
      }


      // update last messages in conversation
      dispatch(SetCurrentConversation(current));
    }
  }, [room_id, filterConversation, ehsPingId, activityId, retry]);

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };


  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "33vw",
          width: isDesktop ? 400 : "100vw",
          // backgroundColor:
          //   theme.palette.mode === "light"
          //     x? "#F8FAFF"
          //     : theme.palette.background,

          // boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
      

        <Stack p={3} spacing={2} sx={{ maxHeight: "100vh", height: "100%" }}>
          <Stack
            alignItems={"center"}
            justifyContent="space-between"
            direction="row"
          >

            <Stack direction={"row"} alignItems="center" spacing={1}>
              <IconButton
                onClick={() => {
                  handleOpenDialog();
                }}
                sx={{ width: "max-content" }}
              >
                <div style={{ alignItems: "right" }}>
                  <i class='icon icon-plus-small'></i>
                </div>

              </IconButton>
              <Typography variant="subtitle2" sx={{ color: "#676667" }}>
                Participants ({(participantInvited || []).length})
              </Typography>

            </Stack>
          </Stack>

          {/* overflow: "scroll",  */}
          <Stack sx={{ flexGrow: 1, overflowY: "scroll", height: "13vw" }}>
            <SimpleBarStyle timeout={500} clickOnTrack={false}>
              <Stack spacing={2}>
                {(participantInvited || []).map((el, idx) => {
                  return <ListParticipants el={el} />;
                })}
              </Stack>
            </SimpleBarStyle>
          </Stack>
        </Stack>
      </Box>
      {openDialog && (
        <Friends handleLoad={handleLoad} open={openDialog} activityId={activityId} handleClose={handleCloseDialog} />
      )}
    </>
  );
};

export default Chats;
