import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { trim, upperCase } from 'lodash';
import { Grid } from '@material-ui/core';

export default function RadioButtons({name ,value, values, handleChange, disabled }) {
 
    return (
        <RadioGroup name={name} value={value} onChange={handleChange} style={{ display:"-webkit-inline-box" }}>
            {values.map(item =>
                upperCase(trim(name))  ===  upperCase(trim("Main Issue"))  ? (
          <Grid item xs={12}>
                  <FormControlLabel value={item.name} disabled={disabled} control={<Radio />} label={item.name}  />
          </Grid>):(       
                 <FormControlLabel value={item.name} disabled={disabled} control={<Radio />} label={item.name}  />
                 )
            )} 


              
        </RadioGroup>      
    );
}