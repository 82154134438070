export default {
    firebaseConfig: {
        apiKey: "AIzaSyCgfhivXDTR583kOe7U3ZbMEVEDdN0i-z4",
        authDomain: "eritop-asp.firebaseapp.com",
        projectId: "eritop-asp",
        storageBucket: "eritop-asp.appspot.com",
        messagingSenderId: "681502271612",
        appId: "1:681502271612:web:36d48cf29c89770fc841f9"
    },
    vapIdKey: "BKAdgzNp2mjqlTCwGVyfgOoNikWJaK2JmfgGgxQtFY_KTgLUOjdXXqNOOJXV0Er8Cj7haUSlmJyGWWmX0OvScdg"
}