import React, { useEffect, useState } from 'react';
import "../../css/common.css";
import AspCompanyInfo from './aspCompanyInfo';
import AspCompanyDocuments from './aspCompanyDocuments';
import { useDispatch, useSelector } from "react-redux";
import { withLayout } from '../../layout';
import ApiClient from '../../utils/api-client';
import EuiRow from "../../eui-components/row";
import EuiGenericTree from "../../eui-components/genericTree";
import PageHelper from "../../utils/page-helper";
import EuiTabs from "../../eui-components/tabs";
import EuiLoading from "../../eui-components/loading";
import AspEhsCompliance from './aspEhsCompliance';
import VehicleManagement from "./vehicle-management/index";
import constants from '../../utils/constants';

const AspCompaniesList = (props) => {
    const {aspRscCountries}=props;
    const [countriesASPList, setcountriesASPList] = useState([]);
    const [filterValue, setFilterValue] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const navbarData = useSelector(state => state.navigation);
    const countryASPList = useSelector(state => state.asp.countryASPList);
    const rscCountries = useSelector(state => state.asp.rscCountries);
    const countriesInitialList = useSelector(state => state.user.countries)
    const userJobRole = useSelector(state => state.user.userData.jobRole);
    const userAspCompanies = useSelector(state => state.user.userDetail?.userAspCompanies);
    const userData = useSelector(state => state.user);

    const countries = []
    const dispatch = useDispatch();

    useEffect(() => {
        if (countriesInitialList && countriesASPList.length === 0) {
            setIsLoading(false);          
            countriesInitialList.forEach(e => {
                let tempAspCompanies = [];
                switch (userJobRole) {
                    case constants.userRoles.ASP_PROJECT_MANAGER:
                        tempAspCompanies = e.aspCompanies?.filter(a => userAspCompanies.map(u => u.aspCompanyId).includes(a.value))
                        break;
                    case constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR:
                    case constants.userRoles.ERICSSON_IM:
                    case constants.userRoles.REPORTER:
                    case constants.userRoles.ASP_MANAGER:
                    case constants.userRoles.EHS_SME:
                    case constants.userRoles.VALIDATION_OFFICER:
                        let customersCountriesList = userData.userDetail.customers.map(customer => customer.customer.countryId);
                        let country = customersCountriesList?.find(c => e.value === c);
                        if (country)
                            tempAspCompanies = e.aspCompanies;
                        else
                            tempAspCompanies = [];

                        break;
                    case constants.userRoles.RSC_COODINATOR:
                    case constants.userRoles.RSC_OHS_INSPECTOR:
                    case constants.userRoles.RSC_SITE_SUPERVISOR:                       
                        let rscCentercountries = aspRscCountries?.filter(item => item.rscCenterId === userData.rscPerson?.rscCenterId);
                        let _country = rscCentercountries?.find(c => e.value === c.countryId);

                        if (_country)
                            tempAspCompanies = e?.aspCompanies;
                        else
                            tempAspCompanies = [];
                        break;
                    default: tempAspCompanies = e?.aspCompanies;
                        break;

                };
                const aspCompanies = tempAspCompanies;
                let properties = { "state": "closed", "element": e.text, "subList": aspCompanies, "id": e.value };
                if ((aspCompanies ?? []).length > 0) {
                    countries.push(properties);
                }
                
            });
            countries.sort(PageHelper.dynamicSort("element"));
            setcountriesASPList(countries)
            dispatch({ type: "SET_COUNTRIES_ASP_LIST", payload: countries });
        }
    }, [aspRscCountries]);

    const aspView = (aspId, countryName) => {
        ApiClient.post("Asp/GetAspCompanyById", { id: parseInt(aspId) }).then(response => {
            dispatch({ type: "SET_ASP_VIEW", payload: { mode: 'view', selectedASP: response.data, selectedCountry: countryName } })
        }).catch(e => console.error(e?.response?.data))
    }

    const aspFilter = (e) => {
        var value = e.target.value;
        if (value !== "") {
            if (value > filterValue.length) {
                var testList = countriesASPList;
                var listTemp = [];
                countryASPList.forEach(e => {
                    var element = {
                        ...e,
                        subList: e.subList.filter(x => x.text.toUpperCase().includes(value.toUpperCase())),
                        state: "opened"
                    }
                    if (element.subList.length > 0) {
                        listTemp.push(element);
                    }

                })
                setFilterValue(value);
                setcountriesASPList(listTemp);
            }
            else {
                var testList = [];
                var listTemp = [];
                countryASPList.forEach(e => testList.push(e));
                testList.forEach(e => {
                    var element = {
                        ...e,
                        subList: e.subList.filter(x => x.text.toUpperCase().includes(value.toUpperCase())),
                        state: "opened"
                    }

                    if (element.subList.length > 0) {
                        listTemp.push(element);
                    }

                })
                setFilterValue(value);
                setcountriesASPList(listTemp);
            }
        }
        else {
            setcountriesASPList(countryASPList)
            setFilterValue("");
        }
    }

    const onItemClicked = (e) => {
        const data = [...countryASPList];
        const item = data.find(item => item.id == e);
        item['state'] = (item.state == "opened" ? "closed" : "opened");
        dispatch({ type: "SET_COUNTRIES_ASP_LIST", payload: data });
        setcountriesASPList(data);
    }

    const selectedAsp = (id, element) => {
        aspView(id, element);
        const newList = [];
        countriesASPList.map(e => {
            if (e.element == element) {
                e.subList.map(s => {
                    if (s.value == id) {
                        s.state = "active";
                    }
                    else {
                        s.state = "";
                    }
                })
            }
            else {
                e.subList.forEach(x => {
                    x.state = "";
                })
            }
            newList.push(e);
        });
        setcountriesASPList(newList)
    }

    const updateTabsContent = () => {
        const viewContent = [{
            value: 1,
            children: <AspCompanyInfo />,
            selected: "selected",
            contentStyle: { width: "100%", maxHeight: 650 }
        },
        {
            value: 2,
            children: <AspCompanyDocuments />,
            selected: "",
            contentStyle: { width: "100%", maxHeight: 650 }
        },
        {
            value: 3,
            children: <AspEhsCompliance />,
            selected: "",
            contentStyle: { width: "100%", maxHeight: 650 }
        },
        {
            value: 4,
            children: <VehicleManagement />,
            selected: "",
            contentStyle: { width: "100%", maxHeight: 650 }
        },
        ]

        return viewContent;
    }

    return (
        <div key={isLoading}>
            <EuiLoading id="ASPListLoading" isLoading={isLoading} />
            <EuiRow className={(isLoading ? "disabled" : "")}>
                <div className="column sm-12 lg-3 xl-3 md-3" style={{ marginBottom: 0 }}>
                    <EuiGenericTree
                        id="aspTree"
                        data={countriesASPList}
                        onFilterChange={(e) => aspFilter(e)}
                        viewTree={navbarData.aspTree}
                        onItemClicked={onItemClicked}
                        onSubItemSelect={selectedAsp}
                        filter={true}
                    />
                </div>
                <div className={(navbarData.aspTree ? "column sm-12 lg-9 xl-9 md-9" : "column sm-12 lg-12 xl-12")}>
                    <div className={"tile fullscreen"} id="dataTreeAsp" style={{ padding: 10 }}>
                        <div className="right">
                            <div className="action" data-type="maximize">
                                <span className="tooltip pointer">
                                    <i className="icon icon-nav"
                                        onClick={() => { dispatch({ type: "TOGGLE_ASP_TREE", payload: !navbarData.aspTree }) }}
                                    ></i> <span className="message right blue">{(navbarData.aspTree ? "Hide ASP Tree" : "View ASP Tree")} </span>
                                </span>
                            </div>
                        </div>
                        <EuiTabs
                            titleColumnStyle={{ marginBottom: "10px" }}
                            contentStyle={{ width: "100%" }}
                            id="ASPTabs"
                            titles=
                            {[{ id: 1, value: 1, name: "ASP information", selected: "selected", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } },
                            {
                                id: 2, value: 2, name: "ASP Documents", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" }
                            },
                            {
                                id: 3, value: 3, name: "EHS Compliance", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" }
                            },
                            {
                                id: 4, value: 4, name: "Vehicle Management", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" }
                            }
                            ]}
                            contents={updateTabsContent()}
                        />
                    </div>
                </div>
            </EuiRow>
        </div>)
}
export default withLayout(AspCompaniesList)