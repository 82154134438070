/* Create new complaint dialog box (Large)
1. Complaint scope: drop - down list of complaints_scopes
2. Complaint type: drop - down list of complaints_types
3. Complaint: Free area text box(required)
4. If scope is ASP then a country list is shown and based on it an asp_companies list is shown and based on the asp company
selected a list of ASP resources is shown for ASP resource
5. Select a list of supporting documents for the complaint
6. Save button*/
import React, { useState, useEffect } from "react";
import EuiTile from "../../eui-components/tile";
import { withRouter } from "react-router-dom";
import EuiRow from '../../eui-components/row';
import { useSelector, useDispatch } from "react-redux";
import EuiRowWithToolbar from '../../eui-components/rowwithtoolbar';
import EuiTabs from "../../eui-components/tabs";
import ComplaintInfo from "./complaintInfo";
import ComplaintDocuments from "./complaintDocuments";
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import constants from "../../utils/constants";
import { withLayout } from '../../layout';
import EuiLoading from "../../eui-components/loading";
import PageHelper from "../../utils/page-helper";
import DateUtils from "../../utils/date-utils";
import ApiClient from '../../utils/api-client';
import { Notification } from '@eds/vanilla';
import DialogModal from '../../components/dialog';
import EuiProgressBar from "../../eui-components/progressbar";
import SasHelper from "../../utils/sas-helper";

const ComplaintDetails = (props) => {
    const dispatch = useDispatch();
    const complaint = useSelector(state => state.complaints.addedComplaint);
    const user = useSelector(state => state.user);
    const isLoading = useSelector(state => state.complaints.isLoading);
    const sasToken = useSelector(state => state.complaints.sasToken);
    const [complaintId, setComplaintId] = useState();
    const [justificationId, setJustificationId] = useState();
    const [percentageArr, setPercentageArray] = useState([]);
    const [resolutionPercenatgeArr, setResolutionPercenatgeArr] = useState([]);
    const [openDialogUploadBar, setOpenDialogUploadBar] = useState(false);
    const [justificationDocsUploadBar, openJustificationDocsUploadBar] = useState(false);
    const files = useSelector(state => state.complaints.complaintDocuments);
    const complaintRecipients = useSelector(state => state.complaints.complaintRecipients);

    useEffect(() => {
        if (openDialogUploadBar) {
            const onProgress = createPercentage;
            PageHelper.uploadItems(files, onProgress, "complaint-documents").then(uploadedFiles => {
                dispatch({ type: "SET_IS_LOADING", payload: true });
                genericAddDocuments(complaintId, uploadedFiles);
            });
        }
        if (justificationDocsUploadBar) {
            const onProgress = createResPercentage;
            PageHelper.uploadItems(files, onProgress, "complaint-documents").then(uploadedFiles => {
                dispatch({ type: "SET_IS_LOADING", payload: true });
                addJustificationDocuments(justificationId, uploadedFiles);
            });
        }
    }, [openDialogUploadBar, justificationDocsUploadBar]);
    const addComplaints = () => {
        if (complaint != null) {
            if (complaint.aspCompanyId == null || complaint.complaintContent == null || complaint.complaintTypeId == null || complaintRecipients == null || complaintRecipients?.length == 0) {
                if (complaintRecipients == null || complaintRecipients?.length == 0) {
                    const notification = new Notification({
                        title: "Please Select at least 1 recipient for the complaint!",
                        timeout: 50000,
                        icon: 'icon-circle-warning'
                    });
                    notification.init();
                    return;
                }
                const notification = new Notification({
                    title: "Please add all the mandatory fields!",
                    timeout: 5000,
                    icon: 'icon-circle-warning'
                });
                notification.init();
                return;
            }
            let addedComplaint = {
                id: parseInt(complaint.id ?? 0),
                aspCompanyId: parseInt(complaint.aspCompanyId),
                complainedAboutUserId: complaint.complainedAboutUserId ? parseInt(complaint.complainedAboutUserId) : null,
                complaintContent: complaint.complaintContent,
                complaintScopeId: parseInt(complaint.complaintScopeId ?? 1),
                complaintTypeId: parseInt(complaint.complaintTypeId ?? 1),
                createUserId: complaint.createUserId ?? parseInt(user.userData.userId),
                createDateTime: complaint.createDateTime ?? null,
                statusId: parseInt(complaint.statusId),
                modifiedUserId: (complaint.id ?? 0) === 0 ? null : parseInt(user.userData.userId),
                modifiedDateTime: (complaint.id ?? 0) === 0 ? null : complaint.modifiedDateTime,
                complaintJustificationId: parseInt(complaint.complaintJustificationId),
                acknowledgedUserId: parseInt(complaint.acknowledgedUserId),
                resolvedUserId: parseInt(complaint.resolvedUserId),
                acknowledgedDateTime: complaint.acknowledgedDateTime,
                resolvedDateTime: complaint.resolvedDateTime,
                complaintRelatedPeople: complaintRecipients
            }
            ApiClient.post("UserAdmin/AddUserComplaints", addedComplaint).then(response => {
                const notification = new Notification({
                    title: "Complaint is added successfully",
                    timeout: 5000,
                    icon: 'icon-check'
                });
                notification.init();
                setComplaintId(response.data.id);
                PageHelper.getCoordinates();
                setPercentageArray(PageHelper.initializePercentageArr(files));
                setOpenDialogUploadBar(true);
            }).catch(error => {
                const notification = new Notification({
                    title: "An error occured while uploading the complaint",
                    timeout: 50000,
                    icon: 'icon-circle-warning'
                });
                notification.init();
                dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
            })
        }
        else {
            const notification = new Notification({
                title: "Please add all the mandatory fields!",
                timeout: 5000,
                icon: 'icon-circle-warning'
            });
            notification.init();
            return;
        }
    }
    const createPercentage = (progressEvent, file) => {
        let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
        let percentageArray = [...percentageArr];
        const index = percentageArray.indexOf(file);
        percentageArray[index].percentage = percentage;
        setPercentageArray(percentageArray);
    }
    const createResPercentage = (progressEvent, file) => {
        let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
        let percentageArray = [...resolutionPercenatgeArr];
        const index = percentageArray.indexOf(file);
        percentageArray[index].percentage = percentage;
        setResolutionPercenatgeArr(percentageArray);
    }
    const sendNotificationEmail = (docLinks) => {
        var emailModel = {
            emailType: complaint.id == 0 || complaint.id == null ? "New" : "Edit",
            aspCompanyId: parseInt(complaint.aspCompanyId),
            complaintId: parseInt(complaintId),
            createUserId: parseInt(user.userData.userId),
            userCreatedEmail: user.userMail,
            userCreatedName: user.userDetail.name,
            complainedAboutUserEmail: user.userDetail.userResourceModels?.find(i => i.value == complaint.complainedAboutUserId)?.email,
            complainedAboutUserId: complaint.complainedAboutUserId ? parseInt(complaint.complainedAboutUserId) : null,
            documentLinks: docLinks ?? "",
            complaintType: complaint.complaintType ?? complaint.complaintTypeName,
            complaintScope: complaint.complaintScope ?? complaint.complaintScopeName,
            complaintContent: complaint.complaintContent,
            complainedAboutUserName: user.userDetail.userResourceModels?.find(i => i.value == complaint.complainedAboutUserId)?.name
        }
        ApiClient.post("UserAdmin/SendComplaintNotificationEmail", emailModel).then(e => {
            const notification = new Notification({
                title: 'Complaint is sent to responsible ASP people successfully!',
                timeout: 50000,
                icon: 'icon-check'
            });
            dispatch({ type: "SET_IS_LOADING", payload: true });
            notification.init();
            if (user.userData.jobRole !== constants.userRoles.ASP_PROJECT_MANAGER) {
                ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                    dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }) }); (response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                    dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.LIST });
                }).catch(error => {
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                    console.error(error);;
                });
            }
            else {
                var userAspCompaniesList = user.userDetail?.userAspCompanies.map(i => parseInt(i.aspCompanyId));
                if (userAspCompaniesList != null || userAspCompaniesList.length != 0) {
                    ApiClient.post("UserAdmin/GetComplaintsByASPCompanyId", { userAspCompanies: userAspCompaniesList, createUserId: parseInt(user.userData.userId) }).then(response => {
                        dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }) }); (response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                        dispatch({ type: "SET_IS_LOADING", payload: false });
                        dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.LIST });
                    }).catch(error => {
                        dispatch({ type: "SET_IS_LOADING", payload: false });
                    })
                }
                else {
                    ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                        dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }) }); (response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                        dispatch({ type: "SET_IS_LOADING", payload: false });
                        dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.LIST });
                    }).catch(error => {
                        dispatch({ type: "SET_IS_LOADING", payload: false });
                    });
                }
            }
        }).catch(err => {
            const notification = new Notification({
                title: "An error occured while sending the complaint's email",
                timeout: 50000,
                icon: 'icon-circle-warning'
            });
            notification.init();
        });
        dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
    }
    const sendJustificationNotificationEmail = (docLinks) => {
        var emailModel = {
            complaintId: parseInt(complaint.id),
            resolvedUserName: user.userDetail.name,
            resolvedUserEmail: user.userMail,
            resolvedUserId: parseInt(user.userData.userId),
            createUserId: parseInt(complaint.createUserId),
            userCreatedName: complaint.createdUserName,
            complainedAboutUserId: complaint.complainedAboutUserId ? parseInt(complaint.complainedAboutUserId) : null,
            documentLinks: docLinks,
            complaintContent: complaint.complaintContent,
            justification: complaint.complaintJustificationDetails,
        }
        ApiClient.post("UserAdmin/SendJustificationNotificationEmail", emailModel).then(e => {
            const notification = new Notification({
                title: 'Complaint is resolved successfully!',
                timeout: 50000,
                icon: 'icon-check'
            });
            notification.init();
            dispatch({ type: "SET_IS_LOADING", payload: true });
            var userAspCompaniesList = user.userDetail?.userAspCompanies.map(i => parseInt(i.aspCompanyId));
            if (userAspCompaniesList != null || userAspCompaniesList.length != 0) {
                ApiClient.post("UserAdmin/GetComplaintsByASPCompanyId", { userAspCompanies: userAspCompaniesList, createUserId: parseInt(user.userData.userId) }).then(response => {
                    dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }) }); (response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                    dispatch({ type: "SET_PAGE_MOD", mod: constants.pageMods.LIST });
                }).catch(error => {
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                })
            }
            else {
                ApiClient.post("UserAdmin/GetUserComplaintsByUserId", { id: parseInt(user.userData.userId) }).then(response => {
                    dispatch({ type: "SET_ALL_COMPLAINTS", payload: response.data?.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }) }); (response.data.map(i => { return { ...i, logInfo: "User Created: " + (i.createdUserName ?? "") + ", " + "Create Date and Time: " + DateUtils.toDateString(i.createDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "User Modified: " + (i.modifiedUserName ?? "") + ", " + "Modified Date and Time: " + DateUtils.toDateString(i.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Acknowledged User: " + (i.acknowledgedUserName ?? "") + ", " + "Acknowledgemnt Date and Time: " + DateUtils.toDateString(i.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") + "<br/>" + "Resolved User: " + (i.resolvedUserName ?? "") + ", " + "Resolution Date and Time: " + DateUtils.toDateString(i.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm"), complaintContentId: [i.id, i.complaintContent], evId: i.id, ackId: i.id, resolveId: i.id } }));
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                    dispatch({ type: "SET_PAGE_MOD", mod: constants.pageMods.LIST });
                }).catch(error => {
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                });
            }
            dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });

        }).catch(error => {
            const notification = new Notification({
                title: 'An error occurred while resolving the complaint!',
                timeout: 5000,
                icon: 'icon-circle-warning'
            });
            notification.init();
            dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
        });
    }
    const addJustificationDocuments = (justificationId, uploadedFiles) => {
        let docs = [];
        if (!uploadedFiles || uploadedFiles?.length == 0) {
            openJustificationDocsUploadBar(false);
            dispatch({ type: "SET_IS_LOADING", payload: false });
            sendJustificationNotificationEmail(docs.map(i => SasHelper.addSasKey(i.documentLink , sasToken)));
            docs = [];
        }
        else {
            docs = uploadedFiles.map(item => {
                return {
                    complaintId: parseInt(complaint.id),
                    complaintJustificationId: parseInt(justificationId),
                    documentType: "Corrective Actions",
                    documentLink: item.documentPath,
                    documentName: item.name,
                    createUserId: parseInt(user.userData.userId)
                }
            });
            ApiClient.post("UserAdmin/AddComplaintDocuments", docs)
                .then(response => {
                    const notification = new Notification({
                        title: 'Documents are uploaded successfully!',
                        timeout: 5000,
                        icon: 'icon-check'
                    });
                    notification.init();
                    dispatch({ type: "SET_COMPLAINT_DOCUMENTS", payload: [] });
                    sendJustificationNotificationEmail(docs.map(i => SasHelper.addSasKey(i.documentLink , sasToken)));
                    docs = [];
                    openJustificationDocsUploadBar(false);
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                }).catch(error => {
                    openJustificationDocsUploadBar(false);
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                });
        }

    }
    const genericAddDocuments = (complaintId, uploadedFiles) => {
        let docs = [];
        if (!uploadedFiles || uploadedFiles?.length == 0) {
            setOpenDialogUploadBar(false);
            sendNotificationEmail(docs.map(i => SasHelper.addSasKey(i.documentLink , sasToken)));
            docs = [];
            dispatch({ type: "SET_IS_LOADING", payload: false });
        }
        else {
            docs = uploadedFiles.map(item => {
                return {
                    complaintId: parseInt(complaintId),
                    documentType: "Evidence",
                    documentLink: item.documentPath,
                    documentName: item.name,
                    createUserId: parseInt(user.userData.userId)
                }
            });
            ApiClient.post("UserAdmin/AddComplaintDocuments", docs)
                .then(response => {
                    const notification = new Notification({
                        title: 'Documents are uploaded successfully!',
                        timeout: 5000,
                        icon: 'icon-check'
                    });
                    notification.init();
                    // retreive list of complaints
                    dispatch({ type: "SET_COMPLAINT_DOCUMENTS", payload: [] });
                    setOpenDialogUploadBar(false);
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                    sendNotificationEmail(docs.map(i => SasHelper.addSasKey(i.documentLink , sasToken)));
                    docs = [];
                }).catch(error => {
                    setOpenDialogUploadBar(false);
                    dispatch({ type: "SET_IS_LOADING", payload: false });
                });
        }

    }
    const resolveComplaint = () => {
        if (complaint.complaintJustificationDetails == null || complaint.complaintJustificationDetails.length == 0) {
            const notification = new Notification({
                title: "Please add all the mandatory fields!",
                timeout: 5000,
                icon: 'icon-circle-warning'
            });
            notification.init();
            return;
        }
        ApiClient.post("UserAdmin/ResolveComplaint", { statusId: parseInt(constants.complaintStatus.Resolved), createUserId: parseInt(user.userData.userId), justification: complaint.complaintJustificationDetails, complaintId: parseInt(complaint.id), complaintJustificationId: parseInt(complaint.complaintJustificationId ?? 0) }).then(response => {
            setJustificationId(response.data.id);
            PageHelper.getCoordinates();
            setResolutionPercenatgeArr(PageHelper.initializePercentageArr(files));
            openJustificationDocsUploadBar(true);
        }).catch(e => {
        });
    }
    return (<EuiTile>
        <EuiRow disabled={isLoading ? "disabled" : ""}>
            <DialogModal
                content={percentageArr.map(file => {
                    return <div key={file.name} style={{ width: 500 }}>
                        <label><strong>{file.name}</strong></label>
                        <EuiProgressBar value={file.percentage} />
                    </div>
                })}
                handleClose={() => setOpenDialogUploadBar(false)}
                id="uploadComplaintDocumentsDialog"
                titleId="titleUploadComplaintDocumentsDialog"
                open={openDialogUploadBar}
                buttonsVisible="false"
                title="Progress Loading..."
                closeButtonVisible="false"
                key={percentageArr}
                disableBackdropClick={true}
            />
            <DialogModal
                content={resolutionPercenatgeArr.map(file => {
                    return <div key={file.name} style={{ width: 500 }}>
                        <label><strong>{file.name}</strong></label>
                        <EuiProgressBar value={file.percentage} />
                    </div>
                })}
                handleClose={() => openJustificationDocsUploadBar(false)}
                id="uploadJustificationDocumentsDialog"
                titleId="titleUploadJustificationDocumentsDialog"
                open={justificationDocsUploadBar}
                buttonsVisible="false"
                title="Progress Loading..."
                closeButtonVisible="false"
                key={resolutionPercenatgeArr}
                disableBackdropClick={true}
            />
            <EuiLoading id="complaintDetailsLoading" isLoading={isLoading} />
            <div className="w-10">
                <EuiBreadcrumb id="compalintBreadcrumb"
                    parentName="Home"
                    childName={complaint.id ?? ""}
                    onClickParent={() => dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.LIST })}
                    className="p-2" />
                <EuiRowWithToolbar
                    toolBarStyle={{ backgroundColor: "#FAFAFA" }}
                    leftIcons={
                        <>
                            <button
                                disabled={isLoading}
                                onClick={() => props.mod === constants.pageMods.RESOLVE ? resolveComplaint() : addComplaints()}
                                className="btn primary">
                                Save
                            </button>
                            <button
                                disabled={isLoading}
                                onClick={() => {
                                    dispatch({ type: "SET_ADDED_COMPLAINT", payload: {} });
                                    dispatch({ type: "SET_PAGE_MOD", payload: constants.pageMods.LIST });
                                }}
                                className="btn">
                                Cancel
                            </button>
                        </>} />
            </div>
            <EuiTabs
                id="complaintDetailsTabs"
                titles={[{ id: 1, value: 1, name: "Information", selected: "selected" },
                { id: 2, value: 2, name: "Uplaod Evidence" },
                ]}
                contents={[{ value: 1, children: <ComplaintInfo mod={props.mod} />, selected: "selected" }, { value: 2, children: <ComplaintDocuments mod={props.mod} />, selected: "false" }]}
                tabStyle={{ width: "100%" }}
            />
            { /* Supporting docs here*/}
        </EuiRow>
    </EuiTile>)
}
export default withRouter(withLayout(ComplaintDetails));