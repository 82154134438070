const AcceptRejectDialogReducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE_DIALOG":
            return {
                ...state,
                openDialog: !state.openDialog,
            };
        case "HANDLE_DIALOG_CHANGE":
            return {
                ...state,
                [action.field]: action.payload,
            }
        case "SET_ACCEPT_REJECT_DIALOG":
            return {
                ...state,
                ...action.payload,
            }
        case "SET_SELECTED_DOCUMENT":
            return {
                ...state,
                selectedDocument: action.payload,
            }
        case "SET_DOCUMENT_VIEW_DIALOG":
            return {
                ...state,
                ...action.payload,
            }
        case "TOGGLE_DOCUMENT_DIALOG":
            return {
                ...state,
                openDialogPreview: !state.openDialogPreview,
            };
        case "SET_ALL_DOCUMENT":
            return {
                ...state,
                allDocument:action.payload,
            }
        case "SET_SELECTED_AND_ALL_DOCUMENT":
            return {
                ...state,
                allDocument: action.payload.allDocument,
                selectedDocument: action.payload.selectedDocument,
            }
        default:
            return state;
    }
};

export default AcceptRejectDialogReducer;