import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import constants from "../../utils/constants";
import EuiDialog from "../../eui-components/dialog";
import UploadDocuments from "../../components/documentUploadModal/documentsUploader";
import DateUtils from "../../utils/date-utils";
import DialogModal from "../../components/dialog";
import PageHelper from "../../utils/page-helper";
import EuiProgressBar from "../../eui-components/progressbar";
import ApiClient from "../../utils/api-client";
import EuiLoading from "../../eui-components/loading";
import WorkplanHelper from "../../utils/workplan-helper";
import { Notification } from '@eds/vanilla';
import SasHelper from "../../utils/sas-helper";

class WorkplanDocuments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            percentageArr: [],
            openDialogUploadBar: false,
            done: false,
            uploadedDocs: [],
            isLoading: false,
            selectedDoc: {},
            edit: false,
            editTitle: "",
            editType: "",
            editId: ""
        }
    }
    

    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
    }


    save = (id) => {
        const wp = { ... this.props.workplanData.workplan };
        const docs = [... this.props.workplanData.workplan.workplanDocs];
        const selectedWorkplan = this.props.workplanData.filteredWorkplans.data.find(item => item.id === this.props.workplanData.workplan.id);
        this.props.setWorkplan({});
        var d = docs.find(item => item.id === id);
        
        d.lastModifiedUser = parseInt(this.props.userData.userData.id);
        if (this.state.editTitle?.length > 0) {
            d.title = this.state.editTitle;
        }
        if (this.state.editType?.length > 0) {
            d.documentType = parseInt(constants.cmsDocumentTypes.find(x => x.text == this.state.editType).value);
        }
        wp['workplanDocs'] = docs;
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/UpdateWorkplanDocument", d)
                .then(response => {
                    this.setState({ edit: false }, () => {
                        const workplan = { ...response.data };
                        workplan["customer"] = { ...selectedWorkplan.customer };
                        WorkplanHelper.mapWorkplan(workplan);
                        
                        this.props.setWorkplan(workplan);
                        this.setState({ isLoading: false });
                        const notification = new Notification({
                            title: 'Document is updated successfully!',
                            timeout: 5000,
                            icon: 'icon-check'
                        });
                        notification.init();
                    });
                })
                .catch(error => {
                    this.setState({ isLoading: false });
                });
        });
        this.setState({
            selectedDocument: d,
            edit: false
        });
    }
    sortByKey = (array, key) => {
        if (array?.length > 0) {
            return array.sort(function (a, b) {
                var x = a[key];
                var y = b[key];

                if (typeof x == "string") {
                    x = ("" + x).toLowerCase();
                }
                if (typeof y == "string") {
                    y = ("" + y).toLowerCase();
                }

                return ((x < y) ? 1 : ((x > y) ? -1 : 0));
            });
        }
       
    }
    cancel = (id) => {
        const wp = { ... this.props.workplanData.workplan };
        var docs = [... this.props.workplanData.workplan.workplanDocs];
        this.props.setWorkplan({});
        this.setState({ edit: false, editId: "" }, () => {
            this.setState({ edit: false, editId: "" })
            var d = docs.find(item => item.id == id);
            d.documentType = d.documentType;
            d.title = d.title;
            wp['workplanDocs'] = docs;
            this.props.setWorkplan(wp);
        })
    }

    update = (e, object) => {
        
        if (object == "title") {
            this.setState({
                editTitle: e.target.value
            });
        }
        if (object == "type") {
            this.setState({
                editType: e.target.value
            });
        }
    }

    uploadDocuments = () => {
        var flag = false;
        
        const files = [...this.props.workplanData.documents];
        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            const notification = new Notification({
                title: 'Upload Failed',
                description: fileSize,
                timeout: 5000,
                icon: 'icon-triangle-warning'
            });
            notification.init();
            return;
        }
        else {
            files.map(item => {
                if (item.documentType == null) {
                    flag = true;
                }
            });
            if (flag) {
                const notification = new Notification({
                    title: 'Upload Failed',
                    description: 'Please add all document types!',
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
                return;
            }
            else {
                PageHelper.getCoordinates();
                this.setState({
                    openDialogUploadBar: true,
                    percentageArr: PageHelper.initializePercentageArr(files),
                    done: true
                }, () => {
                    const onProgress = (progressEvent, file) => {
                        let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                        let percentageArr = [...this.state.percentageArr];
                        const index = percentageArr.indexOf(file);
                        percentageArr[index].percentage = percentage;
                        this.setState({ percentageArr });
                    }

                    PageHelper.uploadItems(files, onProgress, "workplan-docs").then(uploadedFiles => {
                        const notification = new Notification({
                            title: 'Document is uploaded successfully!',
                            timeout: 5000,
                            icon: 'icon-check'
                        });
                        notification.init();
                        
                        this.setState({
                            openDialogUploadBar: false,
                            done: false,
                            uploadedDocs: uploadedFiles,
                            isLoading: true

                        });
                        this.addWorkplanDocs();
                    }).catch(error => {
                        console.error(error);;
                    });
                });
            }
        }
    }

    initiateEditDocument = (cellData) => {
        this.setState({
            edit: true,
            editId: cellData,
            editTitle: "",
            editType: ""
        });
        var id = cellData;
        
        const wp = { ...this.props.workplanData.workplan };
        const docs = [...this.props.workplanData.workplan.workplanDocs];
        this.props.setWorkplan({});
        var d = docs.find(item => item.id == id);
        d["edit"] = true;
        d["editId"] = id;
        d["documentTypeName"] = PageHelper.mapDocumentTypes(d.documentType);
        wp['workplanDocs'] = docs;
        this.props.setWorkplan(wp);
    }

    deleteDocument = () => {
        const selectedWorkplan = this.props.workplanData.filteredWorkplans.data.find(item => item.id === this.props.workplanData.workplan.id);
        var d = this.state.selectedDoc
        
        d['delete'] = true;
        d.lastModifiedUser = parseInt(this.props.userData.userData.id);
        this.setState({ isLoading: true, clicked: false }, () => {
            this.props.setWorkplan({});
            ApiClient.post("Workplan/UpdateWorkplanDocument", d)
                .then(response => {
                    this.setState({ isLoading: false }, () => {
                        const workplan = { ...response.data };
                        workplan["customer"] = { ...selectedWorkplan.customer };
                        WorkplanHelper.mapWorkplan(workplan);
                        
                        this.props.setWorkplan(workplan);
                        const notification = new Notification({
                            title: 'Document is deleted successfully!',
                            timeout: 5000,
                            icon: 'icon-check'
                        });
                        notification.init();
                    });
                })
                .catch(error => {
                    alert(error);
                    this.setState({ isLoading: false });
                });
        });
    }

    initiateDelete = (cellData) => {
        if (!this.state.clicked) {
            this.setState({ clicked: true })
            const wp = { ... this.props.workplanData.workplan };
            const docs = [... this.props.workplanData.workplan.workplanDocs];
            
            var d = docs.find(item => item.id == cellData);
            
            this.setState({
                selectedDoc: d
            });
            document.querySelector("#deleteCell_").click();

        }
    }

    addWorkplanDocs = () => {
        if (!this.state.uploadedDocs.length) {
            const notification = new Notification({
                title: 'Upload Failed',
                description: 'No selected files to be uploaded!',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        const docs = this.state.uploadedDocs.map(item => {
            return {
                title: item.title,
                workplanId: parseInt(this.props.workplanData.workplan.id),
                lastModifiedUser: parseInt(this.props.userData.userData.id),
                documentPath: item.documentPath,
                documentType: parseInt(item.documentType),
                systemId: parseInt(this.props.workplanData.workplan.id),
                objectTypeId: 3
            }
        })
        const selectedWorkplan = this.props.workplanData.filteredWorkplans?.data.find(item => item.id === this.props.workplanData.workplan.id);
        ApiClient.post("Workplan/AddWorkplanDocuments", docs)
            .then(response => {
                this.setState({ isLoading: false }, () => {
                    const workplan = { ...response.data };
                    workplan["customer"] = { ...selectedWorkplan?.customer };
                    WorkplanHelper.mapWorkplan(workplan);
                    this.props.setWorkplan(workplan);
                    this.props.setDocuments([]);
                });
            })
            .catch(error => {
                console.error(error);;
                this.setState({ isLoading: false });
            });
    }

    render() {
        let documents = this.props.workplanData.workplan.workplanDocs?.map(e => { return { ...e, deleteId: e.id, editId: e.id, documentTypeId: e.id + "@" + PageHelper.mapDocumentTypes(e.documentType), documentNameId: e.title + "-@-" + e.id } });
        const documentTypes = constants.cmsDocumentTypes;
        const workplanDocumentColumns = [
            {
                title: "Name",
                key: "documentNameId",
                sort: "none",
                width: "30%",
                hideFilter: this.state.edit ? true : false,
                onCreatedCell: (td, cellData) => {
                    var docId = cellData.split("-@-")[1]
                    var document = documents.find(d => d.id == docId);
                    const cellDataName = document.title;
                    const cellDataLink = document.documentPath;
                    if (this.state.edit && document && document.edit && document.editId == docId && this.state.editId == docId) {
                        let documentName = "";
                        if (this.state.editTitle) {
                            documentName = this.state.editTitle;
                        }
                        else {
                            documentName = document.title
                        }
                        td.innerHTML = `<span id = "updateTitle_"><input style = "width: 90%;" type="text" id="updateTitle_" value="${documentName}" ></input>`;
                        td.querySelector("#updateTitle_").addEventListener('change', (e) => this.update(e, "title"));
                    }
                    else {
                        td.innerHTML = `<a id="workplanDoc_${cellData.id}">${cellDataName}</a>`;
                        td.querySelector("#workplanDoc_" + cellData.id).addEventListener('click', () => window.open(SasHelper.addSasKey(cellDataLink, this.props.sasToken), "_blank"))
                    }
                }
            },
            {
                title: "Document Type",
                key: "documentTypeId",
                width: "20%",
                sort: "none",
                hideFilter: this.state.edit ? true : false,
                onCreatedCell: (td, cellData) => {
                    if (cellData) {
                        var docId = cellData.substring(0, cellData.indexOf("@"));
                        var document = documents.find(doc => doc.id == docId);
                        if (this.state.edit && document && document.edit && document.editId == docId && this.state.editId == docId) {
                            var selectedItem = "";
                            var optionsList = "";
                            var option = "";
                            var value = document.documentTypeName;
                            documentTypes.map(item => {
                                if (item.text.trim() === value.trim()) {
                                    selectedItem = item.text;
                                    option = `<option value="${selectedItem}" selected>${item.text}</option>`
                                }
                                else {
                                    option = `<option value="${item.text}">${item.text}</option>`
                                }
                                optionsList += option
                            })
                            td.innerHTML = `<span><select style = "width: 200px; font-height: 400px; border: 1px solid #878787; line-height: 1.5; padding: 5px 7px 4px; margin-right: 8px; font-weight: 400; font-size: 14px; background: #fff; color: #242424; font-family: Ericsson Hilda; name="selectDialog" id="updateType_" value="${selectedItem}"> ${optionsList}</select>`;
                            td.querySelector("#updateType_").addEventListener('change', (e) => this.update(e, "type"));
                        }
                        else {
                            td.innerHTML = PageHelper.mapDocumentTypes(document.documentType);
                        }
                    }
                }
            },
            {
                title: "Last Modified Date",
                key: "updatedAt",
                sort: "none",
                width: "15%",
                hideFilter: this.state?.edit ? true : false,
                onCreatedCell: (td, cellData) => {
                    
                    td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm");
                }
            },
            {
                title: "Last Modified User",
                key: "lastModifiedUserName",
                width: "15%",
                sort: "none",
                hideFilter: this.state?.edit ? true : false,
            },
            {
                title: "",
                key: "editId",
                hideFilter: true,
                onCreatedCell: (td, cellData) => {
                    if (cellData != null) {
                        var document = documents.find(doc => doc.id == cellData);
                        
                        
                        if (this.state.edit && document.editId == cellData && this.state.editId == cellData) {
                            td.innerHTML = `<span id="save_" class="tooltip"><i id="save_" class="icon icon-check text-lg clickable color-green "></i><span class="message top-end">Save</span></span>`;
                            td.querySelector("#save_").addEventListener('click', () => this.save(cellData));
                        }
                        else if (this.props.userData.userData.jobRole === "1" || this.props.userData.userData.jobRole === "0") {
                                td.innerHTML = `<span id="editCell_" class="tooltip"><i id="editCell_" style={font-weight:500px;} class="icon icon-edit color-black text-lg clickable "></i><span id="editCell_" class="message top-end">Edit</span></span>`;
                                td.querySelector("#editCell_").addEventListener('click', () => this.initiateEditDocument(cellData))
                        }
                        else {
                            td.innerHTML = ""
                        }
                    }
                },
                width: "2%",
            },
            {
                title: "",
                key: "deleteId",
                hideFilter: true,
                onCreatedCell: (td, cellData) => {
                    if (cellData) {
                        var document = documents.find(doc => doc.id == cellData);
                        
                        if (this.state.edit && document.id == cellData && this.state.editId == cellData) {
                            
                            td.innerHTML = `<span id="cancel_" class="tooltip"><i id="cancel_" class="icon icon-cross text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>`;
                            td.querySelector("#cancel_").addEventListener('click', () => this.cancel(cellData));
                        }
                        else if ( this.props.userData.userData.jobRole === "1" || this.props.userData.userData.jobRole === "0") {
                            td.innerHTML = `<span id="deleteCell_" class="tooltip"><i id="deleteCell_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable "></i><span class="message top-end">Delete Document</span></span>`;
                            td.querySelector("#deleteCell_").addEventListener('click', () => this.initiateDelete(cellData))
                        }
                        else {
                            td.innerHTML = ""
                        }
                    }
                },
                width: "2%",
            },

        ]
        return (<EuiRow key={this.props.workplanData.workplan.workplanDocs} disabled={this.state.isLoading ? "disabled" : ""} >
            <EuiLoading id="workplanDocumetsLoading" isLoading={this.state.isLoading} />
            {(!documents ?
                <div className="flex-middle-content m-3 w-10 text-lg">Please click on filter to get a list of Resources</div>
                :
                <>
                    <ObseleteEuiTable
                        classes="tiny"
                        key={this.props.workplanData.workplan + this.props.workplanData.workplan.workplanDocs}
                        columns={workplanDocumentColumns}
                        data={this.sortByKey(documents, "updatedAt")}
                        id="workplanDocumentsTable"
                        viewActionButton={true}
                        actionButton={
                            <button hidden={this.props.userData.userData.jobRole === "1" || this.props.userData.userData.jobRole === "0" ? false : true} id="addDocument" className="btn primary ml-10" onClick={this.addDocument}>{<span > <i class="icon icon-file-add p-1"></i> Upload Document</span>}</button>
                        }
                        hideSearch={true}
                        paginated={true}
                        rowsPerPage={10}
                        sortable={true}
                        filter={true}
                    >
                    </ObseleteEuiTable>
                    <EuiDialog
                        id="workplanAddDocumentDialog"
                        dataTrigger="#addDocument"
                        title="Add Documents"
                        type="primary"
                        body={<UploadDocuments id="documentDialog" dispatch="SET_DOCUMENTS" columns={{ name: true, type: true }} />}
                        buttonName="Upload"
                        onCancel={this.onCancel}
                        onClick={this.uploadDocuments}
                        contentStyle={{ width: "800px" }}
                        bodyStyle={{ height: "400px" }}
                        Done={this.state.done}
                        disableBackdropClick={true}
                    />
                    <DialogModal
                        content={this.state.percentageArr.map(file => {
                            return <div key={file.name} style={{ width: 500 }}>
                                <label><strong>{file.title}</strong></label>
                                <EuiProgressBar value={file.percentage} />
                            </div>
                        })}
                        handleClose={() => this.setState({ openDialogUploadBar: false })}
                        id="uploadBarSessionApproveDialog"
                        titleId="titleuploadBarSessionApproveDialog"
                        open={this.state.openDialogUploadBar}
                        buttonsVisible="false"
                        title="Progress Loading..."
                        closeButtonVisible="false"
                        disableBackdropClick={true}
                    >
                    </DialogModal>
                    {(documents && documents.length && (document.querySelector("#deleteCell_")) ?
                        <>
                            <EuiDialog
                                id="deleteWorkplanAddDocumentDialog"
                                dataTrigger="#deleteCell_"
                                title="Delete Document"
                                body={<div>are you sure you want to delete this document? </div>}
                                buttonName="Delete"
                                type="btn warning"
                                onCancel={this.onCancel}
                                onClick={this.deleteDocument}
                                disableBackdropClick={true}
                            />
                        </>

                        : null)
                    }
                </>)}
        </EuiRow >
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, workplanData: state.workplan, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        setWorkplan: (workplan) => dispatch({ type: "SET_WORKPLAN", payload: workplan }),
        setDocuments: (docs) => dispatch({ type: "SET_DOCUMENTS", payload: docs }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WorkplanDocuments);