import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import EuiLoading from "../../eui-components/loading";
import EuiTabs from "../../eui-components/tabs";
import EuiTile from "../../eui-components/tile";
import constants from "../../utils/constants";
import ApiClient from "../../utils/api-client";
import ProjectInfo from './ProjectInfo.js';
import ErisiteInfo from './ErisiteInfo.js'
import ProjectRepresentatives from './ProjectRepresentatives.js';
import ProjectsHelper from "../../utils/project-helper";
import PageHelper from "../../utils/page-helper";
import ProjectDocuments from './projectDocuments'
import CommonHelper from "../../utils/common-helper";

const ProjectDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const userData = useSelector(state => state.user);
    const Project = useSelector(state => state.projects);
    const dispatch = useDispatch();
    const [editMod, setEditMod] = useState(constants.actionMods.VIEW_ONLY);
    const project = Project.project;
    const user = userData.userData;
    const [contentUpdated, setContentUpdated] = useState(false);
    var content = []
    const getRightButton = () => {
        if (editMod === constants.actionMods.VIEW_ONLY) {
            return (<>
                <div>
                    <button style={{ float: "right", margin: "10px" }} className={"btn primary m-1 " + (isLoading ? "loading  disabled" : "")} onClick={() => setEditMod(constants.actionMods.EDIT)}>
                        <i className="icon icon-edit p-1" />Edit Project</button></div>
            </>)

        } else if (editMod === constants.actionMods.EDIT) {
            return (<div>
                <button style={{ float: "right" }} className={"btn primary m-1 "} onClick={() => saveProject()}><i className="icon icon-check p-1" />Save</button>
                <button style={{ float: "right" }} className={"btn m-1"} onClick={cancelEdit}><i className="icon icon-cross p-1" />Cancel</button>
            </div>)
        }
    }

    useEffect(() => {
        if ((contentUpdated === true) && (user.jobRole === constants.userRoles.ADMIN || user.jobRole === constants.userRoles.ERICSSON_IM)) {
            getRightButton();
        }
    }, [contentUpdated])

    const saveProject = () => {
        if (project.name == "" || !project.countryId || !project.customerId) {
            toast.error("Please, fill the mandatory fields!", { containerId: "PD", position: toast.POSITION.TOP_CENTER });
            return;
        }
        setEditMod(constants.actionMods.VIEW_ONLY);
        createEditProject();
        setIsLoading(true);
    }

    const cancelEdit = () => {
        dispatch({ type: "SET_PROJECT", payload: project });
        setEditMod(constants.actionMods.VIEW_ONLY)
    }

    const createEditProject = () => {
        const UpdatedProject = Project.project;
        const oldProject = Object.values(Project.allProjects?.data).find(x => x.id == UpdatedProject.id);
        let visitor = CommonHelper.getVisitorData();
        UpdatedProject.logInfo = {
            userId: parseInt(userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: Object.entries(oldProject).reduce((a, b) => {
                return a + b.join(" : ") + "    |    ";
            }, ""),
            userLogin: {
                nameOrMail: userData.userMail,
                jobRole: userData.loginRole,
                password: userData.password
            }
        }
        setIsLoading(true);
        ApiClient.post("Projects/CreateEditProject", UpdatedProject).then(response => {
            if (response) {
                ApiClient.post("Projects/GetAllProjects").then(response => {
                    let projects = response.data.data;
                    ProjectsHelper.mapProjects(projects, userData.countries)
                    dispatch({ type: "SET_ALL_PROJECTS", payload: { data: projects, totalCount: response.data.totalCount } });
                    ProjectsHelper.mapEditedProject(UpdatedProject, userData.countries);
                    dispatch({ type: "SET_PROJECT", payload: UpdatedProject });
                    setIsLoading(false)
                    toast.success(constants.processPozitiveMessage, { containerId: "PD", position: toast.POSITION.TOP_CENTER });
                }).catch(error => console.error(error))
            }
        }).catch(error => console.error(error.response.data))
    }

    const updateContentArray = () => {
        const viewContent = [{
            id: 1,
            content: <ProjectInfo mod={editMod} />,
            selected: "selected"
        },
        {
            id: 2,
            content: <ErisiteInfo />,
        },
        {
            id: 3,
            content: < ProjectRepresentatives />
        },
        {
            id: 4,
            content: <ProjectDocuments />
        }
        ]
        viewContent.map((item) => {
            content.push({ value: item.id, children: item.content, selected: item.selected, contentStyle: { width: "100%" } })
        });
        if (contentUpdated === false) {
            setContentUpdated(true)
        }
        return (content);
    }

    return (
        <EuiTile fullscreen tileStyle={{ padding: 0 }} className={isLoading ? " disabled " : ""} >
            <EuiLoading id="projectDetailsLoading" isLoading={isLoading} />
            <div className="right-panel">
                <div className="right">
                    {(contentUpdated && (user.jobRole === constants.userRoles.ADMIN || user.jobRole === constants.userRoles.ERICSSON_IM) ? getRightButton() : null
                    )}
                </div>
            </div>
            <div className="w-10">
                <EuiBreadcrumb id="projectBreadcrumb"
                    parentName="Home"
                    childName={project.name}
                    onClickParent={() => dispatch({ type: "CHANGE_PROJECT_MOD", payload: constants.pageMods.LIST })}
                    className="p-2" />
            </div>
            <EuiLoading id="projectSearchLoading" isLoading={isLoading} />
            <EuiTabs
                titlesColumnStyle={{ paddingLeft: "15px", paddingTop: "40px", marginBottom: "10px" }}
                contentStyle={{ width: "100%" }}
                id="projectDetailsTabs"
                titles={[{ id: 1, value: 1, name: "Project information", selected: "selected", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } },
                { id: 2, value: 2, name: "Project Erisite Information", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } },
                { id: 3, value: 3, name: "Project Teams", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } },
                { id: 4, value: 4, name: "Project Documents", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } }
                ]}
                contents={updateContentArray()}
                tabStyle={{ fontWeight: "500px", width: "100%", color: "black" }}
            />
            <ToastContainer enableMultiContainer containerId={'PD'} autoClose={2500} />
        </EuiTile>)
}

export default withRouter(ProjectDetails);