import React from "react";
import { Dropdown } from "@eds/vanilla/dropdown/Dropdown";

class EuiDropdownMore extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
         if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new Dropdown(element);
                    component.init();                    
                });
            }
        }
        else {
            console.error("You should set an id to Dropdown component");
        }        
    }
    render() {
        return (
            <>
                <div id={this.props.id} className={"dropdown more " + (this.props.className ?? "")} data-type="click" style={{ ...this.props.style }}>
                    <button className="btn clickable" style={{...this.props.buttonStyle}}onClick={() => this.props.onClick && this.props.onClick()}><i style={{ ...this.props.styleIcon }} className="icon icon-more"></i></button>
                    <div className="menu right" style={{ minWidth:100  , ...this.props.styleMenu }}>
                        {this.props.instances && this.props.instances.map(i => (
                            <div key={i.id}                                              
                                 className="item"
                                 disabled={i.disabled}                                 
                                 onClick={() => i.onClick && i.onClick()}
                                 style={i.style}
                                 tabIndex="0"> {i.text}
                            </div>
                            ))
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default EuiDropdownMore;
