import React from "react";
import { FileUploader } from "react-drag-drop-files"; // refactor this element

const DragAndDropFileUploader = (props) => {

    return <FileUploader
        handleChange={props.handleChange}
        name={props.name}
        hoverTitle={props.hoverTitle}
        children={props.children}
        multiple
    />
}
export default DragAndDropFileUploader;