import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ApiClient from "../../utils/api-client";
import ComplaintsList from './complaintsList';
import ApiHelper from '../../utils/api-helper';
import constants from "../../utils/constants";
import ComplaintViewDetails from "./complaintViewDetails";
import ComplaintDetails from "./complaintDetails"

class Complaints extends React.Component {
    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        ApiHelper.setCountriesWithDetails((data) => this.props.setCountriesList(data), (data) => this.props.setCustomersList(data));
        if (this.props.complaintScopes?.length == 0) {
            ApiClient.post("UserAdmin/GetComplaintsScopes").then(response => {
                if (response.data) {
                    this.props.addComplaintScopes(response.data);
                }
            }).catch(err => console.error(err.response.data));
        }
        if (this.props.complaintTypes?.length == 0) {
            ApiClient.post("UserAdmin/GetComplaintsTypes").then(response => {
                if (response.data) {
                    this.props.addComplaintTypes(response.data);
                }
            }).catch(err => console.error(err.response.data));
        }
        if (this.props.aspContacts?.length == 0) {
            ApiClient.post("UserAdmin/GetAspContactsList").then(response => {
                if (response.data) {
                    
                    this.props.setAspContacts(response.data);
                }
            }).catch(err => console.error(err.response.data));
        }
    }
    render() {
        if (this.props.mod == constants.pageMods.ADD) {
            return <ComplaintDetails />
        }
        else if (this.props.mod == constants.pageMods.VIEW_ONLY) {
            return <ComplaintViewDetails />
        }
        else if (this.props.mod == constants.pageMods.RESOLVE) {
            return <ComplaintDetails mod={constants.pageMods.RESOLVE}/>
        }
        else {
            return <ComplaintsList />

        }
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, complaintTypes: state.complaints.complaintTypes, complaintScopes: state.complaints.complaintScopes, resourceUsers: state.complaints.resourceUsers, aspContacts: state.complaints.aspContacts, mod: state.complaints.pageMod });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        addComplaintScopes: (complaintScopes) => dispatch({ type: "ADD_COMPLAINT_SCOPES", payload: complaintScopes }),
        addComplaintTypes: (complaintTypes) => dispatch({ type: "ADD_COMPLAINT_TYPES", payload: complaintTypes }),
        setCountriesList: (countries) => dispatch({ type: "SET_ALL_COUNTRIES", payload: countries }),
        setCustomersList: (customers) => dispatch({ type: "SET_ALL_CUSTOMERS", payload: customers }),
        addResourceUsers: (resourceUsers) => dispatch({ type: "ADD_RESOURCE_USERS", payload: resourceUsers }),
        setAspContacts: (aspContacts) => dispatch({ type: "SET_ASP_CONTACTS", payload: aspContacts })
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Complaints));