import React, { useEffect, useState } from "react";
import EuiVerticalBarChart from "../../../../eui-components/verticalBarChart";
import EuiRow from "../../../../eui-components/row";
import DateUtils from "../../../../utils/date-utils";

const PerformanceTrend = (props) => {

    var selected = props.selected;
    const [viewType, setViewType] = useState(selected ? 2 : null);

    var dates = [];
    if (props.data && props.data.length) {
        props.data.map(item => {
            if (dates.indexOf(item.actualEnd) === -1)
                dates.push(item.actualEnd);
        });
    }
    dates = dates.sort(function (a, b) { return a.localeCompare(b); });
    var dailyDates = dates?.map(item => item.slice(5, 10));

    var ftrs = dates?.map(item => props.data.filter(site => site.actualEnd === item && site.ftrStatus == "FTR")?.length);
    var noSnags = dates.map(item => {
        var items = props.data.filter(site => site.actualEnd === item && site.noSnag);
        var snaggedItems = items?.map(i => isNaN(parseInt(i.noSnag)) ? 0 : parseInt(i.noSnag));
        var numberOfSnags = snaggedItems?.reduce((a, b) => a + b, 0);
        return numberOfSnags;
    })

    var readyForCA = dates?.map(item => props.data.filter(site => site.actualEnd === item && site.readyForCAStatus == "Ready For Acceptance")?.length);
    var teamEfficiency = dates?.map(item => props.data.filter(site => site.actualEnd === item && site.efficient)?.length);
    var qualityIndex = dates?.map(item => props.data.filter(site => site.actualEnd === item && site.ehsStatus <= 0.5)?.length);
    var allSites = dates?.map(item => props.data.filter(site => site.actualEnd === item)?.length);

    var weeklyDates = [];

    var ftrsWeekly = [];
    var noSnagsWeekly = [];
    var readyForCAWeekly = [];
    var teamEfficiencyWeekly = [];
    var qualityIndexWeekly = [];
    var allSitesWeekly = [];


    const groupedDatesByWeek = DateUtils.groupByWeek(dates);

    Object.keys(groupedDatesByWeek).forEach(o => {
        weeklyDates.push(o);
        var dateRange = groupedDatesByWeek[o];
        ftrsWeekly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd) && site.ftrStatus == "FTR")?.length);
        var itemsSnags = props.data.filter(site => dateRange?.includes(site.actualEnd) && site.noSnag);
        var snaggedItems = itemsSnags?.map(i => isNaN(parseInt(i.noSnag)) ? 0 : parseInt(i.noSnag));
        var numberOfSnags = snaggedItems?.reduce((a, b) => a + b, 0);
        noSnagsWeekly.push(numberOfSnags);
        readyForCAWeekly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd) && site.readyForCAStatus == "Ready For Acceptance")?.length);
        teamEfficiencyWeekly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd) && site.efficient)?.length);
        qualityIndexWeekly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd) && site.ehsStatus <= 0.5)?.length);
        allSitesWeekly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd))?.length);
    });


    var monthlyDates = [];

    var ftrsMonthly = [];
    var noSnagsMonthly = [];
    var readyForCAMonthly = [];
    var teamEfficiencyMonthly = [];
    var qualityIndexMonthly = [];
    var allSitesMonthly = [];

    const groupedDatesByMonth = DateUtils.groupByMonth(dates);
    Object.keys(groupedDatesByMonth).forEach(o => {
        monthlyDates.push(o);
        var dateRange = groupedDatesByMonth[o];
        ftrsMonthly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd) && site.ftrStatus == "FTR")?.length);
        var itemsSnags = props.data.filter(site => dateRange?.includes(site.actualEnd) && site.noSnag);
        var snaggedItems = itemsSnags?.map(i => isNaN(parseInt(i.noSnag)) ? 0 : parseInt(i.noSnag));
        
        var numberOfSnags = snaggedItems?.reduce((a, b) => a + b, 0);
        noSnagsMonthly.push(numberOfSnags);
        readyForCAMonthly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd) && site.readyForCAStatus == "Ready For Acceptance")?.length);
        teamEfficiencyMonthly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd) && site.efficient)?.length);
        qualityIndexMonthly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd) && site.ehsStatus <= 0.5)?.length);
        allSitesMonthly.push(props.data?.filter(site => dateRange?.includes(site.actualEnd))?.length);

    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case "daily": {
                setViewType(1);
            }
                break;
            case "weekly": {
                setViewType(2)
            } break;
            case "monthly": {
                setViewType(3)
            } break;
            default: {
                setViewType(2);
            }
                break;
        }

    }

    const ftrData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [
            { "name": "Accepted", values: viewType == 1 ? allSites : viewType == 3 ? allSitesMonthly : allSitesWeekly },
            { "name": "FTR", "values": viewType == 1 ? ftrs : viewType == 3 ? ftrsMonthly : ftrsWeekly }
        ]
    };

    const snagsData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [{ "name": "Number Of Snags", "values": viewType == 1 ? noSnags : viewType == 3 ? noSnagsMonthly : noSnagsWeekly }
        ]
    };

    const caData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [
            {
                "name": "Accepted", values: viewType == 1 ? allSites : viewType == 3 ? allSitesMonthly : allSitesWeekly
            },
            { "name": "Ready For Customer Acceptance", "values": viewType == 1 ? readyForCA : viewType == 3 ? readyForCAMonthly : readyForCAWeekly }
        ]
    };

    const efficiencyData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [
            { "name": "Accepted", values: viewType == 1 ? allSites : viewType == 3 ? allSitesMonthly : allSitesWeekly },
            { "name": "On Time Delivery", values: viewType == 1 ? teamEfficiency : viewType == 3 ? teamEfficiencyMonthly : teamEfficiencyWeekly }]
    };

    const qIndexData = {
        "common": viewType == 1 ? dailyDates : viewType == 3 ? monthlyDates : weeklyDates,
        "series": [
            { "name": "Accepted", values: viewType == 1 ? allSites : viewType == 3 ? allSitesMonthly : allSitesWeekly },
            { "name": "Quality Index <= 0.4", values: viewType == 1 ? qualityIndex : viewType == 3 ? qualityIndexMonthly : qualityIndexWeekly }
        ]
    };

    return (<EuiRow className="w-10" key={props.data || viewType || selected}>
        <EuiRow className="mt-2 mb-2">
            <input id="monthly" name="monthly" type="radio" value={viewType === 3} checked={viewType === 3} onChange={(e) => handleChange(e)} />
            <label className="mr-2" style={{ color: "gray", fontSize: "14px" }} for="monthly">Monthly</label>
            <input id="weekly" name="weekly" type="radio" value={viewType === 2} checked={viewType === 2 || !viewType} onChange={(e) => handleChange(e)} />
            <label className="mr-2" style={{ color: "gray", fontSize: "14px" }} for="weekly">Weekly</label>
            <input id="daily" name="daily" type="radio" value={viewType === 1} checked={viewType === 1} onChange={(e) => handleChange(e)} />
            <label className="mr-2" style={{ color: "gray", fontSize: "14px" }} for="daily">Daily</label>
        </EuiRow>
        <EuiRow key={viewType || props.selected} style={{ overflowY: "scroll", maxHeight: "600px" }}>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id="ftrPerformanceTrendBarChart" key={viewType} data={ftrData} height={200} unit="Site Activity" />
            </EuiRow>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id="efficiencyPerformanceTrendBarChart" key={viewType} data={efficiencyData} unit="Site Activity" height={200} />
            </EuiRow>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id="caPerformanceTrendBarChart" key={viewType} data={caData} unit="Site Activity" height={200} />
            </EuiRow>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id="snagsPerformanceTrendBarChart" applyColorScale={true} key={viewType} data={snagsData} unit="Snag" height={200} />
            </EuiRow>
            <EuiRow className="w-10 mt-3" style={{ marginBottom: "30px" }}>
                <EuiVerticalBarChart id="qIndexPerformanceTrendBarChart" key={viewType} data={qIndexData} unit="Site Activity" height={200} />
            </EuiRow>
        </EuiRow>
    </EuiRow>);
}
export default PerformanceTrend;