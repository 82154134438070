const ResolutionReducer = (state, action) => {
    switch (action.type) {
        case "SET_VIOLATED_ITEMS":
            return {
                ...state,
                violatedItems: action.payload,
            }
        case "SET_SELECTED_ROW":
            return {
                ...state,
                selectedRow: action.payload,
            }
        case "SET_COMMENT":
            return {
                ...state,
                comment: action.payload,
            }

        default:
            return state;
    }
};

export default ResolutionReducer;