import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import PageHelper from "../../utils/page-helper";
import EuiTile from "../../eui-components/tile";
import EuiTextArea from "../../eui-components/textarea";


class ActivityErisiteInfo extends React.Component {

    render() {
        return (<EuiRow className="scrollable">
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityErisiteWorkplanId" name="erisiteWorkplanId" label="Erisite Workplan ID" value={this.props.activityData.activity.erisiteWorkplanId} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityErisitePlanningObjectId" name="erisitePlanningObjectId" label="Erisite Planning Object ID" value={this.props.activityData.activity.erisitePlanningObjectId} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activitySystemRecordid" name="systemrecordid" label="System Record ID" value={this.props.activityData.activity.systemrecordid || this.props.activityData.activity.externalSystemRecordId } />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityErisiteStatus" name="erisiteStatus" label="Erisite Status" value={this.props.activityData.activity.erisiteStatus} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityerisiteUpdateDatetime" name="erisiteUpdateDatetime" label="Erisite Update Datetime" value={DateUtils.toDateString(this.props.activityData.activity.erisiteUpdateDatetime, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)"} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityAssignedGroup" name="assignedGroup" label="Assigned Group" value={this.props.activityData.activity.assignedGroup} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityAssignedIndividual" name="assignedIndividual" label="Assigned Individual" value={this.props.activityData.activity.assignedIndividual} />        
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }}
                id="activityerisitePlannedStart"
                name="erisitePlannedStart"
                label="Erisite Planned Start"
                value={DateUtils.toDateString(this.props.activityData.activity.erisitePlannedStart, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)"} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }}
                id="activityerisitePlannedEnd"
                name="erisitePlannedEnd"
                label="Erisite Planned End"
                value={DateUtils.toDateString(this.props.activityData.activity.erisitePlannedEnd, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)"} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }}
                id="activityerisiteForecastStart"
                name="erisiteForecastStart"
                label="Erisite Forecast Start"
                value={DateUtils.toDateString(this.props.activityData.activity.erisiteForecastStart, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)"} />
            <EuiTextField className="p-1 w-7" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }}
                id="activityerisiteForecastEnd"
                name="erisiteForecastEnd"
                label="Erisite Forecast End"
                value={DateUtils.toDateString(this.props.activityData.activity.erisiteForecastEnd, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)"} />
            <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }}
                id="activityerisiteActualStart"
                name="erisiteActualStart"
                label="Erisite Actual Start"
                value={DateUtils.toDateString(this.props.activityData.activity.erisiteActualStart, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)"} />
            <EuiTextField className="p-1 w-7" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }}
                id="activityerisiteActualEnd"
                name="erisiteActualEnd"
                label="Erisite Actual End"
                value={DateUtils.toDateString(this.props.activityData.activity.erisiteActualEnd, "YYYY-MM-DD HH:mm:ss", true) || "(-----,----)"} />
            <EuiTile title="Eritop & Erisite Integration Information" tileStyle={{ paddingLeft: 5 }} headerStyle={{ paddingBottom: 5 }}>
                <EuiRow>
                    <EuiTextField className="p-1  pl-0 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityErisiteIntegrationStatus" name="erisiteIntegrationStatus" label="Erisite Integration Status" value={PageHelper.getYesNo(this.props.activityData.activity.integrationStatus)} />
                    <EuiTextField className="p-1  pl-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityerisiteIntegationDate" name="erisiteIntegrationDate" label="Erisite Integration Date" value={DateUtils.toDateString(this.props.activityData.activity.integrationDate, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />
                    <EuiTextField className="p-1  pl-2 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityerisiteIntegationUser" name="erisiteIntegrationUser" label="Erisite Integration User" value={this.props.activityData.activity.integrationUserName} />
                    <EuiTextArea className="p-1 pl-0 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} style={{ minHeight: 100 }} id="activityErisiteIntegrationOutput" name="integrationOutput" label="Erisite Integration Output" value={this.props.activityData.activity.integrationOutput} />
                </EuiRow>
            </EuiTile>
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, activityData: state.activity });
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityErisiteInfo); 