import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EuiDatepicker from "../../eui-components/datepicker";
import FormField from "../../eui-components/formfield";
import EuiRow from "../../eui-components/row";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import EuiSwitch from "../../eui-components/switch";
import EuiTextField from "../../eui-components/textfield";
import constants from "../../utils/constants";
import DateUtils from "../../utils/date-utils";

const ProjectInfo = (props) => {
    const userData = useSelector(state => state.user);
    const Project = useSelector(state => state.projects);
    const dispatch = useDispatch();
    const [countryName, setCountry] = useState(Project.project?.countryName ?? "");
    const [customerName, setCustomer] = useState(Project.project?.customerName ?? "");
    const [countries, setCountries] = useState(userData.countries);
    const [customers, setCustomers] = useState(userData.customers);
    const [contactPerson, setContactPerson] = useState("");
    const [customerId, setCustomerId] = useState();
    const [countryId, setCountryId] = useState();
    const [projectName, setProjectName] = useState("");
    const project = { ...Project.project };
    const handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const text = target.text;
        const name = target.name;
        const checked = target.checked;
        if (props.mod === constants.actionMods.CREATE) {
            if (name == "name") {
                project["name"] = value;
            }
            else if (name == "countryNameSelect") {
                project["countryId"] = value;
                setCustomers(countries.find(x => x.value === value)?.customers ?? userData.customers)
            }
            else if (name == "customerNameSelect") {
                project["customerId"] = value;
            }
            else if (name == "contactPerson") {
                project["contactPerson"] = value;
            }
            dispatch({ type: "SET_PROJECT", payload: project });
        }
        else {
            if (name == "name") {
                project["name"] = value;
                setProjectName(value)
            }
            else if (name == "countryNameSelect") {
                setCustomers(countries?.find(x => x.value === value)?.customers ?? userData.customers)
                setCountry(text);
                setCountryId(value);
                project["countryId"] = value;
            }
            else if (name == "customerNameSelect") {
                setCustomer(text)
                setCustomerId(value)
                project["customerId"] = value;
            }

            else if (name == "status") {
                if (checked) {
                    project["active"] = true;
                }
                else {
                    project["active"] = false;
                }
            }
            else if (name == "contactPerson") {
                project["contactPerson"] = value;
                setContactPerson(value)
            }
            else if (name == "plannedStartDate") {
                project["plannedStartDate"] = value;
            }
            else if (name == "plannedEndDate") {
                project["plannedEndDate"] = value;
            }
            else {
                project[name] = value;
            }
            dispatch({ type: "SET_PROJECT", payload: project });
        }
    }
    
    return (
        <EuiRow>
            {(props.mod === constants.actionMods.VIEW_ONLY) ?
                <EuiRow style={{ paddingLeft: "20px", paddingTop: "30px" }}>
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly={props.mod === constants.actionMods.VIEW_ONLY}
                        required
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="projectNameView"
                        name="name"
                        label="Project Name*"
                        value={Project.project?.name || ""} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly={props.mod === constants.actionMods.VIEW_ONLY}
                        required
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="projectCountryNameView"
                        name="countryName"
                        label="Country Name*"
                        value={countryName || ""}
                    />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly={props.mod === constants.actionMods.VIEW_ONLY}
                        required
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="projectCustomerNameView"
                        name="customerName"
                        label="Customer Name*"
                        value={customerName || ""}
                    />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly={props.mod === constants.actionMods.VIEW_ONLY}
                        required
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="projectStatusView"
                        name="status"
                        label="Project Status"
                        value={Project.project?.active ? "Active" : "Not active" || ""}
                        onChange={(e) => handleChange(e)} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly={props.mod === constants.actionMods.VIEW_ONLY}
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        style={{ width: "350px", paddingRight: 0, paddingLeft: 0, paddingBottom: 0, fontSize: "16px" }}
                        id="ProjectContactPersonView"
                        name="contactPerson"
                        label="Contact Person"
                        value={Project.project?.contactPerson || ""}
                        onChange={(e) => handleChange(e)} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly={props.mod === constants.actionMods.VIEW_ONLY || props.mod === constants.actionMods.EDIT}
                        required
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="ProjectCreatedAtView"
                        name="createdAt"
                        label="Creation date"
                        value={DateUtils.toDateString(Project.project?.createdAt, "YYYY-MM-DD HH:mm") || ""}
                        onChange={(e) => handleChange(e)} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly={props.mod === constants.actionMods.VIEW_ONLY}
                        required
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="ProjectPlannedStartDateView"
                        name="plannedStartDate"
                        label="Planned Start Date"
                        value={DateUtils.toDateString(Project.project?.plannedStartDate, "YYYY-MM-DD HH:mm") || ""}
                        onChange={(e) => handleChange(e)} />
                    <EuiTextField
                        className="p-1 w-5"
                        fullwidth
                        readOnly={props.mod === constants.actionMods.VIEW_ONLY}
                        required
                        style={{ padding: 0, width: "350px", fontSize: "16px" }}
                        labelstyle={{ color: "gray" }}
                        id="ProjectPlannedEndDateView"
                        name="plannedEndDate"
                        label="Planned End Date"
                        value={DateUtils.toDateString(Project.project?.plannedEndDate, "YYYY-MM-DD HH:mm") || ""}
                        onChange={(e) => handleChange(e)} />
                </EuiRow> :
                (props.mod === constants.actionMods.CREATE || props.mod === constants.actionMods.EDIT) ?
                    <FormField className="w-10">
                        <EuiTextField
                            className="p-1 w-5"
                            fullwidth
                            readOnly={props.mod === constants.actionMods.VIEW_ONLY}
                            required
                            style={{ paddingTop: "5px", paddingRight: "28px", paddingBottom: '4px', paddingLeft: '7px', width: "350px" }}
                            labelstyle={{ color: "gray" }}
                            id="projectName"
                            autoComplete="new-password"
                            name="name"
                            label="Project Name*"
                            value={Project.project?.name || ""}
                            onChange={(e) => handleChange(e)} />
                        <EuiAutocompleteSelect
                            className="p-1 w-5"
                            fullwidth
                            required
                            label={{ title: "Country Name*", className: "color-gray" }}
                            id="countryNameProjectSelect"
                            name="countryNameSelect"
                            style={{ paddingTop: "5px", width: "350px", paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }}
                            options={countries || []}
                            value={Project.project?.countryId || 0}
                            onChange={(e) => handleChange(e)}
                            placeholder="---Select country---"
                        />
                        <EuiAutocompleteSelect
                            className="p-1 w-5"
                            fullwidth
                            key={customers}
                            required
                            label={{ title: "Customer Name*", className: "color-gray" }}
                            id="customerNameProjectSelect"
                            name="customerNameSelect"
                            style={{ paddingTop: "5px", width: "350px", paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }}
                            options={customers || []}
                            value={Project.project?.customerId || 0}
                            onChange={(e) => handleChange(e)}
                            placeholder="---Select customer---"
                        />
                        <FormField>
                            <EuiTextField
                                className="p-1 w-5"
                                fullwidth
                                readOnly={props.mod === constants.actionMods.VIEW_ONLY}
                                style={{ paddingTop: "5px", paddingRight: "28px", paddingBottom: '4px', paddingLeft: '7px', width: "350px" }}
                                labelstyle={{ color: "gray" }}
                                id="projectContactPerson"
                                name="contactPerson"
                                autoComplete="new-password"
                                label="Contact Person"
                                value={Project.project?.contactPerson || ""}
                                onChange={(e) => handleChange(e)} />
                        </FormField>
                        {(props.mod === constants.actionMods.EDIT) ?
                            <FormField className="w-10">
                                <label className="required" style={{ fontSize: "14px", color: "gray" }} >Project Status</label>
                                <EuiSwitch
                                    name="status"
                                    label="Project Status"
                                    checked={Project.project?.active}
                                    onChange={(e) => handleChange(e)}
                                    dataEnabled="Active"
                                    dataDisabled="Not Active"
                                    disabled={false}
                                    id="projectStatus"
                                />
                                <EuiTextField
                                    className="p-1 w-5"
                                    fullwidth
                                    readOnly={props.mod === constants.actionMods.VIEW_ONLY || props.mod === constants.actionMods.EDIT}
                                    required
                                    style={{ padding: 0, width: "350px" }}
                                    labelstyle={{ color: "gray" }}
                                    id="projectCreatedAt"
                                    name="createdAt"
                                    label="Creation date"
                                    value={DateUtils.toDateString(Project.project?.createdAt, "YYYY - MM - DD HH:mm") || ""}
                                    onChange={(e) => handleChange(e)} />
                                <EuiDatepicker
                                    type="text"
                                    placeholder={Project.project?.plannedStartDate || "YYYY-MM-DD"}
                                    disabled={false}
                                    id="projectPlannedStartDate"
                                    name="plannedStartDate"
                                    maxLength="10"
                                    style={{ width: "350px" }}
                                    label="Planned Start Date"
                                    labelstyle={{ color: "gray" }}
                                    className="with-icon"
                                    onChange={(e) => handleChange(e)}
                                    required={false}
                                />
                                <EuiDatepicker
                                    type="text"
                                    placeholder={Project.project?.plannedEndDate || "YYYY-MM-DD"}
                                    disabled={false}
                                    id="projectPlannedEndDate"
                                    name="plannedEndDate"
                                    label="Planned End Date"
                                    maxLength="10"
                                    labelstyle={{ color: "gray" }}
                                    className="with-icon"
                                    style={{ width: "350px" }}
                                    onChange={(e) => handleChange(e)}
                                    required={false}
                                />
                            </FormField> : null}
                    </FormField>
                    : null}
        </EuiRow>)
}
export default ProjectInfo;
