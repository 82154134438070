import React from "react";
import { useDispatch, useSelector } from "react-redux";
import EuiRow from "../../../eui-components/row";
import EuiTag from "../../../eui-components/tag";
import ObseleteEuiTable from "../../../eui-components/obseleteTable";
import constants from "../../../utils/constants";
import DateUtils from "../../../utils/date-utils";

const AssignedSessions = ({ mod }) => {
    const dashboardIsLoading = useSelector(state => state.dashboard.isLoading);
    const inspectorSessions = useSelector(state => state.rsc.assignedSessions);
    const rscPerson = useSelector(state => state.rsc.selectedInspector);
    const columns = [
        {
            title: rscPerson.personType ? (rscPerson.personType == constants.userRoles.RSC_OHS_INSPECTOR ? "EHS Ping ID" : "Activity ID") : "ID",
            key: "id",
            width: "7%",
        },
        {
            title: rscPerson.personType == constants.userRoles.RSC_OHS_INSPECTOR ? "Activity ID" : "Activity Name",
            key: "activity",
            width: "13%"
        },
        {
            title: "Site",
            key: "site",
            width: "10%",
        },
        {
            title: "Workplan",
            key: "workplan",
            width: "10%",
        },
        {
            title: "Sent To RSC",
            key: "sentToRSC",
            width: "10%",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm:ss")
            }
        },
        {
            title: "Assigned Date and Time",
            key: "assignedOn",
            width: "10%",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm:ss")
            }
        },
        {
            title: "Total Time (minutes)",
            key: "totalTime",
            width: "15%",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = cellData?.toFixed(3) + " minutes"
            }
        },
    ];
    const getRscPersonLogs = () => {
        return (
            <>
                <EuiTag
                    id={"rscPersonname" + rscPerson.id}
                    key={rscPerson.name}
                    className={"w-10"}
                    type="big"
                    name={"RSC Inspector: " + (rscPerson.name)} />
                <EuiTag
                    id={"checkedInTimeId" + rscPerson.name + rscPerson.id }
                    type="big"
                    key={rscPerson.lastCheckinDate}
                    className={"w-10"}
                    name={"Last check in Date: " + (DateUtils.toDateString(rscPerson?.lastCheckinDate, "YYYY-MM-DD HH:mm:ss"))} />
                <EuiTag 
                    id={"checkedOutTime" + rscPerson.id}
                    type="big"
                    key={rscPerson.lastCheckoutDate}
                    className={"w-10"}
                    name={"Last check out Date: " + (DateUtils.toDateString(rscPerson?.lastCheckoutDate, "YYYY-MM-DD HH:mm:ss"))}
                />
                <EuiTag
                    id={"lastTotalProcessed" + rscPerson.id}
                    type="big"
                    key={rscPerson.lastTotalProcessed}
                    className={"w-10"}
                    name={"Last Total Sessions Processed: " + (rscPerson.lastTotalProcessed ?? "")}
                />
            </>)
    }
    return (<EuiRow key={dashboardIsLoading}>
        {rscPerson.personType == 7 ? <EuiRow id={inspectorSessions.rscPersonName} key={rscPerson.id} style={{ justifyContent: "space-between", display: "flex" }} className="w-10">{getRscPersonLogs()}</EuiRow> : <></>}
        <ObseleteEuiTable
        columns={columns}
        data={inspectorSessions}
        filter={true}
        classes="tiny dashed"
        key={dashboardIsLoading}
        id={"InspectorSessions"+ mod}
        paginated={true}
        rowsPerPage={6}
        totalCount={inspectorSessions?.length}
        /></EuiRow>)
}
export default AssignedSessions;