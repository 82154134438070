import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../eui-components/row";
import { ToastContainer } from 'react-toastify';
import EuiMultiPanelTile from "../../eui-components/multipaneltile";
import TeamFilter from "./teamFilter";
import TeamList from "./teamList";
import DialogModal from "../../components/dialog";
import TeamInfo from "./teamInfo";
import constants from "../../utils/constants";
import { setTeam } from "../../store/actions/teamAction";
import ApiClient from "../../utils/api-client";
import { setAllCustomersWithProjects } from "../../store/actions/userAction";

class TeamSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            openDialog: false,
        }
    }

    componentDidMount() {
        const initialState = new Date();
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Team/GetFilteredTeams", { userId: parseInt(this.props.userData.userData.userId),page: 1, pageSize: 30 })
            .then(response => {
                const data = [...response.data.data];
                this.props.setFilteredTeams({ data, totalCount: response.data.totalCount, initialState: initialState.getTime() });
            })
            .catch(error => {
                console.error(error);
            }).finally(() => {
                this.setState({ isLoading: false });
            });
        });

        this.setState({ isLoading: true }, () => {
            ApiClient.post("User/GetCustomersWithProjects")
                .then(response => {
                    this.props.setAllCustomersWithProjects(response.data);
                }).finally(() => {
                    this.setState({ isLoading: false });
                })
        });
    }

    createNewTeam = () => {
        this.setState({ openDialog: false }, () => {
            this.props.createEditTeam();
        })
    }

    render() {
        const isLoading = this.state.isLoading || this.props.isLoading

        const leftPanel = {
            title: "Filter By:",
            content: <TeamFilter />,
        };
        const filteredTeams = this.props.teamData.filteredTeams;
        filteredTeams.userId= parseInt(this.props.userData.userData.userId);
        const midPanel = {
            title: "Filtered Teams",
            content:  <TeamList key={filteredTeams.initialState} />
            //  filteredTeams.initialState === 0 ? 
            // <TeamList key={filteredTeams.initialState} />
            // <div className="flex-start-content m-3 w-10" >Please click on filter to get a list of teams</div>
                // : <TeamList key={filteredTeams.initialState} />
                ,
            rightIcon: <button
                //TODO hidden={this.props.userData.userData.jobRole === "1" || this.props.userData.userData.jobRole === "0" ? false : true}
                className={"btn primary m-1 " + (isLoading? "loading  disabled" : "")}
                disabled={isLoading}
                onClick={() => this.setState({ openDialog: true }, () => this.props.setTeam({ id: 0, createDate: new Date() }))}>
                <i className="icon icon-plus p-1" />Add Team
            </button>,
        };


        return (<EuiRow>

            <EuiMultiPanelTile
                id="teamMultiPanel"
                className="fullscreen"
                key={isLoading}
                leftPanel={leftPanel}
                midPanel={midPanel}
                style={{ paddingRight: 23 }}
            />

            <DialogModal
                buttonName="Save"
                content={<TeamInfo mod={constants.actionMods.CREATE} key={this.props.userData.customersWithProjects} />}
                handleClose={() => this.setState({ openDialog: false })}
                id="teamCreateDialog"
                onClick={() => this.createNewTeam()}
                open={this.state.openDialog}
                disableBackdropClick={true}
                title="Create Team"
            />

            <ToastContainer enableMultiContainer containerId={'WS'} autoClose={2500} />
        </EuiRow >);
    }
}

const mapStateToProps = state => ({
    userData: state.user,
    navbarData: state.navigation,
    teamData: state.team
});
const mapDispatchToProps = dispatch => {
    return {
        setTeam: (team) => setTeam(team)(dispatch),
        setAllCustomersWithProjects: (customersWithProjects) => setAllCustomersWithProjects(customersWithProjects)(dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TeamSearch));