import { constant, groupBy } from "lodash";
import { object } from "prop-types";
import constants from "./constants";
import DateUtils from "./date-utils";
import PageHelper from "./page-helper";

export default class ProductivityDashboardHelper {
    static mapProductivityAndUtilization = (activities, pings, numberOfMonths, installationDone = false) => {
        var result = {};
        let detailedActivities = [];
        let selectedActivities = [];
        if (installationDone) {
            detailedActivities = activities;
            selectedActivities = activities;
        }
        else {
            selectedActivities = this.getInstallationActivities(activities)
            detailedActivities = this.getDetailedActivities(activities, [], []);
        }

        let productivityPoints = detailedActivities?.reduce((a, b) => {
            a += b.productivityPoints;
            return a;
        }, 0);

        let groupByTL = groupBy(detailedActivities, item => `${item["resourceId"]}`);
        result.productivity = Math.round((productivityPoints / (6 * Object.keys(groupByTL)?.length) * 100) / numberOfMonths) || 0;
        result.productivityPoints = productivityPoints;
        let validPings = pings?.filter(item => item.ratingDate);
        let messedPings = validPings.filter(item => !item.ratingDate);
        
        let ohsLeaders = groupBy(validPings, item => `${item["userId"]}`);
        result.utilization = Math.round((validPings?.length / (26 * Object.keys(ohsLeaders)?.length) * 100) / numberOfMonths) || 0;
        result.numberOfSessions = validPings?.length
        
        return result;
    }

    static getTrendData = (activities, pings, trendDistrbution) => {
        let selectedActivities = this.getDetailedActivities(activities, [], []);
        let validPings = pings?.filter(item => item.ratingDate);
        validPings.sort(PageHelper.dynamicSort("ratingDate"));
        let result = {};
        selectedActivities = selectedActivities?.sort(PageHelper.dynamicSort("sentToRSC"));
        let activitiesGroupByDates = groupBy(selectedActivities, item => `${item["sentToRSC"]?.slice(0, 10)}`);
        let pingsGroupByDates = groupBy(validPings, item => `${item["ratingDate"]?.slice(0, 10)}`);
        let dates = [];
        Object.keys(activitiesGroupByDates).forEach(date => {
            dates.push(date);
        });
        Object.keys(pingsGroupByDates).forEach(date => {
            if (dates.indexOf(date) === -1)
                dates.push(date);
        });
        const groupedDatesByMonth = DateUtils.groupByMonth(dates);
        let productivityTrend = [];
        let trendDates = [];
        let utilizationTrend = [];
        Object.keys(groupedDatesByMonth).forEach(date => {
            let dateRange = groupedDatesByMonth[date];
            trendDates.push(date);
            let dateLabeledActivities = selectedActivities?.filter(site => dateRange?.includes(site.sentToRSC?.slice(0, 10)));
            
            let dateLabeledpings = validPings?.filter(site => dateRange?.includes(site.ratingDate?.slice(0, 10)));
            
            let productivtyUtilizationOutcome = this.mapProductivityAndUtilization(dateLabeledActivities, dateLabeledpings, 1, true);
            productivityTrend.push(productivtyUtilizationOutcome.productivity);
            utilizationTrend.push(productivtyUtilizationOutcome.utilization);
        });
        result.trendDates = trendDates;
        result.productivityTrend = productivityTrend;
        result.utilizationTrend = utilizationTrend;
        return result;
    }

    static getGroupedData = (activities, pings, resourcesList, filterControls, keyColumn, numberOfMonths) => {
        let result = [];
        let selectedActivities = this.getDetailedActivities(activities, [], []);
        const activitiesData = selectedActivities.map(item => {
            var countryId = filterControls.aspCompaniesList?.find(e => e.value === item.aspCompanyId)?.countryId;
            var buildHub = filterControls.countriesList?.find(e => e.value === countryId)?.buildHub;
            var projectId = item.workplan?.projectId;
            var customerId = filterControls.projectsList?.find(e => e.value === projectId)?.customerId;
            var customerUnit = filterControls.customersList?.find(e => e.value === customerId)?.customerUnit;
            const el = {
                ...item,
                countryId: countryId,
                buildHub: buildHub,
                projectId: projectId,
                customerId: customerId,
                customerUnit: customerUnit,
                productivityMonth: new Date(item.installationDate)?.toLocaleString('default', { month: 'short' }) || ""
            }
            return el;
        })
        activitiesData.filter(item => !item.projectId);
        const pingsData = pings.map(item => {
            var countryId = filterControls.aspCompaniesList?.find(e => e.value === item.activity.aspCompanyId)?.countryId;
            var buildHub = filterControls.countriesList?.find(e => e.value === countryId)?.buildHub;
            var projectId = item.activity.workplan?.projectId;
            var customerId = filterControls.projectsList?.find(e => e.value === projectId)?.customerId;
            var customerUnit = filterControls.customersList?.find(e => e.value === customerId)?.customerUnit;
            const el = {
                ...item,
                resourceId: item.userId,
                countryId: countryId,
                buildHub: buildHub,
                projectId: projectId,
                customerId: customerId,
                customerUnit: customerUnit,
                aspCompanyId: item?.activity?.aspCompanyId
            }
            return el;
        })
        pingsData.filter(item => !item.projectId);


        let groupedActivitiesMonthly = groupBy(activitiesData, item => `${item[keyColumn[0]]}+${item[keyColumn[1]]}+${item['productivityMonth']}`);
        let groupedActivities = groupBy(activitiesData, item => `${item[keyColumn[0]]}+${item[keyColumn[1]]}`);
        let groupedPings = groupBy(pingsData, item => `${item[keyColumn[0]]}+${item[keyColumn[1]]}`);

        
        
        let monthlyActivityResult = [];
        Object.keys(groupedActivitiesMonthly).forEach(e => {
            
            
            
            let isEhs = false;

            const kpis = this.mapProductivityAndUtilization(groupedActivitiesMonthly[e] || [], [], 1, true);
            let columnGrps = (e.split("+"));
            
            const mappedGroups = columnGrps.map(e => {
                const el = {
                    value: e.includes("Id") ? parseInt(e) : e,
                    text: keyColumn[columnGrps.indexOf(e)]
                };
                return el;
            });
            if ((groupedPings[e] && groupedPings[e].length) || kpis.numberOfSessions) {
                delete groupedPings[e];
                isEhs = true;
            }
            
            let x = e.split('+');
            
            monthlyActivityResult.push({
                aspCompanyName: keyColumn.includes("aspCompanyId") ? filterControls.aspCompaniesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "aspCompanyId")?.value)))?.text : "",
                teamLeaderName: keyColumn.includes("resourceId") ? resourcesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "resourceId")?.value)))?.text : "",
                countryName: keyColumn.includes("countryId") ? filterControls.countriesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "countryId")?.value)))?.text : "",
                buildHub: keyColumn.includes("buildHub") ? mappedGroups.find(g => g.text === "buildHub")?.value : "",
                projectName: keyColumn.includes("projectId") ? filterControls.projectsList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "projectId")?.value)))?.text : "",
                customerName: keyColumn.includes("customerId") ? filterControls.customersList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "customerId")?.value)))?.text : "",
                customerUnit: keyColumn.includes("customerUnit") ? mappedGroups.find(g => g.text === "customerUnit")?.value : "",
                productivityPercentage: kpis.productivity,
                productivityPoints: kpis.productivityPoints,
                productivityMonth: e.split('+')[2],
                utilizationPercentage: kpis.utilization ? kpis.utilization : 0,
                utilizationNumberOfSessions: kpis.numberOfSessions ? kpis.numberOfSessions : 0,
                isEhs: isEhs,

            })

        });
        Object.keys(groupedActivities).forEach(e => {
            
            
            
            const kpis = this.mapProductivityAndUtilization(groupedActivities[e] || [], groupedPings[e] || [], numberOfMonths, true);
            let columnGrps = (e.split("+"));
            let isEhs = false;
            
            const mappedGroups = columnGrps.map(e => {
                const el = {
                    value: e.includes("Id") ? parseInt(e) : e,
                    text: keyColumn[columnGrps.indexOf(e)]
                };
                return el;
            });
            if ((groupedPings[e] && groupedPings[e].length) || kpis.numberOfSessions) {
                delete groupedPings[e];
                isEhs = true;
            }
            
            
            result.push({
                aspCompanyName: keyColumn.includes("aspCompanyId") ? filterControls.aspCompaniesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "aspCompanyId")?.value)))?.text : "",
                teamLeaderName: keyColumn.includes("resourceId") ? resourcesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "resourceId")?.value)))?.text : "",
                countryName: keyColumn.includes("countryId") ? filterControls.countriesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "countryId")?.value)))?.text : "",
                buildHub: keyColumn.includes("buildHub") ? mappedGroups.find(g => g.text === "buildHub")?.value : "",
                projectName: keyColumn.includes("projectId") ? filterControls.projectsList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "projectId")?.value)))?.text : "",
                customerName: keyColumn.includes("customerId") ? filterControls.customersList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "customerId")?.value)))?.text : "",
                customerUnit: keyColumn.includes("customerUnit") ? mappedGroups.find(g => g.text === "customerUnit")?.value : "",
                productivityPercentage: kpis.productivity,
                productivityPoints: kpis.productivityPoints,
                utilizationPercentage: kpis.utilization ? kpis.utilization : 0,
                utilizationNumberOfSessions: kpis.numberOfSessions ? kpis.numberOfSessions : 0,
                isEhs: isEhs,
                productivityMonth: e.split('+')[2],
            })

        });
        Object.keys(groupedPings).forEach(e => {
            
            
            
            const kpis = this.mapProductivityAndUtilization(groupedActivities[e] || [], groupedPings[e] || [], numberOfMonths, true);
            let columnGrps = (e.split("+"));
            
            const mappedGroups = columnGrps.map(e => {
                const el = {
                    value: e.includes("Id") ? parseInt(e) : e,
                    text: keyColumn[columnGrps.indexOf(e)]
                };
                return el;
            });
            
            result.push({
                aspCompanyName: keyColumn.includes("aspCompanyId") ? filterControls.aspCompaniesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "aspCompanyId")?.value)))?.text : "",
                teamLeaderName: keyColumn.includes("resourceId") ? resourcesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "resourceId")?.value)))?.text : "",
                countryName: keyColumn.includes("countryId") ? filterControls.countriesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "countryId")?.value)))?.text : "",
                buildHub: keyColumn.includes("buildHub") ? mappedGroups.find(g => g.text === "buildHub")?.value : "",
                projectName: keyColumn.includes("projectId") ? filterControls.projectsList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "projectId")?.value)))?.text : "",
                customerName: keyColumn.includes("customerId") ? filterControls.customersList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "customerId")?.value)))?.text : "",
                customerUnit: keyColumn.includes("customerUnit") ? mappedGroups.find(g => g.text === "customerUnit")?.value : "",
                utilizationPercentage: kpis.utilization,
                utilizationNumberOfSessions: kpis.numberOfSessions,
                productivityPercentage: 0,
                productivityPoints: 0,
                isEhs: true
            })

        });

        let finalResult = { data: result, monthlyGroupedActivities: monthlyActivityResult };
        return finalResult;
    }

    static getInstallationActivities = (activities) => {
        const wpSelectedTypes = constants.productivityWpTypes
        let selectedWpNames = wpSelectedTypes?.map(item => item.text);
        let validActivities = activities?.filter(item => selectedWpNames?.includes(item?.workplan?.workplanSuffix?.slice(0, 3)));
        let groupByWP = groupBy(validActivities, item => `${item["workplanId"]}`);
        
        let selectedActivities = [];
        Object.keys(groupByWP).forEach(g => {
            const orderedInstallations = groupByWP[g].filter(item => item.gpoName === constants.installation).sort(PageHelper.dynamicSort("sentToRSC"));
            const orderedPrePats = groupByWP[g].filter(item => item.gpoName === constants.prePat).sort(PageHelper.dynamicSort("actualStart"));
            
            
            if (orderedInstallations?.length && orderedPrePats?.length) {
                selectedActivities.push(orderedInstallations[0]);
            }
        });
        return selectedActivities;
    }

    static getDetailedActivities = (activities, filterControls, resourcesList) => {

        const wpSelectedTypes = constants.productivityWpTypes
        let selectedWpNames = wpSelectedTypes?.map(item => item.text);
        let validActivities = activities?.filter(item => selectedWpNames?.includes(item?.workplan?.workplanSuffix?.slice(0, 3)));
        let groupByWP = groupBy(validActivities, item => `${item["workplanId"]}`);
        
        let records = [];
        Object.keys(groupByWP).forEach(g => {
            const orderedInstallations = groupByWP[g].filter(item => item.gpoName === constants.installation).sort(PageHelper.dynamicSort("sentToRSC"));
            const orderedPrePats = groupByWP[g].filter(item => item.gpoName === constants.prePat).sort(PageHelper.dynamicSort("actualStart"));
            const gpActivity = groupByWP[g];
            
            
            var countryId = orderedInstallations.length ? filterControls.aspCompaniesList?.find(e => e.value === orderedInstallations[0]?.aspCompanyId)?.countryId : filterControls.aspCompaniesList?.find(e => e.value === orderedPrePats[0]?.aspCompanyId)?.countryId;
            var buildHub = orderedInstallations.length ? filterControls.countriesList?.find(e => e.value === countryId)?.buildHub : filterControls.countriesList?.find(e => e.value === countryId)?.buildHub;
            var projectId = orderedInstallations.length ? orderedInstallations[0]?.workplan?.projectId : orderedPrePats[0]?.workplan?.projectId;
            var customerId = orderedInstallations.length ? filterControls.projectsList?.find(e => e.value === projectId)?.customerId : filterControls.projectsList?.find(e => e.value === projectId)?.customerId;
            var customerUnit = orderedInstallations.length ? filterControls.customersList?.find(e => e.value === customerId)?.customerUnit : filterControls.customersList?.find(e => e.value === customerId)?.customerUnit;
            records.push({
                ...orderedInstallations[0],
                aspCompanyName: orderedInstallations.length ? filterControls.aspCompaniesList?.find(company => company.value == orderedInstallations[0]?.aspCompanyId)?.text : filterControls.aspCompaniesList?.find(company => company.value == orderedPrePats[0]?.aspCompanyId)?.text,
                teamLeaderName: orderedInstallations.length ? resourcesList?.find(r => r.value == orderedInstallations[0]?.resourceId)?.text : resourcesList?.find(r => r.value == orderedPrePats[0]?.resourceId)?.text,
                countryName: filterControls.countriesList?.find(c => c.value == countryId)?.text,
                buildHub: buildHub,
                projectName: filterControls.projectsList?.find(p => p.value == projectId)?.text,
                customerName: filterControls.customersList?.find(c => c.value == customerId)?.text,
                customerUnit: customerUnit,
                productivityPoints: orderedInstallations.length && orderedPrePats.length ? wpSelectedTypes.find(s => s.text === orderedInstallations[0]?.workplan?.workplanSuffix?.slice(0, 3))?.value : 0,
                prePateDate: orderedPrePats[0]?.actualStart || "",
                installationDate: orderedInstallations[0]?.sentToRSC?.slice(0, 10),
                targetPoints: wpSelectedTypes.find(s => s.text === gpActivity[0]?.workplan?.workplanSuffix?.slice(0, 3))?.value,
                productivityMonth: new Date(orderedInstallations[0]?.sentToRSC)?.toLocaleString('default', { month: 'short' }) || ""
            });
        });
        return records;
    }

    static getDetailedPings = (pings, filterControls, resourcesList) => {
        let validPings = pings?.filter(item => item.ratingDate);
        let groupByWP = groupBy(validPings, item => `${item.activity["workplanId"]}`);
        
        let records = [];
        Object.keys(groupByWP).forEach(g => {
            
            let group = groupByWP[g];
            var countryId = filterControls.aspCompaniesList?.find(e => e.value === group[0].activity?.aspCompanyId)?.countryId;
            var buildHub = filterControls.countriesList?.find(e => e.value === countryId)?.buildHub;
            var projectId = groupByWP[g][0].activity?.workplan?.projectId;
            var customerId = filterControls.projectsList?.find(e => e.value === projectId)?.customerId;
            var customerUnit = filterControls.customersList?.find(e => e.value === customerId)?.customerUnit;
            records.push({
                ...group[0],
                aspCompanyName: filterControls.aspCompaniesList?.find(company => company.value == group[0].activity?.aspCompanyId)?.text,
                teamLeaderName: resourcesList?.find(r => r.value == group[0].userId)?.text,
                countryName: filterControls.countriesList?.find(c => c.value == countryId)?.text,
                buildHub: buildHub,
                projectName: filterControls.projectsList?.find(p => p.value == projectId)?.text,
                customerName: filterControls.customersList?.find(c => c.value == customerId)?.text,
                customerUnit: customerUnit,
                isEhs: true
            });
        });
        return records;
    }
}
