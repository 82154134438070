import { constant, groupBy } from "lodash";
import constants from "./constants";
import DateUtils from "./date-utils";

export default class DashboardHelper {

    static mapIncentives = (incentivesData, incentives) => {
        var allData = incentivesData?.activities;
        //////////////// !!!!!ohs is quality index note and when done fix naming!!!!!!!!1 //////////

        var total = allData.length;
        var data = [];
        var ratings = [];

        allData.forEach((item, index) => {
            data.push(allData[index]);
        })


        data.forEach(item => {
            if (item.activityRatings) {
                item.activityRatings.forEach(r => {
                    ratings.push(r);
                })
            }
        });

        var snagQuestion = constants.ratingQuestions.find(q => parseInt(q.value) == 10).text;
        var rscStatus = constants.ratingQuestions.find(q => parseInt(q.value) == 17).text

        var ftr = ratings.filter(item => item.ratingValue?.toUpperCase() === "FTR");
        var qualityIndex = ratings.filter(item => item.question?.toUpperCase() === "QI IF DONE");
        var ftrCount = ftr?.length;
        var noSnags = ratings.filter(item => item.question === snagQuestion && (!item.ratingValue || !parseInt(item.ratingValue)));
        var noSnagsCount = noSnags?.length;
        var efficiency = allData?.filter(item => (new Date(item.plannedEnd)?.getDate() - new Date(item.plannedStart)?.getDate()) >= (new Date(item.actualEnd).getDate() - new Date(item.actualStart).getDate()));
        var efficiencyCount = efficiency.length;
        var readyForCustomerAcceptance = ratings?.filter(item => item.question === rscStatus && item.ratingValue?.toUpperCase() === constants.ratingValue.readyForCustomerAcceptance);
        var readyForCustomerAcceptanceCount = readyForCustomerAcceptance?.length;
        var qualityIndexCount = qualityIndex?.filter(item => parseFloat(item.ratingValue) <= 0.4).length;

        const pEFTR = total ? (Math.round((ftrCount) / total * 100)) : 0;
        const pTE = total ? (Math.round((efficiencyCount) / total * 100)) : 0;
        const pNoSnag = total ? (Math.round((noSnagsCount) / total * 100)) : 0;
        const pRA = total ? (Math.round((readyForCustomerAcceptanceCount) / total * 100)) : 0;
        const pOhs = total ? (Math.round((qualityIndexCount) / total * 100)) : 0;
        const overall = Math.round((
            pRA * (incentives?.readyForCustomerAcceptanceWightage || 0)
            + pTE * (incentives?.teamEfficiencyWeightage || 0)
            + pNoSnag * (incentives?.noSnagsWeightage || 0)
            + pEFTR * (incentives?.ericssonFtrWeightage || 0)
            + pOhs * (incentives?.ehsPerformanceWeightage || 0))
            / 100)

        var kpis = {
            "EricssonFTR": { totalItemCount: total, successItemCount: ftrCount, percentage: pEFTR, weightage: incentives?.ericssonFtrWeightage },
            "NoSnags": { totalItemCount: total, successItemCount: noSnagsCount, percentage: pNoSnag, weightage: incentives?.noSnagsWeightage },
            "TeamEfficiency": { totalItemCount: total, successItemCount: efficiencyCount, percentage: pTE, weightage: incentives?.teamEfficiencyWeightage },
            "ReadyForCUstomeAcceptance": { totalItemCount: total, successItemCount: readyForCustomerAcceptanceCount, percentage: pRA, weightage: incentives?.readyForCustomerAcceptanceWightage },
            "OhsPerformance": { totalItemCount: total, successItemCount: qualityIndexCount, percentage: pOhs, weightage: incentives?.ehsPerformanceWeightage },
            "Overall": overall
        }
        return kpis;
    }

    static mapDetailsIncentivesData = (incentivesData, resourcesList, filterControls, keyColumn) => {
        var data = incentivesData?.activities
        //////////////// !!!!!ohs is quality index note and when done fix naming!!!!!!!!1 //////////
        var ohs = incentivesData.ohsPings;
        if (!data || !data?.length)
            return [];
        var result = [];
        
        data = data.map(item => {
            var countryId = filterControls.aspCompaniesList?.find(e => e.value === item.aspCompanyId)?.countryId;
            var buildHub = filterControls.countriesList?.find(e => e.value === countryId)?.buildHub;
            var projectId = item.workplan?.projectId;
            var customerId = filterControls.projectsList?.find(e => e.value === projectId)?.customerId;
            var customerUnit = filterControls.customersList?.find(e => e.value === customerId)?.customerUnit;
            const el = {
                ...item,
                countryId: countryId,
                buildHub: buildHub,
                projectId: projectId,
                customerId: customerId,
                customerUnit: customerUnit
            }
            return el;
        })

        var grouped = groupBy(data, item => `${item[keyColumn[0]]}+${item[keyColumn[1]]}`);

        
        Object.keys(grouped).forEach(e => {
            var g = grouped[e];
            
            var groupedData = { activities: grouped[e], ohsPings: ohs?.filter(item => grouped[e].map(g => g.id).includes(item.activityId)) }
            var kpis = this.mapIncentives(groupedData, constants.incentives);
            var assigned = grouped[e]?.filter(item => item.resourceId)?.length;
            var completed = grouped[e]?.filter(item => item.actualEnd)?.length;

            var columnGrps = (e.split("+"));
            
            const mappedGroups = columnGrps.map(e => {
                const el = {
                    value: e.includes("Id") ? parseInt(e) : e,
                    text: keyColumn[columnGrps.indexOf(e)]
                };
                return el;
            });
            
            result.push({
                aspCompanyName: keyColumn.includes("aspCompanyId") ? filterControls.aspCompaniesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "aspCompanyId")?.value)))?.text : "",
                teamLeaderName: keyColumn.includes("resourceId") ? resourcesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "resourceId")?.value)))?.text : "",
                countryName: keyColumn.includes("countryId") ? filterControls.countriesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "countryId")?.value)))?.text : "",
                buildHub: keyColumn.includes("buildHub") ? mappedGroups.find(g => g.text === "buildHub")?.value : "",
                projectName: keyColumn.includes("projectId") ? filterControls.projectsList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "projectId")?.value)))?.text : "",
                customerName: keyColumn.includes("customerId") ? filterControls.customersList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "customerId")?.value)))?.text : "",
                customerUnit: keyColumn.includes("customerUnit") ? mappedGroups.find(g => g.text === "customerUnit")?.value : "",
                //teamLeaderName: resourcesList?.find(leader => leader?.value == parseInt(grouped[e]?.resourceId))?.email,
                assigned: assigned,
                completed: completed,
                ftr: kpis?.EricssonFTR?.percentage,
                noSnags: kpis?.NoSnags?.percentage,
                readyForCustomerAcceptance: kpis?.ReadyForCUstomeAcceptance?.percentage,
                teamEfficiency: kpis?.TeamEfficiency?.percentage,
                ehsPerformance: kpis.OhsPerformance?.percentage,
                overall: kpis?.Overall,
            })
        });
        
        return result;
    }

    static mapFilterControls = (userData, projects, rscCountries) => {
        
        var countriesIds = [];
        var customersList = [];
        var countriesList = [];
        var aspCompaniesList = [];
        var projectsList = [];
        if (userData.userData?.jobRole == constants.userRoles.REPORTER || userData.userData?.jobRole == constants.userRoles.ERICSSON_IM || userData.userData?.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR) {
            customersList = userData.userDetail.customers.map(customer => {
                var mappedCustomer = {
                    value: customer.customer.id,
                    text: customer.customer.name,
                    countryId: customer.customer.countryId,
                    customerUnit: customer.customer.customerUnit
                }
                return mappedCustomer;
            });
            countriesList = userData.countries?.filter(c => customersList.map(item => item?.countryId).includes(c.value)) || [];
            countriesList.forEach(c => {
                c.aspCompanies.forEach(a => {
                    aspCompaniesList.push(a);
                })
            });
            projectsList = projects?.filter(p => customersList.map(l => l.value)?.includes(p.customerId) && p.active)?.map(item => {
                var project = {
                    text: item.name,
                    value: item.id,
                    customerId: item.customerId
                }
                return project;
            });
        }
        else if (userData.userData?.jobRole == constants.userRoles.RSC_SITE_SUPERVISOR || userData.userData?.jobRole == constants.userRoles.RSC_OHS_INSPECTOR || userData.userData.jobRole == constants.userRoles.RSC_COODINATOR) {
            var rscCentercountries = rscCountries.filter(item => item.rscCenterId == userData.rscPerson?.rscCenterId);
            var mappedRscCenterCountries = rscCentercountries?.map(item => item.countryId);
            countriesList = userData.countries.filter(item => mappedRscCenterCountries?.includes(item.value));
            customersList = userData.customers.filter(item => mappedRscCenterCountries?.includes(item.countryId));
            countriesList.forEach(c => {
                c.aspCompanies.forEach(a => {
                    aspCompaniesList.push(a);
                })
            });
            projectsList = projects?.filter(p => customersList.map(l => l.value)?.includes(p.customerId) && p.active)?.map(item => {
                var project = {
                    text: item.name,
                    value: item.id,
                    customerId: item.customerId
                }
                return project;
            });

        }
        else {
            aspCompaniesList = userData.userDetail.userAspCompanies.map(c => {
                var companyOption = {
                    text: c?.aspCompany.name,
                    value: c?.aspCompanyId,
                    countryId: c?.aspCompany.countryId
                };
                countriesIds.push(c?.aspCompany.countryId);
                return companyOption;
            });
            countriesList = userData?.countries?.filter(item => countriesIds.includes(item.value));
            
            customersList = userData?.customers?.filter(c => countriesIds.includes(c.countryId));
            const customerIds = customersList?.map(e => e.value);
            projectsList = projects?.filter(p => customerIds?.includes(p.customerId) && p.active)?.map(item => {
                var project = {
                    text: item.name,
                    value: item.id,
                    customerId: item.customerId
                }
                return project;
            });
        }

        return {
            aspCompaniesList: aspCompaniesList,
            countriesList: countriesList,
            customersList: customersList,
            projectsList: projectsList
        }

    }

    static mapSitesDetailsActivities = (incentivesData, leadersList, filterControls) => {
        var activities = incentivesData.activities;
        var ehs = incentivesData.ohsPings;
        if (!activities || !activities?.length)
            return [];
        var snagQuestion = constants.ratingQuestions.find(q => parseInt(q.value) == 10)?.text;
        var ftrQuestion = constants.ratingQuestions.find(q => parseInt(q.value) == 6)?.text
        var rscStatus = constants.ratingQuestions.find(q => parseInt(q.value) == 17)?.text

        var mappedActivities = activities.map(a => {
            var countryId = filterControls.aspCompaniesList?.find(e => e.value === a.aspCompanyId)?.countryId;
            var projectId = a.workplan?.projectId;
            var customerId = filterControls.projectsList?.find(e => e.value === projectId)?.customerId;
            var countryName = filterControls.countriesList?.find(e => e.value === countryId)?.text;
            var buildHub = filterControls.countriesList?.find(e => e.value === countryId)?.buildHub;
            var projectName = filterControls.projectsList?.find(e => e.value == parseInt(a.workplan?.projectId))?.text;
            var customerName = filterControls.customersList?.find(e => e.value === filterControls.projectsList?.find(e => e.value === projectId)?.customerId)?.text;
            var customerUnit = filterControls.customersList?.find(e => e.value === customerId)?.customerUnit;
            const mappedItem = {
                ...a,
                countryName: countryName,
                buildHub: buildHub,
                projectName: projectName,
                customerName: customerName,
                customerUnit: customerUnit,
                companyName: filterControls.aspCompaniesList?.find(company => company.value === a.aspCompanyId)?.text,
                leaderName: leadersList?.find(leader => leader.value === a.resourceId)?.text,
                actualEnd: DateUtils.toDateString(a.actualEnd, "YYYY-MM-DD", true),
                plannedEnd: DateUtils.toDateString(a.plannedEnd, "YYYY-MM-DD", true),
                noSnag: a.activityRatings?.find(item => item.question === snagQuestion)?.ratingValue || "",
                wpName: a.workplan?.name,
                ftrStatus: a.activityRatings?.find(r => r.question == ftrQuestion)?.ratingValue,
                readyForCAStatus: a.activityRatings?.find(r => r.question == rscStatus)?.ratingValue,
                efficient: (((new Date(a.plannedEnd)?.getDate() - new Date(a.plannedStart)?.getDate()) >= (new Date(a.actualEnd).getDate() - new Date(a.actualStart).getDate())) ? true : false),
                ehsStatus: a.activityRatings?.find(r => r.question?.toUpperCase() == "QI IF DONE")?.ratingValue
            };
            return mappedItem;
            
        });
        return mappedActivities;
    }



}