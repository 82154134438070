import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import ApiClient from "../../utils/api-client";
import { isEmpty, isNull } from "lodash";





const initialState = {
  direct_chat: {
    conversations: [],
    current_conversation: null,
    current_messages: [],
    participantInvited: []
  },
  group_chat: {},
};

export const getChatTime = (time) => {
  let labelTime = '';
  const date = moment(time);
  const hour = String(date.get('hour')).padStart(2, '0');
  const minutes = String(date.get('minute')).padStart(2, '0');
  const diffDays = moment().diff(date, 'days');
  if (diffDays > 7) {
    const month = String(date.get('month') + 1).padStart(2, '0');
    const day = String(date.get('date') + 1).padStart(2, '0');
    const year = date.get('year');
    labelTime = `${day}/${month}/${year} at ${hour}:${minutes}`;
  } else if (diffDays > 1) {
    labelTime = ` at ${hour}:${minutes}`; // ${days[date.weekday()]}
  } else {
    const isSameDay = date.get('day') === moment().get('day');
    labelTime = `${isSameDay ? 'Today' : 'Yesterday'} at ${hour}:${minutes}`;
  }

  return labelTime;
};

const slice = createSlice({
  name: "conversation",
  initialState,
  reducers: {

    updateFriends(state, action) {
      state.friends = action.payload.friends;
    },


    FetchParticipantInvitedConversations(state, action) {
      const list = action.payload?.participantInvited?.map((el) => {
        return {
          id: el.conversationId,
          userId: (el?.userId || null),
          activityid: (el.activityid || null),
          ehsPingId: el.ehsPingId,
          userName: (el.userName || null),
          email: el?.email || null,
          profileAvatar: (el?.profileAvatar || null),
          online: (el?.isOnline || null),
          msg: '',// el.msg,
          time: getChatTime(el.createdAt),
          pinned: false,
          status: el.status
        };

      });

      state.direct_chat.participantInvited = list;
    },

    fetchDirectConversations(state, action) {
      ///console.log("this_conversation addDirectConversation fetch",EhsPingId,action.payload?.conversations)
      const user_id = JSON.parse(window.localStorage.getItem("userId"));
      let EhsPingId = window.localStorage.getItem("ehsPingId")
      if (!isEmpty(EhsPingId)) {
        EhsPingId = JSON.parse(EhsPingId);
      
      }

      const list = action.payload?.conversations?.map((el) => {
        const user = (el.participants || [])?.find((elm) => parseInt(elm?.userId) !== parseInt(user_id));
        return {
          id: el.id,
          userId: (user?.userId || null),
          userName: (user?.userName || null),
          email: user?.email || null,
          ehsPingId: el.ehsPingId,
          activityid: el.activityid,
          groupName: `Activity-${el.activityid}(${EhsPingId})`,
          groupAvatar: 'https://eu.ui-avatars.com/api/?name=Ericcson+Group&size=250$',
          profileAvatar: user?.profileAvatar,
          time: getChatTime(el.time),

        };

      });

      state.direct_chat.conversations = list;
    },

    addDirectConversation(state, action) {
      const user_id = JSON.parse(window.localStorage.getItem("userId"));
      const this_conversation = action.payload?.conversation;
      ///console.log("this_conversation addDirectConversation",this_conversation)
      const user = (this_conversation?.participants || []).find(
        (elm) => elm.userId !== user_id
      );
      state.direct_chat.conversations = state.direct_chat.conversations.filter(
        (el) => el?.id !== this_conversation.id
      );

      state.direct_chat.conversations.push({
        id: this_conversation.id,
        userId: (user?.userId || null),
        userName: (user?.userName || null),
        email: user?.email || null,
        ehsPingId: this_conversation.ehsPingId,
        activityid: this_conversation.activityid,
        groupName: "Activity-" + this_conversation.activityid,
        groupAvatar: 'https://eu.ui-avatars.com/api/?name=Ericcson+Group&size=250$',
        profileAvatar: user?.profileAvatar,
        time: getChatTime(this_conversation.time),
      });
    },

    setCurrentConversation(state, action) {
      state.direct_chat.current_conversation = action.payload;
    },

    fetchCurrentMessages(state, action) {
      const user_id = JSON.parse(window.localStorage.getItem("userId"));

      const messages = action.payload?.messages;
      const listUsers = state.direct_chat.participantInvited

      const formatted_messages = messages?.map((el) => {

        const user = (listUsers || [])?.find((elm) => parseInt(elm?.userId) === el.from);

        return {
          id: el.id,
          type: "msg",
          subtype: !isNull(el.image_path) ? "img" : !isNull(el.audio_path) ? "audio" : !isNull(el.video_path) ? "video" : !isNull(el.file_path) ? "doc" : !isNull(el.preview) ? "Link" : 'Text',
          message: el.message,
          ehsPingId: el.ehsPingId,
          activityid: el.activityid,
          userName: user?.userName,
          profileAvatar: user?.profileAvatar,
          email: user?.email || null,
          preview: el.preview,
          audio_path: el.audio_path,
          file_path: el.file_path,
          video_path: el.video_path,
          image_path: el.image_path,
          online: el.online,
          time: getChatTime(el.time),
          incoming: parseInt(el.from) !== parseInt(user_id),// to
          outgoing: parseInt(el.from) === parseInt(user_id)// sender from
        }

      });
      state.direct_chat.current_messages = formatted_messages;
    },
    addDirectMessage(state, action) {
      let current_messages = [...state.direct_chat.current_messages];
      let message = current_messages.find(m => m._rnId == action.payload.message._rnId)
      if (!message) {
        current_messages.push(action.payload.message);
      }
      state.direct_chat.current_messages = current_messages;
    },
    initialState(state) {
      state = initialState;
    }
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------


export const FetchParticipantInvitedConversations = ({ participantInvited }) => {
  return async (dispatch, getState) => {
    dispatch(slice?.actions?.FetchParticipantInvitedConversations({ participantInvited }));
  };
};

export const FetchDirectConversations = ({ conversations }) => {
  return async (dispatch, getState) => {
    dispatch(slice?.actions?.fetchDirectConversations({ conversations }));
  };
};
export const AddDirectConversation = ({ conversation }) => {
  return async (dispatch, getState) => {
    dispatch(slice?.actions?.addDirectConversation({ conversation }));
  };
};


export const SetCurrentConversation = (current_conversation) => {

  return async (dispatch, getState) => {
    dispatch(slice.actions.setCurrentConversation(current_conversation));
  };
};


export const FetchCurrentMessages = ({ messages }) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.fetchCurrentMessages({ messages }));
  }
}

export const AddDirectMessage = (message) => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.addDirectMessage({ message }));
  }
}

export function FetchFriends(payloads) {
  return async (dispatch, getState) => {
    const request = {}
    request.status = payloads.status
    request.conversationId = payloads.conversationId
    ///console.log("testing send data payload FetchFriends",payloads)
    ApiClient.post("User/GetAllParticipantInvited", request)
      .then((response) => {
        ///console.log("testing send data payload FetchFriends",response)

        dispatch(slice.actions.updateFriends({ friends: response.data }));
      })
      .catch((err) => {
        ///console.log(err);
      });

  };
}

export const setConvInitialState= () => {
  return async (dispatch, getState) => {
    dispatch(slice.actions.initialState());
  };
};