/**
 * @file Module to create Select component.
 */

/**
 * Class representing a select.
 */
export class Select {

    /**
     * Setup select properties.
     * @param {HTMLElement} element - The DOM element of select.
     */
    constructor(element) {
        this.dom = {
            select: element,
            selectOptions: element.querySelector('.options-list'),
            current: element.querySelector('.current-options'),
            items: element.querySelectorAll('.item'),
            checkboxes: element.querySelectorAll('input[type="checkbox"]'),
            input: element.querySelector('.current-options').children[0],
        };

        this.events = {
            singleOption: new CustomEvent('singleOption', {
                detail: {
                    selectedOptions: this.selectedOptions,
                }
            })
        };

        this.listeners = {
            selectKeydown: (evt) => {
                if (evt.key === 'ArrowUp' || evt.key === 'ArrowDown' || evt.key === 'Enter' || evt.key === ' ') {
                    evt.preventDefault();
                }

                if (evt.key === ' ') {
                    if (this.type === 'input-single' || this.type === 'input-multi') {
                        if (this.dom.select.classList.contains('open') && this.dom.selectOptions.querySelector('.highlight')) {
                            evt.preventDefault();
                        }
                    } else {
                        evt.preventDefault();
                    }
                }
            },

            singleCurrentClick: () => this._toggleSelectOpt(),
            singleSelectOptionsClick: (evt) => this._selectOption(evt),
            singleSelectKeyup: (evt) => this._arrowKeyActions(evt),

            multiCurrentClick: () => this._toggleSelectOpt(),
            multiSelectOptionsChange: (evt) => this._selectOption(evt),
            multiSelectKeyup: (evt) => this._arrowKeyActions(evt),

            multiPillsCurrentClick: () => this._toggleSelectOpt(),
            multiPillsSelectOptionsChange: (evt) => this._selectOption(evt),
            multiPillsSelectQuerySelectorClick: (evt) => this._removePill(evt),
            multiPillsSelectKeyup: (evt) => this._arrowKeyActions(evt),

            inputSingleSelectOptionsClick: (evt) => this._selectOption(evt),
            inputSingleInputClick: () => {
                this.dom.select.classList.add('open');
                this.dom.select.classList.remove('closed');
            },
            inputSingleInputKeyup: () => this._filterOptions(),
            inputSingleSelectKeyup: (evt) => this._arrowKeyActions(evt),
            inputSingleIconClick: () => {
                this.dom.select.classList.toggle('open');
                this.dom.select.classList.toggle('closed');
            },

            inputMultiInputClick: () => {
                this.dom.select.classList.add('open');
                this.dom.select.classList.remove('closed');
            },
            inputMultiSelectOptionsChange: (evt) => this._selectOption(evt),
            inputMultiInputKeyup: (evt) => this._filterOptions(evt),
            inputMultiSelectQuerySelectorClick: (evt) => this._removePill(evt),
            inputMultiSelectKeyup: (evt) => this._arrowKeyActions(evt),
            inputMultiIconClick: () => {
                this.dom.select.classList.toggle('open');
                this.dom.select.classList.toggle('closed');
            },
            scroll: () => {
                if (this.dom.select.classList.contains('open')) {
                    this._optionsListPosition(this.dom.select);

                }
            },
            clickedOutside: ({ target }) => {
                if (!this.dom.select.contains(target)) {
                    this._hideSelectOpt();
                }
            }
        };
        this.type = this.dom.select.getAttribute('data-type') || undefined;
        this.highlightIndex = -1;
        this.selectedOptions = this.dom.select.getAttribute('selectedOptions')?.split(",") || []
        if (this.selectedOptions.length !== 0 && this.selectedOptions[0] !== "") {
            const optionsContainer = document.createElement('div');

            optionsContainer.classList.add('options_container');
            this.dom.select.appendChild(optionsContainer);
            this._createCheckboxes();
            const optionsArr = this.selectedOptions;
            optionsArr.forEach(e => {
                this._refreshMultiPlaceholder();
                const selectEvent = new CustomEvent('selectOption', {
                    detail: { value: this.selectedOptions }
                });
                this.dom.select.dispatchEvent(selectEvent);
            })
            this._addPill();

        }

        else this.selectedOptions = [];

        this.filteredOptions = [];
    }

    /**
     * Initialize select.
     * @public
     */
    init() {
        this._render();
        this._disableState();
        this._addEventListeners();
    }

    /**
     * Destroy select and associated events.
     * @public
     */
    destroy() {
        this._removeEventListeners();
    }


    /**
     * Generate custom select markup.
     * @private
     */
    _render() {

        if (this.type === 'multi') {

            this._createCheckboxes();

        } else if (this.type === 'multi-pills' || this.type === 'input-multi') {

            const optionsContainer = document.createElement('div');
            optionsContainer.classList.add('options_container');
            this.dom.select.appendChild(optionsContainer);
            this._createCheckboxes();

        } else if (this.type === 'single' || this.type === 'input-single') {

            for (let i = 0; i < this.dom.items.length; i += 1) {
                if (this._isEllipsisActive(this.dom.items[i])) {
                    this.dom.items[i].title = this.dom.items[i].innerText;
                }
            }

        } else {

            this.dom.select.innerHTML = '<div class="invalid_select">Invalid type</div>';
        }

        if (this.type === 'input-single' || this.type === 'input-multi') {
            const icon = document.createElement('i');
            icon.classList.add('icon', 'icon-chevron-down');
            this.dom.current.appendChild(icon);
        }

        this.dom.select.classList.add('closed');
    }

    /**
     * Apply disabled state to the custom select.
     * @private
     */
    _disableState() {
        let disEl = null;

        if (this.dom.select.classList.contains('disabled')) {
            if (this.type === 'input-single' || this.type === 'input-multi') {
                disEl = this.dom.current.querySelector('input');
            } else {
                disEl = this.dom.select.querySelector('button.current-options');
            }

            disEl.tabIndex = -1;
        }
    }

    /**
     * Create checkboxes for multi select.
     * @private
     */
    _createCheckboxes() {

        // generate unique ID for each option input/label pair
        const uniqueName = Math.random().toString(36).substr(2, 5);

        for (let i = 0; i < this.dom.items.length; i += 1) {

            const checkbox = document.createElement('input');
            const label = document.createElement('label');
            const itemContent = this.dom.items[i].innerText;

            checkbox.type = 'checkbox';
            checkbox.tabIndex = -1;
            checkbox.id = uniqueName + '_' + Number(i + 1);
            label.htmlFor = uniqueName + '_' + Number(i + 1);

            label.appendChild(document.createTextNode(itemContent));

            // check if text overflows the element and add title attribute

            if (this._isEllipsisActive(this.dom.items[i])) {
                label.title = itemContent;
            }

            this.dom.items[i].innerHTML = '';
            this.dom.items[i].appendChild(checkbox);
            this.dom.items[i].appendChild(label);
        }
    }

    /**
     * Check if option name is longer than element itself.
     * @private
     * @param {HTMLElement} element - select option.
     * @return {Boolean}
     */
    _isEllipsisActive(element) {
        return (element.offsetWidth < element.scrollWidth);
    }

    /**
     * Create pill.
     * @private
     */
    _addPill() {

        if (this.selectedOptions.length) {

            for (let i = 0; i < this.selectedOptions.length; i += 1) {

                const pill = document.createElement('span');

                pill.className += 'pill removable';
                pill.tabIndex = 0;
                pill.innerHTML = this.selectedOptions[i];
                this.dom.select.querySelector('.options_container').appendChild(pill);

                if (this._isEllipsisActive(pill)) {
                    pill.title = this.selectedOptions[i];
                }
            }
        }
    }

    /**
     * Remove pills.
     * @private
     * @param {Event} evt - click event.
     */
    _removePill(evt) {

        if (evt.target.classList.contains('pill')) {

            const pillValue = evt.target.innerText;
            const itemElements = this.dom.items;

            // remove from array

            this.selectedOptions.splice(this.selectedOptions.indexOf(pillValue), 1);

            // remove from html

            evt.target.parentNode.removeChild(evt.target);

            // remove from select

            for (let i = 0; i < itemElements.length; i += 1) {
                if (itemElements[i].innerText === evt.target.innerText) {
                    itemElements[i].querySelector('input').checked = false;
                }
            }

            this._refreshMultiPlaceholder();
        }
    }

    /**
     * Add event listeners.
     * @private
     */
    _addEventListeners() {

        // prevent unwanted page's behavior

        this.dom.select.addEventListener('keydown', this.listeners.selectKeydown);

            switch (this.type) {
                case 'single':

                    this.dom.current.addEventListener('click', this.listeners.singleCurrentClick);
                    this.dom.selectOptions.addEventListener('click', this.listeners.singleSelectOptionsClick);
                    this.dom.select.addEventListener('keyup', this.listeners.singleSelectKeyup);
                    break;

                case 'multi':

                    this.dom.current.addEventListener('click', this.listeners.multiCurrentClick);
                    this.dom.selectOptions.addEventListener('change', this.listeners.multiSelectOptionsChange);
                    this.dom.select.addEventListener('keyup', this.listeners.multiSelectKeyup);
                    break;

            case 'multi-pills':

                this.dom.current.addEventListener('click', this.listeners.multiPillsCurrentClick);
                this.dom.selectOptions.addEventListener('change', this.listeners.multiPillsSelectOptionsChange);
                this.dom.select.querySelector('.options_container')
                    .addEventListener('click', this.listeners.multiPillsSelectQuerySelectorClick);
                this.dom.select.addEventListener('keyup', this.listeners.multiPillsSelectKeyup);
                break;

            case 'input-single':

                this.dom.selectOptions.addEventListener('click', this.listeners.inputSingleSelectOptionsClick);
                this.dom.current.querySelector('.icon').addEventListener('click', this.listeners.inputSingleIconClick);
                this.dom.input.addEventListener('click', this.listeners.inputSingleInputClick);
                this.dom.input.addEventListener('keyup', this.listeners.inputSingleInputKeyup);
                this.dom.select.addEventListener('keyup', this.listeners.inputSingleSelectKeyup);
                break;

            case 'input-multi':

                this.dom.input.addEventListener('click', this.listeners.inputMultiInputClick);
                this.dom.selectOptions.addEventListener('click', this.listeners.inputMultiSelectOptionsChange);
                this.dom.current.querySelector('.icon').addEventListener('click', this.listeners.inputMultiIconClick);
                this.dom.input.addEventListener('keyup', this.listeners.inputMultiInputKeyup);
                this.dom.select.querySelector('.options_container')
                    .addEventListener('click', this.listeners.inputMultiSelectQuerySelectorClick);
                this.dom.select.addEventListener('keyup', this.listeners.inputMultiSelectKeyup);
                break;
        }

        if (document.querySelector('.appcontent')) {
            document.querySelector('.appcontent').addEventListener('scroll', this.listeners.scroll);
        }

        document.addEventListener('click', this.listeners.clickedOutside);
    }

    /**
     * Remove event listeners.
     * @private
     */
    _removeEventListeners() {

        this.dom.select.removeEventListener('keydown', this.listeners.selectKeydown);

        switch (this.type) {
            case 'single':

                this.dom.current.removeEventListener('click', this.listeners.singleCurrentClick);
                this.dom.selectOptions.removeEventListener('click', this.listeners.singleSelectOptionsClick);
                this.dom.select.removeEventListener('keyup', this.listeners.singleSelectKeyup);
                break;

            case 'multi':

                this.dom.current.removeEventListener('click', this.listeners.multiCurrentClick);
                this.dom.selectOptions.removeEventListener('change', this.listeners.multiSelectOptionsChange);
                this.dom.select.removeEventListener('keyup', this.listeners.multiSelectKeyup);
                break;

            case 'multi-pills':

                this.dom.current.removeEventListener('click', this.listeners.multiPillsCurrentClick);
                this.dom.selectOptions.removeEventListener('change', this.listeners.multiPillsSelectOptionsChange);
                this.dom.select.querySelector('.options_container')
                    .removeEventListener('click', this.listeners.multiPillsSelectQuerySelectorClick);
                this.dom.select.removeEventListener('keyup', this.listeners.multiPillsSelectKeyup);
                break;

            case 'input-single':

                this.dom.selectOptions.removeEventListener('click', this.listeners.inputSingleSelectOptionsClick);
                this.dom.current.querySelector('.icon').removeEventListener('click', this.listeners.inputSingleIconClick);
                this.dom.input.removeEventListener('click', this.listeners.inputSingleInputClick);
                this.dom.input.removeEventListener('keyup', this.listeners.inputSingleInputKeyup);
                this.dom.select.removeEventListener('keyup', this.listeners.inputSingleSelectKeyup);
                break;

            case 'input-multi':

                this.dom.input.removeEventListener('click', this.listeners.inputMultiInputClick);
                this.dom.selectOptions.removeEventListener('change', this.listeners.inputMultiSelectOptionsChange);
                this.dom.current.querySelector('.icon').removeEventListener('click', this.listeners.inputMultiIconClick);
                this.dom.input.removeEventListener('keyup', this.listeners.inputMultiInputKeyup);
                this.dom.select.querySelector('.options_container')
                    .removeEventListener('click', this.listeners.inputMultiSelectQuerySelectorClick);
                this.dom.select.removeEventListener('keyup', this.listeners.inputMultiSelectKeyup);
                break;
        }

        if (document.querySelector('.appcontent')) {
            document.querySelector('.appcontent').removeEventListener('scroll', this.listeners.scroll);
        }

        document.removeEventListener('click', this.listeners.clickedOutside);
    }

    /**
     * Select option.
     * @private
     * @param {Event} evt - Field event.
     */
    _selectOption(evt) {
        let eventTarget = null;
        if (evt.type === 'keyup') {
            eventTarget = this.dom.selectOptions.querySelector('.highlight');
        } else {
            // Early exit in case dragged instead of click on single item
            if (evt.target.localName !== "label") {
                return;
            }
            eventTarget = evt.target;
        }


        switch (this.type) {
            default:
            case 'single': {
                const option = eventTarget.innerText;

                this.selectedOptions = [];

                this.dom.current.innerHTML = option;
                this.selectedOptions.push(option);
                this.events.singleOption.detail.selectedOptions = this.selectedOptions;
                document.dispatchEvent(this.events.singleOption);

                this._highlightSingleActiveOption(eventTarget);
                this._hideSelectOpt();
                break;
            }

            case 'multi':

                this._multiSelect(evt, eventTarget);
                this._refreshMultiPlaceholder();
                break;

            case 'multi-pills':

                this._multiSelect(evt, eventTarget);

                // clear container with selected options

                this.dom.select.querySelector('.options_container').innerHTML = '';

                this._addPill();
                this._refreshMultiPlaceholder();
                break;

            case 'input-single':
                if (eventTarget !== null && !eventTarget.classList.contains('no_results')) {
                    this.selectedOptions = [];

                    this._setInputValue(eventTarget);
                    this._highlightSingleActiveOption(eventTarget);
                    this._filterOptions();
                    this._hideSelectOpt();

                    this.selectedOptions.push(eventTarget.innerText.trim());
                }
                break;

            case 'input-multi':

                if (eventTarget !== null && !eventTarget.classList.contains('no_results')) {

                    this._multiSelect(evt, eventTarget);
                    this._refreshMultiPlaceholder();

                    // clear container with selected options

                    this.dom.select.querySelector('.options_container').innerHTML = '';
                    this._addPill();
                }
                break;
        }

        const selectEvent = new CustomEvent('selectOption', {
            detail: { value: this.selectedOptions }
        });
        this.dom.select.dispatchEvent(selectEvent);
    }

    /**
     * Highlight selected option.
     * @private
     * @param {Event} evt - Field event.
     */
    _highlightSingleActiveOption(evt) {

        if (!evt.classList.contains('active')) {

            const options = this.dom.selectOptions.querySelectorAll('.item');

            [].forEach.call(options, function (el) {
                el.classList.remove('active');
            });

            evt.classList.add('active');
        }
    }

    /**
     * Set select value.
     * @private
     * @param {HTMLInputElement} target - input field.
     */
    _setInputValue(target) {
        if (!target.classList.contains('no_results')) {
            this.dom.input.value = target.innerText;
        }
    }

    /**
     * Filter select options.
     * @private
     */
    _filterOptions() {

        this.dom.select.classList.add('open');
        this.dom.select.classList.remove('closed');

        const items = Array.from(this.dom.items);
        const typedValue = this.dom.input.value.toLowerCase().trim();

        this.filteredOptions = items.filter(val => val.innerText.toLowerCase().indexOf(typedValue) > -1);

        if (this.filteredOptions.length) {

            this.dom.selectOptions.innerHTML = '';

            for (let i = 0; i < this.filteredOptions.length; i += 1) {

                if (typedValue !== this.filteredOptions[i].innerText.trim().toLowerCase()) {

                    this.filteredOptions[i].classList.remove('active');

                } else {

                    this.filteredOptions[i].classList.add('active');
                }

                this.dom.selectOptions.appendChild(this.filteredOptions[i]);
            }

        } else {

            this.dom.selectOptions.innerHTML = '<div class="no_results">No results found</div>';
        }
    }

    /**
     * Select several options.
     * @private
     * @param {Event} evt - Click event.
     * @param {HTMLElement} target - select option.
     */
    _multiSelect(evt, target) {
        if (this.selectedOptions[0] == "")
            this.selectedOptions = [];
        const optionsArr = this.selectedOptions;
        let itamValue = null;

        if (evt.type === 'keyup') {

            itamValue = target.querySelector('label').innerText;

            // toggle checkbox

            target.querySelector('input').checked = !target.querySelector('input').checked;

        } else {
            itamValue = target.parentElement.querySelector('label').innerText;
        }

        if (optionsArr.indexOf(itamValue) === -1) {
            optionsArr.push(itamValue);

        } else {
            optionsArr.splice(optionsArr.indexOf(itamValue), 1);
        }
    }

    /**
     * Update placeholder for multi select.
     * @private
     */
    _refreshMultiPlaceholder() {

        const optionNumber = this.selectedOptions.length;
        const optionText = optionNumber === 1 ? ' option' : ' options';
        const placeholder = optionNumber ? optionNumber + optionText + ' selected' : 'Select options';

        if (this.type === 'multi' || this.type === 'multi-pills') {
            this.dom.current.innerHTML = placeholder;
        }

        if (this.type === 'input-multi') {
            this.dom.current.querySelector('input').placeholder = placeholder;
        }
    }

    /**
     * Keyboard accessibility.
     * @private
     * @param {Event} evt - press key.
     */
    _arrowKeyActions(evt) {

        const options = this.dom.selectOptions.querySelectorAll('.item').length - 1;

        switch (evt.key) {
            case 'Escape':
                this._hideSelectOpt();
                break;

            case 'ArrowDown':
                if (this.highlightIndex >= options) {
                    this.highlightIndex = 0;
                } else {
                    this.highlightIndex += 1;
                }
                break;

            case 'ArrowUp':
                if (this.highlightIndex <= 0) {
                    this.highlightIndex = options;
                } else {
                    this.highlightIndex -= 1;
                }
                break;

            case 'Enter':
                if (this.dom.select.classList.contains('open') && this.dom.selectOptions.querySelector('.highlight')) {
                    this._selectOption(evt);
                } else if (!this.dom.select.classList.contains('disabled')) {
                    this._toggleSelectOpt();
                }
                break;

            case ' ':
                if (this.dom.select.classList.contains('open') && this.dom.selectOptions.querySelector('.highlight')) {
                    this._selectOption(evt);
                } else if (!this.dom.select.classList.contains('disabled')) {
                    if (document.activeElement.classList.contains('pill')) {
                        this._removePill(evt);
                    } else {
                        this._toggleSelectOpt();
                    }
                }
                break;

            case 'Tab':
                this._hideSelectOpt();
                break;
        }

        this._highlightOptions();
    }

    /**
     * Highlight option when using keyboard.
     * @private
     */
    _highlightOptions() {
        const options = this.dom.selectOptions.querySelectorAll('.item');

        for (let i = 0; i < options.length; i += 1) {
            options[i].classList.remove('highlight');

            if (i === this.highlightIndex) {
                options[i].classList.add('highlight');

                if (!this._isInViewport(options[i])) {
                    this.dom.selectOptions.scrollTop = options[i].offsetTop;
                }
            }
        }
    }

    /**
     * Remove option's highlight.
     * @private
     */
    _removeOptionsHighlight() {

        const options = this.dom.items.length;

        for (let i = 0; i < options; i += 1) {
            this.dom.items[i].classList.remove('highlight');
        }

        // reset option list's scroll position
        this.dom.selectOptions.scrollTop = 0;
    }

    /**
     * Check if an option is in viewport of list.
     * @private
     * @param {HTMLElement} element - select option.
     * @return {Boolean}
     */
    _isInViewport(element) {
        const elementTop = element.offsetTop;
        const elementBottom = elementTop + element.clientHeight;
        const viewportTop = this.dom.selectOptions.scrollTop;
        const viewportBottom = viewportTop + this.dom.selectOptions.offsetHeight - 4;

        return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    /**
     * Toggle options list.
     * @private
     */
    _toggleSelectOpt() {
        this._removeOptionsHighlight();
        this.dom.select.classList.toggle('open');
        this.dom.select.classList.toggle('closed');
        this.highlightIndex = -1;
        this._optionsListPosition(this.dom.select);
    }

    /**
     * Keep options list in the viewport.
     * @private
     * @param {HTMLElement} select - html select.
     */
    _optionsListPosition(select) {
        let bottomOffset = window.innerHeight - select.getBoundingClientRect().height;

        bottomOffset = bottomOffset - select.getBoundingClientRect().top;

        const listHeight = select.querySelector('.options-list').offsetHeight + 4;

        if (bottomOffset < listHeight && select.classList.contains('open')) {
            select.classList.add('open-top');
        } else {
            select.classList.remove('open-top');
        }
    }

    /**
     * Hide options list.
     * @private
     */
    _hideSelectOpt() {
        this._removeOptionsHighlight();
        this.dom.select.classList.remove('open');
        this.dom.select.classList.add('closed');
        this.highlightIndex = -1;
    }
}
