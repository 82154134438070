import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";


const TeamLeadersListDialog = (props) => {
    const [columns, setColumns] = useState([
        {
            title: "ID",
            key: "value",
            sort: "none",
            width: "3%"
        },
        {
            title: "Name",
            key: "text",
            sort: "none",
            width: "80%"
        }


    ]);

    const dispatch = useDispatch();



    const setSelectedLeader = (e, type) => {
        const detail = e.detail;
        const data = detail.data;

        if (type === "add") {
            dispatch({ type: "SET_ASSIGNED_LEADER", payload: data });

        } else if (type === "remove") {
            dispatch({ type: "SET_ASSIGNED_LEADER", payload: {} });

        }
    }


    return (
        <EuiRow>
            <ObseleteEuiTable
                hideTableInfo={true}
                classes="tiny split-striped"
                columns={columns}
                data={props.teamLeaders}
                id={"table_" + props.id}
                selectable='single'
                selectRow={(e, type) => setSelectedLeader(e, type)}
                rowsPerPage={10}
                search={true}
                viewActionButton={true} />
            <label style={{ color: "red", fontSize: 12 }}>*only active resources with team lead checked allowed</label>
        </EuiRow>
    );
}

export default TeamLeadersListDialog;