import React from "react";
import { connect } from "react-redux";
import RecordLiveStream from "./recordLiveStream";
import SideBarLiveStream from "./sideBarLiveStream";
import SlidePopup from "./slidePopup";

class LiveStream extends React.Component {

    render() {
        return (
            <div id="page-content">
                <SlidePopup />
                <RecordLiveStream getAllLiveSession={() => this.props.getAllLiveSession()} />
                <SideBarLiveStream />             
            </div>
        );
    }
}

const mapStateToProps = state => ({ userData: state.user, sessionData: state.session });
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LiveStream);
