import React, { useState } from "react";
import { connect } from "react-redux";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import PageHelper from "../../utils/page-helper";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import EuiAccordion from "../../eui-components/accordion";
import SimpleSlider from "../../components/slider";
import EuiRow from "../../eui-components/row";
import { ToastContainer, toast } from 'react-toastify';
import constants from "../../utils/constants";
import AdvancedGridList from "../../components/advancedgridlist";
import EuiRowWithToolbar from "../../eui-components/rowwithtoolbar";
import EuiSwitch from "../../eui-components/switch";
import { constant } from "lodash";


class EhsPingChecklists extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checklistDocumentsColumns: [
                {
                    title: "Name",
                    onCreatedCell: (td, cellData) => {
                        
                        const name = this.renderName(cellData);
                        td.innerHTML = name;
                        td.querySelector("#ehsDocumentName_" + cellData.id).addEventListener('click', () => this.selectAndOpenDocument(cellData))
                    },
                    key: "name",
                    width: "10%"
                },
                {
                    title: "Status",
                    onCreatedCell: (td, cellData) => {
                        const status = PageHelper.renderActivityStatus(cellData);
                        td.innerHTML = status;
                    },
                    key: "acceptanceStatusId",
                    width: "10%"
                },
                {
                    title: "Site ASP Comments",
                    key: "siteAspComments",
                    width: "20%"
                },
                {
                    title: "RSC Engineer Comments",
                    key: "reviewComments",
                    width: "20%"
                },
                {
                    title: "RSC Engineer Name",
                    key: "rscPersonName",
                    width: "10%"
                },
            ],
            allDocument: [],
            selectedDocument: { id: 0 },
            imgIndex: 0,
            imgUrlLength: 0,
            totalCount: "",

            openDialogPreview: false,
            viewMod: constants.documentsView.GRID,
            approveExcludeSwitchRef: false,
            hideEmpty: false,
            IsErisiteHighRiskItem: false

        }
    }

    componentDidMount() {
        
        if (this.props.ehsPingData.ehsPing &&
            this.props.ehsPingData.ehsPing.checklists &&
            this.props.ehsPingData.ehsPing.checklists.map(item => item.documents && item.documents.length > 0).length > 0) {
            const allDocument = this.props.ehsPingData.ehsPing
                .checklists.map(item => item.documents)
                .reduce((a, b) => { return a.concat(b); }) ?? [];
            this.setState({ allDocument, imgUrlLength: allDocument.length });
        }
    }

    renderName = (rowData) => {
        const view = PageHelper.getEDSDocumentType(rowData.type);
        let color = PageHelper.getEDSDocumentTypeColor(rowData.type);
        return `<a id="ehsDocumentName_${rowData.id}" class="flex-start-content ${color}">${view}<span>${rowData.name}</span></a>`;
    }

    renderChecklists = () => {
        if (!this.props.ehsPingData.ehsPing ||
            !this.props.ehsPingData.ehsPing.checklists ||
            this.props.ehsPingData.ehsPing.checklists.length === 0
        ) {
            return <div className="flex-middle-content m-3 w-10 text-lg">No checklists have found.</div>
        }
        const viewMod = this.state.viewMod;
        const checklists = [... this.props.ehsPingData.ehsPing.checklists];
        const checklistAccordions = checklists.map(item => {
            const checklistItem = {
                id: "ehsPingChecklist_" + item.id,
                hidden: this.isHiddenChecklist(item),
                title: this.getChecklistName(item),
                titleStyle: { fontWeight: 700, padding: 3 },
                contentStyle: { paddingLeft: 10 },
                content: <>
                    <div className={viewMod !== constants.documentsView.LIST ? "hidden" : ""}>
                        <ObseleteEuiTable
                            classes="tiny"
                            columns={this.state.checklistDocumentsColumns}
                            data={this.getDocuments(item)}
                            id={"ehsPingChecklistTable_" + item.id}
                            scroll={true}
                            sortable={true}
                        >
                        </ObseleteEuiTable>
                    </div>
                    {viewMod !== constants.documentsView.GRID ? null :
                        <AdvancedGridList
                            tileData={this.getDocuments(item)}
                            viewOnly={true}
                            onClickImage={(tile) => { this.selectAndOpenDocument(tile) }}
                            type={3}
                        />
                    }
                </>
            }
            return checklistItem;
        });

        return <EuiAccordion id="ehsPingChecklistAccordion"
            style={{ width: "100%"}}
            items={checklistAccordions}
        />;
    }

    getChecklistName = (rowData) => {
        const color = ActivityInboxHelper.getSubActivityColor(rowData);
        const icon = color === "" ? "icon-circle" : "icon-alarm-level5 color-" + color;
        return <i className={"flex-middle-content text-sm icon " + icon}>
            <span className="subactivity-title">
                {rowData.name + " ( " + (rowData.documents ?? []).length + " ) "}
            </span>
        </i>
    }

    getDocuments = (checklistItem) => {
        if ((checklistItem.documents || []).length === 0) {
            return [];
        }
        return !this.state.approveExcludeSwitchRef ?
            checklistItem.documents :
            checklistItem.documents.filter(item => item.acceptanceStatusId !== 2);
    }

    isHiddenChecklist = (checklist) => {
        return (this.state.approveExcludeSwitchRef && ((checklist.documents || []).length === 0
            || checklist.documents?.filter(doc => doc.acceptanceStatusId !== constants.acceptanceStatusTypes.ACCEPTED).length === 0))
            || (this.state.hideEmpty && ((checklist.documents || []).length === 0))
            || (this.state.IsErisiteHighRiskItem && !checklist.isErisiteHighRiskItem)
            
    }

    renderFilePreview = () => {
        const allDocument = [...this.state.allDocument];
        const comments = this.state.selectedDocument.acceptanceComments ?? "";

        return <SimpleSlider
            id="ehsPingSlider"
            slideData={allDocument}
            currentSlide={this.state.selectedDocument}
            initialSlide={this.state.imgIndex}
            totalCount={this.state.totalCount}
            onNext={this.nextClick}
            onPrev={this.prevClick}
            onClose={this.toogleModal}
            status={PageHelper.getActivityStatus(this.state.selectedDocument.acceptanceStatusId)}
            comment={comments}
            setImageTrack={(slide) => this.setImageTrack(slide)}
            commentTitle="RSC Engineer Comments"
            siteComment={this.state.selectedDocument.ohsSiteComment}
            type={3}
        />
    }

    selectAndOpenDocument = (document) => {
        const rowData = this.state.allDocument.find(item => item.id === document.id);
        if (!rowData) {
            toast.error("Coudn't open the file!", { containerId: 'ED', position: toast.POSITION.TOP_CENTER });
            return;
        }
        this.setImageTrack(rowData);
    }

    setImageTrack = (slide) => {
        const allDocument = [...this.state.allDocument];
        const imgUrlLength = allDocument.length;
        let imgIndex = allDocument.findIndex(item => item.id === slide.id);
        this.setState({ selectedDocument: slide, imgIndex, imgUrlLength, totalCount: (imgIndex + 1) + "/" + imgUrlLength }, () => { this.setState({ openDialogPreview: true }); });
    }

    nextClick = () => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === prevState.imgUrlLength - 1 ? 0 : prevState.imgIndex + 1,
            };
        }, () => {
            const selectedDocument = this.state.allDocument[this.state.imgIndex];
            
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument });
        });
    }

    prevClick = () => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === 0 ? prevState.imgUrlLength - 1 : prevState.imgIndex - 1,
            };
        }, () => {
            const selectedDocument = this.state.allDocument[this.state.imgIndex];
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument })
        });
    }

    toogleModal = () => {
        this.setState({ openDialogPreview: false });
    }

    render() {
        const viewMod = this.state.viewMod;
        return <EuiRowWithToolbar className="responsive-toolbar-row" leftIcons={<>
            <i className={"icon icon-view-list icon-lg m-1 " + (viewMod === constants.documentsView.LIST ? "fw-3" : "")} onClick={() => this.setState({ viewMod: constants.documentsView.LIST })}></i>
            <i className={"icon icon-view-tiles icon-lg m-1 " + (viewMod === constants.documentsView.GRID ? "fw-3" : "")} onClick={() => this.setState({ viewMod: constants.documentsView.GRID })}></i></>}
            rightIcons={<><EuiSwitch id="ehsPingViewApproveExcludeSwitchRef"
                checked={this.state.approveExcludeSwitchRef}
                onChange={(e) => this.setState({ approveExcludeSwitchRef: e.target.checked })}
                dataEnabled="Excluding Accepted"
                dataDisabled="Including Accepted"
                className="p-1"
            />
                <EuiSwitch checked={this.state.hideEmpty}
                    name="hideEmptySwitchEhsView"
                    onChange={(e) => this.setState({ hideEmpty: e.target.checked })}
                    dataEnabled="Hiding Empty"
                    dataDisabled="Showing Empty"
                    className="p-1"
                />
                <EuiSwitch 
                 id="ehsPingHideRiskSeverityStatus"
                    checked={this.state.IsErisiteHighRiskItem}
                    name="hideRiskSeverityStatus"
                    onChange={(e) => this.setState({ IsErisiteHighRiskItem: e.target.checked })}
                    dataEnabled="High Risk is ON"
                    dataDisabled="High Risk is OFF"
                    className="p-1"
                />

            </>}
        >
            {this.renderChecklists()}
            {this.state.openDialogPreview ?
                this.renderFilePreview()
                :
                null
            }
        </EuiRowWithToolbar>
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ehsPingData: state.ehsPing });
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EhsPingChecklists); 