import React from "react";
import UserManualDocuments from './UserManualDocuments';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class UserManuals extends React.Component {
    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
    }
    render() {
        return (<UserManualDocuments />);
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, userAdmin: state.userAdmin });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(UserManuals));