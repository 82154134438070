import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import EuiTile from "../../eui-components/tile";
import PageHelper from "../../utils/page-helper";
import VehicleInboxMenu from "./vehicleInbox.js";
import constants from '../../utils/constants';
import RateInspection from "./rateInspection.js"

class VehicleInbox extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        PageHelper.getCoordinates();
    }

    renderMod = () => {
        
        if (this.props.mod === constants.pageMods.EDIT) {
            return <RateInspection />
        }
        else {
            return <VehicleInboxMenu />
        }
    }
    render() {
        return (<EuiTile fullscreen={true} tileStyle={{ padding: 0 }}>{this.renderMod()}</EuiTile>);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, imInboxData: state.imInbox, mod: state.vehicle.inspectionMod})
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(VehicleInbox)));