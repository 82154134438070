import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import localforage from 'localforage';
import thunk from 'redux-thunk';
import rootReducer from './reducers/rootReducer';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';

const persistConfig = {
    key: 'root',
    storage: localforage,
    whitelist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer);


 const  store = createStore(
    persistedReducer,
    {},
    applyMiddleware(thunk)
);

 const persistor = persistStore(store);


const { dispatch } = store;

const useSelector = useAppSelector;

const useDispatch = () => useAppDispatch();
export { store, persistor, dispatch, useSelector, useDispatch };
