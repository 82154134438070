import React, { useState } from "react"
import EuiTile from "../../eui-components/tile";
import { useSelector, useDispatch } from "react-redux";
import { Notification } from '@eds/vanilla';
import PageHelper from "../../utils/page-helper";
import ObseleteEuiTable from '../../eui-components/obseleteTable';
import constants from "../../utils/constants";
import SasHelper from "../../utils/sas-helper";

const ComplaintDocuments = (props) => {
    const [files, setFiles] = useState([]);
    const sasToken = useSelector(state => state.complaints.sasToken);
    const dispatch = useDispatch();
    const complaint = useSelector(state => state.complaints.addedComplaint);
    const deleteDocument = (cellData) => {
        setFiles(files.filter(i => i.lastModified != cellData));
        dispatch({ type: "SET_COMPLAINT_DOCUMENTS", payload: files.filter(i => i.lastModified != cellData) });
    }
    const download = (fileName, fileTitle) => {
        var FileSaver = require('file-saver');
        FileSaver.saveAs(SasHelper.addSasKey(fileName , sasToken), fileTitle);
    }
    const onFilesUpload = (e) => {
        let tempFiles = [];
        Array.from(e.target.files).map(file => {
            tempFiles.push(file)
        });
        e.target.value = "";
        tempFiles.map(i => {
            if (i.name.split('.')[1] == null || i.name.split('.')[1] == 'exe' || i.name.split('.')[1] == 'zip') {
                const notification = new Notification({
                    title: 'File uploaded is not allowed!',
                    timeout: 50000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
                tempFiles = tempFiles.filter(m => m != i);
                return;
            }
        });
        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            const notification = new Notification({
                title: 'Upload Failed',
                description: fileSize,
                timeout: 5000,
                icon: 'icon-triangle-warning'
            });
            notification.init();
            return
        }
        if (files?.length > 0) {
            setFiles(items => [...items, ...tempFiles])
            dispatch({ type: "SET_COMPLAINT_DOCUMENTS", payload: [...files, ...tempFiles] });
        }
        else {
            setFiles(tempFiles);
            dispatch({ type: "SET_COMPLAINT_DOCUMENTS", payload: tempFiles });
        }
    }
    return (<EuiTile fullscreen>
        {props.mod === constants.actionMods.VIEW_ONLY ? (<div>
            <ObseleteEuiTable
                data={complaint.complaintDocuments}
                id="complaintDocumentsTableId"
                columns={[
                    /*{ title: "", key: "type", width: "2%", onCreatedCell: (td, cellData) => { td.innerHTML = PageHelper.renderDocumentIcon(cellData); } },*/
                    {
                        title: "Name",
                        key: "documentLink",
                        width: '80%',
                        onCreatedCell: (td, cellData) => {
                            var fileTitle = complaint.complaintDocuments.find(e => e.documentLink == cellData)?.documentName;
                            td.innerHTML = `<span><a style="font-family: Ericsson Hilda" id="evidenceDoc_">${fileTitle}</a></span>`;
                            td.querySelector("#evidenceDoc_").addEventListener('click', () => download(cellData, fileTitle))
                        }
                    },
                    {
                        title: "Type",
                        key: "documentType",
                        width: '20%'
                    }
                ]}
                classes="tiny"
                scroll={false}
                sortable={false}
                paginated={false}
                key={complaint}
            />
        </div>) :  <><label htmlFor="uploadComplaintDocs">
            <input style={{ width: "60%", margin: "5px", fontFamily: "Ericsson Hilda" }} multiple id="uploadComplaintDocs" name="files" onChange={(e) => onFilesUpload(e)} type="file" /><span style={{ margin: "5px" }} className="btn"><i className="icon icon-upload"></i>Upload Documents</span></label>
                <div fullscreen style={props.mod == constants.actionMods.RESOLVE ? { height: "100px", width: "400px" } : { height: "300px"}}>
            {files?.length > 0 ?
                (<ObseleteEuiTable
                    data={files.map(i => { return { name: i.name, lastModified: i.lastModified } })}
                    columns={[{ title: "", key: "name", width: "97%" }, {
                        title: "", key: "lastModified", width: "2%", onCreatedCell: (td, cellData) => {
                            td.innerHTML = `<span id="deleteDocTooltip_" class="tooltip"><i id="deleteDoc_" class="icon icon-cross text-lg clickable color-red "></i><span class="message top-end">Delete</span></span>`
                            td.querySelector("#deleteDocTooltip_").addEventListener('click', () => deleteDocument(cellData));
                        }
                    }]}
                    key={files}
                    classes="tiny"
                    id="complaintDocsTable"
                />)
                : <></>}
        </div></>}
        { /* Supporting docs here*/}
    </EuiTile>)
    }
export default ComplaintDocuments;