const initialState = {
    filter: { endDate: null, startDate: null },
    rawData: [],
    hasData: false,
    dateSelected: {
        startDate: null,
        endDate: null
    },
    productivityTeamLeaders: [],
    rscCountries: [],
    kpis: {},
    sitesDetails: {},
    isLoading: true,
    resources: [],
    selectedGroupingColumns: ["resourceId"],
    actualGroupingColumns: ["resourceId"],
    filterControls: [],
    trendData: {},
    detailedActivities: [],
    detailedPings: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_PRODUCTIVITY_DASHBOARD_FILTER":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_RSC_COUNTRIES":
            return {
                ...state,
                rscCountries: action.payload
            }
        case "SET_PRODUCTIVITY_RAW_DATA":
            return {
                ...state,
                rawData: action.payload
            };
        case "SET_PRODUCTIVITY_KPIS":
            return {
                ...state,
                kpis: action.payload
            };
        case "SET_PRODUCTIVITY_DASHBOARD_FILTER":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_PRODUCTIVITY_DASHBORAD_TEAM_LEADERS":
            return {
                ...state,
                productivityTeamLeaders: action.payload
            }
        case "SET_PRODUCTIVITY_DASHBOARD_ISLOADING":
            return {
                ...state,
                isLoading: action.payload
            }
        case "SET_PRODUCTIVITY_SELECTED_DATES":
            return {
                ...state,
                dateSelected: action.payload
            }
        case "SET_PRODUCTIVITY_TEAM_GROUPING_COLUMNS":
            return {
                ...state,
                selectedGroupingColumns: action.payload
            }
        case "SET_PRODUCTIVITY_ACTUAL_TEAM_GROUPING_COLUMNS":
            return {
                ...state,
                actualGroupingColumns: action.payload
            }
        case "SET_PRODUCTIVITY_FILTER_CONTROLS":
            return {
                ...state,
                filterControls: action.payload
            }
        case "SET_PRODUCTIVITY_KPIS_DETAILS":
            return {
                ...state,
                sitesDetails: action.payload
            };
        case "SET_PRODUCTIVITY_ALL_RESOURCES":
            return {
                ...state,
                resources: action.payload
            }
        case "SET_TREND_DATA":
            return {
                ...state,
                trendData: action.payload
            }
        case "SET_PRODUCTIVITY_DETAILED_ACTIVITIES":
            return {
                ...state,
                detailedActivities: action.payload
            }
        case "SET_PRODUCTIVITY_DETAILED_PINGS":
            return {
                ...state,
                detailedPings: action.payload
            }
        default: { return state; }
    }
}