import React, { useState, useEffect } from "react";
import UploadUserManuals from './UploadUserManuals'
import ApiClient from "../../utils/api-client";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EuiTile from "../../eui-components/tile";
import { withLayout } from "../../layout";
import EuiAccordion from '../../eui-components/accordion';
import PageHelper from "../../utils/page-helper";
import EuiLoading from "../../eui-components/loading";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiRow from "../../eui-components/row";
import EuiProgressBar from "../../eui-components/progressbar";
import { Notification } from '@eds/vanilla';
import constants from "../../utils/constants";
import _ from "lodash";
import DialogModal from "../../components/dialog";
import SasHelper from "../../utils/sas-helper";

const UserManualDocuments = (props) => {
    const userAdmin = useSelector(state => state.userAdmin);
    const loginRoles = useSelector(state => state.userAdmin.loginRoles);
    const userManuals = useSelector(state => state.userManuals);
    const userData = useSelector(state => state.user);
    const [openDialog, setOpenDialog] = useState(false);
    const [userManualsList, setUserManualsList] = useState([]);
    const [percentageArr, setPercentageArray] = useState([]);
    const [openDiaolgUploadBar, setOpenDialogUploadBar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [jobRoles, setJobRoles] = useState([]);
    const [SASToken, setSASToken] = useState("");
    const dispatch = useDispatch();
    const files = useSelector(state => state.userManuals.userManuals);
    let userManualsIntialList = []
    const userRoles = userData.loginRoles.map(i => i.value);
    const userManualsColumns = [
        {
            title: "",
            key: "icon",
            width: "10px",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = PageHelper.renderManualType(cellData)
            }
        },
        {
            title: "",
            key: "name",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    const cellDataName = cellData[0];
                    const cellDataLink = cellData[1];
                    td.innerHTML = `<a id="link_"> ${cellDataName}</a>`;
                    td.querySelector("#link_").addEventListener('click', () => window.open(SasHelper.addSasKey(cellDataLink , SASToken), "_blank"));
                }
            }
        }
    ];
    
    useEffect(() => {
        if (!SASToken) {
            setIsLoading(true);
            SasHelper.getSasKey().then(response => {
                setIsLoading(false);
                setSASToken(response);
            })
        }
    } , [SASToken]);

    useEffect(() => {
        if (jobRoles.length === 0 && userAdmin.loginRoles.length != 0) {
            setJobRoles(loginRoles.map(x => { return { value: x.id, text: x.loginName } }))
        }
        if (userManualsList.length === 0 && jobRoles.length != 0 && userRoles != null) {
            setIsLoading(true);
            ApiClient.post("UserManuals/GetUserManualsByListOfRoles", { listOfJobRoles: userRoles }).then(response => {
                setIsLoading(false);
                var sortedArray = sortByKey(response.data, "docName")
                setUserManualsList(_.groupBy(sortedArray, 'jobRole'));
            }).catch(error => console.error(error))
        }
        if (openDiaolgUploadBar) {
            const onProgress = createPercentage;
            PageHelper.uploadItems(files, onProgress, "user-manuals").then(uploadedFiles => {
                const notification = new Notification({
                    title: 'Document is uploaded successfully!',
                    timeout: 5000,
                    icon: 'icon-cross'
                });
                notification.init();
                setOpenDialogUploadBar(false);
                userManualsIntialList = uploadedFiles;
                setIsLoading(true)
                addUserManuals();
            })
        }
    }, [openDiaolgUploadBar, jobRoles, isLoading]);
    const sortByKey = (array, key) => {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];

            if (typeof x == "string") {
                x = ("" + x).toLowerCase();
            }
            if (typeof y == "string") {
                y = ("" + y).toLowerCase();
            }

            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }
    const createPercentage = (progressEvent, file) => {
        
        let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
        let percentageArray = [...percentageArr];
        const index = percentageArray.indexOf(file);
        percentageArray[index].percentage = percentage;
        setPercentageArray(percentageArray);
    }

    const addUserManuals = () => {
        if (!userManualsList) {
            const notification = new Notification({
                title: 'Upload Failed',
                description: 'No selected files to be uploaded!',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return;
        }
        const jobRolesValues = [];
        userManuals.selectedJobRoles.map(i => {
            jobRolesValues.push(jobRoles.find(x => x.text == i).value)
        });
        const docs = userManualsIntialList.map(item => {
            return {
                docName: userManuals.documentName,
                docLink: SasHelper.addSasKey(item.documentPath, SASToken),
                createUser: parseInt(userData.userData.id),
                listOfJobRoles: jobRolesValues
            }
        })
        setOpenDialog(false);
        ApiClient.post("UserManuals/AddUserManuals", docs[0])
            .then(response => {
                ApiClient.post("UserManuals/GetUserManualsByListOfRoles", { listOfJobRoles: userRoles }).then(response => {
                    var sortedArray = sortByKey(response.data, "docName")
                    setUserManualsList(_.groupBy(sortedArray, 'jobRole'));
                    dispatch({ type: "SET_USER_MANUALS", payload: [] });
                    dispatch({ type: "SET_SELECTED_JOB_ROLES", payload: [] });
                    dispatch({ type: "SET_ITEM_NAME", payload: "" });
                    setIsLoading(false);
                    viewUserManuals();
                }).catch(error => console.error(error))
            }).catch(error => {
                
                setIsLoading(false);
            });
    }

    const uploadDocuments = () => {
        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            const notification = new Notification({
                title: 'Upload Failed',
                description: fileSize,
                timeout: 5000,
                icon: 'icon-triangle-warning'
            });
            notification.init();
            return;
        }
        if (userManuals.selectedJobRoles?.length === 0) {
            const notification = new Notification({
                title: 'Upload Failed',
                description: 'Please add all the mandatory fields!',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
            return
        }
        else {
            PageHelper.getCoordinates();
            setOpenDialogUploadBar(true);
            setPercentageArray(PageHelper.initializePercentageArr(files))
        }
    }

    const viewUserManuals = () => {
        let dataItems = [];
        let rows = [];
        for (const [key, value] of Object.entries(userManualsList)) {
            dataItems = value.map(item => {
                return {
                    icon: item.type,
                    name: [item.docName, item.docLink]
                }
            })
            rows.push({
                opened: "opened",
                key: key,
                style: { width: "100%" },
                id: key + "_item",
                title: jobRoles.find(x => x.value == key)?.text,
                titleStyle: {
                    fontWeight: 500,
                },
                style: { width: "100%" },
                contentStyle: {
                    paddingLeft: "15px",
                    paddingRight: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                content: <ObseleteEuiTable
                    classes="compact"
                    columns={userManualsColumns}
                    data={dataItems}
                    id={"_" + key}
                    style={{ padding: 0 }}
                    styleHeader={{ width: "100%", paddingLeft: "15px" }}
                    styleTd={{ width: "100%", paddingLeft: "15px", paddingRight: 0, paddingTop: 0, paddingLeft: 0 }}
                    scroll={true}
                    key={userManualsList}
                    sortable={true}
                    paginated={false}
                    hideTableInfo={true}
                />
            })
        }
        return (
            <EuiTile tileStyle={{ padding: 0 }} className={isLoading ? " disabled " : ""}  >
                <EuiAccordion id="userManualsAccordion"
                    noAutoClose={true}
                    style={{ width: "100%", height: "350px" }}
                    items={rows}
                />
            </EuiTile>);
    }
    return (
        <EuiTile fullscreen tileStyle={{ padding: 0 }} className={isLoading ? " disabled " : ""} key={isLoading} >
            <EuiRow>
                <EuiLoading id="userManualsLoading" isLoading={isLoading} />
                <div className="flex-end-content w-10">
                    <button style={{ float: "right", margin: "10px" }} hidden={(userData.userData.jobRole != constants.userRoles.ADMIN)} className="btn primary m-1 " onClick={() => setOpenDialog(true)}>
                        <i className="icon icon-file-add p-1" />Add User Manual</button></div>
                <EuiRow>

                    {(Object.values(userManualsList).length !== 0) ? viewUserManuals() : <div className="flex-middle-content m-2 w-10" style={{ fontSize: "18px", color: "gray" }}>No documents attached to your job role...</div>}
                </EuiRow>
                <DialogModal
                    content={percentageArr.map(file => {
                        return <div key={file.name} style={{ width: 500 }}>
                            <label><strong>{userManuals.documentName}</strong></label>
                            <EuiProgressBar value={file.percentage} />
                        </div>
                    })}
                    handleClose={() => setOpenDialogUploadBar(false)}
                    id="uploadBarUserManualUploadDialog"
                    titleId="titleuploadBarUserManualUploadDialog"
                    open={openDiaolgUploadBar}
                    buttonsVisible="false"
                    title="Progress Loading..."
                    closeButtonVisible="false"
                >
                </DialogModal>
                <DialogModal
                    id="userManualDocumentDialog"
                    style={{ height: "1000px" }}
                    title=""
                    content={<UploadUserManuals id="userManualDocumentDialog" jobRoles={jobRoles} />}
                    buttonName="Upload"
                    handleClose={() => setOpenDialog(false)}
                    onClick={() => uploadDocuments()}
                    contentStyle={{ width: "800px", height: "200px" }}
                    open={openDialog}
                />
            </EuiRow>
        </EuiTile>
    );
}

export default withRouter(withLayout(UserManualDocuments));