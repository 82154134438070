import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../../layout";

import EuiTile from "../../../eui-components/tile";
import ProductivityDashboardPanels from "./productivityDashboardPanels";

import ApiClient from "../../../utils/api-client";
import PageHelper from "../../../utils/page-helper";

class ProductivityDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        PageHelper.getCoordinates();
        this.setState({ isLoading: true }, () => {
            ApiClient.post("IncentivesPlan/GetRscCountries")
                .then(_response => {
                    this.props.setRscCountries(_response.data);
                    ApiClient.post("User/GetCountriesWithDetails")
                        .then(response => {
                            this.props.setAllCountries(response.data);
                            this.props.setAllCustomers(response.data?.map(c => c.customers)?.reduce((a, b) => a.concat(b)));
                            this.setState({ isLoading: false });
                        }).catch(error => {
                            console.error(error);;
                            alert("Failed to load data. please refresh your screen!");
                        });
                }).catch(error => {
                    console.error(error);;
                    alert("Failed to load data. please refresh your screen!");
                });
        });
    }

    renderMod = () => {
        return <ProductivityDashboardPanels key={this.state.isLoading} />
    }

    render() {
        return (<EuiTile fullscreen={true} tileStyle={{ padding: 0 }}>{this.renderMod()}</EuiTile>);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, imInboxData: state.imInbox });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setAllCountries: (countries) => dispatch({ type: "SET_ALL_COUNTRIES", payload: countries }),
        setAllCustomers: (customers) => dispatch({ type: "SET_ALL_CUSTOMERS", payload: customers }),
        setRscCountries: (rscCountries) => dispatch({ type: "SET_RSC_COUNTRIES", payload: rscCountries })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(ProductivityDashboard)));