import React from "react";

export default class EuiTile extends React.Component {

    render() {
        const { sm, md, lg, xl } = this.props;

        return (
            <div className={`sm-${sm} md-${md} lg-${lg} xl-${xl} ${(this.props.className ?? "")}`}
                id={this.props.id} style={this.props.style} onClick={() => this.props.onClick && this.props.onClick()}>
                <div className={"tile " + (this.props.fullscreen ? " fullscreen " : "") + (this.props.tileClassName ?? "")} style={{ padding: 5, ...(this.props.tileStyle ?? {}) }}>
                    {this.props.title ?
                        <div className="header" style={{ paddingBottom: 5, ...(this.props.headerStyle ?? {}) }}>
                            <div className="left">
                                <div className={"title " + (this.props.titleClassName ?? "")} style={this.props.titleStyle}>{this.props.title}</div>
                                <div className="subtitle">{this.props.subTitle}</div>
                            </div>
                            <div className="right">
                                {this.props.rightIcon}
                            </div>
                        </div>
                        : ""
                    }
                    <div className={"content " + (this.props.contentClassName ?? "")} style={{ ...this.props.styleContent }}>{this.props.children}</div>
                </div>
            </div >
        );
    }
}

EuiTile.defaultProps = {
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12
};
