import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import constants from "../../utils/constants";
import UserList from "./userList";
import UserDetails from "./userDetails";
import ApiHelper from "../../utils/api-helper";
import { ToastContainer } from 'react-toastify';
import UserAdminHelper from "../../utils/user-admin-helper";
import ApiClient from "../../utils/api-client";


class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        this.setState({ isLoading: true }, () => {
            ApiHelper.setCountriesWithDetails((data) => this.props.setAllCountries(data), (data) => this.props.setAllCustomers(data));
            this.setState({ isLoading: false });
        });
    }

    getUserList = (cache = true) => {
        this.setState({ isLoading: true }, () => {
            ApiClient.post("UserAdmin/GetUserAdminList", { cache })
                .then(response => {
                    const users = response.data;
                    UserAdminHelper.mapUsers(users);
                    this.props.setUserList(users);
                    this.setState({ isLoading: false });
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    renderMod = () => {
        if (this.props.userAdminData.mod === constants.pageMods.LIST) {
            return <UserList key={this.state.isLoading} getUserList={this.getUserList} isLoading={this.state.isLoading} />;
        } else {
            return <UserDetails getUserList={this.getUserList} />
        }
    }

    render() {
        return (<>
            {this.renderMod()}
            <ToastContainer enableMultiContainer containerId={'UA'} autoClose={2500} />
        </>);
    }

}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, userAdminData: state.userAdmin });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setUserList: (users) => dispatch({ type: "SET_USER_LIST", payload: users }),
        setUserLoginRoles: (roles) => dispatch({ type: "SET_USER_LOGIN_ROLES", payload: roles }),
        setAllCountries: (countries) => dispatch({ type: "SET_ALL_COUNTRIES", payload: countries }),
        setAllCustomers: (customers) => dispatch({ type: "SET_ALL_CUSTOMERS", payload: customers }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(Users)));