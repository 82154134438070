import React from "react";
import { Dialog } from "@eds/vanilla/dialog/Dialog";

class EuiDialog extends React.Component {
    componentDidMount() {
        if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new Dialog(element);
                    component.init();
                });
            }
        }
        else {
            console.error("You should set an id to Dialog component");
        }
    }

    onClickUpload = () => {
        if (this.props.onClick) {
            this.props.onClick();
            if (this.props.done)
                this.inputElement.click();
        }
    }

    render() {
        return (
            <>
                <div id={this.props.id} className="dialog" data-trigger={this.props.dataTrigger} data-type="simple">
                    <div className="content" style={this.props.contentStyle}>
                        <div className="top">
                            <div className="title">{this.props.title}</div>
                        </div>
                        <div className="body" style={this.props.bodyStyle}>
                            {this.props.body}
                        </div>
                        <div className="bottom">
                            <button type="button" className="btn ghost" data-close="true" ref={input => this.inputElement = input} onClick={() => this.props.onCancel && this.props.onCancel()}> Cancel</button>
                            <button type="submit" className={"btn " + this.props.type ?? "primary"} onClick={this.onClickUpload} >{this.props.buttonName}</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
export default EuiDialog;