import React from "react";
import EuiRow from "../../../eui-components/row";
import EuiMultiPanelTile from "../../../eui-components/multipaneltile";
import { useSelector } from "react-redux";
import ProductivityDashboardIndicator from "./productivityDashboardIndicators";
import ProductivityDashboardFilter from "./productivityDashboardFilter";

const ProductivityDashboardPanels = (props) => {
    const productivityDashboardData = useSelector(state => state.productivityDashboard);
    const incentives = productivityDashboardData.kpis || [];
    const leftPanel = {
        title: "Filter By", content: <ProductivityDashboardFilter />,
        style: { width: "400px" }
    };
    const midPanel = {
        title: <div className="flex-start-content "><div className="mr-2" style={{ color: "gray", fontWeight: 100 }}>
            {(!productivityDashboardData.dateSelected?.startDate || !productivityDashboardData?.dateSelected?.endDate ? "" : new Date(productivityDashboardData?.dateSelected?.startDate).toDateString() + "-" + new Date(productivityDashboardData?.dateSelected?.endDate).toDateString())}
        </div> </div>,
        content: <ProductivityDashboardIndicator data={incentives} />,
    };

    return (<EuiRow style={{ zoom: "0.9" }}>
        <EuiMultiPanelTile id="productivityDashbordMultiPanel"
            midPanelButton={true}
            leftPanel={leftPanel}
            midPanel={midPanel}
            leftPanelStyle={{ width: "400px" }}
        />
    </EuiRow>);
}
export default ProductivityDashboardPanels;