const initialState = {
    mod: 1,
    templates: [],
    customers: [],
    projects: [],
    itemsGroups: [],
    selectedTemplate: {},
    item: {},
    fillData: false,
    allTemplates: [],
    selectedTemplates: []
};


export default (state = initialState, action) => {
    switch (action.type) {
        case "CHANGE_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_TEMPLATES":
            return {
                ...state,
                templates: action.payload
            }
        case "SET_ITEM":
            return {
                ...state,
                item: action.payload
            }
        case "SET_SELECTED_TEMPLATE":
            return {
                ...state,
                selectedTemplate: action.payload
            }
        case "SET_SELECTED_TEMPLATES":
            return {
                ...state,
                selectedTemplates: action.payload
            }
        case "SET_DATA":
            return {
                ...state,
                projects: action.payload.projects,
                customers: action.payload.customers,
                categories: action.payload.categories,
                workManuals: action.payload.workManuals,
                itemsGroups: action.payload.itemsGroups,
                viCodes: action.payload.viCodes
            }
        case "SET_ALL_TEMPLATES":
            return {
                ...state,
                allTemplates: action.payload
            }
        default:
            return state;
    }
}