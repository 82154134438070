import React, { useState, useEffect } from "react";
import UploadDocuments from "../../components/documentUploadModal/documentsUploader";
import ApiClient from "../../utils/api-client";
import { useSelector, useDispatch } from "react-redux";
import EuiTile from "../../eui-components/tile";
import PageHelper from "../../utils/page-helper";
import EuiLoading from "../../eui-components/loading";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiRow from "../../eui-components/row";
import EuiProgressBar from "../../eui-components/progressbar";
import DateUtils from "../../utils/date-utils";
import { Notification } from '@eds/vanilla';
import constants from "../../utils/constants";
import _ from "lodash";
import DialogModal from "../../components/dialog";
import SasHelper from "../../utils/sas-helper";

const CustomerDocuments = () => {
    const userData = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [openDialog, setOpenDialog] = useState(false);
    const selectedCustomer = useSelector(state => state.customers.selectedCustomer);
    const [customerDocumentsList, setCustomerDocumentsList] = useState([]);
    const [percentageArr, setPercentageArray] = useState([]);
    const [openDiaolgUploadBar, setOpenDialogUploadBar] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const files = useSelector(state => state.documents.documents);
    const [editMod, setEditMod] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [editTitle, setEditedTitle] = useState("");
    const [selectedCustomerState, setSelectedCustomerState] = useState();
    const [editType, setEditedType] = useState(null);
    const [warning, setOpenWarning] = useState(false);
    const [removedItem, setDeletedItem] = useState(null);
    const [SASToken, setSASToken] = useState();
    const documentTypes = constants.cmsDocumentTypes;
    let documentsIntialList = []
    const documentsColumns = [
        {
            title: "Name",
            key: "documentNameId",
            sort: "none",
            hideFilter: editMod ? true : false,
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var docId = cellData.split("-@-")[1]
                    
                    var document = customerDocumentsList.find(doc => doc.id == docId);
                    const cellDataName = document.title;
                    const cellDataLink = document.documentPath;
                    if (editMod && document && document.edit && (document.editId == docId)) {
                        let documentName = "";
                        if (editTitle) {
                            documentName = editTitle;
                        }
                        else {
                            documentName = document.title
                        }
                        td.innerHTML = `<span><input style = "width: 90%;" type="text" id="update_" value="${documentName}" ></input>`;
                        td.querySelector("#update_").addEventListener('change', (e) => update(e, "title"));
                    }
                    else {
                        td.innerHTML = `<a id="link_"> ${cellDataName}</a>`;
                        td.querySelector("#link_").addEventListener('click', () => window.open(SasHelper.addSasKey(cellDataLink , SASToken), "_blank"));
                    }
                }
            },
        },
        {
            title: "Document Type",
            key: "documentTypeId",
            sort: "none",
            width: "20%",
            hideFilter: editMod ? true : false,
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var docId = cellData.substring(0, cellData.indexOf("@"))
                    var document = customerDocumentsList.find(doc => doc.id == docId);
                    
                    if (editMod && document && document.edit && (document.editId == docId)) {
                        var selectedItem = "";
                        var optionsList = "";
                        var option = "";
                        var value = "";
                        (editType != null) ? value = editType : value = document.documentTypeName;
                        documentTypes.map(item => {
                            if (item.text.trim() === value.trim()) {
                                selectedItem = item.text;
                                option = `<option value="${selectedItem}" selected>${item.text}</option>`
                            }
                            else {
                                option = `<option value="${item.text}">${item.text}</option>`
                            }
                            optionsList += option
                        })
                        
                        td.innerHTML = `<span><select style = "width: 200px; font-height: 400px; border: 1px solid #878787; line-height: 1.5; padding: 5px 7px 4px; margin-right: 8px; font-weight: 400; font-size: 14px; background: #fff; color: #242424; font-family: Ericsson Hilda; name="selectDialog" id="update_" value="${selectedItem}"> ${optionsList}</select>`;
                        td.querySelector("#update_").addEventListener('change', (e) => update(e, "type"));

                    }
                    else {
                        td.innerHTML = PageHelper.mapDocumentTypes(document.documentType);
                    }
                }
            }
        },
        {
            title: "Last Modified Date",
            key: "lastModifiedDate",
            width: "20%",
            sort: 'none',
            hideFilter: editMod ? true : false,
            onCreatedCell: (td, cellData) => {
                td.innerHTML = DateUtils.toDateString(cellData, "YYYY-MM-DD HH:mm")
            }
        },
        {
            title: "Last Modified User",
            key: "lastModifiedUser",
            sort: "none",
            width: "20%",
            hideFilter: editMod ? true : false,
        },
        {
            title: "",
            key: "editId",
            width: "2%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var document = customerDocumentsList.find(doc => doc.id == cellData);
                    if (editMod && cellData == document.id && (document.editId == cellData)) {
                        td.innerHTML = `<span id="save_" class="tooltip"><i id="save_" class="icon icon-check text-lg clickable color-green "></i><span class="message top-end">Save</span></span>`;
                        td.querySelector("#save_").addEventListener('click', () => save(cellData));
                    }
                    else {
                        td.innerHTML = `<span  id="edit_" class="tooltip"><i id="edit_" style={font-weight:500px;} class="icon icon-edit color-black  text-lg clickable "></i><span class="message top-end">Edit</span></span>`;
                        td.querySelector("#edit_").addEventListener('click', () => setEditMode(cellData))
                    }
                }
            }
        },
        {
            title: "",
            key: "deleteId",
            width: "2%",
            hideFilter: true,
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var document = customerDocumentsList.find(doc => doc.id == cellData);
                    if (editMod && cellData === document.id && (document.editId == cellData)) {
                        td.innerHTML = `<span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>`
                        td.querySelector("#cancel_").addEventListener('click', () => cancel(cellData));
                    }
                    else {
                        td.innerHTML = `<span id="delete_" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable "></i><span class="message top-end">Delete Document</span></span>`;
                        td.querySelector("#delete_").addEventListener('click', () => confirmDelete(cellData));
                    }

                }
            }
        }
    ];
    useEffect(() => {
        if (Object.values(selectedCustomer).length !== 0 && selectedCustomer !== selectedCustomerState) {
            setSelectedCustomerState(selectedCustomer);
            setCustomerDocumentsList([]);
            setIsLoading(true);
        }
        if (customerDocumentsList.length == 0 && Object.values(selectedCustomer).length !== 0) {
            if (editMod) {
                setEditMod(false)
            }
            ApiClient.post("Workplan/GetDocumentsBySystemId", { systemId: parseInt(selectedCustomer.id), objectTypeId: 1 }).then(response => {
                setIsLoading(false);
                
                setSASToken(response.data.sasToken);
                var sortedArray = sortByKey(response.data.data, "updatedAt");
                setCustomerDocumentsList(sortedArray);
            }).catch(error => console.error(error))
        }
        if (openDiaolgUploadBar) {
            const onProgress = createPercentage;
            PageHelper.uploadItems(files, onProgress, "customer-manuals").then(uploadedFiles => {
                const notification = new Notification({
                    title: 'Document is uploaded successfully!',
                    title: 'Document is uploaded successfully!',
                    timeout: 5000,
                    icon: 'icon-check'
                });
                notification.init();
                setOpenDialogUploadBar(false);
                documentsIntialList = uploadedFiles;
                setIsLoading(true)
                addDocuments();
            })
        }
        if (confirm == true) {
            softDeleteDocument();
            setConfirm(false);
            setOpenWarning(false);
        }
    }, [openDiaolgUploadBar, selectedCustomer, confirm, selectedCustomerState]);

    const confirmDelete = (data) => {
        setOpenWarning(true);
        setDeletedItem(data);
    }

    const sortByKey = (array, key) => {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];

            if (typeof x == "string") {
                x = ("" + x).toLowerCase();
            }
            if (typeof y == "string") {
                y = ("" + y).toLowerCase();
            }

            return ((x < y) ? 1 : ((x > y) ? -1 : 0));
        });
    }

    const createPercentage = (progressEvent, file) => {
        
        let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
        let percentageArray = [...percentageArr];
        const index = percentageArray.indexOf(file);
        percentageArray[index].percentage = percentage;
        setPercentageArray(percentageArray);
    }
    const addDocuments = () => {
        if (!documentsIntialList || documentsIntialList.length == 0) {
            const notification = new Notification({
                title: 'Upload Failed',
                description: 'No selected files to be uploaded!',
                timeout: 5000,
                icon: 'icon-check'
            });
            notification.init();
            return;
        }
        const docs = documentsIntialList.map(item => {
            return {
                title: item.title,
                documentPath: item.documentPath,
                systemId: selectedCustomer.id,
                documentType: item.documentType,
                objectTypeId: 1,
                lastModifiedUser: parseInt(userData.userData.userId)
            }
        });
        
        ApiClient.post("Workplan/AddDocuments", docs)
            .then(response => {
                setIsLoading(true);
                ApiClient.post("Workplan/GetDocumentsBySystemId", { systemId: parseInt(selectedCustomer.id), objectTypeId: 1 }).then(response => {
                    if (editMod) {
                        setEditMod(false)
                    }
                    setIsLoading(false);
                    
                    setSASToken(response.data.sasToken)
                    var sortedArray = sortByKey(response.data.data, "updatedAt");
                    setCustomerDocumentsList(sortedArray);
                    dispatch({ type: "SET_CUSTOMER_DOCUMENTS", payload: [] });
                }).catch(error => console.error(error))
            }).catch(error => {
                
                setIsLoading(false);
            });
    }

    const uploadDocuments = () => {
        var flag = false;
        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            const notification = new Notification({
                title: 'Upload Failed',
                description: fileSize,
                timeout: 5000,
                icon: 'icon-triangle-warning'
            });
            notification.init();
            return;
        }
        else {
            files.map(item => {
                if (item.documentType == null) {
                    flag = true;
                }
            });
            if (flag) {
                const notification = new Notification({
                    title: 'Upload Failed',
                    description: 'Please add all document types!',
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();

                return;
            }
            else {
                PageHelper.getCoordinates();
                setOpenDialog(false);
                setOpenDialogUploadBar(true);
                setPercentageArray(PageHelper.initializePercentageArr(files))
            }
        }
    }

    const softDeleteDocument = () => {
        if (confirm === true) {
            const deletedItem = customerDocumentsList.find(item => item.id == removedItem);
            
            deletedItem['delete'] = true;
            deletedItem['lastModifiedUser'] = parseInt(userData.userData.userId);
            ApiClient.post("Workplan/UpdateWorkplanDocument", deletedItem).then(response => {
                const notification = new Notification({
                    title: 'Document is deleted successfully!',
                    timeout: 5000,
                    icon: 'icon-check'
                });
                notification.init();
                setIsLoading(true);
                ApiClient.post("Workplan/GetDocumentsBySystemId", { systemId: parseInt(selectedCustomer.id), objectTypeId: 1 }).then(response => {
                    if (editMod) {
                        setEditMod(false)
                    }
                    setIsLoading(false);
                    setSASToken(response.data.sasToken)
                    var sortedArray = sortByKey(response.data.data, "updatedAt");
                    setCustomerDocumentsList(sortedArray);
                    setDeletedItem(null);
                });
            }).catch(err => {
                const notification = new Notification({
                    title: 'An issue occurred while deleting the document, please try again!',
                    timeout: 5000,
                    icon: 'icon-triangle-warning'
                });
                notification.init();
                setOpenWarning(false);
                setConfirm(false);
                setDeletedItem(null);
            }
            );
        }

    }

    const setEditMode = (data) => {
        const updatedArray = []
        if (editMod == false) {
            customerDocumentsList.map(item => {
                if (item.id == data) {
                    item["edit"] = true;
                    item["editId"] = item.id;
                    item["documentTypeName"] = PageHelper.mapDocumentTypes(item.documentType);
                }
                updatedArray.push(item);
            });
            setCustomerDocumentsList(updatedArray);
            setEditMod(true);
        }
    }

    const update = (e, object) => {
        if (object === "title") {
            setEditedTitle(e.target.value)
        }
        else if (object === "type") {
            setEditedType(e.target.value);
        }
    }

    const cancel = (data) => {
        var updatedArray = []
        customerDocumentsList.map(item => {
            if (item.id == data) {
                item["edit"] = false;
                item["editId"] = 0;
            }
            updatedArray.push(item);
        });
        setCustomerDocumentsList(updatedArray);
        setEditMod(false);
        setEditedTitle("");
        setEditedType(null);
    }

    const save = (data) => {
        
        
        const updatedArray = []
        var document = customerDocumentsList.find(doc => doc.id == data);
        (editTitle.length !== 0) ? document.title = editTitle : document.title = document.title;
        (editType !== null) ? document.documentType = constants.cmsDocumentTypes.find(x => x.text === editType).value : document.documentType = document.documentType;
        document.lastModifiedUser = parseInt(userData.userData.userId);
        setIsLoading(true);
        
        
        ApiClient.post("Workplan/UpdateWorkplanDocument", document).then(response => {
            const notification = new Notification({
                title: 'Document is updated successfully!',
                timeout: 5000,
                icon: 'icon-check'
            });
            notification.init();
            ApiClient.post("Workplan/GetDocumentsBySystemId", { systemId: parseInt(selectedCustomer.id), objectTypeId: 1 }).then(response => {
                setIsLoading(false);
                setSASToken(response.data.sasToken)
                var sortedArray = sortByKey(response.data.data, "updatedAt");
                sortedArray.map(item => {
                    if (item.id == data) {
                        item["edit"] = false;
                        item["editId"] = 0;
                    }
                    updatedArray.push(item);
                });
                setCustomerDocumentsList(updatedArray);
                setEditMod(false);
                setEditedTitle("");
                setEditedType(null);
            }).catch(err => console.error(err));
        }).catch(err => console.error(err));

    }

    const viewCustomerDocuments = () => {
        let dataItems = [];
        dataItems = customerDocumentsList.map(item => {
            return {
                id: item.id,
                documentTypeId: item.id + "@" + PageHelper.mapDocumentTypes(item.documentType),
                documentNameId: item.title + "-@-" + item.id,
                name: [item.title, item.documentPath],
                type: item.documentType,
                lastModifiedDate: item.updatedAt,
                lastModifiedUser: item.lastModifiedUserName,
                editId: item.id,
                deleteId: item.id,
            }
        })
        return <ObseleteEuiTable
            classes="tiny"
            columns={documentsColumns}
            data={dataItems}
            id={"customerDocumentsTable"}
            style={{ padding: 0 }}
            styleHeader={{ width: "100%", paddingLeft: "15px" }}
            styleTd={{ width: "100%", paddingLeft: "15px", paddingRight: 0, paddingTop: 0, paddingLeft: 0 }}
            scroll={false}
            sortable={true}
            paginated={true}
            key={customerDocumentsList + editTitle + editType + editMod}
            rowsPerPage={10}
            filter={true}
        />
    }

    return (
        <EuiRow className={isLoading ? " disabled " : ""} key={isLoading}>
            <EuiTile fullscreen>
            <EuiLoading id="customerDocumentsLoading" isLoading={isLoading} />
            <div className="flex-end-content w-10">
                <button style={{ float: "right", margin: "10px" }} hidden={Object.values(selectedCustomer).length == 0} className="btn primary m-1 " onClick={() => setOpenDialog(true)}>
                    <i className="icon icon-file-add p-1" />Upload Document</button></div>
            {(Object.values(customerDocumentsList).length != 0 && (Object.values(selectedCustomer).length != 0)) ? viewCustomerDocuments() : (Object.values(selectedCustomer).length == 0) ? < div className="flex-middle-content m-2 w-10" style={{ fontSize: "18px", color: "gray" }}>Select a customer to preview documents..</div> : <div className="flex-middle-content m-2 w-10" style={{ fontSize: "18px", color: "gray" }}>No documents attached to this customer</div>}
            <DialogModal
                buttonName="Delete"
                type="warning"
                title={"Delete Document"}
                content="Are you sure you want to delete this document?"
                handleClose={() => setOpenWarning(false)}
                mainButtonStyle={{ backgroundColor: "red" }}
                id="alertDialog"
                onClick={() => setConfirm(true)}
                open={warning}
                style={{ width: "380px", height: "50%" }}
                contentStyle={{ width: "380px" }}
                disableBackdropClick={true}
            />
            <DialogModal
                content={percentageArr.map(file => {
                    return <div key={file.name} style={{ width: 500 }}>
                        <label><strong>{file.title}</strong></label>
                        <EuiProgressBar value={file.percentage} />
                    </div>
                })}
                handleClose={() => setOpenDialogUploadBar(false)}
                id="uploadCustomerDocumentsDialog"
                titleId="titleuploadBarCustomerDocumentsUploadDialog"
                open={openDiaolgUploadBar}
                buttonsVisible="false"
                title="Progress Loading..."
                closeButtonVisible="false"
                key={percentageArr}
                disableBackdropClick={true}
            >
            </DialogModal>
            <DialogModal
                id="customerDocumentsDialog"
                style={{ height: "1000px" }}
                title="Add Documents"
                content={<UploadDocuments id="customerDocumentsDialog" dispatch="SET_CUSTOMER_DOCUMENTS" columns={{ name: true, type: true }} />}
                buttonName="Upload"
                handleClose={() => setOpenDialog(false)}
                onClick={() => uploadDocuments()}
                contentStyle={{ width: "800px", height: "400px" }}
                open={openDialog}
                disableBackdropClick={true}
                />
            </EuiTile>
        </EuiRow>
    );
}
export default CustomerDocuments;