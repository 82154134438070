import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import AspPmActivityDetails from "./aspPmActivityDetails";
import ApiClient from "../../utils/api-client";
import EuiRow from "../../eui-components/row";
import EuiLoading from "../../eui-components/loading";

const AspPmActivities = (props) => {

    const [isLoading, setIsLoading] = useState(true);

    const userData = useSelector(state => state.user);
    const userId = userData.userData.id;

    const dispatch = useDispatch();


    useEffect(() => {
        ApiClient.post("Activity/GetAspPmActivities", { id: parseInt(userId) })
            .then(response => {
                if (response) {
                    setIsLoading(false);
                    dispatch({ type: "SET_ASP_PM_ACTIVITIES", payload: response.data });
                }
            })
            .catch(error => {
                alert(error);
                setIsLoading(false)
            })

    }, []);

    return (
        <EuiRow key={isLoading} className={isLoading ? "disabled" : ""}>
            <EuiLoading isLoading={isLoading} />
            <AspPmActivityDetails  />
        </EuiRow>

    );

}

export default ((withRouter(withLayout(AspPmActivities))));