import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import EuiCard from "../../eui-components/card";
import EuiTooltip from "../../eui-components/tooltip";
import EuiHeader from "../../eui-components/header";
import constants from "../../utils/constants";
import Role2Content from "./roles-contents/role2Content";
import Role567Content from "./roles-contents/role567Content";
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import EuiTag from "../../eui-components/tag";
import Role18Content from "./roles-contents/role18Content";
import { toast } from "react-toastify";
import UserAdminHelper from "../../utils/user-admin-helper";

const UserRoles = (props) => {

    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedAction, setSelectedAction] = useState(0);
    const [selectedRoleItems, setSelectedRoleItems] = useState([]);

    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.userAdmin.currentUser);
    const roleEditMod = useSelector(state => state.userAdmin.roleEditMod);
    const loginRoles = useSelector(state => state.userAdmin.loginRoles);
    const mod = useSelector(state => state.userAdmin.mod);

    const roleItems = {
        "1": currentUser.customers?.filter(c => c.loginRoleId.toString() === constants.userRoles.ERICSSON_IM) ?? [],
        "2": currentUser.userAspCompanies ?? [],
        "5": currentUser.rscPersonUsers?.filter(r => r.rscPerson?.personType.toString() === constants.userRoles.RSC_COODINATOR) ?? [],
        "6": currentUser.rscPersonUsers?.filter(r => r.rscPerson?.personType.toString() === constants.userRoles.RSC_SITE_SUPERVISOR) ?? [],
        "7": currentUser.rscPersonUsers?.filter(r => r.rscPerson?.personType.toString() === constants.userRoles.RSC_OHS_INSPECTOR) ?? [],
        "8": currentUser.customers?.filter(c => c.loginRoleId.toString() === constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR) ?? [],
        "9": currentUser.customers?.filter(c => c.loginRoleId.toString() === constants.userRoles.REPORTER) ?? [],
        "10": currentUser.customers?.filter(c => c.loginRoleId.toString() === constants.userRoles.ASP_MANAGER) ?? [],
        "11": currentUser.customers?.filter(c => c.loginRoleId.toString() === constants.userRoles.EHS_SME) ?? [],
        "12": currentUser.customers?.filter(c => c.loginRoleId.toString() === constants.userRoles.VALIDATION_OFFICER) ?? [],
        "33" : currentUser.customers?.filter(c => c.loginRoleId.toString() === constants.userRoles.EHS_Auditor) ?? []
    };

    const getSelectedRoleContent = () => {
        const role = (selectedRole.role ?? 0).toString();
        if (role === constants.userRoles.ADMIN) {
            return <div className="p-3" >All permissions are available.</div>;
        } else if (role === constants.userRoles.ERICSSON_IM ||
            role === constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR ||
            role === constants.userRoles.REPORTER ||
            role === constants.userRoles.ASP_MANAGER || role === constants.userRoles.EHS_SME
            || role === constants.userRoles.VALIDATION_OFFICER || role === constants.userRoles.EHS_Auditor
        ) {
            return <Role18Content
                selectedRole={selectedRole}
                selectedRoleItems={selectedRoleItems}
                setSelectedRoleItems={setSelectedRoleItems}
                key={selectedRole?.role + roleEditMod}
                selectedAction={selectedAction} />;
        } else if (role === constants.userRoles.ASP_PROJECT_MANAGER) {
            return <Role2Content
                selectedRole={selectedRole}
                selectedRoleItems={selectedRoleItems}
                setSelectedRoleItems={setSelectedRoleItems}
                key={selectedRole?.role + roleEditMod}
                selectedAction={selectedAction} />;
        } else if (constants.rscRoles.includes(role)) {
            return <Role567Content selectedRole={selectedRole}
                setSelectedRoleItems={setSelectedRoleItems}
                id={"rscRole_" + role}
                key={selectedRole?.role + roleEditMod}
                selectedRoleItems={selectedRoleItems}
            />;
        }
    }

    const getRightActions = () => {

        if (props.isMyProfile) {
            return null;
        }

        if (roleEditMod) {
            return <>
                <EuiTooltip title="Save" position="left"><i className="icon icon-check color-green icon-lg icon-frame " onClick={() => save()}></i> </EuiTooltip>
                <EuiTooltip title="Cancel" position="left"><i className="icon icon-cross color-red icon-lg icon-frame" onClick={() => cancel()}></i> </EuiTooltip>
            </>
        }
        if (selectedRole.active) {
            return <>
                {mod !== constants.pageMods.VIEW_ONLY ?
                    <>
                        {selectedRole.role.toString() !== constants.userRoles.ADMIN
                            ? < EuiTooltip title="Edit Role" position="left"><i className="icon icon-edit icon-lg icon-frame "
                                onClick={() => editRole()}></i>
                            </EuiTooltip>
                            : null
                        }
                        <EuiTooltip title="Delete Role" position="left"><i className="icon icon-trashcan icon-lg icon-frame"
                            onClick={() => { setSelectedAction(constants.actionTypes.DELETE); dispatch({ type: "TOGGLE_ROLE_EDIT_MOD", payload: true }); }}></i>
                        </EuiTooltip>
                    </>
                    : null
                }
            </>;

        } else {
            return <>
                {mod !== constants.pageMods.VIEW_ONLY ?
                    <EuiTooltip title="Add Role" position="left"><i className="icon icon-plus icon-lg icon-frame "
                        onClick={() => { setSelectedAction(constants.actionTypes.ADD); dispatch({ type: "TOGGLE_ROLE_EDIT_MOD", payload: true }); }}></i>
                    </EuiTooltip>
                    : null}
            </>
        }
    }

    const setAsPrimary = (e, primaryRole) => {
        const user = { ...currentUser };
        user.role = primaryRole.role;
        dispatch({ type: "SET_CURRENT_USER", payload: user });
        e.preventDefault();
        e.stopPropagation();
    }

    const setDisabled = (selectedItem) => {
        const notMultipleRoles = loginRoles.filter(item => !item.isMultipleRole).map(i => i.id);
        if (notMultipleRoles.includes(currentUser.role) ||
            !selectedItem.isMultipleRole ||
            roleEditMod ||
            (currentUser.roles.filter(c => c.active)?.length > 0 && currentUser.roles.filter(c => c.active)[0]?.loginType != selectedItem.loginType)) {
            return " disabled "
        }
        return "";
    }

    const renderRoles = () => {
        return currentUser.roles?.map(item => {
            return <div className="flex-start-content" key={item.role}>
                <EuiCard
                    id={item.name}
                    title={<div className="flex-start-content ">
                        {UserAdminHelper.getIcon(item.role)}{item.name}
                    </div>}
                    actionIcon={item.active ? <EuiTooltip title="Set as Primary Role" position="left">
                        <i className={"icon icon-favorite-solid selectable p-1 icon-lg " + (item.role != currentUser.role ? " color-gray " : "yellow-43") + (mod === constants.pageMods.VIEW_ONLY ? " disabled " : "")}
                            onClick={(e) => setAsPrimary(e, item)}>
                        </i>
                    </EuiTooltip> : null}
                    onClick={() => { setSelectedRoleItems(roleItems[item.role + ""] ?? []); setSelectedRole(item); props.setRoleDetailMod(true); }}
                    style={{ padding: 0, margin: 2, color: "#242424", backgroundColor: (item.active ? "#E6F1EB" : ""), border: (selectedRole === item ? "3px solid #BBA361" : "") }}
                    className={"w-9 " + (item.active ? "active-card" : "") + setDisabled(item)}
                >
                </EuiCard >
                {setDisabled(item) != "" ? null :
                    <EuiTooltip title="View Role Details">
                        <i className="icon icon-chevron-right text-lg"
                            onClick={() => { setSelectedRoleItems(roleItems[item.role + ""] ?? []); setSelectedRole(item); props.setRoleDetailMod(true); }}></i>
                    </EuiTooltip>
                }
            </div>
        });
    }

    const save = () => {
        const user = { ...currentUser };
        const role = (selectedRole.role ?? 0).toString();
        const roles = [...user.roles];

        

        if (selectedAction != constants.actionTypes.DELETE &&
            role != constants.userRoles.ADMIN &&
            !selectedRoleItems?.length) {
            toast.error("Please, add at least one item for the selected role.", { containerId: "UA", position: toast.POSITION.TOP_CENTER });
            return;
        }

        if (role === constants.userRoles.ADMIN) {
            if (selectedAction === constants.actionTypes.ADD) {
                roles[roles.indexOf(selectedRole)].active = true;
            }
        } else if (role === constants.userRoles.ERICSSON_IM ||
            role === constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR ||
            role === constants.userRoles.REPORTER
            || role === constants.userRoles.ASP_MANAGER
            || role === constants.userRoles.EHS_SME
            || role === constants.userRoles.VALIDATION_OFFICER || role == constants.userRoles.EHS_Auditor) {
            if (selectedAction === constants.actionTypes.ADD) {
                roles[roles.indexOf(selectedRole)].active = true;
                user.customers = [...user.customers, ...selectedRoleItems];
            } else if (selectedAction === constants.actionTypes.EDIT) {
                user.customers = [...user.customers.filter(c => c.loginRoleId != selectedRole.role), ...selectedRoleItems];
            } else if (selectedAction === constants.actionTypes.DELETE) {
                user.customers = user.customers.filter(c => c.loginRoleId != selectedRole.role);
            }

        } else if (role === constants.userRoles.ASP_PROJECT_MANAGER) {
            if (selectedAction === constants.actionTypes.ADD) {
                roles[roles.indexOf(selectedRole)].active = true;
                user.userAspCompanies = [...selectedRoleItems];
            } else if (selectedAction === constants.actionTypes.EDIT) {
                user.userAspCompanies = [...selectedRoleItems];
            } else if (selectedAction === constants.actionTypes.DELETE) {
                user.userAspCompanies = [];
            }
        } else if (constants.rscRoles.includes(role)) {
            if (selectedAction === constants.actionTypes.ADD) {
                roles[roles.indexOf(selectedRole)].active = true;
                user.rscPersonUsers = [...user.rscPersonUsers, ...selectedRoleItems];
            } else if (selectedAction === constants.actionTypes.EDIT) {
                user.rscPersonUsers = [...user.rscPersonUsers.filter(r => r.rscPerson?.personType != selectedRole.role), ...selectedRoleItems];
            } else if (selectedAction === constants.actionTypes.DELETE) {
                user.rscPersonUsers = user.rscPersonUsers.filter(r => r.rscPerson?.personType != selectedRole.role);
            }
        }

        if (selectedAction === constants.actionTypes.DELETE) {
            roles[roles.indexOf(selectedRole)].active = false;
            user.roles = roles;
            setSelectedRoleItems([]);
            user.role = !(roles ?? []).some(r => r.active) ? -1 : user.role;
            dispatch({ type: "SET_CURRENT_USER", payload: user });
        }

        if (selectedAction === constants.actionTypes.ADD && (user.role ?? -1) < 0) {
            user.role = selectedRole.role;
        }

        setSelectedAction(0);
        dispatch({ type: "SET_CURRENT_USER", payload: user });
        dispatch({ type: "TOGGLE_ROLE_EDIT_MOD", payload: false });
        
    }

    const cancel = () => {
        
        setSelectedRoleItems(roleItems[selectedRole.role + ""]);
        setSelectedAction(0);
        dispatch({ type: "TOGGLE_ROLE_EDIT_MOD", payload: false });
    }

    const editRole = () => {
        const currentRoleItems = roleItems[selectedRole.role + ""];
        setSelectedAction(constants.actionTypes.EDIT);
        setSelectedRoleItems(currentRoleItems);
        dispatch({ type: "TOGGLE_ROLE_EDIT_MOD", payload: true });
    }

    return (
        <div className="flex-baseline">
            <div className={(selectedRole ? "w-3" : " w-10")}>
                {mod === constants.pageMods.ADD ? <div className="p-1 fw-2 pt-0 color-red">Please, add at least one role to continue user creation.</div> : null}
                <h4 style={{ marginBottom: 5, marginLeft: 5, marginBlockStart: 5 }}>User Roles</h4>
                {renderRoles()}
                <div className="flex-start-content m-2 mb-1">
                    <EuiTag icon="econ" name="Ericsson" />
                    <EuiTag icon="avatar" name="ASP" />
                    <EuiTag icon="scan" name="Customer" />
                    <EuiTag icon="eye" name="Readonly" />
                </div>
                <div className="flex-start-content">
                    <EuiTag icon="favorite-solid yellow-43" name="Primary Role" />
                    <EuiTag icon="favorite-solid color-gray" name="Secondary Role" />
                    <EuiTag className="green" name="Assigned Roles" />
                </div>
            </div>
            {selectedRole ?
                <div className="w-7">
                    <EuiHeader
                        id="userRolesHeaderId"
                        key={roleEditMod}
                        title={<EuiBreadcrumb id="userDetailBreadcrumb"
                            parentName={<div className="flex-start-content"><i className="icon icon-profile icon-lg pr-1"></i>Profile</div>}
                            childName={selectedRole.name}
                            onClickParent={() => { setSelectedRole(null); props.setRoleDetailMod(false); dispatch({ type: "TOGGLE_ROLE_EDIT_MOD", payload: false }); }}
                            className="p-1"
                            style={{ display: "flex", alignItems: "center" }}
                        />}
                        rightActions={getRightActions()}
                    >
                    </EuiHeader>
                    {getSelectedRoleContent(selectedRole.role)}
                </div>
                :
                null
            }
        </div>
    );

}

export default UserRoles;