import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import { toast } from "react-toastify";
import ApiClient from "../../utils/api-client";
import WorkplanHelper from "../../utils/workplan-helper";
import EuiDatepicker from "../../eui-components/datepicker";
import constants from "../../utils/constants";


class ActivityFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        }
    }


    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        const filter = { ...this.props.activityData.filter };
        if (name == "actualEndDate" && !value)
            filter[name] = null;
        else
            filter[name] = value || "";
        this.props.setActivityFilter(filter);
    }

    getActivities = (page, pageSize) => {
        const filter = this.props.activityData.filter;

        delete filter["page"];
        delete filter["pageSize"];

        if (!filter || Object.values(filter).every(x => (!x || x === ''))) {
            toast.error("Please, fill any field to get related activities", { containerId: "AS", position: toast.POSITION.TOP_CENTER });
            return;
        }
        if (this.props.userData.userData.jobRole == constants.userRoles.ASP_PROJECT_MANAGER) {

            filter.userId = parseInt(this.props.userData.userData.userId);
        }
        else
            filter.userId = 0;
        filter.page = page;
        filter.pageSize = pageSize;
        filter.id = parseInt(filter.id ?? "0");
        filter.sessionId = parseInt(filter.sessionId ?? "0");
        const filterItems = Object.keys(filter)?.filter(item => item !== "singleFilter"&& filter[item]);
        if (filterItems?.length > 3) {
            filter.singleFilter = false;
        }
        else {
            filter.singleFilter = true;

        }
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Workplan/GetFilteredActivities", filter)
                .then(response => {
                    if (response.data) {

                        this.props.setFilteredActivities({ data: [], totalCount: 0 });
                        const data = [...response.data.data];
                        WorkplanHelper.mapActivities(data);
                        this.setState({ isLoading: false }, () => {
                            this.props.setFilteredActivities({ data, totalCount: response.data.totalCount });
                            this.props.changeInitialState();
                        });
                    }

                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    render() {
        return (<EuiRow>
            <EuiTextField className="m-1 w-10" fullwidth id="activityIdFilter" name="id" label="Activity ID (=)" labelClass="color-gray"
                value={this.props.activityData.filter.id || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "id" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="activityErisiteWorkplanIdFilter" name="erisiteWorkplanId" label="Erisite Workplan ID(=)" labelClass="color-gray"
                value={this.props.activityData.filter.erisiteWorkplanId || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "erisiteWorkplanId" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="activitySessionIdFilter" name="sessionId" label="Customer Session ID (=)" labelClass="color-gray"
                value={this.props.activityData.filter.sessionId || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "sessionId" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="activityNameFilter" name="name" label="Activity Name (*)" labelClass="color-gray"
                value={this.props.activityData.filter.name || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "name" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="activityWorkplanNameFilter" name="workplanName" label="Workplan Name (*)" labelClass="color-gray"
                value={this.props.activityData.filter.workplanName || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "workplanName" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="activityProjectNameFilter" name="projectName" label="Project Name (*)" labelClass="color-gray"
                value={this.props.activityData.filter.projectName || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "projectName" } })}
            />

            <EuiDatepicker className="m-1 w-10" fullwidth id="activityActualEndFilter" name="actualEndDate" label="Actual End Date (=)" labelClassName="color-gray"
                value={this.props.activityData.filter.actualEndDate || ""} onChange={(e) => this.handleChange(e)}

            />

            <EuiTextField className="m-1 w-10" fullwidth id="activityCustomerSiteIdFilter" name="customerSiteId" label="Customer Site ID (*)" labelClass="color-gray"
                value={this.props.activityData.filter.customerSiteId || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "customerSiteId" } })}
            />

            <button className={"btn primary m-1 " + (this.state.isLoading ? "loading disabled" : "")} onClick={() => this.getActivities(1, 30)}>Search</button>
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, activityData: state.activity });
const mapDispatchToProps = dispatch => {
    return {
        setFilteredActivities: (filteredActivities) => dispatch({ type: "SET_FILTERED_ACTIVITIES", payload: filteredActivities }),
        setActivityFilter: (filter) => dispatch({ type: "SET_ACTIVITY_FILTER", payload: filter }),
        setActivity: (activity) => dispatch({ type: "SET_ACTIVITY", payload: activity }),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityFilter);