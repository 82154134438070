import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withLayout } from "../../layout";
import ApiClient from "../../utils/api-client";
import GeneralInformation from "./generalInformation";
class Customers extends React.Component {

    componentDidMount() {
        if (this.props.navbarData.navBarView) {
            this.props.toggleNavbar();
        }
        if (!this.props.rscCustomers?.length) {
            ApiClient.post("IncentivesPlan/GetRscCustomers")
                .then(_response => {
                    this.props.setRscCustomers(_response.data);
                });
        }
    }
    render() {
        return (
            <GeneralInformation />
        )
    }

}
const mapStateToProps = state => ({ navbarData: state.navigation });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setRscCustomers: (customers) => dispatch({ type: "SET_RSC_CUSTOMERS", payload: customers }),

    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLayout(Customers)));