import React, { useState } from "react";
import DateUtils from "../../../utils/date-utils";
import EuiTextField from "../../../eui-components/textfield";

const SubactivityDuration = (props) => {
    return <div className="flex-start-content">
        <EuiTextField id="actualStartSubactivityInfo"
            name="ActualStart"
            label="Actual Start : "
            value={DateUtils.toDateString(props.rowData.actualStart, "YYYY-MM-DD HH:mm")}
            readOnly
            labelClass="left"
            labelstyle={{ fontWeight: 400, fontSize: 12, color: "#878787" }}
        ></EuiTextField>
        <div className="vl"></div>
        <EuiTextField id="actualEndSubactivityInfo"
            name="ActualEnd"
            label="Actual End : "
            value={DateUtils.toDateString(props.rowData.actualEnd, "YYYY-MM-DD HH:mm")}
            readOnly
            labelClass="left"
            labelstyle={{ fontWeight: 400, fontSize: 12, paddingLeft: 10, color: "#878787" }}
        ></EuiTextField>
        <div className="vl"></div>
        <EuiTextField id="durationSubactivityInfo"
            name="duration"
            label="Duration : "
            value={DateUtils.dateDifferenceInSeconds(props.rowData.actualEnd, props.rowData.actualStart)}
            readOnly
            labelClass="left"
            labelstyle={{ fontWeight: 400, fontSize: 12, paddingLeft: 10, color: "#878787" }}
        ></EuiTextField>
    </div>
}

export default SubactivityDuration;