import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import ApiClient from "../../utils/api-client";
import EuiDatepicker from "../../eui-components/datepicker";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import FormField from "../../eui-components/formfield";

class IntegrationApiFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            apis: [],
            objects: [],
            parameters: [],
            selectedApi: null,
            selectedObject: null,
            filter: {}
        }
    }

    getData = () =>{
        let { selectedObject, filter} = { ...this.state };
        this.setState({ isLoading: true }, () => {
            ApiClient.get(selectedObject.getApi, filter)
                .then(response => {
                    if (response.data) {
                        ///console.log(response)
                        this.setState({ isLoading: false });
                        this.props.setData(selectedObject, response.data, filter)
                    }

                })
                .catch(error => {
                    console.error(error);
                   // this.setState({ isLoading: false });
                });
        });

    }


    componentDidMount() {
        
        this.setState({ isLoading: true }, () =>
        {
            ApiClient.post("Common/GetIntegrationApis", {})
            .then(res =>
            {
                ///console.log("GetIntegrationApis", res)
                this.setState({isLoading: false})
                var apis = res.data.map(e => (
                    { value: e.id, text: e.name, ...e }
                ))
                this.setState({apis: apis})
            });
        })
        
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        const emptyData = {
            transactionId: 0,
            records: []
        };
        let {selectedApi, objects, selectedObject, parameters, filter} = { ...this.state };
        if (name == "selectedApi")
        {
            selectedApi = this.state.apis.find(e => e.id == value) || null
            objects = selectedApi?.objects?.filter(o => o.active) || null
            parameters = null
            filter = {}
            this.props.setData(null, emptyData, filter)

        }
        else if (name == "selectedObject")
        {
            selectedObject = this.state.objects.find(e => e.id == value)
            parameters = selectedObject?.parameters || null
            this.props.setData(value, emptyData, filter)
        }
        else 
        {
            filter[name] = value;
        }

        this.setState({selectedApi, objects, selectedObject, parameters, filter})
    }

    renderFilter = () => {

        let {parameters} = { ...this.state };
        
        
        return parameters && <>{
            parameters.map(parameter => {
                if(parameter.active)
                {
                    switch(parameter.type)
                    {
                        case 'string':
                            return <EuiTextField
                                className="m-1 w-10"
                                fullwidth
                                id={parameter.id+parameter.name}
                                name={parameter.name}
                                label={parameter.name}
                                labelClass="color-gray"
                                value={this.state.filter[parameter.name] || ""}
                                onChange={(e) => this.handleChange(e)}
                                clear={true}
                                onClear={() => this.handleChange({ target: { value: "", name: parameter.name } })}
                            />
                        case 'DateTime':
                            return <EuiDatepicker
                                className="m-1 w-10"
                                fullwidth
                                id={parameter.name}
                                name={parameter.name}
                                label={parameter.name}
                                labelClassName="color-gray"
                                value={this.state.filter[parameter.name] || ""}
                                onChange={(e) => this.handleChange(e)}
                            />
                    }
                }
            })
        }</>

    }

    isObjectEmpty = (objectName) => {
        return Object.keys(objectName).length === 0
      }
      

    render() {
        let {selectedApi, selectedObject, filter} = { ...this.state };

        return (<EuiRow>
            <FormField className="w-10">
                <label className="color-gray" style={{ fontSize: "14px" }} htmlFor="companySelect">Select App</label>

                <EuiAutocompleteSelect
                    className="w-10"
                    fullwidth
                    key={this.state.apis}
                    id="selectedApi"
                    name="selectedApi"
                    label="Select API"
                    options={this.state.apis}
                    labelClass="color-blue"
                    value={this.state.selectedApi}
                    onChange={(e) => this.handleChange(e)}
                    optionListStyle={{ width: "100%" }}
                />
            </FormField>

            <FormField className="w-10">
                <label className="color-gray" style={{ fontSize: "14px" }} htmlFor="companySelect">Select Object</label>

                <EuiAutocompleteSelect
                    className="w-10"
                    fullwidth
                    key={this.state.objects}
                    disabled={!this.state.selectedApi}
                    id="selectedObject"
                    name="selectedObject"
                    label="Select Object"
                    options={this.state.objects}
                    labelClass="color-blue"
                    value={this.state.selectedObject}
                    onChange={(e) => this.handleChange(e)}
                    optionListStyle={{ width: "100%", maxHeight: "780px" }}
                />
            </FormField>

            {this.renderFilter()}

            <button
                disabled={!selectedApi || !selectedObject || this.isObjectEmpty(filter) }
                className={"btn primary m-1 " + (this.state.isLoading ? "loading disabled" : "")}
                onClick={() => this.getData(selectedApi, selectedObject, filter)}
            >
                Search
            </button>
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ });
const mapDispatchToProps = dispatch => {
    return {

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IntegrationApiFilter);