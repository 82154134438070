import React from 'react';
import { connect } from "react-redux";
import ApiClient from '../../utils/api-client';
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiRow from "../../eui-components/row";
import EuiTree from "../../eui-components/genericTree";
import PageHelper from "../../utils/page-helper";
import "../../css/common.css";
import CreateCustomer from "./createCustomer";
import { toast } from 'react-toastify';
import EuiTile from "../../eui-components/genericTree";
import CustomersTree from './customersTree';
import EuiLoading from '../../eui-components/loading';
import EuiTabs from "../../eui-components/tabs";
import CustomerDocuments from './customerDocuments';
import CommonHelper from '../../utils/common-helper';


class GeneralInformation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            customers: [],
            customerColumns: [
                {
                    key: 'id',
                    title: 'ID',
                    hidden: true,
                    filter: false
                },

                {
                    key: 'text',
                    title: 'Country Name',
                    sort: 'asc'
                },

            ],
            customersList: [],
            genericList: [],
            enPhotoAuth: "",
            enGPS: "",
            enTeamVr: "",
            selectedCustomer: {},
            selectedCountry: "",

            // modeEdit:"plain",

        }

    }


    componentDidMount() {

        ApiClient.post("User/GetAllCountries", {})
            .then(response => {
                this.setState({
                    customers: response.data,
                })
                
                let L = [];
                this.state.customers.forEach(e => {
                    let properties = { "state": "closed", "element": e.text, "subList": e.customers, "id": e.value }; L.push(properties);
                });
                
                L.sort(PageHelper.dynamicSort("element"));
                this.setState({ genericList: L })
                this.props.setCountryCustomersList(L);
                this.setState({
                    isLoading: false
                })
            }).catch(error => {
                console.error(error);;
            })
        PageHelper.getCoordinates();

    }


    handleChange = (event) => {
        const target = event.target;
        
        const value = target.value ?? target.checked;
        



    }

    CustomerView = (customerID, countryName) => {
        this.props.CheckDataEdit(true);
        ApiClient.post("EritopTracker/GetCustomerByID", { id: parseInt(customerID) })
            .then(response => {
                this.setState({
                    selectedCustomer: response.data,
                    selectedCountry: countryName,

                })
                this.props.viewCustomer({ mode: "view", selectedCustomer: this.state.selectedCustomer, selectedCountry: this.state.selectedCountry });
            })

    }
    editCustomer = (customerID, countryName) => {
        this.props.CheckDataEdit(true);
        ApiClient.post("EritopTracker/GetCustomerByID", { id: parseInt(customerID) })
            .then(response => {
                this.setState({
                    selectedCustomer: response.data,
                    selectedCountry: countryName,

                })
                this.props.viewCustomer({ mode: "edit", selectedCustomer: this.state.selectedCustomer, selectedCountry: this.state.selectedCountry });
                this.props.CheckDataEdit(true);
            })
    }

    saveChanges = (id, customerName,
        disableOnSiteGPS, disablePhotoAuth, disableTeamVerification, disableGpsRecordingInDb,
        hideGps, customerEritopAspAdoptionDate, ericssonEritopAspAdoptionDate,
        systemrecordid, eriCustomerId, selectedCountry, logoFile, countryId, logoName, logDetail) => {
        let visitor = CommonHelper.getVisitorData();

        const logInfo = {
            userId: parseInt(this.props.userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: logDetail,
            userLogin: {
                nameOrMail: this.props.userData.userMail,
                jobRole: this.props.userData.loginRole,
                password: this.props.userData.password
            }
        };
        if (!customerName)
            toast.error("Customer name must be filled!", { containerId: 'W', position: toast.POSITION.TOP_CENTER })
        if (logoName == "") {
            logoFile = "";
        }
        this.setState({
            isLoading: true
        })
        ApiClient.post("EritopTracker/SaveCustomer",
            {
                id: parseInt(id), name: customerName, disableOnSiteGPS: disableOnSiteGPS,
                disablePhotoAuth: disablePhotoAuth, disableTeamVerification: disableTeamVerification, disableGpsRecordingInDb: disableGpsRecordingInDb,
                hideGPS: hideGps, customerEritopAspAdoptionDate: customerEritopAspAdoptionDate, ericssonEritopAspAdoptionDate: ericssonEritopAspAdoptionDate,
                systemrecordid: systemrecordid, eriCustomerId: eriCustomerId, countryId: countryId, logo: logoFile, logoName: logoName, logInfo: logInfo, logDetail: logDetail
            })
            .then(response => {
                toast.success("Customer edited successfully!", { containerId: 'W', position: toast.POSITION.TOP_CENTER });
                if (response.data) {

                    ApiClient.post("User/GetAllCountriesList", {}).then(response => {
                        this.setState({
                            customers: response.data,
                            isLoading: false
                        })
                        
                        let L = [];
                        this.state.customers.forEach(e => {
                            let properties = { "state": "closed", "element": e.text, "subList": e.customers, "id": e.value }; L.push(properties);
                        });
                        L.sort(PageHelper.dynamicSort("element"));

                        /*
                        L.map(e => {
                            if (e.element == element) {
                                e.subList.map(s => {
                                    if (s.value == id) {
                                        s.state = "active";
                                    }
                                    else {
                                        s.state = "";
                                    }
                                })
                            }
                            else {
                                e.subList.forEach(x => {
                                    x.state = "";
                                })
                            }
                        });*/

                        this.props.setCountryCustomersList(L);
                        this.CustomerView(id, selectedCountry);

                    }).catch(error => {
                        console.error(error);;
                    });
                    



                }
            }).catch(error => {
                console.error(error);;
            })

    }


    cancel = (customerID, countryName) => {
        this.CustomerView(customerID, countryName)
        this.props.CheckDataEdit(true);

    }
    updateTabsContent = () => {
        var content = [];
        const viewContent = [{
            id: 1,
            content: (<CreateCustomer
                cancel={this.cancel}
                saveChanges={this.saveChanges}
            />),
            selected: "selected",
        },
        {
            id: 2,
            content: <CustomerDocuments />
        }
        ]
        viewContent.map((item) => {
            content.push({ value: item.id, children: item.content, selected: item.selected, contentStyle: { width: "100%" } })
        });
        return (content);
    }
    render() {
        

        return (
            <div key={this.state.isLoading}>
                <EuiLoading isLoading={this.state.isLoading} />
                <EuiRow className={(this.state.isLoading ? "disabled" : "")}>
                    <CustomersTree
                        filter={true}
                        viewSubDetails={this.CustomerView}
                        editSubDetails={this.editCustomer}
                        list={this.props.customersData.countryCustomersList}
                        viewTree={this.props.navbarData.customersTree}
                    >
                    </CustomersTree>
                    <div className={(this.props.navbarData.customersTree ? "tile sm-12 lg-9 xl-9" : "tile sm-12 lg-12 xl-12")} id="data">
                        <div className="right">
                            <div className="action" data-type="maximize">
                                <span className="tooltip pointer">
                                    <i className="icon icon-nav"
                                        onClick={() => { this.props.toggleCustomersTree(!this.props.navbarData.customersTree) }}
                                    ></i> <span className="message right blue">{(this.props.navbarData.customersTree ? "Hide Customers Tree" : "View Customers Tree")} </span>
                                </span>
                            </div>
                        </div>
                        <EuiTabs
                            titleColumnStyle={{ marginBottom: "10px" }}
                            contentStyle={{ width: "100%" }}
                            id="CustomersTabs"
                            titles=
                            {[{ id: 1, value: 1, name: "Customer information", selected: "selected", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } },
                            { id: 2, value: 2, name: "Customer Documents", titleStyle: { fontWeight: 700, padding: 3, fontSize: 800, color: "gray" } }]}
                            contents={this.updateTabsContent()}
                        />
                    </div >
                </EuiRow >
            </div>
        );
    }
}


const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, customersData: state.customers, });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setRowData: (rowData) => dispatch({ type: "SET_ROW_DATA", payload: rowData }),
        setCountryCustomersList: (countryCustomersList) => dispatch({ type: "SET_COUNTRY_CUSTOMERS_LIST", payload: countryCustomersList }),
        viewCustomer: ({ mode, selectedCustomer, selectedCountry }) => dispatch({ type: "VIEW_CUSTOMER", payload: { mode, selectedCustomer, selectedCountry } }),
        CheckDataEdit: (editCheckData) => dispatch({ type: "EDIT_CHECK_DATA", payload: editCheckData }),
        toggleCustomersTree: (customersTreeState) => dispatch({ type: "Toggle_CUSTOMERS_TREE", payload: customersTreeState }),
    };

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralInformation);
