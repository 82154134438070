import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import EuiNewMultiSelect from '../../eui-components/select/newmultiselect.js';
import PageHelper from "../../utils/page-helper";
import { toast, ToastContainer } from 'react-toastify';
import { Notification } from '@eds/vanilla';
import EuiTextField from "../../eui-components/textfield";
import EuiTable from "../../eui-components/table";
import EuiRow from "../../eui-components/row";

const UploadUserManuals = (props) => {
    const [editItem, setEditedItem] = useState(null);
    const [editMod, setEditMod] = useState(false);
    const [selectedJobRoles, setSelectedJobRoles] = useState([]);
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const docsColumns = [{
        title: "Name *",
        key: "name",
        width: "10%"
    },
    {
        title: "Eligible Users *",
        key: "jobRoles",
        width: "30%"
    },
    {
        title: "",
        key: "edit",
        width: "2%",

    },
    {
        title: "",
        key: "delete",
        width: "2%",

    }
    ]
    const onUpload = (e) => {
        const files = [...e.target.files];
        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            const notification = new Notification({
                title: 'Upload Failed',
                description: fileSize,
                timeout: 5000,
                icon: 'icon-triangle-warning'
            });
            notification.init();
            return;
        }
        const documents = [];
        files.forEach(item => {
            setEditedItem(item);
            documents.push(item);
            
        });
        setFiles(documents);
    }
    const viewFiles = () => {
        const documents = [];
        let viewedFiles = [...files];
        viewedFiles.forEach(item => {
            var element = {
                name: (editMod === true) ? (< EuiTextField className="m-1 w-10" fullwidth="true" id={"update_" + item.lastModified} name="name" value={editItem.name} onChange={(e) => handleChange(e)} />) : (editItem.name),
                jobRoles: (<EuiNewMultiSelect disabled={editMod == false} onChange={(e, options) => handleChange(e, options)} value={selectedJobRoles} name="updateRoleId" placeholder="--Select Elgible Users--" className="m- 1" id={"roleIds_" + item.lastModified} options={props.jobRoles} ></EuiNewMultiSelect >),
                edit: (editMod === true) ? (<button style={{ border: "none" }} className="btn icon-btn" onClick={() => saveEditedItem()}><i style={{ color: "green" }} className="icon icon-check"></i></button>) : (<span id={"edit_" + item.lastModified} onClick={() => { openEdit(item) }} className="tooltip"><i id={"edit_" + item.lastModified} className="icon icon-edit  text-lg clickable"></i><span className="message top-end">Edit Document</span></span>),
                delete: (editMod === true) ? (<button style={{ border: "none" }} className="btn icon-btn" onClick={() => setEditMod(false)}><i style={{ color: "red" }} className="icon icon-cross"></i></button>) : (< span id={"delete_" + item.lastModified} onClick={onDelete} className="tooltip" ><i id={"delete_" + item.lastModified} className="icon icon-trashcan  text-lg clickable "></i><span className="message top-end">Delete Document</span></span>),
            }
            documents.push(element);
        });
        return (<EuiTable
            key={files}
            classes="tiny"
            columns={docsColumns}
            data={documents}
            id={props.id + "obseleteTable"}>
        </EuiTable>);
    }
    const openEdit = (item) => {
        setEditMod(true);
        setEditedItem(item);
    }
    const saveEditedItem = () => {
        setEditMod(false);
        if (selectedJobRoles.length != 0 && files.length != 0 && editItem.name != "") {
            dispatch({ type: "SET_USER_MANUALS", payload: files });
            dispatch({ type: "SET_SELECTED_JOB_ROLES", payload: selectedJobRoles });
            dispatch({ type: "SET_ITEM_NAME", payload: editItem.name });
        }
        else {
            toast.error("Please add all the mandatory fields. ", { containerId: "UM", position: toast.POSITION.TOP_CENTER })
        }
    }
    const handleChange = (e, selectedItems) => {
        if (selectedItems) {
            setSelectedJobRoles(selectedItems);
        }
        else {
            const name = e.target.name;
            const value = e.target.value;
            const item = { ...editItem };
            item[name] = value;
            setEditedItem(item);
        }
    }

    const onDelete = () => {
        dispatch({ type: "SET_USER_MANUALS", payload: [] });
        dispatch({ type: "SET_SELECTED_JOB_ROLES", payload: [] });
        dispatch({ type: "SET_ITEM_NAME", payload: "" });
        setFiles([]);
        setSelectedJobRoles([]);

    }
    return (
        <EuiRow>
            <ToastContainer containerId={'UM'} autoClose={2500} />
            <div className="col sm-12 m-12 l-12 xl-12 mb-1 w-10">
                <label htmlFor="uploadDocs">
                <input style={{ width: "60%", marginBottom: "5px", fontFamily: "Ericsson Hilda" }} id="uploadDocs" name="files" onChange={(e) => onUpload(e)} type="file" /><span className="btn"><i className="icon icon-upload"></i>Upload File</span></label>
            </div>
            <div className="col sm-12 m-12 l-12 xl-12">
                {(files && files.length !== 0 ?
                    <EuiRow style={{ alignItems: "center" }}>{viewFiles()}</EuiRow>
                    : null)}
            </div>
        </EuiRow >
    );
}
export default withRouter(UploadUserManuals);