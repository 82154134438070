import React, { useEffect, useCallback, useState } from "react";
import VehicleHelper from '../../utils/vehicle-helper';
import { useDispatch, useSelector } from "react-redux";
import constants from '../../utils/constants';
import EuiRow from '../../eui-components/row';
import EuiHeader from '../../eui-components/header';
import EuiLoading from '../../eui-components/loading';
import ApiClient from '../../utils/api-client';
import CommonHelper from '../../utils/common-helper';

import VehicleInspectionList from "./vehicleInspectionList.js"
const VehicleInboxMenu = () => {
    const userData = useSelector(state => state.user.userData);
    const vehicle = useSelector(state => state.vehicle);
    const assignedInspections = vehicle?.assignedInspections || [];
    const unassignedInspections = vehicle?.unassignedInspections || [];
    const allInspections = vehicle?.allInspections || [];
    const dispatch = useDispatch();
    const [isLoading, changeIsLoading] = useState(false);
    useEffect(() => {
        getCountryInspections();
    }, []);
    const getMenuList = () => {
        const menu = [
            {
                id: 1, title: "Unassigned Inspections",
                icon: <i className="icon icon-view-list m-1 text-lg"></i>,
                content: <VehicleInspectionList id="unassigned" isLoading={isLoading} data={unassignedInspections} />
            },
            {
                id: 2, title: "Assigned Inspections", icon: <i className="icon icon-check m-1 text-lg"></i>,
                content: <VehicleInspectionList id="assigned" isLoading={isLoading} data={assignedInspections} />,
            }
        ]
        return menu;
    }
    const getCountryInspections = () => {
        changeIsLoading(true);
        //GetCountryRelatedInspections
        ApiClient.post("Vehicle/GetAllMonthlyInspections").then(response => {
            dispatch({ type: "SET_ALL_INSPECTIONS", payload: response.data });
            changeIsLoading(false);
        })
            .catch(error => {
                console.error(error);;
                changeIsLoading(false);
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
            });
    }
    const getUnassignedInspections = () => {
        changeIsLoading(true);
        ApiClient.post("Vehicle/GetUnassignedVehiclesInspection", { id: parseInt(userData.id) }).then(response => {
            dispatch({ type: "SET_UNASSIGNED_INSPECTIONS", payload: response.data });
            changeIsLoading(false);
        })
            .catch(error => {
                console.error(error);;
                changeIsLoading(false);
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
            });
    }
    const getAssignedInspections = () => {
        changeIsLoading(true);
        ApiClient.post("Vehicle/GetAssignedVehiclesInspection", { id: parseInt(userData.id) }).then(response => {
            dispatch({ type: "SET_ASSIGNED_INSPECTIONS", payload: response.data });
            changeIsLoading(false);
        })
            .catch(error => {
                console.error(error);;
                changeIsLoading(false);
                CommonHelper.createNotification({ title: constants.errorMessage, icon: "icon-cross", timeout: 5000 });
            });
    }
    return (
        <EuiRow style={{ width: "100%" }} key={isLoading} disabled={isLoading ? "disabled" : ""}>
            <EuiLoading id="viewUnassignedInspections" isLoading={isLoading} />
            <EuiHeader title="Vehicle Inbox" ></EuiHeader>
            <VehicleInspectionList id="inspectionsID" isLoading={isLoading} data={allInspections} />
        </EuiRow>
    )
}
export default VehicleInboxMenu;