import { getAuthToken, getTokenAndRenew } from "./adalConfig";
import { _axios } from "./api-client";

export default class TokenHelper {

    static setToken(token) {
      //  localStorage.setItem("token", token);

       // _axios.defaults.headers.authorization = "Bearer " + token;
        
         getTokenAndRenew().then((token)=>{
            _axios.defaults.headers.authorization = "Bearer " + token;
         }).catch(error => {
            _axios.defaults.headers.authorization = "Bearer " + getAuthToken(); 
         });
       
    }
}