import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../../eui-components/row";
import Table from "../../../components/table";
import PageHelper from "../../../utils/page-helper";
import DateUtils from "../../../utils/date-utils";
import DialogModal from "../../../components/dialog";
import ApiClient from "../../../utils/api-client";
import ActivityInboxHelper from "../../../utils/activity-inbox-helper";
import { ToastContainer, toast } from 'react-toastify';
import constants from "../../../utils/constants";
import green from '@material-ui/core/colors/green';
import { Chip, Tooltip } from "@material-ui/core";
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import EuiProgressBar from "../../../eui-components/progressbar";
import CircularProgressWithLabel from "../../../components/circularProgressWithLabel";
import EuiSwitch from "../../../eui-components/switch";

class ActivityList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            activityColumns: [
                {
                    field: 'assigned', title: '',
                    render: (rowData) => (rowData.assigned ?? 0) ? <i className="icon icon-avatar" style={{ fontSize: 21, marginLeft: 5 }}></i> : null,
                    filtering: false,
                    width: "1%",
                    type: "boolean"
                },
                {
                    field: 'id', title: 'Activity ID',
                    filterCellStyle: { maxWidth: 110, display: "flex" },
                    width: "10%",
                },
                { field: 'name', title: 'Activity Name', width: "20%", },
                { field: 'customerName', title: 'Customer', width: "10%", },
                { field: 'workplanName', title: 'Workplan', width: "20%", },
                { field: 'siteName', title: 'Site Code', width: "10%", },
                { field: 'aspCompany', title: 'ASP Company', width: "10%", },
                {
                    field: 'sentToRsc',
                    title: 'Inbox Date',
                    defaultSort: 'desc',
                    filterCellStyle: { maxWidth: 160, display: "flex" },
                    render: (rowData) => rowData.completedMaxDate ? <div className="flex-start-content">
                        <span className="mr-3">{DateUtils.toDateString(rowData.sentToRsc, "YYYY-MM-DD HH:mm")}</span>
                        <CircularProgressWithLabel value={rowData.percentage} style={{ color: (rowData.percentage < 50 ? "#ED0E00" : '#1174E6'), width: 30, height: 30 }} />
                    </div>
                        : <div className="flex-start-content"><span className="mr-3">{DateUtils.toDateString(rowData.sentToRsc, "YYYY-MM-DD HH:mm")}</span><i className="icon icon-check color-green icon-lg m-1"></i></div>,
                    //<EuiProgressBar value={rowData.percentage} color={ActivityInboxHelper.getPercentageColor(rowData.percentage)} />,
                    width: "14%",
                }
            ],
            openFilter: true,
            openDialog: false,
            openDialogUnassign: false,
            openDialogPreview: false,
            selectedDocument: { id: 0 },
            tableRef: React.createRef(),
            assignedFilter: 1,
            allActivities: false
        }
    }

    componentDidMount() {
        this.getActivities(7);
        PageHelper.getCoordinates();
    }

    getActivities = (amount = 0) => {
        const userData = this.props.userData;
        this.setState({ isLoading: true }, () => {
            ApiClient.post("ActivityInbox/GetActivitiesOfUser",
                { id: parseInt(userData.userData.userId), amount, rscCenterId: userData.rscPerson?.rscCenterId, userRole: userData.rscPerson?.personType }, {}, 12000000)
                .then(response => {
                    const data = ActivityInboxHelper.mapActivities(response.data);
                    this.props.setActivities(data);
                    this.setState({ isLoading: false });

                }).catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                })
        });
    }

    assignToMe = () => {
        const userData = this.props.userData;
        this.setState({ openDialog: false, isLoading: true }, () => {
            const logInfo = {
                userId: parseInt(this.props.userData.userData.userId),
                latitude: PageHelper.coord.latitude,
                longitude: PageHelper.coord.longitude,
            }
            ApiClient.post("ActivityInbox/AssignActivity", {
                id: this.props.activityInboxData.currentActivity.id,
                updateUser: this.props.userData.userData.userId, logInfo,
                rscCenterId: userData.rscPerson?.rscCenterId,
                userRole: userData.rscPerson?.personType 
            })
                .then(response => {
                    const data = [...this.props.activityInboxData.activities];
                    const i = data.indexOf(this.props.activityInboxData.currentActivity);
                    this.setState({ isLoading: false });
                    if (!response.data.success) {
                        //data.splice(i, 1);
                       // this.props.setActivities(data);
                       toast.error(response.data.errorMessage || constants.errorMessage, { containerId: 'AL', position: toast.POSITION.TOP_CENTER });
                       this.getActivities(this.state.allActivities ? 0 : 7)
                        return;
                    }
                    data[i].assigned = 1;
                    data[i].assignedOn = DateUtils.getUtcDate();
                    data[i].rscPersonId = this.props.userData.rscPerson.id;

                    data[i].plainActivity.assigned = 1;
                    data[i].plainActivity.assignedOn = DateUtils.getUtcDate();
                    data[i].plainActivity.rscPersonId = this.props.userData.rscPerson.id;

                    this.props.setActivities(data);
                    
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    unassignActivity = () => {
        this.setState({ openDialogUnassign: false, isLoading: true }, () => {
            const logInfo = {
                userId: parseInt(this.props.userData.userData.userId),
                latitude: PageHelper.coord.latitude,
                longitude: PageHelper.coord.longitude,
            }
            const payload = { id: this.props.activityInboxData.currentActivity.id, updateUser: this.props.userData.userData.userId,rscCenterId: this.props.userData.rscPerson?.rscCenterId,
                userRole: this.props.userData.rscPerson?.personType, logInfo }
            ApiClient.post("ActivityInbox/UnassignActivity", payload)
                .then(response => {
                    const data = [...this.props.activityInboxData.activities];
                    const i = data.indexOf(this.props.activityInboxData.currentActivity);
                    this.setState({ isLoading: false });
                    if (!response.data.success) {
                        toast.error(response.data.errorMessage || constants.errorMessage, { containerId: 'AL', position: toast.POSITION.TOP_CENTER });
                        this.getActivities(this.state.allActivities ? 0 : 7)
                        
                        return;
                    }
                    data[i].assigned = 0;
                    data[i].assignedOn = null;
                    data[i].rscPersonId = null;

                    data[i].plainActivity.assigned = 0;
                    data[i].plainActivity.assignedOn = null;
                    data[i].plainActivity.rscPersonId = null;

                    this.props.setActivities(data);
                   
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    renderSessionActions = (rowData) => {
        return <span>
            <button className="btn " style={{ marginRight: 5, background: (rowData.liveSessionStatus === 1 ? green[300] : "") }}>Join</button>
        </span>
    }

    joinSession = (rowData) => {
        const activity = { ...rowData };
        if (this.props.userData.userData.jobRole !== constants.userRoles.RSC_SITE_SUPERVISOR) {
            activity.viewOnly = true;
        }
        this.getSubactivitesOfActivityAndJoin(activity);
    }

    getSubactivitesOfActivityAndJoin = (activity) => {
        this.setState({ isLoading: true }, () => {
            ApiClient.post( "ActivityInbox/GetDetailsOfActivity", {
                id: activity.id,
               // id : 5861033
            })
                .then(response => {
                    if (!response.data) {
                        this.setState({ isLoading: false });
                        //Add notification
                        return;
                    }
                    debugger;
                    if(response.data?.rscPersonId != this.props.userData?.rscPerson?.id && !activity.viewOnly ) {
                        toast.error("Activity is not assigned to you. It is assigned to other inspector. Please refresh your page to see the latest assigments.", { containerId: 'AL', position: toast.POSITION.TOP_CENTER })
                        this.setState({ isLoading: false });
                        return;
                    }
                    
                    const data = ActivityInboxHelper.mapSubactivities(response.data.subactivities, activity.aspResource, activity.addInfo);
                    const recordings = PageHelper.mapRecordings(response.data.recordings);
                    const teamVerificationLogs = ActivityInboxHelper.mapTeamLogs(response.data.teamVerificationLogs);
                    const resourceVerificationLogs = ActivityInboxHelper.mapResourceLogs(response.data.resourceVerificationLogs);
                    const teamInfo = response.data.teamInfo || {};
                    teamInfo.status = teamVerificationLogs.length > 0 ? !teamVerificationLogs.some(item => !item.validStatus) : null;
                    teamInfo.verificationSessionId = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].verificationSessionId : "";
                    teamInfo.isOffsiteVerification = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].isOffsiteVerification : null;
                    teamInfo.resourceStatus = resourceVerificationLogs.length > 0 ? !resourceVerificationLogs.some(item => !item.status) : null;
                    this.setState({ isLoading: false },
                        () => {
                            activity["subActivities"] = data;
                            this.props.setCurrentActivity({...activity , activityRatingDate : response.data?.activityRatingDate});

                            const liveSessionInfo = { ...response.data.liveSessionInfo };
                            liveSessionInfo.sessionName = activity.name;
                            liveSessionInfo.siteName = activity.siteName;
                            liveSessionInfo.rscScope =  activity?.plainActivity?.rscScope;
                            liveSessionInfo.activityId = activity.id;

                            this.props.setLiveSessionInfo(liveSessionInfo);

                            const teamsLogInfo = { teamInfo, teamVerificationLogs, resourceVerificationLogs };
                            this.props.setTeamsLogInfo(teamsLogInfo);

                            this.props.setRecordings(recordings);
                            this.props.setRatings(response.data.ratings || []);
                            this.props.toggleLiveStreamMod(true);
                            setTimeout(() => this.props.history.push("/live-streaming"), 1000);
                        });
                }).catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                })
        });
    }

    getFilteredActivities = (type) => {
        if (!this.props.activityInboxData.activities) {
            return [];
        }

        if (type === 1) {
            return this.props.activityInboxData.activities.filter(item => item.assigned);
        } else if (type === 2) {
            return this.props.activityInboxData.activities.filter(item => !item.assigned);
        } else if (type === 3) {
            return this.props.activityInboxData.activities;
        }

        return [];
    }

    render() {
        return (<EuiRow>

            <Table
                actions={[
                    rowData => ({
                        icon: () => <span><button className="btn primary">Assign</button></span>,
                        onClick: (event, rowData) => this.setState({ openDialog: true }, () => this.props.setCurrentActivity(rowData)),
                        hidden: this.props.userData.userData.jobRole !== constants.userRoles.RSC_SITE_SUPERVISOR || rowData.assigned
                    }),
                    rowData => ({
                        icon: () => this.renderSessionActions(rowData),
                        onClick: (event, rowData) => { this.joinSession(rowData) },
                        hidden: this.props.userData.userData.jobRole === constants.userRoles.RSC_SITE_SUPERVISOR && !rowData.assigned
                    }),
                    rowData => ({
                        icon: () => <i className="icon icon-cross color-red text-lg m-1" ></i>,
                        onClick: (event, rowData) => this.setState({ openDialogUnassign: true }, () => this.props.setCurrentActivity(rowData)),
                        tooltip: "Unassign Activity",
                        hidden: this.props.userData.userData.jobRole !== constants.userRoles.RSC_SITE_SUPERVISOR || !rowData.assigned
                    }),
                    {
                        icon: () => <i className="icon icon-info m-1" style={{ fontSize: 19 }}></i>,
                        tooltip:<>
                     {   <span style={{ fontSize: 11,display: "block"}}>
                                <b>1.Session should not Accepted or Rejected  </b> <br />
                                <b>2.Session type should not be VSS/VCOP </b><br />
                                <b>3.Session should not exempted </b><br />
                                <b>4.Session should be sent to RSC </b><br />
                                <b>5.Session type should not be Remote Customer Acceptance </b> <br />


                                
                       </span>}
                       </>,
                        isFreeAction: true
                    },
                    {
                        icon: () => <i className="icon icon-reload m-1" style={{ fontSize: 19 }}></i>,
                        tooltip: "Refresh",
                        onClick: (event, rowData) => this.getActivities(this.state.allActivities ? 0 : 7),
                        isFreeAction: true
                    },
                    {
                        icon: () => <i className="icon icon-filter m-1" style={{ fontSize: 19, opacity: (this.state.openFilter ? "0.5" : "1") }}></i>,
                        tooltip: "Filter Activities",
                        onClick: (event, rowData) => { this.setState({ openFilter: !this.state.openFilter }) },
                        isFreeAction: true
                    },
                ]}
                columns={this.state.activityColumns}
                data={this.getFilteredActivities(this.state.assignedFilter)}
                isLoading={this.state.isLoading}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10],
                    cellStyle: { fontSize: "0.85rem", padding: 0, paddingLeft: 10 },
                    filtering: this.state.openFilter,
                    search: false,
                    paginationType: "stepped",
                    actionsColumnIndex: -1,
                    draggable: true
                }}
                title={<div className="flex-start-content" style={{ marginLeft: 34 }}>
                    <Chip className={"summary-chip color-text "}
                        style={{ backgroundColor: blue[400], fontSize: 14, marginLeft: 0, width: "auto", boxShadow: (this.state.assignedFilter === 1 ? " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "") }}
                        label={"Assigned:" + this.getFilteredActivities(1).length}
                        clickable={true}
                        icon={this.state.assignedFilter === 1 ? <i className="icon icon-filter" /> : null}
                        onClick={() => this.setState({ assignedFilter: 1 })}></Chip>
                    <Chip className={"summary-chip color-text "}
                        style={{ backgroundColor: red[200], fontSize: 14, width: "auto", margin: 5, boxShadow: (this.state.assignedFilter === 2 ? " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "") }}
                        label={"Unassigned:" + this.getFilteredActivities(2).length}
                        clickable={true}
                        icon={this.state.assignedFilter === 2 ? <i className="icon icon-filter" /> : null}
                        onClick={() => this.setState({ assignedFilter: 2 })}></Chip>
                    <Chip className={"summary-chip color-text "}
                        style={{ backgroundColor: "lightgray", fontSize: 14, width: "auto", boxShadow: (this.state.assignedFilter === 3 ? " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "") }}
                        label={"All RSC Inbox:" + this.getFilteredActivities(3).length}
                        clickable={true}
                        icon={this.state.assignedFilter === 3 ? <i className="icon icon-filter" /> : null}
                        onClick={() => this.setState({ assignedFilter: 3 })}></Chip>

                    <EuiSwitch
                        id="filterActivityInboxSwitch"
                        className="m-1 ml-2"
                        onChange={() => this.setState({ allActivities: !this.state.allActivities }, () => this.getActivities(this.state.allActivities ? 0 : 7))}
                        checked={this.state.allActivities}
                        dataEnabled="Showing All"
                        dataDisabled="Showing Last Week"
                        name="allActivities"
                    />
                   </div>}
                tableRef={this.state.tableRef}
            >
            </Table>

            <DialogModal
                buttonName="OK"
                content={<p>{"Are you sure you want to assign me to Activity ID : " + this.props.activityInboxData.currentActivity.id + " ?"}</p>}
                handleClose={() => this.setState({ openDialog: false })}
                id="assignDialog"
                onClick={this.assignToMe}
                open={this.state.openDialog}
                title="Assign to Me"
            >
            </DialogModal>
          
            <DialogModal
                buttonName="OK"
                content={<p>{"Are you sure you want to unassign me to Activity ID : " + this.props.activityInboxData.currentActivity.id + " ?"}</p>}
                handleClose={() => this.setState({ openDialogUnassign: false })}
                id="unassignDialog"
                onClick={this.unassignActivity}
                open={this.state.openDialogUnassign}
                title="Unassign Me"
                type="warning"
            >
            </DialogModal>

            <ToastContainer enableMultiContainer containerId={'AL'} autoClose={2500} />
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, activityInboxData: state.activityInbox });
const mapDispatchToProps = dispatch => {
    return {
        setActivities: (activities) => dispatch({ type: "SET_ACTIVITIES", payload: activities }),
        setActivityPageMod: (mod) => dispatch({ type: "SET_ACTIVITY_PAGE_MOD", payload: mod }),
        setCurrentActivity: (activity) => dispatch({ type: "SET_CURRENT_ACTIVITY", payload: activity }),
        setLiveSessionInfo: (liveSessionInfo) => dispatch({ type: "SET_LIVE_SESSION_INFO", payload: liveSessionInfo }),
        toggleLiveStreamMod: (mod) => dispatch({ type: "TOGGLE_LIVE_STREAM_MOD", payload: mod }),
        setRecordings: (recordings) => dispatch({ type: "SET_RECORDINGS", payload: recordings }),
        setTeamsLogInfo: (teamsLogInfo) => dispatch({ type: "SET_TEAMS_LOG_INFO", payload: teamsLogInfo }),
        setRatings: (ratings) => dispatch({ type: "SET_RATINGS", payload: ratings }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(ActivityList));