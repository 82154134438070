import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageHelper from "../../utils/page-helper";
import { ToastContainer } from 'react-toastify';
import constants from "../../utils/constants";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiRow from "../../eui-components/row";
import DragAndDropFileUploader from "./dragandDropFiles";
import _ from "lodash";


const UploadDocuments = (props) => {
    const documentTypes = constants.cmsDocumentTypes;
    const [editMod, setEditMod] = useState(false);
    const [editTitle, setEditedTitle] = useState("");
    const [editType, setEditedType] = useState("");
    const dispatch = useDispatch();
    const [files, setFiles] = useState([]);
    const [editDesc, setEditedDesc] = useState("");

    const documentsColumns = [
        props.columns.name && props.columns.description ? {
            title: "Name",
            key: "lastModifiedItem",
            width: "30%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {

                    var document = files?.find(doc => doc.lastModified === cellData);

                    const cellDataName = document?.title;
                    td.innerHTML = cellDataName
                }
            }
        } : (props.columns.name && !props.description) ? {
            title: "Name",
            key: "lastModifiedItem",
            width: '40%',
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var document = files.find(doc => doc.lastModified == cellData);
                    const cellDataName = document.title;
                    if (editMod && document && document.edit && (document.editId == cellData)) {
                        var value = (editTitle.length === 0) ? cellDataName : editTitle;
                        td.innerHTML = `<span><input style = "width: 90%; " type="text" id="update_" value="${value}"></input>`
                        td.querySelector("#update_").addEventListener('change', (e) => update(e, "title"));
                    }
                    else {
                        td.innerHTML = cellDataName
                    }
                }
            },
            resize: true
        } : {},
        props.columns.description ? {
            title: "Description",
            key: "lastModifiedItem",
            width: "30%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var document = files?.find(doc => doc.lastModified == cellData);
                    var cellDataDescription = document?.desc ?? "";
                    if (editMod && document && document.edit && (document.editId == cellData)) {
                        var value = (editDesc.length === 0) ? cellDataDescription : editDesc;
                        td.innerHTML = `<span><input id="update_" value="${value}"></input></span>`;
                        td.querySelector("#update_").addEventListener('focusout', (e) => update(e, "desc", cellData));
                    }
                    else {
                        td.innerHTML = cellDataDescription;
                    }
                }
            }

        } : {},
        props.columns.type ? {
            title: "Document Type *",
            key: "lastModifiedItem",
            color: "red",
            width: props.columns.description ? "30%" : '30%',
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var document = files.find(doc => doc.lastModified === cellData);
                    var documentType = ""
                    if (document.documentType != null) {
                        documentType = PageHelper.mapDocumentTypes(document.documentType);
                    }
                    else {
                        documentType = "Select a document type *";
                    }
                    if (editMod && document && document.edit && (document.editId === cellData)) {
                        var selectedItem = "";
                        var optionsList = `<option disabled selected value=""></option>`;
                        var option = "";
                        documentTypes.map(item => {
                            if (document.documentType && editType == null) {
                                if (item.value === document.documentType) {
                                    selectedItem = item.text;
                                    option = `<option value="${selectedItem}" selected>${item.text}</option>`
                                }
                                else {
                                    option = `<option value="${item.text}">${item.text}</option>`
                                }
                                optionsList += option
                            }
                            else if (editType) {
                                if (item.text.trim() === editType.trim()) {
                                    selectedItem = item.text;
                                    option = `<option value="${selectedItem}" selected>${item.text}</option>`
                                }
                                else {
                                    option = `<option value="${item.text}">${item.text}</option>`
                                }
                                optionsList += option
                            }
                            else {
                                option = `<option value="${item.text}">${item.text}</option>`
                                optionsList += option
                            }
                        });
                        td.innerHTML = `<span><select style = "width: 200px; font-height: 400px; border: 1px solid #878787; line-height: 1.5; padding: 5px 7px 4px; margin-right: 8px; font-weight: 400; font-size: 14px; background: #fff; color: #242424; font-family: Ericsson Hilda; value="" name="selectDialog" id="update_""> ${optionsList}</select>`
                        td.querySelector("#update_").addEventListener('change', (e) => update(e, "type"));
                    }
                    else {
                        td.innerHTML = documentType;
                        ;
                    }
                }
            }
        } : {},
        props.columns.enableGenericDocuments ? {
            title: "Classification",
            width: "25%",
            key: "lastModifiedItem",
            onCreatedCell: (td, cellData) => {
                if (cellData != null) {
                    var document = files?.find(doc => doc.lastModified == cellData);
                    var docId = document.lastModified;
                    var selected = document.selected;
                    selected ? td.innerHTML = `<label class="switch" > <input checked id="genericDocCheckBox_${docId}" type="checkbox"> <i class="ball"></i> <span data-enabled="Generic" data-disabled="Specific"></span></label>` : td.innerHTML = `<label class="switch"> <input id="genericDocCheckBox_${docId}" type="checkbox"> <i class="ball"></i> <span data-enabled="Generic" data-disabled="Specific"></span></label>`;
                    td.querySelector("#genericDocCheckBox_" + docId).addEventListener('change', (e) => setGenericDocuments(e, cellData));
                }
            }

        } :
            {}, {
            title: "",
            key: "lastModifiedItem",
            width: "5%",
            onCreatedCell: (td, cellData) => {
                var document = files.find(doc => doc.lastModified == cellData);
                if (editMod && cellData == document.lastModified && (document.editId == cellData)) {
                    td.innerHTML = `<span id="save_" class="tooltip"><i id="save_" class="icon icon-check text-lg clickable color-green "></i><span class="message top-end">Save</span></span>`;
                    td.querySelector("#save_").addEventListener('click', () => save(cellData));
                }
                else {
                    td.innerHTML = `<span  id="edit_" class="tooltip"><i id="edit_" style={font-weight:500px;} class="icon icon-edit color-black  text-lg clickable "></i><span class="message top-end">Edit</span></span>`;
                    td.querySelector("#edit_").addEventListener('click', () => setEditMode(cellData))
                }
            }
        }, {
            title: "",
            key: "lastModifiedItem",
            width: "5%",
            onCreatedCell: (td, cellData) => {
                if (cellData) {
                    var document = files?.find(doc => doc.lastModified == cellData);
                    if (editMod && cellData === document.lastModified && (document.editId == cellData)) {
                        td.innerHTML = `<span id="cancel_" class="tooltip"><i id="cancel_"  class="icon icon-cross  text-lg clickable color-red "></i><span class="message top-end">Cancel</span></span>`
                        td.querySelector("#cancel_").addEventListener('click', () => cancel(cellData));
                    }
                    else {
                        td.innerHTML = `<span id="delete_" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable "></i><span class="message top-end">Delete Document</span></span>`;
                        td.querySelector("#delete_").addEventListener('click', () => softDeleteDocuments(cellData));
                    }
                }
            }
        },
    ];

    const cancel = (data) => {
        var updatedArray = []
        files.map(item => {
            if (item.lastModified == data) {
                item["edit"] = false;
                item["editId"] = 0;
            }
            updatedArray.push(item);
        });
        setEditMod(false);
        setFiles(updatedArray);
        setEditedTitle("");
        setEditedType(null);
        setEditedDesc("");
    }

    const save = (data, dDesc) => {
        var updatedArray = [];
        var desc = editDesc || dDesc;

        files.map(item => {
            if (item.lastModified === data) {
                item.edit = false;
                item.editId = 0;
                if (editTitle.length !== 0) {
                    item.title = editTitle;
                    item.name = editTitle;

                }
                if (editType != null && editType.length > 0) {
                    item.documentType = constants.cmsDocumentTypes.find(x => x.text === editType)?.value;
                }
                if (desc) {
                    item.desc = desc
                }
            }
            updatedArray.push(item);
        });

        setEditMod(false);
        setEditedTitle("");
        setEditedType(null);
        setEditedDesc("");
        setFiles(updatedArray);
        dispatch({ type: props.dispatch, payload: updatedArray });
    }

    const setEditMode = (data) => {
        const updatedArray = []
        files.map(item => {
            if (item.lastModified === data) {
                item["edit"] = true;
                item["editId"] = data;
            }
            updatedArray.push(item);
        });
        setEditMod(true);
        setFiles(updatedArray);
    }

    const update = (e, object, cellData) => {
        if (object === "title") {
            setEditedTitle(e.target.value)
        }
        else if (object === "type") {
            setEditedType(e.target.value);
        }
        else {
            setEditedDesc(e.target.value);
            if (cellData) {
                save(cellData, e.target.value)
            }
        }
    }

    const softDeleteDocuments = (data) => {
        const updatedDocumentsList = files.filter(item => item.lastModified != data);
        setFiles(updatedDocumentsList);
        dispatch({ type: props.dispatch, payload: [] })
        dispatch({ type: props.dispatch, payload: updatedDocumentsList })
    }

    const handleChange = (data) => {
        const listFile = [...files]
        Array.from(data).forEach(element => {
            var tempItem = element
            tempItem.title = element.name;
            tempItem.documentType = element.type;
            tempItem.lastModifiedItem = element.lastModified;
            tempItem.selected = false;
            listFile.push(tempItem);
        });
        let uniqueFiles = _.uniqBy(listFile, 'title');

        setFiles(uniqueFiles);

        dispatch({ type: props.dispatch, payload: uniqueFiles });
    }
    const setGenericDocuments = (e, cellData) => {
        const checked = e.target.checked;
        const updatedArray = []
        if (checked === true) {
            files.map(item => {
                if (item.lastModified === cellData) {
                    item.generic = true;
                    item.selected = true;
                }
                updatedArray.push(item);
            });
            setFiles(updatedArray);
            dispatch({ type: props.dispatch, payload: updatedArray });
        } else if (checked === false) {
            files.map(item => {
                if (item.lastModified === cellData) {
                    item.generic = false;
                    item.selected = false;
                }
                updatedArray.push(item);
            });
            setFiles(updatedArray);
            dispatch({ type: props.dispatch, payload: updatedArray });
        }
    }

    return (<>
        <div style={{ position: "relative", overflow: "hidden", textAlign: "center", alignContent: "center", borderWidth: "1.5", borderStyle: "dotted", display: "flex", backgroundColor: "#f1f1f1", height: "200px", width: "100%", opacity: "30%" }}>
            <div className="flex-start-content" style={{ position: "relative", textAlign: "center", opacity: "100%", fontSize: "20px", marginLeft: "250px", backgroundColor: "#f1f1f1", color: "black" }}><span><i className="icon icon-plus"></i></span>Drag and drop your files here</div>
            <div style={{ opacity: "0%", position: "absolute" }}>
                <DragAndDropFileUploader
                    handleChange={(files) => handleChange(files)}
                    name="Files Upload"
                    hoverTitle="Drag and drop your documents"
                    children={<div style={{ position: "absolute", padding: "8px 800px 200px 8px" }}></div>}

                />
            </div>
        </div>
        <EuiRow key={files + editTitle + editType + editDesc + editMod}>
            <ToastContainer containerId={'UM'} autoClose={2500} />
            <div className="col sm-12 m-12 l-12 xl-12">
                {(files && files.length !== 0 && props.columns.enableGenericDocuments) ?
                    <>
                        <ObseleteEuiTable
                            classes="tiny"
                            columns={documentsColumns}
                            data={files}
                            id={props.id + "Table"}
                            hideTableInfo={true}
                            key={editTitle + editType + editDesc + editMod}
                        >
                        </ObseleteEuiTable>
                        <label style={{ fontSize: "14px", color: 'grey' }}>* Please toggle the classification switch to make the document accessible to all ASP companies.</label>
                    </>
                    : (files && files.length !== 0) ?
                        <ObseleteEuiTable
                            classes="tiny"
                            columns={documentsColumns}
                            data={files}
                            id={props.id + "Table"}
                            hideTableInfo={true}
                            key={editTitle + editType + editDesc + editMod}
                        >
                        </ObseleteEuiTable> : null}
            </div>
        </EuiRow >
    </>
    )
}
export default UploadDocuments;