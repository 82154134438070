import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";


class SiteErisiteInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        return (<EuiRow>
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="erilatitudetxFilter" name="erilatitudetx" label="Erisite Latitude" value={this.props.siteData.site.erilatitudetx} />
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="erilongitudetxFilter" name="erilongitudetx" label="Erisite Longitude" value={this.props.siteData.site.erilongitudetx} />
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="eriSysSiteIdFilter" name="eriSysSiteId" label="Erisite Site ID" value={this.props.siteData.site.eriSysSiteId} />
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="eriCustomerIdFilter" name="eriCustomerId" label="Erisite Customer ID" value={this.props.siteData.site.eriCustomerId} />           
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="erisiteUpdateDatetime" name="erisiteUpdateDatetime" label="Erisite Update Datetime" value={DateUtils.toDateString(this.props.siteData.site.erisiteUpdateDatetime, "YYYY-MM-DD HH:mm:ss") || "(-----,----)"} />                      
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, siteData: state.site });
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SiteErisiteInfo); 