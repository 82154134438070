import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AspCompaniesList from './aspCompaniesList';
import ApiHelper from "../../utils/api-helper";
import ApiClient from '../../utils/api-client';


function AspCompanies(props) {

    const {reportingPeriods, setReportingPeriods, aspRscCountries, setAspRscCountries} = props;

    useEffect(() => {
        if (props.navbarData.navBarView) {
            props.toggleNavbar();
        }
        ApiHelper.setCountriesWithDetails((data) => props.setCountriesList(data), (data) => props.setCustomersList(data));
        ApiHelper.setAllUsers((data) => props.setAllUsers(data));
        if (reportingPeriods?.length === 0) {
            ApiClient.post("Asp/GetReportingPeriods").then(response => {
                setReportingPeriods(response.data)
            });
        }
        if (!aspRscCountries?.length) {
            ApiClient.post("IncentivesPlan/GetRscCountries")
                .then(_response => {
                    setAspRscCountries(_response.data)
                });
        }
    }, [])
    
  return (
     <AspCompaniesList aspRscCountries={aspRscCountries}/>
  )
}

// class AspCompanies extends React.Component {
//     componentDidMount() {
        // if (this.props.navbarData.navBarView) {
        //     this.props.toggleNavbar();
        // }
        // ApiHelper.setCountriesWithDetails((data) => this.props.setCountriesList(data), (data) => this.props.setCustomersList(data));
        // ApiHelper.setAllUsers((data) => this.props.setAllUsers(data));
        // if (this.props.reportingPeriods.length === 0) {
        //     ApiClient.post("Asp/GetReportingPeriods").then(response => {
        //         this.props.setReportingPeriods(response.data);
        //     });

        // }
        // if (!this.props.rscCountries?.length) {
        //     ApiClient.post("IncentivesPlan/GetRscCountries")
        //         .then(_response => {
        //             this.props.setAspRscCountries(_response.data);
        //         });
        // }
//     }
//     render() {
//         return <AspCompaniesList />
//     }
// }
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, reportingPeriods: state.asp.reportingPeriods, aspRscCountries: state.asp.rscCountries });
const mapDispatchToProps = dispatch => {
    return {
        toggleNavbar: () => dispatch({ type: "TOGGLE_NAVBAR" }),
        setCountriesList: (countries) => dispatch({ type: "SET_ALL_COUNTRIES", payload: countries }),
        setCustomersList: (customers) => dispatch({ type: "SET_ALL_CUSTOMERS", payload: customers }),
        setReportingPeriods: (reportingPeriods) => dispatch({ type: "SET_REPORTING_PERIODS", payload: reportingPeriods }),
        setAllUsers: (users) => dispatch({ type: "SET_ALL_USERS", payload: users }),
        setAspRscCountries: (countries) => dispatch({ type: "SET_ASP_RSC_COUNTRIES", payload: countries }),
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AspCompanies));