import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import FormField from "../../eui-components/formfield";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import AspHelper from "../../utils/asp-helper";
import SasHelper from "../../utils/sas-helper";


class ResourceInfo extends React.Component {
    
    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
    }

    createComponent = () => {
        this.props.createEditResourceForm(true);
    }
    render() {
        return (
            <EuiRow>
                <div className="column sm-12 lg-3 xl-3" style={{ maxHeight: "300px" }}>
                    <EuiTextField className="p-1 w-10"
                        fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="id"
                        label="Resource ID" value={this.props.aspResourcesData.resource?.id} />
                    <EuiTextField className="p-1 w-10"
                        fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="name"
                        label="Employee Name" value={this.props.aspResourcesData.resource?.name} />
                    <EuiTextField
                        className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="email"
                        label="Email Address" value={this.props.aspResourcesData.resource?.email} />
                    <EuiTextField
                        className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="phone"
                        label="Mobile Number" value={this.props.aspResourcesData.resource?.phone} />
                    <EuiTextField
                        className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="LME"
                        label="ASP Manager Email Address" value={this.props.aspResourcesData.resource?.lineManagerEmail}
                    />
                    <FormField>
                        <label style={{ color: "gray", fontSize: "14px" }}>Job Roles </label>
                        {(this.props.aspResourcesData.resource?.jobs ?
                            this.props.aspResourcesData.resource?.jobs.map(e =>
                                (<span id="rolePill" className="pill">{e}</span>))
                            : null)}
                    </FormField>
                    <EuiTextField
                        className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} required labelstyle={{ color: "gray" }} id="isignum"
                        label="iSignum" value={this.props.aspResourcesData.resource?.isignum} />
                    <EuiTextField
                        className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="jobId"
                        label="Position" value={this.props.aspResourcesData.resource?.positionName}
                    />
                </div>
                <div className="column sm-12 lg-3 xl-3" style={{ maxHeight: "300px" }}>
                    <EuiTextField
                        className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="jobId"
                        label="Customer" value={this.props.userData.customers?.find(c => c.value === this.props.aspResourcesData.resource?.customerId)?.text}
                    />
                    <EuiTextField
                        multiLine={true}
                        className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="company"
                        label="ASP Company Name" value={this.props.aspResourcesData.resource?.aspCompany}
                    />
                    <EuiTextField
                        className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="status"
                        label="Status" value={
                            (typeof (this.props.aspResourcesData.resource?.status) == "number" ?
                                this.aprops.aspResourcesData.statuses?.find(this.props.aspResourcesData.resource?.status).text
                                : this.props.aspResourcesData.resource?.status)
                        }
                    />
                    <EuiTextField
                        className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="teamLead"
                        label="Team Lead" value={(this.props.aspResourcesData.resource?.teamLead ? "Yes" : "No")} />
                    <EuiTextField
                        multiLine={true}
                        className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="reamrks"
                        label="Remarks" value={this.props.aspResourcesData.resource?.remarks} />
                    {(AspHelper.getProfileCreationState(this.props.aspResourcesData.resource, this.props.aspResourcesData.users) === "Not Created" ?
                        <EuiTextField
                            className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="userProfileCreated"
                            label="Login Account Status" value="Not Created" />
                        :
                        <>
                            <EuiTextField
                                className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="userProfileCreated"
                                label="Login Account Status" value="Created" />
                            <EuiTextField
                                className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="userProfileCreated"
                                label="Login Account Created Datetime"
                                value={AspHelper.getProfileCreationState(this.props.aspResourcesData.resource, this.props.aspResourcesData.users)}
                            />
                        </>
                    )}
                </div>
                <div className="column sm-12 lg-3 xl-3" style={{ maxHeight: "300px" }}>
                    <EuiTextField
                        className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="idtype"
                        label="Identification Type"
                        value={(this.props.aspResourcesData.resource?.identificationTypeId ? AspHelper.mapIdType(this.props.aspResourcesData.resource?.identificationTypeId) : "")} />
                    <EuiTextField
                        className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="idtype"
                        label="Identification Number"
                        value={(this.props.aspResourcesData.resource?.identificationNumber ? this.props.aspResourcesData.resource?.identificationNumber : "")} />
                    <EuiTextField
                        className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="nationality"
                        label="Nationality" value={this.props.aspResourcesData.resource?.country}
                    />
                    <EuiTextField
                        className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="idtype"
                        label="Identification Expiry Date"
                        value={(this.props.aspResourcesData.resource?.identificationExpiry ? DateUtils.toDateString(this.props.aspResourcesData.resource?.identificationExpiry) : "")} />
                    <EuiTextField
                        className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="idtype"
                        label="Last Modified Date Time"
                        value={(this.props.aspResourcesData.resource?.lastModifiedDate ? DateUtils.toDateString(this.props.aspResourcesData.resource?.lastModifiedDate, "YYYY-MM-DD HH:mm") : "")} />
                    <EuiTextField
                        className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="idtype"
                        label="Last Modified User"
                        multiLine={true}
                        value={(this.props.aspResourcesData.resource?.lastModifiedUserName ? this.props.aspResourcesData.resource.lastModifiedUserName : "")} />
                    <FormField className="p-1 w-5">
                        <label style={{ color: "gray", fontSize: "14px" }}>Resource CV</label>
                        <a href={SasHelper.addSasKey(this.props.aspResourcesData.resource?.cvPath, this.props.sasToken)} style={{ display: this.props.aspResourcesData.resource?.cvPath ? "block" : "none" }} to="_blank"><i className="icon icon-download-save clickable mr-2" style={{ fontSize: "14px", fontWeight:"bold" }} /> Download file </a>
                    </FormField>
                </div>
                {((this.props.aspResourcesData.resource?.profileImg ||
                    this.props.aspResourcesData.resource?.path) && this.props.aspResourcesData.resource?.faceId ?
                    <div className="column sm-12 lg-3 xl-3">
                        <img className="flex-middle-content"
                            src={(this.props.aspResourcesData.resource?.path ?
                                SasHelper.addSasKey(this.props.aspResourcesData.resource?.path, this.props.sasToken) :
                                SasHelper.addSasKey(this.props.aspResourcesData.resource?.profileImg, this.props.sasToken))} style={{ width: "50%", height: "180px" }} />
                        <EuiTextField
                            className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="faceId"
                            label="Face ID" value={this.props.aspResourcesData.resource?.faceId}
                        />
                    </div>
                    : null)}
            </EuiRow >
        );
    }
}
const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, sasToken: state.complaints.sasToken });
const mapDispatchToProps = dispatch => {
    return {
        changeResourcesMod: (mod) => dispatch({ type: "CHANGE_RESOURCE_MOD", payload: mod }),
        setAspResource: (resource) => dispatch({ type: "SET_RESOURCE", payload: resource }),
        createEditResourceForm: (state) => dispatch({ type: "CREATE_EDIT_RESOURCE_FORM", payload: state }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResourceInfo);