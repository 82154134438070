﻿import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import constants from "../../utils/constants";
import EuiTile from "../../eui-components/tile";
import DialogModal from "../../components/dialog";
import ApiClient from "../../utils/api-client";
import { red } from "@material-ui/core/colors";

import { Typography, makeStyles } from "@material-ui/core";
import { isEmpty, remove } from "lodash";
import ContentDeleteProjectDialog from "./ContentDeleteProjectDialog";
import CommonHelper from "../../utils/common-helper";



const ProjectsList = (props) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user);

    const Project = useSelector((state) => state.projects);
    const ProjectsList = useSelector((state) => state.projects.allProjects.data);
    const totalCount = Project.allProjects?.totalCount;

    const [isLoading, setIsLoading] = useState(false);

    const [errorTokenCode, setErrorTokenCode] = useState(false);
    const [errorMessageTokenCode, setErrorMessageTokenCode] = useState("");


    const [tokenCode, setTokenCode] = useState("");
    const [selectedProjectId, setSelectedProjectId] = useState(0);
    const [requestId, setRequestId] = useState(0);

  const [warning, setOpenWarning] = useState(false);
  const [removedItem, setDeletedItem] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const projectColumns = [
    {
      title: "ID",
      onCreatedCell: (td, cellData) => {
        td.innerHTML = `<a id="project_${cellData}">${cellData}</a>`;
        td.querySelector("#project_" + cellData).addEventListener("click", () =>
          openProjectDetails(cellData)
        );
      },
      key: "id",
      sort: "asc",
      width: "3%",
    },
    {
      title: "Project Name",
      key: "name",
      sort: "none",
    },
    {
      title: "Customer Name",
      key: "customerName",
      sort: "none",
      width: "10%",
    },
    {
      title: "Country",
      key: "countryName",
      sort: "none",
      width: "10%",
    },
    {
      title: "Status",
      key: "viewActive",
      sort: "none",
      width: "5%",
    },
    
    {
      title: "Erisite Project",
      key: "systemrecordid",
      width: "5%",
      hideFilter: true,
      onCreatedCell: (td, cellData) => {
        if (cellData != null) {
          td.innerHTML = `<span style = "margin-left: 30px;"><i id="check_" class="icon icon-check text-lg color-black "></i></span>`;
        } else {
          td.innerHTML = `<span style = "margin-left: 30px;"><i id="cross_" class="icon icon-cross text-lg color-black "></i></span>`;
        }
      },
    },
    {
      title: "",
      key: "id",
      width: "2%",
      hideFilter: true,
      notSearchable: true,
      onCreatedCell: (td, cellData) => {
        if (cellData) {
           var project = ProjectsList.find(p => p.id === cellData);    
           if(project.pendingDelete ){
            td.innerHTML =""
           } else{
            td.innerHTML = `<span id="delete_${cellData}" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable ${project.pendingDelete&&"disabled"}"></i><span class="message top-end ${project.pendingDelete&&"disabled"}" ">Delete Project</span></span>`;
            !project.pendingDelete && td.querySelector(`#delete_${cellData}`).addEventListener("click", () =>  confirmDelete(cellData) );

                    }
                }
      },
  },   {
    title: "",
    key: "id",
    width: "1%",
    hideFilter: true,
    onCreatedCell: (td, cellData) => {
        if (cellData) {
            let project = ProjectsList.find(item => item.id === cellData);    
            if(project.pendingDelete||isPending){
              td.innerHTML = `<span id="block _${cellData}" class="tooltip"><i id="block_${cellData}" style={font-weight:500px;} class="icon icon-block color-black  text-lg clickable "></i>
              <span class="message top-end">
              <span>Pending To Deleted  <span>
              </span>
              </span>`;
                    }

                }
            },

},
  ];
  const columns = React.useMemo(() => projectColumns, [projectColumns]);
  const data = React.useMemo(() => ProjectsList, [ProjectsList]);

  const confirmDelete = (projectId) => {
    // get tokenCode sent by email from api 
    setSelectedProjectId(projectId)
    CreateRequest(projectId)
    setDeletedItem(projectId);
  };

 const  CreateRequest = (projectId) => {
  // opend confirmation delete project
    const request={
            projectId:parseInt(projectId) ,
            userId:parseInt( userData.userData.id),
    }
    setIsLoading(true);
  ApiClient.post("Projects/CreateRequest", {...request}).then(response => {
        setOpenWarning(true);
        setRequestId(response.data)
        setIsLoading(false);
        setIsPending(false)

      }).catch(err => {
        CommonHelper.createNotification({ title: "Error", description: "An issue occurred while deleting the Project, please try again!", icon: "icon-triangle-warning" ,timeout: 5000});
          setOpenWarning(false);
          setConfirm(false);
          setDeletedItem(null);
          setIsLoading(false);

      }
      );
    
  };
  const VerifyCodeConfiramtion = () => {
    if (confirm === true) {   
      setIsLoading(true);
   
      const request={
        requestId:parseInt(requestId),
        userId:parseInt(userData.userData.id) ,
        tokenCode:tokenCode
}
      ApiClient.post("Projects/VerifyCode", {...request}).then(response => {
        setIsLoading(false);
        if (response.data) { 
          setIsPending(true)
          
          CommonHelper.createNotification({ title: "Success", description: "Project Deletion Request Created successfully", icon: "check", timeout: 5000 });
      } else {
          CommonHelper.createNotification({ title: "Error", description: "the token sent by Email its doesn`t match!", icon: "cross",timeout: 5000 });
          setOpenWarning(true);

        }   
       
      }).catch(err => {
      ///console.log("projects VerifyCode err",err)
        CommonHelper.createNotification({ title: "Error", description: "An issue occurred while deleting the project, please try again!", icon: "icon-triangle-warning" ,timeout: 5000});
         setOpenWarning(false);
         setIsLoading(false);
          setConfirm(false);
          setDeletedItem(null);
      }
      );
    }
  };
  useEffect(() => {
    if (confirm === true) {
      VerifyCodeConfiramtion();
      setConfirm(false);
      setOpenWarning(false);
    }
  }, [confirm]);

    const openProjectDetails = (projectId) => {
        const selectedProject = ProjectsList.find((item) => item.id === projectId);
        dispatch({ type: "SET_PROJECT", payload: selectedProject });
        dispatch({
            type: "CHANGE_PROJECT_MOD",
            payload: constants.pageMods.VIEW_ONLY,
        });
    };

    const onChangeTokenCode = (e) => {
        setTokenCode(e.target.value);
  };
  const projectsListData = ProjectsList.map((project) => {
    return {
      ID: project.id,
      Project_Name: project.name,
      Customer_Name: project.customerName,
      Country: project.countryName,
      Status: project.viewActive,
      Erisite_Project: project.systemrecordid ? "Yes" : "No",
    };
  });
  return (
    <>
      <EuiTile fullscreen key={ProjectsList}>
        <EuiRow disabled={isLoading ? "disabled" : ""}>
          <EuiLoading
            id="projectsToDetailLoading"
            isLoading={isLoading}
          /> 
   
          <ObseleteEuiTable
            classes="tiny split-striped"
            columns={projectColumns}
            data={data}
            id="projecListTable"
            scroll={false}
            sortable={true}
            paginated={true}
            totalCount={totalCount}
            search={true}
            rowsPerPage={10}
            filter={true}
            key={columns}
            exportExcel={true}
            viewActionButton={true}
            Reportdata={projectsListData}
            excelFileName={"Projects_Excel" + new Date().toISOString()}
            paginationItems={[15, 20, 30]}
          />
        </EuiRow>

                <DialogModal
                    buttonName="Delete"
                    disabled={isEmpty(tokenCode)}
                    type="warning"
                    title={
                        <div className="flex-start-content" style={{ marginLeft: 34 }}>
                            <Typography
                                style={{
                                    color: red[200],
                                    fontSize: 16,
                                    //   width: "452PX",
                                    margin: 5,
                                    lineHeight: "50px",
                                }}
                            >
                                Delete project reqaust
                            </Typography>
                        </div>
                    }

                    content={<ContentDeleteProjectDialog
                        errorTokenCode={errorTokenCode}
                        tokenCode={tokenCode}
                        onChangeTokenCode={onChangeTokenCode}
                        errorMessageTokenCode={errorMessageTokenCode} />
                    }
                    handleClose={() => setOpenWarning(false)}
                    mainButtonStyle={{ backgroundColor: "red" }}
                    id="alertDialog"
                    onClick={() => setConfirm(true)}
                    open={warning}
                    style={{ height: "50%" }}
                    //   contentStyle={{ width: "380px" }}
                    disableBackdropClick={true}
                />
            </EuiTile>
        </>
    );
};
export default withRouter(ProjectsList);
