const initialState = {
    navBarView: true,
    panelView: false,
    currentPage: {},
    openedGroups: [],
    currentContract: {},
    reportType: "photoReport",
    reportName: "",
    theme: "light",
    customersTree: true,
    aspTree: true
};

export default (state = initialState, action) => {
    switch (action.type) {
        case "TOGGLE_NAVBAR":
            return {
                ...state,
                navBarView: !state.navBarView
            };
        case "TOGGLE_PANEL":
            return {
                ...state,
                panelView: !state.panelView
            };
        case "CHANGE_PAGE":
            return {
                ...state,
                currentPage: action.payload
            };
        case "CHANGE_OPENED_GROUPS":
            return {
                ...state,
                openedGroups: action.payload
            };
        case "GET_REPORT":
            return {
                ...state,
                reportType: action.payload
            };

        case "SESSION_REPORT":
            return {
                ...state,
                reportFields: action.payload.reportFields,
                reportName: action.payload.reportName
            };
        case "CHANGE_THEME":
            return {
                ...state,
                theme: action.payload
            };
        case "Toggle_CUSTOMERS_TREE":
            return {
                ...state,
                customersTree: action.payload
            }
        case "TOGGLE_ASP_TREE":
            return {
                ...state,
                aspTree: action.payload
            }

        default:
            return state;
    }

};
