import React from "react";

export default class EuiTextArea extends React.Component {
    render() {
        const classes = [];
        this.props.fullwidth && classes.push("fullwidth");
        this.props.disabled && classes.push("disabled");
        this.props.required && classes.push("required");
        return (
            <div className={this.props.className ?? ""} style={this.props.divStyle}>
                {this.props.label && <label className={this.props.labelClass} style={{ fontSize: 14, ...this.props.labelstyle }} htmlFor={this.props.name}>{this.props.label}{this.props.optional ? <span style={{ color: "#878787", fontSize: 11 }}>{" (optional)"}</span> : ""}</label>}
                <textarea placeholder={this.props.placeholder} {...this.props} className={classes.join(' ')} />
            </div>
        );
    }
}
