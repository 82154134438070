import React from 'react'
import { useDispatch, useSelector } from "react-redux"; import FormField from "../../eui-components/formfield";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import EuiNumberField from '../../eui-components/numberfield';
import constants from '../../utils/constants';
import EuiTextField from '../../eui-components/textfield';

const AddEhsCompliance = (props) => {
    const dispatch = useDispatch();
    const AddedEhsCompliance = useSelector(state => state.asp.ehsCompliance);
    //const [reportingPeriods, setReportingPeriods] = useState([]);
    const reportingPeriods = useSelector(state => state.asp.reportingPeriods);
    const selectedASP = useSelector(state => state.asp.selectedASP);
    const selectedCountry = useSelector(state => state.asp.selectedCountry);
    const countries = useSelector(state => state.user.countries);
    const ehsCompliance = { ...AddedEhsCompliance }

    /*useEffect(() => {
        if (reportingPeriods.length == 0 && selectedASP != null) {
            ApiClient.post("Asp/GetReportingPeriodsByCompanyId", { id: parseInt(selectedASP?.id) }).then(response => {
                var reportingPeriodsList = response.data;
                if (props.mod == constants.actionMods.EDIT) {
                    var i = reportingPeriodsList?.unshift({ value: AddedEhsCompliance?.reportingPeriodId, text: AddedEhsCompliance?.reportingPeriodName });
                }
                setReportingPeriods(reportingPeriodsList);
            });
        }
    }, [selectedASP]);*/

    const handleChange = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        ehsCompliance[name] = value;
        dispatch({ type: "SET_EHS_COMPLIANCE", payload: ehsCompliance });
    }
    return (<FormField>
        <EuiTextField
            className="p-1 w-5"
            fullwidth
            value={selectedCountry}
            labelStyle={{ color: "gray" }}
            label="Country"
            name="Country"
            readOnly={true}
            id="AspCountryId"
        />
        <EuiAutocompleteSelect
            className="p-1 w-5"
            fullwidth
            key={countries}
            disabled={props.mod == constants.actionMods.EDIT}
            required
            labelStyle={{ color: "gray" }}
            label={{ title: "Customer", className: "color-gray" }}
            id="customersSelect"
            name="customerId"
            style={{ paddingTop: "5px", width: "350px", maxWidth: "350px", paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }}
            options={countries.find(c => c.text == selectedCountry).customers || []}
            value={AddedEhsCompliance?.customerId || -1}
            onChange={(e) => handleChange(e)}
            placeholder={AddedEhsCompliance?.customerName || "---Select Customer---"}

        />
        <EuiAutocompleteSelect
            className="p-1 w-5"
            fullwidth
            key={reportingPeriods}
            disabled={props.mod == constants.actionMods.EDIT}
            required
            label={{ title: "Reporting Period", className: "color-gray" }}
            id="ReportingPeriodSelect"
            name="reportingPeriodId"
            style={{ paddingTop: "5px", width: "350px", maxWidth: "350px", paddingRight: 0, paddingLeft: 0, paddingBottom: 0 }}
            options={reportingPeriods || []}
            value={AddedEhsCompliance?.reportingPeriodId || -1}
            onChange={(e) => handleChange(e)}
            placeholder={AddedEhsCompliance?.reportingPeriodName || "---Select Reporting Period---"}
            optionListStyle={{ maxHeight: '121px' }}

        />
        <EuiNumberField
            className="p-1 w-5"
            label="Ehs Compliance %"
            labelStyle={{ color: "gray" }}
            required={true}
            min={0}
            max={100}
            step={0.01}
            style={{ width: "100px" }}
            id="EhsCompliancePercentageId"
            name="compliancePct"
            value={AddedEhsCompliance?.compliancePct}
            onChange={(e) => handleChange(e)}
        />
        <p style={{ padding: "5px" }}>*Only percentage with 2 decimal points is allowed</p>
    </FormField>);
}
export default AddEhsCompliance;