import React, { useState } from "react";
import { connect } from "react-redux";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import PageHelper from "../../utils/page-helper";
import ActivityInboxHelper from "../../utils/activity-inbox-helper";
import EuiAccordion from "../../eui-components/accordion";
import SimpleSlider from "../../components/slider";
import EuiRow from "../../eui-components/row";
import { ToastContainer, toast } from 'react-toastify';
import EuiRowWithToolbar from "../../eui-components/rowwithtoolbar";
import AdvancedGridList from "../../components/advancedgridlist";
import constants from "../../utils/constants";
import EuiSwitch from "../../eui-components/switch";
import DialogModal from "../../components/dialog";
import ApiClient from "../../utils/api-client";
import EuiLoading from "../../eui-components/loading";
import { Notification } from '@eds/vanilla';
import UpdateSUbactivityItem from "./updateSubactivityItem";


class ActivityChecklists extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checklistDocumentsColumns: [
                {
                    title: "Name",
                    onCreatedCell: (td, cellData) => {
                        const name = this.renderName(cellData);
                        td.innerHTML = name;
                        td.querySelector("#activityDocumentName_" + cellData.id).addEventListener('click', () => this.selectAndOpenDocument(cellData))
                    },
                    key: "name",
                    width: "10%"
                },
                {
                    title: "Status",
                    onCreatedCell: (td, cellData) => {
                        const status = PageHelper.renderActivityStatus(cellData);
                        td.innerHTML = status;
                    },
                    key: "acceptanceStatusId",
                    width: "10%"
                },
                {
                    title: "Site ASP Comments",
                    key: "siteAspComments",
                    width: "20%"
                },
                {
                    title: "RSC Engineer Comments",
                    key: "reviewComments",
                    width: "20%"
                },
                {
                    title: "RSC Engineer Name",
                    key: "rscPersonName",
                    width: "10%"
                },
            ],
            columns: [{
                title: "",
                key: "id",
                width: "2%",
                onCreatedCell: (td, cellData) => {
                    td.innerHTML = `<span id="delete_" class="tooltip"><i id="delete_" style={font-weight:500px;} class="icon icon-trashcan color-black  text-lg clickable "></i><span class="message top-end">Delete Template</span></span>`;
                    // td.querySelector("#delete_").addEventListener('click', () => { this.openDeleteDialog(cellData) })

                },
            }],
            allDocument: [],
            selectedDocument: { id: 0 },
            imgIndex: 0,
            imgUrlLength: 0,
            totalCount: "",

            openDialogPreview: false,
            viewMod: constants.documentsView.GRID,
            approveExcludeSwitchRef: false,
            showCommentAvailble : false ,
            rscNotReviewed : false,
           // completedExcludeSwitchRef: this.props.activityData.activity?.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED,
           completedExcludeSwitchRef:false,
            excludeViAccepted: false,
            excludeViRejected: false,
            filter: false,
            openDeleteDialog: false,
            openEditDialog: false,
            data: [],
            selectedItem: {},
            isLoading: false,
            selectedChecklists: [],
            enableSelection: false
        }
    }

    componentDidMount() {
        if (!this.props.activityTemplateData.projects.length || !this.props.activityTemplateData.customers.length) {
            this.setState({
                isLoading: true
            }, () => {
                ApiClient.post("ActivityTemplate/GetData", {})
                    .then(response => {
                        if (response.data) {
                            this.props.setData({ projects: response.data.projects, customers: response.data.customers, categories: response.data.categories, workManuals: response.data.workManuals, itemsGroups: response.data.itemsGroups, viCodes: response.data.viCodes });
                            this.setState({
                                isLoading: false
                            })

                        }
                    }).
                    catch(error => {
                        alert(error);
                        this.setState({
                            isLoading: false
                        });
                    });
            });

        }

        const activity = { ...this.props.activityData.activity };
        if (this.props.activityData.activity &&
            this.props.activityData.activity.subactivities &&
            this.props.activityData.activity.subactivities.map(item => item.documents && item.documents.length > 0).length > 0) {
            const allDocument = this.props.activityData.activity
                .subactivities?.map(item => item.documents)
                .reduce((a, b) => { return a?.concat(b); });
            this.setState({ allDocument, imgUrlLength: allDocument?.length });
            const checklists = [...this.props.activityData.activity.subactivities];
            const _checklists = checklists.map(e => {
                const el = {
                    ...e,
                    checked: false
                };
                return el;
            });
            activity['subactivities'] = _checklists;
            this.props.setActivity(activity);
            this.setState({
                data: checklists.map(item => item.id)
            })
        }
    }

    renderName = (rowData) => {
        const view = PageHelper.getEDSDocumentType(rowData.type);
        let color = PageHelper.getEDSDocumentTypeColor(rowData.type);
        return `<a id="activityDocumentName_${rowData.id}" class="flex-start-content ${color}">${view}<span>${rowData.name}</span></a>`;
    }

    onCheckboxChange = (e, id) => {
        const selectedItems = [...this.state.selectedChecklists];
        const activity = { ...this.props.activityData.activity };
        const checklists = [...activity.subactivities];
        const item = checklists.find(i => i.id == id);
        item.checked = e.target.value;
        activity['subactivities'] = checklists;
        this.props.setActivity(activity);
        if (e.target.value) {
            selectedItems.push(item);
            this.setState({ selectedChecklists: selectedItems });
        }
        else {
            const items = selectedItems.filter(element => element.id !== item.id);
            this.setState({ selectedChecklists: items });
        }
    }

    renderChecklists = () => {
        if (!this.props.activityData.activity ||
            !this.props.activityData.activity.subactivities ||
            this.props.activityData.activity.subactivities.length === 0
        ) {
            return <EuiRow><div className="flex-middle-content m-3 w-10 text-lg">No checklists have found.</div></EuiRow>
        }
        const viewMod = this.state.viewMod;
        const checklists = [...this.props.activityData.activity.subactivities.sort((a, b) => (
            ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) === (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity)) ?
                (((a.itemSortId ? parseInt(a.itemSortId) : Infinity) === (b.itemSortId ? parseInt(b.itemSortId) : Infinity)) ?
                    (a.name - b.name)
                    :
                    ((a.itemSortId ? parseInt(a.itemSortId) : Infinity) - (b.itemSortId ? parseInt(b.itemSortId) : Infinity)))
                :

                ((a.itemCatSortId ? parseInt(a.itemCatSortId) : Infinity) - (b.itemCatSortId ? parseInt(b.itemCatSortId) : Infinity))
        ))];
        const activeViCodesId = this.props.activityTemplateData?.viCodes?.map(item => item.value);
        const checklistAccordions = checklists.map(item => {
            const checklistItem = {
                id: "activityChecklist_" + item.id,
                hidden: this.isHiddenChecklist(item),
                title: this.getSubActivityName(item),
                subTitle : "saif",
                titleStyle: { fontWeight: 700, padding: 3, backgroundColor: item.viCodeId && activeViCodesId?.includes(item.viCodeId) ? "#e6f1eb" : "" },
                contentStyle: { paddingLeft: 10 },
                checked: item.checked,
                onCheckboxChangeChange: (e) => {
                    this.onCheckboxChange(e, item.id)
                },
                selectable: (this.props.activityData.activity.actualStart ? false : true),
                content: <>
                    <div className={viewMod !== constants.documentsView.LIST ? "hidden" : ""}>
                        <ObseleteEuiTable
                            classes="tiny"
                            columns={this.state.checklistDocumentsColumns}
                            data={this.getDocuments(item)}
                            id={"activityChecklistTable_" + item.id}
                            scroll={true}
                            sortable={true}
                        >
                        </ObseleteEuiTable>
                    </div >
                    {
                        viewMod !== constants.documentsView.GRID ? null :
                            <AdvancedGridList
                                tileData={this.getDocuments(item)}
                                viewOnly={true}
                                onClickImage={(tile) => { this.selectAndOpenDocument(tile) }}
                                type={3}
                            />
                    }
                </>
            };
            return checklistItem;
        });
        return <EuiAccordion
            id="activityChecklistAccordion"
            style={{ width: "100%" }
            }
            items={checklistAccordions}
            selectable={this.state.enableSelection}
            onCheckboxChange={this.onCheckboxChange}

        />;
    }

    editItem = () => {
        const selectedItem = { ...this.props.activityData.selectedChecklistItem };
        const activity = { ...this.props.activityData.activity };
        if (!selectedItem.name || !selectedItem.subactivityGroupId || !selectedItem.itemCatSortId || !selectedItem.itemSortId || !selectedItem.severity || !selectedItem) {
            const notification = new Notification({
                title: 'Edit Activity Item',
                description: 'Please fill all required data to save',
                timeout: 5000,
                icon: 'icon-triangle'
            });
            notification.init();
        }
        else {
            const docs = selectedItem.documents;
            const activityDocuments = selectedItem.activityDocuments;
            selectedItem['documents'] = "";
            selectedItem['activityDocuments'] = [];
            this.setState({
                isLoading: true,
                openEditDialog: false
            }, () => {
                ApiClient.post("Workplan/EditAssignedItem", selectedItem)
                    .then(response => {
                        if (response.data) {
                            var items = response.data;
                            items.forEach(e => {
                                var activityDocuments = activity['subactivities']?.find(s => s.id == e.id)?.activityDocuments;
                                var docs = activity['subactivities']?.find(s => s.id == e.id)?.documents;
                                e.activityDocuments = activityDocuments;
                                e.documents = docs;
                            });
                            activity['subactivities'] = items;

                            this.props.setActivity(activity);
                            const notification = new Notification({
                                title: 'Edit Activity Item',
                                description: 'Item successfully edited',
                                timeout: 5000,
                                icon: 'icon-check'
                            });
                            notification.init();
                            this.setState({
                                isLoading: false,
                                selectedChecklists: []
                            });

                        }
                    })
                    .catch(error => {
                        alert(error);
                        this.setState({
                            isLoading: false
                        })
                    })
            })
        }
    }

    inititeEditItem = () => {
        const selectedItem = { ...this.state.selectedChecklists[0] };
        if (this.state.selectedChecklists.length > 1) {
            const notification = new Notification({
                title: 'Edit Activity Items',
                description: 'Only one item at a time can be edited!',
                timeout: 5000,
                icon: 'icon-cross'
            });
            notification.init();
        }
        else {
            this.props.setSelctedChecklistItem(selectedItem);
            this.setState({
                openEditDialog: true
            });


        }
    }

    inititeDeleteItem = () => {
        this.setState({
            openDeleteDialog: true
        })
    }

    deleteItem = () => {
        const selectedItems = [...this.state.selectedChecklists];
        selectedItems.forEach(item => {
            item['documents'] = "";
            item["activityDocuments"] = [];
        });
        const selectedIds = selectedItems.map(el => el.id);
        this.setState({
            isLoading: true,
            openDeleteDialog: false,
            selectedChecklists: []
        }, () => {
            ApiClient.post("Workplan/DeleteAssignedItems", selectedItems)
                .then(response => {
                    if (response.data) {
                        const activity = { ...this.props.activityData.activity };
                        const subActivities = [...activity.subactivities?.filter(item => !selectedIds.includes(item.id))]
                        activity['subactivities'] = subActivities;
                        this.setState({
                            isLoading: false,
                            selectedItem: {}
                        });
                        const notification = new Notification({
                            title: 'Delete Activity Items',
                            description: 'Items successfully deleted',
                            timeout: 5000,
                            icon: 'icon-check'
                        });
                        notification.init();
                        this.props.setActivity(activity);

                    }
                }).catch(error => {
                    alert(error);
                    this.setState({
                        isLoading: false
                    })
                })
        })

    }

    getSubActivityName = (rowData) => {
        const color = ActivityInboxHelper.getSubActivityColor(rowData);
        const icon = color === "" ? "icon-circle" : "icon-alarm-level5 color-" + color;
        return <div style={{ flexDirection : 'column', display : 'flex' , alignItems : 'flex-start' , overflow : 'hidden'}}><i className={"flex-middle-content text-sm icon " + icon}>
            
        <span className="subactivity-title">
            {rowData.name + " ( " + (this.getDocuments(rowData)).length + " ) "}
        </span>
        {rowData.completed ? <i className="icon icon-check text-lg color-green"></i> : <i className="icon icon-minus text-lg color-red"></i>}

    </i>
     {  rowData.comments && <i> <span style={{color : "green"}}> Comment : </span>  {rowData.comments}</i> }
    </div>
    }

    renderFilePreview = () => {
        const allDocument = [...this.state.allDocument];
        const comments = this.state.selectedDocument.reviewComments ?? "";

        return <SimpleSlider
            id="activitySlider"
            slideData={allDocument}
            currentSlide={this.state.selectedDocument}
            initialSlide={this.state.imgIndex}
            totalCount={this.state.totalCount}
            onNext={this.nextClick}
            onPrev={this.prevClick}
            onClose={this.toogleModal}
            status={PageHelper.getActivityStatus(this.state.selectedDocument.acceptanceStatusId)}
            comment={comments}
            setImageTrack={(slide) => this.setImageTrack(slide)}
            commentTitle="RSC Engineer Comments"
            siteComment={this.state.selectedDocument.siteAspComments}
            type={3}
        />
    }

    selectAndOpenDocument = (document) => {
        const rowData = this.state.allDocument.find(item => item.id === document.id);
        if (!rowData) {
            toast.error("Coudn't open the file!", { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            return;
        }
        this.setImageTrack(rowData);
    }

    setImageTrack = (slide) => {
        const allDocument = [...this.state.allDocument];
        const imgUrlLength = allDocument.length;
        let imgIndex = allDocument.findIndex(item => item.id === slide.id);
        this.setState({ selectedDocument: slide, imgIndex, imgUrlLength, totalCount: (imgIndex + 1) + "/" + imgUrlLength }, () => { this.setState({ openDialogPreview: true }); });
    }

    nextClick = () => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === prevState.imgUrlLength - 1 ? 0 : prevState.imgIndex + 1,
            };
        }, () => {
            const selectedDocument = this.state.allDocument[this.state.imgIndex];
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument });
        });
    }

    prevClick = () => {
        this.setState(prevState => {
            return {
                imgIndex: prevState.imgIndex === 0 ? prevState.imgUrlLength - 1 : prevState.imgIndex - 1,
            };
        }, () => {
            const selectedDocument = this.state.allDocument[this.state.imgIndex];
            this.setState({ totalCount: (this.state.imgIndex + 1) + "/" + this.state.imgUrlLength, selectedDocument })
        });
    }

    toogleModal = () => {
        this.setState({ openDialogPreview: false });
    }

    isHiddenChecklist = (checklist) => {
        
        const showAll = !this.state.filter
        if (showAll) {
            return !showAll
        }
        else { 
            const commentFilter = !(this.state.showCommentAvailble && checklist.comments)
            const completedFilter = !(this.state.completedExcludeSwitchRef && checklist.completed )
            const acceptedFilter =  !(( this.state.approveExcludeSwitchRef ) &&
            (checklist?.documents || []).length > 0 && (checklist?.documents?.filter(doc => 
                    (
                        
                        ( doc.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED))) 
                        || []).length > 0)
           const ViAcceptedFilter  = !(( this.state.excludeViAccepted ) && (checklist?.documents || []).length>0
           && checklist?.documents?.filter(doc => 
                  (
                      ( doc.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED)) && doc.rscPerson?.name === "Eritop VI Agent")
                      .length > 0)
                      const ViAcceptedRejected  = !(( this.state.excludeViRejected  ) && (checklist.documents || []).length > 0
                      && (checklist?.documents?.filter(doc => 
                             (
                                 ( doc.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED)) && (doc.rscPerson?.name == "Eritop VI Agent" ||  doc?.rscPersonId == 8086))  || []).length>0 )
           // const result =     ((
                // (checklist.documents || []).length === 0
                // || checklist?.documents?.filter(doc => 
                //         !(
                //             (this.state.approveExcludeSwitchRef && doc.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED) ||
                //             (
                //                 (
                //                     (this.state.excludeViAccepted && doc.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED) ||
                //                     (this.state.excludeViRejected && doc.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED)
                //                 )
                //                 && (doc.rscPerson?.name == "Eritop VI Agent" ||  doc.rscPersonId == 8086)
                //             ) 
                //         )
                //     ).length === 0)
    
                // || (this.state.completedExcludeSwitchRef && !checklist.completed))  || (this.state.showCommentAvailble && !checklist.comments) && !this.state.filter 
                // return result
           //     debugger;
    //   if(this.state.approveExcludeSwitchRef) {
            
    //     return acceptedFilter;
    //     }
    //     if(commentFilter) {
    //         return commentFilter
    //     }
    //     if(this.state.completedExcludeSwitchRef) {
    //      return completedFilter
    //     }
    //     if(this.state.approveExcludeSwitchRef) {
    //         return acceptedFilter
    //     }
    //     if(this.state.excludeViAccepted){
    //         return ViAcceptedFilter
    //     }
    //     if(this.state.excludeViRejected) {

    //        return ViAcceptedRejected
    //     }
    //     return []
         return (commentFilter && completedFilter && acceptedFilter && (ViAcceptedFilter) && ViAcceptedRejected)  && this.state.filter;
       // return  result;
       //this.applyFilter(checklist)
        }
        
            
    }

    getDocuments = (checklistItem) => {
    const showAll = !this.state.filter
    if(showAll)
    return checklistItem.documents || []
    else {
        // return checklistItem.documents.filter(item => 
        //     this.state.filter || !((this.state.approveExcludeSwitchRef && item.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED)
        //     || (((this.state.excludeViAccepted && item.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED) ||
        //             (this.state.excludeViRejected && item.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED))
        //         && (item.rscPerson?.name == "Eritop VI Agent" || item.rscPersonId == 8086)))
        //     ) || []

        if(this.state.approveExcludeSwitchRef) {
            const acceptedFilter =  this.state.approveExcludeSwitchRef ?  checklistItem?.documents?.filter(doc => 
                (
                    ( doc.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED))) || [] : []
        return acceptedFilter;
        }
        if(this.state.completedExcludeSwitchRef) {
            const completedFilter = this.state.completedExcludeSwitchRef ? checklistItem?.documents || [] : []
            return completedFilter
        }
        if(this.state.excludeViAccepted){
            const ViAcceptedFilter  = this.state.excludeViAccepted ? 
            checklistItem?.documents?.filter(doc => 
                   (
                       ( doc.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED)) && (doc.rscPerson?.name == "Eritop VI Agent" ||  doc?.rscPersonId == 8086)
                   ) || [] : []
        return ViAcceptedFilter;
        }
        if(this.state.excludeViRejected) {

            const ViRejected  = this.state.excludeViRejected ? checklistItem?.documents?.filter(doc => 
                (
                    ( doc.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED)) && (doc.rscPerson?.name == "Eritop VI Agent" || doc?.rscPersonId == 8086)
                   ) || [] : []
            return ViRejected;
        }
        return []
        

                   
                  

                          
                    


                              
//debugger;
      // return (acceptedFilter || []).concat(completedFilter || []).concat(ViAcceptedFilter || []).concat(ViRejected || [])
        
    }
    
       /* if ((checklistItem.documents || []).length === 0) {
            return [];
        }
        return checklistItem.documents.filter(item => 
            this.state.filter || !((this.state.approveExcludeSwitchRef && item.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED)
            || (((this.state.excludeViAccepted && item.acceptanceStatusId === constants.acceptanceStatusTypes.ACCEPTED) ||
                    (this.state.excludeViRejected && item.acceptanceStatusId === constants.acceptanceStatusTypes.REJECTED))
                && item.rscPerson?.name === "Eritop VI Agent"))
            );
            */
    }

    handleSelectionSwitch = (e) => {
        if (!e.target.checked) {
            const activity = { ...this.props.activityData.activity };
            const checklists = [...activity.subactivities];
            const _checklists = checklists.map(e => {
                const el = {
                    ...e,
                    checked: false
                };
                return el;
            });
            activity['subactivities'] = _checklists;
            this.props.setActivity(activity);
            this.setState({ enableSelection: e.target.checked, selectedChecklists: [] })

        }
        else {
            this.setState({
                enableSelection: e.target.checked
            })
        }
    }

    render() {
        const viewMod = this.state.viewMod;
        return (<div key={this.state.isLoading && this.state.enableSelection}><EuiLoading isLoading={this.state.isLoading} />
            <EuiRowWithToolbar widthright="w-10" widthleft="w-4" className={"responsive-toolbar-row mb-2 " + (this.state.isLoading ? "disabled" : "")} leftIcons={<>
                {(this.state.selectedChecklists.length > 0 && this.state.enableSelection ?
                    <>  <span id="deleteItem" className="tooltip clickable"> <i id="deleteItem" onClick={this.inititeDeleteItem} className="icon icon-trashcan icon-lg m-1 fw-3 clickable"></i><span className="message top-end">Delete Selected Items</span></span>
                        <span id="editItem" className="tooltip clickable"> <i id="editItem" onClick={this.inititeEditItem} className="icon icon-edit icon-lg m-1 fw-3 clickable"></i><span className="message top-end">Edit Selected Item</span></span>
                    </>
                    : null)}
                <i className={"icon icon-view-list icon-lg m-1 " + (viewMod === constants.documentsView.LIST ? "fw-3" : "")} onClick={() => this.setState({ viewMod: constants.documentsView.LIST })}></i>
                <i className={"icon icon-view-tiles icon-lg m-1 " + (viewMod === constants.documentsView.GRID ? "fw-3" : "")} onClick={() => this.setState({ viewMod: constants.documentsView.GRID })}></i>

            </>
            }
                rightIcons={
                <>
                    <EuiSwitch id="filter"
                        checked={!this.state.filter}
                        onChange={(e) => this.setState({ filter: !(e.target.checked) })}
                        dataEnabled="Showing All"
                        dataDisabled="Filtered"
                        className="p-1"
                    />
                    <EuiSwitch checked={this.state.showCommentAvailble}
                                        name="commentAvailable"
                                        onChange={(e) => this.setState({ showCommentAvailble: e.target.checked })}
                                        dataEnabled="Comment Available"
                                        dataDisabled="Comment Available"
                                        className="p-1"
                                    />
                    <EuiSwitch id="activityViewApproveExcludeSwitchRef"
                        checked={this.state.approveExcludeSwitchRef}
                        onChange={(e) => this.setState({ approveExcludeSwitchRef: e.target.checked })}
                        // dataEnabled="Including Accepted"
                        // dataDisabled="Including Accepted"
                        dataEnabled="Rejected"
                         dataDisabled="Rejected"
                        className="p-1"
                    />
                    <EuiSwitch id="activityViewCompletedSwith"
                        checked={this.state.completedExcludeSwitchRef}
                        onChange={(e) => this.setState({ completedExcludeSwitchRef: e.target.checked })}
                        dataEnabled="Showing Completed"
                        dataDisabled="Showing Completed"
                        className="p-1"
                    />
                    {(!this.props.activityData.activity.actualEnd ?
                        <EuiSwitch id="activityEnableSelection"
                            checked={this.state.enableSelection}
                            onChange={(e) => this.handleSelectionSwitch(e)}
                            dataEnabled="Edit is enabled"
                            dataDisabled="Enable edit"
                            className="p-1"
                        />
                    : null)}
                        
                    <EuiSwitch id="excludeViAccepted"
                        checked={this.state.excludeViAccepted}
                        onChange={(e) => this.setState({ excludeViAccepted: e.target.checked })}
                        dataEnabled="VI Accepted"
                        dataDisabled="VI Accepted"
                        className="p-1"
                    />
                    <EuiSwitch id="excludeViRejected"
                        checked={this.state.excludeViRejected}
                        onChange={(e) => this.setState({ excludeViRejected: e.target.checked })}
                        dataEnabled="VI Rejected"
                        dataDisabled="VI Rejected"
                        className="p-1"
                    />
                   {/* <EuiSwitch id="rscNotReviewed"
                        checked={this.state.rscNotReviewed}
                        onChange={(e) => this.setState({ rscNotReviewed: e.target.checked })}
                        dataEnabled="RSC Not Reviewed"
                        dataDisabled="RSC Not Reviewed"
                        className="p-1"
                    />
                    */}
                </>}
            >
                {this.renderChecklists()}
                {
                    (this.state.openDialogPreview ?
                        this.renderFilePreview()
                        :
                        null)
                }
                <DialogModal
                    id="deleteItem"
                    open={this.state.openDeleteDialog}
                    title="Delete Selected Items"
                    content={
                        <div>Are you sure you want to delete selected items?</div>}
                    buttonName="Delete"
                    type="primary"
                    handleClose={() => { this.setState({ openDeleteDialog: false }) }}
                    onClick={this.deleteItem}
                    disableBackdropClick={this.props.disableBackdropClick}
                    mainButtonStyle={{ backgroundColor: 'red' }}
                />
                <DialogModal
                    id="editItem"
                    open={this.state.openEditDialog}
                    title="Edit Item"
                    disableBackdropClick={this.props.disableBackdropClick}
                    content={<UpdateSUbactivityItem id="updateSubItem" />}
                    contentStyle={{ width: "600px" }}
                    buttonName="Save"
                    type="primary"
                    handleClose={() => { this.setState({ openEditDialog: false }) }}
                    onClick={this.editItem}
                />

            </EuiRowWithToolbar >
        </div >)
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, activityData: state.activity, activityTemplateData: state.activityChecklists });
const mapDispatchToProps = dispatch => {
    return {
        setActivity: (activity) => dispatch({ type: "SET_ACTIVITY", payload: activity }),
        setSelctedChecklistItem: (item) => dispatch({ type: "SET_SELECTED_CHECKLIST_ITEM", payload: item }),
        setData: (data) => dispatch({ type: "SET_DATA", payload: data }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityChecklists);