import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EuiRow from "../../../eui-components/row";
import Table from "../../../components/table";
import PageHelper from "../../../utils/page-helper";
import DateUtils from "../../../utils/date-utils";
import DialogModal from "../../../components/dialog";
import ApiClient from "../../../utils/api-client";
import EHSInboxHelper from "../../../utils/ehs-inbox-helper";
import { ToastContainer, toast } from 'react-toastify';
import constants from "../../../utils/constants";
import green from '@material-ui/core/colors/green';
import { Chip } from "@material-ui/core";
import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import ActivityInboxHelper from "../../../utils/activity-inbox-helper";
import EuiSwitch from "../../../eui-components/switch";
import CommonHelper from "../../../utils/common-helper";
import VehicleHelper from "../../../utils/vehicle-helper";

class EHSPingList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            ehsColumns: [
                {
                    field: 'assigned', title: '',
                    render: (rowData) => (rowData.assigned ?? 0) ? <i className="icon icon-avatar" style={{ fontSize: 21, marginLeft: 5 }}></i> : null,
                    filtering: false,
                    width: "1%",
                    type: "boolean"
                },
                {
                    field: 'id', title: 'EHS ID',
                    filterCellStyle: { maxWidth: 100, display: "flex" },
                    width: "5%",
                },
                { field: 'customerName', title: 'Customer', width: "10%" },
                { field: 'workplanName', title: 'Workplan', width: "20%", },
                { field: 'siteCode', title: 'Site Code/Name', width: "20%", render: (rowData) => rowData.siteCode + " / " + rowData.siteName },
                { field: 'aspTeamLead', title: 'ASP Team Lead', width: "10%", },
                { field: 'aspCompany', title: 'ASP Company', width: "10%", },
                {
                    field: 'sentOhsToRsc',
                    title: 'Sent to RSC Date',
                    defaultSort: 'desc',
                    filterCellStyle: { maxWidth: 150, display: "flex" },
                    render: (rowData) => DateUtils.toDateString(rowData.sentOhsToRsc, "YYYY-MM-DD HH:mm"),
                    width: "10%",
                }
            ],
            openFilter: true,
            openDialog: false,
            openDialogUnassign: false,
            openDialogPreview: false,
            selectedDocument: { id: 0 },
            tableRef: React.createRef(),
            assignedFilter: 1,
            allPings: false,
        }
    }

    componentDidMount() {
        this.getEhsPings(14);
        PageHelper.getCoordinates();
    }

    getEhsPings = (amount = 0) => {
        const userData = this.props.userData;
        this.setState({ isLoading: true }, () => {
            ApiClient.post("EhsInbox/GetEhsPingsOfUser",
                { id: parseInt(userData.userData.userId), amount, rscCenterId: userData.rscPerson?.rscCenterId, userRole: userData.rscPerson?.personType })
                .then(response => {

                    const data = EHSInboxHelper.mapEhsPings(response.data);
                    this.props.setEhsPings(data);
                    this.setState({ isLoading: false });

                }).catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                })
        });
    }

    assignToMe = () => {
        const userData = this.props.userData;
        this.setState({ openDialog: false, isLoading: true }, () => {
            const logInfo = {
                userId: parseInt(this.props.userData.userData.userId),
                latitude: PageHelper.coord.latitude,
                longitude: PageHelper.coord.longitude,
            }
            ApiClient.post("EhsInbox/AssignEhsPing", {
                id: this.props.ehsInboxData.currentEhsPing.id,
                updateUser: this.props.userData.userData.userId, logInfo,
                rscCenterId: userData.rscPerson?.rscCenterId,
                userRole: userData.rscPerson?.personType
            })
                .then(response => {
                    const data = [...this.props.ehsInboxData.ehsPings];
                    const i = data.indexOf(this.props.ehsInboxData.currentEhsPing);
                    if (!response.data) {
                        data.splice(i, 1);
                        this.props.setEhsPings(data);
                        return;
                    }
                    data[i].assigned = true;
                    data[i].assignedOn = new Date();
                    data[i].rscPersonId = this.props.userData.rscPerson.id;

                    data[i].plainEhsPing.assigned = true;
                    data[i].plainEhsPing.assignedOn = new Date();
                    data[i].plainEhsPing.rscPersonId = this.props.userData.rscPerson.id;

                    this.props.setEhsPings(data);
                    this.setState({ isLoading: false });
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });
    }

    unassignEhsPing = () => {

        this.setState({ openDialogUnassign: false, isLoading: true }, () => {
            const logInfo = {
                userId: parseInt(this.props.userData.userData.userId),
                latitude: PageHelper.coord.latitude,
                longitude: PageHelper.coord.longitude,
            }
            ApiClient.post("EhsInbox/UnassignEhsPing", { id: this.props.ehsInboxData.currentEhsPing.id, updateUser: this.props.userData.userData.userId, logInfo })
                .then(response => {
                    const data = [...this.props.ehsInboxData.ehsPings];
                    const i = data.indexOf(this.props.ehsInboxData.currentEhsPing);
                    if (!response.data) {
                        toast.error(constants.errorMessage, { containerId: 'EL', position: toast.POSITION.TOP_CENTER });
                        return;
                    }
                    data[i].assigned = false;
                    data[i].assignedOn = null;
                    data[i].rscPersonId = null;

                    data[i].plainEhsPing.assigned = false;
                    data[i].plainEhsPing.assignedOn = null;
                    data[i].plainEhsPing.rscPersonId = null;

                    this.props.setEhsPings(data);
                    this.setState({ isLoading: false });
                }).catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false });
                });
        });

        //const data = [...this.props.ehsInboxData.ehsPings];
        //const i = data.indexOf(this.props.ehsInboxData.currentEhsPing);
        //data[i].assigned = 0;
        //data[i].assignedOn = null;
        //data[i].rscPersonId = null;
        //this.props.setEhsPings(data);
        //this.setState({ openDialogUnassign: false });
    }

    renderSessionActions = (rowData) => {
        return <span>
            <button className="btn " style={{ marginRight: 5, background: (rowData.liveSessionStatus === 1 ? green[300] : "") }}>Join</button>
        </span>
    }
    checkAssignedToRSCInspector(rowData) {
        var id = rowData.id;
        var rscPerson = this.props.userData.rscPerson;
        return new Promise(resolve => {
            ApiClient.post("EhsInbox/GetPingAssignedRSCPerson", { id: id }).then(response => {
                if (response.data.responseDetail == rscPerson?.id) {

                    this.addJoinLog(rowData);
                    resolve(true)
                }
                else {
                    toast.error("This EHS Ping is no longer assigned to you.", { containerId: 'EL', position: toast.POSITION.TOP_CENTER });
                    this.getEhsPings(14);
                    resolve(false);
                }
            }).catch(error => {
                toast.error(constants.errorMessage, { containerId: 'EL', position: toast.POSITION.TOP_CENTER });
                this.getEhsPings(14);
                resolve(false);
            }
            );
        });
    }
    addJoinLog(ehs) {
        var user = this.props.userData;
        let visitor = CommonHelper.getVisitorData();
        ehs.logInfo = {
            userId: parseInt(user.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: "RSC Person Name: " + user.userData.username + ", RSC Person ID: " + user.rscPerson?.id
        };
        ApiClient.post("EhsInbox/AddSessionJoinLog", ehs).then(response => {
            CommonHelper.createNotification({ title: "You have joined the session and the time you joined is logged", icon: "icon-check", timeout: 50000 });
        }).catch(err => {
            console.error(err?.response?.data);
        })
    }
    joinSession = async (rowData) => {
        const ehs = { ...rowData };
        this.getAllVehiclesByAspCompanyId(ehs);
        if (this.props.userData.userData.jobRole !== constants.userRoles.RSC_OHS_INSPECTOR || !ehs.sentOhsToRsc) {
            ehs.viewOnly = true;
        }
        var assigned = await this.checkAssignedToRSCInspector(ehs);

        if (assigned) {
            this.getChecklistsOfEhsAndJoin(ehs);
            // if (ehs.acceptanceStatusId !== 2 && ehs.activity.completed === 0) {
            //     this.createNewConversationOrRoom(ehs);
            // }
        }
    }

    getAllVehiclesByAspCompanyId = (ehs) => {
        const request = {
            ActivityId: ehs.activityId,
            AspCompanyId: ehs.aspCompanyId || 0,
            EhsPingId: ehs.id

        }
        ApiClient.post("Vehicle/GetAspCompanyVehiclesByActivity", { ...request })
            .then(response => {
                const vehicles = response.data;
                VehicleHelper.mapVehicles(vehicles);
                this.props.setVehicleList(vehicles)
            });
    }

    getChecklistsOfEhsAndJoin = (ehs) => {
        this.setState({ isLoading: true }, () => {
            ApiClient.post("EhsInbox/GetDetailsOfEhsPing", {
                ehsPingId: ehs.id,
                customerId: ehs.customerId,
            })
                .then(response => {
                    if (!response.data) {
                        this.setState({ isLoading: false });
                        //Add notification
                        return;
                    }
                    const data = EHSInboxHelper.mapChecklists(response.data.checklists, ehs.aspResource, ehs.addInfo);
                    const teamVerificationLogs = ActivityInboxHelper.mapTeamLogs(response.data.teamVerificationLogs);
                    const resourceVerificationLogs = ActivityInboxHelper.mapResourceLogs(response.data.resourceVerificationLogs);
                    const teamInfo = response.data.teamInfo || {};
                    teamInfo.status = teamVerificationLogs.length > 0 ? !teamVerificationLogs.some(item => !item.validStatus) : null;
                    teamInfo.verificationSessionId = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].verificationSessionId : "";
                    teamInfo.isOffsiteVerification = resourceVerificationLogs.length > 0 ? resourceVerificationLogs[0].isOffsiteVerification : null;
                    teamInfo.resourceStatus = resourceVerificationLogs.length > 0 ? !resourceVerificationLogs.some(item => !item.status) : null;
                    const recordings = PageHelper.mapRecordings(response.data.recordings);
                    this.setState({ isLoading: false },
                        () => {
                            ehs["checklists"] = data;
                            ehs["Activity_highRiskItemConfig"] = response.data.activity_highRiskItemConfig;
                            this.props.setCurrentEhsPing(ehs);
                            const liveSessionInfo = { ...response.data.liveSessionInfo };
                            liveSessionInfo.sessionName = ehs.name;
                            liveSessionInfo.siteName = ehs.siteName;
                            liveSessionInfo.ehsPingId = ehs.id;
                            liveSessionInfo.activityId = ehs.activityId;
                            liveSessionInfo.acceptanceStatusId = ehs.acceptanceStatusId;
                            liveSessionInfo.completedActivity = ehs.activity.completed
                            liveSessionInfo.rscScope = ehs.activity?.rscScope                 
                            this.props.setLiveSessionInfo(liveSessionInfo);

                            const teamsLogInfo = { teamInfo, teamVerificationLogs, resourceVerificationLogs };
                            this.props.setTeamsLogInfo(teamsLogInfo);
                            this.props.setRecordings(recordings);
                            this.props.toggleLiveStreamMod(true);

                            setTimeout(() => this.props.history.push("/live-streaming"), 1000);
                        });
                }).catch(error => {
                    this.setState({ isLoading: false });
                    console.error(error);;
                })
        });
    }

    getFilteredEhsPings = (type) => {
        if (!this.props.ehsInboxData.ehsPings) {
            return [];
        }

        if (type === 1) {
            return this.props.ehsInboxData.ehsPings.filter(item => item.assigned);
        } else if (type === 2) {
            return this.props.ehsInboxData.ehsPings.filter(item => !item.assigned);
        } else if (type === 3) {
            return this.props.ehsInboxData.ehsPings;
        }

        return [];
    }

    render() {
        return (<EuiRow>

            <Table
                actions={[
                    rowData => ({
                        icon: () => <span><button className="btn primary">Assign</button></span>,
                        onClick: (event, rowData) => this.setState({ openDialog: true }, () => this.props.setCurrentEhsPing(rowData)),
                        hidden: this.props.userData.userData.jobRole !== constants.userRoles.RSC_OHS_INSPECTOR || (rowData.assigned || !rowData.sentOhsToRsc)
                    }),
                    rowData => ({
                        icon: () => this.renderSessionActions(rowData),
                        onClick: (event, rowData) => { this.joinSession(rowData) },
                        hidden: this.props.userData.userData.jobRole === constants.userRoles.RSC_OHS_INSPECTOR && (!rowData.assigned && rowData.sentOhsToRsc)
                    }),
                    rowData => ({
                        icon: () => <i className="icon icon-cross color-red text-lg m-1" ></i>,
                        onClick: (event, rowData) => this.setState({ openDialogUnassign: true }, () => this.props.setCurrentEhsPing(rowData)),
                        tooltip: "Unassign Ehs Ping",
                        hidden: true
                        //this.props.userData.userData.jobRole !== constants.userRoles.RSC_OHS_INSPECTOR || (!rowData.assigned && rowData.sentOhsToRsc)
                    }),
                    {
                        icon: () => <i className="icon icon-reload m-1" style={{ fontSize: 19 }}></i>,
                        tooltip: "Refresh",
                        onClick: (event, rowData) => this.getEhsPings(this.state.allPings ? 0 : 14),
                        isFreeAction: true
                    },
                    {
                        icon: () => <i className="icon icon-filter m-1" style={{ fontSize: 19, opacity: (this.state.openFilter ? "0.5" : "1") }}></i>,
                        tooltip: "Filter Activities",
                        onClick: (event, rowData) => { this.setState({ openFilter: !this.state.openFilter }) },
                        isFreeAction: true
                    },
                ]}
                columns={this.state.ehsColumns}
                data={this.getFilteredEhsPings(this.state.assignedFilter)}
                isLoading={this.state.isLoading}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10],
                    cellStyle: { fontSize: "0.85rem", padding: 0, paddingLeft: 10 },
                    filtering: this.state.openFilter,
                    search: false,
                    paginationType: "stepped",
                    actionsColumnIndex: -1,
                    draggable: true
                }}
                title={<div className="flex-start-content" style={{ marginLeft: 34 }}>
                    <Chip className={"summary-chip color-text "}
                        style={{ backgroundColor: blue[400], fontSize: 14, marginLeft: 0, width: "auto", boxShadow: (this.state.assignedFilter === 1 ? " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "") }}
                        label={"Assigned:" + this.getFilteredEhsPings(1).length}
                        clickable={true}
                        icon={this.state.assignedFilter === 1 ? <i className="icon icon-filter" /> : null}
                        onClick={() => this.setState({ assignedFilter: 1 })}></Chip>
                    <Chip className={"summary-chip color-text "}
                        style={{ backgroundColor: red[200], fontSize: 14, width: "auto", margin: 5, boxShadow: (this.state.assignedFilter === 2 ? " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "") }}
                        label={"Unassigned:" + this.getFilteredEhsPings(2).length}
                        clickable={true}
                        icon={this.state.assignedFilter === 2 ? <i className="icon icon-filter" /> : null}
                        onClick={() => this.setState({ assignedFilter: 2 })}></Chip>
                    <Chip className={"summary-chip color-text "}
                        style={{ backgroundColor: "lightgray", fontSize: 14, width: "auto", boxShadow: (this.state.assignedFilter === 3 ? " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)" : "") }}
                        label={"All RSC Inbox:" + this.getFilteredEhsPings(3).length}
                        clickable={true}
                        icon={this.state.assignedFilter === 3 ? <i className="icon icon-filter" /> : null}
                        onClick={() => this.setState({ assignedFilter: 3 })}></Chip>

                    <EuiSwitch
                        id="filterOhsPingSwitch"
                        className="m-1 ml-2"
                        onChange={() => this.setState({ allPings: !this.state.allPings }, () => this.getEhsPings(this.state.allPings ? 0 : 14))}
                        checked={this.state.allPings}
                        dataEnabled="Showing All"
                        dataDisabled="Showing Last Week"
                        name="allPings"
                    />
                </div>}
                tableRef={this.state.tableRef}
            >
            </Table>

            <DialogModal
                buttonName="OK"
                content={<p>{"Are you sure you want to assign me to EHS Ping ID : " + this.props.ehsInboxData.currentEhsPing.id + " ?"}</p>}
                handleClose={() => this.setState({ openDialog: false })}
                id="assignDialog"
                onClick={this.assignToMe}
                open={this.state.openDialog}
                title="Assign to Me"
            >
            </DialogModal>

            <DialogModal
                buttonName="OK"
                content={<p>{"Are you sure you want to unassign me to EHS Ping ID : " + this.props.ehsInboxData.currentEhsPing.id + " ?"}</p>}
                handleClose={() => this.setState({ openDialogUnassign: false })}
                id="unassignDialog"
                onClick={this.unassignEhsPing}
                open={this.state.openDialogUnassign}
                title="Unassign Me"
                type="warning"
            >
            </DialogModal>

            <ToastContainer enableMultiContainer containerId={'EL'} autoClose={2500} />
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ehsInboxData: state.ehsInbox });
const mapDispatchToProps = dispatch => {
    return {
        setEhsPings: (ehsPings) => dispatch({ type: "SET_EHS_PINGS", payload: ehsPings }),
        setEhsPageMod: (mod) => dispatch({ type: "SET_EHS_PAGE_MOD", payload: mod }),
        setCurrentEhsPing: (ehsPing) => dispatch({ type: "SET_CURRENT_EHS_PING", payload: ehsPing }),
        setLiveSessionInfo: (liveSessionInfo) => dispatch({ type: "SET_LIVE_SESSION_INFO", payload: liveSessionInfo }),
        toggleLiveStreamMod: (mod) => dispatch({ type: "TOGGLE_LIVE_STREAM_MOD", payload: mod }),
        setRecordings: (recordings) => dispatch({ type: "SET_RECORDINGS", payload: recordings }),
        setTeamsLogInfo: (teamsLogInfo) => dispatch({ type: "SET_TEAMS_LOG_INFO", payload: teamsLogInfo }),
        setVehicleList: (vehicle) => dispatch({ type: "SET_VEHICLE_LIST", payload: vehicle }),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EHSPingList)); 