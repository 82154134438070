import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import constants from "../../utils/constants";
import ObseleteEuiTable from "../../eui-components/obseleteTable";
import EuiTile from "../../eui-components/tile";
import EuiBreadcrumb from "../../eui-components/breadcrumbs";
import EuiTextField from "../../eui-components/textfield";
import EuiRow from "../../eui-components/row";
import DateUtils from "../../utils/date-utils";
import AdvancedGridList from "../../components/advancedgridlist";
import DocumentDialogModal from "../../components/documentDialogModal";
import MenuMultiPanelTile from "../../components/menuMultiPanel";
import TicketInfo from "./ticketInfo";
import TicketSolution from "./ticketSolution";
import EuiDialog from "../../eui-components/dialog";
import DialogModal from "../../components/dialog";
import ApiClient from "../../utils/api-client";
import TicketFilteredList from "./ticketFilteredList";
import TicketHelper from "../../utils/ticket-helper";
import EuiLoading from "../../eui-components/loading";
import PreviewTicket from "./PreviewTicket";
import TicketChat from "./ticketChat";


class TicketDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            editMod: constants.actionMods.EDIT,
            openDialog: false,
            openTicketPreviewDialog: false,
            selectedDocument: { id: 0 },
            solution: "",
            existingSolutions: [],
            selectedTicket: {}
        }
    }

    componentDidMount() {
        this.findExistingSolutions();
    }

    previewSelectedTicket = (selectedTicket) => {
        this.setState({ selectedTicket: selectedTicket, openTicketPreviewDialog: true })
    }

    getRightButton = () => {
        return <button
            hidden={false}
            className={"btn primary m-1 " + (this.state.isLoading ? "loading  disabled" : "")}
            onClick={() => {
                if (!this.state.solution) {
                    
                    toast.error("Solution cannot be empty", { containerId: 'TD', position: toast.POSITION.TOP_CENTER });
                } else {
                    this.setState({ openDialog: true });
                }
            }}>

            <i className="icon icon-check p-1" />Resolve Ticket
        </button>
    }

    findExistingSolutions = () => {
        this.setState({ isLoading: true });

        const payload = {
            Title: this.props.ticketData.currentTicket.title
        };

        
        

        // api call
        ApiClient.post("Ticketing/FindTickets", payload)
            .then(response => {
                this.setState({ isLoading: false });

                if (response.data) {
                    let data = response.data;

                    TicketHelper.mapTickets(data, this.props.ticketData.classificationList);
                    this.setState({ existingSolutions: data });
                } else {
                    toast.info("An error occured, try again later! XX", { containerId: 'TD', position: toast.POSITION.TOP_CENTER });
                }
            })
            .catch(error => {
                this.setState({ openAssignDialog: false })
                
                toast.error("An error occured, try again later! XX", { containerId: 'TD', position: toast.POSITION.TOP_CENTER });
            })
    }

    isViewOnly = () => {
        if (this.props.ticketData.currentTicket.status == 4
            && this.props.userData.userData.userId == this.props.ticketData.currentTicket.assignedUserId) {
            return false;
        }

        return true;
    }

    isChatDisabled = () => {
        if ((this.props.ticketData.currentTicket.status == 4
            && this.props.userData.userData.userId == this.props.ticketData.currentTicket.assignedUserId)
            || this.props.ticketData.currentTicket.status == 2) {
            return false;
        }

        return true;
    }

    resolveTicket = () => {
        
        
        this.setState({ openDialog: false, isLoading: true });

        const payload = {
            Id: parseInt(this.props.ticketData.currentTicket.id),
            solution: this.state.solution,
            UpdateUserId: parseInt(this.props.userData.userData.userId)
        };


        
        

        // api call
        ApiClient.post("Ticketing/ResolveTicket", payload)
            .then(response => {
                this.setState({ isLoading: false });

                if (response.data.success) {

                    // Trigger the list  renewal
                    this.props.setTickets([]);
                    this.props.setTicketSelectedTab(constants.ticketTabs.DEFAULT);

                    this.props.setTicketPageMod(constants.pageMods.LIST);
                } else {
                    toast.info("An error occured, try again later!", { containerId: 'TD', position: toast.POSITION.TOP_CENTER });
                }
            })
            .catch(error => {
                this.setState({ openAssignDialog: false })
                
                toast.error("An error occured, try again later!", { containerId: 'TD', position: toast.POSITION.TOP_CENTER });
            })

        // API call
    }

    setSolution = (solutionText) => {
        
        
        this.setState({ solution: solutionText });
    }

    getMenuList = () => {
        const menu = [
            {
                id: 1, title: "Ticket Information",
                icon: <i className="icon icon-info m-1 text-lg"></i>,
                content: <TicketInfo />

            },
            {
                id: 2, title: "Solution", icon: <i className="icon icon-check m-1 text-lg"></i>,
                content: <TicketSolution setSolution={this.setSolution} viewOnly={this.isViewOnly()} />,
                rightIcon: this.isViewOnly() ? null : this.getRightButton()
            },
            {
                id: 3, title: "Existing Solutions", icon: <i className="icon icon-lightbulb m-1 text-lg"></i>,
                content: <TicketFilteredList enableFilter initialTickets={this.state.existingSolutions} previewSelectedTicket={this.previewSelectedTicket} id="ExistingSolutions" />
            },
            {
                id: 4, title: "Chat", icon: <i className={"icon icon-message-double m-1 text-lg"}></i>,
                content: <TicketChat isReadOnly={this.props.ticketData.currentTicket.status === 2} />,
                hidden: false,
                disabled: this.isChatDisabled()
            }
        ]
        return menu;
    }

    render() {

        return (
            <EuiTile fullscreen>
            
                <EuiBreadcrumb id="ticketBreadcrumb"
                    parentName={<div className="flex-start-content"><i className="icon icon-home icon-lg pr-1"></i>Home</div>}
                    childName={<div className="flex-start-content"><i className="icon icon-floor icon-lg pr-1"></i>{this.props.ticketData.currentTicket.title}</div>}
                    onClickParent={() => this.props.setTicketPageMod(constants.pageMods.LIST)}
                    className="p-2"
                    style={{ display: "flex", alignItems: "center" }}
                />

                <MenuMultiPanelTile id="ticketDetailsMenuMultiPanel"
                    menuList={this.getMenuList()}
                    key={this.state.editMod + this.props.isLoading} />

                <DialogModal
                    buttonName="Confirm"
                    content={<div>Are you sure you want to resolve ticket? </div>}
                    handleClose={() => this.setState({ openDialog: false })}
                    id="ticketCloseDialog"
                    onClick={() => this.resolveTicket()}
                    open={this.state.openDialog}
                    title="Resolve Ticket"
                >
                </DialogModal>

                <DialogModal
                    buttonName="Ok"
                    content={<PreviewTicket selectedTicket={this.state.selectedTicket} />}
                    handleClose={() => this.setState({ openTicketPreviewDialog: false })}
                    id="previewTicketDialog"
                    onClick={() => { this.setState({ openTicketPreviewDialog: false }) }}
                    open={this.state.openTicketPreviewDialog}
                    title="Preview Ticket"
                >
                </DialogModal>

                <EuiLoading size="large" id="ticketListLoading" isLoading={this.state.isLoading} />
                <ToastContainer enableMultiContainer containerId={'TD'} autoClose={2500} />

            </EuiTile>);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, ticketData: state.ticket });
const mapDispatchToProps = dispatch => {
    return {
        setTickets: (tickets) => dispatch({ type: "SET_TICKETS", payload: tickets }),
        setTicketPageMod: (mod) => dispatch({ type: "SET_TICKET_PAGE_MOD", payload: mod }),
        setCurrentTicket: (ticket) => dispatch({ type: "SET_CURRENT_TICKET", payload: ticket }),
        setTicketSelectedTab: (selectedTab) => dispatch({ type: "SET_TICKET_SELECTED_TAB", payload: selectedTab })
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(TicketDetails));