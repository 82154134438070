const initialState = {
    room_id: null,
    activityId: null,
    rscScope: '',
    ehsPingId: null,
    connection: null
};
export default (state = initialState, action) => {
  switch (action.type) {

    case "INIT_ROOM":
        return {...initialState, connection: state.connection};
    case "SET_ROOM_ID":
        return {
            ...state,
            room_id: action.payload.room_id
        };
    case "SET_ACTIVITY_ID":
        return {
            ...state,
            activityId: action.payload.activityId,
            rscScope: action.payload.rscScope,
            ehsPingId: action.payload.ehsPingId
        };
    case "SET_CONNECTION":
        return {
            ...state,
            connection: action.payload.connection
        };
      default:
          return state;
  }
};
