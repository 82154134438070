const initialState = {
    mod: 1,
    filter: { archived: true },
    filteredResources: [],
    resource: { id: 0 },
    companies: [],
    countries: [],
    customers: [],
    createEditMode: false,
    jobs: [],
    certificates: [],
    mode: "initial",
    selectedASP: {},
    selectedCountry: "",
    idTypes: [],
    skills: [],
    certificate: {},
    disableAddCertForDate: false,
    disableAddCertForName: false,
    addCrtNotify: false,
    createResource: false,
    updateTables: false,
    key: "",
    autoComlpeteKey: "",
    selectedItem: 1,
    users: [],
    statuses: [],
    groupedSkills: [],
    selectedTab: "Pre-assessment Result",
    projects: [],
    assignedProject: {},
    countryASPList: [],
    ehsCompliance: {},
    reportingPeriods: [],
    disabledResource: false,
    rscCountries: []
};
export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_RESOURCE":
            return {
                ...state,
                resource: action.payload
            };
        case "CHANGE_RESOURCE_MOD":
            return {
                ...state,
                mod: action.payload
            };
        case "SET_FILTERED_RESOURCES":
            return {
                ...state,
                filteredResources: action.payload
            };
        case "SET_RESOURCE_FILTER":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_RESOURCE_COMPANIES":
            return {
                ...state,
                companies: action.payload
            }
        case "SET_RESOURCE_COUNTRIES":
            return {
                ...state,
                countries: action.payload
            }
        case "CREATE_EDIT_RESOURCE_FORM":
            return {
                ...state,
                createEditMode: action.payload
            }
        case "SET_JOBS":
            return {
                ...state,
                jobs: action.payload
            }
        case "SET_CERTIFICATES":
            return {
                ...state,
                certificates: action.payload
            }
        case "SET_CERTIFICATE":
            return {
                ...state,
                certificate: action.payload
            }
        case "DISABLE_ADD_CERT_FOR_NAME":
            return {
                ...state,
                disableAddCertForName: action.payload
            }
        case "DISABLE_ADD_CERT_FOR_DATE":
            return {
                ...state,
                disableAddCertForDate: action.payload
            }
        case "SET_ADD_CRT_NOTIFY":
            return {
                ...state,
                addCrtNotify: action.payload
            }
        case "SET_ID_TYPES":
            return {
                ...state,
                idTypes: action.payload
            }
        case "SET_CREATE_RESOURCE":
            return {
                ...state,
                createResource: action.payload
            }
        case "UPDATE_TABLE":
            return {
                ...state,
                key: action.payload
            }
        case "UPDATE_AUTO_COMPLETE_KEY":
            return {
                ...state,
                autoComlpeteKey: action.payload
            }
        case "UPDATE_SELECTED_ITEM":
            return {
                ...state,
                selectedItem: action.payload
            }
        case "SET_SKILLS":
            return {
                ...state,
                skills: action.payload
            }
        case "SET_USERS":
            return {
                ...state,
                users: action.payload
            }
        case "SET_ASP_VIEW": {
            return {
                ...state,
                mode: action.payload,
                selectedASP: action.payload.selectedASP,
                selectedCountry: action.payload.selectedCountry
            };
        }
        case "SET_RESOURCE_STATUSES":
            return {
                ...state,
                statuses: action.payload
            }
        case "SET_GROUPED_SKILLS":
            return {
                ...state,
                groupedSkills: action.payload
            }
        case "SET_RESOURCE_CUSTOMERS":
            return {
                ...state,
                customers: action.payload
            }
        case "SET_SELECTED_TAB":
            return {
                ...state,
                selectedTab: action.payload
            }
        case "SET_RESOURCE_PROJECTS":
            return {
                ...state,
                projects: action.payload
            }
        case "SET_ASSIGNED_PROJECT":
            return {
                ...state,
                assignedProject: action.payload
            }
        case "SET_COUNTRIES_ASP_LIST": {
            return {
                ...state,
                countryASPList: action.payload
            };
        }
        case "SET_EHS_COMPLIANCE": {
            return {
                ...state,
                ehsCompliance: action.payload
            }
        }
        case "SET_REPORTING_PERIODS": {
            return {
                ...state,
                reportingPeriods: action.payload
            }
        }
        case "SET_DISABLE_RESOURCE": {
            return {
                ...state,
                disabledResource: action.payload
            }
        }
        case "SET_ASP_RSC_COUNTRIES": {
            return {
                ...state,
                rscCountries: action.payload
            }
        }
        default:
            return state;
    }
};
