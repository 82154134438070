import { constant, groupBy } from "lodash";
import constants from "./constants";
import DateUtils from "./date-utils";

export default class EhsDashboardHelper {

    static mapOhsDashboardData = (data, constants) => {
        
        
        var total = data?.length;
        if (!data || !data.length)
            return null;
        
        var ehsPass = data?.filter(item => parseInt(item.acceptanceStatusId) == 2);
        var firstTimePass = data?.filter(item => item.ftp);
        var criticalViolationReporting = data?.filter(item => item.violationReport);
        var rscReviewLt = data?.filter(item => parseInt(DateUtils.dateDifferenceInMinutes(item.ratingDate, item.sentOhsToRsc)) <= 15);
        var overallEhsLt = data?.filter(item => parseInt(DateUtils.dateDifferenceInMinutes(item.ratingDate, item.uploadDate)) <= 45);

        var ehsPassPercent = total ? (Math.round((ehsPass?.length) / total * 100)) : 0;
        var firstTimePassPercent = total ? (Math.round((firstTimePass?.length) / total * 100)) : 0;
        var ecriticalViolationReportingPercent = total ? (Math.round((criticalViolationReporting?.length) / total * 100)) : 0;
        var rscReviewLtPercent = total ? (Math.round((rscReviewLt?.length) / total * 100)) : 0;
        var overallEhsLtPercent = total ? (Math.round((overallEhsLt?.length) / total * 100)) : 0;
        var overall = Math.round((
            ehsPassPercent * (constants?.ehsPassWeightage || 0)
            + firstTimePassPercent * (constants?.ftpWeightage || 0)
            + (100 - ecriticalViolationReportingPercent) * (constants?.criticalViolationReportingWeightage || 0)
            + rscReviewLtPercent * (constants?.rscReviewLtWightage || 0)
            + overallEhsLtPercent * (constants?.overallEhsLtWeightage || 0))
            / 100);
        var kpis = {
            "ehsPass": { totalItemCount: data.length, successItemCount: ehsPass?.length, percentage: ehsPassPercent, weightage: constants?.ehsPassWeightage },
            "firstTimePass": { totalItemCount: data.length, successItemCount: firstTimePass?.length, percentage: firstTimePassPercent, weightage: constants?.ftpWeightage },
            "criticalViolationReporting": { totalItemCount: data.length, successItemCount: criticalViolationReporting?.length, percentage: ecriticalViolationReportingPercent, weightage: constants?.criticalViolationReportingWeightage },
            "rscReviewLt": { totalItemCount: data.length, successItemCount: rscReviewLt?.length, percentage: rscReviewLtPercent, weightage: constants?.rscReviewLtWightage },
            "overallEhsLt": { totalItemCount: data.length, successItemCount: overallEhsLt?.length, percentage: overallEhsLtPercent, weightage: constants?.overallEhsLtWeightage },
            "Overall": overall
        }
        return kpis;
    }

    static mapGroupedEhsData = (data, resourcesList, filterControls, keyColumn) => {
        if (!data || !data?.length)
            return [];
        var result = [];
        
        data = data.map(item => {
            var countryId = filterControls.aspCompaniesList?.find(e => e.value === item.activity?.aspCompanyId)?.countryId;
            var buildHub = filterControls.countriesList?.find(e => e.value === countryId)?.buildHub;
            var projectId = item.workplan?.projectId;
            var customerId = filterControls.projectsList?.find(e => e.value === projectId)?.customerId;
            var customerUnit = filterControls.customersList?.find(e => e.value === customerId)?.customerUnit;
            const el = {
                ...item,
                resourceId: item.activity?.resourceId,
                activity: {
                    id: item?.activity?.id, name: item?.activity?.name
                },
                activityName: item?.activity?.name,
                aspCompanyId: item.activity?.aspCompanyId,
                countryId: countryId,
                buildHub: buildHub,
                projectId: projectId,
                customerId: customerId,
                customerUnit: customerUnit
            }
            return el;
        })
        var grouped = groupBy(data, item => `${item[keyColumn[0]]}+${item[keyColumn[1]]}`);
        
        Object.keys(grouped).forEach(e => {
            var g = grouped[e];
            
            var groupedData = { activities: grouped[e] }
            var kpis = this.mapOhsDashboardData(groupedData.activities, constants.ehsDashboardConstants);
            var assigned = grouped[e]?.length;
            var completed = grouped[e]?.length;
            var columnGrps = (e.split("+"));
            
            const mappedGroups = columnGrps.map(e => {
                const el = {
                    value: e.includes("Id") ? parseInt(e) : e,
                    text: keyColumn[columnGrps.indexOf(e)]
                };
                return el;
            });
            
            result.push({
                aspCompanyName: keyColumn.includes("aspCompanyId") ? filterControls.aspCompaniesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "aspCompanyId")?.value)))?.text : "",
                teamLeaderName: keyColumn.includes("resourceId") ? resourcesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "resourceId")?.value)))?.text : "",
                activityId: keyColumn.includes("activity") ? mappedGroups.find(g => g.text === "activity")?.value?.id : "",
                activityName: keyColumn.includes("activity") ? mappedGroups.find(g => g.text === "activity")?.value?.name : "",
                countryName: keyColumn.includes("countryId") ? filterControls.countriesList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "countryId")?.value)))?.text : "",
                buildHub: keyColumn.includes("buildHub") ? mappedGroups.find(g => g.text === "buildHub")?.value : "",
                projectName: keyColumn.includes("projectId") ? filterControls.projectsList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "projectId")?.value)))?.text : "",
                customerName: keyColumn.includes("customerId") ? filterControls.customersList?.find(company => company.value == parseInt((mappedGroups.find(g => g.text === "customerId")?.value)))?.text : "",
                customerUnit: keyColumn.includes("customerUnit") ? mappedGroups.find(g => g.text === "customerUnit")?.value : "",
                //teamLeaderName: resourcesList?.find(leader => leader?.value == parseInt(grouped[e]?.resourceId))?.email,
                submitted: assigned,
                completed: completed,
                ehsPass: kpis?.ehsPass?.percentage,
                ftp: kpis?.firstTimePass?.percentage,
                criticalViolationReporting: kpis?.criticalViolationReporting?.percentage,
                rscReviewLt: kpis?.rscReviewLt?.percentage,
                overallEhsLt: kpis?.overallEhsLt?.percentage,
                overall: kpis?.Overall,
            })
        });
        
        return result;
    }

    static mapFilterControls = (userData, projects, rscCountries) => {
        
        var countriesIds = [];
        var customersList = [];
        var countriesList = [];
        var aspCompaniesList = [];
        var projectsList = [];
        if (userData.userData?.jobRole == constants.userRoles.REPORTER || userData.userData?.jobRole == constants.userRoles.ERICSSON_IM || userData.userData?.jobRole == constants.userRoles.IMPLEMENTATION_MANAGER_ASP_MENTOR) {
            customersList = userData.userDetail.customers.map(customer => {
                var mappedCustomer = {
                    value: customer.customer.id,
                    text: customer.customer.name,
                    countryId: customer.customer.countryId,
                    customerUnit: customer.customer.customerUnit
                }
                return mappedCustomer;
            });
            countriesList = userData.countries?.filter(c => customersList.map(item => item?.countryId).includes(c.value))
            countriesList.forEach(c => {
                c.aspCompanies.forEach(a => {
                    aspCompaniesList.push(a);
                })
            });
            projectsList = projects?.filter(p => customersList.map(l => l.value)?.includes(p.customerId) && p.active)?.map(item => {
                var project = {
                    text: item.name,
                    value: item.id,
                    customerId: item.customerId
                }
                return project;
            });
        }
        else if (userData.userData?.jobRole == constants.userRoles.RSC_SITE_SUPERVISOR || userData.userData?.jobRole == constants.userRoles.RSC_OHS_INSPECTOR || userData.userData.jobRole == constants.userRoles.RSC_COODINATOR) {
            var rscCentercountries = rscCountries.filter(item => item.rscCenterId == userData.rscPerson?.rscCenterId);
            var mappedRscCenterCountries = rscCentercountries?.map(item => item.countryId);
            countriesList = userData.countries.filter(item => mappedRscCenterCountries?.includes(item.value));
            customersList = userData.customers.filter(item => mappedRscCenterCountries?.includes(item.countryId));
            countriesList.forEach(c => {
                c.aspCompanies.forEach(a => {
                    aspCompaniesList.push(a);
                })
            });
            projectsList = projects?.filter(p => customersList.map(l => l.value)?.includes(p.customerId) && p.active)?.map(item => {
                var project = {
                    text: item.name,
                    value: item.id,
                    customerId: item.customerId
                }
                return project;
            });
        }
        else {
            aspCompaniesList = userData.userDetail.userAspCompanies.map(c => {
                var companyOption = {
                    text: c?.aspCompany.name,
                    value: c?.aspCompanyId,
                    countryId: c?.aspCompany.countryId
                };
                countriesIds.push(c?.aspCompany.countryId);
                return companyOption;
            });
            countriesList = userData?.countries?.filter(item => countriesIds.includes(item.value));
            
            customersList = userData?.customers?.filter(c => countriesIds.includes(c.countryId));
            const customerIds = customersList?.map(e => e.value);
            projectsList = projects?.filter(p => customerIds?.includes(p.customerId) && p.active)?.map(item => {
                var project = {
                    text: item.name,
                    value: item.id,
                    customerId: item.customerId
                }
                return project;
            });
        }
        return {
            aspCompaniesList: aspCompaniesList,
            countriesList: countriesList,
            customersList: customersList,
            projectsList: projectsList
        }
    }

    static mapSitesDetails = (data, leadersList, filterControls) => {
        if (!data || !data?.length)
            return [];
        var mappedActivities = data.map(a => {
            var countryId = filterControls.aspCompaniesList?.find(e => e.value === a.activity?.aspCompanyId)?.countryId;
            var projectId = a.workplan?.projectId;
            var customerId = filterControls.projectsList?.find(e => e.value === projectId)?.customerId;
            var countryName = filterControls.countriesList?.find(e => e.value === countryId)?.text;
            var buildHub = filterControls.countriesList?.find(e => e.value === countryId)?.buildHub;
            var projectName = filterControls.projectsList?.find(e => e.value == parseInt(a.workplan?.projectId))?.text;
            var customerName = filterControls.customersList?.find(e => e.value === filterControls.projectsList?.find(e => e.value === projectId)?.customerId)?.text;
            var customerUnit = filterControls.customersList?.find(e => e.value === customerId)?.customerUnit;
            const mappedItem = {
                ...a,
                countryName: countryName,
                buildHub: buildHub,
                projectName: projectName,
                customerName: customerName,
                customerUnit: customerUnit,
                companyName: filterControls.aspCompaniesList?.find(company => company.value === a.activity?.aspCompanyId)?.text,
                leaderName: leadersList?.find(leader => leader.value === a.activity?.resourceId)?.text,
                supervisorName: a.rscPerson?.name,
                wpName: a.workplan?.name,
                ehsPass: parseInt(a.acceptanceStatusId) == 2 ? true : false,
                ftp: a.ftp ? true : false,
                criticalViolationReporting: a.violationReport ? true : false,
                rscReviewLt: (parseInt(DateUtils.dateDifferenceInMinutes(a.ratingDate, a.sentOhsToRsc)) <= 15 ? true : false),
                overallEhsLt: (parseInt(DateUtils.dateDifferenceInMinutes(a.ratingDate, a.uploadDate)) <= 45 ? true : false),
            };
            return mappedItem;
        });
        return mappedActivities;
    }
}