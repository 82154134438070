import React,{useCallback, useEffect} from 'react'
import EuiCheckbox from '../../../eui-components/checkbox';
import EuiDatepicker from '../../../eui-components/datepicker';
import EuiNumberField from '../../../eui-components/numberfield';
import EuiRow from '../../../eui-components/row';
import EuiAutocompleteSelect from '../../../eui-components/select/autocompleteselect';
import EuiTextField from '../../../eui-components/textfield';
import EuiTile from '../../../eui-components/tile';
import VehicleHelper from '../../../utils/vehicle-helper';
import DateUtils from '../../../utils/date-utils';

export default function VehicleInformationEHS(props) {
    const {vehicleReducer,vehicleAudit}=props;
    const getVehicleStatus = useCallback(() => {
            return VehicleHelper.vehicleHTMLStatus[vehicleReducer.vehicleStatus];
     
    }, [vehicleReducer]);


    useEffect(() => {
     ///console.log("vehicleReducervehicleReducer",vehicleAudit)
    }, [])
    
    return (
        <>
        <EuiTile title= {"Vehicle Id : " +vehicleReducer.id } contentClassName="tile-bordered" >
            {/* <EuiLoading id="vehicleDetailLoading" isLoading={isLoading} /> */}
            <EuiRow>
                <div className="column sm-12 md-6 lg-6 xl-6 filled">
                    <EuiTextField className="p-1"
                        fullwidth
                        required
                        readOnly
                        style={{ padding: 2 }}
                        id="vehicleChassisNumberId"
                        inputClassName={"validation " + (VehicleHelper.isValidChassisNumber() ? " valid " : "")}
                        name="chassisNumber"
                        label="Chassis Number"
                        pattern="[A-Za-z0-9]+"
                        validationMessage={VehicleHelper.isValidChassisNumber() ? "Valid Chassis Number" : "Only numbers and letters can be used and have to be unique."}
                        value={vehicleReducer.chassisNumber || ""}
                    />
                    <EuiTextField className="p-1"
                        fullwidth
                        required
                        readOnly
                        style={{ padding: 2 }}
                        id="vehicleManufacturerNameId"
                        name="vehicleManufacturer"
                        label="Manufacturer"
                        value={vehicleReducer.vehicleManufacturer || ""}
                    />
                    <EuiTextField className="p-1"
                      fullwidth
                      required
                      readOnly
                      style={{ padding: 2 }}
                      id="validUntilId"
                      name="validUntil"
                      label="valid Until"
                      value={DateUtils.toDateString(vehicleAudit.validUntil, 'YYYY-MM-DD HH:mm:ss')    || ""}
                  />
                  <EuiTextField className="p-1"
                    fullwidth
                    required
                    readOnly
                    style={{ padding: 2 }}
                    id="isVehicleCompliantId"
                    name="isVehicleCompliant"
                    label="is Vehicle Compliant"
                    value={vehicleAudit.isVehicleCompliant || ""}
                />
                </div>
                <div className="column sm-12 md-6 lg-6 xl-6 filled">
                    {/* {
                        getVehicleStatus()
                    } */}
                    <EuiTextField 
                        id="vehicleTypeSelect"
                        className="p-2"
                        fullwidth
                        style={{ padding: 2 }}
                        disabled
                        name="vehicleType"
                        label={ "Vehicle Type" }

                        // placeholder="---Select Vehicle Type---"
                        // options={VehicleHelper.vehicleTypesOptions}
                        value={  vehicleReducer.vehicleType}
                        // optionListStyle={{ width: "100%" ,padding: 3 }}

                        required
                    />
                    <EuiTextField className="p-1"
                        fullwidth
                        required
                        readOnly
                        style={{ padding: 2 }}
                        id="registrationPlateNoId"
                        name="registrationPlateNo"
                        label="Registration Plate No"
                        value={vehicleReducer.registrationPlateNo || ""}
                    />
                     
                    <EuiTextField className="p-1"
                        fullwidth
                        required
                        readOnly
                        style={{ padding: 2}}
                        id="vehicleModelNameId"
                        name="vehicleModelName"
                        label="Model Name"
                        value={vehicleReducer.vehicleModelName || ""}
                    />
                      <EuiTextField className="p-1"
                        fullwidth
                        required
                        readOnly
                        style={{ padding: 2 }}
                        id="vehicleComplianceStatusId"
                        name="vehicleComplianceStatus"
                        label="vehicle Compliance Status"
                        value={vehicleAudit.vehicleComplianceStatus || ""}
                    />
                   
                </div>

      
              

            </EuiRow>
        </EuiTile>
      
        
    
    </>
      
    );
}
