import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import EuiTextField from "../../../eui-components/textfield";
import Table from "../../../components/table/index";
import PageHelper from "../../../utils/page-helper";
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { red } from "@material-ui/core/colors";



class SixthStepContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activitiesColumns:
                [
                    { title: "Site Code", field: "siteCode" },
                    { title: "Work Plan Name", field: "workPlanName" },
                    { title: "Activity ID", field: "activityId" },
                    { title: "Activity Name", field: "activityName" },
                    { title: "ASP Company Name", field: "aspCompanyName" },
                    { title: "ASP Team Lead", field: "aspTeamLead" },
                    { title: "RSC Engineer", field: "rscEngineer" },
                    { title: "RSC Status", field: "rscStatus", render: rowData => PageHelper.renderStatus(rowData.rscStatus) },
                ],
            subActivitiesColumns:
                [
                    {
                        title: "Activity Checklist Item", field: "name",
                        cellStyle: { width: "95%", fontFamily: '"Ericsson Hilda", Helvetica, Arial, sans- serif', padding: 0, paddingLeft: 70 },
                        render: rowData => <span><FiberManualRecordRoundedIcon
                            style={{ fontSize: 20, paddingTop: 6, color: this.getCheckStatusValue(rowData) }} />
                            {rowData.name + "(" + (rowData.documents.length ?? 0).toString() + ")"}
                        </span>
                    },
                ],
            subActivityDocumentsColumns: [
                {
                    title: "", field: "type",
                    render: rowData => this.renderType(rowData),
                    defaultGroupOrder: 0
                },
                {
                    title: "Name", field: "name", grouping: false,
                    cellStyle: { width: "85%", fontFamily: '"Ericsson Hilda", Helvetica, Arial, sans- serif', padding: 0 }
                }
            ],
            selectedRow: { activityId: 0 },
            tableRef: React.createRef(),
            selectedSubActivity: { id: 0 },
        }
    }

    componentDidMount() {
    }

    getCheckStatusValue = (rowData) => {
        const data = []
        const documents = rowData?.documents
       return (documents.length == 0 || documents?.find(d=>d.acceptanceStatusId == 3)) ? red[500] : green[500]
    

    
    }
    renderRow = (rowData) => {
        var selected = (this.state.selectedRow && this.state.selectedRow.activityId === rowData.activityId);
        return PageHelper.renderRow(selected);
    }

    renderType = (rowData) => {
        //const subActivityDocuments = this.state.selectedSubActivity.documents ? this.state.selectedSubActivity.documents : [];
        if (rowData == 1) {
            return <span style={{ paddingLeft: 70 }} ><CropOriginalIcon
                style={{ fontSize: 20, paddingTop: 5 }} /> {"Images" /*(" + subActivityDocuments.filter(item => item.type == 1).length + ") "*/}
            </span>
        } else if (rowData == 2) {
            return <span style={{ paddingLeft: 70 }}> <VideocamOutlinedIcon style={{ fontSize: 20, paddingTop: 5, paddingRight: 5 }} /> {"Videos"/* (" + subActivityDocuments.filter(item => item.type == 2).length + ") "*/}
            </span>
        } else if (rowData == 3) {
            return <span style={{ paddingLeft: 70 }}> <PictureAsPdfOutlinedIcon style={{ fontSize: 20, paddingTop: 5, paddingRight: 5 }} /> {"PDF" /* (" + subActivityDocuments.filter(item => item.type == 3).length + ") "*/}
            </span>
        }
        return <span style={{ paddingLeft: 70 }}><DescriptionOutlinedIcon style={{ fontSize: 20, paddingTop: 5 }} /> {"Other Documents" /* (" + subActivityDocuments.filter(item => item.type == 4).length + ") "*/}
        </span>
    }

    render() {
        return (
            <EuiRow>
                <EuiRow>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField><EuiTextField style={{ marginRight: "0px", width: "100%" }}
                            label="Session Name"
                            name="name"
                            value={this.props.sessionData.sessionInfo.name || ""}
                            disabled
                        /></FormField>
                    </div>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField>
                            <EuiTextField
                                style={{ width: "100%" }}
                                label="Customer"
                                value={this.props.sessionData.sessionInfo.customerName || ""}
                                name="customer"
                                id="sessionCustomer"
                                disabled
                            />
                        </FormField>
                    </div>
                    {
                        //<div className="column sm-6 md-1 lg-1 xl-1 filled">
                        //    <FormField>
                        //        <EuiTextField
                        //            style={{ width: "100%" }}
                        //            label="Project"
                        //            value={this.props.sessionData.sessionInfo.projectName || ""}
                        //            name="project"
                        //            id="sessionProject"
                        //            disabled
                        //        />
                        //    </FormField>
                        //</div>
                    }
                    <div className="column sm-6 md-1 lg-1 xl-1 filled">
                        <FormField><EuiTextField style={{ width: "100%" }}
                            label="Site Code"
                            name="siteId"
                            value={this.props.sessionData.sessionInfo.siteCode || ""}
                            disabled
                        /></FormField>
                    </div>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField>
                            <EuiTextField
                                style={{ width: "100%" }}
                                label="Customer Scope Identification"
                                value={this.props.sessionData.sessionInfo.customerScopeIdentification || ""}
                                name="customerScopeIdentification"
                                id="sessionUser"
                                disabled
                            />
                        </FormField>
                    </div>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField><EuiTextField style={{ marginRight: "0px", width: "100%" }}
                            label="Customer Project Name"
                            name="customerProjectName"
                            value={this.props.sessionData.sessionInfo.customerProjectName || ""}
                            disabled
                        /></FormField>
                    </div>
                    <div className="column sm-6 md-2 lg-2 xl-2 filled">
                        <FormField>
                            <EuiTextField
                                style={{ marginRight: "0px", width: "100%" }}
                                label="Scope of Work Description"
                                value={this.props.sessionData.sessionInfo.scopeOfWorkDescription || ""}
                                name="scopeOfWorkDescription"
                                id="sessionScopeOfWorkDescription"
                                disabled
                            />
                        </FormField>
                    </div>
                </EuiRow>
                <Table
                    title="Activities"
                    columns={this.state.activitiesColumns}
                    data={this.props.sessionData.sessionSummary.length > 0 ?
                        this.props.sessionData.sessionSummary.filter(item => item.sessionType != 6) :
                        []}
                    detailPanel={rowDataActivity => {
                        return (
                            <Table
                                columns={this.state.subActivitiesColumns}
                                container={{ style: { paddingLeft: 15 } }}
                                data={rowDataActivity && rowDataActivity.subActivities && rowDataActivity.subActivities.length > 0 ?
                                    rowDataActivity.subActivities.filter(item => item.sessionAcceptance != 2)
                                    :
                                    []
                                }
                                detailPanel={rowDataSubActivity => {
                                    return (
                                        <Table
                                            columns={this.state.subActivityDocumentsColumns}
                                            container={{ style: { paddingLeft: 50 } }}
                                            data={rowDataSubActivity && rowDataSubActivity.documents && rowDataSubActivity.documents.length > 0 ? rowDataSubActivity.documents : []
                                            }
                                            groupbar={{ custom: <div></div> }}
                                            groupRow={{ custom: true }}
                                            options={{
                                                search: false,
                                                toolbar: false,
                                                header: false,
                                                paging: false,
                                                grouping: true,
                                                showTextRowsSelected: false,
                                            }}
                                        >
                                        </Table>
                                    )
                                }}
                                onRowClick={(event, rowData, togglePanel) => this.setState({ selectedSubActivity: rowData, selectedRow: rowDataActivity }, () => { togglePanel(); })}
                                options={{
                                    search: false,
                                    toolbar: false,
                                    header: false,
                                    paging: false,
                                }}
                            >
                            </Table>
                        )
                    }}
                    onRowClick={(event, rowData, togglePanel) => { this.setState({ selectedRow: rowData }); togglePanel(); }}
                    options={{ paging: false, minBodyHeight: 260, search: false, rowStyle: (rowData) => this.renderRow(rowData) }}
                    tableRef={this.state.tableRef}
                >
                </Table>
            </EuiRow>
        );
    }
}
const FormField = (props) => (
    <div style={{ margin: 10, ...props.style }} className="field validate">
        {props.children}
    </div>
);
const mapStateToProps = state => ({ sessionData: state.session });
export default connect(
    mapStateToProps,
    null
)(SixthStepContent);
