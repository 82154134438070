import React, { useState, useEffect } from "react";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import EuiTile from "../../eui-components/tile";
import EuiRow from "../../eui-components/row";
import EuiTextArea from "../../eui-components/textarea";
import { useSelector, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import constants from "../../utils/constants";
import EuiTextField from "../../eui-components/textfield";
import EuiPillGroup from '../../eui-components/pillgroup';
import DateUtils from "../../utils/date-utils";

const ComplaintInfo = (props) => {
    //const resourceUsers = useSelector(state => state.complaints.resourceUsers);
    const user = useSelector(state => state.user);
    const [countryId, setCountryId] = useState();
    const complaint = useSelector(state => state.complaints.addedComplaint);
    const dispatch = useDispatch();
    const aspContacts = useSelector(state => state.complaints.aspContacts);
    const complaintTypes = useSelector(state => state.complaints.complaintTypes);
    const countries = useSelector(state => state.user.countries);
    const [aspCompanyId, setAspCompanyId] = useState(complaint.aspCompanyId);
    const [usersList, setUsersList] = useState(user.userDetail?.userResourceModels?.filter(x => x.aspCompanyId == complaint.aspCompanyId));
    const [aspCompanies, setAspCompanies] = useState();
    const addedComplaint = { ...complaint };
    useEffect(() => {
        if (complaint.complaintRelatedPeopleView?.length > 0) {
            dispatch({
                type: "SET_COMPLAINT_RECIPIENTS", payload: complaint.complaintRelatedPeopleView?.map(x => { return { responsiblePersonId: x.value } })
            });
        }
    }, [])
    const handleChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const text = e.target.text;
        if (name == "countryId") {
            setCountryId(value);
            setAspCompanies(countries.find(i => i.value == value)?.aspCompanies);
        }
        if (name == "aspCompanyId") {
            setAspCompanyId(value);
            setUsersList(user.userDetail.userResourceModels.filter(x => x.aspCompanyId == value));
            aspContacts.filter(i => i.aspCompanyId == value).length == 1 ? dispatch({ type: "SET_COMPLAINT_RECIPIENTS", payload: aspContacts.filter(i => i.aspCompanyId == value).map(x => { return { responsiblePersonId: x.value } }) }) : dispatch({ type: "SET_COMPLAINT_RECIPIENTS", payload: [] });
        }
        if (name == "complaintTypeId") {
            addedComplaint["complaintType"] = text;
        }
        if (name == "complaintScopeId") {
            addedComplaint["complaintScope"] = text;
        }
        addedComplaint[name] = value;
        dispatch({ type: "SET_ADDED_COMPLAINT", payload: addedComplaint });
    }
    const handleAspContactsChange = (filteredPills) => {
       // const complaintTemp = { ...complaint }; // ...addedComplaint
        var filteredAsps = aspContacts.filter(i => i.aspCompanyId == aspCompanyId).filter(i => filteredPills.includes(i.value.toString())).map(x => { return { responsiblePersonId: x.value } });
        dispatch({ type: "SET_COMPLAINT_RECIPIENTS", payload:  filteredAsps  });
    }
    if (props.mod == constants.actionMods.VIEW_ONLY) {
        return (<EuiTile fullscreen>
            <EuiRow>
                <div className="p-1 w-10">
                    <span style={{ float: "right", marginLeft: 'auto' }}><button hidden={user.userData.jobRole !== constants.userRoles.ASP_PROJECT_MANAGER} disabled={complaint.acknowledgedDateTime !== null || complaint.statusId == constants.complaintStatus.Cancelled || complaint.statusId == constants.complaintStatus.Closed} onClick={() => props.sendAcknowledgment()} className="btn primary">
                        Acknowledge
                    </button>
                    </span>
                </div>
                {/*<EuiTextField*/}
            {/*    className="p-1 w-5"*/}
            {/*    style={{ width: "350px" }}*/}
            {/*    readOnly={true}*/}
            {/*    labelstyle={{ color: "gray" }}*/}
            {/*    required*/}
            {/*    label="Scope *"*/}
            {/*    id="complaintScopeNameId"*/}
            {/*    name="complaintScopeName"*/}
            {/*    value={complaint?.complaintScopeName}*/}
            {/*/>*/}
            <EuiTextField
                className="p-1 w-3"
                style={{ width: "350px" }}
                readOnly={true}
                labelstyle={{ color: "gray" }}
                required
                label={"Complaint Type"}
                id="complaintTypeaNameId"
                name="complaintTypeName"
                value={complaint?.complaintTypeName}
            />
            <EuiTextField
                className="p-1 w-3"
                readOnly={true}
                labelstyle={{ color: "gray" }}
                style={{ width: "350px" }}
                required
                label="ASP Company"
                id="aspCompanyNameId"
                name="aspCompanyName"
                value={complaint.aspCompanyName || ""}
            />
            <EuiTextField
                className="p-1 w-3"
                readOnly={true}
                style={{width: "350px"}}
                labelstyle={{ color: "gray" }}
                required
                label="ASP Team"
                id="complainedAboutUserNameId"
                name="complainedAboutUserName"
                value={complaint.complainedAboutUserName || ""}
            />
            <EuiTextArea
                className="p-1 w-10"
                readOnly={true}
                required
                style={{ height: "90px", width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="complaintContentDetailsId"
                name="complaintContentDetails"
                label="Complaint Details"
                value={complaint?.complaintContent || ""}
            />
            <EuiTextArea
                className="p-1 w-10"
                readOnly={true}
                required
                style={complaint?.complaintJustificationDetails ? { height: "90px", width: "100%" } : { width: "100%"}}
                labelstyle={{ color: "gray" }}
                id="complaintJustificationViewId"
                name="complaintJustificationDetails"
                label="Corrective actions"
                value={complaint?.complaintJustificationDetails || ""}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="createdUserNameId"
                name="createdUserName"
                label="Created User"
                value={complaint?.createdUserName || ""}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="createdDateandTimeId"
                name="createdDateandTime"
                label="Create Date and Time"
                    value={DateUtils.toDateString(complaint?.createDateTime ?? "", "YYYY-MM-DD HH:mm") || ""}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{ width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="closedUserNameId"
                name="closedUserName"
                label="Closed User"
                value={complaint.statusId == constants.complaintStatus.Closed ? complaint.modifiedUserName : ""}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{ width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="closedDateAndTimeId"
                name="closedDateAndTime"
                label="Closed Date and Time"
                value={complaint.statusId == constants.complaintStatus.Closed? DateUtils.toDateString(complaint.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") : ""}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{ width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="cancelledUserNameId"
                name="cancelledUserName"
                label="Cancel User"
                value={complaint.statusId == constants.complaintStatus.Cancelled ? complaint.modifiedUserName : ""}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{ width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="cancledDateAndTimeId"
                name="cancledDateAndTime"
                label="Canceled Date and Time"
                value={complaint.statusId == constants.complaintStatus.Cancelled ? DateUtils.toDateString(complaint.modifiedDateTime ?? "", "YYYY-MM-DD HH:mm") : ""}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{ width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="acknowledgedUserNameId"
                name="acknowledgedUserName"
                label="Acknowledged User"
                value={complaint.acknowledgedUserName}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{ width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="acknowledgedDateTimeId"
                name="acknowledgedDateTime"
                label="Acknowledgment Date and Time"
                value={DateUtils.toDateString(complaint.acknowledgedDateTime ?? "", "YYYY-MM-DD HH:mm") || ""}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{ width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="resolvedUserNameId"
                name="resolvedUserName"
                label="Resolved User"
                value={complaint.resolvedUserName || ""}
            />
            <EuiTextField
                className="p-1 w-2"
                readOnly={true}
                required
                style={{ width: "100%" }}
                labelstyle={{ color: "gray" }}
                id="resolvedDateTimeId"
                name="resolvedDateTime"
                label="Resolution Date and Time"
                value={DateUtils.toDateString(complaint.resolvedDateTime ?? "", "YYYY-MM-DD HH:mm") || ""}
                />
            </EuiRow>
        </EuiTile>)
    }
    else if (props.mod === constants.pageMods.RESOLVE) {
        return (<EuiTile fullscreen>
            <EuiTextArea
                className="p-1 w-10"
                readOnly={false}
                required
                style={{ height: "100px", width: "100%"  }}
                labelstyle={{ color: "gray" }}
                id="complaintJustificationId"
                name="complaintJustificationDetails"
                label="Resolution *"
                onChange={(e) => handleChange(e)}
                value={complaint?.complaintJustificationDetails || ""}
            />
        </EuiTile>)
    }
    else if (props.mod === "Corrective actions") {
        return (<EuiTile fullscreen>
            <div className="p-2 w-10" >
                {(complaint.complaintRelatedPeopleView?.length !== 0) ?
                    (<EuiPillGroup id="aspContactPillsId" pills={complaint.complaintRelatedPeopleView || []} name="aspContactPills" />)
                    : (<p style={{ color: 'red' }}>No recipients available for the selected ASP Company</p>)}
            </div>
        </EuiTile> )
    }
    else {
        return (<EuiTile fullscreen>
            <EuiRow className="w-5" style={{ float: "left" }} >
                {/*<EuiAutocompleteSelect
                className="p-1 w-10"
                fullwidth
                required
                label={{ title: "Scope *", className: "color-gray" }}
                id="complaintScopeIdSelect"
                name="complaintScopeId"
                options={complaintScopes || []}
                value={complaint?.complaintScopeId || 1}
                onChange={(e) => handleChange(e)}
                placeholder={complaint?.complaintScope || "Select Complaint Scope"}
            />*/}
            <EuiAutocompleteSelect
                className="p-1 w-10"
                fullwidth
                required
                label={{ title: "Type *", className: "color-gray" }}
                id="complaintTypeIdSelect"
                name="complaintTypeId"
                options={complaintTypes || []}
                value={complaint?.complaintTypeId || -1}
                onChange={(e) => handleChange(e)}
                placeholder={complaint?.complaintType || "Select Complaint Type"}
            />
                <EuiAutocompleteSelect
                className="p-1 w-10"
                fullwidth
                required
                label={{ title: "Country *", className: "color-gray" }}
                id="complaintCountryIdSelect"
                name="countryId"
                options={countries || []}
                value={countryId || -1}
                onChange={(e) => handleChange(e)}
                placeholder="Select Country"
            />
            <EuiAutocompleteSelect
                className="p-1 w-10"
                fullwidth
                required
                key={aspCompanies}
                label={{ title: "ASP Company *", className: "color-gray" }}
                id="complaintAspCompanyIdSelect"
                name="aspCompanyId"
                options={aspCompanies || [{ text: complaint.aspCompanyName, value: complaint.aspCompanyId }]}
                value={complaint.aspCompanyId || -1}
                onChange={(e) => handleChange(e)}
                placeholder="Select ASP Company"
            />
            <EuiAutocompleteSelect
                className="p-1 w-10"
                    fullwidth
                label={{ title: "ASP Team Resource", className: "color-gray" }}
                id="aspResourceIdSelect"
                name="complainedAboutUserId"
                key={usersList}
                    options={usersList || [{ text: complaint.complainedAboutUserName, value: complaint.complainedAboutUserId }]}
                    value={complaint.complainedAboutUserId || -1}
                onChange={(e) => handleChange(e)}
                placeholder="Select Complained About User"
            />
            </EuiRow>
            <EuiRow className="w-5" style={{ float: "right" }}>
                <div className="p-1 w-10">
                    <label className="color-gray" style={{ fontSize: "14px" }} >Complaint Recipients: <span style={{ fontSize: "14px", color: "green" }}>Green represents the selected recipients</span></label>
                    <span className="color-gray" style={{ fontSize: "13px" }}>* Select at least 1 recipient for the complaint </span>
                    {(aspCompanyId == null) ? (<p className="color-gray" style={{ fontSize: "13px", color: "red" }}>* Select an ASP Company</p>)
                        :
                        (aspContacts.filter(i => i.aspCompanyId == aspCompanyId)?.length !== 0) ?
                            (<div>
                                <EuiPillGroup id="aspContactPillsId" pillsContrast={true} onChange={(filteredPills) => handleAspContactsChange(filteredPills)} pills={aspContacts.filter(i => i.aspCompanyId == aspCompanyId) || []} name="complaintRelatedPeople" /></div>)
                            : (<p style={{ color: 'red', fontSize: "13px"  }}>No recipients available for the selected ASP Company</p>)}
            </div>
            </EuiRow>
            <EuiRow className="w-10">
            <EuiTextArea
                fullwidth
                className="w-10"
                readOnly={false}
                required
                style={{ height: "100px", width: "100%", paddingLeft: "5px" }}
                labelstyle={{ color: "gray" }}
                id="complaintContentId"
                name="complaintContent"
                label="Details *"
                value={complaint?.complaintContent}
                onChange={(e) => handleChange(e)}
                />
            </EuiRow>
        </EuiTile>)
    }
}
export default withRouter(ComplaintInfo);