import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import EuiSelect from "../../../eui-components/select";
import constants from "../../../utils/constants";

class SeverityLevelDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rejectReasons: constants.severityLevels,
            rejectReason: 0,
            rejectDescription: "",
        }
    }
    componentDidMount() {
        if (this.props.rejectReason) {
            this.setState({ rejectReason: this.props.rejectReason, rejectDescription: this.props.rejectDescription });
        }
    }

    render() {
        return (
            <EuiRow>
                <div style={{ width: 400 }}>
                    {!this.props.rejectReason ? null :
                        <>
                            <label htmlFor="reasonDialog">Severity Levels*</label>
                            <EuiSelect style={{ width: "50%" }}
                                label="---Select Severity Level---"
                                options={this.state.rejectReasons}
                                value={this.state.rejectReason}
                                name={"reasonDialog"}
                                id={"reasonDialog"}
                                onChange={e => this.setState({ rejectReason: e.target.value },
                                    () => { this.props.getRejectReason(this.state.rejectReason) })}
                            />
                        </>
                    }
                    <label htmlFor="rejectDescription">Comments*</label>
                    <textarea placeholder="Comments"
                        className="fullwidth"
                        id="rejectDescription"
                        style={{ height: "150px" }}
                        label="Description"
                        name="rejectDescription"
                        defaultValue={this.state.rejectDescription}
                        onBlur={e => this.setState({ rejectDescription: e.target.value },
                            () => { this.props.getRejectDescription(this.state.rejectDescription) })}
                    />
                </div>
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SeverityLevelDialog);
