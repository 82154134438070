import React from "react";

export default class EuiTextField extends React.Component {
    render() {
        const classes = [];
        this.props.fullwidth && classes.push("fullwidth");
        this.props.disabled && classes.push("disabled");
        this.props.required && classes.push("required");
        this.props.withIcon && classes.push("with-icon");

        return (
            <div className={this.props.className ?? ""} style={this.props.divStyle}>
                {this.props.label && <label className={this.props.labelClass} style={{ fontSize: 14, fontWeight: 500, color: "gray", ...this.props.labelStyle }} htmlFor={this.props.id}>{this.props.label}{this.props.optional ? <span style={{ color: "#878787", fontSize: 11 }}>{" (optional)"}</span> : ""}</label>}
                {(this.props.multiLine ?
                    <textarea style={{ /*display: "inline-table" ,*/height:"max-content"}} type="text" {...this.props} className={classes.join(' ')} />

                    : <input type={this.props.type ?? "text"} {...this.props} className={classes.join(' ') + " " + (this.props.suffix ? " with-icon " : "") + (this.props.inputClassName ?? "")} />

                )}
                {this.props.suffix}
                {this.props.clear ? <div className="suffix icon-inside" style={{ marginLeft: -26 }}><i className="icon icon-cross-small text-sm clickable" onClick={() => this.props.onClear && this.props.onClear()}></i></div> : null}
                <span className="validation-msg">{this.props.validationMessage}</span>
                <span className="add-info-text">{this.props.additionalInfo}</span>
            </div>
        );
    }
}
