import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import { setFilteredTeams } from "../../store/actions/teamAction";
import ApiClient from "../../utils/api-client";

class TeamFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
        }
    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        const filter = { ...this.props.teamData.filter };
        filter[name] = value || "";
        this.props.setFilter(filter);
    }

    getTeams = (page, pageSize) => {
        const filter = this.props.teamData.filter;

        delete filter["page"];
        delete filter["pageSize"];

        if (!filter || Object.values(filter).every(x => (!x || x === ""))) {
            toast.error("Please, fill any field to get related teams", { containerId: "WS", position: toast.POSITION.TOP_CENTER });
            return;
        }

        const initialState = new Date();    
        filter.page = page;
        filter.pageSize = pageSize;
        filter.id = parseInt(filter.id ?? "0");
        this.setState({ isLoading: true }, () => {
            ApiClient.post("Team/GetFilteredTeams", filter)
                .then(response => {
                    this.props.setFilteredTeams({ searchedData: [], data: [], totalCount: 0 });
                    const data = [...response.data.data];
                    this.setState(  { isLoading: false }, () => {
                        this.props.setFilteredTeams({
                            searchedData: data, data,
                            totalCount: response.data.totalCount,
                            initialState: initialState.getTime()
                        });
                    });
                })
                .catch(error => {
                    console.error(error);
                    this.setState({ isLoading: false });
                });
        });
    }

    render() {
        return (<EuiRow>
            <EuiTextField className="m-1 w-10" fullwidth id="teamIdFilter" name="id" label="Team ID (=)"
                value={this.props.teamData.filter.id || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "id" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="teamNameFilter" name="name" label="Team Name (*)"
                value={this.props.teamData.filter.name || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "name" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="customerIdFilterTeam" name="customerId" label="Customer ID (*)"
                value={this.props.teamData.filter.customerId || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "customerId" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="customerNameFilterTeam" name="customerName" label="Customer Name (*)"
                value={this.props.teamData.filter.customerName || ""} onChange={(e) => this.handleChange(e)}
                clear={true}
                onClear={() => this.handleChange({ target: { value: "", name: "customerName" } })}
            />
            <button className={"btn primary m-1 " + (this.state.isLoading ? "loading disabled" : "")} onClick={() => this.getTeams(1, 30)}>Search</button>
        </EuiRow >);
    }
}

const mapStateToProps = state => ({
    userData: state.user, navbarData: state.navigation, teamData: state.team
});
const mapDispatchToProps = dispatch => {
    return {
        setFilteredTeams: (filteredTeams) => setFilteredTeams(filteredTeams)(dispatch),
        setFilter: (filter) => dispatch({ type: "SET_FILTER", payload: filter }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TeamFilter); 