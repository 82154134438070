import React from "react";
import { Select } from "@eds/vanilla/select/Select";

class EuiSelect extends React.Component {

    componentDidMount() {
        if (this.props.id) {
            const elements = document.querySelectorAll('#' + this.props.id);
            if (elements) {
                Array.from(elements).forEach(element => {
                    const component = new Select(element);
                    component.init();
                });
            }
        }
        else {
            console.error("You should set an id to Select component");
        }
    }

    render() {

        const p = {
            "data-type": "single"
        }

        return (
            <>
                <div
                    id={this.props.id}
                    className={"select" + (this.props.disabled ? " disabled" : "")}
                    {...p}
                    style={{ maxWidth: "100%", display: "block", ...this.props.styleDiv }}
                >
                    <button
                        type="button"
                        className="btn current-options"
                        style={{ borderRadius: 0, border: "1px solid #878787", fontSize: 14, ...this.props.style }}
                        onClick={() => this.props.onClick && this.props.onClick()}
                    >
                        {(this.props.options.find(option => option.value == this.props.value) || { text: this.props.label }).text}
                    </button>
                    <div className="options-list" style={{ width: "-webkit-fill-available", maxHeight: 170, ...this.props.optionListStyle }} >
                        {this.props.options &&
                            this.props.options.map(o => (
                                <div
                                    key={o.value}
                                    className={"item " + (o.hidden ? " hidden " : "") + (!o.hidden && (this.props.value === o.value ? "active" : ""))}
                                    style={{ display: (o.hidden ? "none" : null) }}
                                    onClick={() =>
                                        this.props.onChange && this.props.onChange({ target: { value: o.value, name: this.props.name, text: o.text } })
                                    }
                                >
                                    {o.text}
                                </div>
                            ))}
                    </div>
                </div>
            </>
        );
    }
}

export default EuiSelect;
