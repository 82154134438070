export default class AutoAssignmentHelper {

    static mapRscPerson = (rscPerson) => {
        if (!rscPerson) {
            return;
        }

        var checkinLogs = rscPerson.dailyCheckinHistory ?? rscPerson.rscCheckinLogs;

        //rscPerson.lastTotalAssignment = checkinLogs?.reduce((a, b) => { a = a + b.totalAssignment; return a; }, 0);
        rscPerson.lastTotalProcessed = checkinLogs?.reduce((a, b) => { a = a + b.totalProcessed; return a; }, 0);

        var logs = checkinLogs?.reduce((a, b, c, d) => {
            const logCheckin = {
                timestamp: b.checkinDate, title: `<span class="color-green">Check-in</span>`, icon: "success",
                //content: `<div>Total session assignment: <b>${b.totalAssignment}</b></div>`,
            };
            a = [...a, logCheckin];
            if (b.checkoutDate) {
                const logCheckout = {
                    timestamp: b.checkoutDate, title: `<span class="color-red">Check-out</span>`, icon: "failed",
                    content: `<div>Total check-in time: <b>${b.totalInterval}</b></div><div>Total processed session: <b>${b.totalProcessed}</b></div>`,
                };
                a = [...a, logCheckout];
            }
            return a;
        }, []);

        rscPerson.dailyCheckinHistory = logs;
    }

    static mapRscPersons = (rscPersons) => {
        if ((rscPersons ?? []) === 0) {
            return;
        }
        rscPersons.map(item => {
            this.mapRscPerson(item);
        });

    }
}