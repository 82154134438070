import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import PageHelper from "../../../utils/page-helper";
import Table from "../../../components/table/index";
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import CreateEditSubActivity from "./createEditSubActivity";
import DialogModal from "../../../components/dialog";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ApiClient from "../../../utils/api-client";
import { ToastContainer, toast } from 'react-toastify';
import LazyLoad from 'react-lazyload';
import EuiProgressBar from "../../../eui-components/progressbar";
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'
import { ExistNoExtension } from "../../../utils/file-helper";
import SasHelper from "../../../utils/sas-helper";
import EuiSwitch from "../../../eui-components/switch";

class ThirdStepContent extends React.Component {

    //editorRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            imageCurrent: 0,
            editImage: false,
            firstEnter: true,
            activitiesColumns:
                [{
                    title: "Actions", render: (rowData) =>
                        <Tooltip id="addSubActivityButtonTooltip" title="Add Activity Checklist Item">
                            <AddIcon style={{ fontSize: 20, paddingTop: 4, paddingLeft: 3 }} id="addSubActivityButton" onClick={() => this.setState({ openDialog: true })} />
                        </Tooltip>,
                    hidden: this.props.sessionData.sessionInfo.viewOnly
                },
                { title: "Site Code", field: "siteCode" },
                { title: "Work Plan Name", field: "workPlanName" },
                { title: "Activity ID", field: "activityId" },
                { title: "Activity Name", field: "activityName" },
                { title: "ASP Company Name", field: "aspCompanyName" },
                { title: "ASP Team Lead", field: "aspTeamLead" },
                { title: "RSC Engineer", field: "rscEngineer" },
                { title: "RSC Status", field: "rscStatus", render: rowData => PageHelper.renderStatus(rowData.rscStatus), defaultSort: "desc" },
                ],
            subActivitiesColumns:
                [
                    {
                        title: "Actions", render: (rowData) =>
                            <label style={{ paddingLeft: 8 }} htmlFor={"uploadDocumentSubActivity" + rowData.id}>
                                <input
                                    style={{ display: "none" }}
                                    id={"uploadDocumentSubActivity" + rowData.id}
                                    name="documentPath"
                                    type="file"
                                    multiple
                                    onChange={e => this.handleFiles(e, rowData)}
                                />
                                <Tooltip id="addFile" title="Add Document">
                                    <AttachFileIcon style={{ fontSize: 20, paddingTop: 4, paddingLeft: 3 }} />
                                </Tooltip>
                            </label>,
                        cellStyle: { width: "10%", padding: 0 },
                        hidden: this.props.sessionData.sessionInfo.viewOnly
                    },
                    {
                        title: "Activity Checklist Item", field: "name",
                        cellStyle: { width: "90%", padding: 0, paddingLeft: 20 },
                        render: rowData => <span className="flex-start-content"><i className={"icon icon-alarm-level4 m-1 " + this.getCheckStatusValue(rowData)}></i>
                            {rowData.name + "(" + (rowData.documents?.length ?? 0).toString()+ ")"}
                        </span>
                    },
                ],
            selectedSubActivityList: [],
            currentImage: 0,
            viewerIsOpen: false,
            uploadedFile: {},

            selectedRow: { id: 0, activityName: "" },
            openDialog: false,
            tableRefActivity: React.createRef(),
            imgEditorRef: React.createRef(),
            newSubActivity: {},

            openDialogUploadBar: false,
            percentageArr: [],
            selectedSubActivity: {},
            problamaticLineItems : false,
            filteredActivities : null
        }

    }

    getCheckStatusValue = (rowData) => {
        const data = []
        const documents = rowData?.documents
       return (documents.length == 0 || documents?.find(d=>d.acceptanceStatusId == 3)) ? "color-red" : "color-green"
    

    
    }
    componentDidMount() {
        if (!this.props.sasToken) {
            SasHelper.getSasKey().then(response => {
                this.props.setSasToken(response)
            })
        }
    }

    openLightbox = (rowData, index) => {
        let activityList = [...this.props.sessionData.sessionSubActivities];

        activityList.map(item => {
            if (item.activityId == rowData.activityId) {
                item.subActivities.map(subItem => {
                    if (subItem.id == rowData.id) {
                        subItem.viewerIsOpen = true;
                        subItem.currentImage = index;
                    }
                })
            }
        });
        this.props.setSessionSubActivities(activityList);
    }

    closeLightbox = (rowData) => {
        this.setState({
            editImage: false, imageCurrent: 0, firstEnter: true
        })
        let activityList = [...this.props.sessionData.sessionSubActivities];

        activityList.map(item => {
            if (item.activityId == rowData.activityId) {
                item.subActivities.map(subItem => {
                    if (subItem.id == rowData.id) {
                        subItem.viewerIsOpen = false;
                        subItem.currentImage = 0;
                    }
                })
            }
        });
        this.props.setSessionSubActivities(activityList);
    }

    insertActivityDocumentsDb = (documents) => {
        
        if (!documents || documents.length === 0) {
            this.setState({ isLoading: false, percentageArr: [] });
            return;
        }

        this.setState({ openDialogUploadBar: false, selectedSubActivity: {}, isLoading: true }, () => {
            ApiClient.post("Session/AddSubActivityDocuments", documents)
                .then(response => {
                    if (response.data) {
                        ApiClient.post("Session/GetSubItemsOfActivity", this.props.sessionData.sessionActivities)
                            .then(response => {
                                let newActivityList = response.data;
                                PageHelper.mapActivityData(newActivityList, this.props.sessionData.sessionInfo.id);
                                this.props.setSessionSubActivities(newActivityList);

                                if (this.state.tableRefActivity.current) {
                                    const activities = (this.props.sessionData.sessionSubActivities || []).filter(item => item.sessionType != 6);
                                    activities.map((item, index) => {
                                        this.state.tableRefActivity.current.onToggleDetailPanel([index], this.state.tableRefActivity.current.props.detailPanel);
                                    });
                                }
                            })
                            .catch(error => {
                                console.error(error);;
                                this.setState({ isLoading: false, percentageArr: [] });
                            })
                    } else {
                        this.setState({ isLoading: false, percentageArr: [] },
                            () => {
                                toast.error("An error was occured! Please try later.", { toastId: "documentError", containerId: 'T', position: toast.POSITION.TOP_CENTER });
                            });
                    }
                    this.setState({ isLoading: false, percentageArr: [] },
                        () => toast.success("Document succesfully added.", { toastId: "documentAdd", containerId: 'T', position: toast.POSITION.TOP_CENTER }))

                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false, percentageArr: [] },
                        () => {
                            toast.error("An error was occured! Please try later.", { toastId: "documentError", containerId: 'T', position: toast.POSITION.TOP_CENTER });
                        });
                })
        });
    }

    updateActivityDocDb = (documents, rowData) => {
        
        if (!documents || documents.length === 0) {
            this.setState({ isLoading: false, percentageArr: [] });
            return;
        }
        rowData.documents.filter(doc => doc.type == 1)[this.state.imageCurrent].documentPath = documents[0].documentPath;
        rowData.documents.filter(doc => doc.type == 1)[this.state.imageCurrent].name = documents[0].name;

        this.setState({ openDialogUploadBar: false, selectedSubActivity: {}, isLoading: true }, () => {
            ApiClient.post("Session/UpdateActivityDoc", { EdittedDoc: documents[0], OldDoc: rowData.documents.filter(doc => doc.type == 1)[this.state.imageCurrent] })
                .then(response => {
                    if (response.data) {
                        ApiClient.post("Session/GetSubItemsOfActivity", this.props.sessionData.sessionActivities)
                            .then(response => {
                                let newActivityList = response.data;
                                PageHelper.mapActivityData(newActivityList, this.props.sessionData.sessionInfo.id);
                                this.props.setSessionSubActivities(newActivityList);
                            })
                            .catch(error => {
                                console.error(error);;
                            })
                    } else {
                        toast.error("An error was occured! Please try later.", { toastId: "documentError", containerId: 'T', position: toast.POSITION.TOP_CENTER });
                        return;
                    }
                    this.setState({ isLoading: false, percentageArr: [] },
                        () => toast.success("Document succesfully updated.", { toastId: "documentAdd", containerId: 'T', position: toast.POSITION.TOP_CENTER }))

                })
                .catch(error => {
                    console.error(error);;
                    this.setState({ isLoading: false, percentageArr: [] },
                        () => {
                            toast.error("An error was occured! Please try later.", { toastId: "documentError", containerId: 'T', position: toast.POSITION.TOP_CENTER });
                        });
                })
        });
        this.setState({
            imageCurrent: 0,
            firstEnter: true
        })
    }

    handleViewChange = (currentIndex) => {
        var number = currentIndex;
        
    }

    mapActivityDocuments = (uploadedFiles, rowData) => {
        return uploadedFiles.map(item => {
            if (rowData) {
                return {
                    name: item.name,
                    activityId: rowData.activityId,
                    subActivityId: rowData.id,
                    uploadDate: new Date(),
                    documentPath: item.documentPath,
                    acceptanceStatusId: 2,
                    sessionAcceptanceStatus: 1,
                    latitude: PageHelper.coord.latitude,
                    longitude: PageHelper.coord.longitude
                };
            } else {
                return {
                    name: item.name,
                    uploadDate: new Date(),
                    documentPath: item.documentPath,
                    acceptanceStatusId: 2,
                    sessionAcceptanceStatus: 1,
                    latitude: PageHelper.coord.latitude,
                    longitude: PageHelper.coord.longitude
                };
            }
        });
    }

    handleFiles = (e, rowData) => {
        const files = e !== null ? [...e.target.files] : [...this.state.newSubActivity.activityDocuments];

        const fileSize = PageHelper.checkFilesSize(files);
        if (fileSize) {
            toast.error(fileSize, { containerId: 'AD', position: toast.POSITION.TOP_CENTER });
            return;
        }

        if (ExistNoExtension(files.map(f => f.name))) {
            toast.error("Please, add extension to all documents.", { toastId: "sizeControl", containerId: 'T', position: toast.POSITION.TOP_CENTER });
            return;
        }

        PageHelper.getCoordinates();

        this.setState({ openDialogUploadBar: true, percentageArr: PageHelper.initializePercentageArr(files), selectedSubActivity: rowData }, () => {
            const onProgress = (progressEvent, file) => {
                let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                let percentageArr = [...this.state.percentageArr];
                const index = percentageArr.indexOf(file);
                percentageArr[index].percentage = percentage;
                this.setState({ percentageArr });
            }

            PageHelper.uploadItems(files, onProgress).then(uploadedFiles => {
                if (rowData === null) {
                    const activityDocuments = this.mapActivityDocuments(uploadedFiles, null);
                    
                    this.setState(prevState => ({
                        ...prevState,
                        newSubActivity: {
                            ...prevState.newSubActivity,
                            latitude: PageHelper.coord.latitude,
                            longitude: PageHelper.coord.longitude,
                            activityDocuments
                        }
                    }),
                        () => this.addSubActivity()
                    );
                } else {
                    this.insertActivityDocumentsDb(this.mapActivityDocuments(uploadedFiles, rowData));
                }
            }).catch(error => {
                console.error(error);;
            });
        });
        
    }

    addSubActivity = () => {
        this.setState({ openDialogUploadBar: false, isLoading: true }, () => {
            ApiClient.post("Session/AddSubActivity", this.state.newSubActivity)
                .then(response => {
                    if (response.data) {
                        ApiClient.post("Session/GetSubItemsOfActivity", this.props.sessionData.sessionActivities)
                            .then(response => {
                                let newActivityList = response.data;
                                PageHelper.mapActivityData(newActivityList, this.props.sessionData.sessionInfo.id);
                                this.setState({ isLoading: false },
                                    () => {
                                        this.props.setSessionSubActivities(newActivityList)
                                        toast.success("Checklist item was succesfully created.", { toastId: "subActivityAdd", containerId: 'T', position: toast.POSITION.TOP_CENTER })
                                    });
                            })
                            .catch(error => {
                                console.error(error);;
                                this.setState({ isLoading: false }, () => {
                                    toast.error("An error was occured! Please try later.", { toastId: "documentErrorSubactivity", containerId: 'T', position: toast.POSITION.TOP_CENTER });
                                });
                            })
                    }
                })
                .catch(error => { console.error(error);; })
        });
    }

    onSubmitDialog = () => {
        this.setState({ openDialog: false }, () => {
            this.handleFiles(null, null);
        });
    }

    renderRow = (rowData) => {
        var selected = (this.state.selectedRow && this.state.selectedRow.activityId === rowData.activityId);
        return { ...PageHelper.renderRow(selected), fontSize: "0.9rem" };
    }

    columns = (containerWidth) => {
        let columns = 1;
        if (containerWidth >= 500) columns = 3;
        if (containerWidth >= 900) columns = 5;
        if (containerWidth >= 1500) columns = 7;
        return columns;
    }

    uploadNewImg = (rowData) => {
        
        const editorInstance = this.state.imgEditorRef.current.getInstance();
        var currentSub = rowData.documents.filter(doc => doc.type == 1)[this.state.imageCurrent];
        var base64File = editorInstance.toDataURL();
        const buffer = atob(base64File.split(',')[1]);
        const j = currentSub.name.indexOf('.');
        const name = currentSub.name.slice(0, j) + '1' + currentSub.name.slice(j, currentSub.name.length);
        const ab = new ArrayBuffer(buffer.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < buffer.length; i += 1) {
            ia[i] = buffer.charCodeAt(i);
        }
        const filee = new Blob([ab], {
            type: 'image/jpeg',

        });
        const files = [filee];
        let percentageArr = [...files];
        percentageArr["name"] = name;
        percentageArr.map(item => {
            item.name = name
        });
        this.setState({ openDialogUploadBar: true, percentageArr: PageHelper.initializePercentageArr(files), selectedSubActivity: rowData }, () => {
            const onProgress = (progressEvent, file) => {
                let percentage = Math.floor((progressEvent.loadedBytes * 100) / file.size);
                let percentageArr = [...this.state.percentageArr];
                const index = percentageArr.indexOf(file);
                percentageArr[index].percentage = percentage;
                this.setState({ percentageArr });
            }

            PageHelper.uploadItems(files, onProgress).then(uploadedFiles => {

                this.updateActivityDocDb(this.mapActivityDocuments(uploadedFiles, rowData), rowData);

            }).catch(error => {
                console.error(error);;
            });
        });
        
        this.setState({
            editImage: false,

        })
    }

    editMode = (index) => {
        this.setState({
            editImage: true,

        })
        if (this.state.imageCurrent == 0 && this.state.firstEnter) {
            this.setState({
                imageCurrent: index,
                firstEnter: false
            })
        }
    }

    viewMode = () => {
        this.setState({
            editImage: false,
        })
    }

    next = (index) => {
        if (this.state.imageCurrent == 0 && this.state.firstEnter) {
            this.setState({ imageCurrent: index + 1 })
        }
        else {
            this.setState({ imageCurrent: this.state.imageCurrent + 1 })
        }

    }

    previous = (index) => {

        if (this.state.imageCurrent != 0) {

            this.setState({ imageCurrent: this.state.imageCurrent - 1 })
        }
        else {
            this.setState({
                imageCurrent: index - 1,
                firstEnter: false
            })
        }


    }

    renderDetailPanel = (rowDataActivity) => {
        return <Table
            key={rowDataActivity.activityId}
            columns={this.state.subActivitiesColumns}
            container={{ style: { paddingLeft: 15 } }}
            data={!this.state.problamaticLineItems ? rowDataActivity.subActivities : rowDataActivity.subActivities.filter(s=>(s.documents || []).length == 0 || s.documents?.find(f=>f?.acceptanceStatusId != 2)) ?? []}
            detailPanel={rowData => {
                return (
                    <div>
                        <LazyLoad height={rowData.documents.filter(doc => doc.type == 1).length > 0 ? 300 : 0} >
                            <Gallery id="thirdGallery"
                                photos={rowData.documents.filter(doc => doc.type == 1).map(item => ({ src: item.src, width: item.width, height: item.height, title: item.name }))}
                                {...(rowData.documents.filter(doc => doc.type == 1).length > 0 ? { direction: "column", columns: this.columns } : {})}
                                onClick={(e, { photo, index }) => this.openLightbox(rowData, index)} />
                        </LazyLoad>

                        <ModalGateway >
                            {rowData.viewerIsOpen ? (
                                this.state.editImage ? (
                                    <Modal onClose={() => this.closeLightbox(rowData)}
                                    >
                                        <div>
                                            <span style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Tooltip id="normalView" title="View Mode" style={{ alignItem: "right" }}>
                                                    <IconButton style={{ alignItem: "right" }} aria-label="normalView" id="View" onClick={this.viewMode} >
                                                        <i className="icon icon-image" style={{ fontSize: 20, color: "white", alignItem: "right" }}></i>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip id="save" title="Save Image" style={{ alignItem: "right" }}>
                                                    <IconButton style={{ alignItem: "right" }} aria-label="upload" id="upload" onClick={() => this.uploadNewImg(rowData)}>
                                                        <i className="icon icon-upload" style={{ fontSize: 20, color: "white", alignItem: "right" }}></i>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip id="close" title="close" style={{ alignItem: "right" }}>
                                                    <IconButton style={{ alignItem: "right" }} aria-label="close" id="close" onClick={() => this.closeLightbox(rowData)}>
                                                        <i className="icon icon-cross" style={{ fontSize: 20, color: "white", alignItem: "right" }}></i>
                                                    </IconButton>
                                                </Tooltip>
                                            </span>
                                            <ImageEditor
                                                ref={this.state.imgEditorRef}
                                                includeUI={{
                                                    loadImage: {
                                                        path: SasHelper.addSasKey(rowData.documents.filter(doc => doc.type == 1)[this.state.imageCurrent].documentPath, this.props.sasToken),
                                                        name: rowData.documents.filter(doc => doc.type == 1)[this.state.imageCurrent].name
                                                    },
                                                    uiSize: {
                                                        height: '600px'
                                                    },
                                                    theme: {
                                                        'common.bi.image': null,
                                                        'common.bisize.width': '0px',
                                                        'common.bisize.height': '0px',
                                                        'loadButton.color': '#1e1e1e',
                                                        'loadButton.backgroundColor': '#1e1e1e',
                                                        'loadButton.border': '0px solid #1e1e1e',
                                                        'downloadButton.color': '#1e1e1e',
                                                        'downloadButton.backgroundColor': '#1e1e1e',
                                                        'downloadButton.border': '0px solid #1e1e1e'
                                                    }
                                                }}> </ImageEditor>
                                          
                                        </div>
                                    </Modal>

                                ) :
                                    <Modal onClose={() => this.closeLightbox(rowData)} >
                                        <Carousel
                                            //onExit={() => this.closeLightbox(rowData)}
                                            currentIndex={(this.state.imageCurrent == 0 && this.state.firstEnter ? rowData.currentImage : this.state.imageCurrent)}
                                            views={rowData.documents.filter(doc => doc.type == 1).map(item => ({ src: item.src, width: item.width, height: item.height, title: item.title })).map(x => {
                                                return ({
                                                    ...x,
                                                    srcset: x.srcSet,
                                                    caption: x.title
                                                })
                                            })}
                                            components={{
                                                NavigationNext: ({ innerProps, isModal }) =>
                                                    isModal ? (
                                                        <div {...innerProps} >

                                                            <Tooltip id="next" title="Next" style={{ display: "flex", justifyContent: "flex-end", zIndex: 50 }}>
                                                                <IconButton style={{ alignItem: "right" }} aria-label="next" id="next" onClick={() => this.next(rowData.currentImage)}  >
                                                                    <i className="icon icon-chevron-right" style={{ fontSize: 50, color: "white", alignItem: "right" }}></i>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    ) : null
                                                ,
                                                NavigationPrev: ({ innerProps, isModal }) =>
                                                    isModal ? (
                                                        <div {...innerProps}  >

                                                            <Tooltip id="previous" title="Previous" style={{ display: "flex", justifyContent: "flex-start" }}>
                                                                <IconButton style={{ alignItem: "left" }} aria-label="previous" id="previous" onClick={() => this.previous(rowData.currentImage)}  >
                                                                    <i className="icon icon-chevron-left" style={{ fontSize: 50, color: "white", alignItem: "right" }}></i>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </div>
                                                    ) : null
                                                ,
                                                Header:
                                                    ({ innerProps, isModal }) =>
                                                        isModal ? (
                                                            <div {...innerProps}>
                                                                <span style={{ display: "flex", justifyContent: "flex-end" }}>
                                                                    <Tooltip id="edit" title="Edit Image" style={{ display: "flex", justifyContent: "flex-end", zIndex: 50 }}>
                                                                        <IconButton style={{ alignItem: "right" }} aria-label="edit" id="edit" onClick={() => this.editMode(rowData.currentImage)}  >
                                                                            <i className="icon icon-edit" style={{ fontSize: 20, color: "white", alignItem: "right" }}></i>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip id="close" title="Close" style={{ display: "flex", justifyContent: "flex-end", zIndex: 50 }}>
                                                                        <IconButton style={{ alignItem: "right" }} aria-label="close" id="close" onClick={() => this.closeLightbox(rowData)} >
                                                                            <i className="icon icon-cross" style={{ fontSize: 20, color: "white", alignItem: "right" }}></i>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                            </div>

                                                        ) : null
                                            }
                                            }
                                        />
                                    </Modal>
                            ) : null
                            }

                        </ ModalGateway>

                    </div >
                )
            }
            }
            isLoading={this.state.isLoading}
            onRowClick={(event, rowData, togglePanel) => this.setState({ selectedRow: rowDataActivity }, () => togglePanel())}
            onSelectionChange={(rows) => this.setState({ selectedSubActivityList: rows })}
            options={{
                selection: !this.props.sessionData.sessionInfo.viewOnly,
                search: false,
                showTextRowsSelected: false, toolbar: false, paging: false,
                showSelectAllCheckbox: false,
                header: false,
            }}
            style={{ overflow: "hidden" }}
            tableRef={rowDataActivity.tableRef}
        >

        </Table >
    }

    selectAllSubActivityOfRow = (rows) => {
        rows.map(row => {
            if (row.tableRef && row.tableRef.current) {
                row.tableRef.current.onAllSelected(true);
            }
        });
        
        this.state.tableRefActivity.current.props.data.map(item => {
            if (!rows.map(item => item.activityId).includes(item.activityId)) {
                if (item.tableRef.current) {
                    item.tableRef.current.onAllSelected(false);
                }
            }
        })
    }

    renderTopTableHeaders = () => {
     return  <div className="row " style={{  alignItems : 'center' ,justifyContent : 'left' , flex : 1 , right : 10 }}>
        <EuiSwitch className="sm-6 lg-3 md-3" style = {{ top: 5 , height : '50%' }}   id="problamaticLineItems"
                    name="problamaticLineItems"
                    checked={this.state.problamaticLineItems}
                    onChange={(e) => {
                        const switchvalue = !this.state.problamaticLineItems
                        // const sessionData = {...this.props.sessionData}
                        // const activities =  sessionData.sessionSubActivities || []
                        // const map = [{subActivities:[]},{subActivities : []}]
                       //  map.find(f=> f.acceptanceStatusId == 1 || f.acceptanceStatusId == 3)
                        // const data = map.map(m=>m).filter(f=>f.subActivities.length > 0)
                        // const updatedactivites =   null
                        
                        

                        this.setState({ problamaticLineItems : switchvalue })
                    }}
                    dataEnabled="Problematic LineItems"
                    dataDisabled="Problematic LineItems"/>
       </div> 
    }
    render() {
        const activities = (this.state.filteredActivities ?  this.state.filteredActivities : this.props.sessionData.sessionSubActivities)
      
        return (
            <>
                <EuiRow style={{ paddingRight: 15 }}>
                    <Table
                        title="Selected Activities"
                      
                       // title = {this.renderTopTableHeaders()}
                        titleStyle = {{width : 300}}
                       
                        toolbar = {{ custom : this.renderTopTableHeaders(), style : {width : "300%"}}}
                        columns={this.state.activitiesColumns}
                        data={activities.filter(item => item.sessionType != 6)}
                        detailPanel={rowDataActivity => this.renderDetailPanel(rowDataActivity)}
                        isLoading={this.props.isLoading}
                        onRowClick={(event, rowData, togglePanel) => { this.setState({ selectedRow: rowData }, () => togglePanel()); }}
                        options={{
                            minBodyHeight: 350,
                            rowStyle: (rowData) => this.renderRow(rowData),
                            selection: true,
                            showTextRowsSelected: false,
                            showSelectAllCheckbox: false,
                            paging: false,
                            selectionProps: rowData =>
                                ({
                                    color: "default",
                                    disabled: !rowData.tableData.showDetailPanel || this.props.sessionData.sessionInfo.viewOnly,
                                    style: {
                                        padding: 3,
                                        margin: "0px 0px 0px 20px"
                                    }
                                })
                        }}
                        onSelectionChange={rows => this.selectAllSubActivityOfRow(rows)}
                        style={{ overflow: "hidden" }}
                        tableRef={this.state.tableRefActivity}
                    >
                    </Table>

                    <DialogModal
                        buttonName="Upload"
                        content={<CreateEditSubActivity
                            subActivity={{ activityId: this.state.selectedRow.activityId, activityName: this.state.selectedRow.activityName }}
                            getNewSubActivity={(newSubActivity) => this.setState({ newSubActivity })}
                        />}
                        handleClose={() => this.setState({ openDialog: false })}
                        id="subActivityDialog"
                        onClick={this.onSubmitDialog}
                        open={this.state.openDialog}
                        title={this.state.selectedRow.activityName}
                    >
                    </DialogModal>

                    <DialogModal
                        content={
                            <div>
                                <label><strong>{(this.state.selectedSubActivity && this.state.selectedSubActivity.name) ?? (this.state.newSubActivity && this.state.newSubActivity.name)}</strong></label>
                                <br />
                                {
                                    this.state.percentageArr.map(file => {
                                        return <div key={file.name} style={{ width: 500 }}>
                                            <label><strong>{file.name}</strong></label>
                                            <EuiProgressBar value={file.percentage} />
                                        </div>
                                    })
                                }
                            </div>
                        }
                        handleClose={() => this.setState({ openDialogUploadBar: false })}
                        id="uploadBarDialog"
                        titleId="titleuploadBarDialog"
                        open={this.state.openDialogUploadBar}
                        buttonsVisible="false"
                        title="Progress Loading..."
                        closeButtonVisible="false"
                    >
                    </DialogModal>

                    <ToastContainer enableMultiContainer containerId={'T'} autoClose={2500} />
                </EuiRow>

                <EuiRow style={{ paddingTop: 10, fontSize: 14 }}><b>Note: Please add new checklist item or document before selection!</b>
                </EuiRow>
            </>
        );
    }
}




const mapStateToProps = state => ({ sessionData: state.session, sasToken: state.complaints.sasToken  });
const mapDispatchToProps = dispatch => {
    return {
        setSessionSubActivities: (subActivities) => dispatch({ type: "SET_SESSION_SUB_ACTIVITIES", payload: subActivities }),
        setSessionDocuments: (documents) => dispatch({ type: "SET_SESSION_DOCUMENTS", payload: documents }),
        setSasToken: (sasToken) => dispatch({ type: "SET_SAS_TOKEN", payload: sasToken })

    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ThirdStepContent);
