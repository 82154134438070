import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import FormField from "../../eui-components/formfield";
import EuiDatepicker from "../../eui-components/datepicker";
import EuiTimepicker from "../../eui-components/timepicker";
import AutoCompleteSelect from "../../components/autocompleteselect";
import EuiTextField from "../../eui-components/textfield";
import constants from "../../utils/constants";
import EuiAutocompleteSelect from "../../eui-components/select/autocompleteselect";
import ActivityHelper from "../../utils/activity-helper";
import ApiClient from "../../utils/api-client";
import EuiSwitch from "../../eui-components/switch";
import { color } from "d3";

class AddEditActivityDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            workplans: [],
            isLoading: false,
            teamLeaders: [],
            aspCompanies: [],
            teams: [],
            disableTeamLeader: true,
            isActive: false,
            isEHSAuditorActivity : false
        }
    }

    componentDidMount() {
        this.setState({
            aspCompanies: this.props.aspCompanies,
            teamLeaders: this.props.teamLeaders,
            isEHSAuditorActivity : this.props.activityData?.activity?.isEHSAuditorActivity
        });
        
        var customerId = this.props.workplanData?.workplan?.customer?.value ||
            this.props.activityData.activity?.workplan?.customer?.value ||
            0
        ApiClient.post("Workplan/GetActivityTeams", {Id: parseInt(customerId)})
        .then(responseT => {
            this.props.setTeams(responseT.data);
            this.setState({
                teams: responseT.data
            });

        })
        .catch(error => alert(error))
        if (!this.props.edit)
            this.props.setActivity({ id: 0, subactivities: [] })
    }

    handleChange = (e,data) => {
        const target = e.target;
        const name = target.name;
        var value = target.value;
        const text = target.text;
        
        const activity = { ...this.props.activityData.activity };
        if (name === "rscScope" || name === "gpoName") {
            activity[name] = text;
        }
        else if (name === "aspCompanyId") {
            activity[name] = value;
            activity.resourceId = -1;
            if (value) {
                 const state =  this.props.activityData?.activity?.isEHSAuditorActivity

                const endPoint  = ! state ? "Workplan/GetTeamLeadersByAspCompany" : "Workplan/GetEligibleEHSAuditorByWorkplanId"
                const payload = { id: parseInt(value), aspCompanyId: parseInt(value), workPlanId: this.props.workplanData?.workplan?.id || this.props.activityData?.activity?.workplanId  }
                ApiClient.post(endPoint, payload)
                    .then((response) => {
                        this.setState({ disableTeamLeader: false, teamLeaders: (response.data || []) });
                    })
                    .catch(error => {
                        alert(error);
                    });
                    
            }
            else {
                this.setState({ teamLeaders: [], disableTeamLeader: true });
            }
        }
        
        else if (name == "workplanModel" && value) {
            activity[name] = value;
            ApiClient.post("Workplan/GetAspCompaniesOfWorkplan", { Id: value.value })
                .then(response => {
                    this.setState({ aspCompanies: response.data });
                    activity.workplanModel = value;
                })
                .catch(error => {
                    alert(error);
                });
            
           
        }
        else {
            activity[name] = value;
        }
        this.props.setActivity(activity);
    }

    toggleEHSAuditor =  (e) => {
        this.setState({isEHSAuditorActivity : e.target.checked})
        const activity = {...this.props.activityData.activity , isEHSAuditorActivity : e.target.checked}
        this.props.setActivity(activity)
       /* this.setState({isEHSAuditorActivity : e.target.checked});
        ApiClient.post("Workplan/GetAllWorkplans", filter)
                    .then(response => {
                        this.setState({ workplans: response.data, isLoading: false });
                    }).catch(error => {
                        console.error(error);;
                        this.setState({ workplans: [], isLoading: false });
                    });*/
    }
   
    handleRemoteChange = (e, value) => {
        if (!e || !value) {
            return;
        }
        const target = e.target;
        if (value && value.length > 3) {
            const filter = { name: value, id: 0 }
            this.setState({ isLoading: true }, () => {
                ApiClient.post("Workplan/GetAllWorkplans", filter)
                    .then(response => {
                        this.setState({ workplans: response.data, isLoading: false });
                    }).catch(error => {
                        console.error(error);;
                        this.setState({ workplans: [], isLoading: false });
                    });
            })
        }
    }

    render() {
        var today = Date.now();

        return (
            <EuiRow key={this.props.activityData.activity} >
                {(this.props.edit ?
                    <EuiRow>
                        <EuiTextField className="p-1 w-3" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="activityEditId" name="activityEditIdId" label="Activity ID" value={this.props.activityData.activity?.id} />
                    </EuiRow>
                    : null)}
                <FormField className="w-10">
                    <EuiRow style={{width : "100%" , alignItems : 'center' , marginBottom :  30 }}>
                    <label className="required" style={{ fontSize: "14px" }} htmlFor="gpoSelect">Global Planning Object</label>
                    <EuiSwitch style = {{position: "absolute" , right: 50 , color : 'red'     }} 
                    checked = {this.state.isEHSAuditorActivity === true}
                    dataEnabled="EHS Auditor"
                    dataDisabled="Team Leader"
                    onChange={(e) => this.toggleEHSAuditor(e)}
                    />
                    </EuiRow>
              
                    
                    <EuiAutocompleteSelect id="gpoNameSelect" name="gpoName" label="---Select Session Type---"
                        key={constants.globalPlanningObject}
                        style={{ width: "100%" }}
                        options={constants.globalPlanningObject}
                        value={(this.props.edit ? ActivityHelper.mapGpoName(this.props.activityData.activity?.gpoName) : 0)}
                        onChange={(e) => this.handleChange(e)}
                        optionListStyle={{ width: "100%" }}
                        required
                    />
                </FormField>
                {(this.props.NoWorkplan ?
                    <FormField className="w-10">
                        <label className="required" htmlFor="workplan" style={{ color: "gray", fontSize: 14 }}>Workplan Name</label>
                        <AutoCompleteSelect
                            required={true}
                            id="workplansActityCreate"
                            options={this.state.workplans}
                            name="workplanModel"
                            onChange={e => this.handleChange(e)}
                            onInputChange={(e, value) => this.handleRemoteChange(e, value)}
                            value={this.props.activityData.activity.workplanModel}
                            placeholder="Start typing workplan name, a dropdown with options will appear"
                            isLoading={this.state.isLoading}
                        />
                    </FormField>
                    : null)}
                <EuiRow>
                    <FormField className="w-10">
                        <EuiTextField required className="w-10" fullwidth id="name" name="name" label="Activity Name" labelClass="required" labelStyle={{ fontSize: "14px" }}
                            value={(this.props.activityData.activity?.name || "")} onChange={(e) => this.handleChange(e)} />
                    </FormField>
                </EuiRow>
                <EuiRow>
                    <FormField className="w-10">
                        <label className="required" style={{ fontSize: "14px" }} htmlFor="rscScopeSelect">Session Type</label>
                        <EuiAutocompleteSelect id="rscScopeSelect" name="rscScope" label="---Select Session Type---"
                            key={constants.rscScopes}
                            style={{ width: "100%" }}
                            options={constants.rscScopes}
                            value={(this.props.edit ? ActivityHelper.mapRscScopes(this.props.activityData.activity?.rscScope) : 0)}
                            onChange={(e) => this.handleChange(e)}
                            optionListStyle={{ width: "100%" }}
                            required
                        />
                    </FormField>
                </EuiRow>
                <EuiRow>
                    <div className="col sm-6 md-6 l-6 xl-6 p-0 mt-1" >
                        <EuiDatepicker className="w-10 ml-1"
                            id={(this.props.edit ? "EditactivityPlannedStart" : "AddActivityPlannedStart")}
                            label="Eritop Planned Start"
                            labelStyle={{ color: "gray" }}
                            placeholder="YYYY-MM-DD"
                            name="plannedStart"
                            value={(this.props.edit ? this.props.activityData.activity?.plannedStart || "" : today)}
                            onChange={e => this.handleChange(e)}
                        />
                    </div>
                    <div className="col sm-6 md-6 l-6 xl-6 p-0 mt-1" >
                        <EuiTimepicker id="activityPlannedStartTime"
                            label="Planned Start Time"
                            name="plannedStartTime"
                            style={{ marginTop: 20, marginLeft: "20px" }}
                            value={this.props.activityData.activity?.plannedStartTime || "00:00"}
                            onChange={(e) => this.handleChange(e)}
                        /></div>
                </EuiRow>
                <EuiRow>
                    <div className="col sm-6 md-6 l-6 xl-6 p-0 mt-1" >
                        <EuiDatepicker className="w-10 ml-1"
                            id={(this.props.edit ? "EditactivityPlannedEnd" : "AddActivityPlannedEnd")}
                            label="Eritop Planned End"
                            labelStyle={{ color: "gray" }}
                            placeholder="YYYY-MM-DD"
                            name="plannedEnd"
                            value={(this.props.edit ? this.props.activityData.activity?.plannedEnd || "" : today)}
                            onChange={e => this.handleChange(e)}
                        />
                    </div>
                    <div className="col sm-6 md-6 l-6 xl-6 p-0 mt-1" >
                        <EuiTimepicker id="activityPlannedEndTime"
                            name="plannedEndTime"
                            style={{ marginTop: 20, marginLeft: "20px" }}
                            value={this.props.activityData.activity?.plannedEndTime || "00:00"}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </div>
                </EuiRow>
                
                <EuiRow>
                    <FormField className="w-10">
                        <label className="required" style={{ color: "gray", fontSize: 14 }} htmlFor="aspCompanySelect">ASP Company</label>
                        <EuiAutocompleteSelect
                            key={(this.props.edit ? this.props.aspCompanies : (this.props.insideWorkplan ? this.props.aspCompanies : this.state.aspCompanies))}
                            required
                            labelClass="required"
                            id="aspCompanySelectActivity"
                            name="aspCompanyId"
                            label="---Select ASP Company---"
                            style={{ width: "100%" }}
                            options={(this.props.edit ? this.props.aspCompanies : (this.props.insideWorkplan ? this.props.aspCompanies : this.state.aspCompanies))}
                            value={this.props.activityData.activity?.aspCompanyId || 0}
                            onChange={(e) => this.handleChange(e)}
                            optionListStyle={{ width: "100%" }}
                        />
                    </FormField>
                </EuiRow>
    
               { ! this.state.isEHSAuditorActivity && <EuiRow >
                    <FormField className="w-10" >
                        <label style={{ color: "gray", fontSize: 14 }} htmlFor="teamLeadersSelect">Team Leader</label>
                        <label style={{ color: "gray", fontSize: 12 }}>only active resources with team lead checked allowed</label>
                        <EuiAutocompleteSelect
                            key={this.state.teamLeaders || this.props.teamLeaders}
                            id="teamLeadersSelectActivity"
                            name="resourceId"
                            label="---Select Team Leader---"
                            style={{ width: "100%" }}
                            options={this.state.teamLeaders || this.props.teamLeaders}
                            value={this.props.activityData.activity?.resourceId || 0}
                            onChange={(e) => this.handleChange(e)}
                            optionListStyle={{ width: "100%" }}
                        />
                    </FormField>
                </EuiRow>
    }
    {
      this.state.isEHSAuditorActivity &&   <EuiRow >
        <FormField className="w-10" >
            <label style={{ color: "gray", fontSize: 14 }} htmlFor="ehsAuditorSelect">EHS  Auditor</label>
            
            <EuiAutocompleteSelect
                key={this.state.teamLeaders || this.props.teamLeaders}
                id="ehsAuditorSelectActivity"
                name="resourceId"
                label="---Select EHS Auditor---"
                style={{ width: "100%" }}
                options={this.state.teamLeaders || this.props.teamLeaders}
                value={this.props.activityData.activity?.resourceId || 0}
                onChange={(e) => this.handleChange(e)}
                optionListStyle={{ width: "100%" }}
            />
        </FormField>
    </EuiRow> 
    }

                {(this.props.edit ?
                    <EuiRow >
                        <FormField className="w-10" >
                            <label style={{ color: "gray", fontSize: 14 }} htmlFor="assignedTeamId">Assign skills and competence requirements</label>
                            <EuiAutocompleteSelect
                                key={this.state.teams}
                                id="teamLeadersSelect"
                                name="assignedTeamId"
                                label="---Select Team ---"
                                style={{ width: "100%" }}
                                options={this.state.teams}
                                value={this.props.activityData.activity?.assignedTeamId || -1}
                                onChange={(e) => this.handleChange(e)}
                                optionListStyle={{ width: "100%" }}
                            />
                        </FormField>
                    </EuiRow> : null)}
            </EuiRow >
                
      );
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, aspResourcesData: state.asp, activityData: state.activity, workplanData: state.workplan });
const mapDispatchToProps = dispatch => {
    return {
        setActivity: (activity) => dispatch({ type: "SET_ACTIVITY", payload: activity }),
        setTeams: (teams) => dispatch({ type: "SET_TEAMS", payload: teams }),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddEditActivityDialog);