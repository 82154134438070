import React from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import FormField from "../../eui-components/formfield";
import EuiSelect from "../../eui-components/select";
import EuiNumberField from "../../eui-components/numberfield";
import { setCertificate } from "../../store/actions/teamAction";
import EuiSwitch from "../../eui-components/switch";

class AddCertificate extends React.Component {
    constructor(props) {
        super(props);
        var teamCertificates = props.teamData.team.teamCertificates.map(t => t.userCertificates.code)
        var certificates = props.teamData.certificates.filter(cert => !teamCertificates.includes(cert.code))
        this.state = {
            certificates: props.teamData.certificate.id? props.teamData.certificates : certificates,
            crtId: "",
            validSinse: "",
            validTill: "",
            disableAdd: false,
            initialState: "",
            dateError: false,
            certificateExist: "",
            toggleDialog: false,
            selectedCrtId: ""
        }
    }

    componentDidMount() {

    }

    handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;
        const crt = { ...this.props.teamData.certificate }
        crt[name] = value;
        if (name === "minCertificateNumber") {
            crt.minCertificateNumber = +value;
        }

        this.props.setCertificate(crt);
    }

    render() {
        return (
            <EuiRow className="w-9" style={{ width: '42rem' }} >
                <FormField className="w-10 mt-2 mb-2">
                    <label className="required" style={{ fontSize: "14px" }} htmlFor="ctrSelect">Select Certificate</label>
                    <EuiSelect
                        required
                        className="m-1"
                        fullwidth
                        label="Select Certificate"
                        id={this.props.id + "Select"}
                        optionListStyle={{ width: "100%", height: "145px" }}
                        disabled={this.props.teamData.certificate.id}
                        name="certificateId"
                        options={this.state.certificates}
                        value={this.props.teamData.certificate.certificateId}
                        onChange={(e) => this.handleChange(e)}
                    />
                </FormField>
                <EuiRow>
                    <FormField className="w-10 mt-2 mb-2">
                        <EuiNumberField
                            required
                            className="w-10 mb-2"
                            label="Required Certificates *"
                            labelStyle={{ color: "gray" }}
                            min={1}
                            max={100}
                            step={1}
                            style={{ width: "100%" }}
                            id="minCertificateNumber"
                            name="minCertificateNumber"
                            value={this.props.teamData.certificate.minCertificateNumber}
                            onChange={(e) => this.handleChange(e)}
                        />
                    </FormField>
                </EuiRow >
                <EuiRow>
                    <EuiSwitch
                        checked={this.props.teamData.certificate.isActive}
                        name="isActive"
                        onChange={(e) => this.handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                        dataEnabled="Is Active"
                        dataDisabled="Not Active"
                        className="p-1"
                    />
                </EuiRow >
                <FormField className="w-10 mt-2 mb-2" style={{ color: "red" }}>
                    *Mandatory data must be filled to save.
                </FormField>
            </EuiRow >
        );
    }
}

const mapStateToProps = state => ({
    userData: state.user,
    navbarData: state.navigation,
    teamData: state.team
});
const mapDispatchToProps = dispatch => {
    return {
        setCertificate: (certificate) => setCertificate(certificate)(dispatch),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCertificate);
