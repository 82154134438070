import React from "react"

const EuiHeader = (props) => {

    return (
        <div className="w-10">
            <div id={props.id} className="header " style={{ padding: 5, marginTop: 5, alignItems: "center", height: 42, width: "100%", ...(props.style ?? {}) }}>
                <div className="left ">
                    <span className="title m-1" style={props.titleStyle}>{props.title}</span>
                    <span className="subtitle m-1">{props.subTitle}</span>
                </div>
                {props.detail ?
                    <div className="detail">{props.detail}</div>
                    : null
                }
                <div className="right ">
                    {props.rightActions}
                </div>
            </div>
            <div className="header-bottom-line"></div>
        </div>
    );

}

export default EuiHeader;