import React from "react";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import Rater from 'react-rater'

class ApproveSessionDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            sessionComment: "",
            sessionRating: 5
        }
    }

    render() {
        return (
            <EuiRow>
                <div style={{ width: "100%", margin:5 }}>
                    <label htmlFor="sessionComment" style={{ marginBottom: 10, display:(this.props.hideLabel ? "none" : "flex") }}>Comments*</label>
                    <textarea placeholder="Comments"
                        className="fullwidth"
                        id="sessionComment"
                        style={{ height: "100px" }}
                        label="Comments"
                        name="sessionComment"
                        defaultValue={this.state.sessionComment}
                        onBlur={e => this.setState({ sessionComment: e.target.value },
                            () => { this.props.getSessionComment(this.state.sessionComment) })
                        }
                    />
                    <div style={{ display: (this.props.hideRating ? "none" : "flex"), alignItems: "center"  }}>
                        <label style={{ width: "45%" }} htmlFor="sessionRating">Session Rating</label>
                        <Rater total={5}
                            rating={this.state.sessionRating}
                            interactive={true}
                            onRate={(event) => {
                                this.setState({ sessionRating: event.rating },
                                    () => this.props.getSessionRating(this.state.sessionRating)
                                )
                            }}
                        />
                    </div>
                </div>
            </EuiRow>
        );
    }
}

const mapStateToProps = state => ({});
const mapDispatchToProps = dispatch => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ApproveSessionDialog);
