import React, { useState } from "react";
import EuiRow from "../../eui-components/row";
import { useSelector, useDispatch } from "react-redux";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import EuiRowWithToolbar from "../../eui-components/rowwithtoolbar";
import EuiTooltip from "../../eui-components/tooltip";
import constants from "../../utils/constants";

const IMChecklistInfo = (props) => {

    const mod = useSelector(state => state.imInbox.mod);
    const currentActivity = useSelector(state => state.imInbox.currentIMActivity);

    return (
        <EuiRowWithToolbar className={"mt-1"} contentStyle={{ display: "block" }} leftIcons={mod != constants.pageMods.VIEW_ONLY ? <>
            <EuiTooltip title="Accept All">
                <i className={"icon icon-check m-1 icon-frame "} onClick={(item, status) => props.changeChecklistAllStatus(item, constants.acceptanceStatusTypes.ACCEPTED)}></i>
            </EuiTooltip>
            <EuiTooltip title="Reject All">
                <i className={"icon icon-cross m-1 icon-frame "} onClick={(item, status) => props.changeChecklistAllStatus(item, constants.acceptanceStatusTypes.REJECTED)}></i>
            </EuiTooltip>
        </> : null}
        >
            <EuiTextField id="imActivityChecklistCustomerName"
                className="p-1"
                name="customerName"
                label="Customer Name : "
                value={currentActivity.customerName}
                fullwidth
                readOnly
                labelstyle={{ color: "gray" }}
            ></EuiTextField>

            <EuiTextField id="imActivityChecklistProjectName"
                className="p-1"
                name="projectName"
                label="Project Name : "
                value={currentActivity.projectName}
                fullwidth
                readOnly
                labelstyle={{ color: "gray" }}
            ></EuiTextField>

            <EuiTextField id="imActivityChecklistWorkplanName"
                className="p-1"
                name="workplanName"
                label="Workplan Name : "
                value={currentActivity.workplanName}
                fullwidth
                readOnly
                labelstyle={{ color: "gray" }}
            ></EuiTextField>

            <EuiTextField id="actualStartSubactivityInfo"
                className="p-1"
                name="ActualStart"
                label="Actual Start : "
                value={DateUtils.toDateString(props.rowData.actualStart, "YYYY-MM-DD HH:mm")}
                fullwidth
                readOnly
                labelstyle={{ color: "gray" }}
            ></EuiTextField>
            <EuiTextField id="actualEndSubactivityInfo"
                className="p-1"
                name="ActualEnd"
                label="Actual End : "
                value={DateUtils.toDateString(props.rowData.actualEnd, "YYYY-MM-DD HH:mm")}
                fullwidth
                readOnly
                labelstyle={{ color: "gray" }}
            ></EuiTextField>
            <EuiTextField id="durationSubactivityInfo"
                className="p-1"
                name="duration"
                label="Duration : "
                value={DateUtils.dateDifferenceInMinutes(props.rowData.actualEnd, props.rowData.actualStart)}
                readOnly
                fullwidth
                labelstyle={{ color: "gray" }}
            ></EuiTextField>

        </EuiRowWithToolbar >
    );
}

export default IMChecklistInfo;