import React from "react";
import { ColorScale, VerticalBarChartGrouped } from '@eds/vanilla';
class EuiVerticalBarChart extends React.Component {

    componentDidMount() {
        const myColorScale = new ColorScale({
            length: 2, // <-- Make a color scale consisting of 2 colors
            offset: 1  // <-- Offset the starting color by one
        });
        myColorScale.init();
        const Data = this.props.data;
        const chart = new VerticalBarChartGrouped({
            element: document.getElementById(this.props.id),
            data: Data,
            y: { unit: this.props.unit },
            width: this.props.width,
            height: this.props.height,
            colorScale: this.props.applyColorScale ? myColorScale : ""
        });
        chart.init();
    }

    render() {
        return (
            <div className="w-10" key={this.props.key} id={this.props.id}></div>
        );
    }
}
export default EuiVerticalBarChart;