
export const login = (loginUser) => dispatch => {
    dispatch({
        type: "LOGIN",
        payload: loginUser
    });
};


export const logout = () => dispatch => {
    dispatch({
        type: "LOGOUT"
    });
};

export const passwordReset = () => dispatch => {
    dispatch({
        type: "RESET_PASSWORD",

    });
};

export const setAllUsers = (users) => dispatch => {
    dispatch({
        type: "SET_ALL_USERS",
        payload: {
            users
        }
    });
};

export const setAllCustomers = (customers) => dispatch => {
    dispatch({
        type: "SET_ALL_CUSTOMERS",
        payload: {
            customers
        }
    });
};

export const setAllCustomersWithProjects = (customersWithProjects) => dispatch => {
    dispatch({
        type: "SET_ALL_CUSTOMERS_WITH_PROJECTS",
        payload: customersWithProjects
    });
};

export const setAllCountries = (countries) => dispatch => {
    dispatch({
        type: "SET_ALL_COUNTRIES",
        payload: {
            countries
        }
    });
};

export const setRscPerson = (rscPerson) => dispatch => {
    dispatch({
        type: "SET_RSC_PERSON",
        payload: {
            rscPerson
        }
    });
};

export const setECAConfig = (ecaConfig) => dispatch => {
    dispatch({
        type: "SET_ECA_CONFIG",
        payload: {
            ecaConfig
        }
    });
};