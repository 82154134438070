import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { groupBy } from "lodash";

import EuiRow from "../../../eui-components/row";
import DialogModal from "../../../components/dialog";
import ObseleteEuiTable from "../../../eui-components/obseleteTable";

import ManageColumnsDialog from "./manageColumnDialog";

import DateUtils from "../../../utils/date-utils";
import productivityDashboardHelper from "../../../utils/productivity-dashboard-helper"


const ProductivityPerformanceDetails = (props) => {
    const dispatch = useDispatch();

    const productivityDashboardData = useSelector(state => state.productivityDashboard);
    const resources = productivityDashboardData.resources;
    const data = productivityDashboardData.sitesDetails.data || [];
    const monthlyActivityResult = productivityDashboardData.sitesDetails.monthlyGroupedActivities || [];

    const [openColumnsDialog, setOpenColumnsDialog] = useState(false);

    var columns = [
        {
            title: "Build Hub",
            key: "buildHub",
            sort: "none",
            width: "15%",
            headerStyle: !productivityDashboardData.actualGroupingColumns?.includes("buildHub") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !productivityDashboardData.actualGroupingColumns?.includes("buildHub") ? "display:none;" : null,
        },
        {
            title: "Customer Unit",
            key: "customerUnit",
            sort: "none",
            width: "15%",
            headerStyle: !productivityDashboardData.actualGroupingColumns?.includes("customerUnit") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !productivityDashboardData.actualGroupingColumns?.includes("customerUnit") ? "display:none;" : null,
        },
        {
            title: "Country",
            key: "countryName",
            sort: "none",
            width: "15%",
            headerStyle: !productivityDashboardData.actualGroupingColumns?.includes("countryId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !productivityDashboardData.actualGroupingColumns?.includes("countryId") ? "display:none;" : null,
        },
        {
            title: "Customer",
            key: "customerName",
            sort: "none",
            width: "15%",
            headerStyle: !productivityDashboardData.actualGroupingColumns?.includes("customerId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !productivityDashboardData.actualGroupingColumns?.includes("customerId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "Project",
            key: "projectName",
            sort: "none",
            width: "15%",
            headerStyle: !productivityDashboardData.actualGroupingColumns?.includes("projectId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !productivityDashboardData.actualGroupingColumns?.includes("projectId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "ASP Company",
            key: "aspCompanyName",
            sort: "none",
            width: "15%",
            headerStyle: !productivityDashboardData.actualGroupingColumns?.includes("aspCompanyId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !productivityDashboardData.actualGroupingColumns?.includes("aspCompanyId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "ASP Team Leader",
            key: "teamLeaderName",
            sort: "none",
            width: "15%",
            headerStyle: !productivityDashboardData.actualGroupingColumns?.includes("resourceId") ? "display:none;white-space:nowrap;" : "white-space:nowrap;",
            cellStyle: !productivityDashboardData.actualGroupingColumns?.includes("resourceId") ? "display:none;white- space: nowrap;" : "white-space:nowrap;",
        },
        {
            title: "Productivity Points",
            key: "productivityPoints",
            sort: "none",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = (!Number.isInteger(cellData) ? parseFloat(cellData).toFixed(3) : cellData);
                td.style = "text-align:center";

            },
            width: "6%",
            headerStyle: "white-space:nowrap;",
            cellStyle: "white-space:nowrap;",

        },
        {
            title: "Productivity %",
            key: "productivityPercentage",
            sort: "none",
            width: "5%",
            headerStyle: "white-space:nowrap;",
            cellStyle: "white-space:nowrap;",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = (!Number.isInteger(cellData) ? parseFloat(cellData).toFixed(3) : cellData) + "%";
                if (cellData == NaN) {
                    td.innerHTML = "0%";
                }
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            }
        },
        {
            title: "Utilization Sessions",
            key: "utilizationNumberOfSessions",
            sort: "none",
            width: "6%",
            headerStyle: "white-space:nowrap;",
            cellStyle: "white-space:nowrap;",
            onCreatedCell: (td, cellData) => {
                td.style = "text-align:center";
            }
        },
        {
            title: "Utilization %",
            key: "utilizationPercentage",
            sort: "none",
            width: "5%",
            headerStyle: "white-space:nowrap;",
            cellStyle: "white-space:nowrap;",
            onCreatedCell: (td, cellData) => {
                td.innerHTML = (!Number.isInteger(cellData) ? parseFloat(cellData).toFixed(3) : cellData) + "%";
                if (cellData == 100)
                    td.style = "background-color:rgb(230, 241, 235); text-align:center";
                else
                    td.style = "text-align:center";
            }
        },

    ];

    var DD = new Date();
    const fileName = "dashboard-teamsPerformanceDetails" + DD.toISOString();
    let detailedActivities = productivityDashboardData.detailedActivities.map(item => {
        let mappedItem = {
            BuildHub: item.buildHub,
            CustomerUnit: item.customerUnit,
            Country: item.countryName,
            Customer: item.customerName,
            Project: item.projectName,
            AspCompany: item.aspCompanyName,
            Workplan: item.workplan?.name,
            SiteType: item.workplan?.workplanSuffix?.slice(0, 3),
            ID: item.id,
            ActivityName: item.name,
            ASPTeamLeader: item.teamLeaderName,
            PrePatDate: item.prePateDate?.slice(0, 10),
            InstallationDate: item.installationDate?.slice(0, 10),
            ProductivityPoints: item.productivityPoints,
            ProductivityMonth: item.productivityMonth
        }
        return mappedItem;
    });
    let detailedPings = productivityDashboardData.detailedPings.map(item => {
        let mappedItem = {
            BuildHub: item.buildHub,
            CustomerUnit: item.customerUnit,
            Country: item.countryName,
            Customer: item.customerName,
            Project: item.projectName,
            AspCompany: item.aspCompanyName,
            Workplan: item.activity.workplan?.name,
            ActivityId: item.activity.id,
            ASPTeamLeader: item.teamLeaderName,
            PingId: item.id,
            RatingDate: item.ratingDate?.slice(0, 10)
        }
        return mappedItem;
    });
    let mappedData = data?.map(e => {
        var mappedItem = {
            BuildHub: e.buildHub,
            CustomerUnit: e.customerUnit,
            Country: e.countryName,
            Customer: e.customerName,
            Project: e.projectName,
            AspCompany: e.aspCompanyName,
            ASPTeamLeader: e.teamLeaderName,
            ProductivityPoints: e.productivityPoints,
            ProductivityPercentage: e.productivityPercentage,
            ProductivityMonth: e.productivityMonth,
            UtilizationSessions: e.utilizationNumberOfSessions,
            UtilizationPercantage: e.utilizationPercentage,
            isEhs: e.isEhs
        };
        if (!productivityDashboardData.actualGroupingColumns.includes("resourceId"))
            delete mappedItem["ASPTeamLeader"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("buildHub"))
            delete mappedItem["BuildHub"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("customerUnit"))
            delete mappedItem["CustomerUnit"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("countryId"))
            delete mappedItem["Country"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("customerId"))
            delete mappedItem["Customer"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("projectId"))
            delete mappedItem["Project"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("aspCompanyId"))
            delete mappedItem["AspCompany"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("resourceId"))
            delete mappedItem["ASPTeamLeader"];

        return mappedItem;
    });
    let mappedActivities = monthlyActivityResult.map(e => {
        var mappedItem = {
            BuildHub: e.buildHub,
            CustomerUnit: e.customerUnit,
            Country: e.countryName,
            Customer: e.customerName,
            Project: e.projectName,
            AspCompany: e.aspCompanyName,
            ASPTeamLeader: e.teamLeaderName,
            ProductivityPoints: e.productivityPoints,
            ProductivityPercentage: e.productivityPercentage,
            ProductivityMonth: e.productivityMonth,
            UtilizationSessions: e.utilizationNumberOfSessions,
            UtilizationPercantage: e.utilizationPercentage
        };
        if (!productivityDashboardData.actualGroupingColumns.includes("resourceId"))
            delete mappedItem["ASPTeamLeader"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("buildHub"))
            delete mappedItem["BuildHub"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("customerUnit"))
            delete mappedItem["CustomerUnit"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("countryId"))
            delete mappedItem["Country"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("customerId"))
            delete mappedItem["Customer"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("projectId"))
            delete mappedItem["Project"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("aspCompanyId"))
            delete mappedItem["AspCompany"];
        if (!productivityDashboardData.selectedGroupingColumns.includes("resourceId"))
            delete mappedItem["ASPTeamLeader"];

        return mappedItem;
    });
    let pingsLeaders = detailedPings.map(a => a.ASPTeamLeader).filter((v, i, a) => a.indexOf(v) === i);
    let _groupedActivities = mappedActivities.map(item => {
        let activity = {
            ...item

        };
        delete activity["UtilizationSessions"];
        delete activity["UtilizationPercantage"];
        return activity;
    });
    let final_gpA = groupBy(_groupedActivities, item => `${item["ASPTeamLeader"]}+{${item["ProductivityMonth"]}}`);
    let groupedActivities = [];
    Object.keys(final_gpA).forEach(g => {
        final_gpA[g].forEach(item => {
            groupedActivities.push(item);
        })
    });
    let groupedPings = mappedData.filter(a => a.isEhs).map(item => {
        let ping = {
            ...item
        };
        delete ping["ProductivityPoints"];
        delete ping["ProductivityPercentage"];
        delete ping["productivityMonth"];
        delete ping["ProductivityMonth"];
        delete ping["isEhs"];
        return ping;
    });
    var excelData = [{ name: 'Detailed Activities', data: detailedActivities }, { name: "Detailed EHS Pings", data: detailedPings }, { name: 'Grouped Activities', data: groupedActivities }, { name: 'Grouped EHS Pings', data: groupedPings }];

    const applyColumnsUpdates = () => {
        if (!productivityDashboardData.selectedGroupingColumns || !productivityDashboardData.selectedGroupingColumns.length) {
            alert("put notification to select at least one column");
            return;
        }
        dispatch({ type: "SET_PRODUCTIVITY_ACTUAL_TEAM_GROUPING_COLUMNS", payload: productivityDashboardData.selectedGroupingColumns });
        let numberOfMonths = DateUtils.getMonthDifference(new Date(productivityDashboardData.filter.startDate), new Date(productivityDashboardData.filter.endDate));
        var groupedData = productivityDashboardHelper.getGroupedData(productivityDashboardData.rawData.activities, productivityDashboardData.rawData.ohsPings, resources, productivityDashboardData.filterControls, productivityDashboardData.selectedGroupingColumns, numberOfMonths || 1);
        
        dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_ISLOADING", payload: true });
        dispatch({ type: "SET_PRODUCTIVITY_KPIS_DETAILS", payload: groupedData });
        dispatch({ type: "SET_PRODUCTIVITY_DASHBOARD_ISLOADING", payload: false });
        setOpenColumnsDialog(false);
    }

    const initiateOpenColumnsDialog = () => {
        setOpenColumnsDialog(true);
    }
  
    return (<EuiRow key={data}>
        <DialogModal
            id="manageColumnsQualityDashboardPerformanceDetails"
            disableBackdropClick={true}
            title="Grouping Columns"
            content={<ManageColumnsDialog />}
            buttonName="Apply"
            type="primary"
            onClick={applyColumnsUpdates}
            customStyle={{ width: 400, height: 650 }}
            open={openColumnsDialog}
            handleClose={() => setOpenColumnsDialog(false)}
            contentStyle={{ height: "70%", width: "100%" }} />
        <ObseleteEuiTable
            key={productivityDashboardData.actualGroupingColumns}
            id="aspPmDashboardPerformanceDetails"
            columns={columns}
            data={data}
            filter={true}
            viewActionButton={true}
            hideSearch={true}
            actionButton={<span className="tooltip"><i className="icon icon-settings" style={{ fontSize: "25px", marginRight: "15px" }} onClick={initiateOpenColumnsDialog} /><span className="message left">Edit Grouping Columns</span></span>}
            scroll={false}
            hideSearch={true}
            classes="tiny split-striped"
            exportExcel={true}
            Reportdata={excelData}
            paginated={true}
            rowsPerPage={13}
            excelFileName={fileName}
            multiSheets={true}
        />
    </EuiRow>);
}
export default ProductivityPerformanceDetails;