import React from 'react';
import 'tui-image-editor/dist/tui-image-editor.css'
import ImageEditor from '@toast-ui/react-image-editor'

const EditImage = ({ path, name, forwardedRef }) => {
    return (
        < ImageEditor
            ref={forwardedRef}
            includeUI={{
                loadImage: {
                    path: path,
                    name: name
                },
                uiSize: {
                    height: '600px'
                },
                theme: {
                    'common.bi.image': null,
                    'common.bisize.width': '0px',
                    'common.bisize.height': '0px',
                    'loadButton.color': '#1e1e1e',
                    'loadButton.backgroundColor': '#1e1e1e',
                    'loadButton.border': '0px solid #1e1e1e',
                    'downloadButton.color': '#1e1e1e',
                    'downloadButton.backgroundColor': '#1e1e1e',
                    'downloadButton.border': '0px solid #1e1e1e',
                },
                menuBarPosition: 'bottom',
            }}> </ImageEditor>
    )

}
export default EditImage 