import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import { toast } from "react-toastify";
import ApiClient from "../../utils/api-client";
import WorkplanHelper from "../../utils/workplan-helper";
import { objectOf } from "prop-types";
import EuiDatepicker from "../../eui-components/datepicker";
import constants from "../../utils/constants";
import EuiNewMultiSelect from "../../eui-components/select/newmultiselect";
import EuiCheckbox from "../../eui-components/checkbox";
import EuiTile from "../../eui-components/tile";
import FormField from "../../eui-components/formfield";
import EuiPillGroup from "../../eui-components/pillgroup";
import ApiHelper from "../../utils/api-helper";

const AspPmFilter = (props) => {

    const [isLoading, setIsLoading] = useState(false);

    const activityData = useSelector(state => state.activity);
    const aspPmActivityFilter = useSelector(state => state.activity.filter);

    const userData = useSelector(state => state.user);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!activityData.aspPmTeamLeaders || !activityData.aspPmTeamLeaders.length) {
            setIsLoading(true);
            ApiClient.post("Activity/GetTeamLeadersByAspManager", { id: parseInt(userData.userData.userId) })
                .then(response => {
                    dispatch({ type: "SET_ASP_PM_TEAM_LEADERS", payload: response.data });

                    setIsLoading(false);
                }).
                catch(error => {
                    alert(error);
                    setIsLoading(false);
                });
        }
        if (!activityData.filteredActivities || !activityData.filteredActivities.length && activityData.aspPmFilterTypes.length == 1 && activityData.aspPmFilterTypes.includes(constants.imFilterTypes.UN_ASSIGNED)) {
            setIsLoading(true);
            getActivities();
        }

    }, [])

    const handleChange = (e, selectedItems = null) => {
        const target = e.target;
        const name = target?.name;
        const value = target?.value;

        const filter = { ...aspPmActivityFilter };
        if (selectedItems) {
            const selectedLeaders = activityData.aspPmTeamLeaders.filter(c => selectedItems.includes(c.text.trim()));
            const ids = selectedLeaders.map(s => s.value);
            filter["teamLeaderIds"] = ids || [];
        }
        else if (name == "actualEndDate" && !value) {
            filter[name] = null;
        }

        else {
            filter[name] = value || "";
        }
        dispatch({ type: "SET_ACTIVITY_FILTER", payload: filter });
    }

    const getActivities = () => {

        const filter = { ...activityData.filter };

        const filterTypes = [...activityData.aspPmFilterTypes];
        if (filterTypes?.includes("1")) {
            filter.assigned = true;
        } else {
            filter.assigned = false;

        } if (filterTypes?.includes("2")) {
            filter.unAssigned = true;
        }
        else {
            filter.unAssigned = false;

        } if (filterTypes?.includes("3")) {
            filter.closed = true;
        } else {
            filter.closed = false;

        }
        filter.userId = parseInt(userData.userData.userId);
        filter.id = parseInt(filter.id ?? "0");
        filter.sessionId = parseInt(filter.sessionId ?? "0");
        if (!filter.teamLeaderIds) {
            filter.teamLeaderIds = [];
        }
        setIsLoading(true)
        ApiClient.post("Activity/GetFilteredActivities", filter)
            .then(response => {
                if (response.data) {

                    dispatch({ type: "SET_FILTERED_ACTIVITIES", payload: { data: [], totalCount: 0 } });

                    const data = [...response.data?.data];
                    WorkplanHelper.mapActivities(data);

                    setIsLoading(false)
                    dispatch({ type: "SET_FILTERED_ACTIVITIES", payload: { data, totalCount: response.data.totalCount } });

                    dispatch({ type: "CHANGE_INITIAL_STATE" });

                }

            })
            .catch(error => {
                console.error(error);;
                setIsLoading(false)
            });
    }

    const handleFilterTypes = (pills) => {
        dispatch({ type: "SET_ASP_PM_FILTER_TYPES", payload: [...pills] });
    }

    return (<EuiRow >
        {/* <EuiRow className='m-1 w-2'>
            <EuiCheckbox
                label="Un-assigned"
                labelStyle={{ color: "gray", fontSize: 14 }}
                className="w-10"
                id="unAssignedActivitiesCheckbox"
                refValue={activityData.filter.unAssigned}
                defaultChecked={true}
                onChange={(e) => handleChange(e)}
                name="unAssigned" />

            <EuiCheckbox
                label="Assigned"
                labelStyle={{ color: "gray", fontSize: 14 }}
                className="w-10"
                id="assignedActivitiesCheckbox"
                refValue={activityData.filter.assigned || false}
                onChange={(e) => handleChange(e)}
                name="assigned" />

            <EuiCheckbox
                label="Closed"
                labelStyle={{ color: "gray", fontSize: 14 }}
                className="w-10"
                id="closedActivitiesCheckbox"
                refValue={activityData.filter.closed || false}
                onChange={(e) => handleChange(e)}
                name="closed" />
        </EuiRow>*/}
        <EuiPillGroup id="aspPmActivityPillsId" pills={constants.aspPmPills} name="filterTypes" onChange={(filteredPills) => handleFilterTypes(filteredPills)} />
        <EuiRow className='w-10' style={{ height: "400px", overflowY: "scroll" }}>
            <label className="color-gray" style={{ fontSize: 14, width: "100%", padding: 5, paddingBottom: 0 }}>Team Leader (=)</label>
            <EuiNewMultiSelect
                style={{ width: "400px" }}
                className="m-1 w-10"
                inputClassName="w-10"
                label="Team Leader (=)"
                labelClass="color-gray"
                id="teamLeaderAspPmMultiSelect"
                name="teamLeaders"
                options={activityData.aspPmTeamLeaders}
                key={activityData.aspPmTeamLeaders}
                onChange={(e, options) => handleChange(e, options)}
                value={activityData.filter.teamLeaders}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="activityIdFilter" name="id" label="Activity ID (=)" labelClass="color-gray"
                value={activityData.filter.id || ""} onChange={(e) => handleChange(e)}
                clear={true}
                onClear={() => handleChange({ target: { value: "", name: "id" } })}
            />

            <EuiTextField className="m-1 w-10" fullwidth id="activityErisiteWorkplanIdFilter" name="erisiteWorkplanId" label="Erisite Workplan ID(=)" labelClass="color-gray"
                value={activityData.filter.erisiteWorkplanId || ""} onChange={(e) => handleChange(e)}
                clear={true}
                onClear={() => handleChange({ target: { value: "", name: "erisiteWorkplanId" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="activityNameFilter" name="name" label="Activity Name (*)" labelClass="color-gray"
                value={activityData.filter.name || ""} onChange={(e) => handleChange(e)}
                clear={true}
                onClear={() => handleChange({ target: { value: "", name: "name" } })}
            />
            <EuiTextField className="m-1 w-10" fullwidth id="activityWorkplanNameFilterPm" name="workplanName" label="Workplan Name (*)" labelClass="color-gray"
                value={activityData.filter.workplanName || ""} onChange={(e) => handleChange(e)}
                clear={true}
                onClear={() => handleChange({ target: { value: "", name: "workplanName" } })}
            />

            <EuiDatepicker className="m-1 w-10" fullwidth id="activityActualEndFilterPm" name="actualEndDate" label="Actual End Date (=)" labelClassName="color-gray"
                value={activityData.filter.actualEndDate || ""} onChange={(e) => handleChange(e)}

            />
            <EuiTextField className="m-1 w-10" fullwidth id="activityCustomerSiteIdFilter" name="customerSiteId" label="Customer Site ID (*)" labelClass="color-gray"
                value={activityData.filter.customerSiteId || ""} onChange={(e) => handleChange(e)}
                clear={true}
                onClear={() => handleChange({ target: { value: "", name: "customerSiteId" } })}
            />

        </EuiRow>
        <EuiRow>
            <button className={"btn primary m-1 " + (isLoading ? "loading disabled" : "")} onClick={() => getActivities()}>Search</button>
        </EuiRow>
    </EuiRow >);
}

export default AspPmFilter;