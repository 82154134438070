/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withRouter } from "react-router-dom";
import EuiRow from "../../../eui-components/row";
import { connect } from "react-redux";
import "../../../css/livestreamStyle.css";
import SettingsItem from "./settingsItem";
import ApproveSessionContent from "../../sessions/approve-session/approveSessionContent";
import DisplayItem from "./displayItem";
import FilesItem from "./filesItem";
import ParticipantsItem from "./participantsItem";
import ApproveEhsPing from "../../ehs-inbox/ehs-pings/approveEhsPing";
import constants from "../../../utils/constants";
import ApiClient from "../../../utils/api-client";
import TeamCertificationItem from "./team-certification/teamCertificationItem";
import PageHelper from "../../../utils/page-helper";
import ApproveActivity from "../../activity-inbox/activities/approveActivity";
import DialogModal from "../../../components/dialog";
import ApproveIMActivity from "../../im-inbox/approveIMActivity";
import VehiclesEHS from "../vehicles/VehiclesEHS";
import Cookies from "js-cookie";
import GeneralChat from "../../chatTools/dashboard";
import { Tooltip } from "@material-ui/core";
import { ChatCircleDots } from "phosphor-react";
import { setChat } from "../../../store/actions/roomAction";

class SideBarLiveStream extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedItem: 0,
            openDialog: false,
            isGreen: false,
        }
    }
   
    componentDidMount() {
         this.openSlidePopup("100%", 2);
    //    this.openSlidePopup(this.props.navData.navBarView ? "79%" : "100%" , 7);

        if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION || this.props.liveStreamData.liveSessionInfo.sessionCode) {
            this.props.toggleSlidePopup();
        }
    }
 
  
    isPublisherReady = () => {
        return !this.props.liveStreamData.publisher || !this.props.liveStreamData.publisher.stream || !this.props.liveStreamData.publisher.stream.connection;
    }
    openSlidePopup = (width, type) => {
        if (type === this.state.selectedItem || !this.props.liveStreamData.show) {
            this.props.toggleSlidePopup();
        }
        this.setState({ selectedItem: type });
        this.props.changeWidthSlidePopup(width);
        let checklistContent = <EuiRow></EuiRow>;
        let title = "Checklist";
        if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION) {
            checklistContent = <ApproveSessionContent routing="false" />;
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.ACTIVITY) {
            checklistContent = <ApproveActivity routing="false" />;
            title = this.props.liveStreamData.liveSessionInfo.siteName || "Checklist";
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.EHS) {
            checklistContent = <ApproveEhsPing routing="false" />;
            title = this.props.liveStreamData.liveSessionInfo.siteName || "Checklist";
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.VSS) {
            checklistContent = <ApproveIMActivity routing="false" />;
            title = this.props.liveStreamData.liveSessionInfo.siteName || "Checklist";
        }
        this.props.setChat(this.props.liveStreamData?.liveSessionInfo?.activityId, this.props.liveStreamData?.liveSessionInfo?.rscScope, null);
        // eslint-disable-next-line default-case
        switch (type) {
            case 1:
                this.props.setContent({ title: "Settings", htmlContent: <SettingsItem /> })
                return;
            case 2:
                this.props.setContent({ title, htmlContent: checklistContent, width })
                return;
            case 3:
                this.props.setContent({ title: "Display", htmlContent: <DisplayItem /> })
                return;
            case 4:
                this.props.setContent({ title: "Documents", htmlContent: <FilesItem /> })
                return;
            case 5:
                this.props.setContent({ title: "Team Certificates", htmlContent: <TeamCertificationItem />, width })
                return;
            case 6:
                this.props.setContent({ title: "Participants", htmlContent: <ParticipantsItem /> })
                return;
            case 7:
            (    this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION ||
                this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.EHS) &&
                this.props.setContent({ title: "Vehicles", htmlContent: <VehiclesEHS />,width })
                  return;
            case 8:
                // eslint-disable-next-line no-unused-expressions, no-self-compare
                (this.props.liveStreamData?.liveSessionInfo?.activityId)>0?
                this.props.setContent({ 
                    title: "Chat" , 
                    Tooltip:"chat",
                    htmlContent:<GeneralChat
                     activityId={this.props.liveStreamData?.liveSessionInfo?.activityId}  
                     ehsPingId={null} 
                      rscScope={this.props.liveStreamData?.liveSessionInfo?.rscScope}
                     />
                    ,width })
                   : this.props.setContent({ 
                       title: "Chat" , 
                       Tooltip:"No chat",
                       htmlContent:<EuiRow>
                           <div className="flex-middle-content m-3 w-10 text-lg">this Session it doesn't have any activity to chat.</div>
                       </EuiRow>,width })
                       
                
            
                      return;
        }

    }

    refreshLiveSession = () => {
        if (this.props.liveStreamData.show) {
            this.props.toggleSlidePopup();
        }

        this.props.setPublisher(undefined);

        ApiClient.post("ActivityInbox/GetLiveSessionInfo",
            { systemId: this.props.liveStreamData.liveSessionInfo.sessionId, type: this.props.liveStreamData.liveSessionInfo.type })
            .then(response => {
                
                if (response.data) {
                    const liveSessionInfo = { ...response.data };
                    liveSessionInfo.sessionName = this.props.liveStreamData.liveSessionInfo.sessionName;
                    liveSessionInfo.siteName = this.props.liveStreamData.liveSessionInfo.siteName;
                    liveSessionInfo.activityId =this.props.liveStreamData.liveSessionInfo.sessionId;
                    if (!liveSessionInfo.sessionCode) {
                        this.props.toggleSlidePopup();
                    }
                    this.props.setLiveSessionInfo(liveSessionInfo);
                
            //   this.getAllVehiclesByAspCompanyId(response.data);
                
                }
            })
            .catch(error => { console.error(error);; });
    }

    leaveOfflineSession = () => {
        this.props.toggleLiveStreamMod(false);
        if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.ACTIVITY) {
            this.props.history.push("/activity-inbox");
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.EHS) {
            this.props.history.push("/ehs-inbox");
        } else if (this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.VSS) {
            this.props.history.push("/im-inbox");
        }
        this.props.setLiveSessionInfo({ type: 0, sessionCode: "", latitude: "", longitude: "", streamingUserName: "", streamingUserId: 0, streamingId: 0, sessionId: 0, sessionName: "", siteName: "" });
    }

    render() {
        return (
            <>
                <div className="left-sidebar">
                    <div className="left-menu">
                        <a className={"left-menu-item " + (this.state.selectedItem === 1 ? "active" : "")}
                            /*onClick={() => this.openSlidePopup(370, 1)} */
                            style={{ display: "none" }}>
                            <i className="icon icon-settings" style={{ fontSize: 25 }}></i>
                            <span className="mt-1">Settings</span>
                        </a> <a className={"left-menu-item " + (this.state.selectedItem === 2 ? "active" : "")} onClick={() => this.openSlidePopup("100%", 2)}>
                            <i className="icon icon-view-list" style={{ fontSize: 25 }}></i>
                            <span className="mt-1">Checklist</span></a>
                        <a className={"left-menu-item " + (this.state.selectedItem === 3 ? "active" : "")} onClick={() => this.openSlidePopup(400, 3)}>
                            <i className="icon icon-terminal" style={{ fontSize: 25 }}></i>
                            <span className="mt-1">Display</span>
                        </a>
                        <a className={"left-menu-item " + (this.state.selectedItem === 4 ? "active" : "")} onClick={() => this.openSlidePopup(700, 4)}>
                            <i className="icon icon-folder" style={{ fontSize: 25 }}></i>
                            <span className="mt-1">Documents</span>
                        </a>
                        {this.props.liveStreamData.liveSessionInfo.type !== constants.systemTypes.SESSION ?
                            <a className={"left-menu-item " + (this.state.selectedItem === 5 ? "active" : "")} onClick={() => this.openSlidePopup("95%", 5)}>
                                <i className={"icon icon-medal " + PageHelper.getVerificationColor(this.props.liveStreamData.teamsLogInfo.teamInfo?.status)} style={{ fontSize: 25 }}></i>
                                <span className="mt-1">
                                    <div className="flex-middle-content">Team</div>
                                    <div>Certificates</div>
                                </span>
                            </a>
                            : null

                        }
                        
                        <a className={"left-menu-item " + (this.state.selectedItem === 6 ? "active" : "")} onClick={() => this.openSlidePopup(400, 6)}>
                            <i className="icon icon-group" style={{ fontSize: 25 }}></i>
                            <span className="mt-1">Participants</span>
                       </a>
                       
                       {        (    this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.SESSION ||
                                  this.props.liveStreamData.liveSessionInfo.type === constants.systemTypes.EHS) ?
                            <a className={"left-menu-item " + (this.state.selectedItem === 7 ? "active" : "")} onClick={() => this.openSlidePopup("100%", 7)}>
                                <i className={"icon icon-vehicle-car m-1 text-lg"} style={{ fontSize: 25 }}></i>
                                <span className="mt-1">
                                    <div>Vehicles</div>
                                </span>
                            </a>
                            : null

                        }

                        <Tooltip title= {this.props.liveStreamData.liveSessionInfo.activityId>0?"This ehs ping has activity to chat":"This ehs ping dosen't have any activity"}>
                        <a className={"left-menu-item " + (this.state.selectedItem ===8 ? "active" : "")} 
                        onClick={() => { this.openSlidePopup("100%", 8) }
                        }>
                        <i className={"icon icon-message-double m-1 text-lg"} style={{ fontSize: 25 }}></i>

                            <span className="mt-1">Chat</span>
                        </a> 
                         </Tooltip>
                        {this.props.liveStreamData.liveSessionInfo.type !== constants.systemTypes.SESSION && !this.props.liveStreamData.liveSessionInfo.sessionCode ?
                            <>
                                <a className={"left-menu-item "} onClick={() => this.refreshLiveSession()}>
                                    <i className="icon icon-camera-classic" style={{ fontSize: 25 }}></i>
                                    <span className="mt-1">Join</span>
                                </a>
                                <a className={"left-menu-item "} onClick={() => this.setState({ openDialog: true })}>
                                    <i className="icon icon-logout" style={{ fontSize: 25 }}></i>
                                    <span className="mt-1">Exit</span>
                                </a>
                            </>
                            : null
                        }

                    </div>

                </div>

                <DialogModal
                    buttonName="Confirm"
                    content={"Are you sure you want to leave from session? If you leave your modifications will be lost."}
                    handleClose={() => this.setState({ openDialog: false })}
                    id="stopRecordingDialog"
                    onClick={this.leaveOfflineSession}
                    open={this.state.openDialog}
                    title="Exit"
                    type="warning"
                >
                </DialogModal>
            </>
        );
    }
}

const mapStateToProps = state => ({ 
    userData: state.user, 
    sessionData: state.session, 
    liveStreamData: state.liveStream,
    vehicles:state.vehicle?.vehicleList,
    navData: state.navigation,
    activityInboxData: state.activityInbox,
    currentIMActivit: state.imInbox.currentIMActivit

 });
const mapDispatchToProps = dispatch => {
    return {
        toggleSlidePopup: () => dispatch({ type: "TOGGLE_SLIDE_POPUP" }),
        changeWidthSlidePopup: (width) => dispatch({ type: "CHANGE_WIDTH_SLIDE_POPUP", payload: width }),
        toggleLiveStreamMod: (mod) => dispatch({ type: "TOGGLE_LIVE_STREAM_MOD", payload: mod }),
        setContent: (content) => dispatch({ type: "SET_CONTENT", payload: content }),
        setPublisher: publisher => dispatch({ type: "SET_PUBLISHER", payload: publisher }),
        setLiveSessionInfo: (liveSessionInfo) => dispatch({ type: "SET_LIVE_SESSION_INFO", payload: liveSessionInfo }),
        setVehicleList: (vehicle) =>  dispatch({ type: "SET_VEHICLE_LIST", payload: vehicle }),
        setChat: (activityId, rscScope, ehsPingId) => dispatch(setChat(activityId, rscScope, ehsPingId))


    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SideBarLiveStream));
