import React, { useState } from "react";
import { connect } from "react-redux";
import EuiRow from "../../eui-components/row";
import EuiTextField from "../../eui-components/textfield";
import DateUtils from "../../utils/date-utils";
import EuiTextArea from "../../eui-components/textarea";
import constants from "../../utils/constants";


class ErisiteInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        return (<EuiRow>
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="workplanNameErisite" name="name" label="Workplan Name" value={this.props.workplanData.workplan.name} />
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="erisiteWorkplanId" name="erisiteWorkplanId" label="Erisite Workplan ID" value={this.props.workplanData.workplan.erisiteWorkplanId} />
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="wpAssignmentIsErisite" name="wpAssignmentID" label="WP Assignment ID" value={this.props.workplanData.workplan.wpAssignmentID} />
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="erisiteProjectId" name="erisiteProjectId" label="Erisite Project ID" value={this.props.workplanData.workplan.erisiteProjectId} />
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="wpTypeErisite" name="name" label="WP Type" value={this.props.workplanData.workplan.wpType} />
            {this.props.workplanData.workplan.wpType === constants.wpTypeFarend ?
                <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="farendId" name="farendId" label="Farend ID" value={this.props.workplanData.workplan.farendName} />
                : null
            }
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="erisiteStatus" name="erisiteStatus" label="Erisite Status" value={this.props.workplanData.workplan.erisiteStatus} />
            <EuiTextField className="p-1 w-5" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="erisiteUpdateDatetime" name="erisiteUpdateDatetime" label="Erisite Update Datetime" value={DateUtils.toDateString(this.props.workplanData.workplan.erisiteUpdateDatetime,null,true) || "(-----,----)"} />           
            <EuiTextArea className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} style={{minHeight: 50}} id="erisiteDescription" name="erisiteDescription" label="Erisite Workplan Description" value={this.props.workplanData.workplan.erisiteDescription} />
            {
                //<EuiTextArea className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} id="eriNetworkElement" name="eriNetworkElement" label="Site Network Element" value={this.props.workplanData.workplan.eriNetworkElement} />
            }
            <EuiTextArea className="p-1 w-10" fullwidth readOnly style={{ padding: 0 }} labelstyle={{ color: "gray" }} style={{ minHeight: 200 }}  id="wpRelatedNetworkElement" name="wpRelatedNetworkElement" label="Related Network Element" value={this.props.workplanData.workplan.wpRelatedNetworkElement} />
        </EuiRow >);
    }
}

const mapStateToProps = state => ({ userData: state.user, navbarData: state.navigation, workplanData: state.workplan });
const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ErisiteInfo); 