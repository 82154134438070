import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import DialogModal from "../../components/dialog";
import EuiAccordion from '../../eui-components/accordion';
import EuiDatepicker from "../../eui-components/datepicker";
import EuiTile from "../../eui-components/tile";
import EuiLoading from "../../eui-components/loading";
import EuiRow from "../../eui-components/row";
import EuiTable from "../../eui-components/table";
import ApiClient from "../../utils/api-client";
import constants from "../../utils/constants";
import DateUtils from "../../utils/date-utils";
import PageHelper from "../../utils/page-helper";
import TeamMembersInfo from "./TeamMembersInfo";
import _ from "lodash";
import { Notification } from '@eds/vanilla';
import CommonHelper from "../../utils/common-helper";

const ProjectRepresentatives = (props) => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user);
    const userAdmin = useSelector(state => state.userAdmin);
    const loginRoles = useSelector(state => state.userAdmin.loginRoles);
    const Project = useSelector(state => state.projects);
    const [openDialog, setOpenDialog] = useState(false);
    const [teamMembers, setTeamMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editMod, setEditMod] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [deletedItem, setDeletedItem] = useState(null);
    const [editedItem, setEditedItem] = useState(null);
    const [userRoles, setUserRoles] = useState([]);
    const teamMember = useSelector(state => state.projects.member);

    useEffect(() => {
        if (userRoles.length == 0 && loginRoles != null) { setUserRoles(userAdmin.loginRoles?.map(x => { return { value: x.id, text: x.loginName } })) };

        if (!teamMembers || teamMembers.length === 0) {
            setIsLoading(true);
            ApiClient.post("Projects/GetTeamMembersByProjectId", { id: Project.project.id })
                .then(response => {
                    setIsLoading(false)
                    const arr = _.groupBy(response.data.data, item => item.user.jobTitle);
                    setTeamMembers(arr);
                    dispatch({ type: "SET_TEAM_MEMBERS", payload: response.data });
                }).catch(error => console.error(error))
        }
        if (confirmDelete == true && deleteWarning == false && deletedItem !== null) {
            deleteTeamMember(deletedItem);
        }
        if (confirmDelete == true) {
            setDeleteWarning(false);
            
        }
    }, [confirmDelete, deleteWarning])
    const handleChange = (e) => {
        const value = e.target.value;
            const updatedTeamMember = { ...Project.member }
            updatedTeamMember.validUntil = value;
            dispatch({ type: "SET_TEAM_MEMBER", payload: updatedTeamMember })
        }
    const saveEditedDate = () => {
        setEditMod(false);
        AddTeamMember();
    }
    const editMember = (teamMember) => {
        setEditMod(true)
        setEditedItem(teamMember);
        dispatch({ type: "SET_TEAM_MEMBER", payload: teamMember })
    }
    const deleteTeamMember = (teamMember) => {
        setConfirmDelete(false);
        setIsLoading(true);
        let visitor = CommonHelper.getVisitorData();
        teamMember.logInfo = {
            userId: parseInt(userData.userData.userId),
            logBrowser: visitor.vBrowser,
            logIP: visitor.vIP,
            logLocation: visitor.vCountry,
            latitude: visitor.latitude,
            longitude: visitor.longitude,
            logDetail: Object.entries(teamMember).reduce((a, b) => {
                return a + b.join(" : ") + "    |    ";
            }, ""),
            userLogin: {
                nameOrMail: userData.userMail,
                jobRole: userData.loginRole,
                password: userData.password
            }
        }
        
        ApiClient.post("Projects/DeleteTeamMember", teamMember).then(response => {
            ApiClient.post("Projects/GetTeamMembersByProjectId", { id: Project.project.id })
                .then(response => {
                    setIsLoading(false);
                    dispatch({ type: "SET_TEAM_MEMBERS", payload: response.data });
                    const arr = _.groupBy(response.data.data, item => item.user.jobTitle);
                    const notification = new Notification({
                        title: 'Your transaction is completed successfully!',
                        timeout: 5000,
                        icon: 'icon-check'
                    });
                    notification.init();
                    setTeamMembers(arr);
                }).catch(error => {
                    console.error(error);
                    toast.error("An error occurred retrieving data, please try again later.", { containerId: "PR", position: toast.POSITION.TOP_CENTER });
                })
        }).catch(error => {
            
            toast.error("Could not process the transaction, Please try again later.", { containerId: "PR", position: toast.POSITION.TOP_CENTER });
        })
    }
    const validateUserInput = () => {
        if (!teamMember.userId || !teamMember.validUntil) {
            
            toast.error("Please, fill the mandatory fields.", { containerId: "PR", position: toast.POSITION.TOP_CENTER });
            return;
        }
        else {
            AddTeamMember();
        }
    }
    const AddTeamMember = () => {
        let duplicate = false;
        let TeamMember = {}
        if (teamMember.id > 0) {
                setIsLoading(true);
                ApiClient.post("Projects/GetTeamMembersById", { id: teamMember.id }).then(response => {
                    
                    TeamMember = {
                        ...response.data,
                        validUntil: teamMember.validUntil
                    }
                    let visitor = CommonHelper.getVisitorData();
                    TeamMember.logInfo = {
                        userId: parseInt(userData.userData.userId),
                        logBrowser: visitor.vBrowser,
                        logIP: visitor.vIP,
                        logLocation: visitor.vCountry,
                        latitude: visitor.latitude,
                        longitude: visitor.longitude,
                        logDetail: Object.entries(teamMember).reduce((a, b) => {
                            return a + b.join(" : ") + "    |    ";
                        }, ""),
                        userLogin: {
                            nameOrMail: userData.userMail,
                            jobRole: userData.loginRole,
                            password: userData.password
                        }
                    }
                    
                    ApiClient.post("Projects/AddTeamMember", TeamMember).then(response => {
                        ApiClient.post("Projects/GetTeamMembersByProjectId", { id: Project.project.id })
                            .then(response => {
                                const notification = new Notification({
                                    title: 'Your transaction is completed successfully!',
                                    timeout: 5000,
                                    icon: 'icon-check'
                                });
                                notification.init();
                                setOpenDialog(false);
                                const arr = _.groupBy(response.data.data, item => item.user.jobTitle);
                                dispatch({ type: "SET_TEAM_MEMBERS", payload: response.data });
                                setTeamMembers(arr);
                                setIsLoading(false);
                                dispatch({ type: "SET_TEAM_MEMBER", payload: {} });
                            }).catch(error => {
                                console.error(error);
                                toast.error("An error occurred retrieving data, please try again later.", { containerId: "PR", position: toast.POSITION.TOP_CENTER });
                            })
                    }).catch(error => {
                        toast.error("An error occurred, please try again later.", { containerId: "PR", position: toast.POSITION.TOP_CENTER });
                        
                    })
                })
            }
        else {
            
            const addedTeamMember = {
                id: 0,
                userId: parseInt(teamMember.userId),
                projectId: parseInt(Project.project.id),
                validUntil: teamMember?.validUntil,
                assignedBy: parseInt(userData.userData.userId)
            }
                    for (const [key, values] of Object.entries(teamMembers)) {
                        const listOfSimilarUsers = values.filter(item => item.userId === addedTeamMember.userId);
                        // needs to be revisited
                        if (listOfSimilarUsers.length != 0) {
                            
                                    duplicate = true;
                        }
                            }
            if (duplicate === false) {
                let visitor = CommonHelper.getVisitorData();
                addedTeamMember.logInfo = {
                    userId: parseInt(userData.userData.userId),
                    logBrowser: visitor.vBrowser,
                    logIP: visitor.vIP,
                    logLocation: visitor.vCountry,
                    latitude: visitor.latitude,
                    longitude: visitor.longitude,
                    logDetail: Object.entries(addedTeamMember).reduce((a, b) => {
                        return a + b.join(" : ") + "    |    ";
                    }, ""),
                    userLogin: {
                        nameOrMail: userData.userMail,
                        jobRole: userData.loginRole,
                        password: userData.password
                    }
                }
                setOpenDialog(false);
                setIsLoading(true);
                ApiClient.post("Projects/AddTeamMember", addedTeamMember).then(response => {
                        ApiClient.post("Projects/GetTeamMembersByProjectId", { id: Project.project.id })
                            .then(response => {
                                const notification = new Notification({
                                    title: 'Your transaction is completed successfully!',
                                    timeout: 5000,
                                    icon: 'icon-check'
                                });
                                notification.init();
                                const arr = _.groupBy(response.data.data, item => item.user.jobTitle);
                                dispatch({ type: "SET_TEAM_MEMBERS", payload: response.data });
                                setTeamMembers(arr);
                                setIsLoading(false);
                                dispatch({ type: "SET_TEAM_MEMBER", payload: {} });
                            }).catch(error => {
                                console.error(error);
                                toast.error("An error occurred retrieving data, please try again later.", { containerId: "PR", position: toast.POSITION.TOP_CENTER });
                                setIsLoading(false);
                            })
                    }).catch(error => {
                        toast.error("An error occurred, please try again later.", { containerId: "PR", position: toast.POSITION.TOP_CENTER });
                        
                        setIsLoading(false);
                    })
                }
                else {
                    setOpenDialog(false);
                    toast.error("Can't add the same team member twice.", { containerId: "PR", position: toast.POSITION.TOP_CENTER });
                    dispatch({ type: "SET_TEAM_MEMBER", payload: {} });
                    return;
                }
        }
        }
    const setDeletionItem = (teamMember) => {
        setDeletedItem(teamMember);
        setDeleteWarning(true);
    }
    const viewMembers = () => {
        const rows = [];
        let dataItems = [];
        for (const [key, value] of Object.entries(teamMembers)) {
            dataItems = value.map(item => {
                return {
                    name: item.user?.name,
                    email: item.user?.email,
                    vaildUntil: (editMod && (editedItem == item)) ? (
                        <EuiDatepicker
                            type="text"
                            placeholder="YYYY-MM-DD"
                            disabled={false}
                            id={"_" + item.id}
                            name="editedValidty"
                            maxLength="10"
                            style={{ width: "250px" }}
                            value={item.validUntil || ""}
                            className="with-icon"
                            onChange={(e) => handleChange(e)}
                            required={true}
                        />) : (item.validUntil ? DateUtils.toDateString(item.validUntil) : ""),
                    editValidty: editMod && (editedItem == item) ? (<><button style={{ border: "none" }} className="btn icon-btn" onClick={() => saveEditedDate()}><i style={{ color: "green" }} className="icon icon-check"></i></button><button style={{ border: "none" }} className="btn icon-btn" onClick={() => setEditMod(false)}><i style={{ color: "red" }} class="icon icon-cross"></i></button></>)
                        :
                        (<><button disabled={(userData.userData.jobRole != constants.userRoles.ADMIN && userData.userData.jobRole != constants.userRoles.ERICSSON_IM)} style={{ border: "none" }} className="btn icon-btn" onClick={() => editMember(item)}><i className="icon icon-edit"></i></button><button disabled={(userData.userData.jobRole != constants.userRoles.ADMIN && userData.userData.jobRole != constants.userRoles.ERICSSON_IM)} style={{ border: "none" }} className="btn icon-btn" onClick={() => setDeletionItem(item)}><i className="icon icon-trashcan"></i></button></>)
                }
            })
            
            rows.push({
                key: key,
                style: { width: "100%" },
                id: key + "_item",
                title: key === 'null' ? "-" : key,
                titleStyle: {
                    fontWeight: 500,
                },
                style: { width: "100%" },
                contentStyle: {
                },
                content: <EuiTable
                    classes="table tiny"
                    columns={projectMembersColumns}
                    data={dataItems}
                    id={"_" + key}
                    key={key}
                    styleHeader={{ width: "200px", paddingLeft: "15px" }}
                    styleTd={{ width: "200px", paddingLeft: "15px" }}
                    scroll={true}
                    sortable={false}
                    paginated={false}
                />
            })
        }
        return (<EuiTile tileStyle={{ padding: 0 }} className={isLoading ? " disabled " : ""} >
            <EuiAccordion id="teamMembersAccordion"
                noAutoClose={true}
                style={{ width: "100%" }}
                items={rows}
            />
        </EuiTile>);
    }
    const projectMembersColumns = [{
        title: "Name",
        key: "name",
        sort: "none",
        width: "10%"
    },
    {
        title: "Email",
        key: "email",
        sort: "none",
        width: "10%"
    },
    {
        title: "Validity date",
        key: "validUntil",
        sort: "none",
        width: "10%"
    }, {
        title: "",
        key: "ediValidty",
        sort: "none",
        width: "10%"
    }
    ];
    return (
        <EuiRow disabled={isLoading ? "disabled" : ""} key={isLoading}>
            <EuiLoading id="teamsSearchLoading" isLoading={isLoading} style={{ zIndex: 99 }} />
            <ToastContainer enableMultiContainer containerId={'PR'} autoClose={2500} />
            <div className="flex-end-content w-10">
                <button title="Add Project Teams" disabled={(userData.userData.jobRole != constants.userRoles.ADMIN && userData.userData.jobRole != constants.userRoles.ERICSSON_IM)} style={{ minWidth: "auto", padding: "5px 10px 5px", margin: "10px" }} className="btn icon-btn"
                    onClick={() => setOpenDialog(true)}>
                    <i style={{ fontSize: "20px" }} className="icon icon-plus" />
                </button>
            </div>

            {(
                (Object.values(teamMembers).length == 0) ? <div className="flex-middle-content m-2 w-10" style={{ fontSize: "18px", color: "gray" }}>Add project teams to the project...</div> :
                    viewMembers()
                )}
                <DialogModal
                    style={{ height: "1000px" }}
                    buttonName="Save"
                    content={<TeamMembersInfo />}
                    handleClose={() => {
                        setOpenDialog(false);
                        dispatch({ type: "SET_TEAM_MEMBER", payload: {} });
                    }
                    }
                    disabled={isLoading}
                    id="addMemberDialog"
                    onClick={() => validateUserInput()}
                    open={openDialog}
                    title="Add a new team member"
                    contentStyle={{ height: "280px" }}
                    disableBackdropClick={true}
                />
            <DialogModal
                buttonName="Confirm"
                type="warning"
                title={"Confirm deleting a project member"}
                content="Are you sure you want to delete this member?"
                handleClose={() => setDeleteWarning(false)}
                mainButtonStyle={{ backgroundColor: "red", borderColor: "red" }}
                id="alertDialog"
                onClick={() => setConfirmDelete(true)}
                open={deleteWarning}
                style={{ width: "380px", height: "50%" }}
                contentStyle={{ width: "380px" }}
                disableBackdropClick={true}
            />
            </EuiRow>)
}
export default ProjectRepresentatives;
