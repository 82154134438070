const initialState = {
    filter: { endDate: null, startDate: null },
    rawData: [],
    EricssonFTR: [],
    CustomerFTR: [],
    TeamEfficiency: [],
    RSCAdoption: [],
    EHSCompliance: [],
    hasData: false,
    planType: "",
    dateSelected: {
        startDate: null,
        endDate: null
    },
    aspPmTeamLeaders: [],
    kpis: {},
    sitesDetails: {},
    isLoading: true,
    resources: [],
    rscCountries: [],
    selectedGroupingColumns: ["resourceId"],
    actualGroupingColumns: ["resourceId"],
    filterControls:[],

}

export default (state = initialState, action) => {
    switch (action.type) {
        case "SET_DASHBOARD_FILTER":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_RAW_DATA":
            return {
                ...state,
                rawData: action.payload
            };
        case "SET_KPIS":
            return {
                ...state,
                kpis: action.payload
            };
        case "SET_KPIS_DETAILS":
            return {
                ...state,
                sitesDetails: action.payload
            };
        case "SET_PLAN_TYPE":
            return {
                ...state,
                planType: action.payload
            };
        case "SET_DATE":
            return {
                ...state,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            };
        case "SET_DASHBOARD_FILTER":
            return {
                ...state,
                filter: action.payload
            };
        case "SET_ASP_PM_DASHBORAD_TEAM_LEADERS":
            return {
                ...state,
                aspPmTeamLeaders: action.payload

            }
        case "SET_DASHBOARD_ISLOADING":
            return {
                ...state,
                isLoading: action.payload
            }
        case "SET_ALL_RESOURCES":
            return {
                ...state,
                resources: action.payload
            }
        case "SET_SELECTED_DATES":
            return {
                ...state,
                dateSelected: action.payload
            }
        case "SET_RSC_COUNTRIES":
            return {
                ...state,
                rscCountries: action.payload
            }
        case "SET_TEAM_GROUPING_COLUMNS":
            return {
                ...state,
                selectedGroupingColumns: action.payload
            }
        case "SET_ACTUAL_TEAM_GROUPING_COLUMNS":
            return {
                ...state,
                actualGroupingColumns: action.payload
            }
        case "SET_FILTER_CONTROLS":
            return {
                ...state,
                filterControls: action.payload
            }
        
        default: { return state; }
    }
}